import MapService from '@services/mapService/mapService';
import {
    FarmSeasonFieldCropDataT,
    FarmSeasonFieldT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SelectFieldStepPropsT } from './SelectFieldStepTypes';
import SelectFieldStepView from './SelectFieldStepView';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const SelectFieldStep = ({ selectedCrop, formFields, setFormFields, popStep }: SelectFieldStepPropsT): JSX.Element => {
    const { t } = useTranslation();
    // get map state
    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });
    const [selectedFields, setSelectedFields] = useState<Partial<FarmSeasonFieldT>[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => {
                MapService.updateSelectedPolygons({ selectedPolygons: [] });
            };
        }

        MapService.selectablePolygons({ selectMode: true });
        MapService.defineCondition({
            selectCondition: ({ id: polygonId }) => {
                const field = { ...formFields.find((formField) => formField.id === polygonId) };

                const oldFieldCrops = field.field_crops?.filter(
                    (fieldCrop) => fieldCrop.farm_season_crop.id !== selectedCrop.id,
                );

                if (field.field_crops && field.field_crops?.length > 0) {
                    field.field_crops = oldFieldCrops?.map((fieldCrop) => {
                        if (
                            oldFieldCrops.filter((oldFieldCrop) => oldFieldCrop.coupled_id === fieldCrop.coupled_id)
                                .length === 1
                        ) {
                            return {
                                ...fieldCrop,
                                yield: null,
                                coupled_id: null,
                            };
                        }

                        return {
                            ...fieldCrop,
                        };
                    });
                }

                return {
                    selectType: field.field_crops && field.field_crops.length > 0 ? 'relations' : null,
                    field: {
                        ...field,
                        field_crops: [
                            ...(field.field_crops as FarmSeasonFieldCropDataT[]),
                            {
                                order: (field.field_crops?.length as number) + 1,
                                coupled_id: null,
                                yield: null,
                                farm_season_crop: {
                                    id: selectedCrop.id,
                                    is_accompanied: selectedCrop.is_accompanied,
                                    crop: {
                                        id: selectedCrop.crop.id,
                                        translation_slug: selectedCrop.crop.translation_slug,
                                    },
                                },
                            },
                        ],
                    },
                    onConfirm: (confirmedField) =>
                        setSelectedFields((currentFields) => [
                            ...currentFields.filter((currentField) => currentField.id !== confirmedField.id),
                            confirmedField,
                        ]),
                };
            },
        });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
            MapService.defineCondition({ selectCondition: null });
        };
    }, []);

    useEffect(() => {
        const selected = formFields?.filter((field) =>
            thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id),
        );

        const newFields = [
            ...selected.map((selectedField) => {
                let field;
                let newFieldCropArr;
                const foundField = selectedFields.find((sField) => sField.id === selectedField.id);

                if (foundField) {
                    field = { ...foundField };
                    newFieldCropArr = [...(foundField.field_crops as FarmSeasonFieldCropDataT[])];
                } else {
                    field = { ...selectedField };
                    newFieldCropArr = [...(selectedField.field_crops as FarmSeasonFieldCropDataT[])];
                }

                if (newFieldCropArr.length === 0) {
                    newFieldCropArr.push({
                        order: selectedField.field_crops ? selectedField.field_crops.length + 1 : undefined,
                        coupled_id: null,
                        yield: null,
                        farm_season_crop: {
                            id: selectedCrop.id,
                            is_accompanied: selectedCrop.is_accompanied,
                            crop: {
                                id: selectedCrop.crop.id,
                                translation_slug: selectedCrop.crop.translation_slug,
                            },
                        },
                    });
                }

                return {
                    ...field,
                    field_crops: newFieldCropArr,
                };
            }),
        ];

        setSelectedFields(newFields);
    }, [thisMap.selectedPolygons]);

    const onOrganise = (fieldId?: number) => {
        MapService.openModal({
            modal: 'relations',
            modalData: {
                field: selectedFields.find((selectedField) => selectedField.id === fieldId) as FarmSeasonFieldT,
                onConfirm: (newField) =>
                    setSelectedFields((currentSelectedFields) => [
                        ...currentSelectedFields.filter((field) => field.id !== fieldId),
                        newField,
                    ]),
            },
        });
    };

    useEffect(() => {
        const formSelectedFields = formFields
            ?.filter((field) => field.field_crops?.some((crop) => crop.farm_season_crop.id === selectedCrop.id))
            .map((oldField) => ({ ...oldField }));

        if (selectedFields.length !== formSelectedFields?.length) {
            setSelectedFields(formSelectedFields);
        }

        if (thisMap?.selectedPolygons?.length !== formSelectedFields?.length) {
            MapService.updateSelectedPolygons({
                selectedPolygons: formSelectedFields.map((field) => field.polygon),
            });
        }
    }, [formFields]);

    const onConfirm = () => {
        setFormFields((values) => ({
            fields: values.fields.map((field) => {
                const newField = { ...field };
                const selectedField = selectedFields.find((sField) => sField.id === field.id);

                // Delete this type of crop from all fields
                if (newField.field_crops && newField.field_crops.length > 0) {
                    newField.field_crops = newField.field_crops.filter(
                        (field_crop) => field_crop.farm_season_crop.id !== selectedCrop.id,
                    );
                }

                // Check if Grouped still exists
                if (newField.field_crops && newField.field_crops.length > 0) {
                    newField.field_crops = newField.field_crops.map((fieldCrop) => {
                        if (
                            newField.field_crops?.filter(
                                (oldFieldCrops) => oldFieldCrops.coupled_id === fieldCrop.coupled_id,
                            ).length === 1
                        ) {
                            return {
                                ...fieldCrop,
                                yield: null,
                                coupled_id: null,
                            };
                        }

                        return {
                            ...fieldCrop,
                        };
                    });
                }

                // Add additional or create this type of crop for field
                if (selectedField) {
                    newField.field_crops = selectedField.field_crops;
                }

                return { ...newField };
            }),
        }));
        setIsSubmitted(true);
        popStep();
    };

    const onSelectAll = () => {
        const fieldsNotSelected = formFields.filter(
            (formField) => !selectedFields.find((sF) => sF.id === formField.id),
        );
        const fieldsWithoutCropOrWithCurrentNotSelected = fieldsNotSelected.filter(
            (formField) =>
                !formField.field_crops?.length ||
                (formField.field_crops?.length === 1 &&
                    formField.field_crops[0].farm_season_crop.id === selectedCrop.id),
        );
        setSelectedFields((fields) => [...fields, ...fieldsWithoutCropOrWithCurrentNotSelected]);
        MapService.updateSelectedPolygons({
            selectedPolygons: [
                ...(thisMap.selectedPolygons ?? []),
                ...fieldsWithoutCropOrWithCurrentNotSelected.map((field) => field.polygon),
            ],
        });
    };

    return (
        <SelectFieldStepView
            isSubmitted={isSubmitted}
            selectedCrop={selectedCrop}
            selectedFields={selectedFields}
            t={t}
            onConfirm={onConfirm}
            onOrganise={onOrganise}
            onSelectAll={onSelectAll}
        />
    );
};

export default SelectFieldStep;
