import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import { BigNumberUnit, BoxedIcon } from '@ui-kit';
import useMetricStyle from './Metric.style';
import { MetricPropsT } from './Metric.types';

export const Metric = ({ boxedIconProps, bigNumberUnitProps, label, tooltipScProps }: MetricPropsT) => {
    const { classes } = useMetricStyle();

    return (
        <Box>
            <Box className={classes.container}>
                <BoxedIcon {...boxedIconProps} />
                {bigNumberUnitProps && <BigNumberUnit {...bigNumberUnitProps} />}
            </Box>
            {label && (
                <Typography
                    sx={{ display: 'flex', alignItems: 'center', marginLeft: '3rem', fontSize: '1rem' }}
                    variant="body2"
                >
                    {label}&nbsp;
                    {tooltipScProps && (
                        <TooltipSc display={'flex'} translationSlug={tooltipScProps.text}>
                            <InfoOutlinedIcon className={classes.infoIcon} />
                        </TooltipSc>
                    )}
                </Typography>
            )}
        </Box>
    );
};
