// import { GetFarmSeasonCropsDispatchTypesT } from '@actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELD_LOADING = 'POST_FARM_SEASON_FIELD_LOADING';
export const POST_FARM_SEASON_FIELD_FAILED = 'POST_FARM_SEASON_FIELD_FAILED';
export const POST_FARM_SEASON_FIELD_SUCCESS = 'POST_FARM_SEASON_FIELD_SUCCESS';

export type PostFarmSeasonFieldDataT = {
    field: Partial<FarmSeasonFieldT>;
};

export type PostFarmSeasonFieldResponseDataT = {
    field: FarmSeasonFieldT;
};

export type FarmSeasonFieldPostCallBackT = (dispatch: Dispatch<PostFarmSeasonFieldDispatchTypesT>) => Promise<boolean>;

export type PostFarmSeasonFieldResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldLoadingT = {
    type: typeof POST_FARM_SEASON_FIELD_LOADING;
};

export type PostFarmSeasonFieldFailedT = {
    type: typeof POST_FARM_SEASON_FIELD_FAILED;
    error: PostFarmSeasonFieldResponseErrorT;
};

export type PostFarmSeasonFieldSuccessT = {
    type: typeof POST_FARM_SEASON_FIELD_SUCCESS;
    payload: PostFarmSeasonFieldResponseDataT;
};

export type PostFarmSeasonFieldDispatchTypesT =
    | PostFarmSeasonFieldLoadingT
    | PostFarmSeasonFieldFailedT
    | PostFarmSeasonFieldSuccessT;
