import { useTheme } from '@mui/material';
import { IconCheck, IconInfo, IconWarning } from '@soil-capital/ui-kit';

/**
 * Checks the snackbar variant used and return a boolean value and background color according to it.
 *
 * @param variant The snackbar variant used
 * @returns Which snackbar to display and the icon background color for this snackbar
 */
const useCustomSnackbarLogic = (variant: 'success' | 'error' | 'info') => {
    const theme = useTheme();
    const iconColor = theme.palette.white.main;

    switch (variant) {
        case 'info':
            return {
                iconBackgroundColor: theme.palette.primary.light,
                snackbarIcon: <IconInfo fontSize="medium" style={{ color: iconColor }}></IconInfo>,
            };
        case 'success':
            return {
                iconBackgroundColor: theme.palette.success.main,
                snackbarIcon: <IconCheck fontSize="small" style={{ color: iconColor }}></IconCheck>,
            };
        case 'error':
            return {
                iconBackgroundColor: theme.palette.errorLight.light,
                snackbarIcon: <IconWarning fontSize="small" style={{ color: iconColor }}></IconWarning>,
            };
    }
};

export default useCustomSnackbarLogic;
