import useHandleOpenNewSeason from '@hooks/useHandleOpenNewSeason';
import { useEffect, useState } from 'react';
import { ModalNewSeasonStartedLogicPropsT } from './ModalNewSeasonStartedTypes';

const useModalNewSeasonStartedLogic = ({ controller, onClickActionSuccess }: ModalNewSeasonStartedLogicPropsT) => {
    const [buttonActionSuccessLoading, setbuttonActionSuccessLoading] = useState(false);

    const { handleOpenNewSeason, openNewSeasonLoading } = useHandleOpenNewSeason();

    useEffect(() => {
        if (controller.isModalOpen === true) {
            handleOpenNewSeason();
        }
    }, [controller.isModalOpen]);

    // fetch BE every 3s to know when paiment has been registered
    const handleClickActionSuccess = async () => {
        setbuttonActionSuccessLoading(true);
        await onClickActionSuccess();
        setbuttonActionSuccessLoading(false);
    };

    return {
        handleClickActionSuccess,
        buttonActionSuccessLoading,
        openNextFarmSeasonLoading: openNewSeasonLoading,
    };
};

export default useModalNewSeasonStartedLogic;
