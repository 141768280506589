import ProgressHeader from '@components/ProgressHeader/ProgressHeader';
import useInstallationStyle from '@pages/HomePage/Installation/Installation.style';
import { Typography, Button } from '@mui/material';
import TaskList from '@components/TaskList/TaskList';
import useAppNavigate from '@hooks/useAppNavigate';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';

import { InstallationTasksViewPropsI } from './InstallationTasksTypes';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { ROUTE } from '@constants/routes';

const InstallationView = ({
    taskItems,
    totalProgressPercentage,
    totalProgressRatio,
    t,
    showValidate,
}: InstallationTasksViewPropsI): JSX.Element => {
    const { classes } = useInstallationStyle();
    const taskItemsToDo = taskItems.filter((task) => !task.hidden);
    const isConfirmed = useSelector(
        (state: HomePageStateT) => !!state.farmSeason.currentFarmSeason?.installation_validated_at,
    );
    const navigate = useAppNavigate();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div>
            <ProgressHeader
                image={`/assets/images/installation/farm-step-${Math.trunc(totalProgressPercentage / 10)}.png`}
                percentage={totalProgressPercentage}
                progressTitle={totalProgressRatio}
                withAnimation
                withProgress
            />
            {showValidate && (
                <div className={classes.buttonHolder}>
                    {canEditFarmSeason.access && (
                        <Button
                            className={classes.button}
                            onClick={() => navigate(`${ROUTE.SELF_ENCODING}/tasks/confirm`)}
                        >
                            {t('pages.installationtasks.confirm-button')}
                        </Button>
                    )}
                </div>
            )}
            <div
                className={`${classes.installationPageWrapper} ${isConfirmed ? classes.confirmed : ''}`}
                id="installationPageWrapper"
            >
                <Typography component="h6" variant="h6">
                    {t('pages.installationtasks.your-tasks')}
                </Typography>
                {taskItemsToDo.length > 0 && <TaskList taskItems={taskItemsToDo} />}
            </div>
        </div>
    );
};

export default InstallationView;
