/* If has_access_token is true, we know the httpOnly access_token cookie exist */
export const getHasExistingToken = () => {
    const parsedCookies = getCookies();
    const hasAccessTokenCookie = parsedCookies.has_access_token === '1';
    return {
        hasAccessTokenCookie,
    };
};

export const getCookies = () => {
    const cookieEntries = document.cookie.split(';').map((cookie) => cookie.trim().split('='));
    return Object.fromEntries(cookieEntries);
};

export const removeHasAccessTokenCookie = () => {
    document.cookie = 'has_access_token=0; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};
