import { Typography } from '@mui/material';
import { ModalBase } from '@soil-capital/ui-kit';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import roundNumber from '@utils/roundNumber';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { TotalAreaDecreaseModalPropsT } from './useTotalAreaCheckModalsTypes';
const TotalAreaDecreaseModal = ({
    currentSeasonArea,
    controller,
    previousSeasonArea,
}: TotalAreaDecreaseModalPropsT) => {
    const { t } = useTranslation();
    const getText: TFunction = (finalKey: 'any', tParams) => {
        return t(`pages.installationtasks.add-field-task.modal-total-area-decrease.${finalKey}`, tParams);
    };

    return (
        <ModalBase controller={controller}>
            <ModalBaseLayout
                title={{ text: getText('title') }}
                confirmButton={{ text: getText('confirm'), onClick: controller.onModalConfirm }}
                cancelButton={{ text: getText('cancel'), onClick: controller.onModalCancel }}
            >
                <Typography variant="body2">
                    <Trans
                        components={{ b: <b /> }}
                        i18nKey={getText('previous-season-area', {
                            previousSeasonArea: roundNumber(previousSeasonArea, { round: 'down' }),
                        })}
                    />
                </Typography>
                <Typography variant="body2">
                    <Trans
                        components={{ b: <b /> }}
                        i18nKey={getText('area-diff-with-previous-season', {
                            areaDiffWithPreviousSeason: roundNumber(previousSeasonArea - currentSeasonArea, {
                                round: 'up',
                            }),
                        })}
                    />
                </Typography>
                <Typography variant="body2">
                    <Trans
                        components={{ b: <b /> }}
                        i18nKey={getText('current-season-area', {
                            currentSeasonArea: roundNumber(currentSeasonArea, { round: 'up' }),
                        })}
                    />
                </Typography>
                <Typography variant="body2" marginTop="1rem">
                    <Trans components={{ b: <b /> }} i18nKey={getText('description')} />
                </Typography>
            </ModalBaseLayout>
        </ModalBase>
    );
};

export default TotalAreaDecreaseModal;
