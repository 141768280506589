/* eslint-disable @typescript-eslint/no-explicit-any */
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import tillages from '@constants/tillages';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import useMapFieldReset from '@hooks/useMapFieldReset';
import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import _, { parseInt } from 'lodash';
import pesticidesGetListAction from '@actions/StaticActions/PesticidesGetListAction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import farmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import { postFarmSeasonOperationSpraying } from '@actions/FarmSeasonOperation/PostFarmSeasonOperationSpraying';
import { PesticideT } from '@reducers/StaticReducer/StaticReducerType';
import { GridRenderCellParams } from '@mui/x-data-grid';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import SprayingView from './SprayingView';
import { SprayingDataT, SprayingFormDataT, SprayingTaskRouteParamsT, summaryTableRowDataT } from './SprayingTypes';
import useSprayingViewStyle from './SprayingView.style';
import formatNumber from '@utils/formatNumber';
import { useCanEditFarmOperations } from '@hooks/usePermissions';

/* eslint-disable padding-line-between-statements */
const Spraying = (): JSX.Element => {
    const operation_type = 'spraying';
    const { classes } = useSprayingViewStyle();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { t } = useTranslation();
    const { cropId, tillageId } = useParams<SprayingTaskRouteParamsT>();
    const farm = useSelector((store: HomePageStateT) => store.farm.currentFarm);
    const farmId = farm?.id;
    const farmCountryId = farm?.country_id;
    const farmSeasonId = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason?.id);
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop?.cropsList);
    const fields = useSelector((store: HomePageStateT) => store.farmSeasonField?.fieldsList);
    const pesticides = useSelector((state: HomePageStateT) => state.static.pesticidesList);
    const [submitted, setSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditOperations } = useCanEditFarmOperations(currentFarmSeason);

    const emptyPesticide = {
        pesticide_id: 0,
        quantity: '',
    };
    const emptyOperation = {
        dontHaveAny: false,
        passage: 0,
        width: '',
        pesticides: [emptyPesticide],
    };

    const [operationData, setOperationData] = useState<SprayingDataT>(emptyOperation);
    const [operationDataLoading, setOperationDataLoading] = useState(true);
    const [modalWarning, setModalWarning] = useState(false);

    const cropColors = setupCropPaletteFromCropLegacy(crops);
    const resetMap = useMapFieldReset();

    const selectedFields = fields?.filter(
        (field) =>
            field.tillage_id === parseInt(tillageId || '', 10) &&
            field.field_crops?.filter((field_crop) => field_crop.farm_season_crop.id === parseInt(cropId || '', 10))
                .length,
    );

    // Get entities from url (crop, tillage)
    const currentCrop = useSelector((state: HomePageStateT) =>
        state.farmSeasonCrop?.cropsList?.find((crop) => crop.id === parseInt(cropId || '', 10)),
    );
    const currentTillage = tillages?.find((tillage) => tillage.id === parseInt(tillageId || '', 10));

    // On component load
    useEffect(() => {
        // Update Map
        MapService.addPolygons({
            polygons: selectedFields?.map((field) =>
                addPolygonData({ field, colorPalette: cropColors, withTillage: true }),
            ),
            autofocus: true,
        });

        // Get pesticides list from database if store is empty
        dispatch(pesticidesGetListAction(parseInt(farmCountryId, 10)));

        // Get spraying operation data
        const getOperationData = async () => {
            setOperationDataLoading(true);
            const res = await farmSeasonOperationApiService.get(
                farmId,
                farmSeasonId,
                operation_type,
                tillageId,
                cropId,
            );
            if (res.data?.id) {
                if (res.data.pesticides.length <= 0) res.data.pesticides = [emptyPesticide];
                setOperationData(res.data);
            }
            setOperationDataLoading(false);
        };
        getOperationData();

        // reset map at unload
        return () => {
            resetMap(true);
        };
    }, []);

    // update operation values into database
    const postData = async (values: SprayingFormDataT) => {
        await dispatch(
            postFarmSeasonOperationSpraying(farmId, farmSeasonId, {
                farm_season_crop_id: parseInt(cropId || '', 10),
                tillage_id: parseInt(tillageId || '', 10),
                dont_have_any: values.dontHaveAny,
                passage: parseInt(String(values.passage), 10),
                width: parseInt(String(values.width), 10),
                pesticides: values.pesticides,
            }),
        );
    };

    // Formik Validation
    const validationSchema = Yup.object().shape({
        dontHaveAny: Yup.bool(),
        passage: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when('dontHaveAny', { is: false, then: Yup.number().required() }),
        width: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when('dontHaveAny', { is: false, then: Yup.number().required() }),
        pesticides: Yup.array()
            .notRequired()
            .nullable()
            .when('dontHaveAny', {
                is: false,
                then: Yup.array()
                    .required()
                    .of(
                        Yup.object().shape({
                            pesticide_id: Yup.number(),
                            quantity: Yup.number().required().min(0.001),
                            unit: Yup.string(),
                        }),
                    ),
            }),
    });

    // Formik Form
    const formik = useFormik<SprayingDataT>({
        initialValues: operationData,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema,
        onSubmit: async (values: SprayingDataT, { setSubmitting }) => {
            setSubmitted(true);
            await postData(values);
            setSubmitting(false);
            navigate(`/operations/${cropId}/${tillageId}`);
        },
    });

    const onPesticideChange = (fieldName: any, fieldData: PesticideT | null) => {
        formik.setFieldValue(fieldName, fieldData ? fieldData.id : null);
        formik.setFieldValue(`${fieldName.split('.').splice(-1, 1).join('.')}.unit`, fieldData ? fieldData.unit : null);
    };

    const onClickAddPesticide = (arrayHelpers: any) => arrayHelpers.push(emptyPesticide);
    const onClickDeletePesticide = (arrayHelpers: any, index: number, canDelete: boolean) =>
        canDelete && arrayHelpers.remove(index);

    // summary table
    const summaryTable = {
        columns: [
            { field: 'name', headerName: 'Nom', flex: 1, disableColumnMenu: true },
            {
                field: 'numberOfUse',
                headerName: t('pages.operations.spraying.summary-col-number-of-use'),
                flex: 1,
                minWidth: 200,
                disableColumnMenu: true,
                renderColCell: ({ value }: GridRenderCellParams) =>
                    value > formik.values.passage && formik.values.passage >= 0 ? (
                        <div className={`${classes.tableCell} ${classes.tableCellWarning}`}>
                            <strong>
                                <>{value}x</>
                            </strong>
                            <ErrorOutlineIcon className={classes.tableCellWarningIcon} />
                        </div>
                    ) : (
                        <div className={classes.tableCell}>
                            <>{value}x</>
                        </div>
                    ),
            },
            {
                field: 'totalQuantity',
                headerName: t('pages.operations.spraying.summary-col-total-quantity'),
                flex: 1,
                minWidth: 180,
                disableColumnMenu: true,
            },
        ],
        rows: _.uniq(formik.values.pesticides.map((p) => p.pesticide_id))
            .filter((pid) => pid > 0)
            .map((pid) => ({
                id: pid,
                name: pesticides?.find((p) => p.id === pid)?.name ?? '',
                numberOfUse: _.countBy(formik.values.pesticides, 'pesticide_id')[pid],
                totalQuantity: `${formatNumber(
                    _.sumBy(
                        _.filter(formik.values.pesticides, ['pesticide_id', pid]).map((pesticide) =>
                            Number(pesticide.quantity),
                        ),
                    ),
                )} ${pesticides?.find((p) => p.id === pid)?.unit}/${t('constants.hectare-unit')}`,
            })),
    };

    useEffect(() => {
        setModalWarning(
            formik.values.passage >= 0 &&
                !_.isEmpty(summaryTable.rows?.find((p: summaryTableRowDataT) => p.numberOfUse > formik.values.passage)),
        );
    }, [formik.values, summaryTable.rows]);

    return (
        <SprayingView
            dataLoading={operationDataLoading}
            farmId={farmId}
            farmSeasonId={farmSeasonId}
            formik={formik}
            menuTitle={`${t(currentCrop?.crop?.translation_slug || '')} - ${t(currentTillage?.name || '')}`}
            modalWarning={modalWarning}
            pesticides={pesticides}
            submitted={submitted}
            summaryTable={summaryTable}
            onClickAddPesticide={onClickAddPesticide}
            onClickDeletePesticide={onClickDeletePesticide}
            onPesticideChange={onPesticideChange}
            canEdit={canEditOperations.access}
        />
    );
};

export default Spraying;
