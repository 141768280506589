import { RESET } from '@/store/reducers/RootTypes';
import { clearStorage } from '@/utils/clearStorage';
import { getHasExistingToken } from '@/utils/getHasAccessTokenCookie';
import useAppDispatch from '@hooks/useAppDispatch';
import { appInitUserData } from '@store/actions/AppActions/AppActions';
import getUser from '@store/actions/AuthActions/GetUserActions';
import { UserT } from '@store/reducers/AuthReducer/AuthReducerTypes';
import { useEffect, useState } from 'react';

/**
 * get all needed data for the user with remaining token (= user, permissions, farms, farmSeasons)
 * @returns userDataLoading
 */
const useUserDataInitialization = () => {
    const dispatch = useAppDispatch();
    const [userDataInitializationLoading, setUserDataInitializationLoading] = useState(false);
    const { hasAccessTokenCookie } = getHasExistingToken();

    // This useEffect is used at the root of the project and will not unmount
    useEffect(() => {
        (async () => {
            // initialize user after a page refresh (no user logged in but maybe a token not expired..)
            setUserDataInitializationLoading(() => true);

            let user: UserT | false = false;
            if (hasAccessTokenCookie) {
                user = await dispatch(getUser());
            }

            if (user) {
                await dispatch(appInitUserData({ user }));
            } else {
                // if user is not authenticated, clear the storage in case the users logged out from another app
                clearStorage();
                dispatch({ type: RESET });
            }

            setUserDataInitializationLoading(() => false);
        })();
    }, []);

    return { userDataInitializationLoading };
};

export default useUserDataInitialization;
