import { postFarmSeasonFieldsCrops } from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCrops';
import useMapFieldReset from '@hooks/useMapFieldReset';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import addPolygonData from '@utils/addPolygonData';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';

import { PostFieldsCropsDataT } from '../CropsTaskTypes';

import { SelectStepPropsT } from './SelectStepTypes';
import SelectStepView from './SelectStepView';
import { FarmSeasonCropT } from '@store/reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';
import useModalForgottenFields from '@components/ModalForgottenFields/useModalForgottenFields';

const SelectStep = ({ currentStep, pushStep, cropColorPalette, popStep }: SelectStepPropsT): JSX.Element => {
    const [selectedCrop, setSelectedCrop] = useState<FarmSeasonCropT>();
    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField?.fieldsList);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const fieldLoading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const cropsList = useSelector((state: HomePageStateT) => state.farmSeasonCrop.cropsList);
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { ModalForgottenFields, modalForgottenFieldsController } = useModalForgottenFields({ modalType: 'crops' });

    const setCanRefresh = useMapFieldReset();

    useEffect(() => {
        if (isSubmitted) setCanRefresh(!isSubmitted);
    }, [isSubmitted]);

    const formik = useFormik({
        initialValues: {
            fields: fields?.length > 0 ? [...fields] : [],
        },
        enableReinitialize: true,
        onSubmit: async (values: PostFieldsCropsDataT, { setSubmitting }) => {
            const save = async () => {
                if (await dispatch(postFarmSeasonFieldsCrops(farmId, farmSeasonId, values))) {
                    navigate('..');
                }
            };
            setIsSubmitted(true);

            const forgottenFields = values.fields.filter((f) => !f.field_crops?.length).map((f) => f.name);
            if (forgottenFields.length > 0) {
                await modalForgottenFieldsController.openModal({
                    forgottenFieldNames: forgottenFields,
                    fieldsCount: values.fields.length,
                    saveCallback: save,
                });
            } else {
                await save();
            }
            setSubmitting(false);
        },
    });

    const onCropClick = (crop: FarmSeasonCropT) => {
        setSelectedCrop(crop);
        pushStep(2.1);
    };

    useEffect(() => {
        MapService.addPolygons({
            polygons: formik?.values?.fields?.map((field) => addPolygonData({ field, colorPalette: cropColorPalette })),
        });
    }, [formik?.values?.fields]);

    return (
        <SelectStepView
            cropColorPalette={cropColorPalette}
            cropList={cropsList}
            currentStep={currentStep}
            fieldLoading={fieldLoading}
            formFields={formik?.values?.fields}
            isDirty={formik?.dirty ?? false}
            isSubmitted={isSubmitted}
            popStep={popStep}
            pushStep={pushStep}
            selectedCrop={selectedCrop}
            setFormFields={formik?.setValues}
            submit={formik.submitForm}
            t={t}
            onCropClick={onCropClick}
            ModalForgottenFields={ModalForgottenFields}
        />
    );
};

export default SelectStep;
