import { useAutoAnimate } from '@hooks/useAutoAnimate';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '../../types/HomePageStoreTypes';
import { useRef } from 'react';
import { setupCropPaletteFromCrop } from '@utils/setupCropPalette';
import staticCropEntity from '@entities/staticCropEntity';
import cropEntity from '@entities/cropEntity';
import fieldEntity from '@entities/fieldEntity';
import { CropT } from '@entities/cropEntity/cropEntity.types';

const useListStepLogic = () => {
    const animateParent = useRef(null);
    useAutoAnimate(animateParent);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const farmSeasonId = currentFarmSeason.id;
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    const { staticCropState } = staticCropEntity.useState();
    const { cropState } = cropEntity.useState({ farmSeasonId });
    const { fieldState } = fieldEntity.useState({ farmSeasonId });
    const { getFieldsAreaForCrop } = fieldState.useGetFieldsAreaForCrop();
    const { getFieldsCountForCrop } = fieldState.useGetFieldsCountForCrop();

    const cropColors = setupCropPaletteFromCrop(cropState.list.map((c): CropT => ({ ...c, crop_id: c.crop_id ?? 0 })));

    return {
        canEditFarmSeason,
        animateParent,
        currentFarmSeason,
        getFieldsAreaForCrop,
        getFieldsCountForCrop,
        cropColors,
        crops: cropState.list,
        getStaticCrop: staticCropState.getById,
    };
};

export default useListStepLogic;
