import TaskItemView from '@components/TaskItem/TaskItemView';
import { TaskItemPropsT } from '@components/TaskItem/TaskItemTypes';

const TaskItem = ({
    additionalContentJSX,
    taskDone = false,
    label = 'Item',
    linkTo = '#',
    disabled,
}: TaskItemPropsT): JSX.Element => (
    <TaskItemView
        additionalContentJSX={additionalContentJSX}
        disabled={disabled}
        label={label}
        linkTo={linkTo}
        taskDone={taskDone}
    />
);

export default TaskItem;
