import { OrganicCarbonLevelT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const ORGANIC_CARBON_LIST_GET_LOADING = 'ORGANIC_CARBON_LIST_GET_LOADING';
export const ORGANIC_CARBON_LIST_GET_SUCCESS = 'ORGANIC_CARBON_LIST_GET_SUCCESS';
export const ORGANIC_CARBON_LIST_GET_FAILED = 'ORGANIC_CARBON_LIST_GET_FAILED';

export type OrganicCarbonListGetSuccessDataT = OrganicCarbonLevelT[];

export type OrganicCarbonListGetLoadingT = {
    type: typeof ORGANIC_CARBON_LIST_GET_LOADING;
};

export type OrganicCarbonListGetSuccessT = {
    type: typeof ORGANIC_CARBON_LIST_GET_SUCCESS;
    payload: OrganicCarbonListGetSuccessDataT;
};

export type OrganicCarbonListGetFailedT = {
    type: typeof ORGANIC_CARBON_LIST_GET_FAILED;
    error: string;
};

export type OrganicCarbonListGetDispatchType =
    | OrganicCarbonListGetLoadingT
    | OrganicCarbonListGetSuccessT
    | OrganicCarbonListGetFailedT;

export type OrganicCarbonListGetCallback = (dispatch: Dispatch<OrganicCarbonListGetDispatchType>) => Promise<boolean>;
