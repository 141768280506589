import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import useRelationsDndStyle from './RelationsDndView.style';
import { RelationsDndViewPropsT } from './RelationsDndTypes';
import DndListItem from './DndListItem/DndListItem';

const RelationsDndView = ({
    fieldCrops,
    defineMoveState,
    moveCrop,
    moveState,
    defineCouple,
    deleteCrop,
}: RelationsDndViewPropsT): JSX.Element => {
    const { classes } = useRelationsDndStyle();
    const { t } = useTranslation();

    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <div className={classes.listContainer}>
                    {fieldCrops?.map((fieldCrop, index) => (
                        <DndListItem
                            key={`${
                                fieldCrop.farm_season_crop.crop.translation_slug
                            }-${fieldCrop.farm_season_crop.is_accompanied?.toString()}`}
                            canAssociat={index !== fieldCrops.length - 1}
                            canDrag={fieldCrop.coupled_id === null}
                            defineMoveState={defineMoveState}
                            deleteCrop={deleteCrop}
                            index={index}
                            isAssociated={
                                fieldCrop.coupled_id !== null &&
                                fieldCrop.coupled_id === fieldCrops[index + 1]?.coupled_id
                            }
                            moveCrop={moveCrop}
                            moveState={moveState}
                            text={`${t(fieldCrop.farm_season_crop.crop.translation_slug || '')}${
                                fieldCrop.farm_season_crop.is_accompanied ? t('constants.select-companion') : ''
                            }`}
                            onCoupleClick={() => defineCouple({ index })}
                        />
                    ))}
                </div>
            </DndProvider>
        </div>
    );
};

export default RelationsDndView;
