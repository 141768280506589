import soilAcidities from '@constants/soilAcidities';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import isArrayEqual from '@utils/isArrayEqual';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FieldsSoilAcidityDataT } from '../SelectStepTypes';

import { SelectFieldStepPropsT } from './SelectFieldStepTypes';
import SelectFieldStepView from './SelectFieldStepView';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const SelectFieldStep = ({
    selectedSoilAcidity,
    formFields,
    setFormFields,
    popStep,
}: SelectFieldStepPropsT): JSX.Element => {
    const { t } = useTranslation();
    // get map state
    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });
    const [selectedFields, setSelectedFields] = useState<FieldsSoilAcidityDataT[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }

        MapService.selectablePolygons({ selectMode: true });
        MapService.defineCondition({
            selectCondition: ({ id: polygonId }) => {
                const field = formFields.find((formField) => formField.id === polygonId);

                return {
                    selectType: field?.soil_acidity_id ? 'warning' : null,
                    modal: {
                        title: t(
                            'pages.installationtasks.fields-subtasks.soil-acidity-task.select-step.select-field-step.alert-selection-model.title',
                        ),
                        description: `${t(
                            'pages.installationtasks.fields-subtasks.soil-acidity-task.select-step.select-field-step.alert-selection-model.description',
                        )} ${t(
                            soilAcidities.find((soilAcidity) => soilAcidity.id === field?.soil_acidity_id)?.name || '',
                        )}`,
                        confirmMessage: t(
                            'pages.installationtasks.fields-subtasks.soil-acidity-task.select-step.select-field-step.alert-selection-model.confirm-message',
                        ),
                    },
                };
            },
        });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
            MapService.defineCondition({ selectCondition: null });
        };
    }, []);

    useEffect(() => {
        const selected = formFields?.filter((field) =>
            thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id),
        );

        if (selected) {
            setSelectedFields(selected);
        }
    }, [thisMap.selectedPolygons]);

    useEffect(() => {
        const formSelectedFields = formFields?.filter((field) => field.soil_acidity_id === selectedSoilAcidity.id);

        if (selectedFields.length !== formSelectedFields?.length) {
            setSelectedFields(formSelectedFields);
        }

        if (thisMap?.selectedPolygons?.length !== formSelectedFields?.length) {
            MapService.updateSelectedPolygons({
                selectedPolygons: formSelectedFields.map((field) => field.polygon),
            });
        }
    }, [formFields]);

    const onSelectedFieldDelete = (id: number | string) => {
        setSelectedFields((fields) => fields.filter((field) => field.id !== id));
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    const onConfirm = () => {
        setFormFields((values) => ({
            fields: values.fields.map((field) => {
                const newField = { ...field };
                const selectedField = selectedFields.find((sField) => sField.id === field.id);

                // Delete this tillage from all
                if (newField.soil_acidity_id === selectedSoilAcidity.id) {
                    newField.soil_acidity_id = null;
                }

                // Change tillage id
                if (selectedField) {
                    newField.soil_acidity_id = selectedSoilAcidity.id;
                }

                return newField;
            }),
        }));
        setIsSubmitted(true);
        popStep();
    };

    const onSelectAll = () => {
        const fieldsWithoutSoilAcidity = formFields.filter(
            (formField) =>
                !selectedFields.find((field) => field.id === formField.id) &&
                (!formField.soil_acidity_id || formField.soil_acidity_id === selectedSoilAcidity.id),
        );
        setSelectedFields((fields) => [...fields, ...fieldsWithoutSoilAcidity]);
        MapService.updateSelectedPolygons({
            selectedPolygons: [
                ...(thisMap.selectedPolygons || []),
                ...fieldsWithoutSoilAcidity.map((field) => field.polygon),
            ],
        });
    };

    return (
        <SelectFieldStepView
            isDirty={
                !isArrayEqual(
                    selectedFields,
                    formFields?.filter((formField) => formField.id === selectedSoilAcidity.id),
                )
            }
            selectedFields={selectedFields}
            isSubmitted={isSubmitted}
            selectedSoilAcidity={selectedSoilAcidity}
            t={t}
            onConfirm={onConfirm}
            onSelectAll={onSelectAll}
            onSelectedFieldDelete={onSelectedFieldDelete}
        />
    );
};

export default SelectFieldStep;
