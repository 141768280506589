import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

const useHistoryMaxYear = () => {
    const historyMaxYear = useSelector((state: AppStateT) => {
        const maxYear = state.static.seasons?.find(
            (season) => season.id === state.farmSeason.currentFarmSeason?.season_id,
        )?.harvest_year;

        return maxYear ? new Date().setFullYear(maxYear) : new Date();
    });

    return historyMaxYear;
};

export default useHistoryMaxYear;
