import authService from '@services/authService/authService';
import { AxiosError } from 'axios';
import jwt from 'jsonwebtoken';

import { appInitUserData } from '../AppActions/AppActions';
import {
    LOGIN_FAILED,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LoginActionDataT,
    LoginResponseErrorT,
    ScToken,
} from './LoginActionsTypes';

const login =
    (
        inputValues: LoginActionDataT,
        remember: boolean,
    ): any => // eslint-disable-line
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (dispatch: any): Promise<LoginResponseErrorT> => {
        try {
            dispatch({ type: LOGIN_LOADING });

            const res = await authService.login({
                email: inputValues.email,
                password: inputValues.password,
            });

            const tokenPayload = jwt.decode(res.data.access_token) as ScToken;

            if (!tokenPayload.access.includes('FP_ACCESS_TO_PLATFORM')) {
                throw {
                    message: 'Not authorized',
                    response: {
                        status: 403,
                    },
                };
            }

            await dispatch(appInitUserData({ user: res.data.user }));

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
                remember,
            });

            return {};
        } catch (e) {
            const list = [];
            const error = e as AxiosError<string>;

            if (error.response?.status === 400) {
                list.push('email', 'password');
            } else if (error.response?.status === 401) {
                list.push('user-not-allowed');
            } else if (error.response?.status === 403) {
                list.push('user-not-active');
            }
            dispatch({
                type: LOGIN_FAILED,
                error: {
                    message: error.response?.data || error.message,
                    list,
                },
            });

            return {
                message: error.response?.data || error.message,
                list,
            };
        }
    };

export default login;
