import { InfoPaper } from '@ui-kit';
import useSubscrptionInfoCardStyle from './SubscriptionInfoCard.style';
import { Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PRODUCT_PLAN_ID } from '@constants/productPlan';
import useSubscriptionInfoCardLogic from './SubscriptionInfoCard.logic';
import UpsellActions from './components/UpsellActions/UpsellActions';
import RenewalActions from './components/RenewalActions/RenewalActions';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';

const SubscriptionInfoCard = () => {
    const { classes } = useSubscrptionInfoCardStyle();
    const { t } = useTranslation();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | undefined>();
    const stripeSecret = process.env.REACT_APP_STRIPE_API_KEY ?? '';

    useEffect(() => {
        const promise = loadStripe(stripeSecret);
        setStripePromise(promise);
    }, []);

    const { productPlanId, formatedCurrentPrice } = useSubscriptionInfoCardLogic();

    return (
        <>
            <InfoPaper title={t('pages.settings.billing.plan.title')}>
                <>
                    <Grid className={classes.gridContainer} container>
                        <Grid item xs={4} lg={2}>
                            <Typography mb="8px" color="secondary" variant="body2">
                                {t('pages.settings.billing.subscription.plan.title')}
                            </Typography>
                            <Typography color="primary" variant="body2">
                                {productPlanId === PRODUCT_PLAN_ID.STANDARD &&
                                    t('pages.settings.billing.plan.plan-name.standard')}
                                {productPlanId === PRODUCT_PLAN_ID.BASIC &&
                                    t('pages.settings.billing.plan.plan-name.basic')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <Typography mb="8px" color="secondary" variant="body2">
                                {t('pages.settings.billing.subscription.fee.title')}
                            </Typography>
                            <Typography color="primary" variant="body2">
                                {formatedCurrentPrice}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <Typography mb="8px" color="secondary" variant="body2">
                                {t('pages.settings.billing.subscription.certificate-revenues.title')}
                            </Typography>
                            <Typography color="primary" variant="body2">
                                {productPlanId === PRODUCT_PLAN_ID.STANDARD && '100%'}
                                {productPlanId === PRODUCT_PLAN_ID.BASIC && '70%'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <div className={classes.contextBar}>
                        <UpsellActions />
                        {stripePromise && (
                            <Elements stripe={stripePromise}>
                                <RenewalActions />
                            </Elements>
                        )}
                    </div>
                </>
            </InfoPaper>
        </>
    );
};

export default SubscriptionInfoCard;
