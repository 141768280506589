import { makeStyles } from 'tss-react/mui';

const useTillageViewStyle = makeStyles()(() => ({
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '20px',
        marginBottom: '40px',
    },
    title: {
        marginBottom: '20px',
    },
    formContainer: {
        marginTop: '20px',
    },
    tillageTypeContainer: {
        margin: '20px 0',
    },
    inputCard: {
        marginBottom: '15px',
    },
    disabledZone: {
        filter: 'opacity(0.5)',
        pointerEvents: 'none',
    },
}));

export default useTillageViewStyle;
