import {
    appAddPendingStateForHomePageLayout,
    appRemovePendingStateForHomePageLayout,
} from '@actions/AppActions/AppActions';
import { AppStateT } from '@store/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from './useAppDispatch';
import { HomePageLayoutPendingStateT } from './useHomePageLoadingTypes';

/**
 * add a pending state to display the loader for the HomePageLayout
 * we can add many pending states to load continuously until they are all resolved.
 * when the component who called this hook unmount, pendings runned by this component are cleaned
 */
const useHomePageLoading = () => {
    const dispatch = useAppDispatch();
    const pendingStates = useSelector((state: AppStateT) => state.app.pendingStatesForHomePageLayout);
    const [localStateSlugsUsed, setLocalStateSlugsUsed] = useState<Set<string>>(new Set([]));

    const pendingStatesRef = useRef(pendingStates); // <-- ref to cache pendingStates for cleanup
    useEffect(() => {
        pendingStatesRef.current = pendingStates;
    }, [pendingStates]);

    const localStateSlugsUsedRef = useRef(localStateSlugsUsed); // <-- ref to cache localState for cleanup
    useEffect(() => {
        localStateSlugsUsedRef.current = localStateSlugsUsed;
    }, [localStateSlugsUsed]);

    const loading = Object.values(pendingStates).length > 0;

    const addPendingState = useCallback(({ pending, slug }: HomePageLayoutPendingStateT) => {
        if (pending === false) {
            dispatch(appRemovePendingStateForHomePageLayout({ pendingSlug: slug }));
            return;
        }

        setLocalStateSlugsUsed((slugs) => new Set(slugs.add(slug)));
        dispatch(appAddPendingStateForHomePageLayout({ pending, slug }));

        if (pending === true) {
            return;
        }

        pending.then(() => dispatch(appRemovePendingStateForHomePageLayout({ pendingSlug: slug })));
    }, []);

    // cancel pendings started when component unmount
    useEffect(
        () => () => {
            localStateSlugsUsedRef.current.forEach((slug) => {
                if (pendingStatesRef.current[slug]) {
                    dispatch(appRemovePendingStateForHomePageLayout({ pendingSlug: slug }));
                }
            });
        },
        [],
    );

    return { addPendingState, homePageLoading: loading };
};

export default useHomePageLoading;
