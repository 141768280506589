import { useEffect, useState } from 'react';

function useAsyncSearch<T>(searchQuery: (query: string, signal: AbortSignal) => Promise<T>, sessionKey: string) {
    const oldQuery = sessionStorage.getItem(`${sessionKey}-query`) || null;
    const items = JSON.parse(sessionStorage.getItem(`${sessionKey}-${oldQuery}`) ?? '{}')?.items || [];
    const [query, setQuery] = useState(oldQuery);
    const [list, setList] = useState<T>(items);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const loadUsers = async () => {
            setLoading(true);
            const sessionItems = JSON.parse(sessionStorage.getItem(`${sessionKey}-${query}`) ?? '{}')?.items || [];
            const sessionItemsDelay = parseInt(sessionStorage.getItem(`${sessionKey}-${query}-expire`) as string) ?? 0;
            // 3 Minute delay session cache, to avoid mistake refetch. This will fix the issue of data not being synched with database
            if (!sessionItems.length || sessionItemsDelay < Date.now() - 1000 * 60 * 3) {
                const listItems = await searchQuery(query !== null ? query : '', controller.signal);
                setList(listItems);
                sessionStorage.setItem(`${sessionKey}-${query}`, JSON.stringify({ items: listItems }));
                sessionStorage.setItem(`${sessionKey}-${query}-expire`, Date.now().toString());
            } else {
                setList(sessionItems);
            }
            if (query !== null) sessionStorage.setItem(`${sessionKey}-query`, query);

            setLoading(false);
        };

        let timeoutId: NodeJS.Timeout | undefined;
        if (query !== null) {
            timeoutId = setTimeout(loadUsers, 500);
        } else {
            loadUsers();
        }

        return () => {
            controller.abort();
            clearTimeout(timeoutId);
        };
    }, [query]);

    return { list, loading, setQuery, query };
}

export default useAsyncSearch;
