import AgroforestryTaskView from './AgroforestryTaskView';
import AgroforestryTaskContext from './AgroforestryTaskContext/AgroforestryTaskContext';

const IrrigationTask = (): JSX.Element => (
    <AgroforestryTaskContext>
        <AgroforestryTaskView />
    </AgroforestryTaskContext>
);

export default IrrigationTask;
