import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldPopupContentLogicPropsT } from './FieldPopupContent.types';
import selectIsBaselineSeason from '../../store/selectors/selectIsBaselineSeason';
import { useEffect } from 'react';
import useMapObserver from '../Map/hooks/useMapObserver';
import SegmentService from '../../services/segmentService/segmentService';
import { useLazyGetFarmSeasonFieldPreviousHarvestsQuery } from '@api/farmSeasonFieldPreviousHarvests.api';

const useFieldPopupContentLogic = ({ polygonId, mapContext }: FieldPopupContentLogicPropsT) => {
    const { t } = useTranslation();

    const field = useSelector((state: HomePageStateT) =>
        state.farmSeasonField.fieldsList.find((f) => f.id === Number(polygonId)),
    );
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);

    const tillagePracticeItem = useSelector((state: HomePageStateT) =>
        state.static.tillagePracticeList.find((t) => t.id === field?.tillage_id),
    );
    const isBaseline = useSelector(selectIsBaselineSeason());

    const crops = field?.field_crops?.map((fc) => ({
        key: fc.farm_season_crop.id,
        name: t(fc.farm_season_crop.crop.translation_slug ?? ''),
        isAccompanied: fc.farm_season_crop.is_accompanied,
    }));

    // Disable popup on create field page
    const onCreateFieldPage = () => {
        return !!mapContext.creatablePolygons;
    };

    // If no field found, don't display the popup.
    const hasFoundField = () => {
        return !!field?.name;
    };

    const [getPreviousHarvests, previousHarvestsData] = useLazyGetFarmSeasonFieldPreviousHarvestsQuery();

    // If no extra content to display (side to name, area), don't display the popup.
    const hasExtraContent = () => {
        return !!(
            t(tillagePracticeItem?.translation_slug ?? '') ||
            crops?.length ||
            field?.has_drainage ||
            field?.is_irrigated ||
            field?.has_agroforestry ||
            field?.is_organic ||
            field?.has_cover_crops ||
            previousHarvestsData
        );
    };

    const canDisplayPopup = (!onCreateFieldPage() || field?.is_permanent) && hasFoundField() && hasExtraContent();

    useEffect(() => {
        if (canDisplayPopup) {
            SegmentService.fieldOverviewDisplayedTrack({ past_season_information: !isBaseline });
        }

        if (field) {
            getPreviousHarvests(
                {
                    farmId: currentFarm.id,
                    farmSeasonId: currentFarmSeason.id,
                    farmSeasonFieldId: field.id,
                },
                true,
            );
        }
    }, []);

    const previousHarvests =
        previousHarvestsData?.data?.history /*.filter((item) => item.initial_field_id === field?.id)*/ ?? [];
    const previousHarvestsLoading = previousHarvestsData.isLoading;

    useMapObserver('closeMapPopup', ({ timeOpened, closingAction }) => {
        if (canDisplayPopup) {
            SegmentService.fieldOverviewClosedTrack({
                past_season_information: !isBaseline,
                closing_action: closingAction,
                time_opened: timeOpened,
            });
        }
    });

    return {
        field: field,
        fieldName: field?.name,
        canDisplayPopup,
        previousHarvests,
        previousHarvestsLoading,
    };
};

export default useFieldPopupContentLogic;
