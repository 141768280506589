import TextLink from '@components/TextLink/TextLink';
import { useTranslation } from 'react-i18next';

import useNotificationFooterStyle from './NotificationFooter.style';
import { NotificationFooterPropsT } from './NotificationFooterTypes';

const NotificationFooter = ({ fetchMoreLoading, onFetchMore, visible }: NotificationFooterPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useNotificationFooterStyle({ visible });

    return (
        <div className={classes.NotificationFooter}>
            <TextLink loaderPosition="left" loading={fetchMoreLoading} textAlign="center" onClick={onFetchMore}>
                {t('component.notification-footer.load-more')}
            </TextLink>
        </div>
    );
};

export default NotificationFooter;
