import { Divider, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import MenuBar from '@components/MenuBar/MenuBar';
import ColorPatternButtonView from '@components/ColorPatternButton/ColorPatternButtonView';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';

import { TillageStepRouteParamsT, TillageStepViewPropsT } from './TillageStepTypes';
import useTillageStepStyle from './TillageStepView.style';
import formatNumber from '@utils/formatNumber';

const TillageStepView = ({
    tillages,
    menuTitle,
    operationsDone,
    canEdit = false,
}: TillageStepViewPropsT): JSX.Element => {
    const { classes } = useTillageStepStyle();
    const navigate = useAppNavigate();
    const { cropId } = useParams<TillageStepRouteParamsT>();
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <div className={classes.container}>
            <MenuBar
                className={classes.menuBar}
                menuTitle={menuTitle}
                onArrowBack="/operations"
                onClose="/operations"
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.operations.tillage-step.title">
                    Sélectionnez l’<span style={{ color: theme.palette.secondary.dark }}>ITK</span>
                </Trans>
            </Typography>
            <Typography className={classes.description} color="secondary" component="p" variant="body2">
                <Trans i18nKey="pages.operations.tillage-step.description"></Trans>
            </Typography>
            <div className={classes.listContainer}>
                {tillages
                    ?.filter((tillage) => !operationsDone.isDone[tillage.id])
                    .map((tillage) => (
                        <ColorPatternButtonView
                            key={`${tillage.id}-${String(tillage.name)}`}
                            color="#FFF"
                            name={t(tillage.name)}
                            patternSvg={tillage.pattern}
                            taskDone={operationsDone.isDone[tillage.id]}
                            border
                            hasPattern
                            withStatus
                            onClick={() => navigate(`/operations/${cropId}/${tillage.id}`)}
                        >
                            <>
                                <Typography color="primary" variant="body1">
                                    {t('constants.fields-subtitle', {
                                        count: operationsDone.fromFilteredFields.filter(
                                            (formField) => formField.tillage_id === tillage.id,
                                        ).length,
                                    })}
                                </Typography>
                                <Typography color="primary" variant="body1">
                                    {formatNumber(
                                        operationsDone.fromFilteredFields
                                            .filter((formField) => formField.tillage_id === tillage.id)
                                            .map((formField) => formField.area)
                                            .reduce((tot, acc) => tot + acc, 0),
                                        3,
                                    )}
                                    &nbsp;
                                    {t('constants.hectare-unit')}
                                </Typography>
                            </>
                        </ColorPatternButtonView>
                    ))}
                {!!tillages?.filter((tillage) => !operationsDone.isDone[tillage.id]).length && (
                    <Divider className={classes.divider} />
                )}
                {tillages
                    ?.filter((tillage) => operationsDone.isDone[tillage.id])
                    .map((tillage) => (
                        <ColorPatternButtonView
                            key={`${tillage.id}-${String(tillage.name)}`}
                            color="#FFF"
                            name={t(tillage.name)}
                            patternSvg={tillage.pattern}
                            taskDone={operationsDone.isDone[tillage.id]}
                            border
                            hasPattern
                            withStatus
                            onClick={() => navigate(`/operations/${cropId}/${tillage.id}`)}
                        >
                            <>
                                <Typography color="primary" variant="body1">
                                    {t('constants.fields-subtitle', {
                                        count: operationsDone.fromFilteredFields.filter(
                                            (formField) => formField.tillage_id === tillage.id,
                                        ).length,
                                    })}
                                </Typography>
                                <Typography color="primary" variant="body1">
                                    {formatNumber(
                                        operationsDone.fromFilteredFields
                                            .filter((formField) => formField.tillage_id === tillage.id)
                                            .map((formField) => formField.area)
                                            .reduce((tot, acc) => tot + acc, 0),
                                        3,
                                    )}
                                    &nbsp;
                                    {t('constants.hectare-unit')}
                                </Typography>
                            </>
                        </ColorPatternButtonView>
                    ))}
            </div>
            <TwoButtonFooterView
                rightDisabled={!!tillages?.filter((tillage) => !operationsDone.isDone[tillage.id]).length}
                rightOnCLick={() => navigate('/operations')}
                rightText={canEdit ? t('constants.confirm') : t('constants.back')}
            />
        </div>
    );
};

export default TillageStepView;
