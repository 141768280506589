import { useEffect } from 'react';
import useUrlQueryParams from './useUrlQueryParams';

const useActionOnQueryParams = ({
    key,
    value,
    action,
    condition = true,
}: {
    key: string;
    value: string;
    action: () => void;
    condition?: boolean;
}) => {
    const URLSearchParams = useUrlQueryParams();

    // Observe query param '?key=value' to trigger the action
    useEffect(() => {
        if (URLSearchParams.get(key) === value && condition) {
            action();
        }
    }, [key, value, URLSearchParams, condition]);
};

export default useActionOnQueryParams;
