import MenuBar from '@components/MenuBar/MenuBar';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import { useContext } from 'react';
import { Prompt } from '@components/Prompt/Prompt';

import CropList from './CropList/CropList';
import { ResidueContext } from './ResidueTaskContext/ResidueTaskContext';
import { ResidueContextT } from './ResidueTaskContext/ResidueTaskContextTypes';
import useResidueTaskStyle from './ResidueTaskView.style';
import SelectFields from './SelectFields/SelectFields';

const ResidueTaskView = (): JSX.Element => {
    const { classes } = useResidueTaskStyle();
    const { currentStep, setCurrentStep, t } = useContext<ResidueContextT>(ResidueContext);

    return (
        <div>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={false}
            />
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={currentStep} totalSteps={3} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.fields-subtasks.residu-task.menu-title')}
                    onArrowBack={currentStep > 1 ? () => setCurrentStep((state) => state - 1) : '..'}
                    onClose=".."
                />
                {currentStep === 1 && <CropList />}
                {currentStep === 2 && <SelectFields />}
            </div>
        </div>
    );
};

export default ResidueTaskView;
