import { MapPropsT } from '@components/Map/MapTypes';
import { AppStateT } from '../store';
import selectMap from './selectMap';

const selectMapProperty =
    <T extends keyof MapPropsT>(property: T) =>
    (state: AppStateT) => {
        const mapState = selectMap()(state);
        if (!mapState) {
            return;
        }
        return mapState[property];
    };

export default selectMapProperty;
