import cropsGet from '@actions/StaticActions/CropsGetAction';
import { geoJsonFeaturePointT, geoJsonFeaturePointWithPropertiesT } from '@components/Map/MapTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { ResultsDataT } from '@pages/HomePage/Result/ResultTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import farmSeasonResultsApiService from '@services/farmSeasonResultsApiService/farmSeasonResultsApiService';
import { hasResultAccessGuard } from '@services/guardsService/guardsService';
import MapService from '@services/mapService/mapService';
import center from '@turf/center';
import distance from '@turf/distance';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import useHomePageLoading from '@hooks/useHomePageLoading';
import { GetCarbonTrajectoryApiT } from '@services/farmSeasonResultsApiService/farmSeasonResultsApiServiceTypes';
import ResultView from './ResultView';

const Result = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const farmId = currentFarm?.id;

    const mapId = 'map-results-benchmark';
    const defaultMapCenter =
        currentFarm?.center ||
        ({ type: 'Feature', geometry: { type: 'Point', coordinates: [4.7981658, 50.642919] } } as geoJsonFeaturePointT);
    const defaultMapZoom = 6;

    const baseUrlSvg = `${process.env.REACT_APP_API_BASE_URL}/farm/${farmId}/season/${currentFarmSeason.id}/results/svg`;

    const [resultsData, setResultsData] = useState<ResultsDataT>();
    const [carbonTrajectory, setCarbonTrajectory] = useState<GetCarbonTrajectoryApiT>();
    const [mapShow, setMapShow] = useState(false);
    const [mapChartData, setMapChartData] = useState<{ crop_id: number; percentage: number }[]>([]);
    const { addPendingState, homePageLoading } = useHomePageLoading();

    // function to detect if a GeoJson feature is intersecting a feature collection
    const furthestPoint = (centerPoint: geoJsonFeaturePointT, points: geoJsonFeaturePointT[]): number => {
        const distances = [] as number[];

        _.forEach(points, (point) =>
            distances.push(distance(centerPoint.geometry.coordinates, point.geometry.coordinates)),
        );

        return Math.max(...distances);
    };

    // On component load
    useEffect(() => {
        const getResultsData = async () => {
            addPendingState({ pending: true, slug: 'Result-getFarmSeasonResults' });
            const res = await farmSeasonResultsApiService.get(farmId, currentFarmSeason.id);

            if (res.data?.texts) {
                setResultsData(res.data);
            }

            await dispatch(cropsGet(Number(currentFarm?.country_id)));

            addPendingState({ pending: false, slug: 'Result-getFarmSeasonResults' });
            setMapShow(true);
        };

        const getCarbonTrajectoryData = async () => {
            addPendingState({ pending: true, slug: 'Result-getCarbonTrajectory' });

            try {
                const res = await farmSeasonResultsApiService.getCarbonTrajectory(farmId, currentFarmSeason.id);
                if (res.data) {
                    setCarbonTrajectory(res.data);
                }
            } catch (e) {
                console.error('Error getting carbon trajectory data: ', e);
            }
            addPendingState({ pending: false, slug: 'Result-getCarbonTrajectory' });
        };

        getResultsData();
        getCarbonTrajectoryData();
    }, [currentFarmSeason.id]);

    // Update graph map with values
    useEffect(() => {
        if (resultsData?.map_benchmark_farms_points) {
            const pointsFeatures = _.map(resultsData.map_benchmark_farms_points, (point) => ({
                type: 'Feature',
                geometry: point,
                properties: {},
            })) as geoJsonFeaturePointWithPropertiesT[];
            const pointsFeaturesWithMyFarm = [
                ...pointsFeatures,
                { type: 'Feature', geometry: defaultMapCenter.geometry, properties: {} },
            ] as geoJsonFeaturePointWithPropertiesT[];

            MapService.addPoints({
                id: mapId,
                points: pointsFeatures,
                // autofocus: true,
            });

            MapService.addCircle({
                id: mapId,
                center: center({ type: 'FeatureCollection', features: pointsFeaturesWithMyFarm }),
                radius: furthestPoint(defaultMapCenter, pointsFeatures) + 20, // in kilometers (20km margin)
                autofocus: true,
            });

            const tmpArr: { crop_id: number; percentage: number }[] = [];
            _.forEach(resultsData.texts.map_data.values.crop_ids, (o, i) => {
                tmpArr.push({
                    crop_id: o,
                    percentage: Math.round(resultsData.texts.map_data.values.relativeAreas[i] * 100),
                });
                setMapChartData(tmpArr);
            });
        }
    }, [resultsData]);

    const hasResultAccess = hasResultAccessGuard();

    if (!hasResultAccess.passed) {
        return <Navigate to={hasResultAccess.redirect} replace />;
    }

    return (
        <>
            {resultsData && !homePageLoading && (
                <ResultView
                    baseUrlSvg={baseUrlSvg}
                    defaultMapCenter={defaultMapCenter}
                    defaultMapZoom={defaultMapZoom}
                    mapChartData={mapChartData}
                    mapShow={mapShow}
                    resultsData={resultsData}
                    totalLivestockEmissions={resultsData?.livestock_emissions}
                    cropNetEmissions={resultsData?.crop_net_emissions}
                    carbonTrajectory={carbonTrajectory}
                />
            )}
        </>
    );
};

export default Result;
