import { FertiliserT } from '@reducers/FertiliserReducer/FertiliserReducerTypes';
import FertiliserApiService from '@services/fertiliserApiService/fertiliserApiService';
import { FertiliserCreateT } from '@services/fertiliserApiService/FertiliserApiServiceTypes';
import { Dispatch } from '@reduxjs/toolkit';

import { FertilisersCreateDispatchTypes, FERTILISERS_CREATE_SUCCESS } from './FertilisersCreateActionTypes';

const fertilisersCreate =
    (farmId: number, fertiliser: FertiliserCreateT) =>
    async (dispatch: Dispatch<FertilisersCreateDispatchTypes>): Promise<FertiliserT | false> => {
        try {
            const res = await FertiliserApiService.createFertiliser(farmId, fertiliser);

            dispatch({
                type: FERTILISERS_CREATE_SUCCESS,
                payload: res.data,
            });

            return res.data;
        } catch (error) {
            return false;
        }
    };

export default fertilisersCreate;
