import { makeStyles } from 'tss-react/mui';

const useFertilisationMachinerySectionViewStyle = makeStyles<{ isOpen: boolean; disableOpen: boolean }>()(
    (theme, { isOpen, disableOpen }) => ({
        container: {
            marginBottom: '10px',
        },
        titleBar: {
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: disableOpen ? theme.palette.grey[400] : theme.palette.primary.main,
            borderRadius: isOpen && !disableOpen ? '15px 15px 0 0' : '15px',
            marginBottom: isOpen ? '3px' : '0px',
            transition: 'border-radius .5s',
        },
        collapseContainer: {
            padding: '15px',
            borderRadius: '0 0 15px 15px',
        },
        passageInput: {
            marginBottom: '15px',
        },
        checkboxTitleContainer: {
            padding: '3px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '5px',
            marginBottom: '10px',
            border: `1px dashed ${theme.palette.primary.light}`,
        },
        arrowDownIcon: {
            transform: 'rotate(-90deg)',
            fontSize: '20px',
        },
        listContainer: {
            marginBottom: '5px',
        },
        fertiliserItem: {
            marginBottom: '5px',
        },
        collapseIcon: {
            cursor: disableOpen ? 'default' : 'pointer',
            '&:hover': {
                color: disableOpen ? 'inherit' : theme.palette.primary.light,
            },
        },
    }),
);

export default useFertilisationMachinerySectionViewStyle;
