import { makeStyles } from 'tss-react/mui';

const useNavLogoStyle = makeStyles()(() => ({
    logoImg: {
        width: '140px',
        height: 'auto',
    },
}));

export default useNavLogoStyle;
