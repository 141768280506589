import { makeStyles } from 'tss-react/mui';

const useCropListViewStyle = makeStyles()((theme) => ({
    container: {},
    checkbox: {},
    darkerTitle: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
    listContainer: {
        marginBottom: '25px',
    },
}));

export default useCropListViewStyle;
