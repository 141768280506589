import { AxiosResponse } from 'axios';
import request from '@services/apiService/apiService';

import {
    ProductPlanGetLatestStatusDataT,
    ProductPlanGetLatestStatusResponseT,
    ProductPlanGetActiveDataT,
    ProductPlanGetActiveResponseT,
    ProductPlanGetPreviousDataT,
    ProductPlanGetPreviousResponseT,
} from './productPlanApiServiceTypes';

class FarmSeasonProductPlanApiService {
    public static getLatestStatus = async ({
        farmId,
    }: ProductPlanGetLatestStatusDataT): Promise<AxiosResponse<ProductPlanGetLatestStatusResponseT>> =>
        request({
            url: `/farm/${farmId}/latest-product-plan-status`,
            method: 'GET',
        });

    public static getActive = async ({
        farmId,
        farmSeasonId,
    }: ProductPlanGetActiveDataT): Promise<AxiosResponse<ProductPlanGetActiveResponseT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/product-plan/active`,
            method: 'GET',
        });

    public static getPreviousProductPlan = async ({
        farmId,
        farmSeasonId,
    }: ProductPlanGetPreviousDataT): Promise<AxiosResponse<ProductPlanGetPreviousResponseT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/product-plan/previous`,
            method: 'GET',
        });
}

export default FarmSeasonProductPlanApiService;
