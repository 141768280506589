import { TableColumnsT, TableRowsT } from '@components/Table/TableViewTypes';
import TableView from '@components/Table/TableView';
import { useTranslation } from 'react-i18next';
import Card from '@components/CardBasic/CardBasic';
import { useTableViewCellStyle } from '@components/Table/TableView.style';

import { CardIrrigationPropsT } from './CardIrrigation.types';
import useCardIrrigationLogic from './CardIrrigation.logic';
import formatNumber from '@utils/formatNumber';

const CardIrrigationView = ({
    irrigation,
    irrigationMethodList,
    irrigationPowerSourceList,
    irrigationWaterSourceList,
}: CardIrrigationPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useTableViewCellStyle();

    const { irrigationTransform } = useCardIrrigationLogic({
        irrigationPowerSourceList,
        irrigationWaterSourceList,
        irrigationMethodList,
    });

    /* ------------------------------ Table Config ------------------------------ */
    const columns: TableColumnsT<'label' | 'value'> = [
        { field: 'label' },
        { field: 'value', cellClassName: classes.boldAndHalf },
    ];
    const rows: TableRowsT<'label' | 'value'> = [
        {
            label: t('pages.farmer-data.irrigation.col-depth'),
            value: formatNumber(irrigation?.pumping_depth) ?? '—',
        },
        {
            label: t('pages.farmer-data.irrigation.col-distance'),
            value: formatNumber(irrigation?.horizontal_distance) ?? '—',
        },
        {
            label: t('pages.farmer-data.irrigation.col-total-amount'),
            value: formatNumber(irrigation?.total_amount) ?? '—',
        },
    ];
    /* -------------------------------------------------------------------------- */

    return (
        <Card additionalInfo={irrigationTransform(irrigation)} title={t('pages.farmer-data.irrigation.title')}>
            <TableView columns={columns} rows={rows} size="small" />
        </Card>
    );
};

export default CardIrrigationView;
