import {
    HomePageMainScreenTypesT,
    HomePageRouteObjectT,
    HomePageSecondaryScreenTypesT,
    MainScreenIdT,
    SecondaryScreenIdT,
} from '@/routers/HomePageRouter/HomePageRouterTypes';
import { Override } from '@/utils/Override';
import { useMemo } from 'react';
import { matchRoutes, RouteMatch } from 'react-router-dom';
import { HomePageRouterScreenPropsT } from './HomePageLayoutTypes';
import { AgnosticRouteObject } from '@remix-run/router/dist/utils';

/** find the route matched by screen depending on 'screen' property of routes */
export const useHomePageRouterScreen = ({ homePageRouterConfig, pathname }: HomePageRouterScreenPropsT) => {
    const screenRoutes = useMemo(() => {
        const routes = matchRoutes(homePageRouterConfig as AgnosticRouteObject[], pathname) as
            | Override<RouteMatch, { route: HomePageRouteObjectT }>[]
            | null;

        // by default latest route matched is displayed. We will do the same for each screen id.
        const screenRoutes: Record<MainScreenIdT | SecondaryScreenIdT, Omit<HomePageRouteObjectT, 'children'> | null> =
            {
                main: null,
                secondary: null,
            };

        // /!\ this doesn't support <Outlet />
        routes?.forEach((r) => {
            const [id] = r.route.screen?.split('-') || [];

            if (id) {
                screenRoutes[id as MainScreenIdT | SecondaryScreenIdT] = r.route;
            }
        });

        // Set a default to main if no screen property provided
        if (!screenRoutes.main) {
            const routesWithoutScreenProp = routes?.filter((r) => !r.route.screen);
            screenRoutes.main = routesWithoutScreenProp?.[routesWithoutScreenProp.length - 1]?.route || null;
        }

        return screenRoutes;
    }, [pathname, homePageRouterConfig]);

    return { mainRoute: screenRoutes.main, secondaryRoute: screenRoutes.secondary };
};

/** select main screen class and secondary screen class depending on screen types defined in routes */
export const useHomePageScreenTypes = ({ mainRoute, secondaryRoute }: ReturnType<typeof useHomePageRouterScreen>) => {
    const mainType = mainRoute?.screen?.split('-')[1] as HomePageMainScreenTypesT;

    const secondaryType = secondaryRoute?.screen?.split('-')[1] as HomePageSecondaryScreenTypesT;

    return { mainType, secondaryType };
};
