import { postFarmSeasonCrops } from '@actions/FarmSeasonCropActions/PostFarmSeasonCrops';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { CropListStepPropsT, FarmSeasonCropDataT, onDeleteCropPropsT, PostCropsDataT } from './CropListStepTypes';
import CropListStepView from './CropListStepView';
import { useModal } from '@soil-capital/ui-kit';
import OnboardingApiService from '@services/onboardingApiService/onboardingApiService';
import { postFarmSeasonFieldsCrops } from '@store/actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCrops';
import { FarmSeasonCropT } from '@store/actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { FarmSeasonFieldCropDataT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import SegmentService from '@services/segmentService/segmentService';
import useOperationDone from '@hooks/useOperationDone';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';
import { useIsReadOnlyUser } from '@/hooks/usePermissions';
import { useIsCropNeedingCropDestinationAssignment } from '@/hooks/useIsCropNeedingCropDestinationAssignment';
import { useGetLatestActiveSeasonId } from '@/hooks/useGetLatestActiveSeasonId';

const CropListStep = ({ pushStep }: CropListStepPropsT): JSX.Element => {
    const isReadOnlyUser = useIsReadOnlyUser();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { t } = useTranslation();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const cropsList = useSelector((state: HomePageStateT) => state.farmSeasonCrop.cropsList);
    const crops = useSelector((state: HomePageStateT) => state.static.crops);
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const farmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const dispatch = useAppDispatch();
    const modalDeleteCrop = useModal();
    const controllerWarningOperationDone = useModal();
    const controllerWarningPermanentCrop = useModal();
    const { getHasAnyOperationDone } = useOperationDone();
    const [hasGotCropSuggestion, setHasGotCropSuggestion] = useState(false);
    const [suggestCropsLoading, setSuggestCropsLoading] = useState(false);
    const latestActiveSeasonId = useGetLatestActiveSeasonId();
    const isCurrentSeasonActive = latestActiveSeasonId === farmSeason?.season_id;

    const isCFT = useIsCFTCarbonModel();

    useEffect(() => {
        MapService.interact({ disabled: true });

        return () => MapService.interact({ disabled: false });
    }, []);

    const validationSchema = yup.object().shape({
        crops: yup
            .array()
            .of(
                yup.object().shape({
                    crop: yup.object().shape({
                        id: yup
                            .number()
                            .required(t('validation.no-crop-selected'))
                            .min(1, t('validation.no-crop-selected')),
                    }),
                    yield: isCFT
                        ? yup
                              .number()
                              .typeError(t('validation.no-yield-provided'))
                              .when(['crop.is_permanent'], {
                                  is: (is_permanent: boolean) => !is_permanent,
                                  then: yup
                                      .number()
                                      .min(0, t('validation.yield-minimum-0'))
                                      .required(t('validation.no-yield-provided')),
                                  otherwise: yup.number().nullable(),
                              })
                        : yup.number().nullable(),
                    is_accompanied: isCFT ? yup.boolean() : yup.boolean().nullable(),
                    crop_destination_percentage: yup.number().when('crop.id', {
                        is: (id: number) =>
                            isCurrentSeasonActive
                                ? useIsCropNeedingCropDestinationAssignment({
                                      crops,
                                      cropId: id,
                                  })
                                : false,
                        then: yup
                            .number()
                            .required(t('validation.no-destination-percentage'))
                            .min(0, t('validation.under-0-destination-percentage'))
                            .max(100, t('validation.above-100-destination-percentage')),
                        otherwise: yup.number().nullable(),
                    }),
                }),
            )
            .required('Must have crops'),
    });

    const formik = useFormik({
        initialValues: {
            crops:
                cropsList?.length > 0
                    ? cropsList
                    : [
                          {
                              crop: {
                                  id: 0,
                              },
                              yield: undefined,
                              is_accompanied: isCFT ? false : null,
                              crop_destination_percentage: null,
                          },
                      ],
            fields,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: PostCropsDataT, { setSubmitting }) => {
            setIsSubmitted(true);

            if (formik.dirty && farmId && farmSeasonId) {
                const saveCropsResults = await dispatch(
                    postFarmSeasonCrops(farmId, farmSeasonId, { crops: values.crops }),
                );

                // save fields already linked to crops from CAP file
                let hasLinkedCropToSave = false;
                if (saveCropsResults) {
                    values.fields
                        .filter((f) => f.field_crops?.length)
                        .forEach((field) => {
                            // link converted CAP crops to matching fields
                            field.field_crops
                                ?.filter((fC) => !fC.farm_season_crop.id && fC.farm_season_crop?.crop?.id)
                                .forEach((fieldCrop) => {
                                    const farmSeasonCrop = saveCropsResults.crops.find(
                                        (fsc) =>
                                            fsc.crop.id === fieldCrop.farm_season_crop.crop.id &&
                                            fsc.is_accompanied === false, // cap crop import are not accompanied
                                    );
                                    if (farmSeasonCrop) {
                                        fieldCrop.farm_season_crop = farmSeasonCrop as FarmSeasonCropT;
                                        hasLinkedCropToSave = true;
                                    }
                                });

                            // remove crops from fields if they've been deleted
                            const fieldCropsToSave: FarmSeasonFieldCropDataT[] = [];
                            field.field_crops?.forEach((fsfC) => {
                                if (saveCropsResults.crops.map((c) => c.id).indexOf(fsfC.farm_season_crop.id) >= 0) {
                                    fieldCropsToSave.push(fsfC);
                                }
                            });
                            field.field_crops = fieldCropsToSave;
                        });

                    if (hasLinkedCropToSave) {
                        await dispatch(postFarmSeasonFieldsCrops(farmId, farmSeasonId, { fields: values.fields }));
                    }
                } else {
                    // This prevents going to the next step if "saveCropsResults" is false
                    return;
                }
            }

            setSubmitting(false);
            pushStep(2);
        },
        validate: (values) => {
            const errors: any = {}; // eslint-disable-line
            const crops: { is_accompanied: boolean }[] = [];
            values.crops.forEach((value, index) => {
                values.crops.forEach((value2, index2) => {
                    if (index !== index2) {
                        if (value.crop.id === value2.crop.id && value.is_accompanied === value2.is_accompanied) {
                            crops[index] = { is_accompanied: t('validation.duplicate-crop') };
                            crops[index2] = { is_accompanied: t('validation.duplicate-crop') };
                            errors.crops = crops;
                        }
                    }
                });
            });

            return errors;
        },
    });

    const onDeleteCrop = ({ arrayHelpers, index, crop }: onDeleteCropPropsT) => {
        if (isReadOnlyUser) return;
        if (
            fields
                .filter((f) => f.is_permanent)
                .some((field) => field.field_crops?.some((field_crop) => field_crop.farm_season_crop.id === crop.id))
        ) {
            controllerWarningPermanentCrop.openModal();

            return;
        }
        if (getHasAnyOperationDone({ farmSeasonCropId: crop.id })) {
            controllerWarningOperationDone.openModal();

            return;
        } else if (
            fields.filter(
                (field) => field.field_crops?.filter((field_crop) => field_crop.farm_season_crop.id === crop.id).length,
            ).length
        ) {
            modalDeleteCrop.openModal(() => arrayHelpers.remove(index));

            return;
        }
        arrayHelpers.remove(index);
    };

    const isPermanentCrop = (crop: FarmSeasonCropDataT) => {
        return fields
            .filter((f) => f.is_permanent)
            .some((field) => field.field_crops?.some((field_crop) => field_crop.farm_season_crop.id === crop.id));
    };

    const handleCropSuggestionOption = async () => {
        setSuggestCropsLoading(true);
        try {
            const { data: suggestedCrops } = await OnboardingApiService.getSuggestion({
                farmId,
                farmSeasonId,
                suggestionType: 'crops',
            });
            SegmentService.dataSuggestionRetrievedTrack({ success: !!suggestedCrops?.length, place: 'crops' });

            formik.setFieldValue('crops', [
                ...formik.values.crops.filter((c) => c.crop.id > 0),
                ...((suggestedCrops || [])
                    // don't add suggested crop if already in the list.
                    .filter(
                        (sc) =>
                            !cropsList.find(
                                (fsc) => fsc.crop.id === sc.crop.id && fsc.is_accompanied === sc.is_accompanied,
                            ),
                    )
                    .map((fsc) => ({
                        crop: fsc.crop,
                        yield: '',
                        is_accompanied: fsc.is_accompanied,
                        crop_destination_percentage: fsc.crop_destination_percentage,
                        origin: 'suggestion',
                    })) as FarmSeasonCropDataT[]),
            ]);

            setHasGotCropSuggestion(true);
        } finally {
            setSuggestCropsLoading(false);
        }
    };

    const goToSelectFields = () => {
        pushStep(2);
    };

    return (
        <CropListStepView
            suggestCropsLoading={suggestCropsLoading}
            hasGotCropSuggestion={hasGotCropSuggestion}
            formik={formik}
            isSubmitted={isSubmitted}
            modalDeleteCrop={modalDeleteCrop}
            handleCropSuggestionOption={handleCropSuggestionOption}
            t={t}
            onDeleteCrop={onDeleteCrop}
            controllerWarningOperationDone={controllerWarningOperationDone}
            controllerWarningPermanentCrop={controllerWarningPermanentCrop}
            goToSelectFields={goToSelectFields}
            isPermanentCrop={isPermanentCrop}
            isCFT={isCFT}
        />
    );
};

export default CropListStep;
