import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import AddButtonView from '@components/AddButton/AddButtonView';
import { FieldArray, FormikProvider, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import WarningMessageView from '@components/WarningMessage/WarningMessageView';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';
import CollapsePaperView from '@components/CollapsePaper/CollapsePaperView';

import useFertilisationMachinerySectionStyle from './FertilisationMachinerySectionView.style';
import { FertilisationMachinerySectionViewPropsT } from './FertilisationMachinerySectionViewTypes';
import FertiliserInputView from './FertiliserInput/FertiliserInputView';

/* eslint-disable no-nested-ternary */
const FertilisationMachinerySectionView = ({
    disabled,
    formik,
    setShowColapseId,
    machineryFertiliserArray,
    passage,
    onPassesChange,
    dataSource,
    withInhibitor,
    title,
    showCollapseId,
    id,
    fertilisers,
    fertilisersTypeIds,
    fertilisersUnit,
    passageError,
    passageHelperText,
    machineryTouched,
    machineryError,
    dataLoading,
    disableOpen,
    liquid = false,
}: FertilisationMachinerySectionViewPropsT): JSX.Element => {
    const { classes } = useFertilisationMachinerySectionStyle({ isOpen: showCollapseId === id, disableOpen });
    const { t } = useTranslation();

    const fertilisersFiltered = useMemo(
        () =>
            fertilisers?.filter(
                (fertiliser) =>
                    fertilisersTypeIds.includes(fertiliser.fertiliser_type_id) && fertiliser.unit === fertilisersUnit,
            ),
        [fertilisers, fertilisersTypeIds.join(), fertilisersUnit],
    );

    return (
        <CollapsePaperView
            disabled={disableOpen}
            isOpen={showCollapseId === id}
            loading={dataLoading}
            title={title}
            onClick={() => {
                showCollapseId === id ? setShowColapseId('') : setShowColapseId(id);
            }}
        >
            <>
                <TextField
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography color="gray" variant="caption">
                                    {t('constants.unit.passage', { count: passage || undefined })}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                    className={classes.passageInput}
                    disabled={disabled}
                    error={passageError}
                    helperText={passageHelperText}
                    type="number"
                    value={passage !== null && passage >= 0 ? String(passage) : ''}
                    variant="outlined"
                    fullWidth
                    onChange={onPassesChange}
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                />
                {withInhibitor && machineryFertiliserArray?.length > 0 && (
                    <div className={classes.checkboxTitleContainer}>
                        <Typography color="primary" variant="h6">
                            {t('pages.operations.fertilising.inhibitor-title')}
                        </Typography>
                        <SubdirectoryArrowLeftIcon className={classes.arrowDownIcon} color="primary" />
                    </div>
                )}
                <FormikProvider value={formik}>
                    <FieldArray
                        name={dataSource}
                        render={(arrayHelpers) => {
                            const fielteredHighEmission =
                                machineryFertiliserArray?.find(
                                    (machineryFertiliser) =>
                                        fertilisersFiltered?.find(
                                            (fertiliser) => fertiliser.id === machineryFertiliser.fertiliser?.id,
                                        )?.high_emission,
                                ) ?? false;

                            return (
                                <>
                                    <Grid className={classes.listContainer} spacing={1} container>
                                        {machineryFertiliserArray?.map((machineryFertiliser, index) => {
                                            const key = `${dataSource}.${index}.key`;
                                            const quantityName = `${dataSource}.${index}.quantity`;
                                            const touchedQuantity = getIn(formik.touched, quantityName);
                                            const errorQuantity = getIn(formik.errors, quantityName);
                                            const fertiliserIdName = `${dataSource}.${index}.fertiliser`;
                                            const touchedFertiliser = getIn(formik.touched, fertiliserIdName);
                                            const errorFertiliser = getIn(formik.errors, fertiliserIdName);
                                            const inhibitorName = `${dataSource}.${index}.inhibitor`;
                                            const unitName = `${dataSource}.${index}.unit`;

                                            return (
                                                <Grid key={key} xs={12} item>
                                                    <FertiliserInputView
                                                        className={classes.fertiliserItem}
                                                        disabled={disabled}
                                                        fertiliser={machineryFertiliser.fertiliser}
                                                        fertiliserError={Boolean(touchedFertiliser && errorFertiliser)}
                                                        fertiliserHelperText={
                                                            touchedFertiliser && errorFertiliser ? errorFertiliser : ''
                                                        }
                                                        fertilisersTypeIds={fertilisersTypeIds}
                                                        fertilisersUnit={fertilisersUnit}
                                                        inhibitorValue={!!machineryFertiliser.inhibitor}
                                                        liquid={liquid}
                                                        quantity={machineryFertiliser.quantity}
                                                        quantityError={Boolean(touchedQuantity && errorQuantity)}
                                                        quantityHelperText={
                                                            touchedQuantity && errorQuantity ? errorQuantity : ''
                                                        }
                                                        unit={machineryFertiliser.unit}
                                                        withInhibitor={withInhibitor}
                                                        onDelete={() => {
                                                            arrayHelpers.remove(index);
                                                        }}
                                                        onFertiliserChange={(fertiliser) => {
                                                            formik.setFieldValue(fertiliserIdName, fertiliser ?? null);
                                                            formik.setFieldValue(unitName, fertiliser?.unit || '');
                                                        }}
                                                        onInhibitorChange={(e, value) =>
                                                            formik.setFieldValue(inhibitorName, value)
                                                        }
                                                        onQuantityChange={(event) => {
                                                            formik.setFieldValue(
                                                                quantityName,
                                                                event.target.value
                                                                    ? Math.floor(Number(event.target.value) * 10) / 10
                                                                    : null,
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                    {fielteredHighEmission && (
                                        <WarningMessageView
                                            message={t('pages.operations.fertilising.high-emission-warning')}
                                        />
                                    )}
                                    {Boolean(machineryTouched && machineryError !== '') && (
                                        <ErrorMessageView message={machineryError} />
                                    )}
                                    <AddButtonView
                                        disabled={disabled}
                                        label={t('constants.add-product')}
                                        onClick={() =>
                                            arrayHelpers.push({
                                                fertiliser: null,
                                                quantity: null,
                                                unit: '',
                                                inhibitor: false,
                                            })
                                        }
                                    />
                                </>
                            );
                        }}
                    />
                </FormikProvider>
            </>
        </CollapsePaperView>
    );
};

export default FertilisationMachinerySectionView;
