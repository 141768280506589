import { makeStyles } from 'tss-react/mui';

const useLabelAutocompleteStyle = makeStyles()(() => ({
    label: {
        marginBottom: '10px',
        textAlign: 'left',
    },

    helperText: {
        marginTop: '3px',
        marginLeft: '14px',
    },
    circularProgess: {
        padding: '0',
    },
    disabled: {
        pointerEvents: 'none',
    },
}));

export default useLabelAutocompleteStyle;
