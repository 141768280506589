import {
    PostFarmSeasonFieldsOrganicDataT,
    PostFarmSeasonFieldsOrganicResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsOrganicApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsOrganicDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsOrganicResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/organic`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsOrganicApiService;
