import { makeStyles } from 'tss-react/mui';

const useCardOperationStyle = makeStyles()(() => ({
    cellOperation: {
        '& ul': {
            marginTop: '4px',
            marginBottom: '5px',
        },
        '& li': {
            marginLeft: '20px',
        },
        '& .label': {
            fontWeight: 'bold',
        },
    },
}));

export default useCardOperationStyle;
