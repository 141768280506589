import irrigationMethodGetListAction from '@actions/StaticActions/IrrigationMethodGetListAction';
import irrigationPowerSourceGetListAction from '@actions/StaticActions/IrrigationPowerSourceGetListAction';
import irrigationWaterSourceGetListAction from '@actions/StaticActions/IrrigationWaterSourceGetListAction';
import organicMatterGetListAction from '@actions/StaticActions/OrganicMatterGetListAction';
import pHGetListAction from '@actions/StaticActions/PhGetListAction';
import tillageGetListAction from '@actions/StaticActions/TillageGetListAction';
import tillagePracticeGetListAction from '@actions/StaticActions/TillagePracticeGetListAction';
import getTrees from '@actions/StaticActions/TreesGet';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FieldsContextPropsT, FieldsContextStoreT } from './FieldsContextTypes';

export const FieldsContextStore = createContext({} as FieldsContextStoreT);

const FieldsContext = ({ children }: FieldsContextPropsT): JSX.Element => {
    const dispatch = useAppDispatch();

    /**
     * Get list for forms
     */
    useEffect(() => {
        dispatch(pHGetListAction());
        dispatch(irrigationMethodGetListAction());
        dispatch(irrigationWaterSourceGetListAction());
        dispatch(irrigationPowerSourceGetListAction());
        dispatch(getTrees());
        dispatch(tillagePracticeGetListAction());
        dispatch(tillageGetListAction());
        // dispatch(moistureGetListAction());
        // dispatch(drainageGetListAction());
        // dispatch(organicFertilizationGetListAction());
        dispatch(organicMatterGetListAction());
        // dispatch(cropsGet());
        // dispatch(tillageGetListAction());
    }, []);

    const fieldList = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const soilAcidityList = useSelector((state: HomePageStateT) => state.static.pHList);
    const soilAcidityLoading = useSelector((state: HomePageStateT) => state.static.pHListLoading);
    const soilOrganicMatterList = useSelector((state: HomePageStateT) => state.static.organicMatterLevelList);
    const soilOrganicMatterLoading = useSelector((state: HomePageStateT) => state.static.organicMatterLevelListLoading);
    const irrigationMethodList = useSelector((state: HomePageStateT) => state.static.irrigationMethodList);
    const irrigationMethodLoading = useSelector((state: HomePageStateT) => state.static.irrigationMethodListLoading);
    const irrigationWaterSourceList = useSelector((state: HomePageStateT) => state.static.irrigationWaterSourceList);
    const irrigationWaterSourceLoading = useSelector(
        (state: HomePageStateT) => state.static.irrigationWaterSourceListLoading,
    );
    const irrigationPowerSourceList = useSelector((state: HomePageStateT) => state.static.irrigationPowerSourceList);
    const irrigationPowerSourceLoading = useSelector(
        (state: HomePageStateT) => state.static.irrigationPowerSourceListLoading,
    );
    const treeList = useSelector((state: HomePageStateT) => state.static.trees);
    const treeLoading = useSelector((state: HomePageStateT) => state.static.treesLoading);
    const tillageList = useSelector((state: HomePageStateT) => state.static.tillageList);
    const tillageLoading = useSelector((state: HomePageStateT) => state.static.tillageListLoading);
    const tillagePracticeList = useSelector((state: HomePageStateT) => state.static.tillagePracticeList);
    const tillagePracticeLoading = useSelector((state: HomePageStateT) => state.static.tillagePracticeListLoading);
    const frequencyList = useSelector((state: HomePageStateT) => state.static.frequencyList);
    const frequencyLoading = useSelector((state: HomePageStateT) => state.static.frequencyListLoading);

    const [selectedField, setSelectedField] = useState<FarmSeasonFieldT | null>(null);
    const [nothingToSave, setNothingToSave] = useState<boolean>(true);
    const [addMode, setAddMode] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const { t } = useTranslation();

    const store: FieldsContextStoreT = {
        selectedField,
        setSelectedField,
        fieldList,
        nothingToSave,
        setNothingToSave,
        addMode,
        setAddMode,
        editMode,
        setEditMode,
        t,
        soilAcidityList,
        soilAcidityLoading,
        soilOrganicMatterList,
        soilOrganicMatterLoading,
        irrigationMethodList,
        irrigationMethodLoading,
        irrigationWaterSourceList,
        irrigationWaterSourceLoading,
        irrigationPowerSourceList,
        irrigationPowerSourceLoading,
        treeList,
        treeLoading,
        tillagePracticeList,
        tillagePracticeLoading,
        tillageList,
        tillageLoading,
        frequencyList,
        frequencyLoading,
    };

    return <FieldsContextStore.Provider value={store}>{children}</FieldsContextStore.Provider>;
};

export default FieldsContext;
