import fertilisersCreate from '@actions/StaticActions/FertilisersCreateAction';
import { ModalPropsT } from '@soil-capital/ui-kit';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import emptyFieldValuesToNull from '@utils/emptyFieldValuesToNull';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FertiliserT } from '@reducers/FertiliserReducer/FertiliserReducerTypes';
import { useState } from 'react';
import { maxTwoDecimals } from '@/validations/validations';
import { NumberFieldValue } from '@utils/NumbersToFieldValues';
import { KeysOfType } from '@/utils/KeysOfType';
import { FertiliserCreateT } from '@services/fertiliserApiService/FertiliserApiServiceTypes';

import { FertiliserCreateFormT } from './ModalCrateFertiliserTypes';

const customNameComputed = (values: FertiliserCreateFormT) =>
    `Custom ${(values.n || 0).toLocaleString()}-${(values.p || 0).toLocaleString()}-${(
        values.k || 0
    ).toLocaleString()}`;

const getFertiliserByName = (fertilisers: FertiliserT[], values: FertiliserCreateFormT): FertiliserT | undefined => {
    const customName = customNameComputed(values);

    return fertilisers.find(
        (f) =>
            f.name?.toLowerCase() ===
            (values.name !== null ? values.name?.toLowerCase()?.trim() : customName?.toLowerCase()),
    );
};

/* Check if component combinaison is same between a fertiliser and form values */
const haveSimilarComponents = (fertiliser: FertiliserT, values: FertiliserCreateFormT): boolean =>
    `${fertiliser.n}${fertiliser.p}${fertiliser.k}${fertiliser.ca}${fertiliser.mg}${fertiliser.s}` ===
    `${values.n}${values.p}${values.k}${values.ca || 0}${values.mg || 0}${values.s || 0}`;

const useModalCreateFertiliserLogic = (controller: ModalPropsT<FertiliserT>, unit: FertiliserT['unit']) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const fertilisers = useSelector((state: AppStateT) =>
        state.fertiliser.fertilisersList?.filter(
            (fertiliser) => fertiliser.unit === unit && fertiliser.fertiliser_type_id === 1,
        ),
    );

    const [existingDefaultsForNPK, setExistingDefaultsForNPK] = useState<FertiliserT[]>([]);

    const validationSchema = Yup.object({
        n: Yup.number().required().min(0).test(maxTwoDecimals(t)).max(100),
        p: Yup.number().required().min(0).test(maxTwoDecimals(t)).max(100),
        k: Yup.number().required().min(0).test(maxTwoDecimals(t)).max(100),
        ca: Yup.number().min(0).test(maxTwoDecimals(t)).max(100),
        mg: Yup.number().min(0).test(maxTwoDecimals(t)).max(100),
        s: Yup.number().min(0).test(maxTwoDecimals(t)).max(100),
        name: Yup.string()
            .nullable()
            .test('required', t('components.modal-create-fertiliser.name-is-required'), (value) => value !== undefined),
    });

    const sumValues = (
        keys: KeysOfType<FertiliserCreateFormT, NumberFieldValue | undefined>[],
        values: FertiliserCreateFormT,
    ) => keys.reduce((total: number, key) => total + Number(values[key]), 0);

    const formik = useFormik<FertiliserCreateFormT>({
        validationSchema,
        initialValues: {
            n: '',
            p: '',
            k: '',
            ca: '',
            mg: '',
            s: '',
            name: null,
            unit,
            fertiliser_type_id: 1, // 'Mineral fertiliser'
            volumic_mass: 1, // FP-4027
        },
        validate: (values) => {
            const totalComponents = sumValues(['n', 'p', 'k', 'ca', 'mg', 's'], values);

            if (totalComponents > 100) {
                return {
                    totalIsMax100: t('components.modal-create-fertiliser.total-is-max-100', { total: totalComponents }),
                };
            }

            setExistingDefaultsForNPK(
                fertilisers?.filter(
                    (f) => f.farm_id === null && `${f.n}${f.p}${f.k}` === `${values.n}${values.p}${values.k}`,
                ),
            );

            const fertiliserNameExisting = getFertiliserByName(fertilisers, values);

            if (fertiliserNameExisting) {
                return haveSimilarComponents(fertiliserNameExisting, values)
                    ? undefined
                    : { nameAlreadyExist: t('components.modal-create-fertiliser.name-already-exist') };
            }

            return undefined;
        },
        onSubmit: async (values) => {
            if (!farmId) {
                throw Error('farmId is required to call "fertilisersCreate"');
            }

            const fertiliserNameExisting = getFertiliserByName(fertilisers, values);

            if (fertiliserNameExisting && haveSimilarComponents(fertiliserNameExisting, values)) {
                controller.onModalConfirm(fertiliserNameExisting);

                return;
            }

            const fertiliserCreateData = emptyFieldValuesToNull(values);

            if (!fertiliserCreateData.name) {
                fertiliserCreateData.name = customNameComputed(values);
            }

            // set defaults
            fertiliserCreateData.ca = fertiliserCreateData.ca || 0;
            fertiliserCreateData.mg = fertiliserCreateData.mg || 0;
            fertiliserCreateData.s = fertiliserCreateData.s || 0;

            const newFertiliser = await dispatch(fertilisersCreate(farmId, fertiliserCreateData as FertiliserCreateT));

            if (newFertiliser) {
                controller.onModalConfirm(newFertiliser);
                formik.resetForm();
                setExistingDefaultsForNPK([]);
            }
        },
    });

    return { formik, customNameComputed, existingDefaultsForNPK, setExistingDefaultsForNPK };
};

export default useModalCreateFertiliserLogic;
