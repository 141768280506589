import TableView from '@components/Table/TableView';
import { useTranslation } from 'react-i18next';
import Card from '@components/CardBasic/CardBasic';
import { useTableViewCellStyle } from '@components/Table/TableView.style';

import { CardCropsColumnsT, CardCropsRowsT, CardCropsPropsT } from './CardCrops.types';
import formatNumber from '@utils/formatNumber';

const CardCrops = ({ fieldCrops }: CardCropsPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useTableViewCellStyle();

    /* ------------------------------ Table Config ------------------------------ */
    const rows: CardCropsRowsT = fieldCrops.map((crop): CardCropsRowsT[0] => ({
        name: t(crop.farm_season_crop.crop.translation_slug ?? ''),
        residue: formatNumber(crop.residue || crop.farm_season_crop.residue) ?? '',
        yield: formatNumber(Number(crop.yield || crop.farm_season_crop.yield)),
    }));
    const columns: CardCropsColumnsT = [
        {
            field: 'name',
            headerName: t('pages.farmer-data.crop.col-name'),
            cellClassName: classes.bold,
        },
        {
            field: 'yield',
            headerName: t('pages.farmer-data.crop.col-yield'),
            cellClassName: classes.bold,
        },
        {
            field: 'residue',
            headerName: t('pages.farmer-data.crop.col-residue'),
            cellClassName: classes.bold,
        },
    ];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Translates ------------------------------- */
    const cropCountText = t('pages.farmer-data.crop.crop-count', {
        count: fieldCrops.length,
        cropCount: fieldCrops.length,
    });
    const cropTitle = t('pages.farmer-data.crop.title');
    /* -------------------------------------------------------------------------- */

    return (
        <Card additionalInfo={cropCountText} title={cropTitle}>
            <>{!!rows.length && <TableView columns={columns} rows={rows} size="small" />}</>
        </Card>
    );
};

export default CardCrops;
