import useLoader from '@hooks/useLoader';
import { Typography, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';

import NotificationSwitch from '../NotificationSwitch/NotificationSwitch';

import useNotificationListStyle from './NotificationList.style';
import { NotificationListPropsT } from './NotificationListTypes';

const NotificationList = ({
    fetchLoading,
    notifications,
    notificationsCount,
    showOnlyUnread,
    unreadNotificationsCount,
    markAsReadLoadings,
    handleClosePanel,
    handleMarkAsRead,
}: NotificationListPropsT) => {
    const { classes } = useNotificationListStyle();
    const theme = useTheme();
    const { containerRef } = useLoader(fetchLoading, { bgColor: theme.palette.spanishWhite.light });
    const { t } = useTranslation();

    return (
        <div ref={containerRef} className={classes.NotificationList}>
            {/* Messages if no notifications : */}
            {!fetchLoading &&
                showOnlyUnread &&
                unreadNotificationsCount === 0 &&
                notificationsCount > 0 &&
                notifications.length === 0 && (
                    <Typography color="text.primary">
                        {t('component.notification-container.no-unread-remaning')}
                    </Typography>
                )}

            {!fetchLoading && notificationsCount === 0 && (
                <Typography color="text.primary">{t('component.notification-container.no-notif-at-all')} </Typography>
            )}

            {notifications.map((notification) => (
                <NotificationSwitch
                    key={notification.id}
                    isLoading={!!markAsReadLoadings?.find((loadingId) => loadingId === notification.id)}
                    notification={notification}
                    showAction={notification.read_at ? undefined : 'markAsRead'}
                    onActionClick={handleMarkAsRead}
                    onNotificationClickDone={handleClosePanel}
                />
            ))}
        </div>
    );
};

export default NotificationList;
