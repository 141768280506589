import {
    PostFarmSeasonFieldsSoilOrganicCarbonDataT,
    PostFarmSeasonFieldsSoilOrganicCarbonResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilOrganicCarbonTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsSoilOrganicCarbonApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsSoilOrganicCarbonDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsSoilOrganicCarbonResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/soil-organic-carbons`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsSoilOrganicCarbonApiService;
