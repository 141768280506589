import { DrainageListGetSuccessDataT } from '@actions/StaticActions/DrainageGetListActionTypes';
import { CropsGetSuccessDataT } from '@actions/StaticActions/CropsGetActionsTypes';
import { LanguagesGetSuccessDataT } from '@actions/StaticActions/LanguagesGetActionsTypes';
import { LivestocksGetResponseSuccessT } from '@actions/StaticActions/LivestocksActionsTypes';
import { MoistureListGetSuccessDataT } from '@actions/StaticActions/MoistureGetListActionTypes';
import { OrganicMatterListGetSuccessDataT } from '@actions/StaticActions/OrganicMatterGetListActionTypes';
import { OrganicCarbonListGetSuccessDataT } from '@actions/StaticActions/OrganicCarbonGetListActionTypes';
import { PhListGetSuccessDataT } from '@actions/StaticActions/PhGetListActionTypes';
import { SeasonGetSuccessDataT } from '@actions/StaticActions/seasonGetTypes';
import { SoilListGetSuccessDataT } from '@actions/StaticActions/SoilGetListActionTypes';
import { CountryGetResponseSuccessT, RegionGetResponseSuccessT } from '@actions/StaticActions/StaticActionsTypes';
import { IrrigationMethodListGetSuccessDataT } from '@actions/StaticActions/IrrigationMethodGetListActionTypes';
import { IrrigationWaterSourceListGetSuccessDataT } from '@actions/StaticActions/IrrigationWaterSourceGetListActionTypes';
import { IrrigationPowerSourceListGetSuccessDataT } from '@actions/StaticActions/IrrigationPowerSourceGetListActionTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';
import { CurrenciesGetSuccessDataT } from '@actions/StaticActions/currenciesGetTypes';
import { TreesGetSuccessDataT } from '@actions/StaticActions/TreesGetTypes';
import { TillagePracticeListGetSuccessDataT } from '@actions/StaticActions/TillagePracticeGetListActionTypes';
import { TillageListGetSuccessDataT } from '@actions/StaticActions/TillageGetListActionTypes';
import { PesticidesListGetSuccessDataT } from '@actions/StaticActions/PesticidesGetListActionTypes';
import { MachineryT } from '@reducers/StaticReducer/StaticReducerType';
import { PriceResponseT } from './price.types';

class StaticApiService {
    public static getCountries = async (): Promise<AxiosResponse<CountryGetResponseSuccessT>> =>
        request({
            url: '/static_data/countries',
            method: 'GET',
        });

    public static getPrice = async (id: number): Promise<AxiosResponse<PriceResponseT>> =>
        request({
            url: `/static_data/price/${id}`,
            method: 'GET',
        });

    public static getCurrencies = async (): Promise<AxiosResponse<CurrenciesGetSuccessDataT>> =>
        request({
            url: '/static_data/currencies',
            method: 'GET',
        });

    public static getTrees = async (): Promise<AxiosResponse<TreesGetSuccessDataT>> =>
        request({
            url: '/static_data/tree-types',
            method: 'GET',
        });

    public static getRegions = async (): Promise<AxiosResponse<RegionGetResponseSuccessT>> =>
        request({
            url: '/static_data/regions',
            method: 'GET',
        });

    public static getLivestocks = async (): Promise<AxiosResponse<LivestocksGetResponseSuccessT>> =>
        request({
            url: '/static_data/livestocks',
        });

    public static getSeasons = async (): Promise<AxiosResponse<SeasonGetSuccessDataT>> =>
        request({
            url: '/static_data/seasons',
            method: 'GET',
        });

    // public static getTranslation = async (lang: string): Promise<TranslationT> => import(`@translations/${lang}.json`);

    public static getLanguages = async (): Promise<AxiosResponse<LanguagesGetSuccessDataT>> =>
        request({
            url: '/static_data/languages',
            method: 'GET',
        });

    public static getSoilTypeList = async (): Promise<AxiosResponse<SoilListGetSuccessDataT>> =>
        request({
            url: '/static_data/soiltypes',
            method: 'GET',
        });

    public static getSoilAcidityList = async (): Promise<AxiosResponse<PhListGetSuccessDataT>> =>
        request({
            url: '/static_data/soilaciditylevels',
            method: 'GET',
        });

    public static getDrainageList = async (): Promise<AxiosResponse<DrainageListGetSuccessDataT>> =>
        request({
            url: '/static_data/drainages',
            method: 'GET',
        });

    public static getOrganicMatterLevelList = async (): Promise<AxiosResponse<OrganicMatterListGetSuccessDataT>> =>
        request({
            url: '/static_data/organicmatterlevels',
            method: 'GET',
        });

    public static getOrganicCarbonLevelList = async (): Promise<AxiosResponse<OrganicCarbonListGetSuccessDataT>> =>
        request({
            url: '/static_data/organiccarbonlevels',
            method: 'GET',
        });

    public static getMoistureList = async (): Promise<AxiosResponse<MoistureListGetSuccessDataT>> =>
        request({
            url: '/static_data/soilhumidity',
            method: 'GET',
        });

    public static getTillageList = async (): Promise<AxiosResponse<TillageListGetSuccessDataT>> =>
        request({
            url: '/static_data/tillages',
            method: 'GET',
        });

    public static getTillagePracticeList = async (): Promise<AxiosResponse<TillagePracticeListGetSuccessDataT>> =>
        request({
            url: '/static_data/tillages-practice',
            method: 'GET',
        });

    public static getCrops = async (country_id: number): Promise<AxiosResponse<CropsGetSuccessDataT>> =>
        request({
            url: `/static_data/crops/${country_id}`,
            method: 'GET',
        });

    public static getIrrigationMethodList = async (): Promise<AxiosResponse<IrrigationMethodListGetSuccessDataT>> =>
        request({
            url: '/static_data/irrigation-methods',
            method: 'GET',
        });

    public static getIrrigationWaterSourceList = async (): Promise<
        AxiosResponse<IrrigationWaterSourceListGetSuccessDataT>
    > =>
        request({
            url: '/static_data/irrigation-water-sources',
            method: 'GET',
        });

    public static getIrrigationPowerSourceList = async (): Promise<
        AxiosResponse<IrrigationPowerSourceListGetSuccessDataT>
    > =>
        request({
            url: '/static_data/irrigation-power-sources',
            method: 'GET',
        });

    public static getPesticides = async (country_id: number): Promise<AxiosResponse<PesticidesListGetSuccessDataT>> =>
        request({
            url: `/static_data/pesticides/${country_id}`,
            method: 'GET',
        });

    public static getMachineryList = async (country_id: number): Promise<AxiosResponse<MachineryT[]>> =>
        request({
            url: `/static_data/machineries/${country_id}`,
            method: 'GET',
        });
}

export default StaticApiService;
