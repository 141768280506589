import React from 'react';
import { useLocation } from 'react-router-dom';

/** get query param value from url */
const useUrlQueryParams = (): URLSearchParams => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default useUrlQueryParams;
