import IrrigationTaskView from './IrrigationTaskView';
import IrrigationTaskContext from './IrrigationTaskContext/IrrigationTaskContext';

const IrrigationTask = (): JSX.Element => (
    <IrrigationTaskContext>
        <IrrigationTaskView />
    </IrrigationTaskContext>
);

export default IrrigationTask;
