import { Prompt } from '@components/Prompt/Prompt';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import soilOrganicCarbons from '@constants/soilOrganicCarbons';
import ColorPatternButtonView from '@components/ColorPatternButton/ColorPatternButtonView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import { SelectStepViewPropsT } from './SelectStepTypes';
import useSelectStepStyle from './SelectStepView.style';
import SelectFieldStep from './SelectFieldStep/SelectFieldStep';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import formatNumber from '@utils/formatNumber';

const SelectStepView = ({
    t,
    currentStep,
    popStep,
    formFields,
    selectedSoilOrganicCarbon,
    setFormFields,
    onSoilOrganicCarbonClick,
    isDirty,
    fieldLoading,
    submit,
    isSubmitting,
    ModalForgottenFields,
}: SelectStepViewPropsT): JSX.Element | null => {
    const { classes } = useSelectStepStyle();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    if (currentStep === 1.1) {
        if (!selectedSoilOrganicCarbon) {
            return null;
        }

        return (
            <SelectFieldStep
                formFields={formFields}
                popStep={popStep}
                selectedSoilOrganicCarbon={selectedSoilOrganicCarbon}
                setFormFields={setFormFields}
            />
        );
    }

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={isDirty && !isSubmitting}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.fields-subtasks.soil-organic-carbons-task.select-step.title">
                    Veuillez <span className={classes.darkerTitle}> ajouter des champs</span> pour les différents niveaux
                    de carbonne organique dans le sol
                </Trans>
                <HelpButtonView id="pages.installationtasks.fields-subtasks.soil-organic-carbons-task.select-step.title" />
            </Typography>
            <div className={classes.listContainer}>
                {soilOrganicCarbons?.map((soilOrganicCarbon) => (
                    <ColorPatternButtonView
                        key={`${soilOrganicCarbon.id}-${String(soilOrganicCarbon.name)}`}
                        color={soilOrganicCarbons[soilOrganicCarbon.id - 1]?.color}
                        name={t(soilOrganicCarbon.name, {
                            lowLevel: soilOrganicCarbon.lowLevel,
                            highLevel: soilOrganicCarbon.highLevel,
                        })}
                        onClick={() => onSoilOrganicCarbonClick(soilOrganicCarbon)}
                    >
                        <>
                            <Typography color="primary" variant="body1">
                                {t('constants.fields-subtitle', {
                                    count: formFields.filter(
                                        (formField) => formField.soil_organic_carbon_id === soilOrganicCarbon.id,
                                    ).length,
                                })}
                            </Typography>
                            <Typography color="primary" variant="body1">
                                {formatNumber(
                                    formFields
                                        .filter(
                                            (formField) => formField.soil_organic_carbon_id === soilOrganicCarbon.id,
                                        )
                                        .map((formField) => formField.area)
                                        .reduce((tot, acc) => tot + acc, 0),
                                    3,
                                )}
                                &nbsp;
                                {t('constants.hectare-unit')}
                            </Typography>
                        </>
                    </ColorPatternButtonView>
                ))}
            </div>
            <TwoButtonFooterView
                rightLoading={fieldLoading}
                rightOnCLick={submit}
                rightText={canEditFarmSeason.access ? t('constants.save') : ''}
            />
            {ModalForgottenFields}
        </div>
    );
};

export default SelectStepView;
