import farmSeasonFieldsOrganicFertilizationHistoryApiService from '@services/farmSeasonFieldsOrganicFertilizationHistoryApiService/farmSeasonFieldsOrganicFertilizationHistoryApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsOrganicFertilizationHistoryPostCallBackT,
    PostFarmSeasonFieldsOrganicFertilizationHistoryDataT,
    PostFarmSeasonFieldsOrganicFertilizationHistoryDispatchTypesT,
    PostFarmSeasonFieldsOrganicFertilizationHistoryResponseDataT,
    PostFarmSeasonFieldsOrganicFertilizationHistoryResponseErrorT,
    POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_FAILED,
    POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_LOADING,
    POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS,
} from './PostFarmSeasonFieldsOrganicFertilizationHistoryTypes';

const postFarmSeasonFieldsOrganicFertilizationHistory =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsOrganicFertilizationHistoryDataT,
    ): FarmSeasonFieldsOrganicFertilizationHistoryPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsOrganicFertilizationHistoryDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsOrganicFertilizationHistoryResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsOrganicFertilizationHistoryResponseDataT> =
                await farmSeasonFieldsOrganicFertilizationHistoryApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_FAILED,
                error: error as PostFarmSeasonFieldsOrganicFertilizationHistoryResponseErrorT,
            });

            return {};
        }
    };

export default postFarmSeasonFieldsOrganicFertilizationHistory;
