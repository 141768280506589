import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CardFarm from '../CardFarm/CardFarm';
import CardLiveStock from '../CardLivestock/CardLivestock';
import SummaryPannel from '../SummaryPannel/SummaryPannel';

import useSummaryFarmLogic from './SummaryFarm.logic';
import { SummaryFarmPropsT } from './SummaryFarmTypes';
import formatNumber from '@utils/formatNumber';

const SummaryFarm = ({ farmSeasonField }: SummaryFarmPropsT): JSX.Element => {
    const { country, harvestYear, farm, totalArea } = useSummaryFarmLogic({ farmSeasonField });
    const { t } = useTranslation();

    return (
        <SummaryPannel
            colorTheme="primary"
            subTitle={`${formatNumber(totalArea, 3)} ${t('constants.hectare-unit')}`}
            title={farm?.name ?? ''}
        >
            <Grid spacing={4} container>
                <Grid md={6} xs={12} item>
                    <CardFarm
                        city={farm?.city ?? ''}
                        country={country}
                        farmAddress={farm?.address ?? ''}
                        postcode={farm?.postal_code ?? ''}
                        harvestYear={harvestYear}
                    />
                </Grid>
                <Grid md={6} xs={12} item>
                    <CardLiveStock />
                </Grid>
            </Grid>
        </SummaryPannel>
    );
};

export default SummaryFarm;
