import { FarmSeasonCropT } from '@reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_LOADING = 'POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_LOADING';
export const POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_FAILED = 'POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_FAILED';
export const POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS = 'POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS';

export type PostFarmSeasonFieldsCropsResidueDataT = {
    fields: FarmSeasonFieldT[];
    crops: FarmSeasonCropT[];
    dontHaveAny: boolean;
};

export type PostFarmSeasonFieldsCropsResidueResponseDataT = {
    fields: FarmSeasonFieldT[];
    crops: FarmSeasonCropT[];
    installation: {
        onboarding: {
            finished: string[];
            field: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsCropsResiduePostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsCropsResidueDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsCropsResidueResponseErrorT>;

export type PostFarmSeasonFieldsCropsResidueResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsCropsResidueLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_LOADING;
};

export type PostFarmSeasonFieldsCropsResidueFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_FAILED;
    error: PostFarmSeasonFieldsCropsResidueResponseErrorT;
};

export type PostFarmSeasonFieldsCropsResidueSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS;
    payload: PostFarmSeasonFieldsCropsResidueResponseDataT;
};

export type PostFarmSeasonFieldsCropsResidueDispatchTypesT =
    | PostFarmSeasonFieldsCropsResidueLoadingT
    | PostFarmSeasonFieldsCropsResidueFailedT
    | PostFarmSeasonFieldsCropsResidueSuccessT;
