import { makeStyles } from 'tss-react/mui';

import { ButtonIconActionStyleProps } from './ButtonIconActionTypes';

const sizes = {
    small: '15px',
    medium: '35px',
    large: '50px',
};

const paddings = {
    small: '2px',
    medium: '5px',
    large: '6px',
};

const useButtonIconActionStyle = makeStyles<ButtonIconActionStyleProps>()((theme, { color, size, withoutBorder }) => ({
    ButtonIconAction: {
        border: withoutBorder ? 0 : `${size === 'small' ? 1 : 2}px solid ${theme.palette[color].main}`,
        borderRadius: '100%',
        color: theme.palette[color].main,
        '&:hover': {
            color: theme.palette[color].light,
        },
        height: sizes[size],
        width: sizes[size],
        minWidth: sizes[size],
        padding: paddings[size],
        '& svg': {
            maxWidth: '100%',
            width: '100%',
            maxHeight: '100%',
            height: '100%',
            '& path': {
                fill: theme.palette[color].main,
            },
        },
    },
}));

export default useButtonIconActionStyle;
