import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_LOADING = 'POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_LOADING';
export const POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_FAILED = 'POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_FAILED';
export const POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS = 'POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS';

export type CoverCropHistoryDataT = {
    id?: number;
    farm_season_field_id: number;
    year_introduction: string;
    frequency: number;
};

export type PostFarmSeasonFieldsCoverCropHistoryDataT = {
    coverCropHistories: CoverCropHistoryDataT[];
    dontHaveAny: boolean;
};

export type PostFarmSeasonFieldsCoverCropHistoryResponseDataT = {
    fields: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            irrigation: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsCoverCropHistoryPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsCoverCropHistoryDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsCoverCropHistoryResponseErrorT>;

export type PostFarmSeasonFieldsCoverCropHistoryResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsCoverCropHistoryLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_LOADING;
};

export type PostFarmSeasonFieldsCoverCropHistoryFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_FAILED;
    error: PostFarmSeasonFieldsCoverCropHistoryResponseErrorT;
};

export type PostFarmSeasonFieldsCoverCropHistorySuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS;
    payload: PostFarmSeasonFieldsCoverCropHistoryResponseDataT;
};

export type PostFarmSeasonFieldsCoverCropHistoryDispatchTypesT =
    | PostFarmSeasonFieldsCoverCropHistoryLoadingT
    | PostFarmSeasonFieldsCoverCropHistoryFailedT
    | PostFarmSeasonFieldsCoverCropHistorySuccessT;
