/* eslint-disable @typescript-eslint/no-explicit-any */
import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import { useContext, useEffect } from 'react';
// import palette from '@constants/palette/crop.palette';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import useMapFieldReset from '@hooks/useMapFieldReset';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useModal } from '@soil-capital/ui-kit';

import { Context } from '../TillagePracticeTaskContext/TillagePracticeTaskContext';
import { ContextT } from '../TillagePracticeTaskContext/TillagePracticeTaskContextTypes';

import FieldsGroupListView from './FieldsGroupListView';

type FieldsGroupListProps = {
    canEdit?: boolean;
};

const FieldsGroupList = ({ canEdit = false }: FieldsGroupListProps): JSX.Element => {
    const farmSeasonFields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const {
        t,
        formik,
        loading,
        groupedFields,
        setGroupedFields,
        setCurrentStep,
        nothingOnFields,
        setNothingOnFields,
        onSubmit,
        setSelectedGroup,
        setSelectedKey,
        keyColor,
        setGroupedFieldIndex,
        nothingToSave,
        setNothingToSave,
        tillagePracticeList,
        errorNothingOnFields,
        setErrorNothingOnFields,
    } = useContext<ContextT>(Context);

    const resetMap = useMapFieldReset();
    const modalDeleteAllGroupedField = useModal();
    const modalDeleteGroupedField = useModal();

    // Put the color on the map
    useEffect(() => {
        // set color of the group
        const fieldsWithColorOnMap: FarmSeasonFieldT[] = [];
        Object.keys(groupedFields).forEach((key) => {
            groupedFields[key].forEach((field) => {
                fieldsWithColorOnMap.push({ ...field, mapLayerColor: keyColor[key] });
            });
        });

        // set color in not in a group
        farmSeasonFields?.forEach((field: any) => {
            if (!fieldsWithColorOnMap.find((f) => f.id === field.id)) {
                fieldsWithColorOnMap.push({
                    ...field,
                    mapLayerColor: field.field_crops?.some(
                        (field_crop: any) => field_crop.farm_season_crop.crop.is_permanent,
                    )
                        ? '#000000'
                        : '#ffffff',
                });
            }
        });

        MapService.addPolygons({
            polygons: fieldsWithColorOnMap.map((field) => addPolygonData({ field, withColor: true })),
        });

        // reset formik form
        formik.resetForm();

        // reset the map on unmount
        return () => {
            resetMap(true);
        };
    }, [groupedFields, keyColor]);

    // delete all groups
    const deleteTillagePracticeHistoryGroups = () => {
        setGroupedFields({});
        setNothingOnFields(true);
        setNothingToSave(false);
    };

    // add a cover crop group
    const addTillagePractice = () => {
        formik.resetForm();
        setSelectedGroup([]);
        setGroupedFieldIndex(Object.keys(groupedFields).length + 1);
        setNothingToSave(false);
        setCurrentStep(2);
    };

    // edit a cover crop group
    const editTillagePractice = (groupKey: string) => {
        setSelectedGroup(groupedFields[groupKey]);
        setSelectedKey(() => groupKey);
        setGroupedFieldIndex(Object.keys(groupedFields).indexOf(groupKey) + 1);
        formik.resetForm();
        formik.setFieldValue('year_of_change', groupedFields[groupKey][0].tillage_practice_history?.year_of_change);
        formik.setFieldValue(
            'tillage_change_type_id',
            groupedFields[groupKey][0].tillage_practice_history?.tillage_change_type_id,
        );
        formik.setFieldValue('year_end_labour', groupedFields[groupKey][0].tillage_practice_history?.year_end_labour);
        formik.setFieldValue('key', groupKey);
        setNothingToSave(!canEdit);
        setCurrentStep(2);
    };

    // delete a cover crop group
    const deleteTillagePractice = (groupKey: string) => {
        const newGroupedFields = { ...groupedFields };
        delete newGroupedFields[groupKey];
        setGroupedFields(newGroupedFields);
        setNothingToSave(false);
    };

    return (
        <FieldsGroupListView
            addTillagePractice={addTillagePractice}
            modalDeleteAllGroupedField={modalDeleteAllGroupedField}
            modalDeleteGroupedField={modalDeleteGroupedField}
            deleteTillagePractice={deleteTillagePractice}
            deleteTillagePracticeHistoryGroups={deleteTillagePracticeHistoryGroups}
            editTillagePractice={editTillagePractice}
            errorNothingOnFields={errorNothingOnFields}
            groupedFields={groupedFields}
            keyColor={keyColor}
            loading={loading}
            nothingOnFields={nothingOnFields}
            nothingToSave={nothingToSave}
            setErrorNothingOnFields={setErrorNothingOnFields}
            setNothingOnFields={setNothingOnFields}
            setNothingToSave={setNothingToSave}
            t={t}
            tillagePracticeList={tillagePracticeList}
            onSubmit={onSubmit}
            canEdit={canEdit}
        />
    );
};

export default FieldsGroupList;
