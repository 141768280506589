// import { GetFarmSeasonCropsDispatchTypesT } from '@actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { InstallationOnboardingT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const DELETE_FARM_SEASON_FIELD_LOADING = 'DELETE_FARM_SEASON_FIELD_LOADING';
export const DELETE_FARM_SEASON_FIELD_FAILED = 'DELETE_FARM_SEASON_FIELD_FAILED';
export const DELETE_FARM_SEASON_FIELD_SUCCESS = 'DELETE_FARM_SEASON_FIELD_SUCCESS';

export type DeleteFarmSeasonFieldDataT = {
    farmSeasonFieldId: number;
};

export type DeleteFarmSeasonFieldResponseDataT = {
    farmSeasonFieldId: number;
    installation: InstallationOnboardingT;
};

export type FarmSeasonFieldDeleteCallBackT = (
    dispatch: Dispatch<DeleteFarmSeasonFieldDispatchTypesT>,
) => Promise<boolean>;

export type DeleteFarmSeasonFieldResponseErrorT = {
    message?: string;
    list?: string[];
};

export type DeleteFarmSeasonFieldLoadingT = {
    type: typeof DELETE_FARM_SEASON_FIELD_LOADING;
};

export type DeleteFarmSeasonFieldFailedT = {
    type: typeof DELETE_FARM_SEASON_FIELD_FAILED;
    error: DeleteFarmSeasonFieldResponseErrorT;
};

export type DeleteFarmSeasonFieldSuccessT = {
    type: typeof DELETE_FARM_SEASON_FIELD_SUCCESS;
    payload: DeleteFarmSeasonFieldResponseDataT;
};

export type DeleteFarmSeasonFieldDispatchTypesT =
    | DeleteFarmSeasonFieldLoadingT
    | DeleteFarmSeasonFieldFailedT
    | DeleteFarmSeasonFieldSuccessT;
