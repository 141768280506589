import { makeStyles } from 'tss-react/mui';

const useTooltipMissingSoilCharacteristicsStyle = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    errorIcon: {
        fontSize: '20px',
        color: theme.palette.primary.main,
        margin: '0 5px 0 5px',
    },
}));

export default useTooltipMissingSoilCharacteristicsStyle;
