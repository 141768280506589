import { makeStyles } from 'tss-react/mui';

const useSelectFieldStepViewStyle = makeStyles()((theme) => ({
    container: {},
    titleDarker: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
    subtitles: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
}));

export default useSelectFieldStepViewStyle;
