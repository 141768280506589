import { AppStateT } from '../store';
import selectSeasonProductPlan from './selectProductPlan';

const selectIsProductPlanActive = () => (state: AppStateT) => {
    const productPlan = selectSeasonProductPlan()(state);

    if (productPlan?.status === 'active') {
        return true;
    }

    // TODO add sepa delay check (60 days)
    if (productPlan?.status === 'pending') {
        return true;
    }

    return false;
};

export default selectIsProductPlanActive;
