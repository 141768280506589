import { makeStyles } from 'tss-react/mui';

const useSelectFieldsViewStyle = makeStyles()((theme) => ({
    container: {},
    groupedFieldTitle: {
        color: theme.palette.secondary.dark,
        marginBottom: '10px',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    title: {
        marginBottom: '20px',
    },
    titleDark: {
        color: theme.palette.secondary.dark,
    },
    inputContainer: {
        marginBottom: '20px',
    },
}));

export default useSelectFieldsViewStyle;
