/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, Button, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Trans } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { FieldArray, FormikErrors, FormikProvider } from 'formik';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useAgroforestryValuesFormStyle from './AgroforestryValuesFormView.style';
import { AgroforestryValuesFormViewPropsT } from './AgroforestryValuesFormTypes';
import AgroforestryCardForm from '@components/AgroforestryCardForm/AgroforestryCardForm';
import { AgroforestryCardFormValuesT } from '@components/AgroforestryCardForm/AgroforestryCardFormTypes';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';

const AgroforestryValuesFormView = ({
    selectedField,
    fieldLoading,
    formik,
    treesList,
    treesListLoading,
    previousSeasonTreesMap,
    t,
}: AgroforestryValuesFormViewPropsT): JSX.Element => {
    const { classes } = useAgroforestryValuesFormStyle();
    const theme = useTheme();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <>
            <Typography className={classes.fieldName} variant="h5">
                <span style={{ color: theme.palette.secondary.dark }}>{selectedField?.name}</span>
            </Typography>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.fields-subtasks.agroforestry.form.title">
                    Veuillez définir les
                    <span style={{ color: theme.palette.secondary.dark }}> types d&apos;arbres</span> pour cette
                    parcelle
                </Trans>
                <HelpButtonView id="pages.installationtasks.fields-subtasks.agroforestry.form.title" />
            </Typography>
            <FormikProvider value={formik}>
                <FieldArray
                    name="agroforestryValues"
                    render={(arrayHelpers) => (
                        <>
                            {formik.values.agroforestryValues.map((tree, index, selfValuesArray) => {
                                const previousSeasonTrees =
                                    previousSeasonTreesMap[tree.previous_farm_season_field_tree_id ?? ''];
                                const handleClose =
                                    selfValuesArray.length > 1 && !tree.previous_farm_season_field_tree_id
                                        ? () => arrayHelpers.remove(index)
                                        : undefined;
                                const handleValuesChange = (values: AgroforestryCardFormValuesT) => {
                                    if (!values.quantity_per_ha || !selectedField?.area)
                                        return arrayHelpers.replace(index, values);

                                    const newValues = {
                                        ...values,
                                        quantity: Math.floor(selectedField?.area * Number(values.quantity_per_ha)),
                                    };
                                    arrayHelpers.replace(index, newValues);
                                };

                                return (
                                    <AgroforestryCardForm
                                        disabledForm={!canEditFarmSeason.access}
                                        key={index}
                                        treeValues={tree}
                                        onValuesChange={handleValuesChange}
                                        onDelete={handleClose}
                                        submittedForm={formik.submitCount > 0}
                                        errors={
                                            formik.errors.agroforestryValues?.[
                                                index
                                            ] as FormikErrors<AgroforestryCardFormValuesT>
                                        }
                                        treesList={treesList}
                                        treesListLoading={treesListLoading}
                                        previousTreeValues={previousSeasonTrees}
                                    />
                                );
                            })}
                            {canEditFarmSeason.access ? (
                                <Button
                                    className={classes.addButton}
                                    color="secondary"
                                    fullWidth
                                    onClick={() => arrayHelpers.push({ tree_type_id: '', quantity: '', diameter: '' })}
                                >
                                    <AddIcon className={classes.addIcon} />
                                    {t('constants.add')}
                                </Button>
                            ) : null}
                        </>
                    )}
                />
            </FormikProvider>

            <div className={classes.footerButton}>
                <TwoButtonFooterView
                    rightButtonOverride={
                        <RestrictSaveFarmSeason>
                            <LoadingButton
                                text={t('constants.save')}
                                onClick={() => formik.handleSubmit()}
                                loading={fieldLoading}
                            />
                        </RestrictSaveFarmSeason>
                    }
                />
            </div>
        </>
    );
};

export default AgroforestryValuesFormView;
