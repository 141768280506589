/* eslint-disable @typescript-eslint/no-unused-vars */

import { Analytics } from '@segment/analytics-next';
import { ROLE_ID } from '@constants/roleIds';

/* eslint-disable @typescript-eslint/no-explicit-any */
class SegmentService {
    private static get windowSegment() {
        return (window as any).segment as Analytics | undefined;
    }
    // Segment PAGE event
    private static page = (category: string, pageName: string, properties?: Record<string, unknown>): void => {
        this.windowSegment?.page(category, pageName, properties);
    };

    // Segment TRACK event
    private static track(event: string, properties?: any): void {
        this.windowSegment?.track(event, properties);
    }

    // Segment IDENTIFY event
    public static identify(id?: number, traits?: any): void {
        this.windowSegment?.identify(id ? id.toString(10) : null, traits);
    }

    private static getRoleName(role_id: ROLE_ID | null) {
        switch (role_id) {
            case ROLE_ID.AGRONOMIST:
                return 'AGRONOMIST';
            case ROLE_ID.FARMER:
                return 'FARMER';
            default:
                return null;
        }
    }

    /* --------- Specific tracks - (Directory of Segment messages in FP) -------- */

    /** MSC-1886 - Trigger: user click on the button “Check my eligibility to the carbon programme” */
    public static eligibilityStartedTrack() {
        this.track('Eligibility started');
    }

    /** FP-3764 - Trigger: On click on “edit field” button */
    public static fieldEditionClickedTrack(props: { area_source: any; original_area: any; user_area: any; name: any }) {
        this.track('Field edition clicked', props);
    }

    /** FP-3764 - Trigger: modal “edit field” opening (whether from the fields list or from the field details) */
    public static fieldEditionDisplayedTrack(props: {
        area_source: any;
        original_area: any;
        user_area: any;
        name: any;
        is_area_editable?: any;
    }) {
        this.track('Field edition displayed', props);
    }

    /** FP-3764 - Trigger: user clicks on “save” in the field modification modal */
    public static fieldDetailModifiedTrack(props: {
        area_source: any;
        original_area: any;
        user_area: any;
        area_diff_percent: any;
        area_diff_ha: any;
        has_error: boolean;
        has_name_changed: any;
        has_area_changed: any;
        name: any;
    }) {
        this.track('Field detail modified', props);
    }

    /** FP-3764 - Trigger: Save of all fields + save details field */
    public static totalAreaCheckTrack(props: {
        baseline_user_area: any;
        season_original_area: any;
        current_total_user_area: any;
        total_area_increase: any;
        total_area_diff_percent: any;
        total_area_diff_ha: any;
    }) {
        this.track('Total area check', props);
    }

    /** FP-3764 - Trigger: Modal of total area check displayed - warning for area increase */
    public static totalAreaIncreaseWarningTrack(props: {
        action_result: 'cancel';
        original_total_area: any;
        current_total_user_area: any;
        baseline_total_user_area: any;
        previous_total_user_area: any;
    }) {
        this.track('Total area increase warning ', props);
    }

    /** FP-3764 - Trigger: Modal of total area check displayed - warning for area decrease */
    public static totalAreaDecreaseConfirmationTrack(props: {
        action_result: 'cancel' | 'save';
        original_total_area: any;
        current_total_user_area: any;
        baseline_total_user_area: any;
        previous_total_user_area: any;
    }) {
        this.track('Total area decrease confirmation', props);
    }

    /** FP-3938 - Trigger: Click on “draw” in field tools */
    public static fieldManualDrawingInitiatedTrack(props: { baseline: boolean }) {
        this.track('Field manual drawing initiated ', props);
    }

    /** FP-3938 - Trigger: Click on “edit” in map field tools */
    public static fieldManualModificationStartedTrack(props: { baseline: boolean }) {
        this.track('Field manual modification started', props);
    }

    /** FP-3938 - Trigger: Click on “split” in map field tools */
    public static fieldManualSplitStartedTrack(props: { baseline: boolean }) {
        this.track('Field manual split started', props);
    }

    /** FP-3938 - Trigger: Click on “merge” in map field tools */
    public static fieldManualMergeStartedTrack(props: { baseline: boolean }) {
        this.track('Field manual merge started', props);
    }

    /** FP-3938 - Trigger: Click on “finalise” in map field tools */
    public static fieldManualDrawingFinalizedTrack(props: { baseline: boolean }) {
        this.track('Field manual drawing finalised', props);
    }

    /** FP-3938 - Trigger: Click on “finalise” in map field tools */
    public static fieldManualModificationFinalizedTrack(props: { baseline: boolean }) {
        this.track('Field manual modification finalised', props);
    }

    /** FP-3938 - Trigger: Click on “finalise” in map field tools */
    public static fieldManualSplitFinalizedTrack(props: { baseline: boolean }) {
        this.track('Field manual split finalised', props);
    }

    /** FP-3938 - Trigger: Click on “finalise” in map field tools */
    public static fieldManualMergeFinalizedTrack(props: { baseline: boolean }) {
        this.track('Field manual merge finalised', props);
    }

    /** FP-3938 - Trigger: Click on “bring back fields from last season */
    public static dataSuggestionRetrievedTrack(props: {
        /** success mean 1 or more data retrieved */
        success: boolean;
        place: 'crops' | 'cover_crops' | 'tillage_practices' | 'irrigation' | 'drainage' | 'residue';
    }) {
        this.track('Past season data retrieved', props);
    }

    // FP-3939 - Trigger: Display of the modal “forgotten fields” in a task
    public static forgottenFieldsModalDisplayedTrack(props: {
        forgotten_task: 'tillage' | 'crop' | 'SOM' | 'SOC' | 'acidity';
        action_result: 'cancel' | 'save';
        nb_fields_forgotten: number;
        nb_fields_total: number;
    }) {
        this.track('Forgotten fields modal displayed', props);
    }

    // FP-3956 | FP-4140 - Trigger: farm summary navigation action clicked
    public static farmSummaryNavigationClickedTrack(props: {
        context:
            | 'baseline-self-encoding-to-start'
            | 'renewal-self-encoding-to-start'
            | 'self-encoding-ongoing'
            | 'waiting-agronomist-encoding'
            | 'waiting-data-validation'
            | 'waiting-results-validation'
            | 'results-available'
            | 'renewal-new-season-to-start'
            | 'renewal-payment-required'
            | 'renewal-payment-in-progress'
            | null;
    }) {
        this.track('farm summary navigation clicked', props);
    }

    // FP-4106 - Trigger: Click on "Change plan"
    public static upsellButtonClickedTrack({
        baseline,
        user_role_id,
    }: {
        baseline: boolean;
        user_role_id: ROLE_ID | null;
    }) {
        const roleName = this.getRoleName(user_role_id);
        this.track('Upsell button clicked', { baseline, user_role: roleName });
    }
    // FP-4157 - Trigger: "Send payment link" button clicked
    public static upsellPaymentEmailRequestedTrack({ baseline }: { baseline: boolean }) {
        this.track('Upsell payment email requested', { baseline });
    }
    // FP-4157 - Trigger: "finalise payment" button clicked
    public static upsellFinalisePaymentTrack({
        baseline,
        user_role_id,
    }: {
        baseline: boolean;
        user_role_id: ROLE_ID | null;
    }) {
        const roleName = this.getRoleName(user_role_id);
        this.track('Upsell finalise payment button clicked', { baseline, user_role: roleName });
    }
    // FP-4276 - Trigger: The field overview modal is displayed
    public static fieldOverviewDisplayedTrack(props: { past_season_information: boolean }) {
        this.track('Field overview displayed', props);
    }
    // FP-4276 - Trigger: The field overview modal is closed
    public static fieldOverviewClosedTrack(props: {
        past_season_information: boolean;
        closing_action: 'closing button' | 'stop hovering';
        time_opened: number;
    }) {
        this.track('Field overview closed', props);
    }
    // FP-4288 - Trigger: User clicks on “Pay in advance”
    public static renewalPayInAdvanceButtonTrack({
        discount,
        user_role_id,
    }: {
        discount: number;
        user_role_id: ROLE_ID | null;
    }) {
        const roleName = this.getRoleName(user_role_id);
        this.track('Pay in advance button clicked', { payment_discount: discount, user_role: roleName });
    }

    // FP-4422 - Trigger: The celebration modal concerning certificates is displayed
    public static certificateCelebrationModalDisplayedTrack(props: { iban_requested: boolean }) {
        this.track('Certificate celebration modal displayed', props);
    }

    // FP-4435 - Trigger: Farmer clicks on "Start a new season" in famr dashboard
    public static newSeasonStartedTrack(props: { status: 'basic' | 'standard' }) {
        this.track('New season started', props);
    }

    /* -------------- Specific pages - (Directory of Segment pages) ------------- */
    /* -------------------------------------------------------------------------- */

    // FP-3956 | FP-4140
    public static farmPage(props: {
        context:
            | 'baseline-self-encoding-to-start'
            | 'renewal-self-encoding-to-start'
            | 'self-encoding-ongoing'
            | 'waiting-agronomist-encoding'
            | 'waiting-data-validation'
            | 'waiting-results-validation'
            | 'results-available'
            | 'renewal-new-season-to-start'
            | 'renewal-payment-required'
            | 'renewal-payment-in-progress'
            | null;
    }) {
        this.page('Farmer portal', 'Farm summary', props);
    }

    // FP-4433 - Trigger: User clicks on the edit payment method button
    public static paymentMeanEditionButtonClickedTrack(props: { place: 'billing_page' | 'payment_modal' }) {
        this.track('Payment mean edition button clicked', props);
    }
}

export default SegmentService;
