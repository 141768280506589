import { CoverCropHistoryDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsCoverCropHistoryTypes';
import { OrganicFertilizationHistoryDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicFertilizationHistoryTypes';
import { PastureHistoryDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsPastureHistoryTypes';
import { FarmSeasonFieldTillagePracticeHistoryT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { useTranslation } from 'react-i18next';
import findById from '@utils/findById';

import { CardHistoryLogicPropsT } from './CardHistory.types';

const useCardHistoryLogic = ({ tillageChangeList }: CardHistoryLogicPropsT) => {
    const { t } = useTranslation();

    /* ------------------------- Cell Value Transformers ------------------------ */

    /** Display "[tillage change] from [year of change] (labour ended in [end labour])" */
    const transformTillageHistory = (tillagePracticeHistory: FarmSeasonFieldTillagePracticeHistoryT) => {
        if (!tillagePracticeHistory?.tillage_change_type_id || !tillagePracticeHistory?.year_of_change) {
            return '—';
        }

        // display labour text only if year_end exist
        const labourEndText = tillagePracticeHistory.year_end_labour
            ? t('pages.farmer-data.history.cell-labour-end', { labourEndYear: tillagePracticeHistory.year_end_labour })
            : '';

        return `${t('pages.farmer-data.history.cell-tillage-history', {
            tillageChange: t(
                findById(tillagePracticeHistory.tillage_change_type_id, tillageChangeList)?.translation_slug ?? '',
            ),
            yearOfChange: tillagePracticeHistory.year_of_change,
        })} ${labourEndText}`;
    };

    /** Display "Introduced in [year of introduction] every [frequency] years(s)" */
    const transformCoverCropHistory = (coverCropHistory: CoverCropHistoryDataT) =>
        coverCropHistory?.year_introduction && coverCropHistory?.frequency
            ? t('pages.farmer-data.history.cell-cover-crop-history', {
                  yearIntroduction: coverCropHistory.year_introduction,
                  frequency: coverCropHistory.frequency,
                  count: coverCropHistory.frequency,
              })
            : '—';

    /** Display "Introduced in [year of introduction] every [frequency] years(s)" */
    const transformFertilisationHistory = (fertilisationHistory: OrganicFertilizationHistoryDataT) =>
        fertilisationHistory?.year_introduction && fertilisationHistory?.frequency
            ? t('pages.farmer-data.history.cell-fertilisation-history', {
                  yearIntroduction: fertilisationHistory.year_introduction,
                  frequency: fertilisationHistory.frequency,
                  count: fertilisationHistory.frequency,
              })
            : '—';

    /** Display "Transformed in [year of transformation]" */
    const transformPastureHistory = (pastureHistory: PastureHistoryDataT) =>
        pastureHistory?.year_of_change
            ? t('pages.farmer-data.history.cell-pasture-history', { yearOfChange: pastureHistory.year_of_change })
            : '—';

    /* -------------------------------------------------------------------------- */

    return {
        transformCoverCropHistory,
        transformPastureHistory,
        transformFertilisationHistory,
        transformTillageHistory,
    };
};

export default useCardHistoryLogic;
