import useMapStyle from '@components/Map/Map.style';
import { MapViewPropsT } from '@components/Map/MapTypes';
import RelationsDndModal from '@components/RelationsDndModal/RelationsDndModal';
import WarningModalView from '@components/WarningModal/WarningModalView';
import IconLayers from '@icons/icon-layers.svg';
import IconPolygon from '@icons/icon-polygon.svg';
import IconSplit from '@icons/icon-split.svg';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import BlockIcon from '@mui/icons-material/Block';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import { Box, CircularProgress, Fab, Slide, SvgIcon, Typography, Zoom } from '@mui/material';
import 'mapbox-gl/dist/mapbox-gl.css';

const MapView = ({
    t,
    id,
    MapGL,
    loading,
    createMode,
    disabled,
    drawing,
    onClickDraw,
    onClickEdit,
    onClickMerge,
    onClickSplit,
    onCancelMerge,
    onFinishMerge,
    onFinishSplit,
    hover,
    overlappingPolygon,
    warningModalOpen,
    warningModalData,
    relationModalOpen,
    relationModalData,
    blockedModalOpen,
    blockedModalData,
    onWarningModalConfirm,
    onWarningModalCancel,
    onRelationModalConfirm,
    onRelationModalClose,
    onBlockedModalConfirm,
    onClickHome,
    selectMode,
    editMode,
    mergeMode,
    splitMode,
    hasPolygons,
    interactive,
}: MapViewPropsT): JSX.Element => {
    const { classes } = useMapStyle({ hover });

    // loader shown on first load
    const loader = (
        <Box
            alignItems="center"
            className={classes.loader}
            display="flex"
            height="100%"
            justifyContent="center"
            style={{ opacity: loading ? 1 : 0 }}
        >
            <CircularProgress />
        </Box>
    );

    // disabling box
    const disablingBox = (
        <Box
            alignItems="center"
            className={classes.disablingBox}
            display="flex"
            height="100%"
            justifyContent="center"
            width="100%"
        />
    );

    // snackbar box
    const snackbarBox = (
        <Box
            alignItems="flex-start"
            className={classes.snackbarBox}
            display="flex"
            height="100%"
            justifyContent="center"
            style={
                createMode || editMode || mergeMode || splitMode || drawing || selectMode
                    ? { transform: 'scale(1,1)' }
                    : {}
            }
            width="100%"
        >
            <Slide direction="down" in={drawing}>
                <Typography className={classes.snackbarMsg}>{t('component.map.snackbar-msg.draw-mode')}</Typography>
            </Slide>
            <Slide direction="down" in={!drawing && editMode}>
                <Typography className={classes.snackbarMsg}>{t('component.map.snackbar-msg.edit-mode')}</Typography>
            </Slide>
            <Slide direction="down" in={!drawing && mergeMode}>
                <Typography className={classes.snackbarMsg}>{t('component.map.snackbar-msg.merge-mode')}</Typography>
            </Slide>
            <Slide direction="down" in={!drawing && splitMode}>
                <Typography className={classes.snackbarMsg}>{t('component.map.snackbar-msg.split-mode')}</Typography>
            </Slide>
            <Slide direction="down" in={!drawing && !editMode && !mergeMode && !splitMode && createMode}>
                <Typography className={classes.snackbarMsg}>{t('component.map.snackbar-msg.create-mode')}</Typography>
            </Slide>
            <Slide direction="down" in={!drawing && !createMode && selectMode}>
                <Typography className={classes.snackbarMsg}>{t('component.map.snackbar-msg.select-mode')}</Typography>
            </Slide>
        </Box>
    );

    // call-to-action buttons to start/cancel/save polygon drawing
    const cta =
        createMode && !drawing && !editMode && !mergeMode && !splitMode ? (
            <div className={`cta-btn ${classes.addPolygonButton}`}>
                <Zoom in={createMode && !drawing}>
                    <Fab
                        aria-label="add"
                        className="cta-buttons create-button"
                        variant="extended"
                        onClick={() => onClickDraw?.('start')}
                    >
                        <div className="cta-btn-icon add-polygon-button-icon">
                            <CreateIcon />
                        </div>
                        {t('component.map.cta-btn.add-polygon')}
                    </Fab>
                </Zoom>
                <Zoom in={createMode && !drawing && hasPolygons}>
                    <Fab
                        aria-label="edit"
                        className="cta-buttons edit-button"
                        variant="extended"
                        onClick={() => onClickEdit?.()}
                    >
                        <div className="cta-btn-icon edit-polygon-button-icon">
                            <SvgIcon component={IconPolygon} viewBox="0 0 20 20" />
                        </div>
                        {t('component.map.cta-btn.edit-polygon')}
                    </Fab>
                </Zoom>
                <Zoom in={createMode && !drawing && hasPolygons}>
                    <Fab
                        aria-label="merge"
                        className="cta-buttons merge-button"
                        variant="extended"
                        onClick={() => onClickMerge?.()}
                    >
                        <div className="cta-btn-icon merge-polygons-button-icon">
                            <SvgIcon component={IconLayers} viewBox="0 0 48 48" />
                        </div>
                        {t('component.map.cta-btn.merge-polygons')}
                    </Fab>
                </Zoom>
                <Zoom in={createMode && !drawing && hasPolygons}>
                    <Fab
                        aria-label="split"
                        className="cta-buttons split-button"
                        variant="extended"
                        onClick={() => onClickSplit?.()}
                    >
                        <div className="cta-btn-icon split-polygons-button-icon">
                            <SvgIcon component={IconSplit} viewBox="0 0 12 15" />
                        </div>
                        {t('component.map.cta-btn.split-polygons')}
                    </Fab>
                </Zoom>
            </div>
        ) : (
            <div className={`cta-btn ${classes.drawingButtons}`}>
                <Zoom in={drawing || editMode || mergeMode || splitMode}>
                    <Fab
                        aria-label="cancel"
                        className="drawing-button drawing-cancel-button"
                        variant="extended"
                        onClick={() => {
                            if (mergeMode) return onCancelMerge?.();
                            else return onClickDraw?.('cancel');
                        }}
                    >
                        <div className="cta-btn-icon drawing-button-icon drawing-cancel-button-icon">
                            <BlockIcon />
                        </div>
                        {t('constants.cancel')}
                    </Fab>
                </Zoom>
                <Zoom in={drawing || mergeMode}>
                    <Fab
                        aria-label="ok"
                        className="drawing-button drawing-ok-button"
                        disabled={overlappingPolygon}
                        variant="extended"
                        onClick={() => {
                            if (splitMode) {
                                return onFinishSplit?.();
                            } else if (mergeMode) return onFinishMerge?.();
                            else return onClickDraw?.('save');
                        }}
                    >
                        <div className="cta-btn-icon drawing-button-icon drawing-ok-button-icon">
                            <DoneIcon />
                        </div>
                        {t('component.map.cta-btn.drawing-ok')}
                    </Fab>
                </Zoom>
            </div>
        );

    const mapCenterFarmBtn = (
        <div className="mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-backhome-container">
            <button className="mapboxgl-ctrl-backhome" type="button" onClick={onClickHome}>
                <span aria-hidden="true" className="mapboxgl-ctrl-icon" />
            </button>
        </div>
    );

    const warningModal = warningModalOpen && (
        <WarningModalView
            className={classes.modal}
            confirmMessage={warningModalData?.modal.confirmMessage}
            description={warningModalData?.modal.description}
            leftButtonText={t('constants.cancel')}
            leftOnClick={onWarningModalCancel}
            rightButtonText={t('constants.confirm')}
            rightOnCLick={onWarningModalConfirm}
            title={warningModalData?.modal.title}
        />
    );

    const blockedModal = blockedModalOpen && (
        <WarningModalView
            className={classes.modal}
            description={blockedModalData?.modal.description}
            rightButtonText={t('constants.ok')}
            rightOnCLick={onBlockedModalConfirm}
            title={blockedModalData?.modal.title}
        />
    );

    const cropsRelationsModal = relationModalOpen && (
        <RelationsDndModal
            className={classes.modal}
            field={relationModalData?.field}
            onClose={onRelationModalClose}
            onConfirm={(field_crops) => onRelationModalConfirm?.(field_crops)}
        />
    );

    let classMode = '';

    if (!drawing && editMode) classMode = 'editing-field';
    else if (!drawing && mergeMode) classMode = 'selecting-field';
    else if (!editMode && drawing) classMode = 'creating-field';
    else if (!drawing && !createMode && selectMode) classMode = 'selecting-field';

    // the view
    return (
        <div className={`${classes.mapContainer} ${classMode}`} id={`${id}-container`}>
            {loader}
            {disabled || relationModalOpen || warningModalOpen || blockedModal ? disablingBox : ''}
            {!disabled ? snackbarBox : ''}
            {createMode || drawing ? cta : ''}
            {warningModal}
            {blockedModal}
            {cropsRelationsModal}
            <div className={classes.map} id={id}>
                {interactive && mapCenterFarmBtn}
                {MapGL}
            </div>
        </div>
    );
};

export default MapView;
