import { makeStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react';

const useRequiredInputLabelStyle = makeStyles()((theme) => ({
    label: {
        ...(theme.typography.h6 as CSSObject),
        color: theme.palette.secondary.main,
    },
}));

export default useRequiredInputLabelStyle;
