import useStickyFooterLayoutStyle from './StickyFooterLayout.style';
import { StickyFooterLayoutPropsT } from './StickyFooterLayoutTypes';

/**
 * Sticky footer with fade top background for scroll.
 * /!\ nearest scrolling ancestor should not have fixed size if its content can grow !
 * @require parent with `display:'flex'` and `flexDirection:'column'`
 */
const StickyFooterLayout = ({
    bgColor,
    children,
    paddingBottom,
    fillParentPadding,
}: StickyFooterLayoutPropsT): JSX.Element => {
    const { classes } = useStickyFooterLayoutStyle({
        bgColor,
        paddingBottom,
        fillParentPadding,
    });
    return <div className={classes.StickyFooterLayout}>{children}</div>;
};

export default StickyFooterLayout;
