import translationGet from '@actions/StaticActions/TranslationsGetAction';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useUserLanguageChanged = (): void => {
    const dispatch = useAppDispatch();
    const user = useSelector((state: AppStateT) => state.auth.user);
    const languages = useSelector((state: AppStateT) => state.static.languages);

    useEffect(() => {
        if (user?.language_id) {
            const isoCodeLanguage = languages.find((lang) => lang.id === user.language_id)?.iso_code;

            if (isoCodeLanguage) {
                dispatch(translationGet(isoCodeLanguage));
            }
        }
    }, [user?.language_id]);
};

export default useUserLanguageChanged;
