import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    IrrigationMethodListGetCallback,
    IrrigationMethodListGetDispatchType,
    IrrigationMethodListGetSuccessDataT,
    IRRIGATION_METHOD_LIST_GET_FAILED,
    IRRIGATION_METHOD_LIST_GET_LOADING,
    IRRIGATION_METHOD_LIST_GET_SUCCESS,
} from './IrrigationMethodGetListActionTypes';

const localStorageKey = 'irrigationMethods';

const irrigationMethodGetListAction =
    (): IrrigationMethodListGetCallback =>
    async (dispatch: Dispatch<IrrigationMethodListGetDispatchType>): Promise<boolean> => {
        try {
            dispatch({
                type: IRRIGATION_METHOD_LIST_GET_LOADING,
            });

            const irrigationMethods = localStorage.getItem(localStorageKey);

            if (!irrigationMethods) {
                const res: AxiosResponse<IrrigationMethodListGetSuccessDataT> =
                    await StaticApiService.getIrrigationMethodList();

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));
                dispatch({
                    type: IRRIGATION_METHOD_LIST_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: IRRIGATION_METHOD_LIST_GET_SUCCESS,
                    payload: JSON.parse(irrigationMethods),
                });
            }

            return true;
        } catch (error) {
            dispatch({
                type: IRRIGATION_METHOD_LIST_GET_FAILED,
                error: error as string,
            });

            return false;
        }
    };

export default irrigationMethodGetListAction;
