import { makeStyles } from 'tss-react/mui';

const useInputCardStyle = makeStyles<{ disabled: boolean }>()((theme, { disabled }) => ({
    card: {
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.05)',
        marginBottom: '10px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        justifyContent: 'space-between',
    },

    text: {
        color: disabled ? theme.palette.grey[400] : theme.palette.primary.main,
    },
    textSection: {},
    textSectionTitle: {
        lineHeight: 1.3,
    },
    textSectionDescription: {
        display: 'grid',
        gridTemplateColumns: '80px calc(100% - 80px)',
    },
    inputContainer: {
        maxWidth: '140px',
    },
    textField: {
        '& > div > input.Mui-disabled ': {
            color: theme.palette.grey[400],
        },
    },
    progress: {},
}));

export default useInputCardStyle;
