import FarmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonOperationSprayingPostCallBackT,
    PostFarmSeasonOperationSprayingDataT,
    PostFarmSeasonOperationSprayingDispatchTypesT,
    PostFarmSeasonOperationSprayingResponseDataT,
    PostFarmSeasonOperationSprayingResponseErrorT,
    POST_FARM_SEASON_OPERATION_SPRAYING_FAILED,
    POST_FARM_SEASON_OPERATION_SPRAYING_LOADING,
    POST_FARM_SEASON_OPERATION_SPRAYING_SUCCESS,
} from './PostFarmSeasonOperationSprayingTypes';

export const postFarmSeasonOperationSpraying =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonOperationSprayingDataT,
    ): FarmSeasonOperationSprayingPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonOperationSprayingDispatchTypesT>,
    ): Promise<PostFarmSeasonOperationSprayingResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_OPERATION_SPRAYING_LOADING });
            const res: AxiosResponse<PostFarmSeasonOperationSprayingResponseDataT> =
                await FarmSeasonOperationApiService.post<'spraying'>(farmId, farmSeasonId, 'spraying', body);

            dispatch({
                type: POST_FARM_SEASON_OPERATION_SPRAYING_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_OPERATION_SPRAYING_FAILED,
                error: error as PostFarmSeasonOperationSprayingResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
