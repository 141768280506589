import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
const useTaskItemStyle = makeStyles<{ taskDone: boolean; disabled?: boolean }>()(
    (theme, { taskDone, disabled }, classes: any) => ({
        navLink: {
            color: theme.palette.primary.light,
            textDecoration: 'none',
            fontWeight: 'bold',
            '&.active > span': {
                borderRadius: '10px 0 0 10px',
                backgroundColor: theme.palette.spanishWhite.light,
            },
            cursor: disabled ? 'default' : 'pointer',
        },

        taskButton: {},

        taskButtonCard: {
            textAlign: 'left',
            padding: '2px 10px',
            boxShadow: `
      0px 3px 5px -1px ${alpha(theme.palette.spanishWhiteShadow, 0.2)},
      0px 6px 10px 0px ${alpha(theme.palette.spanishWhiteShadow, 0.5)},
      0px 1px 18px 0px ${alpha(theme.palette.spanishWhiteShadow, 0.2)}
    `,
        },

        taskItem: {
            [`&, & .${classes.taskButton}, & .${classes.taskButtonCard}`]: {
                width: '100%',
            },
            [`& .${classes.taskButton}, & .${classes.taskButtonCard}`]: {
                borderRadius: '15px',
            },
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: '2px',
            opacity: disabled || taskDone ? 0.5 : 1,
        },

        taskButtonCardContent: {
            padding: '10px !important',
            color: theme.palette.spanishWhite.contrastText,
            '& Label': {
                paddingLeft: '6px',
                '& svg': {
                    opacity: 0.4,
                    marginRight: '10px',
                },
                '& span': {
                    fontWeight: theme.typography.fontWeightBold,
                },
            },
            '& Label svg': {
                color: taskDone ? theme.palette.shamrock.main : undefined,
            },
        },

        taskFocusVisible: {},
    }),
);

export default useTaskItemStyle;
