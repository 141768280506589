import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import getFarmSeasonLivestock from '@actions/FarmSeasonLivestockActions/GetFarmSeasonLivestocks';
import { livestocksGet } from '@actions/StaticActions/LivestocksAction';
import { TableColumnsT, TableRowsT } from '@components/Table/TableViewTypes';
import { useTranslation } from 'react-i18next';
import findById from '@utils/findById';
import CardBasic from '@components/CardBasic/CardBasic';
import TableView from '@components/Table/TableView';

import useCardLiveStockStyle from './CardLivestock.style';

const CardLiveStock = (): JSX.Element => {
    const { classes } = useCardLiveStockStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    /* ---------------- Get Redux Data ---------------- */

    const staticLivestocks = useSelector((state: HomePageStateT) => state.static.livestocks);
    const farmSeasonLivestocks = useSelector((state: HomePageStateT) => state.farmSeasonLivestock);
    const isTableLoading = useSelector(
        (state: HomePageStateT) => state.static.livestocksLoading || state.farmSeasonLivestock.loading,
    );
    // Context
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);

    /* -------------------------- refresh store with "up to date" data ------------------------- */

    useEffect(() => {
        if (farmId && farmSeasonId && !farmSeasonLivestocks.loaded) {
            dispatch(getFarmSeasonLivestock(farmId, farmSeasonId));
            dispatch(livestocksGet());
        }
    }, []);

    /* ----------------------------- Columns Schema ----------------------------- */

    const columns: TableColumnsT<'animal' | 'quantity'> = [
        {
            field: 'animal',
            headerName: t('component.livestock-table.col.animal'),
        },
        {
            field: 'quantity',
            headerName: t('component.livestock-table.col.quantity'),
        },
    ];

    /* --------------------- Build rows from farmSeasonLivestocks list -------------------- */

    const rows: TableRowsT<'animal' | 'quantity'> = farmSeasonLivestocks.livestockList.map((livestock) => ({
        animal: t(findById(+livestock.livestock_id, staticLivestocks)?.translation_slug ?? ''),
        quantity: livestock.quantity,
    }));

    const animalsCount = farmSeasonLivestocks.livestockList.reduce(
        (total, livestock) => total + Number(livestock.quantity),
        0,
    );
    const totalAnimalsText = t('pages.farmer-data.livestock.animals-count', { count: animalsCount });

    return (
        <CardBasic
            additionalInfo={totalAnimalsText}
            className={classes.card}
            color="primary"
            isLoading={isTableLoading}
            title={t('pages.farmer-data.livestock.title')}
        >
            <>{!!rows.length && <TableView columns={columns} rows={rows} />}</>
        </CardBasic>
    );
};

export default CardLiveStock;
