import { api } from './api.service';
import { GetNotificationCelebrationsResponseT, MarkAsReadRequestT } from './notification.types';

export const notificationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCelebration: build.query<GetNotificationCelebrationsResponseT, void>({
            query: () => ({
                url: '/notifications/celebration',
                method: 'GET',
            }),
            providesTags: ['Notification'],
        }),

        markAsRead: build.mutation<void, MarkAsReadRequestT>({
            query: ({ notificationId }) => ({
                url: `/notifications/${notificationId}/read`,
                method: 'PUT',
            }),
            invalidatesTags: ['Notification'],
        }),
    }),
});
