import soilOrganicMatters from '@constants/soilOrganicMatters';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import isArrayEqual from '@utils/isArrayEqual';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FieldsSoilOrganicMatterDataT } from '../SelectStepTypes';

import { SelectFieldStepPropsT } from './SelectFieldStepTypes';
import SelectFieldStepView from './SelectFieldStepView';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const SelectFieldStep = ({
    selectedSoilOrganicMatter,
    formFields,
    setFormFields,
    popStep,
}: SelectFieldStepPropsT): JSX.Element => {
    const { t } = useTranslation();
    // get map state
    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });
    const [selectedFields, setSelectedFields] = useState<FieldsSoilOrganicMatterDataT[]>([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }

        MapService.selectablePolygons({ selectMode: true });
        MapService.defineCondition({
            selectCondition: ({ id: polygonId }) => {
                const field = formFields.find((formField) => formField.id === polygonId);
                const soilOrganicMatter = soilOrganicMatters.find(
                    (soilOrganic) => soilOrganic.id === field?.soil_organic_matter_id,
                );

                return {
                    selectType: field?.soil_organic_matter_id ? 'warning' : null,
                    modal: {
                        title: t(
                            'pages.installationtasks.fields-subtasks.soil-organic-matters-task.select-step.select-field-step.alert-selection-model.title',
                        ),
                        description: t(
                            'pages.installationtasks.fields-subtasks.soil-organic-matters-task.select-step.select-field-step.alert-selection-model.description',
                            { lowLevel: soilOrganicMatter?.lowLevel, highLevel: soilOrganicMatter?.highLevel },
                        ),
                        confirmMessage: t(
                            'pages.installationtasks.fields-subtasks.soil-organic-matters-task.select-step.select-field-step.alert-selection-model.confirm-message',
                        ),
                    },
                };
            },
        });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
            MapService.defineCondition({ selectCondition: null });
        };
    }, []);

    useEffect(() => {
        const selected = formFields?.filter((field) =>
            thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id),
        );
        setSelectedFields(selected);
    }, [thisMap.selectedPolygons]);

    useEffect(() => {
        const formSelectedFields = formFields?.filter(
            (field) => field.soil_organic_matter_id === selectedSoilOrganicMatter.id,
        );

        if (selectedFields.length !== formSelectedFields?.length) {
            setSelectedFields(formSelectedFields);
        }

        if (thisMap?.selectedPolygons?.length !== formSelectedFields?.length) {
            MapService.updateSelectedPolygons({
                selectedPolygons: formSelectedFields.map((field) => field.polygon),
            });
        }
    }, [formFields]);

    const onSelectedFieldDelete = (id: number | string) => {
        setSelectedFields((fields) => fields.filter((field) => field.id !== id));
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    const onConfirm = () => {
        setFormFields((values) => ({
            fields: values.fields.map((field) => {
                const newField = { ...field };
                const selectedField = selectedFields.find((sField) => sField.id === field.id);

                // Delete this tillage from all
                if (newField.soil_organic_matter_id === selectedSoilOrganicMatter.id) {
                    newField.soil_organic_matter_id = null;
                }

                // Change tillage id
                if (selectedField) {
                    newField.soil_organic_matter_id = selectedSoilOrganicMatter.id;
                }

                return newField;
            }),
        }));
        setIsSubmitted(true);
        popStep();
    };

    const onSelectAll = () => {
        const fieldsWithoutSoilOrganicMatter = formFields.filter(
            (formField) =>
                !selectedFields.find((field) => field.id === formField.id) &&
                (!formField.soil_organic_matter_id ||
                    formField.soil_organic_matter_id === selectedSoilOrganicMatter.id),
        );
        setSelectedFields((fields) => [...fields, ...fieldsWithoutSoilOrganicMatter]);
        MapService.updateSelectedPolygons({
            selectedPolygons: [
                ...(thisMap.selectedPolygons || []),
                ...fieldsWithoutSoilOrganicMatter.map((field) => field.polygon),
            ],
        });
    };

    return (
        <SelectFieldStepView
            isDirty={
                !isArrayEqual(
                    selectedFields,
                    formFields?.filter((formField) => formField.id === selectedSoilOrganicMatter.id),
                )
            }
            selectedFields={selectedFields}
            isSubmitted={isSubmitted}
            selectedSoilOrganicMatter={selectedSoilOrganicMatter}
            t={t}
            onConfirm={onConfirm}
            onSelectAll={onSelectAll}
            onSelectedFieldDelete={onSelectedFieldDelete}
        />
    );
};

export default SelectFieldStep;
