import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

import {
    DeleteFarmSeasonOperationApiT,
    GetFarmSeasonOperationApiT,
    OperationTypeT,
    PostFarmSeasonOperationApiT,
    PostFarmSeasonOperationResponseApiT,
} from './farmSeasonOperationApiServiceTypes';

class farmSeasonOperationApiService {
    /**
     * Insert any operation if doesn't exist. Update operation if already exist
     */
    public static post = async <T extends OperationTypeT>(
        farmId: number,
        farmSeasonId: number,
        operationType: T,
        body: PostFarmSeasonOperationApiT[T],
    ): Promise<AxiosResponse<PostFarmSeasonOperationResponseApiT[T]>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/operations/${operationType}`,
            method: 'POST',
            data: body,
        });

    /** Get any Operation */
    public static get = async <T extends OperationTypeT>(
        farmId: number,
        farmSeasonId: number,
        operationType: T,
        tillageId?: number | string,
        farmSeasonCropId?: number | string,
    ): Promise<AxiosResponse<GetFarmSeasonOperationApiT[T]>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/operations/${operationType}?tillageId=${tillageId}&farmSeasonCropId=${farmSeasonCropId}`,
            method: 'GET',
        });

    public static deleteAll = async (
        farmId: number,
        farmSeasonId: number,
        tillageId?: number | string,
        farmSeasonCropId?: number | string,
    ): Promise<AxiosResponse<DeleteFarmSeasonOperationApiT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/operations?tillageId=${tillageId}&farmSeasonCropId=${farmSeasonCropId}`,
            method: 'DELETE',
        });

    public static delete = async <T extends OperationTypeT>(
        farmId: number,
        farmSeasonId: number,
        operationType: T,
        tillageId?: number | string,
        farmSeasonCropId?: number | string,
    ): Promise<AxiosResponse<DeleteFarmSeasonOperationApiT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/operations/${operationType}?tillageId=${tillageId}&farmSeasonCropId=${farmSeasonCropId}`,
            method: 'DELETE',
        });
}

export default farmSeasonOperationApiService;
