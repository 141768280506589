import { useModal } from '@soil-capital/ui-kit';
import { useState } from 'react';
import ModalForgottenFields from './ModalForgottenFields';
import { UseModalForgottenFieldsPropsT } from './ModalForgottenFields.types';

const useModalForgottenFields = ({ modalType }: UseModalForgottenFieldsPropsT) => {
    const [forgottenFieldNames, setForgottenFieldNames] = useState<string[]>([]);
    const [totalFieldsCount, setTotalFieldsCount] = useState<number | null>(null);
    const modalForgottenFieldsController = useModal();

    const openModal = async ({
        forgottenFieldNames,
        fieldsCount,
        saveCallback,
    }: {
        forgottenFieldNames: string[];
        saveCallback: () => void | Promise<void>;
        fieldsCount: number;
    }) => {
        setForgottenFieldNames(forgottenFieldNames);
        setTotalFieldsCount(fieldsCount);
        return modalForgottenFieldsController.openModal(async () => {
            await saveCallback();
        });
    };

    return {
        ModalForgottenFields: (
            <ModalForgottenFields
                modalType={modalType}
                modalForgottenFieldsController={modalForgottenFieldsController}
                forgottenFieldNames={forgottenFieldNames}
                totalFieldsCount={totalFieldsCount}
            />
        ),
        modalForgottenFieldsController: {
            openModal,
        },
    };
};

export default useModalForgottenFields;
