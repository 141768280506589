import { api2 } from '@api/api2.service';
import {
    FieldBodyT,
    FieldEndpointParamsT,
    FieldT,
    SaveMergedFieldRequestT,
    SaveMergedFieldResponseT,
    SaveSplittedFieldRequestT,
    SaveSplittedFieldResponseT,
} from './fieldEntity.types';
import { EntityState } from '@reduxjs/toolkit';
import fieldEntityCache, { fieldCacheAdapter } from './fieldEntity.cache';
import fieldCropEntityCache from '../fieldCropEntity/fieldCropEntity.cache';

// TODO temporary update global field reducer to keep the state sync

const route = (fsId: number) => `/farm-seasons/${fsId}/fields`;

export const fieldApi = api2.injectEndpoints({
    endpoints: (build) => ({
        getField: build.query<EntityState<FieldT>, FieldEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: route(farmSeasonId),
                method: 'GET',
            }),
            transformResponse: (response: FieldT[]) =>
                fieldCacheAdapter.setAll(fieldCacheAdapter.getInitialState(), response),
        }),

        createField: build.mutation<FieldT, FieldEndpointParamsT & { body: FieldBodyT }>({
            query: ({ body, farmSeasonId }) => ({
                url: route(farmSeasonId),
                method: 'POST',
                body,
            }),
            onQueryStarted: fieldEntityCache.add,
        }),

        updateField: build.mutation<FieldT, FieldEndpointParamsT & { body: FieldBodyT; id: number }>({
            query: ({ body, farmSeasonId, id }) => ({
                url: `${route(farmSeasonId)}/${id}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: fieldEntityCache.update,
        }),

        deleteField: build.mutation<void, FieldEndpointParamsT & { id: number }>({
            query: ({ farmSeasonId, id }) => ({
                url: `${route(farmSeasonId)}/${id}`,
                method: 'DELETE',
            }),
            onQueryStarted: fieldEntityCache.remove,
        }),

        /* --------------------------------- ACTIONS -------------------------------- */
        saveMergedField: build.mutation<SaveMergedFieldResponseT, SaveMergedFieldRequestT>({
            query: ({ body, farmSeasonId }) => ({
                url: `/farm-seasons/${farmSeasonId}/fields/save-merged-field`,
                method: 'POST',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                const { data: mergedFieldResp } = await apiContext.queryFulfilled;

                fieldEntityCache.add({ farmSeasonId, body: mergedFieldResp.field_created }, apiContext, 'cache-only');

                mergedFieldResp.fields_deleted.forEach((id) => {
                    fieldEntityCache.remove({ farmSeasonId, id }, apiContext, 'cache-only');
                });

                mergedFieldResp.field_crops_created.forEach((fc) => {
                    fieldCropEntityCache.add({ farmSeasonId, body: fc }, apiContext, 'cache-only');
                });
            },
        }),

        saveSplittedField: build.mutation<SaveSplittedFieldResponseT, SaveSplittedFieldRequestT>({
            query: ({ body, farmSeasonId }) => ({
                url: `/farm-seasons/${farmSeasonId}/fields/save-splitted-field`,
                method: 'POST',
                body,
            }),
            onQueryStarted: async ({ farmSeasonId }, apiContext) => {
                const { data: splittedFieldResp } = await apiContext.queryFulfilled;

                splittedFieldResp.fields_created.forEach((f) => {
                    fieldEntityCache.add({ farmSeasonId, body: f }, apiContext, 'cache-only');
                });

                fieldEntityCache.remove(
                    { farmSeasonId, id: splittedFieldResp.field_deleted },
                    apiContext,
                    'cache-only',
                );

                splittedFieldResp.field_crops_created.forEach((fc) => {
                    fieldCropEntityCache.add({ farmSeasonId, body: fc }, apiContext, 'cache-only');
                });
            },
        }),
    }),
});

export default {
    useGet: fieldApi.useGetFieldQuery,
    useCreate: fieldApi.useCreateFieldMutation,
    useUpdate: fieldApi.useUpdateFieldMutation,
    useDelete: fieldApi.useDeleteFieldMutation,
    useSaveMerged: fieldApi.useSaveMergedFieldMutation,
    useSaveSplited: fieldApi.useSaveSplittedFieldMutation,
};
