import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Button, Typography, useTheme } from '@mui/material';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Prompt } from '@components/Prompt/Prompt';
import { Trans } from 'react-i18next';

import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useSelectFieldsViewStyle from './SelectFieldsView.style';
import { SelectFieldsViewPropsT } from './SelectFieldsTypes';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import { ModalWarning } from '@soil-capital/ui-kit';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const IrrigatedFieldsSelectView = ({
    deleteAll,
    modalDeleteField,
    fieldLoading,
    formik,
    isSubmitted,
    modalDeleteSelection,
    onSelectedFieldDelete,
    onSelectAll,
    t,
    pushStep,
}: SelectFieldsViewPropsT): JSX.Element => {
    const { classes } = useSelectFieldsViewStyle();
    const theme = useTheme();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <>
            <div className={classes.container}>
                <Prompt
                    messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                    messageText={t('modal.navigation-prompt-if-unsaved.text')}
                    when={!isSubmitted}
                />
                <Typography className={classes.title} color="secondary" variant="h4">
                    <Trans i18nKey="pages.installationtasks.fields-subtasks.agroforestry.select-on-map">
                        Veuillez sélectionner <span style={{ color: theme.palette.secondary.dark }}>sur la carte</span>
                        les parcelles avec de l&apos;agroforestrie
                    </Trans>
                    <HelpButtonView id="pages.installationtasks.fields-subtasks.agroforestry.select-on-map" />
                </Typography>
                <SelectedFieldsContainer
                    disabled={!canEditFarmSeason.access}
                    handleRemoveSelectedPolygon={onSelectedFieldDelete}
                    getCanNotDeleteSelected={({ polygon }) =>
                        polygon.properties.previous_has_agroforestry
                            ? 'tooltip.field-has-tree-and-can-not-be-deselected'
                            : null
                    }
                />
                <LabelCheckboxView
                    disabled={!canEditFarmSeason.access}
                    border="dotted"
                    checked={!formik.values.has_agroforestry}
                    error={formik.touched.has_agroforestry && Boolean(formik.errors.has_agroforestry)}
                    helperText={formik.touched.has_agroforestry ? formik.errors.has_agroforestry : undefined}
                    label={t('pages.installationtasks.fields-subtasks.agroforestry.no-agroforestry')}
                    name="agroforestry-checkbox"
                    onChange={(event, checked) => {
                        if (formik.values.fields.length) {
                            modalDeleteSelection.openModal(() => {
                                deleteAll?.();
                                formik.setFieldValue('has_agroforestry', !checked);
                            });
                        } else {
                            formik.setFieldValue('has_agroforestry', !checked);
                        }
                    }}
                />
                <TwoButtonFooterView
                    leftOnClick={onSelectAll}
                    leftText={canEditFarmSeason.access ? t('constants.select-all-fields') : ''}
                    rightButtonOverride={
                        canEditFarmSeason.access ? (
                            <LoadingButton
                                loading={fieldLoading}
                                onClick={() => formik.handleSubmit()}
                                text={t('constants.save')}
                            />
                        ) : (
                            <Button onClick={() => pushStep(2)}>{t('constants.continue')}</Button>
                        )
                    }
                />
            </div>
            <ModalWarning
                controller={modalDeleteSelection}
                confirmMessage={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.confirm-message')}
                description={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.title')}
            />
            <ModalWarning
                controller={modalDeleteField}
                confirmMessage={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.confirm-message')}
                description={t('pages.installationtasks.fields-subtasks.irrigation-task.delete-irrigated-field')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.title')}
            />
        </>
    );
};

export default IrrigatedFieldsSelectView;
