import {
    PostFarmSeasonFieldsTillagePracticeHistoryDataT,
    PostFarmSeasonFieldsTillagePracticeHistoryResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillagePracticeHistoryTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsTillagePracticeHistoryApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsTillagePracticeHistoryDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsTillagePracticeHistoryResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/tillage-practice-history`,
            method: 'POST',
            data: body,
        });
}

export default farmSeasonFieldsTillagePracticeHistoryApiService;
