import FarmSeasonService from '@services/farmSeasonApiService/farmSeasonApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonInstallationConfirmPostCallBackT,
    FarmSeasonPostInstallationConfirmDispatchTypesT,
    PostFarmSeasonInstallationConfirmDataT,
    PostFarmSeasonInstallationConfirmResponseDataT,
    PostFarmSeasonInstallationConfirmResponseErrorT,
    POST_FARM_SEASON_INSTALLATION_CONFIRM_FAILED,
    POST_FARM_SEASON_INSTALLATION_CONFIRM_LOADING,
    POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS,
} from './FarmSeasonPostInstallationConfirmTypes';

export const postFarmSeasonInstallationConfirm =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonInstallationConfirmDataT,
    ): FarmSeasonInstallationConfirmPostCallBackT =>
    async (
        dispatch: Dispatch<FarmSeasonPostInstallationConfirmDispatchTypesT>,
    ): Promise<PostFarmSeasonInstallationConfirmResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_INSTALLATION_CONFIRM_LOADING });
            const res: AxiosResponse<PostFarmSeasonInstallationConfirmResponseDataT> =
                await FarmSeasonService.postConfirm(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_INSTALLATION_CONFIRM_FAILED,
                error: error as PostFarmSeasonInstallationConfirmResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
