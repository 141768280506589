import { ProgressBarPropsT } from './ProgressBar.types';
import ProgressBarView from './ProgressBarView';

const ProgressBar = ({ currentStep, totalSteps, color, className, height }: ProgressBarPropsT): JSX.Element => {
    const progression = (currentStep / totalSteps) * 100;

    return <ProgressBarView className={className} color={color} height={height} progression={progression} />;
};

export default ProgressBar;
