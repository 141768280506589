import { makeStyles } from 'tss-react/mui';

const useTillagePracticeHistoryViewStyle = makeStyles()((theme) => ({
    errorYearLabour: {
        color: theme.palette.error.light,
        marginTop: '10px',
    },
    defineConventional: {
        color: theme.palette.primary.light,
        fontFamily: theme.typography.h1.fontFamily,
        whiteSpace: 'nowrap',
    },

    gridRow: {},
    inputContainer: {},
}));

export default useTillagePracticeHistoryViewStyle;
