import { makeStyles } from 'tss-react/mui';

const useModalPaymentDoneStyle = makeStyles()(() => ({
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));

export default useModalPaymentDoneStyle;
