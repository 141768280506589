import { countryGet } from '@actions/StaticActions/StaticAction';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import findById from '@utils/findById';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SummaryFarmLogicPropsT } from './SummaryFarmTypes';

const useSummaryFarmLogic = ({ farmSeasonField }: SummaryFarmLogicPropsT) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const seasonList = useSelector((state: HomePageStateT) => state.static.seasons);
    const countryList = useSelector((state: HomePageStateT) => state.static.countries);

    useEffect(() => {
        dispatch(countryGet());
    }, []);

    const harvestYear = findById(farmSeason?.season_id ?? 0, seasonList || [])?.harvest_year;
    const country = t(findById(Number(farm?.country_id), countryList)?.translation_slug ?? '');

    const totalArea = farmSeasonField.fieldsList?.reduce((total, field) => field.area + total, 0);

    return { harvestYear, country, farm, totalArea };
};

export default useSummaryFarmLogic;
