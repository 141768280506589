import { useEffect, useState } from 'react';
import useProgressLinearStyle from '@components/ProgressLinear/ProgressLinear.style';
import { ProgressLinearViewPropsT } from '@components/ProgressLinear/ProgressLinearTypes';
import { LinearProgress, Typography } from '@mui/material';

/* eslint-disable react/jsx-no-comment-textnodes */
const ProgressLinearView = ({ label, percentage }: ProgressLinearViewPropsT): JSX.Element => {
    const { classes } = useProgressLinearStyle();

    const [pct, setPct] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPct(percentage);
        }, 900);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={classes.root}>
            <Typography className={classes.text} component="small">
                {label}
            </Typography>
            <LinearProgress className={classes.bar} color="success" value={pct} variant="determinate" />
        </div>
    );
};

export default ProgressLinearView;
