import { Card, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import useFarmStyle from './Farm.style';
import { useNavigate } from 'react-router-dom';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import { HomePageStateT } from '../types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import IconFarm from '@icons/icon-farm.svg';
import { LocationOn } from '@mui/icons-material';
import { Person } from '@mui/icons-material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { useMemo } from 'react';
import MapIcon from '@mui/icons-material/Map';
import { Metric } from '@ui-kit';
import Co2Icon from '@mui/icons-material/Co2';
import IconSeed from '@icons/icon-seed.svg';
import WaterIcon from '@mui/icons-material/Water';
import NatureIcon from '@mui/icons-material/Nature';
import useTranslationSlugs from '@hooks/useTranslationSlugs';
import { FarmSummaryTranslationT } from './Farm.types';
import useInitialFetch from '@hooks/useInitialFetch';
import AnalyticsApiService from '@services/analyticsApiService/analyticsApiService';
import LoadingSection from '@components/LoadingSection/LoadingSection';
import IconEco from '@icons/icon-eco.svg';
import IconWaterDrop from '@icons/icon-water_drop.svg';
import IconRye from '@icons/icon-rye.svg';
import CardFlowNavigationContainer from '@components/CardFlowNavigationContainer/CardFlowNavigationContainer';
import formatNumber from '@utils/formatNumber';

const Farm = () => {
    const { classes } = useFarmStyle();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmSeasonList = useSelector((state: HomePageStateT) => state.farmSeason.farmSeasonList);
    const addressConcatenated = useMemo(
        () =>
            `${farm.address[0].toUpperCase()}${farm.address.slice(1)}, ${
                farm.postal_code
            } ${farm.city[0].toUpperCase()}${farm.city.slice(1)}, ${farm.country.name}`,
        [farm],
    );
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);

    const baselineFarmSeason = farmSeasonList.find((fs) => fs.baseline_farm_season_id === null);
    const baselineSeasonYear = currentFarm.is_v1
        ? '2020'
        : baselineFarmSeason?.created_at
        ? new Date(baselineFarmSeason.created_at).getFullYear()
        : '-';

    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const currentSeason = useSelector((state: HomePageStateT) =>
        state.static.seasons?.find((s) => s.id === currentFarmSeason?.season_id),
    );
    const { getText } = useTranslationSlugs<FarmSummaryTranslationT>('farm-dashboard.summary');

    const { data: farmSummaries, isLoading: farmSummariesLoading } = useInitialFetch(
        () => AnalyticsApiService.getFarmSummary({ farmId: farm.id }),
        [],
        [farm.id],
    );
    const currentFarmSummary = useMemo(
        () => farmSummaries?.find((farmSummary) => farmSummary.farm_season_id === currentFarmSeason.id),
        [farmSummaries, currentFarmSeason.season_id],
    );

    return (
        <div className={classes.root}>
            {/* ------------------------------- Page Header ------------------------------ */}
            <div className={classes.menuBar}>
                {/* needed for the justifyContent: 'space-between' */}
                <span></span>
                <Typography className={classes.menuBarTitle} color="secondary" variant="body2">
                    {t('farm-dashboard.layout.title')}
                </Typography>
                <div className={classes.closeLink}>
                    <TooltipSc
                        translationSlug={'farm-dashboard.layout.tooltip.edit-infos'}
                        display="flex"
                        className={classes.editIconWrapper}
                    >
                        <EditIcon className={`${classes.icons}`} onClick={() => navigate('/farm/edit')} />
                    </TooltipSc>
                    <CloseIcon className={classes.icons} onClick={() => navigate('/')} />
                </div>
            </div>
            {/* -------------------------------------------------------------------------- */}

            <CardFlowNavigationContainer cardSx={{ mb: 3 }} />

            {/* ------------------------------- Farm details ------------------------------ */}
            <Box sx={{ marginBottom: '1rem' }}>
                <Box className={classes.iconAndInfoRow}>
                    <IconFarm />
                    <Typography variant="body2" display="inline">
                        {farm.name}
                    </Typography>
                </Box>
                <Box className={classes.iconAndInfoRow}>
                    <LocationOn />
                    <Typography variant="body2" display="inline">
                        {addressConcatenated}
                    </Typography>
                </Box>
                <Box className={classes.iconAndInfoRow}>
                    <CalendarTodayOutlined />
                    <Typography variant="body2" display="inline">
                        {farmSeasonList.length === 1 && !currentFarm.is_v1
                            ? getText('program-progress-baseline', { programStartYear: baselineSeasonYear })
                            : getText('program-progress', {
                                  programStartYear: baselineSeasonYear,
                                  pastSeasonsNumber: currentFarm.is_v1
                                      ? farmSeasonList.length + 1
                                      : farmSeasonList.length,
                              })}
                    </Typography>
                </Box>
                {currentFarmSeason.agronomist && (
                    <Box className={classes.iconAndInfoRow}>
                        <Person />
                        <Typography variant="body2" display="inline">
                            {getText('agronomist', { lastKnownAgronomist: currentFarmSeason.agronomist })}
                        </Typography>
                    </Box>
                )}
            </Box>

            {/* ------------------------------ Farm Summary ------------------------------ */}
            <Card sx={{ padding: '1rem' }} elevation={0}>
                <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
                    {getText('season-title', { harvestYear: currentSeason?.harvest_year ?? '-' })}
                </Typography>
                <LoadingSection loading={farmSummariesLoading}>
                    <Box sx={{ marginBottom: '1.5rem' }}>
                        <Metric
                            boxedIconProps={{ icon: <MapIcon /> }}
                            bigNumberUnitProps={{
                                value: formatNumber(currentFarmSummary?.total_area_ha, 2) ?? '-',
                                unit: typeof currentFarmSummary?.total_area_ha === 'number' ? 'ha' : '',
                            }}
                            label={getText('area', {})}
                            tooltipScProps={{
                                text: getText('tooltip.area', {
                                    permanentCropsArea:
                                        typeof currentFarmSummary?.total_area_ha === 'number'
                                            ? formatNumber(
                                                  currentFarmSummary.total_area_ha -
                                                      (currentFarmSummary.cultivated_area_ha ?? 0),
                                                  2,
                                              )
                                            : '-',
                                }),
                            }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '1.5rem' }}>
                        <Metric
                            boxedIconProps={{ icon: <IconRye /> }}
                            bigNumberUnitProps={{
                                value: formatNumber(currentFarmSummary?.cultivated_field_count) ?? '-',
                            }}
                            label={getText('fields', { count: currentFarmSummary?.cultivated_field_count })}
                            tooltipScProps={{ text: getText('tooltip.fields', {}) }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '1.5rem' }}>
                        <Metric
                            boxedIconProps={{ icon: <Co2Icon /> }}
                            bigNumberUnitProps={{
                                value: formatNumber(currentFarmSummary?.emission_ton_co2_over_ha, 2) ?? '-',
                                unit: typeof currentFarmSummary?.emission_ton_co2_over_ha === 'number' ? 't/ha' : '',
                            }}
                            label={getText('carbon-results-ha', {})}
                            tooltipScProps={{
                                text: getText('tooltip.carbon-results-ha', {
                                    carbonResultsTotal:
                                        formatNumber(currentFarmSummary?.carbon_results_total, 2) ?? '-',
                                    farmTotalArea: formatNumber(currentFarmSummary?.total_area_ha, 2) ?? '-',
                                }),
                            }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '1.5rem' }}>
                        <Metric
                            boxedIconProps={{
                                icon: <IconSeed />,
                            }}
                            bigNumberUnitProps={{
                                value:
                                    currentFarmSummary?.crop_count === 0 ||
                                    typeof currentFarmSummary?.crop_count !== 'number'
                                        ? '-'
                                        : formatNumber(currentFarmSummary?.cultivated_crop_count) ?? '-',
                            }}
                            label={getText('crops', { count: currentFarmSummary?.crop_count })}
                            tooltipScProps={{ text: getText('tooltip.crops', {}) }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '1.5rem' }}>
                        <Metric
                            boxedIconProps={{ icon: <IconEco /> }}
                            bigNumberUnitProps={{
                                value: formatNumber(currentFarmSummary?.organic_area_percent, 0) ?? '-',
                                unit: typeof currentFarmSummary?.organic_area_percent === 'number' ? '%' : '',
                            }}
                            label={getText('organic', {})}
                            tooltipScProps={{
                                text: getText('tooltip.organic', {
                                    count: currentFarmSummary?.organic_field_count,
                                    organicFieldsArea: formatNumber(currentFarmSummary?.organic_area_ha, 2) ?? '',
                                    organicFieldsNumber: currentFarmSummary?.organic_field_count ?? '',
                                }),
                            }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '1.5rem' }}>
                        <Metric
                            boxedIconProps={{ icon: <IconWaterDrop /> }}
                            bigNumberUnitProps={{
                                value: formatNumber(currentFarmSummary?.irrigated_area_percent, 0) ?? '-',
                                unit: typeof currentFarmSummary?.irrigated_area_percent === 'number' ? '%' : '',
                            }}
                            label={getText('irrigation', {})}
                            tooltipScProps={{
                                text: getText('tooltip.irrigation', {
                                    count: currentFarmSummary?.irrigated_field_count,
                                    irrigatedFieldsArea: formatNumber(currentFarmSummary?.irrigated_area_ha, 2) ?? '-',
                                    irrigatedFieldsNumber: currentFarmSummary?.irrigated_field_count ?? '-',
                                }),
                            }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '1.5rem' }}>
                        <Metric
                            boxedIconProps={{ icon: <WaterIcon /> }}
                            bigNumberUnitProps={{
                                value: formatNumber(currentFarmSummary?.drained_area_percent, 0) ?? '-',
                                unit: typeof currentFarmSummary?.drained_area_percent === 'number' ? '%' : '',
                            }}
                            label={getText('drainage', {})}
                            tooltipScProps={{
                                text: getText('tooltip.drainage', {
                                    count: currentFarmSummary?.drained_field_count,
                                    drainedFieldsArea: formatNumber(currentFarmSummary?.drained_area_ha, 2) ?? '-',
                                    drainedFieldsNumber: currentFarmSummary?.drained_field_count ?? '-',
                                }),
                            }}
                        />
                    </Box>
                    <Box>
                        <Metric
                            boxedIconProps={{ icon: <NatureIcon /> }}
                            bigNumberUnitProps={{
                                value: formatNumber(currentFarmSummary?.agroforestry_area_percent, 0) ?? '-',
                                unit: typeof currentFarmSummary?.agroforestry_area_percent === 'number' ? '%' : '',
                            }}
                            label={getText('agroforestery', {})}
                            tooltipScProps={{
                                text: getText('tooltip.agroforestery', {
                                    count: currentFarmSummary?.agroforestry_field_count,
                                    agroforesteryFieldsArea:
                                        formatNumber(currentFarmSummary?.agroforestry_area_ha, 2) ?? '-',
                                    agroforesteryFieldsNumber: currentFarmSummary?.agroforestry_field_count ?? '-',
                                }),
                            }}
                        />
                    </Box>
                </LoadingSection>
            </Card>
        </div>
    );
};

export default Farm;
