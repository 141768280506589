import useAppDispatch from '@hooks/useAppDispatch';
import appActions from '@actions/AppActions/AppActions';
import { useEffect } from 'react';

import { PromptPropsT } from './PromptTypes';

const Prompt = ({ messageText, messageTitle, when }: PromptPropsT) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (when && messageTitle) {
            dispatch(appActions.setNavigatePromptMessage({ text: messageText, title: messageTitle }));
        }

        return () => {
            dispatch(appActions.setNavigatePromptMessage(null));
        };
    }, [messageTitle, when]);

    return null;
};

export { Prompt };
