import { makeStyles } from 'tss-react/mui';

const useSimpleListItemViewStyle = makeStyles()(() => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        borderRadius: '5px',
        marginBottom: '5px',
    },
    icon: {
        cursor: 'pointer',
        fontSize: '1.1em',
    },
}));

export default useSimpleListItemViewStyle;
