import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import { AxiosError } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFinalizeDispatchTypesT,
    FARM_SEASON_FINALIZE_FAILED,
    FARM_SEASON_FINALIZE_LOADING,
    FARM_SEASON_FINALIZE_SUCCESS,
} from './FarmSeasonFinalizeTypes';

export const farmSeasonFinalize =
    (farmId: number, farmSeasonId: number) =>
    async (dispatch: Dispatch<FarmSeasonFinalizeDispatchTypesT>): Promise<void> => {
        try {
            dispatch({ type: FARM_SEASON_FINALIZE_LOADING });
            const res = await FarmSeasonApiService.finalizeSeason(farmId, farmSeasonId);
            dispatch({
                type: FARM_SEASON_FINALIZE_SUCCESS,
                payload: res.data,
            });

            return;
        } catch (e) {
            const error = e as AxiosError<string>;
            dispatch({
                type: FARM_SEASON_FINALIZE_FAILED,
                error: { message: error.response?.data || error.message },
            });
        }
    };

export default farmSeasonFinalize;
