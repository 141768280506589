// import { deleteFarmSeasonCrop } from '@actions/FarmSeasonCropActions/DeleteFarmSeasonCrop';
import { useModal } from '@soil-capital/ui-kit';
import useOperationDone from '@hooks/useOperationDone';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import cropsGet from '@store/actions/StaticActions/CropsGetAction';
import { CropT } from '@entities/cropEntity/cropEntity.types';
import fieldEntity from '@entities/fieldEntity';
import cropEntity from '@entities/cropEntity';

const useCropsLogic = () => {
    const [stepArray, setStepArray] = useState<number[]>([1]);
    const [currentStep, setCurrentStep] = useState<number>(stepArray[stepArray.length - 1]);
    const [selectedCrop, setSelectedCrop] = useState<CropT | null>(null);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);

    const { getFieldsCountForCrop } = fieldEntity.useState({ farmSeasonId }).fieldState.useGetFieldsCountForCrop();

    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmId = currentFarm.id;
    const dispatch = useAppDispatch();
    const modalDeleteCrop = useModal();
    const controllerWarningOperationDone = useModal();
    const controllerWarningHasPermanentField = useModal();
    const { getHasAnyOperationDone } = useOperationDone();
    const [deleteCrop] = cropEntity.useDelete();

    useEffect(() => {
        dispatch(cropsGet(Number(currentFarm?.country_id)));
    }, []);

    useEffect(() => {
        setCurrentStep(stepArray[stepArray.length - 1]);
    }, [stepArray]);

    const pushStep = (step: number) => {
        setStepArray((state) => [...state, step]);
    };

    const popStep = () => {
        setStepArray((state) => [...state.splice(0, state.length - 1)]);
    };

    const hasPermanentFields = (farmSeasonCropId: number) => {
        return fields.find(
            (field) =>
                field.field_crops?.find((fieldCrop) => fieldCrop.farm_season_crop.id === farmSeasonCropId) &&
                field.is_permanent,
        );
    };

    const addOrModifyCrop = (crop: CropT | null): void => {
        if (crop && hasPermanentFields(crop.id)) {
            controllerWarningHasPermanentField.openModal();
            return;
        }

        setSelectedCrop(crop);
        pushStep(2);
    };

    const onCropDelete = async (id: number) => {
        if (getHasAnyOperationDone({ farmSeasonCropId: id })) {
            controllerWarningOperationDone.openModal();
        } else if (hasPermanentFields(id)) {
            controllerWarningHasPermanentField.openModal();
        } else if (getFieldsCountForCrop({ cropId: id }) && farmId && farmSeasonId) {
            let fetch: Promise<unknown> | null = null;
            await modalDeleteCrop.openModal(() => {
                fetch = deleteCrop({ farmSeasonId, id });
            });
            await fetch;
        } else if (farmId && farmSeasonId) {
            await deleteCrop({ farmSeasonId, id });
        }
    };

    return {
        addOrModifyCrop,
        controllerWarningOperationDone,
        controllerWarningHasPermanentField,
        currentStep,
        modalDeleteCrop,
        popStep,
        selectedCrop,
        onCropDelete,
    };
};

export default useCropsLogic;
