import { makeStyles } from 'tss-react/mui';

const useSelectFieldsSortStyle = makeStyles()(() => ({
    sortIcon: {
        marginRight: '10px',
    },
    SelectFieldsSort: {
        '.MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            background: 'white',
        },
    },
}));

export default useSelectFieldsSortStyle;
