import { makeStyles } from 'tss-react/mui';

const useSelectFieldStepViewStyle = makeStyles()((theme) => ({
    container: {},
    fieldItem: {},
    titleDarker: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
    subtitles: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    fieldContainer: {
        minHeight: '260px',
        padding: '10px',
        backgroundColor: 'rgba(237,200,128,0.15)',
        marginBottom: '30px',
    },
    emptyFieldContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '240px',
    },
}));

export default useSelectFieldStepViewStyle;
