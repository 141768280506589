import { makeStyles } from 'tss-react/mui';

const useRelationsStepViewStyle = makeStyles()((theme) => ({
    container: {},
    subtitle: {
        marginBottom: '20px',
    },
    listTitle: {
        marginBottom: '5px',
    },
    listContainer: {
        marginBottom: '20px',
    },
    dark: {
        color: theme.palette.secondary.dark,
    },
}));

export default useRelationsStepViewStyle;
