import { FarmSeasonProductPlanT, ProductPlanT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

export const SUBSCRIPTION_SAVE_PRODUCT_PLAN_SUCCESS = 'SUBSCRIPTION_SAVE_PRODUCT_PLAN_SUCCESS';

export type SubscriptionSaveProductPlanDataT = {
    farmSeasonProductPlanId: number;
    productPlanId: ProductPlanT['id'];
    farmId: number;
    farmSeasonId: number;
};

export type SubscriptionSaveProductPlanSuccessDataT = {
    farmSeasonProductPlan: FarmSeasonProductPlanT;
};

export type SubscriptionSaveProductPlanSuccessT = {
    type: typeof SUBSCRIPTION_SAVE_PRODUCT_PLAN_SUCCESS;
    payload: SubscriptionSaveProductPlanSuccessDataT;
};

export type SubscriptionSaveProductPlanActionsT = SubscriptionSaveProductPlanSuccessT;
