import { makeStyles } from 'tss-react/mui';

const useButtonSocialStyle = makeStyles()(() => ({
    button: {
        display: 'flex',
        '& svg': {
            height: '35px',
            width: '35px',
            margin: '-20px',
        },
    },
    buttonText: {
        flex: '1 1 100%',
        marginLeft: '40px',
    },
    containerRounded: {
        borderRadius: '10px',
    },
}));

export default useButtonSocialStyle;
