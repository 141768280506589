import { useModal } from '@soil-capital/ui-kit';
import { useEffect } from 'react';
import { CertificatesGeneratedModalLogicPropsT } from './CertificatesGeneratedModal.types';
import useAppNavigate from '@hooks/useAppNavigate';
import { celebrationApi } from '@api/celebration.api';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import getMinCertificatesEarningAmount from '@utils/getMinCertificatesEarningAmount';
import FarmSeasonProductPlanApiService from '@services/productPlanApiService/productPlanApiService';
import useInitialFetch from '@hooks/useInitialFetch';
import SegmentService from '@services/segmentService/segmentService';

const useCertificatesGeneratedModalLogic = ({ onReadCelebration }: CertificatesGeneratedModalLogicPropsT) => {
    const controller = useModal();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason.id);

    const { data: previousPlan, isLoading: previousPlanLoading } = useInitialFetch(
        () => FarmSeasonProductPlanApiService.getPreviousProductPlan({ farmId, farmSeasonId }),
        null,
        [farmSeasonId],
    );

    const {
        data: context,
        isLoading: contextLoading,
        isError,
    } = celebrationApi.useGetCertificatesGeneratedContextQuery({
        farmId,
        farmSeasonId,
    });

    const minEarningAmount =
        context && previousPlan
            ? getMinCertificatesEarningAmount({
                  certificatesCount: context.certificate_qty,
                  currencyIso: context.currency_iso,
                  previousProductPlanId: previousPlan.id,
              })
            : null;

    useEffect(() => {
        controller.openModal(onReadCelebration, onReadCelebration);
    }, []);

    const handleRedirection = () => {
        if (context?.has_iban) {
            navigate('/certificates');
        } else {
            navigate('/billing');
        }
        controller.onModalConfirm();
    };

    const onModalDisplayed = () => {
        SegmentService.certificateCelebrationModalDisplayedTrack({ iban_requested: !context?.has_iban });
    };

    return {
        handleRedirection,
        controller,
        certificatesQty: context?.certificate_qty,
        firstPaymentAmount: context?.first_payment_amount,
        firstPaymentCurrency: context?.first_payment_currency,
        minEarningAmount,
        hasIban: context?.has_iban,
        isLoading: contextLoading || previousPlanLoading,
        isError,
        onModalDisplayed,
        currencyIso: context?.currency_iso,
    };
};

export default useCertificatesGeneratedModalLogic;
