import { Grid, Typography } from '@mui/material';
import { Prompt } from '@components/Prompt/Prompt';
import { Trans, useTranslation } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useSelectFieldsStyle from './SelectFieldsView.style';
import { SelectFieldsViewPropsT } from './SelectFieldsTypes';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import useFieldSuggestion from '@pages/HomePage/Installation/hooks/useFieldSuggestion';
import { useSelector } from 'react-redux';
import selectIsYoySeason from '@store/selectors/selectIsYoySeason';
import CallToActionSuggestion from '@pages/HomePage/Installation/components/CallToActionSuggestion/CallToActionSuggestion';
import FieldAmountLabel from '@components/FieldsAmountLabelDeprecated/FieldAmountLabelDeprecated';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';

const SelectFieldsView = ({
    nothingToSave,
    selectedCrop,
    selectRemainingFields,
    onSelectedFieldDelete,
    formik,
    selectedFields,
}: SelectFieldsViewPropsT): JSX.Element => {
    const { classes } = useSelectFieldsStyle();
    const { t } = useTranslation();
    const { handleBringBackSuggestion, needAttentionFields, suggestedFields, suggestionDone } = useFieldSuggestion({
        suggestionType: 'residue',
        suggestionParam: selectedCrop?.crop.id?.toString(),
    });
    const isYoySeason = useSelector(selectIsYoySeason());
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!nothingToSave}
            />
            <Typography className={classes.groupedFieldTitle}>
                {t(selectedCrop?.crop.translation_slug || '')}
            </Typography>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.fields-subtasks.residue-task.select-field-title">
                    Veuillez ajouter <span className={classes.titleDark}>les parcelles avec un export de résidus</span>
                </Trans>
                <HelpButtonView id="pages.installationtasks.fields-subtasks.residue-task.select-field-title" />
            </Typography>

            {canEditFarmSeason.access && isYoySeason && (
                <CallToActionSuggestion
                    suggestion={{
                        done: suggestionDone,
                        needAttentionCount: needAttentionFields.length,
                        suggestedCount: suggestedFields.length,
                    }}
                    onClick={handleBringBackSuggestion}
                />
            )}

            <SelectedFieldsContainer
                disabled={!canEditFarmSeason.access}
                needAttentionFields={needAttentionFields}
                suggestedFields={suggestedFields}
                headerText={<FieldAmountLabel fields={selectedFields} />}
                handleRemoveSelectedPolygon={onSelectedFieldDelete}
            />

            <Grid className={classes.inputContainer} xs={12} item>
                <LabelTextField
                    disabled={!canEditFarmSeason.access}
                    endAdornment="t/ha"
                    error={formik.touched.residue && !!formik.errors.residue}
                    helperText={formik.touched.residue && formik.errors.residue}
                    id="residue"
                    label={t('pages.installationtasks.fields-subtasks.residue-task.select-field.amount-residue')}
                    name="irrigationDepth"
                    type="number"
                    value={formik.values.residue?.toString() || ''}
                    variant="outlined"
                    required
                    onChange={(event) => formik.setFieldValue('residue', +event.target.value)}
                />
            </Grid>

            <TwoButtonFooterView
                leftOnClick={selectRemainingFields}
                leftText={canEditFarmSeason.access ? t('constants.select-remaining-fields') : ''}
                rightButtonOverride={
                    <RestrictSaveFarmSeason>
                        <LoadingButton
                            disabled={!formik.isValid}
                            onClick={() => formik.handleSubmit()}
                            text={canEditFarmSeason.access ? t('constants.confirm') : t('constants.save')}
                        />
                    </RestrictSaveFarmSeason>
                }
            />
        </div>
    );
};

export default SelectFieldsView;
