import { Box, Typography } from '@mui/material';
import { ModalBase } from '@soil-capital/ui-kit';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import SegmentService from '@services/segmentService/segmentService';
import { useTranslation } from 'react-i18next';
import { ModalForgottenFieldsPropsT } from './ModalForgottenFields.types';

const forgottenTaskMap: Record<ModalForgottenFieldsPropsT['modalType'], 'SOM' | 'SOC' | 'acidity' | 'tillage' | 'crop'> = {
    'organic-matter': 'SOM',
    'organic-carbon': 'SOC',
    'soil-acidity': 'acidity',
    'tillage-practices': 'tillage',
    crops: 'crop',
};

const ModalForgottenFields = ({
    modalForgottenFieldsController,
    modalType,
    forgottenFieldNames,
    totalFieldsCount,
}: ModalForgottenFieldsPropsT) => {
    const { t } = useTranslation();
    const handleSave = () => {
        SegmentService.forgottenFieldsModalDisplayedTrack({
            action_result: 'save',
            forgotten_task: forgottenTaskMap[modalType],
            nb_fields_forgotten: forgottenFieldNames.length,
            nb_fields_total: totalFieldsCount ?? 0,
        });
        return modalForgottenFieldsController.onModalConfirm();
    };

    const handleCancel = () => {
        SegmentService.forgottenFieldsModalDisplayedTrack({
            action_result: 'cancel',
            forgotten_task: forgottenTaskMap[modalType],
            nb_fields_forgotten: forgottenFieldNames.length,
            nb_fields_total: totalFieldsCount ?? 0,
        });
        return modalForgottenFieldsController.onModalCancel();
    };
    return (
        <ModalBase width="560px" controller={modalForgottenFieldsController} showCloseButton>
            <ModalBaseLayout
                title={{ text: t('components.modal-forgotten-fields.title', { count: forgottenFieldNames.length }) }}
                confirmButton={{
                    text: t('components.modal-forgotten-fields.confirm-button', { count: forgottenFieldNames.length }),
                    onClick: handleCancel,
                }}
                cancelButton={{
                    text: t('components.modal-forgotten-fields.cancel-button'),
                    onClick: handleSave,
                }}
            >
                <Typography variant="body2">
                    {t(`components.modal-forgotten-fields.description-${modalType}`, {
                        count: forgottenFieldNames.length,
                    })}
                </Typography>
                <Box sx={{ margin: 3 }}>
                    {forgottenFieldNames.slice(0, 4).map((name, i) => (
                        <Typography key={i} variant="body2">
                            {name}
                        </Typography>
                    ))}
                    {forgottenFieldNames.slice(4).length > 0 && (
                        <Typography variant="body2">+ {forgottenFieldNames.slice(4).length}</Typography>
                    )}
                </Box>
            </ModalBaseLayout>
        </ModalBase>
    );
};

export default ModalForgottenFields;
