import { makeStyles } from 'tss-react/mui';

const useListStepStyle = makeStyles()(() => ({
    root: {},
    title: {
        marginBottom: '10px',
    },
    addButton: {
        marginTop: '10px',
    },
    listContainer: {},
}));

export default useListStepStyle;
