import { IconActionPropsT } from './IconActionTypes';
import { CircularProgress, IconButton as IconButtonMui } from '@mui/material';
import useIconActionStyle from './IconAction.style';
import useCallbackLoading from '@hooks/useCallbackLoading';
import TooltipSc from '../TooltipSc/TooltipSc';

const IconAction = ({ disabled = false, onClick, icon, TooltipProps }: IconActionPropsT) => {
    const { classes } = useIconActionStyle({ disabled });
    const { callback: handleIconClick, loading: iconLoading } = useCallbackLoading({ callback: onClick });

    return (
        <TooltipSc {...TooltipProps}>
            <IconButtonMui
                disableRipple
                disabled={!onClick || iconLoading || disabled}
                onClick={() => handleIconClick?.()}
                className={classes.icon}
            >
                {iconLoading ? <CircularProgress id="loader-icon" size={20} /> : icon}
            </IconButtonMui>
        </TooltipSc>
    );
};

export default IconAction;
