import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import { useContext, useEffect } from 'react';
// import palette from '@constants/palette/crop.palette';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import useMapFieldReset from '@hooks/useMapFieldReset';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useModal } from '@soil-capital/ui-kit';

import { Context } from '../OrganicFertilizationTaskContext/OrganicFertilizationTaskContext';
import { ContextT } from '../OrganicFertilizationTaskContext/OrganicFertilizationTaskContextTypes';

import FieldsGroupListView from './FieldsGroupListView';

type FieldsGroupListProps = {
    canEdit?: boolean;
};
const FieldsGroupList = ({ canEdit = false }: FieldsGroupListProps): JSX.Element => {
    const farmSeasonFields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const {
        t,
        formik,
        loading,
        groupedFields,
        setGroupedFields,
        setCurrentStep,
        nothingOnFields,
        setNothingOnFields,
        onSubmit,
        setSelectedGroup,
        setSelectedKey,
        keyColor,
        setGroupedFieldIndex,
        nothingToSave,
        setNothingToSave,
        errorNothingOnFields,
        setErrorNothingOnFields,
    } = useContext<ContextT>(Context);

    const resetMap = useMapFieldReset();
    const modalDeleteAllGroupedField = useModal();
    const modalDeleteGroupedField = useModal();

    // Put the color on the map
    useEffect(() => {
        // set color of the group
        const fieldsWithColorOnMap: FarmSeasonFieldT[] = [];
        Object.keys(groupedFields).forEach((key) => {
            groupedFields[key].forEach((field) => {
                fieldsWithColorOnMap.push({ ...field, mapLayerColor: keyColor[key] });
            });
        });

        // set color in not in a group
        farmSeasonFields?.forEach((field) => {
            if (!fieldsWithColorOnMap.find((f) => f.id === field.id)) {
                fieldsWithColorOnMap.push({
                    ...field,
                    mapLayerColor: field.field_crops?.some(
                        (field_crop) => field_crop.farm_season_crop.crop.is_permanent,
                    )
                        ? '#000000'
                        : '#ffffff',
                });
            }
        });

        MapService.addPolygons({
            polygons: fieldsWithColorOnMap.map((field) => addPolygonData({ field, withColor: true })),
        });

        // reset formik form
        formik.resetForm();

        // reset the map on unmount
        return () => {
            resetMap(true);
        };
    }, [groupedFields, keyColor]);

    // delete all groups
    const deleteOrganicFertilizationHistoryGroups = () => {
        setGroupedFields({});
        setNothingOnFields(true);
        setNothingToSave(false);
    };

    // add an organic fertilization group
    const addOrganicFertilization = () => {
        formik.resetForm();
        formik.setFieldValue('frequency', null);
        formik.setFieldValue('year_introduction', '');
        setSelectedGroup([]);
        setGroupedFieldIndex(Object.keys(groupedFields).length + 1);
        setNothingToSave(false);
        setCurrentStep(2);
    };

    // edit an organic fertilization group
    const editOrganicFertilization = (groupKey: string) => {
        const [year_introduction, frequency] = groupKey.split('-');
        setSelectedGroup(groupedFields[groupKey]);
        setSelectedKey(groupKey);
        setGroupedFieldIndex(Object.keys(groupedFields).indexOf(groupKey) + 1);
        formik.resetForm();
        formik.setFieldValue('year_introduction', year_introduction);
        formik.setFieldValue('frequency', frequency);
        setNothingToSave(!canEdit);
        setCurrentStep(2);
    };

    // delete an organic fertilization group
    const deleteOrganicFertilization = (groupKey: string) => {
        const newGroupedFields = { ...groupedFields };
        delete newGroupedFields[groupKey];
        setGroupedFields(newGroupedFields);
        setNothingToSave(false);
    };

    return (
        <FieldsGroupListView
            addOrganicFertilization={addOrganicFertilization}
            modalDeleteAllGroupedField={modalDeleteAllGroupedField}
            modalDeleteGroupedField={modalDeleteGroupedField}
            deleteOrganicFertilization={deleteOrganicFertilization}
            deleteOrganicFertilizationHistoryGroups={deleteOrganicFertilizationHistoryGroups}
            editOrganicFertilization={editOrganicFertilization}
            errorNothingOnFields={errorNothingOnFields}
            groupedFields={groupedFields}
            keyColor={keyColor}
            loading={loading}
            nothingOnFields={nothingOnFields}
            nothingToSave={nothingToSave}
            setErrorNothingOnFields={setErrorNothingOnFields}
            setNothingOnFields={setNothingOnFields}
            setNothingToSave={setNothingToSave}
            t={t}
            onSubmit={onSubmit}
            canEdit={canEdit}
        />
    );
};

export default FieldsGroupList;
