import CollapsableSectionView from '@components/CollapsableSection/CollapsableSectionView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import IconEdit from '@icons/icon-edit.svg';
import { Grid } from '@mui/material';
import { ModalWarning } from '@soil-capital/ui-kit';
import { useContext } from 'react';
import { FieldsContextStore } from '../FieldsContext/FieldsContext';
import { FieldsContextStoreT } from '../FieldsContext/FieldsContextTypes';

import useFieldNameAreaModal from '@components/FieldNameAreaModal/useFieldNameAreaModal';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import Restricted from '@components/Restricted/Restricted';
import { RestrictedPropsT } from '@components/Restricted/RestrictedTypes';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import useAppNavigate from '@hooks/useAppNavigate';
import useCallbackLoading from '@hooks/useCallbackLoading';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import usePropertyDelayedOnContentChange from '@hooks/usePropertyDelayedOnContentChange';
import useTotalAreaCheckModals from '@hooks/useTotalAreaCheckModals/useTotalAreaCheckModals';
import SidePageRightLayout from '@layouts/SidePageRightLayout/SidePageRightLayout';
import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import roundNumber from '@utils/roundNumber';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '../../types/HomePageStoreTypes';
import AgroforestryView from './Agroforestry/AgroforestryView';
import CoverCropHistoryView from './CoverCropHistory/CoverCropHistoryView';
import CropRelationsView from './CropRelations/CropRelationsView';
import { useDeleteFarmSeasonField } from './EditField.logic';
import { EditFieldViewPropsT } from './EditFieldTypes';
import useEditFieldStyle from './EditFieldView.style';
import IrrigationView from './Irrigation/IrrigationView';
import OrganicFertilizationHistoryView from './OrganicFertilizationHistory/OrganicFertilizationHistoryView';
import PastureHistoryView from './PastureHistory/PastureHistoryView';
import TillagePracticeHistoryView from './TillagePracticeHistory/TillagePracticeHistoryView';
import SegmentService from '@services/segmentService/segmentService';
import formatNumber from '@utils/formatNumber';
import { useIsCFTCarbonModel } from '@hooks/useIsCFTCarbonModel';

const EditFieldView = ({
    formik,
    warningOperationDone,
    controllerWarningOperationDone,
    selectedField,
    previousSeasonTreesMap,
    dontHavePermanentCrop,
    controllerWarningIsPermanent,
    warningPermanentCrop,
}: EditFieldViewPropsT): JSX.Element => {
    const { classes } = useEditFieldStyle();
    const navigate = useAppNavigate();
    const isBaseline = useSelector(selectIsBaselineSeason());
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm.id);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);
    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const { TotalAreaDecreaseModal, TotalAreaIncreaseModal, checkTotalAreaWithPreviousSeason } =
        useTotalAreaCheckModals({ farmId, farmSeasonId: currentFarmSeason.id });

    const { FieldNameAreaModal, fieldNameAreaModalController } = useFieldNameAreaModal();

    const isCFT = useIsCFTCarbonModel();

    const restrictedProps: RestrictedPropsT<'EDIT_FARM-SEASON'> = {
        toPermission: 'EDIT_FARM-SEASON',
        forResource: currentFarmSeason,
        withProps: () => ({ disabled: true }),
    };

    const { propDelayed: formikValuesDelayed, contentAnim } = usePropertyDelayedOnContentChange({
        msDelay: 300,
        propToDelay: formik.values,
        contentChangeRef: selectedField.id,
    });

    const {
        t,
        soilAcidityList,
        soilAcidityLoading,
        soilOrganicMatterList,
        soilOrganicMatterLoading,
        tillagePracticeList,
        tillagePracticeLoading,
    } = useContext<FieldsContextStoreT>(FieldsContextStore);

    const {
        deleteFarmSeasonField,
        deleteModalController,
        deleteNotLinkedModalController,
        controllerWarningAnyOperationCreated,
        modalDeleteHasTree,
        modalIsPermanentController,
    } = useDeleteFarmSeasonField({
        controllerWarningOperationDone,
    });

    const handleSaveField = async () => {
        const hasErrors = !!Object.keys(await formik.validateForm()).length;
        await formik.submitForm();

        if (hasErrors) {
            setTimeout(() => {
                const scrollSection = document.querySelector('#SidePageRightLayout');
                const element = document.querySelector('.Mui-error');
                if (!element || !scrollSection) {
                    return;
                }
                const offsetY = -100;
                scrollSection.scrollTo({
                    top:
                        element.getBoundingClientRect().top -
                        (scrollSection.getBoundingClientRect().top - scrollSection.scrollTop) +
                        offsetY,
                    behavior: 'smooth',
                });
            });
        } else {
            navigate('/fields');

            if (!isBaselineSeason) {
                const { userStopped } = await checkTotalAreaWithPreviousSeason();
                if (userStopped) {
                    return;
                }
            }
        }
    };
    const handleEditFieldNameArea = async (field: FarmSeasonFieldT) => {
        if (!field.original_area) {
            throw Error('No original area');
        }

        SegmentService.fieldEditionClickedTrack({
            area_source: field.area_source,
            name: field.name,
            original_area: field.original_area,
            user_area: field.area === field.original_area ? null : field.area,
        });

        const fieldNameAreaValues = await fieldNameAreaModalController.openEditFieldModal({
            finalArea: field.area,
            name: field.name,
            originalArea: field.original_area,
            areaSource: field.area_source,
        });
        if (!fieldNameAreaValues) {
            return;
        }
        formik.setValues((values) => ({
            ...values,
            user_area: fieldNameAreaValues.userArea,
            name: fieldNameAreaValues.name,
        }));
    };

    const { callback: handleConfirmClick, loading: confirmButtonLoading } = useCallbackLoading({
        callback: handleSaveField,
    });

    return (
        <SidePageRightLayout
            animContentChange={{ changing: contentAnim.changing, ms: contentAnim.duration }}
            confirmOverride={
                <RestrictSaveFarmSeason>
                    <LoadingButton
                        sx={{ marginLeft: 5 }}
                        disabled={formik.submitCount > 0 && Object.keys(formik.errors).length > 0}
                        loading={confirmButtonLoading}
                        text={t('constants.save')}
                        onClick={() => handleConfirmClick()}
                    />
                </RestrictSaveFarmSeason>
            }
            cancelText={t('constants.cancel')}
            onCancelClick={() => navigate('/fields', { replace: true })}
            actionText={canEditFarmSeason.access ? t('pages.fields.edit-field.delete-this-field') : ''}
            onActionClick={() => deleteFarmSeasonField(selectedField)}
            displayPromptOnLeave={formik.dirty && !formik.isSubmitting}
            pageTitle={
                <Restricted
                    toPermission="EDIT_FARM-SEASON"
                    forResource={currentFarmSeason}
                    withProps={() => ({ fallback: <span>{formikValuesDelayed.name}</span> })}
                >
                    <span>
                        {formikValuesDelayed.name}
                        {` (${formatNumber(
                            roundNumber(formikValuesDelayed.user_area ?? formikValuesDelayed.original_area, {
                                round: 'down',
                            }),
                        )} ha) `}
                        <IconEdit
                            onClick={() => handleEditFieldNameArea(selectedField)}
                            className={classes.fieldNameEditIcon}
                        />
                    </span>
                </Restricted>
            }
        >
            <CropRelationsView
                formik={formik}
                warningOperationDone={warningOperationDone}
                warningPermanentCrop={warningPermanentCrop}
            />
            <div className={classes.divider} />
            <CollapsableSectionView
                sectionName={t('pages.fields.crop-relations.general-characteristics')}
                defaultCollapseState={true}
            >
                <Grid className={classes.gridRow} spacing={2} container>
                    {isBaseline && (
                        <Grid xs={12} lg={3} item>
                            <Restricted {...restrictedProps}>
                                <LabelAutocomplete
                                    error={
                                        formik.touched.soil_organic_matter_id && !!formik.errors.soil_organic_matter_id
                                    }
                                    getOptionLabel={(organicMatter) => organicMatter.name}
                                    helpName="pages.installationtasks.fields-subtasks.soil-organic-matters-task.select-step.title"
                                    helperText={
                                        formik.touched.soil_organic_matter_id
                                            ? formik.errors.soil_organic_matter_id
                                            : undefined
                                    }
                                    id="soil_organic_matter_id"
                                    label={t('component.simulationtool.farm.organic-matter-level')}
                                    loading={soilOrganicMatterLoading}
                                    options={soilOrganicMatterList}
                                    placeholder={t('constants.select')}
                                    value={
                                        soilOrganicMatterList?.some(
                                            (organicMatter) =>
                                                organicMatter.id ===
                                                parseInt(formikValuesDelayed.soil_organic_matter_id, 10),
                                        )
                                            ? Number(formikValuesDelayed.soil_organic_matter_id)
                                            : ''
                                    }
                                    required
                                    withHelp
                                    onChange={(organicMatterId) =>
                                        formik.setFieldValue('soil_organic_matter_id', organicMatterId)
                                    }
                                />
                            </Restricted>
                        </Grid>
                    )}
                    {isBaseline && (
                        <Grid xs={12} lg={3} item>
                            <Restricted {...restrictedProps}>
                                <LabelAutocomplete
                                    error={formik.touched.soil_acidity_id && !!formik.errors.soil_acidity_id}
                                    getOptionLabel={(soilAcidity) => soilAcidity.name}
                                    helpName="pages.installationtasks.fields-subtasks.soil-acidity-task.select-step.title"
                                    helperText={
                                        formik.touched.soil_acidity_id ? formik.errors.soil_acidity_id : undefined
                                    }
                                    id="soil_acidity_id"
                                    label={t('component.simulationtool.farm.ph-level')}
                                    loading={soilAcidityLoading}
                                    options={soilAcidityList}
                                    placeholder={t('constants.select', { count: 3 })}
                                    sortList={false}
                                    value={
                                        soilAcidityList?.some(
                                            (pH) => pH.id === parseInt(formikValuesDelayed.soil_acidity_id, 10),
                                        )
                                            ? Number(formikValuesDelayed.soil_acidity_id)
                                            : ''
                                    }
                                    required
                                    withHelp
                                    onChange={(soilAcidityId) => formik.setFieldValue('soil_acidity_id', soilAcidityId)}
                                />
                            </Restricted>
                        </Grid>
                    )}
                    {dontHavePermanentCrop && (
                        <>
                            {isCFT && (
                                <>
                                    <Grid xs={12} lg={3} item>
                                        <Restricted {...restrictedProps}>
                                            <LabelAutocomplete
                                                error={formik.touched.tillage_id && !!formik.errors.tillage_id}
                                                helpName="pages.installationtasks.fields-subtasks.tillages-task.select-step.title"
                                                helperText={
                                                    formik.touched.tillage_id ? formik.errors.tillage_id : undefined
                                                }
                                                id="tillage_id"
                                                label={t('pages.fields.edit-field.cultural-practice')}
                                                loading={tillagePracticeLoading}
                                                options={tillagePracticeList}
                                                placeholder={t('constants.select')}
                                                value={
                                                    tillagePracticeList?.some(
                                                        (tillage) =>
                                                            tillage.id === parseInt(formikValuesDelayed.tillage_id, 10),
                                                    )
                                                        ? Number(formikValuesDelayed.tillage_id)
                                                        : ''
                                                }
                                                required
                                                withHelp
                                                onChange={(tillageId) => {
                                                    selectedField?.id && warningOperationDone({})
                                                        ? formik.setFieldValue(
                                                              'tillage_id',
                                                              formikValuesDelayed.tillage_id,
                                                          )
                                                        : formik.setFieldValue('tillage_id', tillageId);

                                                    formik.setFieldValue('is_tillage_permanent', false);
                                                }}
                                            />
                                        </Restricted>
                                        <Restricted {...restrictedProps}>
                                            <LabelCheckboxView
                                                className={classes.permanentTillageCheckbox}
                                                noMargin
                                                checked={formikValuesDelayed.is_tillage_permanent}
                                                label={t(
                                                    'pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.label-is-permanent-tillage',
                                                )}
                                                onChange={(event) =>
                                                    formik.setFieldValue('is_tillage_permanent', event.target.checked)
                                                }
                                                withHelp
                                                helpName="pages.installationtasks.fields-subtasks.tillages-task.select-step.select-field-step.label-is-permanent-tillage"
                                            ></LabelCheckboxView>
                                        </Restricted>
                                    </Grid>
                                    <Grid xs={0} lg={4} item />
                                    <Grid xs={12} item>
                                        <Restricted {...restrictedProps}>
                                            <LabelCheckboxView
                                                checked={!!formikValuesDelayed.is_organic}
                                                containerClass={classes.checkboxContainer}
                                                error={formik.touched.is_organic && !!formik.errors.is_organic}
                                                helpName="pages.installationtasks.fields-subtasks.organic-task.title"
                                                helperText={
                                                    formik.touched.is_organic ? formik.errors.is_organic : undefined
                                                }
                                                label={t('pages.fields.edit-field.this-field-is-organic')}
                                                noMargin
                                                withHelp
                                                onChange={(event) =>
                                                    formik.setFieldValue('is_organic', event.target.checked)
                                                }
                                            />
                                        </Restricted>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Restricted {...restrictedProps}>
                                            <LabelCheckboxView
                                                checked={formikValuesDelayed.has_cover_crops}
                                                containerClass={classes.checkboxContainer}
                                                error={
                                                    formik.touched.has_cover_crops && !!formik.errors.has_cover_crops
                                                }
                                                helpName="pages.installationtasks.fields-subtasks.cover-crop-task.title"
                                                helperText={
                                                    formik.touched.has_cover_crops
                                                        ? formik.errors.has_cover_crops
                                                        : undefined
                                                }
                                                label={t('pages.fields.edit-field.this-field-has-cover-crops')}
                                                noMargin
                                                withHelp
                                                onChange={(event) =>
                                                    formik.setFieldValue('has_cover_crops', event.target.checked)
                                                }
                                            />
                                        </Restricted>
                                    </Grid>
                                </>
                            )}
                            <Grid xs={12} item>
                                <Restricted {...restrictedProps}>
                                    <LabelCheckboxView
                                        checked={formikValuesDelayed.has_drainage}
                                        containerClass={classes.checkboxContainer}
                                        error={formik.touched.has_drainage && !!formik.errors.has_drainage}
                                        helpName="pages.installationtasks.fields-subtasks.drainage-task.title"
                                        helperText={
                                            formik.touched.has_drainage ? formik.errors.has_drainage : undefined
                                        }
                                        label={t('pages.fields.edit-field.this-field-is-drained')}
                                        noMargin
                                        withHelp
                                        onChange={(event) => formik.setFieldValue('has_drainage', event.target.checked)}
                                    />
                                </Restricted>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CollapsableSectionView>
            {dontHavePermanentCrop && (
                <>
                    <div className={classes.divider} />
                    <IrrigationView formik={formik} disabledForm={!canEditFarmSeason.access} />
                    <div className={classes.divider} />
                    <AgroforestryView
                        formik={formik}
                        previousSeasonTreesMap={previousSeasonTreesMap}
                        disabledForm={!canEditFarmSeason.access}
                        selectedField={selectedField}
                    />
                    {isBaseline && (
                        <>
                            <div className={classes.divider} />
                            <TillagePracticeHistoryView formik={formik} disabledForm={!canEditFarmSeason.access} />
                            <div className={classes.divider} />
                            <CoverCropHistoryView formik={formik} disabledForm={!canEditFarmSeason.access} />
                            <div className={classes.divider} />
                            <OrganicFertilizationHistoryView formik={formik} disabledForm={!canEditFarmSeason.access} />
                            <div className={classes.divider} />
                            <PastureHistoryView formik={formik} disabledForm={!canEditFarmSeason.access} />
                        </>
                    )}
                </>
            )}
            {/* warning when update a field property related to operations when any operation is done */}
            <ModalWarning
                controller={controllerWarningOperationDone}
                description={t('modal.warning.field-linked-to-operations-give-error-on-edit')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
            {/* Used for cohort 4 farmers */}
            <ModalWarning
                controller={controllerWarningAnyOperationCreated}
                description={t('modal.warning.delete-field-with-operations.description')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.delete-field-with-operations.title')}
            />
            <ModalWarning
                description={t('pages.fields.delete-not-linked-modal.description')}
                leftButtonText={t('constants.cancel')}
                controller={deleteNotLinkedModalController}
                rightButtonText={t('constants.confirm')}
                title={t('pages.fields.delete-not-linked-modal.title')}
            />
            <ModalWarning
                description={t('pages.fields.modal.has-tree-and-can-not-be-deleted.description')}
                rightButtonText={t('constants.ok')}
                controller={modalDeleteHasTree}
                title={t('pages.fields.modal.has-tree-and-can-not-be-deleted.title')}
            />
            <ModalWarning
                description={t('pages.crops.delete-modal.permanent-crop.description')}
                rightButtonText={t('constants.ok')}
                controller={modalIsPermanentController}
                title={t('pages.crops.delete-modal.permanent-crop.title')}
            />
            <ModalWarning
                description={t('modal.warning.delete-crop.permanent-crop.description')}
                rightButtonText={t('constants.ok')}
                controller={controllerWarningIsPermanent}
                title={t('modal.warning.title')}
            />
            <ModalWarning
                confirmMessage={t('pages.fields.delete-modal.confirm-message')}
                description={t('pages.fields.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                controller={deleteModalController}
                rightButtonText={t('constants.confirm')}
                title={t('pages.fields.delete-modal.title')}
            />
            {/* Modla to edit field name and area (with margins) */}
            {FieldNameAreaModal}
            {TotalAreaDecreaseModal} {TotalAreaIncreaseModal}
        </SidePageRightLayout>
    );
};

export default EditFieldView;
