import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useWarningMessageViewStyle = makeStyles()((theme) => ({
    container: {
        backgroundColor: alpha(theme.palette.warning.light, 0.1),
        border: `1px solid ${alpha(theme.palette.warning.main, 0.6)}`,
        marginBottom: '10px',
        padding: '5px',
        borderRadius: '10px',
        color: theme.palette.warning.dark,
    },
    icon: {
        fontSize: '24px',
        marginRight: '10px',
    },
    typography: {
        display: 'flex',
        alignItems: 'center',
    },
    checkbox: {
        marginBottom: 0,
        paddingRight: '2px',
        paddingLeft: '2px',
        paddingBottom: '5px',
    },
}));

export default useWarningMessageViewStyle;
