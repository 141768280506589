import { useContext, useRef } from 'react';
import CollapsableSectionView from '@components/CollapsableSection/CollapsableSectionView';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Collapse, Grid } from '@mui/material';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';

import { FieldsContextStoreT } from '../../FieldsContext/FieldsContextTypes';
import { FieldsContextStore } from '../../FieldsContext/FieldsContext';

import { IrrigationViewPropsT } from './IrrigationViewTypes';
import useIrrigationStyle from './IrrigationView.style';

const IrrigationView = ({ formik, disabledForm }: IrrigationViewPropsT): JSX.Element => {
    const { classes } = useIrrigationStyle();

    const {
        t,
        irrigationMethodList,
        irrigationMethodLoading,
        irrigationWaterSourceList,
        irrigationWaterSourceLoading,
        irrigationPowerSourceList,
        irrigationPowerSourceLoading,
    } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const sectionRef = useRef<HTMLDivElement>(null);

    return (
        <CollapsableSectionView
            defaultCollapseState={true}
            helpName="pages.installationtasks.fields-subtasks.irrigation-task.select-irrigated-fields"
            sectionName={t('pages.installationsubtasks.irrigation')}
            sectionRef={sectionRef}
        >
            <>
                <Grid className={classes.gridRow} spacing={2} container>
                    <Grid xs={12} item>
                        <LabelCheckboxView
                            disabled={disabledForm}
                            checked={!!formik.values.irrigation?.is_irrigated}
                            error={formik.touched.irrigation?.is_irrigated && !!formik.errors.irrigation?.is_irrigated}
                            helperText={
                                formik.touched.irrigation?.is_irrigated
                                    ? formik.errors.irrigation?.is_irrigated
                                    : undefined
                            }
                            label={t('pages.fields.edit-field.this-field-is-irrigated')}
                            noMargin
                            onChange={(event) => {
                                formik.setFieldValue('irrigation.is_irrigated', event.target.checked);
                                event.target.checked &&
                                    setTimeout(() => sectionRef.current?.scrollIntoView({ behavior: 'smooth' }), 400);
                            }}
                        />
                    </Grid>
                </Grid>
                <Collapse in={formik.values.irrigation?.is_irrigated} timeout="auto" unmountOnExit>
                    <Grid className={classes.gridRow} spacing={2} container>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <LabelAutocomplete
                                disabled={disabledForm}
                                error={
                                    formik.touched.irrigation?.irrigation_method_id &&
                                    !!formik.errors.irrigation?.irrigation_method_id
                                }
                                helperText={
                                    formik.touched.irrigation?.irrigation_method_id
                                        ? formik.errors.irrigation?.irrigation_method_id
                                        : undefined
                                }
                                id="irrigation.irrigation_method_id"
                                label={t('pages.installationtasks.fields-subtasks.irrigation-task.irrigation-method')}
                                loading={irrigationMethodLoading}
                                options={irrigationMethodList}
                                value={
                                    formik.values.irrigation?.irrigation_method_id !== '' &&
                                    formik.values.irrigation?.irrigation_method_id !== undefined
                                        ? Number(formik.values.irrigation?.irrigation_method_id)
                                        : ''
                                }
                                required
                                onChange={(irrigationMethodId) =>
                                    formik.setFieldValue('irrigation.irrigation_method_id', irrigationMethodId)
                                }
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <LabelAutocomplete
                                disabled={disabledForm}
                                error={
                                    formik.touched.irrigation?.irrigation_water_source_id &&
                                    !!formik.errors.irrigation?.irrigation_water_source_id
                                }
                                helperText={
                                    formik.touched.irrigation?.irrigation_water_source_id
                                        ? formik.errors.irrigation?.irrigation_water_source_id
                                        : undefined
                                }
                                id="irrigation.irrigation_water_source_id"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-water-source',
                                )}
                                loading={irrigationWaterSourceLoading}
                                options={irrigationWaterSourceList}
                                value={
                                    formik.values.irrigation?.irrigation_water_source_id !== '' &&
                                    formik.values.irrigation?.irrigation_water_source_id !== undefined
                                        ? Number(formik.values.irrigation?.irrigation_water_source_id)
                                        : ''
                                }
                                required
                                onChange={(waterSourceId) =>
                                    formik.setFieldValue('irrigation.irrigation_water_source_id', waterSourceId)
                                }
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <LabelAutocomplete
                                disabled={disabledForm}
                                error={
                                    formik.touched.irrigation?.irrigation_power_source_id &&
                                    !!formik.errors.irrigation?.irrigation_power_source_id
                                }
                                helperText={
                                    formik.touched.irrigation?.irrigation_power_source_id
                                        ? formik.errors.irrigation?.irrigation_power_source_id
                                        : undefined
                                }
                                id="irrigation.irrigation_power_source_id"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-power-source',
                                )}
                                loading={irrigationPowerSourceLoading}
                                options={irrigationPowerSourceList}
                                value={
                                    formik.values.irrigation?.irrigation_power_source_id !== '' &&
                                    formik.values.irrigation?.irrigation_power_source_id !== undefined
                                        ? Number(formik.values.irrigation?.irrigation_power_source_id)
                                        : ''
                                }
                                required
                                onChange={(powerSourceId) =>
                                    formik.setFieldValue('irrigation.irrigation_power_source_id', powerSourceId)
                                }
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <LabelTextField
                                disabled={disabledForm}
                                InputProps={{ inputProps: { min: 0 } }}
                                endAdornment="m"
                                error={
                                    formik.touched.irrigation?.pumping_depth &&
                                    !!formik.errors.irrigation?.pumping_depth
                                }
                                helperText={
                                    formik.touched.irrigation?.pumping_depth && formik.errors.irrigation?.pumping_depth
                                }
                                id="irrigation.pumping_depth"
                                label={t('pages.installationtasks.fields-subtasks.irrigation-task.irrigation-depth')}
                                name="irrigation.pumping_depth"
                                type="number"
                                value={formik.values.irrigation?.pumping_depth}
                                variant="outlined"
                                fullWidth
                                required
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <LabelTextField
                                disabled={disabledForm}
                                InputProps={{ inputProps: { min: 0 } }}
                                endAdornment="m"
                                error={
                                    formik.touched.irrigation?.horizontal_distance &&
                                    !!formik.errors.irrigation?.horizontal_distance
                                }
                                helpName="irrigationHorizontalDistance-help"
                                helperText={
                                    formik.touched.irrigation?.horizontal_distance &&
                                    formik.errors.irrigation?.horizontal_distance
                                }
                                id="irrigation.horizontal_distance"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-horizontal-distance',
                                )}
                                name="irrigation.horizontal_distance"
                                type="number"
                                value={formik.values.irrigation?.horizontal_distance}
                                variant="outlined"
                                fullWidth
                                required
                                withHelp
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <LabelTextField
                                disabled={disabledForm}
                                InputProps={{ inputProps: { min: 0 } }}
                                endAdornment="mm"
                                error={
                                    formik.touched.irrigation?.total_amount && !!formik.errors.irrigation?.total_amount
                                }
                                helpName="irrigationWaterQuantity-help"
                                helperText={
                                    formik.touched.irrigation?.total_amount && formik.errors.irrigation?.total_amount
                                }
                                id="irrigation.total_amount"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-water-quantity',
                                )}
                                name="irrigation.total_amount"
                                type="number"
                                value={formik.values.irrigation?.total_amount}
                                variant="outlined"
                                fullWidth
                                required
                                withHelp
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
            </>
        </CollapsableSectionView>
    );
};

export default IrrigationView;
