import { FarmSeasonT } from '@store/reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { AxiosError } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonOpenNextFarmSeasonDispatchTypesT,
    FARM_SEASON_OPEN_NEXT_FARM_SEASON_FAILED,
    FARM_SEASON_OPEN_NEXT_FARM_SEASON_LOADING,
    FARM_SEASON_OPEN_NEXT_FARM_SEASON_SUCCESS,
} from './FarmSeasonOpenNextFarmSeasonTypes';
import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';

export const farmSeasonOpenNextFarmSeason =
    (farmId: number, farmSeasonId: number) =>
    async (dispatch: Dispatch<FarmSeasonOpenNextFarmSeasonDispatchTypesT>): Promise<FarmSeasonT | false> => {
        try {
            dispatch({ type: FARM_SEASON_OPEN_NEXT_FARM_SEASON_LOADING });
            const res = await FarmSeasonApiService.openNextFarmSeason(farmId, farmSeasonId);
            dispatch({
                type: FARM_SEASON_OPEN_NEXT_FARM_SEASON_SUCCESS,
                payload: { nextFarmSeason: res.data },
            });

            return res.data;
        } catch (e) {
            const error = e as AxiosError<string>;
            dispatch({
                type: FARM_SEASON_OPEN_NEXT_FARM_SEASON_FAILED,
                error: { message: error.response?.data || error.message },
            });

            return false;
        }
    };

export default farmSeasonOpenNextFarmSeason;
