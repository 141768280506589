import FarmSeasonFieldsIrrigationApiService from '@services/farmSeasonFieldsIrrigationApiService/farmSeasonFieldsIrrigationApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsIrrigationPostCallBackT,
    PostFarmSeasonFieldsIrrigationDataT,
    PostFarmSeasonFieldsIrrigationDispatchTypesT,
    PostFarmSeasonFieldsIrrigationResponseDataT,
    PostFarmSeasonFieldsIrrigationResponseErrorT,
    POST_FARM_SEASON_FIELDS_IRRIGATION_FAILED,
    POST_FARM_SEASON_FIELDS_IRRIGATION_LOADING,
    POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS,
} from './PostFarmSeasonFieldsIrrigationTypes';

const postFarmSeasonFieldsIrrigation =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsIrrigationDataT,
    ): FarmSeasonFieldsIrrigationPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsIrrigationDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsIrrigationResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_IRRIGATION_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsIrrigationResponseDataT> =
                await FarmSeasonFieldsIrrigationApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_IRRIGATION_FAILED,
                error: error as PostFarmSeasonFieldsIrrigationResponseErrorT,
            });

            return {};
        }
    };

export default postFarmSeasonFieldsIrrigation;
