import { useRef, useState } from 'react';
import { BillingDetailsFormRefControllerT } from '../BillingDetailsForm.types';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useStartUpsellMutation } from '@api/upsell.api';

/**
 * This hook is explicitly designed to be used with `EditBillingDetailsForm.tsx`
 */
const useBillingDetailsUpsellFlow = () => {
    const billingDetailsFormRef = useRef<BillingDetailsFormRefControllerT>(null);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const [startUpsell] = useStartUpsellMutation();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    /** This is the definition of the logic that will happen after the submit */
    const onSubmitForm = async () => {
        setIsSubmitting(true);
        const data = await billingDetailsFormRef.current?.getFormData();

        if (data) {
            try {
                await startUpsell({ farmId, farmSeasonId, body: data });
                setIsSubmitting(false);
                return true;
            } catch (e) {
                billingDetailsFormRef.current?.displayError('constants.server-error');
                setIsSubmitting(false);
                return false;
            }
        }
        setIsSubmitting(false);
        return false;
    };

    return { billingDetailsFormRef, onSubmitForm, isSubmitting };
};

export default useBillingDetailsUpsellFlow;
