import { useRef } from 'react';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Pagination, Typography } from '@mui/material';
import { ModalWarning } from '@soil-capital/ui-kit';
import IconHourglass from '@components/IconHourglass/IconHourglass';
import FullPageLayout from '@/layouts/FullPageLayout/FullPageLayout';
import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

import useFarmerDataStyle from './FarmerDataView.style';
import SummaryField from './components/SummaryField/SummaryField';
import useFarmerDataLogic from './FarmerDataView.logic';
import SummaryFarm from './components/SummaryFarm/SummaryFarm';
import ModalPayment from './components/ModalPayment/ModalPayment';
import ModalPaymentDone from './components/ModalPaymentDone/ModalPaymentDone';
import ButtonValidatePage from './components/ButtonValidatePage/ButtonValidatePage';

const FarmerDataView = (): JSX.Element => {
    const { classes } = useFarmerDataStyle();
    const { t } = useTranslation();

    const dividerRef = useRef<HTMLHRElement>(null);

    const {
        farmSeason,
        farmSeasonField,
        irrigationMethodList,
        irrigationPowerSourceList,
        irrigationWaterSourceList,
        machineryList,
        pHList,
        pesticideList,
        soilOrganicMatterList,
        tillageChangeList,
        tillageList,
        treesList,
        handleFarmerValidateData,
        validateLoading,
        modalValidateErrorController,
        modalPaymentController,
        modalResultReadyMustPayController,
        modalResultComputingController,
        lastPage,
        currentFieldDisplayed,
        page,
        setPage,
    } = useFarmerDataLogic();

    const handlePaginationChange = (newPage: number) => {
        setPage(newPage);
        const layoutEl = document.getElementById('FullPageLayout');
        setTimeout(() => layoutEl?.scrollTo({ top: (dividerRef.current?.offsetTop ?? 0) + 2 }), 1);
    };

    const HeaderValidation = () => (
        <>
            <Typography className={classes.title} color="secondary" variant="h4">
                {t('pages.farmer-data.validation-title')}
            </Typography>
            <Typography color="secondary" variant="body1">
                {t('pages.farmer-data.validation-description')}
            </Typography>
        </>
    );

    const HeaderValidated = () => (
        <>
            <Typography className={classes.title} color="secondary" variant="h4">
                {t('pages.farmer-data.validated-title')}
            </Typography>
            <Typography color="secondary" variant="body1">
                {t('pages.farmer-data.validated-description')}
            </Typography>
        </>
    );

    return (
        <>
            <FullPageLayout>
                {farmSeason?.summary_validated_at ? <HeaderValidated /> : <HeaderValidation />}

                <Divider ref={dividerRef} className={classes.divider} />
                {page === 1 ? (
                    <SummaryFarm farmSeasonField={farmSeasonField} />
                ) : (
                    <SummaryField
                        field={currentFieldDisplayed}
                        irrigationMethodList={irrigationMethodList}
                        irrigationPowerSourceList={irrigationPowerSourceList}
                        irrigationWaterSourceList={irrigationWaterSourceList}
                        isLoading={farmSeasonField.loading}
                        machineryList={machineryList}
                        pHList={pHList}
                        pesticideList={pesticideList}
                        soilOrganicMatterList={soilOrganicMatterList}
                        tillageChangeList={tillageChangeList}
                        tillageList={tillageList}
                        treesList={treesList}
                    />
                )}
                <div className={classes.paginationValidationSection}>
                    <Card>
                        <Pagination
                            color="secondary"
                            count={lastPage}
                            page={page}
                            onChange={(e, newPage) => handlePaginationChange(newPage)}
                        />
                    </Card>

                    <ButtonValidatePage
                        currentPage={page}
                        farmSeason={farmSeason as FarmSeasonT}
                        isComputingResults={validateLoading}
                        lastPage={lastPage}
                        onValidateCrop={() => setPage(page + 1)}
                        onValidateFarmInfos={() => setPage(page + 1)}
                        onValidateWholeFarm={handleFarmerValidateData}
                    />
                    {farmSeason?.summary_validated_at &&
                        [0, 1].includes(farmSeason.partner_type) &&
                        !farmSeason.has_results_access &&
                        !farmSeason.one_payment && (
                            <LoadingButton
                                loading={false}
                                text={t('pages.farmer-data.access-results')}
                                type="button"
                                onClick={() =>
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    modalResultReadyMustPayController.openModal(modalPaymentController.openModal as any)
                                }
                            />
                        )}
                </div>
            </FullPageLayout>

            <ModalWarning
                controller={modalValidateErrorController}
                confirmMessage={t('pages.farmer-data.error-computing-carbon-results-text')}
                rightButtonText={t('constants.close')}
                title={t('pages.farmer-data.error-computing-carbon-results-title')}
            />
            <ModalWarning
                controller={modalResultComputingController}
                description={t('pages.farmer-data.results-computing-description')}
                rightButtonText={t('constants.close')}
                title={
                    <div className={classes.titleHourglass}>
                        {t('pages.farmer-data.results-computing-title')} <IconHourglass />
                    </div>
                }
            />
            <ModalWarning
                controller={modalResultReadyMustPayController}
                description={t('pages.farmer-data.results-ready-pay-description')}
                leftButtonText={t('constants.close')}
                rightButtonText={t('pages.farmer-data.results-ready-pay-button')}
                title={t('pages.farmer-data.results-ready-pay-title')}
            />
            <ModalPayment controller={modalPaymentController} />
            <ModalPaymentDone modalPaymentController={modalPaymentController} />
        </>
    );
};

export default FarmerDataView;
