import MenuBar from '@components/MenuBar/MenuBar';
import ProgressBar from '@components/ProgressBar/ProgressBar';

import CropListStep from './CropListStep/CropListStep';
import { CropsTaskViewPropsT } from './CropsTaskTypes';
import useCropsTaskStyle from './CropsTaskView.style';
import SelectStep from './SelectStep/SelectStep';

const CropsTaskView = ({
    currentStep,
    pushStep,
    handleBackButton,
    cropColorPalette,
    stepArray,
    popStep,
    t,
}: CropsTaskViewPropsT): JSX.Element => {
    const { classes } = useCropsTaskStyle();

    return (
        <div>
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={currentStep} totalSteps={3} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.add-crop-task.title')}
                    onArrowBack={stepArray.length > 1 ? () => handleBackButton() : '..'}
                    onClose=".."
                />

                {currentStep === 1 && <CropListStep pushStep={pushStep} />}
                {currentStep >= 2 && (
                    <SelectStep
                        cropColorPalette={cropColorPalette}
                        currentStep={currentStep}
                        popStep={popStep}
                        pushStep={pushStep}
                    />
                )}
            </div>
        </div>
    );
};

export default CropsTaskView;
