import {
    fetchMoreNotifications,
    fetchNotifications,
    fetchSilentNotifications,
    markAllNotificationAsRead,
    markNotificationAsRead,
} from '@actions/NotificationActions/NotificationActions';
import notificationConfig from '@constants/config/notifications.config';
import { NotificationT } from '@reducers/NotificationReducer/NotificationReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useNotificationContainerLogic = () => {
    const dispatch = useAppDispatch();

    /* ---------------------------------- State --------------------------------- */
    const [showOnlyUnread, setShowOnlyUnread] = useState(false);
    const [popoverAnchor, setPopoverAnchor] = useState<Element | null>(null);
    /* -------------------------------------------------------------------------- */

    /* --------------------------------- Fetches -------------------------------- */
    useEffect(() => {
        dispatch(fetchNotifications({ limit: notificationConfig.initialFetchLimit, unread: showOnlyUnread }));
        const intervalId = setInterval(() => {
            dispatch(fetchSilentNotifications({ limit: notificationConfig.initialFetchLimit, unread: showOnlyUnread }));
        }, notificationConfig.silentFetchInterval);

        return () => {
            clearInterval(intervalId);
        };
    }, [showOnlyUnread]);
    /* -------------------------------------------------------------------------- */

    /* -------------------------------- Selectors ------------------------------- */
    const unreadNotificationsCount = useSelector(
        (state: HomePageStateT) => state.notifications.unreadNotificationsCount,
    );
    const notificationsCount = useSelector((state: HomePageStateT) => state.notifications.notificationsCount);
    const notifications = useSelector((state: HomePageStateT) => state.notifications.notificationsList);
    const fetchMoreLoading = useSelector((state: HomePageStateT) => state.notifications.fetchMoreNotifLoading);
    const fetchLoading = useSelector((state: HomePageStateT) => state.notifications.fetchNotifLoading);
    const markAsReadLoadings = useSelector((state: HomePageStateT) => state.notifications.markNotifAsReadLoadings);
    const markAllAsReadLoading = useSelector((state: HomePageStateT) => state.notifications.markAllNotifAsReadLoading);
    /* -------------------------------------------------------------------------- */

    let canFetchMore = false;

    if (showOnlyUnread && !fetchLoading) {
        canFetchMore = unreadNotificationsCount > notifications.length;
    } else if (!fetchLoading) {
        canFetchMore = notificationsCount > notifications.length;
    }

    /* ----------------------------- handle functions ---------------------------- */
    const handleOpenPanel = (event: MouseEvent) => {
        setPopoverAnchor(event.currentTarget);
    };

    const handleClosePanel = () => {
        setPopoverAnchor(null);
    };

    const handleMarkAsRead = (e: MouseEvent, notification: NotificationT) => {
        e.stopPropagation();
        dispatch(markNotificationAsRead(notification));
    };

    const handleFetchMore = () => {
        dispatch(
            fetchMoreNotifications({
                limit: notificationConfig.fetchMoreLimit,
                offset: notifications.length,
                unread: showOnlyUnread,
            }),
        );
    };

    const handleMarkAllAsRead = () => {
        dispatch(markAllNotificationAsRead());
    };
    /* -------------------------------------------------------------------------- */

    return {
        unreadNotificationsCount,
        notificationsCount,
        showOnlyUnread,
        setShowOnlyUnread,
        notifications,
        fetchLoading,
        fetchMoreLoading,
        markAllAsReadLoading,
        markAsReadLoadings,
        canFetchMore,
        popoverAnchor,
        handleClosePanel,
        handleOpenPanel,
        handleMarkAsRead,
        handleFetchMore,
        handleMarkAllAsRead,
    };
};

export default useNotificationContainerLogic;
