import { makeStyles } from 'tss-react/mui';

const useResultViewStyle = makeStyles()((theme) => ({
    title: {},
    secondaryTitle: {
        color: theme.palette.secondary.dark,
        marginBottom: '10px',
    },
    date: {
        width: '100%',
        marginBottom: '10px',
        color: theme.palette.secondary.light,
    },
    introText: {
        fontSize: '16px',
        color: theme.palette.driftwood.light,
        '& strong': {
            backgroundColor: theme.palette.spanishWhite.light,
            padding: '2px',
        },
    },
    helpIcon: {
        position: 'absolute',
        top: '45px',
        right: '65px',
    },
    figure: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '170px',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
    },
    mainFigure: {
        padding: '20px',
        '& h4': {
            fontSize: '1.15rem',
        },
        '& img': {
            marginTop: '10px',
            width: '100%',
        },
    },
    innerFigure: {
        fontSize: '1.1em',
        color: theme.palette.primary.light,
        borderRadius: '8px',
        overflow: 'hidden',
        marginTop: '10px',
        '& .left': {
            padding: '30px 30px 10px',
            background: '#2f4445 url(/assets/images/award-background.png) no-repeat top left / cover',
        },
        '& .right': {
            padding: '20px',
            background: '#e6edf9 url(/assets/images/results-figure-pattern.png) no-repeat top left / cover',
            '& .value-align': {
                textAlign: 'left',
            },
        },
        '& .big-value': {
            fontSize: '24px',
        },
    },
    map: {
        backgroundColor: 'lightgray',
        width: '100%',
        height: '400px',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        border: '4px solid white',
        position: 'relative',
    },
    mapCenterCircle: {
        width: '400px',
        height: '400px',
        borderRadius: '50%',
        // border: '4px solid white',
        // backgroundColor: 'rgba(255,255,255,.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mapCenterCircleFake: {
        position: 'absolute',
        marginLeft: '400px',
        width: '350px',
        height: '350px',
        borderRadius: '50%',
        border: '4px solid white',
        backgroundColor: 'rgba(255,255,255,.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'transparent',
        pointerEvents: 'none',
    },
    mapCenterPoint: {
        width: '15px',
        height: '15px',
        border: '4px solid white',
        backgroundColor: theme.palette.driftwood.light,
        borderRadius: '50%',
    },
    mapMarker: {
        position: 'absolute',
        minWidth: '80px',
        marginTop: '-80px',
        padding: '4px 8px',
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bolder',
        fontSize: '12px',
        textAlign: 'center',
        color: theme.palette.driftwood.light,
        background: 'rgb(255, 255, 255)',
        border: '4px solid rgb(255, 255, 255)',
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 2px 0px',
        pointerEvents: 'none',
        '& .arrow': {
            position: 'absolute',
            width: 0,
            height: 0,
            borderWidth: '10px',
            borderStyle: 'solid',
            borderColor: '#FFFFFF transparent transparent transparent',
            bottom: '-21px',
            left: 'calc(50% - 10px)',
        },
    },
    mapChart: {
        borderRadius: '15px',
        backgroundColor: 'white',
        width: '42%',
        minHeight: '50%',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        position: 'absolute',
        left: '50px',
        padding: '30px',
    },
    mapChartBar: {
        overflow: 'hidden',
        width: '0%',
        animation: '1.5s expand 3s ease forwards',
        borderRadius: '5px',
        display: 'flex',
        marginBottom: '8px',
        '& .block': {
            display: 'flex',
            height: '50px',
            color: '#fff',
            fontWeight: 'bold',
            backgroundColor: '#334D5C',
            position: 'relative',
            overflow: 'hidden',
            opacity: 1,
            transition: '2s opacity 1s ease',
            textAlign: 'center',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    mapChartLegend: {
        minHeight: '40px',
        width: '100%',
        display: 'flex',
        '& .block': {
            display: 'flex',
            height: 'auto',
            color: theme.palette.driftwood.light,
            position: 'relative',
            opacity: 1,
            transition: 'opacity, .3s ease',
            textAlign: 'center',
            alignContent: 'center',
            alignItems: 'flex-start',
            justifyContent: 'center',
            fontSize: '90%',
            '& .value': {
                overflowWrap: 'normal',
                position: 'absolute',
                width: 'clamp(60px,100%,80px)',
                backgroundColor: 'white',
                padding: '0 1px',
                display: 'table',
            },
        },
    },
    tabContentContainer: {
        borderRadius: '0 0 15px 15px',
        padding: '30px 0',
        minHeight: '300px',
    },
    svgChart: {
        opacity: 0,
        transition: '.9s opacity .2s ease, transform .7s cubic-bezier(.7,.32,.18,1.13), background-color .2s ease',
        transform: 'perspective(400px) translate3d(0,30px,5px)',
        position: 'relative',
        borderRadius: '15px',
        backgroundColor: 'rgba(255,255,255,.3)',
        padding: '20px',
        width: '100%',
        marginBottom: '20px',
        '&.nobg': {
            padding: 0,
        },
        '&.nobg .animate': {
            backgroundColor: 'transparent !important',
            marginBottom: 0,
        },
        '& *': {
            pointerEvents: 'none',
            userSelect: 'none',
        },
        '& svg': {
            width: '100%',
        },
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.5)',
        },
        '& svg > rect': {
            fill: 'transparent !important',
        },
        '& svg g.ytick text, & svg .points .point': {
            opacity: '0 !important',
            transition: 'all .7s ease',
        },
        '&.animate svg g.ytick text, &.animate svg .points .point': {
            opacity: '1 !important',
        },
        '& svg g.ytick:nth-of-type(1) text, & svg .points .point:nth-of-type(1)': { transitionDelay: '.3s' },
        '& svg g.ytick:nth-of-type(2) text, & svg .points .point:nth-of-type(2)': { transitionDelay: '.4s' },
        '& svg g.ytick:nth-of-type(3) text, & svg .points .point:nth-of-type(3)': { transitionDelay: '.5s' },
        '& svg g.ytick:nth-of-type(4) text, & svg .points .point:nth-of-type(4)': { transitionDelay: '.6s' },
        '& svg g.ytick:nth-of-type(5) text, & svg .points .point:nth-of-type(5)': { transitionDelay: '.7s' },
        '& svg g.ytick:nth-of-type(6) text, & svg .points .point:nth-of-type(6)': { transitionDelay: '.8s' },
        '& svg g.ytick:nth-of-type(7) text, & svg .points .point:nth-of-type(7)': { transitionDelay: '.9s' },
        '& svg g.ytick:nth-of-type(8) text, & svg .points .point:nth-of-type(8)': { transitionDelay: '1s' },
        '& svg g.ytick:nth-of-type(9) text, & svg .points .point:nth-of-type(9)': { transitionDelay: '1.1s' },
        '& svg g.ytick:nth-of-type(10) text, & svg .points .point:nth-of-type(10)': { transitionDelay: '1.2s' },
        '& svg g.ytick:nth-of-type(11) text, & svg .points .point:nth-of-type(11)': { transitionDelay: '1.3s' },
        '& svg g.ytick:nth-of-type(12) text, & svg .points .point:nth-of-type(12)': { transitionDelay: '1.4s' },
        '& svg g.ytick:nth-of-type(13) text, & svg .points .point:nth-of-type(13)': { transitionDelay: '1.5s' },
        '& svg g.ytick:nth-of-type(14) text, & svg .points .point:nth-of-type(14)': { transitionDelay: '1.6s' },
        '& svg g.ytick:nth-of-type(15) text, & svg .points .point:nth-of-type(15)': { transitionDelay: '1.7s' },
    },
    graphText: {
        fontSize: '16px',
        color: theme.palette.driftwood.light,
    },
    hr: {
        height: '2px',
        backgroundColor: theme.palette.spanishWhiteShadow,
        margin: '20px 0 40px',
    },
    formControl: {
        width: '100%',
        transform: 'translateY(-10px)',
        '& .MuiSelect-icon': {
            width: '20px',
            overflow: 'visible',
        },
    },
    benchmarkTextCompare: {
        '& i': { color: '#ceab69' }, // benchmark farms
        '& strong': { color: '#416671' }, // your farm
    },
    wrongFarmCoordinates: {
        position: 'absolute',
        marginLeft: '400px',
    },
}));

export default useResultViewStyle;
