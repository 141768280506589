import { ModalWarning } from '@soil-capital/ui-kit';
import { useModal } from '@soil-capital/ui-kit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { keepController } from './ModalNoFieldsSuggestion.slice';

const ModalNoFieldsSuggestion = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const controller = useModal();

    useEffect(() => {
        dispatch(keepController(controller));

        return () => {
            dispatch(keepController(null));
        };
    }, []);

    return (
        <ModalWarning
            controller={controller}
            title={t('components.modal-no-fields-suggestion.title')}
            description={t('components.modal-no-fields-suggestion.description')}
            rightButtonText={t('components.modal-no-fields-suggestion.button-confirm')}
        />
    );
};

export default ModalNoFieldsSuggestion;
