/* eslint-disable no-param-reassign */

import { FieldArray, FormikProvider } from 'formik';
import InputRow from '@components/InputRow/InputRow';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';

import { OperationCardFormPropsT } from './OperationCardFormTypes';
import useOperationCardFormStyle from './OperationCardForm.style';

const OperationCardForm = ({ machineries, shouldAutoFocus, form, disabled }: OperationCardFormPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useOperationCardFormStyle();

    return (
        <Card className={classes.card} elevation={0} variant="elevation">
            <FormikProvider value={form}>
                <FieldArray
                    name="operations"
                    render={() => (
                        <>
                            {machineries?.map((operation, operationIndex) => (
                                <InputRow
                                    key={operation.id}
                                    TextFieldProps={{
                                        disabled,
                                        autoFocus: operationIndex === 0 && shouldAutoFocus,
                                        inputProps: {
                                            step: 1,
                                            min: 0,
                                        },
                                        InputProps: {
                                            endAdornment: t('constants.unit.passage', {
                                                count: form.values.operations?.[operationIndex]?.passage || undefined,
                                            }),
                                        },
                                        name: `operations[${operationIndex}].passage`,
                                        type: 'number',
                                        value: form.values.operations?.[operationIndex]?.passage?.toString() ?? '',
                                        variant: 'outlined',
                                        onChange: (event) => {
                                            if (+event.target.value <= 0) {
                                                event.target.value = '';
                                            } else {
                                                event.target.value = parseInt(event.target.value)?.toString();
                                            }
                                            form.handleChange(event);
                                        },
                                        onWheel: (e) => (e.target as HTMLInputElement).blur(),
                                    }}
                                    label={t(operation.translation_slug)}
                                />
                            ))}
                        </>
                    )}
                />
            </FormikProvider>
        </Card>
    );
};

export default OperationCardForm;
