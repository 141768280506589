import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import useErrorMessageStyle from './ErrorMessageView.style';
import { ErrorMessageViewPropsT } from './ErrorMessageViewTypes';

const ErrorMessageView = ({ message, className }: ErrorMessageViewPropsT): JSX.Element | null => {
    const { classes } = useErrorMessageStyle();

    return message ? (
        <div className={`${classes.container} ${className}`}>
            <ErrorOutlineIcon className={classes.icon} />
            <Typography className={classes.typography} textAlign="left">
                {message}
            </Typography>
        </div>
    ) : null;
};

export default ErrorMessageView;
