import IconDelete from '@icons/icon-delete.svg';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import { useAutoAnimate } from '@hooks/useAutoAnimate';
import MapService from '@services/mapService/mapService';
import { SuggestedFieldT } from '@services/onboardingApiService/onboardingApiServiceTypes';
import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { AppStateT } from '@store/store';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CardNameValueList from '../CardNameValueList/CardNameValueList';
import HeaderNameValueList from '../HeaderNameValueList/HeaderNameValueList';
import IconAction from '../IconButton/IconAction';
import SelectedFieldsHeader from '../SelectedFIeldsHeader/SelectedFieldsHeader';
import SelectedFieldsZone from '../SelectedFieldsZone/SelectedFieldsZone';
import useSelectedFieldsContainerStyle from './SelectedFieldsContainer.style';
import { SelectedFieldsContainerPropsT } from './SelectedFieldsContainerTypes';
import formatNumber from '@utils/formatNumber';

/** list of fields selected on the map. */
const SelectedFieldsContainer = ({
    headerText,
    needAttentionFields,
    suggestedFields,
    customMinHeight,
    fieldsListOverride,
    getAdditionalActions,
    getCanNotDeleteSelected,
    handleRemoveSelectedPolygon,
    subHeader,
    disabled,
}: SelectedFieldsContainerPropsT) => {
    const { t } = useTranslation();
    const { classes } = useSelectedFieldsContainerStyle();
    const map = useSelector((state: AppStateT) => MapService.getMapFromState({ state: state.map }));
    const farmSeasonFieldList =
        fieldsListOverride || useSelector((state: AppStateT) => state.farmSeasonField.fieldsList);
    const [internalNeedAttentionFields, setInternalNeedAttentionFields] = useState<SuggestedFieldT[]>([]);
    const [sortedFields, setSortedFields] = useState<FarmSeasonFieldT[]>([]);
    const [selectedFields, setSelectedFields] = useState<FarmSeasonFieldT[] | null>(null);
    const [internalSuggestedFields, setInternalSuggestedFields] = useState<SuggestedFieldT[]>();
    const animateParent = useRef(null);
    useAutoAnimate(animateParent);
    const sortableFieldData = useMemo(
        () => selectedFields?.map((f) => ({ entity: f, area: f.area, name: f.name })),
        [selectedFields],
    );

    // keep internal needAttentionFields to remove already selected fields as they don't need attention as they are already confirmed
    useEffect(() => {
        if (!needAttentionFields) {
            return;
        }
        setInternalNeedAttentionFields(
            needAttentionFields.filter(
                (attentionField) => !selectedFields?.find((selectedField) => selectedField.id === attentionField.id),
            ),
        );
    }, [needAttentionFields]);

    // keep internal suggestedFields to remove already selected fields as they are not magic anymore because selected by the user.
    useEffect(() => {
        if (!suggestedFields) {
            return;
        }
        // exclude already selecteds
        const internalSuggestedFields = suggestedFields.filter(
            (suggestedField) => !selectedFields?.find((selectedField) => selectedField.id === suggestedField.id),
        );
        setInternalSuggestedFields(internalSuggestedFields);
        addSelectedPolygons(
            // map for polygons
            internalSuggestedFields.map((sf) => ({
                ...(farmSeasonFieldList.find((fsf) => fsf.id === sf.id) as FarmSeasonFieldT),
            })),
        );
    }, [suggestedFields]);

    // Keep sync selected fields from the polygons on the map
    useEffect(() => {
        const newSelectedFields: FarmSeasonFieldT[] =
            (map.selectedPolygons
                ?.map((polygon) => {
                    const selectedField = farmSeasonFieldList?.find(
                        (field) => field.id === polygon.id,
                    ) as FarmSeasonFieldT;

                    // if we selected a field who need attention, remove it from the attention list.
                    if (
                        internalNeedAttentionFields?.find((attentionField) => attentionField.id === selectedField?.id)
                    ) {
                        removeAttentionField(selectedField);
                    }

                    return selectedField;
                })
                .filter((f) => !!f) as FarmSeasonFieldT[]) || [];

        setSelectedFields(newSelectedFields);
    }, [map.selectedPolygons, fieldsListOverride]);

    const isSuggestedField = useCallback(
        (f: FarmSeasonFieldT) => internalSuggestedFields?.find((suggestedField) => suggestedField.id === f.id),
        [internalSuggestedFields],
    );

    const removeSelectedPolygon = useCallback(
        (field: FarmSeasonFieldT) => {
            // external handling
            if (handleRemoveSelectedPolygon) {
                handleRemoveSelectedPolygon(field.id);
                return;
            }

            // remove the map polygon will update the field list.
            MapService.updateSelectedPolygons({
                selectedPolygons: map.selectedPolygons?.filter((polygon) => polygon.id !== field.id) || [],
            });
        },
        [handleRemoveSelectedPolygon, selectedFields],
    );

    const addSelectedPolygons = useCallback(
        (fields: (FarmSeasonFieldT | undefined)[]) => {
            const polygonsToAdd = (fields.filter((v) => !!v) as FarmSeasonFieldT[]).map((f) => f.polygon);

            MapService.updateSelectedPolygons({
                selectedPolygons: [...(map.selectedPolygons || []), ...polygonsToAdd]
                    // distinct id to avoid duplicates
                    .filter((polygon, i, arr) => arr.findIndex((p) => p.id === polygon.id) === i),
            });
        },
        [map.selectedPolygons],
    );

    const addAttentionFieldToSelected = (field: { id: number }) => {
        const fieldToMove = farmSeasonFieldList.find((f) => f.id === field.id);
        addSelectedPolygons([fieldToMove]);
        removeAttentionField(fieldToMove);
    };
    const removeAttentionField = (field?: { id: number }) => {
        if (field) {
            setInternalNeedAttentionFields((needAttentionFields) =>
                needAttentionFields.filter((attentionField) => attentionField.id !== field.id),
            );
        }
    };

    return (
        <div ref={animateParent}>
            {!!internalNeedAttentionFields?.length && (
                <>
                    <HeaderNameValueList nameHeader={t('components.selected-fields-container.need-attention-fields')} />
                    <SelectedFieldsZone minHeight="0" placeholderShown={false}>
                        <CardNameValueList
                            items={internalNeedAttentionFields.map((needAttentionField) => ({
                                ...needAttentionField,
                                id: needAttentionField.id,
                            }))}
                            getName={(f) => f.name}
                            getValue={(f) => `${formatNumber(f.area, 2)} ${t('constants.hectare-unit')}`}
                            getActionIcons={(f) => [
                                <IconAction
                                    key="add-field-action"
                                    icon={<CheckIcon />}
                                    onClick={() => addAttentionFieldToSelected(f)}
                                />,
                                <IconAction
                                    key="remove-field-action"
                                    icon={<ClearIcon />}
                                    onClick={() => removeAttentionField(f)}
                                />,
                            ]}
                        />
                    </SelectedFieldsZone>
                </>
            )}
            <SelectedFieldsHeader
                sortableData={sortableFieldData}
                headerText={headerText}
                onSortedFields={setSortedFields}
            />
            <SelectedFieldsZone
                placeholderShown={selectedFields?.length === 0}
                placeholderText={t('constants.no-field-selected')}
                minHeight={customMinHeight}
            >
                <CardNameValueList
                    header={subHeader}
                    items={sortedFields}
                    getName={(f) => (
                        <Typography variant="h6" className={classes.name}>
                            {f.name}
                            {isSuggestedField(f) ? <AutoFixHighIcon className={classes.icon} /> : null}
                        </Typography>
                    )}
                    getValue={(f) => `${formatNumber(f.area, 2)} ${t('constants.hectare-unit')}`}
                    getActionIcons={(f, i) => [
                        ...(getAdditionalActions?.(f.id, i) || []),
                        disabled ? null : (
                            <IconAction
                                key="delete-action"
                                icon={<IconDelete />}
                                disabled={!!getCanNotDeleteSelected?.(f)}
                                onClick={() => removeSelectedPolygon(f)}
                                TooltipProps={{
                                    translationSlug: getCanNotDeleteSelected?.(f),
                                    placement: 'right',
                                }}
                            />
                        ),
                    ]}
                />
            </SelectedFieldsZone>
        </div>
    );
};

export default SelectedFieldsContainer;
