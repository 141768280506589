import { makeStyles } from 'tss-react/mui';

const useSearchListWithBarViewStyle = makeStyles()((theme) => ({
    container: {
        minWidth: '500px',
        width: '600px',
    },
    searchBar: {
        marginBottom: '20px',
    },
    listWrapper: {
        borderRadius: '15px',
        minHeight: '300px',
        height: '500px',
        background: theme.palette.spanishWhite.light,
        overflow: 'hidden',
    },
    scroll: {
        height: '100%',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
    },
    list: {
        padding: '15px',
    },
    avatar: {
        width: '30px',
        height: '30px',
    },
    svg: {
        fontSize: '34px',
        color: theme.palette.primary.light,
    },
    loadingContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useSearchListWithBarViewStyle;
