import {
    PostFarmSeasonFieldAgroforestryValuesDataT,
    PostFarmSeasonFieldAgroforestryValuesResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldAgroforestryValuesTypes';
import request from '@services/apiService/apiService';
import { FarmSeasonFieldAgroforestryT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsAgroforestryValuesApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        farmSeasonFieldId: number,
        body: PostFarmSeasonFieldAgroforestryValuesDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldAgroforestryValuesResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/field/${farmSeasonFieldId}/agroforestry-values`,
            method: 'POST',
            data: body,
        });

    public static getFieldAgroforestry = async (
        farmId: number,
        farmSeasonId: number,
        farmSeasonFieldId: number,
    ): Promise<AxiosResponse<FarmSeasonFieldAgroforestryT[]>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/field/${farmSeasonFieldId}/agroforestry-values`,
            method: 'GET',
        });
}

export default farmSeasonFieldsAgroforestryValuesApiService;
