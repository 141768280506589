/* eslint-disable @typescript-eslint/no-explicit-any */
import { CONTRACT_DATE, CONTRACT_LANGUAGES } from '@/constants/terms-and-condition';
import logout from '@actions/AuthActions/LogoutActions';
import { useAuth0 } from '@auth0/auth0-react';
import AppVersionView from '@components/AppVersion/AppVersionView';
import TextLink from '@components/TextLink/TextLink';
import IconLogout from '@icons/icon-logout.svg';
import { Avatar, Button, CircularProgress, Menu, MenuItem, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import useNavProfileStyle, { avatarMenuStyle } from './NavProfile.style';
import { NavProfileViewPropsT } from './NavProfileTypes';

const NavProfileView = ({
    t,
    user,
    anchorEl,
    setAnchorEl,
    dispatch,
    translationLoading,
}: NavProfileViewPropsT): JSX.Element => {
    const { classes } = useNavProfileStyle();
    const { logout: logoutAuth0, isAuthenticated } = useAuth0();

    // Terms and Conditions link
    const { i18n } = useTranslation();
    const langKey = (CONTRACT_LANGUAGES as any).includes(i18n.language) ? i18n.language : CONTRACT_LANGUAGES[0];
    const termsAndConditionsPdfLink = `/terms-and-conditions/${CONTRACT_DATE}-${langKey}.pdf`;

    const openProfileMenu = () => {
        const menuAnchor = document.querySelector('#nav-avatar-menu-anchor');
        setAnchorEl(menuAnchor);
    };

    const avatarMenuPaperProps = {
        style: avatarMenuStyle.paper,
    };

    const closeAvatarMenu = () => setAnchorEl(null);

    const logoutAndRedirect = () => {
        if (isAuthenticated) {
            logoutAuth0({ returnTo: `${window.location.origin}/logout` });
        } else {
            dispatch(logout());
        }
    };

    return (
        <div className={classes.container}>
            {translationLoading && <CircularProgress className={classes.loadingLanguage} size={50} />}
            <div id="nav-avatar-wrapper">
                <Avatar className={classes.avatar} onClick={openProfileMenu} />
                <span id="nav-avatar-menu-anchor" />
            </div>
            <Menu
                PaperProps={avatarMenuPaperProps}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                className={classes.avatarMenu}
                id="simple-menu"
                open={Boolean(anchorEl)}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                disableAutoFocusItem
                keepMounted
                onClose={closeAvatarMenu}
            >
                <MenuItem className={classes.avatarMenuEmail} disableRipple>
                    {user?.email}
                </MenuItem>
                <hr className={classes.emailSeparation} />
                <MenuItem className={classes.avatarMenuLanguage} disableRipple>
                    <Typography className={classes.avatarMenuLanguageText}>
                        {t('pages.homepage.language')} :{' '}
                    </Typography>
                    <LanguageSelector />
                </MenuItem>
                <MenuItem className={classes.avatarMenuLogout} disableRipple>
                    <Button
                        className={classes.avatarMenuLogoutBtn}
                        fullWidth
                        variant="text"
                        onClick={logoutAndRedirect}
                    >
                        <SvgIcon className={classes.logoutIcon} component={IconLogout} viewBox="0 0 24 24" />
                        {t('pages.homepage.logout')}
                    </Button>
                </MenuItem>
                <hr className={classes.emailSeparation} />
                <TextLink
                    className={classes.tcsLink}
                    variant="body1"
                    textAlign="center"
                    onClick={() => window.open(termsAndConditionsPdfLink, '_blank')}
                >
                    {t('pages.homepage.terms-of-use')}
                </TextLink>
                <MenuItem className={classes.appVersion}>
                    <AppVersionView />
                </MenuItem>
            </Menu>
            <Typography className={classes.typography} color="primary" variant="h6" onClick={openProfileMenu}>
                {user?.first_name} {user?.last_name}
            </Typography>
        </div>
    );
};

export default NavProfileView;
