import { Paper, Typography } from '@mui/material';
import useSelectedFieldsZoneStyle from './SelectedFieldsZone.style';
import { SelectedFieldsZonePropsT } from './SelectedFieldsZoneTypes';

const SelectedFieldsZone = ({ children, placeholderText, placeholderShown, minHeight }: SelectedFieldsZonePropsT) => {
    const { classes } = useSelectedFieldsZoneStyle({ minHeight, placeholderShown });

    return (
        <Paper className={classes.SelectedFieldsZone} elevation={0}>
            <div className={classes.placeholder}>
                <Typography color="secondary" variant="body1">
                    {placeholderText}
                </Typography>
            </div>
            <div className={classes.children}>{children}</div>
        </Paper>
    );
};

export default SelectedFieldsZone;
