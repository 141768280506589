import { Dispatch } from '@reduxjs/toolkit';
import authService from '@services/authService/authService';
import { AxiosError } from 'axios';

import { ROLE_ID } from '@constants/roleIds';
import { appInitFarmsAndSeasons, appInitUserData } from '../AppActions/AppActions';
import { getUserPermissions } from '../AppActions/GetPermissions';
import {
    AUTHORIZE_AUTH0_FAILED,
    AUTHORIZE_AUTH0_LOADING,
    AUTHORIZE_AUTH0_SUCCESS,
    AuthorizeAuth0ActionDataT,
    AuthorizeAuth0DispatchTypesT,
    AuthorizeAuth0ResponseErrorT,
} from './AuthorizeAuth0ActionsTypes';

const authorizeAuth0Action =
    ({ token }: AuthorizeAuth0ActionDataT) =>
    async (dispatch: Dispatch<AuthorizeAuth0DispatchTypesT>): Promise<AuthorizeAuth0ResponseErrorT> => {
        try {
            dispatch({ type: AUTHORIZE_AUTH0_LOADING });

            const res = await authService.authorizeAuth0({
                token,
            });

            const userIsFarmer = res.data.user.role_id === ROLE_ID.FARMER;
            await Promise.all([
                getUserPermissions()(dispatch),
                userIsFarmer && appInitFarmsAndSeasons({ userId: res.data.user.id })(dispatch),
            ]);

            await dispatch(appInitUserData({ user: res.data.user }) as never);

            dispatch({
                type: AUTHORIZE_AUTH0_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (e) {
            const error = e as AxiosError<string>;

            dispatch({
                type: AUTHORIZE_AUTH0_FAILED,
                payload: {
                    error: {
                        message: error.response?.data || error.message,
                    },
                },
            });

            return {
                message: error.response?.data || error.message,
            };
        }
    };

export default authorizeAuth0Action;
