import { makeStyles } from 'tss-react/mui';

import { TextLinkStylePropsT } from './TextLinkTypes';

const useTextLinkStyle = makeStyles<TextLinkStylePropsT>()((theme, { loading, loaderPosition, textAlign }) => ({
    TextLink: {
        textAlign,
        opacity: loading ? 0.5 : 1,
        transition: 'all ease .3s',
        '&>p': {
            display: 'inline',
        },
    },
    loader: {
        color: theme.palette.primary.main,
        marginBottom: '-3px',
        marginRight: loaderPosition === 'left' ? '5px' : '-20px',
        marginLeft: loaderPosition === 'left' ? '-20px' : '5px',
    },
    text: {
        textDecoration: 'underline',
        cursor: loading ? 'default' : 'pointer',
    },
}));

export default useTextLinkStyle;
