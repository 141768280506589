/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import { FieldValuesToNumbers } from './NumbersToFieldValues';

/** update all '' values to null */
const emptyFieldValuesToNull = <T extends Record<string, any>>(formValues?: T) =>
    Object.keys(formValues || {}).reduce((nullableValues: Partial<T>, key: keyof T) => {
        nullableValues[key] = formValues?.[key] === '' ? undefined : formValues?.[key];

        return nullableValues;
    }, {}) as FieldValuesToNumbers<T>;

export default emptyFieldValuesToNull;
