import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TaskItemT } from './InstallationTasksTypes';
import { useEffect } from 'react';
import MapService from '@services/mapService/mapService';

import InstallationTasksView from './InstallationTasksView';
import cropEntity from '@entities/cropEntity';
import { setupCropPaletteFromCrop } from '@utils/setupCropPalette';
import addPolygonData from '@utils/addPolygonData';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

const InstallationTasks = (): JSX.Element => {
    const { t } = useTranslation();

    const isConfirmed = useSelector(
        (state: HomePageStateT) => !!state.farmSeason.currentFarmSeason?.installation_validated_at,
    );
    const onboarding = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding,
    );
    const isBaseline = useSelector(selectIsBaselineSeason());
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason.id);
    const { cropState } = cropEntity.useState({ farmSeasonId });
    const fieldsList = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);

    const isCFT = useIsCFTCarbonModel();

    // make sure the map is not in edit mode anymore
    useEffect(() => {
        MapService.creatablePolygons({ createMode: false });
        MapService.showOpenDataLayer({ openDataMode: false });
    }, []);

    useEffect(() => {
        if (!cropState.list.length || !fieldsList.length) {
            return;
        }
        const cropPalette = setupCropPaletteFromCrop(cropState.list);
        MapService.addPolygons({
            polygons: fieldsList.map((field) => addPolygonData({ field, colorPalette: cropPalette })),
        });
    }, [fieldsList, cropState.list]);

    /* -------------------------------------------------------------------------- */
    /*                                  Baseline                                  */
    /* -------------------------------------------------------------------------- */
    const generalTasksBaseline = [
        true, // "create your farms"
        onboarding?.crop?.done || false,
        onboarding?.field?.done || false,
        onboarding?.livestock?.done || false,
    ];
    const generalTasksTotalBaseline = generalTasksBaseline.length;
    const generalTasksDoneCountBaseline = generalTasksBaseline.filter((isDone) => isDone).length;

    const fieldsSubtaskItemsBaseline = [
        onboarding?.agroforestry?.done || false,
        onboarding?.drainage?.done || false,
        onboarding?.irrigation?.done || false,
        onboarding?.soilAcidity?.done || false,
        ...(isCFT ? [onboarding?.soilOrganicMatter?.done || false] : [onboarding?.soilOrganicCarbon?.done || false]),
        ...(isCFT ? [onboarding?.organic?.done || false] : []),
        ...(isCFT ? [onboarding?.tillage?.done || false] : []),
        ...(isCFT ? [onboarding?.coverCrop?.done || false] : []),
        ...(isCFT ? [onboarding?.residue?.done || false] : []),
    ];
    const fieldsSubtaskItemsTotalBaseline = fieldsSubtaskItemsBaseline.length;
    const fieldsSubtaskItemsDoneCountBaseline = fieldsSubtaskItemsBaseline.filter((isDone) => isDone).length;
    const fieldsSubtaskDoneBaseline = fieldsSubtaskItemsTotalBaseline === fieldsSubtaskItemsDoneCountBaseline;

    // general tasks + fields subtasks + history subtasks
    const allTasksTotalBaseline = generalTasksTotalBaseline + fieldsSubtaskItemsTotalBaseline;
    const allTasksDoneCountBaseline = generalTasksDoneCountBaseline + fieldsSubtaskItemsDoneCountBaseline;

    const taskItemsBaseline: TaskItemT[] = [
        {
            name: t('pages.installationtasks.create-your-farm'),
            done: true,
            disabled: true,
            link: {
                to: '',
            },
        },
        {
            name: t('pages.installationtasks.add-your-fields'),
            done: onboarding?.field?.done || false,
            link: {
                to: 'tasks/fields',
            },
        },
        {
            name: t('pages.installationtasks.add-your-crops'),
            done: onboarding?.crop?.done || false,
            disabled: !onboarding?.field?.done,
            link: {
                to: 'tasks/crops',
            },
        },
        {
            name: t('pages.installationtasks.complete-your-fields'),
            done: fieldsSubtaskDoneBaseline || false,
            disabled: !onboarding?.field?.done,
            link: {
                to: 'tasks/fields/subtasks',
            },
            subtaskItems: fieldsSubtaskDoneBaseline
                ? undefined
                : {
                      text: `${fieldsSubtaskItemsDoneCountBaseline}/${fieldsSubtaskItemsTotalBaseline}`,
                      percentage: (fieldsSubtaskItemsDoneCountBaseline / fieldsSubtaskItemsTotalBaseline) * 100,
                  },
        },
        {
            name: t('pages.installationtasks.add-your-livestock'),
            done: onboarding?.livestock?.done || false,
            disabled: !onboarding?.crop?.done,
            link: {
                to: 'tasks/livestock',
            },
        },
    ];
    const totalProgressRatioBaseline = `${allTasksDoneCountBaseline}/${allTasksTotalBaseline}`;
    const totalProgressPercentageBaseline = (allTasksDoneCountBaseline / allTasksTotalBaseline) * 100;
    const showValidateBaseline =
        (!isConfirmed &&
            onboarding?.crop?.done &&
            (!isCFT || onboarding?.tillage?.done) &&
            onboarding?.soilAcidity?.done &&
            onboarding?.soilOrganicMatter?.done) ||
        false;
    /* -------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------- */
    /*                                Year on Year                                */
    /* -------------------------------------------------------------------------- */
    const generalTasksYoY = [
        onboarding?.crop?.done || false,
        onboarding?.field?.done || false,
        onboarding?.livestock?.done || false,
        onboarding?.agroforestry?.done || false,
        onboarding?.drainage?.done || false,
        onboarding?.irrigation?.done || false,
        ...(isCFT ? [onboarding?.organic?.done || false] : []),
        ...(isCFT ? [onboarding?.tillage?.done || false] : []),
        ...(isCFT ? [onboarding?.coverCrop?.done || false] : []),
        ...(isCFT ? [onboarding?.residue?.done || false] : []),
    ];
    const generalTasksTotalYoY = generalTasksYoY.length;
    const generalTasksDoneCountYoY = generalTasksYoY.filter((isDone) => isDone).length;

    const taskItemsYoY: TaskItemT[] = [
        {
            name: t('pages.installationtasks.add-your-fields-yoy'),
            done: onboarding?.field?.done || false,
            link: {
                to: 'tasks/fields',
            },
        },
        {
            name: t('pages.installationtasks.add-your-crops-yoy'),
            done: onboarding?.crop?.done || false,
            disabled: !onboarding?.field?.done,
            link: {
                to: 'tasks/crops',
            },
        },

        // FIELDS subtasks
        {
            name: t('pages.installationsubtasks.tillage-practice-yoy'),
            done: onboarding?.tillage?.done || false,
            disabled: !onboarding?.crop?.done,
            link: {
                to: `tasks/fields/subtasks/tillage`,
            },
        },
        {
            name: t('pages.installationsubtasks.cover-crops-yoy'),
            done: onboarding?.coverCrop?.done || false,
            disabled: !onboarding?.crop?.done,
            link: {
                to: `tasks/fields/subtasks/coverCrop`,
            },
        },
        {
            name: t('pages.installationsubtasks.organic-fields-yoy'),
            done: onboarding?.organic?.done || false,
            disabled: !onboarding?.field?.done,
            link: {
                to: `tasks/fields/subtasks/organic`,
            },
        },
        {
            name: t('pages.installationsubtasks.drainage-yoy'),
            done: onboarding?.drainage?.done || false,
            disabled: !onboarding?.field?.done,
            link: {
                to: `tasks/fields/subtasks/drainage`,
            },
        },
        {
            name: t('pages.installationsubtasks.irrigation-yoy'),
            done: onboarding?.irrigation?.done || false,
            disabled: !onboarding?.field?.done,
            link: {
                to: `tasks/fields/subtasks/irrigation`,
            },
        },
        {
            name: t('pages.installationsubtasks.agroforestry-yoy'),
            done: onboarding?.agroforestry?.done || false,
            disabled: !onboarding?.field?.done,
            link: {
                to: `tasks/fields/subtasks/agroforestry`,
            },
        },

        // CROPS subtasks
        {
            name: t('pages.installationsubtasks.residue-yoy'),
            done: onboarding?.residue?.done || false,
            disabled: !onboarding?.crop?.done,
            link: {
                to: `tasks/fields/subtasks/residue`,
            },
        },

        {
            name: t('pages.installationtasks.add-your-livestock-yoy'),
            done: onboarding?.livestock?.done || false,
            disabled: !onboarding?.crop?.done,
            link: {
                to: 'tasks/livestock',
            },
        },
    ];
    const totalProgressRatioYoY = `${generalTasksDoneCountYoY}/${generalTasksTotalYoY}`;
    const totalProgressPercentageYoY = (generalTasksDoneCountYoY / generalTasksTotalYoY) * 100;
    const showValidateYoY = (!isConfirmed && onboarding?.crop?.done && (!isCFT || onboarding?.tillage?.done)) || false;
    /* -------------------------------------------------------------------------- */

    return (
        <InstallationTasksView
            showValidate={isBaseline ? showValidateBaseline : showValidateYoY}
            t={t}
            taskItems={isBaseline ? taskItemsBaseline : taskItemsYoY}
            totalProgressPercentage={isBaseline ? totalProgressPercentageBaseline : totalProgressPercentageYoY}
            totalProgressRatio={isBaseline ? totalProgressRatioBaseline : totalProgressRatioYoY}
        />
    );
};

export default InstallationTasks;
