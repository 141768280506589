import useTableTotalCellStyle from './TableTotalCell.style';
import { TableTotalCellPropsT } from './TableTotalCellTypes';

const TableTotalCell = ({ value, size, id }: TableTotalCellPropsT) => {
    const { classes } = useTableTotalCellStyle({ size });

    const highlitedCellsIds = [
        'total-cell-revenue',
        'total-cell-onMarket',
        'total-cell-amountSold',
        'total-cell-onMarket',
    ];

    return (
        <div
            id={id}
            className={`${classes.TableTotalCell} ${
                highlitedCellsIds.includes(id) ? classes.TableTotalCellHighlited : ''
            }`}
        >
            {value}
        </div>
    );
};

export default TableTotalCell;
