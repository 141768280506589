/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { TagNameT } from './api2.service';
import EntityError from '@entities/EntityError';

type ApiContextT = { queryFulfilled: Promise<{ data: any; meta: any }>; dispatch: any };

/**
 * 'fetch-only' = no optimistic response.\
 * 'cache-only' = only mutate the cache. Still wait the fetch but only to undo on error.\
 * 'cache-then-fetch' = mutate the cache first, then wait the fetch to mutate again or undo on error
 */
type OptimisticStrategyT = 'fetch-only' | 'cache-only' | 'cache-then-fetch';

/**
 * P: EndpointParamsT, E: entity, T: Tag
 * @param api
 * @param tagName
 * @param cacheAdapter
 * @returns
 */
export const getUpdateQueryHelper = <P extends Record<string, number>, E extends { id: number }, T extends TagNameT>(
    api: any,
    tagName: T,
    cacheAdapter: EntityAdapter<E>,
) => ({
    /**
     *
     * @param params
     * @param apiContext
     * @param optimisticStrategy {@link OptimisticStrategyT}
     */
    // TODO handle non existing ids for optimistic\
    add: async (
        { body, ...endpointParams }: P & { body?: Partial<E> },
        apiContext: ApiContextT,
        optimisticStrategy: OptimisticStrategyT = 'fetch-only',
    ) => {
        // TODO Could we use the schema there to make sur the data are safe in createCache? -> This implies body properties to match resource
        if (optimisticStrategy === 'cache-then-fetch' && !body) {
            throw new EntityError(tagName, 'cache', 'try to add optimistic but no body passed');
        }
        if (optimisticStrategy === 'cache-only' && !body?.id) {
            throw new EntityError(tagName, 'cache', 'try to add permanent optimistic but no id');
        }

        const createCache = (entity: E) =>
            apiContext.dispatch(
                api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                    cacheAdapter.addOne(draft, entity);
                }),
            );

        if (optimisticStrategy === 'cache-only' || optimisticStrategy === 'cache-then-fetch') {
            const { undo } = createCache(body as E);
            apiContext.queryFulfilled.catch(undo);
        }

        if (optimisticStrategy === 'fetch-only' || optimisticStrategy === 'cache-then-fetch') {
            const { data } = await apiContext.queryFulfilled;
            createCache(data);
        }
    },

    update: async (
        { id, body, ...endpointParams }: P & { body: Partial<E>; id: number },
        apiContext: ApiContextT,
        optimisticStrategy: OptimisticStrategyT = 'fetch-only',
    ) => {
        const updateCache = (changes: Partial<E>) =>
            apiContext.dispatch(
                api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                    cacheAdapter.updateOne(draft, { changes, id });
                }),
            );

        if (optimisticStrategy === 'cache-only' || optimisticStrategy === 'cache-then-fetch') {
            const { undo } = updateCache(body);
            apiContext.queryFulfilled.catch(undo);
        }

        if (optimisticStrategy === 'fetch-only' || optimisticStrategy === 'cache-then-fetch') {
            const { data } = await apiContext.queryFulfilled;
            updateCache(data);
        }
    },

    remove: async (
        { id, ...endpointParams }: P & { id: number },
        apiContext: ApiContextT,
        optimisticStrategy: Exclude<OptimisticStrategyT, 'cache-then-fetch'> = 'fetch-only',
    ) => {
        const removeFromCache = () =>
            apiContext.dispatch(
                api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                    cacheAdapter.removeOne(draft, id);
                }),
            );

        if (optimisticStrategy === 'cache-only') {
            const { undo } = removeFromCache();
            apiContext.queryFulfilled.catch(undo);
        } else if (optimisticStrategy === 'fetch-only') {
            await apiContext.queryFulfilled;
            removeFromCache();
        }
    },

    removeWhere: async (
        key: keyof E,
        value: number,
        endpointParams: P,
        apiContext: ApiContextT,
        optimisticStrategy: Omit<OptimisticStrategyT, 'cache-then-fetch'> = 'fetch-only',
    ) => {
        const removeFromCache = () =>
            apiContext.dispatch(
                api.util.updateQueryData(`get${tagName}`, endpointParams, (draft: EntityState<E>) => {
                    const entitiesToRemove = draft.ids
                        .map((id) => draft.entities[id])
                        .filter((e: any) => typeof value === 'number' && e?.[key] === value)
                        .filter((e) => !!e) as E[];
                    cacheAdapter.removeMany(
                        draft,
                        entitiesToRemove.map((e) => e.id),
                    );
                }),
            );

        if (optimisticStrategy === 'cache-only') {
            const { undo } = removeFromCache();
            apiContext.queryFulfilled.catch(undo);
        } else if (optimisticStrategy === 'fetch-only') {
            await apiContext.queryFulfilled;
            removeFromCache();
        }
    },
});
