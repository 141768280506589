import cropsGet from '@actions/StaticActions/CropsGetAction';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import { Grid } from '@mui/material';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CropItemViewPropsT } from './CropItemViewTypes';
import CardFormLayout from '@/layouts/CardFormLayout/CardFormLayout';
import { useIsCropNeedingCropDestinationAssignment } from '@/hooks/useIsCropNeedingCropDestinationAssignment';
import { useGetLatestActiveSeasonId } from '@/hooks/useGetLatestActiveSeasonId';
import useCropItemStepViewStyle from './CropItemView.style';

const CropItemView = ({
    cropError,
    cropHelperText,
    cropId,
    onCropChange,
    yield: yieldValue,
    yieldError,
    yieldHelperText,
    yieldName,
    onYieldChange,
    title,
    onClose,
    isAccompanied,
    isAccompaniedError,
    isAccompaniedHelperText,
    isAccompaniedName,
    onRelationChange,
    index = 0,
    withHelp,
    disabledForm,
    isCFT,
    cropDestinationPercentage,
    onCropDestinationPercentageChange,
    cropDestinationPercentageError,
    cropDestinationPercentageName,
    cropDestinationPercentageHelperText,
}: CropItemViewPropsT): JSX.Element => {
    const { classes } = useCropItemStepViewStyle();
    const crops = useSelector((state: HomePageStateT) => state.static.crops);
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const latestActiveSeasonId = useGetLatestActiveSeasonId();
    const isCurrentSeasonActive = latestActiveSeasonId === currentFarmSeason?.season_id;
    const isCropNeedingDestination = isCurrentSeasonActive
        ? useIsCropNeedingCropDestinationAssignment({
              crops,
              cropId,
          })
        : false;

    useEffect(() => {
        if (!(crops?.length > 0)) dispatch(cropsGet(Number(currentFarm?.country_id)));
    }, []);

    return (
        <CardFormLayout title={title} onClose={onClose}>
            <Grid spacing={2} container>
                <Grid xs={7} item>
                    <LabelAutocomplete
                        disabled={disabledForm}
                        error={cropError}
                        helpName="crop-select-help"
                        helperText={cropHelperText}
                        id={`select-${index}`}
                        label={t('pages.installationtasks.add-crop-task.crop-list-step.crop-item.crop-type')}
                        options={crops}
                        value={crops?.length ? cropId || '' : ''}
                        withHelp={withHelp}
                        required
                        onChange={(value) => onCropChange(value as number)}
                    />
                </Grid>
                <Grid xs={5} item>
                    <LabelTextField
                        disabled={disabledForm}
                        endAdornment={t('pages.installationtasks.add-crop-task.crop-list-step.crop-item.end-adornment')}
                        error={yieldError}
                        helpName="yield-help"
                        helperText={yieldHelperText}
                        id={`yield-${index}`}
                        label={t('pages.installationtasks.add-crop-task.crop-list-step.crop-item.average-yield')}
                        name={yieldName}
                        type="number"
                        value={yieldValue}
                        variant="outlined"
                        withHelp={withHelp}
                        fullWidth
                        required
                        onChange={onYieldChange}
                    />
                </Grid>
                {isCropNeedingDestination && (
                    <Grid xs={12} item>
                        <LabelTextField
                            disabled={disabledForm}
                            endAdornment={'%'}
                            error={cropDestinationPercentageError}
                            id={`crop-destination-percentage-${index}`}
                            helpName="crop-destination-percentage-help"
                            helperText={cropDestinationPercentageHelperText}
                            label={t('pages.installationtasks.add-crop-task.crop-list-step.crop-item.food-percentage')}
                            name={cropDestinationPercentageName}
                            type="number"
                            value={cropDestinationPercentage}
                            variant="outlined"
                            withHelp={withHelp}
                            required
                            onChange={onCropDestinationPercentageChange}
                            className={classes.label}
                            labelFullWidth
                        />
                    </Grid>
                )}
                {isCFT && (
                    <Grid xs={12} item>
                        <LabelCheckboxView
                            disabled={disabledForm}
                            checked={isAccompanied}
                            error={isAccompaniedError}
                            helpName="crop-company-help"
                            helperText={isAccompaniedHelperText}
                            id={`accompanied-${index}`}
                            label={t(
                                'pages.installationtasks.add-crop-task.crop-list-step.crop-item.has-relation-crop',
                            )}
                            name={isAccompaniedName}
                            withHelp={withHelp}
                            noMargin
                            onChange={onRelationChange}
                        />
                    </Grid>
                )}
            </Grid>
        </CardFormLayout>
    );
};

export default CropItemView;
