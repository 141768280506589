import { useCanEditAllOperations } from '@/hooks/usePermissions';
import AdminPanelView from './AdminPanel/AdminPanelView';
import useSideBarStyle from './SideBar.style';
import SideBarNav from './SideBarNav/SideBarNav';

const SideBarView = (): JSX.Element => {
    const { classes } = useSideBarStyle();
    const canEditAllOperations = useCanEditAllOperations();

    return (
        <div className={classes.sideBarInner} id="homepage-sidebar">
            <SideBarNav />
            {canEditAllOperations && <AdminPanelView />}
        </div>
    );
};

export default SideBarView;
