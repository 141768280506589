import RootReducer from '@reducers/RootReducer';
import authMiddleware from '@middlewares/AuthMiddleware';
import { api } from '@api/api.service';
import { api2 } from '@api/api2.service';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // Temporary skip Serializability due to issues.
            serializableCheck: false,
            immutableCheck: false,
            // the concat order is important.  authMiddleware is async and must be at the end of the array
            // https://github.com/reduxjs/redux-toolkit/issues/3118#issuecomment-1403751057
        }).concat(api.middleware, api2.middleware, authMiddleware),
});

export type AppStateT = ReturnType<typeof store.getState>;

export type AppDispatchT = typeof store.dispatch;

export default store;
