import { MenuItem, Select } from '@mui/material';

import { LanguageSelectorViewPropsT } from './LanguageSelectorTypes';

const LanguageSelectorView = ({ onChange, currentLanguage, languageList }: LanguageSelectorViewPropsT): JSX.Element => (
    <Select value={currentLanguage?.iso_code} autoWidth onChange={onChange}>
        {languageList?.map((item) => (
            <MenuItem key={item.id} value={item.iso_code} disableRipple>
                {item.name}
            </MenuItem>
        ))}
    </Select>
);

export default LanguageSelectorView;
