import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import { useContext, useEffect, useState } from 'react';
import useMapFieldReset from '@hooks/useMapFieldReset';

import { Context } from '../CoverCropTaskContext/CoverCropTaskContext';
import { ContextT } from '../CoverCropTaskContext/CoverCropTaskContextTypes';

import HistoryFormView from './HistoryFormView';

type HistoryFormProps = {
    canEdit?: boolean;
};

const HistoryForm = ({ canEdit = false }: HistoryFormProps): JSX.Element => {
    const { formik, groupedFieldIndex, t, selectedGroup, selectedKey, keyColor, nothingToSave, setCurrentStep } =
        useContext<ContextT>(Context);
    const [listOfYears, setListOfYears] = useState<(string | number)[]>([]);
    const resetMap = useMapFieldReset();

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const oldestYear = currentYear - 21;
        const tempListYear: (string | number)[] = [];

        for (let i = currentYear; i >= oldestYear; i -= 1) {
            tempListYear.push(i);
        }
        tempListYear.push(t('constants.mode-than-x-years', { x: 20 }));
        setListOfYears(tempListYear);
    }, []);

    // Put the color on the map
    useEffect(() => {
        // set color of the group
        const fieldsWithColorOnMap: FarmSeasonFieldT[] = [];
        selectedGroup.forEach((field) => {
            fieldsWithColorOnMap.push({ ...field, mapLayerColor: keyColor[selectedKey] });
        });

        if (fieldsWithColorOnMap.length > 0) {
            MapService.addPolygons({
                polygons: fieldsWithColorOnMap.map((field) => addPolygonData({ field, withColor: true })),
            });
        }

        // trigger the change to verifiy the form validity
        formik.setFieldValue('frequency', formik.values.frequency);

        // reset the map on unmount
        return () => {
            resetMap(true);
        };
    }, []);

    return (
        <HistoryFormView
            formik={formik}
            groupedFieldIndex={groupedFieldIndex}
            listOfYears={listOfYears}
            nothingToSave={nothingToSave}
            t={t}
            setCurrentStep={setCurrentStep}
            canEdit={canEdit}
        />
    );
};

export default HistoryForm;
