import { Palette, TypographyPropsVariantOverrides } from '@mui/material';
import { FontStyleOptions, TypographyStyleOptions, Variant } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides extends Record<Exclude<Variant, 'button' | 'overline'>, boolean> {
        text: true; // demo for variants overrides
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExtendedTypographyOptions
    extends Partial<Record<keyof TypographyPropsVariantOverrides, TypographyStyleOptions> & FontStyleOptions> {}

/** @deprecated. ui-kit typography should be used instead */
const typography = (palette: Palette): ExtendedTypographyOptions => ({
    htmlFontSize: 16,
    fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    allVariants: {
        color: palette.primary.main,
    },
    h1: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: '6rem',
        lineHeight: 1.167,
        letterSpacing: '-0.01562em',
    },
    h2: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 300,
        fontSize: '3.75rem',
        lineHeight: 1.2,
        letterSpacing: '-0.00833em',
    },
    h3: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '3rem',
        lineHeight: 1.167,
        letterSpacing: '0em',
    },
    h4: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '1.30rem',
        lineHeight: 1.3,
        letterSpacing: '0em',
    },
    h5: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '0.95rem',
        lineHeight: 1.4,
        letterSpacing: '0em',
    },
    h6: {
        fontFamily: '"BrownStd-Bold","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 100,
        fontSize: '0.8rem',
        lineHeight: 1.2,
        letterSpacing: '0.01em',
    },
    subtitle1: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.75,
        letterSpacing: '0.00938em',
    },
    subtitle2: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.57,
        letterSpacing: '0.00714em',
    },
    body1: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 200,
        fontSize: '0.8rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
    },
    body2: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
    },
    text: {},
    caption: {
        fontFamily: '"BrownStd-Regular","Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 100,
        fontSize: '0.8rem',
        lineHeight: 1.2,
        letterSpacing: '0.01em',
    },
});

export default typography;
