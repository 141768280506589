import { makeStyles } from 'tss-react/mui';

const useCardNameValueListStyle = makeStyles<{
    hasThumbnail: boolean;
    hasValue: boolean;
    nameWidth?: string;
    variant?: 'spanishWhite';
}>()((theme, { hasThumbnail, hasValue, nameWidth, variant }) => ({
    CardNameValueItem: {
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: `${nameWidth || '50%'} ${hasValue ? ' auto' : ''} auto ${hasThumbnail ? '50px' : ''}`,
        gridTemplateRows: '20px',
        padding: '15px 15px 15px 20px',
        alignItems: 'center',
        marginBottom: '7px',
        // transitions for selected item
        transition: 'all ease .3s',
        '& *': {
            transition: 'fill ease .2s, color ease .3s',
        },
        '&:last-child': {
            marginBottom: 0,
        },
        backgroundColor: variant ? theme.palette[variant].light : undefined,
    },

    actionIcons: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
    },

    name: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    cursorPointer: {
        cursor: 'pointer',
    },

    cursorDefault: {
        cursor: 'default',
        pointerEvents: 'none',
    },

    thumbnail: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        transform: 'translateX(15px)',
        width: '100%',
        height: '100%',
        minWidth: '50px',
        minHeight: '50px',
    },

    thumbnailImage: {
        display: 'block',
        objectPosition: 'center',
        objectFit: 'cover',
        width: '50px',
        transform: 'translateX(0px)',
        backgroundColor: theme.palette.secondaryWhiteCloud.main,
        zIndex: 3,
    },

    thumbnailIcon: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '10px',
        top: 0,
        zIndex: 5,
        transform: 'translateX(0px)',
        fill: theme.palette.warning.light,
        transition: 'opacity .15s ease, background-color .3s linear',
        opacity: 0,
        backgroundColor: 'transparent',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: '#00000090',
        },
    },

    thumbnailLoading: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        zIndex: 1,
        transform: 'translateX(0px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        opacity: 0.7,
        pointerEvents: 'none',
    },

    itemSelected: {
        backgroundColor: theme.palette.secondaryDrySage.main,
        '&:hover': {
            backgroundColor: `${theme.palette.secondaryDrySage.main} !important`,
        },
        '& *': {
            color: theme.palette.common.white,
        },
        '& svg': {
            fill: `${theme.palette.common.white} !important`,
        },

        '& button:hover': {
            backgroundColor: '#ffffff22',
        },
    },
    itemClickable: {
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.secondaryWhiteCloud.main,
        },
    },
}));

export default useCardNameValueListStyle;
