import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';
import { MapStylePropsT } from '@components/Map/MapTypes';
import { Theme } from '@mui/system';

const ctaBtnIconStyle = (theme: Theme) => ({
    backgroundColor: theme.palette.spanishWhiteBgShadow,
    borderRadius: '50%',
    marginRight: '6px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignContent: 'space-around',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '& svg': {
        width: '14px',
        height: '14px',
    },
});

const useMapStyle = makeStyles<MapStylePropsT>()((theme, { hover }) => ({
    // container
    mapContainer: {
        position: 'relative',
        width: '100% !important',
        height: '100% !important',
        overflow: 'hidden',

        // general hover cursor
        '& .mapboxgl-canvas-container': { cursor: hover ? 'pointer !important' : 'default' },

        // selecting cursor
        '&.selecting-field .mapboxgl-canvas-container': {
            cursor: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f57c00' width='15px' height='17px' viewBox='0 0 15 17'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M12.4,5.6L8.3,5.5c-0.1,0-0.3,0-0.4,0.1L7.2,5.8l-1.7-4C5.1,0.5,3.7-0.2,2.5,0.2c-1.4,0.4-2.1,2-1.6,3.3L4,11.1l-1.9,0.3 c-0.2,0-1,0.3-1.4,1.2L0,14.5l6.7,2.8c0.5,0.2,1,0.2,1.5,0l6.1-2.5c0.9-0.4,1.4-1.3,1.2-2.3l-1.2-5.4C14.1,6.3,13.3,5.6,12.4,5.6 z M12.3,7.6l1.2,5.4l-6.1,2.5l-4.9-2.1l4.2-0.8L2.7,2.8C2.6,2.5,2.8,2.2,3,2.1c0.3-0.1,0.5,0,0.7,0.3L6,8.1l1.6-0.7L12.3,7.6z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\") 0 0, pointer !important;",
        },

        // creating cursor
        '& .mapboxgl-map.mode-draw_polygon .mapboxgl-canvas-container, &.creating-field .mapboxgl-canvas-container': {
            cursor: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f57c00' width='24px' height='24px' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z'/%3E%3C/svg%3E\") 0 22, crosshair !important;",
        },

        // editing cursor
        '&:not(.creating-field) .mapboxgl-map.mode-direct_select .mapboxgl-canvas-container, &.editing-field .mapboxgl-canvas-container':
            {
                cursor: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f57c00' width='12px' height='20px' viewBox='0 0 12 20'%3E%3Cpath d='M8.7,11.3l2.7-0.2c2.7-0.2,0.6-1.9,0.6-1.9L5.6,3.8l0,0l0,0L1.2,0L0.7,5.7l0,0l0,0L0,14.2c0,0-0.3,2.7,1.8,0.9l2-1.8 l2.9,6.9l4.9-2L8.7,11.3z M5.4,11.9l-0.8-2L3,11.4l-0.8,0.7l0.2-2.3L2.8,4l4.4,3.8l0,0L9,9.3L7.9,9.3h0l0,0L5.8,9.5l0.8,1.9L9,17.1 l-1.2,0.5L5.4,11.9z'/%3E%3C/svg%3E\") 0 0, default !important;",
            },
    },

    // loader
    loader: {
        backgroundColor: theme.palette.driftwood.light,
        position: 'relative',
        zIndex: 100,
        transition: 'opacity 1.5s ease',
        pointerEvents: 'none',
    },

    // disabling box
    disablingBox: {
        backgroundColor: 'rgba(255,255,255,.7)',
        position: 'absolute',
        zIndex: 99,
        pointerEvents: 'all',
        top: 0,
    },

    // snackbar box
    snackbarBox: {
        position: 'absolute',
        zIndex: 95,
        pointerEvents: 'none',
        top: 0,
        border: `6px solid ${theme.palette.warning.light}`,
        borderRadius: '0 14px 0 0',
        transition: 'transform .5s ease',
        transform: 'scale(1.03, 1.07)',
        boxShadow: `inset 0px 0px 15px 5px ${alpha(theme.palette.warning.light, 0.55)}`,
    },
    snackbarMsg: {
        backgroundColor: theme.palette.warning.light,
        padding: '2px 10px',
        position: 'absolute',
        borderRadius: '0 0 10px 10px',
        fontWeight: 'bolder',
        fontSize: '11px',
        color: theme.palette.secondary.dark,
    },

    // map styling
    map: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        '& .mapboxgl-ctrl-logo': {
            opacity: 0.5,
        },
        '& .mapboxgl-ctrl-attrib': {
            borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
            '&.mapboxgl-compact': {
                minHeight: '24px',
                borderRadius: `${theme.shape.borderRadius}px`,
                opacity: 0.5,
            },
        },
        '& .mapboxgl-ctrl-group:not(:empty)': {
            boxShadow: 'none',
            borderRadius: '10px',
            marginTop: '20px',
            marginRight: '20px',
        },
        '& .mapboxgl-ctrl-attrib-button': {
            borderRadius: '50%',
            backgroundColor: 'none !important',
        },
        '& .mapboxgl-ctrl-group button': {
            width: '40px',
            height: '40px',
            borderRadius: '10px !important',
        },
        '& .mapboxgl-ctrl-top-right': {
            top: '60px',
        },
        '& .mapboxgl-ctrl button': {
            backgroundSize: '25px',
        },
        '& .mapboxgl-ctrl-backhome-container': {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 10,
            '& .mapboxgl-ctrl-icon': {
                // HOME
                backgroundImage:
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='%23B07C48'%3E%3Cg%3E%3Crect fill='none' height='24' width='24'/%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M19,9.3V4h-3v2.6L12,3L2,12h3v8h6v-6h2v6h6v-8h3L19,9.3z M17,18h-2v-6H9v6H7v-7.81l5-4.5l5,4.5V18z'/%3E%3Cpath d='M10,10h4c0-1.1-0.9-2-2-2S10,8.9,10,10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\");",
            },
        },
        '& .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon': {
            // FULLSCREEN
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 20 20' enable-background='new 0 0 20 20' xml:space='preserve'%3E%3Cg%3E%3Cpolygon fill='%23B07C48' points='7.273,8.182 4.545,8.182 4.545,5.455 0,10 4.545,14.545 4.545,11.818 7.273,11.818 '/%3E%3Cpolygon fill='%23B07C48' points='20,10 15.455,5.455 15.455,8.182 12.727,8.182 12.727,11.818 15.455,11.818 15.455,14.545 '/%3E%3Cpolygon fill='%23B07C48' points='8.182,7.273 11.818,7.273 11.818,4.545 14.545,4.545 10,0 5.455,4.545 8.182,4.545 '/%3E%3Cpolygon fill='%23B07C48' points='11.818,12.727 8.182,12.727 8.182,15.455 5.455,15.455 10,20 14.545,15.455 11.818,15.455 '/%3E%3C/g%3E%3C/svg%3E\")",
        },
        '& .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon': {
            // EXIT FULLSCREEN
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='msc-onboarding-3.3' transform='translate(-956.000000, -56.000000)' fill-rule='nonzero' fill='%23B07C48'%3E%3Cg id='map' transform='translate(276.000000, 26.000000)'%3E%3Cg id='expand' transform='translate(670.000000, 20.000000)'%3E%3Cg id='open_with_black_24dp' transform='translate(10.000000, 10.000000)'%3E%3Cpath d='M11.8181818,9.76996262e-15 L8.18181818,8.8817842e-15 L8.18181818,2.72727273 L5.45454545,2.72727273 L10,7.27272727 L14.5454545,2.72727273 L11.8181818,2.72727273 L11.8181818,9.76996262e-15 Z M0,11.8181818 L2.72727273,11.8181818 L2.72727273,14.5454545 L7.27272727,10 L2.72727273,5.45454545 L2.72727273,8.18181818 L8.8817842e-16,8.18181818 L0,11.8181818 Z M12.7272727,10 L17.2727273,14.5454545 L17.2727273,11.8181818 L20,11.8181818 L20,8.18181818 L17.2727273,8.18181818 L17.2727273,5.45454545 L12.7272727,10 Z M8.18181818,20 L11.8181818,20 L11.8181818,17.2727273 L14.5454545,17.2727273 L10,12.7272727 L5.45454545,17.2727273 L8.18181818,17.2727273 L8.18181818,20 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
        },
        '& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon': {
            // PLUS (ZOOM IN)
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='10.3px' height='10.3px' viewBox='0 0 10.3 10.3' enable-background='new 0 0 10.3 10.3' xml:space='preserve'%3E%3Cpath fill='%23B07C48' d='M10.15,4.287H6.015V0.15C6.015,0.067,5.949,0,5.865,0H4.437c-0.083,0-0.15,0.067-0.15,0.15v4.136H0.15 C0.067,4.287,0,4.354,0,4.437v1.428c0,0.084,0.067,0.15,0.15,0.15h4.136v4.135c0,0.084,0.067,0.15,0.15,0.15h1.428 c0.084,0,0.15-0.066,0.15-0.15V6.015h4.135c0.084,0,0.15-0.066,0.15-0.15V4.437C10.3,4.354,10.234,4.287,10.15,4.287z'/%3E%3C/svg%3E%0A\")",
        },
        '& .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon': {
            // MINUS (ZOOM OUT)
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='10.3px' height='10.3px' viewBox='0 0 10.3 10.3' enable-background='new 0 0 10.3 10.3' xml:space='preserve'%3E%3Cpath fill='%23B07C48' d='M10.15,4.287l-10-0.001C0.067,4.287,0,4.354,0,4.437v1.428c0,0.084,0.067,0.15,0.15,0.15h9.999 c0.084,0,0.149-0.066,0.149-0.15V4.437C10.3,4.354,10.234,4.287,10.15,4.287z'/%3E%3C/svg%3E\")",
        },
        '& .mapboxgl-marker-anchor-center': {
            zIndex: 100,
        },
    },

    // add polygons styling
    addPolygonButton: {
        '&.cta-btn': {
            position: 'absolute',
            zIndex: 20,
            bottom: '20px',
            left: '20px',
            boxShadow: 'none !important',
        },
        '& .cta-buttons': {
            backgroundColor: '#ffffff !important',
            color: `${theme.palette.driftwood.light} !important`,
            boxShadow: 'none !important',
            '& .cta-btn-icon': ctaBtnIconStyle(theme),
            '& .edit-polygon-button-icon svg': {
                fill: theme.palette.driftwood.light,
            },
            marginLeft: '10px',
            textTransform: 'none',
            padding: '6px 12px 6px 6px',
            height: '32px',
        },
        '& .create-button': {
            marginLeft: 0,
        },
    },
    drawingButtons: {
        '&.cta-btn': {
            position: 'absolute',
            zIndex: 20,
            bottom: '20px',
            left: '20px',
            boxShadow: 'none !important',
        },
        '& .cta-btn-icon': ctaBtnIconStyle(theme),
        '& .drawing-button': {
            backgroundColor: '#ffffff !important',
            color: `${theme.palette.driftwood.light} !important`,
            boxShadow: 'none !important',
            '& .cta-btn-icon': {
                backgroundColor: 'transparent !important',
                marginLeft: '-4px',
                marginRight: '2px',
            },
            textTransform: 'none',
        },
        '& .drawing-ok-button': {
            marginLeft: '10px',
            backgroundColor: `${theme.palette.shamrock.main} !important`,
            color: `#ffffff !important`,
            '&.Mui-disabled': {
                opacity: 0.5,
            },
            textTransform: 'none',
        },
    },

    // Name Modal Styling
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 100,
    },
    titleDarker: {
        color: theme.palette.secondary.dark,
    },
}));

// MapBox DRAW styles
// source: https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/EXAMPLES.md
export const getMapBoxDrawStyles = (theme: Theme) => [
    // ACTIVE (being drawn)
    // line stroke
    {
        id: 'gl-draw-line',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': theme.palette.warning.dark,
            'line-dasharray': [0.2, 2],
            'line-width': 2,
        },
    },
    // polygon fill
    {
        id: 'gl-draw-polygon-fill',
        type: 'fill',
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        paint: {
            'fill-color': theme.palette.warning.main,
            'fill-outline-color': theme.palette.warning.dark,
            'fill-opacity': 0.5,
        },
    },
    // polygon mid points
    {
        id: 'gl-draw-polygon-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
            'circle-radius': 3,
            'circle-color': theme.palette.warning.main,
        },
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        id: 'gl-draw-polygon-stroke-active',
        type: 'line',
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': theme.palette.warning.main,
            'line-dasharray': [0.2, 2],
            'line-width': 2,
        },
    },
    // vertex point halos
    {
        id: 'gl-draw-polygon-and-line-vertex-halo-active',
        type: 'circle',
        filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
        paint: {
            'circle-radius': 5,
            'circle-color': '#FFF',
        },
    },
    // vertex points
    {
        id: 'gl-draw-polygon-and-line-vertex-active',
        type: 'circle',
        filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
        paint: {
            'circle-radius': 3,
            'circle-color': theme.palette.warning.main,
        },
    },
];

export default useMapStyle;
