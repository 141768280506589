import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FarmPayloadT } from '@actions/FarmActions/FarmActionsTypes';
import { farmUpdate } from '@actions/FarmActions/FarmActions';
import { countryGet } from '@actions/StaticActions/StaticAction';
import { useTranslation } from 'react-i18next';

import FarmEditView from './FarmEditView';
import { useNavigate } from 'react-router-dom';

const FarmEdit = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmerUser = useSelector((state: HomePageStateT) => state.auth.selectedUser || state.auth.user);
    const loading = useSelector((state: HomePageStateT) => state.farm.loading);
    const countries = useSelector((state: HomePageStateT) =>
        state.static.countries?.filter((country) => country.carbon_program === true),
    );

    useEffect(() => {
        if (!(countries?.length > 0)) dispatch(countryGet());
    }, []);

    const { t } = useTranslation();

    const validationSchema = yup.object({
        name: yup.string().min(2, t('validation.too-short')).required(t('validation.no-farm-name-provided')),
        address: yup
            .string()
            .min(2, t('validation.too-short'))
            .required(t('validation.no-address-provided'))
            .max(120, t('validation.max-chars-120')),
        postal_code: yup.string().min(2, t('validation.too-short')).required(t('validation.no-postal-code-provided')),
        city: yup.string().min(2, t('validation.too-short')).required(t('validation.no-city-provided')),
        country_id: yup.string().required(t('validation.no-country-provided')),
    });

    const formik = useFormik({
        initialValues: {
            name: farm?.name || '',
            address: farm?.address || '',
            postal_code: farm?.postal_code || '',
            city: farm?.city || '',
            country_id: farm?.country_id?.toString() || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: FarmPayloadT, { setSubmitting }) => {
            if (farm) {
                const successFarmUpdate = await dispatch(
                    farmUpdate({ farmId: farm.id, payload: values, userId: farmerUser.id }),
                );
                setSubmitting(false);
                if (successFarmUpdate) {
                    navigate('/farm');
                }
            }
        },
    });

    return <FarmEditView countries={countries} formik={formik} loading={loading} t={t} />;
};

export default FarmEdit;
