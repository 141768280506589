import { makeStyles } from 'tss-react/mui';

const usePagePrintableStyle = makeStyles<{ printWithBg: boolean }>()((theme, { printWithBg }) => ({
    PagePrintableStyle: {
        display: 'none',
        '@media print': {
            display: 'block',
        },
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#fff',
        WebkitPrintColorAdjust: printWithBg ? 'exact' : undefined,
    },
    header: {
        textAlign: 'center',
        margin: '20px',
    },
}));

export default usePagePrintableStyle;
