export enum ROLE_ID {
    FARMER = 1,
    AGRONOMIST = 2,
    ASSOCIATE_ADMIN = 7,
    EXTERNAL_AGRONOMIST = 8,
    SALES = 9,
    TRAINED_PARTNER_ADMIN = 10,
    TRAINED_PARTNER_COLLABORATOR = 11,
    TRAINED_PARTNER_READONLY_ADMIN = 12,
    TRAINED_PARTNER_READONLY_COLLABORATOR = 13,
}
