import { CropBodyT, CropEndpointParamsT, CropT } from './cropEntity.types';
import { api2 } from '@api/api2.service';
import { EntityState } from '@reduxjs/toolkit';
import cropEntityCache, { cropCacheAdapter } from './cropEntity.cache';

const route = (fsId: number) => `/farm-seasons/${fsId}/crops`;

export const cropApi = api2.injectEndpoints({
    endpoints: (build) => ({
        getCrop: build.query<EntityState<CropT>, CropEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: route(farmSeasonId),
                method: 'GET',
            }),
            transformResponse: (response: CropT[]) =>
                cropCacheAdapter.setAll(cropCacheAdapter.getInitialState(), response),
        }),

        createCrop: build.mutation<CropT, CropEndpointParamsT & { body: CropBodyT }>({
            query: ({ body, farmSeasonId }) => ({
                url: route(farmSeasonId),
                method: 'POST',
                body,
            }),
            onQueryStarted: cropEntityCache.add,
        }),

        updateCrop: build.mutation<CropT, CropEndpointParamsT & { body: CropBodyT; id: number }>({
            query: ({ body, farmSeasonId, id }) => ({
                url: `${route(farmSeasonId)}/${id}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: cropEntityCache.update,
        }),

        deleteCrop: build.mutation<void, CropEndpointParamsT & { id: number }>({
            query: ({ farmSeasonId, id }) => ({
                url: `${route(farmSeasonId)}/${id}`,
                method: 'DELETE',
            }),
            onQueryStarted: cropEntityCache.remove,
        }),
    }),
});

export default {
    useCreate: cropApi.useCreateCropMutation,
    useUpdate: cropApi.useUpdateCropMutation,
    useDelete: cropApi.useDeleteCropMutation,
    useGet: cropApi.useGetCropQuery,
};
