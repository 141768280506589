/* eslint-disable @typescript-eslint/no-explicit-any */
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import MapService from '@services/mapService/mapService';
import { FarmT } from '@store/actions/FarmActions/FarmActionsTypes';
import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import centroid from '@turf/centroid';
import _ from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import MapSectionView from './MapSectionView';

const MapSection = ({ style }: { style?: any }): JSX.Element => {
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const currentFields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);

    const reCenterMap = (farm: FarmT, fields: FarmSeasonFieldT[]): void => {
        if (!_.isEmpty(fields)) {
            MapService.focusPolygons({ polygons: _.map(fields, 'polygon') });
        } else if (farm && farm.center && farm.center.geometry) {
            MapService.center({
                latitude: farm.center.geometry.coordinates[1],
                longitude: farm.center.geometry.coordinates[0],
            });
        }
    };

    // Center fields on season change
    const [alreadyCenteredForFsId, setAlreadyCenteredForFsId] = useState<number | null>(null);
    if (currentFarm && currentFields.length && alreadyCenteredForFsId !== currentFarmSeason.id) {
        reCenterMap(currentFarm, currentFields);
        setAlreadyCenteredForFsId(currentFarmSeason.id);
    }

    const defaultMapCenter = !_.isEmpty(currentFields)
        ? centroid({ type: 'FeatureCollection', features: _.map(currentFields, 'polygon') })
        : currentFarm?.center;

    const defaultMapZoom = !_.isEmpty(currentFields) ? 14 : 15;

    return (
        <MapSectionView
            defaultMapCenter={defaultMapCenter}
            defaultMapZoom={defaultMapZoom}
            showMaskLayer={currentFarmSeason?.baseline_farm_season_id !== null}
            style={style}
        />
    );
};

export default MapSection;
