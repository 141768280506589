// Source: https://github.com/Turfjs/turf/blob/99dd9da9dc92823835f9fe9b1f9c3eca665933ce/packages/turf-line-offset/index.js
// Cfr. https://github.com/Turfjs/turf/pull/1949 & https://github.com/Turfjs/turf/issues/933

import { flattenEach } from '@turf/meta';
import { getCoords, getType } from '@turf/invariant';
import { isObject, lineString, multiLineString, lengthToDegrees } from '@turf/helpers';
import intersection from './intersection';

/**
 * Takes a {@link LineString|line} and returns a {@link LineString|line} at offset by the specified distance.
 *
 * @name lineOffset
 * @param {Geometry|Feature<LineString|MultiLineString>} geojson input GeoJSON
 * @param {number} distance distance to offset the line (can be of negative value)
 * @param {Object} [options={}] Optional parameters
 * @param {string} [options.units='kilometers'] can be degrees, radians, miles, kilometers, inches, yards, meters
 * @returns {Feature<LineString|MultiLineString>} Line offset from the input line
 * @example
 * var line = turf.lineString([[-83, 30], [-84, 36], [-78, 41]], { "stroke": "#F00" });
 *
 * var offsetLine = turf.lineOffset(line, 2, {units: 'miles'});
 *
 * //addToMap
 * var addToMap = [offsetLine, line]
 * offsetLine.properties.stroke = "#00F"
 */
function lineOffset(geojson, distance, options) {
    // Optional parameters
    options = options || {};
    if (!isObject(options)) throw new Error('options is invalid');
    var units = options.units;

    // Valdiation
    if (!geojson) throw new Error('geojson is required');
    if (distance === undefined || distance === null || isNaN(distance)) throw new Error('distance is required');

    var type = getType(geojson);
    var properties = geojson.properties;

    switch (type) {
        case 'LineString':
            return lineOffsetFeature(geojson, distance, units);
        case 'MultiLineString':
            var coords = [];
            flattenEach(geojson, function (feature) {
                coords.push(lineOffsetFeature(feature, distance, units).geometry.coordinates);
            });
            return multiLineString(coords, properties);
        default:
            throw new Error('geometry ' + type + ' is not supported');
    }
}

/**
 * Line Offset
 *
 * @private
 * @param {Geometry|Feature<LineString>} line input line
 * @param {number} distance distance to offset the line (can be of negative value)
 * @param {string} [units=kilometers] units
 * @returns {Feature<LineString>} Line offset from the input line
 */
function lineOffsetFeature(line, distance, units) {
    var segments = [];
    var offsetDegrees = lengthToDegrees(distance, units);
    var coords = getCoords(line);
    var finalCoords = [];
    coords.forEach(function (currentCoords, index) {
        if (index !== coords.length - 1) {
            var segment = processSegment(currentCoords, coords[index + 1], offsetDegrees);
            segments.push(segment);
            if (index > 0) {
                var seg2Coords = segments[index - 1];
                var intersects = intersection(segment, seg2Coords);

                // Handling for line segments that aren't straight
                if (intersects !== false) {
                    seg2Coords[1] = intersects;
                    segment[0] = intersects;
                }

                finalCoords.push(seg2Coords[0]);
                if (index === coords.length - 2) {
                    finalCoords.push(segment[0]);
                    finalCoords.push(segment[1]);
                }
            }
            // Handling for lines that only have 1 segment
            if (coords.length === 2) {
                finalCoords.push(segment[0]);
                finalCoords.push(segment[1]);
            }
        }
    });
    return lineString(finalCoords, line.properties);
}

/**
 * Process Segment
 * Inspiration taken from http://stackoverflow.com/questions/2825412/draw-a-parallel-line
 *
 * @private
 * @param {Array<number>} point1 Point coordinates
 * @param {Array<number>} point2 Point coordinates
 * @param {number} offset Offset
 * @returns {Array<Array<number>>} offset points
 */
function processSegment(point1, point2, offset) {
    // arccos of dot product between point1 and point2 is the angle between points (in radians)
    var angle = Math.acos(
        Math.cos((point1[1] * Math.PI) / 180) *
            Math.cos((point1[0] * Math.PI) / 180) *
            Math.cos((point2[1] * Math.PI) / 180) *
            Math.cos((point2[0] * Math.PI) / 180) +
            Math.cos((point1[1] * Math.PI) / 180) *
                Math.sin((point1[0] * Math.PI) / 180) *
                Math.cos((point2[1] * Math.PI) / 180) *
                Math.sin((point2[0] * Math.PI) / 180) +
            Math.sin((point1[1] * Math.PI) / 180) * Math.sin((point2[1] * Math.PI) / 180),
    );

    // Convert to degrees
    var L = (angle * 180) / Math.PI;

    if (L > 0) {
        // Transform local cartesian coordinate system to account for lat steps being smaller farther away from the equator
        var midpointFactor = Math.cos((((point1[1] + point2[1]) / 2) * Math.PI) / 180);

        var out1x = point1[0] + (offset * (point2[1] - point1[1])) / L / midpointFactor;
        var out2x = point2[0] + (offset * (point2[1] - point1[1])) / L / midpointFactor;
        var out1y = point1[1] + ((offset * (point1[0] - point2[0])) / L) * midpointFactor;
        var out2y = point2[1] + ((offset * (point1[0] - point2[0])) / L) * midpointFactor;
    } else {
        var out1x = point1[0];
        var out2x = point2[0];
        var out1y = point1[1];
        var out2y = point2[1];
    }
    return [
        [out1x, out1y],
        [out2x, out2y],
    ];
}

export default lineOffset;
