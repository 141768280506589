import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useTaskStepViewStyle = makeStyles()((theme) => ({
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        paddingTop: '20px',
        marginBottom: '20px',
    },
    title: {
        marginBottom: '10px',
    },
    description: {
        marginBottom: '10px',
    },
    listContainer: {
        marginBottom: '20px',
    },
    divider: {
        width: '150px',
        borderWidth: '1px',
        margin: '10px auto 10px !important',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.5)} !important`,
    },
}));

export default useTaskStepViewStyle;
