import { Paper, SvgIcon, Typography } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useTranslation } from 'react-i18next';
import IconDelete from '@icons/icon-delete.svg';

import useDndListItemStyle from './DndListItem.style';
import { DndListItemViewPropsT } from './DndListItemTypes';

// TODO: Put Relations back on when defined
const DndListItemView = ({
    index,
    text,
    isAssociated = false,
    // canAssociat = false,
    drag,
    drop,
    reference,
    handlerId,
    isDragging,
    canDrag,
    moveState,
    // onCoupleClick,
    deleteCrop,
}: DndListItemViewPropsT): JSX.Element => {
    const opacity = isDragging ? 0.5 : 1;
    const { classes } = useDndListItemStyle({ opacity, canDrag });
    const { t } = useTranslation();

    drag(drop(reference));

    return (
        <div ref={reference} className={classes.relative} data-handler-id={handlerId}>
            {moveState && moveState.hoverIndex < moveState.dragIndex && moveState.hoverIndex === index && (
                <div className={classes.topLine} />
            )}
            <Paper className={classes.container} elevation={0}>
                <ReorderIcon className={classes.icon} color="secondary" />
                <Typography className={classes.text} color="primary" variant="h5" noWrap>
                    {text}
                </Typography>
                <SvgIcon className={classes.iconDelete} component={IconDelete} onClick={() => deleteCrop(index)} />
            </Paper>
            {moveState && moveState.hoverIndex > moveState.dragIndex && moveState.hoverIndex === index && (
                <div className={classes.bottomLine} />
            )}
            {isAssociated && <div className={classes.link} />}
            {isAssociated && (
                <Typography className={classes.assocText} color="secondary" variant="h6">
                    {t('component.relationDndModalView.relationsStep.dndListItem.assoc-title')}
                </Typography>
            )}
        </div>
    );
};

export default DndListItemView;
