/* eslint-disable @typescript-eslint/no-explicit-any */
import LabelInputWrapperView from '@components/LabelInputWrapper/LabelInputWrapperView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Link, Paper, TextField, Typography } from '@mui/material';
import { ModalCentered } from '@soil-capital/ui-kit';
import { Trans, useTranslation } from 'react-i18next';

import { ModalCreateFertiliserProps } from './ModalCrateFertiliserTypes';
import useModalCreateFertiliserLogic from './ModalCreateFertiliser.logic';
import useModalCreateFertiliserStyle from './ModalCreateFertiliser.style';

/**
 * create custom fertiliser (mineral solid)
 */
const ModalCreateFertiliser = ({ controller, fertiliserUnit, liquid }: ModalCreateFertiliserProps): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useModalCreateFertiliserStyle();
    const { formik, customNameComputed, existingDefaultsForNPK, setExistingDefaultsForNPK } =
        useModalCreateFertiliserLogic(controller, fertiliserUnit);

    const SelectableFertilisers = () => (
        <>
            {existingDefaultsForNPK?.map((f, i) => (
                <>
                    {/* eslint-disable-next-line react/prop-types */}
                    <Link key={f.id} className={classes.link} onClick={() => controller.onModalConfirm(f)}>
                        {t(f.translation_slug)}
                    </Link>
                    {i < existingDefaultsForNPK.length - 1 ? <>, &nbsp;</> : ''}
                </>
            ))}
        </>
    );

    return (
        <ModalCentered controller={controller}>
            <Paper className={classes.container} elevation={0}>
                <Typography className={classes.title} color="secondary" variant="h4">
                    {liquid
                        ? t('components.modal-create-fertiliser-liquid.title')
                        : t('components.modal-create-fertiliser.title')}
                </Typography>
                <div className={classes.formContent}>
                    <LabelInputWrapperView
                        label={
                            liquid
                                ? t('components.modal-create-fertiliser-liquid.proportions-n-p-k')
                                : t('components.modal-create-fertiliser.proportions-n-p-k')
                        }
                        required
                    >
                        <>
                            <div className={classes.npkInputs}>
                                <TextField
                                    name="n"
                                    placeholder="N"
                                    type="number"
                                    value={formik.values.n}
                                    variant="outlined"
                                    autoFocus
                                    onChange={formik.handleChange}
                                />
                                <span>-</span>
                                <TextField
                                    name="p"
                                    placeholder="P"
                                    type="number"
                                    value={formik.values.p}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                />
                                <span>-</span>
                                <TextField
                                    name="k"
                                    placeholder="K"
                                    type="number"
                                    value={formik.values.k}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <Typography className={classes.errorContainer} variant="body2">
                                {formik.submitCount > 0 && (formik.errors.n || formik.errors.p || formik.errors.k)}
                            </Typography>
                        </>
                    </LabelInputWrapperView>
                    <LabelInputWrapperView
                        label={
                            liquid
                                ? t('components.modal-create-fertiliser-liquid.proportions-ca-mg-s')
                                : t('components.modal-create-fertiliser.proportions-ca-mg-s')
                        }
                    >
                        <>
                            <div className={classes.npkInputs}>
                                <TextField
                                    name="ca"
                                    placeholder="Ca"
                                    type="number"
                                    value={formik.values.ca}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                />
                                <span>-</span>
                                <TextField
                                    name="mg"
                                    placeholder="Mg"
                                    type="number"
                                    value={formik.values.mg}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                />
                                <span>-</span>
                                <TextField
                                    name="s"
                                    placeholder="S"
                                    type="number"
                                    value={formik.values.s}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <Typography className={classes.errorContainer} variant="body2">
                                {formik.submitCount > 0 && (formik.errors.ca || formik.errors.mg || formik.errors.s)}
                            </Typography>
                        </>
                    </LabelInputWrapperView>
                    <LabelInputWrapperView label={t('components.modal-create-fertiliser.name')} required>
                        <TextField
                            error={formik.submitCount > 0 && !!formik.errors.name}
                            helperText={formik.submitCount > 0 && formik.errors.name}
                            name="name"
                            value={formik.values.name !== null ? formik.values.name : customNameComputed(formik.values)}
                            variant="outlined"
                            onChange={formik.handleChange}
                        />
                    </LabelInputWrapperView>
                    <Typography className={classes.errorContainer} variant="body2">
                        {formik.submitCount > 0 &&
                            ((formik.errors as any)?.totalIsMax100 ||
                                (formik.errors as any)?.fertiliserAlreadyExist ||
                                (formik.errors as any)?.nameAlreadyExist)}
                    </Typography>
                </div>
                <div className={classes.fertiliserSuggestion}>
                    {existingDefaultsForNPK.length ? (
                        <Trans
                            components={{ fertilisers: <SelectableFertilisers /> }}
                            count={existingDefaultsForNPK.length}
                            i18nKey="components.modal-create-fertiliser.npk-exist"
                        />
                    ) : (
                        ''
                    )}
                </div>
                <TwoButtonFooterView
                    leftOnClick={() => {
                        controller.onModalCancel();
                        formik.resetForm();
                        setExistingDefaultsForNPK([]);
                    }}
                    leftText={t('constants.cancel')}
                    rightLoading={formik.isSubmitting}
                    rightOnCLick={formik.handleSubmit}
                    rightText={t('constants.save')}
                />
            </Paper>
        </ModalCentered>
    );
};

export default ModalCreateFertiliser;
