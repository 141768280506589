import useLoader from '@hooks/useLoader';
import { Typography } from '@mui/material';
import { CheckCircle, CloseOutlined } from '@mui/icons-material';

import useSummaryPannelStyle from './SummaryPannel.style';
import { SummaryPannelPropsT } from './SummaryPannelTypes';

const SummaryPannel = ({
    colorTheme = 'secondary',
    children,
    title,
    subTitle,
    hasProperties,
    isLoading,
}: SummaryPannelPropsT): JSX.Element => {
    const { classes } = useSummaryPannelStyle({ colorTheme });

    const { containerRef } = useLoader(!!isLoading);

    return (
        <div ref={containerRef} className={classes.root}>
            <header className={classes.header}>
                <Typography className="title" component="h2" variant="h6">
                    {title}
                </Typography>
                <Typography variant="body1">{subTitle}</Typography>
                <div style={{ margin: 'auto' }} />
                {hasProperties?.map((prop) => (
                    <Typography key={prop.translatedSlug} className="hasProperty" variant="body1">
                        {prop.checked ? <CheckCircle className="iconCheck" /> : <CloseOutlined className="iconCheck" />}
                        {prop.translatedSlug}
                    </Typography>
                ))}
            </header>

            {/* Content */}
            <section className={classes.content}>{children}</section>
        </div>
    );
};

export default SummaryPannel;
