import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ModalPropsT } from '@soil-capital/ui-kit';

export interface ModalStateT {
    controller: ModalPropsT | null;
}

export const defaultModalNoFieldsSuggestionState: ModalStateT = {
    controller: null,
};

export const slice = createSlice({
    name: 'modalNoFieldsSuggestion',
    initialState: defaultModalNoFieldsSuggestionState,
    reducers: {
        keepController: (state, action: PayloadAction<ModalStateT['controller']>) => {
            state.controller = action.payload;
        },
    },
});

export const { keepController } = slice.actions;

export default slice.reducer;
