import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

import { FarmSeasonCertificateT, FarmSeasonPurchaseT } from './carbonBankApiServiceTypes';

class CarbonBankService {
    /** get certificates for each farmSeason */
    public static getCertificates = async (farmId: number): Promise<AxiosResponse<FarmSeasonCertificateT[]>> =>
        request({ method: 'GET', url: `/farm/${farmId}/certificates` });

    /** get payments for each farmSeason */
    public static getPayments = async (farmId: number): Promise<AxiosResponse<FarmSeasonPurchaseT[]>> =>
        request({ method: 'GET', url: `/farm/${farmId}/purchases` });
}

export default CarbonBankService;
