import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import MenuBar from '@components/MenuBar/MenuBar';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Prompt } from '@components/Prompt/Prompt';
import useLoader from '@hooks/useLoader';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import useAppDispatch from '@hooks/useAppDispatch';

import { DeleteFarmSeasonOperation } from '@actions/FarmSeasonOperation/DeleteFarmSeasonOperation';
import useAppNavigate from '@hooks/useAppNavigate';

import OperationCardForm from '../components/OperationCardForm/OperationCardForm';

import { TillageViewPropsT } from './TillageTypes';
import useTillageViewStyle from './TillageView.style';
import { LoadingButton, useModal } from '@soil-capital/ui-kit';
import { ModalWarning } from '@soil-capital/ui-kit';

const TillageView = ({
    currentCrop,
    currentTillage,
    tillageOperationForm,
    tillageMachineries,
    operationsDataLoading,
    submitted,
    farmId,
    farmSeasonId,
    canEdit = false,
}: TillageViewPropsT): JSX.Element => {
    const { classes } = useTillageViewStyle();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const modalDeleteOperation = useModal();

    const { containerRef: loaderRef } = useLoader(operationsDataLoading);

    return (
        <>
            <div ref={loaderRef} className={classes.container}>
                <Prompt
                    messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                    messageText={t('modal.navigation-prompt-if-unsaved.text')}
                    when={tillageOperationForm.dirty && !submitted}
                />
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={`${t(currentCrop?.crop?.translation_slug || '')} - ${t(
                        currentTillage?.translation_slug || '',
                    )}`}
                    onArrowBack={() => navigate(-1)}
                    onClose={() => navigate(-1)}
                />
                <Typography className={classes.title} color="secondary" variant="h4">
                    {t('pages.operations.tillage.title')}
                </Typography>
                <Typography color="secondary" variant="body1">
                    {t('page.operations.tillage.description')}
                    <HelpButtonView id="pages.operations.tillage.description" />
                </Typography>

                <div className={classes.formContainer}>
                    <div
                        className={`${classes.tillageTypeContainer} ${
                            tillageOperationForm.values?.dont_have_any ? classes.disabledZone : ''
                        }`}
                    >
                        <OperationCardForm
                            disabled={!canEdit}
                            form={tillageOperationForm}
                            machineries={tillageMachineries}
                            shouldAutoFocus={
                                !operationsDataLoading && tillageOperationForm.values.dont_have_any === false
                            }
                        />
                    </div>

                    {/* Checkbox dont_have_any */}
                    {canEdit && (
                        <LabelCheckboxView
                            disabled={!canEdit}
                            border="dotted"
                            checked={tillageOperationForm.values.dont_have_any}
                            error={
                                tillageOperationForm.touched.dont_have_any &&
                                !!tillageOperationForm.errors.dont_have_any
                            }
                            helperText={
                                tillageOperationForm.touched.dont_have_any
                                    ? tillageOperationForm.errors.dont_have_any
                                    : undefined
                            }
                            label={t('pages.operations.tillage.no-operation')}
                            name="dont_have_any"
                            onChange={tillageOperationForm.handleChange}
                        />
                    )}
                    <TwoButtonFooterView
                        leftOnClick={() =>
                            modalDeleteOperation.openModal(async () => {
                                if (!currentTillage.id || !currentCrop.id) {
                                    throw Error(
                                        'currentTillage.id and currentCrop.id are required to call "DeleteFarmSeasonOperation"',
                                    );
                                }
                                await dispatch(
                                    DeleteFarmSeasonOperation(
                                        farmId,
                                        farmSeasonId,
                                        'tillage',
                                        currentTillage.id,
                                        currentCrop.id,
                                    ),
                                );
                                navigate(-1);
                            })
                        }
                        leftText={canEdit ? t('constants.delete-operation') : undefined}
                        rightButtonOverride={
                            canEdit ? (
                                <LoadingButton
                                    loading={tillageOperationForm.isSubmitting}
                                    onClick={() => tillageOperationForm.handleSubmit()}
                                    text={t('constants.save')}
                                />
                            ) : null
                        }
                    />
                </div>
            </div>
            <ModalWarning
                controller={modalDeleteOperation}
                description={t('modal.delete-operation.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('modal.warning.title')}
            />
        </>
    );
};

export default TillageView;
