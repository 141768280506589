import request from '../apiService/apiService';
import {
    AnalyticsFarmPracticesResponseT,
    AnalyticsFarmSoilResponseT,
    AnalyticsFarmSummaryResponseT,
    GetFarmSummaryPropsT,
} from './analyticsApiService.types';

class AnalyticsApiService {
    public static getFarmSummary = ({ farmId }: GetFarmSummaryPropsT) => {
        return request<AnalyticsFarmSummaryResponseT>({
            method: 'GET',
            url: `/private/analytics/farm/${farmId}/summary`,
            baseURL: process.env.REACT_APP_PP_API_BASE_URL,
        });
    };

    public static getFarmSoil = ({ farmId }: GetFarmSummaryPropsT) => {
        return request<AnalyticsFarmSoilResponseT>({
            method: 'GET',
            url: `/private/analytics/farm/${farmId}/soil`,
            baseURL: process.env.REACT_APP_PP_API_BASE_URL,
        });
    };

    public static getFarmPractices = ({ farmId }: GetFarmSummaryPropsT) => {
        return request<AnalyticsFarmPracticesResponseT>({
            method: 'GET',
            url: `/private/analytics/farm/${farmId}/practices`,
            baseURL: process.env.REACT_APP_PP_API_BASE_URL,
        });
    };
}

export default AnalyticsApiService;
