import { makeStyles } from 'tss-react/mui';

const useCropsStyle = makeStyles()(() => ({
    relative: {
        position: 'relative',
        height: '100%',
    },
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        paddingTop: '20px',
        marginBottom: '20px',
    },
}));

export default useCropsStyle;
