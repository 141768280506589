/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from '@reduxjs/toolkit';
import authService from '@services/authService/authService';
import { AxiosError } from 'axios';

import {
    ResendVerifyEmailDispatchTypesT,
    RESEND_VERIFY_EMAIL_LOADING,
    RESEND_VERIFY_EMAIL_SUCCESS,
    RESEND_VERIFY_EMAIL_FAILED,
    ResendVerifyEmailResponseErrorT,
} from './ResendVerifyEmailActionsTypes';

const resendVerifyEmail =
    (): any => // eslint-disable-line
    async (dispatch: Dispatch<ResendVerifyEmailDispatchTypesT>): Promise<ResendVerifyEmailResponseErrorT> => {
        try {
            dispatch({
                type: RESEND_VERIFY_EMAIL_LOADING,
            });

            const res = await authService.resendVerifyEmail();

            dispatch({
                type: RESEND_VERIFY_EMAIL_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (e) {
            const error = e as AxiosError<string>;
            const list = error.response?.status !== 200 ? ['error'] : [];
            dispatch({
                type: RESEND_VERIFY_EMAIL_FAILED,
                error: {
                    message: error.response?.data || error.message,
                    list,
                },
            });

            return {
                message: error.response?.data || error.message,
                list,
            };
        }
    };

export default resendVerifyEmail;
