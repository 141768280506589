import { makeStyles } from 'tss-react/mui';

import { TableTotalCellStyleProps } from '@pages/HomePage/CarbonBank/components/TableTotalCell/TableTotalCellTypes';

const useTableTotalCellStyle = makeStyles<TableTotalCellStyleProps>()((theme, { size }) => ({
    TableTotalCell: {
        margin: size === 'medium' ? '-16px' : '-10px -6px', // TableCell has fixed padding 16 (or 10/6 for small fixed in CertificateTable!).
        padding: size === 'medium' ? '16px' : '10px 6px',
        height: size === 'medium' ? '52px' : '40px',
        backgroundColor: theme.palette.driftwood.main,
        color: theme.palette.secondary.contrastText,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    TableTotalCellHighlited: {
        backgroundColor: '#B07D4A',
    }
}));

export default useTableTotalCellStyle;
