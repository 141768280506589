import { Container } from '@mui/material';
import TopBar from '@pages/HomePage/components/TopBar/TopBar';
import SearchListWithBarView from '@components/SearchListWithBar/SearchListWithBarView';
import { useTranslation } from 'react-i18next';

import useSearchPageStyle from './SearchPageView.style';
import { SearchPageViewPropsT } from './SearchPageTypes';
import SearchPageStepView from './SearchPageStep/SearchPageStepView';
import { FarmerSearchT, FarmSearchT } from '@services/userApiService/userApiServiceTypes';

const SearchPageView = ({
    selectedFarmer,
    setSelectedFarmer,
    farmers,
    usersLoading,
    setUserQuery,
    onFarmSelect,
    userAuth,
    userQuery,
}: SearchPageViewPropsT): JSX.Element => {
    const { classes } = useSearchPageStyle();
    const { t } = useTranslation();

    return (
        <Container className={classes.container} maxWidth="xl">
            <div>
                <TopBar displayContextSelector={false} disableNotification />
            </div>
            <div className={classes.searchContainer}>
                {!selectedFarmer && (
                    <SearchPageStepView className={classes.searchSection} title={t('pages.search.farmer.title')}>
                        <SearchListWithBarView
                            id="farmer-search"
                            list={farmers}
                            loading={usersLoading}
                            mode="farmer"
                            placeholder={t('pages.search.farmer.search-placeholder')}
                            query={userQuery ?? undefined}
                            search={setUserQuery}
                            userAuth={userAuth}
                            onItemClick={(farmer) => setSelectedFarmer(farmer as FarmerSearchT)}
                        />
                    </SearchPageStepView>
                )}
                {selectedFarmer && (
                    <SearchPageStepView
                        className={classes.searchSection}
                        title={t('pages.search.farm.title')}
                        onBack={() => setSelectedFarmer(null)}
                    >
                        <SearchListWithBarView
                            id="farm-search"
                            list={selectedFarmer.farms || []}
                            loading={false}
                            mode="farm"
                            placeholder={t('pages.search.farm.search-placeholder')}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            search={() => {}}
                            userAuth={userAuth}
                            onItemClick={(farm) =>
                                onFarmSelect({ farmSelected: farm as FarmSearchT, userSelected: selectedFarmer })
                            }
                        />
                    </SearchPageStepView>
                )}
            </div>
        </Container>
    );
};

export default SearchPageView;
