import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

/** tell if agronomist or higher admin level can finalize the season */
const useCanFinalizeSeason = (): boolean => {
    // All operation are done if we don't find a not done operation through all field_crop
    const isAllOperationDone = useSelector(
        (state: AppStateT) =>
            !state.farmSeasonField.fieldsList
                ?.filter(
                    (field) => !field.field_crops?.some((field_crop) => field_crop.farm_season_crop.crop.is_permanent),
                )
                .find((field) => {
                    // Find a not done operation:
                    const notDoneForField = !!field.field_crops?.find(
                        (fieldCrop) =>
                            !(
                                fieldCrop.operation_fertilising_done &&
                                fieldCrop.operation_sowing_done &&
                                fieldCrop.operation_spraying_done &&
                                fieldCrop.operation_tillage_done
                            ),
                    );

                    return notDoneForField;
                }),
    );

    const isInstallationDone = useSelector(
        (state: AppStateT) => !!state.farmSeason.currentFarmSeason?.installation_validated_at,
    );

    return isAllOperationDone && isInstallationDone;
};

export default useCanFinalizeSeason;
