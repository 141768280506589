import { AxiosResponse } from 'axios';
import request from '../apiService/apiService';
import { UserInvitationI } from './partnerInvitationApiServiceTypes';

class PartnerInvitationApiService {
    public static getPendingInvitation = async (email: string): Promise<AxiosResponse<UserInvitationI>> =>
        request({
            url: `${process.env.REACT_APP_PP_API_BASE_URL}/private/invitations/partner/pending?email=${email}`,
            method: 'GET',
        });
}

export default PartnerInvitationApiService;
