/**
 * Find an item in a list by it's id
 * @param id The id to retrieve the item
 * @param list List of items, should contain the item.id we want to find
 * @returns The item found or undefined
 */
const findById = <T extends { id: number }>(id: number, list: T[]): T | undefined =>
    list?.find((item) => item.id === id);

export default findById;
