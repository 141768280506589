import { useModal } from '@soil-capital/ui-kit';
import { useState } from 'react';
import FieldNameAreaModal from './FieldNameAreaModal';
import { FieldNameAreaDataT, FieldNameAreaRespT } from './FieldNameAreaModalTypes';

const useFieldNameAreaModal = () => {
    const [fieldData, setFieldData] = useState<FieldNameAreaDataT>();
    const [actionName, setActionName] = useState<'create' | 'edit'>();
    const modalController = useModal<FieldNameAreaRespT>();

    const openModal = (
        action: 'create' | 'edit',
        fieldParam?: FieldNameAreaDataT,
        confirmCallbackBeforeCloas?: (props: FieldNameAreaRespT) => Promise<void>,
    ) => {
        setFieldData(fieldParam ? { ...fieldParam } : undefined);
        setActionName(action);
        return new Promise<FieldNameAreaRespT | false>(async (res) => {
            await modalController.openModal(
                async (fieldNameAreaData) => {
                    await confirmCallbackBeforeCloas?.(fieldNameAreaData);
                    res(fieldNameAreaData);
                },
                () => res(false),
            );
        });
    };

    return {
        fieldNameAreaModalController: {
            /** @param fieldParam no fieldParam will display a loader. */
            openCreateFieldModal: (
                fieldParam?: FieldNameAreaDataT,
                confirmCallbackBeforeClose?: (props: FieldNameAreaRespT) => Promise<void>,
            ) => openModal('create', fieldParam, confirmCallbackBeforeClose),
            openEditFieldModal: (
                fieldParam: FieldNameAreaDataT,
                confirmCallbackBeforeClose?: (props: FieldNameAreaRespT) => Promise<void>,
            ) => openModal('edit', fieldParam, confirmCallbackBeforeClose),
        },
        FieldNameAreaModal: (
            <FieldNameAreaModal modalController={modalController} fieldData={fieldData} actionName={actionName} />
        ),
    };
};

export default useFieldNameAreaModal;
