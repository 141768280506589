import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

export const FARM_SEASON_OPEN_NEXT_FARM_SEASON_FAILED = 'FARM_SEASON_CREATE_NEXT_FARM_SEASON_FAILED';
export const FARM_SEASON_OPEN_NEXT_FARM_SEASON_LOADING = 'FARM_SEASON_CREATE_NEXT_FARM_SEASON_LOADING';
export const FARM_SEASON_OPEN_NEXT_FARM_SEASON_SUCCESS = 'FARM_SEASON_CREATE_NEXT_FARM_SEASON_SUCCESS';

export type FarmSeasonOpenNextFarmSeasonFailedDataT = {
    message?: string;
};

export type FarmSeasonOpenNextFarmSeasonLoadingT = {
    type: typeof FARM_SEASON_OPEN_NEXT_FARM_SEASON_LOADING;
};
export type FarmSeasonOpenNextFarmSeasonSuccessT = {
    type: typeof FARM_SEASON_OPEN_NEXT_FARM_SEASON_SUCCESS;
    payload: { nextFarmSeason: FarmSeasonT };
};
export type FarmSeasonOpenNextFarmSeasonFailedT = {
    type: typeof FARM_SEASON_OPEN_NEXT_FARM_SEASON_FAILED;
    error: FarmSeasonOpenNextFarmSeasonFailedDataT;
};

export type FarmSeasonOpenNextFarmSeasonDispatchTypesT =
    | FarmSeasonOpenNextFarmSeasonLoadingT
    | FarmSeasonOpenNextFarmSeasonSuccessT
    | FarmSeasonOpenNextFarmSeasonFailedT;
