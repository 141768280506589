import { makeStyles } from 'tss-react/mui';

const useAddButtonViewStyle = makeStyles()(() => ({
    addIcon: {
        fontSize: '1.2rem',
        margin: '-3px 10px -2px 0',
    },
}));

export default useAddButtonViewStyle;
