/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import MapService from '@services/mapService/mapService';
import irrigationMethodGetListAction from '@actions/StaticActions/IrrigationMethodGetListAction';
import irrigationWaterSourceGetListAction from '@actions/StaticActions/IrrigationWaterSourceGetListAction';
import irrigationPowerSourceGetListAction from '@actions/StaticActions/IrrigationPowerSourceGetListAction';

import { FieldDataT } from '../IrrigationTaskTypes';

import { IrrigationTaskContextT } from './IrrigationTaskContextType';

export const IrrigationContext = createContext({} as any);

const IrrigationTaskContext = ({ children }: IrrigationTaskContextT): JSX.Element => {
    const { t } = useTranslation();
    const [stepArray, setStepArray] = useState([1]);
    const [currentStep, setCurrentStep] = useState(stepArray[stepArray.length - 1]);
    const [fieldIrrigated, setFieldIrrigated] = useState<FieldDataT>();

    const fieldLoading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const irrigationMethodList = useSelector((state: HomePageStateT) => state.static.irrigationMethodList);
    const irrigationWaterSourceList = useSelector((state: HomePageStateT) => state.static.irrigationWaterSourceList);
    const irrigationPowerSourceList = useSelector((state: HomePageStateT) => state.static.irrigationPowerSourceList);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm)?.id;
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason)?.id;
    const fieldsList = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);

    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!(irrigationMethodList?.length > 0)) dispatch(irrigationMethodGetListAction());

        if (!(irrigationWaterSourceList?.length > 0)) dispatch(irrigationWaterSourceGetListAction());

        if (!(irrigationPowerSourceList?.length > 0)) dispatch(irrigationPowerSourceGetListAction());
    }, []);

    useEffect(() => {
        setCurrentStep(stepArray[stepArray.length - 1]);
    }, [stepArray]);

    const pushStep = (step: any) => {
        setStepArray((state) => [...state, step]);
    };

    const popStep = () => {
        setStepArray((state) => [...state.splice(0, state.length - 1)]);
    };

    const handleBackButton = () => {
        if (currentStep > 1) {
            setStepArray((state) => {
                const newState = [...state];
                newState.pop();

                return newState;
            });
        }
    };

    const store = {
        currentStep,
        pushStep,
        handleBackButton,
        popStep,
        t,
        fieldIrrigated,
        setFieldIrrigated,
        irrigationMethodList,
        fieldLoading,
        irrigationWaterSourceList,
        irrigationPowerSourceList,
        farmId,
        farmSeasonId,
        fieldsList,
        thisMap,
    };

    return <IrrigationContext.Provider value={store}>{children}</IrrigationContext.Provider>;
};
export default IrrigationTaskContext;
