import { makeStyles } from 'tss-react/mui';

const useSubscriptionInfoCardStyle = makeStyles()(() => ({
    actionButton: {
        marginRight: '8px',
    },
    gridContainer: {
        marginBottom: '16px',
    },
    // Allows using "\n" in strings
    preline: {
        whiteSpace: 'pre-line',
    },
    divider: {
        marginBottom: '16px',
    },
    contextBar: {
        '> *': {
            marginBottom: '16px',
        },
        '> *:last-child': {
            marginBottom: '0px',
        },
    },
}));

export default useSubscriptionInfoCardStyle;
