/* eslint-disable @typescript-eslint/ban-types */
import { Dispatch } from '@reduxjs/toolkit';

import { MAP_UPDATE, MapUpdateT } from './MapActionsTypes';

export const mapUpdate =
    ({ payload }: MapUpdateT): ((dispatch: Dispatch<MapUpdateT>) => {}) =>
    (dispatch) => {
        dispatch({
            type: MAP_UPDATE,
            payload,
        });

        return {};
    };

export default mapUpdate;
