import dots1 from '@patterns/dots-1.svg?path';
import dots2 from '@patterns/dots-2.svg?path';
import dots3 from '@patterns/dots-3.svg?path';
import dots4 from '@patterns/dots-4.svg?path';

const soilAcidities = [
    {
        id: 1,
        name: 'constants.soil-acidity-1',
        pattern: dots1,
        mapPattern: 'dots1',
    },
    {
        id: 2,
        name: 'constants.soil-acidity-2',
        pattern: dots2,
        mapPattern: 'dots2',
    },
    {
        id: 3,
        name: 'constants.soil-acidity-3',
        pattern: dots3,
        mapPattern: 'dots3',
    },
    {
        id: 4,
        name: 'constants.soil-acidity-4',
        pattern: dots4,
        mapPattern: 'dots4',
    },
];

export default soilAcidities;
