import Logo from '@images/logo-my-soilcapital.png';

import useNavLogoStyle from './NavLogo.style';

const NavLogoView = (): JSX.Element => {
    const { classes } = useNavLogoStyle();

    return <img alt="" className={classes.logoImg} src={Logo} />;
};

export default NavLogoView;
