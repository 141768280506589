import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FieldsSubtasksView from './FieldsSubtasksView';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

const FieldsSubtasks = (): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();

    const agroforestryDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.agroforestry?.done,
    );
    const drainageDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.drainage?.done,
    );
    const irrigationDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.irrigation?.done,
    );
    const organicDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.organic?.done,
    );
    const soilAcidityDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.soilAcidity?.done,
    );
    const soilOrganicMatterDone = useSelector(
        (state: HomePageStateT) =>
            state.farmSeason.currentFarmSeason?.installation?.onboarding?.soilOrganicMatter?.done,
    );
    const soilOrganicCarbonDone = useSelector(
        (state: HomePageStateT) =>
            state.farmSeason.currentFarmSeason?.installation?.onboarding?.soilOrganicCarbon?.done,
    );
    const tillageDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.tillage?.done,
    );
    const coverCropDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.coverCrop?.done,
    );
    const residueDone = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.residue?.done,
    );
    const onboarding = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding,
    );

    const isCFT = useIsCFTCarbonModel();

    const isBaseline = useSelector(selectIsBaselineSeason());

    const generalTasks = [
        true, // "create your farms"
        onboarding?.crop?.done || false,
        onboarding?.field?.done || false,
        onboarding?.livestock?.done || false,
    ];
    const generalTasksTotal = generalTasks.length;
    const generalTasksDoneCount = generalTasks.filter((isDone) => isDone).length;

    const fieldsSubtaskItems = [
        onboarding?.agroforestry?.done || false,
        onboarding?.drainage?.done || false,
        onboarding?.irrigation?.done || false,
        onboarding?.soilAcidity?.done || false,
        onboarding?.soilOrganicMatter?.done || false,
        ...(isCFT ? [onboarding?.organic?.done || false] : []),
        ...(isCFT ? [onboarding?.tillage?.done || false] : []),
        ...(isCFT ? [onboarding?.coverCrop?.done || false] : []),
        ...(isCFT ? [onboarding?.residue?.done || false] : []),
    ];
    const fieldsSubtaskItemsTotal = fieldsSubtaskItems.length;
    const fieldsSubtaskItemsDoneCount = fieldsSubtaskItems.filter((isDone) => isDone).length;

    const historyTasks = [
        onboarding?.pastureHistory?.done,
        onboarding?.coverCropHistory?.done,
        onboarding?.tillagePracticeHistory?.done,
        onboarding?.organicFertilizationHistory?.done,
    ];
    const historySubtaskItemsTotal = historyTasks.length;
    const historySubtaskItemsDoneCount = historyTasks.filter((isDone) => isDone).length;

    // general tasks + fields subtasks + history subtasks
    const allTasksTotal = generalTasksTotal + fieldsSubtaskItemsTotal + historySubtaskItemsTotal;
    const allTasksDoneCount = generalTasksDoneCount + fieldsSubtaskItemsDoneCount + historySubtaskItemsDoneCount;

    const subtaskItems = [
        {
            name: t('pages.installationsubtasks.organic-matter'),
            hidden: !(isCFT && isBaseline),
            done: soilOrganicMatterDone ?? false,
            link: {
                to: `${location.pathname}/organicMatter`,
            },
        },
        {
            name: t('pages.installationsubtasks.organic-carbon'),
            hidden: !(!isCFT && isBaseline),
            done: soilOrganicCarbonDone ?? false,
            link: {
                to: `${location.pathname}/organicCarbon`,
            },
        },
        {
            name: t('pages.installationsubtasks.ph'),
            hidden: !isBaseline,
            done: soilAcidityDone ?? false,
            link: {
                to: `${location.pathname}/acidity`,
            },
        },
        {
            name: t('pages.installationsubtasks.tillage-practice'),
            hidden: !isCFT,
            done: tillageDone ?? false,
            link: {
                to: `${location.pathname}/tillage`,
            },
        },
        {
            name: t('pages.installationsubtasks.cover-crops'),
            hidden: !isCFT,
            done: coverCropDone ?? false,
            link: {
                to: `${location.pathname}/coverCrop`,
            },
        },
        {
            name: t('pages.installationsubtasks.organic-fields'),
            hidden: !isCFT,
            done: organicDone ?? false,
            link: {
                to: `${location.pathname}/organic`,
            },
        },
        {
            name: t('pages.installationsubtasks.drainage'),
            done: drainageDone ?? false,
            link: {
                to: `${location.pathname}/drainage`,
            },
        },
        {
            name: t('pages.installationsubtasks.irrigation'),
            done: irrigationDone ?? false,
            link: {
                to: `${location.pathname}/irrigation`,
            },
        },
        {
            name: t('pages.installationsubtasks.agroforestry'),
            done: agroforestryDone ?? false,
            link: {
                to: `${location.pathname}/agroforestry`,
            },
        },
        {
            name: t('pages.installationsubtasks.residue'),
            hidden: !isCFT,
            done: residueDone ?? false,
            link: {
                to: `${location.pathname}/residue`,
            },
        },
    ];

    const subTaskItemsToDo = subtaskItems.filter((subtaskItem) => subtaskItem.done === false && !subtaskItem.hidden);
    const subTaskItemsDone = subtaskItems.filter((subtaskItem) => subtaskItem.done === true && !subtaskItem.hidden);
    const totalProgressPercentage = (allTasksDoneCount / allTasksTotal) * 100;

    return (
        <FieldsSubtasksView
            subTaskItemsDone={subTaskItemsDone}
            subTaskItemsToDo={subTaskItemsToDo}
            t={t}
            totalProgressPercentage={totalProgressPercentage}
        />
    );
};

export default FieldsSubtasks;
