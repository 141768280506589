import useAppDispatch from '@hooks/useAppDispatch';
import useOperationDone from '@hooks/useOperationDone';
import { GetHasAnyOperationDoneFiltersT } from '@hooks/useOperationDoneTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import {
    FarmSeasonFieldIrrigationT,
    FarmSeasonFieldT,
    FarmSeasonFieldTillagePracticeHistoryT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';
import { useModal } from '@soil-capital/ui-kit';
import addPolygonData from '@utils/addPolygonData';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { FieldsContextStore } from '../FieldsContext/FieldsContext';
import { FieldsContextStoreT } from '../FieldsContext/FieldsContextTypes';

import {
    useAgroforestryCardFormValidations,
    useAgroforestryCardFormValues,
} from '@components/AgroforestryCardForm/AgroforestryCardForm.hooks';
import { postFarmSeasonField } from '@store/actions/FarmSeasonFieldActions/PostFarmSeasonField';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import { Navigate } from 'react-router-dom';
import { FieldFormDataT } from './EditFieldTypes';
import EditFieldView from './EditFieldView';
import { ROLE_ID } from '@constants/roleIds';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

const EditField = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const { selectedField, t, setSelectedField } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const userRoleId = useSelector((state: HomePageStateT) => state.auth.user?.role_id);
    const isFarmer = userRoleId === ROLE_ID.FARMER;
    const cropsList = useSelector((state: HomePageStateT) => state.farmSeasonCrop.cropsList);
    const controllerWarningOperationDone = useModal();
    const controllerWarningIsPermanent = useModal();
    const isBaseline = useSelector(selectIsBaselineSeason());
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const isCFT = useIsCFTCarbonModel();

    const dontHavePermanentCrop = !selectedField?.field_crops?.some(
        (fieldCrop) => fieldCrop.farm_season_crop.crop.is_permanent,
    );

    const { getHasAnyOperationDone } = useOperationDone();

    useEffect(() => {
        const cropColorPalette = setupCropPaletteFromCropLegacy(cropsList);

        setTimeout(() => {
            // Wait for HomePageLayout -> MapService.setupOffset update before focus
            if (cropColorPalette.length && selectedField?.polygon) {
                MapService.addPolygons({
                    polygons: [{ ...addPolygonData({ field: selectedField, colorPalette: cropColorPalette }) }],
                    autofocus: true,
                });
            }
        }, 1);
    }, [selectedField, cropsList]);

    useEffect(() => {
        return () => {
            setSelectedField(null);
            MapService.addPolygons({
                polygons: fields.map((f) =>
                    addPolygonData({ field: f, colorPalette: setupCropPaletteFromCropLegacy(cropsList) }),
                ),
                autofocus: true,
            });
        };
    }, []);

    const { agroforestryCardFormValues, previousSeasonTreesMap, getAgroforestryValuesToSubmit } =
        useAgroforestryCardFormValues({
            field: selectedField,
        });
    const { agroforestryCardFormValidation } = useAgroforestryCardFormValidations({ previousSeasonTreesMap });

    // The form validationSchema schema
    const validationSchema = Yup.object({
        soil_organic_matter_id: isBaseline
            ? Yup.string().required(t('validation.mandatory-field'))
            : Yup.string().nullable(),
        soil_acidity_id: isBaseline ? Yup.string().required(t('validation.mandatory-field')) : Yup.string().nullable(),
        tillage_id:
            dontHavePermanentCrop && isCFT
                ? Yup.string().required(t('validation.mandatory-field'))
                : Yup.string().nullable(true),
        has_cover_crops: isCFT ? Yup.boolean() : Yup.boolean().nullable(),
        is_organic: isCFT ? Yup.boolean() : Yup.boolean().nullable(),
        has_drainage: Yup.boolean(),

        irrigation: Yup.object().shape({
            is_irrigated: Yup.boolean(),
            horizontal_distance: Yup.string().when('is_irrigated', {
                is: true,
                then: Yup.string()
                    .required(t('validation.mandatory-field'))
                    .test('bigger-than-0', t('validation.must-be-greater-than-x', { x: 0 }), (horizontal_distance) =>
                        horizontal_distance ? +horizontal_distance > 0 : false,
                    ),
            }),
            irrigation_method_id: Yup.string().when('is_irrigated', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
            irrigation_power_source_id: Yup.string().when('is_irrigated', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
            irrigation_water_source_id: Yup.string().when('is_irrigated', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
            pumping_depth: Yup.string().when('is_irrigated', {
                is: true,
                then: Yup.string()
                    .required(t('validation.mandatory-field'))
                    .test('bigger-than-0', t('validation.must-be-greater-than-x', { x: 0 }), (pumping_depth) =>
                        pumping_depth ? +pumping_depth > 0 : false,
                    ),
            }),
            total_amount: Yup.string().when('is_irrigated', {
                is: true,
                then: Yup.string()
                    .required(t('validation.mandatory-field'))
                    .test('bigger-than-0', t('validation.must-be-greater-than-x', { x: 0 }), (total_amount) =>
                        total_amount ? +total_amount > 0 : false,
                    ),
            }),
        }),
        agroforestry: Yup.object().shape({
            has_agroforestry: Yup.boolean(),
            trees: Yup.array().when('has_agroforestry', {
                is: true,
                then: Yup.array().of(agroforestryCardFormValidation),
            }),
        }),
        tillage_practice_history: Yup.object().shape({
            has_tillage_practice_history: Yup.boolean(),
            tillage_change_type_id: Yup.string().when('has_tillage_practice_history', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
            year_of_change: Yup.string().when('has_tillage_practice_history', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
            year_end_labour: Yup.string().when(['tillage_change_type_id'], (tillage_change_type_id, schema) =>
                ['1', '3'].includes(tillage_change_type_id)
                    ? schema
                          .required(t('validation.mandatory-field'))
                          .test(
                              'not-bigger-than',
                              t(
                                  'pages.installationtasks.history.tillage-practice.history-form.year-end-labour-validation',
                              ),
                              (value: string, context: { parent: FarmSeasonFieldTillagePracticeHistoryT }) =>
                                  +value < +context.parent.year_of_change,
                          )
                    : schema,
            ),
        }),
        cover_crop_history: Yup.object().shape({
            has_cover_crop_history: Yup.boolean(),
            year_introduction: Yup.string().when('has_cover_crop_history', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
            frequency: Yup.number().when('has_cover_crop_history', {
                is: true,
                then: Yup.number()
                    .min(1, t('validation.min-max-years'))
                    .max(20, t('validation.min-max-years'))
                    .required(t('validation.mandatory-field')),
            }),
        }),
        organic_fertilization_history: Yup.object().shape({
            has_organic_fertilization_history: Yup.boolean(),
            year_introduction: Yup.string().when('has_organic_fertilization_history', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
            frequency: Yup.number().when('has_organic_fertilization_history', {
                is: true,
                then: Yup.number()
                    .min(1, t('validation.min-max-years'))
                    .max(20, t('validation.min-max-years'))
                    .required(t('validation.mandatory-field')),
            }),
        }),
        pasture_history: Yup.object().shape({
            has_pasture_history: Yup.boolean(),
            year_of_change: Yup.string().when('has_pasture_history', {
                is: true,
                then: Yup.string().required(t('validation.mandatory-field')),
            }),
        }),
        field_crops: Yup.array().min(1, t('validation.min-1-crop')),
    });

    // Formik Form
    if (!selectedField?.original_area) {
        throw Error("field to edit don't have original_area");
    }
    const formik = useFormik<FieldFormDataT>({
        initialValues: {
            name: selectedField?.name ?? t('pages.fields.edit-field.field-name'),
            // as BE area is = user_area ?? area, we use the original_area (DB area) to set user_area
            user_area: selectedField?.original_area === selectedField?.area ? null : selectedField?.area ?? null,
            original_area: selectedField?.original_area,
            soil_organic_matter_id: selectedField?.soil_organic_matter_id?.toString() ?? '',
            soil_acidity_id: selectedField?.soil_acidity_id?.toString() ?? '',
            tillage_id: selectedField?.tillage_id?.toString() ?? '',
            is_tillage_permanent: selectedField?.is_tillage_permanent ?? false,
            has_cover_crops: selectedField?.has_cover_crops ?? false,
            is_organic: selectedField?.is_organic ?? false, // TODO: change the value in the database to a boolean
            has_drainage: selectedField?.has_drainage ?? false,
            irrigation: {
                is_irrigated: selectedField?.is_irrigated ?? false,
                id: selectedField?.irrigation?.id,
                horizontal_distance: selectedField?.irrigation?.horizontal_distance?.toString() ?? '',
                irrigation_method_id: selectedField?.irrigation?.irrigation_method_id?.toString() ?? '',
                irrigation_power_source_id: selectedField?.irrigation?.irrigation_power_source_id?.toString() ?? '',
                irrigation_water_source_id: selectedField?.irrigation?.irrigation_water_source_id?.toString() ?? '',
                pumping_depth: selectedField?.irrigation?.pumping_depth?.toString() ?? '',
                total_amount: selectedField?.irrigation?.total_amount?.toString() ?? '',
            },
            agroforestry: {
                has_agroforestry: selectedField?.has_agroforestry ?? false,
                trees: agroforestryCardFormValues,
            },
            tillage_practice_history: {
                has_tillage_practice_history: selectedField?.has_tillage_practice_history ?? false,
                id: selectedField?.tillage_practice_history?.id,
                tillage_change_type_id:
                    selectedField?.tillage_practice_history?.tillage_change_type_id?.toString() ?? '',
                year_of_change: selectedField?.tillage_practice_history?.year_of_change ?? '',
                year_end_labour: selectedField?.tillage_practice_history?.year_end_labour ?? '',
            },
            cover_crop_history: {
                has_cover_crop_history: selectedField?.has_cover_crop_history ?? false,
                id: selectedField?.cover_crop_history?.id,
                year_introduction: selectedField?.cover_crop_history?.year_introduction ?? '',
                frequency: selectedField?.cover_crop_history?.frequency?.toString() ?? '',
            },
            organic_fertilization_history: {
                has_organic_fertilization_history: selectedField?.has_organic_fertilization_history ?? false,
                id: selectedField?.organic_fertilization_history?.id,
                year_introduction: selectedField?.organic_fertilization_history?.year_introduction ?? '',
                frequency: selectedField?.organic_fertilization_history?.frequency.toString() ?? '',
            },
            pasture_history: {
                has_pasture_history: selectedField?.has_pasture_history ?? false,
                id: selectedField?.pasture_history?.id,
                year_of_change: selectedField?.pasture_history?.year_of_change ?? '',
            },
            field_crops: selectedField?.field_crops ?? [],
            previous_farm_season_field: selectedField?.previous_farm_season_field,
            is_permanent: selectedField?.is_permanent ?? false,
        },
        validationSchema,
        enableReinitialize: true,

        // Submit the form
        onSubmit: async (values: FieldFormDataT) => {
            if (!selectedField || !farm || !farmSeason) return;

            const field: Partial<FarmSeasonFieldT> = {
                ...selectedField,
                name: values.name,
                user_area: values.user_area,
                soil_organic_matter_id: +values.soil_organic_matter_id,
                soil_acidity_id: +values.soil_acidity_id,
                tillage_id: +values.tillage_id,
                is_tillage_permanent: values.is_tillage_permanent,
                has_cover_crops: values.has_cover_crops,
                is_organic: values.is_organic,
                has_drainage: values.has_drainage,

                is_irrigated: values.irrigation.is_irrigated,
                irrigation: {
                    ...(selectedField.irrigation as FarmSeasonFieldIrrigationT),
                    id: values.irrigation.id as number,
                    horizontal_distance: +values.irrigation.horizontal_distance,
                    irrigation_method_id: +values.irrigation.irrigation_method_id,
                    irrigation_power_source_id: +values.irrigation.irrigation_power_source_id,
                    irrigation_water_source_id: +values.irrigation.irrigation_water_source_id,
                    pumping_depth: +values.irrigation.pumping_depth,
                    total_amount: +values.irrigation.total_amount,
                },
                has_agroforestry: values.agroforestry.has_agroforestry,
                agroforestry: getAgroforestryValuesToSubmit(values.agroforestry.trees),
                has_tillage_practice_history: values.tillage_practice_history.has_tillage_practice_history,
                tillage_practice_history: {
                    id: values.tillage_practice_history.id,
                    farm_season_field_id: selectedField.id as number,
                    tillage_change_type_id: +values.tillage_practice_history.tillage_change_type_id,
                    year_end_labour: values.tillage_practice_history.year_end_labour,
                    year_of_change: values.tillage_practice_history.year_of_change,
                },
                has_cover_crop_history: values.cover_crop_history.has_cover_crop_history,
                cover_crop_history: {
                    id: values.cover_crop_history.id,
                    farm_season_field_id: selectedField.id as number,
                    frequency: +values.cover_crop_history.frequency,
                    year_introduction: values.cover_crop_history.year_introduction,
                },
                has_organic_fertilization_history:
                    values.organic_fertilization_history.has_organic_fertilization_history,
                organic_fertilization_history: {
                    id: values.organic_fertilization_history.id,
                    farm_season_field_id: selectedField.id as number,
                    frequency: +values.organic_fertilization_history.frequency,
                    year_introduction: values.organic_fertilization_history.year_introduction,
                },
                has_pasture_history: values.pasture_history.has_pasture_history,
                pasture_history: {
                    id: values.pasture_history.id,
                    farm_season_field_id: selectedField.id as number,
                    year_of_change: values.pasture_history.year_of_change,
                },
                field_crops: values.field_crops,
            };

            await dispatch(postFarmSeasonField(farm.id, farmSeason.id, { field }, !dontHavePermanentCrop));
        },
    });

    /**
     * check if any operation is done on the farmSeasonField. And display a modal if it's the case.
     * @returns true if any operation is done and modal displayed.
     */
    const warningOperationDone = (filters: GetHasAnyOperationDoneFiltersT) => {
        if (
            isFarmer && // MSC-2767 let the agronomist change the tillage, as it is possible via the mini-onboardings (still in test though)
            getHasAnyOperationDone({
                ...filters,
                farmSeasonFieldId: selectedField?.id,
            })
        ) {
            controllerWarningOperationDone.openModal();

            return true;
        }

        return false;
    };

    const warningPermanentCrop = (field: FieldFormDataT) => {
        if (field.is_permanent) {
            controllerWarningIsPermanent.openModal();

            return true;
        }

        return false;
    };

    if (!selectedField) {
        return <Navigate to="/fields" replace />;
    }
    return (
        <EditFieldView
            controllerWarningOperationDone={controllerWarningOperationDone}
            formik={formik}
            selectedField={selectedField as FarmSeasonFieldT}
            warningOperationDone={warningOperationDone}
            previousSeasonTreesMap={previousSeasonTreesMap}
            dontHavePermanentCrop={dontHavePermanentCrop}
            controllerWarningIsPermanent={controllerWarningIsPermanent}
            warningPermanentCrop={warningPermanentCrop}
        />
    );
};

export default EditField;
