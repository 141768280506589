import { Paper, Typography } from '@mui/material';
import React from 'react';
import useInfoPaperStyle from './InfoPaper.style';

const InfoPaper: React.FC<{ children: React.ReactNode; title: string }> = ({ children, title }) => {
    const { classes } = useInfoPaperStyle();

    return (
        <Paper className={classes.container} variant="elevation" elevation={0}>
            <Typography className={classes.title} color="secondary.dark" variant="h5">
                {title}
            </Typography>
            {children}
        </Paper>
    );
};

export default InfoPaper;
