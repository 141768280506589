export type NotificationReducerStateT = {
    markAllNotifAsReadLoading: boolean;
    /** ids of the loading notifications */
    markNotifAsReadLoadings: string[];
    fetchNotifLoading: boolean;
    fetchMoreNotifLoading: boolean;

    notificationsList: NotificationT[];
    snackbarStack: NotificationT[];
    notificationsCount: number;
    unreadNotificationsCount: number;
};

export enum NotificationTypes {
    RESULTS_GENERATION = 'App\\Notifications\\ResultGenerationNotification',
}

export enum ResultsGenerationStatus {
    READY = 'RESULTS_GENERATION_READY',
    REQUESTED = 'RESULTS_GENERATION_REQUESTED',
    SUCCESSED = 'RESULTS_GENERATION_SUCCESSED',
    FAILED = 'RESULTS_GENERATION_FAILED',
    IN_PROGRESS = 'RESULTS_GENERATION_IN_PROGRESS',
}

export type NotificationDataT = {
    [NotificationTypes.RESULTS_GENERATION]: {
        status: ResultsGenerationStatus;
        farmSeasonId: number;
        farmerId: number;
        farmerFirstName: string;
        farmerLastName: string;
        generationStartedAt: string;
    };
};

export type NotificationT = {
    id: string;
    type: NotificationTypes.RESULTS_GENERATION;
    data: NotificationDataT[NotificationTypes.RESULTS_GENERATION];
    notifiable_id: number;
    notifiable_type: 'App\\Models\\User';
    created_at: string;
    read_at?: string;
};
