import FarmSeasonFieldsCropsResidueApiService from '@services/farmSeasonFieldsCropsResidueApiService/farmSeasonFieldsCropsResidueApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsCropsResiduePostCallBackT,
    PostFarmSeasonFieldsCropsResidueDataT,
    PostFarmSeasonFieldsCropsResidueDispatchTypesT,
    PostFarmSeasonFieldsCropsResidueResponseDataT,
    PostFarmSeasonFieldsCropsResidueResponseErrorT,
    POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_FAILED,
    POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_LOADING,
    POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS,
} from './PostFarmSeasonFieldsCropsResidueTypes';

export const postFarmSeasonFieldsCropsResidue =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsCropsResidueDataT,
    ): FarmSeasonFieldsCropsResiduePostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsCropsResidueDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsCropsResidueResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsCropsResidueResponseDataT> =
                await FarmSeasonFieldsCropsResidueApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_FAILED,
                error: error as PostFarmSeasonFieldsCropsResidueResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
