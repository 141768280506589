import { makeStyles } from 'tss-react/mui';

import { NotificationFooterStylePropsT } from './NotificationFooterTypes';

const useNotificationFooterStyle = makeStyles<NotificationFooterStylePropsT>()((theme, { visible }) => ({
    NotificationFooter: {
        transition: 'all ease .2s',
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'unset' : 'none',
    },
}));

export default useNotificationFooterStyle;
