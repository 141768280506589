import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import formatNumber from '@utils/formatNumber';

/**
 *
 * @param date
 * @returns relativeDate:
 * - < 5m => now
 * - < 1h => 15 minutes ago
 * - < 10h => 8 hours ago
 * - null
 * fullDate:
 */
const useRelativeDate = (date: Date) => {
    const { t } = useTranslation();
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [relativeDate, setRelativeDate] = useState<string | null>(null);

    // Update the relative date every minute
    useEffect(() => {
        if (!date) {
            return undefined;
        }

        const clockInterval = setInterval(() => {
            setCurrentDate(new Date());
        }, 60000);

        return () => clearInterval(clockInterval);
    }, []);

    useEffect(() => {
        if (!date) {
            return;
        }

        let timeCount: string;
        const diffTime = (currentDate.getTime() - date.getTime()) / 1000;

        switch (true) {
            case diffTime / 60 < 1: // < 1min
                setRelativeDate(t('time.now'));
                break;
            case diffTime / 60 < 60: // < 60min
                timeCount = formatNumber(diffTime / 60, 0);
                setRelativeDate(t('time.minutes-ago', { time: timeCount, count: +timeCount }));
                break;
            case diffTime / (60 * 60) < 10: // < 10h
                timeCount = formatNumber(diffTime / (60 * 60), 0);
                setRelativeDate(t('time.hours-ago', { time: timeCount, count: +timeCount }));
                break;
            default:
                setRelativeDate(null);
        }
    }, [currentDate, date]);

    const fullDate = date?.toLocaleDateString(i18n.resolvedLanguage ?? navigator.language, {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
    });

    return { relativeDate, fullDate };
};

export default useRelativeDate;
