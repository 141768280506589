export const RESEND_VERIFY_EMAIL_LOADING = 'RESEND_VERIFY_EMAIL_LOADING';
export const RESEND_VERIFY_EMAIL_FAILED = 'RESEND_VERIFY_EMAIL_FAILED';
export const RESEND_VERIFY_EMAIL_SUCCESS = 'RESEND_VERIFY_EMAIL_SUCCESS';

export type ResendVerifyEmailResponseDataT = {
    result: boolean;
};

export type ResendVerifyEmailResponseErrorT = {
    message?: string;
    list?: string[];
};

export type ResendVerifyEmailLoadingT = {
    type: typeof RESEND_VERIFY_EMAIL_LOADING;
};

export type ResendVerifyEmailFailedT = {
    type: typeof RESEND_VERIFY_EMAIL_FAILED;
    error: ResendVerifyEmailResponseErrorT;
};

export type ResendVerifyEmailSuccessT = {
    type: typeof RESEND_VERIFY_EMAIL_SUCCESS;
    payload: ResendVerifyEmailResponseDataT;
};

export type ResendVerifyEmailDispatchTypesT =
    | ResendVerifyEmailLoadingT
    | ResendVerifyEmailFailedT
    | ResendVerifyEmailSuccessT;
