import farmSeasonFinalize from '@actions/FarmSeasonActions/FarmSeasonFinalize';
import farmSeasonInvalidateByAgronomist from '@actions/FarmSeasonActions/FarmSeasonInvalidateByAgronomist';
import farmSeasonValidateByAgronomist from '@actions/FarmSeasonActions/FarmSeasonValidateByAgronomist';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import useCanFinalizeSeason from '@hooks/useCanFinalizeSeason';
import IconTractor from '@icons/icon-tractor.svg';
import { History } from '@mui/icons-material';
import { Avatar, ButtonBase, Divider, Paper, SvgIcon, Typography } from '@mui/material';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import request from '@services/apiService/apiService';
import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import { ModalWarning, useModal } from '@soil-capital/ui-kit';
import { isNullOrEmpty } from '@utils/stringHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import TooltipSc from '@components/TooltipSc/TooltipSc';
import { useCanManageResults, useIsReadOnlyUser } from '@hooks/usePermissions';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import useAdminPanelStyle from './AdminPanelView.style';
import ModalFinalizeSeasonView from './ModalFinalizeSeason/ModalFinalizeSeasonView';

const AdminPanelView = (): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const isOperationPath = location.pathname.includes('operation');
    const onboarding = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason.installation?.onboarding,
    );
    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const canManageResults = useCanManageResults();

    const isHistoryDone = !!(
        onboarding?.pastureHistory?.done &&
        onboarding.coverCropHistory?.done &&
        onboarding.tillagePracticeHistory?.done &&
        onboarding.organicFertilizationHistory?.done
    );
    const isHistoryPath = location.pathname.includes('practice-history');
    const { classes } = useAdminPanelStyle();
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();
    const selectedUser = useSelector((state: HomePageStateT) => state.auth.selectedUser);
    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const agronomistValidationLoading = useSelector(
        (state: HomePageStateT) => state.farmSeason.agronomistValidationLoading,
    );
    const [processLoading, setProcessLoading] = useState(false);
    const isReadOnlyUser = useIsReadOnlyUser();
    const hasInvalidPolygon = useSelector(
        (state: HomePageStateT) => !!state.farmSeasonField.fieldsList.find((f) => !f.is_geom_valid),
    );

    const canFinalizeSeason = useCanFinalizeSeason();
    const modalFinalizeSeason = useModal();
    const [loadingGenerate, setLoadingGenerate] = useState(false);

    const handleFinalize = () => {
        if (farm && farmSeason)
            modalFinalizeSeason.openModal(async () => dispatch(farmSeasonFinalize(farm.id, farmSeason.id)));
    };

    const handleGenerate = async () => {
        setLoadingGenerate(true);

        request({ url: `/farm/${farm?.id}/season/${farmSeason?.id}/report`, method: 'GET', responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data as BlobPart]));
                const link = document.createElement('a');
                link.href = url;
                const date = new Date();
                const format = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}-${
                    date.getMonth() + 1
                }T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                link.setAttribute('download', `${selectedUser?.fullName}-${farm?.name}-${format}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoadingGenerate(false);
            })
            .catch(() => {
                setLoadingGenerate(false);
            });
    };

    const controllerModalNoOpenOperation = useModal();
    const controllerModalNoOpenHistory = useModal();

    const handleOpenOperations = () => {
        // prevent open operations page if initial setup (installation) is not finalized.
        if (!farmSeason?.installation_validated_at) {
            controllerModalNoOpenOperation.openModal();
        } else {
            navigate('/operations');
        }
    };
    const handleOpenPracticeHistory = () => {
        // prevent open operations page if initial setup (installation) is not finalized.
        if (!farmSeason?.installation_validated_at) {
            controllerModalNoOpenHistory.openModal();
        } else {
            navigate('/practice-history');
        }
    };

    const handleValidate = () => {
        if (farm && farmSeason) dispatch(farmSeasonValidateByAgronomist(farm.id, farmSeason.id));
    };

    const handleInvalidate = () => {
        if (farm && farmSeason) dispatch(farmSeasonInvalidateByAgronomist(farm.id, farmSeason.id));
    };

    const handleStartProcess = async () => {
        if (farm && farmSeason) {
            setProcessLoading(true);
            FarmSeasonApiService.startResultProcess(farm.id, farmSeason.id).finally(() => setProcessLoading(false));
        }
    };

    const getAuditInfoMessage = (): JSX.Element | null => {
        if (farmSeason && !isNullOrEmpty(farmSeason.audit_status)) {
            return (
                <Typography className={classes.auditMsg} color="primary" variant="body1">
                    {t(`pages.homepage.sidebar.admin-panel.audit-status-${farmSeason.audit_status}`)}
                </Typography>
            );
        }

        return null;
    };

    return (
        <>
            <Paper
                className={`${classes.container} simulationActionOff simulationAction`}
                elevation={0}
                variant="elevation"
            >
                <Typography color="primary" variant="h5">
                    {t('pages.homepage.sidebar.admin-panel.working-as')}
                </Typography>
                <ButtonBase className={classes.profile} onClick={() => navigate('/search')}>
                    <Avatar className={classes.avatar} />
                    <Typography className={classes.profileName} color="primary" variant="h6">
                        {selectedUser?.fullName}
                    </Typography>
                </ButtonBase>
                {!isReadOnlyUser && (
                    <>
                        <Divider />
                        {isBaselineSeason && (
                            <ButtonBase
                                className={`${classes.task} ${isHistoryPath ? classes.taskActive : ''}`}
                                onClick={handleOpenPracticeHistory}
                            >
                                <div className={classes.iconContainer}>
                                    <SvgIcon className={classes.icon} component={History} viewBox="2 6 21 13" />
                                </div>
                                <Typography className={classes.taskText} color="primary" variant="h6">
                                    {t('pages.homepage.sidebar.admin-panel.practice-history')}
                                </Typography>
                            </ButtonBase>
                        )}
                        <ButtonBase
                            className={`${classes.task} ${isOperationPath ? classes.taskActive : ''}`}
                            onClick={handleOpenOperations}
                        >
                            <div className={classes.iconContainer}>
                                <SvgIcon className={classes.icon} component={IconTractor} viewBox="0 0 21 18" />
                            </div>
                            <Typography className={classes.taskText} color="primary" variant="h6">
                                {t('pages.homepage.sidebar.admin-panel.operation')}
                            </Typography>
                        </ButtonBase>
                        {canFinalizeSeason &&
                            (isBaselineSeason ? isHistoryDone : true) &&
                            !farmSeason?.operations_validated_by && (
                                <LoadingButton
                                    className={classes.finalizeSeasonBtn}
                                    disabled={
                                        !!farmSeason?.operations_validated_by ||
                                        farmSeason?.audit_status === 'in_progress' ||
                                        farmSeason?.audit_status === 'done'
                                    }
                                    loading={false}
                                    padding="12px"
                                    text={
                                        farmSeason?.operations_validated_by
                                            ? t('pages.homepage.sidebar.admin-panel.finalized-season')
                                            : t('pages.homepage.sidebar.admin-panel.finalize-season')
                                    }
                                    fullWidth
                                    onClick={handleFinalize}
                                />
                            )}

                        {canManageResults && (
                            <LoadingButton
                                className={classes.finalizeSeasonBtn}
                                disabled={
                                    farmSeason?.audit_status === 'in_progress' || farmSeason?.audit_status === 'done'
                                }
                                loading={loadingGenerate}
                                padding="12px"
                                text={t('pages.homepage.sidebar.admin-panel.generate-report')}
                                fullWidth
                                onClick={handleGenerate}
                            />
                        )}

                        {farmSeason?.operations_validated_at && canManageResults && (
                            <TooltipSc
                                translationSlug={hasInvalidPolygon ? 'component.admin-panel.has-invalid-polygon' : null}
                                display="block"
                                placement="right"
                            >
                                <LoadingButton
                                    disabled={
                                        farmSeason?.audit_status === 'in_progress' ||
                                        farmSeason?.audit_status === 'done' ||
                                        hasInvalidPolygon
                                    }
                                    className={classes.finalizeSeasonBtn}
                                    loading={processLoading}
                                    padding="12px"
                                    text={t('component.admin-panel.process.button-start')}
                                    fullWidth
                                    onClick={handleStartProcess}
                                />
                            </TooltipSc>
                        )}

                        {!farmSeason?.results_validated_by_agronomist &&
                            farmSeason?.operations_validated_at &&
                            canManageResults && (
                                <TooltipSc
                                    translationSlug={
                                        farmSeason.has_results
                                            ? ''
                                            : 'component.admin-panel.tooltip-no-results-generated'
                                    }
                                    display="block"
                                    placement="right"
                                >
                                    <LoadingButton
                                        className={classes.finalizeSeasonBtn}
                                        disabled={
                                            !farmSeason.has_results ||
                                            farmSeason?.audit_status === 'in_progress' ||
                                            farmSeason?.audit_status === 'done'
                                        }
                                        loading={agronomistValidationLoading}
                                        padding="12px"
                                        text={t('component.admin-panel.validate')}
                                        fullWidth
                                        onClick={handleValidate}
                                    />
                                </TooltipSc>
                            )}
                        {farmSeason?.results_validated_by_agronomist && canManageResults && (
                            <LoadingButton
                                className={classes.finalizeSeasonBtn}
                                disabled={
                                    farmSeason.audit_status === 'in_progress' || farmSeason.audit_status === 'done'
                                }
                                loading={agronomistValidationLoading}
                                padding="12px"
                                text={t('component.admin-panel.invalidate')}
                                fullWidth
                                onClick={handleInvalidate}
                            />
                        )}

                        {getAuditInfoMessage()}

                        <ModalFinalizeSeasonView modalController={modalFinalizeSeason} />
                    </>
                )}
            </Paper>

            <ModalWarning
                controller={controllerModalNoOpenOperation}
                description={t('modal.warning.installation-should-be-done-to-access-operations')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
            <ModalWarning
                controller={controllerModalNoOpenHistory}
                description={t('modal.warning.installation-should-be-done-to-access-practice-history')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
        </>
    );
};

export default AdminPanelView;
