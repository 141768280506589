import FarmSeasonCropsApiService from '@services/farmSeasonCropsApiService/farmSeasonCropsApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    PostFarmSeasonCropsDataT,
    PostFarmSeasonCropsDispatchTypesT,
    PostFarmSeasonCropsResponseDataT,
    PostFarmSeasonCropsResponseErrorT,
    POST_FARM_SEASON_CROPS_FAILED,
    POST_FARM_SEASON_CROPS_LOADING,
    POST_FARM_SEASON_CROPS_SUCCESS,
} from './PostFarmSeasonCropsTypes';

export const postFarmSeasonCrops =
    (farmId: number, farmSeasonId: number, body: PostFarmSeasonCropsDataT) =>
    async (dispatch: Dispatch<PostFarmSeasonCropsDispatchTypesT>) => {
        try {
            dispatch({ type: POST_FARM_SEASON_CROPS_LOADING });
            const res: AxiosResponse<PostFarmSeasonCropsResponseDataT> = await FarmSeasonCropsApiService.post(
                farmId,
                farmSeasonId,
                body,
            );

            dispatch({
                type: POST_FARM_SEASON_CROPS_SUCCESS,
                payload: res.data,
            });

            return { crops: res.data.crops, fields: res.data.fields };
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_CROPS_FAILED,
                error: error as PostFarmSeasonCropsResponseErrorT,
            });

            return false;
        }
    };

export const placeholder = '';
