import { Grid, InputAdornment } from '@mui/material';
import CardFormLayout from '@/layouts/CardFormLayout/CardFormLayout';
import { useTranslation } from 'react-i18next';
import LabelAutocomplete from '../LabelAutocomplete/LabelAutocomplete';
import LabelTextField from '../LabelTextField/LabelTextField';
import { AgroforestryCardFormPropsT } from './AgroforestryCardFormTypes';

const AgroforestryCardForm = ({
    treeValues,
    previousTreeValues,
    onDelete,
    disabledForm,
    submittedForm,
    errors,
    treesListLoading,
    treesList,
    onValuesChange,
}: AgroforestryCardFormPropsT) => {
    const { t } = useTranslation();

    return (
        <CardFormLayout
            title={t(treesList.find((t) => t.id === treeValues.tree_type_id)?.translation_slug ?? '')}
            marginBottom="20px"
            onClose={disabledForm ? undefined : onDelete}
        >
            <Grid container spacing={2}>
                <Grid xs={10} item>
                    <LabelAutocomplete
                        disabled={disabledForm || !!previousTreeValues}
                        error={submittedForm && !!errors?.tree_type_id}
                        helperText={submittedForm ? errors?.tree_type_id : undefined}
                        label={t('pages.installationtasks.fields-subtasks.agroforestry.form.type-of-tree')}
                        loading={treesListLoading}
                        options={treesList}
                        value={treeValues.tree_type_id}
                        required={!treeValues.previous_farm_season_field_tree_id}
                        onChange={(v) => onValuesChange({ ...treeValues, tree_type_id: v })}
                    />
                </Grid>
                <>
                    {!!previousTreeValues && (
                        <Grid xs={6} item>
                            <LabelTextField
                                label={t('pages.installationtasks.fields-subtasks.agroforestry.form.previous-quantity')}
                                value={previousTreeValues.quantity}
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                    )}
                    <Grid xs={6} item>
                        {previousTreeValues ? (
                            // Cutted trees
                            <LabelTextField
                                label={t('pages.installationtasks.fields-subtasks.agroforestry.form.cutted-trees')}
                                error={submittedForm && !!errors?.cutCount}
                                helperText={submittedForm && errors?.cutCount}
                                inputProps={{
                                    style: { paddingRight: '0px' },
                                }}
                                type="number"
                                value={treeValues.cutCount}
                                variant="outlined"
                                fullWidth
                                required
                                onChange={(e) =>
                                    onValuesChange({ ...treeValues, cutCount: Number(e.currentTarget.value) })
                                }
                                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                                disabled={disabledForm}
                            />
                        ) : (
                            <>
                                <LabelTextField
                                    label={t('pages.installationtasks.fields-subtasks.agroforestry.trees-by-hectare')}
                                    error={submittedForm && !!errors?.quantity}
                                    helperText={submittedForm && errors?.quantity}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {t(
                                                    'pages.installationtasks.fields-subtasks.agroforestry.trees-per-hectare.suffix',
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        style: { paddingRight: '10px' },
                                    }}
                                    type="number"
                                    value={treeValues.quantity_per_ha}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={(el) =>
                                        onValuesChange({ ...treeValues, quantity_per_ha: el.target.value })
                                    }
                                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                                    disabled={disabledForm}
                                />
                                <div style={{ height: '10px' }} />
                                <LabelTextField
                                    label={t('pages.installationtasks.fields-subtasks.agroforestry.trees-total-number')}
                                    error={submittedForm && !!errors?.quantity}
                                    helperText={submittedForm && errors?.quantity}
                                    inputProps={{
                                        style: { paddingRight: '0px' },
                                    }}
                                    type="number"
                                    value={treeValues.quantity}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                />
                            </>
                        )}
                    </Grid>
                    {!!previousTreeValues && (
                        <Grid xs={6} item>
                            <LabelTextField
                                label={t('pages.installationtasks.fields-subtasks.agroforestry.form.previous-diameter')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                    inputProps: { max: 100 },
                                }}
                                inputProps={{
                                    style: { paddingRight: '30px' },
                                }}
                                value={previousTreeValues.diameter}
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                    )}
                </>
                <Grid xs={6} item>
                    <LabelTextField
                        label={
                            previousTreeValues
                                ? t('pages.installationtasks.fields-subtasks.agroforestry.form.new-diameter')
                                : t('pages.installationtasks.fields-subtasks.agroforestry.form.diameter')
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            inputProps: { max: 100 },
                        }}
                        error={submittedForm && !!errors?.diameter}
                        helperText={submittedForm && errors?.diameter}
                        inputProps={{
                            style: { paddingRight: '30px' },
                        }}
                        type="number"
                        value={treeValues.diameter}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(el) => onValuesChange({ ...treeValues, diameter: el.target.value })}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        disabled={disabledForm}
                    />
                </Grid>
            </Grid>
        </CardFormLayout>
    );
};

export default AgroforestryCardForm;
