import { Typography } from '@mui/material';
import useHeaderNameValueListStyle from './HeaderNameValueList.style';
import { HeaderNameValueListPropsT } from './HeaderNameValueListTypes';

const HeaderNameValueList = ({ nameHeader, valueHeader }: HeaderNameValueListPropsT): JSX.Element => {
    const { classes } = useHeaderNameValueListStyle();
    return (
        <div className={classes.HeaderNameValueList}>
            {/* Name zone */}
            <Typography color="secondary" variant="body2">
                {nameHeader}
            </Typography>

            {/* Value Zone */}
            {typeof valueHeader === 'string' ? (
                <Typography color="secondary" variant="body2">
                    {valueHeader}
                </Typography>
            ) : (
                valueHeader
            )}
        </div>
    );
};

export default HeaderNameValueList;
