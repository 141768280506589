import { Paper } from '@mui/material';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import MenuBar from '@components/MenuBar/MenuBar';
import { useTranslation } from 'react-i18next';

import useRelationsDndModalStyle from './RelationsDndModalView.style';
import { RelationsDndModalViewPropsT } from './RelationsDndModalTypes';
import RelationsStep from './RelationsStep/RelationsStep';
import YieldStep from './YieldStep/YieldStep';

// TODO: Put Relations back on when defined
const RelationsDndModalView = ({
    className,
    state,
    currentStep,
    // pushStep,
    popStep,
    onSubmit,
    onClose,
}: RelationsDndModalViewPropsT): JSX.Element => {
    const { classes } = useRelationsDndModalStyle();
    const { t } = useTranslation();

    // TODO: Change to RelationStep to use RelationsDnd component
    return (
        <Paper className={`${className} ${classes.container}`} elevation={0}>
            <ProgressBar color="secondary" currentStep={currentStep} height="5px" totalSteps={2} />
            <div className={classes.formContainer}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('component.relationDndModalView.menuTitle', { value: currentStep })}
                    onArrowBack={currentStep === 2 ? () => popStep() : onClose}
                    onClose={onClose}
                />
                {currentStep === 1 && <RelationsStep state={state} onContinue={onSubmit /* () => pushStep(2) */} />}
                {currentStep === 2 && <YieldStep state={state} onSubmit={onSubmit} />}
            </div>
        </Paper>
    );
};

export default RelationsDndModalView;
