import request from '@services/apiService/apiService';
import { NotificationT } from '@reducers/NotificationReducer/NotificationReducerTypes';

import {
    NotificationApiFetchParamsT,
    NotificationApiMarkAsReadParamsT,
    NotificationApiFetchResponseT,
} from './notificationsApiServiceTypes';

class NotificationsApiService {
    public static fetch = async ({ limit, offset, unread }: NotificationApiFetchParamsT) =>
        request<NotificationApiFetchResponseT>({
            url: '/notifications',
            method: 'GET',
            params: { limit, offset, unread },
        });

    public static markAsRead = async ({ notificationId }: NotificationApiMarkAsReadParamsT) =>
        request<NotificationT>({
            url: `/notifications/${notificationId}/read`,
            method: 'PUT',
        });

    public static markAllAsRead = async () =>
        request<void>({
            url: `/notifications/read-all`,
            method: 'PUT',
        });
}

export default NotificationsApiService;
