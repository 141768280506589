import { useModal } from '@soil-capital/ui-kit';
import { geoJsonFeatureCollectionT } from '@components/Map/MapTypes';
import { ModalUploadCapFileResponseT } from '@components/ModalUploadCapFile/ModalUploadCapFileTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UploadCapFieldsModalPropsT } from './FieldsTaskTypes';
import useValidateUploadedFields from './useValidateUploadedFields';
import { FieldT } from '@entities/fieldEntity/fieldEntity.types';
import { postFarmSeasonFields } from '@store/actions/FarmSeasonFieldActions/PostFarmSeasonFields';
import useAppDispatch from '@hooks/useAppDispatch';
import { PostFarmSeasonFieldDataT } from '@store/actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTypes';
import fieldEntity from '@entities/fieldEntity';
import FarmSeasonFieldsApiService from '@/services/farmSeasonFieldsApiService/farmSeasonFieldsApiService';
import { FarmSeasonFieldT } from '@/store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import selectPreviousFarmSeason from '@/store/selectors/selectPreviousFarmSeason';

const useUploadCapFieldsModal = ({ focusFieldsOnMap }: UploadCapFieldsModalPropsT) => {
    const { t } = useTranslation();
    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const currentFarmSeason = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason);

    const [uploadWarningMessages, setUploadWarningMessages] = useState<string[]>([]);
    const modalUploadCapFileController = useModal<ModalUploadCapFileResponseT>();
    const modalDefaultPrjUsed = useModal();
    const { validateUploadedFields } = useValidateUploadedFields();
    const dispatch = useAppDispatch();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason.id);
    const { refetch: refetchFields } = fieldEntity.useGet({ farmSeasonId });

    const previousFarmSeasonId = useSelector(selectPreviousFarmSeason())?.id;

    const openUploadCapModal = (fields: FieldT[]) => {
        modalUploadCapFileController.openModal(async ({ defaultPrjUsed, uploadedFields }) => {
            if (!isBaselineSeason && !currentFarmSeason.farm_boundaries_mask) {
                throw Error('farm_boundaries_mask is required in baseline');
            }

            let pastFields: FarmSeasonFieldT[] | [] = [];

            if (!isBaselineSeason && previousFarmSeasonId) {
                const response = await FarmSeasonFieldsApiService.get(farmId, previousFarmSeasonId);
                pastFields = response.data.fields;
            }

            const farmBoundariesMask = isBaselineSeason
                ? null
                : (JSON.parse(currentFarmSeason.farm_boundaries_mask as string) as geoJsonFeatureCollectionT);

            const {
                uploadedFieldsToAdd,
                hasFieldsIntersectingExistings,
                hasFieldsOutsideBoundaries,
                hasProcessingErrors,
            } = validateUploadedFields(uploadedFields, fields, farmBoundariesMask, pastFields);

            const warningMessages: string[] = [];
            if (hasFieldsIntersectingExistings) {
                warningMessages.push(t('validation.duplicated-fields'));
            }
            if (hasFieldsOutsideBoundaries) {
                warningMessages.push(t('validation.fields-outside-farm'));
            }
            if (hasProcessingErrors) {
                warningMessages.push(t('validation.computation-errors'));
            }

            // When fieldsToAdd was of length 0 it was causing a bug
            // FP-3980
            if (uploadedFieldsToAdd.length > 0) {
                await dispatch(
                    postFarmSeasonFields(farmId, farmSeasonId, {
                        fields: uploadedFieldsToAdd.map(
                            (f): PostFarmSeasonFieldDataT => ({
                                ...f,
                                name: f.name,
                                original_area: f.area,
                                user_area: null,
                                polygon: {
                                    id: Number(f.id),
                                    properties: {},
                                    type: 'Feature',
                                    geometry: f.polygon.geometry,
                                },
                                area_source: 'pac',
                            }),
                        ),
                    }),
                );
                refetchFields();
                focusFieldsOnMap(uploadedFieldsToAdd);
                (async () => {
                    if (defaultPrjUsed) {
                        await modalDefaultPrjUsed.openModal();
                    }
                })();
            }

            (async () => {
                if (warningMessages.length) {
                    setUploadWarningMessages(warningMessages);
                }
            })();
        });
    };

    return {
        modalUploadCapFileController,
        modalDefaultPrjUsed,
        openUploadCapModal,
        uploadWarningMessages,
        setUploadWarningMessages,
    };
};

export default useUploadCapFieldsModal;
