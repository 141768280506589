import farmSeasonValidate from '@actions/FarmSeasonActions/FarmSeasonValidate';
import { getFarmSeasonCrops } from '@actions/FarmSeasonCropActions/GetFarmSeasonCrops';
import { getFarmSeasonFields } from '@actions/FarmSeasonFieldActions/GetFarmSeasonFields';
import irrigationMethodGetListAction from '@actions/StaticActions/IrrigationMethodGetListAction';
import irrigationPowerSourceGetListAction from '@actions/StaticActions/IrrigationPowerSourceGetListAction';
import irrigationWaterSourceGetListAction from '@actions/StaticActions/IrrigationWaterSourceGetListAction';
import getMachineries from '@actions/StaticActions/MachineryListGet';
import organicMatterGetListAction from '@actions/StaticActions/OrganicMatterGetListAction';
import pesticidesGetListAction from '@actions/StaticActions/PesticidesGetListAction';
import pHGetListAction from '@actions/StaticActions/PhGetListAction';
import tillageGetListAction from '@actions/StaticActions/TillageGetListAction';
import tillagePracticeGetListAction from '@actions/StaticActions/TillagePracticeGetListAction';
import getTrees from '@actions/StaticActions/TreesGet';
import { useModal } from '@soil-capital/ui-kit';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFarmerDataLogic = () => {
    const dispatch = useAppDispatch();

    /* -------------------- Get fetched data through redux ------------------- */

    const farmSeasonField = useSelector((state: HomePageStateT) => state.farmSeasonField);
    const farmSeasonCrop = useSelector((state: HomePageStateT) => state.farmSeasonCrop);
    const validateLoading = useSelector((state: HomePageStateT) => state.farmSeason.validateLoading);
    // Static datas
    const soilOrganicMatterList = useSelector((state: HomePageStateT) => state.static.organicMatterLevelList);
    const pHList = useSelector((state: HomePageStateT) => state.static.pHList);
    const tillageList = useSelector((state: HomePageStateT) => state.static.tillagePracticeList);
    const tillageChangeList = useSelector((state: HomePageStateT) => state.static.tillageList);
    const irrigationMethodList = useSelector((state: HomePageStateT) => state.static.irrigationMethodList);
    const irrigationWaterSourceList = useSelector((state: HomePageStateT) => state.static.irrigationWaterSourceList);
    const irrigationPowerSourceList = useSelector((state: HomePageStateT) => state.static.irrigationPowerSourceList);
    const treesList = useSelector((state: HomePageStateT) => state.static.trees);
    const machineryList = useSelector((state: HomePageStateT) => state.static.machineryList);
    const pesticideList = useSelector((state: HomePageStateT) => state.static.pesticidesList);
    // Context
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);

    /* ----------------------- refresh store with "up to date" data ----------------------- */

    useEffect(() => {
        if (currentFarm && currentFarmSeason) {
            dispatch(getFarmSeasonFields(currentFarm?.id, currentFarmSeason?.id, { with: 'operations' }));
            dispatch(getFarmSeasonCrops(currentFarm?.id, currentFarmSeason?.id));
        }
        // Static datas
        dispatch(organicMatterGetListAction());
        dispatch(pHGetListAction());
        dispatch(tillagePracticeGetListAction());
        dispatch(tillageGetListAction());
        dispatch(irrigationMethodGetListAction());
        dispatch(irrigationWaterSourceGetListAction());
        dispatch(irrigationPowerSourceGetListAction());
        dispatch(getTrees());
        dispatch(getMachineries(Number(currentFarm?.country_id)));
        dispatch(pesticidesGetListAction(Number(currentFarm?.country_id)));
    }, []);

    /* ------------------------ Farmer validate his data ------------------------ */
    const modalValidateErrorController = useModal();
    const modalPaymentController = useModal();
    const modalResultReadyMustPayController = useModal();
    const modalResultComputingController = useModal();

    const handleFarmerValidateData = async () => {
        if (!currentFarm || !currentFarmSeason) return;

        const success = await dispatch(farmSeasonValidate(currentFarm.id, currentFarmSeason.id));

        if (!success) {
            modalValidateErrorController.openModal();

            return;
        }

        if (
            [0, 1].includes(currentFarmSeason.partner_type) &&
            !currentFarmSeason.has_results_access &&
            !currentFarmSeason.one_payment
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            modalResultReadyMustPayController.openModal(modalPaymentController.openModal as any);
        } else {
            modalResultComputingController.openModal();
        }
    };

    const [page, setPage] = useState(1);
    const [fieldsByCrop, setFieldsByCrop] = useState<FarmSeasonFieldT[]>([]);
    useEffect(() => {
        const tmpFieldsByCrop: Record<string, FarmSeasonFieldT[]> = farmSeasonCrop?.cropsList.reduce(
            (acc, farmSeasonCropItem) => ({ ...acc, [farmSeasonCropItem.id as number]: [] }),
            {},
        );

        farmSeasonField.fieldsList.forEach((field) => {
            tmpFieldsByCrop[field.field_crops?.[0]?.farm_season_crop.id as number]?.push(field);
        });

        setFieldsByCrop(Object.values(tmpFieldsByCrop).flat());
    }, [farmSeasonField]);

    // 1 offset page because first page is farm informations
    const currentFieldDisplayed = fieldsByCrop[page - 2];

    const lastPage = farmSeasonField.fieldsList?.length + 1;

    return {
        farmSeasonField,
        irrigationMethodList,
        irrigationPowerSourceList,
        irrigationWaterSourceList,
        machineryList,
        pHList,
        pesticideList,
        soilOrganicMatterList,
        tillageChangeList,
        tillageList,
        treesList,
        handleFarmerValidateData,
        farmSeason: currentFarmSeason,
        validateLoading,
        modalValidateErrorController,
        modalPaymentController,
        modalResultReadyMustPayController,
        modalResultComputingController,
        lastPage,
        page,
        setPage,
        currentFieldDisplayed,
    };
};

export default useFarmerDataLogic;
