import { makeStyles } from 'tss-react/mui';

const useCropRelationsViewStyle = makeStyles()((theme) => ({
    container: {
        marginTop: '10px',
    },
    cropModal: {
        minWidth: '350px',
    },
    divider: {
        marginBottom: '20px',
        marginTop: '20px',
        borderTop: `1px solid ${theme.palette.secondary.light}`,
    },
}));

export default useCropRelationsViewStyle;
