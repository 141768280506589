import { Prompt } from '@components/Prompt/Prompt';
import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import soilAcidities from '@constants/soilAcidities';
import ColorPatternButtonView from '@components/ColorPatternButton/ColorPatternButtonView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import { SelectStepViewPropsT } from './SelectStepTypes';
import useSelectStepStyle from './SelectStepView.style';
import SelectFieldStep from './SelectFieldStep/SelectFieldStep';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import formatNumber from '@utils/formatNumber';

const SelectStepView = ({
    t,
    currentStep,
    popStep,
    formFields,
    selectedSoilAcidity,
    setFormFields,
    onSoilAcidityClick,
    isDirty,
    fieldLoading,
    submit,
    ModalForgottenFields,
}: SelectStepViewPropsT): JSX.Element | null => {
    const { classes } = useSelectStepStyle();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    if (currentStep === 1.1) {
        if (!selectedSoilAcidity) {
            return null;
        }

        return (
            <SelectFieldStep
                formFields={formFields}
                popStep={popStep}
                selectedSoilAcidity={selectedSoilAcidity}
                setFormFields={setFormFields}
            />
        );
    }

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={isDirty}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.fields-subtasks.soil-acidity-task.select-step.title">
                    Veuillez <span className={classes.darkerTitle}> ajouter des champs</span> pour les differents
                    niveaux de pH
                </Trans>
                <HelpButtonView id="pages.installationtasks.fields-subtasks.soil-acidity-task.select-step.title" />
            </Typography>
            <div className={classes.listContainer}>
                {soilAcidities?.map((soilAcidity) => (
                    <ColorPatternButtonView
                        key={`${soilAcidity.id}-${String(soilAcidity.name)}`}
                        color="#FFF"
                        name={t(soilAcidity.name)}
                        patternSvg={soilAcidity.pattern}
                        border
                        hasPattern
                        onClick={() => onSoilAcidityClick(soilAcidity)}
                    >
                        <>
                            <Typography color="primary" variant="body1">
                                {t('constants.fields-subtitle', {
                                    count: formFields.filter(
                                        (formField) => formField.soil_acidity_id === soilAcidity.id,
                                    ).length,
                                })}
                            </Typography>
                            <Typography color="primary" variant="body1">
                                {formatNumber(
                                    formFields
                                        .filter((formField) => formField.soil_acidity_id === soilAcidity.id)
                                        .map((formField) => formField.area)
                                        .reduce((tot, acc) => tot + acc, 0),
                                    3,
                                )}
                                &nbsp;
                                {t('constants.hectare-unit')}
                            </Typography>
                        </>
                    </ColorPatternButtonView>
                ))}
            </div>
            <TwoButtonFooterView
                rightLoading={fieldLoading}
                rightOnCLick={submit}
                rightText={canEditFarmSeason.access ? t('constants.save') : ''}
            />
            {ModalForgottenFields}
        </div>
    );
};

export default SelectStepView;
