import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Container } from '@mui/material';
import LogoImg from '@components/LogoImg/LogoImg';

import { PagePrintablePropsT } from './PagePrintableTypes';
import usePagePrintableStyle from './PagePrintable.style';

const PagePrintable = ({ children, setPrint, printWithBg = true, className, filename }: PagePrintablePropsT) => {
    const { classes } = usePagePrintableStyle({ printWithBg });
    const pagePrintableRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setPrint(() => () => {
            if (!pagePrintableRef.current) {
                return;
            }

            // add temporary print style to hide the app.
            const headTag: HTMLHeadElement | null = document.querySelector('head');
            const tmpPrintStyle = document.createElement('style');
            tmpPrintStyle.setAttribute('media', 'print');
            tmpPrintStyle.setAttribute('type', 'text/css');
            tmpPrintStyle.innerHTML = '#root {display: none;}';
            headTag?.appendChild(tmpPrintStyle);

            // temporary on top of potential other printable pages.
            pagePrintableRef.current.style.zIndex = '999';

            // temporary title change for pdf filename.
            const titleTag = headTag?.querySelector('title');
            const pageTitle = titleTag?.innerHTML;

            if (titleTag) {
                titleTag.innerHTML = filename;
            }

            window.print();
            pagePrintableRef.current.style.zIndex = '';
            headTag?.removeChild(tmpPrintStyle);

            if (titleTag && pageTitle) {
                titleTag.innerHTML = pageTitle;
            }
        });
    }, [filename, setPrint]);

    const body = document.querySelector('body');

    if (!body) {
        return null;
    }

    return ReactDOM.createPortal(
        <div ref={pagePrintableRef} className={`${classes.PagePrintableStyle} ${className}`}>
            <div className={classes.header}>
                <a href="https://soilcapital.com/">
                    <LogoImg logoType="soilcapital" />
                </a>
            </div>
            <Container component="section">{children}</Container>
        </div>,
        body,
    );
};

export default PagePrintable;
