import { shapeEntity } from '../shapeEntity';
import fieldEntityApi, { fieldApi } from './fieldEntity.api';
import fieldEntitySchema from './fieldEntity.schema';
import fieldEntityState from './fieldEntity.state';

export default shapeEntity('Field', {
    useState: fieldEntityState,
    ...fieldEntityApi,
    api: fieldApi,
    schema: fieldEntitySchema,
});
