import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { PostFarmSeasonOperationFertilisingDispatchTypesT } from './PostFarmSeasonOperationFertilisingTypes';

export const FARM_SEASON_OPERATION_DELETE_LOADING = 'FARM_SEASON_OPERATION_DELETE_LOADING';
export const FARM_SEASON_OPERATION_DELETE_FAILED = 'FARM_SEASON_OPERATION_DELETE_FAILED';
export const FARM_SEASON_OPERATION_DELETE_SUCCESS = 'FARM_SEASON_OPERATION_DELETE_SUCCESS';

export type DeleteFarmSeasonOperationDeleteResponseDataT = {
    fields: FarmSeasonFieldT[];
};

export type FarmSeasonOperationDeleteDeleteCallBackT = (
    dispatch: Dispatch<DeleteFarmSeasonOperationDeleteDispatchTypesT>,
) => Promise<DeleteFarmSeasonOperationDeleteResponseErrorT>;

export type DeleteFarmSeasonOperationDeleteResponseErrorT = {
    message?: string;
    list?: string[];
};

export type DeleteFarmSeasonOperationDeleteLoadingT = {
    type: typeof FARM_SEASON_OPERATION_DELETE_LOADING;
};

export type DeleteFarmSeasonOperationDeleteFailedT = {
    type: typeof FARM_SEASON_OPERATION_DELETE_FAILED;
    error: DeleteFarmSeasonOperationDeleteResponseErrorT;
};

export type DeleteFarmSeasonOperationDeleteSuccessT = {
    type: typeof FARM_SEASON_OPERATION_DELETE_SUCCESS;
    payload: DeleteFarmSeasonOperationDeleteResponseDataT;
};

export type DeleteFarmSeasonOperationDeleteDispatchTypesT =
    | DeleteFarmSeasonOperationDeleteLoadingT
    | DeleteFarmSeasonOperationDeleteFailedT
    | DeleteFarmSeasonOperationDeleteSuccessT
    | PostFarmSeasonOperationFertilisingDispatchTypesT;
