import MapService from '@services/mapService/mapService';
import { AppStateT } from '@store/store';
import addPolygonData from '@utils/addPolygonData';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useMapFieldReset = (inOperation = false): Dispatch<SetStateAction<boolean>> => {
    const [canRefresh, setCanRefresh] = useState(true);
    const fields = useSelector((state: AppStateT) => state.farmSeasonField.fieldsList);
    const cropsList = useSelector((state: AppStateT) => state.farmSeasonCrop.cropsList);
    const cropColors = setupCropPaletteFromCropLegacy(cropsList);

    useEffect(
        () => () => {
            if (canRefresh) {
                MapService.removeClickedPolygon({});
                MapService.showOpenDataLayer({ openDataMode: false });
                MapService.creatablePolygons({ createMode: false });
                MapService.addPolygons({
                    polygons: fields?.map((field) =>
                        addPolygonData(
                            inOperation &&
                                field.field_crops?.some((field_crop) => field_crop.farm_season_crop.crop.is_permanent)
                                ? {
                                      field,
                                      colorOverride: '#000000',
                                  }
                                : {
                                      field,
                                      colorPalette: cropColors,
                                  },
                        ),
                    ),
                });
            }
        },
        [fields, canRefresh, inOperation],
    );

    return setCanRefresh;
};

export default useMapFieldReset;
