import { Button, Divider, FormHelperText, Paper, Typography } from '@mui/material';
import { FileRejection, useDropzone } from 'react-dropzone';
import SimpleListItemView from '../SimpleListItem/SimpleListItemView';
import useDropZoneStyle from './DropZone.style';
import { DropZonePropsT } from './DropZoneTypes';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useMemo, useState } from 'react';

const DropZone = ({
    processingErrors,
    files,
    onFilesChange,
    textDropFiles,
    textBrowseFiles,
    acceptedFilesTypes,
}: DropZonePropsT): JSX.Element => {
    const { classes } = useDropZoneStyle();
    const [uploadErrors, setUploadErrors] = useState<string[]>([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedFilesTypes,
        onDrop: (newFiles: File[], fileRejections: FileRejection[]): void => {
            // merge with already uploaded files + remove duplicates
            onFilesChange(
                [...files, ...newFiles].filter(
                    (field, i, self) => i === self.findIndex((selfField) => selfField.name === field.name),
                ),
            );

            setUploadErrors(fileRejections.map((fileError) => `${fileError.file.name} is not a valid type of file`));
        },
    });
    const { onClick, ...dropzoneRootProps } = useMemo(() => getRootProps(), [acceptedFilesTypes, files]);

    const handleDeleteFile = (file: File) => onFilesChange(files.filter((f) => f.name !== file.name));
    return (
        <Paper className={classes.uploadContainer} elevation={0} variant="elevation">
            {files.map((file, index: number) => {
                const key = `${file.name}-${index}`;

                return <SimpleListItemView key={key} text={file.name} onClose={() => handleDeleteFile(file)} />;
            })}

            <div className={classes.uploadSection} {...dropzoneRootProps}>
                <input {...getInputProps() /*eslint-disable-line*/} />
                <CloudUploadOutlinedIcon className={classes.uploadIcon} viewBox="0 0 24 24" />
                <Typography color="secondary" variant="h5">
                    {textDropFiles}
                </Typography>
                <Divider className={classes.divider} />
                <Button onClick={onClick} className={classes.uploadButton} color="secondary" id="browser-files">
                    {textBrowseFiles}
                </Button>
            </div>

            {[...uploadErrors, ...processingErrors].map((error) => (
                <FormHelperText key={error} className={classes.helperText} error>
                    {error}
                </FormHelperText>
            ))}
        </Paper>
    );
};

export default DropZone;
