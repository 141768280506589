/* eslint-disable @typescript-eslint/no-explicit-any */
import MapService from '@services/mapService/mapService';
import { useContext, useEffect } from 'react';
import useAppNavigate from '@hooks/useAppNavigate';

import { IrrigationContext } from '../IrrigationTaskContext/IrrigationTaskContext';

import IrrigatedFieldsListView from './IrrigatedFieldsListView';

const IrrigatedFieldList = (): JSX.Element => {
    const { pushStep, t, setFieldIrrigated, fieldsList, thisMap, irrigationMethodList } = useContext(IrrigationContext);

    const navigate = useAppNavigate();

    const onConfirmSelectedField = () => {
        navigate('..');
    };

    const onFieldClick = (field: any) => {
        setFieldIrrigated(field);
        pushStep(3);
    };

    useEffect(() => {
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap?.selectedPolygons ? [...thisMap.selectedPolygons] : [],
        });
    }, []);

    return (
        <IrrigatedFieldsListView
            irrigatedFields={fieldsList?.filter((field: any) => field.is_irrigated)}
            irrigationMethodList={irrigationMethodList}
            t={t}
            onConfirmSelectedField={onConfirmSelectedField}
            onFieldClick={onFieldClick}
        />
    );
};

export default IrrigatedFieldList;
