import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import { useModal } from '@soil-capital/ui-kit';
import useOperationDone from '@hooks/useOperationDone';
import { deleteFarmSeasonField as deleteFarmSeasonFieldAction } from '@actions/FarmSeasonFieldActions/DeleteFarmSeasonField';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '../../types/HomePageStoreTypes';
import { DeleteFarmSeasonFieldPropsT } from './EditFieldTypes';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

export const useDeleteFarmSeasonField = ({ controllerWarningOperationDone }: DeleteFarmSeasonFieldPropsT) => {
    const navigate = useAppNavigate();
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const dispatch = useAppDispatch();
    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { getHasAnyOperationDone, getHasV3OperationsCreated } = useOperationDone();

    const deleteModalController = useModal();
    const controllerWarningAnyOperationCreated = useModal(); // used for cohort 4 farmers
    const isCFT = useIsCFTCarbonModel();
    const deleteNotLinkedModalController = useModal();
    const modalDeleteHasTree = useModal();
    const modalIsPermanentController = useModal();

    const canDeleteWithoutWarning = (farmSeasonFieldId: number) => {
        const selectedField = fields.find((field) => field.id === farmSeasonFieldId);

        if (selectedField?.has_tillage_practice_history) return false;

        if (selectedField?.has_agroforestry) return false;

        if (selectedField?.has_cover_crop_history) return false;

        if (selectedField?.has_organic_fertilization_history) return false;

        if (selectedField?.has_pasture_history) return false;

        if (selectedField?.is_irrigated) return false;

        if (selectedField?.has_cover_crops !== null) return false;

        if (selectedField?.has_drainage !== null) return false;

        if (selectedField?.soil_acidity_id !== null) return false;

        if (selectedField?.soil_organic_matter_id !== null) return false;

        if (selectedField?.soil_organic_carbon_id !== null) return false;

        if (selectedField?.tillage_id !== null) return false;

        if (selectedField?.field_crops?.length) return false;

        return true;
    };

    const deleteFarmSeasonField = async (fieldToDelete: FarmSeasonFieldT) => {
        if (fieldToDelete?.polygon.properties.previous_has_agroforestry) {
            modalDeleteHasTree.openModal();
            return;
        }
        if (fieldToDelete?.is_permanent) {
            modalIsPermanentController.openModal();
            return;
        }
        if (!isCFT && getHasV3OperationsCreated({ farmSeasonFieldId: fieldToDelete.id })) {
            // used for cohort 4 farmers
            controllerWarningAnyOperationCreated.openModal();
        } else if (getHasAnyOperationDone({ farmSeasonFieldId: fieldToDelete.id })) {
            controllerWarningOperationDone.openModal();
        } else if (canDeleteWithoutWarning(fieldToDelete.id)) {
            await deleteNotLinkedModalController.openModal(async () => {
                await dispatch(deleteFarmSeasonFieldAction(farm.id, farmSeason.id, fieldToDelete.id));
                navigate('/fields');
            });
        } else {
            await deleteModalController.openModal(async () => {
                await dispatch(deleteFarmSeasonFieldAction(farm.id, farmSeason.id, fieldToDelete.id));
                navigate('/fields');
            });
        }
    };

    return {
        deleteFarmSeasonField,
        controllerWarningAnyOperationCreated,
        deleteModalController,
        deleteNotLinkedModalController,
        modalDeleteHasTree,
        modalIsPermanentController,
    };
};
