import { ErrorsReducerStateT } from '@reducers/ErrorsReducer/ErrorsReducerTypes';

export const ERRORS_ADD = 'ERRORS_ADD';
export const ERRORS_CLEAN = 'ERRORS_CLEAN';
export const ERRORS_REMOVE = 'ERRORS_REMOVE';

export type ErrorAddT = {
    type: typeof ERRORS_ADD;
    payload: ErrorsReducerStateT[0];
};

export type ErrorRemoveT = {
    type: typeof ERRORS_REMOVE;
    payload: Pick<ErrorsReducerStateT[0], 'errorId'>;
};

export type ErrorCleanT = {
    type: typeof ERRORS_CLEAN;
};

export type ErrorActionsT = ErrorAddT | ErrorRemoveT | ErrorCleanT;
