import useFieldPopupContentLogic from './FieldPopupContent.logic';
import { MapPopupContentPropsT, MapPopupContentT } from '../Map/components/MapPopup/MapPopup.types';
import MapPopupContentLayout from '@layouts/MapPopupContentLayout/MapPopupContentLayout';

import LoadingSection from '@components/LoadingSection/LoadingSection';
import FieldPopupItem from '@components/FieldPopupItem/FieldPopupItem';
import useFieldPopupContentStyle from '@components/FieldPopupContent/FieldPopupContent.style';

const FieldPopupContent: MapPopupContentT = ({ mapContext, onClose, polygonId }: MapPopupContentPropsT) => {
    const { classes } = useFieldPopupContentStyle();

    const { field, fieldName, canDisplayPopup, previousHarvests, previousHarvestsLoading } = useFieldPopupContentLogic({
        polygonId,
        mapContext,
    });

    if (!canDisplayPopup || !field) {
        return null;
    }

    return (
        <MapPopupContentLayout title={fieldName ? fieldName : ''} onClose={onClose}>
            <>
                <FieldPopupItem field={field} isHighlighted={true} />

                {!field.is_permanent && (
                    <LoadingSection
                        loading={previousHarvestsLoading}
                        className={previousHarvestsLoading ? classes.loadingSection : ''}
                    >
                        {previousHarvests?.map(
                            (item) =>
                                item.field && (
                                    <FieldPopupItem key={item.field.id} field={item.field} farmSeason={item.season} />
                                ),
                        )}
                    </LoadingSection>
                )}
            </>
        </MapPopupContentLayout>
    );
};

export default FieldPopupContent;
