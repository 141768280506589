import { Paper, Typography } from '@mui/material';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';

import useWarningModalStyle from './WarningModalView.style';
import { WarningModalViewPropsT } from './WarningModalViewTypes';

/** @deprectated use <ModalWarning/> instead */
const WarningModalView = ({
    title,
    className,
    leftOnClick,
    leftButtonText,
    rightOnCLick,
    rightButtonText,
    description,
    confirmMessage,
    rightLoading = false,
    leftLoading = false,
    children,
}: WarningModalViewPropsT): JSX.Element => {
    const { classes } = useWarningModalStyle();

    return (
        <Paper className={`${className} ${classes.container}`} elevation={0}>
            <Typography className={classes.title} color="secondary" variant="h4">
                {title}
            </Typography>
            {(description || confirmMessage || children) && (
                <div className={classes.messagesContainer}>
                    {description && (
                        <Typography className={classes.messages} color="primary" variant="body2">
                            {description}
                        </Typography>
                    )}
                    {confirmMessage && (
                        <Typography
                            className={`${classes.messages} ${classes.confirmMessage}`}
                            color="primary"
                            variant="h6"
                        >
                            {confirmMessage}
                        </Typography>
                    )}
                    {children}
                </div>
            )}
            <TwoButtonFooterView
                leftLoading={leftLoading}
                leftOnClick={leftOnClick}
                leftText={leftButtonText}
                rightLoading={rightLoading}
                rightOnCLick={rightOnCLick}
                rightText={rightButtonText}
            />
        </Paper>
    );
};

export default WarningModalView;
