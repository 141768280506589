import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => {
    return {
        container: {
            padding: '16px',
        },
        title: {
            marginBottom: '16px',
        },
    };
});
