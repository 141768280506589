import { NotificationActionTypes } from '@actions/NotificationActions/NotificationActionsTypes';
import notificationConfig from '@constants/config/notifications.config';
import { Snackbar } from '@mui/material';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NotificationT } from '@reducers/NotificationReducer/NotificationReducerTypes';

import NotificationSwitch from '../NotificationSwitch/NotificationSwitch';

const NotificationSnackbar = () => {
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch({ type: NotificationActionTypes.SHIFT_SNACKBAR_STACK });
    };

    const handleSnackbarClose = (e: MouseEvent) => {
        e.stopPropagation();
        handleClose();
    };

    const firstNotification = useSelector((state: HomePageStateT) => state.notifications.snackbarStack[0]);
    const [notificationDisplayed, setNotificationDisplayed] = useState<NotificationT>();
    const [open, setOpen] = useState(false);
    const [autoHideDuration, setAutoHideDuration] = useState<number | null | undefined>(
        notificationConfig.snackbarDuration,
    );

    useEffect(() => {
        if (open === true && firstNotification?.id !== notificationDisplayed?.id) {
            setOpen(false);
            setTimeout(() => setNotificationDisplayed(undefined), 500);
        }

        if (open === false && !notificationDisplayed && firstNotification) {
            setNotificationDisplayed(firstNotification);
            setOpen(true);
        }
    }, [firstNotification, notificationDisplayed, open]);

    return useMemo(
        () => (
            <>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    autoHideDuration={autoHideDuration}
                    open={open}
                    onClose={(e, raison) => (raison === 'clickaway' ? null : handleClose())}
                    onMouseEnter={() => setAutoHideDuration(undefined)}
                    onMouseLeave={() => setAutoHideDuration(notificationConfig.snackbarDuration)}
                >
                    {notificationDisplayed && (
                        <div style={{ maxWidth: '330px', marginBottom: '60px' }}>
                            <NotificationSwitch
                                notification={notificationDisplayed}
                                showAction="closeSnackbar"
                                onActionClick={handleSnackbarClose}
                                onNotificationClickDone={handleClose}
                            />
                        </div>
                    )}
                </Snackbar>
            </>
        ),
        [notificationDisplayed, open, firstNotification],
    );
};

export default NotificationSnackbar;
