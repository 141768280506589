import {
    PostFarmSeasonFieldsTillageDataT,
    PostFarmSeasonFieldsTillageResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillageTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsTillageApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsTillageDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsTillageResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/tillages`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsTillageApiService;
