import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import FarmSeasonFieldsIrrigationValuesApiService from '@services/farmSeasonFieldsIrrigationValuesApiService/farmSeasonFieldsIrrigationValuesApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsIrrigationValuesPostCallBackT,
    PostFarmSeasonFieldIrrigationValuesDataT,
    PostFarmSeasonFieldIrrigationValuesDispatchTypesT,
    PostFarmSeasonFieldIrrigationValuesResponseDataT,
    PostFarmSeasonFieldIrrigationValuesResponseErrorT,
    POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_FAILED,
    POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_LOADING,
    POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS,
} from './PostFarmSeasonFieldIrrigationValuesTypes';

const postFarmSeasonFieldIrrigationValues =
    (
        farmId: number,
        farmSeasonId: number,
        farmSeasonFieldId: number,
        body: PostFarmSeasonFieldIrrigationValuesDataT,
        fieldsList: FarmSeasonFieldT[],
    ): FarmSeasonFieldsIrrigationValuesPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldIrrigationValuesDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldIrrigationValuesResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldIrrigationValuesResponseDataT> =
                await FarmSeasonFieldsIrrigationValuesApiService.post(farmId, farmSeasonId, farmSeasonFieldId, body);

            const newFieldList = fieldsList.map((field) => {
                if (field.id === res.data.farm_season_field_id) {
                    const newField = { ...field, irrigation: res.data.irrigation };

                    return newField;
                }

                return field;
            });

            dispatch({
                type: POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS,
                payload: {
                    fieldsList: newFieldList,
                    installation: res.data.installation,
                },
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_FAILED,
                error: error as PostFarmSeasonFieldIrrigationValuesResponseErrorT,
            });

            return {};
        }
    };

export default postFarmSeasonFieldIrrigationValues;
export const placeholder = '';
