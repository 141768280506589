import { Prompt } from '@components/Prompt/Prompt';
import { Trans, useTranslation } from 'react-i18next';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import MenuBar from '@components/MenuBar/MenuBar';

import useSidePageLeftLayoutStyle from './SidePageLeftLayout.style';
import { SidePageLeftLayoutPropsT } from './SidePageLeftLayoutTypes';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';

import StickyFooterLayout from '../StickyFooterLayout/StickyFooterLayout';
import { Typography, useTheme } from '@mui/material';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import StickyHeaderLayout from '../StickyHeaderLayout/StickyHeaderLayout';
import LoadingSection from '@components/LoadingSection/LoadingSection';

const SidePageLeftLayout = ({
    children,
    pageTitle,
    onArrowBack,
    onClose,
    displayPromptOnLeave,
    progressPercent,
    TwoButtonFooterViewProps,
    titleSlug,
    showTitleHelp,
    loading,
}: SidePageLeftLayoutPropsT): JSX.Element => {
    const { classes } = useSidePageLeftLayoutStyle({ hasFooter: !!TwoButtonFooterViewProps });
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <div className={classes.SidePageLeftLayout}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!!displayPromptOnLeave}
            />

            {/* --------------------------------- Header --------------------------------- */}
            <StickyHeaderLayout bgColor={theme.palette.spanishWhite.light} paddingTop="16px">
                {progressPercent !== undefined ? (
                    <ProgressBar color="secondary" currentStep={progressPercent} totalSteps={1} />
                ) : (
                    <div className={classes.fakeProgressBarHeight} />
                )}
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={pageTitle}
                    onArrowBack={onArrowBack}
                    onClose={onClose}
                />
            </StickyHeaderLayout>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans components={{ 1: <span className={classes.titleDarker} /> }} defaults={t(titleSlug)} />

                {showTitleHelp && <HelpButtonView id={typeof showTitleHelp === 'string' ? showTitleHelp : titleSlug} />}
            </Typography>

            {/* --------------------------------- Content -------------------------------- */}
            <LoadingSection className={classes.loadingSectionHeight} loading={loading}>
                {children}
            </LoadingSection>

            {/* ---------------------------------- Footer --------------------------------- */}
            {TwoButtonFooterViewProps && (
                <StickyFooterLayout bgColor={theme.palette.spanishWhite.light} paddingBottom="16px">
                    <TwoButtonFooterView rightDisabled={loading} {...TwoButtonFooterViewProps} />
                </StickyFooterLayout>
            )}
        </div>
    );
};

export default SidePageLeftLayout;
