/* eslint-disable @typescript-eslint/no-explicit-any */
import { ROLE_ID } from '@/constants/roleIds';
import {
    PermissionAndConditionsT,
    PermissionAndScopesT,
    PermissionNamesT,
    SplittedPermissionT,
} from '@actions/AppActions/GetPermissionsTypes';
import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import store, { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

export type ResourceForPermissionT<T extends PermissionNamesT> = Parameters<
    ReturnType<(typeof mapOfPermissionAndSelectors)[T]>
>[0];

export type AccessComputedT<T extends PermissionNamesT> = {
    access: boolean;
    forbiddenScope?: PermissionAndScopesT[T][number];
    forbiddenCondition?: PermissionAndConditionsT[T][number];
};

/** compute 1 permission type (edit_farm-season) with maybe many conditions/scopes */
const computePermissionHelper = <T extends PermissionNamesT>({
    permissionName,
    splittedPermissions,
    getEffectiveConditions,
}: {
    permissionName: T;
    splittedPermissions: null | Record<T, SplittedPermissionT[]>;
    /** check if the condition match */
    getEffectiveConditions: () => Record<PermissionAndConditionsT[T][number], boolean>;
}): AccessComputedT<T> => {
    if (!splittedPermissions) {
        return { access: false };
    }
    const effectiveConditions = getEffectiveConditions() as Record<string, boolean>;
    let forbiddenScope;
    let forbiddenCondition;
    const computedPermission = splittedPermissions[permissionName]?.reduce<boolean | null>(
        (canAccess, splitedPermission) => {
            // keep more restrictive access
            if (canAccess === false) {
                return false;
            }

            // TODO currently no scope handling

            if (!splitedPermission.condition) {
                if (splitedPermission.allow === 'CAN') {
                    return true;
                }
                forbiddenScope = splitedPermission.scope;
                return false;
            }

            // if condition match, apply the permission
            if (effectiveConditions[splitedPermission.condition]) {
                if (splitedPermission.allow === 'CAN') {
                    return true;
                }
                forbiddenScope = splitedPermission.scope;
                forbiddenCondition = splitedPermission.condition;
                return false;
            }

            return canAccess;
        },
        null,
    );
    return { access: computedPermission === true, forbiddenScope, forbiddenCondition };
};

const canEditFarmSeason = (permissions: null | Record<string, SplittedPermissionT[]>) => (farmSeason: FarmSeasonT) => {
    return computePermissionHelper({
        permissionName: 'EDIT_FARM-SEASON',
        splittedPermissions: permissions,
        getEffectiveConditions: () => {
            return {
                'IF-AUDIT-DONE': farmSeason.audit_status === 'done',
                'IF-AUDIT-IN-PROGRESS': farmSeason.audit_status === 'in_progress',
                'IF-SUMMARY-VALIDATED': !!farmSeason.summary_validated_at,
            };
        },
    });
};

const canEditFarmHistory = (permissions: null | Record<string, SplittedPermissionT[]>) => (farmSeason: FarmSeasonT) => {
    return computePermissionHelper({
        permissionName: 'EDIT_PRACTICE-HISTORY',
        splittedPermissions: permissions,
        getEffectiveConditions: () => {
            return {
                'IF-AUDIT-DONE': farmSeason.audit_status === 'done',
                'IF-AUDIT-IN-PROGRESS': farmSeason.audit_status === 'in_progress',
                'IF-SUMMARY-VALIDATED': !!farmSeason.summary_validated_at,
            };
        },
    });
};

const canEditFarmOperations =
    (permissions: null | Record<string, SplittedPermissionT[]>) => (farmSeason: FarmSeasonT) => {
        return computePermissionHelper({
            permissionName: 'EDIT_OPERATIONS',
            splittedPermissions: permissions,
            getEffectiveConditions: () => {
                return {
                    'IF-AUDIT-DONE': farmSeason.audit_status === 'done',
                    'IF-AUDIT-IN-PROGRESS': farmSeason.audit_status === 'in_progress',
                    'IF-SUMMARY-VALIDATED': !!farmSeason.summary_validated_at,
                };
            },
        });
    };

const canSeeResult = (permissions: null | Record<string, SplittedPermissionT[]>) => (farmSeason: FarmSeasonT) => {
    return computePermissionHelper({
        permissionName: 'SEE_RESULT',
        splittedPermissions: permissions,
        getEffectiveConditions: () => {
            return {
                'IF-VALIDATED': !!farmSeason.results_validated_by_agronomist,
            };
        },
    });
};

/** permission to edit farmSeason. hook way. Useful to pass farmSeason 1 time */
export const useCanEditFarmSeason = (farmSeason: FarmSeasonT) => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return { canEditFarmSeason: canEditFarmSeason(permissions)(farmSeason) };
};

export const useCanVisitFarm = () => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    const canVisitFarm = !!permissions?.['VISIT_FARM'];

    return canVisitFarm;
};

export const useIsReadOnlyUser = () => {
    const user = store.getState().auth.user;

    return !!(
        user &&
        (user.role_id === ROLE_ID.TRAINED_PARTNER_READONLY_ADMIN ||
            user.role_id === ROLE_ID.TRAINED_PARTNER_READONLY_COLLABORATOR)
    );
};

export const useCanEditAllOperations = () => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    const canVisitFarm = !!permissions?.['EDIT_OPERATIONS']?.some((p) => p.scope === 'ALL');

    return canVisitFarm;
};

export const useIsAssociateUser = () => {
    const user = store.getState().auth.user;

    return !!(
        user &&
        (user.role_id === ROLE_ID.AGRONOMIST ||
            user.role_id === ROLE_ID.ASSOCIATE_ADMIN ||
            user.role_id === ROLE_ID.EXTERNAL_AGRONOMIST ||
            user.role_id === ROLE_ID.SALES)
    );
};

export const useCanEditFarmHistory = (farmSeason: FarmSeasonT) => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return { canEditHistory: canEditFarmHistory(permissions)(farmSeason) };
};

export const useCanEditFarmOperations = (farmSeason: FarmSeasonT) => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return { canEditOperations: canEditFarmOperations(permissions)(farmSeason) };
};

/** permission to edit result. hook way. Useful to pass farmSeason 1 time */
export const useCanSeeResult = (farmSeason: FarmSeasonT) => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return canSeeResult(permissions)(farmSeason);
};

export const useCanUpdateSelfUpsell = () => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return !!permissions?.['UPDATE_UPSELL']?.some((p) => p.scope === 'FARMER');
};

export const useCanUpdateUpsellForFarmer = () => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return !!permissions?.['UPDATE_UPSELL']?.some((p) => p.scope === 'ASSOCIATE');
};

export const useCanEditIBAN = () => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return !!permissions?.['EDIT_IBAN'];
};

export const useCanManageResults = () => {
    const permissions = useSelector((state: AppStateT) => state.app.permissions);
    return !!permissions?.['MANAGE_RESULTS'];
};

export const mapOfPermissionAndSelectors = {
    'EDIT_FARM-SEASON': canEditFarmSeason,
    'EDIT_PRACTICE-HISTORY': canEditFarmHistory,
    EDIT_OPERATIONS: canEditFarmOperations,
    SEE_RESULT: canSeeResult,
};
/** get functions initialized with permissions from state. Useful to compute permission in a "no hook way" */
const usePermissions = <T extends PermissionNamesT>(permissions: T[]) => {
    const splittedPermissions = useSelector((state: AppStateT) => state.app.permissions);
    return Object.fromEntries(permissions.map((p) => [p, mapOfPermissionAndSelectors[p](splittedPermissions)])) as {
        [k in T]: ReturnType<(typeof mapOfPermissionAndSelectors)[k]>;
    };
};

export default usePermissions;
