import { Route, Navigate } from 'react-router-dom';
import TaskStep from '@pages/HomePage/Operations/TaskStep/TaskStep';
import TillageStep from '@pages/HomePage/Operations/TillageStep/TillageStep';
import CropsStep from '@pages/HomePage/Operations/CropsStep/CropsStep';
import FertilisationTask from '@pages/HomePage/Operations/FertilisationTask/FertilisationTask';
import Tillage from '@pages/HomePage/Operations/Tillage/Tillage';
import Sowing from '@pages/HomePage/Operations/Sowing/Sowing';
import Spraying from '@pages/HomePage/Operations/Spraying/Spraying';
import AppRoutes from '@components/AppRoutes/AppRoutes';

import { OperationsRouterTypes } from './OperationsRouterTypes';

const OperationsRouter = (): JSX.Element => {
    const OperationsRouterConfig: OperationsRouterTypes[] = [
        {
            path: '/:cropId/:tillageId/fertilisation',
            component: <FertilisationTask />,
        },
        {
            path: '/:cropId/:tillageId',
            component: <TaskStep />,
        },
        {
            path: '/:cropId',
            component: <TillageStep />,
        },
        {
            path: '/',
            component: <CropsStep />,
        },
        {
            path: '/:cropId/:tillageId/tillagePractice',
            component: <Tillage />,
        },
        {
            path: '/:cropId/:tillageId/spraying',
            component: <Spraying />,
        },
        {
            path: '/:cropId/:tillageId/sowing',
            component: <Sowing />,
        },
    ];

    return (
        <AppRoutes>
            {OperationsRouterConfig.map((item) => (
                <Route key={item.path} element={item.component} path={item.path} />
            ))}
            <Route element={<Navigate to="/operations" replace />} />
        </AppRoutes>
    );
};

export default OperationsRouter;
