import CardNameValueList from '@components/CardNameValueList/CardNameValueList';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';
import useFieldNameAreaModal from '@components/FieldNameAreaModal/useFieldNameAreaModal';
import FieldAmountLabel from '@components/FieldsAmountLabel/FieldAmountLabel';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import IconAction from '@components/IconButton/IconAction';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import { geoJsonFeatureT } from '@components/Map/MapTypes';
import useMapObserver from '@components/Map/hooks/useMapObserver';
import ModalRestartFieldEncoding from '@components/ModalRestartFieldEncoding/ModalRestartFieldEncoding';
import ModalUploadCapFile from '@components/ModalUploadCapFile/ModalUploadCapFile';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import SelectedFieldsHeader from '@components/SelectedFIeldsHeader/SelectedFieldsHeader';
import SelectedFieldsZone from '@components/SelectedFieldsZone/SelectedFieldsZone';
import TextLink from '@components/TextLink/TextLink';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import { FieldT } from '@entities/fieldEntity/fieldEntity.types';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import IconDelete from '@icons/icon-delete.svg';
import IconEdit from '@icons/icon-edit.svg';
import SidePageLeftLayout from '@layouts/SidePageLeftLayout/SidePageLeftLayout';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TaskIcon from '@mui/icons-material/Task';
import { Button, Typography } from '@mui/material';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import MapService from '@services/mapService/mapService';
import { ModalPropsT, ModalWarning } from '@soil-capital/ui-kit';
import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import area from '@turf/area';
import formatNumber from '@utils/formatNumber';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useFieldsTaskLogic from './FieldsTask.logic';
import useFieldsTaskStyle from './FieldsTask.style';
import usePolygonInterractionsOnMap from './usePolygonInterractionsOnMap';
import useUploadCapFieldsModal from './useUploadCapFieldsModal';

const FieldsTask = (): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useFieldsTaskStyle();
    const focusFieldsOnMap = (fields: Partial<FarmSeasonFieldT>[]) => {
        MapService.focusPolygons({ polygons: fields.map((f) => f.polygon) as geoJsonFeatureT[] });
    };

    /* ------------------------ Field Name and area Modal ----------------------- */
    const { FieldNameAreaModal, fieldNameAreaModalController } = useFieldNameAreaModal();

    /* --------------------------- Form interractions --------------------------- */
    const {
        fieldLoading,
        handleRestartEncodings,
        modalRestartController,
        onFieldDelete,
        handleEditFieldNameArea,
        deleteCropModal,
        controllerWarningAnyOperationCreated,
        TotalAreaIncreaseModal,
        TotalAreaDecreaseModal,
        checkTotalAreaLoading,
        fields,
        isArgentinianFarm,
        onConfirmFieldsTask,
        confirmFieldsTaskAttempted,
        deleteFieldLoading,
    } = useFieldsTaskLogic({ fieldNameAreaModalController });

    /* -------------------------- Cap file import modal ------------------------- */
    const {
        modalDefaultPrjUsed,
        modalUploadCapFileController,
        openUploadCapModal,
        setUploadWarningMessages,
        uploadWarningMessages,
        // REFACTOR
    } = useUploadCapFieldsModal({ focusFieldsOnMap });

    /* ---------------------------- Map intteractions --------------------------- */
    const {
        createNewField,
        getOpenDataAndCreateNewField,
        useSyncFormFieldsOnMap,
        createNewFieldAndRemoveMerged,
        createNewFieldsAndRemoveSplitted,
        editFieldPolygon,
        editFieldPolygonDeps,
        createFieldOnMapLoading,
        mergeFieldOnMapLoading,
        splitFieldOnMapLoading,
    } = usePolygonInterractionsOnMap({
        fieldNameAreaModalController,
    });

    /* --------------------- Observe and handle map actions --------------------- */
    useMapObserver('clickedEmptyPolygon', createNewField);
    useMapObserver('clickedOpenDataPolygon', getOpenDataAndCreateNewField);
    useMapObserver('drawnedPolygon', createNewField);
    useMapObserver('mergedPolygon', createNewFieldAndRemoveMerged);
    useMapObserver('splittedPolygon', createNewFieldsAndRemoveSplitted);
    useMapObserver('editedPolygon', editFieldPolygon, editFieldPolygonDeps);
    useSyncFormFieldsOnMap(fields);

    /* -------------------------------------------------------------------------- */

    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason);
    const baseUrlSourceData = `${process.env.REACT_APP_API_BASE_URL}/farm/${currentFarm?.id}/season/${currentFarmSeason?.id}`;
    const [sortedFields, setSortedFields] = useState<FieldT[]>([]);
    const hasLastYearField = !isBaselineSeason && !!fields.find((f) => f.previous_farm_season_field_id);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    const sortableFieldData = useMemo(() => {
        return fields.map((f: FieldT) => ({
            entity: f,
            area: f.area,
            name: f.name,
            original_area: f.original_area,
        }));
    }, [fields]);

    return (
        <SidePageLeftLayout
            pageTitle={t('pages.installationtasks.add-field-task.menu-title')}
            onArrowBack=".."
            onClose=".."
            titleSlug="pages.installationtasks.add-field-task.final-step.title"
            showTitleHelp
            TwoButtonFooterViewProps={{
                rightButtonOverride: (
                    <RestrictSaveFarmSeason>
                        <LoadingButton
                            loading={fieldLoading || checkTotalAreaLoading}
                            text={t('constants.continue')}
                            onClick={onConfirmFieldsTask}
                            disabled={
                                createFieldOnMapLoading ||
                                mergeFieldOnMapLoading ||
                                splitFieldOnMapLoading ||
                                deleteFieldLoading
                            }
                        />
                    </RestrictSaveFarmSeason>
                ),
            }}
        >
            {canEditFarmSeason.access &&
                (isBaselineSeason ? (
                    <Typography color="secondary" variant="body2" marginBottom="15px">
                        {t('pages.installationtasks.add-field-task.description')}
                    </Typography>
                ) : (
                    <Typography color="secondary" variant="body2" marginBottom="15px">
                        {t('pages.installationtasks.add-field-task.description-yoy')}
                    </Typography>
                ))}

            {/* Button upload CAP file. */}
            {!isArgentinianFarm && canEditFarmSeason.access && (
                <div className={classes.buttonImportCap}>
                    <Button
                        color="secondary"
                        onClick={() => openUploadCapModal(fields)}
                        fullWidth
                        startIcon={<FileUploadIcon />}
                    >
                        {t('pages.installationtasks.add-field-task.final-step.button-import-cap')}
                    </Button>
                    <span className={classes.buttonImportCapHelp}>
                        <HelpButtonView
                            size="large"
                            id="pages.installationtasks.add-field-task.final-step.button-import-cap"
                        />
                    </span>
                </div>
            )}

            {/* Fields list */}
            <SelectedFieldsHeader
                sortableData={sortableFieldData}
                headerText={<FieldAmountLabel />}
                onSortedFields={(fields) => {
                    setSortedFields(fields);
                }}
                additionnalSortFunctions={{
                    'custom-area-diff-asc': (a, b) =>
                        Math.abs(a.area - a.original_area) - Math.abs(b.area - b.original_area),
                    'custom-area-diff-desc': (a, b) =>
                        Math.abs(b.area - b.original_area) - Math.abs(a.area - a.original_area),
                }}
            />
            <SelectedFieldsZone placeholderShown={!fields.length} placeholderText={t('constants.no-field-selected')}>
                <CardNameValueList
                    nameWidth="35%"
                    getName={(item) => (
                        <span className={classes.fieldNameWrapper}>
                            <Typography variant="h6" className={classes.fieldName}>
                                {item.name}
                            </Typography>
                            {item.areaSource === 'pac' && (
                                <TooltipSc
                                    translationSlug={item.areaSource === 'pac' ? 'tooltip.field-coming-from-cap' : null}
                                >
                                    <TaskIcon className={classes.capFileIcon} />
                                </TooltipSc>
                            )}
                        </span>
                    )}
                    items={sortedFields.map((f) => ({
                        id: f.polygon?.id || 0,
                        name: f.name,
                        previousHasAgroforestry: f.previous_has_agroforestry,
                        previousIsPermanent: f.is_permanent,
                        area: f.area,
                        polygon: f.polygon,
                        areaSource: f.area_source,
                        originalArea: f.original_area,
                        updated_at: f.updated_at,
                    }))}
                    getValue={(item) => `${formatNumber(item.area, 2)} ${t('constants.hectare-unit')}`}
                    getActionIcons={(item) => [
                        canEditFarmSeason.access ? (
                            <IconAction
                                key="edit-action"
                                icon={<IconEdit />}
                                disabled={item.previousHasAgroforestry || item.previousIsPermanent}
                                TooltipProps={{
                                    translationSlug: item.previousHasAgroforestry
                                        ? 'tooltip.field-has-tree-and-can-not-be-edited'
                                        : null,
                                    placement: 'right',
                                }}
                                onClick={async () => {
                                    await handleEditFieldNameArea(item.id, {
                                        finalArea: item.area,
                                        originalArea: item.originalArea ?? area(item.polygon) / 10000,
                                        name: item.name,
                                        areaSource: item.areaSource,
                                    });
                                }}
                            />
                        ) : null,
                        canEditFarmSeason.access ? (
                            <IconAction
                                key="delete-action"
                                icon={<IconDelete />}
                                disabled={item.previousHasAgroforestry || item.previousIsPermanent}
                                TooltipProps={{
                                    translationSlug: item.previousHasAgroforestry
                                        ? 'tooltip.field-has-tree-and-can-not-be-deleted'
                                        : null,
                                    placement: 'right',
                                }}
                                onClick={() => onFieldDelete(item.id)}
                            />
                        ) : null,
                    ]}
                    getThumbnail={(item) => {
                        return {
                            src: `${baseUrlSourceData}/field/thumbnail/${item.id}?t=${item.updated_at ?? '1'}`,
                            onClick: (v) => v.id && v.polygon && MapService.focusPolygons({ polygons: [v.polygon] }),
                        };
                    }}
                />
            </SelectedFieldsZone>
            {confirmFieldsTaskAttempted}
            {!fields.length}
            <ErrorMessageView
                message={
                    confirmFieldsTaskAttempted && !fields.length
                        ? t('pages.installationtasks.add-field-task.atleast-one-field-required')
                        : null
                }
            />

            {/* Action reset fields */}
            {canEditFarmSeason.access && hasLastYearField && (
                <TextLink onClick={handleRestartEncodings} variant="body2" textAlign="right">
                    {t('pages.installationtasks.add-field-task.final-step.button-restart-encoding')}
                </TextLink>
            )}

            {/* --------------------------------- Modals --------------------------------- */}
            <ModalRestartFieldEncoding controller={modalRestartController} />
            {TotalAreaIncreaseModal}
            {/* Used for cohort 4 farmers */}
            <ModalWarning
                controller={controllerWarningAnyOperationCreated}
                description={t('modal.warning.delete-field-with-operations.description')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.delete-field-with-operations.title')}
            />
            <ModalWarning
                controller={deleteCropModal}
                confirmMessage={t('pages.installationtasks.add-field-task.delete-modal.confirm-message')}
                description={t('pages.installationtasks.add-field-task.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.add-field-task.delete-modal.title')}
            />
            {FieldNameAreaModal}
            {TotalAreaDecreaseModal}

            {/* ---------------------------- Upload CAP files ---------------------------- */}
            <ModalUploadCapFile controller={modalUploadCapFileController} farmSeasonId={currentFarmSeason.id} />
            <ModalWarning
                controller={modalDefaultPrjUsed}
                description={t('pages.installationtasks.add-field-task.default-prj-modal.description')}
                rightButtonText={t('OK')}
            />
            <ModalWarning
                controller={
                    {
                        isModalOpen: uploadWarningMessages.length > 0,
                        onModalConfirm: () => setUploadWarningMessages([]),
                    } as ModalPropsT
                }
                description={t('pages.installationtasks.import-field-task.partially-done')}
                rightButtonText={t('OK')}
                title={t('modal.warning.title')}
                confirmMessage={uploadWarningMessages.join('\n\n')}
            />
        </SidePageLeftLayout>
    );
};

export default FieldsTask;
