import { makeStyles } from 'tss-react/mui';
import { ColorPatternCardViewStyleProps } from '@components/ColorPatternCard/ColorPatternCardViewTypes';

const useColorPatternButtonViewStyle = makeStyles<ColorPatternCardViewStyleProps>()(
    (theme, { color, hasPattern, backgroundImage, border }) => ({
        card: {
            boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.05)',
            marginBottom: '5px',
        },
        container: {
            display: 'grid',
            gridTemplateColumns: '34px calc(100% - 34px - 48px) 48px',
            padding: '15px',
        },
        colorSquareContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        colorSquare: {
            backgroundColor: color || 'black',
            backgroundImage: hasPattern ? backgroundImage : '',
            borderRadius: '5px',
            width: '34px',
            height: '34px',
            border: `solid ${border ? '2px' : 0} ${theme.palette.primary.main}`,
        },
        textSection: {
            marginLeft: '15px',
        },
        textSectionTitle: {
            lineHeight: 1.3,
        },
        textSectionDescription: {
            display: 'grid',
            gridTemplateColumns: '80px calc(100% - 80px)',
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        icons: {
            fontSize: '20px',
            color: theme.palette.primary.light,
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.primary.dark,
                borderRadius: '100%',
                background: theme.palette.grey[100],
            },
        },
        editIcon: {
            marginRight: '5px',
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
);

export default useColorPatternButtonViewStyle;
