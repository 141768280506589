import { geoJsonFeatureT } from '@components/Map/MapTypes';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import MapService from '@services/mapService/mapService';
import { geoJsonOpenDataFeatureT, MapEventsSubscribableT } from '@services/mapService/mapServiceTypes';
import area from '@turf/area';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PolygonInterractionsOnMapPropsT } from './FieldsTaskTypes';
import useCurrentFarmSeasonId from '@hooks/currentFarmSeasonId';
import fieldEntity from '@entities/fieldEntity';
import { FieldBodyT, FieldT, PolygonGeometryT } from '@entities/fieldEntity/fieldEntity.types';
import EntityError from '@entities/EntityError';
import { FieldNameAreaDataT, FieldNameAreaRespT } from '@components/FieldNameAreaModal/FieldNameAreaModalTypes';
import sleep from '@utils/sleep';
import theme from '@theme/theme';

const usePolygonInterractionsOnMap = ({ fieldNameAreaModalController }: PolygonInterractionsOnMapPropsT) => {
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);
    const { farmSeasonId } = useCurrentFarmSeasonId();

    const { fieldState } = fieldEntity.useState({ farmSeasonId });
    const [createField, { isLoading: createFieldOnMapLoading }] = fieldEntity.useCreate();
    const [updateField] = fieldEntity.useUpdate();
    const [saveMergedField, { isLoading: mergeFieldOnMapLoading }] = fieldEntity.useSaveMerged();
    const [saveSplittedField, { isLoading: splitFieldOnMapLoading }] = fieldEntity.useSaveSplited();

    /* ---------------- Enable polygon creation mode (if access) ---------------- */
    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => undefined;
        }

        MapService.creatablePolygons({ createMode: true });
        MapService.showOpenDataLayer({ openDataMode: true });
        return () => {
            MapService.updateSelectedPolygons({ selectedPolygons: [] });
            MapService.creatablePolygons({ createMode: false });
            MapService.showOpenDataLayer({ openDataMode: false });
        };
    }, []);

    /* ------------------------- Show fields on map and keep them sync ------------------------ */
    const useSyncFormFieldsOnMap = (fields: FieldT[]) => {
        useEffect(() => {
            MapService.addPolygons({
                polygons: fields
                    // handle imutability
                    .map(
                        (field): geoJsonFeatureT => ({
                            ...field.polygon,
                            properties: {
                                ...field.polygon.properties,
                                previous_has_agroforestry: field.previous_has_agroforestry || undefined,
                                is_permanent: field.is_permanent || undefined,
                            },
                        }),
                    )
                    .filter((p) => !!p),
            });
        }, [fields]);
    };

    // what to do with edited polygons
    const editFieldPolygon = async (editedPolygon: geoJsonFeatureT) => {
        const field = fieldState.getById(editedPolygon.id);
        if (!field) {
            throw new EntityError('Field', 'cache');
        }
        await fieldNameAreaModalController.openEditFieldModal(
            prepareFieldDataForModal(editedPolygon),
            async (fieldData) => {
                await updateField({
                    farmSeasonId,
                    id: editedPolygon.id,
                    body: getFieldBody(fieldData, editedPolygon.geometry),
                });
            },
        );
    };

    const createNewField = async (addedPolygon: geoJsonFeatureT) => {
        await fieldNameAreaModalController.openCreateFieldModal(
            prepareFieldDataForModal(addedPolygon),
            async (fieldData) => {
                await createField({ farmSeasonId, body: getFieldBody(fieldData, addedPolygon.geometry) });
            },
        );
    };

    const getOpenDataAndCreateNewField = async (addedPolygon: geoJsonOpenDataFeatureT) => {
        // open the modal without data to show the loader
        fieldNameAreaModalController.openCreateFieldModal();

        if (!addedPolygon.properties?.parcel_id || !addedPolygon.properties?.version) {
            throw Error('no opendata properties needed to fetch the parcel');
        }
        const openDataParcel = (
            await MapService.mapOpenDataParcel({
                source: addedPolygon.properties.source,
                version: addedPolygon.properties.version,
                parcelId: addedPolygon.properties.parcel_id,
            })
        ).data;

        fieldNameAreaModalController.openCreateFieldModal(
            prepareFieldDataForModal(
                addedPolygon as geoJsonFeatureT,
                parseFloat(String(openDataParcel.properties.area)),
            ),

            async (fieldData) => {
                const newPolygonGeometry: PolygonGeometryT = {
                    type: 'Polygon', // make sure we don't end up with a MultiPolygon
                    coordinates: openDataParcel.geometry.coordinates,
                };

                await createField({
                    body: getFieldBody({ ...fieldData, areaSource: 'opendata' }, newPolygonGeometry),
                    farmSeasonId,
                });
            },
        );
    };

    /* ----------------------------- Merge Polygons ----------------------------- */
    const createNewFieldAndRemoveMerged = async ({
        newPolygon,
        oldPolygons,
    }: MapEventsSubscribableT['mergedPolygon']) => {
        fieldNameAreaModalController.openCreateFieldModal(prepareFieldDataForModal(newPolygon), async (fieldData) => {
            await saveMergedField({
                farmSeasonId,
                body: {
                    merged_field: getFieldBody(fieldData, newPolygon.geometry),
                    from_field_ids: oldPolygons.map((p) => p.id),
                },
            });
        });
    };

    /* ----------------------------- Split polygons ----------------------------- */
    const createNewFieldsAndRemoveSplitted = ({
        newPolygons,
        oldPolygons,
    }: MapEventsSubscribableT['splittedPolygon']) => {
        if (newPolygons === null || oldPolygons === null) {
            return;
        }

        const splittedFieldIdMapNewFields: Record<number, geoJsonFeatureT[]> = Object.fromEntries(
            oldPolygons.map((p) => [p.id, []]),
        );
        newPolygons.forEach((p) => {
            if (!p.properties.label || !p.properties.area || !p.properties.originalFeatureId) {
                throw Error('No properties label or area or originalFeatureId to add new field (split polygon)');
            }
            splittedFieldIdMapNewFields[Number(p.properties.originalFeatureId)].push(p);
        });

        oldPolygons.reduce(async (previousPromise, oldPolygon) => {
            await previousPromise;

            const newFieldsBodies: FieldBodyT[] = [];
            let previousPolygon: geoJsonFeatureT | null = null;

            // Collect new fields for 1 original splitted polygon
            await splittedFieldIdMapNewFields[oldPolygon.id].reduce(
                async (previousModalPromise, newPolygon, i, arr) => {
                    const isLastPolygon = i === arr.length - 1;
                    const previousModalResp = await previousModalPromise;
                    // If a modal has been cancelled, we can't save the whole field split.
                    // So we loop on false and never get the isLastPolygon who trigger the saveSplitteField()
                    if (previousModalResp === false) {
                        return false;
                    }

                    // Will not enter in the contition at first iteration.
                    if (previousModalResp !== null && previousPolygon) {
                        newFieldsBodies.push(getFieldBody(previousModalResp, previousPolygon.geometry));
                        // Wait the close modal animation before opening the next one
                        await sleep(theme.transitions.duration.leavingScreen + 50);
                    }

                    previousPolygon = newPolygon;

                    return fieldNameAreaModalController.openCreateFieldModal(
                        prepareFieldDataForModal(newPolygon),
                        async (props: FieldNameAreaRespT) => {
                            // Save all splitted fields on last sub-field
                            if (isLastPolygon) {
                                newFieldsBodies.push(getFieldBody(props, newPolygon.geometry));
                                await saveSplittedField({
                                    farmSeasonId,
                                    body: { from_field_id: oldPolygon.id, splitted_fields: newFieldsBodies },
                                });
                            }
                        },
                    );
                },
                Promise.resolve<FieldNameAreaRespT | false | null>(null),
            );
            await sleep(theme.transitions.duration.leavingScreen + 50);
        }, Promise.resolve());
    };

    const prepareFieldDataForModal = (
        polygon: geoJsonFeatureT,
        originalArea: number = area(polygon) / 10000,
    ): FieldNameAreaDataT => ({
        finalArea: null, // All polygons are new shape so no user_area to take into account
        originalArea: originalArea,
        name: polygon.properties.label ?? '',
    });

    const getFieldBody = (fieldData: FieldNameAreaRespT, polygonGeometry: PolygonGeometryT) => {
        const fieldBody: FieldBodyT = {
            name: fieldData.name,
            user_area: fieldData.userArea,
            original_area: fieldData.originalArea,
            area_source: 'drawn',
            polygon_geometry: polygonGeometry,
        };

        return fieldEntity.schema.validateSync(fieldBody);
    };

    return {
        useSyncFormFieldsOnMap,
        createNewField,
        getOpenDataAndCreateNewField,
        createNewFieldAndRemoveMerged,
        createNewFieldsAndRemoveSplitted,
        editFieldPolygon,
        editFieldPolygonDeps: [fieldState],
        createFieldOnMapLoading,
        mergeFieldOnMapLoading,
        splitFieldOnMapLoading,
    };
};

export default usePolygonInterractionsOnMap;
