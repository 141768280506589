import { Prompt } from '@components/Prompt/Prompt';
import { useTranslation } from 'react-i18next';

import useSidePageRightLayoutStyle from './SidePageRightLayout.style';
import { SidePageRightLayoutPropsT } from './SidePageRightLayoutTypes';
import { Button, Typography } from '@mui/material';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import TextLink from '@components/TextLink/TextLink';
import useCallbackLoading from '@hooks/useCallbackLoading';
import StickyFooterLayout from '../StickyFooterLayout/StickyFooterLayout';

const SidePageRightLayout = ({
    children,
    pageTitle,
    displayPromptOnLeave,
    onConfirmClick,
    confirmText,
    confirmDisabled,
    confirmOverride,
    onCancelClick,
    cancelText,
    onActionClick,
    actionText,
    animContentChange,
}: SidePageRightLayoutPropsT): JSX.Element => {
    const { classes } = useSidePageRightLayoutStyle({ animContent: animContentChange });
    const { t } = useTranslation();

    const { callback: handleActionClick, loading: actionLinkLoading } = useCallbackLoading({ callback: onActionClick });
    const { callback: handleConfirmClick, loading: confirmButtonLoading } = useCallbackLoading({
        callback: onConfirmClick,
    });

    return (
        <>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!!displayPromptOnLeave}
            />

            <div id="SidePageRightLayout" className={classes.container}>
                {pageTitle && (
                    <Typography color="secondary" className={classes.pageTitle} variant="h4">
                        {pageTitle}
                    </Typography>
                )}

                {children}

                {/* Footer buttons */}
                <StickyFooterLayout bgColor="#fff" paddingBottom="16px">
                    <div className={`${classes.footer}`}>
                        {actionText && (
                            <TextLink
                                loaderPosition="left"
                                onClick={handleActionClick}
                                loading={actionLinkLoading}
                                variant="body2"
                            >
                                {actionText}
                            </TextLink>
                        )}
                        {cancelText && (
                            <Button color="inherit" sx={{ marginLeft: 5 }} onClick={onCancelClick}>
                                {cancelText}
                            </Button>
                        )}
                        {confirmOverride ||
                            (confirmText && (
                                <LoadingButton
                                    sx={{ marginLeft: 5 }}
                                    disabled={confirmDisabled}
                                    loading={confirmButtonLoading}
                                    text={confirmText}
                                    onClick={handleConfirmClick}
                                />
                            ))}
                    </div>
                </StickyFooterLayout>
            </div>
        </>
    );
};

export default SidePageRightLayout;
