import { Dispatch } from '@reduxjs/toolkit';

export const DELETE_FARM_SEASON_LIVESTOCKS_LOADING = 'DELETE_FARM_SEASON_LIVESTOCKS_LOADING';
export const DELETE_FARM_SEASON_LIVESTOCKS_FAILED = 'DELETE_FARM_SEASON_LIVESTOCKS_FAILED';
export const DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS = 'DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS';

export type DeleteFarmSeasonLivestocksResponseDataT = {
    installation: {
        onboarding: {
            finished: string[];
            livestock: {
                done: boolean;
                option: 'yes' | 'no';
            };
        };
    };
};

export type FarmSeasonLivestocksDeleteCallBackT = (
    dispatch: Dispatch<DeleteFarmSeasonLivestocksDispatchTypesT>,
) => Promise<DeleteFarmSeasonLivestocksResponseErrorT>;

export type DeleteFarmSeasonLivestocksResponseErrorT = {
    message?: string;
    list?: string[];
};

export type DeleteFarmSeasonLivestocksLoadingT = {
    type: typeof DELETE_FARM_SEASON_LIVESTOCKS_LOADING;
};

export type DeleteFarmSeasonLivestocksFailedT = {
    type: typeof DELETE_FARM_SEASON_LIVESTOCKS_FAILED;
    error: DeleteFarmSeasonLivestocksResponseErrorT;
};

export type DeleteFarmSeasonLivestocksSuccessT = {
    type: typeof DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS;
    payload: DeleteFarmSeasonLivestocksResponseDataT;
};

export type DeleteFarmSeasonLivestocksDispatchTypesT =
    | DeleteFarmSeasonLivestocksLoadingT
    | DeleteFarmSeasonLivestocksFailedT
    | DeleteFarmSeasonLivestocksSuccessT;
