import { FertiliserT } from '@reducers/FertiliserReducer/FertiliserReducerTypes';

import { MachineryFertiliserT } from '../FertilisationTask/FertilisationTaskTypes';

/**
 * compute the total of a component (n, p, k, ...) for all fertiliser operations
 */
const sumFertiliserComponent = (
    fertiliserOperations: MachineryFertiliserT[],
    componentKey: keyof Pick<FertiliserT, 'n' | 'p' | 'k' | 'ca' | 'mg' | 's'>,
): number => {
    if (!fertiliserOperations.length) {
        return 0;
    }

    const weightedComponents = fertiliserOperations.map(
        (operation) =>
            ((operation.fertiliser?.[componentKey] || 0) / 100) *
            ((operation.quantity || 0) * (operation.fertiliser?.volumic_mass || 1)),
    );

    return Math.ceil(weightedComponents.reduce((previousValue, currentValue) => previousValue + currentValue));
};

export default sumFertiliserComponent;
