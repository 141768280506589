import { IrrigationMethodT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const IRRIGATION_METHOD_LIST_GET_LOADING = 'IRRIGATION_METHOD_LIST_GET_LOADING';
export const IRRIGATION_METHOD_LIST_GET_SUCCESS = 'IRRIGATION_METHOD_LIST_GET_SUCCESS';
export const IRRIGATION_METHOD_LIST_GET_FAILED = 'IRRIGATION_METHOD_LIST_GET_FAILED';

export type IrrigationMethodListGetSuccessDataT = IrrigationMethodT[];

export type IrrigationMethodListGetLoadingT = {
    type: typeof IRRIGATION_METHOD_LIST_GET_LOADING;
};

export type IrrigationMethodListGetSuccessT = {
    type: typeof IRRIGATION_METHOD_LIST_GET_SUCCESS;
    payload: IrrigationMethodListGetSuccessDataT;
};

export type IrrigationMethodListGetFailedT = {
    type: typeof IRRIGATION_METHOD_LIST_GET_FAILED;
    error: string;
};

export type IrrigationMethodListGetDispatchType =
    | IrrigationMethodListGetLoadingT
    | IrrigationMethodListGetSuccessT
    | IrrigationMethodListGetFailedT;

export type IrrigationMethodListGetCallback = (
    dispatch: Dispatch<IrrigationMethodListGetDispatchType>,
) => Promise<boolean>;
