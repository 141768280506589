import { makeStyles } from 'tss-react/mui';

const useCoverCropTaskViewStyle = makeStyles()((theme) => ({
    relative: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '15px',
        marginBottom: '40px',
    },
    titleDarker: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
    checkboxContainer: {
        padding: '10px',
        border: `2px dashed ${theme.palette.secondary.main}`,
        marginBottom: '20px',
        borderRadius: '5px',
    },
    progressBar: {},
}));

export default useCoverCropTaskViewStyle;
