import { makeStyles } from 'tss-react/mui';

const useFarmStyle = makeStyles()((theme) => ({
    root: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menuBarTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
    },
    closeLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    icons: {
        fontSize: '16px',
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
    editIconWrapper: {
        marginRight: '18px',
        marginLeft: '-34px',
    },
    iconAndInfoRow: {
        marginBottom: '0.5rem',
        '>svg': {
            height: '1.5rem',
            width: '1.5rem',
            padding: '0.125rem',
            marginRight: '0.5rem',
            verticalAlign: 'middle',
            color: theme.palette.primary.main,
        },
    },
}));

export default useFarmStyle;
