import { notificationApi } from '@api/notification.api';

const useCelebrationNotificationContainerLogic = () => {
    // const celebrationNotifications = // RTK Hook
    const { data: celebrationNotification } = notificationApi.useGetCelebrationQuery();
    const [markCelebrationAsRead] = notificationApi.useMarkAsReadMutation();

    const readCelebration = () => {
        if (celebrationNotification?.oldest_celebration?.id) {
            markCelebrationAsRead({ notificationId: celebrationNotification.oldest_celebration.id });
        }
    };

    return {
        readCelebration,
        celebrationNotification: celebrationNotification?.oldest_celebration,
    };
};

export default useCelebrationNotificationContainerLogic;
