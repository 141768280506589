import useRelativeDate from '@hooks/useRelativeDate';
import { Badge, Card, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import useNotificationItemStyle from './NotificationItem.style';
import { NotificationItemProps } from './NotificationItemTypes';

const NotificationItem = ({
    icon,
    iconBg,
    iconColor,
    text,
    date,
    isLoading,
    showAction,
    onNotificationClick,
    onActionClick,
}: NotificationItemProps) => {
    const { classes } = useNotificationItemStyle({ iconBg, iconColor, clickable: !!onNotificationClick });
    const notifTextRef = useRef<HTMLSpanElement | null>(null);

    const [textOverflowing, setTextOverflowing] = useState(false);

    useEffect(() => {
        const textContainerHeight = notifTextRef?.current?.parentElement?.offsetHeight;
        const textHeight = notifTextRef?.current?.offsetHeight;

        if (textHeight && textContainerHeight) setTextOverflowing(textHeight > textContainerHeight);
    }, [text]);

    const { fullDate, relativeDate } = useRelativeDate(date);

    return (
        <>
            <Card className={classes.NotificationItem} onClick={() => onNotificationClick?.()}>
                <div className={classes.cardContent}>
                    <div className={classes.icon}>{icon}</div>
                    <div className={classes.cardTextContainer}>
                        <Typography className={classes.cardText} variant="body2">
                            <Tooltip
                                enterDelay={300}
                                enterNextDelay={300}
                                leaveDelay={100}
                                placement="top"
                                title={textOverflowing ? text ?? '' : ''}
                            >
                                <span ref={notifTextRef}>{text}</span>
                            </Tooltip>
                        </Typography>
                        {date && (
                            <Tooltip disableHoverListener={!relativeDate} title={fullDate}>
                                <Typography className={classes.cardDate} variant="body1">
                                    {relativeDate || fullDate}
                                </Typography>
                            </Tooltip>
                        )}
                    </div>
                    {isLoading ? (
                        <div className={classes.loaderContainer}>
                            <CircularProgress className={classes.loaderColor} size="15px" />
                        </div>
                    ) : (
                        <>
                            {showAction === 'closeSnackbar' && (
                                <IconButton
                                    component="span"
                                    size="small"
                                    onClick={(e: MouseEvent) => onActionClick?.(e)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}

                            {showAction === 'markAsRead' && (
                                <IconButton component="span" onClick={(e: MouseEvent) => onActionClick?.(e)}>
                                    <Badge badgeContent=" " color="primary" variant="dot">
                                        <></>
                                    </Badge>
                                </IconButton>
                            )}
                        </>
                    )}
                </div>
            </Card>
        </>
    );
};

export default NotificationItem;
