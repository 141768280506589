import { Card, CardActionArea, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { CheckCircle } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';

import useColorPatternButtonStyle from './ColorPatternButtonView.style';
import { ColorPatternButtonViewPropsT } from './ColorPatternButtonViewTypes';
import TooltipSc from '../TooltipSc/TooltipSc';

const ColorPatternButtonView = ({
    name,
    children,
    color,
    hasPattern,
    onClick,
    disabled,
    patternSvg,
    border,
    singleLineDescription,
    customImage,
    secondDescription,
    withStatus,
    taskDone,
    hasError,
    tooltipSlug,
}: ColorPatternButtonViewPropsT): JSX.Element => {
    const { classes } = useColorPatternButtonStyle({
        color,
        hasPattern,
        backgroundImage: `url(${patternSvg})`,
        border,
        singleLineDescription,
        secondDescription,
        withStatus,
        disabled: disabled || taskDone,
        hasError,
    });

    return (
        <Card className={classes.card} elevation={0} variant="elevation">
            <TooltipSc translationSlug={tooltipSlug} display="block">
                <CardActionArea className={classes.container} disabled={disabled || hasError} onClick={onClick}>
                    {customImage ? (
                        <div className={classes.custom}>{customImage}</div>
                    ) : (
                        <div className={classes.colorContainer} />
                    )}
                    <div className={classes.textSection}>
                        <Typography className={classes.textSectionTitle} color="primary" variant="h5">
                            {name}
                        </Typography>
                        {children && <div className={classes.textSectionDescription}>{children}</div>}
                    </div>
                    {secondDescription && (
                        <div>
                            <Typography color="primary">{secondDescription}</Typography>
                        </div>
                    )}
                    {withStatus && (
                        <div className={classes.statusContainer}>
                            {taskDone ? <CheckCircle className={classes.done} /> : <CircleUnchecked />}
                        </div>
                    )}
                    <div className={classes.iconContainer}>
                        {hasError ? (
                            <ErrorIcon className={classes.iconError} />
                        ) : (
                            <ArrowRightAltIcon className={classes.icon} color={disabled ? 'disabled' : 'secondary'} />
                        )}
                    </div>
                </CardActionArea>
            </TooltipSc>
        </Card>
    );
};

export default ColorPatternButtonView;
