/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */

import { Alert, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import useAppDispatch from '@hooks/useAppDispatch';
import { ErrorActionRemove } from '@actions/ErrorActions/ErrorActions';
import selectErrors from '@store/selectors/errorTypeSelector';

const SnackbarError = (): JSX.Element => {
    const errorsServer = useSelector(selectErrors('server'));
    const dispatch = useAppDispatch();
    const handleClose = (id: string, preventClose?: boolean) => {
        if (preventClose) {
            return;
        }
        dispatch(ErrorActionRemove({ errorId: id }));
    };

    return (
        <>
            {errorsServer.map((err: any) => (
                <Snackbar key={err.errorId} autoHideDuration={5000} open onClose={(e) => handleClose(err.errorId, !!e)}>
                    <Alert elevation={4} severity="error" onClose={() => handleClose(err.errorId)}>
                        {err.message}
                    </Alert>
                </Snackbar>
            ))}
        </>
    );
};

export default SnackbarError;
