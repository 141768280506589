import TableView from '@components/Table/TableView';
import Card from '@components/CardBasic/CardBasic';
import { useTranslation } from 'react-i18next';
import { useTableViewCellStyle } from '@components/Table/TableView.style';

import { CardHistoryColumnsT, CardHistoryPropsT, CardHistoryRowsT } from './CardHistory.types';
import useCardHistoryLogic from './CardHistory.logic';

const CardHistory = ({
    tillageChangeList,
    coverCropHistory,
    fertilisationHistory,
    pastureHistory,
    tillageHistory,
}: CardHistoryPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useTableViewCellStyle();
    const {
        transformCoverCropHistory,
        transformFertilisationHistory,
        transformPastureHistory,
        transformTillageHistory,
    } = useCardHistoryLogic({ tillageChangeList });

    /* ------------------------------ Table Config ------------------------------ */
    const columns: CardHistoryColumnsT = [{ field: 'label' }, { field: 'value', cellClassName: classes.boldAndHalf }];
    const rows: CardHistoryRowsT = [
        { label: t('pages.farmer-data.history.col-tillage'), value: transformTillageHistory(tillageHistory) },
        { label: t('pages.farmer-data.history.col-cover-crop'), value: transformCoverCropHistory(coverCropHistory) },
        {
            label: t('pages.farmer-data.history.col-fertilisation'),
            value: transformFertilisationHistory(fertilisationHistory),
        },
        { label: t('pages.farmer-data.history.col-pasture'), value: transformPastureHistory(pastureHistory) },
    ];
    /* -------------------------------------------------------------------------- */

    return (
        <Card title={t('pages.farmer-data.history.title')}>
            <TableView columns={columns} rows={rows} size="small" />
        </Card>
    );
};

export default CardHistory;
