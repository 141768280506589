import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import { useContext, useEffect, useState } from 'react';
import useMapFieldReset from '@hooks/useMapFieldReset';

import { Context } from '../TillagePracticeTaskContext/TillagePracticeTaskContext';
import { ContextT } from '../TillagePracticeTaskContext/TillagePracticeTaskContextTypes';

import HistoryFormView from './HistoryFormView';

type HistoryFormProps = {
    canEdit?: boolean;
};

const HistoryForm = ({ canEdit = false }: HistoryFormProps): JSX.Element => {
    const {
        formik,
        groupedFieldIndex,
        t,
        selectedGroup,
        selectedKey,
        keyColor,
        nothingToSave,
        tillagePracticeList,
        setCurrentStep,
    } = useContext<ContextT>(Context);
    const [listOfYears, setListOfYears] = useState<(string | number)[]>([]);
    const [fromLabour, setFromLabour] = useState<boolean>(true);
    const resetMap = useMapFieldReset();

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const oldestYear = currentYear - 21;
        const tempListYear: (number | string)[] = [];

        for (let i = currentYear; i >= oldestYear; i -= 1) {
            tempListYear.push(i);
        }
        tempListYear.push(t('constants.mode-than-x-years', { x: 20 }));
        setListOfYears(tempListYear);
    }, []);

    // Put the color on the map
    useEffect(() => {
        // set color of the group
        const fieldsWithColorOnMap: FarmSeasonFieldT[] = [];
        selectedGroup.forEach((field) => {
            fieldsWithColorOnMap.push({ ...field, mapLayerColor: keyColor[selectedKey] });
        });

        if (fieldsWithColorOnMap.length > 0) {
            MapService.addPolygons({
                polygons: fieldsWithColorOnMap.map((field) => addPolygonData({ field, withColor: true })),
            });
        }

        // trigger the change to verifiy the form validity
        formik.setFieldValue('year_of_change', formik.values.year_of_change);

        // reset the map on unmount
        return () => {
            resetMap(true);
        };
    }, []);

    // Updating the visibility of the year of end labour
    useEffect(() => {
        const tillageChangeNotFromLabour = [5, 6];

        if (
            !tillageChangeNotFromLabour.includes(+formik.values.tillage_change_type_id) &&
            formik.values.tillage_change_type_id !== ''
        ) {
            setFromLabour(false);
        } else {
            setFromLabour(true);
            formik.setFieldValue('year_end_labour', '');
        }
    }, [formik.values.tillage_change_type_id]);

    return (
        <HistoryFormView
            formik={formik}
            groupedFieldIndex={groupedFieldIndex}
            listOfYears={listOfYears}
            nothingToSave={nothingToSave}
            t={t}
            tillagePracticeList={tillagePracticeList}
            fromLabour={fromLabour}
            setCurrentStep={setCurrentStep}
            canEdit={canEdit}
        />
    );
};

export default HistoryForm;
