import { GetFarmSeasonCropsDispatchTypesT } from '@actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { areaSourcesT, geoJsonFeatureT } from '@components/Map/MapTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { Feature } from '@/services/shapeService/shapeServiceTypes';

export const POST_FARM_SEASON_FIELDS_LOADING = 'POST_FARM_SEASON_FIELDS_LOADING';
export const POST_FARM_SEASON_FIELDS_FAILED = 'POST_FARM_SEASON_FIELDS_FAILED';
export const POST_FARM_SEASON_FIELDS_SUCCESS = 'POST_FARM_SEASON_FIELDS_SUCCESS';

export type PostFarmSeasonFieldDataT = {
    id?: number;
    original_area: number;
    user_area: number | null;
    name: string;
    polygon: geoJsonFeatureT;
    shapefileCrop?: number | string;
    shapefileCountry?: string;
    area_source?: areaSourcesT;
    metadata?: Feature;
};

export type PostFarmSeasonFieldsDataT = {
    fields: Array<PostFarmSeasonFieldDataT> | false;
};

export type PostFarmSeasonFieldsResponseDataT = {
    fields?: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            field: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsDispatchTypesT | GetFarmSeasonCropsDispatchTypesT>,
) => Promise<boolean>;

export type PostFarmSeasonFieldsResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_LOADING;
};

export type PostFarmSeasonFieldsFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_FAILED;
    error: PostFarmSeasonFieldsResponseErrorT;
};

export type PostFarmSeasonFieldsSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_SUCCESS;
    payload: PostFarmSeasonFieldsResponseDataT;
};

export type PostFarmSeasonFieldsDispatchTypesT =
    | PostFarmSeasonFieldsLoadingT
    | PostFarmSeasonFieldsFailedT
    | PostFarmSeasonFieldsSuccessT;
