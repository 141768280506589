import _ from 'lodash';
import { useCallback, useState } from 'react';

import { coupleParams, moveStateData, RelationsDndPropsT, removeCoupleParams } from './RelationsDndTypes';
import RelationsDndView from './RelationsDndView';

const RelationsDnd = ({ state, warningOperationDone, warningPermanentCrop }: RelationsDndPropsT): JSX.Element => {
    const [moveState, setMoveState] = useState<moveStateData | null>(null);

    const defineMoveState = ({ dragIndex, hoverIndex }: moveStateData): void => {
        let newHoverIndex = hoverIndex;

        if (dragIndex > hoverIndex) {
            /*
             * Check if hovered card is coupled_id (This blocks dragging between coupled_id cards)
             * If coupled_id then iterate over the cards until not coupled_id found
             * When dragged down
             */
            for (let i = hoverIndex; i >= 0; i -= 1) {
                if (
                    state?.fieldCrops[i].coupled_id === null ||
                    state?.fieldCrops[i].coupled_id !== state?.fieldCrops[i - 1]?.coupled_id
                ) {
                    newHoverIndex = i;
                    break;
                }
            }
        } else if (dragIndex < hoverIndex) {
            /*
             * Check if hovered card is coupled_id (This blocks dragging between coupled_id state?.fieldCrops)
             * If coupled_id then iterate over the state?.fieldCrops until not coupled_id found
             * When dragged up
             */
            for (let i = hoverIndex; i <= state?.fieldCrops.length; i += 1) {
                if (
                    state?.fieldCrops[i].coupled_id === null ||
                    state?.fieldCrops[i].coupled_id !== state?.fieldCrops[i + 1]?.coupled_id
                ) {
                    newHoverIndex = i;
                    break;
                }
            }
        }

        // Sets the target position to move the card on mouse click-up-event
        setMoveState({ dragIndex, hoverIndex: newHoverIndex });
    };

    const moveCrop = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCrop = state?.fieldCrops[dragIndex];
            const newCropArr = [
                ...(state?.fieldCrops || []).filter(
                    (card) => `${card.farm_season_crop.id}` !== `${dragCrop.farm_season_crop.id}`,
                ),
            ];

            newCropArr.splice(hoverIndex, 0, dragCrop);

            state?.setFieldCrops([
                ...newCropArr.map((crop, index) => ({
                    ...crop,
                    order: index,
                })),
            ]);
            setMoveState(null);
        },
        [state?.fieldCrops],
    );

    const couple = ({ selectedIndex, coupled_id, oldCoupledId = null }: coupleParams) => {
        state?.setFieldCrops([
            ...(state?.fieldCrops || []).map((fieldCrop, index) => {
                if (oldCoupledId !== null) {
                    if (fieldCrop.coupled_id === oldCoupledId) {
                        return {
                            ...fieldCrop,
                            coupled_id,
                            yield: null,
                        };
                    }
                }

                if (selectedIndex === index || selectedIndex + 1 === index) {
                    return {
                        ...fieldCrop,
                        coupled_id,
                        yield: null,
                    };
                }

                return {
                    ...fieldCrop,
                    yield: null,
                };
            }),
        ]);
    };

    const removeCouple = ({ selectedIndex, coupled_id }: removeCoupleParams) => {
        state?.setFieldCrops([
            ...(state?.fieldCrops || []).map((fieldCrop, index) => {
                if (coupled_id !== null && coupled_id === fieldCrop.coupled_id) {
                    return {
                        ...fieldCrop,
                        coupled_id: null,
                        yield: null,
                    };
                }

                if (selectedIndex === index || selectedIndex + 1 === index) {
                    return {
                        ...fieldCrop,
                        coupled_id: null,
                        yield: null,
                    };
                }

                return {
                    ...fieldCrop,
                    yield: null,
                };
            }),
        ]);
    };

    const defineCouple = ({ index }: { index: number }) => {
        if (
            state?.fieldCrops[index].coupled_id !== null &&
            state?.fieldCrops[index + 1].coupled_id !== null &&
            state?.fieldCrops[index].coupled_id === state?.fieldCrops[index + 1].coupled_id
        ) {
            removeCouple({
                selectedIndex: index,
                coupled_id:
                    state?.fieldCrops.filter(
                        (fieldCrop) => fieldCrop.coupled_id === state?.fieldCrops[index].coupled_id,
                    ).length > 2
                        ? state?.fieldCrops[index].coupled_id
                        : null,
            });
        }

        if (
            state?.fieldCrops[index].coupled_id !== null &&
            state?.fieldCrops[index + 1].coupled_id !== null &&
            state?.fieldCrops[index].coupled_id !== state?.fieldCrops[index + 1].coupled_id
        ) {
            couple({
                selectedIndex: index,
                coupled_id: state?.fieldCrops[index].coupled_id,
                oldCoupledId: state?.fieldCrops[index + 1].coupled_id,
            });
        }

        if (state?.fieldCrops[index].coupled_id !== null && state?.fieldCrops[index + 1].coupled_id === null) {
            couple({ selectedIndex: index, coupled_id: state?.fieldCrops[index].coupled_id });
        }

        if (state?.fieldCrops[index].coupled_id === null && state?.fieldCrops[index + 1].coupled_id === null) {
            couple({ selectedIndex: index, coupled_id: _.uniqueId() });
        }

        if (state?.fieldCrops[index].coupled_id === null && state?.fieldCrops[index + 1].coupled_id !== null) {
            couple({ selectedIndex: index, coupled_id: state?.fieldCrops[index + 1].coupled_id });
        }
    };

    const deleteCrop = (index: number) => {
        const currentCrop = state.fieldCrops.find((item, i) => i === index);

        if (warningPermanentCrop()) {
            return;
        }

        if (
            currentCrop?.id &&
            warningOperationDone({
                farmSeasonFieldCropId: currentCrop?.id,
            })
        ) {
            return;
        }
        state.setFieldCrops([
            ...state.fieldCrops
                .filter((fieldCrop, i) => i !== index)
                .map((fieldCrop) => {
                    if (
                        currentCrop?.coupled_id === fieldCrop.coupled_id &&
                        state.fieldCrops.filter((item) => item.coupled_id === currentCrop?.coupled_id).length < 3
                    ) {
                        return {
                            ...fieldCrop,
                            coupled_id: null,
                            yield: null,
                        };
                    }

                    return { ...fieldCrop };
                }),
        ]);
    };

    return (
        <RelationsDndView
            defineCouple={defineCouple}
            defineMoveState={defineMoveState}
            deleteCrop={deleteCrop}
            fieldCrops={state?.fieldCrops}
            moveCrop={moveCrop}
            moveState={moveState}
        />
    );
};

export default RelationsDnd;
