import { makeStyles } from 'tss-react/mui';

const useNotificationButtonStyle = makeStyles()((theme) => ({
    button: {
        padding: '10px 14px',
        minWidth: 0,
        borderRadius: Number(theme.shape.borderRadius) / 3,
        backgroundColor: theme.palette.spanishWhite.light,
        '&:hover': {
            backgroundColor: theme.palette.spanishWhite.main,
        },
    },
    bellIcon: {
        fontSize: '20px',
    },
    popover: {
        marginTop: '10px',
        marginLeft: '1px',
    },
}));

export default useNotificationButtonStyle;
