import { Checkbox, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconDelete from '@icons/icon-delete.svg';
import AutocompleteFertiliser from '@components/AutocompleteFertiliser/AutocompleteFertiliser';

import useFertiliserInputStyle from './FertiliserInputView.style';
import { FertiliserInputViewPropsT } from './FertiliserInputViewTypes';

/* eslint-disable react/jsx-props-no-spreading */
const FertiliserInputView = ({
    fertilisersTypeIds,
    fertilisersUnit,
    onFertiliserChange,
    unit,
    onQuantityChange,
    inhibitorValue,
    onInhibitorChange,
    withInhibitor,
    fertiliserError,
    fertiliserHelperText,
    quantity,
    quantityError,
    quantityHelperText,
    className,
    fertiliser,
    disabled,
    onDelete,
    liquid = false,
}: FertiliserInputViewPropsT): JSX.Element => {
    const [isHighEmissions, setIsHighEmissions] = useState(false);
    const { classes } = useFertiliserInputStyle();
    const { t } = useTranslation();

    useEffect(() => {
        setIsHighEmissions(fertiliser?.high_emission || false);
    }, [fertiliser]);

    return (
        <div className={`${classes.container} ${className}`}>
            <AutocompleteFertiliser
                autocompleteProps={{
                    className: `${isHighEmissions && classes.highEmissionBackground} ${
                        classes.fertiliserInput
                    } fertiliserInputTransition`,
                    disabled,
                    error: fertiliserError,
                    helperText: fertiliserHelperText,
                    placeholder: t('constants.search-product'),
                    value: fertiliser?.id || '',
                }}
                fertilisersTypeIds={fertilisersTypeIds}
                fertilisersUnit={fertilisersUnit}
                liquid={liquid}
                onChange={(newValue) => onFertiliserChange?.(newValue)}
            />
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="gray" variant="caption">{`${unit}/${t(
                                'constants.hectare-unit',
                            )}`}</Typography>
                        </InputAdornment>
                    ),
                }}
                className={classes.quantityInput}
                disabled={disabled}
                error={quantityError}
                helperText={quantityHelperText}
                inputProps={{
                    style: { paddingRight: '0' },
                }}
                type="number"
                value={quantity ? String(quantity) : ''}
                variant="outlined"
                fullWidth
                onChange={onQuantityChange}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
            {withInhibitor && (
                <div className={classes.checkboxContainer}>
                    <Checkbox
                        checked={inhibitorValue}
                        className={classes.checkbox}
                        disabled={disabled}
                        name=""
                        size="medium"
                        disableRipple
                        onChange={onInhibitorChange}
                    />
                </div>
            )}
            {!disabled && (
                <div className={`${classes.deleteButton} deleteTransition`}>
                    <SvgIcon className={classes.icons} color="primary" component={IconDelete} onClick={onDelete} />
                </div>
            )}
        </div>
    );
};

export default FertiliserInputView;
