import { Dispatch } from '@reduxjs/toolkit';
import { geoJsonFeatureObjectPointT, geoJsonFeaturePointT } from '@components/Map/MapTypes';
import { CountryT } from '@reducers/StaticReducer/StaticReducerType';
import { AppActionsT } from '../AppActions/AppActionsTypes';

export const FARM_SET_CURRENT = 'FARM_SET_CURRENT';
export const FARM_SET_LIST = 'FARM_SET_LIST';

export const FARM_SAVE_LOADING = 'FARM_SAVE_LOADING';
export const FARM_SAVE_SUCCESS = 'FARM_SAVE_SUCCESS';
export const FARM_SAVE_FAILED = 'FARM_SAVE_FAILED';

export const FARM_ALL_LOADING = 'FARM_ALL_LOADING';
export const FARM_ALL_SUCCESS = 'FARM_ALL_SUCCESS';
export const FARM_ALL_FAILED = 'FARM_ALL_FAILED';

export const FARM_UPDATE_LOADING = 'FARM_UPDATE_LOADING';
export const FARM_UPDATE_SUCCESS = 'FARM_UPDATE_SUCCESS';
export const FARM_UPDATE_FAILED = 'FARM_UPDATE_FAILED';

export const FARM_DELETE_LOADING = 'FARM_DELETE_LOADING';
export const FARM_DELETE_SUCCESS = 'FARM_DELETE_SUCCESS';
export const FARM_DELETE_FAILED = 'FARM_DELETE_FAILED';

export type FarmT = {
    id: number;
    name: string;
    address: string;
    postal_code: string;
    city: string;
    country_id: string; // TODO change type to number
    country: CountryT;
    geom?: geoJsonFeatureObjectPointT;
    geom_fallback?: geoJsonFeatureObjectPointT;
    center?: geoJsonFeaturePointT;
    is_v1: boolean;
    partner?: PartnerT;
};

export type PartnerT = {
    default_prepaid_product_plan_id: number | null;
    default_product_plans: string;
    id: number;
    name: string;
};

export type FarmSetCurrentT = {
    type: typeof FARM_SET_CURRENT;
    payload: { farmId: number };
};
export type FarmSetListT = {
    type: typeof FARM_SET_LIST;
    payload: { farmList: FarmT[] };
};

export type DeletedFarmT = {
    id: number;
};

// POST/PUT request payload
export type FarmPayloadT = {
    name: string;
    address: string;
    postal_code: string;
    city: string;
    country_id: string;
};

export type FarmUpdatePayloadT = {
    farmId: number | string;
    userId: number;
    payload: FarmPayloadT;
};

// DELETE request payload
export type FarmDeletePayloadT = {
    farmId: number;
    userId: number;
};

// Error request response
export type FarmResponseErrorT = {
    message?: string;
    list?: string[];
};

// redux actions
// save
export type FarmSaveLoadingT = {
    type: typeof FARM_SAVE_LOADING;
};

export type FarmSaveSuccessT = {
    type: typeof FARM_SAVE_SUCCESS;
    payload: FarmT;
};

export type FarmSaveFailedT = {
    type: typeof FARM_SAVE_FAILED;
    error: FarmResponseErrorT;
};

export type FarmSaveActionCallback = (dispatch: Dispatch<AppActionsT>) => Promise<FarmResponseErrorT>;

// getAll
export type FarmGetAllLoadingT = {
    type: typeof FARM_ALL_LOADING;
};

export type FarmGetAllSuccessT = {
    type: typeof FARM_ALL_SUCCESS;
    payload: FarmT[];
};

export type FarmGetAllFailedT = {
    type: typeof FARM_ALL_FAILED;
    error: FarmResponseErrorT;
};

export type FarmGetAllActionCallback = (dispatch: Dispatch<AppActionsT>) => Promise<FarmT[] | false>;

// update
export type FarmUpdateLoadingT = {
    type: typeof FARM_UPDATE_LOADING;
};

export type FarmUpdateSuccessT = {
    type: typeof FARM_UPDATE_SUCCESS;
    payload: FarmT;
};

export type FarmUpdateFailedT = {
    type: typeof FARM_UPDATE_FAILED;
    error: FarmResponseErrorT;
};

// delete
export type FarmDeleteLoadingT = {
    type: typeof FARM_DELETE_LOADING;
};

export type FarmDeleteSuccessT = {
    type: typeof FARM_DELETE_SUCCESS;
    payload: DeletedFarmT;
};

export type FarmDeleteFailedT = {
    type: typeof FARM_DELETE_FAILED;
    error: FarmResponseErrorT;
};

export type FarmDeleteActionCallback = (dispatch: Dispatch<FarmActionsT>) => Promise<FarmResponseErrorT>;

export type FarmActionsT =
    | FarmSaveLoadingT
    | FarmSaveSuccessT
    | FarmSaveFailedT
    | FarmGetAllLoadingT
    | FarmGetAllFailedT
    | FarmGetAllSuccessT
    | FarmUpdateLoadingT
    | FarmUpdateSuccessT
    | FarmUpdateFailedT
    | FarmDeleteLoadingT
    | FarmDeleteSuccessT
    | FarmDeleteFailedT
    | FarmSetCurrentT
    | FarmSetListT;
