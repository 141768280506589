import { makeStyles } from 'tss-react/mui';

const useSelectFieldStepViewStyle = makeStyles({})((theme) => ({
    container: {},
    titleDarker: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
    checkboxTitleContainer: {
        padding: '3px 60px 3px 10px',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        borderRadius: '5px',
        marginBottom: '10px',
        border: `1px dashed ${theme.palette.primary.light}`,
    },
    arrowDownIcon: {
        transform: 'rotate(-90deg)',
        fontSize: '20px',
    },
    checkbox: {
        borderRadius: '5px',
        '& svg': {
            borderColor: theme.palette.secondary.light,
            height: '20px',
            width: '20px',
        },
    },
}));

export default useSelectFieldStepViewStyle;
