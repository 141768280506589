/* eslint-disable consistent-return,func-names,no-param-reassign,no-multi-assign,padding-line-between-statements,one-var,spaced-comment,no-plusplus */
// modified version of direct_select mode, with snap mode and no more "move polygon" functionality
// source: @mapbox/mapbox-gl-draw/src/modes/direct_select.js

import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import { isOfMetaType } from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import { createSnapList, snap, snapOptions } from './utils/snap';

const DirectSelectWithSnap = { ...DirectSelect };

const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);

DirectSelectWithSnap.onSetup = function (opts) {
    const featureId = opts.featureId;
    const feature = this.getFeature(featureId);

    if (!feature) {
        throw new Error('You must provide a featureId to enter direct_select mode');
    }

    if (feature.type === Constants.geojsonTypes.POINT) {
        throw new TypeError("direct_select mode doesn't handle point features");
    }

    const state = {
        map: this.map,
        options: this._ctx.options,
        featureId,
        feature,
        dragMoveLocation: opts.startPos || null,
        dragMoving: false,
        canDragMove: false,
        selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
        polygonsLayer: opts?.polygonsLayer ?? this.map.getSource('polygonsLayer')?._options?.data?.features ?? [],
        currentVertexPosition: 0,
    };

    this.setSelectedCoordinates(this.pathsToCoordinates(featureId, state.selectedCoordPaths));
    this.setSelected(featureId);
    doubleClickZoom.disable(this);

    this.setActionableState({
        trash: true,
    });

    // snapping options
    state.options.snap = snapOptions.SNAP;
    state.options.snapOptions = {
        snapPx: snapOptions.SNAP_PX,
        snapToMidPoints: snapOptions.SNAP_TO_MID_POINTS,
        snapVertexPriorityDistance: snapOptions.SNAP_VERTEX_PRIORITY_DISTANCE,
    };

    // create a snap list
    state.snapList = createSnapList(this.map, state.polygonsLayer, feature);

    // callbacks
    const moveEndCallback = () => {
        state.snapList = createSnapList(this.map, state.polygonsLayer, feature);
    };
    const optionsChangedCallBack = (newOptions) => {
        state.options = newOptions;
    };
    state['moveEndCallback'] = moveEndCallback;
    state['optionsChangedCallBack'] = optionsChangedCallBack;
    this.map.on('moveend', moveEndCallback);
    this.map.on('draw.snap.options_changed', optionsChangedCallBack);

    return state;
};

DirectSelectWithSnap.toDisplayFeatures = function (state, geojson, display) {
    const featureId = geojson?.properties?.id ?? undefined;
    if (featureId) {
        geojson.properties.active = Constants.activeStates.ACTIVE;
        display(geojson);
        createSupplementaryPoints(geojson, {
            map: this.map,
            midpoints: true,
            selectedPaths: state.selectedCoordPaths,
        }).forEach(display);
        this.fireActionable(state);
    }
    display(geojson);
};

DirectSelectWithSnap.onTouchStart = DirectSelectWithSnap.onMouseDown = function (state, e) {
    if (isVertex(e)) return this.onVertex(state, e);
    if (isMidpoint(e)) return this.onMidpoint(state, e);
};

DirectSelectWithSnap.onStop = function (state) {
    // remove callbacks
    this.map.off('moveend', state.moveEndCallback);
    this.map.off('draw.snap.options_changed', state.optionsChangedCallBack);

    DirectSelect.onStop.call(this);
};

DirectSelectWithSnap.clickActiveFeature = function (state) {
    const lng = state.snappedLng;
    const lat = state.snappedLat;
    state.feature.updateCoordinate(`0.${state.currentVertexPosition}`, lng, lat);

    state.selectedCoordPaths = [];
    this.clearSelectedCoordinates();
    state.feature.changed();
};

DirectSelectWithSnap.dragVertex = function (state, e, _delta) {
    // SNAP!
    const { lng, lat } = snap(state, e);
    state.snappedLng = lng;
    state.snappedLat = lat;

    const selectedCoords = state.selectedCoordPaths.map((coord_path) => state.feature.getCoordinate(coord_path));
    for (let i = 0; i < selectedCoords.length; i++) {
        state.feature.updateCoordinate(state.selectedCoordPaths[i], lng, lat);
    }
};

export default DirectSelectWithSnap;
