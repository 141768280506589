import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import addPolygonData from '@utils/addPolygonData';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Location, Navigate, useLocation, useParams } from 'react-router-dom';
import staticTillages from '@constants/tillages';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import useMapFieldReset from '@hooks/useMapFieldReset';
import useOperationDone from '@hooks/useOperationDone';
import { Override } from '@/utils/Override';

import { CropsStepNavStateT } from '../CropsStep/CropsStepTypes';

import { TillageStepRouteParamsT } from './TillageStepTypes';
import TillageStepView from './TillageStepView';
import { useCanEditFarmOperations } from '@hooks/usePermissions';
import {
    FarmSeasonFieldCropDataT,
    FarmSeasonFieldT,
} from '@/store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

const TillageStep = (): JSX.Element | null => {
    const { cropId } = useParams<TillageStepRouteParamsT>();
    const { state } = useLocation() as Override<Location, { state: CropsStepNavStateT }>;
    const { t } = useTranslation();
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList);
    const cropColors = setupCropPaletteFromCropLegacy(crops);
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const resetMap = useMapFieldReset(true);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditOperations } = useCanEditFarmOperations(currentFarmSeason);

    const { getTillagesDone } = useOperationDone();
    const operationsDone = getTillagesDone(Number(cropId));

    useEffect(() => {
        if (operationsDone.forTillages?.length > 1) {
            MapService.addPolygons({
                polygons: operationsDone.fromFilteredFields.map((field) =>
                    addPolygonData(
                        fields
                            ?.find((f: FarmSeasonFieldT) => f.id === field.id)
                            ?.field_crops?.some(
                                (field_crop: FarmSeasonFieldCropDataT) => field_crop.farm_season_crop.crop.is_permanent,
                            )
                            ? {
                                  field,
                                  colorOverride: '#000000',
                              }
                            : {
                                  field,
                                  colorPalette: cropColors,
                                  withTillage: true,
                              },
                    ),
                ),
                autofocus: true,
            });
        }

        return () => {
            resetMap(true);
        };
    }, [operationsDone.fromFilteredFields, operationsDone.forTillages, cropColors, fields]);

    const getStaticTillages = () =>
        staticTillages?.filter((staticTillage) =>
            operationsDone.forTillages.find((tillage) => tillage?.id === staticTillage.id),
        );

    if (operationsDone.forTillages?.length === 1) {
        if (state?.from === '/operations')
            return <Navigate to={`/operations/${cropId}/${operationsDone.forTillages[0]?.id}`} replace />;

        return <Navigate to="/operations" replace />;
    }

    if (!operationsDone) {
        return null;
    }

    return (
        <TillageStepView
            menuTitle={t(crops?.find((crop) => crop.id === parseInt(cropId || '', 10))?.crop?.translation_slug ?? '')}
            operationsDone={operationsDone}
            tillages={getStaticTillages()}
            canEdit={canEditOperations.access}
        />
    );
};

export default TillageStep;
