/* eslint-disable @typescript-eslint/no-explicit-any */
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import { useFormik } from 'formik';
import farmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import useMapFieldReset from '@hooks/useMapFieldReset';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import { geoJsonFeatureT } from '@components/Map/MapTypes';
import {
    GetFarmSeasonOperationSowingApiT,
    PostFarmSeasonOperationSowingApiT,
} from '@services/farmSeasonOperationApiService/farmSeasonOperationApiServiceTypes';
import { postFarmSeasonOperationSowing } from '@actions/FarmSeasonOperation/PostFarmSeasonOperationSowing';
import emptyFieldValuesToNull from '@/utils/emptyFieldValuesToNull';

import { OperationFieldT } from '../components/OperationCardForm/OperationFieldType';

import { SowingOperationFormT } from './SowingTypes';
import SowingView from './SowingView';
import { useCanEditFarmOperations } from '@hooks/usePermissions';

const Sowing = (): JSX.Element | null => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [operationsData, setOperationsData] = useState<GetFarmSeasonOperationSowingApiT | null>(null);
    const [operationsDataLoading, setOperationsDataLoading] = useState<boolean>(true);
    const [submitted, setSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditOperations } = useCanEditFarmOperations(currentFarmSeason);

    const { cropId, tillageId } = useParams<{ cropId: string; tillageId: string }>();
    const currentCrop = useSelector((state: HomePageStateT) =>
        state.farmSeasonCrop.cropsList?.find((crop) => crop.id?.toString() === cropId),
    );
    const currentTillage = useSelector((state: HomePageStateT) =>
        state.static.tillagePracticeList?.find((tillagePractice) => tillagePractice.id.toString() === tillageId),
    );
    const machineries = useSelector((state: HomePageStateT) =>
        state.static.machineryList?.filter(
            (operation) =>
                operation.operation_type.id === 1 && currentTillage && operation.ranking >= currentTillage.ranking,
        ),
    );
    // sorting alphabeticaly operations
    // TODO: add a hook to sorte alphabetically an array based on a key like translation_slug
    machineries?.sort((a, b) => {
        const operationA = t(a.translation_slug);
        const operationB = t(b.translation_slug);

        if (operationA < operationB) {
            return -1;
        }

        if (operationA > operationB) {
            return 1;
        }

        return 0;
    });
    const currentFarmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const currentFarmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);

    const [hasDontHaveAnyError, setHasDontHaveAnyError] = useState(false);
    const navigate = useAppNavigate();

    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop?.cropsList);
    const fields = useSelector((store: HomePageStateT) => store.farmSeasonField?.fieldsList);
    const selectedFields = fields?.filter(
        (field) =>
            field.tillage_id === parseInt(tillageId || '') &&
            field.field_crops?.filter((field_crop) => field_crop.farm_season_crop.id === parseInt(cropId || '')).length,
    );
    const cropColors = setupCropPaletteFromCropLegacy(crops);
    const resetMap = useMapFieldReset();

    useEffect(() => {
        const polygons: geoJsonFeatureT[] =
            selectedFields?.map((field) => addPolygonData({ field, colorPalette: cropColors })) || [];

        MapService.addPolygons({ polygons, autofocus: true });

        return () => resetMap(true);
    }, []);

    // get operation data
    useEffect(() => {
        const getOperationData = async () => {
            setOperationsDataLoading(true);
            const res = await farmSeasonOperationApiService.get(
                currentFarmId,
                currentFarmSeasonId,
                'sowing',
                tillageId,
                cropId,
            );
            setOperationsData(res.data);
            setOperationsDataLoading(false);
        };

        getOperationData();
    }, []);

    const validationSchema = Yup.object({
        operations: Yup.array().of(
            Yup.object({
                machinery_id: Yup.number(),
                passage: Yup.number().integer().min(0),
            }),
        ),
        seed_origin_id: Yup.string().when('dont_have_any', {
            is: false,
            then: Yup.string().test(
                'seed-origin-required',
                t('validation.seed-origin'),
                (value) => value === '1' || value === '2',
            ),
        }),
        quantity: Yup.number().test(
            'seed-quantity-required',
            t('pages.operations.sowing.seed-weight-error'),
            (value, context) => Number(value) > 0 || context.parent.dont_have_any,
        ),
        dont_have_any: Yup.bool().test(
            'confirm-no-operation',
            t('pages.operations.sowing.confirm-no-operation'),
            (value, context): boolean =>
                value === true ||
                context.parent.operations?.filter((op: any) => !!op.passage).length > 0 ||
                parseInt(tillageId || '') === 4,
        ),
    } as Record<keyof SowingOperationFormT, Yup.BaseSchema>);

    const sowingOperationForm = useFormik<SowingOperationFormT>({
        initialValues: {
            operations: machineries?.map((operation) => ({ machinery_id: operation.id, passage: '', id: '' })) ?? [],
            seed_origin_id: '',
            quantity: '',
            dont_have_any: false,
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const postData: PostFarmSeasonOperationSowingApiT = {
                tillageId: Number(tillageId),
                farmSeasonCropId: Number(cropId),
                farmSeasonFieldIds: selectedFields?.map((field) => field.id),
                dont_have_any: values.dont_have_any,
                seed_origin_id: values.seed_origin_id !== '' ? +values.seed_origin_id : null,
                quantity: +values.quantity,
                operations: values.operations
                    .filter((op) => !!op.passage)
                    .map((op) => emptyFieldValuesToNull(op)) as PostFarmSeasonOperationSowingApiT['operations'],
            };

            setSubmitted(true);
            await dispatch(postFarmSeasonOperationSowing(currentFarmId, currentFarmSeasonId, postData));
            setSubmitting(false);
            navigate(`/operations/${cropId}/${tillageId}`);
        },
    });

    // This will filter index values based on machinery order.
    // Without this changing the language will reorder the labels but keep the input data in place (which by itself can cause issues)
    useEffect(() => {
        sowingOperationForm.setValues({
            ...sowingOperationForm.values,
            operations: [
                ...machineries.map((machinery) => ({
                    ...sowingOperationForm.values.operations.find(
                        (operation) => operation.machinery_id === machinery.id,
                    ),
                })),
            ] as OperationFieldT[],
        });
    }, [i18n.language]);

    // update the form with operation data
    useEffect(() => {
        const operationsForm = machineries?.map((machinery): OperationFieldT => {
            const associatedOperation = operationsData?.operations?.find((op) => op.machinery_id === machinery.id);

            return {
                id: associatedOperation?.id ?? '',
                machinery_id: machinery.id,
                passage: associatedOperation?.passage ?? '',
            };
        });

        sowingOperationForm.resetForm({
            values: {
                operations: operationsForm,
                seed_origin_id: operationsData?.seed_origin_id?.toString() ?? '',
                quantity: operationsData?.quantity?.toString() ?? '',
                dont_have_any: operationsData?.dont_have_any ?? false,
            },
        });
    }, [operationsData]);

    if (!currentCrop || !currentTillage) {
        return null;
    }

    return (
        <SowingView
            currentCrop={currentCrop}
            currentTillage={currentTillage}
            farmId={currentFarmId}
            farmSeasonId={currentFarmSeasonId}
            hasDontHaveAnyError={hasDontHaveAnyError}
            operationsDataLoading={operationsDataLoading}
            setHasDontHaveAnyError={setHasDontHaveAnyError}
            sowingMachineries={machineries}
            sowingOperationForm={sowingOperationForm}
            submitted={submitted}
            t={t}
            canEdit={canEditOperations.access}
        />
    );
};

export default Sowing;
