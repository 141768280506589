import { forwardRef } from 'react';
import BillingDetailsForm from '../BillingDetailsForm/BillingDetailsForm';
import LoadingSection from '../LoadingSection/LoadingSection';
import usePaymentFormLogic from './PaymentForm.logic';
import usePaymentFormStyle from './PaymentForm.style';
import { PaymentFormPropsT, PaymentFormRefControllerT } from './PaymentForm.types';

/**
 * This form has been designed to handle payment flow in the application. \
 * By default the form does not provide any button to trigger the submit action but for that you can use {@link ref}. \
 * This form redirects the user to the redirect url provided by the backend \
 * If you don't want this (^) behavior you should use {@link BillingDetailsForm} in a stand alone manner
 * @param userCompany can be used to initialize form data. This will also prevent the initial data API call provided by {@link usePaymentFormLogic}
 * @param ref can be used to submit form @see {@link BillingDetailsForm} JSDoc
 * @param type is used to define different logics @see {@link PaymentFormPropsT.type} for the list of available type
 *
 * @example
 * const formRef = useRef(null);
 *
 * <PaymentForm type='upsell' ref={formRef} />
 *
 * formRef.current?.redirectToPayment();
 */
const PaymentForm = forwardRef<PaymentFormRefControllerT, PaymentFormPropsT>(
    ({ userCompany, type, withUseFarmDataCheckbox, setIsSubmitting, setInitialLoading }, ref) => {
        const { classes } = usePaymentFormStyle();
        const { userCompanyLoading, userCompanyResData, billingDetailsFormRef } = usePaymentFormLogic({
            type,
            userCompany,
            paymentFormRef: ref,
            setInitialLoading,
            setIsSubmitting,
        });

        return (
            <LoadingSection loading={userCompanyLoading} className={classes.root}>
                <BillingDetailsForm
                    ref={billingDetailsFormRef}
                    userCompany={userCompany ?? userCompanyResData}
                    withUseFarmDataCheckbox={withUseFarmDataCheckbox}
                />
            </LoadingSection>
        );
    },
);

// TODO: This component has been replaced by useBillingDetailsPaymentFlow hook. This component is to be deleted in the next iteration
export default PaymentForm;
