/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import { FarmSeasonCropDataT } from '@pages/HomePage/Installation/InstallationTasks/CropsTask/CropListStep/CropListStepTypes';
import {
    FarmSeasonFieldCropDataT,
    FarmSeasonFieldT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { OperationTypeT, TillagePracticeT } from '@reducers/StaticReducer/StaticReducerType';
import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

import { GetHasAnyOperationDoneFiltersT } from './useOperationDoneTypes';

/* --------------------------- Useful transformers -------------------------- */
const getFieldCrops = (fields: FarmSeasonFieldT[]) => fields.map((f) => f.field_crops).flat();
/**
 * Build an object {cropId|tillageId: hasOperationDone}
 * @param fieldCropsMap fieldCrops filtered for each id => {id: fieldCropsFilteredByKey}
 * @returns A key value object {id: allDone} with all allDone = operation 100% done for all cropFields of id.
 */
const buildAllDoneMap = (fieldCropsMap: {
    [keyId: number]: FarmSeasonFieldCropDataT[];
}): { [keyId: number]: boolean } => {
    const keyIds = Object.keys(fieldCropsMap) as any as Array<number>;
    // By default all operation are AllDone. We will then set to false if we see it's undone. => AllDone = 100% done
    const allDoneDefault = Object.fromEntries(keyIds.map((keyId) => [keyId, true])) as Record<number, boolean>;
    const allDoneMap = keyIds.reduce((acc, keyId) => {
        // only if opperation is still AllDone, we check if the next field as any undone.
        if (acc[keyId] === true) {
            acc[keyId] = !fieldCropsMap[keyId].find(
                (fieldCrop) =>
                    !fieldCrop.operation_tillage_done ||
                    !fieldCrop.operation_spraying_done ||
                    !fieldCrop.operation_sowing_done ||
                    !fieldCrop.operation_fertilising_done,
            );
        }

        return acc;
    }, allDoneDefault);

    return allDoneMap;
};
/* -------------------------------------------------------------------------- */

/* ----------------------------- Useful filters ----------------------------- */
const filterByFieldId = (fields: FarmSeasonFieldT[], fieldId?: number): FarmSeasonFieldT[] =>
    !fieldId ? fields : fields.filter((field) => field.id === fieldId);

const filterByTillageId = (fields: FarmSeasonFieldT[], tillageId?: number): FarmSeasonFieldT[] =>
    !tillageId ? fields : fields.filter((field) => field.tillage_id === tillageId);

const filterByCropId = (fields: FarmSeasonFieldT[], cropId?: number): FarmSeasonFieldT[] =>
    !cropId
        ? fields
        : fields
              .map((field) => ({
                  ...field,
                  field_crops: field.field_crops?.filter((fieldCrop) => fieldCrop.farm_season_crop.crop.id === cropId),
              }))
              .filter((field) => !!field.field_crops?.length);

const filterByFieldCropId = (fields: FarmSeasonFieldT[], fieldCropId?: number): FarmSeasonFieldT[] =>
    !fieldCropId
        ? fields
        : fields
              .map((field) => ({
                  ...field,
                  field_crops: field.field_crops?.filter((fieldCrop) => fieldCrop.id === fieldCropId),
              }))
              .filter((field) => !!field.field_crops?.length);

const filterByFarmSeasonCropId = (fields: FarmSeasonFieldT[], farmSeasonCropId?: number | null): FarmSeasonFieldT[] =>
    !farmSeasonCropId
        ? fields
        : fields
              .map((field) => ({
                  ...field,
                  field_crops: field.field_crops?.filter(
                      (fieldCrop) => fieldCrop.farm_season_crop.id === farmSeasonCropId,
                  ),
              }))
              .filter((field) => !!field.field_crops?.length);
/* -------------------------------------------------------------------------- */

const useOperationDone = () => {
    // Get inital data from store
    const fields = useSelector((state: AppStateT) => state.farmSeasonField?.fieldsList) || [];
    const tillagePracticeList = useSelector((state: AppStateT) => state.static.tillagePracticeList) || [];

    /**
     * get tillage practices that exist for filtered fields + check on each tillage if all opereations categories are done
     * @param farmSeasonCropId filter fields and fieldCrops to keep only those releated to this farmSeasonCropId.
     */
    const getTillagesDone = (farmSeasonCropId: number) => {
        const filteredFields = filterByFarmSeasonCropId(fields, farmSeasonCropId);
        // Restrict tillageIds related to fields
        const tillageIdsRestricted = Array.from(new Set(filteredFields.map((f) => f.tillage_id)));
        // we get tillage from static data.
        const tillagePracticesRestricted = tillageIdsRestricted
            .map((tillageId) => tillagePracticeList?.find((tillage) => tillage.id === tillageId))
            .filter((tillage) => !!tillage) as TillagePracticeT[];

        const fieldCropsGroupedByTillageId = filteredFields.reduce((_fieldCropsGroupedByTillageId, field) => {
            const tillageId = field.tillage_id;

            if (tillageId) {
                _fieldCropsGroupedByTillageId[tillageId] = [
                    ...(_fieldCropsGroupedByTillageId[tillageId] || []),
                    ...(field.field_crops ?? []),
                ];
            }

            return _fieldCropsGroupedByTillageId;
        }, {} as any);

        return {
            forTillages: tillagePracticesRestricted,
            isDone: buildAllDoneMap(fieldCropsGroupedByTillageId),
            fromFilteredFields: filteredFields,
        };
    };

    /**
     * get farmSeasonCrops that exist for filtered fields + check on each farmSeasonCrop if all opereations categories are done
     * // TODO remove if we don't use ?
     */
    const getFarmSeasonCropsDone = () => {
        const fieldCrops = getFieldCrops(fields);
        const farmSeasonCropsEntries = fieldCrops.map((fieldCrop) => [
            fieldCrop?.farm_season_crop.id,
            fieldCrop?.farm_season_crop,
        ]);
        // keep only crops related to fieldCrops
        const farmSeasonCropsRestricted = Array.from(
            new Map<number, FarmSeasonCropDataT>(farmSeasonCropsEntries as any).values(),
        );

        const fieldCropsGroupedByCropId = fieldCrops.reduce((_fieldCropsGroupedByCropId, fieldCrop) => {
            const farmSeasonCropId = fieldCrop?.farm_season_crop.id;

            if (farmSeasonCropId) {
                _fieldCropsGroupedByCropId[farmSeasonCropId] = [
                    ...(_fieldCropsGroupedByCropId[farmSeasonCropId] || []),
                    fieldCrop,
                ];
            }

            return _fieldCropsGroupedByCropId;
        }, {} as any);

        return {
            forFarmSeasonCrops: farmSeasonCropsRestricted,
            isDone: buildAllDoneMap(fieldCropsGroupedByCropId),
            fromFilteredFields: fields,
        };
    };

    /**
     * get fields filtered by farmSeasonCrop and tillageId + check on each operation category if all opereations are done for filteredFields
     */
    const getCategoriesDone = (farmSeasonCropId: number, tillageId: number) => {
        const operationTypes: OperationTypeT[] = ['fertilising', 'sowing', 'spraying', 'tillage'];
        const allDoneDefault = Object.fromEntries(
            operationTypes.map((operationType) => [operationType, true]),
        ) as Record<OperationTypeT, boolean>;

        const fieldsFilteredByTillage = filterByTillageId(fields, tillageId);
        const fieldsFilteredByFieldCrop = filterByFarmSeasonCropId(fieldsFilteredByTillage, farmSeasonCropId);
        const fieldCrops = getFieldCrops(fieldsFilteredByFieldCrop);

        const allDone = fieldCrops.reduce((_allDone, fieldCrop) => {
            _allDone.tillage = (_allDone.tillage && fieldCrop?.operation_tillage_done) || false;
            _allDone.sowing = (_allDone.sowing && fieldCrop?.operation_sowing_done) || false;
            _allDone.spraying = (_allDone.spraying && fieldCrop?.operation_spraying_done) || false;
            _allDone.fertilising = (_allDone.fertilising && fieldCrop?.operation_fertilising_done) || false;

            return _allDone;
        }, allDoneDefault);

        return {
            forCategories: operationTypes,
            isDone: allDone,
            fromFilteredFields: fieldsFilteredByFieldCrop,
            hasAnyDone: !!Object.values(allDone).find((done) => done),
        };
    };

    /** check for all filtered fields if any operation is done */
    const getHasAnyOperationDone = (filters: GetHasAnyOperationDoneFiltersT) => {
        const fieldsFilteredByFarmSeasonField = filterByFieldId(fields, filters.farmSeasonFieldId);
        const fieldsFilteredByFarmSeasonCrop = filterByFarmSeasonCropId(
            fieldsFilteredByFarmSeasonField,
            filters.farmSeasonCropId,
        );
        const fieldsFilteredByCrop = filterByCropId(fieldsFilteredByFarmSeasonCrop, filters.cropId);
        const fieldsFilteredByFieldCrop = filterByFieldCropId(fieldsFilteredByCrop, filters.farmSeasonFieldCropId);
        const fieldsFilteredByTillage = filterByTillageId(fieldsFilteredByFieldCrop, filters.tillageId);

        const fieldCrops = getFieldCrops(fieldsFilteredByTillage);

        const hasAnyDone = !!fieldCrops.find(
            (fieldCrop) =>
                fieldCrop?.operation_tillage_done ||
                fieldCrop?.operation_sowing_done ||
                fieldCrop?.operation_spraying_done ||
                fieldCrop?.operation_fertilising_done,
        );

        return hasAnyDone;
    };

    /** check for all filtered fields if any operation is created - used for cohort 4 farmers */
    const getHasV3OperationsCreated = (filters: GetHasAnyOperationDoneFiltersT) => {
        const fieldsFilteredByFarmSeasonField = filterByFieldId(fields, filters.farmSeasonFieldId);
        const fieldsFilteredByFarmSeasonCrop = filterByFarmSeasonCropId(
            fieldsFilteredByFarmSeasonField,
            filters.farmSeasonCropId,
        );
        const fieldsFilteredByCrop = filterByCropId(fieldsFilteredByFarmSeasonCrop, filters.cropId);
        const fieldsFilteredByFieldCrop = filterByFieldCropId(fieldsFilteredByCrop, filters.farmSeasonFieldCropId);
        const fieldsFilteredByTillage = filterByTillageId(fieldsFilteredByFieldCrop, filters.tillageId);

        const fieldCrops = getFieldCrops(fieldsFilteredByTillage);

        const hasCreatedOperations = !!fieldCrops.find(
            (fieldCrop) => fieldCrop?.farm_season_operations?.length ?? 0 > 0,
        );

        return hasCreatedOperations;
    };

    return {
        getFarmSeasonCropsDone,
        getTillagesDone,
        getCategoriesDone,
        getHasAnyOperationDone,
        getHasV3OperationsCreated,
    };
};

export default useOperationDone;
