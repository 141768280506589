import { api } from './api.service';
import {
    CancelUpsellRequestT,
    CancelUpsellResponseT,
    GetUpsellStatusRequestDataT,
    GetUpsellStatusT,
    PayUpsellRequestT,
    PayUpsellResponseT,
    SendUpsellReminderEmailRequestT,
    SendUpsellReminderEmailResponseT,
    StartUpsellRequestDataT,
    StartUpsellT,
} from './upsell.types';
import { userCompanyApi } from './userCompany.api';

export const upsellApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUpsellStatus: builder.query<GetUpsellStatusT, GetUpsellStatusRequestDataT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/upsell/status`,
                method: 'GET',
            }),
        }),
        startUpsell: builder.mutation<StartUpsellT, StartUpsellRequestDataT>({
            query: ({ farmId, farmSeasonId, body }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/upsell/start`,
                method: 'POST',
                body: body,
            }),
            onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
                try {
                    const { data: updatedUserCompany } = await queryFulfilled;
                    dispatch(
                        userCompanyApi.util.updateQueryData(
                            'getUserCompany',
                            { userId: updatedUserCompany.user_id },
                            (draft) => {
                                Object.assign(draft, updatedUserCompany);
                            },
                        ),
                    );
                } catch {}
            },
        }),
        cancelUpsell: builder.mutation<CancelUpsellResponseT, CancelUpsellRequestT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/upsell/cancel`,
                method: 'POST',
            }),
            onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
                try {
                    const { data: updatedUpsellStatus } = await queryFulfilled;
                    dispatch(
                        upsellApi.util.updateQueryData('getUpsellStatus', request, (draft) => {
                            Object.assign(draft, updatedUpsellStatus);
                        }),
                    );
                } catch {}
            },
        }),
        sendReminderEmail: builder.query<SendUpsellReminderEmailResponseT, SendUpsellReminderEmailRequestT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/upsell/reminder-email`,
                method: 'POST',
            }),
        }),
        payUpsell: builder.mutation<PayUpsellResponseT, PayUpsellRequestT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/upsell/payment/app`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useGetUpsellStatusQuery,
    useStartUpsellMutation,
    useCancelUpsellMutation,
    useLazySendReminderEmailQuery,
    usePayUpsellMutation,
    useLazyGetUpsellStatusQuery,
} = upsellApi;
