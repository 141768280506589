import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { AppActionsT } from '../AppActions/AppActionsTypes';

export const GET_FARM_SEASON_FIELDS_LOADING = 'GET_FARM_SEASON_FIELDS_LOADING';
export const GET_FARM_SEASON_FIELDS_FAILED = 'GET_FARM_SEASON_FIELDS_FAILED';
export const GET_FARM_SEASON_FIELDS_SUCCESS = 'GET_FARM_SEASON_FIELDS_SUCCESS';

export type GetFarmSeasonFieldsResponseDataT = {
    fields: FarmSeasonFieldT[];
};

export type FarmSeasonFieldsGetCallBackT = (
    dispatch: Dispatch<AppActionsT>,
) => Promise<GetFarmSeasonFieldsResponseErrorT>;

export type GetFarmSeasonFieldsResponseErrorT = {
    message?: string;
    list?: string[];
};

export type GetFarmSeasonFieldsLoadingT = {
    type: typeof GET_FARM_SEASON_FIELDS_LOADING;
};

export type GetFarmSeasonFieldsFailedT = {
    type: typeof GET_FARM_SEASON_FIELDS_FAILED;
    error: GetFarmSeasonFieldsResponseErrorT;
};

export type GetFarmSeasonFieldsSuccessT = {
    type: typeof GET_FARM_SEASON_FIELDS_SUCCESS;
    payload: GetFarmSeasonFieldsResponseDataT;
};

export type GetFarmSeasonFieldsDispatchTypesT =
    | GetFarmSeasonFieldsLoadingT
    | GetFarmSeasonFieldsFailedT
    | GetFarmSeasonFieldsSuccessT;
