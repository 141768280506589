import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    TreesGetCallBackT,
    TreesDispatchTypes,
    TREES_GET_LOADING,
    TreesGetSuccessDataT,
    TREES_GET_SUCCESS,
    TREES_GET_FAILED,
    TreesGetFailedDataT,
} from './TreesGetTypes';

const getTrees =
    (): TreesGetCallBackT =>
    async (dispatch: Dispatch<TreesDispatchTypes>): Promise<TreesGetFailedDataT> => {
        try {
            dispatch({ type: TREES_GET_LOADING });

            const localTrees = localStorage.getItem('trees');

            if (!localTrees || !Array.isArray(JSON.parse(localTrees))) {
                const res: AxiosResponse<TreesGetSuccessDataT> = await StaticApiService.getTrees();

                localStorage.setItem('trees', JSON.stringify(res.data));

                dispatch({
                    type: TREES_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: TREES_GET_SUCCESS,
                    payload: JSON.parse(localTrees),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: TREES_GET_FAILED,
                error: error as TreesGetFailedDataT,
            });

            return {};
        }
    };

export default getTrees;
