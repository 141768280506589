import { SprayingPesticideDataT } from '@pages/HomePage/Operations/Spraying/SprayingTypes';
import findById from '@utils/findById';
import { useTranslation } from 'react-i18next';
import {
    FarmSeasonCropOperationT,
    FarmSeasonOperationFertilisingMachineryFertiliserT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

import { CardOperationLogicPropsT, CardOperationRowsT } from './CardOperation.types';
import formatNumber from '@utils/formatNumber';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCardOperationLogic = ({ pesticideList, fieldCrop, machineryList }: CardOperationLogicPropsT) => {
    const { t } = useTranslation();

    /* -------------------------- Transform Cell Values ------------------------- */
    /** Display "[practice type]: [x] passage(s)" */
    const transformToMachineryPassages = (machineryId: number, passages: number, boldLabel = false) => (
        <>
            <span className={boldLabel ? 'label' : ''}>
                {t(findById(machineryId, machineryList)?.translation_slug ?? '')}:{' '}
            </span>
            {passages || 0}{' '}
            {t('constants.unit.passage', {
                count: passages,
            })}
        </>
    );

    /** Display "[Phyto]: [x] [unit]" */
    const transformSprayingValue = (pesticide: SprayingPesticideDataT) => {
        const staticPesticide = findById(pesticide.pesticide_id, pesticideList);
        const pesticideName = pesticide?.pesticide?.name ?? staticPesticide?.name ?? '-NA-';
        const pesticideUnit = pesticide?.pesticide?.unit ?? staticPesticide?.unit ?? '?';

        return `${pesticideName}: ${formatNumber(Number(pesticide.quantity)) ?? ''} ${pesticideUnit}/${t(
            'constants.hectare-unit',
        )}`;
    };

    /** Display "[Fert.]: [x] l/ha" */
    const transformFertilisingValue = (machineryOperation: FarmSeasonOperationFertilisingMachineryFertiliserT) =>
        `${t(machineryOperation.fertiliser?.translation_slug)}: ${formatNumber(machineryOperation.quantity)} ${
            machineryOperation.unit
        }/${t('constants.hectare-unit')}`;

    const cellTillage = (operation: FarmSeasonCropOperationT<'App\\Models\\FarmSeasonOperationTillage'>) => {
        const operationMachineries = operation?.farm_season_operationable?.farm_season_operation_tillage_machinery;

        return operationMachineries?.length ? (
            <>
                <span className="label">{t('pages.farmer-data.operation.cell-tillage-operations')}:</span>
                <ul>
                    {operationMachineries.map((op) => (
                        <li key={op.id}>{transformToMachineryPassages(op.machinery_id, op.passage)}</li>
                    ))}
                </ul>
            </>
        ) : (
            '—'
        );
    };

    const cellSowing = (operationObject: FarmSeasonCropOperationT<'App\\Models\\FarmSeasonOperationSowing'>) => {
        const operation = operationObject?.farm_season_operationable;

        return operation ? (
            <>
                <span className="label">{t('pages.farmer-data.operation.cell-sowing-origin')}:</span>{' '}
                {t(operation?.seed_origin?.translation_slug)}
                <br />
                <span className="label">{t('pages.farmer-data.operation.cell-sowing-quantity')}:</span>{' '}
                {`${formatNumber(Number(operation?.quantity)) ?? ''} `}
                {t('constants.unit.kilo-per-ha', { count: Number(operation?.quantity) })}
                <br />
                <span className="label">{t('pages.farmer-data.operation.cell-sowing-operations')}:</span>
                <ul>
                    {operation.farm_season_operation_sowing_machinery?.map((op) => (
                        <li key={op.id}>{transformToMachineryPassages(op.machinery_id, op.passage)}</li>
                    ))}
                </ul>
            </>
        ) : (
            '—'
        );
    };

    const cellSpraying = (operationObject: FarmSeasonCropOperationT<'App\\Models\\FarmSeasonOperationSpraying'>) => {
        const operation = operationObject?.farm_season_operationable?.farm_season_operation_spraying_machinery?.[0];

        return operation ? (
            <div>
                <span className="label">{t('pages.farmer-data.operation.cell-spraying-width')}:</span> {operation.width}{' '}
                {t('constants.unit.meter', { count: Number(operation.width) })} <br />
                <span className="label">{t('pages.farmer-data.operation.cell-spraying-phytos')}:</span>{' '}
                {operation.passage} {t('constants.unit.passage', { count: operation.passage })} <br />
                <ul>
                    {operation.farm_season_operation_spraying_machinery_pesticide?.map((pesticide, i) => (
                        <li key={i}>{transformSprayingValue(pesticide)}</li>
                    ))}
                </ul>
            </div>
        ) : (
            '—'
        );
    };

    const cellFertilising = (
        operationObject: FarmSeasonCropOperationT<'App\\Models\\FarmSeasonOperationFertilising'>,
    ) => {
        const machineryOperations =
            operationObject?.farm_season_operationable?.farm_season_operation_fertilising_machinery;

        if (!machineryOperations?.length) {
            return '—';
        }

        return machineryOperations.map((machineryOp) => (
            <div key={machineryOp.id}>
                {transformToMachineryPassages(machineryOp.machinery_id, machineryOp.passage, true)}
                <ul>
                    {machineryOp.farm_season_operation_fertilising_machinery_fertiliser?.map((op, i) => (
                        <li key={i}>
                            {transformFertilisingValue(op)}{' '}
                            {op.inhibitor && `-> ${t('pages.farmer-data.operation.cell-fertilising-inhibitor')}`}
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };

    const operationCellMap = {
        tillage: cellTillage,
        sowing: cellSowing,
        spraying: cellSpraying,
        fertilising: cellFertilising,
    };
    const operationCellRenderer = (operationValue: FarmSeasonCropOperationT, row: CardOperationRowsT[0]) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        operationCellMap[row.id](operationValue as any);
    /* -------------------------------------------------------------------------- */

    const operationTillage = fieldCrop?.farm_season_operations?.find(
        (operation) => operation.farm_season_operationable_type === 'App\\Models\\FarmSeasonOperationTillage',
    );
    const operationSowing = fieldCrop?.farm_season_operations?.find(
        (operation) => operation.farm_season_operationable_type === 'App\\Models\\FarmSeasonOperationSowing',
    );
    const operationSpraying = fieldCrop?.farm_season_operations?.find(
        (operation) => operation.farm_season_operationable_type === 'App\\Models\\FarmSeasonOperationSpraying',
    );
    const operationFertilising = fieldCrop?.farm_season_operations?.find(
        (operation) => operation.farm_season_operationable_type === 'App\\Models\\FarmSeasonOperationFertilising',
    );

    return { operationCellRenderer, operationTillage, operationSowing, operationSpraying, operationFertilising };
};

export default useCardOperationLogic;
