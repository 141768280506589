import { api } from './api.service';
import {
    createUserCompanyRequestT,
    createUserCompanyResponseT,
    getUserCompanyRequestT,
    getUserCompanyResponseT,
    updateUserCompanyRequestT,
    updateUserCompanyResponseT,
} from './userCompany.types';

export const userCompanyApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserCompany: build.query<getUserCompanyResponseT, getUserCompanyRequestT>({
            query: ({ userId }) => ({
                url: `/users/${userId}/company`,
                method: 'GET',
            }),
            providesTags: (result) => (result ? [{ type: 'UserCompany', id: result.id }] : ['UserCompany']),
        }),
        updateUserCompany: build.mutation<updateUserCompanyResponseT, updateUserCompanyRequestT>({
            query: ({ userId, userCompanyId, body }) => ({
                url: `/users/${userId}/company/${userCompanyId}`,
                method: 'PUT',
                body: body,
            }),
            onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
                try {
                    const { data: updatedUserCompany } = await queryFulfilled;
                    dispatch(
                        userCompanyApi.util.updateQueryData(
                            'getUserCompany',
                            { userId: updatedUserCompany.user_id },
                            (draft) => {
                                Object.assign(draft, updatedUserCompany);
                            },
                        ),
                    );
                } catch {}
            },
        }),
        createUserCompany: build.mutation<createUserCompanyResponseT, createUserCompanyRequestT>({
            query: ({ userId, body }) => ({
                url: `/users/${userId}/company`,
                method: 'POST',
                body: body,
            }),
            onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
                try {
                    const { data: updatedUserCompany } = await queryFulfilled;
                    dispatch(
                        userCompanyApi.util.updateQueryData(
                            'getUserCompany',
                            { userId: updatedUserCompany.user_id },
                            (draft) => {
                                Object.assign(draft, updatedUserCompany);
                            },
                        ),
                    );
                } catch {}
            },
        }),
    }),
});

export const { useGetUserCompanyQuery, useUpdateUserCompanyMutation, useCreateUserCompanyMutation } = userCompanyApi;
