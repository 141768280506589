import { useEffect } from 'react';

export const useHubspot = () => {
    useEffect(() => {
        const hs = document.getElementById('hs-script-loader');
        if (process.env.NODE_ENV === 'production' && !hs) {
            const body = document.getElementsByTagName('body')[0];
            const scriptElt = document.createElement('script');
            scriptElt.async = true;
            scriptElt.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_PIXEL_CODE}.js`;
            body.appendChild(scriptElt);
        }
    }, []);
};
