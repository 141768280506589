import {
    FARM_ALL_FAILED,
    FARM_ALL_LOADING,
    FARM_ALL_SUCCESS,
    FARM_DELETE_FAILED,
    FARM_DELETE_LOADING,
    FARM_DELETE_SUCCESS,
    FARM_SAVE_FAILED,
    FARM_SAVE_LOADING,
    FARM_SAVE_SUCCESS,
    FARM_UPDATE_FAILED,
    FARM_UPDATE_LOADING,
    FARM_UPDATE_SUCCESS,
    FARM_SET_CURRENT,
    FARM_SET_LIST,
    FarmT,
} from '@actions/FarmActions/FarmActionsTypes';
import { RESET } from '@reducers/RootTypes';
import { AppActionsT } from '@actions/AppActions/AppActionsTypes';
import { SUBSCRIPTION_SAVE_FARM_SUCCESS } from '@actions/SubscriptionActions/SubscriptionSaveFarmTypes';
import { Reducer } from '@reduxjs/toolkit';

import { FarmReducerStateT } from './FarmReducerTypes';

export const selectedFarmListKey = 'selected_farm_list';
const selectedFarmListFromStorage = localStorage.getItem(selectedFarmListKey);
const selectedFarmListDefaultValue = selectedFarmListFromStorage ? JSON.parse(selectedFarmListFromStorage) : null;

const persistSelectedFarmList = (value: FarmT[] | null) =>
    localStorage.setItem(selectedFarmListKey, JSON.stringify(value));

export const selectedCurrentFarmKey = 'selected_current_farm';
const selectedCurrentFarmFromStorage = localStorage.getItem(selectedCurrentFarmKey);
const selectedCurrentFarmDefaultValue = selectedCurrentFarmFromStorage
    ? JSON.parse(selectedCurrentFarmFromStorage)
    : null;

const persistSelectedCurrentFarm = (value: FarmT | undefined) =>
    value ? localStorage.setItem(selectedCurrentFarmKey, JSON.stringify(value)) : null;

export const defaultFarmState: FarmReducerStateT = {
    loading: false,
    loaded: false,
    success: '',
    farmList: selectedFarmListDefaultValue,
    currentFarm: selectedCurrentFarmDefaultValue,
};

const farmReducer: Reducer<FarmReducerStateT, AppActionsT> = (state, action): FarmReducerStateT => {
    if (!state) {
        return defaultFarmState;
    }

    switch (action.type) {
        case FARM_SAVE_LOADING:
        case FARM_UPDATE_LOADING:
        case FARM_DELETE_LOADING:
        case FARM_ALL_LOADING:
            return { ...state, loading: true };

        case FARM_SAVE_FAILED:
        case FARM_UPDATE_FAILED:
        case FARM_DELETE_FAILED:
        case FARM_ALL_FAILED:
            return { ...state, error: action.error, loading: false };

        case FARM_ALL_SUCCESS: {
            persistSelectedFarmList(action.payload);
            return {
                ...state,
                farmList: action.payload,
                loading: false,
                loaded: true,
                currentFarm: action.payload[0], // TODO: Define a better way to setup the default value
            };
        }

        case SUBSCRIPTION_SAVE_FARM_SUCCESS:
            return {
                ...state,
                currentFarm: action.payload.farm,
                farmList: [...(state.farmList || []), action.payload.farm],
            };

        case FARM_SAVE_SUCCESS:
            return {
                ...state,
                currentFarm: action.payload,
                loading: false,
                farmList: [...(state.farmList || []), action.payload],
            };

        case FARM_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                currentFarm: action.payload,
                farmList: state.farmList?.map((farm) => (farm.id === action.payload.id ? action.payload : farm)) || [],
            };

        case FARM_DELETE_SUCCESS:
            return {
                ...state,
                currentFarm: undefined,
                loading: false,
                farmList: state.farmList?.filter((farm) => farm.id !== action.payload.id) || [],
            };

        case FARM_SET_LIST:
            return {
                ...state,
                farmList: action.payload.farmList,
            };

        case FARM_SET_CURRENT: {
            const currentFarm = state.farmList?.find((farm) => farm.id === action.payload.farmId);
            persistSelectedCurrentFarm(currentFarm);
            return {
                ...state,
                currentFarm,
            };
        }

        case RESET:
            return {
                loading: false,
                loaded: false,
                success: '',
                farmList: null,
                currentFarm: undefined,
            };
        default:
            return state;
    }
};

export default farmReducer;
