import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import HistorySubtasksView from './HistorySubtasksView';

const HistorySubtasks = (): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();

    const coverCropDone = useSelector(
        (state: HomePageStateT) =>
            state.farmSeason?.currentFarmSeason?.installation?.onboarding?.coverCropHistory?.done,
    );

    const organicFertilizationDone = useSelector(
        (state: HomePageStateT) =>
            state.farmSeason?.currentFarmSeason?.installation?.onboarding?.organicFertilizationHistory?.done,
    );

    const pastureDone = useSelector(
        (state: HomePageStateT) => state.farmSeason?.currentFarmSeason?.installation?.onboarding?.pastureHistory?.done,
    );

    const tillagePracticeDone = useSelector(
        (state: HomePageStateT) =>
            state.farmSeason?.currentFarmSeason?.installation?.onboarding?.tillagePracticeHistory?.done,
    );

    const onboarding = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding,
    );

    const generalTasks = [
        true, // "create your farms"
        onboarding?.crop?.done || false,
        onboarding?.field?.done || false,
        onboarding?.livestock?.done || false,
    ];
    const generalTasksTotal = generalTasks.length;
    const generalTasksDoneCount = generalTasks.filter((isDone) => isDone).length;

    const fieldsSubtaskItems = [
        onboarding?.agroforestry?.done || false,
        onboarding?.drainage?.done || false,
        onboarding?.irrigation?.done || false,
        onboarding?.organic?.done || false,
        onboarding?.soilAcidity?.done || false,
        onboarding?.soilOrganicMatter?.done || false,
        onboarding?.tillage?.done || false,
        onboarding?.coverCrop?.done || false,
        onboarding?.residue?.done || false,
    ];
    const fieldsSubtaskItemsTotal = fieldsSubtaskItems.length;
    const fieldsSubtaskItemsDoneCount = fieldsSubtaskItems.filter((isDone) => isDone).length;

    const historyTasks = [
        onboarding?.pastureHistory?.done,
        onboarding?.coverCropHistory?.done,
        onboarding?.tillagePracticeHistory?.done,
        onboarding?.organicFertilizationHistory?.done,
    ];
    const historySubtaskItemsTotal = historyTasks.length;
    const historySubtaskItemsDoneCount = historyTasks.filter((isDone) => isDone).length;

    // general tasks + fields subtasks + history subtasks
    const allTasksTotal = generalTasksTotal + fieldsSubtaskItemsTotal + historySubtaskItemsTotal;
    const allTasksDoneCount = generalTasksDoneCount + fieldsSubtaskItemsDoneCount + historySubtaskItemsDoneCount;

    const historyItems = [
        {
            name: t('pages.installationtasks.history.cover-crops-history'),
            done: coverCropDone || false,
            link: {
                to: `${location.pathname}/cover-crops`,
            },
        },
        {
            name: t('pages.installationtasks.history.organic-fertilization-history'),
            done: organicFertilizationDone || false,
            link: {
                to: `${location.pathname}/organic-fertilization`,
            },
        },
        {
            name: t('pages.installationtasks.history.pasture-history'),
            done: pastureDone || false,
            link: {
                to: `${location.pathname}/pasture`,
            },
        },
        {
            name: t('pages.installationtasks.history.tillage-practice-history'),
            done: tillagePracticeDone || false,
            link: {
                to: `${location.pathname}/tillage-practice`,
            },
        },
    ];

    const historyItemsToDo = historyItems.filter((historyItem) => historyItem.done === false);
    const historyItemsDone = historyItems.filter((historyItem) => historyItem.done === true);
    const totalProgressPercentage = (allTasksDoneCount / allTasksTotal) * 100;

    return (
        <HistorySubtasksView
            historyItemsDone={historyItemsDone}
            historyItemsToDo={historyItemsToDo}
            t={t}
            totalProgressPercentage={totalProgressPercentage}
        />
    );
};

export default HistorySubtasks;
