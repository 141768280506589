import { useTheme } from '@mui/material';
import { ModalBase } from '@soil-capital/ui-kit';
import DropZone from '@components/DropZone/DropZone';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import ShapeFileService from '@services/shapeService/shapeFileService';
import uploadService from '@services/uploadService/uploadService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalUploadCapFilePropsT } from './ModalUploadCapFileTypes';

const ModalUploadCapFile = ({ controller, farmSeasonId }: ModalUploadCapFilePropsT) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const countryId = useSelector((state: HomePageStateT) => state.farm.currentFarm.country_id);

    const [files, setFiles] = useState<File[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    const acceptedFiles = {
        'application/octet-stream': ['.zip', '.shp', '.dbf', '.cst', '.prj', '.shx'],
        'text/plain': ['.txt'],
    };

    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);

    const handleClearUpload = () => {
        controller.onModalCancel();
        setTimeout(() => {
            setFiles([]);
            setErrors([]);
        }, theme.transitions.duration.leavingScreen);
    };

    const processFiles = async (files: File[]) => {
        if (!files.length) {
            setErrors([t('validation.no-shp-dbf-prj-or-zip')]);
            return;
        }
        // get buffers and group by file.
        const { filesBuffers, missingOrUnknownExtensionErrors } = await ShapeFileService.getFilesBuffers(files);
        if (missingOrUnknownExtensionErrors.length) {
            setErrors(missingOrUnknownExtensionErrors);
            return;
        }

        const { defaultPrjUsed, filesEpsg, missingPrjErrors } = await ShapeFileService.getFilesEpsg(
            filesBuffers,
            Number(currentFarm.country_id),
        );
        if (missingPrjErrors.length) {
            setErrors(missingPrjErrors);
            return;
        }

        uploadService.uploadCapFiles(currentFarm.id, farmSeasonId, files);

        const { uploadedFields, fileFormatError } = await ShapeFileService.generateFields(
            filesBuffers,
            filesEpsg,
            Number(countryId),
        );

        if (fileFormatError) {
            setErrors([fileFormatError]);
            return;
        }

        await controller.onModalConfirm({ uploadedFields, defaultPrjUsed });
        handleClearUpload();
    };

    return (
        <ModalBase controller={controller} showCloseButton width="550px">
            <ModalBaseLayout
                title={{
                    text: t('pages.installationtasks.add-field-task.final-step.button-import-cap'),
                    alignment: 'center',
                }}
                cancelButton={{ text: t('constants.cancel'), onClick: handleClearUpload }}
                confirmButton={{
                    text: t('pages.installationtasks.add-field-task.upload-step.button-upload-files'),
                    onClick: () => processFiles(files),
                }}
            >
                <DropZone
                    processingErrors={errors}
                    files={files}
                    onFilesChange={(newFiles) => setFiles(newFiles)}
                    textBrowseFiles={t('pages.installationtasks.add-field-task.upload-step.search-file-button-text')}
                    textDropFiles={t('pages.installationtasks.add-field-task.upload-step.drop-file-message')}
                    acceptedFilesTypes={acceptedFiles}
                />
            </ModalBaseLayout>
        </ModalBase>
    );
};

export default ModalUploadCapFile;
