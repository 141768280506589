import { Typography, Divider } from '@mui/material';
import TaskList from '@components/TaskList/TaskList';
import ProgressHeader from '@components/ProgressHeader/ProgressHeader';

import useFieldsSubtasksStyle from './FieldsSubtasks.style';
import { FieldsSubtasksViewPropsI } from './FieldsSubtasks.types';

const FieldsSubtasksView = ({
    subTaskItemsDone,
    subTaskItemsToDo,
    t,
    totalProgressPercentage,
}: FieldsSubtasksViewPropsI): JSX.Element => {
    const { classes } = useFieldsSubtasksStyle();

    return (
        <div>
            <ProgressHeader
                className={classes.progress}
                image={`/assets/images/installation/farm-step-${Math.trunc(totalProgressPercentage / 10)}.png`}
                menuTitle={t('pages.installationtasks.complete-your-fields')}
                withMenu
                onArrowBack=".."
                onClose=".."
            />
            <div className={classes.fieldsSubtasksPageWrapper} id="installationSubtasksPageWrapper">
                <Typography component="h3" variant="h3">
                    {t('pages.installationsubtasks.your-subtasks')}
                </Typography>

                {subTaskItemsToDo.length > 0 && <TaskList taskItems={subTaskItemsToDo} />}
                {subTaskItemsToDo.length > 0 && subTaskItemsDone.length > 0 && <Divider className={classes.hr} />}
                {subTaskItemsDone.length > 0 && <TaskList taskItems={subTaskItemsDone} tasksDone />}
            </div>
        </div>
    );
};

export default FieldsSubtasksView;
