import { ButtonProps, Components, ComponentsOverrides, Theme } from '@mui/material';

const buttonCss: ComponentsOverrides<Theme>['MuiButton'] = {
    root: {
        textTransform: 'none',
        borderRadius: '10px',
    },
    text: {
        fontSize: '1rem',
    },
    contained: {
        textTransform: 'none',
        padding: '16px 30px 16px',
        lineHeight: 1,
        '&:hover': {},
    },
    containedSizeSmall: {
        padding: '12px 22px',
    },
    containedPrimary: ({ theme }) => ({
        background: theme.palette.primary.light,
        ':hover': {
            background: theme.palette.primary.main,
        },
    }),
};

const buttonProperties: ButtonProps = {
    variant: 'contained',
};

const MuiButton: Components<Theme>['MuiButton'] = { styleOverrides: buttonCss, defaultProps: buttonProperties };

export default MuiButton;
