import { CurrencyT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const CURRENCIES_GET_LOADING = 'CURRENCIES_GET_LOADING';
export const CURRENCIES_GET_SUCCESS = 'CURRENCIES_GET_SUCCESS';
export const CURRENCIES_GET_FAILED = 'CURRENCIES_GET_FAILED';

export type CurrenciesGetSuccessDataT = CurrencyT[];
export type CurrenciesGetFailedDataT = {
    message?: string;
    list?: string[];
};

export type CurrenciesGetCallBackT = (dispatch: Dispatch<CurrenciesDispatchTypes>) => Promise<CurrenciesGetFailedDataT>;
export type CurrenciesGetLoadingT = {
    type: typeof CURRENCIES_GET_LOADING;
};

export type CurrenciesGetSuccessT = {
    type: typeof CURRENCIES_GET_SUCCESS;
    payload: CurrenciesGetSuccessDataT;
};

export type CurrenciesGetFailedT = {
    type: typeof CURRENCIES_GET_FAILED;
    error: CurrenciesGetFailedDataT;
};

export type CurrenciesDispatchTypes = CurrenciesGetLoadingT | CurrenciesGetSuccessT | CurrenciesGetFailedT;
