import { AppState } from '@auth0/auth0-react';
import { mapOfPermissionAndSelectors } from '@hooks/usePermissions';
import { cloneElement } from 'react';
import { useSelector } from 'react-redux';
import { RestrictedPropsT } from './RestrictedTypes';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import { PermissionNamesT } from '@actions/AppActions/GetPermissionsTypes';

const Restricted = <T extends PermissionNamesT>({
    children,
    toPermission,
    forResource,
    withProps,
}: RestrictedPropsT<T> & { children: JSX.Element }): JSX.Element | null => {
    const permissions = useSelector((state: AppState) => state.app.permissions);
    const { access, forbiddenCondition, forbiddenScope } =
        mapOfPermissionAndSelectors[toPermission](permissions)(forResource);

    if (access) {
        return children;
    }

    if (!withProps) {
        return null;
    }

    const { disabled, fallback, tooltipPlacement, tooltipSlug } = withProps({ forbiddenScope, forbiddenCondition });
    const newChildren = fallback ?? children;
    const customizedChildren = disabled || tooltipSlug ? cloneElement(newChildren, { disabled: true }) : newChildren;
    return (
        <TooltipSc placement={tooltipPlacement} translationSlug={tooltipSlug} display="block">
            {customizedChildren}
        </TooltipSc>
    );
};

export default Restricted;
