import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';
import appActions from '@actions/AppActions/AppActions';
import { ROUTE } from '@constants/routes';
import useAppDispatch from '@hooks/useAppDispatch';
import IconCheckCircle from '@icons/icon-check-circle.svg';
import IconDash from '@icons/icon-dash.svg';
import IconFarm from '@icons/icon-farm.svg';
import IconGraph from '@icons/icon-graph.svg';
import IconRye from '@icons/icon-rye.svg';
import IconSeed from '@icons/icon-seed.svg';
import IconTractor from '@icons/icon-tractor.svg';
import CertificatIcon from '@icons/icon-workspace_premium.svg';
import SettingsIcon from '@mui/icons-material/Settings';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { MainRouterLocationStateT } from '@routers/MainRouterTypes';
import {
    hasCertificatesGuard,
    hasDataSummaryAccessGuard,
    hasOnboardingFieldDoneGuard,
    hasResultAccessGuard,
    isInstallationOpenedIfAuthFarmerGuard,
} from '@services/guardsService/guardsService';
import { AppStateT } from '@store/store';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { NavigationItemT } from './SideBarNavTypes';
import SideBarNavView from './SideBarNavView';

const SideBarNav = (): JSX.Element => {
    const { t } = useTranslation();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const hasFarmerValidatedSeason = !!currentFarmSeason?.summary_validated_at;

    const user = useSelector((state: HomePageStateT) => state.auth.user);
    const isCFT = useIsCFTCarbonModel();
    const hasFieldCrops = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList)?.some((field) =>
        field.field_crops ? field.field_crops.length > 0 : false,
    );
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const [linkActive, setLinkActive] = useState<NavigationItemT | undefined>();
    const [linkHover, setLinkHover] = useState<NavigationItemT | undefined>();

    let hoverTimer: NodeJS.Timeout | undefined;
    const onLinkHover = (eventType: 'over' | 'out', item: NavigationItemT) => {
        if (hoverTimer) clearTimeout(hoverTimer);
        if (eventType === 'over' && linkHover !== item) {
            setLinkHover(item);
        } else if (eventType === 'out' && linkHover === item) {
            hoverTimer = setTimeout(() => setLinkHover(undefined), 200);
        }
    };

    const location = useLocation();
    const mainLocation = (location.state as MainRouterLocationStateT)?.mainRouterLocation || location;

    // FP-4319 - Used to temporary hide settings page for Argentinian farmers
    const isArgentinianFarm = useSelector((state: AppStateT) => state.farm.currentFarm?.country.iso_code === 'AR');

    const navigationItems: NavigationItemT[] = useMemo(
        () => [
            {
                text: t('pages.homepage.sidebar.installation'),
                icon: {
                    icon: IconDash,
                    viewBox: '0 0 17 17',
                },
                active: false,
                disabled: false,
                link: ROUTE.SELF_ENCODING,
                hidden: !isInstallationOpenedIfAuthFarmerGuard().passed,
            },
            {
                text: t('pages.homepage.sidebar.my-farm'),
                icon: {
                    icon: IconFarm,
                    viewBox: '0 0 19 21',
                },
                active: false,
                disabled: false,
                link: '/farm',
            },
            {
                text: t('pages.homepage.sidebar.my-farm-profile'),
                icon: {
                    icon: IconFarm,
                    viewBox: '0 0 19 21',
                },
                active: false,
                disabled: false,
                link: `/${currentFarmSeason?.id}/farm-profile`,
                isV3: true,
                hidden: isCFT,
            },
            {
                text: t('pages.homepage.sidebar.crops'),
                icon: {
                    icon: IconSeed,
                    viewBox: '0 0 20 21',
                },
                active: false,
                disabled: !hasOnboardingFieldDoneGuard().passed,
                link: '/crops',
            },
            {
                text: t('pages.homepage.sidebar.fields'),
                icon: {
                    icon: IconRye,
                    viewBox: '0 0 18 20',
                },
                active: false,
                disabled: !hasOnboardingFieldDoneGuard().passed,
                link: '/fields',
            },
            {
                text: t('pages.homepage.sidebar.my-operations'),
                icon: {
                    icon: IconTractor,
                    viewBox: '0 0 19 21',
                },
                active: false,
                disabled: false,
                link: `/${currentFarmSeason?.id}/technical-itinerary`,
                isV3: true,
                hidden: isCFT || !hasFieldCrops,
            },
            {
                active: false,
                disabled: false,
                icon: { icon: IconCheckCircle, viewBox: '0 0 19 21' },
                link: ROUTE.DATA,
                text: hasFarmerValidatedSeason
                    ? t('pages.homepage.sidebar.farmer-data-summary')
                    : t('pages.homepage.sidebar.farmer-data'),
                hidden: !hasDataSummaryAccessGuard().passed,
            },
            {
                text: t('pages.homepage.sidebar.results'),
                icon: {
                    icon: IconGraph,
                    viewBox: '0 0 24 22',
                },
                active: false,
                disabled: false,
                link: ROUTE.RESULTS,
                isV3: false,
                hidden: !isCFT || !hasResultAccessGuard().passed,
            },
            {
                text: t('pages.homepage.sidebar.results'),
                icon: {
                    icon: IconGraph,
                    viewBox: '0 0 24 22',
                },
                active: false,
                disabled: false,
                link: `/${currentFarmSeason?.id}/results`,
                isV3: true,
                hidden: isCFT || !currentFarmSeason?.has_results,
            },
            {
                active: false,
                disabled: false,
                icon: { icon: CertificatIcon, viewBox: '0 0 23 23' },
                link: '/certificates',
                text: t('pages.homepage.sidebar.certificates'),
                hidden: !hasCertificatesGuard().passed,
            },
            {
                text: t('pages.settings.billing.title'),
                icon: { icon: SettingsIcon, viewBox: '0 0 23 23' },
                active: false,
                disabled: false,
                link: '/billing',
                hidden: !isCFT || isArgentinianFarm,
            },
        ],
        [user.role_id, user.language_id, t, hasFarmerValidatedSeason, isCFT, hasFieldCrops, currentFarmSeason],
    );

    useEffect(() => {
        const activeItem = navigationItems.find(
            (item) =>
                (item.link !== '/' && matchPath(`${item.link}/*`, mainLocation.pathname)) ||
                (item.link === '/' && (mainLocation.pathname.includes('tasks') || mainLocation.pathname === '/')),
        );
        setLinkActive(activeItem);
    }, [mainLocation, navigationItems]);

    useEffect(() => {
        const firstItemDisplayed = navigationItems.filter((item) => !item.hidden)[0];
        if (firstItemDisplayed === linkActive || firstItemDisplayed === linkHover) {
            dispatch(appActions.setIsFirstMenuItemSelected(true));
        } else {
            dispatch(appActions.setIsFirstMenuItemSelected(false));
        }
    }, [pathname, linkActive, linkHover]);

    return <SideBarNavView config={navigationItems} linkActive={linkActive} onLinkHover={onLinkHover} />;
};

export default SideBarNav;
