import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { PostFarmSeasonOperationFertilisingApiT } from '@services/farmSeasonOperationApiService/farmSeasonOperationApiServiceTypes';
import { FertilisationOperationT } from '@pages/HomePage/Operations/FertilisationTask/FertilisationTaskTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_OPERATION_FERTILISING_LOADING = 'POST_FARM_SEASON_OPERATION_FERTILISING_LOADING';
export const POST_FARM_SEASON_OPERATION_FERTILISING_FAILED = 'POST_FARM_SEASON_OPERATION_FERTILISING_FAILED';
export const POST_FARM_SEASON_OPERATION_FERTILISING_SUCCESS = 'POST_FARM_SEASON_OPERATION_FERTILISING_SUCCESS';

export type PostFarmSeasonOperationFertilisingDataT = PostFarmSeasonOperationFertilisingApiT;

export type PostFarmSeasonOperationFertilisingResponseDataT = {
    baseline?: FertilisationOperationT;
    exceptions?: FertilisationOperationT[]; // TODO: Add Exceptions
    fields?: FarmSeasonFieldT[];
};

export type FarmSeasonOperationFertilisingPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonOperationFertilisingDispatchTypesT>,
) => Promise<PostFarmSeasonOperationFertilisingResponseErrorT>;

export type PostFarmSeasonOperationFertilisingResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonOperationFertilisingLoadingT = {
    type: typeof POST_FARM_SEASON_OPERATION_FERTILISING_LOADING;
};

export type PostFarmSeasonOperationFertilisingFailedT = {
    type: typeof POST_FARM_SEASON_OPERATION_FERTILISING_FAILED;
    error: PostFarmSeasonOperationFertilisingResponseErrorT;
};

export type PostFarmSeasonOperationFertilisingSuccessT = {
    type: typeof POST_FARM_SEASON_OPERATION_FERTILISING_SUCCESS;
    payload: PostFarmSeasonOperationFertilisingResponseDataT;
};

export type PostFarmSeasonOperationFertilisingDispatchTypesT =
    | PostFarmSeasonOperationFertilisingLoadingT
    | PostFarmSeasonOperationFertilisingFailedT
    | PostFarmSeasonOperationFertilisingSuccessT;
