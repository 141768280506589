import { CircularProgress, Typography } from '@mui/material';
import useAppNavigate from '@hooks/useAppNavigate';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import { useTranslation } from 'react-i18next';

import useProgressWraperStyle from './ProgressWrapper.style';
import { ProgressWrapperPropsT } from './ProgressWrapperTypes';
import useProgressWrapperLogic from './ProgressWrapper.logic';
import StickyFooterLayout from '@/layouts/StickyFooterLayout/StickyFooterLayout';
import StickyHeaderLayout from '@/layouts/StickyHeaderLayout/StickyHeaderLayout';

const ProgressWrapper = ({
    children,
    title,
    subTitle,
    nextButtonText,
    skipLinkText,
    handleSkipLinkClick,
    showBackLink,
    nextButtonDisabled,
    nextButtonLoading = false,
    nextButtonUrl,
    handleNextButton,
    skipLoading,
}: ProgressWrapperPropsT): JSX.Element => {
    const { classes } = useProgressWraperStyle({ skipLoading: !!skipLoading });
    const navigate = useAppNavigate();
    const { t } = useTranslation();

    const { handleNextButtonClick } = useProgressWrapperLogic({ nextButtonUrl, handleNextButton });

    return (
        <div className={classes.root}>
            <StickyHeaderLayout bgColor="white" paddingTop="40px" fillParentPadding="40px">
                <Typography className={classes.title} color="primary" component="h1" variant="h4">
                    {title}
                </Typography>
            </StickyHeaderLayout>
            <Typography color="secondary" component="h2" variant="h4" className={classes.subTitle}>
                {subTitle}
            </Typography>
            {children}
            <StickyFooterLayout bgColor="white" paddingBottom="40px" fillParentPadding="40px">
                <footer className={classes.footerContaier}>
                    <div className={classes.backLinkContainer}>
                        {typeof showBackLink === 'boolean' && showBackLink ? (
                            <span aria-hidden="true" className={classes.backLink} onClick={() => navigate(-1)}>
                                <ArrowRightAlt className={classes.arrowBack} /> &nbsp;
                                <Typography component="span"> {t('constants.back')}</Typography>
                            </span>
                        ) : (
                            showBackLink
                        )}
                    </div>

                    {skipLinkText && (
                        <>
                            {skipLoading && <CircularProgress className={classes.progress} size="24px" />}
                            <a aria-hidden="true" className={classes.skipLink} onClick={handleSkipLinkClick}>
                                <Typography color="primary" component="span">
                                    {skipLinkText}
                                </Typography>
                            </a>
                        </>
                    )}

                    {nextButtonText && (
                        <LoadingButton
                            className={classes.buttonNext}
                            disabled={nextButtonDisabled}
                            loading={nextButtonLoading}
                            text={nextButtonText}
                            onClick={handleNextButtonClick}
                        />
                    )}
                </footer>
            </StickyFooterLayout>
        </div>
    );
};

export default ProgressWrapper;
