import { BASIC_PLAN_ID, STANDARD_PLAN_ID } from '@/constants/productPlanIds';
import { AppStateT } from '../store';

const selectIsProductPlanName =
    ({ name }: { name: 'BASIC' | 'STANDARD' }) =>
    (state: AppStateT) => {
        const productPlan = state.farmSeason.currentFarmSeason?.latest_farm_season_product_plan;
        if (name === 'BASIC') {
            return productPlan?.product_plan?.id === BASIC_PLAN_ID;
        }
        if (name === 'STANDARD') {
            return productPlan?.product_plan?.id === STANDARD_PLAN_ID;
        }
        return;
    };

export default selectIsProductPlanName;
