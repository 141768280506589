import {
    DELETE_FARM_SEASON_LIVESTOCKS_FAILED,
    DELETE_FARM_SEASON_LIVESTOCKS_LOADING,
    DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS,
} from '@actions/FarmSeasonLivestockActions/DeleteFarmSeasonLivestocksTypes';
import {
    GET_FARM_SEASON_LIVESTOCKS_FAILED,
    GET_FARM_SEASON_LIVESTOCKS_LOADING,
    GET_FARM_SEASON_LIVESTOCKS_SUCCESS,
} from '@actions/FarmSeasonLivestockActions/GetFarmSeasonLivestocksTypes';
import { POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS } from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksInstallationTypes';
import {
    POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_FAILED,
    POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_LOADING,
    POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS,
} from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksSubscriptionTypes';
import { RESET } from '@reducers/RootTypes';
import { AppActionsT } from '@actions/AppActions/AppActionsTypes';
import { RESET_FARM_SEASON_LIVESTOCK } from '@actions/FarmSeasonLivestockActions/FarmSeasonLivestockActionsTypes';
import { SUBSCRIPTION_SAVE_FARM_SUCCESS } from '@actions/SubscriptionActions/SubscriptionSaveFarmTypes';
import { Reducer } from '@reduxjs/toolkit';

import { FarmSeasonLivestockReducerStateT } from './FarmSeasonLivestockReducerTypes';

export const defaultFarmSeasonLivestockState: FarmSeasonLivestockReducerStateT = {
    loaded: false,
    loading: false,
    error: undefined,
    livestockList: [],
};

const FarmSeasonLivestockReducer: Reducer<FarmSeasonLivestockReducerStateT, AppActionsT> = (
    state,
    action,
): FarmSeasonLivestockReducerStateT => {
    if (!state) {
        return defaultFarmSeasonLivestockState;
    }

    switch (action.type) {
        case DELETE_FARM_SEASON_LIVESTOCKS_LOADING:
        case POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_LOADING:
        case GET_FARM_SEASON_LIVESTOCKS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_FARM_SEASON_LIVESTOCKS_SUCCESS:
        case POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS:
        case POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                livestockList: action.payload.livestocks,
            };

        case DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                livestockList: [],
            };

        case GET_FARM_SEASON_LIVESTOCKS_FAILED:
        case DELETE_FARM_SEASON_LIVESTOCKS_FAILED:
        case POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_FAILED:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error,
            };

        case SUBSCRIPTION_SAVE_FARM_SUCCESS:
            return {
                ...state,
                loading: false,
                livestockList: action.payload.farmSeason.eligibility_has_livestock ? [...state.livestockList] : [],
            };

        case RESET_FARM_SEASON_LIVESTOCK:
        case RESET:
            return {
                ...defaultFarmSeasonLivestockState,
            };

        default:
            return state;
    }
};

export default FarmSeasonLivestockReducer;
