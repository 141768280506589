import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderNameValueList from '../HeaderNameValueList/HeaderNameValueList';
import SelectEntitySort from '../SelectEntitySort/SelectEntitySort';
import { SelectedFieldsHeaderPropsT } from './SelectedFieldsHeaderTypes';
import formatNumber from '@utils/formatNumber';

type RequiredSortableData = { area: number; name: string };

const SelectedFieldsHeader = <E extends { id?: number | string }, T extends Record<string, unknown>>({
    sortableData,
    headerText,
    onSortedFields,
    additionnalSortFunctions,
}: SelectedFieldsHeaderPropsT<E, T & RequiredSortableData>) => {
    const { t } = useTranslation();
    const totalArea = sortableData?.reduce((total, field) => total + (field.area || 0), 0);

    const sortFunctions = useMemo(
        () => ({
            'name-asc': (a: RequiredSortableData, b: RequiredSortableData) =>
                a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
            'name-desc': (a: RequiredSortableData, b: RequiredSortableData) =>
                a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1,
            'area-asc': (a: RequiredSortableData, b: RequiredSortableData) => a.area - b.area,
            'area-desc': (a: RequiredSortableData, b: RequiredSortableData) => b.area - a.area,
            ...additionnalSortFunctions,
        }),
        [additionnalSortFunctions],
    );

    return (
        <HeaderNameValueList
            nameHeader={
                headerText ??
                `${sortableData?.length || 0} ${t('constants.fields', {
                    count: sortableData?.length || 0,
                })} (${formatNumber(totalArea, 2)} ${t('constants.hectare-unit')})`
            }
            valueHeader={
                onSortedFields && (
                    <SelectEntitySort
                        onEntitiesSorted={(fields: E[]) => {
                            onSortedFields(fields);
                        }}
                        sortableData={sortableData ?? null}
                        sortFunctions={sortFunctions}
                        initialSortKey="name-asc"
                    />
                )
            }
        />
    );
};

export default SelectedFieldsHeader;
