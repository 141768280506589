import { makeStyles } from 'tss-react/mui';

const useLoadingButtonStyle = makeStyles()((theme) => ({
    button: {
        position: 'relative',
        overflow: 'hidden',
    },
    loading: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.grey[300],
    },
    progress: {
        opacity: 0.5,
        color: theme.palette.grey[600],
    },
}));

export default useLoadingButtonStyle;
