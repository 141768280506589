import { useSelector } from 'react-redux';
import { FarmSeasonT } from '../reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { AppStateT } from '../store';

const getLatestFarmSeason = (farmSeasons: FarmSeasonT[]) => {
    return [...farmSeasons].sort((s1, s2) => s1.id - s2.id).pop();
};

const selectLatestFarmSeason = () => (state: AppStateT) => {
    return getLatestFarmSeason(state.farmSeason.farmSeasonList);
};

export const useSelectLatestFarmSeason = () => {
    return useSelector(selectLatestFarmSeason());
};

export default selectLatestFarmSeason;
