import CheckedButtonView from '@components/CheckedButton/CheckedButtonView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Typography, useTheme } from '@mui/material';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IrrigatedFieldListViewPropsT } from './IrrigatedFieldsListTypes';
import useIrrigatedFieldsListStyle from './IrrigatedFieldsListView.style';
import formatNumber from '@utils/formatNumber';

const IrrigatedFieldListView = ({
    onConfirmSelectedField,
    irrigatedFields,
    onFieldClick,
    irrigationMethodList,
    t,
}: IrrigatedFieldListViewPropsT): JSX.Element => {
    const { classes } = useIrrigatedFieldsListStyle();
    const theme = useTheme();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div className={classes.container}>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.fields-subtasks.irrigation-task.select-field-to-define-practices">
                    <span style={{ color: theme.palette.secondary.dark }}>Pour chaque champ,</span> veuillez définir
                    l&apos;irrigation
                </Trans>
            </Typography>
            <div className={classes.listContainer}>
                {irrigatedFields?.map((irrigatedField) => (
                    <CheckedButtonView
                        key={`${irrigatedField.id}-${String(irrigatedField.name)}`}
                        done={irrigatedField?.irrigation !== null}
                        name={t(irrigatedField.name)}
                        onClick={() => onFieldClick(irrigatedField)}
                    >
                        <>
                            <Typography color="primary" variant="body1">
                                {`${formatNumber(irrigatedField.area, 3)} ${t('constants.hectare-unit')}`}
                            </Typography>
                            <Typography color="primary" variant="body1">
                                {irrigatedField?.irrigation && (
                                    <span>
                                        {t(
                                            irrigationMethodList.find(
                                                (irrigationMethod) =>
                                                    irrigationMethod.id ===
                                                    irrigatedField.irrigation.irrigation_method_id,
                                            ).translation_slug,
                                        )}
                                        &nbsp; : {(+irrigatedField.irrigation.total_amount).toFixed(2)} mm
                                    </span>
                                )}
                            </Typography>
                        </>
                    </CheckedButtonView>
                ))}
            </div>
            <TwoButtonFooterView
                rightOnCLick={onConfirmSelectedField}
                rightText={canEditFarmSeason.access ? t('constants.confirm') : ''}
            />
        </div>
    );
};

export default IrrigatedFieldListView;
