import { Route, Navigate } from 'react-router-dom';
import AppRoutes from '@components/AppRoutes/AppRoutes';
import { InstallationTasksRouterConfigTypes } from './InstallationTasksRouterConfigTypes';
import InstallationTasks from '@pages/HomePage/Installation/InstallationTasks/InstallationTasks';
import InstallationSubtasks from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/FieldsSubtasks';
import LivestockInstallation from '@pages/HomePage/Installation/InstallationTasks/LivestocksTask/LivestocksTask';
import FieldsTask from '@pages/HomePage/Installation/InstallationTasks/FieldsTask/FieldsTask';
import CropsTask from '@pages/HomePage/Installation/InstallationTasks/CropsTask/CropsTask';
import FieldsSubtasksRouter from '@routers/FieldsSubtasksRouter/FieldsSubtasksRouter';
import ConfirmTask from '@pages/HomePage/Installation/InstallationTasks/ConfirmTask/ConfirmTask';
import { useSelector } from 'react-redux';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import { ROUTE } from '@constants/routes';

export const InstallationTasksRouter = (): JSX.Element => {
    const isBaselineSeason = useSelector(selectIsBaselineSeason());

    const installationTasksRouterConfig: Array<InstallationTasksRouterConfigTypes> = [
        {
            path: '/',
            component: <InstallationTasks />,
        },
        {
            path: '/tasks/fields/subtasks',
            component: isBaselineSeason ? <InstallationSubtasks /> : <Navigate to={ROUTE.SELF_ENCODING} />,
        },
        {
            path: '/tasks/fields/subtasks/*',
            component: <FieldsSubtasksRouter />,
        },
        {
            path: '/tasks/fields',
            component: <FieldsTask />,
        },
        {
            path: '/tasks/crops',
            component: <CropsTask />,
        },
        {
            path: '/tasks/livestock',
            component: <LivestockInstallation />,
        },
        {
            path: '/tasks/confirm',
            component: <ConfirmTask />,
        },
    ];

    return (
        <AppRoutes>
            {installationTasksRouterConfig.map((item) => (
                <Route key={item.path} element={item.component} path={item.path} />
            ))}
            <Route element={<Navigate to="/" replace />} />
        </AppRoutes>
    );
};

export default InstallationTasksRouter;
