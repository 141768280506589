import CardBasic from '@components/CardBasic/CardBasic';
import TableView from '@components/Table/TableView';
import { TableColumnsT, TableRowsT } from '@components/Table/TableViewTypes';
import { useTableViewCellStyle } from '@components/Table/TableView.style';
import { useTranslation } from 'react-i18next';

import { CardFArmPropsT } from './CardFarmTypes';
import useCardFarmStyle from './CardFarm.style';

const CardFarm = ({ city, country, farmAddress, postcode, harvestYear }: CardFArmPropsT): JSX.Element => {
    const { classes } = useCardFarmStyle();
    const { classes: tableStyles } = useTableViewCellStyle();
    const { t } = useTranslation();

    const rows: TableRowsT<'label' | 'value'> = [
        { label: t('pages.farmer-data.farm.cell-address'), value: farmAddress },
        { label: t('pages.farmer-data.farm.cell-postcode'), value: postcode },
        { label: t('pages.farmer-data.farm.cell-city'), value: city },
        { label: t('pages.farmer-data.farm.cell-country'), value: country },
        { label: t('pages.farmer-data.farm.cell-harvest'), value: harvestYear ?? '-' },
    ];
    const columns: TableColumnsT<'label' | 'value'> = [
        { field: 'label' },
        { field: 'value', cellClassName: tableStyles.boldAndHalf },
    ];

    return (
        <CardBasic className={classes.card} color="primary" title={t('pages.farmer-data.farm.title')}>
            <TableView color="primary" columns={columns} rows={rows} />
        </CardBasic>
    );
};

export default CardFarm;
