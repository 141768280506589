import {
    PostFarmSeasonFieldsIrrigationDataT,
    PostFarmSeasonFieldsIrrigationResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsIrrigationTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsIrrigationApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsIrrigationDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsIrrigationResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/irrigation`,
            method: 'POST',
            data: body,
        });
}

export default farmSeasonFieldsIrrigationApiService;
