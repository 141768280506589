import FarmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonOperationSowingPostCallBackT,
    PostFarmSeasonOperationSowingDataT,
    PostFarmSeasonOperationSowingDispatchTypesT,
    PostFarmSeasonOperationSowingResponseDataT,
    PostFarmSeasonOperationSowingResponseErrorT,
    POST_FARM_SEASON_OPERATION_SOWING_FAILED,
    POST_FARM_SEASON_OPERATION_SOWING_LOADING,
    POST_FARM_SEASON_OPERATION_SOWING_SUCCESS,
} from './PostFarmSeasonOperationSowingTypes';

export const postFarmSeasonOperationSowing =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonOperationSowingDataT,
    ): FarmSeasonOperationSowingPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonOperationSowingDispatchTypesT>,
    ): Promise<PostFarmSeasonOperationSowingResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_OPERATION_SOWING_LOADING });
            const res: AxiosResponse<PostFarmSeasonOperationSowingResponseDataT> =
                await FarmSeasonOperationApiService.post<'sowing'>(farmId, farmSeasonId, 'sowing', body);

            dispatch({
                type: POST_FARM_SEASON_OPERATION_SOWING_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_OPERATION_SOWING_FAILED,
                error: error as PostFarmSeasonOperationSowingResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
