import { Dispatch } from '@reduxjs/toolkit';
import PermissionApiService from '@services/permissionApiService/permissionApiService';
import logout from '../AuthActions/LogoutActions';
import { AppActionsT } from './AppActionsTypes';
import { SplittedPermissionT } from './GetPermissionsTypes';

/** split a permission name into properties: <boolean>_<action>_<scope>_<resource>?_<condition> */
export const computeSplittedPermissions = (permissions: string[]) => {
    const splittedPermissions = permissions.reduce((mappingSplittedPermissions, originalPermission) => {
        const [, allow, operation, scope, resource, condition] = originalPermission.split('_');
        const newSplittedPermission: SplittedPermissionT = {
            allow: allow as 'CAN' | 'CAN-NOT',
            operation,
            scope,
            resource,
            condition,
        };
        const newPermissionName = `${newSplittedPermission.operation}_${newSplittedPermission.resource}`;
        const oldSplitedPermissions = mappingSplittedPermissions[newPermissionName] ?? [];

        return {
            ...mappingSplittedPermissions,
            [newPermissionName]: [...oldSplitedPermissions, newSplittedPermission],
        };
    }, {} as Record<string, SplittedPermissionT[]>);

    return splittedPermissions;
};

/** get farmUser permissions for farmId */
export const getFarmUserPermissions =
    ({
        farmId,
        userId,
    }: {
        farmId: number;
        userId: number;
    }): ((dispatch: Dispatch<AppActionsT>) => Promise<string[] | false>) =>
    async (dispatch) => {
        try {
            const { permissions } = (await PermissionApiService.getFarmUserPermissions({ farmId, userId })).data;
            const splittedPermissions = computeSplittedPermissions(permissions);

            dispatch({
                type: 'APP_SET_PERMISSIONS',
                payload: { permissions: splittedPermissions },
            });

            return [...permissions];
        } catch (error) {
            logout()(dispatch);
            console.error(error);
            throw error;
        }
    };

/** get user permissions for authenticated user */
export const getUserPermissions =
    (): ((dispatch: Dispatch<AppActionsT>) => Promise<string[] | false>) => async (dispatch) => {
        dispatch({
            type: 'APP_SET_PERMISSIONS_LOADING',
            payload: { loading: true },
        });
        try {
            const { permissions } = (await PermissionApiService.getUserPermissions()).data;
            const splittedPermissions = computeSplittedPermissions(permissions);

            dispatch({
                type: 'APP_SET_PERMISSIONS',
                payload: { permissions: splittedPermissions },
            });

            return [...permissions];
        } catch (error) {
            logout()(dispatch);
            throw error;
        } finally {
            dispatch({
                type: 'APP_SET_PERMISSIONS_LOADING',
                payload: { loading: false },
            });
        }
    };
