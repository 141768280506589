import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OutlinedInputProps } from '@mui/material';
import { ModalBase } from '@soil-capital/ui-kit';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import {
    AREA_HA_THRESOLD_FOR_PERCENT,
    AREA_MARGIN_BOTTOM_HA,
    AREA_MARGIN_BOTTOM_PERCENT,
    AREA_MARGIN_TOP_HA,
    AREA_MARGIN_TOP_PERCENT,
} from '@constants/field-name-area-modal';
import SegmentService from '@services/segmentService/segmentService';
import { NumberFieldValue } from '@utils/NumbersToFieldValues';
import roundNumber from '@utils/roundNumber';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabelNumberField from '../LabelNumberField/LabelNumberField';
import LoadingSection from '../LoadingSection/LoadingSection';
import TooltipSc from '../TooltipSc/TooltipSc';
import useFieldNameAreaModalStyle from './FieldNameAreaModal.style';
import { FieldNameAreaModalPropsT } from './FieldNameAreaModalTypes';
import LabelTextField from '../LabelTextField/LabelTextField';
import useTranslationSlugs from '@hooks/useTranslationSlugs';

type TranslationKeyParamMapT = {
    'error-area-margin-percent-bigger': 'marginTopPercent' | 'marginTopHa' | 'maxHa' | 'polygonArea';
    'error-area-margin-percent-smaller': 'marginBottomPercent' | 'marginBottomHa' | 'minHa' | 'polygonArea';
    'error-area-margin-bigger': 'marginTopHa' | 'thresholdForPercent' | 'polygonArea';
    'error-area-margin-smaller': 'marginBottomHa' | 'thresholdForPercent' | 'polygonArea';
    'help-area-polygon-area': 'polygonArea';
    'tooltip-field-area-input': 'marginTopPercentOrHa' | 'marginTopHa' | 'polygonArea';
    'label-name': never;
    'label-area': never;
    'title-create': never;
    'title-edit': never;
    'help-area-pac': never;
    'error-name-empty': never;
};

const FieldNameAreaModal = ({ modalController, fieldData, actionName }: FieldNameAreaModalPropsT) => {
    const { t } = useTranslation();
    const { getText } = useTranslationSlugs<TranslationKeyParamMapT>(
        'pages.installationtasks.add-field-task.modal-field-name-area',
    );
    const { classes } = useFieldNameAreaModalStyle();
    const [newName, setNewName] = useState('');
    const [newArea, setNewArea] = useState<number | ''>('');
    const [nameError, setNameError] = useState<string>('');
    const [areaError, setAreaError] = useState<string>('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const formHasError = !!nameError || !!areaError;

    useEffect(() => {
        checkHasErrors();
    }, [newName, newArea]);

    const isAreaDisabled = fieldData?.areaSource === 'pac';
    // Init Form
    const initialArea =
        roundNumber(fieldData?.finalArea, { round: 'down', decimalePlace: 3 }) ||
        roundNumber(fieldData?.originalArea, { round: 'down', decimalePlace: 3 }) ||
        '';
    const hasCustomArea =
        newArea && newArea !== roundNumber(fieldData?.originalArea, { round: 'down', decimalePlace: 3 });
    useEffect(() => {
        if (modalController.isModalOpen) {
            setNewName(fieldData?.name ?? '');
            setNewArea(initialArea);
            setHasSubmitted(false);

            SegmentService.fieldEditionDisplayedTrack({
                area_source: fieldData?.areaSource,
                name: fieldData?.name,
                original_area: fieldData?.originalArea,
                user_area: fieldData?.finalArea === fieldData?.originalArea ? null : fieldData?.finalArea,
                is_area_editable: !isAreaDisabled,
            });
        }
    }, [fieldData]);

    const getAreaHelperText = (): string => {
        if (!fieldData) {
            return ' ';
        }

        if (fieldData?.areaSource === 'pac') {
            return getText('help-area-pac', {});
        }

        if (hasSubmitted && areaError) {
            return areaError;
        }

        if (hasCustomArea) {
            return getText('help-area-polygon-area', {
                polygonArea: roundNumber(fieldData?.originalArea, { round: 'down', decimalePlace: 3 }).toLocaleString(),
            });
        }
        return ' ';
    };

    const checkHasErrors = (): boolean => {
        if (!fieldData) {
            return false;
        }

        // Name errors
        let nameError = '';
        if (!newName) {
            nameError = getText('error-name-empty', {});
        }

        // Area (margin) errors
        let areaError = '';
        const areaMarginIsPercent = fieldData.originalArea > AREA_HA_THRESOLD_FOR_PERCENT;
        if (areaMarginIsPercent && newArea) {
            const allowedMarginTop = fieldData.originalArea * AREA_MARGIN_TOP_PERCENT;
            const areaGrowed = newArea - fieldData.originalArea;
            if (areaGrowed > allowedMarginTop) {
                areaError = getText('error-area-margin-percent-bigger', {
                    marginTopPercent: AREA_MARGIN_TOP_PERCENT * 100 + '%',
                    marginTopHa:
                        roundNumber(AREA_MARGIN_TOP_PERCENT * fieldData.originalArea, {
                            round: 'down',
                            decimalePlace: 3,
                        }).toLocaleString() + ' ha',
                    maxHa:
                        roundNumber((1 + AREA_MARGIN_TOP_PERCENT) * fieldData.originalArea, {
                            round: 'down',
                            decimalePlace: 3,
                        }).toLocaleString() + ' ha',
                    polygonArea:
                        roundNumber(fieldData.originalArea, { round: 'down', decimalePlace: 3 }).toLocaleString() +
                        ' ha',
                });
            }
            const allowedMarginBottom = fieldData.originalArea * AREA_MARGIN_BOTTOM_PERCENT;
            const areaNarrowed = fieldData.originalArea - newArea;
            if (areaNarrowed > allowedMarginBottom) {
                areaError = getText('error-area-margin-percent-smaller', {
                    marginBottomPercent: AREA_MARGIN_BOTTOM_PERCENT * 100 + '%',
                    marginBottomHa:
                        roundNumber(AREA_MARGIN_BOTTOM_PERCENT * fieldData.originalArea, {
                            round: 'up',
                            decimalePlace: 3,
                        }).toLocaleString() + ' ha',
                    minHa:
                        roundNumber((1 - AREA_MARGIN_BOTTOM_PERCENT) * fieldData.originalArea, {
                            round: 'up',
                            decimalePlace: 3,
                        }).toLocaleString() + ' ha',
                    polygonArea:
                        roundNumber(fieldData.originalArea, { round: 'down', decimalePlace: 3 }).toLocaleString() +
                        ' ha',
                });
            }
        }
        if (!areaMarginIsPercent && newArea) {
            const allowedMarginTop = AREA_MARGIN_TOP_HA;
            const areaGrowed = newArea - fieldData.originalArea;
            if (areaGrowed > allowedMarginTop) {
                areaError = getText('error-area-margin-bigger', {
                    marginTopHa:
                        roundNumber(AREA_MARGIN_TOP_HA, { round: 'down', decimalePlace: 3 }).toLocaleString() + ' ha',
                    thresholdForPercent: AREA_HA_THRESOLD_FOR_PERCENT,
                    polygonArea:
                        roundNumber(fieldData.originalArea, { round: 'down', decimalePlace: 3 }).toLocaleString() +
                        ' ha',
                });
            }
            const allowedMarginBottom = AREA_MARGIN_BOTTOM_HA;
            const areaNarrowed = fieldData.originalArea - newArea;
            if (areaNarrowed > allowedMarginBottom) {
                areaError = getText('error-area-margin-smaller', {
                    marginBottomHa:
                        roundNumber(AREA_MARGIN_BOTTOM_HA, { round: 'up', decimalePlace: 3 }).toLocaleString() + ' ha',
                    thresholdForPercent: AREA_HA_THRESOLD_FOR_PERCENT,
                    polygonArea:
                        roundNumber(fieldData.originalArea, { round: 'down', decimalePlace: 3 }).toLocaleString() +
                        ' ha',
                });
            }
        }

        // update state
        setNameError(nameError);
        setAreaError(areaError);

        return !!nameError || !!areaError;
    };

    const getAreaTooltipText = () => {
        if (fieldData?.originalArea) {
            return getText('tooltip-field-area-input', {
                marginTopPercentOrHa:
                    fieldData?.originalArea > AREA_HA_THRESOLD_FOR_PERCENT
                        ? AREA_MARGIN_TOP_PERCENT * 100 + '%'
                        : AREA_MARGIN_TOP_HA + ' ha',
                marginTopHa:
                    fieldData?.originalArea > AREA_HA_THRESOLD_FOR_PERCENT
                        ? roundNumber(AREA_MARGIN_TOP_PERCENT * fieldData?.originalArea, {
                              round: 'down',
                              decimalePlace: 3,
                          }).toLocaleString() + '%'
                        : AREA_MARGIN_TOP_HA + ' ha',
                polygonArea:
                    roundNumber(fieldData?.originalArea, { round: 'down', decimalePlace: 3 }).toLocaleString() + ' ha',
            });
        }
    };

    const submitForm = async () => {
        if (!fieldData) {
            throw Error('no fieldData');
        }
        setHasSubmitted(true);
        const hasError = checkHasErrors();
        const userArea = hasCustomArea ? newArea || null : null;
        SegmentService.fieldDetailModifiedTrack({
            area_diff_ha: roundNumber(Math.abs(Number(newArea) - Number(fieldData.originalArea))),
            area_diff_percent: roundNumber(
                (Math.abs(Number(newArea) - Number(fieldData.originalArea)) / Number(fieldData.originalArea)) * 100,
            ),
            area_source: fieldData.areaSource,
            has_area_changed: newArea !== initialArea,
            has_error: hasError,
            has_name_changed: newName !== fieldData.name,
            name: newName,
            original_area: fieldData.originalArea,
            user_area: userArea,
        });

        if (hasError) {
            return;
        }

        await modalController.onModalConfirm({
            name: newName,
            finalArea: userArea ?? fieldData.originalArea,
            userArea,
            areaSource: fieldData.areaSource,
            originalArea: fieldData.originalArea,
        });
    };

    const handleNameChange: OutlinedInputProps['onChange'] = (e) => {
        setNewName(e.currentTarget.value);
    };
    const handleAreaChange = (value: NumberFieldValue) => {
        setNewArea(value);
    };

    return (
        <ModalBase controller={modalController} showCloseButton>
            <ModalBaseLayout
                title={{
                    text:
                        actionName === 'create'
                            ? getText('title-create', {})
                            : actionName === 'edit'
                            ? getText('title-edit', {})
                            : null,
                }}
                confirmButton={{
                    text: t('constants.save'),
                    onClick: submitForm,
                    disabled: !fieldData?.originalArea || (hasSubmitted && formHasError),
                }}
                cancelButton={{ text: t('constants.cancel'), onClick: modalController.onModalCancel }}
            >
                <LoadingSection loading={!fieldData} transparentLoadingSection>
                    <LabelTextField
                        className={classes.fieldName}
                        label={getText('label-name', {})}
                        value={newName}
                        variant="outlined"
                        fullWidth
                        required
                        autoFocus
                        onChange={handleNameChange}
                        error={hasSubmitted && !!nameError}
                        helperText={hasSubmitted && nameError}
                    />

                    <LabelNumberField
                        className={classes.areaInput}
                        label={
                            <TooltipSc translationSlug={getAreaTooltipText()}>
                                {getText('label-area', {})} <InfoOutlinedIcon className={classes.infoIcon} />{' '}
                            </TooltipSc>
                        }
                        value={newArea}
                        variant="outlined"
                        fullWidth
                        onChange={handleAreaChange}
                        andormentText="ha"
                        disabled={isAreaDisabled}
                        helperText={getAreaHelperText()}
                        error={hasSubmitted && !!areaError}
                        inputProps={{ step: '.1' }}
                        placeholder={
                            fieldData && roundNumber(fieldData?.originalArea, { round: 'down' }).toLocaleString()
                        }
                    />
                </LoadingSection>
            </ModalBaseLayout>
        </ModalBase>
    );
};

export default FieldNameAreaModal;
