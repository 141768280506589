import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';
import {
    GetCarbonTrajectoryApiT,
    GetFarmSeasonResultsApiT,
} from '@services/farmSeasonResultsApiService/farmSeasonResultsApiServiceTypes';

class farmSeasonResultsApiService {
    public static get = async (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<GetFarmSeasonResultsApiT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/results`,
            method: 'GET',
        });

    public static getCarbonTrajectory = async (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<GetCarbonTrajectoryApiT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/results/carbon-trajectory`,
            method: 'GET',
        });
}

export default farmSeasonResultsApiService;
