/**
 * This mosal self control itself. It opens if the ":stripeStatus" url param exist.
 */

import hasPaidSecond from '@actions/AuthActions/HasPaidSecondActions';
import { useModal } from '@soil-capital/ui-kit';
import { StripeStatusUrlT } from '../ModalPayment/ModalPaymentTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ModalPaymentDoneLogicPropsT } from './ModalPaymentDoneTypes';
import { PaymentStatusT } from '@components/PaymentStatusIcon/PaymentStatusIconTypes';

const useModalPaymentDoneLogic = ({ modalPaymentController }: ModalPaymentDoneLogicPropsT) => {
    const dispatch = useAppDispatch();
    const { stripeStatus } = useParams<StripeStatusUrlT>();
    const { t } = useTranslation();

    // Context
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);

    // Store
    const userhasPaidSecond = useSelector(
        (state: HomePageStateT) => state.farmSeason?.currentFarmSeason?.has_results_access,
    );

    // Component State
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusT>('saving');
    const controller = useModal();

    // Self open if stripeStatus param exist in url
    useEffect(() => {
        if (stripeStatus) {
            controller.openModal();
        }
    }, []);

    // fetch BE every 1s to know when paiment has been registered
    useEffect(() => {
        let intervalId: number | undefined;

        if (!stripeStatus) {
            return undefined;
        }

        if (stripeStatus === 'success' && !userhasPaidSecond && farmId && farmSeasonId) {
            intervalId = window.setInterval(() => {
                dispatch(hasPaidSecond(farmId, farmSeasonId));
            }, 3000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [userhasPaidSecond]);

    // Manage payment status: saving -> canceled | success | done
    useEffect(() => {
        if ((stripeStatus && userhasPaidSecond) || stripeStatus === 'canceled') {
            setPaymentStatus(stripeStatus);
        }
    }, [userhasPaidSecond]);

    const handleNextClicked = async () => {
        if (stripeStatus === 'canceled') {
            modalPaymentController.openModal();
            controller.onModalCancel();
        } else {
            controller.onModalConfirm();
        }
    };

    /* ----------------------------- textes ----------------------------- */
    const text: Record<PaymentStatusT, string> = {
        canceled: t('pages.farmer-data.payment-done.text-canceled'),
        done: t('pages.farmer-data.payment-done.text'),
        success: t('pages.farmer-data.payment-done.text'),
        saving: t('pages.farmer-data.payment-done.text-in-progress'),
    };
    const title: Record<PaymentStatusT, string> = {
        canceled: t('pages.farmer-data.payment-done.title-canceled'),
        done: t('pages.farmer-data.payment-done.title-done'),
        success: t('pages.farmer-data.payment-done.title-done'),
        saving: t('pages.farmer-data.payment-done.title-in-progress'),
    };
    const mainButtonText: Record<PaymentStatusT, string> = {
        canceled: t('constants.try-again'),
        done: t('pages.farmer-data.payment-done.button'),
        success: t('pages.farmer-data.payment-done.button'),
        saving: t('pages.farmer-data.payment-done.button'), // same text than 'done' as it's disabled
    };
    /* -------------------------------------------------------------------------- */

    return { paymentStatus, handleNextClicked, controller, text, mainButtonText, title };
};

export default useModalPaymentDoneLogic;
