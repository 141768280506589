import { DeleteFarmSeasonCropResponseDataT } from '@actions/FarmSeasonCropActions/DeleteFarmSeasonCropTypes';
import { GetFarmSeasonCropsResponseDataT } from '@actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import {
    PostFarmSeasonCropsDataT,
    PostFarmSeasonCropsResponseDataT,
} from '@actions/FarmSeasonCropActions/PostFarmSeasonCropsTypes';
import {
    PostFarmSeasonCropDataT,
    PostFarmSeasonCropResponseDataT,
} from '@actions/FarmSeasonCropActions/PostFarmSeasonCropTypes';
import {
    PutFarmSeasonCropDataT,
    PutFarmSeasonCropResponseDataT,
} from '@actions/FarmSeasonCropActions/PutFarmSeasonCropTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonCropsApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonCropsDataT,
    ): Promise<AxiosResponse<PostFarmSeasonCropsResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/crops`,
            method: 'POST',
            data: body,
        });

    public static get = async (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<GetFarmSeasonCropsResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/crops`,
            method: 'GET',
        });

    public static putOne = async (
        farmId: number,
        farmSeasonId: number,
        farmSeasonCropId: number,
        body: PutFarmSeasonCropDataT,
    ): Promise<AxiosResponse<PutFarmSeasonCropResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/crop/${farmSeasonCropId}`,
            method: 'PUT',
            data: body,
        });

    public static postOne = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonCropDataT,
    ): Promise<AxiosResponse<PostFarmSeasonCropResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/crop`,
            method: 'POST',
            data: body,
        });

    public static deleteOne = async (
        farmId: number,
        farmSeasonId: number,
        farmSeasonCropId: number,
    ): Promise<AxiosResponse<DeleteFarmSeasonCropResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/crop/${farmSeasonCropId}`,
            method: 'DELETE',
        });
}

export default FarmSeasonCropsApiService;
