import resetPassword from '@actions/AuthActions/ResetPasswordActions';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import { AppStateT } from '@store/store';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { ResetPasswordDataT, ResetPasswordRouteParamsT } from './ResetPasswordPageTypes';
import ResetPasswordPageView from './ResetPasswordPageView';

const ResetPasswordPage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { t } = useTranslation();
    const [msgOpen, setMsgOpen] = useState(false);
    const { resetCode } = useParams<ResetPasswordRouteParamsT>();
    const loading = useSelector((state: AppStateT) => state.auth.loading);
    const [searchParams] = useSearchParams();

    const handleMsgOpen = (): void => {
        setMsgOpen(true);
    };

    useEffect(() => {
        if (msgOpen) {
            const origin = searchParams.get('origin');
            const partnerPortalUrl = process.env.REACT_APP_PP_BASE_URL;
            setTimeout(() => {
                if (origin === 'partner' && partnerPortalUrl) {
                    window.location.replace(partnerPortalUrl);
                } else {
                    navigate('/login');
                }
            }, 8000);
        }
    }, [msgOpen]);

    const validationSchema = yup.object({
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
        password: yup
            .string()
            .required(t('validation.no-password-provided'))
            .min(8, t('validation.password-must-be-at-least-x-characters', { min: 8 }))
            .matches(/^(?=.*[0-9])/, t('validation.password-must-at-least-contain-one-figure'))
            .matches(
                /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
                t('validation.password-must-at-least-contain-one-special-character'),
            ),
        password_confirmation: yup
            .string()
            .oneOf([yup.ref('password')], t('validation.password-must-match'))
            .required(t('validation.no-password-confirmation')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            password_confirmation: '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: ResetPasswordDataT, { setSubmitting, setErrors, resetForm }) => {
            if (!resetCode) {
                throw Error('resetCode is required to call "resetPassword"');
            }

            const response = await dispatch(resetPassword(values, resetCode));
            const errors: { password?: string; password_confirmation?: string } = {};
            let apiError = false;

            if (response.list?.length > 0) {
                if (response.list?.includes('password')) {
                    errors.password = t('validation.invalid-password');
                }

                if (response.list?.includes('password_confirmation')) {
                    apiError = true;
                    // todo add a general snackbar for errors...
                    // eslint-disable-next-line no-alert
                    alert(t(response.message.error));
                }
            }

            setErrors(errors);
            setSubmitting(false);

            if (_.isEmpty(errors) && !apiError) {
                resetForm();
                handleMsgOpen();
            }
        },
    });

    return <ResetPasswordPageView formik={formik} loading={loading} msgOpen={msgOpen} t={t} />;
};

export default ResetPasswordPage;
