import FarmSeasonFieldsCoverCropApiService from '@services/farmSeasonFieldsCoverCropApiService/farmSeasonFieldsCoverCropApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsCoverCropPostCallBackT,
    PostFarmSeasonFieldsCoverCropDataT,
    PostFarmSeasonFieldsCoverCropDispatchTypesT,
    PostFarmSeasonFieldsCoverCropResponseDataT,
    PostFarmSeasonFieldsCoverCropResponseErrorT,
    POST_FARM_SEASON_FIELDS_COVER_CROP_FAILED,
    POST_FARM_SEASON_FIELDS_COVER_CROP_LOADING,
    POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS,
} from './PostFarmSeasonFieldsCoverCropTypes';

export const postFarmSeasonFieldsCoverCrop =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsCoverCropDataT,
    ): FarmSeasonFieldsCoverCropPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsCoverCropDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsCoverCropResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_COVER_CROP_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsCoverCropResponseDataT> =
                await FarmSeasonFieldsCoverCropApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_COVER_CROP_FAILED,
                error: error as PostFarmSeasonFieldsCoverCropResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
