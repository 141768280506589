import FarmSeasonFieldsSoilOrganicCarbonApiService from '@services/farmSeasonFieldsSoilOrganicCarbonApiService/farmSeasonFieldsSoilOrganicCarbonApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsSoilOrganicCarbonPostCallBackT,
    PostFarmSeasonFieldsSoilOrganicCarbonDataT,
    PostFarmSeasonFieldsSoilOrganicCarbonDispatchTypesT,
    PostFarmSeasonFieldsSoilOrganicCarbonResponseDataT,
    PostFarmSeasonFieldsSoilOrganicCarbonResponseErrorT,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_FAILED,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_LOADING,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS,
} from './PostFarmSeasonFieldsSoilOrganicCarbonTypes';

export const postFarmSeasonFieldsSoilOrganicCarbon =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsSoilOrganicCarbonDataT,
    ): FarmSeasonFieldsSoilOrganicCarbonPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsSoilOrganicCarbonDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsSoilOrganicCarbonResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsSoilOrganicCarbonResponseDataT> =
                await FarmSeasonFieldsSoilOrganicCarbonApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_FAILED,
                error: error as PostFarmSeasonFieldsSoilOrganicCarbonResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
