import { Typography } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

import useSearchPageStepStyle from './SearchPageStepView.style';
import { SearchPageStepViewPropsT } from './SearchPageStepViewTypes';

const SearchPageStepView = ({ children, className, title, onBack }: SearchPageStepViewPropsT): JSX.Element => {
    const { classes } = useSearchPageStepStyle();

    return (
        <div className={className}>
            <div className={classes.topBar}>
                <div>{onBack && <ArrowBack className={classes.back} fontSize="small" onClick={onBack} />}</div>
                <div className={classes.title}>
                    <Typography color="secondary" variant="h4">
                        {title}
                    </Typography>
                </div>
            </div>
            {children}
        </div>
    );
};

export default SearchPageStepView;
