import { MoistureT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const MOISTURE_LIST_GET_LOADING = 'MOISTURE_LIST_GET_LOADING';
export const MOISTURE_LIST_GET_SUCCESS = 'MOISTURE_LIST_GET_SUCCESS';
export const MOISTURE_LIST_GET_FAILED = 'MOISTURE_LIST_GET_FAILED';

export type MoistureListGetSuccessDataT = MoistureT[];

export type MoistureListGetLoadingT = {
    type: typeof MOISTURE_LIST_GET_LOADING;
};

export type MoistureListGetSuccessT = {
    type: typeof MOISTURE_LIST_GET_SUCCESS;
    payload: MoistureListGetSuccessDataT;
};

export type MoistureListGetFailedT = {
    type: typeof MOISTURE_LIST_GET_FAILED;
    error: string;
};

export type MoistureListGetDispatchType = MoistureListGetLoadingT | MoistureListGetSuccessT | MoistureListGetFailedT;

export type MoistureListGetCallback = (dispatch: Dispatch<MoistureListGetDispatchType>) => Promise<boolean>;
