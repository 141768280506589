import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    LanguagesGetDispatchTypes,
    LanguagesGetFailedDataT,
    LanguagesGetSuccessDataT,
    LANGUAGES_GET_FAILED,
    LANGUAGES_GET_LOADING,
    LANGUAGES_GET_SUCCESS,
} from './LanguagesGetActionsTypes';

const localStorageKey = 'languages';

const languagesGet =
    () =>
    async (dispatch: Dispatch<LanguagesGetDispatchTypes>): Promise<LanguagesGetFailedDataT> => {
        try {
            const languages = localStorage.getItem(localStorageKey);

            if (!languages) {
                dispatch({ type: LANGUAGES_GET_LOADING });

                const res: AxiosResponse<LanguagesGetSuccessDataT> = await StaticApiService.getLanguages();

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));
                dispatch({
                    type: LANGUAGES_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: LANGUAGES_GET_SUCCESS,
                    payload: JSON.parse(languages),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: LANGUAGES_GET_FAILED,
                error: error as LanguagesGetFailedDataT,
            });

            return {};
        }
    };

export default languagesGet;
