import { useRef, useState } from 'react';
import { BillingDetailsFormRefControllerT } from '../BillingDetailsForm.types';
import { useBillingDetailsEditFlowPropsT } from './useBillingDetailsEditFlow.types';
import userCompanyApiService from '@services/userCompanyApiService/userCompanyApiService';

/**
 * This hook is explicitly designed to be used with `EditBillingDetailsForm.tsx` \
 * - {@link userCompany} is provided to initialize the form with UserCompany data
 * - {@link editBillingDetailsFormRef} is used as forwardRef such that the parent can control this component
 */
const useBillingDetailsEditFlow = ({ userCompany }: useBillingDetailsEditFlowPropsT) => {
    const billingDetailsFormRef = useRef<BillingDetailsFormRefControllerT>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    /** This is the definition of the logic that will happen after the submit */
    const onSubmitForm = async () => {
        setIsSubmitting(true);
        const data = await billingDetailsFormRef.current?.getFormData();

        if (data && userCompany) {
            try {
                const res = await userCompanyApiService.update({
                    userId: userCompany.user_id,
                    companyId: userCompany.id,
                    data,
                });

                setIsSubmitting(false);
                return res.data;
            } catch (e) {
                billingDetailsFormRef.current?.displayError('constants.server-error');
                setIsSubmitting(false);
                return false;
            }
        }
        setIsSubmitting(false);
        return false;
    };

    return { billingDetailsFormRef, onSubmitForm, isSubmitting };
};

export default useBillingDetailsEditFlow;
