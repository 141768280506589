import usePaymentStatusIconStyle from './PaymentStatusIcon.style';
import { PaymentStatusIconPropsT } from './PaymentStatusIconTypes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingView from '@components/Loading/LoadingView';

const PaymentStatusIcon = ({ paymentStatus }: PaymentStatusIconPropsT): JSX.Element => {
    const { classes } = usePaymentStatusIconStyle();
    let Icon: JSX.Element | null = null;

    switch (paymentStatus) {
        case 'canceled':
        case 'payment_failed':
            Icon = <ErrorIcon className={classes.iconDropShadow} color="error" />;
            break;
        case 'success':
        case 'done':
        case 'active':
        case 'pending':
            Icon = <CheckCircleIcon className={classes.iconDropShadow} color="inherit" />;
            break;
        case 'saving':
        case 'payment_in_progress':
        case 'requires_action':
            Icon = <LoadingView />;
    }

    return <div className={classes.PaymentStatusIcon}>{Icon}</div>;
};

export default PaymentStatusIcon;
