/* eslint-disable @typescript-eslint/no-explicit-any */
import postFarmSeasonFieldsTillagePracticeHistory from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillagePracticeHistory';
import tillageGetListAction from '@actions/StaticActions/TillageGetListAction';
import palette from '@constants/palette/crop.palette';
import {
    FarmSeasonFieldT,
    FarmSeasonFieldTillagePracticeHistoryT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import * as Yup from 'yup';

import { ContextT, ContextPropsT, FormDataT, GroupedFieldsT } from './TillagePracticeTaskContextTypes';

export const Context = createContext<ContextT>({} as ContextT);

const TillagePracticeTaskContext = ({ children }: ContextPropsT): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm)?.id;
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason)?.id;
    const farmSeasonFields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const loading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);

    const [currentStep, setCurrentStep] = useState(1);
    const [groupedFields, setGroupedFields] = useState<GroupedFieldsT>({});
    const [selectedGroup, setSelectedGroup] = useState<FarmSeasonFieldT[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [nothingOnFields, setNothingOnFields] = useState<boolean>(false);
    const [errorNothingOnFields, setErrorNothingOnFields] = useState<boolean>(false);
    const [groupedFieldIndex, setGroupedFieldIndex] = useState<number | null>(null);
    const [keyColor, setKeyColor] = useState<{ [key: string]: string }>({});
    const [nothingToSave, setNothingToSave] = useState<boolean>(true);

    const tillagePracticeList = useSelector((state: HomePageStateT) => state.static.tillageList) || [];
    const dontHaveAny =
        useSelector(
            (state: HomePageStateT) =>
                state.farmSeason?.currentFarmSeason?.installation?.onboarding?.tillagePracticeHistory?.dontHaveAny,
        ) || false;

    // load data
    useEffect(() => {
        dispatch(tillageGetListAction());
    }, []);

    // initialize values. Groupe Field by cover crop year introduction and and frequency
    useEffect(() => {
        const initGroupedField =
            farmSeasonFields?.reduce((groupedObject, field) => {
                const newGroupedObject: any = { ...groupedObject };

                if (field?.tillage_practice_history?.year_of_change) {
                    const groupKey = `${field.tillage_practice_history?.year_of_change}-${field.tillage_practice_history?.tillage_change_type_id}-${field.tillage_practice_history?.year_end_labour}`;
                    newGroupedObject[groupKey] = [...(groupedObject[groupKey] || []), { ...field }];
                }

                return newGroupedObject;
            }, {} as any) || {};

        setGroupedFields(initGroupedField);
    }, [farmSeasonFields]);

    // set up the checkbox for no cover crop
    useEffect(() => {
        setNothingOnFields(dontHaveAny);
    }, [dontHaveAny]);

    useEffect(() => {
        const initKeyColor = { ...keyColor };
        let colorIndex = 0;
        Object.keys(groupedFields).forEach((key) => {
            if (!initKeyColor[key] && colorIndex < palette.length) {
                while (Object.values(initKeyColor).includes(palette[colorIndex])) {
                    colorIndex += 1;
                }
                initKeyColor[key] = palette[colorIndex];
            }
        });
        setKeyColor(initKeyColor);
    }, [groupedFields]);

    const onSubmit = async () => {
        if (Object.keys(groupedFields).length === 0 && !nothingOnFields) {
            setErrorNothingOnFields(true);
        } else {
            const tillagePracticeHistories: FarmSeasonFieldTillagePracticeHistoryT[] = [];
            Object.keys(groupedFields).forEach((key) => {
                groupedFields[key].forEach((field) => {
                    if (field.tillage_practice_history) {
                        tillagePracticeHistories.push(field.tillage_practice_history);
                    }
                });
            });

            setNothingToSave(true);
            await dispatch(
                postFarmSeasonFieldsTillagePracticeHistory(farmId, farmSeasonId, {
                    tillagePracticeHistories,
                    dontHaveAny: nothingOnFields,
                }),
            );
            navigate('/practice-history');
        }
    };

    // Validation Shema
    const validationSchema = Yup.object({
        year_of_change: Yup.string().required(t('validation.mandatory-field')),
        tillage_change_type_id: Yup.string().required(t('validation.mandatory-field')),
        year_end_labour: Yup.string().when('tillage_change_type_id', (tillage_change_type_id, schema) =>
            ['1', '3'].includes(tillage_change_type_id)
                ? schema
                      .required(t('validation.mandatory-field'))
                      .test(
                          'not-bigger-than',
                          t('pages.installationtasks.history.tillage-practice.history-form.year-end-labour-validation'),
                          (value: string, context: any) => +value < +context.parent.year_of_change,
                      )
                : schema,
        ),
        key: Yup.string().test(
            'combination-unique',
            t('pages.installationtasks.history.tillage-practice.history-form.key-validation'),
            (value) => !Object.keys(groupedFields).some((key) => value === key && value !== selectedKey),
        ),
    });

    // Formik forom
    const formik = useFormik<FormDataT>({
        initialValues: {
            year_of_change: '',
            tillage_change_type_id: '',
            year_end_labour: '',
            key: '',
        },
        onSubmit: () => {
            formik.validateForm().then(() => {
                const newSelectedGroup = selectedGroup.map((field) => ({
                    ...field,
                    tillage_practice_history: {
                        ...field.tillage_practice_history,
                        year_of_change: formik.values.year_of_change,
                        tillage_change_type_id: +formik.values.tillage_change_type_id,
                        year_end_labour: formik.values.year_end_labour,
                    },
                }));
                setSelectedGroup(newSelectedGroup);
                setCurrentStep(3);
            });
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: true,
    });

    const store: ContextT = {
        currentStep,
        setCurrentStep,
        t,
        formik,
        loading,
        groupedFields,
        setGroupedFields,
        selectedGroup,
        setSelectedGroup,
        selectedKey,
        setSelectedKey,
        nothingOnFields,
        setNothingOnFields,
        onSubmit,
        groupedFieldIndex,
        setGroupedFieldIndex,
        keyColor,
        farmSeasonFields,
        nothingToSave,
        setNothingToSave,
        tillagePracticeList,
        errorNothingOnFields,
        setErrorNothingOnFields,
    };

    return <Context.Provider value={store}>{children}</Context.Provider>;
};
export default TillagePracticeTaskContext;
