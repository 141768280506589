import { makeStyles } from 'tss-react/mui';

const useSideBarStyle = makeStyles()(() => ({
    sideBarInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
}));

export default useSideBarStyle;
