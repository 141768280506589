import { MapPropsT } from '@components/Map/MapTypes';

export const MAP_UPDATE = 'MAP_UPDATE';

export type MapUpdateT = {
    type: typeof MAP_UPDATE;
    payload: MapPropsT;
};

export type MapActionsT = MapUpdateT;
