import useMapFieldReset from '@hooks/useMapFieldReset';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import addPolygonData from '@utils/addPolygonData';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tillages from '@constants/tillages';
import useOperationDone from '@hooks/useOperationDone';

import { TaskStepRouteParamsT } from './TaskStepTypes';
import TaskStepView from './TaskStepView';
import { useCanEditFarmOperations } from '@hooks/usePermissions';

const TaskStep = (): JSX.Element => {
    const { cropId, tillageId } = useParams<TaskStepRouteParamsT>();
    const { t } = useTranslation();
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList);
    const cropColors = setupCropPaletteFromCropLegacy(crops);
    const resetMap = useMapFieldReset();
    const currentFarmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const currentFarmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditOperations } = useCanEditFarmOperations(currentFarmSeason);

    const { getCategoriesDone } = useOperationDone();
    const operationsDone = getCategoriesDone(Number(cropId), Number(tillageId));

    useEffect(() => {
        MapService.addPolygons({
            polygons: operationsDone.fromFilteredFields.map((field) =>
                addPolygonData({ field, colorPalette: cropColors, withTillage: true }),
            ),
            autofocus: true,
        });

        return () => {
            resetMap(true);
        };
    }, [operationsDone.fromFilteredFields, cropColors]);

    const operationTasks = [
        {
            slug: 'pages.operations.task-step.tillage-practice',
            route: 'tillagePractice',
            done: operationsDone.isDone.tillage,
        },
        {
            slug: 'pages.operations.task-step.sowing',
            route: 'sowing',
            done: operationsDone.isDone.sowing,
        },
        {
            slug: 'pages.operations.task-step.spraying',
            route: 'spraying',
            done: operationsDone.isDone.spraying,
        },
        {
            slug: 'pages.operations.task-step.fertilising',
            route: 'fertilisation',
            done: operationsDone.isDone.fertilising,
        },
    ];

    return (
        <TaskStepView
            done={operationsDone.hasAnyDone}
            farmId={currentFarmId}
            farmSeasonId={currentFarmSeasonId}
            menuTitle={`${t(crops?.find((crop) => crop.id === Number(cropId))?.crop?.translation_slug ?? '')} - ${t(
                tillages?.find((tillage) => tillage.id === Number(tillageId))?.name ?? '',
            )}`}
            operationTasks={operationTasks}
            canEdit={canEditOperations.access}
        />
    );
};

export default TaskStep;
