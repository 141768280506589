import { postFarmSeasonFieldsCoverCrop } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsCoverCrop';
import useMapFieldReset from '@hooks/useMapFieldReset';
import { useModal } from '@soil-capital/ui-kit';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';

import { PostFieldsCoverCropDataT } from './CoverCropTaskTypes';
import CoverCropTaskView from './CoverCropTaskView';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const CoverCropTask = (): JSX.Element => {
    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });

    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField?.fieldsList);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const fieldLoading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    const dontHaveAny = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding?.coverCrop?.dontHaveAny,
    );

    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();

    const setCanRefresh = useMapFieldReset();

    const modalDeleteSelection = useModal();

    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }
        MapService.selectablePolygons({ selectMode: true });

        return () => MapService.selectablePolygons({ selectMode: false });
    }, []);

    useEffect(() => {
        if (isSubmitted) setCanRefresh(!isSubmitted);
    }, [isSubmitted]);

    const formik = useFormik({
        initialValues: {
            coverCrop: dontHaveAny === null ? true : !dontHaveAny,
            fields: fields?.length > 0 ? [...fields.filter((field) => field.has_cover_crops)] : [],
        },
        enableReinitialize: true,
        onSubmit: async (values: PostFieldsCoverCropDataT, { setSubmitting }) => {
            setIsSubmitted(true);

            await dispatch(postFarmSeasonFieldsCoverCrop(farmId, farmSeasonId, values));
            setSubmitting(false);
            navigate('..');
        },
        validate: (values) => {
            const errors: any = {}; // eslint-disable-line

            if (values.fields.length === 0 && values.coverCrop) {
                errors.coverCrop = t('validation.confirm-no-cover-crop-error-message');
            }

            return errors;
        },
    });

    useEffect(() => {
        if (!loaded) {
            const initFields = fields?.filter((field) => field.has_cover_crops);

            if (thisMap?.selectedPolygons?.length !== initFields?.length) {
                MapService.updateSelectedPolygons({
                    selectedPolygons: initFields.map((field) => field.polygon),
                });
            }

            setLoaded(true);
        }
    }, [fields]);

    const onSelectAll = () => {
        const mapFields = fields?.filter(
            (field) => !Boolean(field.field_crops?.find((field_crop) => field_crop.farm_season_crop.crop.is_permanent)),
        );
        MapService.updateSelectedPolygons({
            selectedPolygons: [...mapFields.map((field) => field.polygon)],
        });
    };

    useEffect(() => {
        const selected =
            fields?.filter((field) => thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id)) ??
            [];
        formik.setValues((state) => ({
            coverCrop: !(!state.coverCrop && selected.length === 0),
            fields: [...selected],
        }));
    }, [thisMap.selectedPolygons]);

    const onSelectedFieldDelete = (id: string | number) => {
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    const deleteAll = () => {
        MapService.updateSelectedPolygons({
            selectedPolygons: [],
        });
        formik.setValues((values) => ({ ...values, fields: [] }));
    };

    return (
        <CoverCropTaskView
            deleteAll={deleteAll}
            fieldLoading={fieldLoading}
            formik={formik}
            isDirty={formik.dirty}
            isSubmitted={isSubmitted}
            modalDeleteSelection={modalDeleteSelection}
            t={t}
            onConfirm={formik.submitForm}
            onSelectAll={onSelectAll}
            onSelectedFieldDelete={onSelectedFieldDelete}
        />
    );
};

export default CoverCropTask;
