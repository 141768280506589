import { Paper, Typography } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useTranslation } from 'react-i18next';

import useDndListItemStyle from './DndListItem.style';
import { DndListItemViewPropsT } from './DndListItemTypes';

// TODO: Put Relations back on when defined
const DndListItemView = ({
    index,
    text,
    isAssociated = false,
    // canAssociat = false,
    drag,
    drop,
    reference,
    handlerId,
    isDragging,
    canDrag,
    moveState,
}: // onCoupleClick,
DndListItemViewPropsT): JSX.Element => {
    const opacity = isDragging ? 0.5 : 1;
    const { classes } = useDndListItemStyle({ opacity, canDrag });
    const { t } = useTranslation();

    drag(drop(reference));

    return (
        <div ref={reference} className={classes.relative} data-handler-id={handlerId}>
            {moveState && moveState?.hoverIndex < moveState?.dragIndex && moveState?.hoverIndex === index && (
                <div className={classes.topLine} />
            )}
            <Paper className={classes.container} elevation={0}>
                <ReorderIcon className={classes.icon} color="secondary" />
                <Typography color="primary" variant="h5">
                    {text}
                </Typography>
            </Paper>
            {moveState && moveState?.hoverIndex > moveState?.dragIndex && moveState?.hoverIndex === index && (
                <div className={classes.bottomLine} />
            )}
            {isAssociated && <div className={classes.link} />}
            {/* {(canAssociat || isAssociated) && (
        <IconButton className={classes.button} color="primary" onClick={onCoupleClick}>
          {isAssociated ? <LinkIcon className={classes.assocIcon} /> : <LinkOffIcon className={classes.assocIcon} />}
        </IconButton>
      )} */}
            {isAssociated && (
                <Typography className={classes.assocText} color="secondary" variant="h6">
                    {t('component.relationDndModalView.relationsStep.dndListItem.assoc-title')}
                </Typography>
            )}
        </div>
    );
};

export default DndListItemView;
