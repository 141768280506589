import { Grid } from '@mui/material';
import { useEffect } from 'react';
import LabelNumberField from '@components/LabelNumberField/LabelNumberField';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { livestocksGet } from '@actions/StaticActions/LivestocksAction';
import { useTranslation } from 'react-i18next';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';

import { LivestockListViewPropsT } from './LivestockListView.types';
import CardFormLayout from '@/layouts/CardFormLayout/CardFormLayout';

const LivestockListView = ({
    quantity,
    onQuantityChange,
    quantityName,
    onClose,
    livestockId,
    onLivestockChange,
    title,
    livestockError,
    livestockHelperText,
    quantityError,
    quantityHelperText,
    usedLivestock,
    disabledForm,
}: LivestockListViewPropsT): JSX.Element => {
    const { t } = useTranslation();
    const livestocks = useSelector((state: HomePageStateT) => state.static.livestocks);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!(livestocks?.length > 0)) dispatch(livestocksGet());
    }, []);

    return (
        <CardFormLayout title={title} onClose={onClose}>
            <Grid spacing={2} container>
                <Grid xs={7} item>
                    <LabelAutocomplete
                        disabled={disabledForm}
                        error={livestockError}
                        getOptionDisabled={(livestock) =>
                            usedLivestock.some((used) => used.livestock_id === String(livestock.id))
                        }
                        helperText={livestockHelperText}
                        label={t('pages.installationtasks.livestock-task.livestock-type')}
                        options={livestocks}
                        value={Number(livestockId)}
                        required
                        onChange={(value) => onLivestockChange(value?.toString())}
                    />
                </Grid>
                <Grid xs={5} item>
                    <LabelNumberField
                        disabled={disabledForm}
                        error={quantityError}
                        helperText={quantityHelperText}
                        label={t('pages.installationtasks.livestock-task.quantity')}
                        name={quantityName}
                        value={quantity}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={onQuantityChange}
                    />
                </Grid>
            </Grid>
        </CardFormLayout>
    );
};

export default LivestockListView;
