import { Typography } from '@mui/material';
import { ModalBase } from '@soil-capital/ui-kit';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import roundNumber from '@utils/roundNumber';
import { TFunction, useTranslation } from 'react-i18next';
import { TotalAreaIncreaseModalProps } from './useTotalAreaCheckModalsTypes';

const TotalAreaIncreaseModal = ({ controller, totalAreaDiffHa }: TotalAreaIncreaseModalProps) => {
    const { t } = useTranslation();
    const getText: TFunction = (finalKey: string, tParams) => {
        return t(`pages.installationtasks.add-field-task.modal-total-area-increase.${finalKey}`, tParams);
    };

    return (
        <ModalBase controller={controller}>
            <ModalBaseLayout
                title={{ text: getText('title') }}
                confirmButton={{ text: getText('confirm'), onClick: controller.onModalCancel }}
            >
                <Typography variant="h5" marginBottom={2}>
                    {getText('text-important', {
                        totalAreaDiffHa: `${roundNumber(totalAreaDiffHa, { round: 'up' })}ha`,
                    })}
                </Typography>
                <Typography variant="body2">{getText('text')}</Typography>
            </ModalBaseLayout>
        </ModalBase>
    );
};

export default TotalAreaIncreaseModal;
