import { Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import DatePicker from '@components/DatePicker/DatePicker';
import { Prompt } from '@components/Prompt/Prompt';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import useHistoryMaxYear from '@hooks/useHistoryMaxYear';

import useHistoryFormStyle from './HistoryFormView.style';
import { HistoryFormViewPropsT } from './HistoryFormTypes';

const HistoryFormView = ({
    groupedFieldIndex,
    t,
    formik,
    nothingToSave,
    setCurrentStep,
    canEdit = false,
}: HistoryFormViewPropsT): JSX.Element => {
    const { classes } = useHistoryFormStyle();

    const historyMaxYear = useHistoryMaxYear();

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!nothingToSave}
            />
            <Typography className={classes.groupedFieldTitle}>
                {t('pages.installationtasks.history.pasture.history-form.pasture-title')} {groupedFieldIndex}
            </Typography>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.history.pasture.history-form.title">
                    Veuillez ajouter les informations pour votre{' '}
                    <span className={classes.titleDark}>couvert végétal</span>
                </Trans>
                <HelpButtonView id="pages.installationtasks.history.pasture.history-form.title" />
            </Typography>
            <Grid className={classes.groupInputContainer} container>
                <Grid className={classes.inputContainer} xs={12} item>
                    <DatePicker
                        disabled={!canEdit}
                        // initialFocusedDate={historyMaxYear}
                        labelText={t('pages.installationtasks.history.pasture.history-form.label-year-of-change')}
                        maxDate={historyMaxYear}
                        openTo="year"
                        value={formik.values.year_of_change === '' ? '' : new Date(formik.values.year_of_change)}
                        onChange={(event) => {
                            const year = new Date(event).getFullYear().toString();
                            formik.setFieldValue('year_of_change', year);
                            formik.setFieldValue('key', year);
                            setTimeout(() => formik.setTouched({ year_of_change: true }));
                        }}
                    />
                    {formik.touched.year_of_change && !!formik.errors.year_of_change && (
                        <Typography className={classes.errorCombination} variant="body2">
                            {formik.errors.year_of_change}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <div className={classes.footerButton}>
                <TwoButtonFooterView
                    rightDisabled={!formik.isValid}
                    rightOnCLick={canEdit ? formik.handleSubmit : () => setCurrentStep(3)}
                    rightText={t('constants.continue')}
                />
            </div>
        </div>
    );
};

export default HistoryFormView;
