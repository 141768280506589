import LabelTextField from '@components/LabelTextField/LabelTextField';
import { Grid, Typography } from '@mui/material';
import Restricted from '@components/Restricted/Restricted';
import { RestrictedPropsT } from '@components/Restricted/RestrictedTypes';
import CardFormLayout from '@/layouts/CardFormLayout/CardFormLayout';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { FieldArray, FormikProvider, getIn } from 'formik';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { FieldsContextStore } from '../../FieldsContext/FieldsContext';
import { FieldsContextStoreT } from '../../FieldsContext/FieldsContextTypes';

import useYieldAndResidueStyle from './YieldAndResidueView.style';
import { YieldAndResidueViewPropsT } from './YieldAndResidueViewTypes';

const YieldAndResidueView = ({ formik }: YieldAndResidueViewPropsT): JSX.Element => {
    const { classes } = useYieldAndResidueStyle();
    const { t } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const restrictEditFarmSeasonProps: RestrictedPropsT<'EDIT_FARM-SEASON'> = {
        toPermission: 'EDIT_FARM-SEASON',
        forResource: currentFarmSeason,
        withProps: () => ({ disabled: true }),
    };

    return (
        <>
            <Typography className={classes.title} component="h4" variant="h4">
                {t('pages.fields.yield-and-residue')}
            </Typography>

            <div className={classes.cropsContainer}>
                <FormikProvider value={formik}>
                    <Grid spacing={2} container>
                        <FieldArray
                            name="field_crops"
                            render={() => (
                                <>
                                    {formik.values.field_crops?.map((fieldCrop, index) => {
                                        const key = `field_crops.${index}.key`;

                                        const {
                                            farm_season_crop: {
                                                residue: cropResidue,
                                                yield: cropYield,
                                                crop: { translation_slug: cropSlug },
                                            },
                                            yield: fieldYield,
                                            residue: fieldResidue,
                                        } = fieldCrop;

                                        const yieldName = `field_crops.${index}.yield`;
                                        const yieldTouched = getIn(formik.touched, yieldName);
                                        const yieldError = getIn(formik.errors, yieldName);

                                        const residueName = `field_crops.${index}.residue`;
                                        const residueTouched = getIn(formik.touched, residueName);
                                        const residueError = getIn(formik.errors, residueName);

                                        return (
                                            <Grid key={key} item xs={12} xl={4} lg={6}>
                                                <CardFormLayout title={t(cropSlug ?? '')}>
                                                    <Grid className={classes.inputsContainer} spacing={2} container>
                                                        <Grid className={classes.inputContainer} xs={6} item>
                                                            <Restricted {...restrictEditFarmSeasonProps}>
                                                                <LabelTextField
                                                                    InputProps={{ inputProps: { min: 0 } }}
                                                                    endAdornment="t/ha"
                                                                    error={yieldTouched && !!yieldError}
                                                                    helpName="yield-help"
                                                                    helperText={yieldTouched && yieldError}
                                                                    id={yieldName}
                                                                    label={t('pages.crops.edit-step.yield')}
                                                                    name={yieldName}
                                                                    type="number"
                                                                    value={fieldYield ?? cropYield ?? 0}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    withHelp
                                                                    onChange={(event) => {
                                                                        formik.setFieldValue(
                                                                            yieldName,
                                                                            event.target.value,
                                                                        );
                                                                    }}
                                                                />
                                                            </Restricted>
                                                        </Grid>
                                                        <Grid className={classes.inputContainer} xs={6} item>
                                                            <Restricted {...restrictEditFarmSeasonProps}>
                                                                <LabelTextField
                                                                    InputProps={{ inputProps: { min: 0 } }}
                                                                    endAdornment="t/ha"
                                                                    error={residueTouched && !!residueError}
                                                                    helperText={residueTouched && residueError}
                                                                    id={residueName}
                                                                    label={t('pages.installationsubtasks.residue')}
                                                                    name={residueName}
                                                                    type="number"
                                                                    value={fieldResidue ?? cropResidue ?? 0}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    onChange={(event) => {
                                                                        formik.setFieldValue(
                                                                            residueName,
                                                                            event.target.value,
                                                                        );
                                                                    }}
                                                                />
                                                            </Restricted>
                                                        </Grid>
                                                    </Grid>
                                                </CardFormLayout>
                                            </Grid>
                                        );
                                    })}
                                </>
                            )}
                        />
                    </Grid>
                </FormikProvider>
            </div>
        </>
    );
};

export default YieldAndResidueView;
