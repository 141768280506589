import { makeStyles } from 'tss-react/mui';

const useSelectFieldStepViewStyle = makeStyles()((theme) => ({
    container: {},
    titleDarker: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
}));

export default useSelectFieldStepViewStyle;
