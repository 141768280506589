import { geoJsonFeatureCollectionT } from '@components/Map/MapTypes';
import _ from 'lodash';
import intersect from '@turf/intersect';
import area from '@turf/area';
//import { toleranceOptions } from '@components/Map/draw-modes/utils/tolerance';
import { toleranceOptions } from '../../components/Map/draw-modes/utils/tolerance';
import { Feature } from './shapeServiceTypes';
import transformTranslate from '@turf/transform-translate';
import { MultiPolygon, Polygon } from '@turf/turf';

class ShapeService {
    static intersectPolygons = (geoJsonFeature: Feature<Polygon>, geoJsonFeatures: Feature<Polygon>[]): 'processingError' | boolean => {
        try {
            return _.some(geoJsonFeatures, (v) => {
                const interGeo = intersect(v.geometry, geoJsonFeature.geometry);

                // No intersect at all.
                if (interGeo === null) {
                    return false;
                }

                // overlapping tolerance -> biggest between (~0.5% of the polygon's area) and (100m2)
                if (
                    area(interGeo) >
                    Math.max(
                        area(geoJsonFeature.geometry) / toleranceOptions.TOLERANCE_RATIO_AREA,
                        toleranceOptions.TOLERANCE_MIN_AREA_SHAPEFILE,
                    )
                ) {
                    return true;
                }

                return false;
            });
        } catch (e) {
            console.error(e);
            return 'processingError';
        }
    };

    static intersectFarmBoundaries = (
        geoJsonFeature: Feature<Polygon>,
        farmMask: geoJsonFeatureCollectionT,
    ): 'processingError' | boolean => {
        const intersect = this.intersectPolygons(geoJsonFeature, farmMask.features);
        if (intersect === 'processingError') {
            // If we have mask exactly matching the polygon, an error is thrown from turf. Moving this polygon can make the intersect work (~= 0)
            const translatedPolygon = transformTranslate(geoJsonFeature.geometry, 0.01, 0.01, {
                units: 'millimeters',
            });
            return this.intersectPolygons({ type: 'Feature', geometry: translatedPolygon, properties: {} }, farmMask.features);
        }
        return intersect;
    };

    /** tell if the first (main) GeoJson intersect the second GeoJson with more than [percentage] percent (compared to the second one). */
    static intersectPolygonWithPercentage = (
        mainGeoJsonFeature: Feature<Polygon>,
        geoJsonFeatureToIntersect: Feature<Polygon>,
        percentage: number,
    ): 'processingError' | boolean => {
        if (percentage < 0 || percentage > 100) {
            throw Error(`Bad percentage: ${percentage}. It should be between 0 and 100`);
        }

        try {
            const interGeo = intersect(mainGeoJsonFeature.geometry, geoJsonFeatureToIntersect.geometry);

            // intersection must be > 85% of the size of the field to consider it equal
            if (interGeo && (area(interGeo) / area(mainGeoJsonFeature.geometry)) * 100 >= percentage) {
                return true;
            }

            return false;
        } catch (e) {
            console.error(e);
            return 'processingError';
        }
    };

    // says if the geoJsonFeature has invalid coordinates (format outside the -90, 90 values we don't handle)
    static hasInvalidCoordinates = (geoJsonFeature: Feature<Polygon>): 'processingError' | boolean => {
        try {
            const coordinates = geoJsonFeature.geometry.coordinates.flat(3);
            const invalidCoordinate = coordinates?.find((coordinate) => {
                return coordinate > 90 || coordinate < -90;
            });
            return !!invalidCoordinate;
        } catch (e) {
            console.error(e);
            return 'processingError';
        }
    };
}

export default ShapeService;
