import formatCurrencyNumber from '@services/formatCurrencyNumber/formatCurrencyNumber';
import formatNumber from '@utils/formatNumber';
import { PurchaseTablePropsT } from './PurchaseTableTypes';

const usePurchaseTableLogic = ({ farmSeasonPurchase }: PurchaseTablePropsT) => {
    const numberIsValid = (val: number) => {
        return val && !isNaN(val);
    };

    // Make rows ready to display data.
    const rows = farmSeasonPurchase?.map((purchase, i) => ({
        harvestYear: purchase.harvest_year,
        revenue: purchase.revenue ? formatCurrencyNumber(purchase.revenue, purchase.currency) : '',
        purchasePrice: numberIsValid(purchase.purchase_price)
            ? formatCurrencyNumber(purchase.purchase_price, purchase.currency)
            : '',
        onMarket: numberIsValid(purchase.on_market) ? formatNumber(purchase.on_market) : '',
        purchaseIssue: purchase.purchase_issue,
    }));

    // Sort rows by harvest year and purchase issue on antichronological order
    rows.sort((a, b) => {
        if (a.harvestYear !== b.harvestYear) return b.harvestYear - a.harvestYear;
        else return b.purchaseIssue - a.purchaseIssue;
    });

    return {
        rows,
    };
};

export default usePurchaseTableLogic;
