import { makeStyles } from 'tss-react/mui';

const useCropModalViewStyle = makeStyles()((theme) => ({
    container: {
        padding: '30px',
        width: '100%',
        backgroundColor: theme.palette.spanishWhite.light,
    },
    title: {
        marginBottom: '20px',
        fontSize: '1.3em',
    },
    select: {
        marginBottom: '20px',
    },
}));

export default useCropModalViewStyle;
