import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import TooltipSc from '@components/TooltipSc/TooltipSc';

import useLabelCheckboxStyle from './LabelCheckboxView.style';
import { LabelCheckboxViewPropsT } from './LabelCheckboxViewTypes';

const LabelCheckboxView = ({
    label,
    onChange,
    checked,
    name,
    error,
    helperText,
    className,
    noMargin,
    border = 'none',
    disabled,
    withHelp,
    containerClass,
    id,
    helpName,
    tooltipSlug,
}: LabelCheckboxViewPropsT): JSX.Element => {
    const { classes } = useLabelCheckboxStyle({ border, noMargin });

    return (
        <div className={`${classes.checkboxContainer} ${containerClass}`}>
            <FormControlLabel
                className={`${classes.label} ${className}`}
                control={
                    <Checkbox
                        checked={checked}
                        className={classes.checkbox}
                        name={name}
                        disableRipple
                        onChange={onChange}
                    />
                }
                disabled={disabled}
                label={
                    <>
                        <TooltipSc translationSlug={tooltipSlug}>{label}</TooltipSc>
                        {withHelp && <HelpButtonView id={helpName || `${id}-help`} />}
                    </>
                }
                name={name}
            />
            {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </div>
    );
};

export default LabelCheckboxView;
