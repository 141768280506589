import ColorPatternButtonView from '@components/ColorPatternButton/ColorPatternButtonView';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import HouseIcon from '@mui/icons-material/House';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, CircularProgress, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useIsReadOnlyUser } from '@/hooks/usePermissions';
import { FarmSearchT, FarmerSearchT } from '@services/userApiService/userApiServiceTypes';
import { useCallback } from 'react';
import useSearchListWithBarStyle from './SearchListWithBarView.style';
import { SearchListWithBarViewPropsT } from './SearchListWithBarViewTypes';

const SearchListWithBarView = <T extends FarmerSearchT | FarmSearchT>({
    id,
    placeholder,
    search,
    list,
    loading,
    mode,
    onItemClick,
    query,
}: SearchListWithBarViewPropsT<T>): JSX.Element => {
    const { classes } = useSearchListWithBarStyle();
    const { t } = useTranslation();
    const isReadOnlyUser = useIsReadOnlyUser();

    const getTooltipSlugForDisabledUsers = (farmerData: FarmerSearchT) => {
        if (!farmerData.farms?.length) {
            return 'tooltip.search-farmer.no-farm';
        }

        // deprecated, searchbar will be deleted
        if (isReadOnlyUser) {
            // Self encoding not confirmed by farmer
            if (!farmerData?.installation_validated_at) {
                return 'tooltip.search-farmer.self-encoding-not-confirmed-by-farmer';
            }

            // Operations not finalized by agronomist
            if (!farmerData?.operations_validated_at) {
                return 'tooltip.search-farmer.operations-not-finalized-by-agronomist';
            }

            // Data summary Not validated by farmer
            if (!farmerData?.summary_validated_at) {
                return 'tooltip.search-farmer.data-summary-no-validated-by-farmer';
            }
        }

        return '';
    };

    const getTooltipSlugForFarmInSubscription = useCallback((farm: FarmSearchT): string | null => {
        const StandardPlanId = 1;

        if (!farm.product_plan_id) {
            return 'tooltip.search-farmer.no-plan-selected';
        }

        if (!farm.tac_signed) {
            return 'tooltip.search-farmer.tac-not-signed';
        }

        if (farm.product_plan_id === StandardPlanId && farm.product_plan_status === 'to_choose') {
            return 'tooltip.search-farmer.standard-plan-not-paid-but-can-change';
        }

        if (farm.product_plan_id === StandardPlanId && farm.product_plan_status === 'to_pay') {
            return 'tooltip.search-farmer.standard-plan-to-pay';
        }

        return null;
    }, []);

    const FarmerCardItem = ({ farmer }: { farmer: FarmerSearchT }) => (
        <TooltipSc
            key={farmer.id}
            placement="right"
            translationSlug={getTooltipSlugForDisabledUsers(farmer)}
            display="block"
        >
            <ColorPatternButtonView
                customImage={<Avatar className={classes.avatar} />}
                disabled={!!getTooltipSlugForDisabledUsers(farmer)}
                name={farmer.full_name}
                secondDescription={t('pages.search.farmer.farm-count', {
                    count: farmer.farms?.length || 0,
                })}
                singleLineDescription
                onClick={() => onItemClick(farmer)}
            >
                <Typography>{farmer.email}</Typography>
            </ColorPatternButtonView>
        </TooltipSc>
    );

    const FarmCardItem = ({ farm }: { farm: FarmSearchT }) => (
        <TooltipSc
            placement="right"
            translationSlug={getTooltipSlugForFarmInSubscription(farm)}
            display="block"
            key={farm.id}
        >
            <ColorPatternButtonView
                disabled={!!getTooltipSlugForFarmInSubscription(farm)}
                customImage={<HouseIcon className={classes.svg} />}
                name={farm.name}
                secondDescription={
                    !!getTooltipSlugForFarmInSubscription(farm) ? (
                        <Typography color="warning.dark" fontFamily="BrownStd-Bold" component="span">
                            {t('pages.search.farm.status-in-subscription-flow')}
                        </Typography>
                    ) : !farm.season_id ? (
                        <Typography color="warning.dark" fontFamily="BrownStd-Bold" component="span">
                            {t('pages.search.farm.status-no-season')}
                        </Typography>
                    ) : null
                }
                singleLineDescription
                onClick={() => onItemClick(farm)}
            >
                <Typography>{`${farm.address} (${t(farm.country_slug)})`}</Typography>
            </ColorPatternButtonView>
        </TooltipSc>
    );

    return (
        <div className={classes.container}>
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {loading ? <CircularProgress size="1rem" /> : <SearchIcon />}
                        </InputAdornment>
                    ),
                }}
                className={classes.searchBar}
                id={id}
                placeholder={placeholder}
                value={query || ''}
                variant="outlined"
                autoFocus
                fullWidth
                onChange={(e) => search(e.target.value)}
            />
            <Paper className={classes.listWrapper} variant="outlined">
                <div className={classes.scroll}>
                    {!loading && list?.length > 0 && (
                        <div className={classes.list}>
                            {list?.map((item) => {
                                switch (mode) {
                                    case 'farmer':
                                        return <FarmerCardItem key={item.id} farmer={item as FarmerSearchT} />;
                                    case 'farm':
                                        return <FarmCardItem key={item.id} farm={item as FarmSearchT} />;
                                    default:
                                        return null;
                                }
                            })}
                        </div>
                    )}
                    {loading && (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size="5rem" />
                        </div>
                    )}
                    {list?.length === 0 && !loading && (
                        <div className={classes.loadingContainer}>
                            <Typography variant="h4">{t('pages.search.farmer.not-found')}</Typography>
                        </div>
                    )}
                </div>
            </Paper>
        </div>
    );
};

export default SearchListWithBarView;
