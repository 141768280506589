import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

import useTopBarStyle from './TopBar.style';
import NavLogo from './NavLogo/NavLogo';
import ContextSelector from './ContextSelector/ContextSelector';
import NavProfile from './NavProfile/NavProfile';
import { TopBarViewPropsT } from './TopBarTypes';
import NotificationContainer from './Notifications/NotificationContainer/NotificationContainer';

const TopBarView = ({ displayContextSelector = true, disableNotification }: TopBarViewPropsT): JSX.Element => {
    const { classes } = useTopBarStyle();

    return (
        <AppBar className={classes.root} color="default" position="relative">
            <Toolbar className={classes.toolbar} variant="regular">
                <div className={classes.logoContainer}>
                    <NavLogo />
                </div>
                <Grid alignItems="center" spacing={2} container>
                    <Grid xs={7} item>
                        {!!displayContextSelector && (
                            <div className={classes.farmAndTimeContainer}>
                                <ContextSelector />
                            </div>
                        )}
                    </Grid>
                    <Grid xs={5} item>
                        <div className={classes.navProfileContainer}>
                            <NavProfile />
                            {!disableNotification && <NotificationContainer />}
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default TopBarView;
