import { useRef } from 'react';
import { useAutoAnimate } from '@hooks/useAutoAnimate';
import { Paper, Typography } from '@mui/material';
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';

import { CardNameValueListPropsT, ItemT } from './CardNameValueListTypes';
import useCardNameValueListStyle from './CardNameValueList.style';

/**
 * Display a list of cards name-value as rows.
 * actions can be displayed at the right + a thumbnail
 *
 * usage:
    <CardNameValueList
        getName={(item) => item.name}
        getValue={(item) => item.value}
        nameWidth="45%"
        getActionIcons={(item) => [
            <IconButton
                key="fixed-key"
                icon={<IconEdit />}
                onClick: () => editCb(item)
            />,
        ]}
        items={[
            { id: 1, name: 'field a', value: '88.523 ha' },
            { id: 2, name: 'field b', value: '50 ha' },
        ]}
    />
 */
const CardNameValueList = <ItemG extends ItemT>({
    getName,
    getValue,
    getActionIcons,
    nameWidth,
    getThumbnail,
    items,
    selectedItemId,
    onItemClick,
    header,
    variant,
}: CardNameValueListPropsT<ItemG>): JSX.Element => {
    const animateParent = useRef(null);
    useAutoAnimate(animateParent);

    const { classes } = useCardNameValueListStyle({
        hasThumbnail: !!getThumbnail,
        hasValue: !!getValue,
        nameWidth,
        variant,
    });

    return (
        <div ref={animateParent}>
            {header}
            {items.map((item: ItemG, i) => {
                const name = getName(item, i);
                const value = getValue?.(item, i);
                const thumbnail = getThumbnail?.(item, i);
                const actionIcons = getActionIcons?.(item, i) || [];
                const active = selectedItemId === item.id;

                return (
                    // Card as Row
                    <Paper
                        key={item.id}
                        elevation={active ? 2 : 0}
                        className={`${classes.CardNameValueItem} ${active ? classes.itemSelected : ''} ${
                            onItemClick ? classes.itemClickable : ''
                        }`}
                        onClick={() => onItemClick?.(item)}
                    >
                        <>
                            {/* Name zone */}
                            {typeof name === 'string' ? (
                                <Typography className={classes.name} variant="h6">
                                    {name}
                                </Typography>
                            ) : (
                                name
                            )}

                            {/* Value zone */}
                            {typeof value === 'string' ? (
                                <Typography color="primary" variant="body2">
                                    {value}
                                </Typography>
                            ) : (
                                value
                            )}

                            {/* Action Icons zone */}
                            <div className={classes.actionIcons}>{actionIcons}</div>

                            {/* Thumbnail zone */}
                            {thumbnail && (
                                <div
                                    className={`${classes.thumbnail} ${
                                        thumbnail.onClick ? classes.cursorPointer : classes.cursorDefault
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        thumbnail.onClick?.(item);
                                        return false; // stop click propagation
                                    }}
                                >
                                    <div className={classes.thumbnailLoading}>
                                        <div className="dot-flashing"></div>
                                    </div>
                                    <img src={thumbnail.src} className={classes.thumbnailImage} />
                                    {thumbnail.onClick && <MyLocationTwoToneIcon className={classes.thumbnailIcon} />}
                                </div>
                            )}
                        </>
                    </Paper>
                );
            })}
        </div>
    );
};

export default CardNameValueList;
