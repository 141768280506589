import { OrganicMatterLevelT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const ORGANIC_MATTER_LIST_GET_LOADING = 'ORGANIC_MATTER_LIST_GET_LOADING';
export const ORGANIC_MATTER_LIST_GET_SUCCESS = 'ORGANIC_MATTER_LIST_GET_SUCCESS';
export const ORGANIC_MATTER_LIST_GET_FAILED = 'ORGANIC_MATTER_LIST_GET_FAILED';

export type OrganicMatterListGetSuccessDataT = OrganicMatterLevelT[];

export type OrganicMatterListGetLoadingT = {
    type: typeof ORGANIC_MATTER_LIST_GET_LOADING;
};

export type OrganicMatterListGetSuccessT = {
    type: typeof ORGANIC_MATTER_LIST_GET_SUCCESS;
    payload: OrganicMatterListGetSuccessDataT;
};

export type OrganicMatterListGetFailedT = {
    type: typeof ORGANIC_MATTER_LIST_GET_FAILED;
    error: string;
};

export type OrganicMatterListGetDispatchType =
    | OrganicMatterListGetLoadingT
    | OrganicMatterListGetSuccessT
    | OrganicMatterListGetFailedT;

export type OrganicMatterListGetCallback = (dispatch: Dispatch<OrganicMatterListGetDispatchType>) => Promise<boolean>;
