import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonSocial } from '@components/ButtonFacebook/ButtonSocial';
import useAppDispatch from '@hooks/useAppDispatch';
import authorizeAuth0Action from '@actions/AuthActions/AuthorizeAuth0Actions';
import { ButtonSocialPropsT } from '@components/ButtonFacebook/ButtonSocialTypes';

import useAuth0ButtonsStyle from './Auth0Buttons.style';

const Auth0Buttons = ({ auth0Loading }: { auth0Loading: boolean }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently, loginWithPopup } = useAuth0();
    const [idProviderLoading, setIdProviderLoading] = useState<ButtonSocialPropsT['socialName']>();
    const [lastIdProviderLoading, setLastIdProviderLoading] = useState<ButtonSocialPropsT['socialName']>();
    const { classes } = useAuth0ButtonsStyle();

    const authorize = async (connection: ButtonSocialPropsT['socialName']) => {
        if (idProviderLoading) {
            return;
        }
        setIdProviderLoading(connection);
        setLastIdProviderLoading(connection);
        const popupWidth = 800;
        const popupHeight = 650;
        // center the popup relative to app window (who is relative to screen)
        const popupLeft = window.screenX + (window.outerWidth - popupWidth) / 2;
        const popupTop = window.screenY + (window.outerHeight - popupHeight) / 2;

        const popup = window.open(
            undefined,
            undefined,
            `width=${popupWidth},height=${popupHeight},top=${popupTop},left=${popupLeft}`,
        );

        try {
            await loginWithPopup({ connection, ui_locales: window.location.origin }, { popup });
            const token = await getAccessTokenSilently();
            await dispatch(authorizeAuth0Action({ token }));
        } catch (e) {
            console.error(e);
        }
        setIdProviderLoading(undefined);
    };

    return (
        <>
            <ButtonSocial
                className={classes.mb2}
                loading={(auth0Loading && lastIdProviderLoading === 'facebook') || idProviderLoading === 'facebook'}
                socialName="facebook"
                onClick={() => authorize('facebook')}
            />
            <ButtonSocial
                loading={
                    (auth0Loading && lastIdProviderLoading === 'google-oauth2') || idProviderLoading === 'google-oauth2'
                }
                socialName="google-oauth2"
                onClick={() => authorize('google-oauth2')}
            />
        </>
    );
};

export default Auth0Buttons;
