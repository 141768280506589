import SimpleSelect from '@mapbox/mapbox-gl-draw/src/modes/simple_select';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import DirectSelectWithSnap from './direct_select_with_snap_mode';

const SimpleSelectSingleClick = { ...SimpleSelect };

SimpleSelectSingleClick.onSetup = function (opts) {
    const state = SimpleSelect.onSetup.call(this, opts);
    state.fromSplitting = opts?.fromSplitting ?? false;
    return state;
};

SimpleSelectSingleClick.clickOnFeature = function (state, e) {
    if (e.featureTarget.geometry.type !== Constants.geojsonTypes.POINT) {
        // switch to direct_select mode for polygon/line features
        this.changeMode(Constants.modes.DIRECT_SELECT, {
            featureId: e.featureTarget.properties.id,
        });
    } else {
        // call parent
        SimpleSelect.clickOnFeature.call(this, state, e);

        // prevent multi-selection for consistency with direct_select mode
        this.setSelected(e.featureTarget.properties.id);
    }
};

SimpleSelectSingleClick.toDisplayFeatures = function (state, geojson, display) {
    SimpleSelect.toDisplayFeatures.call(this, state, geojson, display);
    if (state.fromSplitting) {
        geojson.properties.active = Constants.activeStates.INACTIVE;
    } else if (geojson?.properties?.id) {
        this.changeMode(Constants.modes.DIRECT_SELECT, {
            featureId: geojson.properties.id,
        });
        DirectSelectWithSnap.toDisplayFeatures.call(this, state, geojson, display);
    }
};

export default SimpleSelectSingleClick;
