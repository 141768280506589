import { useRef } from 'react';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import CollapsableSectionView from '@components/CollapsableSection/CollapsableSectionView';
import { Collapse, Grid } from '@mui/material';
import { FieldArray, FormikErrors, FormikProvider } from 'formik';

import { AgroforestryViewPropsT } from './AgroforestryViewTypes';
import AddButtonView from '@components/AddButton/AddButtonView';
import AgroforestryCardForm from '@components/AgroforestryCardForm/AgroforestryCardForm';
import { AgroforestryCardFormValuesT } from '@components/AgroforestryCardForm/AgroforestryCardFormTypes';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useTranslation } from 'react-i18next';

const AgroforestryView = ({
    formik,
    previousSeasonTreesMap,
    disabledForm,
    selectedField,
}: AgroforestryViewPropsT): JSX.Element => {
    const { t } = useTranslation();
    const sectionRef = useRef<HTMLDivElement>(null);
    const treesList = useSelector((state: HomePageStateT) => state.static.trees);
    const treesListLoading = useSelector((state: HomePageStateT) => state.static.treesLoading);

    return (
        <CollapsableSectionView
            defaultCollapseState={true}
            helpName="pages.installationtasks.fields-subtasks.agroforestry.select-on-map"
            sectionName={t('pages.installationsubtasks.agroforestry')}
            sectionRef={sectionRef}
        >
            <>
                <LabelCheckboxView
                    disabled={disabledForm}
                    checked={formik.values.agroforestry?.has_agroforestry}
                    error={
                        formik.touched.agroforestry?.has_agroforestry && !!formik.errors.agroforestry?.has_agroforestry
                    }
                    helperText={
                        formik.touched.agroforestry?.has_agroforestry
                            ? formik.errors.agroforestry?.has_agroforestry
                            : undefined
                    }
                    label={t('pages.fields.edit-field.this-field-has-agroforestry')}
                    noMargin
                    onChange={(event) => {
                        formik.setFieldValue('agroforestry.has_agroforestry', event.target.checked);
                        event.target.checked &&
                            setTimeout(() => sectionRef.current?.scrollIntoView({ behavior: 'smooth' }), 400);
                    }}
                />

                <Collapse in={formik.values.agroforestry?.has_agroforestry} timeout="auto" unmountOnExit>
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="agroforestry.trees"
                            render={(arrayHelpers) => (
                                <Grid container spacing={2}>
                                    {formik.values.agroforestry.trees.map((tree, index) => {
                                        const previousSeasonTrees =
                                            previousSeasonTreesMap[tree.previous_farm_season_field_tree_id ?? ''];
                                        const handleClose =
                                            formik.values.agroforestry.trees.length > 1 &&
                                            !tree.previous_farm_season_field_tree_id
                                                ? () => arrayHelpers.remove(index)
                                                : undefined;
                                        const handleValuesChange = (values: AgroforestryCardFormValuesT) => {
                                            if (!values.quantity_per_ha || !selectedField?.area)
                                                return arrayHelpers.replace(index, values);
                                            const newValues = {
                                                ...values,
                                                quantity: Math.floor(
                                                    selectedField?.area * Number(values.quantity_per_ha),
                                                ),
                                            };
                                            arrayHelpers.replace(index, newValues);
                                        };

                                        return (
                                            <Grid key={index} xs={12} xl={4} lg={6} item>
                                                <AgroforestryCardForm
                                                    disabledForm={disabledForm}
                                                    onValuesChange={handleValuesChange}
                                                    onDelete={handleClose}
                                                    previousTreeValues={previousSeasonTrees}
                                                    submittedForm={formik.submitCount > 0}
                                                    treeValues={tree}
                                                    treesList={treesList}
                                                    treesListLoading={treesListLoading}
                                                    errors={
                                                        formik.errors.agroforestry?.trees?.[
                                                            index
                                                        ] as FormikErrors<AgroforestryCardFormValuesT>
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                    <Grid xs={4} marginRight="50%" item>
                                        {!disabledForm && (
                                            <AddButtonView
                                                fullWidth
                                                onClick={() =>
                                                    arrayHelpers.push({
                                                        id: null,
                                                        tree_type_id: '',
                                                        quantity: '',
                                                        diameter: '',
                                                    })
                                                }
                                                label={t('constants.add')}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </FormikProvider>
                </Collapse>
            </>
        </CollapsableSectionView>
    );
};

export default AgroforestryView;
