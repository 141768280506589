import { Prompt } from '@components/Prompt/Prompt';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import MenuBar from '@components/MenuBar/MenuBar';

import { SoilOrganicCarbonTaskViewPropsT } from './SoilOrganicCarbonTaskTypes';
import useSoilOrganicCarbonTaskStyle from './SoilOrganicCarbonTaskView.style';
import SelectStep from './SelectStep/SelectStep';

const SoilOrganicCarbonTaskView = ({
    t,
    currentStep,
    popStep,
    pushStep,
    handleBackButton,
}: SoilOrganicCarbonTaskViewPropsT): JSX.Element => {
    const { classes } = useSoilOrganicCarbonTaskStyle();

    return (
        <div>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={false}
            />
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={1} totalSteps={2} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.fields-subtasks.soil-organic-carbon-task.menu-title')}
                    onArrowBack={currentStep > 1 ? () => handleBackButton() : '..'}
                    onClose=".."
                />
                {currentStep >= 1 && <SelectStep currentStep={currentStep} popStep={popStep} pushStep={pushStep} />}
            </div>
        </div>
    );
};

export default SoilOrganicCarbonTaskView;
