import RequiredInputLabel from '@components/RequiredInputLabel/RequiredInputLabel';
import { TextField } from '@mui/material';

import useLabelTextFieldStyle from './LabelTextField.style';
import { LabelTextFieldPropsT } from './LabelTextField.types';

const LabelTextField = ({
    id,
    label,
    type,
    required,
    fullWidth,
    variant,
    value,
    name,
    onChange,
    disabled,
    error,
    helperText,
    className,
    onKeyPress,
    autoComplete = 'off',
    autoFocus,
    endAdornment,
    withHelp,
    InputProps,
    helpName,
    tooltipSlug,
    onBlur,
    onFocus,
    sx,
    labelFullWidth,
}: LabelTextFieldPropsT): JSX.Element => {
    const { classes } = useLabelTextFieldStyle();

    return (
        <>
            <RequiredInputLabel
                className={labelFullWidth ? classes.labelFullwidth : classes.label}
                helpName={helpName}
                htmlFor={id}
                id={id}
                required={required}
                tooltipSlug={tooltipSlug}
                withHelp={withHelp}
            >
                {label}
            </RequiredInputLabel>
            <TextField
                InputProps={{
                    endAdornment,
                    ...InputProps,
                }}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                className={`${classes.textField} ${className}`}
                disabled={disabled}
                error={error}
                fullWidth={fullWidth}
                helperText={helperText}
                id={id}
                name={name}
                required={required}
                type={type}
                value={value}
                variant={variant}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                onKeyPress={onKeyPress}
                sx={{ ...sx }}
                onWheel={(e) => type === 'number' && (e.target as HTMLInputElement).blur()}
            />
        </>
    );
};

export default LabelTextField;
