import { BillingDetailsDataT } from '@/components/BillingDetailsForm/BillingDetailsForm.types';
export const SAVE_PAYMENT_INFO = 'SAVE_PAYMENT_INFO';

export const savePaymentInfo = (data: BillingDetailsDataT) => ({
    type: SAVE_PAYMENT_INFO,
    payload: data,
});

export type savePaymentInfoDataT = {
    data: BillingDetailsDataT;
};

export type savePaymentInfoSuccessDataT = {
    data: BillingDetailsDataT;
};

export type savePaymentInfoSuccessT = {
    type: typeof SAVE_PAYMENT_INFO;
    payload: BillingDetailsDataT;
};

export type savePaymentInfoActionsT = savePaymentInfoSuccessT;
