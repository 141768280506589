import FarmSeasonFieldApiService from '@services/farmSeasonFieldsApiService/farmSeasonFieldsApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldPostCallBackT,
    PostFarmSeasonFieldDataT,
    PostFarmSeasonFieldDispatchTypesT,
    PostFarmSeasonFieldResponseDataT,
    PostFarmSeasonFieldResponseErrorT,
    // PostFarmSeasonFieldResponseErrorT,
    POST_FARM_SEASON_FIELD_FAILED,
    POST_FARM_SEASON_FIELD_LOADING,
    POST_FARM_SEASON_FIELD_SUCCESS,
} from './PostFarmSeasonFieldTypes';

export const postFarmSeasonField =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldDataT,
        hasPermanentCrops = false,
    ): FarmSeasonFieldPostCallBackT =>
    async (dispatch: Dispatch<PostFarmSeasonFieldDispatchTypesT>): Promise<boolean> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELD_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldResponseDataT> = await FarmSeasonFieldApiService.save(
                farmId,
                farmSeasonId,
                body,
                hasPermanentCrops,
            );

            dispatch({
                type: POST_FARM_SEASON_FIELD_SUCCESS,
                payload: res.data,
            });

            return true;
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELD_FAILED,
                error: error as PostFarmSeasonFieldResponseErrorT,
            });

            return false;
        }
    };

export const placeholder = '';
