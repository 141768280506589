/* eslint-disable @typescript-eslint/no-explicit-any */
import { useModal } from '@soil-capital/ui-kit';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Context } from '../OrganicFertilizationTaskContext/OrganicFertilizationTaskContext';
import { ContextT } from '../OrganicFertilizationTaskContext/OrganicFertilizationTaskContextTypes';

import { FieldToDeassociateT } from './SelectFieldsTypes';
import SelectFieldsView from './SelectFieldsView';

type SelectFieldsProps = {
    canEdit?: boolean;
};

const SelectFields = ({ canEdit = false }: SelectFieldsProps): JSX.Element => {
    const {
        groupedFields,
        setGroupedFields,
        selectedKey,
        setSelectedKey,
        selectedGroup,
        groupedFieldIndex,
        t,
        formik,
        farmSeasonFields,
        setCurrentStep,
        setNothingToSave,
        nothingToSave,
        setSelectedGroup,
        setNothingOnFields,
        setErrorNothingOnFields,
    } = useContext<ContextT>(Context);

    const [newSelectedGroup, setNewSelectedGroup] = useState<FarmSeasonFieldT[]>(selectedGroup);
    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });
    const modalAlreadyAssociated = useModal();
    const [fieldsToDeassociate, setFieldsToDeassociate] = useState<FieldToDeassociateT[]>([]);

    // Making the map selectable and disable selection once this component is unmount
    useEffect(() => {
        if (!canEdit) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }
        MapService.selectablePolygons({ selectMode: true });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
        };
    }, []);

    // init map selected field
    useEffect(() => {
        MapService.updateSelectedPolygons({
            selectedPolygons: selectedGroup.map((field) => field.polygon),
        });
    }, []);

    // Add field to the group
    const addFieldToGroup = (field: FarmSeasonFieldT) => {
        setNewSelectedGroup((state) => [
            ...state,
            {
                ...field,
                organic_fertilization_history: {
                    year_introduction: formik.values.year_introduction,
                    frequency: +formik.values.frequency,
                    farm_season_field_id: field.id,
                },
            },
        ]);
    };

    const removeFieldFromGroup = (field: FarmSeasonFieldT) => {
        const fieldIndex = newSelectedGroup.findIndex((fieldInGroup) => fieldInGroup.id === field.id);
        const fieldIndexDeassociate = fieldsToDeassociate.findIndex((fieldRef) => fieldRef.field.id === field.id);

        if (fieldIndex > -1) {
            const newArray = [...newSelectedGroup];
            newArray.splice(fieldIndex, 1);
            setNewSelectedGroup(newArray);
        }

        if (fieldIndexDeassociate > -1) {
            const newArray = [...fieldsToDeassociate];
            newArray.splice(fieldIndexDeassociate, 1);
            setFieldsToDeassociate(newArray);
        }
    };

    // init listener to the lastClicked polygon
    useEffect(() => {
        if (thisMap.lastClickedPolygon) {
            const currentField = farmSeasonFields.find(
                (field) => field.id === Number(thisMap.lastClickedPolygon?.clickedPolygon.id),
            );

            if (!currentField) {
                return undefined;
            }

            if (thisMap.lastClickedPolygon.selected) {
                let fieldFreeToAssociate = true;
                let keyAssociated = '';

                // Check if it doesn't belong to another group
                Object.keys(groupedFields).forEach((key) => {
                    if (key !== selectedKey) {
                        if (groupedFields[key].find((field) => field.id === currentField?.id)) {
                            keyAssociated = key;
                            fieldFreeToAssociate = false;
                        }
                    }
                });

                if (fieldFreeToAssociate) {
                    addFieldToGroup(currentField);
                } else {
                    // open modal to inform and confirm.
                    modalAlreadyAssociated.openModal(
                        () => {
                            setFieldsToDeassociate([
                                ...fieldsToDeassociate,
                                { key: keyAssociated, field: currentField },
                            ]);
                            addFieldToGroup(currentField);
                        },
                        () => {
                            MapService.updateSelectedPolygons({
                                selectedPolygons:
                                    thisMap.selectedPolygons?.filter((polygon) => +polygon.id !== currentField.id) ||
                                    [],
                            });
                        },
                    );
                }
            } else {
                removeFieldFromGroup(currentField);
            }
        }

        return () => {
            MapService.removeClickedPolygon({});
        };
    }, [thisMap.lastClickedPolygon]);

    // Delete one fields from selection
    const selectedFieldDelete = (id: any) => {
        const currentField = farmSeasonFields.find((field) => field.id === id);

        if (!currentField) {
            return;
        }
        removeFieldFromGroup(currentField);
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    // confirm grouped field
    const confirmGroupedFields = () => {
        const newGroupedFields = { ...groupedFields };
        delete newGroupedFields[selectedKey];
        newGroupedFields[`${formik.values.year_introduction}-${formik.values.frequency}`] = newSelectedGroup;
        fieldsToDeassociate.forEach((fieldRef) => {
            const fieldIndex = groupedFields[fieldRef.key].findIndex((field) => field.id === fieldRef.field.id);

            if (fieldIndex > -1) {
                const newArray = [...groupedFields[fieldRef.key]];
                newArray.splice(fieldIndex, 1);
                delete newGroupedFields[fieldRef.key];

                if (newArray.length > 0) {
                    newGroupedFields[fieldRef.key] = newArray;
                }
            }
        });
        setNothingToSave(false);
        setGroupedFields(newGroupedFields);
        setSelectedKey('');
        setSelectedGroup([]);
        formik.resetForm();
        setNothingOnFields(false);
        setErrorNothingOnFields(false);
        setCurrentStep(1);
    };

    const selectRemainingFields = () => {
        const fieldsInGroup: FarmSeasonFieldT[] = [];
        Object.keys(groupedFields).forEach((key) => {
            groupedFields[key].forEach((field) => {
                fieldsInGroup.push(field);
            });
        });

        const remainingFields = farmSeasonFields
            .filter((field) => !field.field_crops?.some((field_crop) => field_crop.farm_season_crop.crop.is_permanent))
            .filter((field) => !fieldsInGroup.some((fieldInGroup) => field.id === fieldInGroup.id))
            .filter((field) => !newSelectedGroup.some((fieldSelected) => field.id === fieldSelected.id));

        remainingFields.forEach((field) => {
            addFieldToGroup(field);
        });
        MapService.updateSelectedPolygons({
            selectedPolygons: [...(thisMap.selectedPolygons || []), ...remainingFields.map((field) => field.polygon)],
        });
    };

    return (
        <SelectFieldsView
            confirmGroupedFields={confirmGroupedFields}
            modalAlreadyAssociated={modalAlreadyAssociated}
            formik={formik}
            groupedFieldIndex={groupedFieldIndex}
            newSelectedGroup={newSelectedGroup}
            nothingToSave={nothingToSave}
            selectRemainingFields={selectRemainingFields}
            selectedFieldDelete={selectedFieldDelete}
            t={t}
            canEdit={canEdit}
        />
    );
};

export default SelectFields;
