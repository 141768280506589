import FarmSeasonFieldsCropsApiService from '@services/farmSeasonFieldsCropsApiService/farmSeasonFieldsCropsApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsCropsPostCallBackT,
    PostFarmSeasonFieldsCropsDataT,
    PostFarmSeasonFieldsCropsDispatchTypesT,
    PostFarmSeasonFieldsCropsResponseDataT,
    PostFarmSeasonFieldsCropsResponseErrorT,
    POST_FARM_SEASON_FIELDS_CROPS_FAILED,
    POST_FARM_SEASON_FIELDS_CROPS_LOADING,
    POST_FARM_SEASON_FIELDS_CROPS_SUCCESS,
} from './PostFarmSeasonFieldsCropsTypes';

export const postFarmSeasonFieldsCrops =
    (farmId: number, farmSeasonId: number, body: PostFarmSeasonFieldsCropsDataT): FarmSeasonFieldsCropsPostCallBackT =>
    async (dispatch: Dispatch<PostFarmSeasonFieldsCropsDispatchTypesT>): Promise<boolean> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_CROPS_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsCropsResponseDataT> =
                await FarmSeasonFieldsCropsApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_CROPS_SUCCESS,
                payload: res.data,
            });

            return true;
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_CROPS_FAILED,
                error: error as PostFarmSeasonFieldsCropsResponseErrorT,
            });

            return false;
        }
    };

export const placeholder = '';
