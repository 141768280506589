import { makeStyles } from 'tss-react/mui';

// TODO change title, subTitle and text with theme when typography is consistants
const useMobileHardstopPageStyle = makeStyles()((theme) => ({
    screenContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },

    textContainer: {
        padding: `${theme.spacing(8)}  ${theme.spacing(6)}`,
        textAlign: 'center',
    },

    title: {
        fontWeight: 600,
        fontSize: '1.4rem',
        marginTop: '3rem',
        marginBottom: '2rem',
    },

    subTitle: {
        fontWeight: 600,
        fontSize: '1.1rem',
        lineHeight: 1.5,
        marginBottom: '1.5rem',
    },

    text: {
        fontWeight: 300,
        fontSize: '1.1rem',
        marginBottom: '1rem',
    },

    footerContainer: {
        minHeight: 0,
        flexGrow: 1,
        position: 'relative',
    },

    footerImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'bottom',
    },

    buttonRedirectContainer: {
        position: 'absolute',
        bottom: 0,
        padding: theme.spacing(8),
        boxSizing: 'border-box',
        width: '100%',
    },

    buttonGreen: {
        backgroundColor: theme.palette.shamrock.dark,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.shamrock.dark,
        },
    },
}));

export default useMobileHardstopPageStyle;
