import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    CropsGetDispatchTypes,
    CropsGetFailedDataT,
    CropsGetSuccessDataT,
    CROPS_GET_FAILED,
    CROPS_GET_LOADING,
    CROPS_GET_SUCCESS,
} from './CropsGetActionsTypes';

const cropsGet =
    (country_id: number) =>
    async (dispatch: Dispatch<CropsGetDispatchTypes>): Promise<CropsGetFailedDataT> => {
        try {
            dispatch({ type: CROPS_GET_LOADING });

            const localStorageKey = `crops${country_id}`;
            const crops = localStorage.getItem(localStorageKey);

            if (!crops) {
                const res: AxiosResponse<CropsGetSuccessDataT> = await StaticApiService.getCrops(country_id);

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));
                dispatch({
                    type: CROPS_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: CROPS_GET_SUCCESS,
                    payload: JSON.parse(crops),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: CROPS_GET_FAILED,
                error: error as CropsGetFailedDataT,
            });

            return {};
        }
    };

export default cropsGet;
