import { AxiosResponse } from 'axios';
import request from '@services/apiService/apiService';

import { GetUserCompanyResponseT, GetUserCompanyDataT, PutUserCompanyDataT } from './userCompanyApiServiceTypes';

class userCompanyApiService {
    public static getFirst = async ({ userId }: GetUserCompanyDataT): Promise<AxiosResponse<GetUserCompanyResponseT>> =>
        request({
            url: `/users/${userId}/company`,
            method: 'GET',
        });

    public static update = async ({
        userId,
        companyId,
        data,
    }: PutUserCompanyDataT): Promise<AxiosResponse<GetUserCompanyResponseT>> =>
        request({
            url: `/users/${userId}/company/${companyId}`,
            method: 'PUT',
            data,
        });
}

export default userCompanyApiService;
