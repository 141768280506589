import { FERTILISERS_CREATE_SUCCESS } from '@actions/StaticActions/FertilisersCreateActionTypes';
import {
    FERTILISERS_GET_FAILED,
    FERTILISERS_GET_LOADING,
    FERTILISERS_GET_SUCCESS,
} from '@actions/StaticActions/FertilisersGetActionTypes';
import {
    ORGANIC_FERTILIZATION_LIST_GET_FAILED,
    ORGANIC_FERTILIZATION_LIST_GET_LOADING,
    ORGANIC_FERTILIZATION_LIST_GET_SUCCESS,
} from '@actions/StaticActions/OrganicFertilizationGetListActionTypes';
import { AppActionsT } from '@actions/AppActions/AppActionsTypes';
import { RESET_OPERATION_FERTILISERS } from '@actions/FarmSeasonOperation/FarmSeasonOperationActionsTypes';
import { Reducer } from '@reduxjs/toolkit';
import { RESET } from '../RootTypes';

import { FertiliserReducerStateT } from './FertiliserReducerTypes';

export const defaultFertiliserState: FertiliserReducerStateT = {
    fertilisersList: [],
    fertilisersLoading: false,
    organicFertilizationList: [],
    organicFertilizationListLoading: false,
};

const fertiliserReducer: Reducer<FertiliserReducerStateT, AppActionsT> = (state, action): FertiliserReducerStateT => {
    if (!state) {
        return defaultFertiliserState;
    }

    switch (action.type) {
        case ORGANIC_FERTILIZATION_LIST_GET_LOADING:
            return { ...state, organicFertilizationListLoading: true };
        case ORGANIC_FERTILIZATION_LIST_GET_SUCCESS:
            return { ...state, organicFertilizationList: action.payload, organicFertilizationListLoading: false };
        case ORGANIC_FERTILIZATION_LIST_GET_FAILED:
            return { ...state, organicFertilizationListLoading: false };
        case FERTILISERS_GET_SUCCESS:
            return { ...state, fertilisersList: action.payload, fertilisersLoading: false };
        case FERTILISERS_GET_FAILED:
            return { ...state, fertilisersLoading: false };
        case FERTILISERS_GET_LOADING:
            return { ...state, fertilisersLoading: true };
        case FERTILISERS_CREATE_SUCCESS:
            return { ...state, fertilisersList: [...state.fertilisersList, action.payload] };
        case RESET_OPERATION_FERTILISERS:
        case RESET:
            return { ...state, ...defaultFertiliserState };
        default:
            return state;
    }
};

export default fertiliserReducer;
