/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import postFarmSeasonFieldAgroforestryValues from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldAgroforestryValues';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAppDispatch from '@hooks/useAppDispatch';
import { PostFarmSeasonFieldAgroforestryValuesDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldAgroforestryValuesTypes';

import { AgroforestryContext } from '../AgroforestryTaskContext/AgroforestryTaskContext';
import { AgroforestryContextT } from '../AgroforestryTaskContext/AgroforestryTaskContextType';

import AgroforestryValuesFormView from './AgroforestryValuesFormView';
import { AgroforestryDataT } from './AgroforestryValuesFormTypes';

import {
    useAgroforestryCardFormValidations,
    useAgroforestryCardFormValues,
} from '@components/AgroforestryCardForm/AgroforestryCardForm.hooks';

const AgroforestryValuesForm = (): JSX.Element => {
    const { fieldsList, farmSeasonId, farmId, fieldLoading, pushStep, t, treesList, treesListLoading, selectedField } =
        useContext<AgroforestryContextT>(AgroforestryContext);

    const dispatch = useAppDispatch();

    const { agroforestryCardFormValues, previousSeasonTreesMap, getAgroforestryValuesToSubmit } =
        useAgroforestryCardFormValues({
            field: selectedField ?? null,
        });
    const { agroforestryCardFormValidation } = useAgroforestryCardFormValidations({ previousSeasonTreesMap });

    const agroforestryValidationSchema = Yup.object({
        agroforestryValues: Yup.array().of(agroforestryCardFormValidation),
    });

    const formik = useFormik<AgroforestryDataT>({
        initialValues: {
            agroforestryValues: agroforestryCardFormValues,
        },
        validationSchema: agroforestryValidationSchema,
        enableReinitialize: true,

        onSubmit: async (values: AgroforestryDataT) => {
            const agroforestryValues: PostFarmSeasonFieldAgroforestryValuesDataT = {
                agroforestryValues: getAgroforestryValuesToSubmit(values.agroforestryValues),
            };

            if (!selectedField?.id) {
                throw Error('selectedField.id is required to call "postFarmSeasonFieldAgroforestryValues"');
            }

            await dispatch(
                postFarmSeasonFieldAgroforestryValues(
                    farmId,
                    farmSeasonId,
                    selectedField.id,
                    agroforestryValues,
                    fieldsList,
                ),
            );
            pushStep(2);
        },
    });

    return (
        <AgroforestryValuesFormView
            fieldLoading={fieldLoading}
            formik={formik}
            selectedField={selectedField}
            t={t}
            treesList={treesList}
            treesListLoading={treesListLoading}
            previousSeasonTreesMap={previousSeasonTreesMap}
        />
    );
};

export default AgroforestryValuesForm;
