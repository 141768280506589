import { ProgressHeaderPropsT } from '@components/ProgressHeader/ProgressHeaderTypes';
import ProgressHeaderView from '@components/ProgressHeader/ProgressHeaderView';

const ProgressHeader = ({
    percentage,
    image,
    progressTitle,
    withProgress,
    withMenu,
    menuTitle,
    onArrowBack,
    onClose,
    className,
    withAnimation,
}: ProgressHeaderPropsT): JSX.Element => (
    <ProgressHeaderView
        className={className}
        image={image}
        menuTitle={menuTitle}
        percentage={percentage}
        progressTitle={progressTitle}
        withAnimation={withAnimation}
        withMenu={withMenu}
        withProgress={withProgress}
        onArrowBack={onArrowBack}
        onClose={onClose}
    />
);

export default ProgressHeader;
