import { cursors, geojsonTypes, events, modes } from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
// import { createSnapList, snap, snapOptions } from '@components/Map/draw-modes/utils/snap';
import { createSnapList, snap, snapOptions } from './utils/snap';
import { polygonCut } from '@components/Map/draw-modes/utils/split.js';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import booleanDisjoint from '@turf/boolean-disjoint';
import area from '@turf/area';

const SplitPolygonMode = {};

SplitPolygonMode.onSetup = function (opts) {
    // create empty line
    const line = this.newFeature({
        type: geojsonTypes.FEATURE,
        properties: {},
        geometry: {
            type: geojsonTypes.LINE_STRING,
            coordinates: [[]],
        },
    });
    this.addFeature(line);

    // clear selected features
    const selectedFeatures = this.getSelected();
    this.clearSelectedFeatures();
    doubleClickZoom.disable(this);

    // init state
    const state = {
        map: this.map,
        options: this._ctx.options,
        line,
        polygonsLayer: opts?.polygonsLayer ?? this.map.getSource('polygonsLayer')?._options?.data?.features ?? [],
        selectedFeatures,
        currentVertexPosition: 0,
    };

    // snapping options (snapping off currently, cfr. comment at FP-3078)
    state.options.snap = false; // snapOptions.SNAP
    state.options.snapOptions = {
        snapPx: snapOptions.SNAP_PX,
        snapToMidPoints: snapOptions.SNAP_TO_MID_POINTS,
        snapVertexPriorityDistance: snapOptions.SNAP_VERTEX_PRIORITY_DISTANCE,
    };

    // create a snap list
    state.snapList = createSnapList(this.map, state.polygonsLayer, line);

    // callbacks
    const moveEndCallback = () => {
        state.snapList = createSnapList(this.map, state.polygonsLayer, line);
    };
    const optionsChangedCallBack = (newOptions) => {
        state.options = newOptions;
    };
    state['moveEndCallback'] = moveEndCallback;
    state['optionsChangedCallBack'] = optionsChangedCallBack;
    this.map.on('moveend', moveEndCallback);
    this.map.on('draw.snap.options_changed', optionsChangedCallBack);

    return state;
};

SplitPolygonMode.finalizeCuttingLine = function (state) {
    const cuttingLineString = state.line;
    this.deleteFeature(cuttingLineString.id);

    let newPolygons = [];
    state.polygonsLayer.forEach((el) => {
        if (
            !el?.properties?.is_permanent &&
            !el?.properties?.previous_has_agroforestry &&
            !booleanDisjoint(el, cuttingLineString)
        ) {
            const polycut = polygonCut(el.geometry, cuttingLineString);
            if (polycut.length) {
                polycut.forEach((v, i) => {
                    const calculatedArea = area(v);
                    const newPolygon = this.newFeature({
                        id: new Date().valueOf() + Math.floor(Math.random() * (1000 - i + 1) + i),
                        type: geojsonTypes.FEATURE,
                        properties: {
                            label: `${el.properties.label} ${i + 1}`,
                            area: calculatedArea / 10000,
                            area_source: 'drawn',
                            originalFeatureId: el.id,
                        },
                        geometry: v.geometry,
                    });
                    this.addFeature(newPolygon);
                    newPolygons.push(newPolygon);
                });
            }
        }
    });

    if (newPolygons.length) {
        this.fireUpdate(newPolygons);
    } else {
        // reset the cutting line
        const line = this.newFeature({
            type: geojsonTypes.FEATURE,
            properties: {},
            geometry: {
                type: geojsonTypes.LINE_STRING,
                coordinates: [[]],
            },
        });
        this.addFeature(line);
        state.line = line;
        state.currentVertexPosition = 0;
    }
};

SplitPolygonMode.fireUpdate = function (newF) {
    this.map.fire(events.UPDATE, {
        action: 'SplitPolygon',
        features: newF,
    });
    this.changeMode(modes.SIMPLE_SELECT, { fromSplitting: true });
};

SplitPolygonMode.onClick = function (state, e) {
    // We save some processing by rounding on click, not mousemove
    const lng = state.snappedLng;
    const lat = state.snappedLat;

    // End the drawing if this click is on the previous position
    if (state.currentVertexPosition > 0) {
        const lastVertex = state.line.coordinates[state.currentVertexPosition - 1];
        state.lastVertex = lastVertex;
        if (lastVertex[0] === lng && lastVertex[1] === lat) {
            this.finalizeCuttingLine(state);
        }
    }

    state.line.updateCoordinate(`${state.currentVertexPosition}`, lng, lat);
    state.currentVertexPosition++;
    state.line.updateCoordinate(`${state.currentVertexPosition}`, lng, lat);
};

SplitPolygonMode.onMouseMove = function (state, e) {
    // SNAP!
    const { lng, lat } = snap(state, e);
    state.line.updateCoordinate(state.currentVertexPosition, lng, lat);
    state.snappedLng = lng;
    state.snappedLat = lat;

    if (state.lastVertex && state.lastVertex[0] === lng && state.lastVertex[1] === lat) {
        this.updateUIClasses({ mouse: cursors.POINTER });
    } else {
        this.updateUIClasses({ mouse: cursors.ADD });
    }
};

SplitPolygonMode.onStop = function (state) {
    // remove callbacks
    this.map.off('moveend', state.moveEndCallback);
    this.map.off('draw.snap.options_changed', state.optionsChangedCallBack);

    this.updateUIClasses({ mouse: Constants.cursors.NONE });
    doubleClickZoom.enable(this);
    this.activateUIButton();

    // check to see if we've deleted this feature
    if (this.getFeature(state.line.id) === undefined) return;

    // remove last added coordinate
    state.line.removeCoordinate(`${state.currentVertexPosition}`);

    if (state.line.isValid()) {
        this.map.fire(Constants.events.CREATE, {
            features: [state.line.toGeoJSON()],
        });
    } else {
        this.deleteFeature([state.line.id], { silent: true });
        this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
    }
};

SplitPolygonMode.toDisplayFeatures = function (state, geojson, display) {
    // Active polygon?
    const isActivePolygon = geojson.properties.id === state.line.id;
    geojson.properties.active = isActivePolygon ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;

    // Don't render a polygon until it has two positions
    // (and a 3rd which is just the first repeated)
    if (geojson.geometry.coordinates.length === 0) return;

    // First coordinates array length
    const coordinateCount = geojson.geometry.coordinates[0].length;

    // Show the starting point
    if (coordinateCount && geojson.geometry.coordinates[0]) {
        geojson.properties.meta = Constants.meta.FEATURE;
        display(createVertex(state.line.id, geojson.geometry.coordinates[0], '0', false));
    } else {
        return;
    }

    // render the line
    if (coordinateCount <= 1) return;
    return display(geojson);
};

export default SplitPolygonMode;
