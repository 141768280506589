import { useTranslation } from 'react-i18next';
import { PaymentStatusT } from '../PaymentStatusIcon/PaymentStatusIconTypes';

const usePaymentDoneCardLogic = () => {
    const { t } = useTranslation();

    /* ----------------------------- textes ----------------------------- */
    const defaultTitle: Record<PaymentStatusT, string> = {
        canceled: t('pages.subscription.payment-done.title-canceled'),
        done: t('pages.subscription.payment-done.title-done'),
        success: t('pages.subscription.payment-done.title-done'),
        saving: t('pages.subscription.payment-done.title-in-progress'),
    };
    /* -------------------------------------------------------------------------- */

    return { defaultTitle };
};

export default usePaymentDoneCardLogic;
