import { makeStyles } from 'tss-react/mui';

const useSideBarNavStyle = makeStyles()((theme) => ({
    list: {
        padding: '0',
    },
    navLinkActive: {
        borderRadius: '10px 0 0 10px',
        backgroundColor: theme.palette.spanishWhite.light,
    },
    navLink: {
        color: theme.palette.primary.light,
        textDecoration: 'none',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderRadius: '10px 0 0 10px',
    },
    listItemText: {
        overflow: 'hidden',
        fontSize: '16px',
    },
    listItemIcon: {
        fontSize: '20px',
        color: theme.palette.primary.light,
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    errorIcon: {
        fontSize: '20px',
        color: theme.palette.primary.main,
        margin: 'auto auto auto 5px',
    },
}));

export default useSideBarNavStyle;
