import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentDoneCard from '@components/PaymentDoneCard/PaymentDoneCard';

import useModalPaymentDoneStyle from './ModalPaymentDone.style';
import { ModalPaymentDonePrposT } from './ModalPaymentDoneTypes';
import useModalPaymentDoneLogic from './ModalPaymentDone.logic';

const ModalPaymentDone = ({ modalPaymentController }: ModalPaymentDonePrposT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useModalPaymentDoneStyle();
    const { paymentStatus, controller, text, mainButtonText, title } = useModalPaymentDoneLogic({
        modalPaymentController,
    });

    return (
        <Modal open={controller.isModalOpen} disableScrollLock>
            <div className={classes.modalContent}>
                <PaymentDoneCard
                    overrides={{
                        EndWrapper: ({ $paymentStatus }) => ({
                            secondaryButtonText: $paymentStatus === 'canceled' ? t('constants.cancel') : undefined,
                            handleSecondaryButton: () => $paymentStatus === 'canceled' && controller.onModalCancel(),
                            handleMainButton: () => {
                                // MSC-1903
                                // handleNextClicked();
                                controller.onModalCancel();
                            },
                            mainButtonText: mainButtonText[paymentStatus],
                            text: text[paymentStatus],
                            title: title[paymentStatus],
                        }),
                    }}
                    paymentStatus={paymentStatus}
                />
            </div>
        </Modal>
    );
};

export default ModalPaymentDone;
