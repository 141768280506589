import { geoJsonFeatureT } from '@components/Map/MapTypes';
import { FarmSeasonFieldCropDataT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_LOADING = 'POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_LOADING';
export const POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_FAILED = 'POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_FAILED';
export const POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS = 'POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS';

export type FarmSeasonFieldsSoilAcidityT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    polygon: geoJsonFeatureT;
    tillage_id?: number | null;
};

export type PostFarmSeasonFieldsSoilAcidityDataT = {
    fields: FarmSeasonFieldsSoilAcidityT[];
};

export type PostFarmSeasonFieldsSoilAcidityResponseDataT = {
    fields: FarmSeasonFieldsSoilAcidityT[];
    installation: {
        onboarding: {
            finished: string[];
            tillage: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsSoilAcidityPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsSoilAcidityDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsSoilAcidityResponseErrorT>;

export type PostFarmSeasonFieldsSoilAcidityResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsSoilAcidityLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_LOADING;
};

export type PostFarmSeasonFieldsSoilAcidityFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_FAILED;
    error: PostFarmSeasonFieldsSoilAcidityResponseErrorT;
};

export type PostFarmSeasonFieldsSoilAciditySuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS;
    payload: PostFarmSeasonFieldsSoilAcidityResponseDataT;
};

export type PostFarmSeasonFieldsSoilAcidityDispatchTypesT =
    | PostFarmSeasonFieldsSoilAcidityLoadingT
    | PostFarmSeasonFieldsSoilAcidityFailedT
    | PostFarmSeasonFieldsSoilAciditySuccessT;
