import HistoryTasksRouterConfig from '@routers/HistorySubtasksRouter/HistorySubtasksRouterConfig';
import { Route, Navigate } from 'react-router-dom';
import AppRoutes from '@components/AppRoutes/AppRoutes';

const HistorySubtasksRouter = (): JSX.Element => (
    <AppRoutes>
        {HistoryTasksRouterConfig.map((item) => (
            <Route key={item.path} element={item.component} path={item.path} />
        ))}
        <Route element={<Navigate to="/practice-history" replace />} />
    </AppRoutes>
);

export default HistorySubtasksRouter;
