import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

export const FARM_SEASON_VALIDATE_LOADING = 'FARM_SEASON_VALIDATE_LOADING';
export const FARM_SEASON_VALIDATE_SUCCESS = 'FARM_SEASON_VALIDATE_SUCCESS';
export const FARM_SEASON_VALIDATE_FAILED = 'FARM_SEASON_VALIDATE_FAILED';

export type FarmSeasonValidateFailedDataT = {
    message?: string;
};

export type FarmSeasonValidateSuccessDataT = FarmSeasonT;

export type FarmSeasonValidateLoadingT = {
    type: typeof FARM_SEASON_VALIDATE_LOADING;
};
export type FarmSeasonValidateSuccessT = {
    type: typeof FARM_SEASON_VALIDATE_SUCCESS;
    payload: FarmSeasonValidateSuccessDataT;
};

export type FarmSeasonValidateFailedT = {
    type: typeof FARM_SEASON_VALIDATE_FAILED;
    error: FarmSeasonValidateFailedDataT;
};

export type FarmSeasonValidateDispatchTypes =
    | FarmSeasonValidateLoadingT
    | FarmSeasonValidateSuccessT
    | FarmSeasonValidateFailedT;
