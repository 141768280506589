// External Import
import { Grid } from '@mui/material';

// Internal Import
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';
import { useTranslation } from 'react-i18next';
import useCountries from '@hooks/useCountries';
import useBillingDetailsFormLogic from './BillingDetailsForm.logic';
import { BillingDetailsFormPropsT, BillingDetailsFormRefControllerT } from './BillingDetailsForm.types';
import { forwardRef, useEffect } from 'react';
import useBillingDetailsFormStyle from './BillingDetailsForm.style';
import { useState } from 'react';

/**
 * This component provides a `Pure` JSX form that can be used to manipulate Billing Details called "UserCompany". \
 * By default the component does not include any submit button. \
 * You can achieve submitting via the {@link ref} which is a required property
 * ForwardRef is used here to allow the remounting of ref such that we can have access to current.submit() after the form is mounted
 * ForwardRef is used in conjuction with useImperativeHandle in {@link useBillingDetailsFormLogic}
 * @example
 * const formRef = useRef(null);
 *
 * <BillingDetailForm userCompany={{id, name, address, tax_id, postal_code, city, country_id}} ref={formRef} />
 *
 * formRef.current?.getFormData();
 */
const BillingDetailsForm = forwardRef<BillingDetailsFormRefControllerT, BillingDetailsFormPropsT>(
    ({ userCompany, withUseFarmDataCheckbox }, ref) => {
        const { t } = useTranslation();
        const { classes } = useBillingDetailsFormStyle();
        const { countries } = useCountries();
        const { billingDetailsFormik, serverError, useFarmAddress } = useBillingDetailsFormLogic({
            billingDetailFormRef: ref,
            userCompany,
        });
        const [showSiretField, setShowSiretField] = useState(false);

        useEffect(() => {
            if (billingDetailsFormik.values['country_id'] === 1) {
                setShowSiretField(true);
            } else {
                setShowSiretField(false);
            }
        }, [billingDetailsFormik.values['country_id']]);

        return (
            <Grid spacing={2} container>
                {withUseFarmDataCheckbox && (
                    <>
                        <div className={classes.checkboxContainer}>
                            <LabelCheckboxView
                                containerClass={classes.checkbox}
                                label={<>{t('pages.subscribe.payment.copy-farm-address')}</>}
                                noMargin
                                required
                                onChange={(e, checked) => useFarmAddress(checked)}
                            />
                        </div>
                        <div className="flex-break" />
                    </>
                )}
                <Grid xs={6} item>
                    <LabelTextField
                        autoFocus
                        label={t('component.payment-detail-form.company_name')}
                        name={'name'}
                        error={billingDetailsFormik.touched['name'] && !!billingDetailsFormik.errors['name']}
                        helperText={
                            billingDetailsFormik.touched['name'] && t(billingDetailsFormik.errors['name'] as string)
                        }
                        value={billingDetailsFormik.values['name']}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => billingDetailsFormik.handleChange(e)}
                    />
                </Grid>
                <Grid xs={6} item>
                    <LabelTextField
                        label={t('component.payment-detail-form.vat')}
                        name={'tax_id'}
                        helpName="tax-number-help"
                        error={billingDetailsFormik.touched['tax_id'] && !!billingDetailsFormik.errors['tax_id']}
                        helperText={
                            billingDetailsFormik.touched['tax_id'] && t(billingDetailsFormik.errors['tax_id'] as string)
                        }
                        value={billingDetailsFormik.values['tax_id']}
                        variant="outlined"
                        withHelp
                        fullWidth
                        required
                        onChange={(e) => billingDetailsFormik.handleChange(e)}
                    />
                </Grid>
                <Grid xs={6} item>
                    <LabelTextField
                        label={t('component.payment-detail-form.address')}
                        name={'address'}
                        error={billingDetailsFormik.touched['address'] && !!billingDetailsFormik.errors['address']}
                        helperText={
                            billingDetailsFormik.touched['address'] &&
                            t(billingDetailsFormik.errors['address'] as string)
                        }
                        value={billingDetailsFormik.values['address']}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => billingDetailsFormik.handleChange(e)}
                    />
                </Grid>
                <Grid xs={6} item>
                    <LabelTextField
                        label={t('component.payment-detail-form.postal-code')}
                        name={'postal_code'}
                        error={
                            billingDetailsFormik.touched['postal_code'] && !!billingDetailsFormik.errors['postal_code']
                        }
                        helperText={
                            billingDetailsFormik.touched['postal_code'] &&
                            t(billingDetailsFormik.errors['postal_code'] as string)
                        }
                        value={billingDetailsFormik.values['postal_code']}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => billingDetailsFormik.handleChange(e)}
                    />
                </Grid>
                <Grid xs={6} item>
                    <LabelTextField
                        label={t('component.payment-detail-form.city')}
                        name={'city'}
                        error={billingDetailsFormik.touched['city'] && !!billingDetailsFormik.errors['city']}
                        helperText={
                            billingDetailsFormik.touched['city'] && t(billingDetailsFormik.errors['city'] as string)
                        }
                        value={billingDetailsFormik.values['city']}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => billingDetailsFormik.handleChange(e)}
                    />
                </Grid>
                <Grid xs={6} item>
                    <LabelAutocomplete
                        error={
                            billingDetailsFormik.touched['country_id'] && !!billingDetailsFormik.errors['country_id']
                        }
                        helperText={
                            billingDetailsFormik.touched['country_id'] &&
                            t(billingDetailsFormik.errors['country_id'] as string)
                        }
                        label={t('component.payment-detail-form.country')}
                        options={countries}
                        value={
                            isNaN(billingDetailsFormik.values['country_id'])
                                ? ''
                                : billingDetailsFormik.values['country_id']
                        }
                        required
                        onChange={(countryId) => billingDetailsFormik.setFieldValue('country_id', Number(countryId))}
                    />
                </Grid>
                {showSiretField && (
                    <Grid xs={6} item>
                        <LabelTextField
                            label={t('component.payment-detail-form.siret')}
                            name={'siret_id'}
                            error={
                                billingDetailsFormik.touched['siret_id'] && !!billingDetailsFormik.errors['siret_id']
                            }
                            helperText={
                                billingDetailsFormik.touched['siret_id'] &&
                                t(billingDetailsFormik.errors['siret_id'] as string)
                            }
                            value={billingDetailsFormik.values['siret_id']}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => billingDetailsFormik.handleChange(e)}
                        />
                    </Grid>
                )}
                {serverError && (
                    <Grid xs={12} item>
                        <ErrorMessageView message={t(serverError)} />
                    </Grid>
                )}
            </Grid>
        );
    },
);

export default BillingDetailsForm;
