import {
    AUTHORIZE_AUTH0_FAILED,
    AUTHORIZE_AUTH0_LOADING,
    AUTHORIZE_AUTH0_SUCCESS,
} from '@actions/AuthActions/AuthorizeAuth0ActionsTypes';
import {
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_SUCCESS,
} from '@actions/AuthActions/ForgotPasswordActionsTypes';
import { GET_USER_SUCCESS, UPDATE_USER_ELIGIBILITY } from '@actions/AuthActions/GetUserActionsTypes';
import { LOGIN_FAILED, LOGIN_LOADING, LOGIN_SUCCESS } from '@actions/AuthActions/LoginActionsTypes';
import {
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_LOADING,
    RESET_PASSWORD_SUCCESS,
} from '@actions/AuthActions/ResetPasswordActionsTypes';
import { SIGNUP_FAILED, SIGNUP_LOADING, SIGNUP_SUCCESS } from '@actions/AuthActions/SignUpActionsTypes';
import {
    USER_CHANGE_LANGUAGE_FAILED,
    USER_CHANGE_LANGUAGE_LOADING,
    USER_CHANGE_LANGUAGE_SUCCESS,
} from '@actions/AuthActions/UserChangeLanguageTypes';
import { VERIFY_EMAIL_SUCCESS } from '@actions/AuthActions/VerifyEmailActionsTypes';
import { POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS } from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksSubscriptionTypes';
import { RESET } from '@reducers/RootTypes';

import {
    USER_ADD_AMBASSADOR_FAILED,
    USER_ADD_AMBASSADOR_LOADING,
    USER_ADD_AMBASSADOR_SUCCESS,
} from '@/store/actions/AuthActions/UserAddAmbassadorTypes';
import { Reducer } from '@reduxjs/toolkit';
import { AppActionsT } from '@store/actions/AppActions/AppActionsTypes';
import { SUBSCRIPTION_SAVE_FARM_SUCCESS } from '@store/actions/SubscriptionActions/SubscriptionSaveFarmTypes';
import { AuthReducerPropsT } from './AuthReducerTypes';

export const selectedUserKey = 'selected_user';
const selectedUserFromStorage = localStorage.getItem(selectedUserKey);
const selectedUserDefaultValue = selectedUserFromStorage ? JSON.parse(selectedUserFromStorage) : null;

const persistSelectedUser = (value: { id: number; fullName: string } | null) =>
    localStorage.setItem(selectedUserKey, JSON.stringify(value));

export const defaultAuthState: AuthReducerPropsT = {
    loading: false,
    loaded: false,
    auth0Loading: false,
    selectedUser: selectedUserDefaultValue,
    ambassadorReferralCode: '',
};

const authReducer: Reducer<AuthReducerPropsT, AppActionsT> = (state, action): AuthReducerPropsT => {
    if (!state) {
        return defaultAuthState;
    }

    switch (action.type) {
        case USER_ADD_AMBASSADOR_LOADING:
            return {
                ...state,
                loading: true,
            };
        case USER_ADD_AMBASSADOR_SUCCESS:
            return {
                ...state,
                loading: false,
                ambassadorReferralCode: action.payload.ambassador_referral_code,
            };
        case SIGNUP_FAILED:
        case LOGIN_FAILED:
        case FORGOT_PASSWORD_FAILED:
        case USER_ADD_AMBASSADOR_FAILED:
        case RESET_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error,
            };
        case AUTHORIZE_AUTH0_FAILED:
            return {
                ...state,
                loaded: true,
                error: action.payload.error,
                auth0Loading: false,
            };
        case SIGNUP_LOADING:
        case LOGIN_LOADING:
        case FORGOT_PASSWORD_LOADING:
        case RESET_PASSWORD_LOADING:
        case USER_CHANGE_LANGUAGE_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case AUTHORIZE_AUTH0_LOADING:
            return {
                ...state,
                auth0Loading: true,
                error: undefined,
            };
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: undefined,
                user: action.payload.user,
                farmer_infos: action.payload.farmer_infos,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                farmer_infos: action.payload.farmer_infos,
                ambassadorReferralCode: action.payload.ambassador_referral_code,
            };
        case AUTHORIZE_AUTH0_SUCCESS:
            return {
                ...state,
                auth0Loading: false,
                loaded: true,
                user: action.payload.user,
            };
        case FORGOT_PASSWORD_SUCCESS:
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
            };
        case USER_CHANGE_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                user: state.user && {
                    ...state.user,
                    language_id: action.payload.language_id,
                    user_invitation: state.user?.user_invitation,
                },
            };
        case SUBSCRIPTION_SAVE_FARM_SUCCESS:
            return {
                ...state,
                loading: false,
                user: {
                    ...action.payload.user,
                    user_invitation: state.user?.user_invitation,
                },
            };
        case UPDATE_USER_ELIGIBILITY:
            return {
                ...state,
                loading: false,
                user: state.user && {
                    ...state.user,
                    is_eligible: !!action.payload.is_eligible,
                    user_invitation: state.user?.user_invitation,
                },
            };
        case USER_CHANGE_LANGUAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case 'AUTH_SET_SELECTED_USER': {
            persistSelectedUser(action.payload);
            return {
                ...state,
                selectedUser: action.payload ? { ...action.payload } : null,
            };
        }
        case POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                user: {
                    ...action.payload.user,
                    user_invitation: state.user?.user_invitation,
                },
            };
        case 'SET_AMBASSADOR_REFERRAL_CODE':
            return {
                ...state,
                ambassadorReferralCode: String(action.payload),
            };
        case RESET:
            return {
                loaded: true, // Loaded false is only used for the app initialisation.
                loading: false,
                auth0Loading: false,
                selectedUser: null,
                ambassadorReferralCode: '',
            };
        case VERIFY_EMAIL_SUCCESS:
            if (state.user)
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    user: {
                        ...state.user,
                        email_verification_status: 'verified',
                    },
                };

            return {
                ...state,
                loading: false,
                loaded: true,
            };
        default:
            return state;
    }
};

export default authReducer;
