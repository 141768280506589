import { CropT } from '@store/reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';
import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

// keep reference outside react renderings
const emptyArray: CropT[] = [];

// Currently mixed with legacy static crop behavior -> We don't fetch crops and consider they are in redux
export default () => {
    const list = useSelector((state: AppStateT) => state.static.crops) ?? emptyArray;
    const isLoading = useSelector((state: AppStateT) => state.static.cropsLoading);

    const getById = (cropId: number) => list.find((f) => f.id === cropId);

    const getByIds = (fieldIds: number[]) => {
        return list.filter((field) => fieldIds.includes(field.id));
    };

    return {
        staticCropState: {
            list,
            isLoading,
            getById,
            getByIds,
        },
    };
};
