/* eslint-disable @typescript-eslint/no-explicit-any */
import postFarmSeasonFieldsPastureHistory from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsPastureHistory';
import { PastureHistoryDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsPastureHistoryTypes';
import palette from '@constants/palette/crop.palette';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import * as Yup from 'yup';

import { ContextT, ContextPropsT, FormDataT, GroupedFieldsT } from './PastureTaskContextTypes';

export const Context = createContext({} as ContextT);

const PastureTaskContext = ({ children }: ContextPropsT): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm)?.id;
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason)?.id;
    const farmSeasonFields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const loading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const dontHaveAny =
        useSelector(
            (state: HomePageStateT) =>
                state.farmSeason.currentFarmSeason?.installation?.onboarding?.pastureHistory?.dontHaveAny,
        ) || false;

    const [currentStep, setCurrentStep] = useState(1);
    const [groupedFields, setGroupedFields] = useState<GroupedFieldsT>({});
    const [selectedGroup, setSelectedGroup] = useState<FarmSeasonFieldT[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [nothingOnFields, setNothingOnFields] = useState<boolean>(false);
    const [errorNothingOnFields, setErrorNothingOnFields] = useState<boolean>(false);
    const [groupedFieldIndex, setGroupedFieldIndex] = useState<number | null>(null);
    const [keyColor, setKeyColor] = useState<{ [key: string]: string }>({});
    const [nothingToSave, setNothingToSave] = useState<boolean>(true);

    // initialize values. Groupe Field by cover crop year introduction and and frequency
    useEffect(() => {
        const initGroupedField =
            farmSeasonFields?.reduce((groupedObject, field) => {
                const newGroupedObject: any = { ...groupedObject };

                if (field?.pasture_history?.year_of_change) {
                    const groupKey = String(field.pasture_history?.year_of_change);
                    newGroupedObject[groupKey] = [...(groupedObject[groupKey] || []), { ...field }];
                }

                return newGroupedObject;
            }, {} as any) || {};

        setGroupedFields(initGroupedField);
    }, [farmSeasonFields]);

    // set up the checkbox for no cover crop
    useEffect(() => {
        setNothingOnFields(dontHaveAny);
    }, [dontHaveAny]);

    useEffect(() => {
        const initKeyColor = { ...keyColor };
        let colorIndex = 0;
        Object.keys(groupedFields).forEach((key) => {
            if (!initKeyColor[key] && colorIndex < palette.length) {
                while (Object.values(initKeyColor).includes(palette[colorIndex])) {
                    colorIndex += 1;
                }
                initKeyColor[key] = palette[colorIndex];
            }
        });
        setKeyColor(initKeyColor);
    }, [groupedFields]);

    const onSubmit = async () => {
        if (Object.keys(groupedFields).length === 0 && !nothingOnFields) {
            setErrorNothingOnFields(true);
        } else {
            const pastureHistories: PastureHistoryDataT[] = [];
            Object.keys(groupedFields).forEach((key) => {
                groupedFields[key].forEach((field) => {
                    if (field.pasture_history) {
                        pastureHistories.push(field.pasture_history);
                    }
                });
            });

            setNothingToSave(true);
            await dispatch(
                postFarmSeasonFieldsPastureHistory(farmId, farmSeasonId, {
                    pastureHistories,
                    dontHaveAny: nothingOnFields,
                }),
            );
            navigate('/practice-history');
        }
    };

    // Validation Shema
    const validationSchema = Yup.object({
        year_of_change: Yup.string()
            .required(t('validation.mandatory-field'))
            .test(
                'unique-year',
                t('pages.installationtasks.history.pasture.history-form.year-of-change-validation'),
                (value) => !Object.keys(groupedFields).some((key) => value !== selectedKey && value === key),
            ),
    });

    // Formik forom
    const formik = useFormik<FormDataT>({
        initialValues: {
            year_of_change: '',
            key: '',
        },
        onSubmit: () => {
            formik.validateForm().then(() => {
                const newSelectedGroup = selectedGroup.map((field) => ({
                    ...field,
                    pasture_history: field.pasture_history
                        ? {
                              ...field.pasture_history,
                              year_introduction: formik.values.year_of_change,
                          }
                        : undefined,
                }));
                setSelectedGroup(newSelectedGroup);
                setCurrentStep(3);
            });
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: true,
    });

    const store: ContextT = {
        currentStep,
        setCurrentStep,
        t,
        formik,
        loading,
        groupedFields,
        setGroupedFields,
        selectedGroup,
        setSelectedGroup,
        selectedKey,
        setSelectedKey,
        nothingOnFields,
        setNothingOnFields,
        onSubmit,
        groupedFieldIndex,
        setGroupedFieldIndex,
        keyColor,
        farmSeasonFields,
        nothingToSave,
        setNothingToSave,
        errorNothingOnFields,
        setErrorNothingOnFields,
    };

    return <Context.Provider value={store}>{children}</Context.Provider>;
};
export default PastureTaskContext;
