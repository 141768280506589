import getMachineries from '@actions/StaticActions/MachineryListGet';
import tillagePracticeGetListAction from '@actions/StaticActions/TillagePracticeGetListAction';
import { useEffect } from 'react';
import useAppDispatch from '@hooks/useAppDispatch';

import OperationsView from './OperationsView';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '../types/HomePageStoreTypes';
import { useLocation } from 'react-router-dom';
import { routingRedirect } from '@store/actions/RoutingActions/RoutingActions';
import useMapFieldReset from '@/hooks/useMapFieldReset';
import MapService from '@/services/mapService/mapService';
import { setupCropPaletteFromCropLegacy } from '@/utils/setupCropPalette';
import addPolygonData from '@/utils/addPolygonData';

const Operations = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList);
    const cropColors = setupCropPaletteFromCropLegacy(crops);
    const { pathname } = useLocation();
    const resetMap = useMapFieldReset();

    useEffect(() => {
        if (pathname === '/operations') {
            MapService.addPolygons({
                polygons: fields.map((field) =>
                    addPolygonData(
                        field.field_crops?.some((field_crop) => field_crop.farm_season_crop.crop.is_permanent)
                            ? {
                                  field,
                                  colorOverride: '#000000',
                              }
                            : {
                                  field,
                                  colorPalette: cropColors,
                              },
                    ),
                ),
                autofocus: true,
            });
        }

        return () => {
            resetMap(true);
        };
    }, [resetMap, fields, crops, cropColors]);

    useEffect(() => {
        //  set tillagePracticeList in store to get currentTillage (with url param tillageId) for operations pages
        dispatch(tillagePracticeGetListAction());
        // dispatch(operationGetListAction());
        dispatch(getMachineries(Number(currentFarm?.country_id)));
    }, []);

    useEffect(() => {
        if (pathname !== '/operations') {
            dispatch(routingRedirect({ route: '/operations' }));
        }
    }, [currentFarmSeason.id]);

    return <OperationsView />;
};

export default Operations;
