import { geoJsonFeatureT } from '@components/Map/MapTypes';
import { FarmSeasonFieldCropDataT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_ORGANIC_LOADING = 'POST_FARM_SEASON_FIELDS_ORGANIC_LOADING';
export const POST_FARM_SEASON_FIELDS_ORGANIC_FAILED = 'POST_FARM_SEASON_FIELDS_ORGANIC_FAILED';
export const POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS = 'POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS';

export type FarmSeasonFieldsOrganicT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    polygon: geoJsonFeatureT;
    tillage_id?: number | null;
    has_drainage?: boolean | null;
    is_organic?: boolean | null;
};

export type PostFarmSeasonFieldsOrganicDataT = {
    organic: boolean;
    fields: FarmSeasonFieldsOrganicT[];
};

export type PostFarmSeasonFieldsOrganicResponseDataT = {
    fields: FarmSeasonFieldsOrganicT[];
    installation: {
        onboarding: {
            finished: string[];
            tillage: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsOrganicPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsOrganicDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsOrganicResponseErrorT>;

export type PostFarmSeasonFieldsOrganicResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsOrganicLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_ORGANIC_LOADING;
};

export type PostFarmSeasonFieldsOrganicFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_ORGANIC_FAILED;
    error: PostFarmSeasonFieldsOrganicResponseErrorT;
};

export type PostFarmSeasonFieldsOrganicSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS;
    payload: PostFarmSeasonFieldsOrganicResponseDataT;
};

export type PostFarmSeasonFieldsOrganicDispatchTypesT =
    | PostFarmSeasonFieldsOrganicLoadingT
    | PostFarmSeasonFieldsOrganicFailedT
    | PostFarmSeasonFieldsOrganicSuccessT;
