import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            gap: '0.5rem',
        },
        infoIcon: {
            height: '19px !important',
            width: '19px',
        },
    };
});
