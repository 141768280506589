import { useContext } from 'react';
import postFarmSeasonFieldIrrigationValues from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldIrrigationValues';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAppDispatch from '@hooks/useAppDispatch';
import { PostFarmSeasonFieldIrrigationValuesDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldIrrigationValuesTypes';

import { IrrigationContext } from '../IrrigationTaskContext/IrrigationTaskContext';

import IrrigationValuesFormView from './IrrigationValuesFormView';
import { IrrigationDataT } from './IrrigationValuesFormTypes';
import { useSelector } from 'react-redux';
import selectFieldSuggestion from '@store/selectors/selectFieldSuggestion';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const IrrigationValuesForm = (): JSX.Element => {
    const {
        fieldsList,
        farmSeasonId,
        farmId,
        irrigationPowerSourceList,
        irrigationWaterSourceList,
        irrigationMethodList,
        fieldLoading,
        pushStep,
        t,
        fieldIrrigated,
    } = useContext(IrrigationContext);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    const previousSeasonIrrigationValues = useSelector(
        selectFieldSuggestion({
            suggestionType: 'irrigation',
            farm_season_field_id: fieldIrrigated.id,
        }),
    );

    const dispatch = useAppDispatch();

    const irrigationValidationSchema = Yup.object({
        irrigationMethod: Yup.string().min(1).required(t('validation.mandatory-field')),
        irrigationWaterSource: Yup.string().required(t('validation.mandatory-field')),
        irrigationPowerSource: Yup.string().required(t('validation.mandatory-field')),
        irrigationDepth: Yup.number()
            .required(t('validation.mandatory-field'))
            .test(
                'bigger-than-0',
                t('validation.must-be-greater-than-x', { x: 0 }),
                (irrigationDepth) => Number(irrigationDepth) > 0,
            ),
        irrigationHorizontalDistance: Yup.number()
            .required(t('validation.mandatory-field'))
            .test(
                'bigger-than-0',
                t('validation.must-be-greater-than-x', { x: 0 }),
                (irrigationHorizontalDistance) => Number(irrigationHorizontalDistance) > 0,
            ),
        irrigationWaterQuantity: Yup.number()
            .required(t('validation.mandatory-field'))
            .test(
                'bigger-than-0',
                t('validation.must-be-greater-than-x', { x: 0 }),
                (irrigationWaterQuantity) => Number(irrigationWaterQuantity) > 0,
            ),
    });

    const formik = useFormik({
        initialValues: {
            irrigationMethod:
                fieldIrrigated?.irrigation?.irrigation_method_id ||
                previousSeasonIrrigationValues?.irrigation.irrigation_method_id ||
                '',
            irrigationWaterSource:
                fieldIrrigated?.irrigation?.irrigation_water_source_id ||
                previousSeasonIrrigationValues?.irrigation?.irrigation_water_source_id ||
                '',
            irrigationPowerSource:
                fieldIrrigated?.irrigation?.irrigation_power_source_id ||
                previousSeasonIrrigationValues?.irrigation?.irrigation_power_source_id ||
                '',
            irrigationDepth:
                fieldIrrigated?.irrigation?.pumping_depth ||
                previousSeasonIrrigationValues?.irrigation?.pumping_depth ||
                '',
            irrigationHorizontalDistance:
                fieldIrrigated?.irrigation?.horizontal_distance ||
                previousSeasonIrrigationValues?.irrigation?.horizontal_distance ||
                '',
            irrigationWaterQuantity: fieldIrrigated?.irrigation?.total_amount || '',
        },
        validationSchema: irrigationValidationSchema,
        enableReinitialize: true,
        onSubmit: async (values: IrrigationDataT) => {
            const irrigationValues: PostFarmSeasonFieldIrrigationValuesDataT = {
                irrigation_method_id: +values.irrigationMethod,
                irrigation_water_source_id: +values.irrigationWaterSource,
                irrigation_power_source_id: +values.irrigationPowerSource,
                pumping_depth: +values.irrigationDepth,
                horizontal_distance: +values.irrigationHorizontalDistance,
                total_amount: +values.irrigationWaterQuantity,
                percentage: 100,
                validation: 3,
            };
            await dispatch(
                postFarmSeasonFieldIrrigationValues(
                    farmId,
                    farmSeasonId,
                    fieldIrrigated.id,
                    irrigationValues,
                    fieldsList,
                ),
            );
            pushStep(2);
        },
    });

    return (
        <IrrigationValuesFormView
            fieldIrrigated={fieldIrrigated}
            fieldLoading={fieldLoading}
            formik={formik}
            irrigationMethodList={irrigationMethodList}
            irrigationPowerSourceList={irrigationPowerSourceList}
            irrigationWaterSourceList={irrigationWaterSourceList}
            t={t}
            disabledForm={!canEditFarmSeason.access}
        />
    );
};

export default IrrigationValuesForm;
