import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Typography } from '@mui/material';
import { Prompt } from '@components/Prompt/Prompt';

import useSelectFieldStepStyle from './SelectFieldStepView.style';
import { SelectFieldStepViewPropsT } from './SelectFieldStepTypes';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import FieldAmountLabel from '@components/FieldsAmountLabelDeprecated/FieldAmountLabelDeprecated';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const SelectFieldStepView = ({
    selectedSoilOrganicCarbon,
    onSelectedFieldDelete,
    t,
    onConfirm,
    onSelectAll,
    isSubmitted,
    isDirty,
    selectedFields,
}: SelectFieldStepViewPropsT): JSX.Element => {
    const { classes } = useSelectFieldStepStyle();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!isSubmitted && isDirty}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                {t(
                    'pages.installationtasks.fields-subtasks.soil-organic-carbons-task.select-step.select-field-step.title',
                )}{' '}
                :&nbsp;
                <span className={classes.titleDarker}>
                    {t(selectedSoilOrganicCarbon?.name, {
                        lowLevel: selectedSoilOrganicCarbon?.lowLevel,
                        highLevel: selectedSoilOrganicCarbon?.highLevel,
                    })}
                </span>
            </Typography>

            <SelectedFieldsContainer
                disabled={!canEditFarmSeason.access}
                headerText={<FieldAmountLabel fields={selectedFields} />}
                handleRemoveSelectedPolygon={onSelectedFieldDelete}
            />

            <TwoButtonFooterView
                leftOnClick={onSelectAll}
                leftText={canEditFarmSeason.access ? t('constants.select-remaining-fields') : ''}
                rightButtonOverride={
                    <RestrictSaveFarmSeason>
                        <LoadingButton
                            loading={false}
                            onClick={onConfirm}
                            text={canEditFarmSeason.access ? t('constants.confirm') : t('constants.save')}
                        />
                    </RestrictSaveFarmSeason>
                }
            />
        </div>
    );
};

export default SelectFieldStepView;
