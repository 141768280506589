import { Box, CircularProgress, Theme, ThemeProvider } from '@mui/material';
import { ClassNameMap } from '@mui/styles';

const LoaderComponent = ({
    classes,
    theme,
}: {
    classes: ClassNameMap<'loaderColor' | 'loaderBox'>;
    theme: Theme;
}): JSX.Element => {
    return (
        <ThemeProvider theme={theme}>
            <Box alignItems="center" className={classes.loaderBox} display="flex" height="100%" justifyContent="center">
                <CircularProgress className={classes.loaderColor} />
            </Box>
        </ThemeProvider>
    );
};

export default LoaderComponent;
