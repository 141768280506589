import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import forgotPassword from '@actions/AuthActions/ForgotPasswordActions';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useAppNavigate from '@hooks/useAppNavigate';

import ForgotPasswordPageView from './ForgotPasswordPageView';
import { ForgotPasswordDataT } from './ForgotPasswordPageTypes';

const ForgotPasswordPage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { t } = useTranslation();
    const loading = useSelector((state: AppStateT) => state.auth.loading);
    const [msgOpen, setMsgOpen] = useState(false);

    const handleMsgOpen = (): void => {
        setMsgOpen(true);
    };

    useEffect(() => {
        if (msgOpen) {
            setTimeout(() => {
                navigate('/login');
            }, 8000);
        }
    }, [msgOpen]);

    const validationSchema = yup.object({
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: ForgotPasswordDataT, { setSubmitting, setErrors, resetForm }) => {
            const response = await dispatch(forgotPassword(values));
            const errors: { email?: string } = {};

            if (response.list?.length > 0) {
                if (response.list?.includes('email')) {
                    errors.email = t(response.message.error);
                }
            }

            setErrors(errors);
            setSubmitting(false);

            if (_.isEmpty(errors)) {
                resetForm();
                handleMsgOpen();
            }
        },
    });

    return <ForgotPasswordPageView formik={formik} loading={loading} msgOpen={msgOpen} t={t} />;
};

export default ForgotPasswordPage;
