import { useModal } from '@soil-capital/ui-kit';
import { useGetRenewalContextQuery, useLazyGetPaymentStatusQuery, usePayInAdvanceMutation } from './renewal.api';
import useHomePageLoading from '@hooks/useHomePageLoading';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import formatCurrencyNumber from '@services/formatCurrencyNumber/formatCurrencyNumber';
import SegmentService from '@services/segmentService/segmentService';
import { getLocaleDate } from '@utils/getLocaleDate';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FarmSeasonProductPlanStatusT } from '@store/reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import useUrlQueryParams from '@hooks/useUrlQueryParams';
import { useLazyGetBillingPortalUrlQuery } from '@api/billing.api';
import useActionOnQueryParams from '@hooks/useActionOnQueryParams';
import useOpenNewSeasonCheck from '@hooks/useOpenNewSeasonCheck';
import { useState, useEffect, useCallback } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { GetPaymentStatusResponseT } from './renewal.types';

const useRenewalActionLogic = () => {
    const navigate = useNavigate();
    const { addPendingState } = useHomePageLoading();
    const renewalModalController = useModal();
    const { i18n } = useTranslation();
    const canOpenNewSeason = useOpenNewSeasonCheck();

    const roleId = useSelector((state: HomePageStateT) => state.auth.user?.role_id);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const currencyId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.country.currency_id);
    const [modalState, setModalState] = useState<'confirmation' | 'feedback' | 'subscription-payment'>('confirmation');
    const [paymentStatus, setPaymentStatus] = useState<FarmSeasonProductPlanStatusT | undefined | null>();
    const [isPaymentToConfirm, setIsPaymentToConfirm] = useState<boolean>(false);
    const [clientSecret, setClientSecret] = useState<string | undefined>();
    const stripe = useStripe();

    const {
        data: renewalContext,
        isLoading: renewalContextLoading,
        isFetching: renewalIsFetching,
        isSuccess: renewalIsLoaded,
        refetch: refetchRenewalContext,
    } = useGetRenewalContextQuery({
        farmId,
        farmSeasonId,
    });

    const [getBillingPortalUrl, { isLoading: isGetBillingPortalUrlLoading }] = useLazyGetBillingPortalUrlQuery();

    const queryParams = useUrlQueryParams();

    useEffect(() => {
        const paymentStatus = queryParams.get('renewal_subscription_status');
        if (paymentStatus && !renewalModalController.isModalOpen) {
            setPaymentStatus(paymentStatus === 'canceled' ? 'payment_failed' : 'payment_in_progress');
            setModalState('feedback');
            renewalModalController.openModal();
        }
    }, [queryParams]);

    useEffect(() => {
        if (
            renewalContext?.nextFarmSeasonProductPlan &&
            ['to_pay', 'payment_failed'].includes(renewalContext.nextFarmSeasonProductPlan.status) &&
            !renewalModalController.isModalOpen &&
            modalState == 'feedback'
        ) {
            setModalState('confirmation');
        }

        if (
            renewalContext?.nextFarmSeasonProductPlan?.status == 'payment_in_progress' ||
            renewalContext?.nextFarmSeasonProductPlan?.status == 'requires_action'
        ) {
            setPaymentStatus(renewalContext?.nextFarmSeasonProductPlan?.status);
            setModalState('feedback');
        }
    }, [renewalContext?.nextFarmSeasonProductPlan?.status, modalState, renewalModalController.isModalOpen]);

    const [getPaymentStatus] = useLazyGetPaymentStatusQuery();

    const retrieveProductPlanStatus = async (): Promise<GetPaymentStatusResponseT> => {
        const res = await getPaymentStatus({ farmId, farmSeasonId }).unwrap();
        setPaymentStatus(res.status);
        if (!!res.paymentIntentSecret) {
            setClientSecret(res.paymentIntentSecret);
        }
        return res;
    };

    const confirmStripePayment = useCallback(async () => {
        if (!clientSecret) return;
        setIsPaymentToConfirm(false);
        await stripe?.confirmPayment({
            clientSecret: clientSecret,
            redirect: 'if_required',
        });
        setPaymentStatus('payment_in_progress');
        setClientSecret(undefined);
    }, [clientSecret]);

    useEffect(() => {
        if (!!clientSecret && paymentStatus == 'requires_action') {
            setIsPaymentToConfirm(true);
            if (renewalModalController.isModalOpen) {
                confirmStripePayment();
            }
        }
    }, [clientSecret]);

    useEffect(() => {
        let refetchInterval: NodeJS.Timeout | undefined;

        if (paymentStatus == 'payment_in_progress') {
            refetchInterval = setInterval(async () => {
                const res = await retrieveProductPlanStatus();
                if (['pending', 'active', 'payment_failed'].includes(res.status)) {
                    refetchRenewalContext();
                    clearInterval(refetchInterval);
                }
            }, 3000);
        } else if (paymentStatus == 'requires_action') {
            retrieveProductPlanStatus();
        }

        return () => clearInterval(refetchInterval);
    }, [paymentStatus]);

    useEffect(() => {
        addPendingState({ pending: renewalContextLoading, slug: 'RenewalAFSPPLoading' });
    }, [renewalContextLoading]);

    useEffect(() => {
        addPendingState({ pending: renewalIsFetching, slug: 'RenewalAFSPPLoading' });
    }, [renewalIsFetching]);

    const [payInAdvance, { isLoading: payInAdvanceLoading }] = usePayInAdvanceMutation();

    const confirmRenewal = async (hasSubscription: boolean) => {
        if (hasSubscription) {
            try {
                const res = await payInAdvance({ farmId, farmSeasonId }).unwrap();
                setModalState('feedback');
                setPaymentStatus(res.status);
            } catch {}
        } else {
            setModalState('subscription-payment');
        }
    };

    const openRenewalModal = async () => {
        SegmentService.renewalPayInAdvanceButtonTrack({
            discount: renewalContext?.currentDiscount?.percent_off ?? 0,
            user_role_id: roleId,
        });
        // reset modal state to be sure we start with the confirmation
        setModalState('confirmation');
        renewalModalController.openModal();
    };

    const changePaymentMethod = async (place: 'billing_page' | 'payment_modal') => {
        SegmentService.paymentMeanEditionButtonClickedTrack({ place });
        const res = await getBillingPortalUrl({
            farmId,
            locale: i18n.language,
            withModal: place === 'payment_modal',
        }).unwrap();

        return window.location.replace(res.redirectUrl);
    };

    const redirectToFarmData = () => {
        navigate('/farm');
    };
    const redirectToOpenNextFarmSeasonModal = () => {
        navigate('/farm?modal=start-new-season-success');
    };

    // Trigger action when url contains ?modal=renewal-modal
    useActionOnQueryParams({
        key: 'modal',
        value: 'renewal-modal',
        condition: renewalIsLoaded,
        action: renewalModalController.openModal,
    });

    return {
        renewalModalController,
        confirmRenewal,
        payInAdvanceLoading: payInAdvanceLoading || renewalIsFetching,
        renewalDate: renewalContext ? getLocaleDate(renewalContext.renewal_date, i18n.language) : null,
        formatedPrice: renewalContext?.price
            ? formatCurrencyNumber(renewalContext.price, currencyId == 2 ? 'GBP' : 'EUR')
            : formatCurrencyNumber(980, currencyId == 2 ? 'GBP' : 'EUR'),
        hasPrice: Boolean(renewalContext?.price),
        hasDiscount: Boolean(renewalContext?.currentDiscount),
        discountPercentage: renewalContext?.currentDiscount?.percent_off ?? 0,
        discountedPrice: renewalContext?.currentDiscount
            ? formatCurrencyNumber(renewalContext.currentDiscount.price, currencyId == 2 ? 'GBP' : 'EUR')
            : renewalContext
            ? formatCurrencyNumber(renewalContext.price, currencyId == 2 ? 'GBP' : 'EUR')
            : null,
        discounts: renewalContext?.discounts.map((discount) => ({
            ...discount,
            price: formatCurrencyNumber(discount.price, currencyId == 2 ? 'GBP' : 'EUR'),
        })),
        isRenewalPaid: ['pending', 'active'].includes(
            paymentStatus ?? renewalContext?.nextFarmSeasonProductPlan?.status ?? '',
        ),
        isPaymentToConfirm,
        nextHarvestYear: renewalContext?.currentHarvestYear ? renewalContext?.currentHarvestYear + 1 : null,
        modalState,
        renewalPaymentFlowStatus: paymentStatus ?? 'payment_in_progress',
        isLatestPaidCarbonSeason: renewalContext?.isLatestPaidCarbonSeason,
        openRenewalModal,
        isStandardProductPlan: renewalContext?.isStandardProductPlan,
        hasSubscription: !!renewalContext?.hasSubscription,
        renewalDeadlineDate: getLocaleDate(renewalContext?.renewal_deadline_date ?? '', i18n.language),
        redirectToFarmData,
        isRenewalDeadlinePassed: new Date().getTime() > new Date(renewalContext?.renewal_deadline_date || '').getTime(),
        isPartnerPrepaid: renewalContext?.isPartnerPrepaid,
        changePaymentMethod,
        isGetBillingPortalUrlLoading,
        canOpenNewSeason,
        redirectToOpenNextFarmSeasonModal,
        confirmStripePayment,
    };
};

export default useRenewalActionLogic;
