import { makeStyles } from 'tss-react/mui';

const useCarbonbBankStyle = makeStyles()((theme) => ({
    textInputContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        marginTop: '5px',
        marginBottom: '30px',
    },
    iconAndInfoRow: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '25px',
        '> svg': {
            color: theme.palette.primary.main,
        },
    },
}));

export default useCarbonbBankStyle;
