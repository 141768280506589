import CollapsableSectionView from '@components/CollapsableSection/CollapsableSectionView';
import { Box, FormHelperText, Grid } from '@mui/material';
import RelationsDnd from '@components/RelationsDnd/RelationsDnd';
import AddButtonView from '@components/AddButton/AddButtonView';
import { useModal } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { FarmSeasonFieldCropDataT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

import YieldAndResidueView from '../YieldAndResidue/YieldAndResidueView';

import useCropRelationsViewStyle from './CropRelationsView.style';
import { CropRelationsViewPropsT } from './CropRelationsViewTypes';
import CropModalView from './CropModal/CropModalView';
import { ModalCentered } from '@soil-capital/ui-kit';
import Restricted from '@components/Restricted/Restricted';
import CardNameValueList from '@components/CardNameValueList/CardNameValueList';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { Override } from '@/utils/Override';
import { useSelector } from 'react-redux';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

const CropRelationsView = ({
    formik,
    warningOperationDone,
    warningPermanentCrop,
}: CropRelationsViewPropsT): JSX.Element => {
    const { classes } = useCropRelationsViewStyle();
    const cropModalController = useModal<FarmSeasonFieldCropDataT>();
    const { t } = useTranslation();
    const { openModal, onModalCancel, onModalConfirm } = cropModalController;
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const isCFT = useIsCFTCarbonModel();

    return (
        <>
            <CollapsableSectionView
                helpName="crop-select-help"
                sectionName={t('pages.fields.crop-relations.title')}
                defaultCollapseState={true}
            >
                <>
                    <Grid className={classes.container} spacing={2} container>
                        <Grid xs={12} xl={3} lg={4} item>
                            <Restricted
                                toPermission="EDIT_FARM-SEASON"
                                forResource={currentFarmSeason}
                                withProps={() => ({
                                    fallback: (
                                        <CardNameValueList
                                            variant="spanishWhite"
                                            items={
                                                formik.values.field_crops as Override<
                                                    FarmSeasonFieldCropDataT,
                                                    { id: number }
                                                >[]
                                            }
                                            getName={(fc) => t(fc.farm_season_crop.crop.translation_slug || '')}
                                        ></CardNameValueList>
                                    ),
                                })}
                            >
                                <>
                                    <RelationsDnd
                                        state={{
                                            fieldCrops: formik.values.field_crops,
                                            setFieldCrops: (field_crops) =>
                                                formik.setValues((oldValues) => ({ ...oldValues, field_crops })),
                                        }}
                                        warningOperationDone={warningOperationDone}
                                        warningPermanentCrop={() => warningPermanentCrop(formik.values)}
                                    />
                                    {formik.errors.field_crops && (
                                        <Box py={4}>
                                            <FormHelperText error={!!formik.errors.field_crops}>
                                                <>{formik.errors.field_crops}</>
                                            </FormHelperText>
                                        </Box>
                                    )}
                                    <AddButtonView
                                        label={t('constants.add-crop')}
                                        fullWidth
                                        onClick={() =>
                                            !warningPermanentCrop(formik.values) &&
                                            openModal((fieldCrop) => {
                                                return formik.setValues((oldValues) => ({
                                                    ...oldValues,
                                                    field_crops: [
                                                        ...oldValues.field_crops,
                                                        fieldCrop,
                                                    ] as FarmSeasonFieldCropDataT[],
                                                }));
                                            })
                                        }
                                    />
                                </>
                            </Restricted>
                        </Grid>
                    </Grid>
                    {!!formik.values.field_crops.length && isCFT && (
                        <>
                            <div className={classes.divider} />
                            <YieldAndResidueView formik={formik} />
                        </>
                    )}
                </>
            </CollapsableSectionView>
            <ModalCentered className={classes.cropModal} controller={cropModalController}>
                <CropModalView
                    fieldCrops={formik.values.field_crops}
                    leftButtonText={t('constants.cancel')}
                    leftOnClick={onModalCancel}
                    rightButtonText={t('constants.confirm')}
                    rightOnCLick={onModalConfirm}
                    title={t('pages.fields.crop-relations.crop-modal.title')}
                />
            </ModalCentered>
        </>
    );
};

export default CropRelationsView;
