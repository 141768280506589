import { Box, Button, Paper, Typography } from '@mui/material';
import { LogoImg } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import useErrorCardStyle from './ErrorCard.style';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ErrorCardSlugs from './ErrorCard.constants';

const ErrorCard = () => {
    const { t } = useTranslation();
    const { classes } = useErrorCardStyle();
    const [searchParams] = useSearchParams();
    const errorType = searchParams.get('errorType');
    const slugs = errorType && ErrorCardSlugs[errorType] ? ErrorCardSlugs[errorType] : ErrorCardSlugs.default;
    const navigate = useNavigate();

    return (
        <Paper className={classes.container}>
            <LogoImg className={classes.logo} logoType="soilcapital" height="46px" />
            <Box className={classes.iconContainer}>
                <WarningIcon className={classes.icon} />
            </Box>
            <div className={classes.content}>
                <div className={classes.contentGroup}>
                    <div className={classes.titleContainer}>
                        <Typography align="center" variant="h4">
                            {t(slugs.title)}
                        </Typography>
                    </div>
                    <Typography align="center" variant="body2">
                        {t(slugs.description)}
                    </Typography>
                </div>
            </div>
            <Box className={classes.buttonContainer}>
                <Button color="primary" onClick={() => navigate('/billing')}>
                    {t(slugs?.button ? slugs.button : (ErrorCardSlugs.default.button as string))}
                </Button>
            </Box>
        </Paper>
    );
};

export default ErrorCard;
