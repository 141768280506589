import FarmSeasonLivestocksApiService from '@services/farmSeasonLivestocksApiService/farmSeasonLivestocksApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonLivestocksDeleteCallBackT,
    DeleteFarmSeasonLivestocksDispatchTypesT,
    DeleteFarmSeasonLivestocksResponseDataT,
    DeleteFarmSeasonLivestocksResponseErrorT,
    DELETE_FARM_SEASON_LIVESTOCKS_FAILED,
    DELETE_FARM_SEASON_LIVESTOCKS_LOADING,
    DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS,
} from './DeleteFarmSeasonLivestocksTypes';

export const deleteFarmSeasonLivestock =
    (farmId: number, farmSeasonId: number): FarmSeasonLivestocksDeleteCallBackT =>
    async (
        dispatch: Dispatch<DeleteFarmSeasonLivestocksDispatchTypesT>,
    ): Promise<DeleteFarmSeasonLivestocksResponseErrorT> => {
        try {
            dispatch({ type: DELETE_FARM_SEASON_LIVESTOCKS_LOADING });
            const res: AxiosResponse<DeleteFarmSeasonLivestocksResponseDataT> =
                await FarmSeasonLivestocksApiService.delete(farmId, farmSeasonId);

            dispatch({
                type: DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: DELETE_FARM_SEASON_LIVESTOCKS_FAILED,
                error: error as DeleteFarmSeasonLivestocksResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
