import { createEntityAdapter } from '@reduxjs/toolkit';
import { FieldCropEndpointParamsT, FieldCropT } from './fieldCropEntity.types';
import { getUpdateQueryHelper } from '@api/api2.common';
import { api2 } from '@api/api2.service';
import { FarmSeasonCropT } from '@store/reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';
import EntityError from '../EntityError';
import store from '@store/store';
import { POST_FARM_SEASON_FIELDS_SUCCESS } from '@store/actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTypes';
import { FarmSeasonFieldCropDataT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

export const fieldCropCacheAdapter = createEntityAdapter<FieldCropT>();
const fieldCropCacheQueryHelper = getUpdateQueryHelper<FieldCropEndpointParamsT, FieldCropT, 'FieldCrop'>(
    api2,
    'FieldCrop',
    fieldCropCacheAdapter,
);

// FP-4410 - To be reviewed and refactored by Augustin
const add: typeof fieldCropCacheQueryHelper.add = async (
    { farmSeasonId, body },
    apiContext,
    optimisticStrategy = 'fetch-only',
) => {
    fieldCropCacheQueryHelper.add({ farmSeasonId, body }, apiContext, optimisticStrategy);

    /* ------------------------ Update Legacy Redux State ----------------------- */
    if (optimisticStrategy === 'cache-then-fetch') {
        throw new EntityError('FieldCrop', 'legacy', "can't do optimistic cache-then-fetch due to legacy state");
    }

    let fieldCrop: FieldCropT;
    if (optimisticStrategy === 'cache-only') {
        if (!body?.id) {
            throw new EntityError('FieldCrop', 'legacy', 'try to add permanent optimistic but no id');
        }
        fieldCrop = body as FieldCropT;
    } else if (optimisticStrategy === 'fetch-only') {
        const { data } = await apiContext.queryFulfilled;
        fieldCrop = data;
    } else {
        throw new EntityError('FieldCrop', 'legacy', `unexpected optimistic '${optimisticStrategy}'`);
    }

    const farmSeasonCrop = store
        .getState()
        .farmSeasonCrop.cropsList.find((c) => c.id === fieldCrop.farm_season_crop_id);
    const staticCrop = store.getState().static.crops.find((c) => c.id === farmSeasonCrop?.crop.id);

    if (!staticCrop) {
        throw new EntityError('Crop', 'legacy');
    }

    const newCropForLegacy: FarmSeasonCropT = { ...fieldCrop, crop: { ...staticCrop } };
    const newLegacyFields = store.getState().farmSeasonField.fieldsList;
    newLegacyFields.forEach((legacyField) => {
        if (fieldCrop.farm_season_field_id === legacyField.id) {
            const farmSeasonFieldCrop: FarmSeasonFieldCropDataT = {
                ...legacyField.field_crops,
                farm_season_crop: newCropForLegacy,
            };
            legacyField.field_crops?.push(farmSeasonFieldCrop);
        }
    });

    apiContext.dispatch({
        type: POST_FARM_SEASON_FIELDS_SUCCESS,
        payload: { fields: newLegacyFields },
    });
    /* -------------------------------------------------------------------------- */
};

export default {
    add: add,
    removeWhere: fieldCropCacheQueryHelper.removeWhere,
};
