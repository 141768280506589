import { Dispatch } from '@reduxjs/toolkit';
import { AuthSetSelectedUserT } from './AuthActionTypes';
import { CarbonModelType } from '@/services/authService/authServiceTypes';

export const authSetSelectedUser =
    ({ userId, fullName, model_type }: { userId: number; fullName: string; model_type: CarbonModelType }) =>
    (dispatch: Dispatch<AuthSetSelectedUserT>) => {
        dispatch({
            type: 'AUTH_SET_SELECTED_USER',
            payload: {
                fullName,
                id: userId,
                model_type,
            },
        });
    };
