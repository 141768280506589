import { makeStyles } from 'tss-react/mui';
import { CardFormLayoutStylePropsT } from './CardFormLayoutTypes';

const useCardFormLayoutStyle = makeStyles<CardFormLayoutStylePropsT>()(
    (theme, { marginBottom, hasHeaderHeight, disableClose }) => ({
        CardFormLayout: {
            marginBottom,
            backgroundColor: 'rgba(237, 200, 128, 0.15)', // TODO replace color with theme
            padding: '15px',
            borderRadius: '10px',
        },
        header: {
            display: 'flex',
            height: hasHeaderHeight ? '30px' : '',
        },
        closeIcon: {
            ...(disableClose && {
                pointerEvents: 'none',
                opacity: '0.5',
            }),
            marginLeft: 'auto',
            padding: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            boxSizing: 'content-box',
            color: theme.palette.secondary.main,
            transition: 'color ease .2s',
            '&:hover': {
                color: theme.palette.secondary.dark,
            },
        },
        tooltip: {
            marginLeft: 'auto',
        },
    }),
);

export default useCardFormLayoutStyle;
