import { Pathname } from 'history';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';

// autofocus map on some main pages, where not managed by components
const focusMap = (pathname: Pathname, fields: FarmSeasonFieldT[]) => {
    switch (pathname) {
        case '/farm':
        case '/crops':
            MapService.focusPolygons({ polygons: fields.map((f) => f.polygon) });
            break;
    }
};

const useTriggerOnPage = () => {
    const location = useLocation();
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);

    useEffect(() => {
        if (fields?.length) focusMap(location.pathname, fields);
    }, [location.pathname, fields]);
};

export default useTriggerOnPage;
