import useStickyHeaderLayoutStyle from './StickyHeaderLayout.style';
import { StickyHeaderLayoutPropsT } from './StickyHeaderLayoutTypes';

/**
 * Sticky header with fade bottom background for scroll
 */
const StickyHeaderLayout = ({
    bgColor,
    children,
    paddingTop,
    fillParentPadding,
}: StickyHeaderLayoutPropsT): JSX.Element => {
    const { classes } = useStickyHeaderLayoutStyle({
        bgColor,
        paddingTop,
        fillParentPadding,
    });
    return <div className={classes.StickyHeaderLayout}>{children}</div>;
};

export default StickyHeaderLayout;
