export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export type ResetPasswordActionDataT = {
    email: string;
    password: string;
    password_confirmation: string;
};

export type ResetPasswordResponseDataT = {
    result: boolean;
};

export type ResetPasswordResponseErrorT = {
    message?: string;
    list?: string[];
};

export type ResetPasswordLoadingT = {
    type: typeof RESET_PASSWORD_LOADING;
};

export type ResetPasswordFailedT = {
    type: typeof RESET_PASSWORD_FAILED;
    error: ResetPasswordResponseErrorT;
};

export type ResetPasswordSuccessT = {
    type: typeof RESET_PASSWORD_SUCCESS;
    payload: ResetPasswordResponseDataT;
};

export type ResetPasswordDispatchTypesT = ResetPasswordLoadingT | ResetPasswordFailedT | ResetPasswordSuccessT;
