import { makeStyles } from 'tss-react/mui';

const useHistoryFormViewStyle = makeStyles()((theme) => ({
    container: {},
    title: {},
    groupedFieldTitle: {
        color: theme.palette.secondary.dark,
        marginBottom: '10px',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    titleDark: {
        color: theme.palette.secondary.dark,
    },
    errorCombination: {
        color: theme.palette.error.light,
    },
    groupInputContainer: {
        marginTop: '20px',
    },
    inputContainer: {
        marginBottom: '15px',
    },
    footerButton: {
        marginTop: '20px',
    },
}));

export default useHistoryFormViewStyle;
