/* eslint-disable no-alert */
import useModalWarningApp from '@components/ModalWarningApp/useModalWarningApp';
import store, { AppStateT } from '@store/store';
import { useCallback } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import i18n from 'i18next';

const useAppNavigate = () => {
    const baseNavigate = useNavigate();
    const { openModalWarningApp } = useModalWarningApp();

    const navigate = useCallback(
        async (to: To | number, options?: NavigateOptions): Promise<boolean> => {
            let canNavigate = true;

            // Check the latest state without need to create a new callback
            const { navigatePromptMessage } = (store.getState() as AppStateT).app;

            if (navigatePromptMessage) {
                const { rightButtonClicked } = await openModalWarningApp({
                    title: i18n.t(navigatePromptMessage.title),
                    confirmMessage: i18n.t(navigatePromptMessage.text),
                    rightButtonText: i18n.t('constants.yes'),
                    leftButtonText: i18n.t('constants.cancel'),
                });
                canNavigate = rightButtonClicked;
            }

            if (!canNavigate) {
                return false;
            }

            if (typeof to === 'number' && options !== undefined) {
                console.warn('options has been ignored as you used a delta (number) to navigate ');
            }

            if (typeof to === 'number') {
                baseNavigate(to);
            } else {
                baseNavigate(to, options);
            }
            return true;
        },
        [openModalWarningApp, baseNavigate],
    );

    return navigate;
};

export default useAppNavigate;
