/* eslint-disable @typescript-eslint/no-explicit-any */
import MenuBar from '@components/MenuBar/MenuBar';
import { Trans, useTranslation } from 'react-i18next';
import { Divider, Typography, useTheme } from '@mui/material';
import ColorPatternButtonView from '@components/ColorPatternButton/ColorPatternButtonView';
import useAppNavigate from '@hooks/useAppNavigate';

import useCropsStepStyle from './CropsStepView.style';
import { CropsStepNavStateT, CropsStepViewPropsT } from './CropsStepTypes';
import { FarmSeasonCropT } from '@store/actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import formatNumber from '@utils/formatNumber';

// TODO: Clean this component from filters everywhere (this was a temporary solution)
const CropsStepView = ({ crops, fields, cropColors }: CropsStepViewPropsT): JSX.Element => {
    const { classes } = useCropsStepStyle();
    const { t } = useTranslation();
    const navigate = useAppNavigate();
    const theme = useTheme();

    const getFieldsForCrop = ({ fields, seasonCropId }: { fields: FarmSeasonFieldT[]; seasonCropId: number }) => {
        return fields
            .filter((f) => !f.field_crops?.some((field_crop) => field_crop.farm_season_crop.crop.is_permanent))
            .filter((f) => f.field_crops?.find((fc) => fc.farm_season_crop.id === seasonCropId));
    };

    const seasonCropsWithoutTillage: FarmSeasonCropT[] = crops.filter((c) => {
        if (!c.id) {
            // bad type
            return false;
        }
        const relatedFields = getFieldsForCrop({ fields, seasonCropId: c.id });
        const noTillageFound = relatedFields.find((f) => !f.tillage_id);
        return noTillageFound;
    });

    const remainingSeasonCrops = crops
        .filter((farm_season_crop) => !farm_season_crop.crop.is_permanent)
        .filter(
            (c) => !seasonCropsWithoutTillage.find((seasonCropWithoutTillage) => seasonCropWithoutTillage.id === c.id),
        );

    const isDone = (cropId: any) =>
        !fields
            ?.filter((f) => !f.field_crops?.some((field_crop) => field_crop.farm_season_crop.crop.is_permanent))
            .filter(
                (field) =>
                    field.field_crops
                        ?.filter((field_crop: any) => field_crop.farm_season_crop.id === parseInt(cropId, 10))
                        .filter(
                            (field_crop: any) =>
                                !field_crop.operation_fertilising_done ||
                                !field_crop.operation_sowing_done ||
                                !field_crop.operation_spraying_done ||
                                !field_crop.operation_tillage_done,
                        ).length,
            ).length;

    return (
        <div className={classes.container}>
            <MenuBar className={classes.menuBar} menuTitle={t('pages.operations.crops-step.menu-title')} onClose="/" />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.operations.crops-step.title">
                    Sélectionnez une <span style={{ color: theme.palette.secondary.dark }}>culture</span>
                </Trans>
            </Typography>
            <Typography className={classes.description} color="secondary" component="p" variant="body2">
                <Trans i18nKey="pages.operations.crops-step.description"></Trans>
            </Typography>
            <div className={classes.listContainer}>
                {seasonCropsWithoutTillage.map((seasonCrop) => (
                    <ColorPatternButtonView
                        hasError
                        key={seasonCrop.id}
                        color={cropColors[seasonCrop.crop.id]}
                        name={`${t(seasonCrop.crop.translation_slug || '')}${
                            seasonCrop.is_accompanied ? t('constants.select-companion') : ''
                        }`}
                        taskDone={false}
                        onClick={() => undefined}
                        tooltipSlug="tooltip.errors.tillage-missing-for-some-fields"
                    >
                        <>
                            <Typography color="primary" variant="body1">
                                {t('constants.fields-subtitle', {
                                    count:
                                        fields?.filter(
                                            (field) =>
                                                field.field_crops?.filter(
                                                    (fieldCrop) => fieldCrop.farm_season_crop.id === seasonCrop.id,
                                                ).length,
                                        ).length ?? 0,
                                })}
                            </Typography>
                            <Typography color="primary" variant="body1">
                                {formatNumber(
                                    fields
                                        ?.filter(
                                            (field) =>
                                                field.field_crops?.filter(
                                                    (fieldCrop) => fieldCrop.farm_season_crop.id === seasonCrop.id,
                                                ).length,
                                        )
                                        .reduce((totalArea, field) => totalArea + field.area, 0),
                                    3,
                                )}
                                &nbsp;
                                {t('constants.hectare-unit')}
                            </Typography>
                        </>
                    </ColorPatternButtonView>
                ))}

                {remainingSeasonCrops
                    ?.filter(
                        (crop) =>
                            !!fields?.filter(
                                (field) =>
                                    field.field_crops?.filter(
                                        (fieldCrop) =>
                                            fieldCrop.farm_season_crop.id === crop.id &&
                                            field.tillage_id &&
                                            (!fieldCrop.operation_fertilising_done ||
                                                !fieldCrop.operation_sowing_done ||
                                                !fieldCrop.operation_spraying_done ||
                                                !fieldCrop.operation_tillage_done),
                                    ).length,
                            ).length,
                    )
                    .map((crop) => (
                        <ColorPatternButtonView
                            key={crop.id}
                            color={cropColors[crop.crop.id]}
                            disabled={
                                !fields?.filter(
                                    (field) =>
                                        field.field_crops?.filter(
                                            (fieldCrop) =>
                                                fieldCrop.farm_season_crop.id === crop.id && field.tillage_id,
                                        ).length,
                                ).length
                            }
                            name={`${t(crop.crop.translation_slug || '')}${
                                crop.is_accompanied ? t('constants.select-companion') : ''
                            }`}
                            taskDone={isDone(crop.id)}
                            withStatus
                            onClick={() =>
                                navigate(`/operations/${crop.id}`, {
                                    state: { from: '/operations' } as CropsStepNavStateT,
                                })
                            }
                        >
                            <>
                                <Typography color="primary" variant="body1">
                                    {t('constants.fields-subtitle', {
                                        count:
                                            fields
                                                ?.filter(
                                                    (field) =>
                                                        !field.field_crops?.some(
                                                            (field_crop) =>
                                                                field_crop.farm_season_crop.crop.is_permanent,
                                                        ),
                                                )
                                                .filter(
                                                    (field) =>
                                                        field.field_crops?.filter(
                                                            (fieldCrop) => fieldCrop.farm_season_crop.id === crop.id,
                                                        ).length,
                                                ).length ?? 0,
                                    })}
                                </Typography>
                                <Typography color="primary" variant="body1">
                                    {formatNumber(
                                        fields
                                            ?.filter(
                                                (field) =>
                                                    field.field_crops?.filter(
                                                        (fieldCrop) => fieldCrop.farm_season_crop.id === crop.id,
                                                    ).length,
                                            )
                                            .reduce((totalArea, field) => totalArea + field.area, 0),
                                        3,
                                    )}
                                    &nbsp;
                                    {t('constants.hectare-unit')}
                                </Typography>
                            </>
                        </ColorPatternButtonView>
                    ))}
                {!!remainingSeasonCrops?.filter(
                    (crop) =>
                        !!fields?.filter(
                            (field) =>
                                field.field_crops?.filter(
                                    (fieldCrop) => fieldCrop.farm_season_crop.id === crop.id && field.tillage_id,
                                ).length,
                        ).length && !isDone(crop.id),
                ).length && <Divider className={classes.divider} />}
                {remainingSeasonCrops
                    ?.filter(
                        (crop) =>
                            !fields?.filter(
                                (field) =>
                                    field.field_crops?.filter(
                                        (fieldCrop) => fieldCrop.farm_season_crop.id === crop.id && field.tillage_id,
                                    ).length,
                            ).length || isDone(crop.id),
                    )
                    .map((crop) => (
                        <ColorPatternButtonView
                            key={crop.id}
                            color={cropColors[crop.crop.id]}
                            disabled={
                                !fields?.filter(
                                    (field) =>
                                        field.field_crops?.filter(
                                            (fieldCrop) =>
                                                fieldCrop.farm_season_crop.id === crop.id && field.tillage_id,
                                        ).length,
                                ).length
                            }
                            name={`${t(crop.crop.translation_slug || '')}${
                                crop.is_accompanied ? t('constants.select-companion') : ''
                            }`}
                            taskDone
                            withStatus
                            onClick={() =>
                                navigate(`/operations/${crop.id}`, {
                                    state: { from: '/operations' } as CropsStepNavStateT,
                                })
                            }
                        >
                            <>
                                <Typography color="primary" variant="body1">
                                    {t('constants.fields-subtitle', {
                                        count: fields
                                            ?.filter(
                                                (field) =>
                                                    !field.field_crops?.some(
                                                        (field_crop) => field_crop.farm_season_crop.crop.is_permanent,
                                                    ),
                                            )
                                            ?.filter(
                                                (field) =>
                                                    field.field_crops?.filter(
                                                        (fieldCrop) => fieldCrop.farm_season_crop.id === crop.id,
                                                    ).length,
                                            ).length,
                                    })}
                                </Typography>
                                <Typography color="primary" variant="body1">
                                    {formatNumber(
                                        fields
                                            ?.filter(
                                                (field) =>
                                                    field.field_crops?.filter(
                                                        (fieldCrop) => fieldCrop.farm_season_crop.id === crop.id,
                                                    ).length,
                                            )
                                            .reduce((totalArea, field) => totalArea + field.area, 0),
                                        3,
                                    )}
                                    &nbsp;
                                    {t('constants.hectare-unit')}
                                </Typography>
                            </>
                        </ColorPatternButtonView>
                    ))}
            </div>
        </div>
    );
};

export default CropsStepView;
