import { makeStyles } from 'tss-react/mui';

const useSowingViewStyle = makeStyles()(() => ({
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    mb20: {
        marginBottom: '20px',
    },
    card: {
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.05)',
        marginBottom: '15px',
        padding: '15px',
    },

    inputWeight: {
        width: '100%',
    },

    cardTitle: {
        fontSize: '0.9em',
        lineHeight: 1.5,
        marginBottom: '10px',
    },

    noSowingCheckbox: {
        userSelect: 'none',
    },

    disabledZone: {
        filter: 'opacity(0.5)',
        pointerEvents: 'none',
    },

    radios: {},
}));

export default useSowingViewStyle;
