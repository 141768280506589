import { makeStyles } from 'tss-react/mui';
import { ColorPatternButtonViewStyleProps } from '@components/ColorPatternButton/ColorPatternButtonViewTypes';

const useColorPatternButtonViewStyle = makeStyles<ColorPatternButtonViewStyleProps>()(
    (
        theme,
        {
            color,
            hasPattern,
            backgroundImage,
            border,
            singleLineDescription,
            secondDescription,
            withStatus,
            disabled,
            hasError,
        },
    ) => ({
        card: {
            boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.05)',
            marginBottom: '5px',
            opacity: disabled ? 0.5 : 1,
            border: hasError ? `1px solid ${theme.palette.error.light}` : '',
        },
        container: {
            display: 'grid',
            gridTemplateColumns: secondDescription
                ? '34px calc(70% - 34px - 34px) 30% 34px'
                : withStatus
                ? '34px calc(100% - 34px - 34px - 34px) 34px 34px'
                : '34px calc(100% - 34px - 34px) 34px',
            gridTemplateRows: '34px',
            padding: '15px',
        },
        colorContainer: {
            backgroundColor: color || 'black',
            backgroundImage: hasPattern ? backgroundImage : '',
            borderRadius: '5px',
            width: '34px',
            height: '34px',
            border: `solid ${border ? '2px' : 0} ${theme.palette.primary.main}`,
        },
        textSection: {
            marginLeft: '15px',
        },
        textSectionTitle: {
            lineHeight: 1.3,
        },
        textSectionDescription: {
            display: singleLineDescription ? 'block' : 'grid',
            gridTemplateColumns: '80px calc(100% - 80px)',
        },
        custom: {
            width: '34px',
            height: '34px',
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            fontSize: '2rem',
        },
        iconError: {
            color: theme.palette.error.main,
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        done: {
            color: theme.palette.success.main,
        },
    }),
);

export default useColorPatternButtonViewStyle;
