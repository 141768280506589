import { Typography } from '@mui/material';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import useCallbackLoading from '@hooks/useCallbackLoading';
import useModalBaseLayoutStyle from './ModalBaseLayout.style';
import { ModalBaseLayoutPropsT } from './ModalBaseLayoutTypes';

/** ModalBase is an empty modal. ModalBaseLayout add the title + footer buttons */
export const ModalBaseLayout = ({
    title,
    cancelButton,
    confirmButton,
    actionButton,
    children,
}: ModalBaseLayoutPropsT) => {
    const { classes } = useModalBaseLayoutStyle();
    const { callback: onConfirmCb, loading: onConfirmLoading } = useCallbackLoading({
        callback: confirmButton?.onClick,
    });
    const { callback: onCancelCb, loading: onCancelLoading } = useCallbackLoading({
        callback: cancelButton?.onClick,
    });
    const { callback: onActionCb, loading: onActionLoading } = useCallbackLoading({
        callback: actionButton?.onClick,
    });
    return (
        <div className={classes.ModalBaseLayout}>
            <Typography variant="h4" className={classes.title} textAlign={title.alignment}>
                {title.text}
                {title.help && <HelpButtonView id={title.help} />}
            </Typography>
            <div className={classes.body}>{children}</div>
            <div className={classes.footer}>
                {cancelButton && (
                    <LoadingButton
                        color="inherit"
                        onClick={onCancelCb}
                        loading={onCancelLoading || cancelButton?.loading}
                        className={classes.buttonCancel}
                        text={cancelButton.text}
                    />
                )}
                {actionButton && (
                    <LoadingButton
                        disabled={actionButton?.disabled}
                        color="secondary"
                        onClick={onActionCb}
                        className={classes.actionButton}
                        loading={onActionLoading || actionButton?.loading}
                        text={actionButton.text}
                    />
                )}
                {confirmButton && (
                    <LoadingButton
                        disabled={confirmButton?.disabled}
                        color="primary"
                        onClick={onConfirmCb}
                        loading={onConfirmLoading || confirmButton?.loading}
                        text={confirmButton.text}
                    />
                )}
            </div>
        </div>
    );
};

export default ModalBaseLayout;
