/* eslint-disable @typescript-eslint/no-explicit-any */
import { moveStateData } from '@components/RelationsDndModal/RelationsStep/RelationsStepTypes';
import { Identifier } from 'dnd-core';
import { ConnectDragSource, ConnectDropTarget } from 'react-dnd';

export type DndListItemViewPropsT = {
    text: string;
    isAssociated?: boolean;
    canAssociat?: boolean;
    reference: React.MutableRefObject<HTMLDivElement | null>;
    drag: ConnectDragSource;
    drop: ConnectDropTarget;
    handlerId: Identifier;
    isDragging: boolean;
    moveState: moveStateData | null;
    canDrag: boolean;
    index: number;
    onCoupleClick: () => void;
    deleteCrop: (index: number) => void;
};

export type DndListItemPropsT = {
    text: string;
    isAssociated?: boolean;
    canAssociat?: boolean;
    index: number;
    defineMoveState: (moveStateData: any) => void;
    moveCrop: (dragIndex: number, hoverIndex: number) => void;
    moveState: moveStateData | null;
    canDrag: boolean;
    onCoupleClick: () => void;
    deleteCrop: (index: number) => void;
};

export const CARD = 'card';

export type itemType = {
    index: number;
};
