import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    SeasonGetCallBackT,
    SeasonDispatchTypes,
    SEASON_GET_LOADING,
    SeasonGetSuccessDataT,
    SEASON_GET_SUCCESS,
    SEASON_GET_FAILED,
    SeasonGetFailedDataT,
} from './seasonGetTypes';

const seasonGet =
    (): SeasonGetCallBackT =>
    async (dispatch: Dispatch<SeasonDispatchTypes>): Promise<SeasonGetFailedDataT> => {
        try {
            dispatch({ type: SEASON_GET_LOADING });

            const localSeasons = localStorage.getItem('seasons');

            if (!localSeasons || !Array.isArray(JSON.parse(localSeasons))) {
                const res: AxiosResponse<SeasonGetSuccessDataT> = await StaticApiService.getSeasons();

                localStorage.setItem('seasons', JSON.stringify(res.data));

                dispatch({
                    type: SEASON_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: SEASON_GET_SUCCESS,
                    payload: JSON.parse(localSeasons),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: SEASON_GET_FAILED,
                error: error as SeasonGetFailedDataT,
            });

            return {};
        }
    };

export { seasonGet };
