import { ModalBase } from '@soil-capital/ui-kit';
import BillingDetailsForm from '@components/BillingDetailsForm/BillingDetailsForm';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import { useTranslation } from 'react-i18next';
import { PaymentModalPropsT } from './PaymentModal.types';
import useBillingDetailsPaymentFlow from '@components/BillingDetailsForm/hooks/useBillingDetailsPaymentFlow';

const PaymentModal = ({ paymentModalController, userCompany }: PaymentModalPropsT) => {
    const { t } = useTranslation();
    const { billingDetailsFormRef, onPaymentRedirect, isSubmitting } = useBillingDetailsPaymentFlow({
        type: 'upsell',
        userCompany,
    });

    return (
        <ModalBase width="600px" showCloseButton controller={paymentModalController}>
            <ModalBaseLayout
                title={{
                    text: t('pages.settings.upsell.modal.title'),
                }}
                cancelButton={{
                    text: t('constants.cancel'),
                    onClick: () => paymentModalController.onModalCancel(),
                }}
                confirmButton={{
                    text: t('pages.settings.upsell.modal.submit.button'),
                    onClick: onPaymentRedirect,
                    disabled: isSubmitting,
                }}
            >
                <BillingDetailsForm ref={billingDetailsFormRef} userCompany={userCompany} withUseFarmDataCheckbox />
            </ModalBaseLayout>
        </ModalBase>
    );
};

export default PaymentModal;
