import {
    PostFarmSeasonFieldsCropsResidueDataT,
    PostFarmSeasonFieldsCropsResidueResponseDataT,
} from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsResidueTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsCropsResidueApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsCropsResidueDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsCropsResidueResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/crops/residue`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsCropsResidueApiService;
