import TooltipSc from '@components/TooltipSc/TooltipSc';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InfoTooltipPropsT } from './InfoTooltip.types';
import InfoTooltipStyle from './InfoTooltip.style';

export const InfoTooltip = ({ tooltipScProps, iconSpanStyle }: InfoTooltipPropsT) => {
    const { classes } = InfoTooltipStyle();
    return (
        <TooltipSc {...tooltipScProps} iconSpanStyle={iconSpanStyle} display={'inline-block'}>
            <InfoOutlinedIcon className={classes.infoIcon} />
        </TooltipSc>
    );
};
