import { geoJsonFeatureT } from '@components/Map/MapTypes';
import { FarmSeasonFieldCropDataT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_LOADING =
    'POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_LOADING';
export const POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_FAILED = 'POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_FAILED';
export const POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS =
    'POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS';

export type FarmSeasonFieldsSoilOrganicMatterT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    polygon: geoJsonFeatureT;
    tillage_id?: number | null;
};

export type PostFarmSeasonFieldsSoilOrganicMatterDataT = {
    fields: FarmSeasonFieldsSoilOrganicMatterT[];
};

export type PostFarmSeasonFieldsSoilOrganicMatterResponseDataT = {
    fields: FarmSeasonFieldsSoilOrganicMatterT[];
    installation: {
        onboarding: {
            finished: string[];
            tillage: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsSoilOrganicMatterPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsSoilOrganicMatterDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsSoilOrganicMatterResponseErrorT>;

export type PostFarmSeasonFieldsSoilOrganicMatterResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsSoilOrganicMatterLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_LOADING;
};

export type PostFarmSeasonFieldsSoilOrganicMatterFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_FAILED;
    error: PostFarmSeasonFieldsSoilOrganicMatterResponseErrorT;
};

export type PostFarmSeasonFieldsSoilOrganicMatterSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS;
    payload: PostFarmSeasonFieldsSoilOrganicMatterResponseDataT;
};

export type PostFarmSeasonFieldsSoilOrganicMatterDispatchTypesT =
    | PostFarmSeasonFieldsSoilOrganicMatterLoadingT
    | PostFarmSeasonFieldsSoilOrganicMatterFailedT
    | PostFarmSeasonFieldsSoilOrganicMatterSuccessT;
