import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Trans } from 'react-i18next';
import ColorPatternCardView from '@components/ColorPatternCard/ColorPatternCardView';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Prompt } from '@components/Prompt/Prompt';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import IconEye from '@mui/icons-material/RemoveRedEyeOutlined';
import IconEdit from '@icons/icon-edit.svg';
import useFieldsGroupListStyle from './FieldsGroupListView.style';
import { FieldsGroupListViewPropsT } from './FieldsGroupListTypes';
import { ModalWarning } from '@soil-capital/ui-kit';
import formatNumber from '@utils/formatNumber';

const FieldsGroupListView = ({
    addTillagePractice,
    t,
    loading,
    deleteTillagePracticeHistoryGroups,
    modalDeleteGroupedField,
    modalDeleteAllGroupedField,
    deleteTillagePractice,
    editTillagePractice,
    nothingOnFields,
    setNothingOnFields,
    groupedFields,
    onSubmit,
    keyColor,
    nothingToSave,
    setNothingToSave,
    tillagePracticeList,
    errorNothingOnFields,
    setErrorNothingOnFields,
    canEdit = false,
}: FieldsGroupListViewPropsT): JSX.Element => {
    const { classes } = useFieldsGroupListStyle();

    const groupCardList = () =>
        Object.keys(groupedFields).map((key) => {
            const [yearOfChange, tillageTypeId] = key.split('-');
            const label = t(
                tillagePracticeList.find((tillage) => tillage.id === +tillageTypeId)?.translation_slug || '',
            );
            const fieldLabel = groupedFields[key].length > 1 ? t('constants.fields') : t('constants.field');

            return (
                <ColorPatternCardView
                    key={key}
                    color={keyColor[key]}
                    name={label}
                    onDelete={
                        canEdit ? () => modalDeleteGroupedField.openModal(() => deleteTillagePractice(key)) : undefined
                    }
                    onEdit={() => editTillagePractice(key)}
                    editIcon={canEdit ? IconEdit : IconEye}
                >
                    <>
                        <Typography color="primary" variant="body1">
                            {groupedFields[key].length} {fieldLabel}
                        </Typography>
                        <div className={classes.infoWrapper}>
                            <Typography color="primary" variant="body1">
                                {`${formatNumber(
                                    groupedFields[key].reduce(
                                        (totalArea, field) => totalArea + field.area,
                                        0,
                                    ) as number,
                                    3,
                                )} ${t('constants.hectare-unit')}`}
                            </Typography>
                            <Typography color="primary" variant="body1">
                                {yearOfChange}
                            </Typography>
                        </div>
                    </>
                </ColorPatternCardView>
            );
        });

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!nothingToSave}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.history.tillage-practice.field-group-list.title">
                    Veuillez ajouter vos différents <span className={classes.titleDark}>travail du sol</span>
                </Trans>
                <HelpButtonView id="pages.installationtasks.history.tillage-practice.field-group-list.title" />
            </Typography>
            {Object.keys(groupedFields)?.length > 0 ? (
                <div className={classes.listContainer}>{groupCardList()}</div>
            ) : (
                <div className={classes.emptyFieldContainer}>
                    <Typography color="secondary" variant="body1">
                        {t('pages.installationtasks.history.tillage-practice.field-group-list.no-tillage-practice')}
                    </Typography>
                </div>
            )}

            {canEdit && (
                <Button className={classes.addButton} color="secondary" fullWidth onClick={addTillagePractice}>
                    <AddIcon className={classes.addIcon} />
                    {t('constants.add')}
                </Button>
            )}
            {canEdit && (
                <LabelCheckboxView
                    disabled={!canEdit}
                    border="dotted"
                    checked={nothingOnFields}
                    className={classes.checkbox}
                    error={errorNothingOnFields}
                    helperText={t('pages.installationtasks.history.tillage-practice.check-to-confirm')}
                    label={t(
                        'pages.installationtasks.history.tillage-practice.field-group-list.no-change-tillage-practice',
                    )}
                    name="tillage-checkbox"
                    onChange={(event, checked) => {
                        if (Object.keys(groupedFields).length > 0) {
                            modalDeleteAllGroupedField.openModal(deleteTillagePracticeHistoryGroups);
                            setNothingOnFields(false);
                        } else {
                            setNothingOnFields(checked);
                            setNothingToSave(false);
                        }

                        if (checked) {
                            setErrorNothingOnFields(false);
                        }
                    }}
                />
            )}
            <TwoButtonFooterView
                rightDisabled={nothingToSave}
                rightLoading={loading}
                rightOnCLick={onSubmit}
                rightText={canEdit ? t('constants.save') : ''}
            />

            <ModalWarning
                controller={modalDeleteAllGroupedField}
                confirmMessage={t(
                    'pages.installationtasks.history.tillage-practice.field-group-list.tillage-practice-crop-modal-confirm-message',
                )}
                description={t(
                    'pages.installationtasks.history.tillage-practice.field-group-list.no-tillage-practice-modal-description',
                )}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t(
                    'pages.installationtasks.history.tillage-practice.field-group-list.no-tillage-practice-modal-title',
                )}
            />

            <ModalWarning
                controller={modalDeleteGroupedField}
                confirmMessage={t(
                    'pages.installationtasks.history.tillagePractice.field-group-list.no-tillage-modal-confirm-message-single',
                )}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
            />
        </div>
    );
};

export default FieldsGroupListView;
