import { Dispatch } from '@reduxjs/toolkit';

export const USER_ADD_AMBASSADOR_LOADING = 'USER_ADD_AMBASSADOR_LOADING';
export const USER_ADD_AMBASSADOR_SUCCESS = 'USER_ADD_AMBASSADOR_SUCCESS';
export const USER_ADD_AMBASSADOR_FAILED = 'USER_ADD_AMBASSADOR_FAILED';

export type UserAddAmbassadorSuccessDataT = {
    message: string;
    ambassador_referral_code: string;
};

export type UserAddAmbassadorFailedDataT = {
    message?: string;
    list?: string[];
};

export type UserAddAmbassadorLoadingT = {
    type: typeof USER_ADD_AMBASSADOR_LOADING;
};

export type UserAddAmbassadorSuccessT = {
    type: typeof USER_ADD_AMBASSADOR_SUCCESS;
    payload: UserAddAmbassadorSuccessDataT;
};
export type UserAddAmbassadorFailedT = {
    type: typeof USER_ADD_AMBASSADOR_FAILED;
    error: UserAddAmbassadorFailedDataT;
};

export type UserAddAmbassadorCallbackT = (
    dispatch: Dispatch<UserAddAmbassadorDispatchTypes>,
) => Promise<UserAddAmbassadorFailedDataT | UserAddAmbassadorSuccessDataT>;

export type UserAddAmbassadorDispatchTypes =
    | UserAddAmbassadorLoadingT
    | UserAddAmbassadorSuccessT
    | UserAddAmbassadorFailedT;
