/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

/** manage [data], [isLoading], [error] states by fetching inside a useEffect. */

const useInitialFetch = <R>(
    fetchApiService: () => Promise<AxiosResponse<R> | null>,
    initialData: R,
    deps: any[] = [],
) => {
    const [data, setData] = useState<R | null>(initialData);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    useEffect(() => {
        setIsLoading(true);
        setError(undefined);
        fetchApiService()
            .then((r) => setData(r !== null ? r.data : null))
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    }, deps);

    return { data, isLoading, error, setData };
};

export default useInitialFetch;
