import { makeStyles } from 'tss-react/mui';

const useCollapsePaperViewStyle = makeStyles<{ disabled: boolean; isOpen: boolean }>()(
    (theme, { disabled, isOpen }) => ({
        container: {
            marginBottom: '10px',
        },
        titleBar: {
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: disabled ? theme.palette.grey[400] : theme.palette.primary.main,
            borderRadius: isOpen && !disabled ? '15px 15px 0 0' : '15px',
            marginBottom: isOpen ? '3px' : '0px',
            transition: 'border-radius .5s',
        },
        collapseContainer: {
            padding: '15px',
            borderRadius: '0 0 15px 15px',
        },
        collapseIcon: {
            cursor: disabled ? 'default' : 'pointer',
            '&:hover': {
                color: disabled ? 'inherit' : theme.palette.primary.light,
            },
        },
        progress: {},
    }),
);

export default useCollapsePaperViewStyle;
