import { Grid } from '@mui/material';
import { FieldArray, FormikProvider, getIn } from 'formik';
import AddButtonView from '@components/AddButton/AddButtonView';
import { LoadingButton, ModalWarning } from '@soil-capital/ui-kit';

import useLivestockInstallationStyle from './LivestocksTask.style';
import { LivestockFormPropsT, LivestocksTaskViewPropsT } from './LivestocksTaskTypes';
import LivestockInputContainerView from './LivestockListView/LivestockListView';
import SidePageLeftLayout from '@/layouts/SidePageLeftLayout/SidePageLeftLayout';
import { useTranslation } from 'react-i18next';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { useSelector } from 'react-redux';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';

const LivestockForm = ({ formik, disabledForm }: LivestockFormPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useLivestockInstallationStyle();
    return (
        <Grid spacing={2} container>
            <FormikProvider value={formik}>
                <FieldArray
                    name="livestocks"
                    render={(arrayHelpers) => (
                        <>
                            {formik.values.livestocks.map((livestock, index) => {
                                const key = `livestocks.${index}.key`;
                                const quantityName = `livestocks.${index}.quantity`;
                                const touchedQuantity = getIn(formik.touched, quantityName);
                                const errorQuantity = getIn(formik.errors, quantityName);
                                const livestockIdName = `livestocks.${index}.livestock_id`;
                                const touchedLivestock = getIn(formik.touched, livestockIdName);
                                const errorLivestock = getIn(formik.errors, livestockIdName);
                                const title = `${t('pages.installationtasks.livestock-task.livestock-index')} ${
                                    index + 1
                                }`;

                                return (
                                    <Grid key={key} xs={12} item>
                                        <LivestockInputContainerView
                                            disabledForm={disabledForm}
                                            livestockError={Boolean(touchedLivestock && errorLivestock)}
                                            livestockHelperText={
                                                touchedLivestock && errorLivestock ? errorLivestock : ''
                                            }
                                            livestockId={livestock.livestock_id}
                                            quantity={livestock.quantity}
                                            quantityError={Boolean(touchedQuantity && errorQuantity)}
                                            quantityHelperText={touchedQuantity && errorQuantity ? errorQuantity : ''}
                                            quantityName={quantityName}
                                            title={title}
                                            usedLivestock={formik.values.livestocks}
                                            onClose={disabledForm ? undefined : () => arrayHelpers.remove(index)}
                                            onLivestockChange={(livestockId) => {
                                                formik.setFieldValue(livestockIdName, livestockId);
                                                if (formik.values.option !== 'yes') {
                                                    formik.setFieldValue('option', 'yes');
                                                }
                                            }}
                                            onQuantityChange={(value) => formik.setFieldValue(quantityName, value)}
                                        />
                                    </Grid>
                                );
                            })}
                            <Grid xs={12} item>
                                {!disabledForm ? (
                                    <AddButtonView
                                        label={t('pages.installationtasks.livestock-task.add-new-livestock-type')}
                                        onClick={() => arrayHelpers.push({ quantity: '', livestock_id: '' })}
                                        className={classes.addLivestockButton}
                                    />
                                ) : null}
                            </Grid>
                        </>
                    )}
                />
            </FormikProvider>
        </Grid>
    );
};

const LivestocksTaskView = ({
    formik,
    modalFieldsNotEligibleController,
    modalLivestockNotEligibleController,
    modalRemoveLivestock,
    livestockListLoading,
}: LivestocksTaskViewPropsT): JSX.Element => {
    const { t } = useTranslation();
    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <SidePageLeftLayout
            onClose=".."
            onArrowBack=".."
            pageTitle={t('pages.installationtasks.livestock-task.menu-title')}
            titleSlug={
                isBaselineSeason
                    ? 'pages.installationtasks.livestock-task.tell-us-aboutg-your-livestock-and-quantities'
                    : 'pages.installationtasks.livestock-task.tell-us-aboutg-your-livestock-and-quantities-yoy'
            }
            showTitleHelp="pages.installationtasks.livestock-task.tell-us-aboutg-your-livestock-and-quantities"
            TwoButtonFooterViewProps={{
                rightButtonOverride: (
                    <RestrictSaveFarmSeason>
                        <LoadingButton
                            loading={formik.isSubmitting}
                            text={t('constants.save')}
                            onClick={() => formik.handleSubmit()}
                        />
                    </RestrictSaveFarmSeason>
                ),
            }}
            displayPromptOnLeave={formik.dirty && !formik.isSubmitting}
            loading={livestockListLoading}
        >
            <LivestockForm formik={formik} disabledForm={!canEditFarmSeason.access} />
            <LabelCheckboxView
                disabled={!canEditFarmSeason.access}
                border="dotted"
                checked={formik.values.option === 'no'}
                error={formik.touched.livestocks && Boolean(formik.errors.option)}
                helperText={formik.touched.option ? formik.errors.option : undefined}
                label={t('pages.installationtasks.livestock-task.no-livestock')}
                onChange={(event, checked) => {
                    if (!checked) {
                        formik.setFieldValue('option', 'yes');
                    } else if (formik.values.livestocks.filter((l) => !!l.livestock_id).length) {
                        modalRemoveLivestock.openModal(() => {
                            formik.setValues({ livestocks: [], option: 'no' });
                        });
                    } else {
                        formik.setValues({ livestocks: [], option: 'no' });
                    }
                }}
            />
            <ModalWarning
                controller={modalRemoveLivestock}
                confirmMessage={t('pages.installationtasks.livestock-task.modal-remove-encoded.confirm-message')}
                description={t('pages.installationtasks.livestock-task.modal-remove-encoded.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.livestock-task.modal-remove-encoded.title')}
            />

            <ModalWarning
                confirmMessage={t('pages.installationtasks.livestock-task.modal-fields-not-eligible.confirm-message')}
                controller={modalFieldsNotEligibleController}
                description={t('pages.installationtasks.livestock-task.modal-fields-not-eligible.description')}
                leftButtonText={t('constants.no-didnt')}
                rightButtonText={t('constants.yes')}
                title={t('pages.installationtasks.livestock-task.modal-fields-not-eligible.title')}
            />
            <ModalWarning
                confirmMessage={t(
                    'pages.installationtasks.livestock-task.modal-livestock-not-eligible.confirm-message',
                )}
                controller={modalLivestockNotEligibleController}
                description={t('pages.installationtasks.livestock-task.modal-livestock-not-eligible.description')}
                rightButtonText={t('constants.ok')}
                title={t('pages.installationtasks.livestock-task.modal-livestock-not-eligible.title')}
            />
        </SidePageLeftLayout>
    );
};

export default LivestocksTaskView;
