import { useState } from 'react';
import MenuBar from '@components/MenuBar/MenuBar';
import { Autocomplete, Card, Modal, Paper, SvgIcon, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import InputCardView from '@components/InputCard/InputCardView';
import AddButtonView from '@components/AddButton/AddButtonView';
import { FieldArray, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import WarningMessageView from '@components/WarningMessage/WarningMessageView';
import { DataGrid, GridSortItem } from '@mui/x-data-grid';
import IconDelete from '@icons/icon-delete.svg';
import useLoader from '@hooks/useLoader';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import { LoadingButton, useModal } from '@soil-capital/ui-kit';
import useAppDispatch from '@hooks/useAppDispatch';

import { DeleteFarmSeasonOperation } from '@actions/FarmSeasonOperation/DeleteFarmSeasonOperation';
import { PesticideT } from '@reducers/StaticReducer/StaticReducerType';
import { Prompt } from '@components/Prompt/Prompt';

import { SprayingTaskRouteParamsT, SprayingViewPropsT } from './SprayingTypes';
import useSprayingViewStyle from './SprayingView.style';
import { ModalWarning } from '@soil-capital/ui-kit';

/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
const SprayingView = ({
    dataLoading,
    pesticides,
    formik,
    menuTitle,
    onPesticideChange,
    onClickAddPesticide,
    onClickDeletePesticide,
    summaryTable,
    modalWarning,
    submitted,
    farmId,
    farmSeasonId,
    canEdit = false,
}: SprayingViewPropsT): JSX.Element => {
    const { classes } = useSprayingViewStyle();
    const navigate = useAppNavigate();
    const { t } = useTranslation();
    const { cropId, tillageId } = useParams<SprayingTaskRouteParamsT>();

    const [modalWarningIsChecked, setModalWarningIsChecked] = useState(false);
    const [modalOpenState, setOpenModal] = useState(false);
    const openModal = () => setOpenModal(true);
    const closeModal = () => setOpenModal(false);
    const modalDeleteOperation = useModal();
    const dispatch = useAppDispatch();

    const [summaryTableSorting, setSummaryTableSorting] = useState<GridSortItem[]>([
        { field: 'numberOfUse', sort: 'desc' },
    ]);

    const sortedPesticides = pesticides ? [...pesticides] : [];

    sortedPesticides.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    const { containerRef: loaderRef } = useLoader(dataLoading);

    return (
        <>
            <div ref={loaderRef} className={classes.container}>
                <Prompt
                    messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                    messageText={t('modal.navigation-prompt-if-unsaved.text')}
                    when={formik.dirty && !submitted}
                />
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={menuTitle}
                    onArrowBack={() => navigate(-1)}
                    onClose={`/operations/${cropId}/${tillageId}`}
                />

                <Typography className={classes.title} color="secondary" variant="h4">
                    {t('pages.operations.spraying.title')}
                </Typography>

                <Typography color="secondary" variant="body1">
                    {t('pages.operations.spraying.description')}
                </Typography>

                <div className={classes.formContainer}>
                    <FormikProvider value={formik}>
                        {/* basic inputs */}
                        <InputCardView
                            InputProps={{
                                disabled: !canEdit,
                                endAdornment: t('constants.unit.passage', { count: +formik.values.passage }),
                            }}
                            className={classes.inputCard}
                            disabled={formik.values.dontHaveAny}
                            loading={dataLoading}
                            type="number"
                            value={formik.values.passage}
                            autoFocus
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'passage',
                                    Number(event.target.value) >= 0 ? parseInt(event.target.value) : '',
                                )
                            }
                        >
                            <Typography variant="body1">{t('operation.spraying.passage')}</Typography>
                        </InputCardView>
                        <InputCardView
                            InputProps={{
                                disabled: !canEdit,
                                endAdornment: t('constants.unit.meter', { count: +formik.values.width }),
                            }}
                            className={classes.inputCard}
                            disabled={formik.values.dontHaveAny}
                            loading={dataLoading}
                            type="number"
                            value={formik.values.width}
                            onChange={(event) =>
                                formik.setFieldValue(
                                    'width',
                                    Number(event.target.value) > 0 ? parseInt(event.target.value) : '',
                                )
                            }
                        >
                            <Typography variant="body1">{t('operation.spraying.width')}</Typography>
                        </InputCardView>

                        {/* pesticide list */}
                        <Card className={classes.card} elevation={0} variant="elevation">
                            <div className={classes.cardContainer}>
                                <div className={classes.textSection}>
                                    <Typography variant="body1">
                                        {t('operation.spraying.pesticides')}
                                        <HelpButtonView id="operation.spraying.products-used" />
                                    </Typography>
                                </div>
                                <FieldArray
                                    name="pesticides"
                                    render={(arrayHelpers) =>
                                        formik.values.pesticides.map((pesticide, index) => {
                                            const fieldName = `pesticides.${index}.pesticide_id`;
                                            const fieldPesticide = pesticides?.filter(
                                                (p) => p.id === pesticide.pesticide_id,
                                            )[0];
                                            const initUnit = fieldPesticide?.unit || t('constants.unit.qty');

                                            return (
                                                <div key={`pesticide-${index + 1}`} className={classes.productRow}>
                                                    <div className={classes.productInputRow}>
                                                        <div className={classes.autocompleteSection}>
                                                            <Autocomplete
                                                                ListboxProps={{
                                                                    style: { maxHeight: '200px', overflowX: 'hidden' },
                                                                }}
                                                                disabled={formik.values.dontHaveAny || !canEdit}
                                                                getOptionDisabled={(option) => option.id === 0}
                                                                getOptionLabel={(option: PesticideT) =>
                                                                    option?.name ? option.name : ''
                                                                }
                                                                // getOptionSelected={(option, value) => option.id === value?.id}
                                                                options={sortedPesticides || []}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        autoFocus={index !== 0}
                                                                        name={fieldName}
                                                                        placeholder={t('constants.search-product')}
                                                                        size="small"
                                                                        variant="outlined"
                                                                    />
                                                                )}
                                                                value={fieldPesticide || null}
                                                                onChange={(event, value) => {
                                                                    onPesticideChange?.(fieldName, value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={classes.inputContainer}>
                                                            <TextField
                                                                InputProps={{
                                                                    disabled: !canEdit,
                                                                    endAdornment: `${
                                                                        formik.values.pesticides[index].unit || initUnit
                                                                    }/${t('constants.hectare-unit')}`,
                                                                }}
                                                                disabled={formik.values.dontHaveAny}
                                                                inputProps={{
                                                                    style: { paddingRight: '5px' },
                                                                }}
                                                                name={`pesticides.${index}.quantity`}
                                                                type="number"
                                                                value={formik.values.pesticides[index].quantity}
                                                                variant="outlined"
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.value &&
                                                                        new RegExp(/^\d+((\.|,)\d{0,3})?$/).test(
                                                                            event.target.value,
                                                                        )
                                                                    ) {
                                                                        formik.setFieldValue(
                                                                            `pesticides.${index}.quantity`,
                                                                            event.target.value,
                                                                        );
                                                                    } else if (!event.target.value) {
                                                                        formik.setFieldValue(
                                                                            `pesticides.${index}.quantity`,
                                                                            '',
                                                                        );
                                                                    }
                                                                }}
                                                                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                                                            />
                                                        </div>
                                                        {canEdit && (
                                                            <div className={classes.deleteIconWrap}>
                                                                <SvgIcon
                                                                    className={`${classes.deleteIcon} ${
                                                                        index === 0 &&
                                                                        formik?.values?.pesticides?.length <= 1 &&
                                                                        classes.deleteIconDisabled
                                                                    }`}
                                                                    color="primary"
                                                                    component={IconDelete}
                                                                    onClick={() =>
                                                                        onClickDeletePesticide?.(
                                                                            arrayHelpers,
                                                                            index,
                                                                            index > 0 ||
                                                                                formik?.values?.pesticides?.length > 1,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {canEdit && formik.values.pesticides.length - 1 === index ? (
                                                        <div
                                                            className={`${classes.productInputRow} ${classes.addBtnSection}`}
                                                        >
                                                            <AddButtonView
                                                                className={classes.addBtnView}
                                                                disabled={
                                                                    pesticide.pesticide_id === 0 ||
                                                                    pesticide.quantity <= 0
                                                                }
                                                                label={t('constants.add')}
                                                                onClick={() => onClickAddPesticide?.(arrayHelpers)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            );
                                        })
                                    }
                                />
                            </div>
                        </Card>
                        {canEdit && (
                            <LabelCheckboxView
                                border="dotted"
                                checked={formik.values.dontHaveAny}
                                className={classes.checkbox}
                                disabled={dataLoading || !canEdit}
                                label={t('pages.operations.spraying.no-operation')}
                                onChange={(e, value) => formik.setFieldValue('dontHaveAny', value)}
                            />
                        )}
                        <TwoButtonFooterView
                            leftOnClick={() =>
                                tillageId &&
                                cropId &&
                                modalDeleteOperation.openModal(async () => {
                                    await dispatch(
                                        DeleteFarmSeasonOperation(farmId, farmSeasonId, 'spraying', tillageId, cropId),
                                    );
                                    navigate(-1);
                                })
                            }
                            leftText={canEdit ? t('constants.delete-operation') : undefined}
                            rightDisabled={dataLoading}
                            rightLoading={formik.isSubmitting}
                            rightOnCLick={() => (formik.values.dontHaveAny ? formik.submitForm() : openModal())}
                            rightText={canEdit ? t('constants.save') : t('constants.see-details')}
                        />
                    </FormikProvider>
                </div>
                <Modal className={classes.modal} open={modalOpenState} keepMounted onClose={closeModal}>
                    <Paper className={classes.modalPaper} elevation={0}>
                        <div className={classes.modalHeader}>
                            <CloseIcon className={classes.modalClose} onClick={closeModal} />
                            <Typography color="secondary" variant="h4">
                                {t('pages.operations.spraying.modal-title')}
                            </Typography>
                            <Typography className={classes.modalIntroText} variant="body2">
                                {t('pages.operations.spraying.modal-intro')}
                            </Typography>
                        </div>
                        <div className={classes.modalContent}>
                            <div className={classes.tableWrapper}>
                                <DataGrid
                                    columns={summaryTable?.columns ?? []}
                                    density="compact"
                                    rows={summaryTable?.rows ?? []}
                                    sortModel={summaryTableSorting}
                                    hideFooter
                                    onSortModelChange={(model) => setSummaryTableSorting(model)}
                                />
                            </div>
                        </div>
                        <div className={classes.modalFooter}>
                            {modalWarning && (
                                <WarningMessageView
                                    checkBoxMessage={t(
                                        'pages.operations.spraying.modal-error-greater-values-confirm-cta',
                                    )}
                                    containerClass={classes.warningBox}
                                    isChecked={modalWarningIsChecked}
                                    message={t('pages.operations.spraying.modal-error-greater-values')}
                                    onCheck={(isChecked) => setModalWarningIsChecked(isChecked)}
                                />
                            )}
                            <TwoButtonFooterView
                                leftOnClick={closeModal}
                                leftText={t('constants.back')}
                                rightButtonOverride={
                                    canEdit ? (
                                        <LoadingButton
                                            disabled={
                                                !formik.isValid ||
                                                dataLoading ||
                                                (modalWarning && !modalWarningIsChecked)
                                            }
                                            loading={formik.isSubmitting}
                                            onClick={() => formik.submitForm()}
                                            text={t('constants.validate')}
                                        />
                                    ) : null
                                }
                            />
                        </div>
                    </Paper>
                </Modal>
            </div>
            <ModalWarning
                controller={modalDeleteOperation}
                description={t('modal.delete-operation.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('modal.warning.title')}
            />
        </>
    );
};
// TODO: Unify the modals with fertilisation

export default SprayingView;
