import React, { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import {
    Grid,
    Typography,
    AppBar,
    Tabs,
    Tab,
    Card,
    Divider,
    Select,
    FormControl,
    SelectChangeEvent,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {
    ResultViewPropsT,
    SingleBarChartPropsT,
    SingleBarChartValuesDataT,
    SvgChartPropsT,
    TabPanelPropsT,
} from '@pages/HomePage/Result/ResultTypes';
import Map from '@components/Map/Map';
import { Marker } from '@urbica/react-map-gl';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import palette from '@constants/palette/crop.palette';
import _ from 'lodash';
import { ReactSVG } from 'react-svg';
import { useInView } from 'react-intersection-observer';
import getCropSlug from '@utils/getCropSlug';
import FullPageLayout from '@/layouts/FullPageLayout/FullPageLayout';

import useResultStyle from './ResultView.style';
import formatNumber from '@utils/formatNumber';

/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */

function TabPanel(props: TabPanelPropsT) {
    const { children, value, index, ...other } = props;
    const { classes } = useResultStyle();

    return (
        <div
            aria-labelledby={`results-tab-${index}`}
            className={classes.tabContentContainer}
            hidden={value !== index}
            id={`results-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index: number) {
    return { id: `results-tab-${index}`, 'aria-controls': `results-tabpanel-${index}` };
}

function SingleBarChart(props: SingleBarChartPropsT) {
    const { t } = useTranslation();
    const { chartValues } = props;
    const crops = useSelector((state: HomePageStateT) => state.static.crops);
    const { classes } = useResultStyle();
    const pickedColors = [palette[5], palette[15], palette[10], palette[7], palette[22], palette[23], palette[12]];
    const colors = _.uniq(_.union(pickedColors, palette));

    return (
        <>
            <div className={classes.mapChartBar}>
                {chartValues.map((values: SingleBarChartValuesDataT, index) => (
                    <div
                        key={`results-map-bar-chart-block-${values.crop_id}%`}
                        className="block"
                        style={{ width: `${values.percentage}%`, backgroundColor: colors[index] }}
                        title={t(getCropSlug(crops, values.crop_id))}
                    >
                        <span className="value">{values.percentage}%</span>
                    </div>
                ))}
            </div>
            <div className={classes.mapChartLegend}>
                {chartValues.map((values: SingleBarChartValuesDataT) => (
                    <Typography
                        key={`results-map-bar-chart-legend-${values.crop_id}%`}
                        className="block"
                        style={{ width: `${values.percentage}%` }}
                    >
                        <span className="value">{t(getCropSlug(crops, values.crop_id))}</span>
                    </Typography>
                ))}
            </div>
        </>
    );
}

function SvgChart(props: SvgChartPropsT) {
    const { src } = props;
    const { classes } = useResultStyle();
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
    });

    return (
        <div
            ref={ref}
            className={`${classes.svgChart} ${inView ? 'animate' : ''}`}
            style={{
                opacity: inView ? 1 : 0,
                transform: `perspective(400px) translate3d(0,${inView ? '0,0' : '30px,5px'})`,
            }}
        >
            <ReactSVG renumerateIRIElements={false} useRequestCache={false} src={src} />
        </div>
    );
}

const ResultView = ({
    defaultMapCenter,
    defaultMapZoom,
    resultsData,
    carbonTrajectory,
    mapChartData,
    mapShow,
    baseUrlSvg,
    totalLivestockEmissions,
    cropNetEmissions,
}: ResultViewPropsT): JSX.Element => {
    const { i18n } = useTranslation();
    const { classes } = useResultStyle();
    const { t } = useTranslation();
    const scrollToElement = useRef<HTMLDivElement>(null);
    const crops = useSelector((state: HomePageStateT) => state.static.crops);

    const [value, setValue] = React.useState(0);
    const [cropSelect, setCropSelect] = React.useState('');
    const [factorSelect, setFactorSelect] = React.useState('');

    const layoutEl = useMemo(() => document.getElementById('FullPageLayout'), []);

    const executeScroll = () => {
        setTimeout(() => layoutEl?.scrollTo({ top: (scrollToElement.current?.offsetTop ?? 0) + 20 }), 1);
    };

    const onTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        executeScroll();
    };

    const handleCropSelect = (event: SelectChangeEvent) => {
        setCropSelect(event.target.value as string);
    };

    const handleFactorSelect = (event: SelectChangeEvent) => {
        setFactorSelect(event.target.value as string);
    };

    const translateCropIds = (cropIds: number[]) => {
        const output: string[] = [];

        cropIds.map((cropId) => output.push(t(getCropSlug(crops, cropId))));

        return output.join(', ');
    };

    /**
     * generate 1 of those slugs depending on params:
     * pages.results.service.key_figures_baseline_bad_toptext,
     * pages.results.service.key_figures_baseline_bad_bottomtext,
     * pages.results.service.key_figures_baseline_good_toptext,
     * pages.results.service.key_figures_baseline_good_bottomtext,
     * pages.results.service.key_figures_renewal_bad_toptext,
     * pages.results.service.key_figures_renewal_bad_bottomtext
     * pages.results.service.key_figures_renewal_good_toptext,
     * pages.results.service.key_figures_renewal_good_bottomtext,
     */
    const getKeyFiguresSlug = (textPosition: 'bottom' | 'top') => {
        if (!carbonTrajectory) {
            return '';
        }
        return `pages.results.service.key_figures_${carbonTrajectory.is_baseline ? 'baseline' : 'renewal'}_${
            carbonTrajectory.is_trajectory_good ? 'good' : 'bad'
        }_${textPosition}text`;
    };

    return (
        <FullPageLayout>
            <>
                <Typography className={classes.title} color="secondary" variant="h4">
                    {t('pages.results.title')} <HelpButtonView id="pages.results.title" size="large" />
                </Typography>

                <br />

                <Grid spacing={6} container>
                    <Grid alignContent="center" alignItems="center" xs={7} container item>
                        <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                            {t('pages.results.my-results')}
                        </Typography>
                        <Typography className={classes.date} variant="h6">
                            {`${t('pages.results.date.generated-at')} ${new Date(
                                resultsData.generated_at,
                            ).toLocaleString()}`}
                        </Typography>
                        <Typography className={classes.introText}>
                            <Trans i18nKey="pages.results.introtext" />
                        </Typography>
                    </Grid>
                    {getKeyFiguresSlug('top') && (
                        <Grid alignContent="center" alignItems="center" xs={5} container item>
                            <Card className={`${classes.figure} ${classes.mainFigure}`} elevation={7}>
                                <Typography color="secondary" variant="h4">
                                    <Trans i18nKey={getKeyFiguresSlug('top')} />
                                </Typography>
                                <Grid className={classes.innerFigure} container>
                                    <Grid className="left" xs={5} item>
                                        {resultsData.texts.key_figures_top.mood === 'good' && (
                                            <img
                                                alt="figure1"
                                                src="/assets/images/award-gold.png"
                                                style={{ width: '80%' }}
                                            />
                                        )}
                                        <img alt="logo-white" src="/assets/images/msc-logo-white.png" />
                                    </Grid>
                                    <Grid className="right" xs={7} container item>
                                        <Grid xs={6} item>
                                            {carbonTrajectory?.total_emission !== undefined && (
                                                <Typography className={'value-align'} variant="body2">
                                                    <strong className={'big-value'}>
                                                        {`${formatNumber(carbonTrajectory.average_emission, 2)}`}
                                                    </strong>
                                                    <br />t CO2e/ha
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid xs={6} item>
                                            {carbonTrajectory?.total_emission !== undefined && (
                                                <Typography className={'value-align'} variant="body2">
                                                    <strong className={'big-value'}>
                                                        {`${formatNumber(carbonTrajectory?.total_emission, 0)}`}
                                                    </strong>
                                                    <br />t CO2e total
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Typography variant="body1">
                                            <Grid className="key-text value-align" xs={12} item>
                                                {resultsData?.texts?.key_figures_bottom && (
                                                    <>
                                                        <br />
                                                        <Trans
                                                            i18nKey={getKeyFiguresSlug('bottom')}
                                                            values={{
                                                                // Looks to be not used
                                                                average_emission_value: formatNumber(
                                                                    carbonTrajectory?.average_emission,
                                                                ),
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </Grid>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                <br />
                <br />

                <Grid spacing={3} container>
                    <Grid xs={12} item>
                        <Card className={classes.map} elevation={7}>
                            {!_.isNull(defaultMapCenter) &&
                            !_.isNull(defaultMapCenter.geometry.coordinates) &&
                            mapShow ? (
                                <>
                                    <Map
                                        id="map-results-benchmark"
                                        initAnimation={false}
                                        interactive={false}
                                        latitude={defaultMapCenter.geometry.coordinates[1]}
                                        longitude={defaultMapCenter.geometry.coordinates[0]}
                                        offsetX={250}
                                        zoom={defaultMapZoom}
                                    >
                                        <Marker
                                            anchor="center"
                                            latitude={defaultMapCenter.geometry.coordinates[1]}
                                            longitude={defaultMapCenter.geometry.coordinates[0]}
                                            pitchAlignment="auto"
                                            rotation={0}
                                            rotationAlignment="auto"
                                        >
                                            <div className={classes.mapCenterCircle}>
                                                <div className={classes.mapCenterPoint} />
                                                <div className={classes.mapMarker}>
                                                    {t('pages.results.tab-my-farm.title')}
                                                    <div className="arrow" />
                                                </div>
                                            </div>
                                        </Marker>
                                    </Map>
                                    {/* <div className={classes.mapCenterCircleFake}>
                  .
                  </div> */}
                                </>
                            ) : (
                                <Typography className={classes.wrongFarmCoordinates} color="error" variant="h5">
                                    {t('component.map.wrong-farm-coordinates')}
                                </Typography>
                            )}

                            <div className={classes.mapChart}>
                                <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                    {t('pages.results.map-box-title')}
                                </Typography>
                                <Typography className={classes.introText}>
                                    <Trans
                                        i18nKey={`pages.results.service.${resultsData.texts.map_data.slug}`}
                                        values={{
                                            farmCount: formatNumber(resultsData.texts.map_data.values.farmCount),
                                            medianArea: formatNumber(resultsData.texts.map_data.values.medianArea),
                                            totalArea: formatNumber(resultsData.texts.map_data.values.totalArea),
                                        }}
                                    />
                                </Typography>
                                <Typography
                                    className={classes.secondaryTitle}
                                    color="secondary"
                                    style={{ marginTop: '30px' }}
                                    variant="h4"
                                >
                                    {t('pages.results.map-chart-title')}
                                </Typography>
                                <div className="flex-break" />
                                <SingleBarChart chartValues={mapChartData} />
                            </div>
                        </Card>
                    </Grid>
                </Grid>

                <div ref={scrollToElement} />
                <br />
                <br />

                <AppBar elevation={0} position="static">
                    <Tabs textColor="secondary" value={value} onChange={onTabChange}>
                        <Tab label={t('pages.results.tab-my-farm.title')} disableRipple {...a11yProps(0)} />
                        <Tab label={t('pages.results.tab-my-group-and-me')} disableRipple {...a11yProps(1)} />
                        <Tab label={t('pages.results.tab-sequestrations')} disableRipple {...a11yProps(2)} />
                        <Tab label={t('pages.results.tab-emissions.title')} disableRipple {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel index={0} value={value}>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                {t('pages.results.tab.my-farm.title-compare')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid xs={6} container item>
                            <SvgChart src={`${baseUrlSvg}/evolution_GHGBalance_time/${i18n.language}`} />
                        </Grid>
                        <Grid xs={6} container item>
                            <Typography className={classes.graphText}>
                                <Trans
                                    i18nKey={`pages.results.service.${resultsData.texts.baseline_GHG_explanatory.slug}`}
                                    values={{
                                        regional_average: formatNumber(
                                            resultsData.texts.baseline_GHG_explanatory.values.regional_average,
                                        ),
                                        region: formatNumber(resultsData.texts.baseline_GHG_explanatory.values.region),
                                    }}
                                />
                                <br />
                                <br />
                                <Trans
                                    i18nKey={`pages.results.service.${resultsData.texts.evolution_GHGBalance_time.slug}`}
                                    values={{
                                        average_emission_value: formatNumber(
                                            resultsData.texts.evolution_GHGBalance_time.values.average_emission_value,
                                        ),
                                    }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} style={{ marginTop: '20px' }} container>
                        <Grid xs={6} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                {t('pages.results.tab.my-farm.title-graph2-left')}
                            </Typography>
                        </Grid>
                        <Grid xs={6} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                {t('pages.results.tab.my-farm.title-graph2-right')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <SvgChart src={`${baseUrlSvg}/totalandnet_GHGBalance_dotplot/${i18n.language}`} />
                        </Grid>
                    </Grid>
                    <Grid spacing={6} style={{ marginTop: '20px' }} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                {t('pages.results.tab.my-farm.title-crops')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <SvgChart src={`${baseUrlSvg}/netGHGBalance_treemap/${i18n.language}`} />
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid xs={6} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                <Trans
                                    i18nKey={`pages.results.service.${resultsData.texts.net_GHGBalance_treemap.slug}`}
                                    values={{
                                        areaPerc: resultsData.texts.net_GHGBalance_treemap.values.areaPerc,
                                        crop_id: t(
                                            getCropSlug(crops, resultsData.texts.net_GHGBalance_treemap.values.crop_id),
                                        ),
                                        ghgBalancePerc: resultsData.texts.net_GHGBalance_treemap.values.ghgBalancePerc,
                                    }}
                                />
                            </Typography>
                        </Grid>
                        <Grid xs={6} container item>
                            <Typography variant="body2">
                                {t('pages.results.tab.my-farm.text-figure-treemap')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} container item>
                            <Typography color="secondary.dark" variant="h5" width="100%">
                                {t('pages.results.tab-my-farm.livestock-emissions')}:{' '}
                                <Typography display="inline-block" variant="h5">
                                    {formatNumber(totalLivestockEmissions, 2)} t CO2e
                                </Typography>
                            </Typography>
                            <Typography color="secondary.dark" variant="h5" width="100%">
                                {t('pages.results.tab-my-farm.net-results')}:{' '}
                                {carbonTrajectory?.total_emission && (
                                    <Typography display="inline-block" variant="h5">
                                        {formatNumber(carbonTrajectory?.total_emission, 2)} t CO2e
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel index={1} value={value}>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                {t('pages.results.tab.benchmark.title-compare')}
                            </Typography>
                            <div className="flex-break" />
                            <Typography className={classes.benchmarkTextCompare} color="text.primary" variant="body2">
                                <Trans i18nKey="pages.results.tab.benchmark.text-compare" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <SvgChart src={`${baseUrlSvg}/netGHGBalance_garmin/${i18n.language}`} />
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.graphText}>
                                <Trans
                                    i18nKey={`pages.results.service.${resultsData.texts.net_GHGBalance_garmin.slug}`}
                                    values={{
                                        myFarm_ghgBalance: formatNumber(
                                            resultsData.texts.net_GHGBalance_garmin.values.myFarm_ghgBalance,
                                        ),
                                        performanceCount: formatNumber(
                                            resultsData.texts.net_GHGBalance_garmin.values.performanceCount,
                                        ),
                                        performancePerc: resultsData.texts.net_GHGBalance_garmin.values.performancePerc,
                                    }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} style={{ marginTop: '30px' }} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Card
                                className={`${classes.figure} ${classes.mainFigure}`}
                                elevation={7}
                                style={{ paddingBottom: 0, marginBottom: '30px' }}
                            >
                                <Typography color="secondary" variant="h4">
                                    {t('pages.results.tab.benchmark.achievements-title')}
                                </Typography>
                                <div className="flex-break" />
                                <Typography variant="body2">
                                    {t('pages.results.tab.benchmark.achievements-text')}
                                </Typography>
                                <div className="flex-break" />
                                <SvgChart src={`assets/images/achievements_${i18n.language}.svg`} />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                {t('pages.results.tab.benchmark.title-crops')}
                            </Typography>
                            <div className="flex-break" />
                            <Typography variant="body2">{t('pages.results.tab.benchmark.text-crops')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <SvgChart src={`${baseUrlSvg}/netMyFarmBench_GHGBalance_points/${i18n.language}`} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel index={2} value={value}>
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                {t('pages.results.tab.sequestration.title-compare')}
                            </Typography>
                            <div className="flex-break" />
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                {t('pages.results.tab.sequestration.subtitle-cover-crops')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid xs={6} container item>
                            <SvgChart src={`${baseUrlSvg}/use_covercrop_garmin/${i18n.language}`} />
                        </Grid>
                        <Grid xs={6} container item>
                            <Typography className={classes.graphText}>
                                <Trans
                                    i18nKey={`pages.results.service.${resultsData.texts.use_covercrop_garmin.slug}`}
                                    values={{
                                        coveredPerc: resultsData.texts.use_covercrop_garmin.values.coveredPerc,
                                        performanceCount:
                                            resultsData.texts.use_covercrop_garmin.values.performanceCount,
                                        performancePerc: resultsData.texts.use_covercrop_garmin.values.performancePerc,
                                        benchWithCCPerc: resultsData.texts.use_covercrop_garmin.values.benchWithCCPerc,
                                    }}
                                />
                                <br />
                                <br />
                                {resultsData.texts.extra_details_covercrop.slug_list.map((extra, i) => (
                                    <>
                                        <Trans
                                            i18nKey={`pages.results.service.${extra.slug}`}
                                            values={{
                                                crop_group:
                                                    i18n.language === 'fr'
                                                        ? resultsData.texts.extra_details_covercrop.slug_list_fr[i]
                                                              .values.crop_group
                                                        : extra.values.crop_group,
                                                myFarm_crop_ids: translateCropIds(extra.values.myFarm_crop_ids),
                                                myFarm_cropGroup_cc_perc: extra.values.myFarm_cropGroup_cc_perc,
                                                bench_cropGroup_cc_perc: extra.values.bench_cropGroup_cc_perc,
                                            }}
                                        />
                                        <br />
                                        <br />
                                    </>
                                ))}
                                <Trans i18nKey="pages.results.static_use_covercrop_garmin" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.hr} />
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                {t('pages.results.tab.sequestration.subtitle-tillage')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid xs={6} container item>
                            <SvgChart src={`${baseUrlSvg}/use_reducedtillage_garmin/${i18n.language}`} />
                        </Grid>
                        <Grid xs={6} container item>
                            <Typography className={classes.graphText}>
                                <Trans
                                    i18nKey={`pages.results.service.${resultsData.texts.use_reducedtillage_garmin.slug}`}
                                    values={{
                                        minimalTillagePerc:
                                            resultsData.texts.use_reducedtillage_garmin.values.minimalTillagePerc,
                                        performanceCount:
                                            resultsData.texts.use_reducedtillage_garmin.values.performanceCount,
                                        performancePerc:
                                            resultsData.texts.use_reducedtillage_garmin.values.performancePerc,
                                        benchWithMinTilCount:
                                            resultsData.texts.use_reducedtillage_garmin.values.benchWithMinTilCount,
                                        benchWithMinTilPerc:
                                            resultsData.texts.use_reducedtillage_garmin.values.benchWithMinTilPerc,
                                    }}
                                />
                                <br />
                                <br />
                                {resultsData.texts.extra_details_reducedtillage.slug_list.map((extra, i) => (
                                    <>
                                        <Trans
                                            i18nKey={`pages.results.service.${extra.slug}`}
                                            values={{
                                                crop_group:
                                                    i18n.language === 'fr'
                                                        ? resultsData.texts.extra_details_reducedtillage.slug_list_fr[i]
                                                              .values.crop_group
                                                        : extra.values.crop_group,
                                                myFarm_crop_ids: translateCropIds(extra.values.myFarm_crop_ids),
                                                myFarm_til_red_perc: extra.values.myFarm_til_red_perc,
                                                myFarm_til_sd_perc: extra.values.myFarm_til_sd_perc,
                                                bench_til_red_perc: extra.values.bench_til_red_perc,
                                                bench_til_sd_perc: extra.values.bench_til_sd_perc,
                                            }}
                                        />
                                        <br />
                                        <br />
                                    </>
                                ))}
                                <Trans i18nKey="pages.results.static_use_reducedtillage_garmin" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.hr} />
                    <Grid spacing={6} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                {t('pages.results.tab.sequestration.subtitle-organic-fertilisers')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid xs={6} container item>
                            <SvgChart src={`${baseUrlSvg}/use_organicfert_garmin/${i18n.language}`} />
                        </Grid>
                        <Grid xs={6} container item>
                            <Typography className={classes.graphText}>
                                <Trans
                                    i18nKey={`pages.results.service.${resultsData.texts.use_organicfert_garmin.slug}`}
                                    values={{
                                        organicFertilizerPerc:
                                            resultsData.texts.use_organicfert_garmin.values.organicFertilizerPerc,
                                        performanceCount:
                                            resultsData.texts.use_organicfert_garmin.values.performanceCount,
                                        performancePerc:
                                            resultsData.texts.use_organicfert_garmin.values.performancePerc,
                                        benchWithOrgFertilizerCount:
                                            resultsData.texts.use_organicfert_garmin.values.benchWithOrgFertilizerCount,
                                        benchWithOrgFertilizerPerc:
                                            resultsData.texts.use_organicfert_garmin.values.benchWithOrgFertilizerPerc,
                                    }}
                                />
                                <br />
                                <br />
                                {resultsData.texts.extra_details_organicfert.slug_list.map((extra, i) => (
                                    <>
                                        <Trans
                                            i18nKey={`pages.results.service.${extra.slug}`}
                                            values={{
                                                crop_group:
                                                    i18n.language === 'fr'
                                                        ? resultsData.texts.extra_details_organicfert.slug_list_fr[i]
                                                              .values.crop_group
                                                        : extra.values.crop_group,
                                                myFarm_crop_ids: translateCropIds(extra.values.myFarm_crop_ids),
                                                myFarm_orgF_perc: extra.values.myFarm_orgF_perc,
                                                bench_orgF_perc: extra.values.bench_orgF_perc,
                                            }}
                                        />
                                        <br />
                                        <br />
                                    </>
                                ))}
                                <Trans i18nKey="pages.results.static_use_organicfert_garmin" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.hr} />
                    <Grid spacing={6} style={{ marginTop: '30px' }} container>
                        <Grid alignContent="center" alignItems="center" xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                {t('pages.results.tab.sequestration.title-crops')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid spacing={6} container>
                        <Grid alignItems="flex-start" xs={7} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                {t('pages.results.tab.sequestration.subtitle-crops')}
                            </Typography>
                            <div className="flex-break" />
                            <SvgChart src={`${baseUrlSvg}/myFarmBench_seq_points/${i18n.language}`} />
                        </Grid>
                        <Grid xs={5} container item>
                            <Grid container>
                                <Grid xs={7} container item>
                                    <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                        {t('pages.results.tab.sequestration.choose-crop')}
                                    </Typography>
                                </Grid>
                                <Grid xs={5} container item>
                                    {resultsData.pie_crop_ids.length > 0 && (
                                        <FormControl className={classes.formControl} variant="outlined">
                                            <Select value={cropSelect} native onChange={handleCropSelect}>
                                                {resultsData.pie_crop_ids.map((crop_id: number) => (
                                                    <option key={crop_id} value={crop_id.toString()}>
                                                        {t(getCropSlug(crops, crop_id))}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={12} container item>
                                    {resultsData.pie_crop_ids.map((crop_id, i) => (
                                        <div key={i}>
                                            {(cropSelect === String(crop_id) || (i === 0 && cropSelect === '')) && (
                                                <SvgChart
                                                    src={`${baseUrlSvg}/seqLevers_crop_pie/${i18n.language}/${crop_id}`}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel index={3} value={value}>
                    <Grid spacing={6} container>
                        <Grid xs={12} container item>
                            <Typography className={classes.secondaryTitle} color="secondary" variant="h4">
                                {t('pages.results.tab.emission.title-compare')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} container item>
                            <Grid xs={6} container item>
                                <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                    {t('pages.results.tab.emission.title-factors')}
                                </Typography>
                            </Grid>
                            <Grid xs={6} container item>
                                <Grid xs={7} container item>
                                    <Typography className={classes.secondaryTitle} color="secondary" variant="h5">
                                        {t('pages.results.tab.emission.choose-factor')}
                                    </Typography>
                                </Grid>
                                <Grid xs={5} container item>
                                    {resultsData.emission_factors.length > 0 && (
                                        <FormControl className={classes.formControl} variant="outlined">
                                            <Select value={factorSelect} native onChange={handleFactorSelect}>
                                                {resultsData.emission_factors.map((factor, i) => (
                                                    <option key={i} value={factor}>
                                                        {t(factor)}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container item>
                            <div className={`${classes.svgChart} nobg`} style={{ opacity: 1 }}>
                                {resultsData.emission_factors.map((factor, i) => (
                                    <div key={i}>
                                        {(factorSelect === factor || (i === 0 && factorSelect === '')) && (
                                            <SvgChart
                                                src={`${baseUrlSvg}/myFarmBench_emission_specEmission_points/${i18n.language}/${factor}`}
                                            />
                                        )}
                                    </div>
                                ))}
                                <Grid xs={8} container item>
                                    <SvgChart src={`assets/images/legend_emission_${i18n.language}.svg`} />
                                </Grid>
                            </div>
                        </Grid>
                        <Grid xs={12} container item>
                            <Typography color="secondary.dark" variant="h5" width="100%">
                                {t('pages.results.tab-emissions.crop-emissions')}:{' '}
                                <Typography display="inline-block" variant="h5">
                                    {formatNumber(cropNetEmissions, 2)} t CO2e
                                </Typography>
                            </Typography>
                            <Typography color="secondary.dark" variant="h5" width="100%">
                                {t('pages.results.tab-emissions.livestock-emissions')}:{' '}
                                <Typography display="inline-block" variant="h5">
                                    {formatNumber(totalLivestockEmissions, 2)} t CO2e
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </TabPanel>
            </>
        </FullPageLayout>
    );
};

export default ResultView;
