import MapService from '@services/mapService/mapService';
import { MapPopupContentBridgePropsT } from './MapPopupContentBridge.types';
import { useEffect, useRef } from 'react';
import { MapPopupContentT } from '../MapPopup/MapPopup.types';
import { useSelector } from 'react-redux';
import selectMapProperty from '@store/selectors/selectMapProperty';

/**
 * Bridge to display content in the map popup.
 * The last contents displayed have priority over the previous ones.
 * If the content return null, it's skiped. If it return false, it don't display the popup.
 * /!\ can only be used once to avoid rendering order issues..
 */
const MapPopupContentBridge = ({ contentComponent }: MapPopupContentBridgePropsT) => {
    const previousContentRef = useRef<MapPopupContentT | null>(null);
    const previousContentComponent = useSelector(selectMapProperty('popupContent'));
    useEffect(() => {
        previousContentRef.current = previousContentComponent ?? null;
        MapService.setPopupContent({ contentComponent });

        return () => {
            MapService.setPopupContent({ contentComponent: previousContentRef.current });
        };
    }, []);

    return null;
};

export default MapPopupContentBridge;
