import { Typography, Divider } from '@mui/material';
import TaskList from '@components/TaskList/TaskList';
import ProgressHeader from '@components/ProgressHeader/ProgressHeader';

import { HistorySubtasksViewPropsI } from './HistorySubtasks.types';
import useHistorySubtasksStyle from './HistorySubtasks.style';

const HistorySubtasksView = ({
    historyItemsDone,
    historyItemsToDo,
    t,
    totalProgressPercentage,
}: HistorySubtasksViewPropsI): JSX.Element => {
    const { classes } = useHistorySubtasksStyle();

    return (
        <div>
            <ProgressHeader
                className={classes.progress}
                image={`/assets/images/installation/farm-step-${Math.trunc(totalProgressPercentage / 10)}.png`}
                menuTitle={t('pages.installationtasks.history.add-your-history')}
                withMenu
                onArrowBack="/"
                onClose="/"
            />
            <div className={classes.HistorySubtasksPageWrapper} id="HistorySubtasksPageWrapper">
                <Typography component="h3" variant="h3">
                    {t('pages.installationtasks.history.your-subtasks')}
                </Typography>

                <TaskList taskItems={historyItemsToDo} />
                {historyItemsDone.length > 0 && <Divider className={classes.hr} />}
                <TaskList taskItems={historyItemsDone} tasksDone />
            </div>
        </div>
    );
};

export default HistorySubtasksView;
