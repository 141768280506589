import { Dispatch } from '@reduxjs/toolkit';

export const USER_CHANGE_LANGUAGE_LOADING = 'USER_CHANGE_LANGUAGE_LOADING';
export const USER_CHANGE_LANGUAGE_SUCCESS = 'USER_CHANGE_LANGUAGE_SUCCESS';
export const USER_CHANGE_LANGUAGE_FAILED = 'USER_CHANGE_LANGUAGE_FAILED';

export type UserChangeLanguageSuccessDataT = {
    language_id: number;
};

export type UserChangeLanguageFailedDataT = {
    message?: string;
    list?: string[];
};

export type UserChangeLanguageLoadingT = {
    type: typeof USER_CHANGE_LANGUAGE_LOADING;
};

export type UserChangeLanguageSuccessT = {
    type: typeof USER_CHANGE_LANGUAGE_SUCCESS;
    payload: UserChangeLanguageSuccessDataT;
};
export type UserChangeLanguageFailedT = {
    type: typeof USER_CHANGE_LANGUAGE_FAILED;
    error: UserChangeLanguageFailedDataT;
};

export type UserChangeLanguageCallbackT = (
    dispatch: Dispatch<UserChangeLanguageDispatchTypes>,
) => Promise<UserChangeLanguageFailedDataT | UserChangeLanguageSuccessDataT>;

export type UserChangeLanguageDispatchTypes =
    | UserChangeLanguageLoadingT
    | UserChangeLanguageSuccessT
    | UserChangeLanguageFailedT;
