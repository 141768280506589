import { Container, Typography } from '@mui/material';
import useModalWizzardLayoutStyle from './ModalWizzardLayout.style';
import { MdoalWizzardLayoutPropsT } from './ModalWizzardLayoutTypes';

/**
 * ModalWizzardLayout display centered contents, with title, subTitle, StatusZone, BodyZone, FooterZone.
 * Used To display many/various infos to the user (simone, new season, ...), used to display steps (payment detail, payment done)
 */
const ModalWizzardLayout = ({
    title,
    subTitle,
    StatusZoneComponent,
    BodyZoneComponent,
    FooterZoneComponent,
}: MdoalWizzardLayoutPropsT): JSX.Element => {
    const { classes } = useModalWizzardLayoutStyle();

    return (
        <div className={classes.ModalWizzardLayout}>
            {StatusZoneComponent}
            <Typography variant="h2" className={`${classes.title} title`}>
                {title}
            </Typography>
            <Typography className={`${classes.subTitle} subTitle`}>{subTitle}</Typography>
            <Container maxWidth="md" className={`${classes.bodyZone} bodyZone`}>
                {typeof BodyZoneComponent === 'string' ? (
                    <Typography variant="body2">{BodyZoneComponent}</Typography>
                ) : (
                    BodyZoneComponent
                )}
            </Container>
            <div className={`${classes.footerZone} footerZone`}>{FooterZoneComponent}</div>
        </div>
    );
};

export default ModalWizzardLayout;
