import { makeStyles } from 'tss-react/mui';

const usePaymentStatusIconStyle = makeStyles()(() => ({
    PaymentStatusIcon: {
        marginBottom: '20px',
        width: '100px',
        height: '100px',
        color: '#36B37F',
        '&>div': {
            height: 'auto', // unset loader height
        },
        '& .MuiSvgIcon-root': {
            width: '100%',
            height: '100%',
        },
    },
    iconDropShadow: {
        filter: 'drop-shadow(0px 3px 5px rgb(0 0 0 / 20%))',
    },
}));

export default usePaymentStatusIconStyle;
