import { Card, CardActionArea, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { CheckCircle } from '@mui/icons-material';

import useCheckedButtonStyle from './CheckedButtonView.style';
import { CheckedButtonViewPropsT } from './CheckedButtonViewTypes';

const CheckedButtonView = ({ name, children, onClick, done }: CheckedButtonViewPropsT): JSX.Element => {
    const { classes } = useCheckedButtonStyle();

    return (
        <Card className={classes.card} elevation={0} variant="elevation">
            <CardActionArea className={classes.container} onClick={onClick}>
                <div>{done ? <CheckCircle /> : <CircleUnchecked />}</div>
                <div className={classes.textSection}>
                    <Typography className={classes.textSectionTitle} color="primary" variant="h5">
                        {name}
                    </Typography>
                    <div className={classes.textSectionDescription}>{children}</div>
                </div>
                <div className={classes.iconContainer}>
                    <ArrowRightAltIcon className={classes.icon} color="secondary" />
                </div>
            </CardActionArea>
        </Card>
    );
};

export default CheckedButtonView;
