import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useTableViewStyle = makeStyles<{ color: 'primary' | 'secondary' }>()((theme, { color }) => ({
    tableContainer: {
        borderRadius: 0,
        boxShadow: 'none',
    },
    headerCell: {
        borderTop: `1px solid ${theme.palette[color].light}`,
        borderBottom: `1px solid ${theme.palette[color].light}`,
    },

    bodyRow: {
        '&:nth-of-type(odd)': {
            backgroundColor:
                color === 'primary' ? theme.palette.linkWater.light : alpha(theme.palette[color].light, 0.1),
        },
        '&:nth-of-type(even)': {
            backgroundColor:
                color === 'primary' ? alpha(theme.palette.linkWater.main, 0.5) : alpha(theme.palette[color].light, 0.3),
        },
    },

    bodyCell: {
        border: 'none',
        textAlign: 'center',
    },

    highlitedCellEven: {
        backgroundColor: '#FAF2E9',
    },
    hightlitedCellOdd: {
        backgroundColor: '#F9DCBE',
    },
}));

/** Reusable Style to customize cells through cellClassName */
export const useTableViewCellStyle = makeStyles()(() => ({
    /** to use on 2th col of tables without headers => the first column is the label */
    boldAndHalf: {
        width: '50%',
        fontWeight: 'bold',
        '&>p': {
            fontWeight: 'bold',
        },
    },
    bold: {
        fontWeight: 'bold',
        '&>p': {
            fontWeight: 'bold',
        },
    },
}));

export default useTableViewStyle;
