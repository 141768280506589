import { InfoPaper, InfoTooltip } from '@ui-kit';
import usePayoutCardLogic from './PayoutCard.logic';
import usePayoutCardStyle from './PayoutCard.styles';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import { LabelTextField, ModalBase } from '@soil-capital/ui-kit';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';

const PayoutCard = () => {
    const { classes } = usePayoutCardStyle();
    const {
        iban,
        handlePayoutCardEditButtonClick,
        editModalController,
        formik,
        updateIbanLoading,
        hasIban,
        mask,
        isAllowedToChangeIBAN,
    } = usePayoutCardLogic();
    const { t } = useTranslation();

    return (
        <>
            <InfoPaper title={t('pages.settings.billing.certificate-information.title')}>
                <div className={classes.flexbox}>
                    <Typography color="secondary" variant="body2">
                        {t('pages.settings.billing.certificate-information.iban.title')}
                    </Typography>
                    <InfoTooltip
                        tooltipScProps={{
                            translationSlug: t('pages.settings.billing.certificate-information.iban.tooltip', {}),
                            className: classes.infoTooltip,
                        }}
                    />
                </div>
                <Typography minHeight="60px" mb="34px" color="primary" variant="body2">
                    {iban}
                </Typography>
                {isAllowedToChangeIBAN && (
                    <Button id="payout-action-button" color="secondary" onClick={handlePayoutCardEditButtonClick}>
                        {hasIban
                            ? t('constants.edit')
                            : t('pages.settings.billing.certificate-information.iban.button.add')}
                    </Button>
                )}
            </InfoPaper>
            {/* Iban modal */}
            <ModalBase width="600px" showCloseButton controller={editModalController}>
                <ModalBaseLayout
                    title={{
                        text: t('pages.settings.billing.certificate-information.iban.edit.title'),
                    }}
                    cancelButton={{
                        text: t('constants.cancel'),
                        onClick: () => editModalController.onModalCancel(),
                    }}
                    confirmButton={{
                        text: t('constants.save'),
                        onClick: formik.submitForm,
                        disabled: updateIbanLoading,
                    }}
                >
                    <LabelTextField
                        value={formik.values?.iban}
                        error={formik.touched['iban'] && !!formik.errors['iban']}
                        helperText={formik.touched['iban'] && t(formik.errors['iban'] as string)}
                        onChange={(e) => {
                            formik.setValues((values) => ({ ...values, iban: e.target.value.toUpperCase() }));
                        }}
                        className={classes.ibanInput}
                        label={t('pages.settings.billing.certificate-information.iban.edit.subtitle')}
                        placeholder={mask}
                        required
                    />
                </ModalBaseLayout>
            </ModalBase>
        </>
    );
};

export default PayoutCard;
