import IconGraph from '@icons/icon-graph.svg';
import { Trans } from 'react-i18next';
import {
    NotificationT,
    NotificationTypes,
    ResultsGenerationStatus,
} from '@reducers/NotificationReducer/NotificationReducerTypes';
import { NavigateFunction } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import useAppDispatch from '@hooks/useAppDispatch';
import { markNotificationAsRead } from '@actions/NotificationActions/NotificationActions';
import { AppDispatchT } from '@store/store';

import NotificationItem from '../NotificationItem/NotificationItem';

import { NotificationSwitchPropsT } from './NotificationSwitchTypes';
import { Theme, useTheme } from '@mui/material';
import { ROUTE } from '@constants/routes';

const getIconBgMap = (theme: Theme) => ({
    [NotificationTypes.RESULTS_GENERATION]: {
        [ResultsGenerationStatus.REQUESTED]: theme.palette.grey[400],
        [ResultsGenerationStatus.SUCCESSED]: theme.palette.success.light,
        [ResultsGenerationStatus.FAILED]: theme.palette.error.light,
        [ResultsGenerationStatus.READY]: theme.palette.success.light,
        [ResultsGenerationStatus.IN_PROGRESS]: theme.palette.warning.light,
    },
});

const getNotificationText = (notification: NotificationT): JSX.Element | null => {
    const { farmerFirstName, farmerLastName, generationStartedAt } = notification.data;

    switch (notification.data.status) {
        case ResultsGenerationStatus.SUCCESSED: {
            const { created_at } = notification;
            const durationSeconds = (new Date(created_at).getTime() - new Date(generationStartedAt).getTime()) / 1000;
            const h = Math.floor(durationSeconds / 3600);
            const m = Math.floor((durationSeconds % 3600) / 60);
            const s = Math.floor((durationSeconds % 3600) % 60);
            const duration = (h ? `${h}h` : '') + (m ? `${m}m` : '') + (s ? `${s}s` : '');

            return (
                <Trans
                    components={{ bold: <strong /> }}
                    defaults="Results generation successed for farmer <bold>{{farmerFirstName}} {{farmerLastName}}</bold> (in {{duration}})"
                    i18nKey="component.notification-list.results-generation-successed"
                    values={{ farmerLastName, farmerFirstName, duration }}
                />
            );
        }
        case ResultsGenerationStatus.REQUESTED: {
            return (
                <Trans
                    components={{ bold: <strong /> }}
                    defaults="Results generation requested for farmer <bold>{{farmerFirstName}} {{farmerLastName}}</bold>"
                    i18nKey="component.notification-list.results-generation-requested"
                    values={{ farmerLastName, farmerFirstName }}
                />
            );
        }
        case ResultsGenerationStatus.FAILED: {
            return (
                <Trans
                    components={{ bold: <strong /> }}
                    defaults="Results generation failed for farmer <bold>{{farmerFirstName}} {{farmerLastName}}</bold>"
                    i18nKey="component.notification-list.results-generation-failed"
                    values={{ farmerLastName, farmerFirstName }}
                />
            );
        }
        case ResultsGenerationStatus.IN_PROGRESS: {
            return (
                <Trans
                    components={{ bold: <strong /> }}
                    i18nKey="component.notification-list.results-generation-in-progress"
                    values={{ farmerLastName, farmerFirstName }}
                />
            );
        }
        case ResultsGenerationStatus.READY: {
            return <Trans i18nKey="component.notification-list.results-ready">Your results are ready</Trans>;
        }
        default:
            return null;
    }
};

const getNotificationHandleClick = (
    notification: NotificationT,
    navigate: NavigateFunction,
    dispatch: AppDispatchT,
    onNotificationClickDone?: NotificationSwitchPropsT['onNotificationClickDone'],
) => {
    switch (notification.data.status) {
        case ResultsGenerationStatus.READY: {
            return () => {
                dispatch(markNotificationAsRead(notification));
                // navigate(ROUTE.RESULTS); -- location to refresh data (Limitation of current notifications)
                onNotificationClickDone?.(notification);
                window.location.href = ROUTE.RESULTS;
            };
        }
        // TODO select farmer
        case ResultsGenerationStatus.SUCCESSED: {
            return () => {
                dispatch(markNotificationAsRead(notification));
                navigate(ROUTE.RESULTS);
                onNotificationClickDone?.(notification);
            };
        }
        default:
            return undefined;
    }
};

const NotificationSwitch = ({
    notification,
    isLoading,
    showAction,
    onNotificationClickDone,
    onActionClick,
}: NotificationSwitchPropsT) => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const theme = useTheme();

    return (
        <NotificationItem
            date={new Date(notification.created_at)}
            icon={<IconGraph />}
            iconBg={getIconBgMap(theme)[notification.type][notification.data.status]}
            iconColor={theme.palette.primary.dark}
            isLoading={isLoading}
            showAction={showAction}
            text={getNotificationText(notification)}
            onActionClick={(e) => onActionClick?.(e, notification)}
            onNotificationClick={getNotificationHandleClick(notification, navigate, dispatch, onNotificationClickDone)}
        />
    );
};

export default NotificationSwitch;
