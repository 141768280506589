import { Dispatch } from '@reduxjs/toolkit';

import { FarmSeasonLivestockT } from './GetFarmSeasonLivestocksTypes';

export const POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING = 'POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING';
export const POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED = 'POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED';
export const POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS = 'POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS';

export type PostFarmSeasonLivestocksInstallationResponseDataT = {
    livestocks: FarmSeasonLivestockT[];
    installation: {
        onboarding: {
            finished: string[];
            livestock: {
                done: boolean;
                option: 'yes' | 'no';
            };
        };
    };
    isEligible: boolean;
};

export type PostFarmSeasonLivestocksInstallationDataT = {
    livestocks: FarmSeasonLivestockT[];
    option: string;
};

export type FarmSeasonLivestocksPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonLivestocksInstallationDispatchTypesT>,
) => Promise<PostFarmSeasonLivestocksInstallationResponseErrorT | PostFarmSeasonLivestocksInstallationResponseDataT>;

export type PostFarmSeasonLivestocksInstallationResponseErrorT = {
    message?: string;
    list?: string[];
    error?: any; // eslint-disable-line
};

export type PostFarmSeasonLivestocksInstallationLoadingT = {
    type: typeof POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING;
};

export type PostFarmSeasonLivestocksInstallationFailedT = {
    type: typeof POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED;
    error: PostFarmSeasonLivestocksInstallationResponseErrorT;
};

export type PostFarmSeasonLivestocksInstallationSuccessT = {
    type: typeof POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS;
    payload: PostFarmSeasonLivestocksInstallationResponseDataT;
};

export type PostFarmSeasonLivestocksInstallationDispatchTypesT =
    | PostFarmSeasonLivestocksInstallationLoadingT
    | PostFarmSeasonLivestocksInstallationFailedT
    | PostFarmSeasonLivestocksInstallationSuccessT;
