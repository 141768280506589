/* eslint-disable @typescript-eslint/no-explicit-any */
import { HomePageLayoutPendingStateT } from '@hooks/useHomePageLoadingTypes';
import { resetFieldIrrigationSuggestion } from '@reducers/Onboarding/Suggestion.slice';
import { Dispatch } from '@reduxjs/toolkit';
import store, { AppStateT } from '@store/store';
import { logoutFarmerOrRedirectAgro } from '../AuthActions/LogoutActions';
import { farmGetAll, farmSetCurrent } from '../FarmActions/FarmActions';
import { FarmT } from '../FarmActions/FarmActionsTypes';
import farmSeasonGetAll from '../FarmSeasonActions/FarmSeasonGetAll';
import { farmSeasonSetCurrent } from '../FarmSeasonActions/FarmSeasonSetCurrent';
import { getFarmSeasonCrops } from '../FarmSeasonCropActions/GetFarmSeasonCrops';
import { getFarmSeasonFields } from '../FarmSeasonFieldActions/GetFarmSeasonFields';
import { RESET_FARM_SEASON_LIVESTOCK } from '../FarmSeasonLivestockActions/FarmSeasonLivestockActionsTypes';

import { setPendingInvitation } from '@/api/invitation.slice';
import { CarbonModelType } from '@/services/authService/authServiceTypes';
import PartnerInvitationApiService from '@/services/partnerInvitationApiService/partnerInvitationApiService';
import { UserT } from '@/store/reducers/AuthReducer/AuthReducerTypes';
import { ROLE_ID } from '@constants/roleIds';
import cropEntity from '@entities/cropEntity';
import fieldEntity from '@entities/fieldEntity';
import cropsGet from '../StaticActions/CropsGetAction';
import {
    APP_NAVIGATE_PROMPT_MESSAGE,
    APP_SET_IS_FIRST_MENU_ITEM_SELECTED,
    AppActionsT,
    AppSetModalWarningController,
    AppSetModalWarningProps,
    SetIsFirstMenuItemSelectedActionT,
    SetNavigatePromptMessageActionT,
} from './AppActionsTypes';
import { getUserPermissions } from './GetPermissions';

const appActions = {
    setIsFirstMenuItemSelected: (value: boolean) => (dispatch: Dispatch<SetIsFirstMenuItemSelectedActionT>) =>
        dispatch({ payload: { isFirstMenuItemSelected: value }, type: APP_SET_IS_FIRST_MENU_ITEM_SELECTED }),
    setNavigatePromptMessage:
        (value: null | { title: string; text: string }) => (dispatch: Dispatch<SetNavigatePromptMessageActionT>) =>
            dispatch({ payload: { navigatePromptMessage: value }, type: APP_NAVIGATE_PROMPT_MESSAGE }),
};

export default appActions;

export const appInitUserData =
    ({ user }: { user: UserT }) =>
    async (dispatch: Dispatch<any>) => {
        {
            const userIsFarmer = user.role_id === ROLE_ID.FARMER;
            await Promise.all([
                getUserPermissions()(dispatch),
                userIsFarmer && appInitFarmsAndSeasons({ userId: user.id })(dispatch),
                userIsFarmer &&
                    PartnerInvitationApiService.getPendingInvitation(encodeURIComponent(user.email))
                        .then((p) => dispatch(setPendingInvitation(p.data)))
                        .catch((e) => console.warn('pending invation failed :', e)),
            ]);
        }
    };
/**
 * Fetch farms and farmSeasons and set currentFarm and currentFarmSeason for the userId
 */
export const appInitFarmsAndSeasons =
    ({ userId }: { userId: number }) =>
    async (dispatch: Dispatch<AppActionsT>) => {
        dispatch({ type: 'APP_INIT_FARMS_AND_SEASONS_LOADING', payload: { initFarmsAndSeasonsLoading: true } });

        try {
            const farmList = await farmGetAll(userId)(dispatch);
            if (!farmList) {
                // FP-4233 - redirect agros instead of logout them
                logoutFarmerOrRedirectAgro()(dispatch);

                throw Error("Can't init data for authenticated user.");
            }

            const currentFarm: FarmT | undefined = farmList[0];

            if (!currentFarm?.id) {
                return;
            }

            farmSetCurrent({ farmId: currentFarm.id })(dispatch);
            const farmSeasonList = await farmSeasonGetAll(currentFarm.id)(dispatch);
            if (!farmSeasonList) {
                // FP-4233 - redirect agros instead of logout them
                logoutFarmerOrRedirectAgro()(dispatch);

                throw Error("Can't init data for authenticated user.");
            }

            // select latest farmSeason
            const farmSeasonToSelect = [...farmSeasonList].pop();
            if (!farmSeasonToSelect) {
                return;
            }
            farmSeasonSetCurrent({ farmSeasonId: farmSeasonToSelect.id })(dispatch);
        } finally {
            dispatch({ type: 'APP_INIT_FARMS_AND_SEASONS_LOADING', payload: { initFarmsAndSeasonsLoading: false } });
        }
    };

/** fetch farmSeasonFields and farmSeasonCrops for the currentFarmSeason */
export const appInitDataForFarmSeason = () => async (dispatch: Dispatch<AppActionsT>) => {
    dispatch({
        type: 'APP_ADD_PENDING_STATE_FOR_HOME_PAGE_LAYOUT',
        payload: { pendingStateForHomePageLayout: { pending: true, slug: 'appInitDataForFarmSeason' } },
    });
    try {
        const appState: AppStateT = store.getState();
        const isCFT =
            appState.auth.farmer_infos?.model_type === CarbonModelType.CFT ||
            appState.auth.selectedUser?.model_type === CarbonModelType.CFT;
        const farmId = appState.farm.currentFarm?.id;
        const farmSeasonId = appState.farmSeason.currentFarmSeason?.id;
        if (!farmId || !farmSeasonId) {
            throw Error('unexpected empty farmId or farmSeasonId');
        }
        // previous season data to be cleaned
        dispatch({ type: RESET_FARM_SEASON_LIVESTOCK });
        dispatch<any>(resetFieldIrrigationSuggestion());

        // fetch new data
        await Promise.all([
            isCFT
                ? getFarmSeasonFields(farmId, farmSeasonId)(dispatch)
                : getFarmSeasonFields(farmId, farmSeasonId, { with: 'operations' })(dispatch),
            getFarmSeasonCrops(farmId, farmSeasonId)(dispatch),
        ]);
        // REFACTOR temporary pre-fetch to avoid handle local loaders in app.
        dispatch(fieldEntity.api.endpoints.getField.initiate({ farmSeasonId }) as any);
        dispatch(cropEntity.api.endpoints.getCrop.initiate({ farmSeasonId }) as any);
        // REFACTOR static data should be fetched through rtk query.
        cropsGet(Number(appState.farm?.currentFarm?.country_id))(dispatch);
    } finally {
        dispatch({
            type: 'APP_REMOVE_PENDING_STATE_FOR_HOME_PAGE_LAYOUT',
            payload: { pendingSlugForHomePageLayout: 'appInitDataForFarmSeason' },
        });
    }
};

export const appAddPendingStateForHomePageLayout =
    (pendingState: HomePageLayoutPendingStateT) => async (dispatch: Dispatch<AppActionsT>) => {
        dispatch({
            type: 'APP_ADD_PENDING_STATE_FOR_HOME_PAGE_LAYOUT',
            payload: { pendingStateForHomePageLayout: pendingState },
        });
    };

export const appRemovePendingStateForHomePageLayout =
    ({ pendingSlug }: { pendingSlug: string }) =>
    async (dispatch: Dispatch<AppActionsT>) => {
        dispatch({
            type: 'APP_REMOVE_PENDING_STATE_FOR_HOME_PAGE_LAYOUT',
            payload: { pendingSlugForHomePageLayout: pendingSlug },
        });
    };

export const appSetModalWarningProps =
    ({ modalWarningProps }: AppSetModalWarningProps['payload']) =>
    async (dispatch: Dispatch<AppActionsT>) => {
        dispatch({ type: 'APP_SET_MODAL_WARNING_PROPS', payload: { modalWarningProps: { ...modalWarningProps } } });
    };

export const appInitModalWarningController =
    ({ modalWarningController }: AppSetModalWarningController['payload']) =>
    async (dispatch: Dispatch<AppActionsT>) => {
        dispatch({ type: 'APP_INIT_MODAL_WARNING_CONTROLLER', payload: { modalWarningController } });
    };
