import HelpButtonView from '@components/HelpButton/HelpButtonView';
import { Container, Typography } from '@mui/material';

import useFullPageLayoutStyle from './FullPageLayout.style';
import { FullPageLayoutPropsT } from './FullPageLayoutTypes';

const FullPageLayout = ({
    children,
    size,
    className,
    title,
    actionZone,
    helpId,
}: FullPageLayoutPropsT): JSX.Element => {
    const { classes } = useFullPageLayoutStyle();
    const withHelp = helpId ? <HelpButtonView id={helpId} size="large" /> : '';

    return (
        <div id="FullPageLayout" className={`${classes.FullPageLayout} ${className}`}>
            <Container maxWidth={size || 'xl'}>
                {(title || actionZone) && (
                    <div className={classes.pageHeader}>
                        <Typography color="secondary" variant="h4">
                            {title}
                            {withHelp}
                        </Typography>
                        {actionZone}
                    </div>
                )}
                {children}
            </Container>
        </div>
    );
};

export default FullPageLayout;
