import { array, bool, mixed, number, object, string } from 'yup';
import { FieldAreaSourcesT } from './fieldEntity.types';

const schema = object({
    name: string().required(),
    original_area: number().required(),
    area_source: mixed<FieldAreaSourcesT>().oneOf(['drawn', 'forced', 'opendata', 'pac']).defined(),
    polygon_geometry: object({
        type: mixed<'Polygon'>().oneOf(['Polygon']).required(),
        coordinates: array(array(array().length(2)))
            .required()
            .length(1),
    }).required(),
    user_area: number().nullable(),
    tillage_id: number().nullable(),
    soil_acidity_id: number().nullable(),
    soil_organic_matter_id: number().nullable(),
    is_tillage_permanent: bool().nullable(),
    is_irrigated: bool().nullable(),
    is_organic: bool().nullable(),
    has_drainage: bool().nullable(),
    has_agroforestry: bool().nullable(),
    has_cover_crops: bool().nullable(),
    has_tillage_practice_history: bool().nullable(),
    has_cover_crop_history: bool().nullable(),
    has_pasture_history: bool().nullable(),
    has_organic_fertilization_history: bool().nullable(),
});

const defaultOptions = {
    // Remove unspecified keys from objects
    stripUnknown: true,
};
export default {
    ...schema,
    validate: (v, o) => schema.validate(v, { ...defaultOptions, ...o }),
    validateSync: (v, o) => schema.validateSync(v, { ...defaultOptions, ...o }),
} as typeof schema;
