import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
    return {
        container: {
            color: '#596D79',
            backgroundColor: theme.palette.grey[200],
            borderRadius: '0.5rem',
            width: '2.5rem',
            height: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            path: {
                fill: '#596D79',
            },
        },
    };
});
