import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import useAppNavigate from '@hooks/useAppNavigate';
import { Typography } from '@mui/material';

import { MenuBarViewPropsI } from './MenuBarTypes';
import useMenuBarStyle from './MenuBar.style';

const MenuBarView = ({ onArrowBack, onClose, menuTitle, className }: MenuBarViewPropsI): JSX.Element => {
    const { classes } = useMenuBarStyle();
    const navigate = useAppNavigate();

    const arrowBack = (
        <div className={classes.arrowBackLink}>
            <ArrowBackIcon
                className={classes.icons}
                onClick={typeof onArrowBack === 'string' ? () => navigate(onArrowBack) : onArrowBack}
            />
        </div>
    );

    const closeIcon = (
        <div className={classes.closeLink}>
            <CloseIcon
                className={classes.icons}
                onClick={typeof onClose === 'string' ? () => navigate(onClose) : onClose}
            />
        </div>
    );

    return (
        <div className={`${classes.menuBar} ${className}`}>
            {onArrowBack ? arrowBack : <div className={classes.arrowBackLink} />}
            <Typography className={classes.menuBarTitle} color="secondary" variant="body2">
                {menuTitle}
            </Typography>
            {closeIcon}
        </div>
    );
};

export default MenuBarView;
