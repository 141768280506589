import { makeStyles } from 'tss-react/mui';

const useHeaderNameValueListStyle = makeStyles()((theme) => ({
    HeaderNameValueList: {
        color: theme.palette.driftwood.light,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
}));

export default useHeaderNameValueListStyle;
