import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentForm from '../PaymentForm/PaymentForm';
import { PaymentFormRefControllerT } from '../PaymentForm/PaymentForm.types';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';

const ModalContentPaymentDetail = (): JSX.Element => {
    const { t } = useTranslation();
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    const formRef = useRef<PaymentFormRefControllerT>(null);

    return (
        <ModalBaseLayout
            title={{ text: t('components.modal-new-season-payment-detail.title') }}
            confirmButton={{
                text: t('components.modal-new-season-payment-detail.button-submit'),
                disabled: initialLoading,
                onClick: () => formRef.current?.redirectToPayment(),
            }}
        >
            <PaymentForm
                ref={formRef}
                type="next-season"
                withUseFarmDataCheckbox={true}
                setInitialLoading={setInitialLoading}
            />
        </ModalBaseLayout>
    );
};

export default ModalContentPaymentDetail;
