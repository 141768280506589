import { IrrigationPowerSourceT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const IRRIGATION_POWER_SOURCE_LIST_GET_LOADING = 'IRRIGATION_POWER_SOURCE_LIST_GET_LOADING';
export const IRRIGATION_POWER_SOURCE_LIST_GET_SUCCESS = 'IRRIGATION_POWER_SOURCE_LIST_GET_SUCCESS';
export const IRRIGATION_POWER_SOURCE_LIST_GET_FAILED = 'IRRIGATION_POWER_SOURCE_LIST_GET_FAILED';

export type IrrigationPowerSourceListGetSuccessDataT = IrrigationPowerSourceT[];

export type IrrigationPowerSourceListGetLoadingT = {
    type: typeof IRRIGATION_POWER_SOURCE_LIST_GET_LOADING;
};

export type IrrigationPowerSourceListGetSuccessT = {
    type: typeof IRRIGATION_POWER_SOURCE_LIST_GET_SUCCESS;
    payload: IrrigationPowerSourceListGetSuccessDataT;
};

export type IrrigationPowerSourceListGetFailedT = {
    type: typeof IRRIGATION_POWER_SOURCE_LIST_GET_FAILED;
    error: string;
};

export type IrrigationPowerSourceListGetDispatchType =
    | IrrigationPowerSourceListGetLoadingT
    | IrrigationPowerSourceListGetSuccessT
    | IrrigationPowerSourceListGetFailedT;

export type IrrigationPowerSourceListGetCallback = (
    dispatch: Dispatch<IrrigationPowerSourceListGetDispatchType>,
) => Promise<boolean>;
