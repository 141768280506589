import { makeStyles } from 'tss-react/mui';

const useIconHourglassStyle = makeStyles()(() => ({
    '@keyframes top-clip': {
        '0%': {},
        '50%': { transform: 'translateY(21px)' },
        '100%': { transform: 'translateY(21px)' },
    },

    '@keyframes bottom-sand-path': {
        '0%': {},
        '50%': { transform: 'translateY(0)' },
        '100%': { transform: 'translateY(0)' },
    },

    '@keyframes bottom-sand-g': {
        '0%': {},
        '85%': { transform: 'translateY(0)' },
        '100%': { transform: 'translateY(-9px)' },
    },

    '@keyframes hourglass-rotation': {
        '50%': { transform: 'rotateZ(0)' },
        '100%': { transform: 'rotateZ(180deg)' },
    },
    svg: {
        animationName: '$hourglass-rotation',

        ', & #top-sand #top-clip-path rect, #bottom-sand path, #bottom-sand g, svg': {
            animationDuration: '5s',
            animationDelay: '1s',
            animationIterationCount: 'infinite',
        },

        '& #top-sand #top-clip-path rect': {
            animationName: '$top-clip',
        },

        '& #bottom-sand path': {
            transform: 'translateY(21px)',
            animationName: '$bottom-sand-path',
        },

        '& #bottom-sand g': {
            animationName: '$bottom-sand-g',
        },
    },
}));

export default useIconHourglassStyle;
