/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import useMapFieldReset from '@hooks/useMapFieldReset';
import farmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import {
    GetFarmSeasonOperationTillageApiT,
    PostFarmSeasonOperationTillageApiT,
} from '@services/farmSeasonOperationApiService/farmSeasonOperationApiServiceTypes';
import { postFarmSeasonOperationTillage } from '@actions/FarmSeasonOperation/PostFarmSeasonOperationTillage';
import { useTranslation } from 'react-i18next';
import emptyFieldValuesToNull from '@/utils/emptyFieldValuesToNull';

import { OperationFieldT } from '../components/OperationCardForm/OperationFieldType';

import { TillageOperationFormT } from './TillageTypes';
import TillageView from './TillageView';
import { useCanEditFarmOperations } from '@hooks/usePermissions';

const Tillage = (): JSX.Element | null => {
    const resetMap = useMapFieldReset();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { t, i18n } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditOperations } = useCanEditFarmOperations(currentFarmSeason);

    const farmSeasonId = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason?.id);
    const farmId = useSelector((store: HomePageStateT) => store.farm.currentFarm?.id);
    const { cropId, tillageId } = useParams<{ cropId: string; tillageId: string }>();
    const [operationsData, setOperationsData] = useState<GetFarmSeasonOperationTillageApiT | null>(null);
    const [operationsDataLoading, setOperationsDataLoading] = useState<boolean>(true);
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList);
    const currentCrop = useSelector((state: HomePageStateT) => state.farmSeasonCrop.cropsList)?.find(
        (crop) => crop.id === Number(cropId),
    );
    const currentTillage = useSelector((state: HomePageStateT) => state.static.tillagePracticeList)?.find(
        (tillage) => tillage.id === Number(tillageId),
    );
    const machineries = useSelector((store: HomePageStateT) => store.static.machineryList)?.filter(
        (machinery) =>
            (machinery.operation_type_id === 2 || machinery.operation_type_id === 6) &&
            currentTillage &&
            machinery.ranking >= currentTillage.ranking,
    );

    // sorting alphabeticaly operations
    machineries?.sort((a, b) => {
        const operationA = t(a.translation_slug);
        const operationB = t(b.translation_slug);

        if (operationA < operationB) {
            return -1;
        }

        if (operationA > operationB) {
            return 1;
        }

        return 0;
    });

    const cropColors = setupCropPaletteFromCropLegacy(crops);
    const filteredFields = useSelector((store: HomePageStateT) => store.farmSeasonField.fieldsList)?.filter(
        (field) =>
            !!field.field_crops?.filter(
                (field_crop) =>
                    field_crop.farm_season_crop.id === parseInt(cropId || '') &&
                    field.tillage_id === parseInt(tillageId || ''),
            ).length,
    );

    // get operation data
    useEffect(() => {
        if (!farmId || !farmSeasonId) {
            return;
        }

        const getOperationData = async () => {
            setOperationsDataLoading(true);
            const res = await farmSeasonOperationApiService.get(farmId, farmSeasonId, 'tillage', tillageId, cropId);
            setOperationsData(res.data);
            setOperationsDataLoading(false);
        };

        getOperationData();
    }, [farmId, farmSeasonId]);

    // Setting the map with the right fields
    useEffect(() => {
        MapService.addPolygons({
            polygons: filteredFields?.map((field) => addPolygonData({ field, colorPalette: cropColors })),
        });

        return () => {
            resetMap(true);
        };
    }, [filteredFields, cropColors]);

    // ValidationSchema
    const validationSchema = Yup.object({
        dont_have_any: Yup.boolean().test(
            'no-tillage-practice',
            t('pages.operations.tillage.confirm-no-operation'),
            (value, context): boolean =>
                value === true || context.parent.operations?.filter((op: any) => !!op.passage).length > 0,
        ),
        tillageOperations: Yup.array().of(
            Yup.object({
                passage: Yup.number().integer().min(0),
            }),
        ),
    });

    // creating the form with empty operation meanwhile data is downloaded
    const tillageOperationForm = useFormik<TillageOperationFormT>({
        initialValues: {
            dont_have_any: false,
            operations: machineries?.map((machinery) => ({
                machinery_id: machinery.id,
                passage: '',
                id: '',
            })),
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const postData: PostFarmSeasonOperationTillageApiT = {
                tillageId: Number(tillageId),
                farmSeasonCropId: Number(cropId),
                farmSeasonFieldIds: filteredFields.map((field) => field.id),
                dont_have_any: values.dont_have_any,
                operations: values.operations
                    ?.filter((op) => !!op.passage)
                    .map((op) => emptyFieldValuesToNull(op)) as PostFarmSeasonOperationTillageApiT['operations'],
            };

            setSubmitted(true);
            await dispatch(postFarmSeasonOperationTillage(farmId, farmSeasonId, postData));
            setSubmitting(false);
            navigate(`/operations/${cropId}/${tillageId}`);
        },
    });

    // This will filter index values based on machinery order.
    // Without this changing the language will reorder the labels but keep the input data in place (which by itself can cause issues)
    useEffect(() => {
        tillageOperationForm.setValues({
            ...tillageOperationForm.values,
            operations: [
                ...machineries.map((machinery) => ({
                    ...tillageOperationForm.values.operations.find(
                        (operation) => operation.machinery_id === machinery.id,
                    ),
                })),
            ] as OperationFieldT[],
        });
    }, [i18n.language]);

    // update the form with operation data
    useEffect(() => {
        const operationsForm: OperationFieldT[] = machineries?.map((machinery): OperationFieldT => {
            const associatedOperation = operationsData?.operations?.find((op) => op.machinery_id === machinery.id);

            return {
                id: associatedOperation?.id ?? '',
                machinery_id: machinery.id,
                passage: associatedOperation?.passage ?? '',
            };
        });

        tillageOperationForm.resetForm({
            values: { operations: operationsForm, dont_have_any: operationsData?.dont_have_any ?? false },
        });
    }, [operationsData]);

    if (!currentTillage || !currentCrop) {
        return null;
    }

    return (
        <TillageView
            currentCrop={currentCrop}
            currentTillage={currentTillage}
            farmId={farmId}
            farmSeasonId={farmSeasonId}
            operationsData={operationsData}
            operationsDataLoading={operationsDataLoading}
            submitted={submitted}
            tillageMachineries={machineries}
            tillageOperationForm={tillageOperationForm}
            canEdit={canEditOperations.access}
        />
    );
};

export default Tillage;
