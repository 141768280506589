import { ActionNamesT } from './CardFlowNavigationContainer.types';

// get slugs depending on state
const slugStateMapping: { [key in ActionNamesT]: { title: string; description: string; button?: string } } = {
    'baseline-self-encoding-to-start': {
        description: 'farm-dashboard.navigation.self-encoding-to-start.description.baseline',
        title: 'farm-dashboard.navigation.self-encoding-to-start.title.baseline',
        button: 'farm-dashboard.navigation.self-encoding-to-start.button.baseline',
    },
    'renewal-self-encoding-to-start': {
        description: 'farm-dashboard.navigation.self-encoding-to-start.description.renewal',
        title: 'farm-dashboard.navigation.self-encoding-to-start.title.renewal',
        button: 'farm-dashboard.navigation.self-encoding-to-start.button.renewal',
    },
    // Same card as 'renewal-self-encoding-to-start'
    'renewal-new-season-to-start': {
        description: 'farm-dashboard.navigation.self-encoding-to-start.description.renewal',
        title: 'farm-dashboard.navigation.self-encoding-to-start.title.renewal',
        button: 'farm-dashboard.navigation.self-encoding-to-start.button.renewal',
    },
    'renewal-payment-required': {
        button: 'farm-dashboard.navigation.self-encoding-to-start.button.payment-required',
        description: 'farm-dashboard.navigation.self-encoding-to-start.description.payment-required',
        title: 'farm-dashboard.navigation.self-encoding-to-start.title.payment-required',
    },
    'renewal-payment-in-progress': {
        description: 'farm-dashboard.navigation.self-encoding-to-start.description.payment-in-progress',
        title: 'farm-dashboard.navigation.self-encoding-to-start.title.payment-in-progress',
    },
    'results-available': {
        button: 'farm-dashboard.navigation.results-available.button',
        description: 'farm-dashboard.navigation.results-available.description',
        title: 'farm-dashboard.navigation.results-available.title',
    },
    'self-encoding-ongoing': {
        button: 'farm-dashboard.navigation.self-encoding-ongoing.button',
        description: 'farm-dashboard.navigation.self-encoding-ongoing.description',
        title: 'farm-dashboard.navigation.self-encoding-ongoing.title',
    },
    'waiting-agronomist-encoding': {
        description: 'farm-dashboard.navigation.waiting-agronomist-encoding.description',
        title: 'farm-dashboard.navigation.waiting-agronomist-encoding.title',
    },
    'waiting-data-validation': {
        button: 'farm-dashboard.navigation.waiting-data-validation.button',
        description: 'farm-dashboard.navigation.waiting-data-validation.description',
        title: 'farm-dashboard.navigation.waiting-data-validation.title',
    },
    'waiting-results-validation': {
        description: 'farm-dashboard.navigation.waiting-results-validation.description',
        title: 'farm-dashboard.navigation.waiting-results-validation.title',
    },
};

export default slugStateMapping;
