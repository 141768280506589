import IconCalendar from '@icons/icon-calendar.svg';

import TopBarSelector from '../TopBarSelector/TopBarSelector';

import { SeasonSelectorViewPropsT } from './SeasonSelectorTypes';

const SeasonSelectorView = ({ onChange, currentValue, seasonList, text }: SeasonSelectorViewPropsT): JSX.Element => (
    <TopBarSelector
        currentValue={currentValue}
        icon={{ icon: IconCalendar, viewBox: '0 0 14.846 16.301' }}
        listItem={seasonList}
        text={text}
        onChange={onChange}
    />
);

export default SeasonSelectorView;
