import { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useTranslation } from 'react-i18next';
import { FarmSeasonFieldCropDataT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';
import { FarmSeasonCropDataT } from '@pages/HomePage/Installation/InstallationTasks/CropsTask/CropListStep/CropListStepTypes';

import { CropModalViewPropsT } from './CropModalViewTypes';
import useCropModalStyle from './CropModalView.style';

const CropModalView = ({
    title,
    className,
    leftOnClick,
    leftButtonText,
    rightOnCLick,
    rightButtonText,
    fieldCrops,
}: CropModalViewPropsT): JSX.Element => {
    const { classes } = useCropModalStyle();
    const { t } = useTranslation();
    const [fieldCrop, setFieldCrop] = useState<FarmSeasonFieldCropDataT | null>(null);
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList);

    return (
        <Paper className={`${className} ${classes.container}`} elevation={0}>
            <Typography className={classes.title} color="secondary" variant="h4">
                {title}
            </Typography>
            <div className={classes.select}>
                <LabelAutocomplete
                    getOptionDisabled={(crop) => !!fieldCrops.find((item) => item.farm_season_crop.id === crop.id)}
                    getOptionLabel={(crop) =>
                        `${t(crop.crop?.translation_slug ?? '')}${
                            crop.is_accompanied ? t('constants.select-companion') : ''
                        }`
                    }
                    label={t('pages.fields.crop-relations.crop-modal.crop-label')}
                    options={crops}
                    value={fieldCrop?.farm_season_crop?.id || ''}
                    onChange={(cropId) => {
                        setFieldCrop({
                            coupled_id: null,
                            order: 0,
                            farm_season_crop: crops.find((crop) => crop.id === cropId) as FarmSeasonCropDataT,
                            yield: null,
                            residue: null,
                        });
                    }}
                />
            </div>
            <TwoButtonFooterView
                leftOnClick={leftOnClick}
                leftText={leftButtonText}
                rightDisabled={!fieldCrop?.farm_season_crop?.id}
                rightOnCLick={() => fieldCrop && rightOnCLick(fieldCrop)}
                rightText={rightButtonText}
            />
        </Paper>
    );
};

export default CropModalView;
