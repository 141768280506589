import { api } from '@api/api.service';
import {
    GetPaymentStatusRequestT,
    GetPaymentStatusResponseT,
    GetRenewalContextRequestT,
    GetRenewalContextResponseT,
    PayInAdvanceRequestT,
    PayInAdvanceResponseT,
    PayInitialSubscriptionRequestT,
    PayInitialSubscriptionResponseT,
} from './renewal.types';

export const renewalApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRenewalContext: build.query<GetRenewalContextResponseT, GetRenewalContextRequestT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/renewal/context`,
                method: 'GET',
            }),
        }),
        payInAdvance: build.mutation<PayInAdvanceResponseT, PayInAdvanceRequestT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/renewal/pay`,
                method: 'POST',
            }),
        }),
        payInitialSubscription: build.mutation<PayInitialSubscriptionResponseT, PayInitialSubscriptionRequestT>({
            query: ({ farmId, farmSeasonId, body }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/renewal/pay-initial-subscription`,
                method: 'POST',
                body: body,
            }),
        }),

        getPaymentStatus: build.query<GetPaymentStatusResponseT, GetPaymentStatusRequestT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/renewal/payment-status`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetRenewalContextQuery,
    usePayInAdvanceMutation,
    useLazyGetPaymentStatusQuery,
    usePayInitialSubscriptionMutation,
} = renewalApi;
