import { AppActionsT } from '@actions/AppActions/AppActionsTypes';
import { DELETE_FARM_SEASON_CROP_SUCCESS } from '@actions/FarmSeasonCropActions/DeleteFarmSeasonCropTypes';
import { DELETE_FARM_SEASON_FIELD_SUCCESS } from '@actions/FarmSeasonFieldActions/DeleteFarmSeasonFieldTypes';
import { GET_FARM_SEASON_FIELDS_SUCCESS } from '@actions/FarmSeasonFieldActions/GetFarmSeasonFieldsTypes';
import { POST_FARM_SEASON_FIELDS_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTypes';
import { POST_FARM_SEASON_FIELD_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldTypes';
import { POST_FARM_SEASON_FIELDS_CROPS_SUCCESS } from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsTypes';
import { MAP_UPDATE } from '@actions/MapActions/MapActionsTypes';
import { geoJsonFeatureT, MapPropsT } from '@components/Map/MapTypes';
import { RESET } from '@reducers/RootTypes';
import { Reducer } from '@reduxjs/toolkit';
import addPolygonData from '@utils/addPolygonData';
import setupCropPalette from '@utils/setupCropPalette';

import { FarmSeasonFieldT } from '../FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { MapReducerStateT } from './MapReducerTypes';

// SC HQ ;)
export const defaultMapState: MapReducerStateT = {
    maps: [
        {
            id: 'map',
            latitude: 50.642919,
            longitude: 4.7981658,
            zoom: 15,
            animationDuration: 8000,
            creatablePolygons: false,
            drawing: false,
            canSelectPermanent: false,
            selectablePolygons: false,
        },
        {
            id: 'map-results-benchmark',
            latitude: 50.642919,
            longitude: 4.7981658,
            zoom: 8,
            animationDuration: 8000,
            creatablePolygons: false,
            drawing: false,
            canSelectPermanent: false,
            selectablePolygons: false,
        },
    ],
};

const mapReducer: Reducer<MapReducerStateT, AppActionsT> = (state, action): MapReducerStateT => {
    if (!state) {
        return defaultMapState;
    }

    if (action.type === MAP_UPDATE) {
        const newOrUpdatedMap = [action.payload];
        const mergedMaps: MapPropsT[] = [];

        state.maps.forEach((item) => {
            mergedMaps.push({
                ...item,
                ...newOrUpdatedMap.find((itmInner) => itmInner.id === item.id),
            });
        });

        return {
            maps: mergedMaps,
        };
    }

    if (
        action.type === POST_FARM_SEASON_FIELDS_SUCCESS ||
        action.type === GET_FARM_SEASON_FIELDS_SUCCESS ||
        action.type === POST_FARM_SEASON_FIELDS_CROPS_SUCCESS ||
        action.type === DELETE_FARM_SEASON_CROP_SUCCESS
    ) {
        if (!action.payload.fields) {
            return state;
        }

        const cropColors = setupCropPalette(action.payload.fields);
        const newPolygonLayer = action.payload.fields.map((field: FarmSeasonFieldT) =>
            addPolygonData({ field, colorPalette: cropColors }),
        );

        return {
            maps: state.maps.map((map) => {
                if (map.id === 'map') {
                    return {
                        ...map,
                        polygonsLayer: newPolygonLayer,
                    };
                }

                return map;
            }),
        };
    }

    if (action.type === POST_FARM_SEASON_FIELD_SUCCESS) {
        return {
            maps: state.maps.map((map) => {
                if (map.id === 'map') {
                    return {
                        ...map,
                        polygonsLayer: [...(map.polygonsLayer as geoJsonFeatureT[]), action.payload.field.polygon],
                    };
                }

                return map;
            }),
        };
    }

    if (action.type === DELETE_FARM_SEASON_FIELD_SUCCESS) {
        return {
            maps: state.maps.map((map) => {
                if (map.id === 'map') {
                    return {
                        ...map,
                        polygonsLayer: map.polygonsLayer?.filter(
                            (polygon) => +polygon.id !== action.payload.farmSeasonFieldId,
                        ),
                    };
                }

                return map;
            }),
        };
    }

    if (action.type === RESET) {
        return {
            ...defaultMapState,
        };
    }

    return state;
};

export default mapReducer;
