import { useModal } from '@soil-capital/ui-kit';
import { useEditIbanMutation, useGetPayoutContextQuery } from '@api/payout.api';
import { PayoutContextT } from '@api/payout.types';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { appAddPendingStateForHomePageLayout } from '@store/actions/AppActions/AppActions';
import formatedIban from '@utils/formatIban';
import { useFormik } from 'formik';
import { isValidIBAN, electronicFormatIBAN } from 'ibantools';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useCanEditIBAN } from '@hooks/usePermissions';

const usePayoutCardLogic = () => {
    const { t } = useTranslation();
    const editModalController = useModal();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const countryISO = useSelector((state: HomePageStateT) => state.farm.currentFarm?.country.iso_code);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const { data: payoutContext, isLoading: payoutContextLoading } = useGetPayoutContextQuery({ farmId, farmSeasonId });
    const [updateIban, { isLoading: updateIbanLoading }] = useEditIbanMutation();
    const isAllowedToChangeIBAN = useCanEditIBAN();

    const validationSchema = yup.object({
        iban: yup
            .string()
            .required(t('pages.settings.billing.certificate-information.iban.edit.error.missing-characters'))
            .test('iban', t('pages.settings.billing.certificate-information.iban.edit.error.invalid-iban'), (iban) =>
                Boolean(iban && isValidIBAN(electronicFormatIBAN(iban) as string)),
            ),
    });

    const formik = useFormik<PayoutContextT>({
        initialValues: {
            iban: payoutContext?.iban ?? '',
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async ({ iban }) => {
            try {
                await updateIban({ farmId, farmSeasonId, body: { iban: electronicFormatIBAN(iban) ?? '' } }).unwrap();
                editModalController.onModalCancel();
            } catch (err) {}
        },
    });

    const mask =
        countryISO === 'FR'
            ? 'FR00 0000 0000 00** **** **** *00'
            : countryISO === 'BE'
            ? 'BE00 0000 0000 0000'
            : countryISO === 'GB'
            ? 'GB00 **** 0000 0000 0000 00'
            : '##00 0000 0000 0000 0000 0000 0000';

    useEffect(() => {
        appAddPendingStateForHomePageLayout({ pending: payoutContextLoading, slug: 'PayoutContextLoadingCard' });
    }, [payoutContextLoading]);

    const handlePayoutCardEditButtonClick = () => editModalController.openModal();

    return {
        iban: payoutContext?.iban
            ? formatedIban(payoutContext.iban)
            : t('pages.settings.billing.certificate-information.iban.no-iban'),
        hasIban: Boolean(payoutContext?.iban),
        handlePayoutCardEditButtonClick,
        editModalController,
        formik,
        mask,
        updateIbanLoading,
        isAllowedToChangeIBAN,
    };
};

export default usePayoutCardLogic;
