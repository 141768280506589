import { makeStyles } from 'tss-react/mui';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useFarmerDataViewStyle = makeStyles()((theme) => ({
    title: {
        marginBottom: '20px',
    },

    divider: { margin: '30px 0', backgroundColor: theme.palette.secondary.light },

    fieldDataContainer: {
        backgroundColor: theme.palette.spanishWhiteBgShadow,
        borderRadius: theme.shape.borderRadius,
    },

    paginationValidationSection: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '20px',
        alignItems: 'center',
        '&>.MuiCard-root': {
            padding: theme.spacing(2),
        },
        position: 'sticky',
        bottom: -theme.spacing(10),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        backgroundColor: theme.palette.spanishWhite.light,
        borderTop: `1px solid ${theme.palette.secondary.light}`,
    },

    titleHourglass: {
        display: 'flex',
        '& > svg': {
            marginLeft: 'auto',
        },
    },
}));

export default useFarmerDataViewStyle;
