import { makeStyles } from 'tss-react/mui';

const useFieldsGroupListViewStyle = makeStyles()((theme) => ({
    container: {},
    checkbox: {},
    title: {
        marginBottom: '20px',
    },
    titleDark: {
        color: theme.palette.secondary.dark,
    },
    emptyFieldContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '240px',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '10px',
        backgroundColor: 'rgba(237,200,128,0.15)',
        borderRadius: '10px',
    },
    listContainer: {
        marginBottom: '20px',
    },
    coverCropGroupInfo: {
        display: 'flex',
        paddingRight: '10px',
        justifyContent: 'space-between',
    },
    addButton: {
        marginBottom: '20px',
        padding: '15px',
        fontSize: '0.8125rem',
    },
    addIcon: {
        fontSize: '1.2rem',
        marginRight: '10px',
    },
}));

export default useFieldsGroupListViewStyle;
