import {
    PostFarmSeasonInstallationConfirmDataT,
    PostFarmSeasonInstallationConfirmResponseDataT,
} from '@actions/FarmSeasonActions/FarmSeasonPostInstallationConfirmTypes';
import { UserT } from '@reducers/AuthReducer/AuthReducerTypes';
import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

import {
    ApprovalsDataT,
    CheckTotalAreaParamsT,
    CheckTotalAreaResponseT,
    UpdateFarmSeasonDataT,
} from './FarmSeasonApiServiceTypes';

class FarmSeasonApiService {
    public static getAll = async (farmId: number): Promise<AxiosResponse> =>
        request({
            url: `/farm/${farmId}/seasons`,
            method: 'GET',
        });

    public static getNext = async (farmId?: number, farmSeasonId?: number): Promise<AxiosResponse<FarmSeasonT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/next`, method: 'GET' });

    public static postConfirm = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonInstallationConfirmDataT,
    ): Promise<AxiosResponse<PostFarmSeasonInstallationConfirmResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/confirm`,
            method: 'POST',
            data: body,
        });

    public static finalizeSeason = (farmId: number, farmSeasonId: number): Promise<AxiosResponse<FarmSeasonT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/finalize`, method: 'PUT' });

    public static validateSeason = (farmId: number, farmSeasonId: number): Promise<AxiosResponse<FarmSeasonT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/validate`, method: 'PUT' }, false);

    /** create or update t&c approvals */
    public static postApprovals = (
        farmId: number,
        farmSeasonId: number,
        data: ApprovalsDataT,
    ): Promise<AxiosResponse<ApprovalsDataT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/tac`, method: 'POST', data });

    public static getApprovals = (farmId: number, farmSeasonId: number): Promise<AxiosResponse<ApprovalsDataT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/tac`, method: 'GET' });

    public static updateFarmSeason = (farmId: number, farmSeasonId: number, data: UpdateFarmSeasonDataT) =>
        request<{ farmSeason: FarmSeasonT; user: UserT }>({
            method: 'PUT',
            url: `/farm/${farmId}/season/${farmSeasonId}`,
            data,
        });

    public static validateByAgronomist = (farmId: number, farmSeasonId: number): Promise<AxiosResponse<FarmSeasonT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/agronomist-validate`, method: 'PUT' });

    public static invalidateByAgronomist = (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<FarmSeasonT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/agronomist-invalidate`, method: 'PUT' });

    public static startResultProcess = (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<{ status: 'SUCCESS' | 'FAILED' | 'PROCESS' }>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/start-result-process`, method: 'POST' });

    public static checkResultProcess = (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<{ status: 'SUCCESS' | 'FAILED' | 'PROCESS' }>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/process-status`, method: 'GET' });

    public static createNextFarmSeason = (farmId: number): Promise<AxiosResponse<FarmSeasonT>> =>
        request({ url: `/farm/${farmId}/next-farm-season`, method: 'POST' }, false);

    public static openNextFarmSeason = (farmId: number, farmSeasonId: number): Promise<AxiosResponse<FarmSeasonT>> =>
        request({ url: `/farm/${farmId}/season/${farmSeasonId}/renewal/open-next-season`, method: 'POST' }, false);

    /**
     * Compare totalArea from $farmSeason with previous one
     * @param farmId
     * @param farmSeasonId
     */
    public static checkTotalAreaWithPreviousSeason = ({
        farmId,
        farmSeasonId,
    }: CheckTotalAreaParamsT): Promise<AxiosResponse<CheckTotalAreaResponseT>> => {
        return request({ url: `/farm/${farmId}/season/${farmSeasonId}/check-total-area`, method: 'POST' }, false);
    };
}

export default FarmSeasonApiService;
