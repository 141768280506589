import { UserCompanyT } from '@services/userCompanyApiService/userCompanyApiServiceTypes';

export const SET_BILLING_DETAILS_LOADING = 'SET_BILLING_DETAILS_LOADING';
export const SET_BILLING_DETAILS_SUCCESS = 'SET_BILLING_DETAILS_SUCCESS';
export const SET_BILLING_DETAILS_FAILED = 'SET_BILLING_DETAILS_FAILED';
export const UPDATE_BILLING_DETAILS = 'UPDATE_BILLING_DETAILS';

export type BillingDetailsLoadingT = {
    type: typeof SET_BILLING_DETAILS_LOADING;
};

export type BillingDetailsSuccessT = {
    type: typeof SET_BILLING_DETAILS_SUCCESS;
    payload: UserCompanyT;
};

export type BillingDetailsFailedT = {
    type: typeof SET_BILLING_DETAILS_FAILED;
    error: unknown;
};

export type BillingDetailsUpdateT = {
    type: typeof UPDATE_BILLING_DETAILS;
    payload: UserCompanyT;
};

export type BillingDetailsDispatchesT =
    | BillingDetailsLoadingT
    | BillingDetailsSuccessT
    | BillingDetailsFailedT
    | BillingDetailsUpdateT;
