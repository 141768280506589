import { Button, Typography } from '@mui/material';
import { useAutoAnimate } from '@hooks/useAutoAnimate';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CallToActionSuggestionPropsT } from './CallToActionSuggestionTypes';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import useCallToActionSuggestionStyle from './CallToActionSuggestion.style';

/** display different texts about suggestion depending on state: 'done', 'done + no result', 'not done' (-> call to action) */
const CallToActionSuggestion = ({
    suggestion: { done: suggestionDone, needAttentionCount, suggestedCount },
    onClick,
}: CallToActionSuggestionPropsT) => {
    const { t } = useTranslation();
    const animateParent = useRef(null);
    useAutoAnimate(animateParent);
    const { classes } = useCallToActionSuggestionStyle();

    const hasNoSuggestions = suggestionDone && needAttentionCount === 0 && suggestedCount === 0;
    let text: JSX.Element;

    switch (true) {
        case hasNoSuggestions:
            text = <Typography color="secondary">{t('components.suggestion-cta.no-brings-back-fields')}</Typography>;
            break;
        case suggestionDone:
            text = (
                <Typography color="secondary">
                    {t('components.suggestion-cta.brings-back-fields-done', { suggestedCount, needAttentionCount })}
                </Typography>
            );
            break;
        default:
            text = (
                <Button fullWidth color="secondary" onClick={onClick} startIcon={<AutoFixHighIcon />}>
                    {t('components.suggestion-cta.brings-back-fields')}
                </Button>
            );
    }

    return (
        <div ref={animateParent} className={classes.ctaWrapper}>
            {text}
        </div>
    );
};

export default CallToActionSuggestion;
