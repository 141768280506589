import { useRef, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import { DatePickerPropsT } from './DatePickerTypes';
import useDatePickerStyle from './DatePicker.style';

const DatePicker = ({
    value,
    onChange,
    openTo = 'year',
    maxDate,
    minDate,
    labelText,
    withHelp,
    error,
    helperText,
    views = ['year'],
    disabled,
}: DatePickerPropsT): JSX.Element => {
    const { classes } = useDatePickerStyle();
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const rootRef = useRef<HTMLDivElement>(null);

    const currentDate = new Date();
    const defaultDate = currentDate > maxDate ? maxDate : currentDate;

    const renderInput = () => (
        <>
            <InputLabel className={classes.label} id="area-label">
                {labelText}
                <span style={{ color: 'red' }}> *</span>
                {withHelp && <HelpButtonView id={`${withHelp}-help`} />}
            </InputLabel>
            <TextField
                error={error}
                disabled={disabled}
                helperText={helperText}
                value={value === '' ? '' : value.getFullYear()}
                variant="outlined"
                fullWidth
                required
                onClick={() => !disabled && setOpenDatePicker(true)}
            />
        </>
    );

    return (
        <div ref={rootRef} className="datePickerContainer" style={{ position: 'relative' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    PopperProps={{
                        anchorEl: rootRef.current,
                    }}
                    maxDate={maxDate}
                    minDate={minDate}
                    open={openDatePicker}
                    openTo={openTo}
                    renderInput={renderInput}
                    value={value || defaultDate}
                    views={views}
                    onChange={(e) => {
                        onChange(e);
                    }}
                    onClose={() => setOpenDatePicker(false)}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DatePicker;
