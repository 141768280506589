import {
    PostFarmSeasonFieldsSoilAcidityDataT,
    PostFarmSeasonFieldsSoilAcidityResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilAcidityTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsSoilAcidityApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsSoilAcidityDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsSoilAcidityResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/soil-acidities`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsSoilAcidityApiService;
