import { makeStyles } from 'tss-react/mui';

const useAgroforestryValuesFormViewStyle = makeStyles()(() => ({
    title: {
        marginBottom: '20px',
    },
    fieldName: {
        marginBottom: '5px',
    },
    footerButton: {
        marginTop: '20px',
        width: '100%',
    },
    addButton: {
        marginBottom: '20px',
        padding: '15px',
        fontSize: '0.8125rem',
    },
    addIcon: {
        fontSize: '1.2rem',
        marginRight: '10px',
    },
}));

export default useAgroforestryValuesFormViewStyle;
