import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CropsTaskView from './CropsTaskView';

const CropsTask = (): JSX.Element => {
    const [stepArray, setStepArray] = useState([1]);
    const [currentStep, setCurrentStep] = useState(stepArray[stepArray.length - 1]);
    // eslint-disable-next-line @typescript-eslint/ban-types
    const [cropColorPalette, setCropColorPalette] = useState<{}>({});
    const { t } = useTranslation();
    const cropsList = useSelector((state: HomePageStateT) => state.farmSeasonCrop.cropsList);

    useEffect(() => {
        if (cropsList?.length > 0) {
            setCropColorPalette(setupCropPaletteFromCropLegacy(cropsList));
        }
    }, [cropsList]);

    useEffect(() => {
        setCurrentStep(stepArray[stepArray.length - 1]);
    }, [stepArray]);

    const pushStep = (step: number) => {
        setStepArray((state) => [...state, step]);
    };

    const popStep = () => {
        setStepArray((state) => [...state.splice(0, state.length - 1)]);
    };

    const handleBackButton = () => {
        if (stepArray.length > 1) {
            setStepArray((state) => {
                const newState = [...state];
                newState.pop();

                return newState;
            });
        }
    };

    return (
        <CropsTaskView
            cropColorPalette={cropColorPalette}
            currentStep={currentStep}
            handleBackButton={handleBackButton}
            stepArray={stepArray}
            popStep={popStep}
            pushStep={pushStep}
            t={t}
        />
    );
};

export default CropsTask;
