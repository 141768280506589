import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import FarmApiService from '@services/farmApiService/farmApiService';

import {
    FarmDeleteActionCallback,
    FarmDeletePayloadT,
    FarmActionsT,
    FarmGetAllActionCallback,
    FarmResponseErrorT,
    FarmSaveActionCallback,
    FarmPayloadT,
    FarmT,
    FARM_ALL_LOADING,
    FARM_ALL_SUCCESS,
    FARM_DELETE_FAILED,
    FARM_DELETE_LOADING,
    FARM_DELETE_SUCCESS,
    FARM_SAVE_FAILED,
    FARM_SAVE_LOADING,
    FARM_SAVE_SUCCESS,
    FARM_UPDATE_FAILED,
    FARM_UPDATE_LOADING,
    FARM_UPDATE_SUCCESS,
    DeletedFarmT,
    FarmUpdatePayloadT,
    FarmSetCurrentT,
    FARM_SET_CURRENT,
    FARM_SET_LIST,
    FarmSetListT,
    FARM_ALL_FAILED,
} from './FarmActionsTypes';

export const farmSave =
    ({ farm, userId }: { farm: FarmPayloadT; userId: number }): FarmSaveActionCallback =>
    async (dispatch) => {
        try {
            dispatch({ type: FARM_SAVE_LOADING });

            const res: AxiosResponse<FarmT> = await FarmApiService.save({ payload: farm, userId });

            dispatch({
                type: FARM_SAVE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: FARM_SAVE_FAILED,
                error: error as FarmResponseErrorT,
            });

            return {};
        }
    };

export const farmGetAll =
    (userId: number): FarmGetAllActionCallback =>
    async (dispatch) => {
        try {
            dispatch({ type: FARM_ALL_LOADING });
            const res: AxiosResponse<FarmT[]> = await FarmApiService.getAll({ userId });
            dispatch({
                type: FARM_ALL_SUCCESS,
                payload: res.data,
            });

            return res.data;
        } catch (error) {
            dispatch({
                type: FARM_ALL_FAILED,
                error: error as FarmResponseErrorT,
            });

            return false;
        }
    };

export const farmUpdate =
    ({ farmId, userId, payload }: FarmUpdatePayloadT) =>
    async (dispatch: Dispatch<FarmActionsT>) => {
        try {
            dispatch({ type: FARM_UPDATE_LOADING });
            const res: AxiosResponse<FarmT> = await FarmApiService.update({ farmId, userId, payload });
            dispatch({
                type: FARM_UPDATE_SUCCESS,
                payload: res.data,
            });

            return res.data;
        } catch (error) {
            dispatch({
                type: FARM_UPDATE_FAILED,
                error: error as FarmResponseErrorT,
            });

            return false;
        }
    };

export const farmDelete =
    ({ farmId, userId }: FarmDeletePayloadT): FarmDeleteActionCallback =>
    async (dispatch: Dispatch<FarmActionsT>): Promise<FarmResponseErrorT> => {
        try {
            dispatch({ type: FARM_DELETE_LOADING });
            const res: AxiosResponse<DeletedFarmT> = await FarmApiService.delete({ farmId, userId });
            dispatch({
                type: FARM_DELETE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: FARM_DELETE_FAILED,
                error: error as FarmResponseErrorT,
            });

            return {};
        }
    };

export const farmSetCurrent =
    ({ farmId }: { farmId: number }) =>
    (dispatch: Dispatch<FarmSetCurrentT>) => {
        dispatch({ type: FARM_SET_CURRENT, payload: { farmId } });
    };

export const farmSetList =
    ({ farmList }: { farmList: FarmT[] }) =>
    (dispatch: Dispatch<FarmSetListT>) => {
        dispatch({ type: FARM_SET_LIST, payload: { farmList } });
    };
