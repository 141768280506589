import { makeStyles } from 'tss-react/mui';

const useNotificationListStyle = makeStyles()(() => ({
    NotificationList: {
        minHeight: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));

export default useNotificationListStyle;
