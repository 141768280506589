import { useContext, useRef } from 'react';
import CollapsableSectionView from '@components/CollapsableSection/CollapsableSectionView';
import { Collapse, Grid, Typography } from '@mui/material';
import DatePicker from '@components/DatePicker/DatePicker';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';
import useHistoryMaxYear from '@hooks/useHistoryMaxYear';

import { FieldsContextStoreT } from '../../FieldsContext/FieldsContextTypes';
import { FieldsContextStore } from '../../FieldsContext/FieldsContext';

import useTillagePracticeHistoryStyle from './TillagePracticeHistoryView.style';
import { TillagePracticeHistoryViewPropsT } from './TillagePracticeHistoryViewTypes';

const TillagePracticeHistoryView = ({ formik, disabledForm }: TillagePracticeHistoryViewPropsT): JSX.Element => {
    const { classes } = useTillagePracticeHistoryStyle();
    const { t, tillageList, tillageLoading } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const sectionRef = useRef<HTMLDivElement>(null);

    const historyMaxYear = useHistoryMaxYear();

    return (
        <CollapsableSectionView
            defaultCollapseState={true}
            helpName="pages.installationtasks.history.tillage-practice.history-form.title"
            sectionName={t('pages.installationtasks.history.tillage-practice-history')}
            sectionRef={sectionRef}
        >
            <>
                <Grid className={classes.gridRow} spacing={2} container>
                    <Grid xs={12} item>
                        <LabelCheckboxView
                            disabled={disabledForm}
                            checked={!!formik.values.tillage_practice_history?.has_tillage_practice_history}
                            error={
                                formik.touched.tillage_practice_history?.has_tillage_practice_history &&
                                !!formik.errors.tillage_practice_history?.has_tillage_practice_history
                            }
                            helperText={
                                formik.touched.tillage_practice_history?.has_tillage_practice_history
                                    ? formik.errors.tillage_practice_history?.has_tillage_practice_history
                                    : undefined
                            }
                            label={t('pages.fields.edit-field.this-field-has-tillage-practice-history')}
                            noMargin
                            onChange={(event) => {
                                formik.setFieldValue(
                                    'tillage_practice_history.has_tillage_practice_history',
                                    event.target.checked,
                                );
                                event.target.checked &&
                                    setTimeout(() => sectionRef.current?.scrollIntoView({ behavior: 'smooth' }), 400);
                            }}
                        />
                    </Grid>
                </Grid>
                <Collapse
                    in={formik.values.tillage_practice_history?.has_tillage_practice_history}
                    timeout="auto"
                    unmountOnExit
                >
                    <Grid className={classes.gridRow} spacing={2} container>
                        <Grid className={classes.inputContainer} xs={12} xl={4} lg={6} item>
                            <LabelAutocomplete
                                disabled={disabledForm}
                                error={
                                    formik.touched.tillage_practice_history?.tillage_change_type_id &&
                                    !!formik.errors.tillage_practice_history?.tillage_change_type_id
                                }
                                helpName="pages.installationtasks.hsitory.tillage-practice.tillage-practice-change"
                                helperText={
                                    formik.touched.tillage_practice_history?.tillage_change_type_id
                                        ? formik.errors.tillage_practice_history?.tillage_change_type_id
                                        : undefined
                                }
                                id="tillage_practice_history.tillage_change_type_id"
                                label={t('constants.tillage-practice-change')}
                                loading={tillageLoading}
                                options={tillageList}
                                value={
                                    formik.values.tillage_practice_history?.tillage_change_type_id !== '' &&
                                    formik.values.tillage_practice_history?.tillage_change_type_id !== undefined
                                        ? Number(formik.values.tillage_practice_history?.tillage_change_type_id)
                                        : ''
                                }
                                required
                                withHelp
                                onChange={(tillageId) => {
                                    formik.setFieldValue(
                                        'tillage_practice_history.tillage_change_type_id',
                                        tillageId,
                                        false,
                                    );
                                    formik.setFieldValue('tillage_practice_history.year_end_labour', ''); // Change Year end of labour to empty if the practice change has been changed
                                    setTimeout(() =>
                                        formik.setFieldTouched('tillage_practice_history.tillage_change_type_id', true),
                                    );
                                }}
                            />
                        </Grid>
                        <Grid xs={6} xl={4} lg={6} marginRight={'50%'} item>
                            <DatePicker
                                disabled={disabledForm}
                                error={
                                    formik.touched.tillage_practice_history?.year_of_change &&
                                    !!formik.errors.tillage_practice_history?.year_of_change
                                }
                                helperText={
                                    formik.touched.tillage_practice_history?.year_of_change &&
                                    formik.errors.tillage_practice_history?.year_of_change
                                }
                                labelText={t('constants.year-of-change')}
                                maxDate={historyMaxYear}
                                openTo="year"
                                value={
                                    formik.values.tillage_practice_history?.year_of_change === ''
                                        ? ''
                                        : new Date(formik.values.tillage_practice_history?.year_of_change)
                                }
                                withHelp="pages.installationtasks.hsitory.tillage-practice.year-of-change"
                                onChange={(event) => {
                                    const year = new Date(event).getFullYear().toString();
                                    formik.setFieldValue('tillage_practice_history.year_of_change', year);
                                    setTimeout(() =>
                                        formik.setFieldTouched('tillage_practice_history.year_of_change', true),
                                    );
                                }}
                            />
                        </Grid>

                        {[1, 2, 3, 4].includes(+formik.values.tillage_practice_history?.tillage_change_type_id) && (
                            <Grid xs={6} xl={4} lg={6} item>
                                <Typography className={classes.defineConventional} component="span" variant="body1">
                                    {t(
                                        'pages.installationtasks.history.tillage-practice.history-form.define-end-conventional-till',
                                    )}
                                </Typography>
                                <DatePicker
                                    disabled={disabledForm}
                                    labelText={t(
                                        'pages.installationtasks.history.tillage-practice.history-form.conventional-till-end-year',
                                    )}
                                    maxDate={historyMaxYear}
                                    openTo="year"
                                    value={
                                        formik.values.tillage_practice_history?.year_end_labour === ''
                                            ? ''
                                            : new Date(formik.values.tillage_practice_history?.year_end_labour)
                                    }
                                    onChange={(event) => {
                                        const year = new Date(event).getFullYear().toString();
                                        formik.setFieldValue('tillage_practice_history.year_end_labour', year);
                                        setTimeout(() =>
                                            formik.setFieldTouched('tillage_practice_history.year_end_labour', true),
                                        );
                                    }}
                                />
                                {formik.touched.tillage_practice_history?.year_end_labour &&
                                    !!formik.errors.tillage_practice_history?.year_end_labour && (
                                        <Typography className={classes.errorYearLabour} variant="body2">
                                            {formik.errors.tillage_practice_history?.year_end_labour}
                                        </Typography>
                                    )}
                            </Grid>
                        )}
                    </Grid>
                </Collapse>
            </>
        </CollapsableSectionView>
    );
};

export default TillagePracticeHistoryView;
