import { ROUTE } from '@constants/routes';
import CarbonBank from '@pages/HomePage/CarbonBank/CarbonBank';
import Crops from '@pages/HomePage/Crops/Crops';
import Farm from '@pages/HomePage/Farm/Farm';
import FarmEdit from '@pages/HomePage/FarmEdit/FarmEdit';
import FarmerDataView from '@pages/HomePage/FarmerData/FarmerDataView';
import EditField from '@pages/HomePage/Fields/EditField/EditField';
import Fields from '@pages/HomePage/Fields/Fields';
import Installation from '@pages/HomePage/Installation/Installation';
import Operations from '@pages/HomePage/Operations/Operations';
import Result from '@pages/HomePage/Result/Result';
import Settings from '@pages/HomePage/Settings/Settings';
import {
    hasCertificatesGuard,
    hasDataSummaryAccessGuard,
    hasOnboardingFieldDoneGuard,
    hasResultAccessGuard,
    isAgronomistWithSelectedFarm,
    isInstallationOpenedIfAuthFarmerGuard,
    isNotReadOnlyUser,
} from '@services/guardsService/guardsService';
import { Navigate } from 'react-router-dom';
import HistorySubtasksRouter from '../HistorySubtasksRouter/HistorySubtasksRouter';
import { HomePageRouteObjectT } from './HomePageRouterTypes';

const useHomePageRouterConfig = (): HomePageRouteObjectT[] => {
    const homePageRouterConfig: HomePageRouteObjectT[] = [
        {
            path: '/farm/edit',
            element: <FarmEdit />,
        },
        {
            path: '/farm/*',
            element: <Farm />,
        },
        {
            path: '/fields',
            element: <Fields />,
            guards: [hasOnboardingFieldDoneGuard],
            screen: 'main-left',
            children: [
                {
                    screen: 'secondary-rightPartial',
                    path: 'edit',
                    element: <EditField />,
                },
            ],
        },
        {
            path: '/crops/*',
            guards: [hasOnboardingFieldDoneGuard],
            element: <Crops />,
        },
        {
            path: '/operations/*',
            guards: [isAgronomistWithSelectedFarm, isNotReadOnlyUser],
            element: <Operations />,
        },
        {
            path: '/practice-history/*',
            guards: [isAgronomistWithSelectedFarm, isNotReadOnlyUser],
            element: <HistorySubtasksRouter />,
        },
        {
            path: '/farmer-data/:stripeStatus',
            guards: [hasDataSummaryAccessGuard, isNotReadOnlyUser],
            element: <FarmerDataView />,
            screen: 'main-full',
        },
        {
            path: ROUTE.DATA,
            guards: [hasDataSummaryAccessGuard],
            element: <FarmerDataView />,
            screen: 'main-full',
        },
        {
            path: ROUTE.RESULTS,
            guards: [hasResultAccessGuard],
            element: <Result />,
            screen: 'main-full',
        },
        {
            path: '/certificates',
            guards: [hasCertificatesGuard],
            element: <CarbonBank />,
            screen: 'main-full',
        },
        {
            path: '/billing',
            element: <Settings />,
            screen: 'main-full',
        },
        {
            path: `${ROUTE.SELF_ENCODING}/*`,
            guards: [isInstallationOpenedIfAuthFarmerGuard],
            element: <Installation />,
        },
        {
            path: '/*',
            element: <Navigate to="/farm" />,
        },
    ];

    return homePageRouterConfig;
};

export default useHomePageRouterConfig;
