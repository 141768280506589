import MapService from '@services/mapService/mapService';
import { MapEventsSubscribableT } from '@services/mapService/mapServiceTypes';
import { useEffect } from 'react';

/**
 * handle subscriptions on Map events. Unsubscribe on component unmount
 * @param currentComponentName: used as id to keep the subscription (useful to debug)
 * @param handleMapActionDeps the handleMapAction could depend on react state.
 *                      As the callback is passed once, we need to refresh it for deps
 */
const useMapObserver = <K extends keyof MapEventsSubscribableT>(
    actionName: K,
    handleMapAction: (data: MapEventsSubscribableT[K]) => void,
    handleMapActionDeps?: unknown[],
    currentComponentName?: string,
): void => {
    useEffect(() => {
        const { subscriberId } = MapService.observer.attach(actionName, handleMapAction, currentComponentName);
        return () => MapService.observer.detach(actionName, subscriberId);
    }, [...(handleMapActionDeps || [])]);
};

export default useMapObserver;
