import { makeStyles } from 'tss-react/mui';

const useOperationCardFormStyle = makeStyles()(() => ({
    card: {
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.05)',
        marginBottom: '15px',
        padding: '15px',
    },
}));

export default useOperationCardFormStyle;
