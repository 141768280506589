import PastureTaskContext from './PastureTaskContext/PastureTaskContext';
import PastureTaskView from './PastureTaskView';

const PastureTask = (): JSX.Element => (
    <PastureTaskContext>
        <PastureTaskView />
    </PastureTaskContext>
);

export default PastureTask;
