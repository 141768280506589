import { makeStyles } from 'tss-react/mui';

const useCardFlowStyle = makeStyles()((theme) => ({
    root: {
        width: '750px',
        height: '620px',
        borderRadius: '20px',
        overflow: 'hidden',
        position: 'relative',
    },
    card: {
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        padding: '40px 60px',
        ...theme.styles.scrollSection,
    },
    progressBar: {
        height: '6px',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        zIndex: 5,
    },
}));

export default useCardFlowStyle;
