import FarmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonOperationDeleteDeleteCallBackT,
    DeleteFarmSeasonOperationDeleteDispatchTypesT,
    DeleteFarmSeasonOperationDeleteResponseDataT,
    DeleteFarmSeasonOperationDeleteResponseErrorT,
    FARM_SEASON_OPERATION_DELETE_FAILED,
    FARM_SEASON_OPERATION_DELETE_LOADING,
    FARM_SEASON_OPERATION_DELETE_SUCCESS,
} from './DeleteFarmSeasonOperationTypes';

export const DeleteFarmSeasonOperation =
    (
        farmId: number,
        farmSeasonId: number,
        operationType: 'tillage' | 'spraying' | 'fertilising' | 'sowing',
        tillageId: number | string,
        farmSeasonCropId: number | string,
    ): FarmSeasonOperationDeleteDeleteCallBackT =>
    async (
        dispatch: Dispatch<DeleteFarmSeasonOperationDeleteDispatchTypesT>,
    ): Promise<DeleteFarmSeasonOperationDeleteResponseErrorT> => {
        try {
            dispatch({ type: FARM_SEASON_OPERATION_DELETE_LOADING });
            const res: AxiosResponse<DeleteFarmSeasonOperationDeleteResponseDataT> =
                await FarmSeasonOperationApiService.delete(
                    farmId,
                    farmSeasonId,
                    operationType,
                    tillageId,
                    farmSeasonCropId,
                );

            dispatch({
                type: FARM_SEASON_OPERATION_DELETE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: FARM_SEASON_OPERATION_DELETE_FAILED,
                error: error as DeleteFarmSeasonOperationDeleteResponseErrorT,
            });

            return {};
        }
    };

export const DeleteFarmSeasonOperations =
    (
        farmId: number,
        farmSeasonId: number,
        tillageId: number | string,
        farmSeasonCropId: number | string,
    ): FarmSeasonOperationDeleteDeleteCallBackT =>
    async (
        dispatch: Dispatch<DeleteFarmSeasonOperationDeleteDispatchTypesT>,
    ): Promise<DeleteFarmSeasonOperationDeleteResponseErrorT> => {
        try {
            dispatch({ type: FARM_SEASON_OPERATION_DELETE_LOADING });
            const res: AxiosResponse<DeleteFarmSeasonOperationDeleteResponseDataT> =
                await FarmSeasonOperationApiService.deleteAll(farmId, farmSeasonId, tillageId, farmSeasonCropId);

            dispatch({
                type: FARM_SEASON_OPERATION_DELETE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: FARM_SEASON_OPERATION_DELETE_FAILED,
                error: error as DeleteFarmSeasonOperationDeleteResponseErrorT,
            });

            return {};
        }
    };
