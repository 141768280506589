/* eslint-disable @typescript-eslint/no-explicit-any */
import MapService from '@services/mapService/mapService';
import { useModal } from '@soil-capital/ui-kit';
import { useContext, useEffect, useState } from 'react';
import useAppNavigate from '@hooks/useAppNavigate';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import postFarmSeasonFieldsIrrigation from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsIrrigation';
import { useFormik } from 'formik';

import { IrrigationContext } from '../IrrigationTaskContext/IrrigationTaskContext';

import IrrigatedFieldsSelectView from './IrrigatedFieldsSelectView';
import { FormikFieldsIrrigationDataT } from './IrrigatedFieldsSelectTypes';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const IrrigatedFieldsSelect = (): JSX.Element => {
    const { t, farmId, farmSeasonId, fieldLoading, pushStep, fieldsList, thisMap } = useContext<any>(IrrigationContext);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const modalDeleteSelection = useModal();
    const modalDeleteField = useModal();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    const dontHaveAny = useSelector(
        (state: HomePageStateT) =>
            state.farmSeason.currentFarmSeason?.installation?.onboarding?.irrigation?.dontHaveAny,
    );
    const handleNavigation = (fieldsLength: number) => {
        if (fieldsLength > 0) {
            pushStep(2);
        } else {
            navigate('..');
        }
    };

    // initialize formik
    const irrigatedFields = fieldsList?.filter((field: any) => field.is_irrigated);
    const formik: FormikFieldsIrrigationDataT = useFormik({
        initialValues: {
            irrigation: dontHaveAny === null ? true : !dontHaveAny,
            fields: irrigatedFields?.length > 0 ? [...irrigatedFields] : [],
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsSubmitted(true);
            await dispatch(postFarmSeasonFieldsIrrigation(farmId, farmSeasonId, values));

            handleNavigation(values.fields.length);
        },
        validate: (values) => {
            const errors: any = {}; // eslint-disable-line

            if (values.fields.length === 0 && values.irrigation) {
                errors.irrigation = t('validation.confirm-no-irrigation-error-message');
            }

            return errors;
        },
    });

    // Making the map selectable and disable selection once this component is unmount
    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }
        MapService.selectablePolygons({ selectMode: true });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
        };
    }, []);

    // init listener to update form fields with selectedPolygons from map
    useEffect(() => {
        const selected =
            fieldsList?.filter((field: any) =>
                thisMap.selectedPolygons?.some((polygon: any) => polygon.id === field.polygon.id),
            ) ?? [];
        formik.setValues((state) => ({
            irrigation: !(!state.irrigation && selected.length === 0),
            fields: [...selected],
        }));
    }, [thisMap.selectedPolygons]);

    // init map selected field
    useEffect(() => {
        const initFields = fieldsList?.filter((field: any) => field.is_irrigated);

        if (thisMap?.selectedPolygons?.length !== initFields?.length) {
            MapService.updateSelectedPolygons({
                selectedPolygons: initFields.map((field: any) => field.polygon),
            });
        }
    }, []);

    // delete all fields from selection
    const deleteAll = () => {
        MapService.updateSelectedPolygons({
            selectedPolygons: [],
        });
    };

    // Delete one fields from selection
    const selectedFieldDelete = (id: number | string) => {
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons.filter((polygon: any) => polygon.id !== id),
        });
    };

    // Check if the deleted field already has irrigation
    const onSelectedFieldDelete = (id: number | string) => {
        const deletedField = formik.values.fields.find((field) => field.id === id);

        if ((deletedField as any)?.irrigation) {
            modalDeleteField.openModal(() => {
                selectedFieldDelete(id);
            });
        } else {
            selectedFieldDelete(id);
        }
    };

    // Select all the fields
    const onSelectAll = () => {
        const mapFields = fieldsList?.filter(
            (field: any) =>
                !Boolean(field.field_crops?.find((field_crop: any) => field_crop.farm_season_crop.crop.is_permanent)),
        );
        MapService.updateSelectedPolygons({
            selectedPolygons: [...mapFields.map((field: any) => field.polygon)],
        });
    };

    return (
        <IrrigatedFieldsSelectView
            deleteAll={deleteAll}
            modalDeleteField={modalDeleteField}
            fieldLoading={fieldLoading}
            formik={formik}
            isSubmitted={isSubmitted}
            modalDeleteSelection={modalDeleteSelection}
            t={t}
            onSelectAll={onSelectAll}
            onSelectedFieldDelete={onSelectedFieldDelete}
            pushStep={pushStep}
            handleNavigation={handleNavigation}
        />
    );
};
export default IrrigatedFieldsSelect;
