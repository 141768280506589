import { AppStateT } from '@store/store';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

const useIntercomInitialization = () => {
    const user = useSelector((state: AppStateT) => state.auth.user);
    const { update: intercomUpdate } = useIntercom();
    useEffect(() => {
        if (user) {
            intercomUpdate({
                userId: user?.id.toString(),
                email: user?.email,
                name: `${user?.first_name} ${user?.last_name}`,
                languageOverride: i18next?.language,
                customAttributes: {
                    userId_Hubspot: user?.hubspot_id,
                },
            });
        }
    }, [user]);
};

export default useIntercomInitialization;
