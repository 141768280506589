import TableView from '@components/Table/TableView';
import { useTranslation } from 'react-i18next';
import CardBasic from '@components/CardBasic/CardBasic';
import { Grid } from '@mui/material';
import { FarmSeasonCropOperationT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

import { CardOperationColumnsT, CardOperationPropsT, CardOperationRowsT } from './CardOperation.types';
import useCardOperationLogic from './CardOperation.logic';
import useCardOperationStyle from './CardOperation.styles';

const CardOperationView = ({ pesticideList, machineryList, fieldCrop }: CardOperationPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useCardOperationStyle();

    const { operationCellRenderer, operationFertilising, operationSowing, operationSpraying, operationTillage } =
        useCardOperationLogic({
            pesticideList,
            machineryList,
            fieldCrop,
        });

    /* ------------------------------ Table Config ------------------------------ */
    const rows1: CardOperationRowsT = [
        { id: 'tillage', label: t('pages.farmer-data.operation.cell-tillage'), value: operationTillage },
        { id: 'spraying', label: t('pages.farmer-data.operation.cell-spraying'), value: operationSpraying },
    ];
    const rows2: CardOperationRowsT = [
        { id: 'sowing', label: t('pages.farmer-data.operation.cell-sowing'), value: operationSowing },
        { id: 'fertilising', label: t('pages.farmer-data.operation.cell-fertilising'), value: operationFertilising },
    ];
    const columns: CardOperationColumnsT = [
        { field: 'label' },
        {
            field: 'value',
            renderColCell: ({ value, row }) =>
                operationCellRenderer(value as FarmSeasonCropOperationT, row as CardOperationRowsT[0]),
            cellClassName: classes.cellOperation,
        },
    ];
    /* -------------------------------------------------------------------------- */

    return (
        <CardBasic title={t(fieldCrop.farm_season_crop.crop.translation_slug ?? '')}>
            <Grid spacing={4} container>
                <Grid xs={6} item>
                    <TableView columns={columns} rows={rows1} size="small" />
                </Grid>
                <Grid xs={6} item>
                    <TableView columns={columns} rows={rows2} size="small" />
                </Grid>
            </Grid>
        </CardBasic>
    );
};

export default CardOperationView;
