import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useCollapsableSectionStyle from './CollapsableSectionView.style';
import { CollapsableSectionViewPropsT } from './CollapsableSectionTypes';

const CollapsableSectionView = ({
    children,
    sectionName,
    helpName,
    defaultCollapseState = false,
    sectionRef,
}: CollapsableSectionViewPropsT): JSX.Element => {
    const { classes } = useCollapsableSectionStyle();
    const [expanded, setExpanded] = useState<boolean>(defaultCollapseState);

    return (
        <div ref={sectionRef} className={classes.collapseSection}>
            <div className={classes.nameSectionWrapper}>
                <div className={classes.nameHelpSectionWrapper}>
                    <Typography
                        className={classes.title}
                        component="h4"
                        variant="h4"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {sectionName}
                    </Typography>
                    {helpName && <HelpButtonView id={helpName} />}
                </div>
                <IconButton
                    aria-expanded={expanded}
                    aria-label="show more"
                    className={`${classes.btnIcon} ${expanded ? classes.opened : classes.closed}`}
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </div>
    );
};

export default CollapsableSectionView;
