import { makeStyles } from 'tss-react/mui';

const useRelationsDndModalViewStyle = makeStyles()((theme) => ({
    container: {
        borderRadius: '15px',
        width: '450px',
        backgroundColor: theme.palette.spanishWhite.light,
        overflow: 'hidden',
    },
    formContainer: {
        padding: '10px 20px 20px 20px',
    },
    closeContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        paddingTop: '8px',
        paddingRight: '12px',
    },
    closeIcon: {
        fontSize: '0.95rem',
        color: theme.palette.secondary.main,
        cursor: 'pointer',
    },
    menuBar: {
        marginBottom: '20px',
    },
}));

export default useRelationsDndModalViewStyle;
