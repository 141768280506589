import { useModal } from '@soil-capital/ui-kit';
import { ModalWarning } from '@soil-capital/ui-kit';
import useAppDispatch from '@hooks/useAppDispatch';
import { appInitModalWarningController } from '@store/actions/AppActions/AppActions';
import { AppStateT } from '@store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ModalWarningApp = () => {
    const dispatch = useAppDispatch();
    const controller = useModal();
    useEffect(() => {
        dispatch(appInitModalWarningController({ modalWarningController: controller }));

        return () => {
            dispatch(appInitModalWarningController({ modalWarningController: null }));
        };
    }, []);

    const modalWarningAppProps = useSelector((state: AppStateT) => state.app.modalWarningProps);

    return <ModalWarning controller={controller} {...modalWarningAppProps} />;
};

export default ModalWarningApp;
