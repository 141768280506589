import { makeStyles } from 'tss-react/mui';

const useTopBarSelectorStyle = makeStyles()((theme) => ({
    box: {
        display: 'inline-box',
        padding: '10px 15px',
        backgroundColor: theme.palette.linkWater.light,
        borderRadius: Number(theme.shape.borderRadius) / 2,
        color: theme.palette.primary.light,
    },
    boxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    leftIcon: {
        marginRight: '10px',
        fontSize: '18px',
    },
    selector: {
        fieldset: {
            border: 'none',
        },
        '.MuiSelect-select': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    textContainer: {
        marginRight: theme.spacing(0.5),
    },
}));

export default useTopBarSelectorStyle;
