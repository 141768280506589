import useAppDispatch from '@hooks/useAppDispatch';
import { SelectChangeEvent } from '@mui/material/Select';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import currentLanguageSelector from '@store/selectors/currentLanguageSelector';
import { useSelector } from 'react-redux';

import MapService from '@services/mapService/mapService';
import UserChangeLanguage from '@store/actions/AuthActions/UserChangeLanguage';
import { LanguageT } from '@store/reducers/StaticReducer/StaticReducerType';
import { AppStateT } from '@store/store';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelectorView from './LanguageSelectorView';

// FP-3653 - refresh map when languge is changed
const useChangeMapLanguage = () => {
    const map = useSelector((state: AppStateT) => state.map);
    const { t } = useTranslation();
    const fieldsList = useSelector((state: AppStateT) => state.farmSeasonField?.fieldsList);
    useEffect(() => {
        if (!fieldsList?.length) {
            return;
        }
        const currentPolygons = map.maps[0].polygonsLayer;

        let error = false;
        let atLeastOnePolygonHasCrops = false;
        const updatedPolygons = currentPolygons?.map((currentPolygon) => {
            const currentField = fieldsList.find((f) => f.id === currentPolygon.id);
            if (!currentField || !currentField.field_crops) {
                error = true;
                return currentPolygon;
            }
            if (currentPolygon.properties.crops) {
                atLeastOnePolygonHasCrops = true;
            }
            return {
                ...currentPolygon,
                properties: {
                    ...currentPolygon.properties,
                    crops: currentField?.field_crops
                        .map(
                            (field_crop) =>
                                `▪ ${t(field_crop.farm_season_crop.crop.translation_slug ?? '')}${
                                    field_crop.farm_season_crop.is_accompanied ? ' +' : ''
                                }`,
                        )
                        .join('\n'),
                },
            };
        });

        if (error || !updatedPolygons || !atLeastOnePolygonHasCrops) {
            return;
        }

        MapService.addPolygons({ polygons: updatedPolygons });
    }, [i18next.language]);
};

const LanguageSelector = (): JSX.Element => {
    useChangeMapLanguage();
    const languageList = useSelector((state: HomePageStateT) => state.static.languages);
    const currentLanguage = useSelector(currentLanguageSelector());
    const dispatch = useAppDispatch();
    const onChange = async (event: SelectChangeEvent<string>) => {
        const iso_code = event.target.value;
        const language = languageList.find((lang) => lang.iso_code === iso_code);
        await dispatch(UserChangeLanguage(language as LanguageT));
    };

    return <LanguageSelectorView currentLanguage={currentLanguage} languageList={languageList} onChange={onChange} />;
};

export default LanguageSelector;
