import { Dispatch } from '@reduxjs/toolkit';

export const GET_FARM_SEASON_LIVESTOCKS_LOADING = 'GET_FARM_SEASON_LIVESTOCKS_LOADING';
export const GET_FARM_SEASON_LIVESTOCKS_FAILED = 'GET_FARM_SEASON_LIVESTOCKS_FAILED';
export const GET_FARM_SEASON_LIVESTOCKS_SUCCESS = 'GET_FARM_SEASON_LIVESTOCKS_SUCCESS';

export type FarmSeasonLivestockT = {
    livestock_id: string;
    quantity: number | null;
};

export type GetFarmSeasonLivestocksResponseDataT = {
    livestocks: FarmSeasonLivestockT[];
};

export type FarmSeasonLivestocksGetCallBackT = (
    dispatch: Dispatch<GetFarmSeasonLivestocksDispatchTypesT>,
) => Promise<GetFarmSeasonLivestocksResponseErrorT>;

export type GetFarmSeasonLivestocksResponseErrorT = {
    message?: string;
    list?: string[];
};

export type GetFarmSeasonLivestocksLoadingT = {
    type: typeof GET_FARM_SEASON_LIVESTOCKS_LOADING;
};

export type GetFarmSeasonLivestocksFailedT = {
    type: typeof GET_FARM_SEASON_LIVESTOCKS_FAILED;
    error: GetFarmSeasonLivestocksResponseErrorT;
};

export type GetFarmSeasonLivestocksSuccessT = {
    type: typeof GET_FARM_SEASON_LIVESTOCKS_SUCCESS;
    payload: GetFarmSeasonLivestocksResponseDataT;
};

export type GetFarmSeasonLivestocksDispatchTypesT =
    | GetFarmSeasonLivestocksLoadingT
    | GetFarmSeasonLivestocksFailedT
    | GetFarmSeasonLivestocksSuccessT;
