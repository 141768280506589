import FertiliserApiService from '@services/fertiliserApiService/fertiliserApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FertilisersGetDispatchTypes,
    FertilisersGetFailedDataT,
    FertilisersGetSuccessDataT,
    FERTILISERS_GET_FAILED,
    FERTILISERS_GET_LOADING,
    FERTILISERS_GET_SUCCESS,
} from './FertilisersGetActionTypes';

const fertilisersGet =
    (countryId: number, farmId: number) =>
    async (dispatch: Dispatch<FertilisersGetDispatchTypes>): Promise<FertilisersGetFailedDataT> => {
        try {
            dispatch({ type: FERTILISERS_GET_LOADING });

            const res: AxiosResponse<FertilisersGetSuccessDataT> = await FertiliserApiService.getFertilisersList(
                countryId,
                farmId,
            );

            dispatch({
                type: FERTILISERS_GET_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: FERTILISERS_GET_FAILED,
                error: error as FertilisersGetFailedDataT,
            });

            return {};
        }
    };

export default fertilisersGet;
