import {
    PostFarmSeasonFieldsOrganicFertilizationHistoryDataT,
    PostFarmSeasonFieldsOrganicFertilizationHistoryResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicFertilizationHistoryTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsOrganicFertilizationHistoryApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsOrganicFertilizationHistoryDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsOrganicFertilizationHistoryResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/organic-fertilization-history`,
            method: 'POST',
            data: body,
        });
}

export default farmSeasonFieldsOrganicFertilizationHistoryApiService;
