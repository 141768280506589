import { makeStyles } from 'tss-react/mui';

const useModalCreateFertiliserStyle = makeStyles()((theme) => ({
    container: {
        padding: '30px',
        width: '450px',
        backgroundColor: theme.palette.spanishWhite.light,
    },
    title: {
        marginBottom: '20px',
        fontSize: '1.3em',
    },
    formContent: {
        '& > *': {
            marginBottom: '2px',
        },
    },
    npkInputs: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            margin: '10px',
        },
    },

    errorContainer: {
        minHeight: '20px',
        marginTop: '4px',
        color: theme.palette.error.main,
    },

    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },

    fertiliserSuggestion: {
        marginBottom: theme.spacing(4),
    },
}));

export default useModalCreateFertiliserStyle;
