import useTableHeaderCellStyle from './TableHeaderCell.style';
import { TableHeaderCellPropsT } from './TableHeaderCellTypes';
import { InfoTooltip } from '@ui-kit';
import { t } from 'i18next';

const TableHeaderCell = ({ value, tooltipSlug }: TableHeaderCellPropsT) => {
    const { classes } = useTableHeaderCellStyle();

    return (
        <div className={classes.TableHeaderCell}>
            {value}
            {tooltipSlug && (
                <InfoTooltip
                    iconSpanStyle={{ display: 'flex', alignItems: 'center' }}
                    tooltipScProps={{
                        translationSlug: t(tooltipSlug || ''),
                        placement: 'top',
                    }}
                />
            )}
        </div>
    );
};

export default TableHeaderCell;
