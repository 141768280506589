import { makeStyles } from 'tss-react/mui';

const useFieldAmountLabelStyle = makeStyles()(() => ({
    fieldAmountLabel: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useFieldAmountLabelStyle;
