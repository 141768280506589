import { useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { isNullOrUndefined } from '@utils/numberHelper';
import useAppNavigate from '@hooks/useAppNavigate';

import { SideBarNavViewPropsT } from './SideBarNavTypes';
import useSideBarNavStyle from './SideBarNav.style';
import { ROLE_ID } from '@constants/roleIds';

const SideBarNavView = ({ config, linkActive, onLinkHover }: SideBarNavViewPropsT): JSX.Element => {
    const { classes } = useSideBarNavStyle();
    const navigate = useAppNavigate();
    const userRoleId = useSelector((state: HomePageStateT) => state.auth.user?.role_id);
    const isFarmer = userRoleId === ROLE_ID.FARMER;

    const isBaseline = !useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.baseline_farm_season_id,
    );

    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const fieldsList = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);

    const hasInvalidPolygon = useMemo(() => !!fieldsList.find((f) => !f.is_geom_valid), [fieldsList]);

    const [hasMissingCharacteristics, setHasMissingCharacteristics] = useState(false);

    useEffect(() => {
        for (const field of fields) {
            if (
                (!isNullOrUndefined(field.opendata_soil_drainage_id) &&
                    isNullOrUndefined(field.cft_land_drainage_id) &&
                    isBaseline) ||
                (!isNullOrUndefined(field.opendata_soil_water_capacity_id) &&
                    isNullOrUndefined(field.cft_soil_humidity_id) &&
                    isBaseline) ||
                (!isNullOrUndefined(field.opendata_soil_texture_id) &&
                    isNullOrUndefined(field.cft_soil_type_id) &&
                    isBaseline) ||
                field.area === 0
            ) {
                setHasMissingCharacteristics(true);
                break;
            }
        }
    }, [fields]);

    return (
        <div>
            <List aria-label="main mailbox folders" className={classes.list} component="nav">
                {config.map((item) => (
                    <div key={item.link}>
                        {!item.hidden && (
                            <ListItem
                                className={`${classes.navLink} ${item === linkActive ? classes.navLinkActive : ''}`}
                                component="span"
                                disabled={item.disabled}
                                button
                                onClick={() => {
                                    item.isV3
                                        ? window.open(`${process.env.REACT_APP_V3_BASE_URL}${item.link}`, '_blank')
                                        : navigate(item.link);
                                }}
                                onMouseOver={() => onLinkHover?.('over', item)}
                                onMouseOut={() => onLinkHover?.('out', item)}
                            >
                                <ListItemIcon>
                                    <SvgIcon
                                        className={classes.listItemIcon}
                                        component={item.icon.icon}
                                        viewBox={item.icon.viewBox}
                                    />
                                </ListItemIcon>
                                <div className={classes.nameContainer}>
                                    <ListItemText
                                        primary={item.text}
                                        primaryTypographyProps={{ className: classes.listItemText }}
                                    />
                                    {item.link === '/fields' &&
                                    (hasMissingCharacteristics || hasInvalidPolygon) &&
                                    !isFarmer ? (
                                        <ErrorOutlineIcon className={classes.errorIcon} />
                                    ) : null}
                                </div>
                            </ListItem>
                        )}
                    </div>
                ))}
            </List>
        </div>
    );
};

export default SideBarNavView;
