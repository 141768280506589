import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SoilOrganicMatterTaskView from './SoilOrganicMatterTaskView';

const SoilOrganicMatterTask = (): JSX.Element => {
    const { t } = useTranslation();
    const [stepArray, setStepArray] = useState([1]);
    const [currentStep, setCurrentStep] = useState(stepArray[stepArray.length - 1]);

    useEffect(() => {
        setCurrentStep(stepArray[stepArray.length - 1]);
    }, [stepArray]);

    const pushStep = (step: number) => {
        setStepArray((state) => [...state, step]);
    };

    const popStep = () => {
        setStepArray((state) => [...state.splice(0, state.length - 1)]);
    };

    const handleBackButton = () => {
        if (currentStep > 1) {
            setStepArray((state) => {
                const newState = [...state];
                newState.pop();

                return newState;
            });
        }
    };

    return (
        <SoilOrganicMatterTaskView
            currentStep={currentStep}
            handleBackButton={handleBackButton}
            popStep={popStep}
            pushStep={pushStep}
            t={t}
        />
    );
};

export default SoilOrganicMatterTask;
