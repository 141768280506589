import { makeStyles } from 'tss-react/mui';

const useMapPopupContentLayoutStyle = makeStyles()((theme) => ({
    MapPopupContentLayout: {
        padding: '8px 4px',
        width: '200px',
        borderRadius: '8px !important',
        backgroundColor: 'white',
    },
    title: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        width: 'calc(100% - 20px)',
        padding: '0 4px',
    },
    closeIcon: {
        borderRadius: '4px',
        position: 'absolute',
        padding: '3px',
        right: '8px',
        top: '8px',
        color: theme.palette.secondary.main,
    },
}));

export default useMapPopupContentLayoutStyle;
