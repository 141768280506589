import useAppNavigate from '@hooks/useAppNavigate';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import MapService from '@services/mapService/mapService';
import { useModal } from '@soil-capital/ui-kit';
import addPolygonData from '@utils/addPolygonData';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldsContextStore } from '../FieldsContext/FieldsContext';
import { FieldsContextStoreT } from '../FieldsContext/FieldsContextTypes';

import FieldsListView from './FieldsListView';

const FieldsList = (): JSX.Element => {
    const { setSelectedField, fieldList, selectedField } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const controllerWarningOperationDone = useModal();

    const [cropColorPalette, setCropColorPalette] = useState<string[]>([]);
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const cropsList = useSelector((state: HomePageStateT) => state.farmSeasonCrop.cropsList);
    const navigate = useAppNavigate();

    useEffect(() => {
        if (cropsList?.length > 0) {
            setCropColorPalette(setupCropPaletteFromCropLegacy(cropsList));
        }
    }, [cropsList]);

    useEffect(() => {
        if (cropColorPalette) {
            MapService.addPolygons({
                polygons: fields?.map((field) => addPolygonData({ field, colorPalette: cropColorPalette })),
            });
        }
    }, [fields, cropColorPalette]);

    useEffect(() => {
        if (fields) MapService.focusPolygons({ polygons: _.map(fields, 'polygon') });
    }, [fields]);

    const editField = async (fieldId: number) => {
        const newSelectedField = fieldList.find((field) => field.id === fieldId) ?? null;
        // field is same -> close
        if (newSelectedField?.id === selectedField?.id) {
            const canNavigate = await navigate('/fields', { replace: true });
            if (canNavigate) {
                setSelectedField(null);
            }
            return;
            // field is different -> open / switch
        }

        // edit field is closed -> open
        if (selectedField === null) {
            setSelectedField(newSelectedField);
            navigate('/fields/edit', { replace: true });
            return;
        }

        // edit field is open -> switch
        const canNavigate = await navigate('/fields/edit', { replace: true });
        if (canNavigate) {
            setSelectedField(newSelectedField);
        }
    };

    return (
        <FieldsListView
            controllerWarningOperationDone={controllerWarningOperationDone}
            editField={editField}
            selectedFieldId={selectedField?.id}
        />
    );
};

export default FieldsList;
