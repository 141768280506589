import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type TagNameT = (typeof tagTypes)[number];

const tagTypes = ['Field', 'Crop', 'FieldCrop'] as const;

export const api2 = createApi({
    tagTypes,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL?.replace('/v1', '/v2'),
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json, */*');
            return headers;
        },
        credentials: 'include',
    }),
    refetchOnMountOrArgChange: true, // Temporary true while we handle 2 data state
    endpoints: () => ({}),
    reducerPath: 'apiV2',
});
