import FarmSeasonFieldsOrganicApiService from '@services/farmSeasonFieldsOrganicApiService/farmSeasonFieldsOrganicApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsOrganicPostCallBackT,
    PostFarmSeasonFieldsOrganicDataT,
    PostFarmSeasonFieldsOrganicDispatchTypesT,
    PostFarmSeasonFieldsOrganicResponseDataT,
    PostFarmSeasonFieldsOrganicResponseErrorT,
    POST_FARM_SEASON_FIELDS_ORGANIC_FAILED,
    POST_FARM_SEASON_FIELDS_ORGANIC_LOADING,
    POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS,
} from './PostFarmSeasonFieldsOrganicTypes';

export const postFarmSeasonFieldsOrganic =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsOrganicDataT,
    ): FarmSeasonFieldsOrganicPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsOrganicDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsOrganicResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_ORGANIC_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsOrganicResponseDataT> =
                await FarmSeasonFieldsOrganicApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_ORGANIC_FAILED,
                error: error as PostFarmSeasonFieldsOrganicResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
