import { MainRouterLocationStateT } from '@/routers/MainRouterTypes';
import { Routes, useLocation } from 'react-router-dom';

import { AppRoutesPropsT } from './AppRoutesTypes';

/* keep main location even with simone routes working on top. */
const AppRoutes: typeof Routes = ({ children }: AppRoutesPropsT) => {
    const location = useLocation();
    const mainLocationState = location.state as MainRouterLocationStateT;

    return <Routes location={{ ...(mainLocationState?.mainRouterLocation || location) }}>{children}</Routes>;
};

export default AppRoutes;
