import { makeStyles } from 'tss-react/mui';

const useContextSelectorStyle = makeStyles()(() => ({
    root: {},
    farmSelector: {
        marginRight: '10px',
    },
    selectorContainer: {
        display: 'flex',
    },
}));

export default useContextSelectorStyle;
