import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useInstallationStyle = makeStyles()((theme) => ({
    installationPageWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'center',
        padding: '0 30px 30px 30px',
        '& h6': {
            fontSize: '1rem',
            display: 'block',
            width: '100%',
            textAlign: 'center',
            color: theme.palette.driftwood.light,
        },
    },
    confirmed: {
        backgroundColor: 'rgba(0,0,0,.05)',
        paddingTop: '20px',
    },
    buttonHolder: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '15px',
    },
    hr: {
        width: '150px',
        borderWidth: '1px',
        margin: '10px auto 2px !important',
        backgroundColor: `${alpha(theme.palette.driftwood.light, 0.2)} !important`,
    },
    taskList: {},
    taskItem: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    taskButton: {},
    taskButtonCard: {
        textAlign: 'left',
        padding: '2px 10px',
        boxShadow: `
      0px 3px 5px -1px ${alpha(theme.palette.spanishWhiteShadow, 0.2)},
      0px 6px 10px 0px ${alpha(theme.palette.spanishWhiteShadow, 0.5)},
      0px 1px 18px 0px ${alpha(theme.palette.spanishWhiteShadow, 0.2)}
    `,
        '& Label': {
            color: theme.palette.spanishWhite.contrastText,
        },
    },
    button: {
        background: theme.palette.shamrock.main,
        '&:hover': {
            background: theme.palette.shamrock.light,
        },
    },
    taskFocusVisible: {},
}));

export default useInstallationStyle;
