import LoadingButton from '@components/LoadingButton/LoadingButton';
import { Typography } from '@mui/material';

import useEndWrapperStyle from './EndWrapper.style';
import { EndWrapperPropsT } from './EndWrapperTypes';

const EndWrapper = ({
    titleColor,
    justifyContent,
    title,
    handleMainButton,
    mainButtonText,
    mainButtonLoading,
    text,
    handleSecondaryButton,
    secondaryButtonText,
    children,
    className,
    mainButtonDisabled,
    hideMainButton,
}: EndWrapperPropsT): JSX.Element => {
    const { classes } = useEndWrapperStyle({ titleColor, justifyContent });

    return (
        <div className={`${classes.root} ${className}`}>
            <Typography className={classes.title} variant="h2">
                {title}
            </Typography>
            <Typography className={classes.body} color="primary" variant="body2">
                {text}
            </Typography>
            {children}
            {!hideMainButton && (
                <LoadingButton
                    className={classes.mainButton}
                    disabled={mainButtonDisabled}
                    loading={mainButtonLoading}
                    text={mainButtonText}
                    onClick={handleMainButton}
                />
            )}
            {secondaryButtonText && (
                <Typography
                    className={classes.secodaryButton}
                    color="primary"
                    variant="body2"
                    onClick={handleSecondaryButton}
                >
                    {secondaryButtonText}
                </Typography>
            )}
        </div>
    );
};

export default EndWrapper;
