import { List } from '@mui/material';
import useTaskListStyle from '@components/TaskList/TaskList.style';
import TaskItem from '@components/TaskItem/TaskItem';
import ProgressLinear from '@components/ProgressLinear/ProgressLinear';
import { useTranslation } from 'react-i18next';

import { TaskListViewPropsT, TaskItemT } from './TaskListTypes';

const TaskListView = ({ taskItems }: TaskListViewPropsT): JSX.Element => {
    const { classes } = useTaskListStyle();
    const { t } = useTranslation();

    const renderTaskItems = (tempTaskItems: TaskItemT[]): JSX.Element[] =>
        tempTaskItems.map((taskItem) => (
            <TaskItem
                key={taskItem.name}
                additionalContentJSX={
                    taskItem.subtaskItems && (
                        <ProgressLinear
                            label={`${t('pages.installationtasks.subtasks')} ${taskItem.subtaskItems.text}`}
                            percentage={taskItem.subtaskItems.percentage}
                        />
                    )
                }
                disabled={taskItem.disabled}
                label={taskItem.name}
                linkTo={taskItem.link.to}
                taskDone={taskItem.done}
            />
        ));

    return (
        <List aria-label="tasks" className={classes.taskList}>
            {renderTaskItems(taskItems)}
        </List>
    );
};

export default TaskListView;
