import { Button, Typography } from '@mui/material';
import LogoImg from '@components/LogoImg/LogoImg';
import LandscapeFieldsSrc from '@images/landscape-fields-medium.jpg';
import { useTranslation } from 'react-i18next';

import useMobileHardstopPageStyle from './MobileHardstopPage.style';

const MobileHardstopPage = (): JSX.Element => {
    const { classes } = useMobileHardstopPageStyle();
    const redirectUrl = 'https://soilcapital.com';
    const { t } = useTranslation();

    return (
        <div className={classes.screenContainer}>
            <div className={classes.textContainer}>
                <LogoImg />
                <Typography className={classes.title} color="primary" variant="h1">
                    {t('pages.mobile-hardstop.title')}
                </Typography>
                <Typography className={classes.subTitle} color="primary" variant="subtitle1">
                    {t('pages.mobile-hardstop.sub-title')}
                </Typography>
                <Typography className={classes.text} color="primary" variant="body1">
                    {t('pages.mobile-hardstop.text')}
                </Typography>
            </div>
            <div className={classes.footerContainer}>
                <img
                    alt="landscape with fields as far as the eye can see"
                    className={classes.footerImg}
                    src={LandscapeFieldsSrc}
                />
                <div className={classes.buttonRedirectContainer}>
                    <Button className={classes.buttonGreen} href={redirectUrl} fullWidth>
                        {t('pages.mobile-hardstop.button-redirect')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MobileHardstopPage;
