import { makeStyles } from 'tss-react/mui';

const useLabelTextFieldStyle = makeStyles()((theme) => ({
    label: {
        marginBottom: '10px',
        textAlign: 'left',
    },
    labelFullwidth: {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        wordWrap: 'break-word',
        marginBottom: '10px',
    },
    textField: {
        '& .MuiInputAdornment-root p': {
            color: theme.palette.driftwood.light,
            fontSize: '13px',
            fontWeight: theme.typography.fontWeightBold,
        },
    },
}));

export default useLabelTextFieldStyle;
