export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export type LogoutSuccessT = {
    type: typeof LOGOUT_SUCCESS;
};

export type LogoutDispatchTypesT = LogoutSuccessT;

export type LogoutActionPropsT = {
    /**
     * Only logout locally (session / storage). To be used if we are already unauthenticated from BE
     */
    onlyLocalLogout?: boolean;
};
