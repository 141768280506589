import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import NotificationsApiService from '@services/notificationService/notificationsApiService';
import { NotificationT } from '@reducers/NotificationReducer/NotificationReducerTypes';
import {
    NotificationApiFetchParamsT,
    NotificationApiFetchResponseT,
} from '@services/notificationService/notificationsApiServiceTypes';

import { NotificationActionTypes, NotificationActionsT } from './NotificationActionsTypes';

export const fetchSilentNotifications =
    (params: Omit<NotificationApiFetchParamsT, 'offset'>) =>
    async (dispatch: Dispatch<NotificationActionsT>): Promise<NotificationApiFetchResponseT | false> => {
        try {
            const res = await NotificationsApiService.fetch(params);
            dispatch({
                type: NotificationActionTypes.FETCH_SILENT_SUCCESS,
                payload: {
                    notifications: res.data.notifications,
                    notificationsCount: res.data.notifications_count,
                    unreadNotificationsCount: res.data.unread_count,
                },
            });

            return res.data;
        } catch (error) {
            return false;
        }
    };

export const fetchNotifications =
    (params: Omit<NotificationApiFetchParamsT, 'offset'>) =>
    async (dispatch: Dispatch<NotificationActionsT>): Promise<NotificationApiFetchResponseT | false> => {
        try {
            dispatch({ type: NotificationActionTypes.FETCH, payload: null });
            const res = await NotificationsApiService.fetch(params);
            dispatch({
                type: NotificationActionTypes.FETCH_SUCCESS,
                payload: {
                    notifications: res.data.notifications,
                    notificationsCount: res.data.notifications_count,
                    unreadNotificationsCount: res.data.unread_count,
                },
            });

            return res.data;
        } catch (error) {
            dispatch({
                type: NotificationActionTypes.FETCH_FAIL,
                payload: null,
            });

            return false;
        }
    };

export const fetchMoreNotifications =
    (params: NotificationApiFetchParamsT) =>
    async (dispatch: Dispatch<NotificationActionsT>): Promise<NotificationApiFetchResponseT | false> => {
        try {
            dispatch({ type: NotificationActionTypes.FETCH_MORE, payload: null });
            const res = await NotificationsApiService.fetch(params);
            dispatch({
                type: NotificationActionTypes.FETCH_MORE_SUCCESS,
                payload: {
                    notifications: res.data.notifications,
                    notificationsCount: res.data.notifications_count,
                    unreadNotificationsCount: res.data.unread_count,
                },
            });

            return res.data;
        } catch (error) {
            dispatch({
                type: NotificationActionTypes.FETCH_MORE_FAIL,
                payload: null,
            });

            return false;
        }
    };

export const markNotificationAsRead =
    (notification: NotificationT) =>
    async (dispatch: Dispatch<NotificationActionsT>): Promise<NotificationT | false> => {
        if (notification.read_at) {
            return notification;
        }

        try {
            dispatch({ type: NotificationActionTypes.MARK_AS_READ, payload: { id: notification.id } });
            const res: AxiosResponse<NotificationT> = await NotificationsApiService.markAsRead({
                notificationId: notification.id,
            });
            dispatch({
                type: NotificationActionTypes.MARK_AS_READ_SUCCESS,
                payload: { notification: res.data },
            });

            return res.data;
        } catch (error) {
            dispatch({
                type: NotificationActionTypes.MARK_AS_READ_FAIL,
                payload: error as { id: string },
            });

            return false;
        }
    };

export const markAllNotificationAsRead =
    () =>
    async (dispatch: Dispatch<NotificationActionsT>): Promise<boolean> => {
        try {
            dispatch({ type: NotificationActionTypes.MARK_ALL_AS_READ, payload: null });
            await NotificationsApiService.markAllAsRead();
            dispatch({
                type: NotificationActionTypes.MARK_ALL_AS_READ_SUCCESS,
                payload: null,
            });

            return true;
        } catch (error) {
            dispatch({
                type: NotificationActionTypes.MARK_ALL_AS_READ_FAIL,
                payload: null,
            });

            return false;
        }
    };
