import {
    ConfirmNotEligibleFarmSeasonLivestocksInstallationDataT,
    ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseDataT,
} from '@actions/FarmSeasonLivestockActions/ConfirmNotEligibleFarmSeasonLivestocksInstallationTypes';
import { DeleteFarmSeasonLivestocksResponseDataT } from '@actions/FarmSeasonLivestockActions/DeleteFarmSeasonLivestocksTypes';
import { GetFarmSeasonLivestocksResponseDataT } from '@actions/FarmSeasonLivestockActions/GetFarmSeasonLivestocksTypes';
import {
    PostFarmSeasonLivestocksInstallationDataT,
    PostFarmSeasonLivestocksInstallationResponseDataT,
} from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksInstallationTypes';
import {
    PostFarmSeasonLivestocksSubscriptionDataT,
    PostFarmSeasonLivestocksSubscriptionResponseDataT,
} from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksSubscriptionTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonLivestocksApiService {
    public static postSaveLivestocksSubscription = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonLivestocksSubscriptionDataT,
    ): Promise<AxiosResponse<PostFarmSeasonLivestocksSubscriptionResponseDataT>> =>
        request({
            url: `/subscription/farm/${farmId}/season/${farmSeasonId}/save-livestocks`,
            method: 'POST',
            data: body,
        });

    public static postSaveLivestockInstallation = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonLivestocksInstallationDataT,
    ): Promise<AxiosResponse<PostFarmSeasonLivestocksInstallationResponseDataT>> =>
        request({
            url: `/installation/farm/${farmId}/season/${farmSeasonId}/save-livestocks`,
            method: 'POST',
            data: body,
        });

    public static confirmLivestocksNotEligibleIntallation = async (
        farmId: number,
        farmSeasonId: number,
        body: ConfirmNotEligibleFarmSeasonLivestocksInstallationDataT,
    ): Promise<AxiosResponse<ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseDataT>> =>
        request({
            url: `/installation/farm/${farmId}/season/${farmSeasonId}/confirm-livestocks-not-eligible`,
            method: 'POST',
            data: body,
        });

    public static delete = async (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<DeleteFarmSeasonLivestocksResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/livestocks`,
            method: 'DELETE',
        });

    public static get = async (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<GetFarmSeasonLivestocksResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/livestocks`,
            method: 'GET',
        }).then((r) => {
            const response = r as AxiosResponse<GetFarmSeasonLivestocksResponseDataT>;
            // TODO update the type "FarmSeasonLivestockT" with property "number" and remove this hack
            // Hack used to match the bad type => changing type give typing errors in app + potentially live errors with controlled inputs
            response.data.livestocks = response.data.livestocks.map((livestock) => ({
                ...livestock,
                livestock_id: livestock.livestock_id.toString(),
            }));

            return response;
        });
}

export default FarmSeasonLivestocksApiService;
