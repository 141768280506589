import { makeStyles } from 'tss-react/mui';

const useWarningModalViewStyle = makeStyles()((theme) => ({
    container: {
        padding: '30px',
        backgroundColor: theme.palette.spanishWhite.light,
    },
    title: {
        marginBottom: '20px',
        fontSize: '1.3em',
    },
    messages: {
        marginBottom: '20px',
        whiteSpace: 'pre-line',
    },
    messagesContainer: {
        marginBottom: '20px',
    },
    confirmMessage: {
        fontSize: '1em',
    },
}));

export default useWarningModalViewStyle;
