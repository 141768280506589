import { makeStyles } from 'tss-react/mui';

const useSelectedFieldsContainerStyle = makeStyles()((theme) => ({
    icon: {
        fontSize: '20px',
        width: '20px',
        height: '20px',
        fill: theme.palette.primary.light,
        marginLeft: '8px',
    },
    name: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useSelectedFieldsContainerStyle;
