import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

const useModalFieldsSuggestion = () => {
    const modalFieldsSuggestion = useSelector((state: AppStateT) => state.onboarding.modalFieldsSuggestion.controller);

    return { modalFieldsSuggestion };
};

export default useModalFieldsSuggestion;
