import { postFarmSeasonFieldsTillage } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillage';
import useMapFieldReset from '@hooks/useMapFieldReset';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import addPolygonData from '@utils/addPolygonData';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import { PostFieldsTillageDataT, SelectStepPropsT, TillageDataT } from './SelectStepTypes';
import SelectStepView from './SelectStepView';
import useModalForgottenFields from '@components/ModalForgottenFields/useModalForgottenFields';

const SelectStep = ({ pushStep, popStep, currentStep }: SelectStepPropsT): JSX.Element => {
    const { t } = useTranslation();
    const [selectedTillage, setSelectedTillage] = useState<TillageDataT>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const fields = useSelector((state: HomePageStateT) => state.farmSeasonField?.fieldsList);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const fieldLoading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);

    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();

    const setCanRefresh = useMapFieldReset();
    const { ModalForgottenFields, modalForgottenFieldsController } = useModalForgottenFields({
        modalType: 'tillage-practices',
    });

    useEffect(() => {
        if (isSubmitted) setCanRefresh(!isSubmitted);
    }, [isSubmitted]);

    const formik = useFormik({
        initialValues: {
            fields:
                fields?.length > 0
                    ? fields.map((f) => ({
                          ...f,
                          tillage_id: f.tillage_id ?? null,
                          is_tillage_permanent: f.is_tillage_permanent ?? null,
                      }))
                    : [],
        },
        enableReinitialize: false,
        onSubmit: async (values: PostFieldsTillageDataT, { setSubmitting }) => {
            const save = async () => {
                await dispatch(postFarmSeasonFieldsTillage(farmId, farmSeasonId, values));
                navigate('..');
            };
            setIsSubmitted(true);
            const forgottenFields = values.fields
                .filter(
                    (f) =>
                        !f.tillage_id &&
                        !f.field_crops?.find((field_crop) => field_crop.farm_season_crop.crop.is_permanent),
                )
                .map((f) => f.name);
            if (forgottenFields.length > 0) {
                await modalForgottenFieldsController.openModal({
                    forgottenFieldNames: forgottenFields,
                    fieldsCount: values.fields.length,
                    saveCallback: save,
                });
            } else {
                await save();
            }
            setSubmitting(false);
        },
    });

    const onTillageClick = (tillage: TillageDataT) => {
        setSelectedTillage(tillage);
        pushStep(1.1);
    };

    useEffect(() => {
        MapService.addPolygons({
            polygons: formik?.values?.fields?.map((field) => addPolygonData({ field, withTillage: true })),
        });
    }, [formik?.values?.fields]);

    useEffect(() => {
        if (formik.dirty) {
            window.onbeforeunload = () => t('pages.installationtasks.add-field-task.before-leave');
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [formik.dirty]);

    return (
        <SelectStepView
            currentStep={currentStep}
            fieldLoading={fieldLoading}
            formFields={formik?.values?.fields}
            isDirty={formik.dirty && !isSubmitted}
            popStep={popStep}
            selectedTillage={selectedTillage}
            setFormFields={formik?.setValues}
            setSelectedTillage={setSelectedTillage}
            submit={formik.submitForm}
            t={t}
            onTillageClick={onTillageClick}
            ModalForgottenFields={ModalForgottenFields}
        />
    );
};

export default SelectStep;
