import CloseIcon from '@mui/icons-material/Close';
import { CardFormLayoutPropsT } from './CardFormLayoutTypes';
import useCardFormLayoutStyle from './CardFormLayout.style';
import { Typography } from '@mui/material';
import TooltipSc from '@components/TooltipSc/TooltipSc';

/** card used to display sub forms (crops, agroforestry, field yield + residue,...) */
const CardFormLayout = ({
    children,
    title,
    onClose,
    className,
    marginBottom = '',
    closeTooltipSlug,
    disableClose = false,
}: CardFormLayoutPropsT) => {
    const { classes } = useCardFormLayoutStyle({ marginBottom, hasHeaderHeight: !!title || !!onClose, disableClose });

    return (
        <div className={`${classes.CardFormLayout} ${className}`}>
            <div className={classes.header}>
                {typeof title === 'string' ? <Typography variant="h5">{title}</Typography> : title}
                {onClose && (
                    <TooltipSc className={classes.tooltip} translationSlug={closeTooltipSlug}>
                        <CloseIcon className={classes.closeIcon} onClick={onClose} />
                    </TooltipSc>
                )}
            </div>
            {children}
        </div>
    );
};

export default CardFormLayout;
