import { useContext } from 'react';
import { Prompt } from '@components/Prompt/Prompt';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import MenuBar from '@components/MenuBar/MenuBar';

import IrrigatedFieldsSelect from './IrrigatedFieldsSelect/IrrigatedFieldsSelect';
import IrrigationValuesForm from './IrrigationValuesForm/IrrigationValuesForm';
import useIrrigationTaskStyle from './IrrigationTaskView.style';
import { IrrigationContext } from './IrrigationTaskContext/IrrigationTaskContext';
import IrrigatedFieldsList from './IrrigatedFieldsList/IrrigatedFieldsList';

const IrrigationTaskView = (): JSX.Element => {
    const { classes } = useIrrigationTaskStyle();
    const { currentStep, t, handleBackButton } = useContext(IrrigationContext);

    let StepComponent: JSX.Element;

    switch (currentStep) {
        case 2:
            StepComponent = <IrrigatedFieldsList />;
            break;
        case 3:
            StepComponent = <IrrigationValuesForm />;
            break;
        case 1:
        default:
            StepComponent = <IrrigatedFieldsSelect />;
            break;
    }

    return (
        <div>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={false}
            />
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={currentStep} totalSteps={3} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.fields-subtasks.irrigation-task.menu-title')}
                    onArrowBack={currentStep > 1 ? () => handleBackButton() : '..'}
                    onClose=".."
                />
                {StepComponent}
            </div>
        </div>
    );
};

export default IrrigationTaskView;
