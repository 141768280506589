import {
    GET_FARM_SEASON_FIELDS_FAILED,
    GET_FARM_SEASON_FIELDS_LOADING,
    GET_FARM_SEASON_FIELDS_SUCCESS,
} from '@actions/FarmSeasonFieldActions/GetFarmSeasonFieldsTypes';
import {
    POST_FARM_SEASON_FIELDS_FAILED,
    POST_FARM_SEASON_FIELDS_LOADING,
    POST_FARM_SEASON_FIELDS_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTypes';
import {
    POST_FARM_SEASON_FIELDS_TILLAGE_FAILED,
    POST_FARM_SEASON_FIELDS_TILLAGE_LOADING,
    POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillageTypes';
import {
    POST_FARM_SEASON_FIELDS_DRAINAGE_FAILED,
    POST_FARM_SEASON_FIELDS_DRAINAGE_LOADING,
    POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsDrainageTypes';
import {
    POST_FARM_SEASON_FIELDS_COVER_CROP_FAILED,
    POST_FARM_SEASON_FIELDS_COVER_CROP_LOADING,
    POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsCoverCropTypes';
import {
    POST_FARM_SEASON_FIELDS_CROPS_FAILED,
    POST_FARM_SEASON_FIELDS_CROPS_LOADING,
    POST_FARM_SEASON_FIELDS_CROPS_SUCCESS,
} from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsTypes';
import { RESET } from '@reducers/RootTypes';
import { POST_FARM_SEASON_CROPS_SUCCESS } from '@actions/FarmSeasonCropActions/PostFarmSeasonCropsTypes';
import {
    POST_FARM_SEASON_FIELDS_ORGANIC_FAILED,
    POST_FARM_SEASON_FIELDS_ORGANIC_LOADING,
    POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicTypes';
import {
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_FAILED,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_LOADING,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilOrganicMatterTypes';
import {
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_FAILED,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_LOADING,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilOrganicCarbonTypes';
import {
    POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_FAILED,
    POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_LOADING,
    POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilAcidityTypes';
import {
    POST_FARM_SEASON_FIELDS_IRRIGATION_FAILED,
    POST_FARM_SEASON_FIELDS_IRRIGATION_LOADING,
    POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsIrrigationTypes';
import {
    POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_FAILED,
    POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_LOADING,
    POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldIrrigationValuesTypes';
import {
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_FAILED,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_LOADING,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsAgroforestryTypes';
import {
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_FAILED,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_LOADING,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldAgroforestryValuesTypes';
import {
    POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_FAILED,
    POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_LOADING,
    POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsCoverCropHistoryTypes';
import {
    POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_FAILED,
    POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_LOADING,
    POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicFertilizationHistoryTypes';
import {
    POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_FAILED,
    POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_LOADING,
    POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsPastureHistoryTypes';
import {
    POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_FAILED,
    POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_LOADING,
    POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillagePracticeHistoryTypes';
import {
    POST_FARM_SEASON_FIELDS_NAME_FAILED,
    POST_FARM_SEASON_FIELDS_NAME_LOADING,
    POST_FARM_SEASON_FIELDS_NAME_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldNameTypes';
import {
    POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_FAILED,
    POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_LOADING,
    POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS,
} from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsResidueTypes';
import { DELETE_FARM_SEASON_CROP_SUCCESS } from '@actions/FarmSeasonCropActions/DeleteFarmSeasonCropTypes';
import {
    POST_FARM_SEASON_FIELD_FAILED,
    POST_FARM_SEASON_FIELD_LOADING,
    POST_FARM_SEASON_FIELD_SUCCESS,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldTypes';
import {
    DELETE_FARM_SEASON_FIELD_FAILED,
    DELETE_FARM_SEASON_FIELD_LOADING,
    DELETE_FARM_SEASON_FIELD_SUCCESS,
} from '@actions/FarmSeasonFieldActions/DeleteFarmSeasonFieldTypes';
import {
    POST_FARM_SEASON_OPERATION_FERTILISING_FAILED,
    POST_FARM_SEASON_OPERATION_FERTILISING_LOADING,
    POST_FARM_SEASON_OPERATION_FERTILISING_SUCCESS,
} from '@actions/FarmSeasonOperation/PostFarmSeasonOperationFertilisingTypes';
import {
    POST_FARM_SEASON_OPERATION_SPRAYING_FAILED,
    POST_FARM_SEASON_OPERATION_SPRAYING_LOADING,
    POST_FARM_SEASON_OPERATION_SPRAYING_SUCCESS,
} from '@actions/FarmSeasonOperation/PostFarmSeasonOperationSprayingTypes';
import {
    POST_FARM_SEASON_OPERATION_TILLAGE_FAILED,
    POST_FARM_SEASON_OPERATION_TILLAGE_LOADING,
    POST_FARM_SEASON_OPERATION_TILLAGE_SUCCESS,
} from '@actions/FarmSeasonOperation/PostFarmSeasonOperationTillageTypes';
import {
    POST_FARM_SEASON_OPERATION_SOWING_FAILED,
    POST_FARM_SEASON_OPERATION_SOWING_LOADING,
    POST_FARM_SEASON_OPERATION_SOWING_SUCCESS,
} from '@actions/FarmSeasonOperation/PostFarmSeasonOperationSowingTypes';
import {
    FARM_SEASON_OPERATION_DELETE_FAILED,
    FARM_SEASON_OPERATION_DELETE_LOADING,
    FARM_SEASON_OPERATION_DELETE_SUCCESS,
} from '@actions/FarmSeasonOperation/DeleteFarmSeasonOperationTypes';
import { PUT_FARM_SEASON_CROP_SUCCESS } from '@actions/FarmSeasonCropActions/PutFarmSeasonCropTypes';

import { FarmSeasonFieldReducerStateT, FarmSeasonFieldT } from './FarmSeasonFieldReducerTypes';
import { Reducer } from '@reduxjs/toolkit';
import { AppActionsT } from '@store/actions/AppActions/AppActionsTypes';
import { POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS } from '@store/actions/FarmSeasonActions/FarmSeasonPostInstallationConfirmTypes';

export const defaultFarmSeasonFieldState: FarmSeasonFieldReducerStateT = {
    loaded: false,
    loading: false,
    error: undefined,
    fieldsList: [],
};

const FarmSeasonFieldReducer: Reducer<FarmSeasonFieldReducerStateT, AppActionsT> = (
    state,
    action,
): FarmSeasonFieldReducerStateT => {
    if (!state) {
        return defaultFarmSeasonFieldState;
    }

    switch (action.type) {
        case POST_FARM_SEASON_FIELDS_LOADING:
        case POST_FARM_SEASON_FIELD_LOADING:
        case GET_FARM_SEASON_FIELDS_LOADING:
        case POST_FARM_SEASON_FIELDS_CROPS_LOADING:
        case POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_LOADING:
        case POST_FARM_SEASON_FIELDS_TILLAGE_LOADING:
        case POST_FARM_SEASON_FIELDS_DRAINAGE_LOADING:
        case POST_FARM_SEASON_FIELDS_ORGANIC_LOADING:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_LOADING:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_LOADING:
        case POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_LOADING:
        case POST_FARM_SEASON_FIELDS_IRRIGATION_LOADING:
        case POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_LOADING:
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_LOADING:
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_LOADING:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_LOADING:
        case POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_LOADING:
        case POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_LOADING:
        case POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_LOADING:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_LOADING:
        case POST_FARM_SEASON_FIELDS_NAME_LOADING:
        case DELETE_FARM_SEASON_FIELD_LOADING:
        case POST_FARM_SEASON_OPERATION_FERTILISING_LOADING:
        case POST_FARM_SEASON_OPERATION_SPRAYING_LOADING:
        case POST_FARM_SEASON_OPERATION_TILLAGE_LOADING:
        case POST_FARM_SEASON_OPERATION_SOWING_LOADING:
        case FARM_SEASON_OPERATION_DELETE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_FAILED:
            return {
                ...state,
                loading: false,
            };

        case POST_FARM_SEASON_FIELDS_SUCCESS:
        case GET_FARM_SEASON_FIELDS_SUCCESS:
        case POST_FARM_SEASON_FIELDS_CROPS_SUCCESS:
        case POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS:
        case POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS:
        case POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS:
        case POST_FARM_SEASON_CROPS_SUCCESS:
        case POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS:
        case POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS:
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS:
        case DELETE_FARM_SEASON_CROP_SUCCESS:
        case POST_FARM_SEASON_FIELDS_NAME_SUCCESS:
        case POST_FARM_SEASON_OPERATION_FERTILISING_SUCCESS:
        case POST_FARM_SEASON_OPERATION_SPRAYING_SUCCESS:
        case POST_FARM_SEASON_OPERATION_TILLAGE_SUCCESS:
        case POST_FARM_SEASON_OPERATION_SOWING_SUCCESS:
        case FARM_SEASON_OPERATION_DELETE_SUCCESS:
        case PUT_FARM_SEASON_CROP_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                fieldsList: (action as any).payload?.fields ? [...(action as any).payload?.fields] : state.fieldsList,
            };
        case POST_FARM_SEASON_FIELD_SUCCESS: {
            let newFields: FarmSeasonFieldT[];

            if (state.fieldsList.some((field) => field.id === action.payload.field.id)) {
                newFields = state.fieldsList.map((field) =>
                    field.id === action.payload.field.id ? action.payload.field : field,
                );
            } else {
                newFields = [...state.fieldsList, action.payload.field];
            }

            return {
                ...state,
                loading: false,
                fieldsList: newFields,
            };
        }
        case DELETE_FARM_SEASON_FIELD_SUCCESS:
            return {
                ...state,
                loading: false,
                fieldsList: [...state.fieldsList.filter((field) => field.id !== action.payload.farmSeasonFieldId)],
            };
        case POST_FARM_SEASON_FIELDS_FAILED:
        case POST_FARM_SEASON_FIELD_FAILED:
        case GET_FARM_SEASON_FIELDS_FAILED:
        case POST_FARM_SEASON_FIELDS_CROPS_FAILED:
        case POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_FAILED:
        case POST_FARM_SEASON_FIELDS_DRAINAGE_FAILED:
        case POST_FARM_SEASON_FIELDS_TILLAGE_FAILED:
        case POST_FARM_SEASON_FIELDS_ORGANIC_FAILED:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_FAILED:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_FAILED:
        case POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_FAILED:
        case POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_FAILED:
        case POST_FARM_SEASON_FIELDS_IRRIGATION_FAILED:
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_FAILED:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_FAILED:
        case POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_FAILED:
        case POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_FAILED:
        case POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_FAILED:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_FAILED:
        case POST_FARM_SEASON_FIELDS_NAME_FAILED:
        case DELETE_FARM_SEASON_FIELD_FAILED:
        case POST_FARM_SEASON_OPERATION_FERTILISING_FAILED:
        case POST_FARM_SEASON_OPERATION_SPRAYING_FAILED:
        case POST_FARM_SEASON_OPERATION_TILLAGE_FAILED:
        case POST_FARM_SEASON_OPERATION_SOWING_FAILED:
        case FARM_SEASON_OPERATION_DELETE_FAILED:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error,
            };
        case POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS:
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS: // TODO: Refactor this?!?!!?!?
            return {
                ...state,
                loading: false,
                fieldsList: action.payload.fieldsList,
            };
        case POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS:
            return {
                ...state,
                fieldsList: action.payload.farm_season_fields,
            };
        case RESET:
        // TODO add reset field state when we leave homePage
        case 'AUTH_SET_SELECTED_USER':
            return {
                ...defaultFarmSeasonFieldState,
            };

        default:
            return state;
    }
};

export default FarmSeasonFieldReducer;
