import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import DndListItem from '@components/RelationsDndModal/RelationsStep/DndListItem/DndListItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useRelationsStepStyle from './RelationsStepView.style';
import { RelationsStepViewPropsT } from './RelationsStepTypes';

const RelationsStepView = ({
    fieldCrops,
    defineMoveState,
    moveCrop,
    moveState,
    defineCouple,
    onContinue,
    fieldName,
}: RelationsStepViewPropsT): JSX.Element => {
    const { classes } = useRelationsStepStyle();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Typography color="secondary" variant="h4">
                <span className={classes.dark}>{t('constants.field-prefix')}</span> {fieldName}
            </Typography>
            <Typography className={classes.subtitle} color="secondary" variant="h4">
                <Trans i18nKey="component.relationDndModalView.relationsStep.title">
                    Ce champ contient plusieures cultures. Veuillez definir les cultures associees et les cultures
                    relais
                </Trans>
                <HelpButtonView id="component.relationDndModalView.relationsStep.title" />
            </Typography>
            <Typography className={classes.listTitle} color="secondary" variant="h6">
                {t('component.relationDndModalView.relationsStep.crop-title')}
            </Typography>
            <DndProvider backend={HTML5Backend}>
                <div className={classes.listContainer}>
                    {fieldCrops?.map((fieldCrop, index) => (
                        <DndListItem
                            key={`${
                                fieldCrop.farm_season_crop.crop.translation_slug
                            }-${fieldCrop.farm_season_crop.is_accompanied?.toString()}`}
                            canAssociat={index !== fieldCrops.length - 1}
                            canDrag={fieldCrop.coupled_id === null}
                            defineMoveState={defineMoveState}
                            index={index}
                            isAssociated={
                                fieldCrop.coupled_id !== null &&
                                fieldCrop.coupled_id === fieldCrops[index + 1]?.coupled_id
                            }
                            moveCrop={moveCrop}
                            moveState={moveState}
                            text={`${t(fieldCrop.farm_season_crop.crop.translation_slug || '')}${
                                fieldCrop.farm_season_crop.is_accompanied ? t('constants.select-companion') : ''
                            }`}
                            onCoupleClick={() => defineCouple({ index })}
                        />
                    ))}
                </div>
            </DndProvider>
            <TwoButtonFooterView rightOnCLick={onContinue} rightText={t('constants.confirm')} />
        </div>
    );
};

export default RelationsStepView;
