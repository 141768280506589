import useContextSelectorStyle from './ContextSelector.style';
import FarmSelector from './FarmSelector/FarmSelector';
import SeasonSelector from './SeasonSelector/SeasonSelector';

const ContextSelectorView = (): JSX.Element => {
    const { classes } = useContextSelectorStyle();

    return (
        <div className={classes.selectorContainer}>
            <FarmSelector className={classes.farmSelector} />
            <SeasonSelector />
        </div>
    );
};

export default ContextSelectorView;
