import { LOGIN_SUCCESS } from '@actions/AuthActions/LoginActionsTypes';
import { LOGOUT_SUCCESS } from '@actions/AuthActions/LogoutActionsTypes';
import { AppActionsT } from '@actions/AppActions/AppActionsTypes';
import { Reducer } from '@reduxjs/toolkit';

import { RoutingReducerStateT } from './RoutingReducerTypes';

export const defaultRoutingState: RoutingReducerStateT = {};

const routingReducer: Reducer<RoutingReducerStateT, AppActionsT> = (state, action): RoutingReducerStateT => {
    if (!state) {
        return defaultRoutingState;
    }

    switch (action.type) {
        case 'ROUTING_REDIRECT':
            return {
                redirectTo: action.payload.route,
            };
        case LOGIN_SUCCESS:
            if (action.payload.farmer_infos?.model_type === 'dndc') {
                return {
                    redirectTo: `${process.env.REACT_APP_V3_BASE_URL}/${action.payload.farmer_infos?.latest_active_season}/farm-profile`,
                };
            } else {
                return { redirectTo: '/' };
            }
        case LOGOUT_SUCCESS:
            return {
                redirectTo: '/login',
            };
        default:
            return state;
    }
};

export default routingReducer;
