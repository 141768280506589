import palette from '@constants/palette/crop.palette';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

import getUniqueCrops from './getUniqueCrops';
import { FarmSeasonCropT } from '@store/reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';
import { CropT } from '@entities/cropEntity/cropEntity.types';

interface CropColorsT {
    [key: number]: string;
}

const setupCropPalette = (fieldsArray: FarmSeasonFieldT[]): CropColorsT => {
    const crops = getUniqueCrops(fieldsArray);

    const cropColors: CropColorsT = [];

    crops.sort((a, b) => {
        const nameA = a.id;
        const nameB = b.id;

        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    crops.forEach((crop, index) => {
        cropColors[crop.id] = palette[index];
    });

    return cropColors;
};

export const setupCropPaletteFromCrop = (crops: CropT[]): string[] => {
    const cropColors: string[] = [];

    crops?.sort((a, b) => {
        const nameA = a.crop_id;
        const nameB = b.crop_id;

        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    crops.forEach((crop, index) => {
        cropColors[crop.crop_id] = palette[index];
    });

    return cropColors;
};

/**
 * @deprecated this use the old farmSeaonCrop with nested properties, you should use 'setupCropPaletteFromCropLegacy'
 */
const setupCropPaletteFromCropLegacy = (crops: FarmSeasonCropT[]): string[] => {
    const cropColors: string[] = [];

    crops?.sort((a, b) => {
        const nameA = a.crop.id;
        const nameB = b.crop.id;

        if (nameA < nameB) {
            return -1;
        }

        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    crops.forEach((crop, index) => {
        cropColors[crop.crop.id] = palette[index];
    });

    return cropColors;
};

export default setupCropPalette;
export { setupCropPaletteFromCropLegacy };
