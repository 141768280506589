import MenuBar from '@components/MenuBar/MenuBar';
import { Divider, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import TaskItem from '@components/TaskItem/TaskItem';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import useAppDispatch from '@hooks/useAppDispatch';
import { useModal } from '@soil-capital/ui-kit';

import { DeleteFarmSeasonOperations } from '@actions/FarmSeasonOperation/DeleteFarmSeasonOperation';

import { TaskStepRouteParamsT, TaskStepViewPropsT } from './TaskStepTypes';
import useTaskStepStyle from './TaskStepView.style';
import { ModalWarning } from '@soil-capital/ui-kit';

const TaskStepView = ({
    operationTasks,
    menuTitle,
    done,
    farmId,
    farmSeasonId,
    canEdit = false,
}: TaskStepViewPropsT): JSX.Element => {
    const { classes } = useTaskStepStyle();
    const { cropId, tillageId } = useParams<TaskStepRouteParamsT>();
    const { t } = useTranslation();
    const theme = useTheme();

    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const modalDeleteOperation = useModal();

    return (
        <>
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={menuTitle}
                    onArrowBack={`/operations/${cropId}`}
                    onClose={`/operations/${cropId}`}
                />
                <Typography className={classes.title} color="secondary" variant="h4">
                    <Trans i18nKey="pages.operations.task-step.title">
                        Sélectionnez un type d’<span style={{ color: theme.palette.secondary.dark }}>opération</span>
                    </Trans>
                </Typography>
                <Typography className={classes.description} color="secondary" component="p" variant="body2">
                    <Trans i18nKey="pages.operations.task-step.description" />
                </Typography>
                <div className={classes.listContainer}>
                    {operationTasks
                        .filter((operationTask) => !operationTask.done)
                        ?.map((operationTask) => (
                            <TaskItem
                                key={operationTask.route}
                                label={t(operationTask.slug)}
                                linkTo={`/operations/${cropId}/${tillageId}/${operationTask.route}`}
                                taskDone={operationTask.done}
                            />
                        ))}
                    {!!operationTasks.filter((operationTask) => !operationTask.done).length && (
                        <Divider className={classes.divider} />
                    )}
                    {operationTasks
                        .filter((operationTask) => operationTask.done)
                        ?.map((operationTask) => (
                            <TaskItem
                                key={operationTask.route}
                                label={t(operationTask.slug)}
                                linkTo={`/operations/${cropId}/${tillageId}/${operationTask.route}`}
                                taskDone={operationTask.done}
                            />
                        ))}
                </div>
                <TwoButtonFooterView
                    leftOnClick={() =>
                        tillageId &&
                        cropId &&
                        modalDeleteOperation.openModal(async () => {
                            await dispatch(DeleteFarmSeasonOperations(farmId, farmSeasonId, tillageId, cropId));
                        })
                    }
                    leftText={canEdit ? t('constants.delete-operations') : undefined}
                    rightDisabled={!done}
                    rightOnCLick={() => navigate(`/operations/${cropId}`)}
                    rightText={canEdit ? t('constants.confirm') : t('constants.back')}
                />
            </div>
            <ModalWarning
                controller={modalDeleteOperation}
                description={t('modal.delete-operation.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('modal.warning.title')}
            />
        </>
    );
};

export default TaskStepView;
