import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_LOADING = 'POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_LOADING';
export const POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_FAILED = 'POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_FAILED';
export const POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_SUCCESS = 'POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_SUCCESS';

export type PastureHistoryDataT = {
    id?: number;
    farm_season_field_id: number;
    year_of_change: string;
};

export type PostFarmSeasonFieldsPastureHistoryDataT = {
    pastureHistories: PastureHistoryDataT[];
    dontHaveAny: boolean;
};

export type PostFarmSeasonFieldsPastureHistoryResponseDataT = {
    fields: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            irrigation: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsPastureHistoryPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsPastureHistoryDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsPastureHistoryResponseErrorT>;

export type PostFarmSeasonFieldsPastureHistoryResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsPastureHistoryLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_LOADING;
};

export type PostFarmSeasonFieldsPastureHistoryFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_FAILED;
    error: PostFarmSeasonFieldsPastureHistoryResponseErrorT;
};

export type PostFarmSeasonFieldsPastureHistorySuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_SUCCESS;
    payload: PostFarmSeasonFieldsPastureHistoryResponseDataT;
};

export type PostFarmSeasonFieldsPastureHistoryDispatchTypesT =
    | PostFarmSeasonFieldsPastureHistoryLoadingT
    | PostFarmSeasonFieldsPastureHistoryFailedT
    | PostFarmSeasonFieldsPastureHistorySuccessT;
