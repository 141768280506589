import { useCanUpdateSelfUpsell, useCanUpdateUpsellForFarmer } from '@/hooks/usePermissions';
import { farmSeasonSetLatestAsCurrent } from '@actions/FarmSeasonActions/FarmSeasonSetCurrent';
import { MODAL_SLUG } from '@constants/modalSlugs';
import { ROUTE } from '@constants/routes';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import useOpenNewSeasonCheck from '@hooks/useOpenNewSeasonCheck';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import SegmentService from '@services/segmentService/segmentService';
import { FarmSeasonT } from '@store/reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import selectIsProductPlanName from '@store/selectors/selectIsProductPlanName';
import selectLatestFarmSeason from '@store/selectors/selectLatestFarmSeason';
import getSearchModalSlug from '@utils/getSearchModalSlug';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionNamesT } from './CardFlowNavigationContainer.types';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

const useCardFlowNavigationContainerLogic = () => {
    const [currentState, setCurrentState] = useState<ActionNamesT | false | null>(null);
    const { handleNavigateToAction } = _useHandleNavigateToAction();

    const [nextFarmSeason, setNextFarmSeason] = useState<FarmSeasonT | null>();
    const [nextFarmSeasonIsLoading, setNextFarmSeasonIsLoading] = useState(false);
    const latestFarmSeason = useSelector(selectLatestFarmSeason());
    const canOpenNewSeason: boolean = useOpenNewSeasonCheck();
    const canUpdateUpsell = useCanUpdateSelfUpsell() || useCanUpdateUpsellForFarmer();

    const isStandardPlan = useSelector(selectIsProductPlanName({ name: 'STANDARD' }));
    const isPrepaidPlan = useSelector(
        (state: HomePageStateT) => !!state.farm.currentFarm.partner?.default_prepaid_product_plan_id,
    );

    useEffect(() => {
        const loadNextFarmSeason = async () => {
            if (latestFarmSeason && canOpenNewSeason && !nextFarmSeason) {
                setNextFarmSeasonIsLoading(true);
                const res = await FarmSeasonApiService.getNext(latestFarmSeason.farm_id, latestFarmSeason.id);
                setNextFarmSeason(res.data);
            } else {
                setNextFarmSeason(null);
            }
            setNextFarmSeasonIsLoading(false);
        };
        if (!nextFarmSeason) {
            loadNextFarmSeason();
        }
    }, [latestFarmSeason, canOpenNewSeason]);

    useEffect(() => {
        if (nextFarmSeason !== undefined && latestFarmSeason !== undefined) {
            const { currentState } = _useCurrentState(
                nextFarmSeason,
                latestFarmSeason,
                canOpenNewSeason,
                isStandardPlan,
                isPrepaidPlan,
                canUpdateUpsell,
            );
            setCurrentState(currentState);
        }
    }, [nextFarmSeason, latestFarmSeason]);

    const handleActionClick = (actionName: ActionNamesT) => {
        handleNavigateToAction(actionName);
        SegmentService.farmSummaryNavigationClickedTrack({ context: actionName });
    };

    useEffect(() => {
        SegmentService.farmPage({ context: currentState === false ? null : currentState });
    }, []);

    return { handleActionClick, currentState: currentState, nextFarmSeasonIsLoading: nextFarmSeasonIsLoading };
};

const _useHandleNavigateToAction = () => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const isCFT = useIsCFTCarbonModel();
    const currentFarmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason.id);
    // switch to the latest season before redirect to the related page.
    const handleNavigateToAction = (actionName: ActionNamesT) => {
        dispatch(farmSeasonSetLatestAsCurrent());
        switch (actionName) {
            case 'baseline-self-encoding-to-start':
            case 'renewal-self-encoding-to-start':
                navigate(`${ROUTE.SELF_ENCODING}/tasks/fields`);
                break;
            case 'self-encoding-ongoing':
                navigate(ROUTE.SELF_ENCODING);
                break;
            case 'waiting-data-validation':
                navigate(ROUTE.DATA);
                break;
            case 'results-available':
                isCFT
                    ? navigate(ROUTE.RESULTS)
                    : window.open(`${process.env.REACT_APP_V3_BASE_URL}/${currentFarmSeasonId}/results`, '_blank');
                break;
            case 'renewal-new-season-to-start':
                // Start new season !
                navigate({ search: getSearchModalSlug(MODAL_SLUG.START_NEW_SEASON_SUCCESS) });
                break;
            case 'renewal-payment-required':
                navigate(ROUTE.BILLING_RENEWAL);
                break;
        }
    };
    return {
        handleNavigateToAction,
    };
};

/** get the state of the flow to know what to display and what action to do */
const _useCurrentState = (
    nextFarmSeason: FarmSeasonT | null,
    latestFarmSeason: FarmSeasonT | undefined,
    canOpenNewSeason: boolean,
    isStandardPlan: boolean | undefined,
    isPrepaidPlan: boolean,
    canUpdateUpsell: boolean,
) => {
    const isBaseline = latestFarmSeason?.baseline_farm_season_id === null;
    // installation is an array "[]" when empty
    const selfEncodingStarted = !Array.isArray(latestFarmSeason?.installation) && !!latestFarmSeason?.installation;

    let currentState: ActionNamesT | false = false;

    // Season is the baseline and Self-encoding not started
    if (isBaseline && !selfEncodingStarted) {
        currentState = 'baseline-self-encoding-to-start';
    }

    // A new season is available (results generated)
    if (canOpenNewSeason) {
        const nextFarmSeasonProductPlans = nextFarmSeason?.farm_season_product_plans;
        const nextFarmSeasonLatestProductPlan = nextFarmSeasonProductPlans
            ? nextFarmSeasonProductPlans[nextFarmSeasonProductPlans.length - 1]
            : null;
        const hasNoStandardPlanPaidNextYear =
            !nextFarmSeasonLatestProductPlan || !['active', 'pending'].includes(nextFarmSeasonLatestProductPlan.status);
        const paymentInProgress =
            hasNoStandardPlanPaidNextYear && nextFarmSeasonLatestProductPlan?.status === 'payment_in_progress';

        if (paymentInProgress) {
            currentState = 'renewal-payment-in-progress';
        } else {
            const mustPay = isStandardPlan && hasNoStandardPlanPaidNextYear && !isPrepaidPlan;

            currentState = mustPay ? 'renewal-payment-required' : 'renewal-new-season-to-start';
        }
    }

    if (!isBaseline && !selfEncodingStarted) {
        currentState = 'renewal-self-encoding-to-start';
    }

    // Self encoding started and not finalized
    if (selfEncodingStarted && !latestFarmSeason.installation_validated_at) {
        currentState = 'self-encoding-ongoing';
    }

    // installation finalized and operation not
    if (latestFarmSeason?.installation_validated_at && !latestFarmSeason.operations_validated_at) {
        currentState = 'waiting-agronomist-encoding';
    }

    // operation finalized and data summary not validated
    if (latestFarmSeason?.operations_validated_at && !latestFarmSeason.summary_validated_at) {
        currentState = 'waiting-data-validation';
    }

    // data summary validated and result not validated
    if (latestFarmSeason?.summary_validated_at && !latestFarmSeason?.results_validated_by_agronomist) {
        currentState = 'waiting-results-validation';
    }

    // results available
    if (latestFarmSeason?.results_validated_by_agronomist_at) {
        // if new season available, keep results availables until 2 days (updated after HG-300)
        const twoDaysAgoDate = new Date();
        twoDaysAgoDate.setDate(new Date().getDate() - 2);
        if (
            !(
                (currentState && ['renewal-new-season-to-start'].includes(currentState) && canOpenNewSeason) ||
                (currentState &&
                    ['renewal-payment-required', 'renewal-payment-in-progress'].includes(currentState) &&
                    canUpdateUpsell)
            ) ||
            twoDaysAgoDate < new Date(latestFarmSeason.results_validated_by_agronomist_at)
        ) {
            currentState = 'results-available';
        }
    }

    return { currentState } as { currentState: typeof currentState }; // "as" to force false instead of boolean
};

export default useCardFlowNavigationContainerLogic;
