import { api } from './api.service';
import { GetPriceRequestT, GetPriceResponseT } from './static.types';

export const staticApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPrice: build.query<GetPriceResponseT, GetPriceRequestT>({
            query: ({ id }) => ({
                url: `/static_data/price/${id}`,
                method: 'GET',
            }),
            providesTags: ['Price'],
        }),
    }),
});

export const { useGetPriceQuery, useLazyGetPriceQuery } = staticApi;
