import { api } from './api.service';
import {
    GetPayoutContextRequestT,
    GetPayoutContextResponseT,
    UpdateIbanContextRequestT,
    UpdateIbanContextResponseT,
} from './payout.types';

export const payoutApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPayoutContext: build.query<GetPayoutContextResponseT, GetPayoutContextRequestT>({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/payout/context`,
                method: 'GET',
            }),
            providesTags: ['IBAN'],
        }),
        editIban: build.mutation<UpdateIbanContextResponseT, UpdateIbanContextRequestT>({
            query: ({ farmId, farmSeasonId, body }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/payout/update`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: async ({ farmId, farmSeasonId }, { dispatch, queryFulfilled }) => {
                try {
                    const { data: updatedIban } = await queryFulfilled;
                    dispatch(
                        payoutApi.util.updateQueryData('getPayoutContext', { farmId, farmSeasonId }, (draft) => {
                            Object.assign(draft, updatedIban);
                        }),
                    );
                } catch {}
            },
        }),
    }),
});

export const { useGetPayoutContextQuery, useEditIbanMutation } = payoutApi;
