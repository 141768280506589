import HelpButtonView from '@components/HelpButton/HelpButtonView';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import { InputLabel } from '@mui/material';

import useRequiredInputLabelStyle from './RequiredInputLabel.style';
import { RequiredInputLabelPropsT } from './RequiredInputLabel.types';

const RequiredInputLabel = ({
    children,
    htmlFor,
    required,
    className,
    id,
    withHelp,
    helpName,
    tooltipSlug,
}: RequiredInputLabelPropsT): JSX.Element => {
    const { classes } = useRequiredInputLabelStyle();

    return (
        <InputLabel className={`${className} ${classes.label}`} htmlFor={htmlFor} id={`${id}-label`}>
            <TooltipSc translationSlug={tooltipSlug}>{children}</TooltipSc>
            {required && <span style={{ color: 'red' }}> *</span>}
            {withHelp && <HelpButtonView id={helpName || `${id}-help`} />}
        </InputLabel>
    );
};

export default RequiredInputLabel;
