import { makeStyles } from 'tss-react/mui';

const useEditStepStyle = makeStyles()(() => ({
    root: {},
    title: {
        marginBottom: '10px',
    },
    labelFullwidth: {
        '& .MuiInputBase-root': { width: '150px' },
        '& .MuiFormLabel-root': {
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            wordWrap: 'break-word',
            marginBottom: '10px',
        },
    },
}));

export default useEditStepStyle;
