import { EntityId } from '@reduxjs/toolkit';
import cropEntityApi from './cropEntity.api';
import { cropCacheAdapter } from './cropEntity.cache';
import { CropEndpointParamsT, CropT } from './cropEntity.types';
import { useMemo } from 'react';
import fieldCropEntityState from '../fieldCropEntity/fieldCropEntity.state';

export default ({ farmSeasonId }: CropEndpointParamsT) => {
    const { data = cropCacheAdapter.getInitialState(), isLoading } = cropEntityApi.useGet({ farmSeasonId });

    /* ------------------------------ Selectors ----------------------------- */
    // Could be common
    const { list, getById } = useMemo(() => {
        const { selectAll, selectById } = cropCacheAdapter.getSelectors();
        return {
            list: selectAll(data),
            getById: (id: EntityId) => selectById(data, id),
        };
    }, [data]);

    const getByIds = (fieldIds: number[]) => fieldIds.map((cropId) => getById(cropId)).filter((c) => !!c) as CropT[];

    const useGetCropsCountForField = () => {
        const { fieldCropState } = fieldCropEntityState({ farmSeasonId });
        return {
            getCropsCountForField: ({ fieldId }: { fieldId: number }) =>
                fieldCropState.getByFieldId({ fieldId }).length,
        };
    };

    return {
        cropState: {
            list,
            isLoading,
            getById,
            getByIds,
            useGetCropsCountForField,
        },
    };
};
