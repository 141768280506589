import { useSelector } from 'react-redux';
import { PaymentFormLogicPropsT } from './PaymentForm.types';
import { AppStateT } from '@store/store';
import PaymentApiService from '@services/paymentApiService/paymentApiService';
import { useTranslation } from 'react-i18next';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { UserCompanyT } from '@services/userCompanyApiService/userCompanyApiServiceTypes';
import userCompanyApiService from '@services/userCompanyApiService/userCompanyApiService';
import { BillingDetailsFormRefControllerT } from '../BillingDetailsForm/BillingDetailsForm.types';
import { usePayInitialSubscriptionMutation } from '@pages/HomePage/Settings/components/SubscriptionInfoCard/components/RenewalActions/renewal.api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { savePaymentInfo } from '@/store/actions/SubscriptionActions/SavePaymentInfo';

/**
 * This hook is explicitly designed to be used with `PaymentForm.tsx` \
 * It uses `loadUserCompany` by default when userCompany is undefined which calls the {@link userCompanyApiService.getFirst} API
 * - {@link type} must be used to define the payment logic
 * - {@link userCompany} can be provided to avoid the initialLoading
 * - {@link paymentFormRef} is used as forwardRef such that the parent can control this component
 */
const usePaymentFormLogic = ({
    type,
    userCompany,
    paymentFormRef,
    setInitialLoading,
    setIsSubmitting,
}: PaymentFormLogicPropsT) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const userId = useSelector((state: AppStateT) => state.auth.selectedUser?.id ?? state.auth.user?.id);
    const farmSeasonId = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason?.id);
    const billingDetailsFormRef = useRef<BillingDetailsFormRefControllerT>(null);
    const [payInitialSubscription] = usePayInitialSubscriptionMutation();

    //The loading is initialized via userCompany and this is because we skip the API call if userCompany is provided
    const [userCompanyLoading, setUserCompanyLoading] = useState<boolean>(userCompany ? false : true);
    const [userCompanyResData, setUserCompanyResData] = useState<UserCompanyT | undefined>(undefined);

    useImperativeHandle(
        paymentFormRef,
        () => ({
            redirectToPayment: onPaymentRedirect,
        }),
        [userCompanyLoading, billingDetailsFormRef],
    );

    // Lifecycle on component mount
    useEffect(() => {
        /** This function definition allows the usage of async/await syntax */
        const loadUserCompany = async () => {
            if (userId && !userCompanyResData) {
                const res = await userCompanyApiService.getFirst({ userId });
                setUserCompanyResData(res.data);
            }
            setUserCompanyLoading(false);
        };

        if (!userCompany) {
            loadUserCompany();
        }
    }, []);

    /** This is the definition of the logic that will happen after the submit */
    const onPaymentRedirect = async () => {
        if (setIsSubmitting) {
            setIsSubmitting(true);
        }
        const values = await billingDetailsFormRef.current?.getFormData();

        if (values) {
            try {
                // Todo: Avoid using type and replace it by providing the backend with proper success and fail redirect url's
                if (type == 'next-season') {
                    if (!userId || !farmId || !farmSeasonId) {
                        console.error('payment aborted');
                        return false;
                    }

                    // Call Renewal payment;
                    const { redirectUrl } = await payInitialSubscription({
                        farmId,
                        farmSeasonId,
                        body: { ...values },
                    }).unwrap();
                    if (setIsSubmitting) {
                        setIsSubmitting?.(false);
                    }
                    return window.location.replace(redirectUrl);
                }

                /** We assume the type is either `first`, `second` or `upsell` and this is the default behavior of the logic */
                if (type !== 'first') {
                    const res = await PaymentApiService.postPaymentDetail(
                        farmId,
                        farmSeasonId,
                        { ...values },
                        type,
                        i18n?.language,
                    );
                    if (setIsSubmitting) {
                        setIsSubmitting(false);
                    }

                    return window.location.replace(res.data.redirectUrl);
                } else {
                    //store the values
                    dispatch(savePaymentInfo(values));
                    navigate('/subscribe/product-plan');
                }
                //dispatch(saveStripeRedirectURL(res.data.redirectUrl));
            } catch (error: unknown) {
                if ((error as AxiosError<{ error: 'INVALID_TAX_ID' }>).response?.data.error === 'INVALID_TAX_ID') {
                    billingDetailsFormRef.current?.displayError('validation.tax-invalid-or-country-not-match');
                } else {
                    billingDetailsFormRef.current?.displayError('constants.server-error');
                }
                if (setIsSubmitting) {
                    setIsSubmitting(false);
                }
                return false;
            }
        }
        if (setIsSubmitting) {
            setIsSubmitting(false);
        }
        return false;
    };

    /** calls the setInitialLoading callback on userCompanyLoading change */
    useEffect(() => {
        if (setInitialLoading) {
            setInitialLoading(userCompanyLoading);
        }
    }, [userCompanyLoading]);

    return { userCompanyLoading, userCompanyResData, billingDetailsFormRef };
};

export default usePaymentFormLogic;
