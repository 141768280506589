import { ErrorCardSlugsT } from './ErrorCard.types';

const ErrorCardSlugs: ErrorCardSlugsT = {
    default: {
        title: 'pages.error.payment.generic.title',
        description: 'pages.error.payment.generic.description',
        button: 'pages.error.payment.generic.button',
    },
    ALREADY_PAID: {
        title: 'pages.error.payment.payment-done.title',
        description: 'pages.error.payment.payment-done.description',
        button: 'pages.error.payment.payment-done.button',
    },
    UPSELL_CANCELLED: {
        title: 'pages.error.payment.upsell-cancelled.title',
        description: 'pages.error.payment.upsell-cancelled.description',
        button: 'pages.error.payment.upsell-cancelled.button',
    },
    NEW_HARVEST_STARTED: {
        title: 'pages.error.payment.new-season-started.title',
        description: 'pages.error.payment.new-season-started.description',
        button: 'pages.error.payment.new-season-started.button',
    },
};

export default ErrorCardSlugs;
