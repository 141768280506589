export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export type ForgotPasswordActionDataT = {
    email: string;
};

export type ForgotPasswordResponseDataT = {
    result: boolean;
};

export type ForgotPasswordResponseErrorT = {
    message?: string;
    list?: string[];
};

export type ForgotPasswordLoadingT = {
    type: typeof FORGOT_PASSWORD_LOADING;
};

export type ForgotPasswordFailedT = {
    type: typeof FORGOT_PASSWORD_FAILED;
    error: ForgotPasswordResponseErrorT;
};

export type ForgotPasswordSuccessT = {
    type: typeof FORGOT_PASSWORD_SUCCESS;
    payload: ForgotPasswordResponseDataT;
};

export type ForgotPasswordDispatchTypesT = ForgotPasswordLoadingT | ForgotPasswordFailedT | ForgotPasswordSuccessT;
