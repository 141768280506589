import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonValidateDispatchTypes,
    FarmSeasonValidateFailedDataT,
    FARM_SEASON_VALIDATE_FAILED,
    FARM_SEASON_VALIDATE_LOADING,
    FARM_SEASON_VALIDATE_SUCCESS,
} from './FarmSeasonValidateTypes';

/**
 * validate farm season by the farmer
 * @return true if success
 */
export const farmSeasonValidate =
    (farmId: number, farmSeasonId: number) =>
    async (dispatch: Dispatch<FarmSeasonValidateDispatchTypes>): Promise<boolean> => {
        try {
            dispatch({ type: FARM_SEASON_VALIDATE_LOADING });
            const res: AxiosResponse<FarmSeasonT> = await FarmSeasonApiService.validateSeason(farmId, farmSeasonId);
            dispatch({
                type: FARM_SEASON_VALIDATE_SUCCESS,
                payload: res.data,
            });

            return true;
        } catch (error) {
            dispatch({
                type: FARM_SEASON_VALIDATE_FAILED,
                error: error as FarmSeasonValidateFailedDataT,
            });

            return false;
        }
    };

export default farmSeasonValidate;
