const normalizeString = (str: string) =>
    str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

/**
 *  search if we found needle in haystack. We normalize strings to match special characters and case. à === a and U === u
 * @param needle the part of string we want to found in the haystack
 * @param haystack the bigger string we are searching on
 * @returns true if we found a match
 */
const searchString = (needle: string, haystack: string): boolean =>
    normalizeString(haystack).indexOf(normalizeString(needle)) !== -1;

export default searchString;
