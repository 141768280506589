import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import Restricted from '../Restricted/Restricted';

const RestrictSaveFarmSeason = ({ children }: { children: JSX.Element }) => {
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);

    return (
        <Restricted
            toPermission="EDIT_FARM-SEASON"
            forResource={currentFarmSeason}
            withProps={({ forbiddenCondition }) => ({
                disabled: true,
                tooltipSlug:
                    forbiddenCondition === 'IF-AUDIT-DONE'
                        ? 'permission.tooltip.farm-season-audit-done'
                        : forbiddenCondition === 'IF-AUDIT-IN-PROGRESS'
                        ? 'permission.tooltip.farm-season-audit-in-progress'
                        : forbiddenCondition === 'IF-SUMMARY-VALIDATED'
                        ? 'permission.tooltip.farm-season-summary-validated'
                        : null,
            })}
        >
            {children}
        </Restricted>
    );
};

export default RestrictSaveFarmSeason;
