import { makeStyles } from 'tss-react/mui';

const useChipStyle = makeStyles()(({ palette }) => ({
    customChip: {
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: '24px',
        borderRadius: '4px',
        border: `1px solid ${palette.grey[200]}`,
        padding: '2px 4px',
        width: 'fit-content',
        marginRight: '4px',
        marginBottom: '2px',
        backgroundColor: palette.common.white,
    },
}));

export default useChipStyle;
