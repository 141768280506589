import { FarmerInfosT } from '@/services/authService/authServiceTypes';
import { UserT } from '@reducers/AuthReducer/AuthReducerTypes';

export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export type LoginActionDataT = {
    email: string;
    password: string;
};

export type LoginResponseDataT = {
    user: UserT;
    farmer_infos: FarmerInfosT | null;
    access_token: string;
    expires_in: number;
};

export type LoginResponseErrorT = {
    message?: string;
    list?: string[];
};

export type LoginLoadingT = {
    type: typeof LOGIN_LOADING;
};

export type LoginFailedT = {
    type: typeof LOGIN_FAILED;
    error: LoginResponseErrorT;
};

export type LoginSuccessT = {
    type: typeof LOGIN_SUCCESS;
    payload: LoginResponseDataT;
    remember: boolean;
};

type access = 'FP_ACCESS_TO_PLATFORM';

export type ScToken = {
    permissions: string[];
    access: access[];
};

export type LoginDispatchTypesT = LoginLoadingT | LoginFailedT | LoginSuccessT;
