import TableView from '@components/Table/TableView';
import findById from '@utils/findById';
import { useTranslation } from 'react-i18next';
import CardBasic from '@components/CardBasic/CardBasic';
import { useTableViewCellStyle } from '@components/Table/TableView.style';

import { AgroforestryColumnsT, AgroforestryRowsT, CardAgroforestryPropsT } from './CardAgroforestry.types';
import formatNumber from '@utils/formatNumber';

const CardAgroforestryView = ({ agroforestry, treesList }: CardAgroforestryPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useTableViewCellStyle();

    /* ------------------------------ Table Config ------------------------------ */
    const rows: AgroforestryRowsT = agroforestry.map((tree): AgroforestryRowsT[0] => ({
        diameter: formatNumber(Number(tree.diameter)),
        quantity: formatNumber(Number(tree.quantity)),
        type: t(findById(tree.tree_type_id, treesList)?.translation_slug ?? ''),
    }));
    const columns: AgroforestryColumnsT = [
        { field: 'type', headerName: t('pages.farmer-data.agroforestry.col-type'), cellClassName: classes.bold },
        {
            field: 'quantity',
            headerName: t('pages.farmer-data.agroforestry.col-quantity'),
            cellClassName: classes.bold,
        },
        {
            field: 'diameter',
            headerName: t('pages.farmer-data.agroforestry.col-diameter'),
            cellClassName: classes.bold,
        },
    ];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Translates ------------------------------- */
    const treesCount = agroforestry.reduce((total, tree) => total + Number(tree.quantity), 0);
    const totalTreesText = t('pages.farmer-data.agroforestry.trees-count', {
        count: treesCount,
        treesCount: formatNumber(treesCount),
    });
    const titleText = t('pages.farmer-data.agroforestry.title');
    /* -------------------------------------------------------------------------- */

    return (
        <CardBasic additionalInfo={totalTreesText} title={titleText}>
            <>{!!rows.length && <TableView columns={columns} rows={rows} size="small" />}</>
        </CardBasic>
    );
};

export default CardAgroforestryView;
