import { makeStyles } from 'tss-react/mui';

const useYieldAndResidueViewStyle = makeStyles()((theme) => ({
    cardContainer: {
        padding: '12px',
    },
    card: {
        marginBottom: '5px',
        backgroundColor: theme.palette.spanishWhite.light,
    },
    cropTitle: {
        marginBottom: '5px',
    },
    inputsContainer: {},
    inputContainer: {},
    cropsContainer: {
        marginTop: '10px',
        display: 'flex',
    },
    title: {
        color: theme.palette.driftwood.dark,
        cursor: 'pointer',
        fontSize: '1rem',
    },

    colorContainer: {},
    textSection: {},
}));

export default useYieldAndResidueViewStyle;
