import FarmSeasonFieldsSoilOrganicMatterApiService from '@services/farmSeasonFieldsSoilOrganicMatterApiService/farmSeasonFieldsSoilOrganicMatterApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsSoilOrganicMatterPostCallBackT,
    PostFarmSeasonFieldsSoilOrganicMatterDataT,
    PostFarmSeasonFieldsSoilOrganicMatterDispatchTypesT,
    PostFarmSeasonFieldsSoilOrganicMatterResponseDataT,
    PostFarmSeasonFieldsSoilOrganicMatterResponseErrorT,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_FAILED,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_LOADING,
    POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS,
} from './PostFarmSeasonFieldsSoilOrganicMatterTypes';

export const postFarmSeasonFieldsSoilOrganicMatter =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsSoilOrganicMatterDataT,
    ): FarmSeasonFieldsSoilOrganicMatterPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsSoilOrganicMatterDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsSoilOrganicMatterResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsSoilOrganicMatterResponseDataT> =
                await FarmSeasonFieldsSoilOrganicMatterApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_FAILED,
                error: error as PostFarmSeasonFieldsSoilOrganicMatterResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
