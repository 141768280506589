import farmSeasonFieldsCoverCropHistoryApiService from '@services/farmSeasonFieldsCoverCropHistoryApiService/farmSeasonFieldsCoverCropHistoryApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsCoverCropHistoryPostCallBackT,
    PostFarmSeasonFieldsCoverCropHistoryDataT,
    PostFarmSeasonFieldsCoverCropHistoryDispatchTypesT,
    PostFarmSeasonFieldsCoverCropHistoryResponseDataT,
    PostFarmSeasonFieldsCoverCropHistoryResponseErrorT,
    POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_FAILED,
    POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_LOADING,
    POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS,
} from './PostFarmSeasonFieldsCoverCropHistoryTypes';

const postFarmSeasonFieldsCoverCropHistory =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsCoverCropHistoryDataT,
    ): FarmSeasonFieldsCoverCropHistoryPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsCoverCropHistoryDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsCoverCropHistoryResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsCoverCropHistoryResponseDataT> =
                await farmSeasonFieldsCoverCropHistoryApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_FAILED,
                error: error as PostFarmSeasonFieldsCoverCropHistoryResponseErrorT,
            });

            return {};
        }
    };

export default postFarmSeasonFieldsCoverCropHistory;
