import i18n from 'i18next';

const formatCurrencyNumber = (number: number | null | void, currencyIso: 'EUR' | 'GBP' | null | void) => {
    if (!number || !currencyIso) {
        return '-';
    }

    return new Intl.NumberFormat(i18n.resolvedLanguage ?? navigator.language, {
        style: 'currency',
        currency: currencyIso,
    }).format(number);
};

export default formatCurrencyNumber;
