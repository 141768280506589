import { PesticideT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const PESTICIDES_LIST_GET_LOADING = 'PESTICIDES_LIST_GET_LOADING';
export const PESTICIDES_LIST_GET_SUCCESS = 'PESTICIDES_LIST_GET_SUCCESS';
export const PESTICIDES_LIST_GET_FAILED = 'PESTICIDES_LIST_GET_FAILED';

export type PesticidesListGetSuccessDataT = PesticideT[];

export type PesticidesListGetLoadingT = {
    type: typeof PESTICIDES_LIST_GET_LOADING;
};

export type PesticidesListGetSuccessT = {
    type: typeof PESTICIDES_LIST_GET_SUCCESS;
    payload: PesticidesListGetSuccessDataT;
};

export type PesticidesListGetFailedT = {
    type: typeof PESTICIDES_LIST_GET_FAILED;
    error: string;
};

export type PesticidesListGetDispatchType =
    | PesticidesListGetLoadingT
    | PesticidesListGetSuccessT
    | PesticidesListGetFailedT;

export type PesticidesListGetCallback = (dispatch: Dispatch<PesticidesListGetDispatchType>) => Promise<boolean>;
