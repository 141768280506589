import { makeStyles } from 'tss-react/mui';

const useCollapsableSectionViewStyle = makeStyles()((theme) => ({
    nameSectionWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    collapseSection: {
        padding: '20px 0',
    },
    nameHelpSectionWrapper: {
        display: 'flex',
        alignItems: 'baseline',
    },
    title: {
        color: theme.palette.driftwood.dark,
        cursor: 'pointer',
        fontSize: '1rem',
    },
    btnIcon: {
        backgroundColor: theme.palette.driftwood.light,
        color: theme.palette.common.white,
        width: '20px',
        height: '20px',
        '&:hover': {
            backgroundColor: theme.palette.driftwood.dark,
        },
        '& svg': {
            fontSize: '1rem',
        },
    },
    opened: {
        transform: 'rotate(180deg)',
    },
    closed: {},
}));

export default useCollapsableSectionViewStyle;
