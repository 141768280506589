import cropEntityState from '../cropEntity/cropEntity.state';
import fieldCropEntityState from '../fieldCropEntity/fieldCropEntity.state';
import staticCropEntityState from '../staticCropEntity/staticCropEntity.state';
import fieldEntityApi from './fieldEntity.api';
import { FieldEndpointParamsT, FieldT } from './fieldEntity.types';
import { fieldCacheAdapter } from './fieldEntity.cache';
import { useMemo } from 'react';
import { EntityId } from '@reduxjs/toolkit';

export default ({ farmSeasonId }: FieldEndpointParamsT) => {
    const { data = fieldCacheAdapter.getInitialState(), isLoading } = fieldEntityApi.useGet({ farmSeasonId });

    /* ------------------------------ Selectors ----------------------------- */
    const { list, getById } = useMemo(() => {
        const { selectAll, selectById } = fieldCacheAdapter.getSelectors();
        return {
            list: selectAll(data),
            getById: (id: EntityId) => selectById(data, id),
        };
    }, [data]);

    const getByIds = (fieldIds: number[]) => {
        return fieldIds
            .map((id) => getById(id))
            .filter((f) => {
                // TODO Think about error or refetch if state has missmatch ?
                // if (fields.find(f => !f)) {
                //     refetch();
                // }
                return !!f;
            }) as FieldT[];
    };

    const _useGetFieldsForCrop = () => {
        const { fieldCropState } = fieldCropEntityState({ farmSeasonId });

        const getFieldsForCrop = ({ cropId }: { cropId: number }) => {
            const fieldCrops = fieldCropState.getByCropId({ cropId });
            const fields = getByIds(fieldCrops.map((fc) => fc.farm_season_field_id));
            return fields;
        };
        return {
            getFieldsForCrop,
        };
    };

    const useGetFieldsAreaForCrop = () => {
        const { getFieldsForCrop } = _useGetFieldsForCrop();

        const getFieldsAreaForCrop = ({ cropId }: { cropId: number }) =>
            getFieldsForCrop({ cropId })?.reduce((totalArea, f) => f.area + totalArea, 0) ?? 0;

        return { getFieldsAreaForCrop };
    };

    const useGetFieldsCountForCrop = () => {
        const { getFieldsForCrop } = _useGetFieldsForCrop();

        const getFieldsCountForCrop = ({ cropId }: { cropId: number }) => getFieldsForCrop({ cropId }).length;

        return { getFieldsCountForCrop };
    };

    const _useGetStaticCrops = () => {
        const { fieldCropState } = fieldCropEntityState({ farmSeasonId });
        const { cropState } = cropEntityState({ farmSeasonId });
        const { staticCropState } = staticCropEntityState();

        const getStaticCrops = ({ fieldId }: { fieldId: number }) => {
            const fieldCrops = fieldCropState.getByFieldId({ fieldId });
            const crops = cropState.getByIds(fieldCrops.map((fc) => fc.farm_season_crop_id));
            // TODO handle duplicates? (seasonCrop can have 2 same static crop with "is_accompagned")
            const staticCrops = staticCropState.getByIds(crops.map((c) => c.crop_id));
            return staticCrops;
        };

        return {
            getStaticCrops,
        };
    };

    const useGetFieldIsHarvested = () => {
        const { getStaticCrops } = _useGetStaticCrops();

        const getFieldIsHarvested = ({ fieldId }: { fieldId: number }) => {
            const staticCrops = getStaticCrops({ fieldId });
            return !staticCrops.find((c) => c.is_permanent);
        };

        return { getFieldIsHarvested };
    };

    const useFieldAreaHarvested = () => {
        const { getFieldIsHarvested } = useGetFieldIsHarvested();

        const fieldAreaHarvested = list.reduce((totalArea, field) => {
            if (getFieldIsHarvested({ fieldId: field.id })) {
                return field.area + totalArea;
            }
            return totalArea;
        }, 0);
        return { fieldAreaHarvested };
    };

    const useFieldAreaPermanent = () => {
        const { getFieldIsHarvested } = useGetFieldIsHarvested();
        const fieldAreaPermanent = list.reduce((totalArea, field) => {
            if (!getFieldIsHarvested({ fieldId: field.id })) {
                return field.area + totalArea;
            }
            return totalArea;
        }, 0);
        return { fieldAreaPermanent };
    };

    return {
        fieldState: {
            list,
            isLoading,
            getById,
            getByIds,
            useGetFieldsAreaForCrop,
            useGetFieldsCountForCrop,
            useGetFieldIsHarvested,
            useFieldAreaHarvested,
            useFieldAreaPermanent,
        },
    };
};
