import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_AGROFORESTRY_LOADING = 'POST_FARM_SEASON_FIELDS_AGROFORESTRY_LOADING';
export const POST_FARM_SEASON_FIELDS_AGROFORESTRY_FAILED = 'POST_FARM_SEASON_FIELDS_AGROFORESTRY_FAILED';
export const POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS = 'POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS';

export type FieldAgroforestryDataT = {
    id: number;
    has_agroforestry: boolean;
};

export type PostFarmSeasonFieldsAgroforestryDataT = {
    has_agroforestry: boolean;
    fields: FarmSeasonFieldT[];
};

export type PostFarmSeasonFieldsAgroforestryResponseDataT = {
    fields: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            irrigation: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsAgroforestryPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsAgroforestryDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsAgroforestryResponseErrorT>;

export type PostFarmSeasonFieldsAgroforestryResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsAgroforestryLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_AGROFORESTRY_LOADING;
};

export type PostFarmSeasonFieldsAgroforestryFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_AGROFORESTRY_FAILED;
    error: PostFarmSeasonFieldsAgroforestryResponseErrorT;
};

export type PostFarmSeasonFieldsAgroforestrySuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS;
    payload: PostFarmSeasonFieldsAgroforestryResponseDataT;
};

export type PostFarmSeasonFieldsAgroforestryDispatchTypesT =
    | PostFarmSeasonFieldsAgroforestryLoadingT
    | PostFarmSeasonFieldsAgroforestryFailedT
    | PostFarmSeasonFieldsAgroforestrySuccessT;
