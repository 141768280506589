import { Container } from '@mui/material';
import TopBar from '@pages/HomePage/components/TopBar/TopBar';

import ModalControlWithUrl from '@components/ModalControlWithUrl/ModalControlWithUrl';
import ModalNewSeasonStarted from '@components/ModalNewSeasonStarted/ModalNewSeasonStarted';
import ModalWarningApp from '@components/ModalWarningApp/ModalWarningApp';
import { MODAL_SLUG } from '@constants/modalSlugs';
import useAppNavigate from '@hooks/useAppNavigate';
import useHomePageLoading from '@hooks/useHomePageLoading';
import HomePageLayout from '@layouts/HomePageLayout/HomePageLayout';
import useHomePageRouterConfig from '@routers/HomePageRouter/useHomePageRouterConfig';
import SideBar from './components/SideBar/SideBar';
import FieldsContext from './Fields/FieldsContext/FieldsContext';
import useHomePageLogic from './HomePage.logic';
import useHomePageStyle from './HomePage.style';
import { HomePagePropsT } from './HomePageTypes';
import MapPopupContentBridge from '@components/Map/components/MapPopupContentBridge/MapPopupContentBridge';
import FieldPopupContent from '@components/FieldPopupContent/FieldPopupContent';
import CelebrationNotificationContainer from './components/CelebrationNotificationContainer/CelebrationNotificationContainer';

const HomePagePageView = ({ sideBarRef }: HomePagePropsT): JSX.Element => {
    const navigate = useAppNavigate();
    const { homePageLoading } = useHomePageLoading();
    const { handleSelectNewestSeason } = useHomePageLogic();
    const homePageRouterConfig = useHomePageRouterConfig();

    const { classes } = useHomePageStyle();

    return (
        <Container maxWidth="xl">
            <div className={classes.dashboardPageWrapper} id="dashboardPageWrapper">
                <div className={classes.appBarWrapper}>
                    <TopBar />
                </div>
                <div ref={sideBarRef} className={classes.sideBarWrapper}>
                    <SideBar />
                </div>

                {/* Temporary wrap all routes as context is used in different route.element */}
                <FieldsContext>
                    <HomePageLayout loading={homePageLoading} homePageRouterConfig={homePageRouterConfig} />
                </FieldsContext>
            </div>

            {/* Display field popup as default popup */}
            <MapPopupContentBridge contentComponent={FieldPopupContent} />

            {/* ----------- Modal New Season done (openable from DashboardView or InstallationView) ---------- */}
            <ModalControlWithUrl
                ModalComponent={ModalNewSeasonStarted}
                modalProps={{
                    width: '700px',
                    height: '500px',
                    onClickActionCanceled: () => navigate({ search: '' }),
                    onClickActionSuccess: handleSelectNewestSeason,
                }}
                urlModalSlug={MODAL_SLUG.START_NEW_SEASON_SUCCESS}
            />
            <CelebrationNotificationContainer />
            {/* ------------------------ Global Modal for Warnings ----------------------- */}
            <ModalWarningApp />
        </Container>
    );
};

export default HomePagePageView;
