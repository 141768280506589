import { Dispatch } from '@reduxjs/toolkit';

import { ERRORS_ADD, ERRORS_REMOVE, ERRORS_CLEAN, ErrorCleanT, ErrorAddT, ErrorRemoveT } from './ErrorActionsTypes';

export const ErrorActionAdd =
    (payload: ErrorAddT['payload']) =>
    (dispatch: Dispatch<ErrorAddT>): void => {
        dispatch({ type: ERRORS_ADD, payload });
    };

export const ErrorActionRemove =
    (payload: ErrorRemoveT['payload']) =>
    (dispatch: Dispatch<ErrorRemoveT>): void => {
        dispatch({ type: ERRORS_REMOVE, payload });
    };

export const ErrorActionClean =
    () =>
    (dispatch: Dispatch<ErrorCleanT>): void => {
        dispatch({ type: ERRORS_CLEAN });
    };
