import {
    PostFarmSeasonFieldsCoverCropHistoryDataT,
    PostFarmSeasonFieldsCoverCropHistoryResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsCoverCropHistoryTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsCoverCropHistoryApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsCoverCropHistoryDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsCoverCropHistoryResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/cover-crops-history`,
            method: 'POST',
            data: body,
        });
}

export default farmSeasonFieldsCoverCropHistoryApiService;
