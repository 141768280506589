import { TableColumnsT } from '@components/Table/TableViewTypes';
import TableView from '@components/Table/TableView';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TableHeaderCell from '../TableHeaderCell/TableHeaderCell';

import usePurchaseTableStyle from './PurchaseTable.style';
import { PurchaseTablePropsT, ColumnNameT } from './PurchaseTableTypes';
import usePurchaseTableLogic from './PurchaseTable.logic';

const PurchaseTable = ({ farmSeasonPurchase, size = 'medium' }: PurchaseTablePropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = usePurchaseTableStyle();

    const { rows } = usePurchaseTableLogic({ farmSeasonPurchase });

    const columns: TableColumnsT<ColumnNameT> = [
        {
            headerName: <TableHeaderCell value={t('pages.carbon-bank.purchase-table.harvest-year')} />,
            field: 'harvestYear',
            maxWidth: '150px',
        },
        {
            headerName: <TableHeaderCell value={t('pages.carbon-bank.purchase-table.payment')} />,
            field: 'purchaseIssue',
            maxWidth: '150px',
            renderColCell: ({ value }) => <Typography color="secondary">{value}</Typography>,
        },
        {
            headerName: <TableHeaderCell value={t('pages.carbon-bank.purchase-table.revenue')} />,
            field: 'revenue',
            maxWidth: '150px',
        },
        {
            headerName: <TableHeaderCell value={t('pages.carbon-bank.purchase-table.purchase-price')} />,
            field: 'purchasePrice',
            maxWidth: '150px',
        },
        {
            headerName: <TableHeaderCell value={t('pages.carbon-bank.purchase-table.certificates-number')} />,
            field: 'onMarket',
            maxWidth: '150px',
        },
    ];

    return (
        <div className={classes.PaymentTable}>
            <TableView<ColumnNameT> color="secondary" columns={columns} rows={rows} size={size} />
        </div>
    );
};

export default PurchaseTable;
