import { Card, IconButton, Typography } from '@mui/material';
import useMapPopupContentLayoutStyle from './MapPopupContentLayout.style';
import { GridCloseIcon } from '@mui/x-data-grid';
import { MapPopupContentLayoutPropsT } from './MapPopupContentLayout.types';

const MapPopupContentLayout = ({ onClose, children, title }: MapPopupContentLayoutPropsT) => {
    const { classes } = useMapPopupContentLayoutStyle();
    return (
        <Card elevation={2} className={classes.MapPopupContentLayout}>
            <IconButton className={classes.closeIcon} disableTouchRipple disableFocusRipple onClick={() => onClose()}>
                <GridCloseIcon style={{ fontSize: '16px' }} />
            </IconButton>
            <Typography variant="h3" color="secondary.dark" className={classes.title}>
                {title}
            </Typography>
            {children}
        </Card>
    );
};

export default MapPopupContentLayout;
