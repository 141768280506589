import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import palette from '@constants/palette/crop.palette';
import ColorPatternButtonView from '@components/ColorPatternButton/ColorPatternButtonView';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Prompt } from '@components/Prompt/Prompt';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useCropListViewStyle from './CropListView.style';
import { CropListViewPropsT } from './CropListTypes';
import { ModalWarning } from '@soil-capital/ui-kit';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import formatNumber from '@utils/formatNumber';

const CropListView = ({
    cropList,
    onCropEdit,
    fieldList,
    noResidue,
    onSaveResidue,
    setNoResidue,
    modalConfirmNoResidue,
    deleteAllResidue,
    loading,
    nothingToSave,
    errorConfirmNoResidue,
}: CropListViewPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useCropListViewStyle();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!nothingToSave}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.fields-subtasks.residue-task.define-residue">
                    <span className={classes.darkerTitle}>Pour chaque champ,</span> veuillez préciser la quantité de
                    résidus exportés
                </Trans>
                <HelpButtonView id="pages.installationtasks.fields-subtasks.residue-task.define-residue" />
            </Typography>
            <div className={classes.listContainer}>
                {cropList.map((crop, index) => (
                    <ColorPatternButtonView
                        key={crop.id}
                        color={palette[index]}
                        name={t(crop.crop.translation_slug || '')}
                        onClick={() => onCropEdit(crop)}
                    >
                        {fieldList.filter((field) =>
                            field.field_crops?.some(
                                (fCrop) => fCrop.farm_season_crop.id === crop.id && fCrop.residue && fCrop.residue > 0,
                            ),
                        ).length > 0 ? (
                            <>
                                <Typography color="primary" variant="body1">
                                    {t('pages.installationtasks.fields-subtasks.residue-task.field', {
                                        count: fieldList.filter((field) =>
                                            field.field_crops?.some(
                                                (fCrop) =>
                                                    fCrop.farm_season_crop.id === crop.id &&
                                                    fCrop.residue &&
                                                    fCrop.residue > 0,
                                            ),
                                        ).length,
                                    })}
                                </Typography>
                                <Typography color="primary" variant="body1">
                                    {`${formatNumber(
                                        fieldList
                                            .filter((field) =>
                                                field.field_crops?.some(
                                                    (fCrop) =>
                                                        fCrop.farm_season_crop.id === crop.id &&
                                                        fCrop.residue &&
                                                        fCrop.residue > 0,
                                                ),
                                            )
                                            .reduce((totalHa, field) => totalHa + field.area, 0) as number,
                                        3,
                                    )} ${t('constants.hectare-unit')}`}
                                </Typography>
                            </>
                        ) : null}
                    </ColorPatternButtonView>
                ))}
            </div>
            <LabelCheckboxView
                disabled={!canEditFarmSeason.access}
                border="dotted"
                checked={noResidue}
                className={classes.checkbox}
                error={errorConfirmNoResidue}
                helperText={t('pages.installationtasks.fields-subtasks.residue-task.check-box-to-confirm')}
                label={t('pages.installationtasks.confirm-task.label-residue')}
                name="residue-checkbox"
                onChange={(event, checked) => {
                    if (checked) {
                        if (
                            fieldList.some((field) =>
                                field.field_crops?.some((fCrop) => fCrop.residue && fCrop.residue && fCrop.residue > 0),
                            )
                        ) {
                            modalConfirmNoResidue.openModal(() => {
                                deleteAllResidue();
                            });
                        } else {
                            deleteAllResidue();
                        }
                    } else {
                        setNoResidue(checked);
                    }
                }}
            />
            <TwoButtonFooterView
                rightLoading={loading}
                rightOnCLick={onSaveResidue}
                rightText={canEditFarmSeason.access ? t('constants.save') : ''}
            />
            <ModalWarning
                controller={modalConfirmNoResidue}
                confirmMessage={t('pages.installationtasks.fields-subtasks.residue-task.confirm-no-residue')}
                description={t('pages.installationtasks.fields-subtasks.residue-task.description-no-residue')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.fields-subtasks.residue-task.title-no-residue')}
            />
        </div>
    );
};

export default CropListView;
