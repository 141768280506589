import IconFarm from '@icons/icon-farm.svg';

import TopBarSelector from '../TopBarSelector/TopBarSelector';

import { FarmSelectorViewPropsT } from './FarmSelectorTypes';

const FarmSelectorView = ({ onChange, currentValue, farmList, className }: FarmSelectorViewPropsT): JSX.Element => (
    <TopBarSelector
        className={className}
        currentValue={currentValue}
        icon={{ icon: IconFarm, viewBox: '0 0 19 21' }}
        listItem={farmList}
        onChange={onChange}
    />
);

export default FarmSelectorView;
