import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
    AgroforestryCardFormValidationsPropsT,
    AgroforestryCardFormValuesPropsT,
    AgroforestryCardFormValuesT,
} from './AgroforestryCardFormTypes';

import { useMemo } from 'react';
import { PostFarmSeasonFieldAgroforestryValuesDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldAgroforestryValuesTypes';

export const useAgroforestryCardFormValidations = ({
    previousSeasonTreesMap,
}: AgroforestryCardFormValidationsPropsT) => {
    const { t } = useTranslation();

    return {
        agroforestryCardFormValidation: Yup.object().shape({
            tree_type_id: Yup.string().required(t('validation.mandatory-field')),
            diameter: Yup.string()
                .required(t('validation.mandatory-field'))
                .test(
                    'bigger-than-0',
                    t('validation.must-be-greater-than-x', { x: 0 }),
                    (diameterValue) => Number(diameterValue) > 0,
                )
                .test(
                    'bigger-than-previous',
                    t('validation.must-be-greater-than-previous'),
                    (diameterValue, context) => {
                        const lastYearDiameter: string | number =
                            previousSeasonTreesMap[context.parent.previous_farm_season_field_tree_id]?.diameter ?? 0;
                        return Number(diameterValue) >= Number(lastYearDiameter);
                    },
                ),
            previous_farm_season_field_tree_id: Yup.number().nullable(),
            quantity: Yup.string().when('previous_farm_season_field_tree_id', {
                is: (id: number) => !id,
                then: Yup.string()
                    .required(t('validation.mandatory-field'))
                    .test(
                        'bigger-than-0',
                        t('validation.must-be-greater-than-x', { x: 0 }),
                        (quantityValue) => Number(quantityValue) > 0,
                    ),
            }),
            cutCount: Yup.string().when('previous_farm_season_field_tree_id', {
                is: (id: number) => !!id,
                then: Yup.string()
                    .required(t('validation.mandatory-field'))
                    .test(
                        'bigger-than-0',
                        t('validation.must-be-greater-or-equal-to-x', { x: 0 }),
                        (cutValue) => Number(cutValue) >= 0,
                    )
                    .test(
                        'smaller-than-previous',
                        t('validation.must-be-smaller-or-equal-than-previous'),
                        (cutValue, context) => {
                            const lastYearQuantity: string | number =
                                previousSeasonTreesMap[context.parent.previous_farm_season_field_tree_id]?.quantity;
                            return Number(cutValue) <= Number(lastYearQuantity);
                        },
                    ),
            }),
        }),
    };
};

export const useAgroforestryCardFormValues = ({ field }: AgroforestryCardFormValuesPropsT) => {
    const previousSeasonTreesMap = useMemo(
        () =>
            Object.fromEntries(
                field?.previous_farm_season_field?.agroforestry?.map((agroforestry) => [
                    agroforestry.id,
                    agroforestry,
                ]) || [],
            ),
        [field?.previous_farm_season_field?.agroforestry],
    );

    const agroforestryCardFormValues: AgroforestryCardFormValuesT[] = [];

    field?.agroforestry?.forEach((tree) => {
        agroforestryCardFormValues.push({
            id: tree.id,
            diameter: tree.diameter || '',
            quantity: tree.quantity || '',
            quantity_per_ha: tree.quantity_per_ha || '',
            tree_type_id: tree.tree_type_id || '',
            previous_farm_season_field_tree_id: tree.previous_farm_season_field_tree_id,
            cutCount:
                Number(previousSeasonTreesMap[tree.previous_farm_season_field_tree_id || '']?.quantity) -
                Number(tree.quantity),
        });
    });

    if (agroforestryCardFormValues.length === 0) {
        if (field?.previous_farm_season_field?.agroforestry?.length) {
            field.previous_farm_season_field.agroforestry.forEach((previousTree) =>
                agroforestryCardFormValues.push({
                    diameter: '',
                    quantity: '',
                    quantity_per_ha: '',
                    tree_type_id: previousTree.tree_type_id || '',
                    previous_farm_season_field_tree_id: previousTree.id,
                    cutCount: '',
                }),
            );
        } else {
            agroforestryCardFormValues.push({
                tree_type_id: '',
                diameter: '',
                quantity: '',
                cutCount: '',
                quantity_per_ha: '',
            });
        }
    }

    const getAgroforestryValuesToSubmit = (treesValues: AgroforestryCardFormValuesT[]) => {
        const agroforestryNewValues: PostFarmSeasonFieldAgroforestryValuesDataT['agroforestryValues'] = [];
        treesValues.forEach((tree) => {
            const previousSeasonTree = previousSeasonTreesMap[tree.previous_farm_season_field_tree_id ?? ''];
            const quantity = previousSeasonTree
                ? Number(previousSeasonTree.quantity) - Number(tree.cutCount)
                : Number(tree.quantity);

            agroforestryNewValues.push({
                id: tree?.id,
                tree_type_id: Number(tree.tree_type_id),
                diameter: tree.diameter,
                quantity: String(quantity),
                quantity_per_ha: String(Math.floor(quantity / Number(field?.area))),
                previous_farm_season_field_tree_id: tree.previous_farm_season_field_tree_id,
            });
        });

        return agroforestryNewValues;
    };
    return { agroforestryCardFormValues, previousSeasonTreesMap, getAgroforestryValuesToSubmit };
};
