import { makeStyles } from 'tss-react/mui';

// Temporary popup style to test field overview
const useMapPopupStyle = makeStyles()(() => ({
    MapPopup: {
        '& > .mapboxgl-popup-content': {
            padding: '0',
            background: 'transparent',
            boxShadow: 'none',
        },
        '& > .mapboxgl-popup-tip': {
            display: 'none',
        },
    },
}));

export default useMapPopupStyle;
