import { makeStyles } from 'tss-react/mui';

const highlight_left_bar_width = 2;

const useFieldPopupItemStyle = makeStyles()(({ palette }) => ({
    linkIcon: {
        padding: '0 2px',
        marginLeft: '4px',
        color: palette.secondary.main,
        width: '16px',
    },
    fieldInfo: {
        padding: '4px',
        margin: `0 ${highlight_left_bar_width}px`,
        marginBottom: '2px',

        '&.is-highlight': {
            margin: 0,
            background: palette.spanishWhite.light,
            borderRadius: '4px',
            position: 'relative',
            borderLeft: `${highlight_left_bar_width}px solid ${palette.secondary.main}`,
            '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: `${highlight_left_bar_width}px`,
                backgroundColor: palette.spanishWhite.light,
                width: `${highlight_left_bar_width}px`,
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
            },
        },
    },
    characteristicsIconsGroup: {
        lineHeight: '16px',
        marginTop: '4px',
        '&>*': {
            marginRight: '4px',
        },
        '& .fill-green': {
            color: '#2D9F40',
            fill: '#2D9F40',
        },
        '& .fill-blue': {
            color: '#4599DF',
            fill: '#4599DF',
        },
    },
    permanentContainer: {
        padding: '4px',
    },
}));

export default useFieldPopupItemStyle;
