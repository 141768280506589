import { TableColumnsT, TableRowsT } from '@components/Table/TableViewTypes';
import TableView from '@components/Table/TableView';
import findById from '@utils/findById';
import { useTranslation } from 'react-i18next';
import Card from '@components/CardBasic/CardBasic';
import { useTableViewCellStyle } from '@components/Table/TableView.style';

import { CardGeneralPropsT } from './CardGeneral.types';

const CardGeneral = ({ pHList, tillageList, soilOrganicMatterList, field }: CardGeneralPropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useTableViewCellStyle();

    /* ------------------------------ Table Config ------------------------------ */
    const rows: TableRowsT<'label' | 'value'> = [
        {
            label: t('pages.farmer-data.general.cell-organic-matter'),
            value: findById(field.soil_organic_matter_id ?? 0, soilOrganicMatterList)?.name,
        },
        {
            label: t('pages.farmer-data.general.cell-acidity'),
            value: findById(field.soil_acidity_id ?? 0, pHList)?.name,
        },
        {
            label: t('pages.farmer-data.general.cell-tillage-practice'),
            value: t(findById(field.tillage_id ?? 0, tillageList)?.translation_slug ?? ''),
        },
    ];
    const columns: TableColumnsT<'label' | 'value'> = [
        { field: 'label' },
        { field: 'value', cellClassName: classes.boldAndHalf },
    ];
    /* -------------------------------------------------------------------------- */

    return (
        <Card color="secondary" title={t('pages.farmer-data.general.title')}>
            <TableView color="secondary" columns={columns} rows={rows} size="small" />
        </Card>
    );
};

export default CardGeneral;
