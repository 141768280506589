import { UserT } from '@reducers/AuthReducer/AuthReducerTypes';
import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

import { FarmSeasonLivestockT } from './GetFarmSeasonLivestocksTypes';

export const POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_LOADING = 'POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_LOADING';
export const POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_FAILED = 'POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_FAILED';
export const POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS = 'POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS';

export type PostFarmSeasonLivestocksSubscriptionDataT = {
    livestocks: FarmSeasonLivestockT[];
};

export type PostFarmSeasonLivestocksSubscriptionResponseDataT = {
    livestocks: FarmSeasonLivestockT[];
    farmSeason: FarmSeasonT;
    user: UserT;
    eligibility_status: string;
};

export type FarmSeasonLivestocksPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonLivestocksSubscriptionDispatchTypesT>,
) => Promise<PostFarmSeasonLivestocksSubscriptionResponseErrorT>;

export type PostFarmSeasonLivestocksSubscriptionResponseErrorT = {
    message?: string;
    list?: string[];
    error?: any; // eslint-disable-line
};

export type PostFarmSeasonLivestocksSubscriptionLoadingT = {
    type: typeof POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_LOADING;
};

export type PostFarmSeasonLivestocksSubscriptionFailedT = {
    type: typeof POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_FAILED;
    error: PostFarmSeasonLivestocksSubscriptionResponseErrorT;
};

export type PostFarmSeasonLivestocksSubscriptionSuccessT = {
    type: typeof POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS;
    payload: PostFarmSeasonLivestocksSubscriptionResponseDataT;
};

export type PostFarmSeasonLivestocksSubscriptionDispatchTypesT =
    | PostFarmSeasonLivestocksSubscriptionLoadingT
    | PostFarmSeasonLivestocksSubscriptionFailedT
    | PostFarmSeasonLivestocksSubscriptionSuccessT;
