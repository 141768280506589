import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';

import { Prompt } from '@components/Prompt/Prompt';

import useSelectFieldsStyle from './SelectFieldsView.style';
import { SelectFieldsViewPropsT } from './SelectFieldsTypes';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import { ModalWarning } from '@soil-capital/ui-kit';
import FieldAmountLabel from '@components/FieldsAmountLabelDeprecated/FieldAmountLabelDeprecated';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const SelectFieldsView = ({
    t,
    formik,
    newSelectedGroup,
    groupedFieldIndex,
    confirmGroupedFields,
    selectedFieldDelete,
    selectRemainingFields,
    modalFieldAlreadyAssociated,
    nothingToSave,
    canEdit = false,
}: SelectFieldsViewPropsT): JSX.Element => {
    const { classes } = useSelectFieldsStyle();

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!nothingToSave}
            />
            <Typography className={classes.groupedFieldTitle}>
                {t('pages.installationtasks.history.pasture.select-fields.summary-title', {
                    x: groupedFieldIndex,
                    year: formik.values.year_of_change,
                })}
            </Typography>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.history.pasture.select-fields.title">
                    Veuillez sélectionner <span className={classes.titleDark}>sur la carte</span> les champs avec ce
                    couvert végétal
                </Trans>
            </Typography>

            <SelectedFieldsContainer
                disabled={!canEdit}
                headerText={<FieldAmountLabel fields={newSelectedGroup} />}
                handleRemoveSelectedPolygon={selectedFieldDelete}
            />

            <TwoButtonFooterView
                leftOnClick={selectRemainingFields}
                leftText={canEdit ? t('constants.select-remaining-fields') : ''}
                rightButtonOverride={
                    canEdit ? (
                        <LoadingButton
                            disabled={newSelectedGroup.length === 0}
                            onClick={() => confirmGroupedFields()}
                            text={t('constants.confirm')}
                        />
                    ) : null
                }
            />

            <ModalWarning
                controller={modalFieldAlreadyAssociated}
                confirmMessage={t(
                    'pages.installationtasks.history.pasture.select-fields.do-you-wand-to-associate-with-this-year',
                )}
                description={t('pages.installationtasks.history.pasture.select-fields.field-already-associated')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
            />
        </div>
    );
};

export default SelectFieldsView;
