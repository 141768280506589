import MenuBar from '@components/MenuBar/MenuBar';
import { Card, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import useAppNavigate from '@hooks/useAppNavigate';
import RadioButton from '@components/RadioButton/RadioButton';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';
import useLoader from '@hooks/useLoader';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import { LoadingButton, useModal } from '@soil-capital/ui-kit';
import useAppDispatch from '@hooks/useAppDispatch';
import { DeleteFarmSeasonOperation } from '@actions/FarmSeasonOperation/DeleteFarmSeasonOperation';
import { Prompt } from '@components/Prompt/Prompt';

import OperationCardForm from '../components/OperationCardForm/OperationCardForm';

import useSowingViewStyle from './SowingView.style';
import { SowingViewPropsT } from './SowingTypes';
import { ModalWarning } from '@soil-capital/ui-kit';

const SowingView = ({
    t,
    currentCrop,
    currentTillage,
    sowingMachineries,
    sowingOperationForm,
    setHasDontHaveAnyError,
    operationsDataLoading,
    submitted,
    farmId,
    farmSeasonId,
    canEdit = false,
}: SowingViewPropsT): JSX.Element => {
    const { classes } = useSowingViewStyle();
    const navigate = useAppNavigate();
    const modalDeleteOperation = useModal();
    const dispatch = useAppDispatch();

    const { containerRef: loaderRef } = useLoader(operationsDataLoading);

    return (
        <>
            <div ref={loaderRef} className={classes.container}>
                <Prompt
                    messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                    messageText={t('modal.navigation-prompt-if-unsaved.text')}
                    when={sowingOperationForm.dirty && !submitted}
                />
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={`${t(currentCrop?.crop?.translation_slug || '')} - ${t(
                        currentTillage?.translation_slug,
                    )}`}
                    onArrowBack={() => navigate(-1)}
                    onClose={() => navigate(-1)}
                />
                <Typography className={classes.mb20} color="secondary" variant="h4">
                    {t('pages.operations.sowing.title')}
                </Typography>
                <Typography className={classes.mb20} color="secondary" variant="body1">
                    {t('pages.operations.sowing.description')}
                    <HelpButtonView id="pages.operations.seeds.description" />
                </Typography>
                <div className={sowingOperationForm.values.dont_have_any ? classes.disabledZone : ''}>
                    {/* Sowing Passages card */}
                    {sowingMachineries?.length > 0 && (
                        <OperationCardForm
                            disabled={!canEdit}
                            form={sowingOperationForm}
                            machineries={sowingMachineries}
                            shouldAutoFocus={
                                !operationsDataLoading && sowingOperationForm.values.dont_have_any === false
                            }
                        />
                    )}

                    {/* Seed weight card */}
                    <Card className={classes.card}>
                        <Typography className={classes.cardTitle} color="primary" variant="h6">
                            {t('pages.operations.sowing.seed-title')}
                            <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Grid spacing={2} container>
                            <Grid md={6} xs={12} item>
                                <RadioButton
                                    disabled={!canEdit}
                                    checked={sowingOperationForm.values.seed_origin_id === '1'}
                                    error={Boolean(
                                        sowingOperationForm.touched.seed_origin_id &&
                                            sowingOperationForm.errors.seed_origin_id,
                                    )}
                                    label={t('pages.operations.sowing.seed-origin.farm')}
                                    loading={operationsDataLoading}
                                    value="1"
                                    onClick={(v) => sowingOperationForm.setFieldValue('seed_origin_id', v)}
                                />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <RadioButton
                                    disabled={!canEdit}
                                    checked={sowingOperationForm.values.seed_origin_id === '2'}
                                    error={Boolean(
                                        sowingOperationForm.touched.seed_origin_id &&
                                            sowingOperationForm.errors.seed_origin_id,
                                    )}
                                    label={t('pages.operations.sowing.seed-origin.commercial')}
                                    loading={operationsDataLoading}
                                    value="2"
                                    onClick={(v) => sowingOperationForm.setFieldValue('seed_origin_id', v)}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                {sowingOperationForm.touched.seed_origin_id &&
                                    sowingOperationForm.errors.seed_origin_id && (
                                        <ErrorMessageView message={t(sowingOperationForm.errors.seed_origin_id)} />
                                    )}
                            </Grid>
                        </Grid>
                        <TextField
                            InputProps={{
                                disabled: !canEdit,
                                endAdornment: t('constants.unit.kilo-per-ha', {
                                    count: Number(sowingOperationForm.values.quantity),
                                }),
                            }}
                            className={classes.inputWeight}
                            fullWidth={false}
                            inputProps={{
                                step: 1,
                            }}
                            name="quantity"
                            type="number"
                            value={sowingOperationForm.values.quantity}
                            variant="outlined"
                            onChange={(e) => {
                                setHasDontHaveAnyError(false);

                                if (Number(e.target.value)) {
                                    e.target.value = (Math.floor(Number(e.target.value) * 1000) / 1000)?.toString();
                                }
                                sowingOperationForm.handleChange(e);
                            }}
                            onFocus={(e) => e.target.select()}
                            onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        />
                        {sowingOperationForm.errors.quantity && sowingOperationForm.touched.quantity && (
                            <FormHelperText error>{sowingOperationForm.errors.quantity}</FormHelperText>
                        )}
                    </Card>
                </div>

                {/* No sowing checkbox */}
                {canEdit && (
                    <div className={classes.noSowingCheckbox}>
                        <LabelCheckboxView
                            disabled={!canEdit}
                            border="dotted"
                            checked={sowingOperationForm.values.dont_have_any}
                            error={
                                sowingOperationForm.touched.dont_have_any && !!sowingOperationForm.errors.dont_have_any
                            }
                            helperText={
                                sowingOperationForm.touched.dont_have_any
                                    ? sowingOperationForm.errors.dont_have_any
                                    : undefined
                            }
                            label={t('pages.operations.sowing.no-operation')}
                            name="dont_have_any"
                            onChange={(e) => {
                                setHasDontHaveAnyError(false);
                                sowingOperationForm.handleChange(e);
                            }}
                        />
                    </div>
                )}

                {/* Register button */}
                <TwoButtonFooterView
                    leftOnClick={() =>
                        modalDeleteOperation.openModal(async () => {
                            if (!currentCrop.id) {
                                throw Error('currentCrop.id is required to call "DeleteFarmSeasonOperation"');
                            }
                            await dispatch(
                                DeleteFarmSeasonOperation(
                                    farmId,
                                    farmSeasonId,
                                    'sowing',
                                    currentTillage.id,
                                    currentCrop.id,
                                ),
                            );
                            navigate(-1);
                        })
                    }
                    leftText={canEdit ? t('constants.delete-operation') : undefined}
                    rightButtonOverride={
                        canEdit ? (
                            <LoadingButton
                                loading={sowingOperationForm.isSubmitting}
                                onClick={() => sowingOperationForm.handleSubmit()}
                                text={t('constants.save')}
                            />
                        ) : null
                    }
                />
            </div>
            <ModalWarning
                controller={modalDeleteOperation}
                description={t('modal.delete-operation.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('modal.warning.title')}
            />
        </>
    );
};

export default SowingView;
