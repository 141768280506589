import { makeStyles } from 'tss-react/mui';

const useCropListStepViewStyle = makeStyles()((theme) => ({
    container: {},
    darkerTitle: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
    button: {
        marginBottom: '20px',
    },
    titleIcon: {
        marginLeft: '8px',
        height: '20px',
        width: '20px',
    },
    cropsSuggestionDoneText: {
        display: 'flex',
        height: '46px', // button height which is replaced by this text
        alignItems: 'center',
        marginBottom: '20px',
    },
}));

export default useCropListStepViewStyle;
