import { makeStyles } from 'tss-react/mui';

const useEditFieldViewStyle = makeStyles()((theme) => ({
    fieldName: {
        color: theme.palette.driftwood.light,
        fontSize: '1.5rem',
    },
    fieldNameContainer: {
        marginBottom: '20px',
    },
    fieldNameEditIcon: {
        fill: `${theme.palette.driftwood.light} !important`,
        height: '20px !important',
        width: '20px !important',
        verticalAlign: 'text-top',
        cursor: 'pointer',
    },
    editableInputIconsCancel: {
        '& svg': {
            height: '.8rem',
            width: '.8rem',
        },
    },
    editableInputIconsSave: {
        marginLeft: '-7px',
        color: theme.palette.success.main,
        '& svg': {
            height: '.8rem',
            width: '.8rem',
        },
    },
    editableInputClass: {
        fontSize: '1.5rem',
    },
    checkboxContainer: {
        padding: '5px',
    },
    divider: {
        borderTop: `1px solid ${theme.palette.secondary.light}`,
    },
    gridRow: {
        marginTop: '10px',
    },
    permanentTillageCheckbox: {
        marginBottom: '15px',
    },
}));

export default useEditFieldViewStyle;
