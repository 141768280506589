/* eslint-disable @typescript-eslint/no-explicit-any */
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';
import { useContext, useEffect } from 'react';
import useAppNavigate from '@hooks/useAppNavigate';

import { AgroforestryContext } from '../AgroforestryTaskContext/AgroforestryTaskContext';
import { AgroforestryContextT } from '../AgroforestryTaskContext/AgroforestryTaskContextType';

import SelectedFieldsListView from './SelectedFieldsListView';

const SelectedFieldsList = (): JSX.Element => {
    const { pushStep, t, setSelectedField, fieldsList, thisMap } =
        useContext<AgroforestryContextT>(AgroforestryContext);

    const navigate = useAppNavigate();

    const onConfirmSelectedField = () => {
        navigate('..');
    };

    const onFieldClick = (field: any) => {
        setSelectedField(field);
        pushStep(3);
    };

    useEffect(() => {
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap?.selectedPolygons ? [...thisMap.selectedPolygons] : [],
        });
    }, []);

    const agroforestryFields = fieldsList?.filter((field) => field.has_agroforestry);

    const getTotalTree = (field: FarmSeasonFieldT): number | void => {
        // TODO: adapt next line once the quantity type is return from the server as a number and not a string
        const num = field.agroforestry?.reduce((totalTree: number, treeType) => totalTree + +treeType.quantity, 0);

        return num;
    };

    return (
        <SelectedFieldsListView
            agroforestryFields={agroforestryFields}
            getTotalTree={getTotalTree}
            t={t}
            onConfirmSelectedField={onConfirmSelectedField}
            onFieldClick={onFieldClick}
        />
    );
};

export default SelectedFieldsList;
