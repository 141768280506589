import { Card, LinearProgress } from '@mui/material';

import { CardFlowPropsT } from './CardFlowTypes';
import useCardFlowStyle from './CardFlow.style';

const CardFlow = ({ children, progress = 50 }: CardFlowPropsT): JSX.Element => {
    const { classes } = useCardFlowStyle();

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <LinearProgress
                    className={classes.progressBar}
                    color="primary"
                    value={progress}
                    variant="determinate"
                />
                {children}
            </Card>
        </div>
    );
};

export default CardFlow;
