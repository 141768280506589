import { makeStyles } from 'tss-react/mui';

const useDndListItemViewStyle = makeStyles<{ opacity: number; canDrag: boolean }>()((theme, { opacity, canDrag }) => ({
    relative: {
        position: 'relative',
        opacity,
        cursor: canDrag ? 'move' : 'not-allowed',
    },
    container: {
        backgroundColor: theme.palette.spanishWhite.light,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '12px',
        borderRadius: theme.spacing(2),
        marginBottom: '7px',
        position: 'relative',
        zIndex: 102,
        overflow: 'hidden',
    },
    icon: {
        fontSize: '1.3em',
        marginRight: '10px',
        color: canDrag ? theme.palette.secondary.main : theme.palette.grey[500],
    },
    text: {
        marginRight: 'auto',
    },
    iconDelete: {
        fontSize: '18px',
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        marginRight: '5px',
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
    link: {
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        bottom: '-30px',
        right: '-40px',
        height: '54px',
        width: '100px',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        borderRight: `2px solid ${theme.palette.secondary.main}`,
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    assocIcon: {
        fontSize: '20px',
        color: '#FFF',
    },
    assocText: {
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        bottom: '-11px',
        right: '-125px',
    },
    topLine: {
        position: 'absolute',
        top: -6,
        left: 0,
        height: '2px',
        width: '100%',
        background: theme.palette.secondary.main,
    },
    bottomLine: {
        position: 'absolute',
        bottom: -6,
        left: 0,
        height: '2px',
        width: '100%',
        background: theme.palette.secondary.main,
    },
}));

export default useDndListItemViewStyle;
