import { api } from './api.service';
import { GetCertificateGeneratedContextRequestT, GetCertificateGeneratedContextResponseT } from './celebration.types';

export const celebrationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCertificatesGeneratedContext: build.query<
            GetCertificateGeneratedContextResponseT,
            GetCertificateGeneratedContextRequestT
        >({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/celebration/certificates-generated/context`,
                method: 'GET',
            }),
        }),
    }),
});
