import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    MapPopupContentPropsT,
    MapPopupContextT,
    MapPopupLogicPropsT,
    MapPopupRefT,
    OpenedMapPopupPropsT,
    xyZoneT,
    zoneNameT,
} from './MapPopup.types';
import MapGL from '@urbica/react-map-gl/src/components/MapGL';
import turfCentroid from '@turf/centroid';
import turfDistance from '@turf/distance';
import useWindowSize from '@hooks/useResizeWindow';
import { geoJsonFeatureT } from '../../MapTypes';
import { useSelector } from 'react-redux';
import selectMap from '@store/selectors/selectMap';
import MapService from '@services/mapService/mapService';

const useMapPopupLogic = ({ mapPopupRef, mapRef }: MapPopupLogicPropsT) => {
    const ANIMATION_DURATION = 150;
    const DELAY_OPEN_POPUP_TIMEOUT = 20;
    const DELAY_CLOSE_POPUP_TIMEOUT = 250;
    const POLYGON_POPUP_OVERLAP = 5;

    const [openedProps, setOpenedProps] = useState<OpenedMapPopupPropsT | null>(null);
    const [closingProps, setClosingProps] = useState<OpenedMapPopupPropsT | null>(null);
    const closingPopupIdRef = useRef<number | string | null>(null);
    const closingPromiseRef = useRef<Promise<void> | null>(null);
    const openingPopupIdRef = useRef<string | number | null>(null);
    const popupHoveredRef = useRef(false);
    const forceClosedPopupIdRef = useRef<number | string | null>(null);
    const [computedGridZones, setComputedGridZones] = useState<Record<zoneNameT, xyZoneT> | null>(null);

    const mapState = useSelector(selectMap());
    const mapPopupContent = mapState?.popupContent as
        | ((props: MapPopupContentPropsT) => JSX.Element | null)
        | false
        | undefined;

    const mapPopupContext: MapPopupContextT = {
        creatablePolygons: mapState?.creatablePolygons ?? null,
    };

    const [windowX, windowY] = useWindowSize();

    useEffect(() => {
        if (!mapRef.current) {
            return; // should not happen
        }

        // Compute grid zones on load + on window resize
        setComputedGridZones(_computeGridZones(mapRef.current));
    }, [windowX, windowY]);

    const [displayPopup, setDisplayPopup] = useState(true);
    /** Render again the popup (remove then display after 5ms) */
    const updatePopupChanges = () => {
        setDisplayPopup(false);
        setTimeout(() => setDisplayPopup(true), 5);
    };

    /* ------------------------ Functions to handle popup ----------------------- */
    const lastFunctionTimeout = useRef<NodeJS.Timeout | null>(null);
    /** Delay the function + override the execution if another function delayed is used */
    const _delayFunction = useCallback(
        <T extends unknown[]>(cb: (...args: T) => void, delay: number) => {
            return (...a: T) => {
                clearTimeout(lastFunctionTimeout.current ?? undefined);
                lastFunctionTimeout.current = setTimeout(() => cb(...a), delay);
            };
        },
        [lastFunctionTimeout],
    );

    /**
     * Get in which zone of the canva the polygon is located + get the opposite direction point (target)
     */
    const _getPolygonZone = useCallback(
        (map: mapboxgl.Map, feature: geoJsonFeatureT): { name: zoneNameT; targetPoint: { x: number; y: number } } => {
            const defaultZoneName: zoneNameT = 'topRight';
            const defaultZone = { name: defaultZoneName, targetPoint: { x: 1000, y: 0 } };
            if (!computedGridZones) {
                // initialization error
                return defaultZone;
            }

            // Get centroid of polygon hovered.
            const polygonLngLatCenter = turfCentroid(feature);
            const { x: xPx, y: yPx } = map.project([
                polygonLngLatCenter.geometry.coordinates[0],
                polygonLngLatCenter.geometry.coordinates[1],
            ]);

            // get canvas zone on wich the centroid point is.
            const gridZone = Object.entries(computedGridZones).find(([, xyBox]) => {
                if (xPx > xyBox.minX && xPx < xyBox.maxX && yPx > xyBox.minY && yPx < xyBox.maxY) {
                    return true;
                }
                return false;
            });
            if (!gridZone) {
                // error: Mouse doesn't fit the canva?
                return defaultZone;
            }

            const [zoneName, { targetX, targetY }] = gridZone as [zoneNameT, { targetX: number; targetY: number }];
            return { name: zoneName, targetPoint: { x: targetX, y: targetY } };
        },
        [computedGridZones],
    );

    /** return some popup properties (anchor, offset) depending on where the mouse is placed in the canva (related to computed zones) */
    const _getComputedZonePopupProps = useCallback((mapZoom: number, mouseZoneName: zoneNameT) => {
        const offset = -POLYGON_POPUP_OVERLAP;

        let computedAnchor: OpenedMapPopupPropsT['anchor'] = undefined;
        let computedOffset: [number, number] = [0, 0];
        switch (mouseZoneName) {
            case 'left':
                computedAnchor = 'left';
                computedOffset = [-offset, 0];
                break;
            case 'topLeft':
                computedAnchor = 'top-left';
                computedOffset = [-offset, -offset];
                break;
            case 'top':
                computedAnchor = 'top';
                computedOffset = [0, -offset];
                break;
            case 'topRight':
                computedAnchor = 'top-right';
                computedOffset = [offset, -offset];
                break;
            case 'right':
                computedAnchor = 'right';
                computedOffset = [offset, 0];
                break;
            case 'bottomRight':
                computedAnchor = 'bottom-right';
                computedOffset = [offset, offset];
                break;
            case 'bottom':
                computedAnchor = 'bottom';
                computedOffset = [0, offset];
                break;
            case 'bottomLeft':
                computedAnchor = 'bottom-left';
                computedOffset = [-offset, offset];
                break;
        }
        return { computedAnchor, computedOffset };
    }, []);

    /** get the feature point the closest to a target point -> the feature point on which the popup should display  */
    const _getComputedPopupPosition = useCallback(
        (feature: geoJsonFeatureT, targetPoint: mapboxgl.LngLat): { lng: number; lat: number } => {
            let nearestCoord: [number, number] | null = null;
            let smallestDist = Infinity;
            feature.geometry.coordinates?.[0].forEach((coord) => {
                const dist = turfDistance(coord, targetPoint.toArray());
                if (dist < smallestDist) {
                    nearestCoord = [coord[0], coord[1]];
                    smallestDist = dist;
                }
            });
            if (nearestCoord === null) {
                // should not happen
                return { lat: 0, lng: 0 };
            }
            return { lng: nearestCoord[0], lat: nearestCoord[1] };
        },
        [],
    );

    /**
     * Split the map into zones so we can check in wich zone the mouse is + where is the opposite direction (target).
     * Each time the map sizes change, the grid zones have to be computed.
     */
    const _computeGridZones = useCallback((map: MapGL): Record<zoneNameT, xyZoneT> => {
        const canva = map?.getMap().getCanvas();
        const canvaSize = { x: Number(canva.style.width.slice(0, -2)), y: Number(canva.style.height.slice(0, -2)) };

        // Compute gridZones on canva
        const xZoneWidth = canvaSize.x / 3;
        const yZoneHeight = canvaSize.y / 3;
        const leftInfinite = -10000;
        const rightInfinite = 10000;
        const topInfinite = -10000;
        const bottomInfinite = 10000;
        const topYs = { minY: topInfinite, maxY: yZoneHeight };
        const middleYs = { minY: yZoneHeight, maxY: 2 * yZoneHeight };
        const bottomYs = { minY: 2 * yZoneHeight, maxY: bottomInfinite };
        const rightXs = { minX: 2 * xZoneWidth, maxX: rightInfinite };
        const leftXs = { minX: leftInfinite, maxX: xZoneWidth };
        const middleXs = { minX: xZoneWidth, maxX: 2 * xZoneWidth };

        const GridZones = {
            bottomLeft: { ...bottomYs, ...leftXs, targetX: 3 * xZoneWidth, targetY: 0 },
            bottom: { ...bottomYs, ...middleXs, targetX: 1.5 * xZoneWidth, targetY: 0 },
            bottomRight: { ...bottomYs, ...rightXs, targetX: 0, targetY: 0 },
            topLeft: { ...topYs, ...leftXs, targetX: 3 * xZoneWidth, targetY: 3 * yZoneHeight },
            top: { ...topYs, ...middleXs, targetX: 1.5 * xZoneWidth, targetY: 3 * yZoneHeight },
            topRight: { ...topYs, ...rightXs, targetX: 0, targetY: 3 * yZoneHeight },
            left: {
                ...middleYs,
                minX: leftInfinite,
                maxX: canvaSize.x / 2,
                targetX: 3 * xZoneWidth,
                targetY: 1.5 * yZoneHeight,
            },
            right: {
                ...middleYs,
                minX: canvaSize.x / 2,
                maxX: rightInfinite,
                targetX: 0,
                targetY: 1.5 * yZoneHeight,
            },
        };

        return GridZones;
    }, []);

    const closePopup = async () => {
        closingPopupIdRef.current = openedProps?.popupId ?? closingProps?.popupId ?? null;
        // Wait some ms to be able to block the close depending on where the mouse go (popup -> field owner || field -> popup)
        await new Promise<void>((res) => setTimeout(res, 10));

        if (
            // the popup is already closed
            openedProps === null ||
            // field -> popup shouldn't close the popup
            (!forceClosedPopupIdRef.current && popupHoveredRef.current === true)
        ) {
            closingPopupIdRef.current = null;
            return;
        }

        const openedTime = Date.now() - openedProps.timestamp;
        // time needed to close the popup -> animation duration or opened timestamp - now if the animation isn't finished.
        const timeToClose = openedTime > ANIMATION_DURATION ? ANIMATION_DURATION : openedTime;
        setClosingProps(openedProps);
        setOpenedProps(null);
        const closingPromise = new Promise<void>((res) => {
            setTimeout(() => {
                closingPopupIdRef.current = null;
                closingPromiseRef.current = null;
                setClosingProps(null);
                res();
            }, timeToClose + 20);
        });
        closingPromiseRef.current = closingPromise;

        MapService.observer.notify('closeMapPopup', {
            timeOpened: Math.round(openedTime / 1000),
            closingAction: forceClosedPopupIdRef.current ? 'closing button' : 'stop hovering',
        });

        return closingPromise;
    };

    const openPopupOverFeature: MapPopupRefT['openPopupOverFeature'] = async ({ feature, popupId, map }) => {
        // Don't try to open the same popup already opened or the popup already opening
        if (openedProps?.popupId === popupId || openingPopupIdRef.current === popupId) {
            return;
        }
        openingPopupIdRef.current = popupId;

        // MapPopup already closing
        if (closingPromiseRef.current && closingPopupIdRef.current !== popupId) {
            await closingPromiseRef.current;
        } else if (openedProps) {
            // MapPopup opened ? must be closed first.
            await closePopup();
        }

        // don't open MapPopup if it's already opening on another place
        if (openingPopupIdRef.current !== popupId) {
            return;
        }

        if (!computedGridZones) {
            // Bad initialization, should not happen
            return;
        }

        // Compute positions (popup position, anchor, ...)
        /* -------------------------------------------------------------------------- */
        const mouseZone = _getPolygonZone(map, feature);
        const targetPoint = map.unproject([mouseZone.targetPoint.x, mouseZone.targetPoint.y] as [number, number]);
        const { lat, lng } = _getComputedPopupPosition(feature, targetPoint);

        const zoom = map.getZoom();
        const { computedAnchor, computedOffset } = _getComputedZonePopupProps(zoom, mouseZone.name);

        setOpenedProps({
            lng,
            lat,
            anchor: computedAnchor,
            offset: computedOffset,
            popupId,
            timestamp: Date.now(),
        });
        updatePopupChanges();
        openingPopupIdRef.current = null;
        // Reset the previous force closed popup (to be able to force close this one)
        forceClosedPopupIdRef.current = null;
    };

    const handleClosePopupFromContent = () => {
        forceClosedPopupIdRef.current = openedProps?.popupId ?? null;
        closePopup();
    };

    const delayedOpenPopupOverFeature = _delayFunction(openPopupOverFeature, DELAY_OPEN_POPUP_TIMEOUT);
    const delayedClosePopup = _delayFunction(closePopup, DELAY_CLOSE_POPUP_TIMEOUT);

    useImperativeHandle(
        mapPopupRef,
        () => ({
            openPopupOverFeature: delayedOpenPopupOverFeature,
            closePopup: delayedClosePopup,
        }),
        [delayedOpenPopupOverFeature, delayedClosePopup],
    );

    return {
        openedProps,
        displayPopup,
        closingProps,
        ANIMATION_DURATION,
        popupHoveredRef,
        delayedClosePopup,
        MapPopupContent: !mapPopupContent ? () => null : mapPopupContent,
        polygonId: openedProps?.popupId ?? closingProps?.popupId ?? null,
        mapContext: mapPopupContext,
        handleClosePopupFromContent,
    };
};

export default useMapPopupLogic;
