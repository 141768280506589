import { Dispatch } from '@reduxjs/toolkit';

export const LIVESTOCKS_GET_LOADING = 'LIVESTOCKS_GET_LOADING';
export const LIVESTOCKS_GET_SUCCESS = 'LIVESTOCKS_GET_SUCCESS';
export const LIVESTOCKS_GET_FAILED = 'LIVESTOCKS_GET_FAILED';
export const STATIC_LOAD = 'STATIC_LOAD';

export type Livestocks = {
    id: number;
    name: string;
    translation_slug: string;
};

export type LivestocksGetCallBackT = (
    dispatch: Dispatch<LivestocksDispatchTypesT>,
) => Promise<LivestocksGetResponseFailedT>;

export type LivestocksGetResponseSuccessT = Livestocks[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LivestocksGetResponseFailedT = any;

export type LivestocksGetLoadingT = {
    type: typeof LIVESTOCKS_GET_LOADING;
};

export type LivestocksGetSuccessT = {
    type: typeof LIVESTOCKS_GET_SUCCESS;
    payload: Livestocks[];
};

export type LivestocksGetFailedT = {
    type: typeof LIVESTOCKS_GET_FAILED;
    error: string;
};

export type LivestocksDispatchTypesT = LivestocksGetLoadingT | LivestocksGetSuccessT | LivestocksGetFailedT;
