import { MODAL_SLUG } from '@constants/modalSlugs';

/**
 * Give a string to navigate with navigate({search: '?modal=<slug>'})
 */
const getSearchModalSlug = (modalSlug: MODAL_SLUG) => {
    return `?modal=${modalSlug}`;
};

export default getSearchModalSlug;
