import { ButtonBase, Card, CardContent, FormControlLabel, ListItem } from '@mui/material';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { CheckCircle } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

import useTaskItemStyle from './TaskItem.style';
import { TaskItemViewPropsT } from './TaskItemTypes';

const TaskItemView = ({ additionalContentJSX, label, linkTo, taskDone, disabled }: TaskItemViewPropsT): JSX.Element => {
    const { classes } = useTaskItemStyle({ taskDone, disabled });

    return (
        <NavLink className={classes.navLink} to={linkTo || '/'} onClick={(e) => disabled && e.preventDefault()}>
            <ListItem className={classes.taskItem}>
                <ButtonBase
                    className={classes.taskButton}
                    disabled={disabled || taskDone}
                    focusVisibleClassName={classes.taskFocusVisible}
                >
                    <Card className={classes.taskButtonCard}>
                        <CardContent className={classes.taskButtonCardContent}>
                            <FormControlLabel
                                control={taskDone ? <CheckCircle /> : <CircleUnchecked />}
                                label={label}
                                labelPlacement="end"
                            />
                            {additionalContentJSX}
                        </CardContent>
                    </Card>
                </ButtonBase>
            </ListItem>
        </NavLink>
    );
};

export default TaskItemView;
