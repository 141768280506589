import {
    DELETE_FARM_SEASON_CROP_FAILED,
    DELETE_FARM_SEASON_CROP_LOADING,
    DELETE_FARM_SEASON_CROP_SUCCESS,
} from '@actions/FarmSeasonCropActions/DeleteFarmSeasonCropTypes';
import {
    GET_FARM_SEASON_CROPS_FAILED,
    GET_FARM_SEASON_CROPS_LOADING,
    GET_FARM_SEASON_CROPS_SUCCESS,
} from '@actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import {
    POST_FARM_SEASON_CROPS_FAILED,
    POST_FARM_SEASON_CROPS_LOADING,
    POST_FARM_SEASON_CROPS_SUCCESS,
} from '@actions/FarmSeasonCropActions/PostFarmSeasonCropsTypes';
import {
    POST_FARM_SEASON_CROP_FAILED,
    POST_FARM_SEASON_CROP_LOADING,
    POST_FARM_SEASON_CROP_SUCCESS,
} from '@actions/FarmSeasonCropActions/PostFarmSeasonCropTypes';
import {
    PUT_FARM_SEASON_CROP_FAILED,
    PUT_FARM_SEASON_CROP_LOADING,
    PUT_FARM_SEASON_CROP_SUCCESS,
} from '@actions/FarmSeasonCropActions/PutFarmSeasonCropTypes';
import { POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS } from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsResidueTypes';
import { RESET } from '@reducers/RootTypes';
import { AppActionsT } from '@actions/AppActions/AppActionsTypes';
import { Reducer } from '@reduxjs/toolkit';

import { FarmSeasonCropReducerStateT } from './FarmSeasonCropReducerTypes';

export const defaultFarmSeasonCropState: FarmSeasonCropReducerStateT = {
    loaded: false,
    loading: false,
    error: undefined,
    cropsList: [],
};

const FarmSeasonCropReducer: Reducer<FarmSeasonCropReducerStateT, AppActionsT> = (
    state,
    action,
): FarmSeasonCropReducerStateT => {
    if (!state) {
        return defaultFarmSeasonCropState;
    }

    switch (action.type) {
        case POST_FARM_SEASON_CROPS_LOADING:
        case GET_FARM_SEASON_CROPS_LOADING:
        case POST_FARM_SEASON_CROP_LOADING:
        case PUT_FARM_SEASON_CROP_LOADING:
        case DELETE_FARM_SEASON_CROP_LOADING:
            return {
                ...state,
                loading: true,
            };

        case POST_FARM_SEASON_CROPS_SUCCESS:
        case GET_FARM_SEASON_CROPS_SUCCESS:
        case POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                cropsList: action.payload.crops,
            };

        case POST_FARM_SEASON_CROP_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                cropsList: [...state.cropsList, action.payload],
            };

        case PUT_FARM_SEASON_CROP_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                cropsList: [
                    ...state.cropsList.map((crop) => (crop.id === action.payload.crop.id ? action.payload.crop : crop)),
                ],
            };

        case DELETE_FARM_SEASON_CROP_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                cropsList: [...state.cropsList.filter((crop) => crop.id !== action.payload.id)],
            };

        case POST_FARM_SEASON_CROPS_FAILED:
        case GET_FARM_SEASON_CROPS_FAILED:
        case POST_FARM_SEASON_CROP_FAILED:
        case PUT_FARM_SEASON_CROP_FAILED:
        case DELETE_FARM_SEASON_CROP_FAILED:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error,
            };

        case RESET:
        // TODO add reset crop state when we leave homePage
        case 'AUTH_SET_SELECTED_USER':
            return {
                ...defaultFarmSeasonCropState,
            };

        default:
            return state;
    }
};

export default FarmSeasonCropReducer;
