import {
    FARM_SEASON_ALL_FAILED,
    FARM_SEASON_ALL_LOADING,
    FARM_SEASON_ALL_SUCCESS,
} from '@actions/FarmSeasonActions/FarmSeasonGetAllTypes';
import {
    FARM_SEASON_UPDATE_FAILED,
    FARM_SEASON_UPDATE_LOADING,
    FARM_SEASON_UPDATE_SUCCESS,
} from '@actions/FarmSeasonActions/FarmSeasonUpdateTypes';
import { POST_FARM_SEASON_FIELDS_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTypes';
import { POST_FARM_SEASON_FIELDS_CROPS_SUCCESS } from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsTypes';
import { DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS } from '@actions/FarmSeasonLivestockActions/DeleteFarmSeasonLivestocksTypes';
import { POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillageTypes';
import { RESET } from '@reducers/RootTypes';
import { POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsDrainageTypes';
import { POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicTypes';
import { POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilOrganicMatterTypes';
import { POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilOrganicCarbonTypes';
import { POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilAcidityTypes';
import { POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsIrrigationTypes';
import { POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldIrrigationValuesTypes';
import { POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldAgroforestryValuesTypes';
import { POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsAgroforestryTypes';
import { POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsCoverCropHistoryTypes';
import { POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicFertilizationHistoryTypes';
import {
    POST_FARM_SEASON_INSTALLATION_CONFIRM_FAILED,
    POST_FARM_SEASON_INSTALLATION_CONFIRM_LOADING,
    POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS,
} from '@actions/FarmSeasonActions/FarmSeasonPostInstallationConfirmTypes';
import { POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsPastureHistoryTypes';
import { POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTillagePracticeHistoryTypes';
import { POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsCoverCropTypes';
import { POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS } from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsResidueTypes';
import { DELETE_FARM_SEASON_CROP_SUCCESS } from '@actions/FarmSeasonCropActions/DeleteFarmSeasonCropTypes';
import { DELETE_FARM_SEASON_FIELD_SUCCESS } from '@actions/FarmSeasonFieldActions/DeleteFarmSeasonFieldTypes';
import {
    FARM_SEASON_FINALIZE_FAILED,
    FARM_SEASON_FINALIZE_LOADING,
    FARM_SEASON_FINALIZE_SUCCESS,
} from '@actions/FarmSeasonActions/FarmSeasonFinalizeTypes';
import {
    FARM_SEASON_VALIDATE_FAILED,
    FARM_SEASON_VALIDATE_LOADING,
    FARM_SEASON_VALIDATE_SUCCESS,
} from '@actions/FarmSeasonActions/FarmSeasonValidateTypes';
import { HAS_PAID_SECOND_SUCCESS } from '@actions/AuthActions/HasPaidSecondActionsTypes';
import {
    FARM_SEASON_VALIDATE_BY_AGRONOMIST_FAILED,
    FARM_SEASON_VALIDATE_BY_AGRONOMIST_LOADING,
    FARM_SEASON_VALIDATE_BY_AGRONOMIST_SUCCESS,
} from '@actions/FarmSeasonActions/FarmSeasonValidateByAgronomistTypes';
import {
    FARM_SEASON_INVALIDATE_BY_AGRONOMIST_FAILED,
    FARM_SEASON_INVALIDATE_BY_AGRONOMIST_LOADING,
    FARM_SEASON_INVALIDATE_BY_AGRONOMIST_SUCCESS,
} from '@actions/FarmSeasonActions/FarmSeasonInvalidateByAgronomistTypes';
import { POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS } from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksInstallationTypes';
import { POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS } from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksSubscriptionTypes';
import { CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS } from '@actions/FarmSeasonLivestockActions/ConfirmNotEligibleFarmSeasonLivestocksInstallationTypes';

import { FarmSeasonReducerT, FarmSeasonT, InstallationOnboardingT } from './FarmSeasonReducerTypes';
import { FARM_SEASON_SET_CURRENT } from '@actions/FarmSeasonActions/FarmSeasonSetCurrentTypes';
import { Reducer } from '@reduxjs/toolkit';
import { AppActionsT } from '@actions/AppActions/AppActionsTypes';
import { SUBSCRIPTION_SAVE_FARM_SUCCESS } from '@actions/SubscriptionActions/SubscriptionSaveFarmTypes';
import { SUBSCRIPTION_SAVE_PRODUCT_PLAN_SUCCESS } from '@actions/SubscriptionActions/SubscriptionSaveProductPlanTypes';
import { GET_LATEST_PODUCT_PLAN_STATUS_SUCCESS } from '@actions/ProductPlanActions/getLatestProductPlanStatusTypes';

export const selectedCurrentFarmSeasonKey = 'selected_current_farm_season';
const selectedCurrentFarmSeasonFromStorage = localStorage.getItem(selectedCurrentFarmSeasonKey);
const selectedCurrentFarmSeasonDefaultValue = selectedCurrentFarmSeasonFromStorage
    ? JSON.parse(selectedCurrentFarmSeasonFromStorage)
    : null;

const persistSelectedCurrentFarmSeason = (value: FarmSeasonT | undefined) => {
    delete value?.farm_boundaries;
    value ? localStorage.setItem(selectedCurrentFarmSeasonKey, JSON.stringify(value)) : null;
};

export const defaultFarmSeasonState: FarmSeasonReducerT = {
    loaded: false,
    getAllLoading: false,
    finalizeLoading: false,
    installationLoading: false,
    validateLoading: false,
    farmSeasonList: [],
    updateLoading: false,
    agronomistValidationLoading: false,
    updateIbanLoading: false,
    updateIbanError: null,
    currentFarmSeason: selectedCurrentFarmSeasonDefaultValue,
};

const farmSeasonReducer: Reducer<FarmSeasonReducerT, AppActionsT> = (state, action): FarmSeasonReducerT => {
    if (!state) {
        return defaultFarmSeasonState;
    }

    switch (action.type) {
        case FARM_SEASON_UPDATE_LOADING:
            return { ...state, updateLoading: true };
        case FARM_SEASON_UPDATE_FAILED:
            return { ...state, updateLoading: false, updateError: action.error };
        case FARM_SEASON_UPDATE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
                currentFarmSeason: action.payload,
                farmSeasonList: [...state.farmSeasonList.filter((fs) => fs.id !== action.payload.id), action.payload],
            };

        case SUBSCRIPTION_SAVE_FARM_SUCCESS:
            return {
                ...state,
                loaded: true,
                currentFarmSeason: action.payload.farmSeason,
                farmSeasonList: [...state.farmSeasonList, action.payload.farmSeason],
            };

        case POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS:
        case CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS:
        case POST_FARM_SEASON_FIELDS_SUCCESS:
        case POST_FARM_SEASON_FIELDS_CROPS_SUCCESS:
        case POST_FARM_SEASON_FIELDS_CROPS_RESIDUE_SUCCESS:
        case POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS:
        case POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS:
        case DELETE_FARM_SEASON_LIVESTOCKS_SUCCESS:
        case POST_FARM_SEASON_FIELDS_ORGANIC_SUCCESS:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_MATTER_SUCCESS:
        case POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS:
        case POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_IRRIGATION_SUCCESS:
        case POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS:
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_PASTURE_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS:
        case POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS:
            return {
                ...state,
                currentFarmSeason: {
                    ...(state.currentFarmSeason as FarmSeasonT),
                    installation: { ...state.currentFarmSeason?.installation, ...action.payload.installation },
                },
                farmSeasonList: state.farmSeasonList.map((farmSeason) =>
                    farmSeason.id === state.currentFarmSeason?.id
                        ? {
                              ...farmSeason,
                              installation: {
                                  ...state.currentFarmSeason?.installation,
                                  ...action.payload.installation,
                              },
                          }
                        : farmSeason,
                ),
            };

        case POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                currentFarmSeason: {
                    ...state.currentFarmSeason,
                    ...action.payload.farmSeason,
                },
                farmSeasonList: state.farmSeasonList.map((farmSeason) =>
                    farmSeason.id === action.payload.farmSeason.id
                        ? {
                              ...farmSeason,
                              ...action.payload.farmSeason,
                          }
                        : farmSeason,
                ),
            };

        case DELETE_FARM_SEASON_CROP_SUCCESS:
            return {
                ...state,
                currentFarmSeason: {
                    ...(state.currentFarmSeason as FarmSeasonT),
                    installation: action.payload.installation
                        ? action.payload.installation
                        : (state.currentFarmSeason?.installation as InstallationOnboardingT),
                },
                farmSeasonList: state.farmSeasonList.map((farmSeason) =>
                    farmSeason.id === action.payload.id
                        ? {
                              ...farmSeason,
                              installation: action.payload.installation
                                  ? action.payload.installation
                                  : farmSeason?.installation,
                          }
                        : farmSeason,
                ),
            };

        case HAS_PAID_SECOND_SUCCESS:
            return {
                ...state,
                currentFarmSeason: {
                    ...(state.currentFarmSeason as FarmSeasonT),
                    has_results_access: action.payload.has_results_access,
                },
                farmSeasonList: [
                    ...state.farmSeasonList.map((farmSeason) => {
                        if (farmSeason.id === state.currentFarmSeason?.id) {
                            return {
                                ...farmSeason,
                                has_results_access: action.payload.has_results_access,
                            };
                        }

                        return { ...farmSeason };
                    }),
                ],
            };

        case DELETE_FARM_SEASON_FIELD_SUCCESS:
            return {
                ...state,
                currentFarmSeason: {
                    ...(state.currentFarmSeason as FarmSeasonT),
                    installation: action.payload.installation,
                },
                farmSeasonList: state.farmSeasonList.map((farmSeason) =>
                    farmSeason.id === state.currentFarmSeason?.id
                        ? {
                              ...farmSeason,
                              installation: action.payload.installation,
                          }
                        : farmSeason,
                ),
            };

        case POST_FARM_SEASON_INSTALLATION_CONFIRM_FAILED:
            return { ...state, installationLoading: false, installationError: action.error };

        case POST_FARM_SEASON_INSTALLATION_CONFIRM_LOADING:
            return {
                ...state,
                installationLoading: true,
            };

        case POST_FARM_SEASON_INSTALLATION_CONFIRM_SUCCESS:
            persistSelectedCurrentFarmSeason(action.payload.farm_season);

            return {
                ...state,
                installationLoading: false,
                currentFarmSeason: {
                    ...(state.currentFarmSeason as FarmSeasonT),
                    installation: action.payload.farm_season.installation,
                    installation_validated_at: action.payload.farm_season.installation_validated_at,
                    installation_validated_by: action.payload.farm_season.installation_validated_by,
                },
                farmSeasonList: state.farmSeasonList.map((farmSeason) =>
                    farmSeason.id === action.payload.farm_season?.id
                        ? {
                              ...farmSeason,
                              installation: action.payload.farm_season.installation,
                              installation_validated_at: action.payload.farm_season.installation_validated_at,
                              installation_validated_by: action.payload.farm_season.installation_validated_by,
                          }
                        : farmSeason,
                ),
            };

        case FARM_SEASON_ALL_LOADING:
            return { ...state, getAllLoading: true };

        case FARM_SEASON_ALL_SUCCESS:
            return {
                ...state,
                loaded: true,
                getAllLoading: false,
                farmSeasonList: action.payload,
            };

        case FARM_SEASON_ALL_FAILED:
            return { ...state, loaded: true, getAllLoading: false, getAllError: action.error };

        case FARM_SEASON_SET_CURRENT: {
            const currentFarmSeason = state.farmSeasonList.find(
                (farmSeason) => farmSeason.id === action.payload.farmSeasonId,
            );
            persistSelectedCurrentFarmSeason(currentFarmSeason);

            return {
                ...state,
                currentFarmSeason,
            };
        }

        case FARM_SEASON_VALIDATE_LOADING:
            return {
                ...state,
                validateLoading: true,
            };

        case FARM_SEASON_VALIDATE_BY_AGRONOMIST_FAILED:
        case FARM_SEASON_INVALIDATE_BY_AGRONOMIST_FAILED:
            return {
                ...state,
                agronomistValidationLoading: false,
            };

        case FARM_SEASON_VALIDATE_BY_AGRONOMIST_LOADING:
        case FARM_SEASON_INVALIDATE_BY_AGRONOMIST_LOADING:
            return {
                ...state,
                agronomistValidationLoading: true,
            };

        case FARM_SEASON_VALIDATE_BY_AGRONOMIST_SUCCESS:
        case FARM_SEASON_INVALIDATE_BY_AGRONOMIST_SUCCESS:
            return {
                ...state,
                // TODO currentFarmSeason should be a selector so we could update only the list as the source of truth
                currentFarmSeason: action.payload,
                farmSeasonList: [
                    ...[...state.farmSeasonList].splice(
                        state.farmSeasonList.findIndex((farmSeason) => farmSeason.id === action.payload.id),
                    ),
                    action.payload,
                ],
                agronomistValidationLoading: false,
            };

        case FARM_SEASON_VALIDATE_SUCCESS:
            return {
                ...state,
                // TODO currentFarmSeason should be a selector so we could update only the list as the source of truth
                currentFarmSeason: action.payload,
                farmSeasonList: [
                    ...[...state.farmSeasonList].splice(
                        state.farmSeasonList.findIndex((farmSeason) => farmSeason.id === action.payload.id),
                    ),
                    action.payload,
                ],
                validateLoading: false,
            };
        case FARM_SEASON_VALIDATE_FAILED:
            return { ...state, validateError: action.error, validateLoading: false };

        case RESET:
            return {
                loaded: false,
                getAllLoading: false,
                finalizeLoading: false,
                installationLoading: false,
                validateLoading: false,
                farmSeasonList: [],
                updateLoading: false,
                agronomistValidationLoading: false,
                updateIbanLoading: false,
                updateIbanError: null,
                currentFarmSeason: undefined,
            };

        case FARM_SEASON_FINALIZE_LOADING:
            return { ...state, finalizeLoading: true };
        case FARM_SEASON_FINALIZE_SUCCESS: {
            persistSelectedCurrentFarmSeason(action.payload);

            return {
                ...state,
                finalizeLoading: false,
                currentFarmSeason: { ...action.payload },
                farmSeasonList: [
                    ...[...state.farmSeasonList].splice(
                        state.farmSeasonList.findIndex((farmSeason) => farmSeason.id === action.payload.id),
                    ),
                    action.payload,
                ],
            };
        }
        case GET_LATEST_PODUCT_PLAN_STATUS_SUCCESS: {
            const farmSeasonToUpdate = state.farmSeasonList.find((fs) => fs.id === action.payload.farm_season_id);
            const currentFarmSeasonToUpdate = state.currentFarmSeason;
            // avoid unnecessary rerenders
            if (
                !farmSeasonToUpdate ||
                (farmSeasonToUpdate?.latest_farm_season_product_plan?.status === action.payload.status &&
                    currentFarmSeasonToUpdate?.latest_farm_season_product_plan?.status === action.payload.status)
            ) {
                return state;
            }

            const updatedFarmSeason = {
                ...farmSeasonToUpdate,
                latest_farm_season_product_plan: farmSeasonToUpdate?.latest_farm_season_product_plan
                    ? {
                          ...farmSeasonToUpdate.latest_farm_season_product_plan,
                          status: action.payload.status,
                      }
                    : null,
            };

            return {
                ...state,
                currentFarmSeason:
                    farmSeasonToUpdate.id === state.currentFarmSeason?.id ? updatedFarmSeason : state.currentFarmSeason,
                farmSeasonList: [
                    ...[...state.farmSeasonList].splice(
                        state.farmSeasonList.findIndex((farmSeason) => farmSeason.id === state.currentFarmSeason?.id),
                    ),
                    updatedFarmSeason,
                ],
            };
        }
        case SUBSCRIPTION_SAVE_PRODUCT_PLAN_SUCCESS: {
            const updatedFarmSeason = {
                ...(state.currentFarmSeason as FarmSeasonT),
                latest_farm_season_product_plan: { ...action.payload.farmSeasonProductPlan },
            };
            return {
                ...state,
                currentFarmSeason: updatedFarmSeason,
                farmSeasonList: [
                    ...[...state.farmSeasonList].splice(
                        state.farmSeasonList.findIndex((farmSeason) => farmSeason.id === state.currentFarmSeason?.id),
                    ),
                    updatedFarmSeason,
                ],
            };
        }
        case FARM_SEASON_FINALIZE_FAILED:
            return { ...state, finalizeLoading: false, finalizeError: action.error };

        default:
            return state;
    }
};

export default farmSeasonReducer;
