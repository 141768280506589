import ErrorCard from './ErrorCard/ErrorCard';
import useErrorPageStyle from './ErrorPage.style';

function ErrorPage() {
    const { classes } = useErrorPageStyle();

    return (
        <div className={classes.container}>
            <ErrorCard />
        </div>
    );
}

export default ErrorPage;
