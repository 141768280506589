import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

export default class ShapeFileCropService {
    public static convert = async (
        country: string,
        ids: string[],
    ): Promise<
        AxiosResponse<
            {
                shapeFileCropId: string;
                cropId: number;
            }[]
        >
    > =>
        request({
            url: `/shape-file-crop/convert`,
            method: 'GET',
            params: { ids: ids.join(','), country },
        });
}
