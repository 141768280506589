import {
    PostFarmSeasonFieldsSoilOrganicMatterDataT,
    PostFarmSeasonFieldsSoilOrganicMatterResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsSoilOrganicMatterTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsSoilOrganicMatterApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsSoilOrganicMatterDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsSoilOrganicMatterResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/soil-organic-matters`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsSoilOrganicMatterApiService;
