import { makeStyles } from 'tss-react/mui';

const useIrrigationValuesFormViewStyle = makeStyles()(() => ({
    root: {},
    container: {},
    title: {
        marginBottom: '20px',
    },
    fieldName: {
        marginBottom: '5px',
    },
    listContainer: {
        marginBottom: '25px',
    },
    inputContainer: {
        marginBottom: '20px',
    },
    footerButton: {
        marginTop: '20px',
    },
}));

export default useIrrigationValuesFormViewStyle;
