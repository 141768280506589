import CollapsableSectionView from '@components/CollapsableSection/CollapsableSectionView';
import DatePicker from '@components/DatePicker/DatePicker';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import useHistoryMaxYear from '@hooks/useHistoryMaxYear';
import { Collapse, Grid } from '@mui/material';
import { useContext, useRef } from 'react';

import { FieldsContextStore } from '../../FieldsContext/FieldsContext';
import { FieldsContextStoreT } from '../../FieldsContext/FieldsContextTypes';

import usePastureHistoryStyle from './PastureHistoryView.style';
import { PastureHistoryViewPropsT } from './PastureHistoryViewTypes';

const PastureHistoryView = ({ formik, disabledForm }: PastureHistoryViewPropsT): JSX.Element => {
    const { classes } = usePastureHistoryStyle();
    const { t } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const sectionRef = useRef<HTMLDivElement>(null);

    // max year of change for pasture history to avoid negative values
    const historyMaxYear = useHistoryMaxYear();

    return (
        <CollapsableSectionView
            defaultCollapseState={true}
            helpName="pages.installationtasks.history.pasture.history-form.title"
            sectionName={t('pages.installationtasks.history.pasture-history')}
            sectionRef={sectionRef}
        >
            <>
                <Grid className={classes.gridRow} spacing={2} container>
                    <Grid xs={12} item>
                        <LabelCheckboxView
                            disabled={disabledForm}
                            checked={!!formik.values.pasture_history?.has_pasture_history}
                            error={
                                formik.touched.pasture_history?.has_pasture_history &&
                                !!formik.errors.pasture_history?.has_pasture_history
                            }
                            helperText={
                                formik.touched.pasture_history?.has_pasture_history
                                    ? formik.errors.pasture_history?.has_pasture_history
                                    : undefined
                            }
                            label={t('pages.fields.edit-field.this-field-has-pasture-history')}
                            noMargin
                            onChange={(event) => {
                                formik.setFieldValue('pasture_history.has_pasture_history', event.target.checked);
                                event.target.checked &&
                                    setTimeout(() => sectionRef.current?.scrollIntoView({ behavior: 'smooth' }), 400);
                            }}
                        />
                    </Grid>
                </Grid>
                <Collapse in={formik.values.pasture_history?.has_pasture_history} timeout="auto" unmountOnExit>
                    <Grid className={classes.gridRow} spacing={2} container>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <DatePicker
                                disabled={disabledForm}
                                error={
                                    formik.touched.pasture_history?.year_of_change &&
                                    !!formik.errors.pasture_history?.year_of_change
                                }
                                helperText={
                                    formik.touched.pasture_history?.year_of_change &&
                                    formik.errors.pasture_history?.year_of_change
                                }
                                labelText={t(
                                    'pages.installationtasks.history.pasture.history-form.label-year-of-change',
                                )}
                                maxDate={historyMaxYear}
                                openTo="year"
                                value={
                                    formik.values.pasture_history.year_of_change === ''
                                        ? ''
                                        : new Date(formik.values.pasture_history.year_of_change)
                                }
                                onChange={(event) => {
                                    const year = new Date(event).getFullYear().toString();
                                    formik.setFieldValue('pasture_history.year_of_change', year);
                                    setTimeout(() => formik.setTouched({ pasture_history: { year_of_change: true } }));
                                }}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
            </>
        </CollapsableSectionView>
    );
};

export default PastureHistoryView;
