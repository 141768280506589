import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { isNullOrUndefined } from '@/utils/numberHelper';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useTooltipMissingSoilCharacteristicsStyle from './TooltipMissingSoilCharacteristics.style';
import { TooltipMissingSoilCharacteristicsPropsT } from './TooltipMissingSoilCharacteristicsTypes';
import { ROLE_ID } from '@constants/roleIds';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';

/**
 * wrapper who display a tooltip for admins if some field characteristics are missing (drainage, humidity, soil_type...)
 * also display a icon error side to the children
 */
const TooltipMissingSoilCharacteristics = ({
    children,
    field,
}: TooltipMissingSoilCharacteristicsPropsT): JSX.Element => {
    const userRoleId = useSelector((state: HomePageStateT) => state.auth.user?.role_id);
    const isFarmer = userRoleId === ROLE_ID.FARMER;
    const { t } = useTranslation();
    const { classes } = useTooltipMissingSoilCharacteristicsStyle();

    const missingCharacteristics = useMemo(
        () =>
            [
                !isNullOrUndefined(field.opendata_soil_drainage_id) &&
                    isNullOrUndefined(field.cft_land_drainage_id) &&
                    'cft_land_drainage_id',
                !isNullOrUndefined(field.opendata_soil_water_capacity_id) &&
                    isNullOrUndefined(field.cft_soil_humidity_id) &&
                    'cft_soil_humidity_id',
                !isNullOrUndefined(field.opendata_soil_texture_id) &&
                    isNullOrUndefined(field.cft_soil_type_id) &&
                    'cft_soil_type_id',
                field.area === 0 && 'area',
            ].filter(Boolean) as string[],
        [field],
    );
    const tooltipTexts = useMemo(() => {
        if (isFarmer) {
            return '';
        }
        const texts = [];

        if (missingCharacteristics.some((v) => v.indexOf('cft') >= 0)) {
            const characteristicsTxt = missingCharacteristics
                .filter((v) => v.indexOf('cft') >= 0)
                .map((value) => {
                    switch (value) {
                        case 'cft_land_drainage_id':
                            return t('fields.missing-soil-characteristics-drainage');
                        case 'cft_soil_humidity_id':
                            return t('fields.missing-soil-characteristics-humidity');
                        case 'cft_soil_type_id':
                            return t('fields.missing-soil-characteristics-type');
                        default:
                            return '';
                    }
                })
                .join(', ');

            const missingSoilCharsTxt = t('fields.missing-soil-characteristics', {
                characteristics: characteristicsTxt,
                count: missingCharacteristics.filter((v) => v.indexOf('cft') >= 0).length,
            });

            texts.push(missingSoilCharsTxt);
        }

        if (missingCharacteristics.some((v) => v.indexOf('area') >= 0)) {
            texts.push(t('fields.missing-characteristics-area'));
        }

        if (!field.is_geom_valid) {
            texts.push(t('fields.has-invalid-polygon'));
        }

        if (texts.length > 0) {
            return (
                <ul style={{ marginLeft: '10px' }}>
                    {texts.map((text, index) => (
                        <li key={index}>{text}</li>
                    ))}
                </ul>
            );
        }

        return '';
    }, [isFarmer, missingCharacteristics, field.is_geom_valid]);

    if (!tooltipTexts) {
        return <>{children}</>;
    }

    return (
        <div className={classes.container}>
            {children}
            <Tooltip title={tooltipTexts}>
                <ErrorOutlineOutlined className={classes.errorIcon} />
            </Tooltip>
        </div>
    );
};

export default TooltipMissingSoilCharacteristics;
