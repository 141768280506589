import { makeStyles } from 'tss-react/mui';

const useMenuBarStyle = makeStyles()((theme) => ({
    menuBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: theme.palette.secondary.main,
    },
    arrowBackLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '16px',
        width: '16px',
    },
    menuBarTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
    },
    closeLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    icons: {
        fontSize: '16px',
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
}));

export default useMenuBarStyle;
