import { makeStyles } from 'tss-react/mui';

const useSidePageRightLayoutStyle = makeStyles<{ animContent?: { ms: number; changing: boolean } }>()(
    (theme, { animContent }) => ({
        container: {
            ...theme.styles.scrollSection,
            padding: '30px 30px 0 30px',
            transition: animContent ? `opacity linear ${animContent.ms}ms` : '',
            opacity: animContent?.changing ? 0 : 1,
            display: 'flex',
            flexDirection: 'column',
        },
        pageTitle: {
            marginBottom: '20px',
        },

        footer: {
            paddingRight: theme.spacing(8),
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
        },
    }),
);

export default useSidePageRightLayoutStyle;
