import { geoJsonFeatureT } from '@components/Map/MapTypes';
import { FarmSeasonFieldCropDataT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_LOADING =
    'POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_LOADING';
export const POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_FAILED = 'POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_FAILED';
export const POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS =
    'POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS';

export type FarmSeasonFieldsSoilOrganicCarbonT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    polygon: geoJsonFeatureT;
    tillage_id?: number | null;
};

export type PostFarmSeasonFieldsSoilOrganicCarbonDataT = {
    fields: FarmSeasonFieldsSoilOrganicCarbonT[];
};

export type PostFarmSeasonFieldsSoilOrganicCarbonResponseDataT = {
    fields: FarmSeasonFieldsSoilOrganicCarbonT[];
    installation: {
        onboarding: {
            finished: string[];
            tillage: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsSoilOrganicCarbonPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsSoilOrganicCarbonDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsSoilOrganicCarbonResponseErrorT>;

export type PostFarmSeasonFieldsSoilOrganicCarbonResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsSoilOrganicCarbonLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_LOADING;
};

export type PostFarmSeasonFieldsSoilOrganicCarbonFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_FAILED;
    error: PostFarmSeasonFieldsSoilOrganicCarbonResponseErrorT;
};

export type PostFarmSeasonFieldsSoilOrganicCarbonSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_SOIL_ORGANIC_CARBON_SUCCESS;
    payload: PostFarmSeasonFieldsSoilOrganicCarbonResponseDataT;
};

export type PostFarmSeasonFieldsSoilOrganicCarbonDispatchTypesT =
    | PostFarmSeasonFieldsSoilOrganicCarbonLoadingT
    | PostFarmSeasonFieldsSoilOrganicCarbonFailedT
    | PostFarmSeasonFieldsSoilOrganicCarbonSuccessT;
