import MenuBar from '@components/MenuBar/MenuBar';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import { useContext } from 'react';
import { Prompt } from '@components/Prompt/Prompt';

import { Context } from './TillagePracticeTaskContext/TillagePracticeTaskContext';
import { ContextT } from './TillagePracticeTaskContext/TillagePracticeTaskContextTypes';
import usePastureTaskStyle from './TillagePracticeTaskView.style';
import FieldsGroupList from './FieldsGroupList/FieldsGroupList';
import HistoryForm from './HistoryForm/HistoryForm';
import SelectFields from './SelectFields/SelectFields';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useCanEditFarmHistory } from '@hooks/usePermissions';

const PastureTaskView = (): JSX.Element => {
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditHistory } = useCanEditFarmHistory(currentFarmSeason);
    const { classes } = usePastureTaskStyle();
    const { currentStep, t, setCurrentStep } = useContext<ContextT>(Context);

    let StepComponent: JSX.Element;

    switch (currentStep) {
        case 1:
            StepComponent = <FieldsGroupList canEdit={canEditHistory.access} />;
            break;
        case 2:
            StepComponent = <HistoryForm canEdit={canEditHistory.access} />;
            break;
        case 3:
            StepComponent = <SelectFields canEdit={canEditHistory.access} />;
            break;
        default:
            StepComponent = <FieldsGroupList canEdit={canEditHistory.access} />;
            break;
    }

    return (
        <div>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={false}
            />
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={currentStep} totalSteps={3} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.history.tillage-practice.menu-title')}
                    onArrowBack={currentStep > 1 ? () => setCurrentStep((state) => state - 1) : '..'}
                    onClose=".."
                />
                {StepComponent}
            </div>
        </div>
    );
};

export default PastureTaskView;
