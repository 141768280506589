import FullPageLayout from '@layouts/FullPageLayout/FullPageLayout';
import { useTranslation } from 'react-i18next';
import useSettingsLogic from './Settings.logic';
import SubscriptionInfoCard from './components/SubscriptionInfoCard/SubscriptionInfoCard';
import BillingDetailsCard from './components/BillingDetailsCard/BillingDetailsCard';
import { Grid } from '@mui/material';
import PayoutCard from './components/PayoutCard/PayoutCard';

const Settings = (): JSX.Element => {
    const { t } = useTranslation();

    const {
        conditions: { canSeeBillingDetails },
    } = useSettingsLogic();

    return (
        <FullPageLayout title={t('pages.settings.billing.title')}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SubscriptionInfoCard />
                </Grid>
                {canSeeBillingDetails && (
                    <Grid item xs={12} md={6}>
                        <BillingDetailsCard />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <PayoutCard />
                </Grid>
            </Grid>
        </FullPageLayout>
    );
};

export default Settings;
