import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const DELETE_FARM_SEASON_CROP_LOADING = 'DELETE_FARM_SEASON_CROP_LOADING';
export const DELETE_FARM_SEASON_CROP_FAILED = 'DELETE_FARM_SEASON_CROP_FAILED';
export const DELETE_FARM_SEASON_CROP_SUCCESS = 'DELETE_FARM_SEASON_CROP_SUCCESS';

export type DeleteFarmSeasonCropResponseDataT = {
    id: number;
    fields: FarmSeasonFieldT[];
    installation?: {
        onboarding: {
            finished: string[];
            crop: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonCropDeleteCallBackT = (
    dispatch: Dispatch<DeleteFarmSeasonCropDispatchTypesT>,
) => Promise<DeleteFarmSeasonCropResponseErrorT>;

export type DeleteFarmSeasonCropResponseErrorT = {
    message?: string;
    list?: string[];
};

export type DeleteFarmSeasonCropLoadingT = {
    type: typeof DELETE_FARM_SEASON_CROP_LOADING;
};

export type DeleteFarmSeasonCropFailedT = {
    type: typeof DELETE_FARM_SEASON_CROP_FAILED;
    error: DeleteFarmSeasonCropResponseErrorT;
};

export type DeleteFarmSeasonCropSuccessT = {
    type: typeof DELETE_FARM_SEASON_CROP_SUCCESS;
    payload: DeleteFarmSeasonCropResponseDataT;
};

export type DeleteFarmSeasonCropDispatchTypesT =
    | DeleteFarmSeasonCropLoadingT
    | DeleteFarmSeasonCropFailedT
    | DeleteFarmSeasonCropSuccessT;
