import { FarmSeasonFieldIrrigationT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import findById from '@utils/findById';
import { useTranslation } from 'react-i18next';

import { CardIrrigationLogicPropsT } from './CardIrrigation.types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCardIrrigationLogic = ({
    irrigationMethodList,
    irrigationWaterSourceList,
    irrigationPowerSourceList,
}: CardIrrigationLogicPropsT) => {
    const { t } = useTranslation();

    /** Display "[Method] / [Source] / [Supply]" */
    const irrigationTransform = (fieldIrrigation: FarmSeasonFieldIrrigationT) => {
        const method = findById(fieldIrrigation?.irrigation_method_id, irrigationMethodList);
        const source = findById(fieldIrrigation?.irrigation_power_source_id, irrigationPowerSourceList);
        const supply = findById(fieldIrrigation?.irrigation_water_source_id, irrigationWaterSourceList);

        return method && source && supply
            ? `${t(method.translation_slug)} / ${t(source.translation_slug)} / ${t(supply.translation_slug)}`
            : '';
    };

    return { irrigationTransform };
};

export default useCardIrrigationLogic;
