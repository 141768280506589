import { makeStyles } from 'tss-react/mui';

const useBillingDetailsFormStyle = makeStyles()(() => ({
    checkboxContainer: {
        paddingBottom: '5px',
        marginLeft: '8px',
    },
    checkbox: {
        paddingLeft: '5px',
    },
}));

export default useBillingDetailsFormStyle;
