import { AxiosResponse } from 'axios';
import request from '@services/apiService/apiService';
import { FarmDeletePayloadT, FarmPayloadT, FarmT, FarmUpdatePayloadT } from '@actions/FarmActions/FarmActionsTypes';

class FarmApiService {
    public static save = async ({
        userId,
        payload,
    }: {
        userId: number;
        payload: FarmPayloadT;
    }): Promise<AxiosResponse> =>
        request({
            url: `/user/${userId}/farm`,
            method: 'POST',
            data: payload,
        });

    public static getAll = async ({ userId }: { userId: number }): Promise<AxiosResponse<FarmT[]>> =>
        request({
            url: `/user/${userId}/farms`,
            method: 'GET',
        });

    public static update = async ({ farmId, payload, userId }: FarmUpdatePayloadT): Promise<AxiosResponse> =>
        request({
            url: `/user/${userId}/farm/${farmId}`,
            method: 'PUT',
            data: payload,
        });

    public static delete = async ({ farmId, userId }: FarmDeletePayloadT): Promise<AxiosResponse> =>
        request({
            url: `/user/${userId}/farm/${farmId}`,
            method: 'DELETE',
        });
}

export default FarmApiService;
