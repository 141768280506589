import { HasPaidSecondResponseDataT } from '@actions/AuthActions/HasPaidSecondActionsTypes';
import { PaymentDetailT } from '@reducers/AuthReducer/AuthReducerTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

import { PaymentDetailsResponseDataT } from './paymentApiServiceTypes';

class PaymentApiService {
    public static getPaymentDetails = async (): Promise<AxiosResponse<PaymentDetailsResponseDataT>> =>
        request({
            url: `/payment-details`,
            method: 'GET',
        });

    public static getHasPaidSecond = async (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<HasPaidSecondResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/payment/second`,
            method: 'GET',
        });

    public static postPaymentDetail = (
        farmId?: number,
        farmSeasonId?: number,
        paymentDetail?: PaymentDetailT,
        type?: 'first' | 'second' | 'upsell',
        locale?: string,
    ) =>
        request<{ redirectUrl: string }>({
            method: 'POST',
            url: `/farm/${farmId}/season/${farmSeasonId}/payment/${type}${locale ? `?locale=${locale}` : ''}`,
            data: paymentDetail,
        });

    public static postPaymentDetailNextSeason = (
        farmId?: number,
        latestFarmSeasonId?: number,
        paymentDetail?: PaymentDetailT,
        locale?: string,
    ) =>
        request<{ redirectUrl: string }>({
            method: 'POST',
            url: `/farm/${farmId}/season/${latestFarmSeasonId}/payment/next-season${locale ? `?locale=${locale}` : ''}`,
            data: paymentDetail,
        });
}

export default PaymentApiService;
