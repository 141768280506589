import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useRenewalActionStyle = makeStyles()((theme: Theme) => ({
    actionButton: {
        marginRight: '8px',
        marginTop: '16px',
    },
    preline: {
        whiteSpace: 'pre-line',
    },
    infoTooltip: {
        color: theme.palette.secondary.main,
    },
}));

export default useRenewalActionStyle;
