import { CircularProgress } from '@mui/material';

import useLoadingStyle from './LoadingView.style';

const LoadingView = (): JSX.Element => {
    const { classes } = useLoadingStyle();

    return (
        <div className={classes.loadingContainer}>
            <CircularProgress className={classes.loading} size="6rem" />
        </div>
    );
};

export default LoadingView;
