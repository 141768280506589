import MainRouter from '@routers/MainRouter';

import SnackbarError from './components/SnackbarError/SnackbarError';

const App = (): JSX.Element => {
    return (
        <>
            <SnackbarError />
            <MainRouter />
        </>
    );
};

export default App;
