import { useIsReadOnlyUser } from '@/hooks/usePermissions';
import BillingDetailsForm from '@components/BillingDetailsForm/BillingDetailsForm';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import { Button, Grid, Typography } from '@mui/material';
import { ModalBase } from '@soil-capital/ui-kit';
import { InfoPaper } from '@ui-kit';
import { useTranslation } from 'react-i18next';
import useBillingDetailsCardLogic from './BillingDetailsCard.logic';
import useBillingDetailsCardStyle from './BillingDetailsCard.style';

const BillingDetailsCard = () => {
    const { classes } = useBillingDetailsCardStyle();
    const { t } = useTranslation();
    const {
        data: { userCompany },
        handles: { handleBillingDetailsEditButtonClick },
        modalControllers: { editModalController },
        submittingStates: { isEditSubmitting },
        formSubmits: { submitEditForm },
        formRefs: { editFormRef },
    } = useBillingDetailsCardLogic();
    const isReadOnlyUser = useIsReadOnlyUser();

    return (
        <>
            <InfoPaper title={t('pages.settings.billing.details.title')}>
                <>
                    <Grid className={classes.gridContainer} container>
                        <Grid item xs={6}>
                            <Typography mb="8px" color="secondary" variant="body2">
                                {t('pages.settings.billing.details.address')}
                            </Typography>
                            <div>
                                <Typography mb="8px" color="primary" variant="body2">
                                    {userCompany?.address}
                                </Typography>
                                <Typography mb="8px" color="primary" variant="body2">
                                    {userCompany?.postal_code} {userCompany?.city}
                                </Typography>
                                <Typography mb="8px" color="primary" variant="body2">
                                    {userCompany?.country?.translation_slug
                                        ? t(userCompany?.country?.translation_slug)
                                        : ''}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography mb="8px" color="secondary" variant="body2">
                                {t('pages.settings.billing.details.vat')}
                            </Typography>
                            <Typography color="primary" variant="body2">
                                {userCompany?.tax_id}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!isReadOnlyUser && (
                        <Button color="secondary" onClick={handleBillingDetailsEditButtonClick}>
                            {t('constants.edit')}
                        </Button>
                    )}
                </>
            </InfoPaper>
            {/* Update modal */}
            <ModalBase width="600px" showCloseButton controller={editModalController}>
                <ModalBaseLayout
                    title={{
                        text: t('pages.settings.billing.details.modal.title'),
                    }}
                    cancelButton={{
                        text: t('constants.cancel'),
                        onClick: () => editModalController.onModalCancel(),
                        disabled: isReadOnlyUser,
                    }}
                    confirmButton={{
                        text: t('constants.save'),
                        onClick: async () => {
                            if (await submitEditForm()) {
                                editModalController.onModalCancel();
                            }
                        },
                        disabled: isEditSubmitting,
                    }}
                >
                    <BillingDetailsForm ref={editFormRef} userCompany={userCompany} withUseFarmDataCheckbox />
                </ModalBaseLayout>
            </ModalBase>
        </>
    );
};

export default BillingDetailsCard;
