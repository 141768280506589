import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useFieldsSubtasksStyle = makeStyles()((theme) => ({
    fieldsSubtasksPageWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'center',
        padding: '0 30px 30px 30px',
        '& h3': {
            display: 'block',
            width: '100%',
            textAlign: 'left',
            color: theme.palette.driftwood.light,
            fontSize: '1.312rem',
        },
        '& h4': {
            fontSize: '1.1rem',
            textAlign: 'left',
            marginBottom: '4px',
            marginTop: '20px',
            display: 'block',
            width: '100%',
        },
    },
    hr: {
        width: '150px',
        borderWidth: '1px',
        margin: '10px auto 2px',
        backgroundColor: alpha(theme.palette.driftwood.light, 0.2),
    },
    progress: {
        marginBottom: '20px',
    },
}));

export default useFieldsSubtasksStyle;
