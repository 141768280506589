import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

// Arrow function has parameter type of Identifier instead of ObjectPattern (e.g. `(props) => ({...})` instead of `({color}) => ({...})`).
const useLoaderStyles = makeStyles<{ isOpaque?: boolean; bgColor?: string }>()((theme, { isOpaque, bgColor }) => ({
    loaderColor: {
        color: theme.palette.primary.dark,
    },
    loaderBox: {
        backgroundColor: alpha(bgColor || theme.palette.grey[200], isOpaque ? 1 : 0.7),
        transition: 'opacity 0.5s ease',
        pointerEvents: 'none',
    },
}));

export default useLoaderStyles;
