/* eslint-disable @typescript-eslint/no-explicit-any */
import { FarmT } from '@actions/FarmActions/FarmActionsTypes';
import mapUpdate from '@actions/MapActions/MapActions';
import { MAP_UPDATE } from '@actions/MapActions/MapActionsTypes';
import {
    BlockedModalDataT,
    geoJsonFeatureCollectionT,
    geoJsonFeatureMultiT,
    geoJsonFeatureT,
    InteractionEventT,
    lngLatT,
    MapPropsT,
    RelationModalDataT,
    WarningModalDataT,
} from '@components/Map/MapTypes';
import { MAPBOX_ACCESS_TOKEN, MAPBOX_BASE_STYLE, MAPBOX_OPENDATA_TILESET } from '@constants/mapbox';
import useAppDispatch from '@hooks/useAppDispatch';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useTheme } from '@mui/material';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import cross from '@patterns/cross.svg?path';
import dots1 from '@patterns/dots-1.svg?path';
import dots2 from '@patterns/dots-2.svg?path';
import dots3 from '@patterns/dots-3.svg?path';
import dots4 from '@patterns/dots-4.svg?path';
import smallDots from '@patterns/small-dots.svg?path';
import smallLinesRed from '@patterns/small-lines-red.svg?path';
import smallLines from '@patterns/small-lines.svg?path';
import trees from '@patterns/trees.svg?path';
import waves from '@patterns/waves.svg?path';
import {
    FarmSeasonFieldCropDataT,
    FarmSeasonFieldT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';
import { relationsConditionT } from '@services/mapService/mapServiceTypes';
import SegmentService from '@services/segmentService/segmentService';
import { AppStateT } from '@store/store';
import area from '@turf/area';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import buffer from '@turf/buffer';
import centroid from '@turf/centroid';
import combine from '@turf/combine';
import difference from '@turf/difference';
import distance from '@turf/distance';
import flatten from '@turf/flatten';
import { Feature, Properties } from '@turf/helpers';
import intersect from '@turf/intersect';
import simplify from '@turf/simplify';
import union from '@turf/union';
import MapGL, { FeatureState, Layer, NavigationControl, Source } from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import { Viewport } from '@urbica/react-map-gl/src/components/MapGL';
import _ from 'lodash';
import { AnimationOptions } from 'mapbox-gl';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MapPopup from './components/MapPopup/MapPopup';
import { MapPopupRefT } from './components/MapPopup/MapPopup.types';
import DirectSelectWithSnap from './draw-modes/direct_select_with_snap_mode';
import DrawPolygonWithSnap from './draw-modes/draw_polygon_with_snap_mode';
import SimpleSelectSingleClick from './draw-modes/simple_select_single_click_mode';
import SplitPolygonMode from './draw-modes/split_polygon_mode';
import { toleranceOptions } from './draw-modes/utils/tolerance';
import { getMapBoxDrawStyles } from './Map.style';
import MapView from './MapView';

// todo refactor this component into sub-components now that it's so big

const Map = ({
    id = 'map',
    latitude = 50.642919,
    longitude = 4.7981658,
    zoom = 15,
    interactive = true,
    showMaskLayer,
    offsetX,
    children,
    initAnimation = true,
    style,
}: MapPropsT): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const mapRef = useRef<MapGL | null>(null);
    const [loading, setLoading] = useState(true);
    const [drawMode, setDrawMode] = useState('simple_select');
    const [drawModeOptions, setDrawModeOptions] = useState({
        featureId: undefined as string | number | undefined,
        polygonsLayer: [] as geoJsonFeatureT[] | undefined,
    });
    const [startCreating, setStartCreating] = useState(false);
    const [temporaryDisableOpenData, setTemporaryDisableOpenData] = useState(false);
    const [overlappingPolygon, setOverlappingPolygon] = useState(false);
    const [currentDrawingPolygon, setCurrentDrawingPolygon] = useState<geoJsonFeatureT>();
    const [currentEditingPolygons, setCurrentEditingPolygons] = useState<geoJsonFeatureCollectionT>();
    const [currentSelectedPolygons, setCurrentSelectedPolygons] = useState<geoJsonFeatureT[]>([]);
    const [currentSelectedParcel, setCurrentSelectedParcel] = useState<geoJsonFeatureT>();
    const [currentEmptyPolygon, setCurrentEmptyPolygon] = useState<geoJsonFeatureT>();
    const [warningModalData, setWarningModalData] = useState<WarningModalDataT>();
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [relationModalData, setRelationModalData] = useState<RelationModalDataT | null>(null);
    const [relationModalOpen, setRelationModalOpen] = useState(false);
    const [blockedModalData, setBlockedModalData] = useState<BlockedModalDataT | null>(null);
    const [blockedModalOpen, setBlockedModalOpen] = useState(false);
    const [hoveredStateId, setHoveredStateId] = useState<number>();
    const [hoveredPolygonLayerId, setHoveredPolygonLayerId] = useState<number | string | null>(null);
    const [hoveringPolygonLayer, setHoveringPolygonLayer] = useState(false);
    const [hover, setHover] = useState(false);
    const currentFarm = useSelector((state: AppStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason);
    const baseline = currentFarmSeason.baseline_farm_season_id === null;
    const currentFields = useSelector((state: AppStateT) => state.farmSeasonField.fieldsList);
    const baseUrlSourceData = `${process.env.REACT_APP_API_BASE_URL}/farm/${currentFarm?.id}/season/${currentFarmSeason?.id}`;
    const farmMask = useMemo(
        () => JSON.parse(currentFarmSeason.farm_boundaries_mask ?? 'null') as geoJsonFeatureCollectionT | null,
        [currentFarmSeason.farm_boundaries_mask],
    );

    const mapPopupRef = useRef<MapPopupRefT>(null);

    // get stored map
    const dispatch = useAppDispatch();
    const maps = useSelector((state: AppStateT) => state.map?.maps);
    const mapsFilter = maps.filter((map: MapPropsT) => map.id === id);
    const thisMap = mapsFilter[0];
    const hasOpenDataParcels = thisMap.openData && !temporaryDisableOpenData;

    // Load images on map load
    useEffect(() => {
        const map = mapRef.current?.getMap();
        if (!map) {
            return;
        }

        map.once('load', () => {
            const smallDotsPattern = new Image(36, 36);
            smallDotsPattern.src = smallDots;
            smallDotsPattern.height = 18;
            smallDotsPattern.width = 18;
            smallDotsPattern.onload = () => {
                if (map && !map.hasImage('smallDots')) map.addImage('smallDots', smallDotsPattern);
            };

            const smallLinesPattern = new Image(36, 36);
            smallLinesPattern.src = smallLines;
            smallLinesPattern.height = 18;
            smallLinesPattern.width = 18;
            smallLinesPattern.onload = () => {
                if (map && !map.hasImage('smallLines')) map.addImage('smallLines', smallLinesPattern);
            };

            const smallLinesRedPattern = new Image(36, 36);
            smallLinesRedPattern.src = smallLinesRed;
            smallLinesRedPattern.height = 18;
            smallLinesRedPattern.width = 18;
            smallLinesRedPattern.onload = () => {
                if (map && !map.hasImage('smallLinesRed')) map.addImage('smallLinesRed', smallLinesRedPattern);
            };

            const treePattern = new Image(36, 36);
            treePattern.src = trees;
            treePattern.height = 18;
            treePattern.width = 18;
            treePattern.onload = () => {
                if (map && !map.hasImage('trees')) map.addImage('trees', treePattern);
            };

            const crossPattern = new Image(36, 36);
            crossPattern.src = cross;
            crossPattern.height = 18;
            crossPattern.width = 18;
            crossPattern.onload = () => {
                if (map && !map.hasImage('cross')) map.addImage('cross', crossPattern);
            };

            const dots1Pattern = new Image(36, 36);
            dots1Pattern.src = dots1;
            dots1Pattern.height = 18;
            dots1Pattern.width = 18;
            dots1Pattern.onload = () => {
                if (map && !map.hasImage('dots1')) map.addImage('dots1', dots1Pattern);
            };

            const dots2Pattern = new Image(36, 36);
            dots2Pattern.src = dots2;
            dots2Pattern.height = 18;
            dots2Pattern.width = 18;
            dots2Pattern.onload = () => {
                if (map && !map.hasImage('dots2')) map.addImage('dots2', dots2Pattern);
            };

            const dots3Pattern = new Image(36, 36);
            dots3Pattern.src = dots3;
            dots3Pattern.height = 18;
            dots3Pattern.width = 18;
            dots3Pattern.onload = () => {
                if (map && !map.hasImage('dots3')) map.addImage('dots3', dots3Pattern);
            };

            const dots4Pattern = new Image(36, 36);
            dots4Pattern.src = dots4;
            dots4Pattern.height = 18;
            dots4Pattern.width = 18;
            dots4Pattern.onload = () => {
                if (map && !map.hasImage('dots4')) map.addImage('dots4', dots4Pattern);
            };

            const wavesPattern = new Image(36, 36);
            wavesPattern.src = waves;
            wavesPattern.height = 18;
            wavesPattern.width = 18;
            wavesPattern.onload = () => {
                if (map && !map.hasImage('waves')) map.addImage('waves', wavesPattern);
            };
        });

        // GA event (count map loads)
        ReactGA.event({
            category: 'Map',
            action: 'Map load',
            nonInteraction: true,
        });
    }, [mapRef]);

    // function to detect if a GeoJSON feature is in a given array
    const inArrayPolygon = (geoJsonFeature: geoJsonFeatureT, geoJsonFeatures: geoJsonFeatureT[]): boolean =>
        _.some(geoJsonFeatures, (v) => _.isEqual(v.id.toString(), geoJsonFeature.id.toString()));

    // function to detect if a GeoJson feature is intersecting a feature collection
    const intersectCore = (loopFeature: geoJsonFeatureT, comparingFeature: geoJsonFeatureT) => {
        const interGeo = intersect(loopFeature, comparingFeature);
        let interIsNull = _.isNull(interGeo);

        // overlapping tolerance
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (
            !interIsNull &&
            area(interGeo as Feature<any, Properties>) <=
                Math.max(
                    area(comparingFeature) / toleranceOptions.TOLERANCE_RATIO_AREA,
                    toleranceOptions.TOLERANCE_MIN_AREA,
                )
        ) {
            interIsNull = true;
        }

        return !interIsNull;
    };
    const intersectPolygon = (geoJsonFeature: geoJsonFeatureT, geoJsonFeatures: geoJsonFeatureT[]): boolean =>
        _.some(geoJsonFeatures, (v) => intersectCore(v, geoJsonFeature));
    const getInterPolygons = (geoJsonFeature: geoJsonFeatureT, geoJsonFeatures: geoJsonFeatureT[]): geoJsonFeatureT[] =>
        _.filter(geoJsonFeatures, (v) => intersectCore(v, geoJsonFeature));

    const getDiffPolygons = (
        geoJsonFeature: geoJsonFeatureT,
        geoJsonFeatures: geoJsonFeatureT[],
    ): geoJsonFeatureT[] => {
        const combined = combine({ type: 'FeatureCollection', features: geoJsonFeatures });
        const diff = difference(geoJsonFeature, combined.features[0] as unknown as geoJsonFeatureT);
        const flattenDiff = diff ? flatten(diff as geoJsonFeatureT) : { type: 'FeatureCollection', features: [] };
        return _.filter(
            flattenDiff.features,
            (v) => area(v as geoJsonFeatureT) > toleranceOptions.TOLERANCE_MIN_AREA,
        ) as geoJsonFeatureT[];
    };

    // can be useful later on...
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getClosestPolygon = (lngLat: lngLatT, geoJsonFeatures: geoJsonFeatureT[]): geoJsonFeatureT => {
        const sortedByDistance = _.sortBy(geoJsonFeatures, (v) => distance([lngLat.lng, lngLat.lat], centroid(v)));
        return sortedByDistance[0] ?? undefined;
    };

    const getContiguousPolygons = (
        geoJsonFeature: geoJsonFeatureT,
        geoJsonFeatures: geoJsonFeatureT[],
    ): geoJsonFeatureT[] => {
        const bufferedGeoJsonFeature = buffer(geoJsonFeature, toleranceOptions.TOLERANCE_MERGE_BUFFER);
        return _.filter(
            geoJsonFeatures,
            (v) =>
                v.id === geoJsonFeature.id ||
                intersect(v, bufferedGeoJsonFeature) !== null ||
                union(v, geoJsonFeature)?.geometry?.type === 'Polygon',
        );
    };

    const unionContiguousPolygons = (geoJsonFeatures: geoJsonFeatureT[]): geoJsonFeatureT => {
        let unionFeature = geoJsonFeatures[0];
        geoJsonFeatures.forEach((v, i) => {
            if (i > 0) {
                let newUnionFeature = union(v, unionFeature) as geoJsonFeatureMultiT;
                if (newUnionFeature?.geometry?.type === 'MultiPolygon') {
                    const bufferedUnion = union(
                        buffer(v, toleranceOptions.TOLERANCE_MERGE_BUFFER),
                        buffer(unionFeature, toleranceOptions.TOLERANCE_MERGE_BUFFER),
                    );
                    if (bufferedUnion?.geometry?.type === 'Polygon') {
                        newUnionFeature = simplify(buffer(bufferedUnion, -toleranceOptions.TOLERANCE_MERGE_BUFFER), {
                            tolerance: 0.0001,
                            highQuality: true,
                            mutate: true,
                        }) as geoJsonFeatureT;
                    } else {
                        return;
                    }
                }
                if (newUnionFeature?.geometry?.type === 'Polygon') {
                    unionFeature = newUnionFeature as geoJsonFeatureT;
                }
            }
        });
        if (!unionFeature || unionFeature.geometry.type !== 'Polygon') {
            alert('Cannot merging these fields!...');
        }
        return unionFeature;
    };

    const getBeneathPolygon = (lngLat: lngLatT, geoJsonFeatures: geoJsonFeatureT[]): geoJsonFeatureT => {
        const filteredByPosition = _.filter(geoJsonFeatures, (v) => booleanPointInPolygon([lngLat.lng, lngLat.lat], v));
        return filteredByPosition[0] ?? undefined;
    };

    const isPointInPolygons = (lngLat: lngLatT, geoJsonFeatures: geoJsonFeatureT[]): boolean =>
        _.some(geoJsonFeatures, (v) => booleanPointInPolygon([lngLat.lng, lngLat.lat], v));

    // used to update stored map
    const selfUpdate = (newProps: MapPropsT) => {
        dispatch(mapUpdate({ type: MAP_UPDATE, payload: { id, ...newProps } }));
    };

    // re-center map
    const reCenterMap = (farm: FarmT, fields: FarmSeasonFieldT[]): void => {
        if (!_.isEmpty(fields)) {
            MapService.focusPolygons({ polygons: _.map(fields, 'polygon') });
        } else if (farm && farm.center && farm.center.geometry) {
            MapService.center({
                latitude: farm.center.geometry?.coordinates[1],
                longitude: farm.center.geometry?.coordinates[0],
            });
        }
    };
    // reset polygons states when changing mode
    const resetCurrentPolygons = () => {
        setCurrentDrawingPolygon(undefined);
        setCurrentEditingPolygons(undefined);
        setCurrentSelectedPolygons([]);
        setStartCreating(false);
        setTemporaryDisableOpenData(false);
        setWarningModalOpen(false);
        setBlockedModalOpen(false);
        setRelationModalOpen(false);
        resetMergingGroup();
    };

    // if no store yet, init position from component props
    useEffect(() => {
        if (mapsFilter.length <= 0) {
            selfUpdate({ latitude, longitude, zoom });
        }
    }, [mapsFilter]);

    // if creatable=false => stop drawing and remove drawing cta buttons
    useEffect(() => {
        if (thisMap.creatablePolygons === false) {
            resetCurrentPolygons();
            selfUpdate({ drawing: false });
        }
        setDrawMode('simple_select');
    }, [thisMap.creatablePolygons]);

    // if selectable=false => de-select polygons
    useEffect(() => {
        if (thisMap.selectablePolygons === false) {
            resetCurrentPolygons();
            selfUpdate({ selectedPolygons: [] });
        }
        setDrawMode('simple_select');
    }, [thisMap.selectablePolygons]);

    // Trigger modal if not open
    useEffect(() => {
        if (thisMap.triggerModal?.modal === 'relations' && relationModalData === null) {
            setRelationModalData({
                field: thisMap.triggerModal.modalData.field,
                onConfirm: thisMap.triggerModal.modalData.onConfirm,
            });
            setRelationModalOpen(true);
        }
    }, [thisMap.triggerModal]);

    // Update selected polygons from store if they have been deselected from outside the Map component
    useEffect(() => {
        if (currentSelectedPolygons.length !== thisMap.selectedPolygons?.length) {
            setCurrentSelectedPolygons(thisMap.selectedPolygons?.length ? thisMap.selectedPolygons : []);
        }
        if (thisMap.mergeablePolygons) {
            mergingGroup();
        }
    }, [thisMap.selectedPolygons]);

    // if editable=false => stop editing
    useEffect(() => {
        if (thisMap.editablePolygons === false) {
            resetCurrentPolygons();
        } else {
            setTemporaryDisableOpenData(true);
        }
        setDrawMode('simple_select');
        setDrawModeOptions({ ...drawModeOptions, featureId: undefined });
    }, [thisMap.editablePolygons]);

    // if mergeable=false => stop editing
    useEffect(() => {
        if (thisMap.mergeablePolygons === false) {
            resetCurrentPolygons();
        }
    }, [thisMap.mergeablePolygons]);

    // if splitable=false => stop editing
    useEffect(() => {
        if (thisMap.splitablePolygons === false) {
            resetCurrentPolygons();
        } else {
            setDrawMode('split_polygon');
            setDrawModeOptions({ ...drawModeOptions, polygonsLayer: thisMap?.polygonsLayer });
        }
    }, [thisMap.splitablePolygons]);

    // add selected polygons in store
    useEffect(() => {
        selfUpdate({ selectedPolygons: currentSelectedPolygons });
    }, [currentSelectedPolygons]);

    // add selected parcel in store
    useEffect(() => {
        selfUpdate({ selectedParcel: currentSelectedParcel });
    }, [currentSelectedParcel]);

    // polygons layer changes
    useEffect(() => {
        // if no more polygons in layer, remove selected ones
        if (!thisMap.polygonsLayer?.length) {
            resetCurrentPolygons();
        }

        // add polygons layer to draw options (used for snapping)
        setDrawModeOptions({ ...drawModeOptions, polygonsLayer: thisMap?.polygonsLayer });
    }, [thisMap.polygonsLayer]);

    // add split polygon to the list
    useEffect(() => {
        if (thisMap.splitPolygons) {
            thisMap.polygonsLayer = [...(thisMap?.polygonsLayer ?? []), ...thisMap.splitPolygons];
        }
    }, [thisMap.splitPolygons]);

    // if map disabled => close popup & all current work
    useEffect(() => {
        resetCurrentPolygons();
    }, [thisMap.disabled]);

    // Fit bounds
    useEffect(() => {
        if (
            thisMap.fitBounds &&
            (thisMap.polygonsLayer?.length || thisMap.pointsLayer?.length || thisMap.circleLayer?.length)
        ) {
            const map = mapRef.current?.getMap();
            if (!map) {
                return;
            }
            map.fitBounds(thisMap.fitBounds, {
                padding: thisMap.animationOffset
                    ? {
                          top: 15,
                          bottom: thisMap.animationOffset.y,
                          left: thisMap.animationOffset.x,
                          right: 0,
                      }
                    : offsetX
                    ? {
                          top: 30,
                          bottom: 30,
                          left: offsetX + 100,
                          right: 0,
                      }
                    : 80,
            });
        }
    }, [thisMap.fitBounds]);

    // position of the map on load (with a tiny zoom-in animation)
    const onMapLoad = () => {
        if (initAnimation) {
            selfUpdate({
                latitude: latitude - 0.005,
                longitude: longitude + 0.008,
                zoom: zoom - (zoom > 14 ? 2 : 1),
            });
            setTimeout(() => {
                selfUpdate({ latitude, longitude, zoom });
                setLoading(false);
            }, 700);
        } else {
            setLoading(false);
        }
    };

    // update map position in store
    const onViewportChange = (data: Viewport) => {
        selfUpdate({
            latitude: data.latitude,
            longitude: data.longitude,
            zoom: data.zoom,
        });
    };

    // toggle drawing mode on one of the cta-btn click
    const onClickDraw = (ctaBtn: 'save' | 'start' | 'cancel') => {
        let draw = false;

        switch (ctaBtn) {
            case 'start':
                SegmentService.fieldManualDrawingInitiatedTrack({ baseline });
                setOverlappingPolygon(true);
                draw = true;
                break;
            case 'cancel':
                break;
            case 'save':
                // create
                if (currentDrawingPolygon) {
                    SegmentService.fieldManualDrawingFinalizedTrack({ baseline });
                    MapService.observer.notify('drawnedPolygon', currentDrawingPolygon);
                }

                // edit
                if (currentEditingPolygons) {
                    SegmentService.fieldManualModificationFinalizedTrack({ baseline });
                    MapService.observer.notify('editedPolygon', currentEditingPolygons.features[0]);
                }
                break;
        }

        setStartCreating(draw);
        setDrawMode(draw ? 'draw_polygon' : 'simple_select');
        setTemporaryDisableOpenData(draw);
        setCurrentDrawingPolygon(undefined);
        setCurrentEditingPolygons(undefined);

        if (!draw) {
            selfUpdate({ drawing: draw, editablePolygons: false, mergeablePolygons: false, splitablePolygons: false });
        } else {
            selfUpdate({ drawing: draw });
        }
    };

    // retrieve created/edited polygon
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDrawChange = (allFeatures: any) => {
        if (allFeatures.features?.length) {
            if (thisMap.splitablePolygons) {
                setOverlappingPolygon(allFeatures.features.length < 2);
                setCurrentEditingPolygons({ type: 'FeatureCollection', features: allFeatures.features });
            } else {
                const newPoly = allFeatures.features[0];
                const calculatedArea = area(newPoly);
                newPoly.properties.area = calculatedArea / 10000;
                newPoly.properties.area_source = 'drawn';

                const isIntersectingPolygons = intersectPolygon(
                    newPoly,
                    thisMap?.polygonsLayer?.filter((f: geoJsonFeatureT) => f.id.toString() !== newPoly.id.toString()) ??
                        [],
                );

                let isIntersectingMask = false;
                if (showMaskLayer) {
                    isIntersectingMask = intersectPolygon(newPoly, farmMask?.features ?? []);
                }

                setOverlappingPolygon(isIntersectingPolygons || isIntersectingMask);

                if (thisMap.editablePolygons && !startCreating) {
                    // edit
                    setCurrentEditingPolygons({ type: 'FeatureCollection', features: [newPoly] });
                } else {
                    // create
                    newPoly.id = new Date().valueOf().toString(); // "String ids will only work for features if they are convertible to integers. eg: '1234'"
                    setCurrentDrawingPolygon(newPoly);
                }
            }
        }
    };

    // select or de-select polygon after clicking on it
    const toggleSelectPolygon = (geoJsonFeature: geoJsonFeatureT) => {
        const deselect = inArrayPolygon(geoJsonFeature, currentSelectedPolygons);

        if (deselect) {
            // de-select
            const selectCondition = thisMap.deselectCondition?.(geoJsonFeature);

            if (selectCondition && selectCondition.selectType === 'blocked') {
                setBlockedModalData({ modal: selectCondition.modal });
                setBlockedModalOpen(true);

                return;
            }

            setCurrentSelectedPolygons(
                currentSelectedPolygons.filter((f) => f.id.toString() !== geoJsonFeature.id.toString()),
            );
            MapService.onClickPolygon({ clickedPolygon: geoJsonFeature, selected: false });
            MapService.observer.notify('deselectedPolygon', geoJsonFeature);
        } else {
            // select

            if (!thisMap.mergeablePolygons) {
                const response = thisMap.selectCondition?.(geoJsonFeature);

                if (response && response.selectType === 'warning') {
                    setWarningModalData({ modal: response.modal, polygon: geoJsonFeature });
                    setWarningModalOpen(true);

                    return;
                }

                if (response && response.selectType === 'relations') {
                    setRelationModalData({
                        field: (response as relationsConditionT).field,
                        polygon: geoJsonFeature,
                        onConfirm: (response as relationsConditionT).onConfirm,
                    });
                    setRelationModalOpen(true);

                    return;
                }
            }

            MapService.onClickPolygon({ clickedPolygon: geoJsonFeature, selected: true });
            setCurrentSelectedPolygons((oldPolygons) => [...oldPolygons, geoJsonFeature]);
            MapService.observer.notify('selectedPolygon', geoJsonFeature);
        }
    };

    // start editing (draw) polygon after clicking on it
    const initEditPolygon = (geoJsonFeature: geoJsonFeatureT) => {
        setCurrentEditingPolygons({ type: 'FeatureCollection', features: [geoJsonFeature] });
        setDrawModeOptions({ ...drawModeOptions, featureId: geoJsonFeature.id });
        setDrawMode('direct_select');
    };

    // click on a polygon layer (only used in select mode)
    const onClickPolygon =
        thisMap.selectablePolygons || thisMap.editablePolygons || thisMap.mergeablePolygons
            ? (event: InteractionEventT) => {
                  if (event.features && event.features?.length > 0) {
                      if (thisMap.editablePolygons) {
                          initEditPolygon(event.features[0]);
                      } else {
                          if (event.features[0].properties?.has_permanent_crop && !thisMap.canSelectPermanent) {
                              return undefined;
                          }

                          toggleSelectPolygon(event.features[0]);
                      }
                  }
              }
            : undefined;

    const onHoverOpenDataParcel = (event: InteractionEventT) => {
        if (!hasOpenDataParcels) {
            return;
        }
        if (
            thisMap.openData &&
            thisMap.zoom &&
            thisMap.zoom >= 10 &&
            !showMaskLayer &&
            event.features &&
            event.features.length > 0 &&
            !temporaryDisableOpenData
        ) {
            const hoveredPolygon = event.features[0];
            const nextHoveredStateId = hoveredPolygon.id;
            const isIntersecting = intersectPolygon(hoveredPolygon, thisMap.polygonsLayer ?? []);

            if (hoveredStateId !== nextHoveredStateId && !isIntersecting) {
                setHoveredStateId(nextHoveredStateId);
                setHover(true);
            } else if (isIntersecting) {
                setHoveredStateId(undefined);
                setHover(false);
            }
        }
    };

    const onLeaveOpenDataParcel = () => {
        if (!hasOpenDataParcels) {
            return;
        }
        if (thisMap.openData && hoveredStateId) {
            setHoveredStateId(undefined);
            setHover(false);
        }
    };

    const onHoverEmptySpace = (event: InteractionEventT) => {
        setCurrentEmptyPolygon(undefined);
        const mousePosition = event.lngLat;
        const isMouseHoveringPolygonLayer = isPointInPolygons(mousePosition, thisMap?.polygonsLayer ?? []);
        if (isMouseHoveringPolygonLayer) {
            setHoveringPolygonLayer(true);
            setHover(false);
        } else if (event.features && event.features.length > 0 && !temporaryDisableOpenData) {
            setHoveringPolygonLayer(false);
            setHover(true);
            let newDiffPolygon;
            const hoverFeature = event.features[0];
            const isIntersectingPolygons = intersectPolygon(hoverFeature, thisMap?.polygonsLayer ?? []);
            if (hoverFeature && isIntersectingPolygons) {
                const interFeatures = getInterPolygons(hoverFeature, thisMap?.polygonsLayer ?? []);
                const diffFeatures = getDiffPolygons(hoverFeature, interFeatures);
                if (diffFeatures?.length) {
                    newDiffPolygon = getBeneathPolygon(mousePosition, diffFeatures);
                }
            }
            if (newDiffPolygon) {
                setCurrentEmptyPolygon(newDiffPolygon);
            } else {
                setCurrentEmptyPolygon(hoverFeature);
            }
        }
    };

    const onLeaveEmptySpace = () => {
        setCurrentEmptyPolygon(undefined);
        setHoveringPolygonLayer(false);
        setHover(false);
    };

    const onClickEmptySpace =
        thisMap.openData && !temporaryDisableOpenData && !hoveringPolygonLayer
            ? async () => {
                  if (currentEmptyPolygon) {
                      setStartCreating(true);
                      const newPoly = currentEmptyPolygon;
                      const calculatedArea = area(newPoly);
                      newPoly.properties.area = calculatedArea / 10000;
                      newPoly.properties.area_source = 'drawn';
                      newPoly.geometry.type = 'Polygon';
                      newPoly.id = new Date().valueOf();
                      setCurrentDrawingPolygon(newPoly);
                      setCurrentSelectedParcel(newPoly);
                      MapService.observer.notify('clickedEmptyPolygon', newPoly);
                  }
              }
            : undefined;

    const onWarningModalConfirm = () => {
        if (warningModalData?.polygon) {
            setCurrentSelectedPolygons((oldPolygons) => [...oldPolygons, warningModalData.polygon]);
        }
        setWarningModalOpen(false);
        setWarningModalData(undefined);
    };

    const onWarningModalCancel = () => {
        setWarningModalOpen(false);
        setWarningModalData(undefined);
    };

    const onBlockedModalConfirm = () => {
        setBlockedModalOpen(false);
        setBlockedModalData(null);
    };

    const onRelationModalConfirm = (field_crops: FarmSeasonFieldCropDataT[]) => {
        relationModalData?.onConfirm({
            ...relationModalData.field,
            field_crops,
        });

        if (relationModalData?.polygon) {
            setCurrentSelectedPolygons((oldPolygons) => [...oldPolygons, relationModalData.polygon as geoJsonFeatureT]);
        }

        setRelationModalOpen(false);
        setRelationModalData(null);
    };

    const onRelationModalClose = () => {
        setRelationModalOpen(false);
        setRelationModalData(null);
    };

    // click on an open-data parcel
    const onClickOpenDataParcel = async (event: any) => {
        if (!hasOpenDataParcels) {
            return;
        }
        const newPoly = event.features[0];
        const isIntersectingPolygons = intersectPolygon(newPoly, thisMap?.polygonsLayer ?? []);

        let isIntersectingMask = false;
        if (showMaskLayer) {
            isIntersectingMask = intersectPolygon(newPoly, farmMask?.features ?? []);
        }

        const isIntersecting = isIntersectingPolygons || isIntersectingMask;

        if (event.features?.length > 0 && !isIntersecting) {
            setStartCreating(true);
            newPoly.properties.area_source = 'opendata';
            newPoly.id = new Date().valueOf().toString(); // "String ids will only work for features if they are convertible to integers. eg: '1234'"
            setCurrentDrawingPolygon(newPoly);
            setCurrentSelectedParcel(newPoly);
            MapService.observer.notify('clickedOpenDataPolygon', newPoly);
        }
    };

    // click on the edit polygon button
    const onClickEdit = () => {
        SegmentService.fieldManualModificationStartedTrack({ baseline });
        setTemporaryDisableOpenData(true);
        selfUpdate({ editablePolygons: true, drawing: true });
    };

    // click on the merge polygons button
    const onClickMerge = () => {
        SegmentService.fieldManualMergeStartedTrack({ baseline });
        setOverlappingPolygon(true);
        setTemporaryDisableOpenData(true);
        selfUpdate({ mergeablePolygons: true });
    };

    const onCancelMerge = () => {
        selfUpdate({ mergeablePolygons: false });
    };

    const onFinishMerge = () => {
        SegmentService.fieldManualMergeFinalizedTrack({ baseline });
        if (currentSelectedPolygons?.length && currentSelectedPolygons.length >= 2) {
            // merge those fields into a new one
            const newPoly = unionContiguousPolygons(currentSelectedPolygons);
            onClickDraw('save');
            MapService.observer.notify('mergedPolygon', {
                newPolygon: newPoly,
                oldPolygons: currentSelectedPolygons,
            });
        }
    };

    // show/hide the polygons that can be merged together
    const mergingGroup = () => {
        resetMergingGroup();
        setOverlappingPolygon(true);
        if (currentSelectedPolygons?.length) {
            if (currentSelectedPolygons.length >= 2) setOverlappingPolygon(false);
            const mergeablePolygonsIds = [] as number[];
            currentSelectedPolygons.forEach((s) => {
                getContiguousPolygons(s, thisMap.polygonsLayer ?? [])?.forEach((c) => {
                    if (!mergeablePolygonsIds.includes(c.id)) mergeablePolygonsIds.push(c.id);
                });
            });
            thisMap.polygonsLayer?.forEach((v) => {
                if (!mergeablePolygonsIds.includes(v.id)) v.properties.merging_exclusion = true;
            });
        }
    };

    const resetMergingGroup = () => {
        if (thisMap.polygonsLayer?.length) {
            thisMap.polygonsLayer.forEach((v) => {
                delete v.properties.merging_exclusion;
            });
        }
    };

    const onClickSplit = () => {
        SegmentService.fieldManualSplitStartedTrack({ baseline });
        setOverlappingPolygon(true);
        setTemporaryDisableOpenData(true);
        selfUpdate({ splitablePolygons: true, drawing: true });
    };

    const onFinishSplit = () => {
        SegmentService.fieldManualSplitFinalizedTrack({ baseline });
        if (!thisMap?.splitablePolygons || !currentEditingPolygons?.features?.length) {
            return;
        }
        const polygonsToRemove = [] as geoJsonFeatureT[];
        currentEditingPolygons.features.forEach((v) => {
            if (v?.properties?.originalFeatureId) {
                const originalFeatures = thisMap?.polygonsLayer?.filter(
                    (p) => p.id === v.properties.originalFeatureId && !inArrayPolygon(p, polygonsToRemove),
                );
                if (originalFeatures?.length) {
                    polygonsToRemove.push(originalFeatures[0]);
                }
            }
        });
        const drawing = false;
        const newPolygonsLayer = [
            ...(thisMap?.polygonsLayer?.filter((p) => !inArrayPolygon(p, polygonsToRemove)) ?? []),
            ...currentEditingPolygons.features,
        ];

        setStartCreating(drawing);
        setDrawModeOptions({ ...drawModeOptions, polygonsLayer: newPolygonsLayer });
        setDrawMode('simple_select');
        setTemporaryDisableOpenData(drawing);
        setCurrentDrawingPolygon(undefined);
        setCurrentEditingPolygons(undefined);
        selfUpdate({
            drawing,
            // polygonsToRemove,
            // splitPolygons: currentEditingPolygons.features,
            splitablePolygons: false,
            // polygonsLayer: newPolygonsLayer,
        });
        MapService.observer.notify('splittedPolygon', {
            oldPolygons: polygonsToRemove,
            newPolygons: currentEditingPolygons.features,
        });
    };

    // click on the back-to-home button
    const onClickHome = () => {
        if (currentFarm) reCenterMap(currentFarm, currentFields);
    };

    // easing used in map animations
    const easingFunctions = {
        easeInCubic(s: number) {
            // start slow and gradually increase speed
            return s * s * s;
        },
        easeOutQuint(s: number) {
            // start fast with a long, slow wind-down
            return 1 - Math.pow(1 - s, 5);
        },
        easeInOutCirc(s: number) {
            // slow start and finish with fast middle
            return s < 0.5
                ? (1 - Math.sqrt(1 - Math.pow(2 * s, 2))) / 2
                : (Math.sqrt(1 - Math.pow(-2 * s + 2, 2)) + 1) / 2;
        },
    };

    // map animations settings
    const animationOptions: AnimationOptions = {
        duration: thisMap.animationDuration,
        easing: easingFunctions.easeOutQuint,
        offset: [offsetX || 0, 0],
        animate: true,
        essential: true,
    };

    // MapGL itself, will be shown within MapView
    const baseMapGL = (
        <MapGL
            ref={mapRef}
            accessToken={MAPBOX_ACCESS_TOKEN}
            latitude={thisMap.latitude || 0}
            logoPosition={interactive ? 'top-left' : 'bottom-right'}
            longitude={thisMap.longitude || 0}
            mapStyle={MAPBOX_BASE_STYLE}
            maxZoom={15}
            minZoom={interactive ? 9 : 4}
            pitchWithRotate={false}
            style={style || { width: '100%', height: '100%' }}
            viewportChangeMethod="flyTo"
            viewportChangeOptions={animationOptions}
            zoom={thisMap.zoom || 0}
            onLoad={onMapLoad}
            onViewportChange={interactive ? onViewportChange : undefined}
        >
            {children}

            {/* ************************** MASK LAYER ************************** */}
            {
                // mask data source
                // ...from URL because too large data for MapBox library otherwise
                // (show at "edit" mode, when opendata are usually shown)
                showMaskLayer && thisMap.openData && (
                    <>
                        <Source data={`${baseUrlSourceData}/farm-boundaries-mask`} id="maskLayer" type="geojson" />
                        <Layer
                            id="maskLayerFill"
                            paint={{
                                'fill-color': '#000000',
                                'fill-opacity': thisMap.mergeablePolygons || thisMap.splitablePolygons ? 0 : 0.5,
                            }}
                            source="maskLayer"
                            type="fill"
                        />
                        <Layer
                            id="maskLayerFillPattern"
                            paint={{
                                'fill-pattern': 'smallLinesRed',
                                'fill-opacity':
                                    thisMap.mergeablePolygons || thisMap.splitablePolygons || !thisMap.drawing
                                        ? 0
                                        : 0.5,
                            }}
                            source="maskLayer"
                            type="fill"
                        />
                        <Layer
                            id="maskLayerLine"
                            paint={{
                                'line-color': '#ff3b3b',
                                'line-opacity': 0.65,
                                'line-width': 5,
                                // 'line-gap-width': 1,
                                // 'line-offset': 1,
                                'line-dasharray': [1.8, 0.9],
                            }}
                            source="maskLayer"
                            type="line"
                        />
                    </>
                )
            }
            {/* ************************** /MASK LAYER ************************** */}

            {/* ************************** POLYGONS LAYER ************************** */}
            {thisMap.polygonsLayer?.length && (
                <>
                    <Source
                        data={{ type: 'FeatureCollection', features: thisMap.polygonsLayer }}
                        id="polygonsLayer"
                        type="geojson"
                    />
                    {/* polygons fill styling */}
                    <Layer
                        filter={
                            thisMap.mergeablePolygons || thisMap.splitablePolygons || thisMap.editablePolygons
                                ? [
                                      'none',
                                      ['has', 'merging_exclusion'],
                                      ['has', 'previous_has_agroforestry'],
                                      ['has', 'is_permanent'],
                                  ]
                                : ['!', ['has', 'merging_exclusion']]
                        }
                        id="polygonsFillLayer"
                        paint={{
                            'fill-color': [
                                'case',
                                ['boolean', ['feature-state', 'selected'], false],
                                theme.palette.warning.light,
                                ['!', thisMap.selectablePolygons],
                                [
                                    'case',
                                    ['has', 'color'],
                                    ['get', 'color'],
                                    [
                                        'any',
                                        ['has', 'is_permanent'],
                                        [
                                            'all',
                                            ['==', thisMap.canSelectPermanent, false],
                                            ['has', 'has_permanent_crop'],
                                        ],
                                    ],
                                    '#000000',
                                    '#ffffff',
                                ],
                                [
                                    'any',
                                    ['has', 'is_permanent'],
                                    ['all', ['==', thisMap.canSelectPermanent, false], ['has', 'has_permanent_crop']],
                                ],
                                '#000000',
                                '#ffffff',
                            ],
                            'fill-opacity': [
                                'case',
                                ['boolean', ['feature-state', 'selected'], false],
                                ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.85],
                                ['case', ['boolean', ['feature-state', 'hover'], false], 0.85, 0.7],
                            ],
                        }}
                        source="polygonsLayer"
                        type="fill"
                        onClick={onClickPolygon}
                        onLeave={() => {
                            setHoveredPolygonLayerId(null);
                            mapPopupRef.current?.closePopup();
                        }}
                        onHover={(e: any) => {
                            const map = mapRef.current?.getMap();
                            if (!map) {
                                return;
                            }
                            const feature = e.features?.[0] as geoJsonFeatureT;
                            setHoveredPolygonLayerId(feature?.id ?? null);
                            mapPopupRef.current?.openPopupOverFeature({
                                map,
                                feature: feature,
                                popupId: feature?.id,
                            });
                        }}
                    />
                    {/* polygons border styling */}
                    <Layer
                        id="polygonsLineLayer"
                        paint={{
                            'line-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#555',
                                ['boolean', ['feature-state', 'selected'], false],
                                theme.palette.warning.light,
                                ['!', thisMap.selectablePolygons],
                                [
                                    'case',
                                    ['has', 'color'],
                                    ['get', 'color'],
                                    [
                                        'any',
                                        ['has', 'is_permanent'],
                                        [
                                            'all',
                                            ['==', thisMap.canSelectPermanent, false],
                                            ['has', 'has_permanent_crop'],
                                        ],
                                    ],
                                    '#000000',
                                    '#ffffff',
                                ],
                                [
                                    'any',
                                    ['has', 'is_permanent'],
                                    ['all', ['==', thisMap.canSelectPermanent, false], ['has', 'has_permanent_crop']],
                                ],
                                '#000000',
                                '#ffffff',
                            ],
                            'line-width': [
                                'case',
                                ['boolean', ['feature-state', 'selected'], false],
                                1.5,
                                ['boolean', ['feature-state', 'selected'], false],
                                4,
                                1.5,
                            ],
                        }}
                        source="polygonsLayer"
                        type="line"
                    />
                    {/* polygons fill pattern styling */}
                    <Layer
                        filter={[
                            'any',
                            ['has', 'is_accompanied'],
                            ['has', 'tillagePattern'],
                            ['has', 'soilAcidityPattern'],
                            ['has', 'has_agroforestry'],
                        ]}
                        id="polygonsFillPatternLayer"
                        paint={{
                            'fill-pattern': [
                                'case',
                                !thisMap.selectablePolygons ? ['has', 'tillagePattern'] : false,
                                ['get', 'tillagePattern'],
                                !thisMap.selectablePolygons ? ['has', 'soilAcidityPattern'] : false,
                                ['get', 'soilAcidityPattern'],
                                !thisMap.selectablePolygons
                                    ? [
                                          'all',
                                          ['has', 'has_agroforestry'],
                                          ['!', ['has', 'tillagePattern']],
                                          ['!', ['has', 'soilAcidityPattern']],
                                      ]
                                    : false,
                                'trees',
                                '',
                            ],
                            'fill-opacity': 0.5,
                        }}
                        source="polygonsLayer"
                        type="fill"
                    />
                    {/* polygons center labels */}
                    <Layer
                        filter={['has', 'label']}
                        id="polygonsLabelsLayer"
                        layout={{
                            'text-field': [
                                'concat',
                                ['get', 'label'],
                                ' - ',
                                ['number-format', ['get', 'area'], { 'min-fraction-digits': 3 }],
                                ' ha',
                                '\n',
                                ['get', 'crops'],
                            ], // ['get', 'area'],
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 11,
                        }}
                        paint={{
                            'text-color': '#ffffff',
                            'text-halo-color': '#000',
                            'text-halo-width': 1,
                        }}
                        source="polygonsLayer"
                        type="symbol"
                    />
                    {/* selected polygons states */}
                    {currentSelectedPolygons.map((geoJsonFeature) => (
                        <FeatureState
                            key={geoJsonFeature.id}
                            id={geoJsonFeature.id}
                            source="polygonsLayer"
                            sourceLayer={null as any}
                            state={{ selected: true, hover: geoJsonFeature.id === hoveredPolygonLayerId }}
                        />
                    ))}
                    {/* hovered polygon state */}
                    {hoveredPolygonLayerId ? (
                        <FeatureState
                            key={`${hoveredPolygonLayerId}-hover`}
                            id={`${hoveredPolygonLayerId}`}
                            source="polygonsLayer"
                            sourceLayer={null as any}
                            state={{
                                hover: true,
                                selected: !!currentSelectedPolygons.find((sp) => sp.id === hoveredPolygonLayerId),
                            }}
                        />
                    ) : null}
                </>
            )}
            {/* ************************** /POLYGONS LAYER ************************** */}

            {/* ************************** POINTS LAYER ************************** */}
            {thisMap.pointsLayer?.length && (
                <>
                    <Source
                        data={{ type: 'FeatureCollection', features: thisMap.pointsLayer }}
                        id="pointsLayer"
                        type="geojson"
                    />
                    <Layer
                        id="pointsCircleLayer"
                        paint={{ 'circle-radius': 4, 'circle-color': '#ffffff' }}
                        source="pointsLayer"
                        type="circle"
                    />
                </>
            )}
            {/* ************************** /POINTS LAYER ************************** */}

            {/* ************************** CIRCLE LAYER ************************** */}
            {thisMap.circleLayer?.length && (
                <>
                    <Source
                        data={{ type: 'FeatureCollection', features: thisMap.circleLayer }}
                        id="circleLayer"
                        type="geojson"
                    />
                    <Layer
                        id="circleLayerBorder"
                        paint={{ 'line-color': '#ffffff', 'line-width': 4 }}
                        source="circleLayer"
                        type="line"
                    />
                    <Layer
                        id="circleLayerFill"
                        paint={{ 'fill-color': '#ffffff', 'fill-opacity': 0.3 }}
                        source="circleLayer"
                        type="fill"
                    />
                </>
            )}
            {/* ************************** /CIRCLE LAYER ************************** */}

            {/* ************************** OPEN DATA LAYER ************************** */}
            <Source
                id="openDataLayer"
                type="vector"
                url={MAPBOX_OPENDATA_TILESET}
                // generateId={true} // make sure ids are unique
                minzoom={12}
                maxzoom={15}
            />
            <Layer
                id="openDataFillLayer"
                paint={{
                    'fill-color': '#ffffff',
                    'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
                }}
                source="openDataLayer"
                source-layer="parcels"
                type="fill"
                onClick={onClickOpenDataParcel}
                onHover={onHoverOpenDataParcel}
                onLeave={onLeaveOpenDataParcel}
            />
            <Layer
                id="openDataLineLayer"
                paint={{
                    'line-color': '#ffffff',
                    'line-width': 2,
                    'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
                }}
                source="openDataLayer"
                source-layer="parcels"
                type="line"
            />
            {hoveredStateId && (
                <FeatureState
                    id={hoveredStateId}
                    source="openDataLayer"
                    sourceLayer="parcels"
                    state={{ hover: true }}
                />
            )}
            {/* ************************** /OPEN DATA LAYER ************************** */}

            {/* ************************** EMPTY SPACE POLYGON LAYER ************************** */}
            {showMaskLayer && thisMap.openData && (
                <>
                    <Source data={`${baseUrlSourceData}/farm-boundaries`} id="emptySpaceLayer" type="geojson" />
                    <Layer
                        id="emptySpaceFillLayer"
                        paint={{ 'fill-color': '#ffffff', 'fill-opacity': 0 }}
                        source="emptySpaceLayer"
                        type="fill"
                        onClick={onClickEmptySpace}
                        onHover={onHoverEmptySpace}
                        onLeave={onLeaveEmptySpace}
                    />
                    <Source
                        id="emptyPolygons"
                        data={{ type: 'FeatureCollection', features: currentEmptyPolygon ? [currentEmptyPolygon] : [] }}
                        type="geojson"
                    />
                    <Layer
                        id="emptyPolygonsFillLayer"
                        paint={{ 'fill-color': '#ffffff', 'fill-opacity': 0.3 }}
                        source="emptyPolygons"
                        type="fill"
                    />
                    <Layer
                        id="emptyPolygonsLineLayer"
                        paint={{ 'line-color': '#ffffff', 'line-width': 2 }}
                        source="emptyPolygons"
                        type="line"
                    />
                </>
            )}
            {/* ************************** /EMPTY SPACE POLYGON LAYER ************************** */}

            {interactive && (
                <NavigationControl position="top-right" showCompass={false} visualizePitch={false} showZoom />
            )}

            {thisMap.drawing === true && (
                <Draw
                    boxSelect={false}
                    combineFeaturesControl={false}
                    data={currentEditingPolygons || null}
                    displayControlsDefault={false}
                    lineStringControl={false}
                    mode={drawMode}
                    modeOptions={drawModeOptions}
                    modes={{
                        ...MapboxDraw.modes,
                        draw_polygon: DrawPolygonWithSnap, // create a new polygon from scratch
                        direct_select: DirectSelectWithSnap, // edit geometry of an existing polygon (used just after drawing OR when editing an "old" field)
                        simple_select: SimpleSelectSingleClick, // select an existing field to edit, then switch to direct_select
                        split_polygon: SplitPolygonMode, // draw a line in a polygon to split it
                    }}
                    pointControl={false}
                    polygonControl={false}
                    styles={getMapBoxDrawStyles(theme)}
                    trashControl={false}
                    uncombineFeaturesControl={false}
                    onChange={onDrawChange}
                />
            )}

            {/* ************************** FIELD POPUP ************************** */}
            <MapPopup ref={mapPopupRef} mapRef={mapRef} />
        </MapGL>
    );

    // the styling is in the view
    return (
        <MapView
            MapGL={baseMapGL}
            warningModalData={warningModalData}
            warningModalOpen={warningModalOpen}
            blockedModalData={blockedModalData}
            blockedModalOpen={blockedModalOpen}
            createMode={thisMap.creatablePolygons}
            disabled={thisMap.disabled}
            drawing={thisMap.drawing}
            editMode={thisMap.editablePolygons}
            mergeMode={thisMap.mergeablePolygons}
            splitMode={thisMap.splitablePolygons}
            hasPolygons={!!thisMap.polygonsLayer?.length}
            hover={hover}
            id={id}
            interactive={interactive}
            loading={loading}
            overlappingPolygon={overlappingPolygon}
            relationModalData={relationModalData}
            relationModalOpen={relationModalOpen}
            selectMode={thisMap.selectablePolygons}
            startCreating={startCreating}
            t={t}
            onClickHome={onClickHome}
            onClickDraw={onClickDraw}
            onClickEdit={onClickEdit}
            onClickMerge={onClickMerge}
            onClickSplit={onClickSplit}
            onCancelMerge={onCancelMerge}
            onFinishMerge={onFinishMerge}
            onFinishSplit={onFinishSplit}
            onWarningModalCancel={onWarningModalCancel}
            onWarningModalConfirm={onWarningModalConfirm}
            onBlockedModalConfirm={onBlockedModalConfirm}
            onRelationModalClose={onRelationModalClose}
            onRelationModalConfirm={onRelationModalConfirm}
        />
    );
};

export default Map;
