import formatCurrencyNumber from '@services/formatCurrencyNumber/formatCurrencyNumber';
import formatNumber from '@utils/formatNumber';
import { t } from 'i18next';
import { CertificateTablePropsT } from './CertificateTableTypes';
import getMinCertificatesEarningAmount from '@utils/getMinCertificatesEarningAmount';

const useCertificateTableLogic = ({
    farmSeasonCertificates,
    firstHarvestYear,
    previousProductPlanId,
}: CertificateTablePropsT) => {
    const sumListForKey = <T extends string>(list: { [k in T]: unknown }[], key: T) =>
        list.reduce((acc, curr) => acc + Number(curr[key] || 0), 0);

    const currencyISO = farmSeasonCertificates?.[0]?.currency;
    const certificatePrice = getMinCertificatesEarningAmount({
        certificatesCount: 1,
        previousProductPlanId: previousProductPlanId,
        currencyIso: currencyISO,
    });
    const minCertificatePrice = formatCurrencyNumber(certificatePrice, currencyISO);

    const numberIsValid = (val: number) => {
        return val && !isNaN(val);
    };

    // Make rows ready to display data.
    const rows = farmSeasonCertificates?.map((certificate, i) => ({
        year: isNaN(firstHarvestYear) ? '-' : `${firstHarvestYear + i}`,
        certificates: formatNumber(certificate.certificates),
        inBuffer: formatNumber(certificate.in_buffer),
        onMarket: formatNumber(certificate.on_market),
        bufferAdjustment:
            certificate.buffer_adjustment == 0
                ? '0'
                : numberIsValid(certificate.buffer_adjustment)
                ? formatNumber(-certificate.buffer_adjustment)
                : '',
        avgPrice: certificate.avg_price
            ? formatCurrencyNumber(certificate.avg_price, currencyISO)
            : certificate.on_market >= 0 && certificate.avg_price
            ? `${t('constants.min')} ${minCertificatePrice}`
            : '',
        revenue: certificate.avg_price
            ? formatCurrencyNumber(certificate.on_market * certificate.avg_price, currencyISO)
            : certificate.on_market >= 0 && certificate.avg_price
            ? `${t('constants.min')} ${formatCurrencyNumber(certificatePrice * certificate.on_market, currencyISO)}`
            : '',
        vcVcn:
            certificate.value_chain_percentage || certificate.no_value_chain_percentage
                ? `${certificate.value_chain_percentage ?? 0}% / ${certificate.no_value_chain_percentage ?? 0}%`
                : null,
        purchaseOrder: certificate.xero_purchase_order_link,
    }));

    // Sort the rows in descending order based on the year
    rows.sort((a, b) => {
        const yearA = parseInt(a.year);
        const yearB = parseInt(b.year);
        return yearB - yearA;
    });

    type CertificateKeysT = keyof (typeof farmSeasonCertificates)[0];

    // Make total ready to display
    const totalCertificates = sumListForKey<CertificateKeysT>(farmSeasonCertificates, 'certificates');
    const totalBufferAdjustment = sumListForKey<CertificateKeysT>(farmSeasonCertificates, 'buffer_adjustment');
    const totalHeldInBuffer = sumListForKey<CertificateKeysT>(farmSeasonCertificates, 'in_buffer');
    const totalOnMarket = sumListForKey<CertificateKeysT>(farmSeasonCertificates, 'on_market');
    const averageVc =
        sumListForKey<CertificateKeysT>(farmSeasonCertificates, 'value_chain_percentage') /
        (farmSeasonCertificates.filter((r) => r.value_chain_percentage)?.length || 1);
    const averageVcn =
        sumListForKey<CertificateKeysT>(farmSeasonCertificates, 'no_value_chain_percentage') /
        (farmSeasonCertificates.filter((r) => r.no_value_chain_percentage)?.length || 1);
    const hasAnyVcVcn = !!(averageVc || averageVcn);
    const totalVcVcn = averageVc || averageVcn ? `${averageVc}% / ${averageVcn}%` : null;
    const hasAnyPurchaseOrderValue = !!farmSeasonCertificates.find((r) => r.xero_purchase_order_link);

    let totalCertificatesEarningAmount = 0;
    let totalCertificatesAvgPrice = 0;
    let totalYearsWithAvgPrice = 0;
    farmSeasonCertificates.forEach((farmSeasonCertificate) => {
        const avgPrice = farmSeasonCertificate.avg_price;
        const onMarketCertificates = farmSeasonCertificate.on_market;
        if (onMarketCertificates >= 0 && avgPrice) {
            totalYearsWithAvgPrice += 1;
            totalCertificatesEarningAmount += avgPrice * onMarketCertificates;
            totalCertificatesAvgPrice += Number(avgPrice);
        }
    });
    totalCertificatesAvgPrice = totalCertificatesAvgPrice / totalYearsWithAvgPrice;
    const totalRevenue = formatCurrencyNumber(totalCertificatesEarningAmount, currencyISO);
    const averageAvgPrice = formatCurrencyNumber(totalCertificatesAvgPrice, currencyISO);

    return {
        rows,
        averageAvgPrice,
        totalCertificates,
        totalOnMarket,
        totalBufferAdjustment,
        totalHeldInBuffer,
        hasAnyVcVcn,
        totalVcVcn,
        totalRevenue,
        hasAnyPurchaseOrderValue,
    };
};

export default useCertificateTableLogic;
