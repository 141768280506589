import NotificationButton from '../NotificationButton/NotificationButton';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import NotificationFooter from '../NotificationFooter/NotificationFooter';
import NotificationSnackbar from '../NotificationSnackbar/NotificationSnackbar';
import NotificationList from '../NotificationList/NotificationList';

import useNotificationContainerLogic from './NotificationContainer.logic';
import useNotificationContainerStyle from './NotificationContainer.style';

const NotificationContainer = () => {
    const {
        unreadNotificationsCount,
        notificationsCount,
        showOnlyUnread,
        setShowOnlyUnread,
        notifications,
        fetchLoading,
        fetchMoreLoading,
        markAllAsReadLoading,
        markAsReadLoadings,
        canFetchMore,
        popoverAnchor,
        handleClosePanel,
        handleOpenPanel,
        handleMarkAsRead,
        handleFetchMore,
        handleMarkAllAsRead,
    } = useNotificationContainerLogic();

    const { classes } = useNotificationContainerStyle();

    return (
        <>
            <NotificationButton
                popoverAnchor={popoverAnchor}
                unreadCount={unreadNotificationsCount}
                onClosePanel={handleClosePanel}
                onOpenPanel={handleOpenPanel}
            >
                <div className={classes.notificationPanel}>
                    <NotificationHeader
                        hasUnreadNotifications={!!unreadNotificationsCount}
                        markAllAsReadLoading={markAllAsReadLoading}
                        showOnlyUnread={showOnlyUnread}
                        onMarkAllAsRead={handleMarkAllAsRead}
                        onShowOnlyUnread={(showing) => {
                            setShowOnlyUnread(!showing);
                        }}
                    />
                    <NotificationList
                        fetchLoading={fetchLoading}
                        handleClosePanel={handleClosePanel}
                        handleMarkAsRead={handleMarkAsRead}
                        markAsReadLoadings={markAsReadLoadings}
                        notifications={notifications}
                        notificationsCount={notificationsCount}
                        showOnlyUnread={showOnlyUnread}
                        unreadNotificationsCount={unreadNotificationsCount}
                    />
                    <NotificationFooter
                        fetchMoreLoading={fetchMoreLoading}
                        visible={canFetchMore}
                        onFetchMore={handleFetchMore}
                    />
                </div>
            </NotificationButton>
            <NotificationSnackbar />
        </>
    );
};

export default NotificationContainer;
