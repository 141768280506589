import { Card, CircularProgress, TextField } from '@mui/material';

import useInputCardStyle from './InputCardView.style';
import { InputCardViewPropsT } from './InputCardViewTypes';

const InputCardView = ({
    name,
    disabled,
    id,
    error,
    fullWidth,
    helperText,
    value,
    children,
    InputProps,
    className,
    loading = false,
    type,
    autoFocus,
    onChange,
    onFocus,
}: InputCardViewPropsT): JSX.Element => {
    const { classes } = useInputCardStyle({ disabled: !!disabled });

    return (
        <Card className={`${classes.card} ${className}`} elevation={0} variant="elevation">
            <div className={classes.container}>
                <div className={classes.textSection}>
                    <div className={classes.text}>{children}</div>
                </div>
                <div className={classes.inputContainer}>
                    {loading ? (
                        <CircularProgress className={classes.progress} size="20px" />
                    ) : (
                        <TextField
                            InputProps={{
                                ...InputProps,
                            }}
                            autoFocus={autoFocus}
                            className={classes.textField}
                            disabled={disabled}
                            error={error}
                            fullWidth={fullWidth}
                            helperText={helperText}
                            id={id}
                            name={name}
                            type={type}
                            value={value}
                            variant="outlined"
                            onChange={onChange}
                            onFocus={onFocus}
                            onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        />
                    )}
                </div>
            </div>
        </Card>
    );
};

export default InputCardView;
