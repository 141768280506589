import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';
import { MenuItem, Select } from '@mui/material';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectFieldsSortStyle from './SelectEntitySort.style';
import { SelectEntitySortFunctionsT, SelectEntitySortPropsT } from './SelectEntitySortTypes';

const SelectEntitySort = <
    R extends SelectEntitySortFunctionsT<T>,
    E extends { id?: number | string },
    T extends { entity: E },
>({
    onEntitiesSorted,
    sortableData,
    sortFunctions,
    initialSortKey,
}: SelectEntitySortPropsT<R, E, T>) => {
    const { t } = useTranslation();
    const { classes } = useSelectFieldsSortStyle();
    const [sortValue, setSortValue] = useState<string>('');
    const [lastSortValue, setLastSortValue] = useState<string | null>(null);
    const [lastSortOrder, setLastSortOrder] = useState<E[]>([]); // keep list of ordered entities (findable by refs)

    useEffect(() => {
        // fields not initialized, nothing to sort
        if (sortableData === null) {
            return;
        }

        // set initial sort to A-Z if we have fields.
        if (lastSortValue === null && sortableData.length > 0) {
            setLastSortValue('');
            if (initialSortKey) {
                setSortValue((initialSortKey as string) ?? '');
            }
            return; // update sortValue will trigger again this hook for filter
        }

        // if same value, it means the fields is updated.
        if (lastSortValue !== sortValue) {
            setLastSortValue(sortValue);
        } else if (sortValue !== '' && lastSortOrder.length <= sortableData.length) {
            // When fields update, we remove the sort selected (and keep last sort order). Except field has been deleted
            setSortValue(''); // trigger again this hook with empty sort selected
            return;
        }

        if (sortValue && sortFunctions) {
            const newSortedEntities = [...(sortableData || [])].sort(sortFunctions[sortValue]).map(({ entity }) => {
                if (entity.id) {
                    return entity;
                }

                // add a hidden "_sortId" to retrieve those data if they don't have ids
                (entity as E & { _sortId?: Readonly<string> })._sortId = uniqueId();

                return entity;
            });

            setLastSortOrder(newSortedEntities);
            // avoid reordering from outside with [...]
            onEntitiesSorted([...newSortedEntities]);
        } else {
            // empty sort selected, we keep last order
            const remainingEntities = sortableData.map((item) => item.entity) as Array<
                E & { _sortId?: Readonly<string> }
            >;
            const newSortedEntities = [
                ...((lastSortOrder as Array<E & { _sortId?: Readonly<string> }>)
                    .map(({ _sortId: _lastOrderId, id: lastOrderId }) => {
                        const entityIndex =
                            // find sorted item from his id if exist. Else from his _sortId
                            remainingEntities.findIndex((remainingEntity) => remainingEntity.id === lastOrderId) ??
                            remainingEntities.findIndex((remainingEntity) => remainingEntity._sortId === _lastOrderId);
                        return entityIndex !== -1 ? remainingEntities.splice(entityIndex, 1)[0] : null;
                    })
                    .filter((f) => !!f) as E[]),
                ...remainingEntities,
            ];

            onEntitiesSorted(newSortedEntities);
        }
    }, [sortValue, sortableData]);

    return (
        <Select
            className={classes.SelectFieldsSort}
            value={sortValue || ''}
            onChange={(v) => setSortValue(v.target.value)}
            renderValue={(v) => {
                return v ? (
                    <>
                        <SortIcon className={classes.sortIcon} /> {t(`components.select-fields-sort.selected.${v}`)}{' '}
                        {v.split('-').pop() === 'asc' && <ArrowDropUp />}
                        {v.split('-').pop() === 'desc' && <ArrowDropDown />}
                    </>
                ) : (
                    <>
                        <SortIcon className={classes.sortIcon} />
                        {t('components.select-fields-sort.sort')}
                    </>
                );
            }}
            displayEmpty
        >
            {Object.keys(sortFunctions ?? {})?.map((key) => (
                <MenuItem key={key} value={key}>
                    {t(`components.select-fields-sort.list.${key}`)}{' '}
                    {key.split('-').pop() === 'asc' ? (
                        <ArrowDropUp />
                    ) : key.split('-').pop() === 'desc' ? (
                        <ArrowDropDown />
                    ) : null}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectEntitySort;
