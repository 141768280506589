import LoadingButton from '@components/LoadingButton/LoadingButton';
import TextLink from '@components/TextLink/TextLink';

import useTwoButtonFooterViewStyle from './TwoButtonFooterView.style';
import { TwoButtonFooterViewPropsT } from './TwoButtonFooterViewTypes';

const TwoButtonFooterView = ({
    leftText,
    leftOnClick,
    rightText,
    rightOnCLick,
    rightDisabled,
    type,
    rightLoading,
    leftLoading,
    className,
    rightButtonOverride,
}: TwoButtonFooterViewPropsT): JSX.Element => {
    const { classes } = useTwoButtonFooterViewStyle();

    return (
        <div className={`${classes.container} ${className}`}>
            <TextLink loaderPosition="right" loading={leftLoading} onClick={rightLoading ? undefined : leftOnClick}>
                {leftText}
            </TextLink>
            {rightButtonOverride ??
                (rightText && (
                    <LoadingButton
                        disabled={rightDisabled}
                        loading={rightLoading}
                        text={rightText}
                        type={type}
                        onClick={rightOnCLick}
                    />
                ))}
        </div>
    );
};

export default TwoButtonFooterView;
