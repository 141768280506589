import { makeStyles } from 'tss-react/mui';

const useHomePageStyle = makeStyles()((theme) => ({
    dashboardPageWrapper: {
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr 1fr',
        gridTemplateRows: '80px calc(100vh - (80px + 10px)) 10px',
        gridTemplateAreas: `
    "appbar appbar appbar"
    "sidebar actionSection actionSection"
    `,
        padding: '0 0 0 10px',
    },
    appBarWrapper: {
        gridArea: 'appbar',
    },
    sideBarWrapper: {
        width: '220px',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        '& .avatarMini': {
            display: 'none',
        },
        '&.sideBarClose': {
            width: '50px',
            '& .sideBarNavText': {
                display: 'none',
            },
        },
        '&.simulationClose': {
            '& .simulationActionOff': {
                opacity: 0,
            },
            '& .simulationAction > *': {
                display: 'none',
            },
        },
        '&.simulationOpen': {
            '& .avatarMini': {
                display: 'block',
            },
        },
    },
    // Make the fab button appear if we hover the vertical line on the button.
    pageContainer: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
    },
    toggleIcon: {
        color: theme.palette.driftwood.main,
        fontSize: '10px',
        '&.sideBarClose': {
            transform: `rotate(180deg)`,
        },
    },
    relative: {
        position: 'relative',
    },
}));

export default useHomePageStyle;
