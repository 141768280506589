import OrganicFertilizationTaskContext from './OrganicFertilizationTaskContext/OrganicFertilizationTaskContext';
import OrganicFertilizationTaskView from './OrganicFertilizationTaskView';

const OrganicFertilizationTask = (): JSX.Element => (
    <OrganicFertilizationTaskContext>
        <OrganicFertilizationTaskView />
    </OrganicFertilizationTaskContext>
);

export default OrganicFertilizationTask;
