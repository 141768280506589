import { makeStyles } from 'tss-react/mui';

const useCardBasicStyle = makeStyles()((theme) => ({
    card: {
        boxShadow:
            '0px 4px 5px -2px rgb(0 0 0 / 3%), 0px 7px 10px 1px rgb(0 0 0 / 2%), 0px 2px 16px 1px rgb(0 0 0 / 1%)',
        padding: theme.spacing(4),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
}));

export default useCardBasicStyle;
