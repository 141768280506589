import LogoImg from '@components/LogoImg/LogoImg';
import { Box, Grid, Typography } from '@mui/material';

import useLayoutAuthStyle from './LayoutAuth.style';
import { LayoutAuthPropsT } from './LayoutAuthTypes';

const LayoutAuth = ({ sideZone, form, underFormZone, title }: LayoutAuthPropsT): JSX.Element => {
    const { classes } = useLayoutAuthStyle();

    return (
        <Grid className={classes.root} container>
            <Grid md={6} xs={12} item>
                <Box className={classes.box}>
                    <LogoImg className={classes.logo} />
                    <div className={classes.contentCenter}>
                        <Typography className={classes.title} component="h1" variant="h4">
                            {title}
                        </Typography>
                        <div className={classes.formContainer}>{form}</div>
                    </div>
                </Box>
                <div className={classes.mobileOnly}>{underFormZone}</div>
            </Grid>
            <Grid md={6} xs={12} item>
                {sideZone}
            </Grid>
        </Grid>
    );
};

export default LayoutAuth;
