import request from '../apiService/apiService';
import { PermissionsResponseT } from './permissionApiServiceTypes';

class PermissionApiService {
    public static getFarmUserPermissions = async ({ farmId, userId }: { farmId: number; userId: number }) =>
        request<PermissionsResponseT>({
            url: `/user/${userId}/farm/${farmId}/permissions`,
            method: 'GET',
        });
    public static getUserPermissions = async () =>
        request<PermissionsResponseT>({
            url: `/auth/permissions`,
            method: 'GET',
        });
}

export default PermissionApiService;
