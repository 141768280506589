import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

const useModalNoFieldsSuggestion = () => {
    const modalNoFieldsSuggestion = useSelector(
        (state: AppStateT) => state.onboarding.modalNoFieldsSuggestion.controller,
    );

    return { modalNoFieldsSuggestion };
};

export default useModalNoFieldsSuggestion;
