import FarmSeasonFieldApiService from '@services/farmSeasonFieldsApiService/farmSeasonFieldsApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldDeleteCallBackT,
    DeleteFarmSeasonFieldDispatchTypesT,
    DeleteFarmSeasonFieldResponseDataT,
    DELETE_FARM_SEASON_FIELD_FAILED,
    DELETE_FARM_SEASON_FIELD_LOADING,
    DELETE_FARM_SEASON_FIELD_SUCCESS,
    DeleteFarmSeasonFieldResponseErrorT,
} from './DeleteFarmSeasonFieldTypes';

export const deleteFarmSeasonField =
    (farmId: number, farmSeasonId: number, farmSeasonFieldId: number): FarmSeasonFieldDeleteCallBackT =>
    async (dispatch: Dispatch<DeleteFarmSeasonFieldDispatchTypesT>): Promise<boolean> => {
        try {
            dispatch({ type: DELETE_FARM_SEASON_FIELD_LOADING });
            const res: AxiosResponse<DeleteFarmSeasonFieldResponseDataT> = await FarmSeasonFieldApiService.delete(
                farmId,
                farmSeasonId,
                farmSeasonFieldId,
            );
            dispatch({
                type: DELETE_FARM_SEASON_FIELD_SUCCESS,
                payload: res.data,
            });

            return true;
        } catch (error) {
            dispatch({
                type: DELETE_FARM_SEASON_FIELD_FAILED,
                error: error as DeleteFarmSeasonFieldResponseErrorT,
            });

            return false;
        }
    };

export const placeholder = '';
