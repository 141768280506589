import * as yup from 'yup';
import checkVATNumber from '@utils/checkVatNumber';
import { BillingDetailsDataT, useBillingDetailsFormLogicPropsT } from './BillingDetailsForm.types';
import { useFormik } from 'formik';
import { useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateT } from '@store/store';

/**
 * This schema does not provide direct translation of slugs. \
 * You need to implement your slugs translation system after the validation slugs has been gattered during runtime \
 * Be aware that you can use `t` function with normal strings so you can mix slugs with already translated strings without issues
 * @example
 * t(formik.errors['x']) t('this is my string') t('this-is-my-slug')
 */
const BillingDetailsValidationSchema = yup.object({
    address: yup.string().required('validation.no-address-provided').max(120, 'validation.max-chars-120'),
    city: yup.string().required('validation.no-city-provided'),
    name: yup.string().required('validation.no-company-name-provided'),
    postal_code: yup.string().required('validation.no-postal-code-provided'),
    tax_id: yup
        .string()
        .required('validation.no-vat-provided')
        .test('check-vat', 'validation.no-valid-vat', (v) => checkVATNumber(v)),
    country_id: yup.string().typeError('validation.no-country-provided'),
    siret_id: yup.string().when('country_id', {
        is: '1',
        then: yup
            .string()
            .required('validation.no-siret-provided')
            .min(14, 'validation.siret-length')
            .max(14, 'validation.siret-length'),
    }),
});

/**
 * This hook is explicitly designed to be used with `BillingDetailsForm.tsx` \
 * - {@link billingDetailFormRef} can be used to call submit outside of the component. The formRef value should come from `react.useRef()`
 */
const useBillingDetailsFormLogic = ({ billingDetailFormRef, userCompany }: useBillingDetailsFormLogicPropsT) => {
    const [serverError, setServerError] = useState<string | undefined>(undefined);
    const [oldState, setOldState] = useState<BillingDetailsDataT | undefined>(undefined);
    const currentFarm = useSelector((state: AppStateT) => state.farm.currentFarm);

    /**
     * useFormik for billing details, inherits {@link BillingDetailsDataT} types
     */
    const billingDetailsFormik = useFormik<BillingDetailsDataT>({
        initialValues: {
            address: userCompany?.address ?? '',
            city: userCompany?.city ?? '',
            country_id: userCompany?.country_id ?? NaN,
            name: userCompany?.name ?? '',
            postal_code: userCompany?.postal_code ?? '',
            tax_id: userCompany?.tax_id ?? '',
            siret_id: userCompany?.country_id === 1 ? userCompany?.siret_id ?? userCompany?.siret_id : '',
        },
        validationSchema: BillingDetailsValidationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        // ToDo: Find a better way to avoid onSubmit definition
        onSubmit: function () {
            return;
        },
    });

    /**
     * Allows us to remount ref to be able to submit the form outside of the component
     * see Documentation in JSDoc of the hook below
     * This is used in conjuction with ForwardRef
     */
    useImperativeHandle(
        billingDetailFormRef,
        () => ({
            displayError: (error: string) => setServerError(error),
            getFormData: async () => {
                // validateOnMount: true has to be defined for this to work properly
                await billingDetailsFormik.submitForm();
                if (billingDetailsFormik.isValid) {
                    return billingDetailsFormik.values;
                }
                return false;
            },
        }),
        [billingDetailsFormik.values, billingDetailsFormik.isValid],
    );

    /** This function allow to switch the data source from the current form state to the farm data and in reverse
     * @param toggle can be used to define the logic.
     * @value `true` it will change current form state to farm address
     * @value `false` it will revert the values to the old form state
     * @warning if old form state is null we will define it with default {@link emptyValues}
     */
    const useFarmAddress = (toggle: boolean) => {
        if (toggle) {
            return billingDetailsFormik.setValues((oldValues) => {
                setOldState(oldValues);
                return {
                    address: currentFarm?.address ?? '',
                    city: currentFarm?.city ?? '',
                    country_id: Number(currentFarm?.country_id) ?? NaN,
                    name: currentFarm?.name ?? '',
                    postal_code: currentFarm?.postal_code ?? '',
                    tax_id: '',
                    siret_id: '',
                };
            });
        }

        /** This is used only in case oldState is not available */
        const emptyValues: BillingDetailsDataT = {
            address: '',
            city: '',
            country_id: NaN,
            name: '',
            postal_code: '',
            tax_id: '',
            siret_id: '',
        };

        return billingDetailsFormik.setValues(oldState ?? emptyValues);
    };

    return { billingDetailsFormik, serverError, useFarmAddress };
};

export default useBillingDetailsFormLogic;
