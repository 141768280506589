import { useEffect } from 'react';

import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';
import useAppDispatch from './useAppDispatch';
import { countryGet } from '@store/actions/StaticActions/StaticAction';

const useCountries = () => {
    // Data retrieved from the store
    const countries = useSelector((state: AppStateT) => state.static.countries);
    const loading = useSelector((state: AppStateT) => state.static.countriesLoading);

    // Helpers
    const dispatch = useAppDispatch();

    // Lifecycles
    useEffect(() => {
        // It's possible this hook was called before
        // Therefore we skip the additional country loading if they are already available
        if (countries.length == 0) {
            dispatch(countryGet());
        }
    }, []);

    return { countries, loading };
};

export default useCountries;
