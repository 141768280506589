import { Container, Box, Typography, Button, CircularProgress, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useAppDispatch from '@hooks/useAppDispatch';
import useVerifyEmailPageStyle from '@pages/VerifyEmailPage/VerifyEmailPage.style';
import ButtonResendVerifyEmail from '@components/ButtonResendVerifyEmail/ButtonResendVerifyEmail';
import logout from '@actions/AuthActions/LogoutActions';
import IconCheck from '@icons/icon-check.svg';
import IconExit from '@icons/icon-exit.svg';

import { VerifyEmailPageViewPropsI } from './VerifyEmailPageTypes';

const VerifyEmailPageView = ({ t, msgOpen, error, notice, user }: VerifyEmailPageViewPropsI): JSX.Element => {
    const dispatch = useAppDispatch();
    const { classes } = useVerifyEmailPageStyle();

    return (
        <Container className={classes.root}>
            <Box borderRadius={1.6} className={classes.box} px={10} py={10}>
                {!msgOpen && !notice && <CircularProgress />}
                {!msgOpen && notice && (
                    <div className={classes.wrapper}>
                        <Button
                            className={`${classes.circle} ${
                                user.email_verification_status === 'verified' ? '' : classes.circleRed
                            }`}
                        >
                            {user.email_verification_status === 'verified' ? (
                                <IconCheck className={classes.icon} />
                            ) : (
                                <IconExit className={classes.icon} />
                            )}
                        </Button>
                        <Typography className={classes.msg}>
                            <strong>
                                {t(
                                    user.email_verification_status === 'expired'
                                        ? 'pages.verify-email.expired-text'
                                        : 'pages.verify-email.blocking-text',
                                )}
                            </strong>
                        </Typography>
                        {user.email_verification_status !== 'verified' && (
                            <Box my={4}>
                                <ButtonResendVerifyEmail />
                            </Box>
                        )}
                        <Box textAlign="center">
                            <Link
                                className={classes.loginLink}
                                component={RouterLink}
                                to="/login"
                                variant="body2"
                                onClick={
                                    user.email_verification_status === 'expired' ? () => dispatch(logout()) : undefined
                                }
                            >
                                {t(
                                    user.email_verification_status === 'expired'
                                        ? 'pages.homepage.logout'
                                        : 'constants.cancel',
                                )}
                            </Link>
                        </Box>
                    </div>
                )}
                {msgOpen && error && (
                    <div className={classes.wrapper}>
                        <Button className={`${classes.circle} ${classes.circleRed}`}>
                            <IconExit className={classes.icon} />
                        </Button>
                        <Typography className={classes.msg}>
                            <strong>{t('pages.verify-email.error')}</strong>
                        </Typography>
                    </div>
                )}
                {msgOpen && !error && (
                    <div className={classes.wrapper}>
                        <Button className={classes.circle}>
                            <IconCheck className={classes.icon} />
                        </Button>
                        <Typography className={classes.msg}>
                            <strong>{t('pages.verify-email.verified')}</strong>
                            <br />
                            <br />
                            {t('pages.verify-email.verified-redirect')}
                        </Typography>
                    </div>
                )}
            </Box>
        </Container>
    );
};

export default VerifyEmailPageView;
