import AppRoutes from '@components/AppRoutes/AppRoutes';
import { GuardT } from '@services/guardsService/guardsService';
import GuardedRoute from '@/utils/GuardedRoute';
import { Route, RouteObject, useRoutes } from 'react-router-dom';

export type RouteObjectAppT = RouteObject & { guards?: GuardT[] };

/* keep main location even with simone routes working on top. */
const useAppRoutes = (routes: RouteObjectAppT[]): ReturnType<typeof useRoutes> => {
    return (
        <AppRoutes>
            {routes.map((route, i) => {
                return (
                    <Route
                        key={i}
                        path={route.path}
                        element={<GuardedRoute element={route.element} guards={route?.guards} />}
                    />
                );
            })}
        </AppRoutes>
    );
};

export default useAppRoutes;
