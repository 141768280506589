import farmSeasonFieldsTillagePracticeHistoryApiService from '@services/farmSeasonFieldsTillagePracticeHistoryApiService/farmSeasonFieldsTillagePracticeHistoryApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsTillagePracticeHistoryPostCallBackT,
    PostFarmSeasonFieldsTillagePracticeHistoryDataT,
    PostFarmSeasonFieldsTillagePracticeHistoryDispatchTypesT,
    PostFarmSeasonFieldsTillagePracticeHistoryResponseDataT,
    PostFarmSeasonFieldsTillagePracticeHistoryResponseErrorT,
    POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_FAILED,
    POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_LOADING,
    POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS,
} from './PostFarmSeasonFieldsTillagePracticeHistoryTypes';

const postFarmSeasonFieldsTillagePracticeHistory =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsTillagePracticeHistoryDataT,
    ): FarmSeasonFieldsTillagePracticeHistoryPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsTillagePracticeHistoryDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsTillagePracticeHistoryResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsTillagePracticeHistoryResponseDataT> =
                await farmSeasonFieldsTillagePracticeHistoryApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_TILLAGE_PRACTICE_HISTORY_FAILED,
                error: error as PostFarmSeasonFieldsTillagePracticeHistoryResponseErrorT,
            });

            return {};
        }
    };

export default postFarmSeasonFieldsTillagePracticeHistory;
