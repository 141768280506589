/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from 'i18next';

/**
 * Format a number to be displayed with the correct locale
 * @param value number to be formated. We allow null | undefined but they won't be fomated
 * @param decimals number of digits displayed to the right of the comma
 * @returns formated number as string. This string can't be converted in number
 */
const formatNumber = <T extends null | undefined | number>(
    value: T,
    maxDecimals?: number,
    minDecimals?: number,
): T extends number ? string : T => {
    if (value === null) {
        return null as any;
    }

    if (value === undefined) {
        return undefined as any;
    }

    return new Intl.NumberFormat(i18n.resolvedLanguage ?? navigator.language, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
    }).format(value) as any;
};

export default formatNumber;
