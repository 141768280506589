import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import { AxiosResponse } from 'axios';

import {
    FarmSeasonGetAllCallback,
    FarmSeasonGetAllFailedDataT,
    FARM_SEASON_ALL_FAILED,
    FARM_SEASON_ALL_LOADING,
    FARM_SEASON_ALL_SUCCESS,
} from './FarmSeasonGetAllTypes';

export const farmSeasonGetAll =
    (farmId: number): FarmSeasonGetAllCallback =>
    async (dispatch) => {
        try {
            dispatch({ type: FARM_SEASON_ALL_LOADING });
            const res: AxiosResponse<FarmSeasonT[]> = await FarmSeasonApiService.getAll(farmId);
            const farmSeasons = res.data.sort((s1, s2) => s1.id - s2.id);
            dispatch({
                type: FARM_SEASON_ALL_SUCCESS,
                payload: farmSeasons,
            });

            return [...farmSeasons];
        } catch (error) {
            dispatch({
                type: FARM_SEASON_ALL_FAILED,
                error: error as FarmSeasonGetAllFailedDataT,
            });

            return false;
        }
    };

export default farmSeasonGetAll;
