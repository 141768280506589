import { makeStyles } from 'tss-react/mui';

const useSelectedFieldsZoneStyle = makeStyles<{ minHeight?: string; placeholderShown: boolean }>()(
    (_, { minHeight = '260px', placeholderShown }) => ({
        SelectedFieldsZone: {
            minHeight,
            padding: '10px',
            backgroundColor: 'rgba(237,200,128,0.15)',
            marginBottom: '20px',
            position: 'relative',
        },
        children: {
            opacity: placeholderShown ? 0 : 1,
            transition: placeholderShown ? 'opacity ease-out .2s' : 'opacity ease-in .2s .1s',
        },
        placeholder: {
            opacity: placeholderShown ? 1 : 0,
            visibility: placeholderShown ? 'visible' : 'hidden',
            transition: placeholderShown
                ? 'opacity .2s ease-in .1s, visibility 0s ease 0s'
                : 'opacity .2s ease-out 0s, visibility 0s ease .2s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: `calc(${minHeight} - 20px)`, // -padding
            marginBottom: `calc(-${minHeight} + 20px)`, // -padding
        },
    }),
);

export default useSelectedFieldsZoneStyle;
