import { CircularProgress, Collapse, Paper, SvgIcon, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CollapsePaperViewPropsT } from './CollapsePaperViewTypes';
import useCollapsePaperStyle from './CollapsePaperView.style';

const CollapsePaperView = ({
    title,
    loading,
    isOpen,
    disabled,
    onClick,
    children,
}: CollapsePaperViewPropsT): JSX.Element => {
    const { classes } = useCollapsePaperStyle({ disabled, isOpen });

    return (
        <div className={classes.container}>
            <Paper className={classes.titleBar} elevation={0}>
                <Typography variant="h6">{title}</Typography>
                {loading ? (
                    <CircularProgress className={classes.progress} size="20px" />
                ) : (
                    <SvgIcon
                        className={classes.collapseIcon}
                        component={isOpen ? ExpandLessIcon : ExpandMoreIcon}
                        onClick={() => {
                            if (!disabled) {
                                onClick();
                            }
                        }}
                    />
                )}
            </Paper>
            <Collapse in={isOpen && !loading && !disabled}>
                <Paper className={classes.collapseContainer} elevation={0}>
                    {children}
                </Paper>
            </Collapse>
        </div>
    );
};

export default CollapsePaperView;
