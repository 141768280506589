/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from '@reduxjs/toolkit';
import authService from '@services/authService/authService';

import {
    VerifyEmailDispatchTypesT,
    VERIFY_EMAIL_LOADING,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILED,
    VerifyEmailResponseErrorT,
    VerifyEmailActionDataT,
} from './VerifyEmailActionsTypes';

const verifyEmail =
    ({
        hashedId,
        expires,
        signature,
    }: VerifyEmailActionDataT): any => // eslint-disable-line
    async (dispatch: Dispatch<VerifyEmailDispatchTypesT>): Promise<VerifyEmailResponseErrorT> => {
        try {
            dispatch({
                type: VERIFY_EMAIL_LOADING,
            });

            const res = await authService.verifyEmail({ hashedId, expires, signature });

            dispatch({
                type: VERIFY_EMAIL_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (e: any) {
            const list = e.response?.status !== 200 ? ['error'] : [];
            dispatch({
                type: VERIFY_EMAIL_FAILED,
                error: {
                    message: e.response?.data || e.message,
                    list,
                },
            });

            return {
                message: e.response?.data || e.message,
                list,
            };
        }
    };

export default verifyEmail;
