import { CircularProgress, Typography } from '@mui/material';

import useTextLinkStyle from './TextLink.style';
import { TextLinkPropsT } from './TextLinkTypes';

const TextLink = ({
    children,
    loaderPosition,
    loading,
    variant = 'body1',
    className,
    onClick,
    textAlign = 'left',
}: TextLinkPropsT) => {
    const { classes } = useTextLinkStyle({ loading, loaderPosition, textAlign });

    return (
        <span className={`${classes.TextLink} ${className}`}>
            {loading && loaderPosition === 'left' && (
                <CircularProgress className={classes.loader} color="primary" size={15} />
            )}
            <Typography
                className={classes.text}
                color="primary"
                variant={variant}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={loading ? () => {} : onClick}
            >
                {children}
            </Typography>
            {loading && loaderPosition === 'right' && (
                <CircularProgress className={classes.loader} color="primary" size={15} />
            )}
        </span>
    );
};

export default TextLink;
