import { postFarmSeasonInstallationConfirm } from '@actions/FarmSeasonActions/FarmSeasonPostInstallationConfirm';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import { NotDoneTasksT, PostTasksDataT, TaskT } from './ConfirmTaskTypes';
import ConfirmTaskView from './ConfirmTaskView';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

const ConfirmTask = (): JSX.Element => {
    const onboarding = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason?.installation?.onboarding,
    );
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const loading = useSelector((state: HomePageStateT) => state.farmSeason.installationLoading);
    const isCFT = useIsCFTCarbonModel();

    const { t } = useTranslation();

    const optionalTasks: TaskT[] = [
        {
            task: 'organic',
            value: !isCFT || onboarding?.organic?.done,
            invalidMessage: t('pages.installationtasks.confirm-task.invalid-organic'),
        },
        {
            task: 'drainage',
            value: onboarding?.drainage?.done,
            invalidMessage: t('pages.installationtasks.confirm-task.invalid-drainage'),
        },
        {
            task: 'irrigation',
            value: onboarding?.irrigation?.done,
            invalidMessage: t('pages.installationtasks.confirm-task.invalid-irrigation'),
        },
        {
            task: 'agroforestry',
            value: onboarding?.agroforestry?.done,
            invalidMessage: t('pages.installationtasks.confirm-task.invalid-agroforestry'),
        },
        {
            task: 'coverCrop',
            value: !isCFT || onboarding?.coverCrop?.done,
            invalidMessage: t('pages.installationtasks.confirm-task.invalid-coverCrop'),
        },
        {
            task: 'livestock',
            value: onboarding?.livestock?.done,
            invalidMessage: t('pages.installationtasks.confirm-task.invalid-livestock'),
        },
        {
            task: 'residue',
            value: !isCFT || onboarding?.residue?.done,
            invalidMessage: t('pages.installationtasks.confirm-task.invalid-residue'),
        },
    ];

    const getNotDoneTasks = () => {
        const notDoneTasks: NotDoneTasksT = {};

        optionalTasks.forEach((task) => {
            if (!task.value) {
                notDoneTasks[task.task] = false;
            }
        });

        return notDoneTasks;
    };

    const formik = useFormik({
        initialValues: {
            tasks: { ...getNotDoneTasks() },
        },
        enableReinitialize: true,
        onSubmit: async (values: PostTasksDataT, { setSubmitting }) => {
            if (farmId && farmSeasonId) {
                await dispatch(postFarmSeasonInstallationConfirm(farmId, farmSeasonId, values));
            }
            setSubmitting(false);
            navigate('/');
        },
        validate: (values) => {
            const errors: { tasks: { [key: string]: string | undefined } } = { tasks: {} };

            Object.keys(values.tasks).forEach((key) => {
                if (!(values.tasks as { [key: string]: boolean })[key]) {
                    errors.tasks[key] = optionalTasks.find((task) => task.task === key)?.invalidMessage;
                }
            });

            return Object.keys(errors.tasks).length === 0 ? {} : errors;
        },
    });

    return <ConfirmTaskView formik={formik} loading={loading} />;
};

export default ConfirmTask;
