import { makeStyles } from 'tss-react/mui';

const useAdminPanelViewStyle = makeStyles()((theme) => ({
    container: {
        background: theme.palette.linkWater.light,
        marginRight: '20px',
        padding: '15px',
    },
    profile: {
        display: 'grid',
        gridTemplateColumns: '30px calc(100% - 30px)',
        padding: '3px',
        background: theme.palette.linkWater.main,
        borderRadius: '5px',
        cursor: 'pointer',
        alignItems: 'center',
        width: '100%',
        margin: '5px 0 10px 0',
    },
    avatar: {
        height: '24px',
        width: '24px',
    },
    profileName: {
        marginLeft: '2px',
        display: 'flex',
        alignItems: 'center',
    },
    task: {
        display: 'grid',
        gridTemplateColumns: '30px calc(100% - 30px)',
        padding: '3px',
        borderRadius: '5px',
        cursor: 'pointer',
        alignItems: 'center',
        width: '100%',
        marginTop: '5px',
        '&:hover': {
            background: 'rgba(0,0,0,0.1)',
        },
    },
    taskActive: {
        background: theme.palette.spanishWhite.light,
    },
    iconContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2px',
    },
    icon: {
        fontSize: '20px',
        color: theme.palette.primary.main,
    },
    taskText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    finalizeSeasonBtn: {
        marginTop: '10px',
    },
    auditMsg: {
        marginTop: '10px',
    },
}));

export default useAdminPanelViewStyle;
