import FarmSeasonLivestocksApiService from '@services/farmSeasonLivestocksApiService/farmSeasonLivestocksApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    PostFarmSeasonLivestocksInstallationDataT,
    PostFarmSeasonLivestocksInstallationDispatchTypesT,
    PostFarmSeasonLivestocksInstallationResponseDataT,
    PostFarmSeasonLivestocksInstallationResponseErrorT,
    POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED,
    POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING,
    POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS,
} from './PostFarmSeasonLivestocksInstallationTypes';

export const postFarmSeasonLivestocksInstallation =
    (farmId: number, farmSeasonId: number, body: PostFarmSeasonLivestocksInstallationDataT) =>
    async (dispatch: Dispatch<PostFarmSeasonLivestocksInstallationDispatchTypesT>) => {
        try {
            dispatch({ type: POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING });
            const res: AxiosResponse<PostFarmSeasonLivestocksInstallationResponseDataT> =
                await FarmSeasonLivestocksApiService.postSaveLivestockInstallation(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS,
                payload: res.data,
            });

            return res.data;
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED,
                error: error as PostFarmSeasonLivestocksInstallationResponseErrorT,
            });

            return false;
        }
    };

export const placeholder = '';
