import { makeStyles } from 'tss-react/mui';

import { NotificationHeaderStylePropsT } from './NotificationHeaderTypes';

const useNotificationHeaderStyle = makeStyles<NotificationHeaderStylePropsT>()((theme, { hasUnreadNotifications }) => ({
    NotificationHeader: {
        background: `linear-gradient(0, rgba(255,255,255,0) 0%, ${theme.palette.spanishWhite.light} 15%)`,
        position: 'sticky',
        top: '-15px',
        padding: '15px',
        paddingBottom: '20px',
        margin: '-15px',
        zIndex: 5,
        // boxSizing: 'border-box',
        marginBottom: '-5px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        color: theme.palette.secondary.dark,
    },
    switchContainer: {
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
    },
    switchLabel: {
        cursor: 'pointer',
    },
    markAllAsReadContainer: {
        height: hasUnreadNotifications ? '20px' : '0',
        opacity: hasUnreadNotifications ? 1 : 0,
        transition: 'all ease .2s',
        overflow: 'hidden',
    },
}));

export default useNotificationHeaderStyle;
