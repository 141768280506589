/* eslint-disable @typescript-eslint/no-explicit-any */
import { FertilisationOperationT } from '@pages/HomePage/Operations/FertilisationTask/FertilisationTaskTypes';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { ExceptionTaskPropsT } from './ExceptionTaskTypes';
import ExceptionTaskView from './ExceptionTaskView';

const ExceptionTask = ({
    selectedException,
    menuTitle,
    setSelectedException,
    fertilisers,
    addException,
    modifyException,
    canEdit = false,
}: ExceptionTaskPropsT): JSX.Element => {
    const { t } = useTranslation();
    const [showCollapseId, setShowColapseId] = useState('mineral-liquid');
    const fields = useSelector((store: HomePageStateT) => store.farmSeasonField?.fieldsList);
    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });

    useEffect(() => {
        MapService.selectablePolygons({ selectMode: true });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
        };
    }, []);

    const validationSchema = yup.object().shape({
        mineral_liquid: yup.object().shape(
            {
                passage: yup.number().when('machinery_fertilisers', {
                    is: (values: any) =>
                        values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                    then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                    otherwise: yup.number().nullable(true),
                }),
                machinery_fertilisers: yup.array().when('passage', {
                    is: (value: any) => value > 0,
                    then: yup
                        .array()
                        .of(
                            yup.object().shape({
                                fertiliser: yup
                                    .object()
                                    .typeError(t('validation.no-fertiliser'))
                                    .shape({
                                        id: yup
                                            .number()
                                            .typeError(t('validation.no-fertiliser'))
                                            .min(1, t('validation.no-fertiliser')),
                                    }),
                                quantity: yup
                                    .number()
                                    .typeError(t('validation.no-quantity'))
                                    .min(0.1, t('validation.no-quantity')),
                            }),
                        )
                        .min(1, t('validation.no-product')),
                }),
            },
            [['passage', 'machinery_fertilisers']],
        ),
        mineral_solid: yup.object().shape(
            {
                passage: yup.number().when('machinery_fertilisers', {
                    is: (values: any) =>
                        values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                    then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                    otherwise: yup.number().nullable(true),
                }),
                machinery_fertilisers: yup.array().when('passage', {
                    is: (value: any) => value > 0,
                    then: yup
                        .array()
                        .of(
                            yup.object().shape({
                                fertiliser: yup
                                    .object()
                                    .typeError(t('validation.no-fertiliser'))
                                    .shape({
                                        id: yup
                                            .number()
                                            .typeError(t('validation.no-fertiliser'))
                                            .min(1, t('validation.no-fertiliser')),
                                    }),
                                quantity: yup
                                    .number()
                                    .typeError(t('validation.no-quantity'))
                                    .min(0.1, t('validation.no-quantity')),
                            }),
                        )
                        .min(1, t('validation.no-product')),
                }),
            },
            [['passage', 'machinery_fertilisers']],
        ),
        organic_liquid: yup.object().shape(
            {
                passage: yup.number().when('machinery_fertilisers', {
                    is: (values: any) =>
                        values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                    then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                    otherwise: yup.number().nullable(true),
                }),
                machinery_fertilisers: yup.array().when('passage', {
                    is: (value: any) => value > 0,
                    then: yup
                        .array()
                        .of(
                            yup.object().shape({
                                fertiliser: yup
                                    .object()
                                    .typeError(t('validation.no-fertiliser'))
                                    .shape({
                                        id: yup
                                            .number()
                                            .typeError(t('validation.no-fertiliser'))
                                            .min(1, t('validation.no-fertiliser')),
                                    }),
                                quantity: yup
                                    .number()
                                    .typeError(t('validation.no-quantity'))
                                    .min(0.1, t('validation.no-quantity')),
                            }),
                        )
                        .min(1, t('validation.no-product')),
                }),
            },
            [['passage', 'machinery_fertilisers']],
        ),
        organic_solid: yup.object().shape(
            {
                passage: yup.number().when('machinery_fertilisers', {
                    is: (values: any) =>
                        values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                    then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                    otherwise: yup.number().nullable(true),
                }),
                machinery_fertilisers: yup.array().when('passage', {
                    is: (value: any) => value > 0,
                    then: yup
                        .array()
                        .of(
                            yup.object().shape({
                                fertiliser: yup
                                    .object()
                                    .typeError(t('validation.no-fertiliser'))
                                    .shape({
                                        id: yup
                                            .number()
                                            .typeError(t('validation.no-fertiliser'))
                                            .min(1, t('validation.no-fertiliser')),
                                    }),
                                quantity: yup
                                    .number()
                                    .typeError(t('validation.no-quantity'))
                                    .min(0.1, t('validation.no-quantity')),
                            }),
                        )
                        .min(1, t('validation.no-product')),
                }),
            },
            [['passage', 'machinery_fertilisers']],
        ),
        other_liquid: yup.object().shape(
            {
                passage: yup.number().when('machinery_fertilisers', {
                    is: (values: any) =>
                        values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                    then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                    otherwise: yup.number().nullable(true),
                }),
                machinery_fertilisers: yup.array().when('passage', {
                    is: (value: any) => value > 0,
                    then: yup
                        .array()
                        .of(
                            yup.object().shape({
                                fertiliser: yup
                                    .object()
                                    .typeError(t('validation.no-fertiliser'))
                                    .shape({
                                        id: yup
                                            .number()
                                            .typeError(t('validation.no-fertiliser'))
                                            .min(1, t('validation.no-fertiliser')),
                                    }),
                                quantity: yup
                                    .number()
                                    .typeError(t('validation.no-quantity'))
                                    .min(0.1, t('validation.no-quantity')),
                            }),
                        )
                        .min(1, t('validation.no-product')),
                }),
            },
            [['passage', 'machinery_fertilisers']],
        ),
        other_solid: yup.object().shape(
            {
                passage: yup.number().when('machinery_fertilisers', {
                    is: (values: any) =>
                        values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                    then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                    otherwise: yup.number().nullable(true),
                }),
                machinery_fertilisers: yup.array().when('passage', {
                    is: (value: any) => value > 0,
                    then: yup
                        .array()
                        .of(
                            yup.object().shape({
                                fertiliser: yup
                                    .object()
                                    .typeError(t('validation.no-fertiliser'))
                                    .shape({
                                        id: yup
                                            .number()
                                            .typeError(t('validation.no-fertiliser'))
                                            .min(1, t('validation.no-fertiliser')),
                                    }),
                                quantity: yup
                                    .number()
                                    .typeError(t('validation.no-quantity'))
                                    .min(0.1, t('validation.no-quantity')),
                            }),
                        )
                        .min(1, t('validation.no-product')),
                }),
            },
            [['passage', 'machinery_fertilisers']],
        ),
    });

    const formik = useFormik({
        initialValues: selectedException.exception
            ? selectedException.exception
            : {
                  id: 0,
                  fields_ids: [],
                  dontHaveAny: false,
                  mineral_liquid: {
                      passage: null,
                      machinery_fertilisers: [],
                  },
                  mineral_solid: {
                      passage: null,
                      machinery_fertilisers: [],
                  },
                  organic_liquid: {
                      passage: null,
                      machinery_fertilisers: [],
                  },
                  organic_solid: {
                      passage: null,
                      machinery_fertilisers: [],
                  },
                  other_liquid: {
                      passage: null,
                      machinery_fertilisers: [],
                  },
                  other_solid: {
                      passage: null,
                      machinery_fertilisers: [],
                  },
              },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: FertilisationOperationT, { setSubmitting }) => {
            setSubmitting(false);

            if (typeof selectedException.index !== 'undefined' && selectedException.index >= 0) {
                modifyException(selectedException.index, values);
            } else {
                addException(values);
            }

            setSelectedException(null);
        },
    });

    // Loads initial polygons
    useEffect(() => {
        if (selectedException.exception?.fields_ids?.length && selectedException.exception.fields_ids.length > 0) {
            MapService.updateSelectedPolygons({
                selectedPolygons: fields
                    .filter((field) => selectedException.exception?.fields_ids.find((id) => id === field.id))
                    .map((field) => field.polygon),
            });
        }
    }, []);

    useEffect(() => {
        const selected = fields?.filter((field) =>
            thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id),
        );
        formik.setValues((oldValues) => ({ ...oldValues, fields_ids: selected.map((field) => field.id) }));
    }, [thisMap.selectedPolygons]);

    const onSelectedFieldDelete = (id: any) => {
        formik.setValues((oldValues) => ({
            ...oldValues,
            fields_ids: formik.values.fields_ids.filter((fieldId) => fieldId === id),
        }));
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    return (
        <ExceptionTaskView
            fertilisers={fertilisers}
            formik={formik}
            menuTitle={menuTitle}
            setSelectedException={setSelectedException}
            setShowColapseId={setShowColapseId}
            showCollapseId={showCollapseId}
            titleIndex={selectedException.titleIndex}
            onSelectedFieldDelete={onSelectedFieldDelete}
            canEdit={canEdit}
        />
    );
};

export default ExceptionTask;
