import { Button, Card, Typography } from '@mui/material';
import { CardFlowPropsT } from './CardFlow.types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoadingSection from '@components/LoadingSection/LoadingSection';

const CardFlow = ({ description, title, action, cardSx, loading, minHeight, disabled }: CardFlowPropsT) => (
    <Card elevation={2} sx={{ p: 3, ...cardSx }} style={{ minHeight: minHeight }}>
        <>
            <LoadingSection loading={loading}>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="body2" sx={{ mt: 1.5, mb: 3, whiteSpace: 'pre-line' }}>
                    {description}
                </Typography>
                {action && (
                    <Button size="small" onClick={action.onClick} disabled={disabled}>
                        {action.text} <ArrowForwardIcon style={{ marginLeft: '8px' }} />
                    </Button>
                )}
            </LoadingSection>
        </>
    </Card>
);

export default CardFlow;
