import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@icons/icon-google-logo.svg';
import useLoader from '@hooks/useLoader';
import { useTranslation } from 'react-i18next';

import useButtonSocialStyle from './ButtonSocial.style';
import { ButtonSocialIconMapT, ButtonSocialPropsT } from './ButtonSocialTypes';

export const ButtonSocial = ({ onClick, socialName, loading, className }: ButtonSocialPropsT): JSX.Element => {
    const { classes } = useButtonSocialStyle();
    const { t } = useTranslation();
    const { containerRef } = useLoader(loading ?? false);
    const theme = useTheme();

    const iconMap: ButtonSocialIconMapT = {
        'google-oauth2': { bgColor: '#fff', textColor: '#818181', bgDark: '#e7e7e7', icon: GoogleIcon, name: 'Google' },
        apple: { bgColor: '#000', icon: AppleIcon, name: 'Apple' },
        facebook: { bgColor: '#4267b2', textColor: '#fff', bgDark: '#2e487c', icon: FacebookIcon, name: 'Facebook' },
        linkedin: { bgColor: '#0065b3', icon: LinkedinIcon, name: 'Linkedin' },
        twitter: { bgColor: '#1da1f2', icon: TwitterIcon, name: 'Twitter' },
    };

    const Icon = iconMap[socialName].icon;

    const newTheme = createTheme(
        deepmerge(theme, {
            palette: {
                primary: {
                    light: iconMap[socialName].bgColor,
                    main: iconMap[socialName].bgDark,
                    contrastText: iconMap[socialName].textColor,
                },
            },
        }),
    );

    return (
        <ThemeProvider theme={newTheme}>
            <div ref={containerRef} className={`${className} ${classes.containerRounded}`}>
                <Button
                    disableElevation={false}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={onClick}
                >
                    <Icon />
                    <span className={classes.buttonText}>
                        {t('component.button-social.continue-with')} {iconMap[socialName].name}
                    </span>
                </Button>
            </div>
        </ThemeProvider>
    );
};
