import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    OrganicMatterListGetCallback,
    OrganicMatterListGetDispatchType,
    OrganicMatterListGetSuccessDataT,
    ORGANIC_MATTER_LIST_GET_FAILED,
    ORGANIC_MATTER_LIST_GET_LOADING,
    ORGANIC_MATTER_LIST_GET_SUCCESS,
} from './OrganicMatterGetListActionTypes';

const localStorageKey = 'organicMatters';

const organicMatterGetListAction =
    (): OrganicMatterListGetCallback =>
    async (dispatch: Dispatch<OrganicMatterListGetDispatchType>): Promise<boolean> => {
        try {
            dispatch({
                type: ORGANIC_MATTER_LIST_GET_LOADING,
            });

            const organicMatters = localStorage.getItem(localStorageKey);

            if (!organicMatters) {
                const res: AxiosResponse<OrganicMatterListGetSuccessDataT> =
                    await StaticApiService.getOrganicMatterLevelList();

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));
                dispatch({
                    type: ORGANIC_MATTER_LIST_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: ORGANIC_MATTER_LIST_GET_SUCCESS,
                    payload: JSON.parse(organicMatters),
                });
            }

            return true;
        } catch (error) {
            dispatch({
                type: ORGANIC_MATTER_LIST_GET_FAILED,
                error: error as string,
            });

            return false;
        }
    };

export default organicMatterGetListAction;
