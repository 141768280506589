import FarmSeasonLivestocksApiService from '@services/farmSeasonLivestocksApiService/farmSeasonLivestocksApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    ConfirmNotEligibleFarmSeasonLivestocksInstallationDataT,
    ConfirmNotEligibleFarmSeasonLivestocksInstallationDispatchTypesT,
    ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseDataT,
    ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseErrorT,
    CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED,
    CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING,
    CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS,
} from './ConfirmNotEligibleFarmSeasonLivestocksInstallationTypes';

export const confirmNotEligibleFarmSeasonLivestocksInstallation =
    (farmId: number, farmSeasonId: number, body: ConfirmNotEligibleFarmSeasonLivestocksInstallationDataT) =>
    async (dispatch: Dispatch<ConfirmNotEligibleFarmSeasonLivestocksInstallationDispatchTypesT>) => {
        try {
            dispatch({ type: CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING });
            const res: AxiosResponse<ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseDataT> =
                await FarmSeasonLivestocksApiService.confirmLivestocksNotEligibleIntallation(
                    farmId,
                    farmSeasonId,
                    body,
                );

            dispatch({
                type: CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS,
                payload: res.data,
            });

            return res.data;
        } catch (error) {
            dispatch({
                type: CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED,
                error: error as ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseErrorT,
            });

            return false;
        }
    };

export const placeholder = '';
