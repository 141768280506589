import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useRadioButtonStyle = makeStyles<{ error?: boolean }>()((theme, { error }) => ({
    paperContainer: {
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        textAlign: 'left',
        marginBottom: '10px',
        boxShadow: `
      0px 3px 5px -1px ${alpha(theme.palette.spanishWhiteShadow, 0.2)},
      0px 6px 10px 0px ${alpha(theme.palette.spanishWhiteShadow, 0.5)},
      0px 1px 18px 0px ${alpha(theme.palette.spanishWhiteShadow, 0.2)}
    `,
    },
    radioButtonControl: {
        width: '100%',
        padding: '3px 5px',
        '& .MuiTypography-body1': {
            color: error ? theme.palette.error.dark : 'inherit',
        },
    },
    radio: {
        '&:hover': {
            background: 'transparent',
        },
    },
    icon: {
        color: error ? theme.palette.error.dark : 'inherit',
    },
}));

export default useRadioButtonStyle;
