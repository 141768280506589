import { useState } from 'react';

type CallbackT = (() => Promise<unknown> | void) | undefined;

/** provide the loading state while the callback is pending or not. */
const useCallbackLoading = <T extends CallbackT>({ callback }: { callback?: T }) => {
    const [loading, setLoading] = useState(false);
    const newCallback = callback
        ? async () => {
              setLoading(true);
              try {
                  await callback?.();
              } finally {
                  setLoading(false);
              }
          }
        : undefined;

    return { callback: newCallback as T, loading };
};

export default useCallbackLoading;
