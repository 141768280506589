import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    TillageListGetCallback,
    TillageListGetDispatchType,
    TillageListGetSuccessDataT,
    TILLAGE_LIST_GET_FAILED,
    TILLAGE_LIST_GET_LOADING,
    TILLAGE_LIST_GET_SUCCESS,
} from './TillageGetListActionTypes';

const localStorageKey = 'tillages';

const tillageGetListAction =
    (): TillageListGetCallback =>
    async (dispatch: Dispatch<TillageListGetDispatchType>): Promise<boolean> => {
        try {
            dispatch({
                type: TILLAGE_LIST_GET_LOADING,
            });

            const tillages = localStorage.getItem(localStorageKey);

            if (!tillages) {
                const res: AxiosResponse<TillageListGetSuccessDataT> = await StaticApiService.getTillageList();

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));
                dispatch({
                    type: TILLAGE_LIST_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: TILLAGE_LIST_GET_SUCCESS,
                    payload: JSON.parse(tillages),
                });
            }

            return true;
        } catch (error) {
            dispatch({
                type: TILLAGE_LIST_GET_FAILED,
                error: error as string,
            });

            return false;
        }
    };

export default tillageGetListAction;
