import { TextField, Typography } from '@mui/material';

import useInputRowStyle from './InputRow.style';
import { InputRowPropsT } from './InputRowTypes';

const InputRow = ({ label, TextFieldProps }: InputRowPropsT): JSX.Element => {
    const { classes } = useInputRowStyle();

    return (
        <div className={classes.inputRowContainer}>
            <Typography className={classes.textLabel} variant="body2">
                {label}
            </Typography>
            <TextField
                fullWidth={false}
                inputProps={{
                    step: 1,
                    min: 0,
                }}
                variant="outlined"
                {...TextFieldProps}
                className={`${classes.textFieldPassage} ${TextFieldProps.className}`}
            />
        </div>
    );
};

export default InputRow;
