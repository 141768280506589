import { CropT } from '@store/reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';

const getCropSlug = (crops: CropT[], crop_id: number): string => {
    const crop_arr = crops?.filter((crop) => crop.id === crop_id);

    if (crop_arr?.length > 0) {
        return crop_arr[0]?.translation_slug ?? '';
    }

    return 'db.crop.slug-others';
};

export default getCropSlug;
