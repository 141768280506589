import { makeStyles } from 'tss-react/mui';

const useIrrigationTaskViewStyle = makeStyles()(() => ({
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '15px',
        marginBottom: '40px',
    },
    progressBar: {},
}));

export default useIrrigationTaskViewStyle;
