import { makeStyles } from 'tss-react/mui';

const useSearchPageViewStyle = makeStyles()(() => ({
    container: {},
    searchContainer: {
        height: 'calc(100% - 80px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchSection: {
        marginTop: '20px',
    },
}));

export default useSearchPageViewStyle;
