import useAppNavigate from '@hooks/useAppNavigate';
import { ModalPropsT, useModal } from '@soil-capital/ui-kit';
import useUrlQueryParams from '@hooks/useUrlQueryParams';
import { useEffect } from 'react';
import { ModalControlByUrlPropsT } from './ModalControlWithUrlTypes';

/** open the modal with the controller if the slug is found in the url '?modal=<urlModalSlug>'.
 * The controller can be provided to control the modal from outside. But if the controller is not provided, it will use an internal one.  */
const ModalControlWithUrl = <T extends { controller: ModalPropsT }>({
    urlModalSlug,
    ModalComponent,
    modalProps,
}: ModalControlByUrlPropsT<T>) => {
    const URLSearchParams = useUrlQueryParams();
    const navigate = useAppNavigate();
    // Handle optional controller
    const internalController = useModal();
    const props = { ...modalProps, controller: modalProps.controller || internalController } as T;

    // Observe query param '?modal=' to open / close the modal
    useEffect(() => {
        if (URLSearchParams.get('modal') === urlModalSlug) {
            props.controller.openModal();
        } else if (props.controller.isModalOpen) {
            props.controller.onModalCancel();
        }
    }, [urlModalSlug, URLSearchParams]);

    return (
        <ModalComponent
            {...props}
            controller={{
                ...props.controller,
                onModalCancel: () => {
                    URLSearchParams.delete('modal');
                    navigate({ search: URLSearchParams.toString() });
                    // Will close the modal by useUrlQueryParams update
                    props.controller.onModalCancel();
                },
            }}
        />
    );
};

export default ModalControlWithUrl;
