import { makeStyles } from 'tss-react/mui';

const useInputRowStyle = makeStyles()(() => ({
    inputRowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },

    textLabel: {
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 5px',
    },

    textFieldPassage: {
        maxWidth: '140px',
    },
}));

export default useInputRowStyle;
