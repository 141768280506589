import IconEdit from '@icons/icon-edit.svg';
import SidePageLeftLayout from '@layouts/SidePageLeftLayout/SidePageLeftLayout';
import IconEye from '@mui/icons-material/RemoveRedEyeOutlined';
import { Typography } from '@mui/material';
import { ModalWarning } from '@soil-capital/ui-kit';
import { useContext, useMemo, useState } from 'react';

import { FieldsContextStore } from '../FieldsContext/FieldsContext';
import { FieldsContextStoreT } from '../FieldsContext/FieldsContextTypes';

import CardNameValueList from '@components/CardNameValueList/CardNameValueList';
import FieldAmountLabel from '@components/FieldsAmountLabelDeprecated/FieldAmountLabelDeprecated';
import IconAction from '@components/IconButton/IconAction';
import Restricted from '@components/Restricted/Restricted';
import SelectedFieldsHeader from '@components/SelectedFIeldsHeader/SelectedFieldsHeader';
import TooltipMissingSoilCharacteristics from '@components/TooltipMissingSoilCharacteristics/TooltipMissingSoilCharacteristics';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import MapService from '@services/mapService/mapService';
import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { AppStateT } from '@store/store';
import formatNumber from '@utils/formatNumber';
import { useSelector } from 'react-redux';
import { FieldsListViewPropsT } from './FieldsListTypes';

const FieldsListView = ({
    editField,
    controllerWarningOperationDone,
    selectedFieldId,
}: FieldsListViewPropsT): JSX.Element => {
    const { t, fieldList } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const currentFarm = useSelector((state: AppStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason);
    const [sortedFields, setSortedFields] = useState<FarmSeasonFieldT[]>([]);
    const baseUrlSourceData = `${process.env.REACT_APP_API_BASE_URL}/farm/${currentFarm?.id}/season/${currentFarmSeason?.id}`;
    const sortableFieldData = useMemo(
        () => fieldList.map((f) => ({ entity: f, area: f.area, name: f.name })),
        [fieldList],
    );

    return (
        <SidePageLeftLayout
            pageTitle={t('constants.fields')}
            onClose="/"
            titleSlug="constants.your-fields"
            showTitleHelp="pages.fields.title"
        >
            {/* Fields List */}
            <SelectedFieldsHeader
                onSortedFields={setSortedFields}
                sortableData={sortableFieldData}
                headerText={<FieldAmountLabel fields={fieldList} />}
            />
            <CardNameValueList
                onItemClick={(f) => editField(f.id)}
                selectedItemId={selectedFieldId}
                items={sortedFields}
                getName={(f) => (
                    <TooltipMissingSoilCharacteristics field={f}>
                        <Typography variant="h6">{f.name}</Typography>
                    </TooltipMissingSoilCharacteristics>
                )}
                getValue={(f) => `${formatNumber(f.area, 2)} ${t('constants.hectare-unit')}`}
                getActionIcons={(f) => [
                    <IconAction
                        key={f.id}
                        icon={
                            <Restricted
                                toPermission="EDIT_FARM-SEASON"
                                forResource={currentFarmSeason}
                                withProps={() => ({ fallback: <IconEye /> })}
                            >
                                <IconEdit />
                            </Restricted>
                        }
                    />,
                ]}
                getThumbnail={(item) => {
                    return {
                        src: `${baseUrlSourceData}/field/thumbnail/${item.id}?t=${item.updated_at ?? '1'}`,
                        onClick:
                            selectedFieldId === undefined || selectedFieldId === item.id
                                ? (v) => v.id && v.polygon && MapService.focusPolygons({ polygons: [v.polygon] })
                                : undefined,
                    };
                }}
            />
            {/* warning when delete a field related to operations when any operation is done */}
            <ModalWarning
                controller={controllerWarningOperationDone}
                description={t('modal.warning.field-linked-to-operations-give-error-on-delete')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
        </SidePageLeftLayout>
    );
};

export default FieldsListView;
