/* eslint-disable @typescript-eslint/no-explicit-any */
import postFarmSeasonFieldsOrganicFertilizationHistory from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicFertilizationHistory';
import { OrganicFertilizationHistoryDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsOrganicFertilizationHistoryTypes';
import palette from '@constants/palette/crop.palette';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import * as Yup from 'yup';

import { ContextT, ContextPropsT, FormDataT, GroupedFieldsT } from './OrganicFertilizationTaskContextTypes';

export const Context = createContext<ContextT>({} as ContextT);

const OrganicFertilizationTaskContext = ({ children }: ContextPropsT): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm)?.id;
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason)?.id;
    const farmSeasonFields = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);
    const loading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const dontHaveAny =
        useSelector(
            (state: HomePageStateT) =>
                state.farmSeason.currentFarmSeason?.installation?.onboarding?.organicFertilizationHistory?.dontHaveAny,
        ) || false;

    const [currentStep, setCurrentStep] = useState(1);
    const [groupedFields, setGroupedFields] = useState<GroupedFieldsT>({});
    const [selectedGroup, setSelectedGroup] = useState<FarmSeasonFieldT[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [nothingOnFields, setNothingOnFields] = useState<boolean>(false);
    const [errorNothingOnFields, setErrorNothingOnFields] = useState<boolean>(false);
    const [groupedFieldIndex, setGroupedFieldIndex] = useState<number | null>(null);
    const [keyColor, setKeyColor] = useState<{ [key: string]: string }>({});
    const [nothingToSave, setNothingToSave] = useState<boolean>(true);

    // initialize values. Groupe Field by organic fertilization year introduction and and frequency
    useEffect(() => {
        const initGroupedField =
            farmSeasonFields?.reduce((groupedObject, field) => {
                const newGroupedObject: any = { ...groupedObject };

                if (
                    field?.organic_fertilization_history?.year_introduction &&
                    field?.organic_fertilization_history?.frequency
                ) {
                    const groupKey = `${String(field.organic_fertilization_history?.year_introduction)}-${String(
                        field.organic_fertilization_history?.frequency,
                    )}`;
                    newGroupedObject[groupKey] = [...(groupedObject[groupKey] || []), { ...field }];
                }

                return newGroupedObject;
            }, {} as any) || {};

        setGroupedFields(initGroupedField);
    }, [farmSeasonFields]);

    // set up the checkbox for no organic fertilization
    useEffect(() => {
        setNothingOnFields(dontHaveAny);
    }, [dontHaveAny]);

    useEffect(() => {
        const initKeyColor = { ...keyColor };
        let colorIndex = 0;
        Object.keys(groupedFields).forEach((key) => {
            if (!initKeyColor[key] && colorIndex < palette.length) {
                while (Object.values(initKeyColor).includes(palette[colorIndex])) {
                    colorIndex += 1;
                }
                initKeyColor[key] = palette[colorIndex];
            }
        });
        setKeyColor(initKeyColor);
    }, [groupedFields]);

    const onSubmit = async () => {
        if (Object.keys(groupedFields).length === 0 && !nothingOnFields) {
            setErrorNothingOnFields(true);
        } else {
            const organicFertilizationHistories: OrganicFertilizationHistoryDataT[] = [];
            Object.keys(groupedFields).forEach((key) => {
                groupedFields[key].forEach((field) => {
                    if (field.organic_fertilization_history) {
                        organicFertilizationHistories.push(field.organic_fertilization_history);
                    }
                });
            });

            setNothingToSave(true);
            await dispatch(
                postFarmSeasonFieldsOrganicFertilizationHistory(farmId, farmSeasonId, {
                    organicFertilizationHistories,
                    dontHaveAny: nothingOnFields,
                }),
            );
            navigate('/practice-history');
        }
    };

    // Validation Shema
    const validationSchema = Yup.object({
        year_introduction: Yup.string().required(t('validation.mandatory-field')),
        frequency: Yup.number()
            .min(1, t('validation.min-max-years'))
            .max(20, t('validation.min-max-years'))
            .required(t('validation.mandatory-field')),
        year_frequency: Yup.string().test(
            'groupe-alreay-exist',
            t('pages.installationtasks.history.cover-crop.history-form.validation-group-already-exist'),
            (keyYearFrequency) => {
                let keytest = true;
                Object.keys(groupedFields).forEach((key) => {
                    if (key !== selectedKey) {
                        if (keyYearFrequency === key) {
                            keytest = false;
                        }
                    }
                });

                return keytest;
            },
        ),
    });

    // Formik forom
    const formik = useFormik<FormDataT>({
        initialValues: {
            year_introduction: '',
            frequency: 0,
            year_frequency: '',
        },
        onSubmit: () => {
            formik.validateForm().then(() => {
                const newSelectedGroup = selectedGroup.map((field) => ({
                    ...field,
                    organic_fertilization_history: field.organic_fertilization_history
                        ? {
                              ...field.organic_fertilization_history,
                              year_introduction: formik.values.year_introduction,
                              frequency: +formik.values.frequency,
                          }
                        : undefined,
                }));
                setSelectedGroup(newSelectedGroup);
                setCurrentStep(3);
            });
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: true,
    });

    const store: ContextT = {
        currentStep,
        setCurrentStep,
        t,
        formik,
        loading,
        groupedFields,
        setGroupedFields,
        selectedGroup,
        setSelectedGroup,
        selectedKey,
        setSelectedKey,
        nothingOnFields,
        setNothingOnFields,
        onSubmit,
        groupedFieldIndex,
        setGroupedFieldIndex,
        keyColor,
        farmSeasonFields,
        nothingToSave,
        setNothingToSave,
        errorNothingOnFields,
        setErrorNothingOnFields,
    };

    return <Context.Provider value={store}>{children}</Context.Provider>;
};
export default OrganicFertilizationTaskContext;
