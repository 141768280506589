import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    CountryGetCallBackT,
    CountryGetResponseFailedT,
    CountryGetResponseSuccessT,
    RegionGetCallBackT,
    RegionGetResponseFailedT,
    RegionGetResponseSuccessT,
    COUNTRY_GET_FAILED,
    COUNTRY_GET_LOADING,
    COUNTRY_GET_SUCCESS,
    REGION_GET_FAILED,
    REGION_GET_LOADING,
    REGION_GET_SUCCESS,
    StaticActionsT,
} from './StaticActionsTypes';

// TODO: Create a single file for this action : y.kharchaf@soilcapital.com
export const countryGet =
    (): CountryGetCallBackT =>
    async (dispatch: Dispatch<StaticActionsT>): Promise<CountryGetResponseFailedT> => {
        try {
            dispatch({ type: COUNTRY_GET_LOADING });

            const localCountries = localStorage.getItem('countries');

            if (!localCountries || !Array.isArray(JSON.parse(localCountries))) {
                const res: AxiosResponse<CountryGetResponseSuccessT> = await StaticApiService.getCountries();

                localStorage.setItem('countries', JSON.stringify(res.data));

                dispatch({
                    type: COUNTRY_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: COUNTRY_GET_SUCCESS,
                    payload: JSON.parse(localCountries),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: COUNTRY_GET_FAILED,
                error: error as string,
            });

            return {};
        }
    };
// TODO: Create a single file for this action : y.kharchaf@soilcapital.com
export const regionGet =
    (): RegionGetCallBackT =>
    async (dispatch: Dispatch<StaticActionsT>): Promise<RegionGetResponseFailedT> => {
        try {
            dispatch({ type: REGION_GET_LOADING });

            const localRegions = localStorage.getItem('regions');

            if (!localRegions || !Array.isArray(JSON.parse(localRegions))) {
                const res: AxiosResponse<RegionGetResponseSuccessT> = await StaticApiService.getRegions();

                dispatch({
                    type: REGION_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: REGION_GET_SUCCESS,
                    payload: JSON.parse(localRegions),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: REGION_GET_FAILED,
                error: error as string,
            });

            return {};
        }
    };
