import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_LOADING =
    'POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_LOADING';
export const POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_FAILED =
    'POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_FAILED';
export const POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS =
    'POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS';

export type OrganicFertilizationHistoryDataT = {
    id?: number;
    farm_season_field_id: number;
    year_introduction: string;
    frequency: number;
};

export type PostFarmSeasonFieldsOrganicFertilizationHistoryDataT = {
    organicFertilizationHistories: OrganicFertilizationHistoryDataT[];
    dontHaveAny: boolean;
};

export type PostFarmSeasonFieldsOrganicFertilizationHistoryResponseDataT = {
    fields: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            irrigation: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsOrganicFertilizationHistoryPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsOrganicFertilizationHistoryDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsOrganicFertilizationHistoryResponseErrorT>;

export type PostFarmSeasonFieldsOrganicFertilizationHistoryResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsOrganicFertilizationHistoryLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_LOADING;
};

export type PostFarmSeasonFieldsOrganicFertilizationHistoryFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_FAILED;
    error: PostFarmSeasonFieldsOrganicFertilizationHistoryResponseErrorT;
};

export type PostFarmSeasonFieldsOrganicFertilizationHistorySuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_ORGANIC_FERTILIZATION_HISTORY_SUCCESS;
    payload: PostFarmSeasonFieldsOrganicFertilizationHistoryResponseDataT;
};

export type PostFarmSeasonFieldsOrganicFertilizationHistoryDispatchTypesT =
    | PostFarmSeasonFieldsOrganicFertilizationHistoryLoadingT
    | PostFarmSeasonFieldsOrganicFertilizationHistoryFailedT
    | PostFarmSeasonFieldsOrganicFertilizationHistorySuccessT;
