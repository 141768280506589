import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
    FieldSuggestionResponsesMapT,
    FieldSuggestionTypeT,
    SuggestionResponseT,
} from '@services/onboardingApiService/onboardingApiServiceTypes';

export type FieldSuggestionStateT = Pick<
    {
        [key in FieldSuggestionTypeT]: SuggestionResponseT<key> | null;
    },
    'irrigation'
>;

export type StoreSuggestionParamsT<T extends FieldSuggestionTypeT> = {
    type: T;
    data: FieldSuggestionResponsesMapT[T] | null;
};

export const defaultSuggestionState: FieldSuggestionStateT = {
    irrigation: null,
};

export const slice = createSlice({
    name: 'fieldSuggestion',
    initialState: defaultSuggestionState,
    reducers: {
        storeFieldSuggestion: <T extends keyof FieldSuggestionStateT>(
            state: FieldSuggestionStateT,
            action: PayloadAction<StoreSuggestionParamsT<T>>,
        ) => {
            state[action.payload.type] = action.payload.data;
        },

        resetFieldIrrigationSuggestion: (state: FieldSuggestionStateT) => {
            state.irrigation = null;
        },
    },
});

export const { storeFieldSuggestion, resetFieldIrrigationSuggestion } = slice.actions;

export default slice.reducer;
