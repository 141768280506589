import { makeStyles } from 'tss-react/mui';

const useSelectStepViewStyle = makeStyles()((theme) => ({
    container: {},
    darkerTitle: {
        color: theme.palette.secondary.dark,
    },
    title: {
        marginBottom: '20px',
    },
    listContainer: {
        marginBottom: '25px',
    },
}));

export default useSelectStepViewStyle;
