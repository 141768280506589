/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */

import RequiredInputLabel from '@components/RequiredInputLabel/RequiredInputLabel';
import { FormControl, FormHelperText } from '@mui/material';
import ScAutocomplete from '@components/Autocomplete/Autocomplete';

import { LabelAutocompletePropsT } from './LabelAutocomplete.types';
import useLabelAutocompleteStyle from './LabelAutocomplete.style';
import { NumberFieldValue } from '@/utils/NumbersToFieldValues';

const LabelAutocomplete = <T, V extends NumberFieldValue | string>({
    id,
    label,
    loading = false,
    borderColor,
    required,
    options,
    value,
    onChange,
    placeholder,
    error,
    helperText,
    disabled,
    withHelp,
    className,
    helpName,
    sortList = true,
    tooltipSlug,
    onCreate,
    getOptionDisabled,
    getOptionLabel,
    getPropSelected = (option: any) => option?.id || '',
}: LabelAutocompletePropsT<T, V>): JSX.Element => {
    const { classes } = useLabelAutocompleteStyle();

    return (
        <div className={className}>
            <RequiredInputLabel
                className={classes.label}
                helpName={helpName}
                id={`${id}-label`}
                required={required}
                tooltipSlug={tooltipSlug}
                withHelp={withHelp}
            >
                {label}
            </RequiredInputLabel>
            <FormControl className={`${disabled ? classes.disabled : ''}`} error={error} fullWidth>
                <ScAutocomplete
                    borderColor={borderColor}
                    disabled={disabled}
                    error={error}
                    getOptionDisabled={getOptionDisabled}
                    getOptionLabel={getOptionLabel}
                    getPropSelected={getPropSelected}
                    loading={loading}
                    options={options}
                    placeholder={placeholder}
                    sortList={sortList}
                    value={value}
                    onChange={onChange}
                    onCreate={onCreate}
                />
                <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
            </FormControl>
        </div>
    );
};

export default LabelAutocomplete;
