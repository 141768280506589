import { TableColumnsT } from '@components/Table/TableViewTypes';
import TableView from '@components/Table/TableView';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TableHeaderCell from '../TableHeaderCell/TableHeaderCell';
import TableTotalCell from '../TableTotalCell/TableTotalCell';

import useCertificateTableStyle from './CertificateTable.style';
import { CertificateTablePropsT, ColumnNameT } from './CertificateTableTypes';
import formatNumber from '@utils/formatNumber';
import useCertificateTableLogic from './CertificateTable.logic';

const CertificateTable = ({
    farmSeasonCertificates,
    firstHarvestYear,
    previousProductPlanId,
    hideColumns,
    size = 'medium',
}: CertificateTablePropsT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useCertificateTableStyle();

    const {
        rows,
        averageAvgPrice,
        totalCertificates,
        totalOnMarket,
        totalBufferAdjustment,
        totalHeldInBuffer,
        hasAnyVcVcn,
        totalVcVcn,
        totalRevenue,
        hasAnyPurchaseOrderValue,
    } = useCertificateTableLogic({ farmSeasonCertificates, firstHarvestYear, previousProductPlanId });

    const columns: TableColumnsT<ColumnNameT> = [
        {
            field: 'year',
            headerName: <TableHeaderCell value={t('pages.carbon-bank.table.harvest')} />,
            renderColCell: ({ value, rowIndex }) => (
                <Typography
                    color="secondary"
                    style={{
                        whiteSpace: 'nowrap',
                        opacity: rowIndex !== 0 && farmSeasonCertificates.length === 1 ? 0.5 : 1,
                    }}
                >
                    {value}
                </Typography>
            ),
            maxWidth: '150px',
        },
        {
            field: 'revenue',
            headerName: (
                <TableHeaderCell
                    tooltipSlug="tooltip.carbon-bank.table.revenue"
                    value={t('pages.carbon-bank.table.revenue')}
                />
            ),
            maxWidth: '150px',
            renderColCell: ({ value, row: { certificates } }) =>
                certificates && (
                    <Typography
                        className={classes.noWrap}
                        color="secondary"
                        style={{ opacity: value.includes(t('constants.min')) ? 0.5 : 1}}
                    >
                        {value}
                    </Typography>
                ),
        },
        {
            field: 'avgPrice',
            headerName: (
                <TableHeaderCell
                    tooltipSlug="tooltip.carbon-bank.table.avg-price"
                    value={t('pages.carbon-bank.table.avg-price')}
                />
            ),
            maxWidth: '150px',
            renderColCell: ({ value, row: { certificates } }) =>
                certificates && (
                    <Typography
                        className={classes.noWrap}
                        color="secondary"
                        style={{ opacity: value.includes(t('constants.min')) ? 0.5 : 1 }}
                    >
                        {value}
                    </Typography>
                ),
        },
        {
            field: 'onMarket',
            headerName: (
                <TableHeaderCell
                    tooltipSlug="tooltip.carbon-bank.table.on-market"
                    value={t('pages.carbon-bank.table.on-market')}
                />
            ),
            maxWidth: '150px',
        },
        {
            field: 'inBuffer',
            headerName: (
                <TableHeaderCell
                    tooltipSlug="tooltip.carbon-bank.table.held-in-buffer"
                    value={t('pages.carbon-bank.table.held-in-buffer')}
                />
            ),
            maxWidth: '150px',
        },
        {
            field: 'bufferAdjustment',
            headerName: (
                <TableHeaderCell
                    tooltipSlug="pages.carbon-bank.table.buffer-adjustment.tooltip"
                    value={t('pages.carbon-bank.table.buffer-adjustment.title')}
                />
            ),
            maxWidth: '150px',
        },
        {
            field: 'certificates',
            headerName: (
                <TableHeaderCell
                    tooltipSlug="tooltip.carbon-bank.table.certificates"
                    value={t('pages.carbon-bank.table.certificates')}
                />
            ),
            maxWidth: '150px',
        },
        {
            field: 'vcVcn',
            headerName: hasAnyVcVcn && (
                <TableHeaderCell
                    tooltipSlug="tooltip.carbon-bank.table.vc-vcn"
                    value={t('pages.carbon-bank.table.vc-vcn')}
                />
            ),
            maxWidth: '150px',
        },
        {
            field: 'purchaseOrder',
            headerName: hasAnyPurchaseOrderValue && (
                <TableHeaderCell
                    tooltipSlug="tooltip.carbon-bank.table.purchase-order"
                    value={t('pages.carbon-bank.table.purchase-order')}
                />
            ),
            maxWidth: '150px',
            renderColCell: ({ value }) =>
                value && (
                    <Link component="button" variant="body2" onClick={() => window.open(value as string)}>
                        {t('constants.download')}
                    </Link>
                ),
        },
    ];

    const filteredColumns = columns.filter((col) => !hideColumns?.includes(col.field));

    return (
        <div className={classes.CertificateTable}>
            <TableView<ColumnNameT>
                color="secondary"
                columns={filteredColumns}
                rows={[
                    ...rows,
                    {
                        year: t('constants.total').toUpperCase(),
                        certificates: formatNumber(totalCertificates),
                        inBuffer: formatNumber(totalHeldInBuffer),
                        onMarket: formatNumber(totalOnMarket),
                        bufferAdjustment: formatNumber(
                            isNaN(totalBufferAdjustment) || totalBufferAdjustment === 0 ? 0 : -totalBufferAdjustment,
                        ),
                        avgPrice: averageAvgPrice,
                        revenue: totalRevenue,
                        vcVcn: totalVcVcn,
                        purchaseOrder: null,
                        renderRowCell: ({ value, col }) => (
                            <TableTotalCell id={'total-cell-' + col.field} size={size} value={value} />
                        ),
                    },
                ]}
                size={size}
            />
        </div>
    );
};

export default CertificateTable;
