import { useModal } from '@soil-capital/ui-kit';
import { TOTAL_AREA_PERCENT_DECREASE_THRESHOLD } from '@constants/totalAreaThresholds';
import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import { CheckTotalAreaResponseT } from '@services/farmSeasonApiService/FarmSeasonApiServiceTypes';
import SegmentService from '@services/segmentService/segmentService';
import { useState } from 'react';
import TotalAreaDecreaseModal from './TotalAreaDecreaseModal';
import TotalAreaIncreaseModal from './TotalAreaIncreaseModal';
import { UseTotalAreaCheckModalsParamsT } from './useTotalAreaCheckModalsTypes';

const useTotalAreaCheckModals = ({ farmId, farmSeasonId }: UseTotalAreaCheckModalsParamsT) => {
    const [checkTotalAreaLoading, setCheckTotalAreaLoading] = useState(false);
    const totalAreaIncreaseController = useModal();
    const totalAreaDecreaseController = useModal();
    const [checkTotalAreaResponse, setCheckTotalAreaResponse] = useState<CheckTotalAreaResponseT>();

    const checkTotalAreaWithPreviousSeason = async () => {
        setCheckTotalAreaLoading(true);
        const { data } = await FarmSeasonApiService.checkTotalAreaWithPreviousSeason({ farmId, farmSeasonId });
        setCheckTotalAreaResponse(data);
        setCheckTotalAreaLoading(false);

        SegmentService.totalAreaCheckTrack({
            baseline_user_area: data.baseline_user_area_with_pasture,
            current_total_user_area: data.current_season_user_area_with_pasture,
            season_original_area: data.current_season_original_area_with_pasture,
            total_area_diff_ha: data.total_user_area_with_pasture_diff_ha,
            total_area_diff_percent: data.total_user_area_with_pasture_diff_percent,
            total_area_increase: data.has_user_area_with_pasture_increased,
        });

        let userStopped = false;
        // If total area increase didn't passed the BE check, display increase modal
        if (!data.check_area_grow_passed) {
            await totalAreaIncreaseController.openModal();
            userStopped = true;

            SegmentService.totalAreaIncreaseWarningTrack({
                action_result: 'cancel',
                baseline_total_user_area: data.baseline_user_area_with_pasture,
                current_total_user_area: data.current_season_user_area_with_pasture,
                original_total_area: data.current_season_original_area_with_pasture,
                previous_total_user_area: data.previous_season_user_area_with_pasture,
            });
        }

        // If total area decrease more than threshold, display decrease modal
        if (
            !data.has_user_area_with_pasture_increased &&
            data.total_user_area_with_pasture_diff_percent > TOTAL_AREA_PERCENT_DECREASE_THRESHOLD
        ) {
            const totalAreaDecreaseTrack = (resultAction: 'cancel' | 'save') => {
                SegmentService.totalAreaDecreaseConfirmationTrack({
                    action_result: resultAction,
                    baseline_total_user_area: data.baseline_user_area_with_pasture,
                    current_total_user_area: data.current_season_user_area_with_pasture,
                    original_total_area: data.current_season_original_area_with_pasture,
                    previous_total_user_area: data.previous_season_user_area_with_pasture,
                });
            };
            // user is stopped if he don't agree with the message
            await totalAreaDecreaseController.openModal(
                () => {
                    userStopped = false;
                    totalAreaDecreaseTrack('save');
                },
                () => {
                    userStopped = true;
                    totalAreaDecreaseTrack('cancel');
                },
            );
        }

        return { userStopped };
    };

    return {
        checkTotalAreaWithPreviousSeason,
        checkTotalAreaLoading,
        TotalAreaIncreaseModal: checkTotalAreaResponse?.total_user_area_with_pasture_diff_ha && (
            <TotalAreaIncreaseModal
                controller={totalAreaIncreaseController}
                totalAreaDiffHa={checkTotalAreaResponse?.total_user_area_with_pasture_diff_ha}
            />
        ),
        TotalAreaDecreaseModal: checkTotalAreaResponse?.previous_season_user_area_with_pasture && (
            <TotalAreaDecreaseModal
                controller={totalAreaDecreaseController}
                currentSeasonArea={checkTotalAreaResponse.current_season_user_area_with_pasture}
                previousSeasonArea={checkTotalAreaResponse.previous_season_user_area_with_pasture}
            />
        ),
    };
};

export default useTotalAreaCheckModals;
