import {
    PostFarmSeasonFieldsPastureHistoryDataT,
    PostFarmSeasonFieldsPastureHistoryResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsPastureHistoryTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsPastureHistoryApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsPastureHistoryDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsPastureHistoryResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/pasture-history`,
            method: 'POST',
            data: body,
        });
}

export default farmSeasonFieldsPastureHistoryApiService;
