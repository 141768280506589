import { DeleteFarmSeasonOperationDeleteDispatchTypesT } from './DeleteFarmSeasonOperationTypes';
import { PostFarmSeasonOperationSowingDispatchTypesT } from './PostFarmSeasonOperationSowingTypes';
import { PostFarmSeasonOperationSprayingDispatchTypesT } from './PostFarmSeasonOperationSprayingTypes';
import { PostFarmSeasonOperationTillageDispatchTypesT } from './PostFarmSeasonOperationTillageTypes';

export const RESET_OPERATION_FERTILISERS = 'RESET_OPERATION_FERTILISERS';

export type ResetOperationFertilisers = {
    type: typeof RESET_OPERATION_FERTILISERS;
};

export type FarmSeasonOperationActionsT =
    | DeleteFarmSeasonOperationDeleteDispatchTypesT
    | PostFarmSeasonOperationSowingDispatchTypesT
    | PostFarmSeasonOperationSprayingDispatchTypesT
    | PostFarmSeasonOperationTillageDispatchTypesT
    | ResetOperationFertilisers;
