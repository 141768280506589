import { makeStyles } from 'tss-react/mui';

const usePrintableTableStyle = makeStyles()((theme) => ({
    PrintableTable: {},
    tableContainer: {
        display: 'table',
        width: '100%',
    },
    tableCaption: {
        textAlign: 'center',
        marginTop: '30px',
        marginBottom: '50px',
    },
    tooltipDescriptionLabel: {
        marginBottom: '20px',
        color: theme.palette.text.disabled,
    },
    tooltipDescription: {
        marginLeft: '10px',
    },
}));

export default usePrintableTableStyle;
