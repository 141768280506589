import { Typography } from '@mui/material';
import { Prompt } from '@components/Prompt/Prompt';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import MenuBar from '@components/MenuBar/MenuBar';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Trans } from 'react-i18next';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import { CoverCropTaskViewPropsT } from './CoverCropTaskTypes';
import useCoverCropTaskStyle from './CoverCropTaskView.style';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import { ModalWarning } from '@soil-capital/ui-kit';
import { useSelector } from 'react-redux';
import selectIsYoySeason from '@store/selectors/selectIsYoySeason';
import CallToActionSuggestion from '../../../components/CallToActionSuggestion/CallToActionSuggestion';
import useFieldSuggestion from '../../../hooks/useFieldSuggestion';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const CoverCropTaskView = ({
    t,
    isSubmitted,
    isDirty,
    onSelectedFieldDelete,
    onSelectAll,
    onConfirm,
    formik,
    fieldLoading,
    modalDeleteSelection,
    deleteAll,
}: CoverCropTaskViewPropsT): JSX.Element => {
    const { classes } = useCoverCropTaskStyle();
    const isYoySeason = useSelector(selectIsYoySeason());
    const { handleBringBackSuggestion, needAttentionFields, suggestedFields, suggestionDone } = useFieldSuggestion({
        suggestionType: 'cover-crop',
    });
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={false}
            />
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={1} totalSteps={1} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.fields-subtasks.cover-crop-task.menu-title')}
                    onArrowBack=".."
                    onClose=".."
                />
                <div>
                    <Prompt
                        messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                        messageText={t('modal.navigation-prompt-if-unsaved.text')}
                        when={!isSubmitted && isDirty}
                    />
                    <Typography className={classes.title} color="secondary" variant="h4">
                        <Trans i18nKey="pages.installationtasks.fields-subtasks.cover-crop-task.title">
                            Veullez selectioner <span className={classes.titleDarker}>sur la carte</span> les champs qui
                            ont des couverts vegetaux
                        </Trans>
                        <HelpButtonView id="pages.installationtasks.fields-subtasks.cover-crop-task.title" />
                    </Typography>

                    {canEditFarmSeason.access && isYoySeason && (
                        <CallToActionSuggestion
                            suggestion={{
                                done: suggestionDone,
                                needAttentionCount: needAttentionFields.length,
                                suggestedCount: suggestedFields.length,
                            }}
                            onClick={handleBringBackSuggestion}
                        />
                    )}

                    <SelectedFieldsContainer
                        disabled={!canEditFarmSeason.access}
                        suggestedFields={suggestedFields}
                        needAttentionFields={needAttentionFields}
                        handleRemoveSelectedPolygon={onSelectedFieldDelete}
                    />

                    <LabelCheckboxView
                        disabled={!canEditFarmSeason.access}
                        border="dotted"
                        checked={!formik.values.coverCrop}
                        error={formik.touched.coverCrop && Boolean(formik.errors.coverCrop)}
                        helperText={formik.touched.coverCrop ? formik.errors.coverCrop : undefined}
                        label={t('pages.installationtasks.fields-subtasks.cover-crop-task.no-field-cover-crop')}
                        name="coverCrop"
                        onChange={(event, checked) => {
                            if (formik.values.fields.length) {
                                modalDeleteSelection.openModal(() => {
                                    deleteAll();
                                    formik.setFieldValue('coverCrop', !checked);
                                });
                            } else {
                                formik.setFieldValue('coverCrop', !checked);
                            }
                        }}
                    />
                    <TwoButtonFooterView
                        leftOnClick={onSelectAll}
                        leftText={canEditFarmSeason.access ? t('constants.select-all-fields') : ''}
                        rightButtonOverride={
                            <RestrictSaveFarmSeason>
                                <LoadingButton
                                    loading={fieldLoading}
                                    onClick={() => onConfirm()}
                                    text={t('constants.save')}
                                />
                            </RestrictSaveFarmSeason>
                        }
                    />
                </div>
            </div>
            <ModalWarning
                controller={modalDeleteSelection}
                confirmMessage={t(
                    'pages.installationtasks.fields-subtasks.cover-crop-task.delete-modal.confirm-message',
                )}
                description={t('pages.installationtasks.fields-subtasks.cover-crop-task.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.fields-subtasks.cover-crop-task.delete-modal.title')}
            />
        </div>
    );
};

export default CoverCropTaskView;
