import { useGetActiveFarmSeasonProductPlanQuery } from '@api/farmSeasonProductPlan.api';
import {
    upsellApi,
    useCancelUpsellMutation,
    useGetUpsellStatusQuery,
    useLazySendReminderEmailQuery,
    usePayUpsellMutation,
} from '@api/upsell.api';
import { useGetUserCompanyQuery } from '@api/userCompany.api';
import useBillingDetailsUpsellFlow from '@components/BillingDetailsForm/hooks/useBillingDetailsUpsellFlow';
import { PRODUCT_PLAN_ID, STANDARD_PLAN_PRICE } from '@constants/productPlan';
import useAppDispatch from '@hooks/useAppDispatch';
import useHomePageLoading from '@hooks/useHomePageLoading';
import { useCanUpdateSelfUpsell, useCanUpdateUpsellForFarmer } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import formatCurrencyNumber from '@services/formatCurrencyNumber/formatCurrencyNumber';
import SegmentService from '@services/segmentService/segmentService';
import { UserCompanyT } from '@services/userCompanyApiService/userCompanyApiServiceTypes';
import { useModal } from '@soil-capital/ui-kit';
import { useSelectLatestFarmSeason } from '@store/selectors/selectLatestFarmSeason';
import { getLocaleDate } from '@utils/getLocaleDate';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useUpsellActionsLogic = () => {
    const { addPendingState } = useHomePageLoading();
    const { i18n, t } = useTranslation();
    const dispatch = useAppDispatch();
    const canUpdateUpsellForFarmer = useCanUpdateUpsellForFarmer();
    const canUpdateSelfUpsell = useCanUpdateSelfUpsell();

    const paymentModalController = useModal();
    const upsellModalController = useModal<UserCompanyT>();

    const userId = useSelector((state: HomePageStateT) => state.auth.selectedUser?.id || state.auth.user?.id);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const userRoleId = useSelector((state: HomePageStateT) => state.auth.user?.role_id);
    const currencyId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.country.currency_id);
    const latestFarmSeason = useSelectLatestFarmSeason();
    const selectedFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const isPrepaidPlan = useSelector(
        (state: HomePageStateT) => !!state.farm.currentFarm.partner?.default_prepaid_product_plan_id,
    );
    const isBaseline = useSelector(
        (state: HomePageStateT) => !state.farmSeason?.currentFarmSeason.baseline_farm_season_id,
    );

    const { isLoading: userCompanyLoading, data: userCompany } = useGetUserCompanyQuery({
        userId,
    });

    useEffect(() => {
        addPendingState({ pending: userCompanyLoading, slug: 'userCompanyLoading' });
    }, [userCompanyLoading]);

    const { isLoading: isAFSPPLoading, data: AFSPPData } = useGetActiveFarmSeasonProductPlanQuery({
        farmId,
        farmSeasonId,
    });

    useEffect(() => {
        addPendingState({ pending: isAFSPPLoading, slug: 'AFSPPLoading' });
    }, [isAFSPPLoading]);

    const { isLoading: upsellStatusLoading, data: upsellStatus } = useGetUpsellStatusQuery({
        farmId,
        farmSeasonId,
    });

    useEffect(() => {
        addPendingState({ pending: upsellStatusLoading, slug: 'upsellStatusLoading' });
    }, [upsellStatusLoading]);

    const {
        billingDetailsFormRef: upsellFormRef,
        onSubmitForm: onSubmitUpsellForm,
        isSubmitting: upsellSubmitting,
    } = useBillingDetailsUpsellFlow();

    const setUpsellStatus = (status: boolean) =>
        dispatch(
            upsellApi.util.updateQueryData(
                'getUpsellStatus',
                { farmId: farmId, farmSeasonId: farmSeasonId },
                (draft) => {
                    Object.assign(draft, { started: status });
                },
            ),
        );

    const submitUpsellForm = async () => {
        const data = await onSubmitUpsellForm();

        if (data) {
            setUpsellStatus(true);
            return true;
        }

        return false;
    };

    const handleUpsellClick = () => {
        if (canUpdateUpsellForFarmer) {
            upsellModalController.openModal();
        } else {
            paymentModalController.openModal();
        }
        SegmentService.upsellButtonClickedTrack({ baseline: isBaseline, user_role_id: userRoleId ?? null });
    };

    const [cancelUpsell, { isLoading: cancelUpsellLoading }] = useCancelUpsellMutation();

    const handleCancelUpsellClick = async () => {
        await cancelUpsell({ farmId: farmId, farmSeasonId: farmSeasonId });
    };

    const [sendReminderEmail, { isLoading: sendReminderEmailLoading }] = useLazySendReminderEmailQuery();

    const handleSendReminderEmailClick = async () => {
        SegmentService.upsellPaymentEmailRequestedTrack({ baseline: isBaseline });
        await sendReminderEmail({ farmId, farmSeasonId });

        const reminderEmailSentSnackbarMessage: string = t(
            'pages.settings.billing.plan.upsell.banner.payment-email-sent',
        );
        enqueueSnackbar(reminderEmailSentSnackbarMessage, {
            variant: 'success',
        });
    };

    const [payUpsell, { isLoading: payUpsellLoading }] = usePayUpsellMutation();

    const handleUpsellPaymentClick = async () => {
        SegmentService.upsellFinalisePaymentTrack({ baseline: isBaseline, user_role_id: userRoleId ?? null });

        const data = await payUpsell({ farmId, farmSeasonId }).unwrap();

        if (data) {
            return window.location.replace(data.redirectUrl);
        }
    };

    return {
        isUpsell: AFSPPData?.upsell,
        upsellDate: AFSPPData ? getLocaleDate(AFSPPData.created_at, i18n.language) : null,
        canSeeUpSellButton:
            AFSPPData &&
            AFSPPData.product_plan_id === PRODUCT_PLAN_ID.BASIC &&
            !isPrepaidPlan &&
            (canUpdateSelfUpsell || canUpdateUpsellForFarmer),
        canUpdateUpsellForFarmer:
            AFSPPData &&
            AFSPPData.product_plan_id === PRODUCT_PLAN_ID.BASIC &&
            !isPrepaidPlan &&
            canUpdateUpsellForFarmer,
        hasStartedUpsell: upsellStatus?.started,
        cannotUpsell: latestFarmSeason?.id !== selectedFarmSeason.id,
        formatedStandardPrice: formatCurrencyNumber(STANDARD_PLAN_PRICE, currencyId == 2 ? 'GBP' : 'EUR'),
        paymentModalController: paymentModalController,
        userCompany,
        upsellModalController,
        upsellSubmitting,
        upsellFormRef,
        submitUpsellForm,
        handleUpsellClick,
        cancelUpsellLoading,
        handleCancelUpsellClick,
        handleSendReminderEmailClick,
        sendReminderEmailLoading,
        handleUpsellPaymentClick,
        payUpsellLoading,
    };
};

export default useUpsellActionsLogic;
