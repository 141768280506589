import { useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import LoaderComponent from './loader';
import useLoaderStyles from './loader.styles';

/**
 * @deprecated use LoadingSection instead, or useHomePagePendingState() for global loader.
 * Add a loader + background opacity for a container (with containerRef)
 * */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useLoader = (loading?: boolean, loaderOptions?: { isOpaque?: boolean; bgColor?: string }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [loaderContainer, setLoaderContainer] = useState<HTMLDivElement>();
    const { classes } = useLoaderStyles({ ...loaderOptions });
    const theme = useTheme();

    useEffect(() => {
        if (!containerRef.current) {
            console.warn(
                'The containerRef should be set as a ref for "useLoader" hook. Maybe your ref is not rendered ?',
            );

            return;
        }

        if (loading && !loaderContainer) {
            const el = document.createElement('div');
            containerRef.current.style.position = 'relative';
            containerRef.current.style.overflow = 'hidden';
            setLoaderContainer(el);
            containerRef.current.appendChild(el);
            const root = createRoot(el);

            root.render(LoaderComponent({ classes, theme }));
            el.style.position = 'absolute';
            el.style.zIndex = '100';
            el.style.top = '0';
            el.style.right = '0';
            el.style.bottom = '0';
            el.style.left = '0';
        } else if (!loading && loaderContainer) {
            loaderContainer.style.transition = 'opacity ease .4s';
            loaderContainer.style.opacity = '0';
            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.style.overflow = '';
                    containerRef.current.removeChild(loaderContainer);
                }
            }, 300);
            setLoaderContainer(undefined);
        }
    }, [loading, containerRef]);

    return { containerRef };
};

export default useLoader;
