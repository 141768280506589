import TillagePracticeTaskContext from './TillagePracticeTaskContext/TillagePracticeTaskContext';
import TillagePracticeTaskView from './TillagePracticeTaskView';

const TillagePracticeTask = (): JSX.Element => (
    <TillagePracticeTaskContext>
        <TillagePracticeTaskView />
    </TillagePracticeTaskContext>
);

export default TillagePracticeTask;
