import { UserT } from '@reducers/AuthReducer/AuthReducerTypes';

export const AUTHORIZE_AUTH0_LOADING = 'AUTHORIZE_AUTH0_LOADING';
export const AUTHORIZE_AUTH0_FAILED = 'AUTHORIZE_AUTH0_FAILED';
export const AUTHORIZE_AUTH0_SUCCESS = 'AUTHORIZE_AUTH0_SUCCESS';

export type AuthorizeAuth0ActionDataT = {
    token: string;
};

export type AuthorizeAuth0ResponseDataT = {
    user: UserT;
    access_token: string;
    expires_in: number;
};

export type AuthorizeAuth0ResponseErrorT = {
    message?: string;
};

export type AuthorizeAuth0LoadingT = {
    type: typeof AUTHORIZE_AUTH0_LOADING;
};

export type AuthorizeAuth0FailedT = {
    type: typeof AUTHORIZE_AUTH0_FAILED;
    payload: {
        error: AuthorizeAuth0ResponseErrorT;
    };
};

export type AuthorizeAuth0SuccessT = {
    type: typeof AUTHORIZE_AUTH0_SUCCESS;
    payload: AuthorizeAuth0ResponseDataT;
};

export type AuthorizeAuth0DispatchTypesT = AuthorizeAuth0LoadingT | AuthorizeAuth0FailedT | AuthorizeAuth0SuccessT;
