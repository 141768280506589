import { createTheme } from '@mui/material/styles';

import { SCTheme } from '@soil-capital/ui-kit';
import typography from './typography';
import Select from './components/Select';
import Button from './components/Button';
import { deepmerge } from '@mui/utils';
import palette from './palette';

const theme = createTheme(
    deepmerge(SCTheme, {
        typography,
        components: { MuiSelect: Select, MuiButton: Button },
        palette: { ...palette, white: { main: '#ffffff' } },
    }),
);

export default theme;
