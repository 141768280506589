import { makeStyles } from 'tss-react/mui';

const useYieldStepViewStyle = makeStyles()((theme) => ({
    container: {},
    title: {
        color: theme.palette.secondary.dark,
    },
    subtitle: {
        marginBottom: '20px',
    },
    darkColor: {
        color: theme.palette.secondary.dark,
    },
    listContainer: {
        marginBottom: '20px',
    },
    emptyFieldContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '120px',
    },
    fieldContainer: {
        minHeight: '120px',
        padding: '10px',
        backgroundColor: 'rgba(237,200,128,0.15)',
        marginBottom: '20px',
    },
}));

export default useYieldStepViewStyle;
