import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldPopupItemLogicPropsT } from '../FieldPopupItem/FieldPopupItem.types';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import { useEffect } from 'react';
import useMapObserver from '../Map/hooks/useMapObserver';
import SegmentService from '@services/segmentService/segmentService';

const useFieldPopupItemLogic = ({ field, farmSeason }: FieldPopupItemLogicPropsT) => {
    const { t } = useTranslation();

    const currentFarmSeason = farmSeason ?? useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const season = useSelector((state: HomePageStateT) =>
        state.static.seasons.find((s) => s.id === currentFarmSeason.season_id),
    );
    const tillagePracticeItem = useSelector((state: HomePageStateT) =>
        state.static.tillagePracticeList.find((t) => t.id === field?.tillage_id),
    );
    const isBaseline = useSelector(selectIsBaselineSeason());

    const crops = field?.field_crops?.map((fc) => ({
        key: fc.farm_season_crop.id,
        name: t(fc.farm_season_crop.crop.translation_slug ?? ''),
        isAccompanied: fc.farm_season_crop.is_accompanied,
    }));

    useEffect(() => {
        SegmentService.fieldOverviewDisplayedTrack({ past_season_information: !isBaseline });
    }, []);

    useMapObserver('closeMapPopup', ({ timeOpened, closingAction }) => {
        SegmentService.fieldOverviewClosedTrack({
            past_season_information: !isBaseline,
            closing_action: closingAction,
            time_opened: timeOpened,
        });
    });
    return {
        fieldName: field?.name,
        fieldArea: field?.area,
        tillagePractice: t(tillagePracticeItem?.translation_slug ?? ''),
        harvestYear: season?.harvest_year,
        harvestYearTitle: `${t('constants.harvest')} ${season?.harvest_year}`,
        crops,
        isOrganic: field?.is_organic,
        isIrrigated: field?.is_irrigated,
        isDrained: field?.has_drainage,
        hasAgroforestry: field?.has_agroforestry,
        hasCoverCrop: field?.has_cover_crops,
    };
};

export default useFieldPopupItemLogic;
