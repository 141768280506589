// modified version of mapbox-gl-draw-snap mode
// source: https://github.com/mhsattarian/mapbox-gl-draw-snap-mode
// Heavily inspired from work of @davidgilbertson on GitHub and `leaflet-geoman` project.

import bboxPolygon from '@turf/bbox-polygon';
import booleanDisjoint from '@turf/boolean-disjoint';
import { getCoords } from '@turf/invariant';
import distance from '@turf/distance';
import polygonToLine from '@turf/polygon-to-line';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import midpoint from '@turf/midpoint';

export const snapOptions = {
    SNAP: true, // defaults to false
    SNAP_PX: 10, // defaults to 15
    SNAP_TO_MID_POINTS: true, // defaults to false
    SNAP_VERTEX_PRIORITY_DISTANCE: 1, // defaults to 1.25
};

export const createSnapList = (map, polygonsLayer, currentFeature) => {
    // Get all "snap-to" features
    const polygonsLayerFeatures = polygonsLayer ?? map.getSource('polygonsLayer')?._options?.data?.features ?? [];
    const emptySpaceLayerFeatures = map.getSource('emptySpaceLayer')?._options?.data?.features ?? [];
    const features = [...polygonsLayerFeatures, ...emptySpaceLayerFeatures];
    const snapList = [];

    // Get current bbox as polygon
    const bboxAsPolygon = (() => {
        const canvas = map.getCanvas(),
            w = canvas.width,
            h = canvas.height,
            cUR = map.unproject([w, 0]).toArray(),
            cLL = map.unproject([0, h]).toArray();
        return bboxPolygon([cLL, cUR].flat());
    })();

    // Add feature if in viewport
    features.forEach((feature) => {
        if (feature.id !== currentFeature.id && !booleanDisjoint(bboxAsPolygon, feature)) {
            snapList.push(feature);
        }
    });

    return snapList;
};

const calcLayerDistances = (lngLat, layer) => {
    // the point P which we want to snap (probably the marker that is dragged)
    const P = [lngLat.lng, lngLat.lat];

    // is this a marker?
    const isMarker = layer.geometry.type === 'Point';
    // is it a polygon?
    const isPolygon = layer.geometry.type === 'Polygon';

    let lines = undefined;

    // the coords of the layer
    const latlngs = getCoords(layer);

    if (isMarker) {
        const [mLng, mLat] = latlngs;
        return {
            // return the info for the marker, no more calculations needed
            latlng: { mLng, mLat },
            distance: distance(latlngs, P),
        };
    }

    if (isPolygon) lines = polygonToLine(layer);
    else lines = layer;

    const nearestPoint = nearestPointOnLine(lines, P);
    const [lng, lat] = nearestPoint.geometry.coordinates;

    let segmentIndex = nearestPoint.properties.index;
    if (segmentIndex + 1 === lines.geometry.coordinates.length) segmentIndex--;

    return {
        latlng: { lng, lat },
        segment: lines.geometry.coordinates.slice(segmentIndex, segmentIndex + 2),
        distance: nearestPoint.properties.dist,
        isMarker,
    };
};

const calcClosestLayer = (lngLat, layers) => {
    let closestLayer = {};

    // loop through the layers
    layers.forEach((layer) => {
        // find the closest latlng, segment and the distance of this layer to the dragged marker latlng
        const results = calcLayerDistances(lngLat, layer);

        // save the info if it doesn't exist or if the distance is smaller than the previous one
        if (closestLayer.distance === undefined || results.distance < closestLayer.distance) {
            closestLayer = results;
            closestLayer.layer = layer;
        }
    });

    // return the closest layer and it's data
    // if there is no closest layer, return undefined
    return closestLayer;
};

// minimal distance before marker snaps (in pixels)
const metersPerPixel = function (latitude, zoomLevel) {
    const earthCircumference = 40075017;
    const latitudeRadians = latitude * (Math.PI / 180);
    return (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoomLevel + 8);
};

// we got the point we want to snap to (C), but we need to check if a coord of the polygon
function snapToLineOrPolygon(closestLayer, snapOptions, snapVertexPriorityDistance) {
    // the latlng we ultimately want to snap to
    let snapLatlng;

    // A and B are the points of the closest segment to P (the marker position we want to snap)
    const A = closestLayer.segment[0];
    const B = closestLayer.segment[1];

    // C is the point we would snap to on the segment.
    // The closest point on the closest segment of the closest polygon to P. That's right.
    const C = [closestLayer.latlng.lng, closestLayer.latlng.lat];

    // FP-3211
    if (!A || !B || A.length > 2 || B.length > 2) {
        snapLatlng = C;
    } else {
        // distances from A to C and B to C to check which one is closer to C
        const distanceAC = distance(A, C);
        const distanceBC = distance(B, C);

        // closest latlng of A and B to C
        let closestVertexLatLng = distanceAC < distanceBC ? A : B;

        // distance between closestVertexLatLng and C
        let shortestDistance = distanceAC < distanceBC ? distanceAC : distanceBC;

        // snap to middle (M) of segment if option is enabled
        if (snapOptions && snapOptions.snapToMidPoints) {
            const M = midpoint(A, B).geometry.coordinates;
            const distanceMC = distance(M, C);

            if (distanceMC < distanceAC && distanceMC < distanceBC) {
                // M is the nearest vertex
                closestVertexLatLng = M;
                shortestDistance = distanceMC;
            }
        }

        // the distance that needs to be undercut to trigger priority
        const priorityDistance = snapVertexPriorityDistance;

        // if C is closer to the closestVertexLatLng (A, B or M) than the snapDistance,
        // the closestVertexLatLng has priority over C as the snapping point.
        if (shortestDistance < priorityDistance) {
            snapLatlng = closestVertexLatLng;
        } else {
            snapLatlng = C;
        }
    }

    // return the copy of snapping point
    const [lng, lat] = snapLatlng;
    return { lng, lat };
}

function snapToPoint(closestLayer) {
    return closestLayer.latlng;
}

const checkPrioritySnapping = (closestLayer, snapOptions, snapVertexPriorityDistance = 1.25) => {
    let snappingToPoint = !Array.isArray(closestLayer.segment);
    if (snappingToPoint) {
        return snapToPoint(closestLayer);
    } else {
        return snapToLineOrPolygon(closestLayer, snapOptions, snapVertexPriorityDistance);
    }
};

/**
 * Returns snap points if there are any, otherwise the original lng/lat of the event
 * Also, defines if vertices should show on the state object
 *
 * Mutates the state object
 *
 * @param state
 * @param e
 * @returns {{lng: number, lat: number}}
 */
export const snap = (state, e) => {
    let lng = e.lngLat.lng;
    let lat = e.lngLat.lat;

    // Holding alt (or option on Mac) bypasses all snapping
    if (e.originalEvent.altKey) {
        return { lng, lat };
    }

    // Bypass if snap list is empty
    if (state.snapList.length <= 0) {
        return { lng, lat };
    }

    // snapping is on
    let closestLayer, minDistance, snapLatLng;
    if (state.options.snap) {
        closestLayer = calcClosestLayer({ lng, lat }, state.snapList);

        // if no layers found. Can happen when circle is the only visible layer on the map and the hidden snapping-border circle layer is also on the map
        if (Object.keys(closestLayer).length === 0) {
            return false;
        }

        // compute snap position
        const isMarker = closestLayer.isMarker;
        const snapVertexPriorityDistance = state.options.snapOptions
            ? state.options.snapOptions.snapVertexPriorityDistance
            : undefined;
        if (!isMarker) {
            snapLatLng = checkPrioritySnapping(closestLayer, state.options.snapOptions, snapVertexPriorityDistance);
        } else {
            snapLatLng = closestLayer.latlng;
        }
        minDistance =
            ((state.options.snapOptions && state.options.snapOptions.snapPx) || 15) *
            metersPerPixel(snapLatLng.lat, state.map.getZoom());
    }

    // return either the snap position or the mouse position
    if (closestLayer && closestLayer.distance * 1000 < minDistance) {
        return snapLatLng;
    } else {
        return { lng, lat };
    }
};
