import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useSprayingViewStyle = makeStyles()((theme) => ({
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '20px',
        marginBottom: '40px',
        textTransform: 'capitalize',
    },
    title: {
        marginBottom: '20px',
    },
    formContainer: {
        marginTop: '20px',
    },
    inputCard: {
        marginBottom: '15px',
    },
    card: {
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.05)',
        marginBottom: '15px',
        padding: '15px',
    },
    cardContainer: {
        alignItems: 'center',
    },
    productRow: {
        '&:not(:last-child)': { marginBottom: '10px' },
    },
    productInputRow: {
        display: 'flex',
        position: 'relative',
    },
    textSection: {
        // marginLeft: '15px',
        marginBottom: '10px',
        flexGrow: 1,
    },
    autocompleteSection: {
        flexGrow: 1,
        marginRight: '10px',
        '& .MuiTextField-root .MuiInputBase-root input': {
            height: '22px',
        },
    },
    deleteIconWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '43px',
        position: 'absolute',
        right: '-9px',
    },
    deleteIcon: {
        fontSize: '26px',
        padding: '4px',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark,
            borderRadius: '100%',
            background: theme.palette.grey[100],
        },
    },
    deleteIconDisabled: {
        color: alpha(theme.palette.primary.light, 0.5),
        cursor: 'default',
        pointerEvents: 'none',
    },
    addBtnSection: {
        marginTop: '10px',
        flexGrow: 1,
    },
    addBtnView: {
        marginBottom: 0,
    },
    inputContainer: {
        maxWidth: '120px',
        marginRight: '20px',
    },
    checkbox: {},
    modal: {
        zIndex: 99,
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.3)',
    },
    modalPaper: {
        overflow: 'hidden',
        position: 'relative',
        padding: '20px 30px 30px 30px',
        width: '700px',
        minHeight: '500px',
        maxHeight: '80vh',
        display: 'grid',
        justifyItems: 'stretch',
        alignContent: 'space-between',
    },
    modalHeader: {},
    modalIntroText: {
        padding: '10px 0',
    },
    modalContent: {
        minHeight: 'calc(50vh - 110px)',
        maxHeight: 'calc(80vh - 220px)',
        margin: '10px 0 20px',
    },
    tableWrapper: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
        maxHeight: '40vh',
        height: '100%',
    },
    tableCell: {
        padding: '0 10px',
    },
    tableCellWarning: {
        backgroundColor: alpha(theme.palette.warning.light, 0.1),
        width: '100%',
        color: theme.palette.warning.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableCellWarningIcon: {
        fontSize: '18px',
    },
    warningBox: {
        padding: '10px',
    },
    modalFooter: {},
    modalClose: {
        position: 'absolute',
        top: 20,
        right: 25,
        fontSize: '18px',
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
}));

export default useSprayingViewStyle;
