import { makeStyles } from 'tss-react/mui';

const useNavProfileStyle = makeStyles()((theme) => ({
    avatarMenu: {
        '& .MuiPaper-root': {
            overflowY: 'visible',
            overflowX: 'visible',
        },
        '& .MuiPaper-root::before': {
            content: "''",
            height: '14px',
            width: '14px',
            backgroundColor: 'white',
            display: 'block',
            position: 'absolute',
            marginTop: '-7px',
            transform: 'rotate( 45deg)',
            marginLeft: '16px',
        },
        '& ul > li': {
            cursor: 'default',
        },
        '& ul > li:focus-visible': {
            backgroundColor: 'transparent',
        },
        '& ul > li:hover': {
            backgroundColor: 'transparent',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    typography: {
        display: 'flex',
        marginRight: '30px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    avatar: {
        marginRight: '15px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    avatarMenuEmail: {
        color: theme.palette.primary.light,
        opacity: '0.5',
        fontSize: '0.75rem',
        paddingBottom: '10px',
        '&::after': {
            content: "''",
            height: '3px',
            width: '10px',
        },
    },
    emailSeparation: {
        margin: '0 auto',
        width: 'calc(100% - 30px)',
        backgroundColor: theme.palette.primary.light,
        opacity: '0.2',
        border: 'none',
        height: '1px',
    },
    avatarMenuLanguage: {
        justifyContent: 'center',
        paddingTop: '15px',
        paddingBottom: '7px',
        '&.Mui-focusVisible': {
            backgroundColor: 'transparent !important',
        },
    },
    avatarMenuLanguageText: {
        color: theme.palette.primary.light,
        fontSize: '0.75rem',
        marginRight: '5px',
    },
    avatarMenuLogout: {
        paddingTop: '8px',
    },
    avatarMenuLogoutBtn: {
        fontSize: '0.85rem',
        color: theme.palette.error.light,
    },
    tcsLink: {
        margin: '5px 0',
        display: 'block',
    },
    logoutIcon: {
        fontSize: '17px',
        marginRight: '10px',
    },
    loadingLanguage: {
        opacity: '0.2',
        fontSize: '1rem',
        marginRight: '-45px',
        transform: 'scale(1.5)',
    },
    appVersion: {
        justifyContent: 'center',
        paddingTop: '0',
        paddingBottom: '0',
        '& div': {
            fontSize: '0.6rem',
            opacity: '0.5',
            color: theme.palette.primary.light,
        },
    },
}));

export const avatarMenuStyle = {
    paper: {
        marginTop: '11px',
    },
};

export default useNavProfileStyle;
