import { makeStyles } from 'tss-react/mui';

const useSearchPageStepViewStyle = makeStyles()((theme) => ({
    topBar: {
        display: 'grid',
        gridTemplateColumns: '80px calc(100% - calc(80px * 2))',
        '& > *': {
            display: 'flex',
            alignItems: 'center',
        },
        marginBottom: '20px',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
    },
    back: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
}));

export default useSearchPageStepViewStyle;
