import FarmSeasonFieldsDrainageApiService from '@services/farmSeasonFieldsDrainageApiService/farmSeasonFieldsDrainageApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsDrainagePostCallBackT,
    PostFarmSeasonFieldsDrainageDataT,
    PostFarmSeasonFieldsDrainageDispatchTypesT,
    PostFarmSeasonFieldsDrainageResponseDataT,
    PostFarmSeasonFieldsDrainageResponseErrorT,
    POST_FARM_SEASON_FIELDS_DRAINAGE_FAILED,
    POST_FARM_SEASON_FIELDS_DRAINAGE_LOADING,
    POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS,
} from './PostFarmSeasonFieldsDrainageTypes';

export const postFarmSeasonFieldsDrainage =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsDrainageDataT,
    ): FarmSeasonFieldsDrainagePostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsDrainageDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsDrainageResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_DRAINAGE_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsDrainageResponseDataT> =
                await FarmSeasonFieldsDrainageApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_DRAINAGE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_DRAINAGE_FAILED,
                error: error as PostFarmSeasonFieldsDrainageResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
