import { makeStyles } from 'tss-react/mui';

const useDndListItemViewStyle = makeStyles<{ opacity: number; canDrag: boolean }>()((theme, { opacity, canDrag }) => ({
    relative: {
        position: 'relative',
        width: '68%',
        opacity,
        cursor: canDrag ? 'move' : 'not-allowed',
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '12px',
        borderRadius: '15px',
        marginBottom: '7px',
        position: 'relative',
        zIndex: 102,
        overflow: 'hidden',
    },
    icon: {
        fontSize: '1.3em',
        marginRight: '20px',
        color: canDrag ? theme.palette.secondary.main : theme.palette.grey[500],
    },
    link: {
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        bottom: '-30px',
        right: '-40px',
        height: '54px',
        width: '100px',
        borderTopRightRadius: '15px',
        borderBottomRightRadius: '15px',
        borderRight: `2px solid ${theme.palette.secondary.main}`,
        borderTop: `2px solid ${theme.palette.secondary.main}`,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    button: {
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        bottom: '-17px',
        right: '-52px',
        height: '28px',
        width: '28px',
        background: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary.light,
        },
    },
    assocIcon: {
        fontSize: '20px',
        color: '#FFF',
    },
    assocText: {
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 101,
        bottom: '-11px',
        right: '-125px',
    },
    topLine: {
        position: 'absolute',
        top: -6,
        left: 0,
        height: '2px',
        width: '100%',
        background: theme.palette.secondary.main,
    },
    bottomLine: {
        position: 'absolute',
        bottom: -6,
        left: 0,
        height: '2px',
        width: '100%',
        background: theme.palette.secondary.main,
    },
}));

export default useDndListItemViewStyle;
