import { useTranslation } from 'react-i18next';

type KeyParamMapT = Record<string, string | never>;

const useTranslationSlugs = <K extends KeyParamMapT>(prefixSlug: string) => {
    const { t } = useTranslation();
    const getText = <T extends keyof K>(
        finalKey: T,
        textParams: { [v in K[T]]: number | string | undefined | null },
    ) => {
        return t(`${prefixSlug}.${finalKey as string}`, textParams);
    };
    return { getText };
};

export default useTranslationSlugs;
