import { api2 } from './api2.service';
import {
    FarmSeasonFieldPreviousHarvestsRequestDataT,
    FarmSeasonFieldPreviousHarvestsT,
} from './farmSeasonFieldPreviousHarvests.types';

export const farmSeasonFieldPreviousHarvestsApi = api2.injectEndpoints({
    endpoints: (builder) => ({
        getFarmSeasonFieldPreviousHarvests: builder.query<
            FarmSeasonFieldPreviousHarvestsT,
            FarmSeasonFieldPreviousHarvestsRequestDataT
        >({
            query: ({ farmSeasonId, farmSeasonFieldId }) => ({
                url: `/farm-seasons/${farmSeasonId}/fields/${farmSeasonFieldId}/previous-harvests`,
                method: 'GET',
            }),
            keepUnusedDataFor: 3600,
        }),
    }),
});

export const { useLazyGetFarmSeasonFieldPreviousHarvestsQuery } = farmSeasonFieldPreviousHarvestsApi;
