import { makeStyles } from 'tss-react/mui';

const useTopBarStyle = makeStyles()((theme) => ({
    root: {
        background: 'inherit',
        boxShadow: 'none',
        padding: 0,
        '& .MuiToolbar-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    toolbar: {
        minHeight: '80px',
        [theme.breakpoints.up(600)]: {
            minHeight: '80px',
        },
    },
    logoContainer: {
        minWidth: '220px',
        display: 'block',
        justifyContent: 'center',
    },
    farmAndTimeContainer: {
        flexGrow: 1,
        display: 'flex',
    },
    navProfileContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

export default useTopBarStyle;
