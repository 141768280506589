import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formatNumber from '@utils/formatNumber';
import { Box } from '@mui/material';
import { InfoTooltip } from '@ui-kit';
import useFieldAmountLabelStyle from './FieldAmountLabelDeprecated.style';

const FieldAmountLabelDeprecated = ({ fields }: { fields: Partial<FarmSeasonFieldT>[] }) => {
    const { t } = useTranslation();
    const { classes } = useFieldAmountLabelStyle();

    const { totalCultivatedArea, totalPermanentArea } = useMemo(() => {
        const totalCultivatedArea = _getTotalAreaByType(fields, 'cultivated-area');
        const totalPermanentArea = _getTotalAreaByType(fields, 'permanent-crop-area');

        return { totalCultivatedArea, totalPermanentArea };
    }, [fields]);

    return (
        <Box className={classes.fieldAmountLabel} component="span">
            {`${fields.length || 0} ${t('constants.fields', {
                count: fields?.length || 0,
            })} (${formatNumber(totalCultivatedArea, 2)} ${t('constants.hectare-unit')})`}
            {totalPermanentArea > 0 && (
                <InfoTooltip
                    tooltipScProps={{
                        translationSlug: t('components.selected-fields-header.tooltip-area', {
                            permanentCropArea: formatNumber(totalPermanentArea, 2),
                        }),
                    }}
                />
            )}
        </Box>
    );
};

const _getTotalAreaByType = (
    fields: Partial<FarmSeasonFieldT>[],
    areaType: 'permanent-crop-area' | 'cultivated-area',
) => {
    const totalAreaByType = fields?.reduce((totalArea, field) => {
        const hasPermanentCrop = !!field.field_crops?.find((fc) => fc.farm_season_crop.crop.is_permanent);

        let fieldArea = 0;
        if (areaType === 'permanent-crop-area' && hasPermanentCrop) {
            fieldArea = field.area ?? 0;
        } else if (areaType === 'cultivated-area' && !hasPermanentCrop) {
            fieldArea = field.area ?? 0;
        }

        return totalArea + fieldArea;
    }, 0);

    return totalAreaByType;
};

export default FieldAmountLabelDeprecated;
