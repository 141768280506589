import installationTasksRouterConfig from '@routers/FieldsSubtasksRouter/FieldsSubtasksRouterConfig';
import { Route, Navigate } from 'react-router-dom';
import AppRoutes from '@components/AppRoutes/AppRoutes';

const FieldsSubtasksRouter = (): JSX.Element => (
    <AppRoutes>
        {installationTasksRouterConfig.map((item) => (
            <Route key={item.path} element={item.component} path={item.path} />
        ))}
        <Route element={<Navigate to="/tasks/fields/subtasks" replace />} />
    </AppRoutes>
);

export default FieldsSubtasksRouter;
