import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import useAddButtonViewStyle from './AddButtonView.style';
import { AddButtonViewPropsT } from './AddButtonViewTypes';

const AddButtonView = ({ onClick, label, className, disabled, fullWidth = true }: AddButtonViewPropsT): JSX.Element => {
    const { classes } = useAddButtonViewStyle();

    return (
        <Button className={className} color="secondary" disabled={disabled} fullWidth={fullWidth} onClick={onClick}>
            <AddIcon className={classes.addIcon} />
            {label}
        </Button>
    );
};

export default AddButtonView;
