import { MenuItem, Select, SvgIcon, Typography } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { TopBarSelectorPropsT } from './TopBarSelectorTypes';
import useTopBarSelectorStyle from './TopBarSelector.style';

const TopBarSelector = ({
    onChange,
    currentValue,
    listItem,
    icon,
    text = '',
    className,
}: TopBarSelectorPropsT): JSX.Element => {
    const { classes } = useTopBarSelectorStyle();

    return (
        <Select
            className={className}
            renderValue={(valueId) => (
                <div className={classes.boxContainer}>
                    <SvgIcon className={classes.leftIcon} component={icon.icon} viewBox={icon.viewBox} />
                    <Typography className={classes.textContainer} color="primary" variant="h6">
                        {text} {listItem.find((item) => item.id.toString() === valueId)?.name}
                    </Typography>
                </div>
            )}
            value={currentValue}
            onChange={onChange}
        >
            {listItem.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                    {currentValue === item.id ? (
                        <>
                            <DoubleArrowIcon style={{ marginRight: '5px' }} />
                            <strong>{item.name}</strong>
                        </>
                    ) : (
                        item.name
                    )}
                </MenuItem>
            ))}
        </Select>
    );
};

export default TopBarSelector;
