import CoverCropTask from '@pages/HomePage/Installation/InstallationTasks/HistorySubtasks/CoverCropTask/CoverCropTask';
import OrganicFertilizationTask from '@pages/HomePage/Installation/InstallationTasks/HistorySubtasks/OrganicFertilizationTask/OrganicFertilizationTask';
import HistorySubtasks from '@pages/HomePage/Installation/InstallationTasks/HistorySubtasks/HistorySubtasks';
import PastureTask from '@pages/HomePage/Installation/InstallationTasks/HistorySubtasks/PastureTask/PastureTask';
import TillagePracticeTask from '@pages/HomePage/Installation/InstallationTasks/HistorySubtasks/TillagePracticeTask/TillagePracticeTask';

import { HistorySubtasksRouterConfigTypes } from './HistorySubtasksRouterConfigTypes';

const HistorySubtasksRouterConfig: Array<HistorySubtasksRouterConfigTypes> = [
    {
        path: '/',
        component: <HistorySubtasks />,
    },
    {
        path: '/cover-crops',
        component: <CoverCropTask />,
    },
    {
        path: '/organic-fertilization',
        component: <OrganicFertilizationTask />,
    },
    {
        path: '/pasture',
        component: <PastureTask />,
    },
    {
        path: '/tillage-practice',
        component: <TillagePracticeTask />,
    },
];

export default HistorySubtasksRouterConfig;
