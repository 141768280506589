import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import ColorPatternButtonView from '@components/ColorPatternButton/ColorPatternButtonView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Prompt } from '@components/Prompt/Prompt';
import findCrop from '@utils/findCrop';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useSelectStepStyle from './SelectStepView.style';
import { SelectStepViewPropsT } from './SelectStepTypes';
import SelectFieldStep from './SelectFieldStep/SelectFieldStep';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import formatNumber from '@utils/formatNumber';

const SelectStepView = ({
    cropList,
    formFields,
    setFormFields,
    currentStep,
    onCropClick,
    selectedCrop,
    t,
    cropColorPalette,
    popStep,
    isSubmitted,
    submit,
    fieldLoading,
    isDirty,
    ModalForgottenFields,
}: SelectStepViewPropsT): JSX.Element | null => {
    const { classes } = useSelectStepStyle();

    if (currentStep === 2.1) {
        if (!selectedCrop) {
            return null;
        }

        return (
            <SelectFieldStep
                formFields={formFields}
                popStep={popStep}
                selectedCrop={selectedCrop}
                setFormFields={setFormFields}
            />
        );
    }

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!isSubmitted && isDirty}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.add-crop-task.select-step.title">
                    Pour chaque culture, veuillez sélectionner des champs
                    <span className={classes.darkerTitle}> sur la carte</span>
                </Trans>
                <HelpButtonView id="pages.installationtasks.add-crop-task.select-step.title" />
            </Typography>
            <div className={classes.listContainer}>
                {cropList?.map((crop) => (
                    <ColorPatternButtonView
                        key={`${crop.id}-${String(crop.is_accompanied)}`}
                        color={cropColorPalette[crop.crop.id]}
                        name={`${t(crop.crop.translation_slug || '')}${
                            crop.is_accompanied
                                ? ` + ${t('pages.installationtasks.add-crop-task.select-step.plus-relation')}`
                                : ''
                        }`}
                        onClick={() => onCropClick(crop)}
                    >
                        <>
                            <Typography color="primary" variant="body1">
                                {t('constants.fields-subtitle', {
                                    count: formFields.filter((field) => findCrop(field, crop)).length,
                                })}
                            </Typography>
                            <Typography color="primary" variant="body1">
                                {`${
                                    formFields.filter((field) => findCrop(field, crop)).length > 0
                                        ? formatNumber(
                                              formFields
                                                  .filter((field) => findCrop(field, crop))
                                                  .map((field) => field.area)
                                                  .reduce((tot, curr) => tot + curr),
                                              3,
                                          )
                                        : '0'
                                } ${t('constants.hectare-unit')}`}
                            </Typography>
                        </>
                    </ColorPatternButtonView>
                ))}
            </div>
            <TwoButtonFooterView
                rightButtonOverride={
                    <RestrictSaveFarmSeason>
                        <LoadingButton loading={fieldLoading} text={t('constants.save')} onClick={submit} />
                    </RestrictSaveFarmSeason>
                }
            />
            {ModalForgottenFields}
        </div>
    );
};

export default SelectStepView;
