import * as packageInfo from '../../package.json';

const useLocalStorageReset = () => {
    const cacheV = localStorage.getItem('cache_version');

    if (cacheV !== packageInfo.version) {
        localStorage.clear();
        localStorage.setItem('cache_version', packageInfo.version);
    }
};

export default useLocalStorageReset;
