import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';
import appActions from '@actions/AppActions/AppActions';

import SeasonSelectorView from './SeasonSelectorView';
import { farmSeasonSetCurrent } from '@store/actions/FarmSeasonActions/FarmSeasonSetCurrent';
import useModalWarningApp from '@components/ModalWarningApp/useModalWarningApp';
import { useEffect, useState } from 'react';
import { seasonGet } from '@/store/actions/StaticActions/seasonGet';

const SeasonSelector = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { openModalWarningApp } = useModalWarningApp();
    const [stringifiedFarmSeasonList, setStringifiedFarmSeasonList] = useState<
        {
            name: string;
            id: string;
        }[]
    >([]);
    const navigationPromptMessage = useSelector((state: HomePageStateT) => state.app.navigatePromptMessage);
    const seasonsList = useSelector((state: HomePageStateT) => state.static.seasons);
    const currentValue = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const farmSeasonList = useSelector((state: HomePageStateT) => state.farmSeason.farmSeasonList);

    useEffect(() => {
        if (seasonsList?.length === 0) {
            dispatch(seasonGet());
        }
    }, [seasonsList]);

    useEffect(() => {
        if (seasonsList?.length > 0) {
            const stringifiedFarmSeasonList = farmSeasonList.map((farmSeason) => {
                const season = seasonsList?.find((s) => s.id === farmSeason.season_id);
                return {
                    name: season ? season.harvest_year.toString() : '-',
                    id: farmSeason?.id?.toString(),
                };
            });
            setStringifiedFarmSeasonList(stringifiedFarmSeasonList);
        }
    }, [seasonsList, farmSeasonList]);

    const onChange = async (event: SelectChangeEvent<string>) => {
        let canNavigate = true;

        if (navigationPromptMessage) {
            const { rightButtonClicked } = await openModalWarningApp({
                title: t(navigationPromptMessage.title),
                confirmMessage: t(navigationPromptMessage.text),
                rightButtonText: t('constants.yes'),
                leftButtonText: t('constants.cancel'),
            });
            canNavigate = rightButtonClicked;
        }

        if (canNavigate) {
            dispatch(appActions.setNavigatePromptMessage(null));
            dispatch(farmSeasonSetCurrent({ farmSeasonId: +event.target.value }));
        }
    };

    const { t } = useTranslation();
    const text = t('constants.harvest');

    return (
        <SeasonSelectorView
            currentValue={currentValue?.id?.toString()}
            seasonList={stringifiedFarmSeasonList}
            text={text}
            onChange={onChange}
        />
    );
};

export default SeasonSelector;
