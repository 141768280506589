import LabelTextField from '@components/LabelTextField/LabelTextField';
import { Grid } from '@mui/material';
import MenuBar from '@components/MenuBar/MenuBar';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';

import useFarmEditStyle from './FarmEdit.style';
import { FarmEditViewPropsT } from './FarmEditTypes';
import { CountryT } from '@store/reducers/StaticReducer/StaticReducerType';

const FarmView = ({ formik, loading, countries, t }: FarmEditViewPropsT): JSX.Element => {
    const { classes } = useFarmEditStyle();

    return (
        <div className={classes.root}>
            <MenuBar
                className={classes.menuBar}
                menuTitle={t('pages.farm.title-edit')}
                onClose="/farm"
                onArrowBack={'/farm'}
            />
            <form id="farm-information-form" noValidate onSubmit={formik.handleSubmit}>
                <Grid className={classes.formGrid} spacing={3} container>
                    <Grid xs={12} item>
                        <LabelTextField
                            autoComplete="farm_name"
                            disabled={loading}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            id="name"
                            label={t('pages.farm.name-of-your-farm')}
                            name="name"
                            type="text"
                            value={formik.values.name}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <LabelTextField
                            autoComplete="address"
                            disabled={loading}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            id="address"
                            label={t('pages.farm.address-of-your-farm')}
                            name="address"
                            type="text"
                            value={formik.values.address}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelTextField
                            autoComplete="postal_code"
                            disabled={loading}
                            error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
                            helperText={formik.touched.postal_code && formik.errors.postal_code}
                            id="postal_code"
                            label={t('pages.farm.postal-code')}
                            name="postal_code"
                            type="text"
                            value={formik.values.postal_code}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelTextField
                            autoComplete="city"
                            disabled={loading}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            id="city"
                            label={t('pages.farm.city')}
                            name="city"
                            type="text"
                            value={formik.values.city}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid xs={8} item>
                        <LabelAutocomplete
                            error={formik.touched.country_id && Boolean(formik.errors.country_id)}
                            helperText={formik.touched.country_id ? formik.errors.country_id : undefined}
                            id="country_id"
                            label={t('pages.farm.country')}
                            options={countries}
                            value={
                                countries?.filter(
                                    (country: CountryT) => country.id === parseInt(formik.values.country_id, 10),
                                ).length > 0
                                    ? Number(formik.values.country_id)
                                    : ''
                            }
                            required
                            onChange={(countryId) => formik.setFieldValue('country_id', countryId)}
                        />
                    </Grid>
                </Grid>
                <TwoButtonFooterView
                    rightLoading={loading}
                    rightOnCLick={() => formik.submitForm()}
                    rightText={t('constants.save')}
                />
            </form>
        </div>
    );
};

export default FarmView;
