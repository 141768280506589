import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';

import useWarningMessageStyle from './WarningMessageView.style';
import { WarningMessageViewPropsT } from './WarningMessageViewTypes';

const WarningMessageView = ({
    message,
    checkBoxMessage,
    isChecked,
    onCheck,
    containerClass,
}: WarningMessageViewPropsT): JSX.Element => {
    const { classes } = useWarningMessageStyle();

    return (
        <div className={`${classes.container} ${containerClass}`}>
            <Typography className={classes.typography}>
                <ErrorOutlineIcon className={classes.icon} />
                {message}
            </Typography>
            {checkBoxMessage && (
                <LabelCheckboxView
                    checked={isChecked}
                    containerClass={classes.checkbox}
                    label={checkBoxMessage}
                    onChange={(e, value) => onCheck?.(value)}
                />
            )}
        </div>
    );
};

export default WarningMessageView;
