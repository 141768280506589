import IrrigationTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/IrrigationTask/IrrigationTask';
import DrainageTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/DrainageTask/DrainageTask';
import OrganicTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/OrganicTask/OrganicTask';
import SoilAcidityTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/SoilAcidityTask/SoilAcidityTask';
import SoilOrganicMatterTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/SoilOrganicMatterTask/SoilOrganicMatterTask';
import SoilOrganicCarbonTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/SoilOrganicCarbonTask/SoilOrganicCarbonTask';
import TillageTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/TillageTask/TillageTask';
import AgroforestryTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/AgroforestryTask/AgroforestryTask';
import CoverCropTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/CroverCropTask/CoverCropTask';
import ResidueTask from '@pages/HomePage/Installation/InstallationTasks/FieldsSubtasks/ResidueTask/ResidueTask';

import { FieldsSubtasksRouterConfigTypes } from './FieldsSubtasksRouterConfigTypes';

const FieldsSubtasksRouterConfig: Array<FieldsSubtasksRouterConfigTypes> = [
    {
        path: '/tillage',
        component: <TillageTask />,
    },
    {
        path: '/drainage',
        component: <DrainageTask />,
    },
    {
        path: '/coverCrop',
        component: <CoverCropTask />,
    },
    {
        path: '/organic',
        component: <OrganicTask />,
    },
    {
        path: '/organicMatter',
        component: <SoilOrganicMatterTask />,
    },
    {
        path: '/organicCarbon',
        component: <SoilOrganicCarbonTask />,
    },
    {
        path: '/acidity',
        component: <SoilAcidityTask />,
    },
    {
        path: '/irrigation',
        component: <IrrigationTask />,
    },
    {
        path: '/agroforestry',
        component: <AgroforestryTask />,
    },
    {
        path: '/residue',
        component: <ResidueTask />,
    },
];

export default FieldsSubtasksRouterConfig;
