import { makeStyles } from 'tss-react/mui';
import { StickyFooterLayoutStylePropsT } from './StickyFooterLayoutTypes';

const useStickyFooterLayoutStyle = makeStyles<StickyFooterLayoutStylePropsT>()(
    (_, { bgColor, paddingBottom, fillParentPadding }) => ({
        StickyFooterLayout: {
            background: `linear-gradient(180deg, rgba(0,0,0,0) 0, ${bgColor} 10px)`,
            position: 'sticky',
            bottom: '0',
            padding: `21px 10px ${paddingBottom ?? 0} 10px`,
            margin: '0 -10px',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            ...(fillParentPadding && { marginBottom: `-${fillParentPadding}`, bottom: `-${fillParentPadding}` }),
        },
    }),
);

export default useStickyFooterLayoutStyle;
