import {
    GetFarmSeasonCropsDispatchTypesT,
    GET_FARM_SEASON_CROPS_SUCCESS,
} from '@actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { FarmSeasonCropDataT } from '@pages/HomePage/Installation/InstallationTasks/CropsTask/CropListStep/CropListStepTypes';
import FarmSeasonFieldsApiService from '@services/farmSeasonFieldsApiService/farmSeasonFieldsApiService';
import store from '@store/store';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsPostCallBackT,
    PostFarmSeasonFieldsDataT,
    PostFarmSeasonFieldsDispatchTypesT,
    PostFarmSeasonFieldsResponseDataT,
    PostFarmSeasonFieldsResponseErrorT,
    POST_FARM_SEASON_FIELDS_FAILED,
    POST_FARM_SEASON_FIELDS_LOADING,
    POST_FARM_SEASON_FIELDS_SUCCESS,
} from './PostFarmSeasonFieldsTypes';

/** @deprecated */
export const postFarmSeasonFields =
    (farmId: number, farmSeasonId: number, body: PostFarmSeasonFieldsDataT): FarmSeasonFieldsPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsDispatchTypesT | GetFarmSeasonCropsDispatchTypesT>,
    ): Promise<boolean> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsResponseDataT> = await FarmSeasonFieldsApiService.post(
                farmId,
                farmSeasonId,
                body,
            );

            dispatch({
                type: POST_FARM_SEASON_FIELDS_SUCCESS,
                payload: res.data,
            });

            const farmSeasonCrops: FarmSeasonCropDataT[] = store.getState().farmSeasonCrop.cropsList;

            if (res.data.fields) {
                res.data.fields.forEach((field) => {
                    field.field_crops?.forEach((fieldCrop) => {
                        if (
                            !farmSeasonCrops.find(
                                (farmSeasonCrop) => farmSeasonCrop.id === fieldCrop.farm_season_crop.id,
                            )
                        ) {
                            farmSeasonCrops.push(fieldCrop.farm_season_crop);
                        }
                    });
                });

                dispatch({
                    type: GET_FARM_SEASON_CROPS_SUCCESS,
                    payload: {
                        crops: farmSeasonCrops,
                    },
                });
            }
            return true;
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_FAILED,
                error: error as PostFarmSeasonFieldsResponseErrorT,
            });

            return false;
        }
    };

export const placeholder = '';
