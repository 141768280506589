import { FieldSuggestionTypeT, SuggestionResponseT } from '@services/onboardingApiService/onboardingApiServiceTypes';
import { AppStateT } from '../store';

type SelectFieldSuggestionParamsT<T> = {
    farm_season_field_id: number;
    suggestionType: T;
};

const selectFieldSuggestion =
    <T extends FieldSuggestionTypeT>({ farm_season_field_id, suggestionType }: SelectFieldSuggestionParamsT<T>) =>
    (state: AppStateT): SuggestionResponseT<T>[number] | null => {
        const fieldSuggestion = state.onboarding.suggestions[suggestionType]?.find(
            (suggestion) => suggestion.id === farm_season_field_id,
        );

        return fieldSuggestion ?? null;
    };

export default selectFieldSuggestion;
