import { Container, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useSelector } from 'react-redux';
import PagePrintable from '@/layouts/PagePrintable/PagePrintable';

import CertificateTable from '../CertificateTable/CertificateTable';
import { ColumnNameT } from '../CertificateTable/CertificateTableTypes';

import { PrintableTablePropsT, TooltipDescriptionsT } from './PrintableTableTypes';
import usePrintableTableStyle from './PrintableTable.style';

const tooltipDescriptions: TooltipDescriptionsT = [
    {
        labelSlug: 'pages.carbon-bank.table.certificates',
        descriptionSlug: 'tooltip.carbon-bank.table.certificates',
    },
    {
        labelSlug: 'pages.carbon-bank.table.held-in-buffer',
        descriptionSlug: 'tooltip.carbon-bank.table.held-in-buffer',
    },
    {
        labelSlug: 'pages.carbon-bank.table.on-market',
        descriptionSlug: 'tooltip.carbon-bank.table.on-market',
    },
    {
        labelSlug: 'pages.carbon-bank.table.avg-price',
        descriptionSlug: 'tooltip.carbon-bank.table.avg-price',
    },
    {
        labelSlug: 'pages.carbon-bank.table.revenue',
        descriptionSlug: 'tooltip.carbon-bank.table.revenue',
    },
    {
        labelSlug: 'pages.carbon-bank.table.vc-vcn',
        descriptionSlug: 'tooltip.carbon-bank.table.vc-vcn',
    },
];

const PrintableTable = ({
    CertificateTableProps: { farmSeasonCertificates, firstHarvestYear, previousProductPlanId },
    setPrint,
}: PrintableTablePropsT) => {
    const { classes } = usePrintableTableStyle();
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const user = useSelector((state: HomePageStateT) => state.auth.user);
    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const harvestYear = useSelector(
        (state: HomePageStateT) =>
            state.static.seasons?.find((season) => season.id === state.farmSeason.currentFarmSeason?.season_id)
                ?.harvest_year,
    );

    const hideColumns: ColumnNameT[] = ['purchaseOrder'];

    if (
        farmSeasonCertificates?.[0]?.certificates &&
        !farmSeasonCertificates?.[0]?.value_chain_percentage &&
        !farmSeasonCertificates?.[0]?.no_value_chain_percentage
    ) {
        hideColumns.push('vcVcn');
        const tooltipDescriptionIndexToRemove = tooltipDescriptions.findIndex(
            (tooltipDescription) => tooltipDescription.labelSlug === 'pages.carbon-bank.table.vc-vcn',
        );
        tooltipDescriptionIndexToRemove > -1 && tooltipDescriptions.splice(tooltipDescriptionIndexToRemove, 1);
    }

    return (
        <PagePrintable
            filename={t('pages.carbon-bank.certificates.pdf.filename', {
                firstName: user?.first_name,
                lastName: user?.last_name,
                farmName: farm?.name,
                seasonYears: `${t('constants.harvest')} ${harvestYear ?? '-'}`,
            })}
            setPrint={setPrint}
        >
            <div className={classes.PrintableTable}>
                <div className={classes.tableContainer}>
                    <CertificateTable
                        farmSeasonCertificates={farmSeasonCertificates}
                        firstHarvestYear={firstHarvestYear}
                        previousProductPlanId={previousProductPlanId}
                        hideColumns={hideColumns}
                        size="small"
                    />
                </div>
                <Container maxWidth="sm">
                    <Typography className={classes.tableCaption} variant="body1">
                        {t('pages.carbon-bank.table.performance-verified-by-auditor')}
                    </Typography>

                    {tooltipDescriptions.map(({ descriptionSlug, labelSlug }) => (
                        <Typography key={labelSlug} className={classes.tooltipDescriptionLabel} variant="body1">
                            {t(labelSlug)}
                            {language === 'fr' && ' '}:<br />
                            <div className={classes.tooltipDescription}>
                                <Trans
                                    components={{ b: <b />, i: <i style={{ fontStyle: 'italic' }} /> }}
                                    i18nKey={descriptionSlug}
                                />
                            </div>
                        </Typography>
                    ))}
                </Container>
            </div>
        </PagePrintable>
    );
};

export default PrintableTable;
