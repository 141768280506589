import { makeStyles } from 'tss-react/mui';

const useCheckedButtonViewStyle = makeStyles()((theme) => ({
    card: {
        boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.05)',
        marginBottom: '10px',
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '34px calc(100% - 34px - 34px) 34px',
        gridTemplateRows: '34px',
        padding: '15px',
        '& svg': {
            color: theme.palette.shamrock.light,
        },
    },
    textSection: {
        marginLeft: '15px',
    },
    textSectionTitle: {
        lineHeight: 1.3,
    },
    textSectionDescription: {
        // display: 'grid',
        // gridTemplateColumns: '80px calc(100% - 80px)',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '10px',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        fontSize: '2rem',
        '&.MuiSvgIcon-colorSecondary': {
            color: theme.palette.driftwood.light,
        },
    },
}));

export default useCheckedButtonViewStyle;
