import { useGetActiveFarmSeasonProductPlanQuery } from '@api/farmSeasonProductPlan.api';
import { useLazyGetPriceQuery } from '@api/static.api';
import useHomePageLoading from '@hooks/useHomePageLoading';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import formatCurrencyNumber from '@services/formatCurrencyNumber/formatCurrencyNumber';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useSubscriptionInfoCardLogic = () => {
    const { addPendingState } = useHomePageLoading();

    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const currencyId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.country.currency_id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);

    const { isLoading: isAFSPPLoading, data: AFSPPData } = useGetActiveFarmSeasonProductPlanQuery({
        farmId,
        farmSeasonId,
    });

    useEffect(() => {
        addPendingState({ pending: isAFSPPLoading, slug: 'AFSPPLoading' });
    }, [isAFSPPLoading]);

    const [getPrice, { currentData: price, isLoading: getPriceLoading }] = useLazyGetPriceQuery();

    useEffect(() => {
        if (AFSPPData?.price_id) {
            getPrice({ id: AFSPPData.price_id });
        }
    }, [AFSPPData?.price_id]);

    useEffect(() => {
        addPendingState({ pending: getPriceLoading, slug: 'getPriceLoading' });
    }, [getPriceLoading]);

    return {
        productPlanId: AFSPPData?.product_plan_id,
        formatedCurrentPrice: price?.amount ? formatCurrencyNumber(price.amount, currencyId == 2 ? 'GBP' : 'EUR') : '-',
    };
};

export default useSubscriptionInfoCardLogic;
