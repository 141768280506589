import { Share, Print } from '@mui/icons-material';
import IconPdf from '@icons/icon-pdf-file.svg';
import { Button } from '@mui/material';
import TooltipSc from '@components/TooltipSc/TooltipSc';

import useButtonIconActionStyle from './ButtonIconAction.style';
import { ButtonIconActionPropsT } from './ButtonIconActionTypes';

const ButtonIconAction = ({
    iconType,
    onClick,
    className,
    color = 'primary',
    intercomClass,
    size = 'medium',
    tooltipSlug,
    withoutBorder,
}: ButtonIconActionPropsT): JSX.Element => {
    const { classes } = useButtonIconActionStyle({ color, size, withoutBorder: withoutBorder ?? true });

    const infoSize = {
        small: '10px',
        medium: '20px',
        large: '35px',
    };

    const icons: Record<ButtonIconActionPropsT['iconType'], JSX.Element> = {
        info: <span style={{ fontSize: infoSize[size], marginTop: '4px' }}>i</span>,
        print: <Print />,
        share: <Share />,
        pdf: <IconPdf />,
    };

    return (
        <TooltipSc translationSlug={tooltipSlug || ''}>
            <Button
                className={`${classes.ButtonIconAction} ${intercomClass?.split('.').join('_')} ${className}`}
                variant="text"
                onClick={onClick}
            >
                {icons[iconType]}
            </Button>
        </TooltipSc>
    );
};

export default ButtonIconAction;
