import { Dispatch } from '@reduxjs/toolkit';

export const CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING =
    'CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING';
export const CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED =
    'CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED';
export const CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS =
    'CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS';

export type ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseDataT = {
    installation: {
        onboarding: {
            finished: string[];
            livestock: {
                done: boolean;
                option: 'yes' | 'no';
            };
        };
    };
};

export type ConfirmNotEligibleFarmSeasonLivestocksInstallationDataT = {
    option: string;
};

export type FarmSeasonLivestocksPostCallBackT = (
    dispatch: Dispatch<ConfirmNotEligibleFarmSeasonLivestocksInstallationDispatchTypesT>,
) => Promise<
    | ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseErrorT
    | ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseDataT
>;

export type ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseErrorT = {
    message?: string;
    list?: string[];
    error?: any; // eslint-disable-line
};

export type ConfirmNotEligibleFarmSeasonLivestocksInstallationLoadingT = {
    type: typeof CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_LOADING;
};

export type ConfirmNotEligibleFarmSeasonLivestocksInstallationFailedT = {
    type: typeof CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_FAILED;
    error: ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseErrorT;
};

export type ConfirmNotEligibleFarmSeasonLivestocksInstallationSuccessT = {
    type: typeof CONFIRM_NOT_ELIGIBLE_FARM_SEASON_LIVESTOCKS_INSTALLATION_SUCCESS;
    payload: ConfirmNotEligibleFarmSeasonLivestocksInstallationResponseDataT;
};

export type ConfirmNotEligibleFarmSeasonLivestocksInstallationDispatchTypesT =
    | ConfirmNotEligibleFarmSeasonLivestocksInstallationLoadingT
    | ConfirmNotEligibleFarmSeasonLivestocksInstallationFailedT
    | ConfirmNotEligibleFarmSeasonLivestocksInstallationSuccessT;
