import { RadioButtonPropsT } from './RadioButtonTypes';
import RadioButtonView from './RadioButtonView';

const RadioButton = ({
    label,
    checked,
    value,
    onClick,
    loading,
    error,
    className,
    disabled,
}: RadioButtonPropsT): JSX.Element => (
    <RadioButtonView
        checked={checked}
        className={className}
        error={error}
        label={label}
        loading={loading}
        value={value}
        onClick={onClick}
        disabled={disabled}
    />
);

export default RadioButton;
