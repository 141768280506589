import {
    PostFarmSeasonFieldsDrainageDataT,
    PostFarmSeasonFieldsDrainageResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsDrainageTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsDrainageApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsDrainageDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsDrainageResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/drainage`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsDrainageApiService;
