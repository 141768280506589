import { useCanUpdateSelfUpsell, useCanUpdateUpsellForFarmer } from '@/hooks/usePermissions';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import ModalContentPaymentDetail from '@components/ModalContentPaymentDetail/ModalContentPaymentDetail';
import PaymentStatusIcon from '@components/PaymentStatusIcon/PaymentStatusIcon';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import { Box, Typography } from '@mui/material';
import { ModalBase } from '@soil-capital/ui-kit';
import { InfoTooltip } from '@ui-kit';
import { Trans, useTranslation } from 'react-i18next';
import useRenewalActionLogic from './RenewalActions.logic';
import useRenewalActionStyle from './RenewalActions.style';

const RenewalActions = () => {
    const { classes } = useRenewalActionStyle();
    const { t } = useTranslation();
    const canRenew = useCanUpdateSelfUpsell() || useCanUpdateUpsellForFarmer();

    const {
        modalState,
        renewalModalController,
        confirmRenewal,
        renewalDate,
        formatedPrice,
        hasDiscount,
        discountPercentage,
        discountedPrice,
        payInAdvanceLoading,
        discounts,
        isRenewalPaid,
        renewalPaymentFlowStatus,
        openRenewalModal,
        isLatestPaidCarbonSeason,
        isStandardProductPlan,
        hasSubscription,
        redirectToFarmData,
        renewalDeadlineDate,
        isRenewalDeadlinePassed,
        hasPrice,
        isPartnerPrepaid,
        changePaymentMethod,
        isGetBillingPortalUrlLoading,
        canOpenNewSeason,
        redirectToOpenNextFarmSeasonModal,
        nextHarvestYear,
        isPaymentToConfirm,
        confirmStripePayment,
    } = useRenewalActionLogic();

    return (
        <>
            {isRenewalPaid && (
                <Typography variant="body2">
                    <Trans
                        i18nKey={
                            canOpenNewSeason
                                ? 'pages.settings.billing.subscription.renewal.standard.encoding-ready.description'
                                : 'pages.settings.billing.subscription.renewal.standard.encoding-not-ready.description'
                        }
                        values={{ NextHarvest: nextHarvestYear }}
                    />
                </Typography>
            )}
            {isStandardProductPlan && (
                <Box>
                    {isLatestPaidCarbonSeason && !isRenewalDeadlinePassed && !isPartnerPrepaid && (
                        <>
                            <Typography id="renewal-description-typography" variant="body2" whiteSpace="pre-line">
                                {!isRenewalPaid &&
                                    hasDiscount &&
                                    (hasSubscription ? (
                                        <Trans
                                            i18nKey="pages.settings.billing.subscription.renewal.standard.explanation.discount-available"
                                            values={{
                                                NextRenewalDebitDate: renewalDate,
                                                StandardPlanPrice: formatedPrice,
                                                RenewalDiscountPercentage: discountPercentage,
                                            }}
                                        />
                                    ) : (
                                        <Trans
                                            i18nKey="pages.settings.billing.subscription.renewal.standard.explanation.no-subscription.discount-available"
                                            values={{
                                                standardPlanPrice: formatedPrice,
                                                renewalDiscountPercentage: discountPercentage,
                                                renewalDeadline: renewalDeadlineDate,
                                            }}
                                        />
                                    ))}
                                {!isRenewalPaid &&
                                    !hasDiscount &&
                                    (hasSubscription ? (
                                        <Trans
                                            i18nKey="pages.settings.billing.subscription.renewal.standard.explanation.no-discount"
                                            values={{ StandardPlanPrice: formatedPrice }}
                                        />
                                    ) : (
                                        <Trans
                                            i18nKey="pages.settings.billing.subscription.renewal.standard.explanation.no-subscription.no-discount"
                                            values={{
                                                standardPlanPrice: formatedPrice,
                                                renewalDeadline: renewalDeadlineDate,
                                            }}
                                        />
                                    ))}
                                {!isRenewalPaid && hasPrice && discounts && discounts.length >= 1 && (
                                    <InfoTooltip
                                        tooltipScProps={{
                                            translationSlug: t(
                                                'pages.settings.billing.subscription.renewal.standard.pay-in-advance.tooltip',
                                                {
                                                    Discount1Percentage: discounts?.[0].percent_off,
                                                    RealPriceWithDiscount1: discounts?.[0].price,
                                                    Discount2Percentage: discounts?.[1].percent_off,
                                                    RealPriceWithDiscount2: discounts?.[1].price,
                                                    Discount3Percentage: discounts?.[2].percent_off,
                                                    RealPriceWithDiscount3: discounts?.[2].price,
                                                    // Discount4Percentage: discounts?.[3].percent_off,
                                                    // RealPriceWithDiscount4: discounts?.[3].price,
                                                },
                                            ),
                                            placement: 'bottom',
                                            className: classes.infoTooltip,
                                            PopperProps: {
                                                className: classes.preline,
                                            },
                                        }}
                                    />
                                )}
                            </Typography>
                            {canRenew && (
                                <>
                                    {!isRenewalPaid && hasSubscription && (
                                        <LoadingButton
                                            className={classes.actionButton}
                                            id="renewal"
                                            disabled={!hasPrice}
                                            text={t(
                                                'pages.settings.billing.subscription.renewal.standard.pay-in-advance.button',
                                            )}
                                            onClick={openRenewalModal}
                                        />
                                    )}
                                    <LoadingButton
                                        className={classes.actionButton}
                                        id="update-payment-method"
                                        color="secondary"
                                        loading={isGetBillingPortalUrlLoading}
                                        text={t(
                                            'pages.settings.billing.subscription.renewal.standard.button.payment-mean-change',
                                        )}
                                        onClick={() => changePaymentMethod('billing_page')}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Box>
            )}
            {/* Renewal status modal */}
            <ModalBase
                height={modalState === 'feedback' ? '400px' : undefined}
                width="650px"
                showCloseButton
                controller={renewalModalController}
            >
                {modalState === 'confirmation' ? (
                    <ModalBaseLayout
                        title={{
                            text: t('pages.settings.billing.subscription.renewal.payment.title'),
                        }}
                        cancelButton={{
                            text: t('constants.cancel'),
                            onClick: () => {
                                renewalModalController.onModalCancel();
                            },
                        }}
                        confirmButton={{
                            text: t('pages.settings.billing.subscription.renewal.payment.button'),
                            onClick: () => confirmRenewal(hasSubscription),
                            loading: payInAdvanceLoading,
                        }}
                    >
                        <Typography
                            id="renewal-modal-confirmation-typography"
                            className={classes.preline}
                            mb="16px"
                            variant="body2"
                        >
                            <Trans
                                i18nKey={
                                    hasDiscount
                                        ? 'pages.settings.billing.subscription.renewal.payment.description.discount-available'
                                        : 'pages.settings.billing.subscription.renewal.payment.description.no-discount'
                                }
                                values={{
                                    RealPriceToPay: hasDiscount ? discountedPrice : formatedPrice,
                                    DiscountPercentage: discountPercentage,
                                }}
                            />
                        </Typography>
                    </ModalBaseLayout>
                ) : modalState === 'subscription-payment' ? (
                    // Subscription payment for renewal
                    <ModalContentPaymentDetail />
                ) : modalState === 'feedback' ? (
                    <ModalBaseLayout
                        title={{
                            text: '',
                        }}
                        confirmButton={
                            isRenewalPaid
                                ? {
                                      text: t(
                                          canOpenNewSeason
                                              ? 'pages.settings.billing.subscription.renewal.payment-confirmation.button.open-new-season'
                                              : 'pages.settings.billing.subscription.renewal.payment-confirmation.button.encoding-not-started',
                                      ),
                                      onClick: canOpenNewSeason
                                          ? redirectToOpenNextFarmSeasonModal
                                          : redirectToFarmData,
                                  }
                                : isPaymentToConfirm
                                ? {
                                      text: t(
                                          'pages.settings.billing.subscription.renewal.payment-requires-action.button.confirm-payment',
                                      ),
                                      onClick: confirmStripePayment,
                                      loading: renewalPaymentFlowStatus === 'payment_in_progress',
                                  }
                                : undefined
                        }
                    >
                        <Box
                            id="renewal-modal-feedback-box"
                            height="252px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box width="100%">
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <PaymentStatusIcon paymentStatus={renewalPaymentFlowStatus} />
                                </Box>
                                <Typography className={classes.preline} mb="16px" variant="h4" align="center">
                                    {isRenewalPaid &&
                                        t('pages.settings.billing.subscription.renewal.payment-confirmation.title')}
                                    {(renewalPaymentFlowStatus === 'payment_in_progress' ||
                                        renewalPaymentFlowStatus === 'requires_action') &&
                                        t('pages.settings.billing.subscription.renewal.loading.title')}
                                    {renewalPaymentFlowStatus === 'payment_failed' &&
                                        t('pages.settings.billing.subscription.renewal.payment-failed.title')}
                                </Typography>
                                <Typography className={classes.preline} variant="body2" align="center">
                                    {isRenewalPaid &&
                                        t(
                                            canOpenNewSeason
                                                ? 'pages.settings.billing.subscription.renewal.payment-confirmation.description.open-new-season'
                                                : 'pages.settings.billing.subscription.renewal.payment-confirmation.description.encoding-not-started',
                                        )}
                                    {(renewalPaymentFlowStatus === 'payment_in_progress' ||
                                        renewalPaymentFlowStatus === 'requires_action') &&
                                        t('pages.settings.billing.subscription.renewal.loading.description')}
                                    {renewalPaymentFlowStatus === 'payment_failed' &&
                                        t('pages.settings.billing.subscription.renewal.payment-failed.description')}
                                </Typography>
                            </Box>
                        </Box>
                    </ModalBaseLayout>
                ) : null}
            </ModalBase>
        </>
    );
};
export default RenewalActions;
