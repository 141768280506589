import { makeStyles } from 'tss-react/mui';

const useButtonResendVerifyEmailStyle = makeStyles<{
    disabled: boolean;
    resendLoading: boolean;
}>()((theme, { disabled, resendLoading }) => ({
    link: disabled
        ? {
              cursor: 'not-allowed',
              pointerEvents: 'none',
              opacity: 0.5,
          }
        : {
              cursor: resendLoading ? 'wait' : 'pointer',
          },
}));

export default useButtonResendVerifyEmailStyle;
