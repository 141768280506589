import { Typography } from '@mui/material';
import { Prompt } from '@components/Prompt/Prompt';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import MenuBar from '@components/MenuBar/MenuBar';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Trans } from 'react-i18next';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import { DrainageTaskViewPropsT } from './DrainageTaskTypes';
import useDrainageTaskStyle from './DrainageTaskView.style';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import { ModalWarning } from '@soil-capital/ui-kit';
import { useSelector } from 'react-redux';
import CallToActionSuggestion from '../../../components/CallToActionSuggestion/CallToActionSuggestion';
import selectIsYoySeason from '@store/selectors/selectIsYoySeason';
import useFieldSuggestion from '../../../hooks/useFieldSuggestion';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';

const DrainageTaskView = ({
    t,
    isSubmitted,
    isDirty,
    onSelectedFieldDelete,
    onSelectAll,
    onConfirm,
    formik,
    fieldLoading,
    modalDeleteSelection,
    deleteAll,
}: DrainageTaskViewPropsT): JSX.Element => {
    const { classes } = useDrainageTaskStyle();
    const isYoySeason = useSelector(selectIsYoySeason());
    const { handleBringBackSuggestion, needAttentionFields, suggestedFields, suggestionDone } = useFieldSuggestion({
        suggestionType: 'drainage',
    });
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={isDirty}
            />
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={1} totalSteps={1} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.fields-subtasks.drainage-task.menu-title')}
                    onArrowBack=".."
                    onClose=".."
                />
                <Prompt
                    messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                    messageText={t('modal.navigation-prompt-if-unsaved.text')}
                    when={!isSubmitted && isDirty}
                />
                <Typography className={classes.title} color="secondary" variant="h4">
                    <Trans i18nKey="pages.installationtasks.fields-subtasks.drainage-task.title">
                        Veullez selectioner <span className={classes.titleDarker}>sur la carte</span> les champs qui
                        sont draines
                    </Trans>
                    <HelpButtonView id="pages.installationtasks.fields-subtasks.drainage-task.title" />
                </Typography>

                {canEditFarmSeason.access && isYoySeason && (
                    <CallToActionSuggestion
                        suggestion={{
                            needAttentionCount: needAttentionFields.length,
                            suggestedCount: suggestedFields.length,
                            done: suggestionDone,
                        }}
                        onClick={handleBringBackSuggestion}
                    />
                )}

                <SelectedFieldsContainer
                    disabled={!canEditFarmSeason.access}
                    suggestedFields={suggestedFields}
                    needAttentionFields={needAttentionFields}
                    handleRemoveSelectedPolygon={onSelectedFieldDelete}
                />

                <LabelCheckboxView
                    disabled={!canEditFarmSeason.access}
                    border="dotted"
                    checked={!formik.values.drainage}
                    error={formik.touched.drainage && Boolean(formik.errors.drainage)}
                    helperText={formik.touched.drainage ? formik.errors.drainage : undefined}
                    label={t('pages.installationtasks.fields-subtasks.drainage-task.no-field-drained')}
                    name="drainage"
                    onChange={(event, checked) => {
                        if (formik.values.fields.length) {
                            modalDeleteSelection.openModal(() => {
                                deleteAll();
                                formik.setFieldValue('drainage', !checked);
                            });
                        } else {
                            formik.setFieldValue('drainage', !checked);
                        }
                    }}
                />
                <TwoButtonFooterView
                    leftOnClick={onSelectAll}
                    leftText={canEditFarmSeason.access ? t('constants.select-all-fields') : ''}
                    rightButtonOverride={
                        <RestrictSaveFarmSeason>
                            <LoadingButton
                                loading={fieldLoading}
                                onClick={() => onConfirm()}
                                text={t('constants.save')}
                            />
                        </RestrictSaveFarmSeason>
                    }
                />
            </div>
            <ModalWarning
                controller={modalDeleteSelection}
                confirmMessage={t('pages.installationtasks.fields-subtasks.drainage-task.delete-modal.confirm-message')}
                description={t('pages.installationtasks.fields-subtasks.drainage-task.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.fields-subtasks.drainage-task.delete-modal.title')}
            />
        </div>
    );
};

export default DrainageTaskView;
