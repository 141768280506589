import { ModalWarning } from '@soil-capital/ui-kit';
import { useModal } from '@soil-capital/ui-kit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { keepController } from './ModalFieldsSuggestion.slice';

const ModalFieldsSuggestion = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const controller = useModal();

    useEffect(() => {
        dispatch(keepController(controller));

        return () => {
            dispatch(keepController(null));
        };
    }, []);

    return (
        <ModalWarning
            controller={controller}
            title={t('components.modal-fields-suggestion.title')}
            description={t('components.modal-fields-suggestion.description')}
            confirmMessage={t('components.modal-fields-suggestion.strong-message')}
            leftButtonText={t('components.modal-fields-suggestion.button-cancel')}
            rightButtonText={t('components.modal-fields-suggestion.button-confirm')}
        />
    );
};

export default ModalFieldsSuggestion;
