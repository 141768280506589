import { makeStyles } from 'tss-react/mui';

const usePayoutCardStyle = makeStyles()((theme) => ({
    infoTooltip: {
        height: '20px',
        color: theme.palette.secondary.main,
    },
    ibanInput: {
        width: '300px',
    },
    flexbox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
    },
}));

export default usePayoutCardStyle;
