import translationGet from '@actions/StaticActions/TranslationsGetAction';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import languagesGet from '@actions/StaticActions/LanguagesGetAction';
import { useEffect } from 'react';
import languageConfig from '@constants/config/language.config';
import HttpApi from 'i18next-http-backend';

const useI18nSetup = () => {
    // store data
    const translations = useSelector((state: AppStateT) => state.static.translations);

    // dispatch function
    const dispatch = useAppDispatch();

    useEffect(() => {
        // i18nex initialisation
        i18n.use(detector)
            .use(HttpApi)
            .use(initReactI18next)
            .init(
                {
                    keySeparator: '.',
                    interpolation: {
                        escapeValue: false,
                    },
                    load: 'languageOnly',
                    fallbackLng: languageConfig.defaultLanguage,
                    resources: translations ?? undefined,
                    supportedLngs: languageConfig.languages,
                    backend: {
                        loadPath: `${window.location.origin}/translations/{{lng}}.json`,
                    },
                },
                () => {
                    // use 'fr' instead of 'fr-FR'.
                    const lang = i18n.language.length > 2 ? i18n.language.substring(0, 2) : i18n.language;
                    dispatch(translationGet(lang));
                },
            );
        dispatch(languagesGet());
    }, []);
};

export default useI18nSetup;
