import { makeStyles } from 'tss-react/mui';

const useModalWizzardLayoutStyle = makeStyles()((theme) => ({
    ModalWizzardLayout: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        textAlign: 'center',
        alignItems: 'center',
    },
    bodyZone: {
        width: '100%',
        margin: '20px auto 30px auto',
    },
    footerZone: {
        alignSelf: 'center',
    },
    title: {
        fontSize: '26px',
    },
    subTitle: {
        fontSize: '20px',
        color: theme.palette.secondary.main,
    },
}));

export default useModalWizzardLayoutStyle;
