import useAppNavigate from '@hooks/useAppNavigate';

import { ProgressWrapperLogicPropsT } from './ProgressWrapperTypes';

const useProgressWrapperLogic = ({ handleNextButton, nextButtonUrl }: ProgressWrapperLogicPropsT) => {
    const navigate = useAppNavigate();

    const handleNextButtonClick = async () => {
        if (!handleNextButton) {
            nextButtonUrl && navigate(nextButtonUrl);
        } else {
            handleNextButton();
        }

        return null;
    };

    return { handleNextButtonClick };
};
export default useProgressWrapperLogic;
