import selectLatestFarmSeason from '@store/selectors/selectLatestFarmSeason';
import store, { AppStateT } from '@store/store';
import { Dispatch } from '@reduxjs/toolkit';
import { AppActionsT } from '../AppActions/AppActionsTypes';
import { FarmSeasonSetLatestAsCurrentAction, FARM_SEASON_SET_CURRENT } from './FarmSeasonSetCurrentTypes';

export const farmSeasonSetLatestAsCurrent = (): FarmSeasonSetLatestAsCurrentAction => async (dispatch) => {
    const state = store.getState() as AppStateT;
    const latestFarmSeasonId = selectLatestFarmSeason()(state)?.id;
    farmSeasonSetCurrent({ farmSeasonId: latestFarmSeasonId })(dispatch);
};

export const farmSeasonSetCurrent =
    ({ farmSeasonId }: { farmSeasonId?: number }) =>
    async (dispatch: Dispatch<AppActionsT>) => {
        dispatch({ type: FARM_SEASON_SET_CURRENT, payload: { farmSeasonId } });
    };
