import Map from '@components/Map/Map';
import { MapSectionViewPropsT } from '@pages/HomePage/components/MapSection/MapSectionTypes';

const MapSectionView = ({
    defaultMapCenter,
    defaultMapZoom,
    showMaskLayer,
    style,
}: MapSectionViewPropsT): JSX.Element => {
    if (defaultMapCenter && defaultMapCenter?.geometry?.coordinates && defaultMapCenter?.geometry?.coordinates.length) {
        return (
            <Map
                style={style}
                latitude={defaultMapCenter.geometry?.coordinates[1]}
                longitude={defaultMapCenter.geometry?.coordinates[0]}
                showMaskLayer={showMaskLayer}
                zoom={defaultMapZoom}
            />
        );
    }

    return <Map style={style} />;
};

export default MapSectionView;
