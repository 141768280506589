import TextLink from '@components/TextLink/TextLink';
import { Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useNotificationHeaderStyle from './NotificationHeader.style';
import { NotificationHeaderPropsT } from './NotificationHeaderTypes';

const NotificationHeader = ({
    onShowOnlyUnread,
    showOnlyUnread,
    markAllAsReadLoading,
    onMarkAllAsRead,
    hasUnreadNotifications,
}: NotificationHeaderPropsT) => {
    const { t } = useTranslation();
    const { classes } = useNotificationHeaderStyle({ hasUnreadNotifications });

    return (
        <div className={classes.NotificationHeader}>
            <div className={classes.header}>
                <Typography className={classes.title} variant="h4">
                    {t('component.notification-header.title')}
                </Typography>
                <div className={classes.switchContainer}>
                    <Typography
                        className={classes.switchLabel}
                        color="secondary"
                        onClick={() => onShowOnlyUnread(showOnlyUnread)}
                    >
                        {t('component.notification-header.show-only-unread')}
                    </Typography>

                    <Switch checked={showOnlyUnread} onChange={() => onShowOnlyUnread(showOnlyUnread)} />
                </div>
            </div>
            <div className={classes.markAllAsReadContainer}>
                <TextLink
                    loaderPosition="left"
                    loading={markAllAsReadLoading}
                    textAlign="right"
                    onClick={onMarkAllAsRead}
                >
                    {t('component.notification-header.mark-all-as-read')}
                </TextLink>
            </div>
        </div>
    );
};

export default NotificationHeader;
