import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useProgressLinearStyle = makeStyles()((theme) => ({
    root: {
        padding: '8px 0 4px 0',
    },
    bar: {
        height: '4px',
        backgroundColor: alpha(theme.palette.secondary.light, 0.2),
        boxShadow: 'none',
    },
    text: {
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: '20px',
    },
}));

export default useProgressLinearStyle;
