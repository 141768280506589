import { makeStyles } from 'tss-react/mui';

const useCertificateTableStyle = makeStyles()(() => ({
    CertificateTable: {
        '& .MuiTableCell-sizeSmall': {
            padding: '10px 6px',
        },
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
}));

export default useCertificateTableStyle;
