import request from '../apiService/apiService';
import {
    OnboardingApiFieldsSuggestionParamsT,
    SuggestionResponseT,
    SuggestionTypeT,
} from './onboardingApiServiceTypes';

class OnboardingApiService {
    public static getSuggestion = async <T extends SuggestionTypeT>({
        farmId,
        farmSeasonId,
        suggestionType,
        suggestionParam,
    }: OnboardingApiFieldsSuggestionParamsT<T>) =>
        request<SuggestionResponseT<T>>({
            url:
                `/farm/${farmId}/season/${farmSeasonId}/suggest/${suggestionType}` +
                (suggestionParam ? `/${suggestionParam}` : ''),
            method: 'GET',
        });
}

export default OnboardingApiService;
