import React, { useEffect } from 'react';
import { ButtonResendVerifyEmailPropsT } from '@components/ButtonResendVerifyEmail/ButtonResendVerifyEmailTypes';
import resendVerifyEmail from '@actions/AuthActions/ResendVerifyEmailActions';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import { Alert, Link, Snackbar } from '@mui/material';
import useAppDispatch from '@hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { AppStateT } from '@store/store';
import { t } from 'i18next';

import useButtonResendVerifyEmailStyle from './ButtonResendVerifyEmail.style';

const ButtonResendVerifyEmail = ({ buttonStyle = 'button', color }: ButtonResendVerifyEmailPropsT): JSX.Element => {
    const dispatch = useAppDispatch();
    const user = useSelector((state: AppStateT) => state.auth.user);
    const [resendLoading, setResendLoading] = React.useState(false);
    const [resent, setResent] = React.useState(false);
    const [resentError, setResentError] = React.useState(false);
    const [resentMessage, setResentMessage] = React.useState('pages.verify-email.resent');
    const [showSnackbar, setShowSnackbar] = React.useState(false);

    const disabled = user?.email_verification_status === 'verified' || resent;

    const { classes } = useButtonResendVerifyEmailStyle({ disabled, resendLoading });

    useEffect(() => {
        let showSnackBarTimeout: NodeJS.Timeout;

        if (resent) {
            setShowSnackbar(true);
            showSnackBarTimeout = setTimeout(() => {
                setShowSnackbar(false);
            }, 5000);
        }

        return () => {
            clearTimeout(showSnackBarTimeout);
        };
    }, [resent]);

    const handleResendVerifyEmail = async () => {
        setResendLoading(true);

        const response = await dispatch(resendVerifyEmail());

        if (response.list?.length > 0) {
            setResentError(true);
            setResentMessage('pages.verify-email.resent');
        }

        setResendLoading(false);
        setResent(true);
    };

    const resentSnackbar = (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={1000} open={showSnackbar}>
            <Alert elevation={4} severity={resentError ? 'error' : 'success'}>
                <>{t(resentMessage)}</>
            </Alert>
        </Snackbar>
    );

    if (buttonStyle === 'link') {
        return (
            <>
                <Link className={classes.link} color={color} underline="always" onClick={handleResendVerifyEmail}>
                    <>{t('pages.verify-email.resend-btn')}</>
                </Link>
                {resentSnackbar}
            </>
        );
    }

    return (
        <>
            <LoadingButton
                disabled={disabled}
                loading={resendLoading}
                text={t('pages.verify-email.resend-btn')}
                fullWidth
                onClick={handleResendVerifyEmail}
            />
            {resentSnackbar}
        </>
    );
};

export default ButtonResendVerifyEmail;
