import MapService from '@services/mapService/mapService';
import { useModal } from '@soil-capital/ui-kit';
import { useContext, useEffect, useState } from 'react';
import useAppNavigate from '@hooks/useAppNavigate';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import postFarmSeasonFieldsAgroforestry from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsAgroforestry';
import { useFormik } from 'formik';

import { AgroforestryContext } from '../AgroforestryTaskContext/AgroforestryTaskContext';
import { AgroforestryContextT } from '../AgroforestryTaskContext/AgroforestryTaskContextType';

import SelectFieldsView from './SelectFieldsView';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const SelectFields = (): JSX.Element => {
    const { t, farmId, farmSeasonId, fieldLoading, pushStep, fieldsList, thisMap } =
        useContext<AgroforestryContextT>(AgroforestryContext);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const modalDeleteField = useModal();
    const modalDeleteSelection = useModal();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    const dontHaveAny = useSelector(
        (state: HomePageStateT) =>
            state.farmSeason.currentFarmSeason?.installation?.onboarding?.agroforestry?.dontHaveAny,
    );

    // avoid unselect fields that had trees last season.
    useEffect(() => {
        MapService.defineCondition({
            deselectCondition: (polygon) => {
                return polygon.properties.previous_has_agroforestry
                    ? {
                          selectType: 'blocked',
                          modal: {
                              title: t(
                                  'pages.installationtasks.fields-subtasks.agroforestry.modal-field-has-tree.title',
                              ),
                              description: t(
                                  'pages.installationtasks.fields-subtasks.agroforestry.modal-field-has-tree.description',
                              ),
                          },
                      }
                    : null;
            },
        });

        return () => MapService.defineCondition({ deselectCondition: null });
    }, []);

    // initialize formik
    const agroforestryFields = fieldsList?.filter((field) => field.has_agroforestry);
    const formik = useFormik({
        initialValues: {
            has_agroforestry: dontHaveAny === null ? true : !dontHaveAny,
            fields: agroforestryFields?.length > 0 ? [...agroforestryFields] : [],
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsSubmitted(true);
            await dispatch(postFarmSeasonFieldsAgroforestry(farmId, farmSeasonId, values));

            if (values.fields.length > 0) {
                pushStep(2);
            } else {
                navigate('..');
            }
        },
        validate: (values) => {
            const errors: any = {}; // eslint-disable-line

            if (values.fields.length === 0 && values.has_agroforestry) {
                errors.has_agroforestry = t('validation.confirm-no-agroforestry-error-message');
            }

            return errors;
        },
    });

    // Making the map selectable and disable selection once this component is unmount
    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }
        MapService.selectablePolygons({ selectMode: true });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
        };
    }, []);

    // init listener to update form fields with selectedPolygons from map
    useEffect(() => {
        const selected =
            fieldsList?.filter((field) =>
                thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id),
            ) ?? [];
        formik.setValues((state) => ({
            has_agroforestry: !(!state.has_agroforestry && selected.length === 0),
            fields: [...selected],
        }));
    }, [thisMap.selectedPolygons]);

    // init map selected field
    useEffect(() => {
        const initFields = fieldsList?.filter((field) => field.has_agroforestry);

        if (thisMap?.selectedPolygons?.length !== initFields?.length) {
            MapService.updateSelectedPolygons({
                selectedPolygons: initFields.map((field) => field.polygon),
            });
        }
    }, []);

    // delete all fields from selection
    const deleteAll = () => {
        MapService.updateSelectedPolygons({
            selectedPolygons: [],
        });
    };

    // Delete one fields from selection
    const selectedFieldDelete = (id: number | string) => {
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    // Check if the deleted field already has irrigation
    const onSelectedFieldDelete = (id: number | string) => {
        const deletedField = formik.values.fields.find((field) => field.id === id);

        if (deletedField?.agroforestry && deletedField.agroforestry.length > 0) {
            modalDeleteField.openModal(() => {
                selectedFieldDelete(id);
            });
        } else {
            selectedFieldDelete(id);
        }
    };

    // Select all the fields
    const onSelectAll = () => {
        const mapFields = fieldsList?.filter(
            (field) => !Boolean(field.field_crops?.find((field_crop) => field_crop.farm_season_crop.crop.is_permanent)),
        );
        MapService.updateSelectedPolygons({
            selectedPolygons: [...mapFields.map((field) => field.polygon)],
        });
    };

    return (
        <SelectFieldsView
            pushStep={pushStep}
            deleteAll={deleteAll}
            modalDeleteSelection={modalDeleteSelection}
            modalDeleteField={modalDeleteField}
            fieldLoading={fieldLoading}
            formik={formik}
            isSubmitted={isSubmitted}
            t={t}
            onSelectAll={onSelectAll}
            onSelectedFieldDelete={onSelectedFieldDelete}
        />
    );
};
export default SelectFields;
