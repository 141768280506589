import FarmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonOperationFertilisingPostCallBackT,
    PostFarmSeasonOperationFertilisingDataT,
    PostFarmSeasonOperationFertilisingDispatchTypesT,
    PostFarmSeasonOperationFertilisingResponseDataT,
    PostFarmSeasonOperationFertilisingResponseErrorT,
    POST_FARM_SEASON_OPERATION_FERTILISING_FAILED,
    POST_FARM_SEASON_OPERATION_FERTILISING_LOADING,
    POST_FARM_SEASON_OPERATION_FERTILISING_SUCCESS,
} from './PostFarmSeasonOperationFertilisingTypes';

export const postFarmSeasonOperationFertilising =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonOperationFertilisingDataT,
    ): FarmSeasonOperationFertilisingPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonOperationFertilisingDispatchTypesT>,
    ): Promise<PostFarmSeasonOperationFertilisingResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_OPERATION_FERTILISING_LOADING });
            const res: AxiosResponse<PostFarmSeasonOperationFertilisingResponseDataT> =
                await FarmSeasonOperationApiService.post<'fertilising'>(farmId, farmSeasonId, 'fertilising', body);

            dispatch({
                type: POST_FARM_SEASON_OPERATION_FERTILISING_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_OPERATION_FERTILISING_FAILED,
                error: error as PostFarmSeasonOperationFertilisingResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
