import { useTranslation } from 'react-i18next';
import formatNumber from '@utils/formatNumber';
import { Box } from '@mui/material';
import { InfoTooltip } from '@ui-kit';
import useFieldAmountLabelStyle from './FieldAmountLabel.style';
import useFieldAmountLabelLogic from './FieldAmountLabel.logic';

const FieldAmountLabel = () => {
    const { t } = useTranslation();
    const { classes } = useFieldAmountLabelStyle();
    const { fieldsCount, totalCultivatedArea, totalPermanentArea } = useFieldAmountLabelLogic();

    return (
        <Box className={classes.fieldAmountLabel} component="span">
            {`${fieldsCount} ${t('constants.fields', {
                count: fieldsCount,
            })} (${formatNumber(totalCultivatedArea, 2)} ${t('constants.hectare-unit')})`}
            {totalPermanentArea > 0 && (
                <InfoTooltip
                    tooltipScProps={{
                        translationSlug: t('components.selected-fields-header.tooltip-area', {
                            permanentCropArea: formatNumber(totalPermanentArea, 2),
                        }),
                    }}
                />
            )}
        </Box>
    );
};

export default FieldAmountLabel;
