import MapService from '@services/mapService/mapService';
import { AppStateT } from '../store';

/** get map state (for id 'map'). If a property is provided, return only the state for the prop. */
const selectMap = () => (state: AppStateT) => {
    const mapState = state.map.maps.find((m) => m.id === MapService.defaultMapId);

    return mapState;
};

export default selectMap;
