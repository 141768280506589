/* eslint-disable @typescript-eslint/no-explicit-any */
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import UserApiService from '@services/userApiService/userApiService';
import { useEffect } from 'react';

import { appInitFarmsAndSeasons } from '@store/actions/AppActions/AppActions';
import { authSetSelectedUser } from '@store/actions/AuthActions/AuthActions';
import { useParams } from 'react-router-dom';

const VisitFarm = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { farmerId } = useParams();

    useEffect(() => {
        const searchFarmer = async (id: number) => {
            const response = await UserApiService.searchFarmer(id);
            const foundFarmer = response.data.farmers?.[0];

            if (foundFarmer) {
                dispatch(
                    authSetSelectedUser({
                        userId: foundFarmer.id,
                        fullName: foundFarmer.full_name,
                        model_type: foundFarmer.farms[0].model_type,
                    }),
                );

                if (!foundFarmer.farms?.[0].season_id) {
                    navigate('/subscribe/select-season');
                } else {
                    navigate('/');
                }

                await dispatch(appInitFarmsAndSeasons({ userId: response.data.farmers[0].id }));
            } else {
                throw new Error('404');
            }
        };

        searchFarmer(Number(farmerId));
    }, []);

    return <></>;
};

export default VisitFarm;
