import logoMySoilcapitalSrc from '@images/logo-my-soilcapital.png';
import logoSoilcapitalSrc from '@images/logo-soilcapital.png';

import { LogoImgPropsT } from './LogoImgTypes';

// TODO we should reuse this logo through the app
const LogoImg = ({ className, logoType = 'my-soilcapital' }: LogoImgPropsT): JSX.Element => {
    const logoMap: Record<'my-soilcapital' | 'soilcapital', string> = {
        'my-soilcapital': logoMySoilcapitalSrc,
        soilcapital: logoSoilcapitalSrc,
    };

    return (
        <img
            alt={`${logoType} logo`}
            className={className}
            src={logoMap[logoType]}
            style={{ maxWidth: '140px', height: '38px' }}
        />
    );
};

export default LogoImg;
