import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => {
    return {
        infoIcon: {
            marginLeft: '4px',
            height: '19px !important',
            width: '19px',
        },
    };
});
