import { makeStyles } from 'tss-react/mui';

const useCallToActionSuggestionStyle = makeStyles()(() => ({
    ctaWrapper: {
        marginBottom: '20px',
        height: '46px',
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useCallToActionSuggestionStyle;
