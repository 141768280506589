import { Typography } from '@mui/material';
import { BigNumberUnitPropsT } from './BigNumberUnit.types';

export const BigNumberUnit = ({ value, unit }: BigNumberUnitPropsT) => {
    return (
        <div>
            <Typography display={'inline'} variant="h1" sx={{ fontSize: '2rem', lineHeight: '3rem' }}>
                {value}
            </Typography>
            {unit !== undefined && (
                <Typography display={'inline'} variant="h2" sx={{ fontSize: '1.5rem', lineHeight: '2.5rem' }}>
                    &nbsp;{unit}
                </Typography>
            )}
        </div>
    );
};
