import {
    PostFarmSeasonFieldsAgroforestryDataT,
    PostFarmSeasonFieldsAgroforestryResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsAgroforestryTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsAgroforestryApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsAgroforestryDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsAgroforestryResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/agroforestry`,
            method: 'POST',
            data: body,
        });
}

export default farmSeasonFieldsAgroforestryApiService;
