import { UserT } from '@store/reducers/AuthReducer/AuthReducerTypes';

export type SignUpAuthDataT = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    language_id?: number;
    token?: string;
    invitation_id?: number;
    referral_code?: string;
};

export type SignupAuthResponseT = {
    access_token: string;
    expires_in: number;
    message: string;
    token_type: string;
    user: UserT;
    farmer_infos: FarmerInfosT | null;
};

export type LoginAuthDataT = {
    email: string;
    password: string;
};

export type LoginAuthResponseT = {
    access_token: string;
    expires_in: number;
    token_type: string;
    user: UserT;
    farmer_infos: FarmerInfosT | null;
};

export type FarmerInfosT = {
    latest_active_season: number | null;
    model_type: CarbonModelType | null;
};

export enum CarbonModelType {
    CFT = 'cft',
    DNDC = 'dndc',
}

export type ForgotPasswordAuthDataT = {
    email: string;
};

export type ResetPasswordAuthDataT = {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
};

export type VerifyEmailAuthDataT = {
    hashedId: string;
    expires: string;
    signature: string;
};

export type AuthorizeAuth0DataT = {
    token: string;
};
