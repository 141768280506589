import AddButtonView from '@components/AddButton/AddButtonView';
import ColorPatternCardView from '@components/ColorPatternCard/ColorPatternCardView';
import { Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import IconEye from '@mui/icons-material/RemoveRedEyeOutlined';
import IconEdit from '@icons/icon-edit.svg';
import useListStepStyle from './ListStep.style';
import { ListStepPropsT } from './ListStep.types';
import Restricted from '@components/Restricted/Restricted';
import formatNumber from '@utils/formatNumber';
import useListStepLogic from './ListStep.logic';

const ListStep = ({ addOrModifyCrop, onCropDelete }: ListStepPropsT): JSX.Element => {
    const theme = useTheme();

    const {
        animateParent,
        canEditFarmSeason,
        currentFarmSeason,
        getFieldsAreaForCrop,
        getFieldsCountForCrop,
        cropColors,
        crops,
        getStaticCrop,
    } = useListStepLogic();

    const { classes } = useListStepStyle();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.crops.list-step.title">
                    Ajouter, modifier ou supprimer vos
                    <span style={{ color: theme.palette.secondary.dark }}>cultures</span>
                </Trans>
            </Typography>
            <div ref={animateParent} className={classes.listContainer}>
                {crops?.map((crop) => (
                    <ColorPatternCardView
                        key={crop.id}
                        color={cropColors[crop.crop_id]}
                        name={`${t(getStaticCrop(crop.crop_id)?.translation_slug ?? '')}${
                            crop.is_accompanied ? t('constants.select-companion') : ''
                        }`}
                        onDelete={canEditFarmSeason.access ? () => crop.id && onCropDelete(crop.id) : undefined}
                        onEdit={() => addOrModifyCrop(crop)}
                        editIcon={canEditFarmSeason.access ? IconEdit : IconEye}
                    >
                        <>
                            <Typography color="primary" variant="body1">
                                {t('constants.fields-subtitle', {
                                    count: getFieldsCountForCrop({ cropId: crop.id }),
                                })}
                            </Typography>
                            <Typography color="primary" variant="body1">
                                {formatNumber(getFieldsAreaForCrop({ cropId: crop.id }))}
                                &nbsp;
                                {t('constants.hectare-unit')}
                            </Typography>
                        </>
                    </ColorPatternCardView>
                ))}
            </div>
            <Restricted toPermission="EDIT_FARM-SEASON" forResource={currentFarmSeason}>
                <AddButtonView
                    className={classes.addButton}
                    label={t('constants.add')}
                    onClick={() => addOrModifyCrop(null)}
                />
            </Restricted>
        </div>
    );
};

export default ListStep;
