import { useContext } from 'react';
import { Prompt } from '@components/Prompt/Prompt';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import MenuBar from '@components/MenuBar/MenuBar';

import SelectFields from './SelectFields/SelectFields';
import AgroforestryValuesForm from './AgroforestryValuesForm/AgroforestryValuesForm';
import useAgroforestryTaskStyle from './AgroforestryTaskView.style';
import { AgroforestryContext } from './AgroforestryTaskContext/AgroforestryTaskContext';
import SelectedFieldsList from './SelectedFieldsList/SelectedFieldsList';

const AgroforestryTaskView = (): JSX.Element => {
    const { classes } = useAgroforestryTaskStyle();
    const { currentStep, t, handleBackButton } = useContext(AgroforestryContext);

    let StepComponent: JSX.Element;

    switch (currentStep) {
        case 2:
            StepComponent = <SelectedFieldsList />;
            break;
        case 3:
            StepComponent = <AgroforestryValuesForm />;
            break;
        case 1:
        default:
            StepComponent = <SelectFields />;
            break;
    }

    return (
        <div>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={false}
            />
            <ProgressBar className={classes.progressBar} color="secondary" currentStep={currentStep} totalSteps={3} />
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.fields-subtasks.agroforestry.menu-title')}
                    onArrowBack={currentStep > 1 ? () => handleBackButton() : '..'}
                    onClose=".."
                />
                {StepComponent}
            </div>
        </div>
    );
};

export default AgroforestryTaskView;
