import { Box, CircularProgress } from '@mui/material';
import useLoadingSectionStyle from './LoadingSection.style';
import { LoadingSectionPropsT } from './LoadingSectionTypes';

const LoadingSection = ({
    children,
    className,
    loading = false,
    transparentLoadingSection = false,
    contentDisplay = 'block',
    color = 'primary',
}: LoadingSectionPropsT): JSX.Element => {
    const { classes } = useLoadingSectionStyle({ loading, transparentLoadingSection, contentDisplay });
    const boxClassName = [classes.loaderBox, 'is-' + color].join(' ');

    return (
        <div className={`${className ?? ''} ${classes.LoadingSection}`}>
            {loading && (
                <Box alignItems="center" className={boxClassName} display="flex" height="100%" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
            <div className={classes.content}>{children}</div>
        </div>
    );
};

export default LoadingSection;
