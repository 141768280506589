import { makeStyles } from 'tss-react/mui';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useProgressWraperStyle = makeStyles<{ skipLoading: boolean }>()((theme, { skipLoading }) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    title: {
        fontSize: '26px',
    },
    subTitle: {
        marginTop: '-6px',
        marginBottom: '30px',
    },

    footerContaier: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        minHeight: '46px', // min button height
    },

    backLinkContainer: {
        flexGrow: 1,
    },
    backLink: {
        display: 'flex',
        alignItems: 'end',
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        width: 'fit-content',
        '&>*': {
            fontWeight: 'bold',
        },
        cursor: 'pointer',
    },

    skipLink: {
        display: 'flex',
        alignItems: 'end',
        opacity: skipLoading ? 0.5 : 1,
        cursor: 'pointer',
    },

    arrowBack: {
        transform: 'rotate(180deg)',
    },

    buttonNext: {
        marginLeft: '40px',
        // marginLeft: 'auto',
    },
    progress: {
        opacity: 0.5,
        color: theme.palette.primary.main,
        marginRight: '10px',
    },
}));

export default useProgressWraperStyle;
