import FarmSeasonFieldsAgroforestryApiService from '@services/farmSeasonFieldsAgroforestryApiService/farmSeasonFieldsAgroforestryApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsAgroforestryPostCallBackT,
    PostFarmSeasonFieldsAgroforestryDataT,
    PostFarmSeasonFieldsAgroforestryDispatchTypesT,
    PostFarmSeasonFieldsAgroforestryResponseDataT,
    PostFarmSeasonFieldsAgroforestryResponseErrorT,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_FAILED,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_LOADING,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS,
} from './PostFarmSeasonFieldsAgroforestryTypes';

const postFarmSeasonFieldsAgroforestry =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsAgroforestryDataT,
    ): FarmSeasonFieldsAgroforestryPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsAgroforestryDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsAgroforestryResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_AGROFORESTRY_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsAgroforestryResponseDataT> =
                await FarmSeasonFieldsAgroforestryApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_AGROFORESTRY_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_AGROFORESTRY_FAILED,
                error: error as PostFarmSeasonFieldsAgroforestryResponseErrorT,
            });

            return {};
        }
    };

export default postFarmSeasonFieldsAgroforestry;
