/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import MenuBar from '@components/MenuBar/MenuBar';
import { Modal, Paper, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import WarningMessageView from '@components/WarningMessage/WarningMessageView';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, GridSortItem } from '@mui/x-data-grid';
import _ from 'lodash';
import { setNestedObjectValues } from 'formik';

import FertilisationMachinerySectionView from '../FertilisationMachinerySection/FertilisationMachinerySectionView';
import { FertilisationTaskRouteParamsT } from '../FertilisationTaskTypes';
import sumFertiliserComponent from '../../utils/sumFertiliserComponent';

import { ExceptionTaskViewPropsT } from './ExceptionTaskTypes';
import useExceptionTaskStyle from './ExceptionTaskView.style';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const ExceptionTaskView = ({
    menuTitle,
    setSelectedException,
    formik,
    setShowColapseId,
    showCollapseId,
    fertilisers,
    onSelectedFieldDelete,
    titleIndex,
    canEdit = false,
}: ExceptionTaskViewPropsT): JSX.Element => {
    const { classes } = useExceptionTaskStyle();
    const { t } = useTranslation();
    const { cropId } = useParams<FertilisationTaskRouteParamsT>();
    const farmSeasonCrop = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList)?.find(
        (crop) => crop.id === parseInt(cropId || '', 10),
    );

    const [modalWarningIsChecked, setModalWarningIsChecked] = useState(false);
    const [modalOpenState, setOpenModal] = useState(false);
    const openModal = () => setOpenModal(true);
    const closeModal = () => setOpenModal(false);
    const [summaryTable, setSummaryTable] = useState<{ columns: any[]; rows: any[] }>({ columns: [], rows: [] });
    const [totalNPK, setTotalNPK] = useState<{ n?: number; p?: number; k?: number } | null>(null);
    const [modalWarning, setModalWarning] = useState(false);
    const [summaryTableSorting, setSummaryTableSorting] = useState<GridSortItem[]>([
        { field: 'numberOfUse', sort: 'desc' },
    ]);

    useEffect(() => {
        setModalWarning(
            (Number(farmSeasonCrop?.crop?.n_threshold) > 0 &&
                Number(totalNPK?.n) > Number(farmSeasonCrop?.crop?.n_threshold)) ||
                (Number(farmSeasonCrop?.crop?.p_threshold) > 0 &&
                    Number(totalNPK?.p) > Number(farmSeasonCrop?.crop?.p_threshold)) ||
                (Number(farmSeasonCrop?.crop?.k_threshold) > 0 &&
                    Number(totalNPK?.k) > Number(farmSeasonCrop?.crop?.k_threshold)),
        );
    }, [totalNPK, farmSeasonCrop]);

    useEffect(() => {
        const groupedFertilisers = [
            ...formik.values.mineral_liquid.machinery_fertilisers,
            ...formik.values.mineral_solid.machinery_fertilisers,
            ...formik.values.organic_liquid.machinery_fertilisers,
            ...formik.values.organic_solid.machinery_fertilisers,
            ...formik.values.other_liquid.machinery_fertilisers,
            ...formik.values.other_solid.machinery_fertilisers,
        ];

        setTotalNPK({
            n: sumFertiliserComponent(groupedFertilisers, 'n'),
            p: sumFertiliserComponent(groupedFertilisers, 'p'),
            k: sumFertiliserComponent(groupedFertilisers, 'k'),
        });

        const uniqueUsedFertilisers = _.uniq(groupedFertilisers.map((f) => f.fertiliser?.id));

        const countNPK = (fid: number) => {
            const groupedFertilisersFiltered = groupedFertilisers.filter(
                (fertiliser) => fertiliser?.fertiliser?.id === fid,
            );
            const n = sumFertiliserComponent(groupedFertilisersFiltered, 'n');
            const p = sumFertiliserComponent(groupedFertilisersFiltered, 'p');
            const k = sumFertiliserComponent(groupedFertilisersFiltered, 'k');

            return t('pages.operations.fertilising.summary.values.fertiliser-total-npk', { n, p, k });
        };

        setSummaryTable({
            columns: [
                { field: 'name', headerName: t('constants.name'), flex: 1, disableColumnMenu: true },
                {
                    field: 'numberOfUse',
                    headerName: t('pages.operations.fertilising.summary.titles.number-of-use'),
                    flex: 1,
                    minWidth: 200,
                    disableColumnMenu: true,
                },
                {
                    field: 'totalNPK',
                    headerName: t('pages.operations.fertilising.summary.titles.fertiliser-total-npk'),
                    flex: 1,
                    minWidth: 180,
                    disableColumnMenu: true,
                },
            ],
            rows: uniqueUsedFertilisers
                .filter((fid: number | undefined) => fid && fid > 0)
                .map((fid) => ({
                    id: fid,
                    name: t(fertilisers?.find((f) => f.id === fid)?.translation_slug || ''),
                    numberOfUse: _.countBy(groupedFertilisers, 'fertiliser.id')[fid as number],
                    totalNPK: countNPK(fid as number),
                })),
        });
    }, [
        formik.values.mineral_liquid.machinery_fertilisers,
        formik.values.mineral_solid.machinery_fertilisers,
        formik.values.organic_liquid.machinery_fertilisers,
        formik.values.organic_solid.machinery_fertilisers,
        formik.values.other_liquid.machinery_fertilisers,
        formik.values.other_solid.machinery_fertilisers,
        fertilisers,
    ]);

    return (
        <div className={classes.container}>
            <MenuBar
                className={classes.menuBar}
                menuTitle={menuTitle}
                onArrowBack={() => setSelectedException(null)}
                onClose={() => setSelectedException(null)}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                {t('pages.operations.fertilising-exception.title', { value: titleIndex })}
            </Typography>
            <Typography className={classes.description} color="secondary" component="p" variant="body2">
                <Trans i18nKey="pages.operations.fertilising-exception.description"></Trans>
            </Typography>

            <SelectedFieldsContainer
                customMinHeight={'65px'}
                headerText={t('pages.installationtasks.add-field-task.final-step.left-subtitle')}
                handleRemoveSelectedPolygon={onSelectedFieldDelete}
            />

            {Boolean(
                formik.touched?.fields_ids &&
                    formik.errors?.fields_ids !== '' &&
                    typeof formik.errors?.fields_ids === 'string',
            ) && (
                <ErrorMessageView
                    message={typeof formik.errors?.fields_ids === 'string' ? formik.errors?.fields_ids : ''}
                />
            )}
            <div className={classes.listContainer}>
                <FertilisationMachinerySectionView
                    dataLoading={false}
                    dataSource="mineral_liquid.machinery_fertilisers"
                    disableOpen={formik.values.dontHaveAny}
                    disabled={formik.values.dontHaveAny || !canEdit}
                    fertilisers={fertilisers}
                    fertilisersTypeIds={[1]}
                    fertilisersUnit="L"
                    formik={formik}
                    id="mineral-liquid"
                    machineryError={
                        typeof formik.errors?.mineral_liquid?.machinery_fertilisers === 'string'
                            ? formik.errors?.mineral_liquid?.machinery_fertilisers
                            : ''
                    }
                    machineryFertiliserArray={formik.values.mineral_liquid.machinery_fertilisers}
                    machineryTouched={formik.touched?.mineral_liquid?.machinery_fertilisers}
                    passage={formik.values.mineral_liquid.passage}
                    passageError={Boolean(
                        formik.touched?.mineral_liquid?.passage && formik.errors?.mineral_liquid?.passage,
                    )}
                    passageHelperText={
                        formik.touched?.mineral_liquid?.passage && formik.errors?.mineral_liquid?.passage
                            ? formik.errors?.mineral_liquid?.passage
                            : ''
                    }
                    setShowColapseId={setShowColapseId}
                    showCollapseId={showCollapseId}
                    title={t('pages.operations.fertilising.mineral-liquid-title')}
                    withInhibitor
                    onPassesChange={(event) =>
                        Number(event.target.value) >= 0 &&
                        formik.setFieldValue(
                            'mineral_liquid.passage',
                            event.target.value ? parseInt(event.target.value || '0') : null,
                        )
                    }
                />
                <FertilisationMachinerySectionView
                    dataLoading={false}
                    dataSource="mineral_solid.machinery_fertilisers"
                    disableOpen={formik.values.dontHaveAny}
                    disabled={formik.values.dontHaveAny || !canEdit}
                    fertilisers={fertilisers}
                    fertilisersTypeIds={[1]}
                    fertilisersUnit="Kg"
                    formik={formik}
                    id="mineral-solid"
                    machineryError={
                        typeof formik.errors?.mineral_solid?.machinery_fertilisers === 'string'
                            ? formik.errors?.mineral_solid?.machinery_fertilisers
                            : ''
                    }
                    machineryFertiliserArray={formik.values.mineral_solid.machinery_fertilisers}
                    machineryTouched={formik.touched?.mineral_solid?.machinery_fertilisers}
                    passage={formik.values.mineral_solid.passage}
                    passageError={Boolean(
                        formik.touched?.mineral_solid?.passage && formik.errors?.mineral_solid?.passage,
                    )}
                    passageHelperText={
                        formik.touched?.mineral_solid?.passage && formik.errors?.mineral_solid?.passage
                            ? formik.errors?.mineral_solid?.passage
                            : ''
                    }
                    setShowColapseId={setShowColapseId}
                    showCollapseId={showCollapseId}
                    title={t('pages.operations.fertilising.mineral-solid-title')}
                    withInhibitor
                    onPassesChange={(event) =>
                        Number(event.target.value) >= 0 &&
                        formik.setFieldValue(
                            'mineral_solid.passage',
                            event.target.value ? parseInt(event.target.value || '0') : null,
                        )
                    }
                />
                <FertilisationMachinerySectionView
                    dataLoading={false}
                    dataSource="organic_liquid.machinery_fertilisers"
                    disableOpen={formik.values.dontHaveAny}
                    disabled={formik.values.dontHaveAny || !canEdit}
                    fertilisers={fertilisers}
                    fertilisersTypeIds={[2]}
                    fertilisersUnit="L"
                    formik={formik}
                    id="organic-liquid"
                    machineryError={
                        typeof formik.errors?.organic_liquid?.machinery_fertilisers === 'string'
                            ? formik.errors?.organic_liquid?.machinery_fertilisers
                            : ''
                    }
                    machineryFertiliserArray={formik.values.organic_liquid.machinery_fertilisers}
                    machineryTouched={formik.touched?.organic_liquid?.machinery_fertilisers}
                    passage={formik.values.organic_liquid.passage}
                    passageError={Boolean(
                        formik.touched?.organic_liquid?.passage && formik.errors?.organic_liquid?.passage,
                    )}
                    passageHelperText={
                        formik.touched?.organic_liquid?.passage && formik.errors?.organic_liquid?.passage
                            ? formik.errors?.organic_liquid?.passage
                            : ''
                    }
                    setShowColapseId={setShowColapseId}
                    showCollapseId={showCollapseId}
                    title={t('pages.operations.fertilising.organic-liquid-title')}
                    onPassesChange={(event) =>
                        Number(event.target.value) >= 0 &&
                        formik.setFieldValue(
                            'organic_liquid.passage',
                            event.target.value ? parseInt(event.target.value || '0') : null,
                        )
                    }
                />
                <FertilisationMachinerySectionView
                    dataLoading={false}
                    dataSource="organic_solid.machinery_fertilisers"
                    disableOpen={formik.values.dontHaveAny}
                    disabled={formik.values.dontHaveAny || !canEdit}
                    fertilisers={fertilisers}
                    fertilisersTypeIds={[2]}
                    fertilisersUnit="Kg"
                    formik={formik}
                    id="organic-solid"
                    machineryError={
                        typeof formik.errors?.organic_solid?.machinery_fertilisers === 'string'
                            ? formik.errors?.organic_solid?.machinery_fertilisers
                            : ''
                    }
                    machineryFertiliserArray={formik.values.organic_solid.machinery_fertilisers}
                    machineryTouched={formik.touched?.organic_solid?.machinery_fertilisers}
                    passage={formik.values.organic_solid.passage}
                    passageError={Boolean(
                        formik.touched?.organic_solid?.passage && formik.errors?.organic_solid?.passage,
                    )}
                    passageHelperText={
                        formik.touched?.organic_solid?.passage && formik.errors?.organic_solid?.passage
                            ? formik.errors?.organic_solid?.passage
                            : ''
                    }
                    setShowColapseId={setShowColapseId}
                    showCollapseId={showCollapseId}
                    title={t('pages.operations.fertilising.organic-solid-title')}
                    onPassesChange={(event) =>
                        Number(event.target.value) >= 0 &&
                        formik.setFieldValue(
                            'organic_solid.passage',
                            event.target.value ? parseInt(event.target.value || '0') : null,
                        )
                    }
                />
                <FertilisationMachinerySectionView
                    dataLoading={false}
                    dataSource="other_liquid.machinery_fertilisers"
                    disableOpen={formik.values.dontHaveAny}
                    disabled={formik.values.dontHaveAny || !canEdit}
                    fertilisers={fertilisers}
                    fertilisersTypeIds={[3, 4, 5, 6]}
                    fertilisersUnit="L"
                    formik={formik}
                    id="other-liquid"
                    machineryError={
                        typeof formik.errors?.other_liquid?.machinery_fertilisers === 'string'
                            ? formik.errors?.other_liquid?.machinery_fertilisers
                            : ''
                    }
                    machineryFertiliserArray={formik.values.other_liquid.machinery_fertilisers}
                    machineryTouched={formik.touched?.other_liquid?.machinery_fertilisers}
                    passage={formik.values.other_liquid.passage}
                    passageError={Boolean(
                        formik.touched?.other_liquid?.passage && formik.errors?.other_liquid?.passage,
                    )}
                    passageHelperText={
                        formik.touched?.other_liquid?.passage && formik.errors?.other_liquid?.passage
                            ? formik.errors?.other_liquid?.passage
                            : ''
                    }
                    setShowColapseId={setShowColapseId}
                    showCollapseId={showCollapseId}
                    title={t('pages.operations.fertilising.other-liquid-title')}
                    onPassesChange={(event) =>
                        Number(event.target.value) >= 0 &&
                        formik.setFieldValue(
                            'other_liquid.passage',
                            event.target.value ? parseInt(event.target.value || '0') : null,
                        )
                    }
                />
                <FertilisationMachinerySectionView
                    dataLoading={false}
                    dataSource="other_solid.machinery_fertilisers"
                    disableOpen={formik.values.dontHaveAny}
                    disabled={formik.values.dontHaveAny || !canEdit}
                    fertilisers={fertilisers}
                    fertilisersTypeIds={[3, 4, 5, 6]}
                    fertilisersUnit="Kg"
                    formik={formik}
                    id="other-solid"
                    machineryError={
                        typeof formik.errors?.other_solid?.machinery_fertilisers === 'string'
                            ? formik.errors?.other_solid?.machinery_fertilisers
                            : ''
                    }
                    machineryFertiliserArray={formik.values.other_solid.machinery_fertilisers}
                    machineryTouched={formik.touched?.other_solid?.machinery_fertilisers}
                    passage={formik.values.other_solid.passage}
                    passageError={Boolean(formik.touched?.other_solid?.passage && formik.errors?.other_solid?.passage)}
                    passageHelperText={
                        formik.touched?.other_solid?.passage && formik.errors?.other_solid?.passage
                            ? formik.errors?.other_solid?.passage
                            : ''
                    }
                    setShowColapseId={setShowColapseId}
                    showCollapseId={showCollapseId}
                    title={t('pages.operations.fertilising.other-solid-title')}
                    onPassesChange={(event) =>
                        Number(event.target.value) >= 0 &&
                        formik.setFieldValue(
                            'other_solid.passage',
                            event.target.value ? parseInt(event.target.value || '0') : null,
                        )
                    }
                />
            </div>
            {canEdit && (
                <LabelCheckboxView
                    disabled={!canEdit}
                    border="dotted"
                    checked={formik.values.dontHaveAny}
                    label={t('pages.operations.fertilising.no-fertilising')}
                    onChange={(e, value) => formik.setFieldValue('dontHaveAny', value)}
                />
            )}
            <TwoButtonFooterView
                rightLoading={formik.isSubmitting}
                rightOnCLick={() =>
                    formik.validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                            if (!formik.values.dontHaveAny) {
                                openModal();
                            } else {
                                formik.submitForm();
                            }
                        } else {
                            formik.setTouched(setNestedObjectValues(errors, true));
                        }
                    })
                }
                rightText={canEdit ? t('constants.confirm') : t('constants.see-details')}
            />
            <Modal className={classes.modal} open={modalOpenState} keepMounted onClose={closeModal}>
                <Paper className={classes.modalPaper} elevation={0}>
                    <div className={classes.modalHeader}>
                        <CloseIcon className={classes.modalClose} onClick={closeModal} />
                        <Typography color="secondary" variant="h4">
                            {t('pages.operations.fertilising.summary.title')}
                        </Typography>
                        <Typography className={classes.modalIntroText} variant="body2">
                            {t('pages.operations.fertilising.summary.description')}
                        </Typography>
                    </div>
                    <div className={classes.modalContent}>
                        <div className={classes.tableWrapper}>
                            <DataGrid
                                columns={summaryTable?.columns}
                                density="compact"
                                rows={summaryTable?.rows}
                                sortModel={summaryTableSorting}
                                hideFooter
                                onSortModelChange={(model) => setSummaryTableSorting(model)}
                            />
                        </div>
                    </div>
                    <div className={classes.modalFooter}>
                        <div className={classes.totalContainer}>
                            <div className={`${classes.totalNPK} ${modalWarning ? classes.warningTotal : ''}`}>
                                <Typography className={classes.warningText} color="primary" variant="body2">
                                    {t('pages.operations.fertilising.summary.total-npk', {
                                        n: Math.ceil(totalNPK?.n || 0),
                                        p: Math.ceil(totalNPK?.p || 0),
                                        k: Math.ceil(totalNPK?.k || 0),
                                    })}
                                </Typography>
                                {modalWarning && <ErrorOutlineIcon className={classes.warningIcon} />}
                            </div>
                        </div>
                        {modalWarning && (
                            <WarningMessageView
                                checkBoxMessage={t('pages.operations.fertilising.summary.warning.checkbox-label')}
                                containerClass={classes.warningBox}
                                isChecked={modalWarningIsChecked}
                                message={t('pages.operations.fertilising.summary.warning.message')}
                                onCheck={(isChecked) => setModalWarningIsChecked(isChecked)}
                            />
                        )}
                        <TwoButtonFooterView
                            leftOnClick={closeModal}
                            leftText={t('constants.back')}
                            rightButtonOverride={
                                canEdit ? (
                                    <LoadingButton
                                        disabled={!formik.isValid || (modalWarning && !modalWarningIsChecked)}
                                        loading={formik.isSubmitting}
                                        onClick={formik.submitForm}
                                        text={t('constants.validate')}
                                    />
                                ) : null
                            }
                        />
                    </div>
                </Paper>
            </Modal>
        </div>
    );
};

export default ExceptionTaskView;
