import { makeStyles } from 'tss-react/mui';

import { NotificationItemStyleProps } from './NotificationItemTypes';

const useNotificationItemStyle = makeStyles<NotificationItemStyleProps>()(
    (theme, { clickable, iconColor, iconBg }) => ({
        NotificationItem: {
            boxShadow:
                '0px 4px 5px -2px rgb(0 0 0 / 3%), 0px 7px 10px 1px rgb(0 0 0 / 2%), 0px 2px 16px 1px rgb(0 0 0 / 1%)',
            padding: theme.spacing(2.5),
            cursor: clickable ? 'pointer' : 'default',
            '&:hover': {
                boxShadow:
                    'rgb(0 0 0 / 9%) 0px 4px 5px -2px, rgb(0 0 0 / 6%) 0px 7px 10px 1px, rgb(0 0 0 / 3%) 0px 2px 16px 1px;',
            },
            marginBottom: '7px',
        },
        cardContent: {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
        },
        cardTextContainer: {
            flex: 1,
            padding: '0 5px 0 15px',
        },
        cardText: {
            position: 'relative',
            lineHeight: 1.2,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            // 2 line ellipsis
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
        cardDate: {
            color: theme.palette.grey[400],
            width: 'fit-content',
        },
        cardUnread: {},
        icon: {
            height: '38px',
            width: '38px',
            borderRadius: '100%',
            backgroundColor: iconBg,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                height: '20px',
                widht: '20px',
            },
            '& svg *': {
                fill: iconColor,
            },
        },
        loaderContainer: {
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loaderColor: {
            color: theme.palette.primary.main,
        },
    }),
);

export default useNotificationItemStyle;
