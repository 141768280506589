import { useEffect, useState, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import MapService from '@services/mapService/mapService';
import getTrees from '@actions/StaticActions/TreesGet';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

import { AgroforestryContextT, AgroforestryTaskContextT } from './AgroforestryTaskContextType';

export const AgroforestryContext = createContext({} as AgroforestryContextT);

const AgroforestryTaskContext = ({ children }: AgroforestryTaskContextT): JSX.Element => {
    const { t } = useTranslation();
    const [stepArray, setStepArray] = useState([1]);
    const [currentStep, setCurrentStep] = useState(stepArray[stepArray.length - 1]);
    const [selectedField, setSelectedField] = useState<FarmSeasonFieldT>();

    const fieldLoading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const treesList = useSelector((state: HomePageStateT) => state.static.trees);
    const treesListLoading = useSelector((state: HomePageStateT) => state.static.treesLoading);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm)?.id;
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason)?.id;
    const fieldsList = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList);

    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!(treesList?.length > 0)) dispatch(getTrees());
    }, []);

    useEffect(() => {
        setCurrentStep(stepArray[stepArray.length - 1]);
    }, [stepArray]);

    const pushStep = (step: number) => {
        setStepArray((state) => [...state, step]);
    };

    const popStep = () => {
        setStepArray((state) => [...state.splice(0, state.length - 1)]);
    };

    const handleBackButton = () => {
        if (currentStep > 1) {
            setStepArray((state) => {
                const newState = [...state];
                newState.pop();

                return newState;
            });
        }
    };

    const store: AgroforestryContextT = {
        currentStep,
        pushStep,
        handleBackButton,
        popStep,
        t,
        selectedField,
        setSelectedField,
        treesList,
        treesListLoading,
        fieldLoading,
        farmId,
        farmSeasonId,
        fieldsList,
        thisMap,
    };

    return <AgroforestryContext.Provider value={store}>{children}</AgroforestryContext.Provider>;
};
export default AgroforestryTaskContext;
