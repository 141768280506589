/* eslint-disable @typescript-eslint/no-explicit-any */
import useAsyncSearch from '@hooks/useAsyncSearch';
import UserApiService from '@services/userApiService/userApiService';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';

import SearchPageView from './SearchPageView';
import { authSetSelectedUser } from '@actions/AuthActions/AuthActions';
import { ApiSearchFarmersResponseT, FarmerSearchT, FarmSearchT } from '@services/userApiService/userApiServiceTypes';
import { appInitFarmsAndSeasons } from '@actions/AppActions/AppActions';

const SearchPage = (): JSX.Element => {
    const [selectedFarmer, setSelectedFarmer] = useState<FarmerSearchT | null>(null);
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const user = useSelector((state: AppStateT) => state.auth.user);

    const searchFarmers = async (query: string, signal: AbortSignal): Promise<ApiSearchFarmersResponseT['farmers']> => {
        const response = await UserApiService.searchFarmers({ query, signal });

        return response.data.farmers;
    };

    const onFarmSelect = async ({
        farmSelected,
        userSelected,
    }: {
        farmSelected: FarmSearchT;
        userSelected: FarmerSearchT;
    }) => {
        dispatch(
            authSetSelectedUser({
                userId: userSelected.id,
                fullName: userSelected.full_name,
                model_type: userSelected.farms[0].model_type,
            }),
        );

        if (!farmSelected.season_id) {
            navigate('/subscribe/select-season');
        } else {
            navigate('/');
        }
        await dispatch(appInitFarmsAndSeasons({ userId: userSelected.id }));
    };

    const {
        list: farmers,
        loading: usersLoading,
        setQuery: setUserQuery,
        query: userQuery,
    } = useAsyncSearch((query, signal) => searchFarmers(query, signal), 'search-farmers');

    return (
        <SearchPageView
            farmers={farmers}
            selectedFarmer={selectedFarmer}
            setSelectedFarmer={setSelectedFarmer}
            setUserQuery={setUserQuery}
            userAuth={user}
            userQuery={userQuery}
            usersLoading={usersLoading}
            onFarmSelect={onFarmSelect}
        />
    );
};

export default SearchPage;
