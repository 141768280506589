import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    MachineriesGetCallBackT,
    MachineriesDispatchTypes,
    MACHINERIES_GET_LOADING,
    MachineriesGetSuccessDataT,
    MACHINERIES_GET_SUCCESS,
    MACHINERIES_GET_FAILED,
    MachineriesGetFailedDataT,
} from './MachineryListGetTypes';

const getMachineries =
    (country_id: number): MachineriesGetCallBackT =>
    async (dispatch: Dispatch<MachineriesDispatchTypes>): Promise<MachineriesGetFailedDataT> => {
        try {
            dispatch({ type: MACHINERIES_GET_LOADING });

            const localStorageKey = `machineries${country_id}`;

            const localMachineries = localStorage.getItem(localStorageKey);

            if (!localMachineries || !Array.isArray(JSON.parse(localMachineries))) {
                const res: AxiosResponse<MachineriesGetSuccessDataT> = await StaticApiService.getMachineryList(
                    country_id,
                );

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));

                dispatch({
                    type: MACHINERIES_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: MACHINERIES_GET_SUCCESS,
                    payload: JSON.parse(localMachineries),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: MACHINERIES_GET_FAILED,
                error: error as MachineriesGetFailedDataT,
            });

            return {};
        }
    };

export default getMachineries;
