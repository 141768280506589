import { CountryT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';
import { LivestocksDispatchTypesT } from './LivestocksActionsTypes';
import { TranslationDispatchTypes } from './TranslationsGetActionsTypes';

import { SeasonDispatchTypes } from './seasonGetTypes';
import { LanguagesGetDispatchTypes } from './LanguagesGetActionsTypes';
import { SoilListGetDispatchType } from './SoilGetListActionTypes';
import { PhListGetDispatchType } from './PhGetListActionTypes';
import { DrainageListGetDispatchType } from './DrainageGetListActionTypes';
import { OrganicMatterListGetDispatchType } from './OrganicMatterGetListActionTypes';
import { OrganicCarbonListGetDispatchType } from './OrganicCarbonGetListActionTypes';
import { MoistureListGetDispatchType } from './MoistureGetListActionTypes';
import { TillageListGetDispatchType } from './TillageGetListActionTypes';
import { TillagePracticeListGetDispatchType } from './TillagePracticeGetListActionTypes';
import { CropsGetDispatchTypes } from './CropsGetActionsTypes';
import { CurrenciesDispatchTypes } from './currenciesGetTypes';
import { TreesDispatchTypes } from './TreesGetTypes';
import { IrrigationMethodListGetDispatchType } from './IrrigationMethodGetListActionTypes';
import { IrrigationWaterSourceListGetDispatchType } from './IrrigationWaterSourceGetListActionTypes';
import { IrrigationPowerSourceListGetDispatchType } from './IrrigationPowerSourceGetListActionTypes';
import { PesticidesListGetDispatchType } from './PesticidesGetListActionTypes';
import { MachineriesDispatchTypes } from './MachineryListGetTypes';
import { OrganicFertilizationListGetDispatchType } from './OrganicFertilizationGetListActionTypes';
import { FertilisersGetDispatchTypes } from './FertilisersGetActionTypes';
import { FertilisersCreateDispatchTypes } from './FertilisersCreateActionTypes';

export const COUNTRY_GET_LOADING = 'COUNTRY_GET_LOADING';
export const COUNTRY_GET_SUCCESS = 'COUNTRY_GET_SUCCESS';
export const COUNTRY_GET_FAILED = 'COUNTRY_GET_FAILED';
export const REGION_GET_LOADING = 'REGION_GET_LOADING';
export const REGION_GET_SUCCESS = 'REGION_GET_SUCCESS';
export const REGION_GET_FAILED = 'REGION_GET_FAILED';
export const STATIC_LOAD = 'STATIC_LOAD';

export type Country = {
    id: number;
    name: string;
    iso_code: string;
};

export type Region = {
    id: number;
    name: string;
    country_id?: number;
    iso_code: string;
};

export type CountryGetCallBackT = (dispatch: Dispatch<StaticActionsT>) => Promise<CountryGetResponseFailedT>;

export type RegionGetCallBackT = (dispatch: Dispatch<StaticActionsT>) => Promise<RegionGetResponseFailedT>;

export type CountryGetResponseSuccessT = CountryT[];

export type RegionGetResponseSuccessT = Region[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CountryGetResponseFailedT = any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RegionGetResponseFailedT = any;

export type StaticLoadResponseSuccess = {
    message: string;
    countries: CountryT[];
};

export type CountryGetLoadingT = {
    type: typeof COUNTRY_GET_LOADING;
};

export type RegionGetLoadingT = {
    type: typeof REGION_GET_LOADING;
};

export type CountryGetSuccessT = {
    type: typeof COUNTRY_GET_SUCCESS;
    payload: CountryT[];
};

export type RegionGetSuccessT = {
    type: typeof REGION_GET_SUCCESS;
    payload: Region[];
};

export type CountryGetFailedT = {
    type: typeof COUNTRY_GET_FAILED;
    error: string;
};

export type RegionGetFailedT = {
    type: typeof REGION_GET_FAILED;
    error: string;
};

export type StaticLoad = {
    type: typeof STATIC_LOAD;
    payload: CountryT[];
};

export type StaticActionsT =
    | CountryGetLoadingT
    | CountryGetSuccessT
    | CountryGetFailedT
    | RegionGetLoadingT
    | RegionGetSuccessT
    | RegionGetFailedT
    | StaticLoad
    | SeasonDispatchTypes
    | LivestocksDispatchTypesT
    | TranslationDispatchTypes
    | LanguagesGetDispatchTypes
    | SoilListGetDispatchType
    | PhListGetDispatchType
    | DrainageListGetDispatchType
    | OrganicMatterListGetDispatchType
    | OrganicCarbonListGetDispatchType
    | MoistureListGetDispatchType
    | TillageListGetDispatchType
    | TillagePracticeListGetDispatchType
    | CropsGetDispatchTypes
    | CurrenciesDispatchTypes
    | TreesDispatchTypes
    | IrrigationMethodListGetDispatchType
    | IrrigationWaterSourceListGetDispatchType
    | IrrigationPowerSourceListGetDispatchType
    | PesticidesListGetDispatchType
    | MachineriesDispatchTypes
    | OrganicFertilizationListGetDispatchType
    | FertilisersGetDispatchTypes
    | FertilisersCreateDispatchTypes;
