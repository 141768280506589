import { makeStyles } from 'tss-react/mui';

const useTableHeaderCellStyle = makeStyles()((theme) => ({
    TableHeaderCell: {
        color: theme.palette.secondary.dark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        cursor: 'default',
        textWrap: 'wrap',
    },

    infoIcon: {
        height: '15px',
        width: '15px',
        color: 'inherit',
        marginBottom: '2px',
        opacity: 0.5,
    },

    centerContent: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useTableHeaderCellStyle;
