import { Dispatch } from '@reduxjs/toolkit';
import authService from '@services/authService/authService';
import { AxiosError } from 'axios';

import {
    ResetPasswordActionDataT,
    ResetPasswordDispatchTypesT,
    RESET_PASSWORD_LOADING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    ResetPasswordResponseErrorT,
} from './ResetPasswordActionsTypes';

const resetPassword =
    (
        inputValues: ResetPasswordActionDataT,
        resetCode: string,
    ): any => // eslint-disable-line
    async (dispatch: Dispatch<ResetPasswordDispatchTypesT>): Promise<ResetPasswordResponseErrorT> => {
        try {
            dispatch({
                type: RESET_PASSWORD_LOADING,
            });

            const res = await authService.resetPassword({
                token: resetCode,
                email: inputValues.email,
                password: inputValues.password,
                password_confirmation: inputValues.password_confirmation,
            });

            dispatch({
                type: RESET_PASSWORD_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (e) {
            const error = e as AxiosError<string>;

            const list = error.response?.status !== 200 ? ['password_confirmation'] : [];
            dispatch({
                type: RESET_PASSWORD_FAILED,
                error: {
                    message: error.response?.data || error.message,
                    list,
                },
            });

            return {
                message: error.response?.data || error.message,
                list,
            };
        }
    };

export default resetPassword;
