import { geoJsonFeaturePropertiesT, geoJsonFeatureT } from '@components/Map/MapTypes';
import soilOrganicMatters from '@constants/soilOrganicMatters';
import soilOrganicCarbons from '@constants/soilOrganicCarbons';
import tillages from '@constants/tillages';
import soilAcidities from '@constants/soilAcidities';
import i18n from 'i18next';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

import { Nullable } from './NullableType';
import { Optional } from './OptionalType';
import { FieldsCropColorPaletteT } from '@pages/HomePage/Installation/InstallationTasks/CropsTask/CropsTaskTypes';

const addPolygonData = ({
    field,
    colorPalette,
    withTillage,
    withSoilOrganicMatter,
    withSoilOrganicCarbon,
    withSoilAcidity,
    withColor,
    colorOverride,
}: {
    field: Optional<Nullable<FarmSeasonFieldT, 'soil_acidity_id' | 'soil_organic_matter_id' | 'soil_organic_carbon_id'>, 'id'>;
    colorPalette?: FieldsCropColorPaletteT;
    withTillage?: boolean;
    withSoilOrganicMatter?: boolean;
    withSoilOrganicCarbon?: boolean;
    withSoilAcidity?: boolean;
    withColor?: boolean;
    colorOverride?: string;
}): geoJsonFeatureT => {
    let properties: geoJsonFeaturePropertiesT = {};
    const hasPermanentCrops = Boolean(
        field.field_crops?.some((field_crop) => field_crop.farm_season_crop.crop.is_permanent),
    );

    if (colorPalette && field.field_crops && field.field_crops.length > 0) {
        properties = {
            ...field.polygon?.properties,
            color: colorPalette[field.field_crops[0].farm_season_crop.crop.id],
        };
    }

    if (withColor) {
        properties = {
            ...properties,
            ...field.polygon?.properties,
            color: field.mapLayerColor,
        };
    }

    if (colorOverride) {
        properties = {
            ...properties,
            ...field.polygon?.properties,
            color: colorOverride,
        };
    }

    if (field.field_crops && field.field_crops?.length > 0) {
        properties = {
            ...properties,
            crops: field.field_crops
                .map(
                    (field_crop) =>
                        `▪ ${i18n.t(field_crop.farm_season_crop.crop.translation_slug ?? '')}${
                            field_crop.farm_season_crop.is_accompanied ? ' +' : ''
                        }`,
                )
                .join('\n'),
        };
    }

    if (withTillage && field.tillage_id) {
        properties = {
            ...properties,
            tillagePattern: tillages.find((tillage) => tillage.id === field.tillage_id)?.mapPattern,
        };
    }

    if (withSoilAcidity && field.soil_acidity_id) {
        properties = {
            ...properties,
            soilAcidityPattern: soilAcidities.find((soilAcidity) => soilAcidity.id === field.soil_acidity_id)
                ?.mapPattern,
        };
    }

    if (withSoilOrganicMatter && field.soil_organic_matter_id) {
        properties = {
            ...properties,
            color: soilOrganicMatters[field.soil_organic_matter_id - 1].color,
        };
    }

    if (withSoilOrganicCarbon && field.soil_organic_carbon_id) {
        properties = {
            ...properties,
            color: soilOrganicCarbons[field.soil_organic_carbon_id - 1].color,
        };
    }

    if (field.is_permanent) {
        properties = {
            ...properties,
            color: '#000000',
        };
    }

    if (hasPermanentCrops) {
        properties = {
            ...properties,
            has_permanent_crop: true,
        };
    }

    return {
        ...field.polygon,
        properties: {
            ...field.polygon?.properties,
            ...properties,
        },
    };
};

export default addPolygonData;
