import { makeStyles } from 'tss-react/mui';

const useFarmEditStyle = makeStyles()(() => ({
    root: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    tittle: {
        marginBottom: '10px',
    },
    formGrid: {
        marginBottom: '20px',
    },
}));

export default useFarmEditStyle;
