import { makeStyles } from 'tss-react/mui';

const useHomePageLayoutStyle = makeStyles<{ isFirstMenuItemSelected: boolean; secondaryScreenHeight: string }>()(
    ({ palette, styles }, { isFirstMenuItemSelected, secondaryScreenHeight }) => ({
        mapSection: {
            position: 'relative', // useful for modals overlying only the (secondary/map)Screen
            height: '100%',
            // Map background. (Target the {id}-container in <MapView/>)
            '& #map-container': {
                '& canvas': {
                    right: 0,
                    left: 'auto',
                },
                '& .mapboxgl-ctrl-logo': {
                    marginLeft: '120px',
                },
            },
            display: 'flex',
            flexDirection: 'column',
        },
        mainScreen: {
            ...styles.scrollSection,
            position: 'relative', // useful for modals overlying only the mainScreen
            backgroundColor: palette.spanishWhite.light,
        },
        secondaryScreen: {
            backgroundColor: palette.common.white,
            transition: 'height ease .4s',
            width: '100%',
            height: secondaryScreenHeight,
            position: 'absolute',
            bottom: 0,
            zIndex: 100,
        },
        activeSection: {
            gridArea: 'actionSection',
            borderRadius: isFirstMenuItemSelected ? '0 15px 15px 15px' : '15px 15px 15px 15px',
            overflow: 'hidden',
            backgroundColor: palette.spanishWhite.light,
            zIndex: 0,
        },

        // custom styles for screen type
        mainLeft: {
            minWidth: 'auto',
        },
        mainFull: {
            minWidth: '100%',
        },
    }),
);

export default useHomePageLayoutStyle;
