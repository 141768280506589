import { Divider, Typography } from '@mui/material';

import useDividerTitleStyle from './DividerTitle.style';
import { DividerTitlePropsT } from './DividerTitleTypes';

const DividerTitle = ({ titleTranslated, color }: DividerTitlePropsT): JSX.Element => {
    const { classes } = useDividerTitleStyle({ color });

    return (
        <div className={classes.dividerContainer}>
            <Typography component="h4" variant="h5">
                {titleTranslated}
            </Typography>
            <Divider />
        </div>
    );
};

export default DividerTitle;
