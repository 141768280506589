import { LanguageT } from '@reducers/StaticReducer/StaticReducerType';
import AuthService from '@services/authService/authService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    UserChangeLanguageDispatchTypes,
    UserChangeLanguageFailedDataT,
    UserChangeLanguageSuccessDataT,
    USER_CHANGE_LANGUAGE_FAILED,
    USER_CHANGE_LANGUAGE_LOADING,
    USER_CHANGE_LANGUAGE_SUCCESS,
} from './UserChangeLanguageTypes';

const UserChangeLanguage = (language: LanguageT) => async (dispatch: Dispatch<UserChangeLanguageDispatchTypes>) => {
    try {
        dispatch({ type: USER_CHANGE_LANGUAGE_LOADING });

        const res: AxiosResponse<UserChangeLanguageSuccessDataT> = await AuthService.changeLanguage(language.id || 1);

        dispatch({
            type: USER_CHANGE_LANGUAGE_SUCCESS,
            payload: res.data,
        });
        return { language_id: res.data.language_id };
    } catch (error) {
        dispatch({
            type: USER_CHANGE_LANGUAGE_FAILED,
            error: error as UserChangeLanguageFailedDataT,
        });
        return false;
    }
};

export default UserChangeLanguage;
