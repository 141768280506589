import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { PostFarmSeasonOperationSprayingApiT } from '@services/farmSeasonOperationApiService/farmSeasonOperationApiServiceTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_OPERATION_SPRAYING_LOADING = 'POST_FARM_SEASON_OPERATION_SPRAYING_LOADING';
export const POST_FARM_SEASON_OPERATION_SPRAYING_FAILED = 'POST_FARM_SEASON_OPERATION_SPRAYING_FAILED';
export const POST_FARM_SEASON_OPERATION_SPRAYING_SUCCESS = 'POST_FARM_SEASON_OPERATION_SPRAYING_SUCCESS';

export type PostFarmSeasonOperationSprayingDataT = PostFarmSeasonOperationSprayingApiT;

export type PostFarmSeasonOperationSprayingResponseDataT = {
    fields?: FarmSeasonFieldT[];
};

export type FarmSeasonOperationSprayingPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonOperationSprayingDispatchTypesT>,
) => Promise<PostFarmSeasonOperationSprayingResponseErrorT>;

export type PostFarmSeasonOperationSprayingResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonOperationSprayingLoadingT = {
    type: typeof POST_FARM_SEASON_OPERATION_SPRAYING_LOADING;
};

export type PostFarmSeasonOperationSprayingFailedT = {
    type: typeof POST_FARM_SEASON_OPERATION_SPRAYING_FAILED;
    error: PostFarmSeasonOperationSprayingResponseErrorT;
};

export type PostFarmSeasonOperationSprayingSuccessT = {
    type: typeof POST_FARM_SEASON_OPERATION_SPRAYING_SUCCESS;
    payload: PostFarmSeasonOperationSprayingResponseDataT;
};

export type PostFarmSeasonOperationSprayingDispatchTypesT =
    | PostFarmSeasonOperationSprayingLoadingT
    | PostFarmSeasonOperationSprayingFailedT
    | PostFarmSeasonOperationSprayingSuccessT;
