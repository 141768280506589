import { Grid } from '@mui/material';
import AppRoutes from '@components/AppRoutes/AppRoutes';
import LoadingSection from '@components/LoadingSection/LoadingSection';
import MapSection from '@pages/HomePage/components/MapSection/MapSection';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import MapService from '@services/mapService/mapService';
import GuardedRoute from '@/utils/GuardedRoute';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { useHomePageRouterScreen, useHomePageScreenTypes } from './HomePageLayout.logic';
import useHomePageLayoutStyle from './HomePageLayout.style';
import { HomePageLayoutPropsT } from './HomePageLayoutTypes';
import { AppStateT } from '@store/store';

const HomePageLayout = ({ homePageRouterConfig, loading }: HomePageLayoutPropsT): JSX.Element => {
    const isFirstMenuItemSelected = useSelector((state: HomePageStateT) => state.app.isFirstMenuItemSelected);
    const { pathname } = useLocation();

    const { mainRoute, secondaryRoute } = useHomePageRouterScreen({ homePageRouterConfig, pathname });
    const { mainType, secondaryType } = useHomePageScreenTypes({
        mainRoute,
        secondaryRoute,
    });

    // secondaryScreenHeight must start with 0. Else the map canvas height is not well initialized.
    const [secondaryScreenHeight, setSecondaryScreenHeight] = useState('0');
    useEffect(() => {
        if (secondaryType === 'rightPartial') {
            setSecondaryScreenHeight('70%');
            MapService.setupOffset({ offset: { x: 120, y: (window.innerHeight * 65) / 100 } });
        } else if (secondaryType === 'right') {
            setSecondaryScreenHeight('100%');
        } else {
            MapService.setupOffset({ offset: { x: 120, y: 80 } });
            setSecondaryScreenHeight('0');
        }
    }, [secondaryType]);
    const { classes } = useHomePageLayoutStyle({ isFirstMenuItemSelected, secondaryScreenHeight });

    const mapDataLoaded = useSelector(
        (state: AppStateT) => state.farmSeasonField.loaded && state.farmSeasonCrop.loaded,
    );

    return (
        <Grid className={classes.activeSection} container>
            <LoadingSection loading={loading} contentDisplay="flex">
                <Grid
                    className={`${
                        mainType === 'full' ? classes.mainFull : mainType === 'left' ? classes.mainLeft : ''
                    } ${classes.mainScreen}`}
                    lg={4}
                    xl={3}
                    xs={5}
                    item
                >
                    <AppRoutes>
                        {mainRoute && (
                            <Route
                                path={
                                    secondaryRoute?.path ? `${mainRoute.path}/${secondaryRoute.path}` : mainRoute.path
                                }
                                element={<GuardedRoute element={mainRoute?.element} guards={mainRoute?.guards} />}
                            />
                        )}
                    </AppRoutes>
                </Grid>
                <Grid className={classes.mapSection} lg={8} xl={9} xs={7} item>
                    {mapDataLoaded && (
                        <MapSection
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                right: 0,
                            }}
                        />
                    )}
                    <div className={`${classes.secondaryScreen}`}>
                        <AppRoutes>
                            {secondaryRoute && (
                                <Route
                                    path={
                                        mainRoute?.path
                                            ? `${mainRoute.path}/${secondaryRoute.path}`
                                            : secondaryRoute.path
                                    }
                                    element={
                                        <GuardedRoute
                                            element={secondaryRoute?.element}
                                            guards={secondaryRoute?.guards}
                                        />
                                    }
                                />
                            )}
                        </AppRoutes>
                    </div>
                </Grid>
            </LoadingSection>
        </Grid>
    );
};

export default HomePageLayout;
