import { useEffect, useState } from 'react';
import useProgressHeaderStyle from '@components/ProgressHeader/ProgressHeader.style';
import { ProgressHeaderViewPropsT } from '@components/ProgressHeader/ProgressHeaderTypes';
import { LinearProgress, Typography } from '@mui/material';
import MenuBar from '@components/MenuBar/MenuBar';

const ProgressCircleView = ({
    image,
    percentage = 0,
    progressTitle,
    withMenu,
    withProgress,
    menuTitle,
    onArrowBack,
    onClose,
    className,
    withAnimation,
}: ProgressHeaderViewPropsT): JSX.Element => {
    const { classes } = useProgressHeaderStyle();
    const [pct, setPct] = useState(withAnimation ? 0 : percentage);
    const [opacity, setOpacity] = useState(withAnimation ? 0 : 1);
    const [imgY, setImgY] = useState(withAnimation ? 20 : 0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPct(percentage);
            setOpacity(1);
            setImgY(0);
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`${classes.progressMain} ${className}`}>
            <div className={classes.image} style={{ opacity }}>
                <img alt="progress" src={image} style={{ transform: `translateY(${imgY}px)` }} />
            </div>
            {withProgress && (
                <div className={classes.barContainer}>
                    <LinearProgress
                        className={classes.bar}
                        color="success"
                        style={{ opacity }}
                        value={pct}
                        variant="determinate"
                    />
                    <div className={classes.progressText}>
                        <Typography>{progressTitle}</Typography>
                    </div>
                </div>
            )}
            {withMenu && (
                <div className={classes.menuBarContainer}>
                    <MenuBar
                        className={classes.menuBar}
                        menuTitle={menuTitle}
                        onArrowBack={onArrowBack}
                        onClose={onClose}
                    />
                </div>
            )}
        </div>
    );
};

export default ProgressCircleView;
