import authService from '@services/authService/authService';
import { UserT } from '@store/reducers/AuthReducer/AuthReducerTypes';
import { AxiosError } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { AppActionsT } from '../AppActions/AppActionsTypes';
import { GET_USER_FAILED, GET_USER_LOADING, GET_USER_SUCCESS } from './GetUserActionsTypes';
import { USER_ADD_AMBASSADOR_SUCCESS } from './UserAddAmbassadorTypes';

const getUser =
    () =>
    async (dispatch: Dispatch<AppActionsT>): Promise<UserT | false> => {
        try {
            dispatch({ type: GET_USER_LOADING });

            const res = await authService.getUser();

            dispatch({
                type: GET_USER_SUCCESS,
                payload: {
                    user: res.data.user,
                    farmer_infos: res.data.farmer_infos,
                    ambassador_referral_code: res.data.ambassador_referral_code,
                },
            });

            dispatch({
                type: USER_ADD_AMBASSADOR_SUCCESS,
                payload: {
                    message: 'Ambassador saved in redux',
                    ambassador_referral_code: res.data.ambassador_referral_code,
                },
            });

            return res.data.user;
        } catch (e) {
            const error = e as AxiosError<string>;

            const list = error.response?.status === 401 ? ['email', 'password'] : [];

            dispatch({
                type: GET_USER_FAILED,
                error: {
                    message: error.response?.data || error.message,
                    list,
                },
            });

            return false;
        }
    };

export default getUser;
