import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useIconActionStyle = makeStyles<{ disabled: boolean }>()((theme, { disabled }) => ({
    icon: {
        padding: '5px',
        '&:hover': {
            background: theme.palette.secondaryWhiteCloud.main,
        },
        '& svg': {
            fill: theme.palette.primary.light,

            ...(disabled && {
                fill: alpha(theme.palette.primary.main, 0.3),
            }),
        },
    },
}));

export default useIconActionStyle;
