import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import _ from 'lodash';

/** get {id} array of crops from field_crops without duplicates. */
const getUniqueCrops = (fieldsArray: FarmSeasonFieldT[]): { id: number }[] => {
    const result = _.uniqBy(
        fieldsArray
            ?.map((field) => field.field_crops?.map((field_crop) => ({ id: field_crop.farm_season_crop?.crop?.id })))
            .flat(),
        (elem) => JSON.stringify(elem),
    );

    return _.compact(result);
};

export default getUniqueCrops;
