/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { FieldCropsDataT, RelationsDndModalPropsT } from './RelationsDndModalTypes';
import RelationsDndModalView from './RelationsDndModalView';

const RelationsDndModal = ({ className, field, onConfirm, onClose }: RelationsDndModalPropsT): JSX.Element => {
    const [stepArray, setStepArray] = useState([1]);
    const [currentStep, setCurrentStep] = useState(stepArray[stepArray.length - 1]);

    useEffect(() => {
        setCurrentStep(stepArray[stepArray.length - 1]);
    }, [stepArray]);

    const pushStep = (step: any) => {
        setStepArray((state) => [...state, step]);
    };

    const popStep = () => {
        setStepArray((state) => [...state.splice(0, state.length - 1)]);
    };

    const validationSchema = yup.object().shape({
        field_crops: yup.array().required('test'),
    });

    const formik = useFormik({
        initialValues: {
            field_crops: field?.field_crops && field.field_crops.length > 0 ? field?.field_crops : [],
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: FieldCropsDataT, { setSubmitting }) => {
            onConfirm(values.field_crops);
            setSubmitting(false);
        },
    });

    return (
        <RelationsDndModalView
            className={className}
            currentStep={currentStep}
            popStep={popStep}
            pushStep={pushStep}
            state={{
                fieldCrops: formik.values.field_crops,
                setFieldCrops: (field_crops) => formik.setValues({ field_crops }),
                fieldName: field?.name,
            }}
            onClose={onClose}
            onSubmit={() => formik.submitForm()}
        />
    );
};

export default RelationsDndModal;
