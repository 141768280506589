import { makeStyles } from 'tss-react/mui';

const useUpsellActionsStyle = makeStyles()(() => ({
    actionButton: {
        marginRight: '8px',
    },
    gridContainer: {
        marginBottom: '16px',
    },
    // Allows using "\n" in strings
    preline: {
        whiteSpace: 'pre-line',
    },
    divider: {
        marginBottom: '16px',
    },
    actionBar: {
        marginTop: '16px',
    },
}));

export default useUpsellActionsStyle;
