import { useTranslation } from 'react-i18next';
import { ModalNewSeasonStartedPropsT } from './ModalNewSeasonStartedTypes';
import LoadingButton from '../LoadingButton/LoadingButton';
import useModalNewSeasonStartedLogic from './ModalNewSeasonStarted.logic';
import ModalWizzardLayout from '@/layouts/ModalWizzardLayout/ModalWizzardLayout';
import { ModalBase } from '@soil-capital/ui-kit';
import LoadingView from '../Loading/LoadingView';
import useModalNewSeasonStartedStyle from './ModalNewSeasonStarted.style';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import selectCurrentSeason from '@/store/selectors/selectCurrentSeason';

/** @require payment_status=canceled|success|done as url query param */
const ModalNewSeasonStarted = ({
    controller,
    height,
    width,
    onClickActionCanceled,
    onClickActionSuccess,
}: ModalNewSeasonStartedPropsT): JSX.Element => {
    const { t } = useTranslation();
    const {
        buttonActionSuccessLoading,
        handleClickActionSuccess,
        openNextFarmSeasonLoading: openNextFarmSeasonLoading,
    } = useModalNewSeasonStartedLogic({
        onClickActionCanceled,
        onClickActionSuccess,
        controller,
    });
    const { classes } = useModalNewSeasonStartedStyle();
    const currentSeason = useSelector(selectCurrentSeason());
    const currentYear = currentSeason?.harvest_year;
    const nextYear = currentYear ? currentYear + 1 : undefined;

    return (
        <ModalBase controller={controller} height={height} width={width} showCloseButton>
            <ModalWizzardLayout
                StatusZoneComponent={
                    openNextFarmSeasonLoading ? (
                        <div className={classes.PaymentStatusIcon}>{<LoadingView />}</div>
                    ) : (
                        <div className={classes.PaymentStatusIcon}>
                            <CheckCircleIcon className={classes.iconDropShadow} color="inherit" />
                        </div>
                    )
                }
                title={t('components.modal-new-season-started.title-success')}
                BodyZoneComponent={t('components.modal-new-season-started.description-success', {
                    currentYear,
                    nextYear,
                })}
                FooterZoneComponent={
                    <LoadingButton
                        disabled={openNextFarmSeasonLoading}
                        loading={buttonActionSuccessLoading}
                        onClick={() => handleClickActionSuccess()}
                        text={t('components.modal-new-season-started.button-action-success')}
                    />
                }
            />
        </ModalBase>
    );
};

export default ModalNewSeasonStarted;
