import { makeStyles } from 'tss-react/mui';

const useConfirmTaskViewStyle = makeStyles()(() => ({
    container: {
        padding: '0 30px 30px 30px',
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        justifyContent: 'center',
    },
    description: {
        marginBottom: '20px',
    },
    listContainer: {
        marginBottom: '20px',
    },
    menuBar: {
        marginTop: '20px',
        marginBottom: '40px',
    },
}));

export default useConfirmTaskViewStyle;
