import useAppDispatch from '@hooks/useAppDispatch';
import { appSetModalWarningProps } from '@store/actions/AppActions/AppActions';
import { AppSetModalWarningProps } from '@store/actions/AppActions/AppActionsTypes';
import { AppStateT } from '@store/store';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useModalWarningApp = () => {
    const dispatch = useAppDispatch();
    const modalWarningAppController = useSelector((state: AppStateT) => state.app.modalWarningController);

    const openModalWarningApp = useCallback(
        (modalWarningProps: AppSetModalWarningProps['payload']['modalWarningProps']) => {
            if (!modalWarningAppController) {
                console.warn("the 'ModalWarningApp' has not been initialized with the controller");
            }
            dispatch(appSetModalWarningProps({ modalWarningProps }));
            const promiseResponse = new Promise<{ rightButtonClicked: boolean }>((res) => {
                modalWarningAppController?.openModal(
                    () => res({ rightButtonClicked: true }),
                    () => res({ rightButtonClicked: false }),
                );
            });

            return promiseResponse;
        },
        [dispatch, modalWarningAppController],
    );

    return { openModalWarningApp };
};

export default useModalWarningApp;
