import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

const uploadCapFiles = (farmId: number, farmSeasonId: number, files: File[]) => {
    const promises: Promise<AxiosResponse<string>>[] = [];

    files.forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);

        const promise = request<string>(
            {
                url: `farm/${farmId}/season/${farmSeasonId}/capfiles/upload`,
                method: 'POST',
                data: formData,
            },
            false,
        );
        promises.push(promise);
    });

    return Promise.all(promises);
};

export default {
    uploadCapFiles,
};
