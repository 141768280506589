import { changeValueParams, YieldStepPropsT } from './YieldStepTypes';
import YieldStepView from './YieldStepView';

const YieldStep = ({ state, onSubmit }: YieldStepPropsT): JSX.Element => {
    const getUniqueFieldCrops = () => {
        const newFieldCropsArr = [
            ...(state?.fieldCrops || []).filter((fieldCrop) => {
                if (fieldCrop.coupled_id === null) {
                    return false;
                }

                return true;
            }),
        ];

        return newFieldCropsArr;
    };

    const changeValue = ({ order, value }: changeValueParams) => {
        state?.setFieldCrops([
            ...(state?.fieldCrops || []).map((fieldCrop) => {
                if (fieldCrop.order === order) {
                    return {
                        ...fieldCrop,
                        yield: value.toString(),
                    };
                }

                return {
                    ...fieldCrop,
                };
            }),
        ]);
    };

    return (
        <YieldStepView
            changeValue={changeValue}
            fieldName={state?.fieldName}
            uniqueFieldCrops={getUniqueFieldCrops()}
            onSubmit={onSubmit}
        />
    );
};

export default YieldStep;
