import { makeStyles } from 'tss-react/mui';

const usePaymentTableStyle = makeStyles()(() => ({
    PaymentTable: {
        '& .MuiTableCell-sizeSmall': {
            padding: '10px 6px',
        },
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
}));

export default usePaymentTableStyle;
