import MenuBar from '@components/MenuBar/MenuBar';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import useAppNavigate from '@hooks/useAppNavigate';
import { getIn } from 'formik';

import useConfirmTaskViewStyle from './ConfirmTaskView.style';
import { ConfirmTaskViewPropsT } from './ConfirmTaskTypes';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';

const ConfirmTaskView = ({ formik, loading }: ConfirmTaskViewPropsT): JSX.Element => {
    const { classes } = useConfirmTaskViewStyle();
    const { t } = useTranslation();
    const navigate = useAppNavigate();

    return (
        <div className={classes.container}>
            <div className={classes.wrapper} id="installationSubtasksPageWrapper">
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={t('pages.installationtasks.confirm-task.menu-title')}
                    onArrowBack=".."
                    onClose=".."
                />
            </div>
            <Typography className={classes.description} color="secondary" variant="h5">
                {Object.keys(formik.values.tasks).length > 0
                    ? t('pages.installationtasks.confirm-task.need-confirm-description')
                    : t('pages.installationtasks.confirm-task.no-need-description')}
            </Typography>
            {Object.keys(formik.values.tasks).length > 0 && (
                <div className={classes.listContainer}>
                    {Object.keys(formik.values.tasks).map((key) => {
                        const keyName = `tasks.${key}`;
                        const touchedValue = getIn(formik.touched, keyName);
                        const errorValue = getIn(formik.errors, keyName);

                        return (
                            <LabelCheckboxView
                                key={key}
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                checked={(formik.values.tasks as any)[key]}
                                error={Boolean(touchedValue && errorValue)}
                                helperText={touchedValue && errorValue ? errorValue : ''}
                                label={t(`pages.installationtasks.confirm-task.label-${key}`)}
                                noMargin
                                onChange={(event) => formik.setFieldValue(keyName, event.target.checked)}
                            />
                        );
                    })}
                </div>
            )}
            <TwoButtonFooterView
                leftOnClick={() => navigate('/')}
                leftText={t('constants.cancel')}
                rightButtonOverride={
                    <RestrictSaveFarmSeason>
                        <LoadingButton
                            loading={loading}
                            text={t('constants.validate')}
                            onClick={() => formik.submitForm()}
                        />
                    </RestrictSaveFarmSeason>
                }
            />
        </div>
    );
};

export default ConfirmTaskView;
