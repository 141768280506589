import { ErrorActionAdd } from '@actions/ErrorActions/ErrorActions';
import logout from '@store/actions/AuthActions/LogoutActions';
import store from '@store/store';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import i18n from 'i18next';

import { ErrorT } from './apiServiceTypes';

export const client: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

/**
 *
 * @param options Axios Request Config (method, url, data, ...)
 * @param displayMutationError display snackbar if server return error for methods 'DELETE', 'POST', 'PUT', 'PATCH'.
 * Default true (always true for status 500)
 * @returns
 */
// eslint-disable-next-line
const request = async <R>(options: AxiosRequestConfig, displayMutationError = true): Promise<AxiosResponse<R>> => {
    const onSuccess = (response: AxiosResponse<R>): AxiosResponse<R> => response;
    options.withCredentials = true;

    const onError = (error: ErrorT): never => {
        console.error('Request failed:', error.config);

        if (error.response) {
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            // log message if it wasn't based on the response
            console.error('Error Message:', error.message);
        }

        if (
            Number(error?.response?.status) === 401 &&
            (localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken'))
        ) {
            store.dispatch(logout());
            store.dispatch(
                ErrorActionAdd({
                    message: i18n.t('errors.unauthenticated'),
                    errorType: 'server',
                    errorId: `${options.method}:${options.url}:${error?.response.status}`,
                }),
            );
        } else if (
            (displayMutationError || Number(error?.response.status) === 500) &&
            ['DELETE', 'POST', 'PUT', 'PATCH'].includes(options.method ?? '')
        ) {
            store.dispatch(
                ErrorActionAdd({
                    message: i18n.t('constants.server-error'),
                    errorType: 'server',
                    errorId: `${options.method}:${options.url}`,
                }),
            );
        }

        throw error;
    };

    const response = await client(options).then(onSuccess).catch(onError);

    return response;
};

export default request;
