import { Tooltip, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { TooltipScPropsT } from './TooltipScTypes';

/**
 * Tooltip customizable. We can use translation slug that contains bold tag <b></b> and italic tag <i></i>. translation slug who have " " text doesn't display any tooltip.
 */
const TooltipSc = ({
    children,
    translationSlug,
    placement,
    display = 'inline-block',
    PopperProps,
    open,
    className,
    iconSpanStyle
}: TooltipScPropsT): JSX.Element => {
    const { t } = useTranslation();
    const translatedText = t(translationSlug || '');

    return translatedText.trim() ? (
        <Tooltip
            PopperProps={PopperProps}
            open={open}
            placement={placement || 'top'}
            title={
                <Typography color="common.white" variant="body1">
                    <Trans
                        components={{ b: <b />, i: <i style={{ fontStyle: 'italic' }} /> }}
                        defaults={translatedText}
                    />
                </Typography>
            }
            arrow
            className={className}
        >
            <span style={{ display, ...iconSpanStyle }}>{children}</span>
        </Tooltip>
    ) : (
        <>{children}</>
    );
};

export default TooltipSc;
