const formatedIban = (iban: string): string => {
    return iban
        .split('')
        .map((value, index) => {
            if ((index + 1) % 4 === 0) {
                return value + ' ';
            }
            return value;
        })
        .join('');
};

export default formatedIban;
