import { ConfirmNotEligibleFarmSeasonLivestocksInstallationDispatchTypesT } from './ConfirmNotEligibleFarmSeasonLivestocksInstallationTypes';
import { DeleteFarmSeasonLivestocksDispatchTypesT } from './DeleteFarmSeasonLivestocksTypes';
import { GetFarmSeasonLivestocksDispatchTypesT } from './GetFarmSeasonLivestocksTypes';
import { PostFarmSeasonLivestocksInstallationDispatchTypesT } from './PostFarmSeasonLivestocksInstallationTypes';
import { PostFarmSeasonLivestocksSubscriptionDispatchTypesT } from './PostFarmSeasonLivestocksSubscriptionTypes';

/* ----------------------------- Actions Types ------------------------------ */
export const RESET_FARM_SEASON_LIVESTOCK = 'RESET_FARM_SEASON_LIVESTOCK';

export type ResetFarmSeasonLivestockActionT = {
    type: typeof RESET_FARM_SEASON_LIVESTOCK;
};

export type FarmSeasonLivestockActionsT =
    | GetFarmSeasonLivestocksDispatchTypesT
    | PostFarmSeasonLivestocksInstallationDispatchTypesT
    | PostFarmSeasonLivestocksSubscriptionDispatchTypesT
    | DeleteFarmSeasonLivestocksDispatchTypesT
    | ConfirmNotEligibleFarmSeasonLivestocksInstallationDispatchTypesT
    | ResetFarmSeasonLivestockActionT;
