import CollapsableSectionView from '@components/CollapsableSection/CollapsableSectionView';
import DatePicker from '@components/DatePicker/DatePicker';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import useHistoryMaxYear from '@hooks/useHistoryMaxYear';
import { Collapse, Grid } from '@mui/material';
import { useContext, useRef } from 'react';

import { FieldsContextStore } from '../../FieldsContext/FieldsContext';
import { FieldsContextStoreT } from '../../FieldsContext/FieldsContextTypes';

import useCoverCropHistoryStyle from './CoverCropHistoryView.style';
import { CoverCropHistoryViewPropsT } from './CoverCropHistoryViewTypes';

const CoverCropHistoryView = ({ formik, disabledForm }: CoverCropHistoryViewPropsT): JSX.Element => {
    const { classes } = useCoverCropHistoryStyle();
    const { t } = useContext<FieldsContextStoreT>(FieldsContextStore);
    const sectionRef = useRef<HTMLDivElement>(null);

    const historyMaxYear = useHistoryMaxYear();

    return (
        <CollapsableSectionView
            defaultCollapseState={true}
            helpName="pages.installationtasks.history.cover-crop.history-form.title"
            sectionName={t('pages.installationtasks.history.cover-crops-history')}
            sectionRef={sectionRef}
        >
            <>
                <Grid className={classes.gridRow} spacing={2} container>
                    <Grid xs={12} item>
                        <LabelCheckboxView
                            disabled={disabledForm}
                            checked={!!formik.values.cover_crop_history?.has_cover_crop_history}
                            error={
                                formik.touched.cover_crop_history?.has_cover_crop_history &&
                                !!formik.errors.cover_crop_history?.has_cover_crop_history
                            }
                            helperText={
                                formik.touched.cover_crop_history?.has_cover_crop_history
                                    ? formik.errors.cover_crop_history?.has_cover_crop_history
                                    : undefined
                            }
                            label={t('pages.fields.edit-field.this-field-has-cover-crop-history')}
                            noMargin
                            onChange={(event) => {
                                formik.setFieldValue('cover_crop_history.has_cover_crop_history', event.target.checked);
                                event.target.checked &&
                                    setTimeout(() => sectionRef.current?.scrollIntoView({ behavior: 'smooth' }), 400);
                            }}
                        />
                    </Grid>
                </Grid>
                <Collapse in={formik.values.cover_crop_history?.has_cover_crop_history} timeout="auto" unmountOnExit>
                    <Grid className={classes.gridRow} spacing={2} container>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <DatePicker
                                disabled={disabledForm}
                                error={
                                    formik.touched.cover_crop_history?.year_introduction &&
                                    !!formik.errors.cover_crop_history?.year_introduction
                                }
                                helperText={
                                    formik.touched.cover_crop_history?.year_introduction &&
                                    formik.errors.cover_crop_history?.year_introduction
                                }
                                labelText={t('constants.year-of-introduction')}
                                maxDate={historyMaxYear}
                                openTo="year"
                                value={
                                    formik.values.cover_crop_history?.year_introduction === ''
                                        ? ''
                                        : new Date(formik.values.cover_crop_history?.year_introduction)
                                }
                                onChange={(event) => {
                                    const year = new Date(event).getFullYear().toString();
                                    formik.setFieldValue('cover_crop_history.year_introduction', year);
                                    setTimeout(() =>
                                        formik.setFieldTouched('cover_crop_history.year_introduction', true),
                                    );
                                }}
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} lg={3} item>
                            <LabelTextField
                                disabled={disabledForm}
                                endAdornment={t('constants.year', {
                                    count: +formik.values.cover_crop_history?.frequency
                                        ? +formik.values.cover_crop_history?.frequency
                                        : 0,
                                })}
                                error={
                                    formik.touched.cover_crop_history?.frequency &&
                                    !!formik.errors.cover_crop_history?.frequency
                                }
                                helpName="frequency-help"
                                helperText={
                                    formik.touched.cover_crop_history?.frequency &&
                                    formik.errors.cover_crop_history?.frequency
                                }
                                id="cover_crop_history.frequency"
                                label={t('constants.frequency')}
                                name="cover_crop_history.frequency"
                                type="number"
                                value={
                                    formik.values.cover_crop_history?.frequency
                                        ? formik.values.cover_crop_history?.frequency
                                        : ''
                                }
                                variant="outlined"
                                fullWidth
                                required
                                withHelp
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
            </>
        </CollapsableSectionView>
    );
};

export default CoverCropHistoryView;
