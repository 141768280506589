import { PRICES } from '@constants/prices.constants';
import { PRODUCT_PLAN_ID } from '@constants/productPlan';

export type GetMinCertificatesEarningAmountPropsT = {
    certificatesCount: number;
    previousProductPlanId: PRODUCT_PLAN_ID;
    currencyIso: 'EUR' | 'GBP';
};

const getMinCertificatesEarningAmount = ({
    certificatesCount,
    previousProductPlanId,
    currencyIso,
}: GetMinCertificatesEarningAmountPropsT): number => {
    switch (true) {
        case currencyIso === 'EUR' && previousProductPlanId === PRODUCT_PLAN_ID.BASIC:
            return PRICES.MIN_PRICE_EUR_BASIC * certificatesCount;
        case currencyIso === 'GBP' && previousProductPlanId === PRODUCT_PLAN_ID.BASIC:
            return PRICES.MIN_PRICE_GBP_BASIC * certificatesCount;
        case currencyIso === 'EUR' && previousProductPlanId === PRODUCT_PLAN_ID.STANDARD:
            return PRICES.MIN_PRICE_EUR_STANDARD * certificatesCount;
        case currencyIso === 'GBP' && previousProductPlanId === PRODUCT_PLAN_ID.STANDARD:
            return PRICES.MIN_PRICE_GBP_STANDARD * certificatesCount;
        case currencyIso === null || currencyIso === undefined:
            return 0;
    }
    throw Error(`no price available for previousProductPlanId: ${previousProductPlanId}, currencyIso: ${currencyIso}`);
};
export default getMinCertificatesEarningAmount;
