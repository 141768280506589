import { Dispatch } from '@reduxjs/toolkit';

export const FARM_SEASON_SET_CURRENT = 'FARM_SEASON_SET_CURRENT';

export type FarmSeasonSetCurrentT = {
    type: typeof FARM_SEASON_SET_CURRENT;
    payload: { farmSeasonId?: number };
};

export type FarmSeasonSetCurrentDispatchTypes = FarmSeasonSetCurrentT;

export type FarmSeasonSetLatestAsCurrentAction = (
    dispatch: Dispatch<FarmSeasonSetCurrentDispatchTypes>,
) => Promise<void>;
