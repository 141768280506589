import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

export const FARM_SEASON_FINALIZE_FAILED = 'FARM_SEASON_FINALIZE_FAILED';
export const FARM_SEASON_FINALIZE_LOADING = 'FARM_SEASON_FINALIZE_LOADING';
export const FARM_SEASON_FINALIZE_SUCCESS = 'FARM_SEASON_FINALIZE_SUCCESS';

export type FarmSeasonFinalizeFailedDataT = {
    message?: string;
};

export type FarmSeasonFinalizeLoadingT = {
    type: typeof FARM_SEASON_FINALIZE_LOADING;
};
export type FarmSeasonFinalizeSuccessT = {
    type: typeof FARM_SEASON_FINALIZE_SUCCESS;
    payload: FarmSeasonT;
};
export type FarmSeasonFinalizeFailedT = {
    type: typeof FARM_SEASON_FINALIZE_FAILED;
    error: FarmSeasonFinalizeFailedDataT;
};

export type FarmSeasonFinalizeDispatchTypesT =
    | FarmSeasonFinalizeLoadingT
    | FarmSeasonFinalizeSuccessT
    | FarmSeasonFinalizeFailedT;
