import { makeStyles } from 'tss-react/mui';

const useOnboardingProgressBarStyle = makeStyles<{ height?: string }>()((theme, { height }) => ({
    root: {
        height: height || '6px',
        width: '100%',
    },
    barColorPrimary: {
        backgroundColor: theme.palette.primary.light,
    },
    colorPrimary: {
        backgroundColor: theme.palette.linkWater.light,
    },
}));

export default useOnboardingProgressBarStyle;
