import selectCurrentSeason from '@store/selectors/selectCurrentSeason';
import selectLatestFarmSeason from '@store/selectors/selectLatestFarmSeason';
import { useSelector } from 'react-redux';

const canOpenNewSeasonCheck = (): boolean => {
    const currentSeason = useSelector(selectCurrentSeason());
    const latestFarmSeason = useSelector(selectLatestFarmSeason());

    return Boolean(
        currentSeason &&
            latestFarmSeason &&
            currentSeason?.id > latestFarmSeason.season_id &&
            !!latestFarmSeason.results_validated_by_agronomist,
    );
};
export default canOpenNewSeasonCheck;
