const palette = [
    '#c17542',
    '#9f3e6c',
    '#acb839',
    '#86321a',
    '#d1a362',
    '#64c276',
    '#796d21',
    '#dd5984',
    '#43c29e',
    '#89307c',
    '#c688d0',
    '#afa746',
    '#36dee6',
    '#3b7025',
    '#46b57c',
    '#757ee8',
    '#d960a7',
    '#6d4fb5',
    '#6098e3',
    '#a23a4a',
    '#c8873a',
    '#c4552e',
    '#d1972c',
    '#a2b864',
    '#6269b7',
    '#c86ccd',
    '#6fb24b',
    '#492f7e',
    '#dc7a67',
    '#d64c54',
];

export default palette;
