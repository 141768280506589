import farmSeasonGetAll from '@actions/FarmSeasonActions/FarmSeasonGetAll';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

import { FarmSelectorPropsT } from './FarmSelectorTypes';
import FarmSelectorView from './FarmSelectorView';
import { farmSeasonSetLatestAsCurrent } from '@actions/FarmSeasonActions/FarmSeasonSetCurrent';
import { farmSetCurrent } from '@actions/FarmActions/FarmActions';
import { routingRedirect } from '@actions/RoutingActions/RoutingActions';

const FarmSelector = ({ className }: FarmSelectorPropsT): JSX.Element => {
    const dispatch = useAppDispatch();
    const onChange: SelectInputProps<string>['onChange'] = async (event) => {
        const farmId = +event.target.value;
        dispatch(farmSetCurrent({ farmId }));
        await dispatch(farmSeasonGetAll(farmId));
        dispatch(farmSeasonSetLatestAsCurrent());
        dispatch(routingRedirect({ route: '/' }));
    };

    const farmList = useSelector((state: HomePageStateT) => state.farm.farmList || []);
    const currentValue = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id || '');

    const stringifiedFarmList = farmList.map((farm) => ({ ...farm, id: farm.id.toString() }));

    return (
        <FarmSelectorView
            className={className}
            currentValue={currentValue.toString()}
            farmList={stringifiedFarmList}
            onChange={onChange}
        />
    );
};

export default FarmSelector;
