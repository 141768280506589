import { AxiosResponse } from 'axios';

export const HAS_PAID_SECOND_LOADING = 'HAS_PAID_SECOND_LOADING';
export const HAS_PAID_SECOND_FAILED = 'HAS_PAID_SECOND_FAILED';
export const HAS_PAID_SECOND_SUCCESS = 'HAS_PAID_SECOND_SUCCESS';

export type HasPaidSecondResponseDataT = {
    has_results_access: boolean;
};

export type HasPaidSecondPayloadT = {
    data: HasPaidSecondResponseDataT;
} & AxiosResponse;

export type HasPaidSecondResponseErrorT = {
    message?: string;
    list?: string[];
};

export type HasPaidSecondLoadingT = {
    type: typeof HAS_PAID_SECOND_LOADING;
};

export type HasPaidSecondFailedT = {
    type: typeof HAS_PAID_SECOND_FAILED;
    error: HasPaidSecondResponseErrorT;
};

export type HasPaidSecondSuccessT = {
    type: typeof HAS_PAID_SECOND_SUCCESS;
    payload: HasPaidSecondResponseDataT;
};

export type HasPaidSecondDispatchTypesT = HasPaidSecondLoadingT | HasPaidSecondFailedT | HasPaidSecondSuccessT;
