import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    PhListGetCallback,
    PhListGetDispatchType,
    PhListGetSuccessDataT,
    PH_LIST_GET_FAILED,
    PH_LIST_GET_LOADING,
    PH_LIST_GET_SUCCESS,
} from './PhGetListActionTypes';

const localStorageKey = 'pHs';

const pHGetListAction =
    (): PhListGetCallback =>
    async (dispatch: Dispatch<PhListGetDispatchType>): Promise<boolean> => {
        try {
            dispatch({
                type: PH_LIST_GET_LOADING,
            });

            const pHs = localStorage.getItem(localStorageKey);

            if (!pHs) {
                const res: AxiosResponse<PhListGetSuccessDataT> = await StaticApiService.getSoilAcidityList();

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));
                dispatch({
                    type: PH_LIST_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: PH_LIST_GET_SUCCESS,
                    payload: JSON.parse(pHs),
                });
            }

            return true;
        } catch (error) {
            dispatch({
                type: PH_LIST_GET_FAILED,
                error: error as string,
            });

            return false;
        }
    };

export default pHGetListAction;
