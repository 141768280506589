import { getUpdateQueryHelper } from '@api/api2.common';
import { CropEndpointParamsT, CropT } from './cropEntity.types';
import { api2 } from '@api/api2.service';
import { createEntityAdapter } from '@reduxjs/toolkit';
import fieldCropEntityCache from '../fieldCropEntity/fieldCropEntity.cache';
import store from '@store/store';
import { GET_FARM_SEASON_CROPS_SUCCESS } from '@store/actions/FarmSeasonCropActions/GetFarmSeasonCropsTypes';
import { POST_FARM_SEASON_FIELDS_SUCCESS } from '@store/actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTypes';
import EntityError from '../EntityError';
import { FarmSeasonCropT } from '@store/reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';

export const cropCacheAdapter = createEntityAdapter<CropT>({ sortComparer: (c) => c.id });

const cropCacheQueryHelper = getUpdateQueryHelper<CropEndpointParamsT, CropT, 'Crop'>(api2, 'Crop', cropCacheAdapter);

const add: typeof cropCacheQueryHelper.add = async (
    { farmSeasonId, body },
    apiContext,
    optimisticStrategy = 'fetch-only',
) => {
    cropCacheQueryHelper.add({ farmSeasonId, body }, apiContext, optimisticStrategy);

    /* ------------------------ Update Legacy Redux State ----------------------- */
    if (optimisticStrategy !== 'fetch-only') {
        throw new EntityError(
            'Crop',
            'legacy',
            'we only want to handle sync for crop entpoint itself, no actions use this',
        );
    }

    const { data } = await apiContext.queryFulfilled;

    const newLegacyCrops = [...store.getState().farmSeasonCrop.cropsList];
    // apiContext.getState().apiV2.queries
    const staticCrop = store.getState().static.crops.find((c) => c.id === data.crop_id);
    if (!staticCrop) {
        throw new EntityError('Crop', 'legacy');
    }
    const newCropForLegacy: FarmSeasonCropT = { ...data, crop: { ...staticCrop } };
    newLegacyCrops.push(newCropForLegacy);

    apiContext.dispatch({
        type: GET_FARM_SEASON_CROPS_SUCCESS,
        payload: { crops: newLegacyCrops },
    });
    /* -------------------------------------------------------------------------- */
};

const update: typeof cropCacheQueryHelper.update = async (
    { farmSeasonId, body, id },
    apiContext,
    optimisticStrategy = 'fetch-only',
) => {
    cropCacheQueryHelper.update({ farmSeasonId, body, id }, apiContext, optimisticStrategy);
    /* ------------------------ Update Legacy Redux State ----------------------- */
    if (optimisticStrategy !== 'fetch-only') {
        throw new EntityError(
            'Crop',
            'legacy',
            'we only want to handle sync for crop entpoint itself, no actions use this',
        );
    }
    const { data } = await apiContext.queryFulfilled;

    const newLegacyCrops = [...store.getState().farmSeasonCrop.cropsList];
    // apiContext.getState().apiV2.queries
    const staticCrop = store.getState().static.crops.find((c) => c.id === data.crop_id);
    if (!staticCrop) {
        throw new EntityError('Crop', 'legacy');
    }
    const newCropForLegacy: FarmSeasonCropT = { ...data, crop: { ...staticCrop } };
    const index = newLegacyCrops.findIndex((c) => c.id === id);
    newLegacyCrops[index] = { ...newLegacyCrops[index], ...newCropForLegacy };

    apiContext.dispatch({
        type: GET_FARM_SEASON_CROPS_SUCCESS,
        payload: { crops: newLegacyCrops },
    });

    // udpate crops on fields...
    const newLegacyFields = [
        ...store.getState().farmSeasonField.fieldsList.map((f) => ({
            ...f,
            field_crops: f.field_crops?.map((fc) =>
                fc.farm_season_crop.id === id ? { ...fc, farm_season_crop: newCropForLegacy } : { ...fc },
            ),
        })),
    ];
    apiContext.dispatch({
        type: POST_FARM_SEASON_FIELDS_SUCCESS,
        payload: { fields: newLegacyFields },
    });

    /* -------------------------------------------------------------------------- */
};

const removeAndCascade: typeof cropCacheQueryHelper.remove = async (
    { farmSeasonId, id },
    apiContext,
    optimisticStrategy = 'fetch-only',
) => {
    cropCacheQueryHelper.remove({ farmSeasonId, id }, apiContext, optimisticStrategy);
    fieldCropEntityCache.removeWhere('farm_season_crop_id', id, { farmSeasonId }, apiContext, optimisticStrategy);

    /* ------------------------ Update Legacy Redux State ----------------------- */
    if (optimisticStrategy !== 'fetch-only') {
        throw new EntityError(
            'Crop',
            'legacy',
            'we only want to handle sync for crop entpoint itself, no actions use this',
        );
    }

    await apiContext.queryFulfilled;
    const newLegacyCrops = [...store.getState().farmSeasonCrop.cropsList];
    const index = newLegacyCrops.findIndex((c) => c.id === id);
    newLegacyCrops.splice(index, 1);

    apiContext.dispatch({
        type: GET_FARM_SEASON_CROPS_SUCCESS,
        payload: { crops: newLegacyCrops },
    });

    // udpate crops on fields...
    const newLegacyFields = [
        ...store.getState().farmSeasonField.fieldsList.map((f) => ({
            ...f,
            field_crops: f.field_crops
                ?.map((fc) => (fc.farm_season_crop.id === id ? null : { ...fc }))
                .filter((fc) => !!fc),
        })),
    ];
    apiContext.dispatch({
        type: POST_FARM_SEASON_FIELDS_SUCCESS,
        payload: { fields: newLegacyFields },
    });
    /* -------------------------------------------------------------------------- */
};

export default {
    add,
    remove: removeAndCascade,
    update,
};
