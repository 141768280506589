import { api } from './api.service';
import { BillingPortalRequestT, BillingPortalResponseT } from './billing.types';

export const billingApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getBillingPortalUrl: builder.query<BillingPortalResponseT, BillingPortalRequestT>({
            query: ({ farmId, locale, withModal }) => ({
                url: `/farm/${farmId}/billing-portal?locale=${locale}${withModal ? '&modal=renewal-modal' : ''}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyGetBillingPortalUrlQuery } = billingApi;
