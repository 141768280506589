/* eslint-disable @typescript-eslint/no-explicit-any */
import { postFarmSeasonFieldsCropsResidue } from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsResidue';
import { useModal } from '@soil-capital/ui-kit';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';

import { ResidueContext } from '../ResidueTaskContext/ResidueTaskContext';
import { ResidueContextT } from '../ResidueTaskContext/ResidueTaskContextTypes';

import CropListView from './CropListView';

const CropList = (): JSX.Element => {
    const {
        cropList,
        setSelectedCrop,
        setCurrentStep,
        fieldList,
        noResidue,
        setNoResidue,
        setFieldList,
        nothingToSave,
        setNothingToSave,
    } = useContext<ResidueContextT>(ResidueContext);
    const loading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm)?.id;
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason)?.id;
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const modalConfirmNoResidue = useModal();
    const [errorConfirmNoResidue, setErrorConfirmNoResidue] = useState<boolean>(false);

    const onCropEdit = (crop: any) => {
        setSelectedCrop(() => crop);
        setCurrentStep(2);
    };

    const deleteAllResidue = () => {
        const newFieldList = fieldList.map((field) => ({
            ...field,
            field_crops: field.field_crops?.map((crop) => ({ ...crop, residue: null })) || [],
        }));
        setFieldList(newFieldList);
        setNoResidue(true);
        setErrorConfirmNoResidue(false);
    };

    const onSaveResidue = async () => {
        if (
            !fieldList.some((field) => field.field_crops?.some((crop) => crop.residue && crop.residue > 0)) &&
            !noResidue
        ) {
            setErrorConfirmNoResidue(true);
        } else {
            setNothingToSave(true);
            await dispatch(
                postFarmSeasonFieldsCropsResidue(farmId, farmSeasonId, {
                    fields: [...fieldList],
                    crops: [...cropList],
                    dontHaveAny: noResidue,
                }),
            );
            navigate('..');
        }
    };

    return (
        <CropListView
            cropList={cropList.filter((farm_season_crop) => !farm_season_crop.crop.is_permanent)}
            deleteAllResidue={deleteAllResidue}
            errorConfirmNoResidue={errorConfirmNoResidue}
            fieldList={fieldList}
            loading={loading}
            noResidue={noResidue}
            modalConfirmNoResidue={modalConfirmNoResidue}
            nothingToSave={nothingToSave}
            setErrorConfirmNoResidue={setErrorConfirmNoResidue}
            setNoResidue={setNoResidue}
            onCropEdit={onCropEdit}
            onSaveResidue={onSaveResidue}
        />
    );
};

export default CropList;
