import { AppActionsT } from '@store/actions/AppActions/AppActionsTypes';
import { BillingDetailsReducerT } from './BillingDetail.types';
import { RESET } from '@reducers/RootTypes';
import { Reducer } from '@reduxjs/toolkit';
import {
    SET_BILLING_DETAILS_FAILED,
    SET_BILLING_DETAILS_LOADING,
    SET_BILLING_DETAILS_SUCCESS,
    UPDATE_BILLING_DETAILS,
} from '@store/actions/BillingDetailsActions/BillingDetailsActions.types';

export const defaultBillignDetails: BillingDetailsReducerT = {
    loading: false,
    error: null,
    userCompany: undefined,
};

const BillingDetailsReducer: Reducer<BillingDetailsReducerT, AppActionsT> = (state, action): BillingDetailsReducerT => {
    if (!state) {
        return defaultBillignDetails;
    }

    switch (action.type) {
        case SET_BILLING_DETAILS_LOADING:
            return {
                ...defaultBillignDetails,
                loading: true,
            };
        case SET_BILLING_DETAILS_SUCCESS:
            return {
                userCompany: action.payload,
                error: null,
                loading: false,
            };
        case SET_BILLING_DETAILS_FAILED:
            return {
                userCompany: undefined,
                error: action.error,
                loading: false,
            };
        case UPDATE_BILLING_DETAILS:
            return {
                ...state,
                userCompany: action.payload,
            };
        case RESET:
            return {
                ...defaultBillignDetails,
            };
        default:
            return state;
    }
};

export default BillingDetailsReducer;
