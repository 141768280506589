import RequiredInputLabel from '@components/RequiredInputLabel/RequiredInputLabel';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputAdornment, TextField } from '@mui/material';

import useLabelNumberFieldStyle from './LabelNumberField.style';
import { LabelNumberFieldViewPropsT } from './LabelNumberField.types';

const LabelNumberFieldView = ({
    andormentPosition,
    andormentText,
    id,
    borderColor,
    required,
    label,
    disabled,
    error,
    fullWidth,
    helperText,
    name,
    value,
    variant,
    onChange,
    add,
    minus,
    className,
    withHelp,
    helpName,
    tooltipSlug,
    inputProps,
    placeholder,
}: LabelNumberFieldViewPropsT): JSX.Element => {
    const { classes } = useLabelNumberFieldStyle({ borderColor });

    return (
        <div className={className}>
            <RequiredInputLabel
                className={classes.label}
                helpName={helpName}
                id={`${id}-label`}
                required={required}
                tooltipSlug={tooltipSlug}
                withHelp={withHelp}
            >
                {label}
            </RequiredInputLabel>
            <div className={classes.textFieldContainer}>
                <TextField
                    InputProps={{
                        endAdornment: andormentText && (
                            <InputAdornment position={andormentPosition}>{andormentText}</InputAdornment>
                        ),
                    }}
                    className={classes.textField}
                    disabled={disabled}
                    error={error}
                    fullWidth={fullWidth}
                    helperText={helperText}
                    id={id}
                    inputProps={{
                        style: { paddingRight: '30px' },
                        ...inputProps,
                    }}
                    name={name}
                    required={required}
                    type="number"
                    value={value}
                    variant={variant}
                    onChange={onChange}
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    placeholder={placeholder}
                />
                {!andormentText && (
                    <>
                        <ExpandLessIcon className={classes.topIcon} onClick={add} />
                        <ExpandMoreIcon className={classes.bottomIcon} onClick={minus} />
                    </>
                )}
            </div>
        </div>
    );
};

export default LabelNumberFieldView;
