import LinearProgress from '@mui/material/LinearProgress';

import useOnboardingProgressBarStyle from './ProgressBar.style';
import { ProgressBarViewPropsT } from './ProgressBar.types';

const ProgressBarView = ({ progression, color, className, height }: ProgressBarViewPropsT): JSX.Element => {
    const { classes } = useOnboardingProgressBarStyle({ height });

    return (
        <LinearProgress
            className={className}
            classes={classes}
            color={color}
            value={progression}
            variant="determinate"
        />
    );
};

export default ProgressBarView;
