import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
    tagTypes: ['UserCompany', 'Price', 'IBAN', 'Notification', 'Invitation'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json, */*');
            return headers;
        },
        credentials: 'include',
    }),
    refetchOnMountOrArgChange: true,
    endpoints: () => ({}),
    reducerPath: 'apiV1',
});
