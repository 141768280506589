import FarmSeasonFieldsSoilAcidityApiService from '@services/farmSeasonFieldsSoilAcidityApiService/farmSeasonFieldsSoilAcidityApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsSoilAcidityPostCallBackT,
    PostFarmSeasonFieldsSoilAcidityDataT,
    PostFarmSeasonFieldsSoilAcidityDispatchTypesT,
    PostFarmSeasonFieldsSoilAcidityResponseDataT,
    PostFarmSeasonFieldsSoilAcidityResponseErrorT,
    POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_FAILED,
    POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_LOADING,
    POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS,
} from './PostFarmSeasonFieldsSoilAcidityTypes';

export const postFarmSeasonFieldsSoilAcidity =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsSoilAcidityDataT,
    ): FarmSeasonFieldsSoilAcidityPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsSoilAcidityDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsSoilAcidityResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsSoilAcidityResponseDataT> =
                await FarmSeasonFieldsSoilAcidityApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_SOIL_ACIDITY_FAILED,
                error: error as PostFarmSeasonFieldsSoilAcidityResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
