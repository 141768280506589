import { makeStyles } from 'tss-react/mui';

const useNotificationContainerStyle = makeStyles()((theme) => ({
    notificationPanel: {
        ...theme.styles.scrollSection,
        width: '350px',
        backgroundColor: theme.palette.spanishWhite.light,
        padding: '15px',
        maxHeight: '70vh',
        transition: 'all ease .5s',
        overflowY: 'scroll',
    },
}));

export default useNotificationContainerStyle;
