import { Card, Typography, SvgIcon, CircularProgress } from '@mui/material';
import { useState } from 'react';
import IconEdit from '@icons/icon-edit.svg';
import IconDelete from '@icons/icon-delete.svg';

import useColorPatternCardStyle from './ColorPatternCardView.style';
import { ColorPatternCardViewPropsT } from './ColorPatternCardViewTypes';

const ColorPatternCardView = ({
    name,
    children,
    color,
    hasPattern,
    editIcon = IconEdit,
    onEdit,
    onDelete,
    patternSvg,
    border,
}: ColorPatternCardViewPropsT): JSX.Element => {
    const { classes } = useColorPatternCardStyle({ color, hasPattern, backgroundImage: `url(${patternSvg})`, border });
    const [deleteLoading, setDeleteLoading] = useState(false);

    const onClickDelete = async () => {
        setDeleteLoading(true);
        await onDelete?.();
        setDeleteLoading(false);
    };

    return (
        <Card className={classes.card} elevation={0} variant="elevation">
            <div className={classes.container}>
                <div className={classes.colorSquareContainer}>
                    <div className={classes.colorSquare} />
                </div>
                <div className={classes.textSection}>
                    <Typography className={classes.textSectionTitle} color="primary" variant="h5">
                        {name}
                    </Typography>
                    <div className={classes.textSectionDescription}>{children}</div>
                </div>
                <div className={classes.iconContainer}>
                    <SvgIcon
                        className={`${classes.icons} ${classes.editIcon}`}
                        component={editIcon}
                        onClick={() => onEdit()}
                    />
                    {deleteLoading ? (
                        <div className={classes.loading}>
                            <CircularProgress size="15px" />
                        </div>
                    ) : onDelete ? (
                        <SvgIcon className={classes.icons} component={IconDelete} onClick={onClickDelete} />
                    ) : null}
                </div>
            </div>
        </Card>
    );
};

export default ColorPatternCardView;
