import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useDropZoneStyle = makeStyles()((theme) => ({
    uploadContainer: {
        padding: '20px',
        backgroundColor: 'rgba(237,200,128,0.15)',
        width: '400px',
        margin: 'auto',
    },
    uploadSection: {
        width: '100%',
        border: `3px dashed ${alpha(theme.palette.secondary.light, 0.25)}`,
        transition: 'border-color .15s ease',
        '&:hover': {
            border: `3px dashed ${alpha(theme.palette.secondary.light, 0.5)}`,
        },
        borderRadius: '10px',
        paddingTop: '10px',
        paddingBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    uploadIcon: {
        fontSize: '90px',
        color: 'rgba(220,180,100,0.25)',
    },
    divider: {
        width: '27px',
        marginTop: '7px',
        marginBottom: '14px',
    },
    uploadButton: {
        padding: '15px 25px',
        fontSize: '0.80rem',
        lineHeight: 0.8,
    },
    helperText: {
        marginTop: '5px',
        whiteSpace: 'pre-line',
    },
}));

export default useDropZoneStyle;
