import { geoJsonFeatureT } from '@components/Map/MapTypes';
import { FarmSeasonFieldCropDataT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_COVER_CROP_LOADING = 'POST_FARM_SEASON_FIELDS_COVER_CROP_LOADING';
export const POST_FARM_SEASON_FIELDS_COVER_CROP_FAILED = 'POST_FARM_SEASON_FIELDS_COVER_CROP_FAILED';
export const POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS = 'POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS';

export type FarmSeasonFieldsCoverCropT = {
    id?: number;
    name: string;
    area: number;
    field_crops?: FarmSeasonFieldCropDataT[];
    polygon: geoJsonFeatureT;
    has_cover_crops?: boolean | null;
};

export type PostFarmSeasonFieldsCoverCropDataT = {
    coverCrop: boolean;
    fields: FarmSeasonFieldsCoverCropT[];
};

export type PostFarmSeasonFieldsCoverCropResponseDataT = {
    fields: FarmSeasonFieldsCoverCropT[];
    installation: {
        onboarding: {
            finished: string[];
            coverCrop: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsCoverCropPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsCoverCropDispatchTypesT>,
) => Promise<PostFarmSeasonFieldsCoverCropResponseErrorT>;

export type PostFarmSeasonFieldsCoverCropResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsCoverCropLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_COVER_CROP_LOADING;
};

export type PostFarmSeasonFieldsCoverCropFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_COVER_CROP_FAILED;
    error: PostFarmSeasonFieldsCoverCropResponseErrorT;
};

export type PostFarmSeasonFieldsCoverCropSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_COVER_CROP_SUCCESS;
    payload: PostFarmSeasonFieldsCoverCropResponseDataT;
};

export type PostFarmSeasonFieldsCoverCropDispatchTypesT =
    | PostFarmSeasonFieldsCoverCropLoadingT
    | PostFarmSeasonFieldsCoverCropFailedT
    | PostFarmSeasonFieldsCoverCropSuccessT;
