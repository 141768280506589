import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

import { DividerTitleStyleProps } from './DividerTitleTypes';

const useDividerTitleStyle = makeStyles<DividerTitleStyleProps>()((theme, { color }) => ({
    dividerContainer: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.main,
        margin: '15px 0',
        '&>*:first-child': {
            marginRight: '15px',
        },
        '&>hr': {
            flexGrow: 1,
            background: alpha(theme.palette[color].main, 0.5),
        },
    },
}));

export default useDividerTitleStyle;
