import { makeStyles } from 'tss-react/mui';

const useProgressHeaderStyle = makeStyles()((theme) => ({
    progressMain: {
        width: '100%',
        position: 'relative',
    },
    barContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignContent: 'stretch',
        alignItems: 'center',
        padding: '15px 30px',
    },
    bar: {
        flex: '1 1 auto',
        opacity: 0,
        transition: 'opacity .4s ease-in-out .2s',
    },
    progressText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginLeft: '10px',
        color: theme.palette.spanishWhite.contrastText,
        '& span': {
            fontFamily: theme.typography.caption.fontFamily,
            fontSize: '1rem',
            lineHeight: '0.90rem',
            color: theme.palette.driftwood.light,
        },
    },
    bgColor: {
        stroke: theme.palette.spanishWhiteBgShadow,
    },
    bgShadow: {
        fill: theme.palette.spanishWhiteShadow,
    },
    image: {
        width: '100%',
        minHeight: '100px',
        opacity: 0,
        transition: 'opacity 1s linear',
        overflow: 'hidden',
        position: 'relative',
        paddingBottom: '50%',
        '& img': {
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 1.2s cubic-bezier(.27,.18,0,.99)',
            transform: 'translateY(20px)',
        },
    },
    menuBar: {},
    menuBarContainer: {
        width: '100%',
        padding: '0 30px',
        position: 'absolute',
        top: 20,
        left: 0,
    },
}));

export default useProgressHeaderStyle;
