import { useModal } from '@soil-capital/ui-kit';
import useBillingDetailsEditFlow from '@components/BillingDetailsForm/hooks/useBillingDetailsEditFlow';
import useAppDispatch from '@hooks/useAppDispatch';
import { UserCompanyT } from '@services/userCompanyApiService/userCompanyApiServiceTypes';
import { updateLocalBillingDetails } from '@store/actions/BillingDetailsActions/BillingDetailsActions';
import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';

const useBillingDetailsCardLogic = () => {
    const dispatch = useAppDispatch();
    const userCompany = useSelector((state: AppStateT) => state.billingDetails.userCompany);

    const editModalController = useModal<UserCompanyT>();

    // Edit User Company (Billing Details) Form
    const {
        billingDetailsFormRef: editFormRef,
        onSubmitForm: onSubmitEditForm,
        isSubmitting: isEditSubmitting,
    } = useBillingDetailsEditFlow({
        userCompany,
    });

    const handleBillingDetailsEditButtonClick = () => {
        editModalController.openModal();
    };

    const submitEditForm = async () => {
        const data = await onSubmitEditForm();

        if (data) {
            dispatch(updateLocalBillingDetails(data));
            return true;
        }

        return false;
    };

    return {
        data: {
            userCompany,
        },
        handles: {
            handleBillingDetailsEditButtonClick,
        },
        modalControllers: {
            editModalController,
        },
        submittingStates: {
            isEditSubmitting,
        },
        formSubmits: {
            submitEditForm,
        },
        formRefs: {
            editFormRef,
        },
    };
};

export default useBillingDetailsCardLogic;
