import ScAutocomplete from '@components/Autocomplete/Autocomplete';
import { useSelector } from 'react-redux';
import { AppStateT } from '@store/store';
import { useModal } from '@soil-capital/ui-kit';
import ModalCreateFertiliser from '@components/ModalCreateFertiliser/ModalCreateFertiliser';
import { FertiliserT } from '@reducers/FertiliserReducer/FertiliserReducerTypes';

import { AutocompleteFertiliserPropsT } from './AutocompleteFertiliserTypes';

const AutocompleteFertiliser = ({
    autocompleteProps,
    fertilisersTypeIds,
    fertilisersUnit,
    onChange,
    liquid = false,
}: AutocompleteFertiliserPropsT): JSX.Element => {
    const fertilisers = useSelector((state: AppStateT) =>
        state.fertiliser.fertilisersList?.filter(
            (fertiliser) =>
                fertilisersUnit === fertiliser.unit && fertilisersTypeIds.includes(fertiliser.fertiliser_type_id),
        ),
    );
    const fertilisersLoading = useSelector((state: AppStateT) => state.fertiliser.fertilisersLoading);
    const modalCreateController = useModal<FertiliserT>();
    const handleCreateFertiliser = () => modalCreateController.openModal((newFertiliser) => onChange(newFertiliser));

    return (
        <>
            <ScAutocomplete
                {...autocompleteProps}
                loading={fertilisersLoading}
                options={fertilisers}
                onChange={(id) => onChange(fertilisers.find((fertiliser) => fertiliser.id === Number(id)))}
                onCreate={fertilisersTypeIds.join() === '1' ? handleCreateFertiliser : undefined}
            />
            {fertilisersTypeIds.join() === '1' && (
                <ModalCreateFertiliser
                    controller={modalCreateController}
                    fertiliserUnit={fertilisersUnit}
                    liquid={liquid}
                />
            )}
        </>
    );
};

export default AutocompleteFertiliser;
