import { useEffect, useRef, useState } from 'react';

/**
 * Useful to combine with animations.
 * Take a property, and return a copy who will delay (ms) the update while the initial value update.
 * It don't delay the first value
 */
const usePropertyDelayedOnContentChange = <T>({
    propToDelay,
    msDelay,
    contentChangeRef,
}: {
    propToDelay: T;
    msDelay: number;
    contentChangeRef: unknown;
}) => {
    const [contentChanging, setContentChanging] = useState(false);
    const [propDelayed, setPropDelayed] = useState<T>(propToDelay);
    const oldAnimContentChange = useRef<unknown>(contentChangeRef);

    let timeout: NodeJS.Timeout;

    useEffect(() => {
        if (oldAnimContentChange.current !== contentChangeRef) {
            if (timeout) {
                clearTimeout(timeout);
            }

            setContentChanging(true);

            timeout = setTimeout(() => {
                setPropDelayed(propToDelay);
                setContentChanging(false);
                oldAnimContentChange.current = contentChangeRef;
            }, msDelay);
        } else {
            setPropDelayed(propToDelay);
        }
        return () => clearTimeout(timeout);
    }, [contentChangeRef, propToDelay]);

    return { propDelayed, contentAnim: { changing: contentChanging, duration: msDelay } };
};

export default usePropertyDelayedOnContentChange;
