import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import NavProfileView from './NavProfileView';

const NavProfile = (): JSX.Element => {
    const user = useSelector((state: HomePageStateT) => state.auth.user);
    const translationLoading = useSelector((state: HomePageStateT) => state.static.translationsLoading);
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<Element | null>();

    return (
        <NavProfileView
            anchorEl={anchorEl}
            dispatch={dispatch}
            setAnchorEl={setAnchorEl}
            t={t}
            translationLoading={translationLoading}
            user={user}
        />
    );
};

export default NavProfile;
