import LoadingButton from '@components/LoadingButton/LoadingButton';
import { useTranslation } from 'react-i18next';

import { ButtonValidatePageProps } from './ButtonValidatePageTypes';

/**
 * display a button to validate the page. this button change depending on the current page.
 * - page 1 => validate the farm infos
 * - page 1 < ... < max => validate the fields for a crop
 * - page max => validate the whole farm.
 */
const ButtonValidatePage = ({
    farmSeason,
    currentPage,
    lastPage,
    isComputingResults,
    onValidateFarmInfos,
    onValidateCrop,
    onValidateWholeFarm,
}: ButtonValidatePageProps): JSX.Element | null => {
    const { t } = useTranslation();

    // Only show these buttons if the farmSeason has not already been validated
    if (farmSeason?.summary_validated_at) {
        return null;
    }

    if (isComputingResults) {
        return (
            <LoadingButton
                loading={false}
                text={t('pages.farmer-data.button-validate-encodings-loading')}
                type="button"
                disabled
            />
        );
    }

    if (currentPage === 1) {
        return (
            <LoadingButton
                loading={false}
                text={t('pages.farmer-data.button-validate-farm-infos')}
                type="button"
                onClick={onValidateFarmInfos}
            />
        );
    }

    if (currentPage < lastPage) {
        return (
            <LoadingButton
                loading={false}
                text={t('pages.farmer-data.button-validate-field')}
                type="button"
                onClick={onValidateCrop}
            />
        );
    }

    return (
        <LoadingButton
            loading={false}
            text={t('pages.farmer-data.button-validate-encodings')}
            type="button"
            onClick={onValidateWholeFarm}
        />
    );
};

export default ButtonValidatePage;
