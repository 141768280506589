import { Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useSimpleListItemStyle from './SimpleListItemView.style';
import { SimpleListItemViewPropsT } from './SimpleListItemViewTypes';

const SimpleListItemView = ({ text, onClose }: SimpleListItemViewPropsT): JSX.Element => {
    const { classes } = useSimpleListItemStyle();

    return (
        <Paper className={classes.container} elevation={0}>
            <Typography color="secondary" variant="h6">
                {text}
            </Typography>
            <CloseIcon className={classes.icon} color="secondary" onClick={onClose} />
        </Paper>
    );
};

export default SimpleListItemView;
