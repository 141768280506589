import { makeStyles } from 'tss-react/mui';
import { LoadingSectionStylePropsT } from './LoadingSectionTypes';

const useLoadingSectionStyle = makeStyles<LoadingSectionStylePropsT, never>()(
    (theme, { loading, transparentLoadingSection, contentDisplay }) => ({
        LoadingSection: {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        loaderBox: {
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 10,
            width: '100%',

            '> *': {
                color: theme.palette.primary.dark,
            },

            '&.is-secondary ': {
                '> *': {
                    color: theme.palette.secondary.main,
                },
            },
        },
        content: {
            display: contentDisplay,
            flexWrap: 'wrap',
            height: '100%',
            opacity: loading ? (transparentLoadingSection ? 0.5 : 0) : 1,
            transition: loading ? '' : 'opacity 0.5s ease',
            pointerEvents: loading ? 'none' : undefined,
        },
    }),
);

export default useLoadingSectionStyle;
