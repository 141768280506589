import { makeStyles } from 'tss-react/mui';

const useModalBaseLayoutStyle = makeStyles()(() => ({
    ModalBaseLayout: {
        display: 'flex',
        width: '100%',
        minWidth: '450px',
        minHeight: '180px',
        flexDirection: 'column',
    },
    title: {
        marginTop: '10px',
        marginBottom: '1rem',
    },
    body: {
        flex: 'auto',
    },
    footer: {
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'end',
    },
    buttonCancel: {
        marginRight: '20px',
    },
    actionButton: {
        marginRight: '20px',
    },
}));

export default useModalBaseLayoutStyle;
