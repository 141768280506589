import { AuthorizeAuth0ResponseDataT } from '@actions/AuthActions/AuthorizeAuth0ActionsTypes';
import { UserT } from '@reducers/AuthReducer/AuthReducerTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

import {
    AuthorizeAuth0DataT,
    FarmerInfosT,
    ForgotPasswordAuthDataT,
    LoginAuthDataT,
    LoginAuthResponseT,
    ResetPasswordAuthDataT,
    SignUpAuthDataT,
    SignupAuthResponseT,
    VerifyEmailAuthDataT,
} from './authServiceTypes';

class AuthService {
    public static register = async (inputValues: SignUpAuthDataT): Promise<AxiosResponse<SignupAuthResponseT>> =>
        request(
            {
                url: '/auth/register',
                method: 'POST',
                data: {
                    first_name: inputValues.first_name,
                    last_name: inputValues.last_name,
                    email: inputValues.email,
                    password: inputValues.password,
                    password_confirmation: inputValues.password,
                    language_id: inputValues.language_id,
                    invitation_token: inputValues.token,
                    invitation_id: inputValues.invitation_id,
                    referral_code: inputValues.referral_code,
                },
            },
            false,
        );

    public static login = async (inputValues: LoginAuthDataT): Promise<AxiosResponse<LoginAuthResponseT>> =>
        request(
            {
                url: '/auth/login',
                method: 'POST',
                data: {
                    email: inputValues.email,
                    password: inputValues.password,
                },
            },
            false,
        );

    public static authorizeAuth0 = async (
        inputValues: AuthorizeAuth0DataT,
    ): Promise<AxiosResponse<AuthorizeAuth0ResponseDataT>> =>
        request({
            url: '/auth/authorize/auth0',
            method: 'POST',
            data: {
                token: inputValues.token,
            },
        });

    public static forgotPassword = async (inputValues: ForgotPasswordAuthDataT): Promise<AxiosResponse> =>
        request({
            url: '/auth/forgotpassword',
            method: 'POST',
            data: {
                email: inputValues.email,
            },
        });

    public static resetPassword = async (inputValues: ResetPasswordAuthDataT): Promise<AxiosResponse> =>
        request({
            url: '/auth/resetpassword',
            method: 'POST',
            data: {
                token: inputValues.token,
                email: inputValues.email,
                password: inputValues.password,
                password_confirmation: inputValues.password_confirmation,
            },
        });

    public static verifyEmail = async ({
        hashedId,
        expires,
        signature,
    }: VerifyEmailAuthDataT): Promise<AxiosResponse> =>
        request({
            url: `/auth/email/verify/${hashedId}?expires=${expires}&signature=${signature}`,
            method: 'GET',
        });

    public static resendVerifyEmail = async (): Promise<AxiosResponse> =>
        request({
            url: '/auth/email/resend',
            method: 'GET',
        });

    public static refresh = async (): Promise<AxiosResponse> =>
        request({
            url: '/auth/refresh',
            method: 'POST',
        });

    public static logout = async (token: string): Promise<AxiosResponse> =>
        request({
            url: '/auth/logout',
            method: 'POST',
            headers: {
                Authorization: token,
            },
        });

    public static getUser = async (): Promise<
        AxiosResponse<{
            ambassador_referral_code: string;
            farmer_infos: FarmerInfosT | null;
            user: UserT;
        }>
    > =>
        request({
            url: '/auth/user',
            method: 'GET',
        });

    public static changeLanguage = async (id: number): Promise<AxiosResponse> =>
        request({
            url: '/auth/changelanguage',
            method: 'PUT',
            data: {
                id,
            },
        });

    public static updateAmbassador = async (ambassadorReferralCode: string): Promise<AxiosResponse> =>
        request({
            url: '/auth/updateAmbassador',
            method: 'PUT',
            data: {
                ambassadorReferralCode,
            },
        });
}

export default AuthService;
