import { FarmSeasonT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';

export const FARM_SEASON_VALIDATE_BY_AGRONOMIST_FAILED = 'FARM_SEASON_VALIDATE_BY_AGRONOMIST_FAILED';
export const FARM_SEASON_VALIDATE_BY_AGRONOMIST_LOADING = 'FARM_SEASON_VALIDATE_BY_AGRONOMIST_LOADING';
export const FARM_SEASON_VALIDATE_BY_AGRONOMIST_SUCCESS = 'FARM_SEASON_VALIDATE_BY_AGRONOMIST_SUCCESS';

export type FarmSeasonValidateByAgronomistFailedDataT = {
    message?: string;
};

export type FarmSeasonValidateByAgronomistLoadingT = {
    type: typeof FARM_SEASON_VALIDATE_BY_AGRONOMIST_LOADING;
};
export type FarmSeasonValidateByAgronomistSuccessT = {
    type: typeof FARM_SEASON_VALIDATE_BY_AGRONOMIST_SUCCESS;
    payload: FarmSeasonT;
};
export type FarmSeasonValidateByAgronomistFailedT = {
    type: typeof FARM_SEASON_VALIDATE_BY_AGRONOMIST_FAILED;
    error: FarmSeasonValidateByAgronomistFailedDataT;
};

export type FarmSeasonValidateByAgronomistDispatchTypesT =
    | FarmSeasonValidateByAgronomistLoadingT
    | FarmSeasonValidateByAgronomistSuccessT
    | FarmSeasonValidateByAgronomistFailedT;
