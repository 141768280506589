import booleanDisjoint from '@turf/boolean-disjoint';
import lineOffset from './line-offset';
import { lineString } from '@turf/helpers';
import lineToPolygon from '@turf/line-to-polygon';
import difference from '@turf/difference';
import lineIntersect from '@turf/line-intersect';
import flatten from '@turf/flatten';
import _ from 'lodash';
import area from '@turf/area';
import { toleranceOptions } from './tolerance';

// Modified version of https://gis.stackexchange.com/a/344277/145409
// Variant version also exit here: https://gis.stackexchange.com/questions/322129/client-side-polygon-split
export const polygonCut = (poly, line) => {
    const THICK_LINE_UNITS = 'kilometers';
    const THICK_LINE_WIDTH = 0.001;
    let i, j, intersectPoints, forCut;
    let thickLineString, thickLinePolygon, clipped, flattenPolygons, filteredPolygons;
    let polyCoords = [];
    let offsetLine = [];
    let retVal = [];

    if ((poly.type !== 'Polygon' && poly.type !== 'MultiPolygon') || line.type !== 'LineString') {
        return retVal;
    }

    intersectPoints = lineIntersect(poly, line);
    if (intersectPoints.features.length === 0) {
        return retVal;
    }

    if (booleanDisjoint(line, poly)) {
        return retVal;
    }

    offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, {
        units: THICK_LINE_UNITS,
    });
    offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, {
        units: THICK_LINE_UNITS,
    });

    for (i = 0; i <= 1; i++) {
        forCut = i;
        polyCoords = [];
        for (j = 0; j < line.coordinates.length; j++) {
            polyCoords.push(line.coordinates[j]);
        }
        for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
            polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
        }
        polyCoords.push(line.coordinates[0]);

        thickLineString = lineString(polyCoords);
        thickLinePolygon = lineToPolygon(thickLineString);
        clipped = difference(poly, thickLinePolygon);

        flattenPolygons = flatten(clipped);
        filteredPolygons = _.filter(flattenPolygons.features, (v) => area(v) > toleranceOptions.TOLERANCE_MIN_AREA);
        if (!filteredPolygons || filteredPolygons.length < 2) {
            filteredPolygons = [];
        }
    }

    return filteredPolygons ?? [];
};
