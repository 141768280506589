import { LanguageT } from '@reducers/StaticReducer/StaticReducerType';
import { AppStateT } from '@store/store';
import i18next from 'i18next';
import languageConfig from '@constants/config/language.config';

const currentLanguageSelector =
    () =>
    (state: AppStateT): LanguageT => {
        const userLanguageId = state.auth.user?.language_id;
        const staticLanguage = state.static.languages;

        let currentLang: LanguageT;

        if (userLanguageId && staticLanguage) {
            currentLang = staticLanguage.find((lang) => lang.id === userLanguageId) as LanguageT;
        } else {
            const browserLanguage = languageConfig.languages.includes(navigator.language.substring(0, 2))
                ? navigator.language.substring(0, 2)
                : null;

            const platformLanguage =
                i18next?.language?.substring(0, 2) || browserLanguage || languageConfig.defaultLanguage;
            currentLang = staticLanguage
                ? (staticLanguage?.find((lang) => lang.iso_code === platformLanguage) as LanguageT)
                : { iso_code: platformLanguage, id: 0, name: 'English', translated: 0 };
        }

        return currentLang;
    };

export default currentLanguageSelector;
