import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_LOADING = 'POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_LOADING';
export const POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_FAILED = 'POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_FAILED';
export const POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS = 'POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS';

export type PostFarmSeasonFieldIrrigationValuesDataT = {
    id?: number;
    irrigation_method_id: number;
    irrigation_water_source_id: number;
    irrigation_power_source_id: number;
    pumping_depth: number;
    horizontal_distance: number;
    total_amount: number;
    percentage: number;
    validation: number;
};

export type PostFarmSeasonFieldIrrigationValuesResponseDataT = {
    farm_season_field_id: number;
    irrigation: {
        irrigation_method_id: number;
        irrigation_water_source_id: number;
        irrigation_power_source_id: number;
        pumping_depth: number;
        horizontal_distance: number;
        total_amount: number;
        percentage: number;
        id: number;
    };
    installation: {
        onboarding: {
            finished: string[];
            irrigation: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsIrrigationValuesPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldIrrigationValuesDispatchTypesT>,
) => Promise<PostFarmSeasonFieldIrrigationValuesResponseErrorT>;

export type PostFarmSeasonFieldIrrigationValuesResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldIrrigationValuesLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_LOADING;
};

export type PostFarmSeasonFieldIrrigationValuesFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_FAILED;
    error: PostFarmSeasonFieldIrrigationValuesResponseErrorT;
};

export type PostFarmSeasonFieldIrrigationValuesSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_IRRIGATION_VALUES_SUCCESS;
    // TODO: Define data type
    payload: any; // eslint-disable-line
};

export type PostFarmSeasonFieldIrrigationValuesDispatchTypesT =
    | PostFarmSeasonFieldIrrigationValuesLoadingT
    | PostFarmSeasonFieldIrrigationValuesFailedT
    | PostFarmSeasonFieldIrrigationValuesSuccessT;
