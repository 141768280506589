import { Grid, Paper, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import LabelNumberField from '@components/LabelNumberField/LabelNumberField';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';

import { YieldStepViewPropsT } from './YieldStepTypes';
import useYieldStepStyle from './YieldStepView.style';

const YieldStepView = ({ uniqueFieldCrops, changeValue, onSubmit, fieldName }: YieldStepViewPropsT): JSX.Element => {
    const { classes } = useYieldStepStyle();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Typography className={classes.title} variant="h4">
                {fieldName}
            </Typography>
            <Typography className={classes.subtitle} color="secondary" variant="h4">
                <Trans i18nKey="component.relationDndModalView.yieldStep.title">
                    Veuillez definir le rendement pour chaque culture associee
                </Trans>
            </Typography>
            {uniqueFieldCrops.length > 0 && (
                <Grid className={classes.listContainer} spacing={2} container>
                    {uniqueFieldCrops.map((fieldCrop) => (
                        <Grid
                            key={`${fieldCrop.farm_season_crop.crop.translation_slug}.${fieldCrop.order}`}
                            xs={6}
                            item
                        >
                            <LabelNumberField
                                andormentPosition="end"
                                andormentText="t/ha"
                                label={
                                    <>
                                        {t('component.relationDndModalView.yieldStep.label')}
                                        <br />
                                        <span className={classes.darkColor}>
                                            {t(fieldCrop.farm_season_crop.crop.translation_slug || '')}
                                        </span>
                                    </>
                                }
                                value={fieldCrop.yield ? Number(fieldCrop.yield) : ''}
                                variant="outlined"
                                onChange={(value) => changeValue({ order: fieldCrop.order, value })}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
            {uniqueFieldCrops.length === 0 && (
                <Paper className={classes.fieldContainer} elevation={0}>
                    <div className={classes.emptyFieldContainer}>
                        <Typography color="secondary" variant="body1">
                            {t('component.relationDndModalView.yieldStep.no-assoc-crop')}
                        </Typography>
                    </div>
                </Paper>
            )}
            <TwoButtonFooterView rightOnCLick={onSubmit} rightText={t('constants.confirm')} />
        </div>
    );
};

export default YieldStepView;
