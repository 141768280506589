import BillingDetailsForm from '@components/BillingDetailsForm/BillingDetailsForm';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import { Button, Typography } from '@mui/material';
import { LoadingButton, ModalBase } from '@soil-capital/ui-kit';
import { Trans, useTranslation } from 'react-i18next';
import PaymentModal from './PaymentModal/PaymentModal';
import useUpsellActionsLogic from './UpsellActions.logic';
import useUpsellActionsStyle from './UpsellActions.style';

const UpsellActions = () => {
    const { t } = useTranslation();
    const {
        isUpsell,
        upsellDate,
        canSeeUpSellButton,
        canUpdateUpsellForFarmer,
        hasStartedUpsell,
        cannotUpsell,
        formatedStandardPrice,
        paymentModalController,
        userCompany,
        upsellModalController,
        upsellSubmitting,
        upsellFormRef,
        submitUpsellForm,
        handleUpsellClick,
        cancelUpsellLoading,
        handleCancelUpsellClick,
        handleSendReminderEmailClick,
        sendReminderEmailLoading,
        handleUpsellPaymentClick,
        payUpsellLoading,
    } = useUpsellActionsLogic();
    const { classes } = useUpsellActionsStyle();

    return (
        <>
            {isUpsell && (
                <Typography className={`${classes.preline}`} color="primary" variant="body2">
                    {t('pages.settings.billing.upsell.changelog', { UpsellFinalisedDate: upsellDate })}
                </Typography>
            )}
            <div className={classes.actionBar}>
                {canSeeUpSellButton && !hasStartedUpsell && (
                    <Button
                        id="start-upsell"
                        disabled={cannotUpsell}
                        className={classes.actionButton}
                        onClick={handleUpsellClick}
                    >
                        {t('pages.settings.billing.plan.upsell.button.start')}
                    </Button>
                )}
                {canSeeUpSellButton && hasStartedUpsell && (
                    <>
                        <Button
                            id="pay-upsell"
                            disabled={payUpsellLoading}
                            className={classes.actionButton}
                            onClick={handleUpsellPaymentClick}
                            color="primary"
                        >
                            {t('pages.settings.billing.plan.upsell.button.finalise-payment')}
                        </Button>
                        <LoadingButton
                            id="cancel-upsell"
                            loading={cancelUpsellLoading}
                            className={classes.actionButton}
                            onClick={handleCancelUpsellClick}
                            color="secondary"
                            text={t('pages.settings.billing.plan.upsell.button.cancel')}
                        />
                        {canUpdateUpsellForFarmer && (
                            <LoadingButton
                                id="send-pay-email"
                                loading={sendReminderEmailLoading}
                                className={classes.actionButton}
                                onClick={handleSendReminderEmailClick}
                                color="secondary"
                                text={t('pages.settings.billing.plan.upsell.button.payment-email')}
                            />
                        )}
                    </>
                )}
                {canSeeUpSellButton && !hasStartedUpsell && (
                    <Typography mt="16px" className={classes.preline} color="primary" variant="body2">
                        {cannotUpsell ? (
                            <Trans i18nKey="pages.settings.billing.plan.basic.change-blocked.explanation" />
                        ) : (
                            <Trans
                                i18nKey="pages.settings.billing.plan.upsell.explanation"
                                values={{ StandardPlanPrice: formatedStandardPrice }}
                            />
                        )}
                    </Typography>
                )}
            </div>
            {/* Payment modal */}
            <PaymentModal paymentModalController={paymentModalController} userCompany={userCompany} />
            {/* Upsell modal */}
            <ModalBase width="600px" showCloseButton controller={upsellModalController}>
                <ModalBaseLayout
                    title={{
                        text: t('pages.settings.upsell.modal.title'),
                    }}
                    cancelButton={{
                        text: t('constants.cancel'),
                        onClick: () => upsellModalController.onModalCancel(),
                    }}
                    confirmButton={{
                        text: t('pages.settings.upsell.modal.submit.button'),
                        onClick: async () => {
                            if (await submitUpsellForm()) {
                                upsellModalController.onModalCancel();
                            }
                        },
                        disabled: upsellSubmitting,
                    }}
                >
                    <BillingDetailsForm ref={upsellFormRef} userCompany={userCompany} withUseFarmDataCheckbox />
                </ModalBaseLayout>
            </ModalBase>
        </>
    );
};

export default UpsellActions;
