import { removeHasAccessTokenCookie } from '@/utils/getHasAccessTokenCookie';
import { AUTHORIZE_AUTH0_SUCCESS, AuthorizeAuth0DispatchTypesT } from '@actions/AuthActions/AuthorizeAuth0ActionsTypes';
import { GET_USER_FAILED, GetUserDispatchTypesT } from '@actions/AuthActions/GetUserActionsTypes';
import { LOGIN_SUCCESS, LoginDispatchTypesT } from '@actions/AuthActions/LoginActionsTypes';
import { SIGNUP_SUCCESS, SignUpDispatchTypesT } from '@actions/AuthActions/SignUpActionsTypes';
import { ThunkMiddleware } from '@reduxjs/toolkit';

const authMiddleware: ThunkMiddleware =
    () =>
    (next) =>
    (
        action: LoginDispatchTypesT | SignUpDispatchTypesT | GetUserDispatchTypesT | AuthorizeAuth0DispatchTypesT,
    ): void => {
        switch (action.type) {
            case LOGIN_SUCCESS:
            case AUTHORIZE_AUTH0_SUCCESS:
            case SIGNUP_SUCCESS:
                break;
            case GET_USER_FAILED:
                removeHasAccessTokenCookie();
                break;
            default:
                break;
        }

        next(action);
    };

export default authMiddleware;
