import { ModalWarning } from '@soil-capital/ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabelTextField from '../LabelTextField/LabelTextField';
import { ModalRestartFieldEncodingPropsT } from './ModalRestartFieldEncodingTypes';

const ModalRestartFieldEncoding = ({ controller }: ModalRestartFieldEncodingPropsT) => {
    const { t } = useTranslation();
    const [confirmValue, setConfirmValue] = useState<string>('');
    const [errorText, setErrorText] = useState<string>('');

    // reset modal on close
    useEffect(() => {
        if (controller.isModalOpen === false) {
            setErrorText('');
            setConfirmValue('');
        }
    }, [controller.isModalOpen]);

    // add internal logic before confirm.
    const overrideModalConfirm = async () => {
        if (!confirmValue) {
            return setErrorText(
                t('pages.installationtasks.add-field-task.modal-restart-encoding.input-confirm-error-empty'),
            );
        }

        if (confirmValue !== 'DELETE' && confirmValue !== 'SUPPRIMER') {
            return setErrorText(
                t('pages.installationtasks.add-field-task.modal-restart-encoding.input-confirm-error-wrong'),
            );
        }

        controller.onModalConfirm();
    };

    return (
        <ModalWarning
            controller={{ ...controller, onModalConfirm: overrideModalConfirm }}
            title={t('pages.installationtasks.add-field-task.modal-restart-encoding.title')}
            description={t('pages.installationtasks.add-field-task.modal-restart-encoding.description')}
            confirmMessage={t('pages.installationtasks.add-field-task.modal-restart-encoding.confirm-message')}
            rightButtonText={t('pages.installationtasks.add-field-task.modal-restart-encoding.confirm-text')}
            leftButtonText={t('pages.installationtasks.add-field-task.modal-restart-encoding.cancel-text')}
        >
            <div style={{ width: '250px' }}>
                <LabelTextField
                    label={t('pages.installationtasks.add-field-task.modal-restart-encoding.input-confirm-label')}
                    required
                    value={confirmValue}
                    onChange={(v) => setConfirmValue(v.currentTarget.value.toUpperCase())}
                    helperText={errorText}
                    error={!!errorText}
                />
            </div>
        </ModalWarning>
    );
};

export default ModalRestartFieldEncoding;
