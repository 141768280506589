import { ROUTE } from '@constants/routes';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import farmSeasonGetAll from '@store/actions/FarmSeasonActions/FarmSeasonGetAll';
import { farmSeasonSetLatestAsCurrent } from '@store/actions/FarmSeasonActions/FarmSeasonSetCurrent';
import { routingRedirect } from '@store/actions/RoutingActions/RoutingActions';
import { AppStateT } from '@store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useHomePageLogic = () => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const currentFarmSeason = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason);
    const farmSeasonList = useSelector((state: AppStateT) => state.farmSeason.farmSeasonList);
    const farmSeasonId = currentFarmSeason?.id;

    useEffect(() => {
        if (farmSeasonList.length === 0 && farmId && farmSeasonId) {
            dispatch(farmSeasonGetAll(farmId));
        }
    }, [farmSeasonGetAll, farmSeasonList, farmSeasonId, farmId]);

    /** get all season (with the newest created), then select the newest */
    const handleSelectNewestSeason = async () => {
        if (!farmId || !currentFarmSeason) {
            throw Error('Unexpected empty farmId or currentFarmSeason.');
        }

        if (farmSeasonId) {
            // If we are not on the newest farmSeason, we fetch + select
            if (currentFarmSeason.results_validated_by_agronomist) {
                await dispatch(farmSeasonGetAll(farmId));
                await dispatch(farmSeasonSetLatestAsCurrent());
                dispatch(routingRedirect({ route: `${ROUTE.SELF_ENCODING}/tasks/fields` }));
            }

            // Remove query params as we don't need payment status anymore
            navigate({ search: '' });
        }
    };

    return { handleSelectNewestSeason };
};

export default useHomePageLogic;
