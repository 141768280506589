import useMapFieldReset from '@hooks/useMapFieldReset';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ResidueContext } from '../ResidueTaskContext/ResidueTaskContext';
import { ResidueContextT } from '../ResidueTaskContext/ResidueTaskContextTypes';

import { ResidueFormDataT } from './SelectFieldsTypes';
import SelectFieldsView from './SelectFieldsView';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const SelectFields = (): JSX.Element => {
    const { nothingToSave, selectedCrop, fieldList, t, setFieldList, setCurrentStep, setNoResidue } =
        useContext<ResidueContextT>(ResidueContext);
    const [selectedFields, setSelectedFields] = useState<FarmSeasonFieldT[]>([]);
    const [availableFields, setAvailableFields] = useState<FarmSeasonFieldT[]>([]);
    const mapState = useSelector((state: HomePageStateT) => state.map);
    const thisMap = MapService.getMapFromState({ state: mapState });
    const resetMap = useMapFieldReset();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    // Making the map selectable and disable selection once this component is unmount
    useEffect(() => {
        if (!canEditFarmSeason.access) {
            return () => MapService.updateSelectedPolygons({ selectedPolygons: [] });
        }

        MapService.selectablePolygons({ selectMode: true });

        return () => {
            MapService.selectablePolygons({ selectMode: false });
            resetMap(true);
        };
    }, []);

    // init map available field
    useEffect(() => {
        const fieldsWithCrop = fieldList.filter((field) =>
            field.field_crops?.some((crop) => crop.farm_season_crop.id === selectedCrop?.id),
        );
        setAvailableFields(fieldsWithCrop);
        MapService.addPolygons({ polygons: fieldsWithCrop.map((field) => field.polygon) });
    }, []);

    // init map selected field
    useEffect(() => {
        const fieldsWithResidue = fieldList.filter((field) =>
            field.field_crops?.some(
                (crop) => crop.farm_season_crop.id === selectedCrop?.id && crop.residue && crop.residue > 0,
            ),
        );
        setSelectedFields(fieldsWithResidue);
        MapService.updateSelectedPolygons({
            selectedPolygons: fieldsWithResidue.map((field) => field.polygon),
        });
    }, []);

    // Listen on field select on the map
    useEffect(() => {
        setSelectedFields(() =>
            fieldList.filter((field) => thisMap.selectedPolygons?.some((polygon) => polygon.id === field.polygon.id)),
        );
    }, [thisMap.selectedPolygons]);

    // Validation Schema
    const validationSchema = Yup.object().shape({
        residue: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(t('validation.mandatory-field'))
            .positive(t('validation.must-be-greater-than-x', { x: 0 })),
    });

    const [firstMatchingFieldCropResidue, setFirstMatchingFieldCropResidue] = useState<number | null>();
    useEffect(() => {
        // don't override initialized form
        if (firstMatchingFieldCropResidue) {
            return;
        }

        // don't initialize empty value
        if (!selectedFields.length) {
            return;
        }

        setFirstMatchingFieldCropResidue(
            selectedFields[0]?.field_crops?.find((fc) => fc.farm_season_crop.id === selectedCrop?.id)?.residue,
        );
    }, [selectedFields, selectedCrop]);

    const formik = useFormik<ResidueFormDataT>({
        initialValues: {
            residue: firstMatchingFieldCropResidue,
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: (values) => {
            setFieldList(
                fieldList.map((field) => ({
                    ...field,
                    field_crops: field.field_crops?.map((fCrop) => ({
                        ...fCrop,
                        residue:
                            fCrop.farm_season_crop.id === selectedCrop?.id
                                ? selectedFields.some((sField) => sField.id === field.id)
                                    ? values.residue
                                    : null
                                : fCrop.residue,
                    })),
                })),
            );
            setNoResidue(false);
            setCurrentStep(1);
        },
    });

    // Select remaining fields
    const selectRemainingFields = () => {
        MapService.updateSelectedPolygons({
            selectedPolygons: availableFields.map((field) => field.polygon),
        });
    };

    // on deleting a field from the list
    const onSelectedFieldDelete = (id: number | string) => {
        setSelectedFields((fields) => fields.filter((field) => field.id !== id));
        MapService.updateSelectedPolygons({
            selectedPolygons: thisMap.selectedPolygons?.filter((polygon) => polygon.id !== id) || [],
        });
    };

    return (
        <SelectFieldsView
            formik={formik}
            nothingToSave={nothingToSave}
            selectRemainingFields={selectRemainingFields}
            selectedCrop={selectedCrop}
            selectedFields={selectedFields}
            onSelectedFieldDelete={onSelectedFieldDelete}
        />
    );
};

export default SelectFields;
