import { TFunction } from 'react-i18next';

// Common validations

export const maxTwoDecimals = (t: TFunction) => ({
    name: 'maxTwoDecimals',
    message: t('validation.max-2-decimals'),
    test: (value?: number): boolean => !!(value || 0)?.toString().match(/^\d+(\.\d{1,2})?$/)?.length,
});

export const phoneFormat = (t: TFunction) => ({
    test: (value: string): boolean =>
        !!value?.match(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/)
            ?.length,
    message: t('validation.wrong-phone-format'),
});
