import { Popup as UrbicaPopup } from '@urbica/react-map-gl';
import { forwardRef } from 'react';
import useMapPopupStyle from './MapPopup.style';
import { PopupPropsT, MapPopupRefT } from './MapPopup.types';
import { Fade } from '@mui/material';
import useMapPopupLogic from './MapPopup.logic';

const MapPopup = forwardRef<MapPopupRefT, PopupPropsT>(({ mapRef }, mapPopupRef) => {
    const { classes } = useMapPopupStyle();
    const {
        openedProps,
        displayPopup,
        ANIMATION_DURATION,
        closingProps,
        popupHoveredRef,
        delayedClosePopup,
        MapPopupContent,
        handleClosePopupFromContent,
        mapContext,
        polygonId,
    } = useMapPopupLogic({ mapPopupRef, mapRef });

    return displayPopup ? (
        <UrbicaPopup
            longitude={openedProps?.lng ?? closingProps?.lng ?? 0}
            latitude={openedProps?.lat ?? closingProps?.lat ?? 0}
            className={classes.MapPopup}
            closeButton={false}
            closeOnClick={false}
            anchor={openedProps?.anchor}
            offset={openedProps?.offset}
        >
            <Fade
                in={!!openedProps?.popupId}
                timeout={ANIMATION_DURATION}
                onMouseEnter={() => {
                    popupHoveredRef.current = true;
                }}
                onMouseLeave={() => {
                    popupHoveredRef.current = false;
                    delayedClosePopup();
                }}
            >
                <div>
                    <MapPopupContent
                        mapContext={mapContext}
                        onClose={handleClosePopupFromContent}
                        polygonId={polygonId}
                    />
                </div>
            </Fade>
        </UrbicaPopup>
    ) : null;
});

export default MapPopup;
