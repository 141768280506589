// import StaticApiService from '@services/staticApiService/staticApiService';
import i18next from 'i18next';
import { Dispatch } from '@reduxjs/toolkit';

import {
    TranslationDispatchTypes,
    TranslationGetFailedDataT,
    TRANSLATION_GET_FAILED,
    TRANSLATION_GET_LOADING,
    TRANSLATION_GET_SUCCESS,
} from './TranslationsGetActionsTypes';

const translationGet =
    (lang: string) =>
    async (dispatch: Dispatch<TranslationDispatchTypes>): Promise<TranslationGetFailedDataT> => {
        try {
            dispatch({ type: TRANSLATION_GET_LOADING });

            // const data = await StaticApiService.getTranslation(lang);
            const data = {};

            i18next.addResourceBundle(lang, 'translation', data);
            i18next.changeLanguage(lang);
            dispatch({
                type: TRANSLATION_GET_SUCCESS,
                payload: {
                    iso_code: lang,
                    translation: data,
                },
            });

            return {};
        } catch (error) {
            dispatch({
                type: TRANSLATION_GET_FAILED,
                error: error as TranslationGetFailedDataT,
            });

            return {};
        }
    };

export default translationGet;
