import { api2 } from '@api/api2.service';
import { FieldCropEndpointParamsT, FieldCropT } from './fieldCropEntity.types';
import { EntityState } from '@reduxjs/toolkit';
import { fieldCropCacheAdapter } from './fieldCropEntity.cache';

const route = (farmSeasonId: number) => `/farm-seasons/${farmSeasonId}/field-crops`;

export const fieldCropApi = api2.injectEndpoints({
    endpoints: (build) => ({
        getFieldCrop: build.query<EntityState<FieldCropT>, FieldCropEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: route(farmSeasonId),
                method: 'GET',
            }),
            transformResponse: (response: FieldCropT[]) =>
                fieldCropCacheAdapter.setAll(fieldCropCacheAdapter.getInitialState(), response),
        }),

        // create: build.mutation<CreateFieldCropResponseT, CreateFieldCropRequestT>({
        //     query: ({ body, farmSeasonId }) => ({
        //         url: route(farmSeasonId),
        //         method: 'POST',
        //         body,
        //     }),
        //     onQueryStarted: updateQueryFieldCropHelper.add,
        // }),

        // update: build.mutation<UpdateFieldCropResponseT, UpdateFieldCropRequestT>({
        //     query: ({ body, farmSeasonId, id }) => ({
        //         url: `${route(farmSeasonId)}/${id}`,
        //         method: 'PUT',
        //         body,
        //     }),
        //     onQueryStarted: updateQueryFieldCropHelper.update,
        // }),

        // delete: build.mutation<DeleteFieldCropResponseT, DeleteFieldCropRequestT>({
        //     query: ({ farmSeasonId, id }) => ({
        //         url: `${route(farmSeasonId)}/${id}`,
        //         method: 'DELETE',
        //     }),
        //     onQueryStarted: updateQueryFieldCropHelper.delete,
        // }),
    }),
});

export default {
    useGet: fieldCropApi.useGetFieldCropQuery,
};
