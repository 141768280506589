import SegmentService from '@services/segmentService/segmentService';
import useCallbackLoading from '@hooks/useCallbackLoading';
import useAppDispatch from './useAppDispatch';
import { useSelector } from 'react-redux';
import selectCurrentSeason from '@store/selectors/selectCurrentSeason';
import selectLatestFarmSeason from '@store/selectors/selectLatestFarmSeason';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useCallback } from 'react';
import farmSeasonOpenNextFarmSeason from '@store/actions/FarmSeasonActions/FarmSeasonOpenNextFarmSeason';
import useOpenNewSeasonCheck from '@hooks/useOpenNewSeasonCheck';
import { PRODUCT_PLAN_ID } from '@constants/productPlan';

const useHandleOpenNewSeason = () => {
    const dispatch = useAppDispatch();
    const currentSeason = useSelector(selectCurrentSeason());
    const farmId = useSelector((state: HomePageStateT) => state.farm?.currentFarm?.id);
    const latestFarmSeason = useSelector(selectLatestFarmSeason());
    const farmSeasonId = latestFarmSeason?.id;
    const canOpenNextSeason = useOpenNewSeasonCheck();
    const isLatestProductPlanStandard =
        latestFarmSeason?.latest_farm_season_product_plan?.product_plan_id === PRODUCT_PLAN_ID.STANDARD;

    const makeNewSeasonReady = useCallback(async (): Promise<boolean> => {
        if (farmSeasonId && canOpenNextSeason) {
            const success = await dispatch(farmSeasonOpenNextFarmSeason(farmId, farmSeasonId));

            if (!success) {
                console.warn('Failed to open next farm season');
                return false;
            }

            SegmentService.newSeasonStartedTrack({ status: isLatestProductPlanStandard ? 'standard' : 'basic' });

            return true;
        }
        return false;
    }, [farmId, latestFarmSeason?.results_validated_by_agronomist, currentSeason]);

    const { loading: openNewSeasonLoading, callback: handleOpenNewSeason } = useCallbackLoading({
        callback: makeNewSeasonReady,
    });

    return { openNewSeasonLoading, handleOpenNewSeason };
};
export default useHandleOpenNewSeason;
