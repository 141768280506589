import CoverCropTaskContext from './CoverCropTaskContext/CoverCropTaskContext';
import CoverCropTaskView from './CoverCropTaskView';

const CoverCropTask = (): JSX.Element => (
    <CoverCropTaskContext>
        <CoverCropTaskView />
    </CoverCropTaskContext>
);

export default CoverCropTask;
