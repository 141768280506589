import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    FarmSeasonFieldCoverCropHistoryT,
    FarmSeasonFieldIrrigationT,
    FarmSeasonFieldOrganicFertilizationHistoryT,
    FarmSeasonFieldPastureHistoryT,
    FarmSeasonFieldTillagePracticeHistoryT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';

import CardHistory from '../CardHistory/CardHistory';
import CardAgroforestryView from '../CardAgroforestry/CardAgroforestry';
import CardIrrigationView from '../CardIrrigation/CardIrrigation';
import CardCrops from '../CardCrops/CardCrops';
import CardGeneral from '../CardGeneral/CardGeneral';
import CardOperation from '../CardOperation/CardOperation';
import SummaryPannel from '../SummaryPannel/SummaryPannel';
import DividerTitle from '../DividerTitle/DividerTitle';

import { SummaryFieldPropsT } from './SummaryField.types';
import formatNumber from '@utils/formatNumber';

const SummaryFieldView = ({
    field,
    tillageChangeList,
    treesList,
    irrigationMethodList,
    irrigationPowerSourceList,
    irrigationWaterSourceList,
    soilOrganicMatterList,
    pHList,
    tillageList,
    machineryList,
    pesticideList,
    isLoading,
}: SummaryFieldPropsT): JSX.Element => {
    const { t } = useTranslation();

    return (
        <SummaryPannel
            hasProperties={[
                { checked: field.is_organic ?? false, translatedSlug: t('pages.farmer-data.summary.is-organic') },
                {
                    checked: field.has_cover_crops ?? false,
                    translatedSlug: t('pages.farmer-data.summary.has-cover-crop'),
                },
                { checked: field.has_drainage ?? false, translatedSlug: t('pages.farmer-data.summary.has-drainage') },
            ]}
            isLoading={isLoading}
            subTitle={`${formatNumber(field.area, 3)} ${t('constants.hectare-unit')}`}
            title={field.name}
        >
            <>
                <Grid spacing={4} container>
                    <Grid md={6} xs={12} item>
                        <Grid spacing={4} container>
                            <Grid xs={12} item>
                                <CardGeneral
                                    field={field}
                                    pHList={pHList}
                                    soilOrganicMatterList={soilOrganicMatterList}
                                    tillageList={tillageList}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <CardCrops fieldCrops={field.field_crops ?? []} />
                            </Grid>
                            <Grid xs={12} item>
                                <CardIrrigationView
                                    irrigation={field.irrigation as FarmSeasonFieldIrrigationT}
                                    irrigationMethodList={irrigationMethodList}
                                    irrigationPowerSourceList={irrigationPowerSourceList}
                                    irrigationWaterSourceList={irrigationWaterSourceList}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid md={6} xs={12} item>
                        <Grid spacing={4} container>
                            <Grid xs={12} item>
                                <CardHistory
                                    coverCropHistory={field.cover_crop_history as FarmSeasonFieldCoverCropHistoryT}
                                    fertilisationHistory={
                                        field.organic_fertilization_history as FarmSeasonFieldOrganicFertilizationHistoryT
                                    }
                                    pastureHistory={field.pasture_history as FarmSeasonFieldPastureHistoryT}
                                    tillageChangeList={tillageChangeList}
                                    tillageHistory={
                                        field.tillage_practice_history as FarmSeasonFieldTillagePracticeHistoryT
                                    }
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <CardAgroforestryView agroforestry={field.agroforestry ?? []} treesList={treesList} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <DividerTitle color="secondary" titleTranslated={t('pages.farmer-data.summary.operation-divider')} />

                <Grid spacing={4} container>
                    {field.field_crops?.map((fieldCrop) => (
                        <Grid key={fieldCrop.id} xs={12} item>
                            <CardOperation
                                fieldCrop={fieldCrop}
                                machineryList={machineryList}
                                pesticideList={pesticideList}
                            />
                        </Grid>
                    ))}
                </Grid>
            </>
        </SummaryPannel>
    );
};

export default SummaryFieldView;
