import { GetFarmSeasonFieldsResponseDataT } from '@actions/FarmSeasonFieldActions/GetFarmSeasonFieldsTypes';
import {
    PostFarmSeasonFieldsDataT,
    PostFarmSeasonFieldsResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldsTypes';
import { PostFarmSeasonFieldRenameDataT } from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldNameTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';
import {
    PostFarmSeasonFieldDataT,
    PostFarmSeasonFieldResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldTypes';
import { DeleteFarmSeasonFieldResponseDataT } from '@actions/FarmSeasonFieldActions/DeleteFarmSeasonFieldTypes';

import { GetFarmSeasonFieldsParamsT } from './farmSeasonFieldsApiServiceTypes';

class FarmSeasonFieldsApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields`,
            method: 'POST',
            data: body,
        });

    public static save = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldDataT,
        hasPermanentCrops = false,
    ): Promise<AxiosResponse<PostFarmSeasonFieldResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/field${hasPermanentCrops ? '?hasPermanentCrops=true' : ''}`,
            method: 'POST',
            data: body,
        });

    public static get = async (
        farmId: number,
        farmSeasonId: number,
        params?: GetFarmSeasonFieldsParamsT,
    ): Promise<AxiosResponse<GetFarmSeasonFieldsResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields`,
            params,
            method: 'GET',
        });

    public static rename = async (
        farmId: number,
        farmSeasonId: number,
        farmSeasonFieldId: number,
        body: PostFarmSeasonFieldRenameDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldRenameDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/field/${farmSeasonFieldId}/rename`,
            method: 'POST',
            data: body,
        });

    public static delete = async (
        farmId: number,
        farmSeasonId: number,
        farmSeasonFieldId: number,
    ): Promise<AxiosResponse<DeleteFarmSeasonFieldResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/field/${farmSeasonFieldId}`,
            method: 'DELETE',
        });
}

export default FarmSeasonFieldsApiService;
