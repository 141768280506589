import { api } from './api.service';
import { ActiveFarmSeasonProductPlanRequestDataT, ActiveFarmSeasonProductPlanT } from './farmSeasonProductPlan.types';

export const farmSeasonProductPlanApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getActiveFarmSeasonProductPlan: builder.query<
            ActiveFarmSeasonProductPlanT,
            ActiveFarmSeasonProductPlanRequestDataT
        >({
            query: ({ farmId, farmSeasonId }) => ({
                url: `/farm/${farmId}/season/${farmSeasonId}/product-plan/active`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetActiveFarmSeasonProductPlanQuery } = farmSeasonProductPlanApi;
