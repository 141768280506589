import smallDots from '@patterns/small-dots.svg?path';
import smallLines from '@patterns/small-lines.svg?path';
import cross from '@patterns/cross.svg?path';
import waves from '@patterns/waves.svg?path';

export type tillageT = {
    id: number;
    name: string;
    pattern: string;
    mapPattern: string;
};

const tillages = [
    {
        id: 1,
        name: 'db.tillage.conventional-till',
        pattern: smallLines,
        mapPattern: 'smallLines',
    },
    {
        id: 2,
        name: 'db.tillage.reduced-till',
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 3,
        name: 'db.tillage.no-till',
        pattern: cross,
        mapPattern: 'cross',
    },
    {
        id: 4,
        name: 'db.tillage.no-tillage',
        pattern: waves,
        mapPattern: 'waves',
    },
];

export default tillages;
