import { SoilT } from '@reducers/StaticReducer/StaticReducerType';
import { Dispatch } from '@reduxjs/toolkit';

export const SOIL_LIST_GET_LOADING = 'SOIL_LIST_GET_LOADING';
export const SOIL_LIST_GET_SUCCESS = 'SOIL_LIST_GET_SUCCESS';
export const SOIL_LIST_GET_FAILED = 'SOIL_LIST_GET_FAILED';

export type SoilListGetSuccessDataT = SoilT[];

export type SoilListGetLoadingT = {
    type: typeof SOIL_LIST_GET_LOADING;
};

export type SoilListGetSuccessT = {
    type: typeof SOIL_LIST_GET_SUCCESS;
    payload: SoilListGetSuccessDataT;
};

export type SoilListGetFailedT = {
    type: typeof SOIL_LIST_GET_FAILED;
    error: string;
};

export type SoilListGetDispatchType = SoilListGetLoadingT | SoilListGetSuccessT | SoilListGetFailedT;

export type SoilListGetCallback = (dispatch: Dispatch<SoilListGetDispatchType>) => Promise<boolean>;
