import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useExceptionTaskViewStyle = makeStyles()((theme) => ({
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        paddingTop: '20px',
        marginBottom: '20px',
    },
    title: {
        marginBottom: '10px',
    },
    description: {
        marginBottom: '10px',
    },
    listContainer: {
        marginBottom: '20px',
    },
    modal: {
        zIndex: 99,
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.3)',
    },
    modalPaper: {
        overflow: 'hidden',
        position: 'relative',
        padding: '20px 30px 30px 30px',
        width: '700px',
        minHeight: '500px',
        maxHeight: '80vh',
        display: 'grid',
        justifyItems: 'stretch',
        alignContent: 'space-between',
    },
    modalHeader: {},
    modalIntroText: {
        padding: '10px 0',
    },
    modalContent: {
        minHeight: 'calc(50vh - 110px)',
        maxHeight: 'calc(80vh - 220px)',
        margin: '10px 0 20px',
    },
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
        maxHeight: '40vh',
        height: '100%',
    },
    tableCell: {
        padding: '0 10px',
    },
    tableCellWarning: {
        backgroundColor: alpha(theme.palette.warning.light, 0.1),
        width: '100%',
        color: theme.palette.warning.dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableCellWarningIcon: {
        fontSize: '18px',
    },
    warningBox: {
        padding: '10px',
    },
    modalFooter: {},
    modalClose: {
        position: 'absolute',
        top: 20,
        right: 25,
        fontSize: '18px',
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
    totalContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '10px',
    },
    totalNPK: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '10px',
        padding: '10px 15px',
    },
    warningTotal: {
        backgroundColor: alpha(theme.palette.warning.light, 0.1),
        border: `1px solid ${alpha(theme.palette.warning.main, 0.6)}`,
        color: theme.palette.warning.dark,
    },
    warningIcon: {
        color: theme.palette.warning.dark,
        marginLeft: '10px',
        fontSize: '18px',
    },
    warningText: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
}));

export default useExceptionTaskViewStyle;
