import { Typography, Grid, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';

import useIrrigationValuesFormStyle from './IrrigationValuesFormView.style';
import { IrrigationValuesFormViewPropsT } from './IrrigationValuesFormTypes';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const IrrigationValuesFormView = ({
    fieldIrrigated,
    fieldLoading,
    formik,
    irrigationMethodList,
    irrigationWaterSourceList,
    irrigationPowerSourceList,
    t,
    disabledForm,
}: IrrigationValuesFormViewPropsT): JSX.Element => {
    const { classes } = useIrrigationValuesFormStyle();
    const theme = useTheme();

    return (
        <>
            <div className={classes.container}>
                <Typography className={classes.fieldName} variant="h5">
                    <span style={{ color: theme.palette.secondary.dark }}>{fieldIrrigated?.name}</span>
                </Typography>
                <Typography className={classes.title} color="secondary" variant="h4">
                    <Trans i18nKey="pages.installationtasks.fields-subtasks.irrigation-task.define-field-irrigation-practices">
                        Veuillez <span style={{ color: theme.palette.secondary.dark }}>définir l&apos;irrigation</span>
                        pour ce champ
                    </Trans>
                    <HelpButtonView id="pages.installationtasks.fields-subtasks.irrigation-task.define-field-irrigation-practices" />
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid className={classes.inputContainer} xs={12} item>
                            <LabelTextField
                                disabled={disabledForm}
                                endAdornment="mm"
                                error={
                                    formik.touched.irrigationWaterQuantity && !!formik.errors.irrigationWaterQuantity
                                }
                                helpName="irrigationWaterQuantity-help"
                                helperText={
                                    formik.touched.irrigationWaterQuantity && formik.errors.irrigationWaterQuantity
                                }
                                id="irrigationWaterQuantity"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-water-quantity',
                                )}
                                name="irrigationWaterQuantity"
                                type="number"
                                value={formik.values.irrigationWaterQuantity}
                                variant="outlined"
                                fullWidth
                                required
                                withHelp
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} item>
                            <LabelAutocomplete
                                disabled={disabledForm}
                                error={formik.touched.irrigationMethod && !!formik.errors.irrigationMethod}
                                helperText={
                                    formik.touched.irrigationMethod ? formik.errors.irrigationMethod : undefined
                                }
                                id="irrigationMethod"
                                label={t('pages.installationtasks.fields-subtasks.irrigation-task.irrigation-method')}
                                options={irrigationMethodList}
                                value={Number(formik.values.irrigationMethod)}
                                required
                                onChange={(irrigationMethodId) =>
                                    formik.setFieldValue('irrigationMethod', irrigationMethodId)
                                }
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} item>
                            <LabelAutocomplete
                                disabled={disabledForm}
                                error={formik.touched.irrigationWaterSource && !!formik.errors.irrigationWaterSource}
                                helperText={
                                    formik.touched.irrigationWaterSource
                                        ? formik.errors.irrigationWaterSource
                                        : undefined
                                }
                                id="irrigationWaterSource"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-water-source',
                                )}
                                options={irrigationWaterSourceList}
                                value={Number(formik.values.irrigationWaterSource)}
                                required
                                onChange={(irrigationWaterSourceId) =>
                                    formik.setFieldValue('irrigationWaterSource', irrigationWaterSourceId)
                                }
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} item>
                            <LabelAutocomplete
                                disabled={disabledForm}
                                error={formik.touched.irrigationPowerSource && !!formik.errors.irrigationPowerSource}
                                helperText={
                                    formik.touched.irrigationPowerSource
                                        ? formik.errors.irrigationPowerSource
                                        : undefined
                                }
                                id="irrigationPowerSource"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-power-source',
                                )}
                                options={irrigationPowerSourceList}
                                value={Number(formik.values.irrigationPowerSource)}
                                required
                                onChange={(irrigationPowerSourceId) =>
                                    formik.setFieldValue('irrigationPowerSource', irrigationPowerSourceId)
                                }
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} item>
                            <LabelTextField
                                disabled={disabledForm}
                                endAdornment="m"
                                error={formik.touched.irrigationDepth && !!formik.errors.irrigationDepth}
                                helperText={formik.touched.irrigationDepth && formik.errors.irrigationDepth}
                                id="irrigationDepth"
                                label={t('pages.installationtasks.fields-subtasks.irrigation-task.irrigation-depth')}
                                name="irrigationDepth"
                                type="number"
                                value={formik.values.irrigationDepth}
                                variant="outlined"
                                fullWidth
                                required
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid className={classes.inputContainer} xs={12} item>
                            <LabelTextField
                                disabled={disabledForm}
                                endAdornment="m"
                                error={
                                    formik.touched.irrigationHorizontalDistance &&
                                    !!formik.errors.irrigationHorizontalDistance
                                }
                                helpName="irrigationHorizontalDistance-help"
                                helperText={
                                    formik.touched.irrigationHorizontalDistance &&
                                    formik.errors.irrigationHorizontalDistance
                                }
                                id="irrigationHorizontalDistance"
                                label={t(
                                    'pages.installationtasks.fields-subtasks.irrigation-task.irrigation-horizontal-distance',
                                )}
                                name="irrigationHorizontalDistance"
                                type="number"
                                value={formik.values.irrigationHorizontalDistance}
                                variant="outlined"
                                fullWidth
                                required
                                withHelp
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.footerButton}>
                        <TwoButtonFooterView
                            rightButtonOverride={
                                <RestrictSaveFarmSeason>
                                    <LoadingButton
                                        loading={fieldLoading}
                                        onClick={() => formik.handleSubmit()}
                                        text={t('constants.save')}
                                    />
                                </RestrictSaveFarmSeason>
                            }
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

export default IrrigationValuesFormView;
