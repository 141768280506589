import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    PesticidesListGetCallback,
    PesticidesListGetDispatchType,
    PesticidesListGetSuccessDataT,
    PESTICIDES_LIST_GET_FAILED,
    PESTICIDES_LIST_GET_LOADING,
    PESTICIDES_LIST_GET_SUCCESS,
} from './PesticidesGetListActionTypes';

const pesticidesGetListAction =
    (country_id: number): PesticidesListGetCallback =>
    async (dispatch: Dispatch<PesticidesListGetDispatchType>): Promise<boolean> => {
        try {
            dispatch({
                type: PESTICIDES_LIST_GET_LOADING,
            });

            const localStorageKey = `pesticides${country_id}`;

            const pesticides = localStorage.getItem(localStorageKey);

            if (!pesticides) {
                const res: AxiosResponse<PesticidesListGetSuccessDataT> = await StaticApiService.getPesticides(
                    country_id,
                );

                localStorage.setItem(localStorageKey, JSON.stringify(res.data));
                dispatch({
                    type: PESTICIDES_LIST_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: PESTICIDES_LIST_GET_SUCCESS,
                    payload: JSON.parse(pesticides),
                });
            }

            return true;
        } catch (error) {
            dispatch({
                type: PESTICIDES_LIST_GET_FAILED,
                error: error as string,
            });

            return false;
        }
    };

export default pesticidesGetListAction;
