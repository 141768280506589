import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

import { ApiSearchFarmersResponseT, SetEligibilityRespondeDataT, UserApiServicePropsT } from './userApiServiceTypes';

class UserApiService {
    public static searchFarmers = async ({
        query,
        limit = 25,
        signal,
    }: UserApiServicePropsT): Promise<AxiosResponse<ApiSearchFarmersResponseT>> =>
        request({
            url: `/users/search-farmers`,
            method: 'GET',
            params: { query, limit },
            signal,
        });

    public static searchFarmer = async (farmerId: number): Promise<AxiosResponse<ApiSearchFarmersResponseT>> =>
        request({
            url: `/users/search-farmer`,
            method: 'GET',
            params: { farmerId },
        });

    public static setEligible = async (
        farmId: number,
        farmSeasonId: number,
    ): Promise<AxiosResponse<SetEligibilityRespondeDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/eligible`,
            method: 'POST',
        });
}

export default UserApiService;
