import { Box, Link, Button } from '@mui/material';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import useLoginPageStyle from '@pages/LoginPage/LoginPage.style';
import LayoutAuth from '@components/LayoutAuth/LayoutAuth';
import DividerText from '@components/DividerText/DividerText';
import LabelPasswordField from '@components/LabelPasswordField/LabelPasswordField';
import Auth0Buttons from '@components/Auth0Buttons/Auth0Buttons';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';

import { LoginPageViewPropsI } from './LoginPageTypes';

// TODO remove 'pages.signup.login' translation key (not used anymore)
// TODO remove 'pages.login.log-in' translation key (not used anymore)
// TODO remove 'pages.login.log-in-your-account' translation key (not used anymore)

const LoginPageView = ({
    formik,
    loading,
    auth0Loading,
    t,
    remember,
    setRemember,
    userError,
}: LoginPageViewPropsI): JSX.Element => {
    const { classes } = useLoginPageStyle();
    const [searchParams] = useSearchParams();

    return (
        <LayoutAuth
            form={
                <form id="login-form" onSubmit={formik.handleSubmit}>
                    <LabelTextField
                        autoComplete="email"
                        disabled={loading || searchParams.get('email') !== null}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        id="email"
                        label={t('pages.login.email-address')}
                        name="email"
                        type="email"
                        value={formik.values.email}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                    />
                    <LabelPasswordField
                        autoComplete="current-password"
                        disabled={loading}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        id="password"
                        label={t('pages.login.password')}
                        name="password"
                        value={formik.values.password}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                    />
                    <Box mb={2} mt={-1} textAlign="left">
                        <Link
                            className={classes.forgotLink}
                            component={RouterLink}
                            to="/forgot-password"
                            variant="body2"
                        >
                            {t('pages.login.forgot-password')}
                        </Link>
                    </Box>
                    <Box className={classes.checkbox} mb={2} textAlign="left">
                        <LabelCheckboxView
                            checked={remember}
                            label={t('pages.login.remember-me')}
                            name="remember-me"
                            onChange={(e, checked) => setRemember(checked)}
                        />
                    </Box>
                    <ErrorMessageView message={userError} />
                    <Box my={4}>
                        <LoadingButton
                            loading={loading}
                            text={t('constants.login')}
                            type="submit"
                            variant="contained"
                            disableElevation
                            fullWidth
                        />
                    </Box>
                    <DividerText className={classes.divider} text={t('pages.login.create-account-question')} />
                    <Button
                        className={classes.buttonLight}
                        style={{ width: '100%' }}
                        onClick={() => window.location.replace(`${process.env.REACT_APP_V3_BASE_URL}/signup`)}
                    >
                        {t('constants.signup')}
                    </Button>
                    <DividerText className={classes.divider} text={t('pages.signup.auth0-divider')} />
                    <Auth0Buttons auth0Loading={auth0Loading} />
                </form>
            }
            sideZone={<div className={classes.sideZoneImage}></div>}
            title={t('pages.login.log-in-title')}
        />
    );
};

export default LoginPageView;
