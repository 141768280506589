import useMapFieldReset from '@hooks/useMapFieldReset';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import addPolygonData from '@utils/addPolygonData';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CropsStepView from './CropsStepView';

const CropsStep = (): JSX.Element => {
    const fields = useSelector((store: HomePageStateT) => store.farmSeasonField.fieldsList);
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList);
    const resetMap = useMapFieldReset();
    const cropColors = setupCropPaletteFromCropLegacy(crops);

    useEffect(() => {
        if (cropColors) {
            MapService.addPolygons({
                polygons: fields?.map((field) => addPolygonData({ field, colorPalette: cropColors })),
                autofocus: true,
            });
        }

        return () => {
            resetMap(true);
        };
    }, [fields, cropColors]);

    return <CropsStepView cropColors={cropColors} crops={crops} fields={fields} />;
};

export default CropsStep;
