import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { TableViewPropsT } from './TableViewTypes';
import useTableViewStyle from './TableView.style';

const TableView = <T extends string>({
    columns,
    rows,
    minWidth,
    size = 'medium',
    color = 'primary',
}: TableViewPropsT<T>): JSX.Element => {
    const { classes } = useTableViewStyle({ color });
    const hasHeader = columns.find((col) => !!col.headerName);

    const highlitedFields = ['revenue', 'onMarket', 'amountSold', 'onMarket'];

    return (
        <TableContainer className={classes.tableContainer} component={Paper}>
            <Table size={size} style={{ minWidth }}>
                {hasHeader && (
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <TableCell
                                    key={col.field}
                                    className={`${classes.headerCell} ${col.headerClassName}`}
                                    style={{ minWidth: col.minWidth, width: col.width, maxWidth: col.maxWidth }}
                                >
                                    {typeof col.headerName === 'string' ? (
                                        <Typography color={color}>{col.headerName}</Typography>
                                    ) : (
                                        col.headerName
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {rows.map((row, iRow) => (
                        <TableRow key={iRow} className={classes.bodyRow}>
                            {columns.map(({ field, renderColCell, cellClassName }, iCol) => (
                                <TableCell
                                    key={field}
                                    id={field + '-' + iRow}
                                    className={`${classes.bodyCell} ${cellClassName} ${
                                        highlitedFields.includes(field)
                                            ? iRow % 2 === 0
                                                ? classes.highlitedCellEven
                                                : classes.hightlitedCellOdd
                                            : ''
                                    }`}
                                    component="th"
                                    scope="row"
                                >
                                    <>
                                        {row.renderRowCell?.({
                                            value: row[field],
                                            row,
                                            col: columns[iCol],
                                            colIndex: iCol,
                                            rowIndex: iRow,
                                        }) ||
                                            renderColCell?.({
                                                value: row[field],
                                                row,
                                                col: columns[iCol],
                                                colIndex: iCol,
                                                rowIndex: iRow,
                                            }) || (
                                                <Typography color={color}>
                                                    <>{row[field]}</>
                                                </Typography>
                                            )}
                                    </>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableView;
