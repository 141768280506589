import { makeStyles } from 'tss-react/mui';

const useSidePageLeftLayoutStyle = makeStyles<{ hasFooter: boolean }>()((theme, { hasFooter }) => ({
    SidePageLeftLayout: {
        ...theme.styles.scrollSection,
        display: 'flex',
        flexDirection: 'column',
        padding: `0 30px ${hasFooter ? '0' : '16px'} 30px`,
        backgroundColor: theme.palette.spanishWhite.light,
    },
    fakeProgressBarHeight: {
        minHeight: '6px',
    },
    menuBar: {
        textTransform: 'capitalize',
    },
    title: {
        marginBottom: '20px',
    },
    titleDarker: {
        color: theme.palette.secondary.dark,
    },
    loadingSectionHeight: {
        height: 'max-content',
    },
}));

export default useSidePageLeftLayoutStyle;
