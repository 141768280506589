import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { ROUTE } from '@constants/routes';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// List of routes > page names (& categories)
const trackRoute = (tracker: Analytics, path: string, prevPath?: string) => {
    const properties = {
        referrer: prevPath,
    };

    switch (path) {
        case '#simone':
            tracker?.page('Simone', 'Simone', properties);
            break; // MSC-1885
        // case '/signup':
        //     tracker?.page('Signup flow', 'Signup', properties);
        //     break;
        case '/subscribe':
            tracker?.page('Signup flow', '2 CTA', properties);
            break; // MSC-1884
        case '/subscribe/farm':
            tracker?.page('Signup flow', 'Eligibility', properties);
            break; // MSC-1887

        // MSC-1890
        case '/subscribe/payment-status/done':
        case '/subscribe/payment-status/success':
        case '/subscribe/select-season':
            tracker?.page('Signup flow', 'Season', properties);
            break;
        // FP-3938
        // case '/subscribe/farm': // removed because duplicate of 'Eligibility'
        //     tracker?.page('Signup flow', 'Farm information', properties);
        //     break;
        case '/subscribe/product-plan':
            tracker?.page('Signup flow', 'Pricing plan choice', properties);
            break;
        case '/subscribe/contract':
            tracker?.page('Signup flow', 'T&C', properties);
            break;
        case '/subscribe/payment':
            tracker?.page('Signup flow', 'Farm billing information', properties);
            break;

        // FP-3938
        case '/tasks/fields':
            tracker?.page('Tasks', 'Tasks - Fields', properties);
            break;

        // FP-3938
        case '/fields':
            tracker?.page('Farmer portal', 'Field page', properties);
            break;
        case ROUTE.DATA:
            tracker?.page('Farmer portal', 'Data review page', properties);
            break;
        case ROUTE.RESULTS:
            tracker?.page('Farmer portal', 'Results page', properties);
            break;
        case '/certificates':
            tracker?.page('Farmer portal', 'Certificate page', properties);
            break;

        // FP-3956 | FP-4140
        case '/farm':
            // moved to "SegmentService.farmerPage"
            break;
    }
};

const useSegment = (): Analytics | undefined => {
    const location = useLocation();
    const [prevPath, setPrevPath] = useState('');
    const [segment, setSegment] = useState<Analytics | undefined>(undefined);

    useEffect(() => {
        const loadAnalytics = async () => {
            const [response] = await AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_KEY ?? '' });
            setSegment(response);
            // await response.identify(); // init anonymously (...to test, because not recommended https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#identify)
            trackRoute(response, location?.pathname); // init page track
            // eslint-disable-next-line
            // @ts-ignore
            window.segment = response;
        };
        loadAnalytics();
    }, []);

    useEffect(() => {
        if (segment) {
            trackRoute(segment, location?.pathname, prevPath);
            setPrevPath(location?.pathname);
        }
    }, [location]);

    return segment;
};

export default useSegment;
