import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_CROP_LOADING = 'POST_FARM_SEASON_CROP_LOADING';
export const POST_FARM_SEASON_CROP_FAILED = 'POST_FARM_SEASON_CROP_FAILED';
export const POST_FARM_SEASON_CROP_SUCCESS = 'POST_FARM_SEASON_CROP_SUCCESS';

export type CropT = {
    id: number;
    translation_slug?: string;
    name?: string;
};

export type FarmSeasonCropT = {
    id?: number | null;
    yield?: string | null;
    is_accompanied?: boolean | null;
    crop_destination_percentage?: string | null;
    crop: CropT;
};

export type PostFarmSeasonCropDataT = FarmSeasonCropT;

export type PostFarmSeasonCropResponseDataT = FarmSeasonCropT;

export type FarmSeasonCropPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonCropDispatchTypesT>,
) => Promise<PostFarmSeasonCropResponseErrorT>;

export type PostFarmSeasonCropResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonCropLoadingT = {
    type: typeof POST_FARM_SEASON_CROP_LOADING;
};

export type PostFarmSeasonCropFailedT = {
    type: typeof POST_FARM_SEASON_CROP_FAILED;
    error: PostFarmSeasonCropResponseErrorT;
};

export type PostFarmSeasonCropSuccessT = {
    type: typeof POST_FARM_SEASON_CROP_SUCCESS;
    payload: PostFarmSeasonCropResponseDataT;
};

export type PostFarmSeasonCropDispatchTypesT =
    | PostFarmSeasonCropLoadingT
    | PostFarmSeasonCropFailedT
    | PostFarmSeasonCropSuccessT;
