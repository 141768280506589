import {
    PostFarmSeasonFieldIrrigationValuesDataT,
    PostFarmSeasonFieldIrrigationValuesResponseDataT,
} from '@actions/FarmSeasonFieldActions/PostFarmSeasonFieldIrrigationValuesTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class farmSeasonFieldsIrrigationApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        farmSeasonFieldId: number,
        body: PostFarmSeasonFieldIrrigationValuesDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldIrrigationValuesResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/field/${farmSeasonFieldId}/irrigation-values`,
            method: 'POST',
            data: body,
        });
}

export default farmSeasonFieldsIrrigationApiService;
