import smallDots from '@patterns/small-dots.svg';

const soilOrganicMatters = [
    {
        id: 1,
        color: '#FF5630',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 0.5,
        highLevel: 0.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 2,
        color: '#F45B34',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 1.0,
        highLevel: 1.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 3,
        color: '#E96039',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 1.5,
        highLevel: 1.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 4,
        color: '#DE653D',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 2.0,
        highLevel: 2.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 5,
        color: '#D26B42',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 2.5,
        highLevel: 2.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 6,
        color: '#C77046',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 3.0,
        highLevel: 3.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 7,
        color: '#BC754A',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 3.5,
        highLevel: 3.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 8,
        color: '#B17A4F',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 4.0,
        highLevel: 4.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 9,
        color: '#A67F53',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 4.5,
        highLevel: 4.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 10,
        color: '#9B8457',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 5.0,
        highLevel: 5.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 11,
        color: '#8F8A5C',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 5.5,
        highLevel: 5.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 12,
        color: '#848F60',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 6.0,
        highLevel: 6.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 13,
        color: '#799465',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 6.5,
        highLevel: 6.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 14,
        color: '#6E9969',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 7.0,
        highLevel: 7.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 15,
        color: '#639E6D',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 7.5,
        highLevel: 7.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 16,
        color: '#58A472',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 8.0,
        highLevel: 8.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 17,
        color: '#4CA976',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 8.5,
        highLevel: 8.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 18,
        color: '#41AE7B',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 9.0,
        highLevel: 9.4,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
    {
        id: 19,
        color: '#36B37F',
        name: 'constant.soil-organic-mater.from-low-level-to-high-level',
        lowLevel: 9.5,
        highLevel: 9.9,
        pattern: smallDots,
        mapPattern: 'smallDots',
    },
];

export default soilOrganicMatters;
