import { CircularProgress, FormControlLabel, Paper, Radio } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import useRadioButtonStyle from './RadioButton.style';
import { RadioButtonViewPropsT } from './RadioButtonTypes';

const RadioButtonView = ({
    label,
    checked,
    value,
    onClick,
    loading,
    error,
    className,
    disabled,
}: RadioButtonViewPropsT): JSX.Element => {
    const { classes } = useRadioButtonStyle({ error });

    return (
        <Paper
            className={`${classes.paperContainer} ${className}`}
            elevation={0}
            onClick={(e) => {
                if (!loading && !disabled) {
                    onClick(value);
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
        >
            <FormControlLabel
                className={classes.radioButtonControl}
                control={
                    <Radio
                        checked={checked}
                        checkedIcon={<CheckCircleIcon />}
                        className={classes.radio}
                        color="primary"
                        disabled={loading || disabled}
                        icon={
                            loading ? (
                                <CircularProgress size="1.4rem" style={{ opacity: 0.2 }} />
                            ) : (
                                <RadioButtonUncheckedIcon className={classes.icon} />
                            )
                        }
                        value={value}
                        disableRipple
                    />
                }
                label={label}
            />
        </Paper>
    );
};

export default RadioButtonView;
