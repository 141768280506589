import { makeStyles } from 'tss-react/mui';

const useRelationsDndViewStyle = makeStyles()(() => ({
    root: {},
    listContainer: {
        marginBottom: '10px',
    },
}));

export default useRelationsDndViewStyle;
