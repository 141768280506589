import StaticApiService from '@services/staticApiService/staticApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    LivestocksGetCallBackT,
    LivestocksGetResponseFailedT,
    LivestocksGetResponseSuccessT,
    LIVESTOCKS_GET_FAILED,
    LIVESTOCKS_GET_LOADING,
    LIVESTOCKS_GET_SUCCESS,
    LivestocksDispatchTypesT,
} from './LivestocksActionsTypes';

export const livestocksGet =
    (): LivestocksGetCallBackT =>
    async (dispatch: Dispatch<LivestocksDispatchTypesT>): Promise<LivestocksGetResponseFailedT> => {
        try {
            dispatch({ type: LIVESTOCKS_GET_LOADING });

            const localLivestocks = localStorage.getItem('livestocks');

            if (!localLivestocks) {
                const res: AxiosResponse<LivestocksGetResponseSuccessT> = await StaticApiService.getLivestocks();

                localStorage.setItem('livestocks', JSON.stringify(res.data));

                dispatch({
                    type: LIVESTOCKS_GET_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: LIVESTOCKS_GET_SUCCESS,
                    payload: JSON.parse(localLivestocks),
                });
            }

            return {};
        } catch (error) {
            dispatch({
                type: LIVESTOCKS_GET_FAILED,
                error: error as string,
            });

            return {};
        }
    };

export const placeholder = '';
