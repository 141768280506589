import { Typography } from '@mui/material';

import * as packageInfo from '../../../package.json';

import useAppVersionStyle from './AppVersionView.style';

const AppVersionView = (): JSX.Element => {
    const { classes } = useAppVersionStyle();

    return (
        <Typography className={classes.appVersion} component="div" variant="body1">
            version: {packageInfo.version}
        </Typography>
    );
};

export default AppVersionView;
