import { Dispatch } from '@reduxjs/toolkit';
import authService from '@services/authService/authService';
import { AxiosError } from 'axios';

import {
    ForgotPasswordActionDataT,
    ForgotPasswordDispatchTypesT,
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILED,
    ForgotPasswordResponseErrorT,
} from './ForgotPasswordActionsTypes';

const forgotPassword =
    (
        inputValues: ForgotPasswordActionDataT,
    ): any => // eslint-disable-line
    async (dispatch: Dispatch<ForgotPasswordDispatchTypesT>): Promise<ForgotPasswordResponseErrorT> => {
        try {
            dispatch({
                type: FORGOT_PASSWORD_LOADING,
            });

            const res = await authService.forgotPassword({
                email: inputValues.email,
            });

            dispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (e) {
            const error = e as AxiosError<string>;

            const list = error.response?.status !== 200 ? ['email'] : [];
            dispatch({
                type: FORGOT_PASSWORD_FAILED,
                error: {
                    message: error.response?.data || error.message,
                    list,
                },
            });

            return {
                message: error.response?.data || error.message,
                list,
            };
        }
    };

export default forgotPassword;
