import { makeStyles } from 'tss-react/mui';

const useFieldNameAreaModalStyle = makeStyles()((theme) => ({
    areaInput: {
        width: '50%',
    },
    contentSection: {
        padding: '1rem 0',
    },
    infoIcon: {
        fontSize: '1rem',
        color: theme.palette.primary.main,
        verticalAlign: 'text-bottom',
    },
    fieldName: {
        marginBottom: '1.25rem',
    },
}));

export default useFieldNameAreaModalStyle;
