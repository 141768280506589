import { createTheme, PaletteOptions } from '@mui/material';

const { palette: extendPalette } = createTheme();
const palette: PaletteOptions = {
    common: { black: '#000', white: '#fff' },
    primary: {
        light: '#426771',
        main: '#2D525C',
        dark: '#19414C',
        contrastText: '#fff',
    },
    secondary: {
        light: '#D8B795',
        main: '#B07C48',
        dark: '#5B3A1A',
        contrastText: '#fff',
    },
    secondaryWhiteCloud: extendPalette.augmentColor({
        color: {
            main: '#f8f8f8',
        },
    }),
    secondarySunnyDay: extendPalette.augmentColor({
        color: {
            main: '#f6f3E5',
            dark: '#BDBDBD',
        },
    }),
    secondaryMorningMist: extendPalette.augmentColor({
        color: {
            main: '#e7edf9',
        },
    }),
    secondaryDrySage: extendPalette.augmentColor({
        color: {
            main: '#8e8b76',
        },
    }),
    errorLight: extendPalette.augmentColor({
        color: {
            light: '#ff6c6c',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
    }),
    error: {
        light: '#ff0000',
        main: '#ff0000',
        dark: '#ff0000',
        contrastText: '#fff',
    },
    warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: '#fff',
    },
    info: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: '#426771',
    },
    success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#fff',
    },
    grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#d2d2d2',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
        primary: '#4C4F45',
        secondary: 'rgba(0, 0, 0, 0.54)',
        disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: { paper: '#fff', default: '#ffff' },
    action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(0, 0, 0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },

    /* ------------------------------- customColors ---------------------------- */
    spanishWhite: {
        light: '#F9F5E9',
        main: '#D4C8B7',
        dark: '#D4CDB7',
        contrastText: '#426771',
    },
    spanishWhiteBgShadow: '#f5ead5',
    spanishWhiteShadow: '#ecdec5',
    shamrock: {
        light: '#2cd06b',
        main: '#06C74E',
        dark: '#36B37F',
        contrastText: '#ffffff',
    },
    driftwood: {
        light: '#b07c48',
        main: '#8F5B28',
        dark: '#64370B',
        contrastText: '#ffffff',
    },
    linkWater: {
        light: '#f5f7fa',
        main: '#C2CFE9',
        dark: '#052748',
        contrastText: '#426771',
    },
};

/** @deprecated colors must be used from palette. Some of customColor are now accessible from palette */
export const customColor = {
    spanishWhite: {
        light: '#F9F5E9',
        main: '#D4C8B7',
        dark: '#D4CDB7',
        bgShadow: '#f5ead5',
        shadow: '#ecdec5',
        contrastText: '#426771',
    },

    shamrock: {
        light: '#2cd06b',
        main: '#06C74E',
        dark: '#36B37F',
        contrastText: '#ffffff',
    },
    driftwood: {
        light: '#b07c48',
        main: '#8F5B28',
        dark: '#64370B',
        contrastText: '#ffffff',
    },
    linkWater: {
        light: '#f5f7fa',
        main: '#C2CFE9',
        dark: '#052748',
        contrastText: '#426771',
    },
    white: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#ffffff',
        contrastText: '#426771',
    },
    blue: {
        light: '#81bdf3',
        main: '#65a4de',
        dark: '#388bd6',
        contrastText: '#000000',
    },
};

export default palette;
