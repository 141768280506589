/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import MenuBar from '@components/MenuBar/MenuBar';
import { Divider, Modal, Paper, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Prompt } from '@components/Prompt/Prompt';
import CollapsePaperView from '@components/CollapsePaper/CollapsePaperView';
import AddButtonView from '@components/AddButton/AddButtonView';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, GridColumns, GridRowsProp, GridSortItem } from '@mui/x-data-grid';
import WarningMessageView from '@components/WarningMessage/WarningMessageView';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useAppDispatch from '@hooks/useAppDispatch';
import { setNestedObjectValues } from 'formik';
import useLoader from '@hooks/useLoader';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import { DeleteFarmSeasonOperation } from '@actions/FarmSeasonOperation/DeleteFarmSeasonOperation';
import { LoadingButton, useModal } from '@soil-capital/ui-kit';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import IconEdit from '@icons/icon-edit.svg';
import IconDelete from '@icons/icon-delete.svg';
import sumFertiliserComponent from '../utils/sumFertiliserComponent';
import useFertilisationTaskViewStyle from './FertilisationTaskView.style';
import { FertilisationTaskRouteParamsT, FertilisationTaskViewPropsT } from './FertilisationTaskTypes';
import FertilisationMachinerySectionView from './FertilisationMachinerySection/FertilisationMachinerySectionView';
import SelectedFieldsZone from '@components/SelectedFieldsZone/SelectedFieldsZone';
import CardNameValueList from '@components/CardNameValueList/CardNameValueList';
import HeaderNameValueList from '@components/HeaderNameValueList/HeaderNameValueList';
import { ModalWarning } from '@soil-capital/ui-kit';
import IconAction from '@components/IconButton/IconAction';
import IconEye from '@mui/icons-material/RemoveRedEyeOutlined';
import formatNumber from '@utils/formatNumber';

// TODO: Clean component from filters
const FertilisationTaskView = ({
    menuTitle,
    formik,
    setShowColapseId,
    showCollapseId,
    fertilisers,
    dataLoading,
    setSelectedException,
    filteredFields,
    submitted,
    selectedFields,
    farmId,
    farmSeasonId,
    canEdit = false,
}: FertilisationTaskViewPropsT): JSX.Element => {
    const { classes } = useFertilisationTaskViewStyle();
    const { cropId, tillageId } = useParams<FertilisationTaskRouteParamsT>();
    const { t } = useTranslation();
    const farmSeasonCrop = useSelector((store: HomePageStateT) => store.farmSeasonCrop.cropsList)?.find(
        (crop) => crop.id === Number(cropId),
    );
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();

    const [modalWarningIsChecked, setModalWarningIsChecked] = useState(false);
    const [modalOpenState, setOpenModal] = useState(false);
    const openModal = () => setOpenModal(true);
    const closeModal = () => setOpenModal(false);
    const [summaryTable, setSummaryTable] = useState<{ columns: GridColumns<any>; rows: GridRowsProp<any> }>({
        columns: [],
        rows: [],
    });
    const [totalNPK, setTotalNPK] = useState<{ n: number; p: number; k: number } | null>(null);
    const [modalWarning, setModalWarning] = useState(false);
    const [summaryTableSorting, setSummaryTableSorting] = useState<GridSortItem[]>([
        { field: 'numberOfUse', sort: 'desc' },
    ]);

    const modalDeleteOperation = useModal();

    useEffect(() => {
        if (!farmSeasonCrop?.crop || !totalNPK) {
            return;
        }
        setModalWarning(
            (farmSeasonCrop.crop.n_threshold && totalNPK?.n > farmSeasonCrop.crop.n_threshold) ||
                (farmSeasonCrop.crop.p_threshold && totalNPK?.p > farmSeasonCrop.crop.p_threshold) ||
                (farmSeasonCrop.crop.k_threshold && totalNPK?.k > farmSeasonCrop.crop.k_threshold) ||
                false,
        );
    }, [totalNPK, farmSeasonCrop]);

    useEffect(() => {
        const groupedFertilisers = [
            ...formik.values.baseline.mineral_liquid.machinery_fertilisers,
            ...formik.values.baseline.mineral_solid.machinery_fertilisers,
            ...formik.values.baseline.organic_liquid.machinery_fertilisers,
            ...formik.values.baseline.organic_solid.machinery_fertilisers,
            ...formik.values.baseline.other_liquid.machinery_fertilisers,
            ...formik.values.baseline.other_solid.machinery_fertilisers,
        ];

        setTotalNPK({
            n: sumFertiliserComponent(groupedFertilisers, 'n'),
            p: sumFertiliserComponent(groupedFertilisers, 'p'),
            k: sumFertiliserComponent(groupedFertilisers, 'k'),
        });

        const uniqueUsedFertilisers = _.uniq(groupedFertilisers.map((f) => f.fertiliser?.id));

        const countNPK = (fid: any) => {
            const groupedFertilisersFiltered = groupedFertilisers.filter(
                (fertiliser) => fertiliser?.fertiliser?.id === fid,
            );
            const n = sumFertiliserComponent(groupedFertilisersFiltered, 'n');
            const p = sumFertiliserComponent(groupedFertilisersFiltered, 'p');
            const k = sumFertiliserComponent(groupedFertilisersFiltered, 'k');

            return t('pages.operations.fertilising.summary.values.fertiliser-total-npk', { n, p, k });
        };

        setSummaryTable({
            columns: [
                { field: 'name', headerName: t('constants.name'), flex: 1, disableColumnMenu: true },
                {
                    field: 'numberOfUse',
                    headerName: t('pages.operations.fertilising.summary.titles.number-of-use'),
                    flex: 1,
                    minWidth: 200,
                    disableColumnMenu: true,
                },
                {
                    field: 'totalNPK',
                    headerName: t('pages.operations.fertilising.summary.titles.fertiliser-total-npk'),
                    flex: 1,
                    minWidth: 180,
                    disableColumnMenu: true,
                },
            ],
            rows: uniqueUsedFertilisers
                .filter((fid) => fid && fid > 0)
                .map((fid) => ({
                    id: fid,
                    name: t(fertilisers?.find((f) => f.id === fid)?.translation_slug || ''),
                    numberOfUse: _.countBy(groupedFertilisers, 'fertiliser.id')[fid as number],
                    totalNPK: countNPK(fid),
                })),
        });
    }, [
        formik.values.baseline.mineral_liquid.machinery_fertilisers,
        formik.values.baseline.mineral_solid.machinery_fertilisers,
        formik.values.baseline.organic_liquid.machinery_fertilisers,
        formik.values.baseline.organic_solid.machinery_fertilisers,
        formik.values.baseline.other_liquid.machinery_fertilisers,
        formik.values.baseline.other_solid.machinery_fertilisers,
        fertilisers,
    ]);

    const { containerRef: loaderRef } = useLoader(dataLoading);

    return (
        <>
            <div ref={loaderRef} className={classes.container}>
                <Prompt
                    messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                    messageText={t('modal.navigation-prompt-if-unsaved.text')}
                    when={formik.dirty && !submitted}
                />
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={menuTitle}
                    onArrowBack={`/operations/${cropId}/${tillageId}`}
                    onClose={`/operations/${cropId}/${tillageId}`}
                />
                <Typography className={classes.title} color="secondary" variant="h4">
                    <Trans i18nKey="pages.operations.fertilising.title">Operation - Fertilisation</Trans>
                </Typography>
                <Typography className={classes.description} color="secondary" variant="body2">
                    <Trans i18nKey="pages.operations.fertilising.description"></Trans>
                    <HelpButtonView id="pages.operations.fertilisation.description" />
                </Typography>

                <HeaderNameValueList
                    nameHeader={t('pages.installationtasks.add-field-task.final-step.left-subtitle')}
                    valueHeader={`${t('constants.total')} ${formatNumber(
                        selectedFields.reduce((total, field) => total + field.area, 0) as number,
                        3,
                    )} ${t('constants.hectare-unit')}`}
                />
                <SelectedFieldsZone placeholderShown={false} minHeight={'65px'}>
                    <CardNameValueList
                        items={selectedFields || []}
                        getName={(f) => f.name}
                        getValue={(f) => `${formatNumber(f.area, 2)} ${t('constants.hectare-unit')}`}
                    />
                </SelectedFieldsZone>

                <div className={classes.listContainer}>
                    <FertilisationMachinerySectionView
                        dataLoading={dataLoading}
                        dataSource="baseline.mineral_liquid.machinery_fertilisers"
                        disableOpen={formik.values.baseline.dontHaveAny}
                        disabled={formik.values.baseline.dontHaveAny || !canEdit}
                        fertilisers={fertilisers}
                        fertilisersTypeIds={[1]}
                        fertilisersUnit="L"
                        formik={formik}
                        id="mineral-liquid"
                        machineryError={
                            typeof formik.errors?.baseline?.mineral_liquid?.machinery_fertilisers === 'string'
                                ? formik.errors?.baseline?.mineral_liquid?.machinery_fertilisers
                                : ''
                        }
                        machineryFertiliserArray={formik.values.baseline.mineral_liquid.machinery_fertilisers}
                        machineryTouched={formik.touched?.baseline?.mineral_liquid?.machinery_fertilisers}
                        passage={formik.values.baseline.mineral_liquid.passage}
                        passageError={Boolean(
                            formik.touched?.baseline?.mineral_liquid?.passage &&
                                formik.errors?.baseline?.mineral_liquid?.passage,
                        )}
                        passageHelperText={
                            formik.touched?.baseline?.mineral_liquid?.passage &&
                            formik.errors?.baseline?.mineral_liquid?.passage
                                ? formik.errors?.baseline?.mineral_liquid?.passage
                                : ''
                        }
                        setShowColapseId={setShowColapseId}
                        showCollapseId={showCollapseId}
                        title={t('pages.operations.fertilising.mineral-liquid-title')}
                        liquid
                        withInhibitor
                        onPassesChange={(event) =>
                            Number(event.target.value) >= 0 &&
                            formik.setFieldValue(
                                'baseline.mineral_liquid.passage',
                                event.target.value ? parseInt(event.target.value || '0') : null,
                            )
                        }
                    />
                    <FertilisationMachinerySectionView
                        dataLoading={dataLoading}
                        dataSource="baseline.mineral_solid.machinery_fertilisers"
                        disableOpen={formik.values.baseline.dontHaveAny}
                        disabled={formik.values.baseline.dontHaveAny || !canEdit}
                        fertilisers={fertilisers}
                        fertilisersTypeIds={[1]}
                        fertilisersUnit="Kg"
                        formik={formik}
                        id="mineral-solid"
                        machineryError={
                            typeof formik.errors?.baseline?.mineral_solid?.machinery_fertilisers === 'string'
                                ? formik.errors?.baseline?.mineral_solid?.machinery_fertilisers
                                : ''
                        }
                        machineryFertiliserArray={formik.values.baseline.mineral_solid.machinery_fertilisers}
                        machineryTouched={formik.touched?.baseline?.mineral_solid?.machinery_fertilisers}
                        passage={formik.values.baseline.mineral_solid.passage}
                        passageError={Boolean(
                            formik.touched?.baseline?.mineral_solid?.passage &&
                                formik.errors?.baseline?.mineral_solid?.passage,
                        )}
                        passageHelperText={
                            formik.touched?.baseline?.mineral_solid?.passage &&
                            formik.errors?.baseline?.mineral_solid?.passage
                                ? formik.errors?.baseline?.mineral_solid?.passage
                                : ''
                        }
                        setShowColapseId={setShowColapseId}
                        showCollapseId={showCollapseId}
                        title={t('pages.operations.fertilising.mineral-solid-title')}
                        withInhibitor
                        onPassesChange={(event) =>
                            Number(event.target.value) >= 0 &&
                            formik.setFieldValue(
                                'baseline.mineral_solid.passage',
                                event.target.value ? parseInt(event.target.value || '0') : null,
                            )
                        }
                    />
                    <FertilisationMachinerySectionView
                        dataLoading={dataLoading}
                        dataSource="baseline.organic_liquid.machinery_fertilisers"
                        disableOpen={formik.values.baseline.dontHaveAny}
                        disabled={formik.values.baseline.dontHaveAny || !canEdit}
                        fertilisers={fertilisers}
                        fertilisersTypeIds={[2]}
                        fertilisersUnit="L"
                        formik={formik}
                        id="organic-liquid"
                        machineryError={
                            typeof formik.errors?.baseline?.organic_liquid?.machinery_fertilisers === 'string'
                                ? formik.errors?.baseline?.organic_liquid?.machinery_fertilisers
                                : ''
                        }
                        machineryFertiliserArray={formik.values.baseline.organic_liquid.machinery_fertilisers}
                        machineryTouched={formik.touched?.baseline?.organic_liquid?.machinery_fertilisers}
                        passage={formik.values.baseline.organic_liquid.passage}
                        passageError={Boolean(
                            formik.touched?.baseline?.organic_liquid?.passage &&
                                formik.errors?.baseline?.organic_liquid?.passage,
                        )}
                        passageHelperText={
                            formik.touched?.baseline?.organic_liquid?.passage &&
                            formik.errors?.baseline?.organic_liquid?.passage
                                ? formik.errors?.baseline?.organic_liquid?.passage
                                : ''
                        }
                        setShowColapseId={setShowColapseId}
                        showCollapseId={showCollapseId}
                        title={t('pages.operations.fertilising.organic-liquid-title')}
                        liquid
                        onPassesChange={(event) =>
                            Number(event.target.value) >= 0 &&
                            formik.setFieldValue(
                                'baseline.organic_liquid.passage',
                                event.target.value ? parseInt(event.target.value || '0') : null,
                            )
                        }
                    />
                    <FertilisationMachinerySectionView
                        dataLoading={dataLoading}
                        dataSource="baseline.organic_solid.machinery_fertilisers"
                        disableOpen={formik.values.baseline.dontHaveAny}
                        disabled={formik.values.baseline.dontHaveAny || !canEdit}
                        fertilisers={fertilisers}
                        fertilisersTypeIds={[2]}
                        fertilisersUnit="Kg"
                        formik={formik}
                        id="organic-solid"
                        machineryError={
                            typeof formik.errors?.baseline?.organic_solid?.machinery_fertilisers === 'string'
                                ? formik.errors?.baseline?.organic_solid?.machinery_fertilisers
                                : ''
                        }
                        machineryFertiliserArray={formik.values.baseline.organic_solid.machinery_fertilisers}
                        machineryTouched={formik.touched?.baseline?.organic_solid?.machinery_fertilisers}
                        passage={formik.values.baseline.organic_solid.passage}
                        passageError={Boolean(
                            formik.touched?.baseline?.organic_solid?.passage &&
                                formik.errors?.baseline?.organic_solid?.passage,
                        )}
                        passageHelperText={
                            formik.touched?.baseline?.organic_solid?.passage &&
                            formik.errors?.baseline?.organic_solid?.passage
                                ? formik.errors?.baseline?.organic_solid?.passage
                                : ''
                        }
                        setShowColapseId={setShowColapseId}
                        showCollapseId={showCollapseId}
                        title={t('pages.operations.fertilising.organic-solid-title')}
                        onPassesChange={(event) =>
                            Number(event.target.value) >= 0 &&
                            formik.setFieldValue(
                                'baseline.organic_solid.passage',
                                event.target.value ? parseInt(event.target.value || '0') : null,
                            )
                        }
                    />
                    <FertilisationMachinerySectionView
                        dataLoading={dataLoading}
                        dataSource="baseline.other_liquid.machinery_fertilisers"
                        disableOpen={formik.values.baseline.dontHaveAny}
                        disabled={formik.values.baseline.dontHaveAny || !canEdit}
                        fertilisers={fertilisers}
                        fertilisersTypeIds={[3, 4, 5, 6]}
                        fertilisersUnit="L"
                        formik={formik}
                        id="other-liquid"
                        machineryError={
                            typeof formik.errors?.baseline?.other_liquid?.machinery_fertilisers === 'string'
                                ? formik.errors?.baseline?.other_liquid?.machinery_fertilisers
                                : ''
                        }
                        machineryFertiliserArray={formik.values.baseline.other_liquid.machinery_fertilisers}
                        machineryTouched={formik.touched?.baseline?.other_liquid?.machinery_fertilisers}
                        passage={formik.values.baseline.other_liquid.passage}
                        passageError={Boolean(
                            formik.touched?.baseline?.other_liquid?.passage &&
                                formik.errors?.baseline?.other_liquid?.passage,
                        )}
                        passageHelperText={
                            formik.touched?.baseline?.other_liquid?.passage &&
                            formik.errors?.baseline?.other_liquid?.passage
                                ? formik.errors?.baseline?.other_liquid?.passage
                                : ''
                        }
                        setShowColapseId={setShowColapseId}
                        showCollapseId={showCollapseId}
                        title={t('pages.operations.fertilising.other-liquid-title')}
                        liquid
                        onPassesChange={(event) =>
                            Number(event.target.value) >= 0 &&
                            formik.setFieldValue(
                                'baseline.other_liquid.passage',
                                event.target.value ? parseInt(event.target.value || '0') : null,
                            )
                        }
                    />
                    <FertilisationMachinerySectionView
                        dataLoading={dataLoading}
                        dataSource="baseline.other_solid.machinery_fertilisers"
                        disableOpen={formik.values.baseline.dontHaveAny}
                        disabled={formik.values.baseline.dontHaveAny || !canEdit}
                        fertilisers={fertilisers}
                        fertilisersTypeIds={[3, 4, 5, 6]}
                        fertilisersUnit="Kg"
                        formik={formik}
                        id="other-solid"
                        machineryError={
                            typeof formik.errors?.baseline?.other_solid?.machinery_fertilisers === 'string'
                                ? formik.errors?.baseline?.other_solid?.machinery_fertilisers
                                : ''
                        }
                        machineryFertiliserArray={formik.values.baseline.other_solid.machinery_fertilisers}
                        machineryTouched={formik.touched?.baseline?.other_solid?.machinery_fertilisers}
                        passage={formik.values.baseline.other_solid.passage}
                        passageError={Boolean(
                            formik.touched?.baseline?.other_solid?.passage &&
                                formik.errors?.baseline?.other_solid?.passage,
                        )}
                        passageHelperText={
                            formik.touched?.baseline?.other_solid?.passage &&
                            formik.errors?.baseline?.other_solid?.passage
                                ? formik.errors?.baseline?.other_solid?.passage
                                : ''
                        }
                        setShowColapseId={setShowColapseId}
                        showCollapseId={showCollapseId}
                        title={t('pages.operations.fertilising.other-solid-title')}
                        onPassesChange={(event) => {
                            Number(event.target.value) >= 0 &&
                                formik.setFieldValue(
                                    'baseline.other_solid.passage',
                                    event.target.value ? parseInt(event.target.value || '0') : null,
                                );
                        }}
                    />
                    {filteredFields?.length > 1 && (
                        <>
                            <Divider style={{ marginBottom: '20px' }} />
                            <CollapsePaperView
                                disabled={formik.values.baseline.dontHaveAny}
                                isOpen={showCollapseId === 'exceptions'}
                                loading={dataLoading}
                                title={t('pages.operations.fertilising.exceptions-title')}
                                onClick={() =>
                                    showCollapseId === 'exceptions'
                                        ? setShowColapseId('')
                                        : setShowColapseId('exceptions')
                                }
                            >
                                <>
                                    {formik.values.exceptions?.length && formik.values.exceptions.length > 0 ? (
                                        <SelectedFieldsZone placeholderShown={false} minHeight={'65px'}>
                                            <CardNameValueList
                                                items={formik.values.exceptions || []}
                                                getName={(exception, i) =>
                                                    t('pages.operations.fertilising.exception-list-item-title', {
                                                        index: i + 1,
                                                    })
                                                }
                                                getActionIcons={(exception, i) => [
                                                    <IconAction
                                                        key="edit-action"
                                                        icon={canEdit ? <IconEdit /> : <IconEye />}
                                                        onClick={() =>
                                                            setSelectedException({
                                                                titleIndex: i + 1,
                                                                index: i,
                                                                exception: formik.values.exceptions?.[i]
                                                                    ? { ...formik.values.exceptions?.[i] }
                                                                    : undefined,
                                                                remainingFields: [
                                                                    ...(filteredFields || [])
                                                                        .filter(
                                                                            (field) =>
                                                                                !formik.values?.exceptions?.filter(
                                                                                    (filterException, oldIndex) =>
                                                                                        oldIndex !== i &&
                                                                                        filterException.fields_ids.find(
                                                                                            (id) => id === field.id,
                                                                                        ),
                                                                                ).length,
                                                                        )
                                                                        .map((field) => field.id),
                                                                ],
                                                            })
                                                        }
                                                    />,
                                                    canEdit ? (
                                                        <IconAction
                                                            key="delete-action"
                                                            icon={<IconDelete />}
                                                            onClick={() => {
                                                                const index =
                                                                    formik.values.exceptions?.findIndex(
                                                                        (e) => e.id === exception.id,
                                                                    ) || 0;

                                                                formik.setValues((oldValues) => ({
                                                                    ...oldValues,
                                                                    baseline: {
                                                                        ...oldValues.baseline,
                                                                        fields_ids: [
                                                                            ...oldValues.baseline.fields_ids,
                                                                            ...(oldValues.exceptions?.[index]
                                                                                ?.fields_ids || []),
                                                                        ],
                                                                    },
                                                                    exceptions: [
                                                                        ...(oldValues.exceptions?.filter(
                                                                            (oldException, oldIndex) =>
                                                                                oldIndex !== index,
                                                                        ) || []),
                                                                    ],
                                                                }));
                                                            }}
                                                        />
                                                    ) : null,
                                                ]}
                                            />
                                        </SelectedFieldsZone>
                                    ) : null}
                                    <AddButtonView
                                        disabled={formik.values.baseline.dontHaveAny || !canEdit}
                                        label={t('pages.operations.fertilising.add-exception-button')}
                                        onClick={() =>
                                            setSelectedException({
                                                titleIndex: formik.values.exceptions?.length
                                                    ? formik.values.exceptions.length + 1
                                                    : undefined,
                                                exception: { ...formik.values.baseline, fields_ids: [] },
                                                remainingFields: [
                                                    ...(filteredFields || [])
                                                        .filter(
                                                            (field) =>
                                                                !formik.values?.exceptions?.filter((exception) =>
                                                                    exception.fields_ids.find((id) => id === field.id),
                                                                ).length,
                                                        )
                                                        .map((field) => field.id),
                                                ],
                                            })
                                        }
                                    />
                                </>
                            </CollapsePaperView>
                        </>
                    )}
                </div>
                {canEdit && (
                    <LabelCheckboxView
                        border="dotted"
                        checked={formik.values.baseline.dontHaveAny}
                        disabled={dataLoading || !canEdit}
                        label={t('pages.operations.fertilising.no-fertilising')}
                        onChange={(e, value) => formik.setFieldValue('baseline.dontHaveAny', value)}
                    />
                )}
                <TwoButtonFooterView
                    leftOnClick={() => {
                        if (!tillageId || !cropId) {
                            throw Error('tillageId and cropId are required to call "DeleteFarmSeasonOperation"');
                        }

                        modalDeleteOperation.openModal(async () => {
                            await dispatch(
                                DeleteFarmSeasonOperation(farmId, farmSeasonId, 'fertilising', tillageId, cropId),
                            );
                            navigate(-1);
                        });
                    }}
                    leftText={canEdit ? t('constants.delete-operation') : undefined}
                    rightDisabled={dataLoading}
                    rightLoading={formik.isSubmitting}
                    rightOnCLick={() =>
                        formik.validateForm().then((errors) => {
                            if (Object.keys(errors).length === 0) {
                                if (!formik.values.baseline.dontHaveAny) {
                                    openModal();
                                } else {
                                    formik.submitForm();
                                }
                            } else if (Object.keys(errors).length === 1 && errors.baseline?.dontHaveAny) {
                                openModal();
                            } else {
                                formik.setTouched(setNestedObjectValues(errors, true));
                            }
                        })
                    }
                    rightText={t('constants.see-details')}
                />
                <Modal className={classes.modal} open={modalOpenState} keepMounted onClose={closeModal}>
                    <Paper className={classes.modalPaper} elevation={0}>
                        <div className={classes.modalHeader}>
                            <CloseIcon className={classes.modalClose} onClick={closeModal} />
                            <Typography color="secondary" variant="h4">
                                {t('pages.operations.fertilising.summary.title')}
                            </Typography>
                            <Typography className={classes.modalIntroText} variant="body2">
                                {t('pages.operations.fertilising.summary.description')}
                            </Typography>
                        </div>
                        <div className={classes.modalContent}>
                            <div className={classes.tableWrapper}>
                                <DataGrid
                                    columns={summaryTable?.columns}
                                    density="compact"
                                    rows={summaryTable?.rows}
                                    sortModel={summaryTableSorting}
                                    hideFooter
                                    onSortModelChange={(model) => setSummaryTableSorting(model)}
                                />
                            </div>
                        </div>
                        <div className={classes.modalFooter}>
                            <div className={classes.totalContainer}>
                                <div className={`${classes.totalNPK} ${modalWarning ? classes.warningTotal : ''}`}>
                                    <Typography className={classes.warningText} color="primary" variant="body2">
                                        {t('pages.operations.fertilising.summary.total-npk', {
                                            n: Math.ceil(totalNPK?.n || 0),
                                            p: Math.ceil(totalNPK?.p || 0),
                                            k: Math.ceil(totalNPK?.k || 0),
                                        })}
                                    </Typography>
                                    {modalWarning && <ErrorOutlineIcon className={classes.warningIcon} />}
                                </div>
                            </div>
                            {modalWarning && (
                                <WarningMessageView
                                    checkBoxMessage={t('pages.operations.fertilising.summary.warning.checkbox-label')}
                                    containerClass={classes.warningBox}
                                    isChecked={modalWarningIsChecked}
                                    message={t('pages.operations.fertilising.summary.warning.message')}
                                    onCheck={(isChecked) => setModalWarningIsChecked(isChecked)}
                                />
                            )}
                            <TwoButtonFooterView
                                leftOnClick={closeModal}
                                leftText={t('constants.back')}
                                rightButtonOverride={
                                    canEdit ? (
                                        <LoadingButton
                                            disabled={
                                                !formik.isValid ||
                                                dataLoading ||
                                                (modalWarning && !modalWarningIsChecked)
                                            }
                                            loading={formik.isSubmitting}
                                            onClick={() => formik.submitForm()}
                                            text={t('constants.validate')}
                                        />
                                    ) : null
                                }
                            />
                        </div>
                    </Paper>
                </Modal>
            </div>
            <ModalWarning
                controller={modalDeleteOperation}
                description={t('modal.delete-operation.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('modal.warning.title')}
            />
        </>
    );
};

export default FertilisationTaskView;
