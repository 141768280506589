import FarmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonOperationTillagePostCallBackT,
    PostFarmSeasonOperationTillageDataT,
    PostFarmSeasonOperationTillageDispatchTypesT,
    PostFarmSeasonOperationTillageResponseDataT,
    PostFarmSeasonOperationTillageResponseErrorT,
    POST_FARM_SEASON_OPERATION_TILLAGE_FAILED,
    POST_FARM_SEASON_OPERATION_TILLAGE_LOADING,
    POST_FARM_SEASON_OPERATION_TILLAGE_SUCCESS,
} from './PostFarmSeasonOperationTillageTypes';

export const postFarmSeasonOperationTillage =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonOperationTillageDataT,
    ): FarmSeasonOperationTillagePostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonOperationTillageDispatchTypesT>,
    ): Promise<PostFarmSeasonOperationTillageResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_OPERATION_TILLAGE_LOADING });
            const res: AxiosResponse<PostFarmSeasonOperationTillageResponseDataT> =
                await FarmSeasonOperationApiService.post<'tillage'>(farmId, farmSeasonId, 'tillage', body);

            dispatch({
                type: POST_FARM_SEASON_OPERATION_TILLAGE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_OPERATION_TILLAGE_FAILED,
                error: error as PostFarmSeasonOperationTillageResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
