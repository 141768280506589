import {
    PostFarmSeasonFieldsCropsDataT,
    PostFarmSeasonFieldsCropsResponseDataT,
} from '@actions/FarmSeasonFieldCropActions/PostFarmSeasonFieldsCropsTypes';
import request from '@services/apiService/apiService';
import { AxiosResponse } from 'axios';

class FarmSeasonFieldsCropsApiService {
    public static post = async (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsCropsDataT,
    ): Promise<AxiosResponse<PostFarmSeasonFieldsCropsResponseDataT>> =>
        request({
            url: `/farm/${farmId}/season/${farmSeasonId}/fields/crops`,
            method: 'POST',
            data: body,
        });
}

export default FarmSeasonFieldsCropsApiService;
