import HelpIcon from '@mui/icons-material/Help';

import useHelpButtonViewStyle from './HelpButtonView.style';
import { HelpButtonViewPropsT } from './HelpButtonViewTypes';

const HelpButtonView = ({ id, size }: HelpButtonViewPropsT): JSX.Element => {
    const sizes = {
        small: {
            innerBody: '15px',
            outerBody: '17px',
            position: '-1px',
            height: '12px',
        },
        medium: {
            innerBody: '15px',
            outerBody: '20px',
            position: '-2px',
            height: '14px',
        },
        large: {
            innerBody: '16px',
            outerBody: '22px',
            position: '-2px',
            height: '16px',
        },
    };
    const { classes } = useHelpButtonViewStyle({
        height: size ? sizes[size].height : sizes.small.height,
        size: size ? sizes[size] : sizes.small,
    });

    return (
        <div className={classes.container}>
            <a
                className={id.split('.').join('')}
                href="#/"
                role="button"
                tabIndex={-1}
                onClick={(ev) => {
                    ev.preventDefault();
                }}
            >
                <HelpIcon className={classes.help} color="primary" />
            </a>
        </div>
    );
};

export default HelpButtonView;
