import FarmSeasonLivestocksApiService from '@services/farmSeasonLivestocksApiService/farmSeasonLivestocksApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonLivestocksGetCallBackT,
    GET_FARM_SEASON_LIVESTOCKS_FAILED,
    GET_FARM_SEASON_LIVESTOCKS_LOADING,
    GET_FARM_SEASON_LIVESTOCKS_SUCCESS,
    GetFarmSeasonLivestocksDispatchTypesT,
    GetFarmSeasonLivestocksResponseDataT,
    GetFarmSeasonLivestocksResponseErrorT,
} from './GetFarmSeasonLivestocksTypes';

const getFarmSeasonLivestock =
    (farmId: number, farmSeasonId: number): FarmSeasonLivestocksGetCallBackT =>
    async (
        dispatch: Dispatch<GetFarmSeasonLivestocksDispatchTypesT>,
    ): Promise<GetFarmSeasonLivestocksResponseErrorT> => {
        try {
            dispatch({ type: GET_FARM_SEASON_LIVESTOCKS_LOADING });
            const res: AxiosResponse<GetFarmSeasonLivestocksResponseDataT> = await FarmSeasonLivestocksApiService.get(
                farmId,
                farmSeasonId,
            );

            dispatch({
                type: GET_FARM_SEASON_LIVESTOCKS_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error: unknown) {
            dispatch({
                type: GET_FARM_SEASON_LIVESTOCKS_FAILED,
                error: error as GetFarmSeasonLivestocksResponseErrorT,
            });

            return {};
        }
    };

export default getFarmSeasonLivestock;
