import { Card, Typography } from '@mui/material';
import useLoader from '@hooks/useLoader';

import useCardBasicStyle from './CardBasic.style';
import { CardBasicPropsT } from './CardBasicTypes';

const CardBasic = ({
    children,
    title,
    additionalInfo,
    isLoading = false,
    color = 'primary',
    className,
}: CardBasicPropsT): JSX.Element => {
    const { classes } = useCardBasicStyle();

    const { containerRef } = useLoader(isLoading);

    return (
        <Card ref={containerRef} className={`${classes.card} ${className ?? ''}`} elevation={5}>
            {(title || additionalInfo) && (
                <header className={classes.header}>
                    <Typography color={color} variant="h5">
                        {title}
                    </Typography>
                    <Typography color={color} variant="body1">
                        {additionalInfo}
                    </Typography>
                </header>
            )}
            {children}
        </Card>
    );
};

export default CardBasic;
