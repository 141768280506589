import mapUpdate from '@actions/MapActions/MapActions';
import { MAP_UPDATE } from '@actions/MapActions/MapActionsTypes';
import { geoJsonFeatureT, MapPropsT } from '@components/Map/MapTypes';
import request from '@services/apiService/apiService';
import {
    mapAddCircleDataT,
    mapAddPointsDataT,
    mapAddPolygonsDataT,
    mapCenterDataT,
    mapClickedPolygonsT,
    mapCreatablePolygonsDataT,
    mapEditablePolygonsDataT,
    MapEventsSubscribableT,
    mapFocusPointsDataT,
    mapFocusPolygonsDataT,
    mapGetFromStateT,
    MapIdOnlyT,
    mapInteractDataT,
    mapOpenDataParcelDataT,
    mapRemovePolygonsDataT,
    mapSelectablePolygonsDataT,
    mapSelectConditionDataT,
    mapSetupOffsetData,
    mapShowOpenDataT,
    mapTriggerModalDataT,
    mapZoomDataT,
    selectablePermanentCropPolygonsDataT,
    SetPopupContentPropsT,
    updateSelectedPolygonsDataT,
} from '@services/mapService/mapServiceTypes';
import store, { AppStateT } from '@store/store';
import bbox from '@turf/bbox';
import circle from '@turf/circle';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import ObserverService from '../ObserverService/ObserverService';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';

class MapService {
    public static defaultMapId = 'map';
    public static defaultZoom = 15;
    public static minZoom = 9;
    public static maxZoom = 16;
    public static defaultAnimationDuration = 800;
    public static fastAnimationDuration = 800;
    public static slowAnimationDuration = 8000;

    public static observer = new ObserverService<MapEventsSubscribableT>();

    // get map in store
    private static get = (id: string) => {
        const state = store.getState() as AppStateT;
        const maps = state.map?.maps;

        if (maps && maps.length) {
            const thisMap = maps.filter((map) => map.id === id);

            if (thisMap.length) {
                return thisMap[0];
            }
        }

        return false;
    };

    // get map from state
    public static getMapFromState = ({ id = MapService.defaultMapId, state }: mapGetFromStateT): MapPropsT => {
        const mapsFilter = state.maps?.filter((map: MapPropsT) => map.id === id);

        return mapsFilter[0];
    };

    // update map in store
    private static update = (id: string, newProps: MapPropsT) => {
        store.dispatch(mapUpdate({ type: MAP_UPDATE, payload: { id, ...newProps } }));
    };

    // re-center map
    public static center = ({ id = MapService.defaultMapId, latitude, longitude, zoom = 15 }: mapCenterDataT): void => {
        MapService.update(id, { animationDuration: MapService.slowAnimationDuration });
        MapService.update(id, { latitude, longitude, zoom });
    };

    // setup zone for focus
    public static setupOffset = ({ id = MapService.defaultMapId, offset }: mapSetupOffsetData): void => {
        MapService.update(id, { animationOffset: offset });
    };

    // set zoom level
    public static zoom = ({ id = MapService.defaultMapId, zoom }: mapZoomDataT): void => {
        MapService.update(id, { animationDuration: MapService.fastAnimationDuration });
        MapService.update(id, { zoom });
    };

    // get current zoom level
    public static currentZoom = ({ id = MapService.defaultMapId }: mapZoomDataT): number => {
        const thisMap = MapService.get(id);

        if (thisMap) {
            return thisMap.zoom || this.defaultZoom;
        }

        return 6;
    };

    // increment zoom level
    public static zoomIn = (id = MapService.defaultMapId): void => {
        const thisMap = MapService.get(id);
        let newZoom = MapService.defaultZoom;

        if (thisMap) {
            newZoom = this.defaultZoom;

            if (thisMap.zoom) {
                newZoom = thisMap.zoom + 1;
            }

            if (newZoom > MapService.maxZoom) {
                newZoom = MapService.maxZoom;
            }

            MapService.zoom({ id, zoom: newZoom });
        }
    };

    // decrement zoom level
    public static zoomOut = (id = MapService.defaultMapId): void => {
        const thisMap = MapService.get(id);
        let newZoom = MapService.defaultZoom;

        if (thisMap) {
            newZoom = this.defaultZoom;

            if (thisMap.zoom) {
                newZoom = thisMap.zoom - 1;
            }

            if (newZoom < MapService.minZoom) {
                newZoom = MapService.minZoom;
            }

            MapService.zoom({ id, zoom: newZoom });
        }
    };

    // add one or more polygons on map (typically fields from opendata or SHP or DB)
    public static addPolygons = ({ id = MapService.defaultMapId, polygons, autofocus }: mapAddPolygonsDataT): void => {
        const mapDataToUpdate: MapPropsT = { polygonsLayer: polygons };
        if (autofocus) {
            mapDataToUpdate.fitBounds = bbox({ type: 'FeatureCollection', features: polygons }) as BBox2d;
        }
        MapService.update(id, mapDataToUpdate);
    };

    // add one or more points on map (typically farms for the results benchmark)
    public static addPoints = ({ id = MapService.defaultMapId, points, autofocus = true }: mapAddPointsDataT): void => {
        MapService.update(id, { pointsLayer: points });

        if (autofocus) {
            MapService.focusPoints({ id, points });
        }
    };

    // add one or more points on map (typically farms for the results benchmark)
    public static addCircle = ({
        id = MapService.defaultMapId,
        center,
        radius,
        autofocus = true,
    }: mapAddCircleDataT): void => {
        const newCircle = circle(center.geometry, radius);
        const newCircleFeature = {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            id: _.uniqueId(`${String(id)}_`) as any, // legacy value doesn't match the number type
            type: 'Feature',
            geometry: newCircle.geometry,
            properties: {},
        } as geoJsonFeatureT;

        MapService.update(id, { circleLayer: [newCircleFeature] });

        if (autofocus) {
            MapService.focusPolygons({ id, polygons: [newCircleFeature] });
        }
    };

    // remove one or more polygons from map
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static removePolygons = ({ id = MapService.defaultMapId, polygons }: mapRemovePolygonsDataT): void => {
        const thisMap = MapService.get(id);
        if (thisMap && thisMap?.polygonsLayer?.length) {
            MapService.update(id, {
                polygonsLayer: thisMap.polygonsLayer.filter((geoJsonFeature) =>
                    _.some(polygons, (v) => !_.isEqual(v.id.toString(), geoJsonFeature.id.toString())),
                ),
            });
        }
    };

    // zoom to one or more polygons on map
    public static focusPolygons = ({ id = MapService.defaultMapId, polygons }: mapFocusPolygonsDataT): void => {
        const bboxPolygons = bbox({ type: 'FeatureCollection', features: polygons }) as BBox2d;
        MapService.update(id, { fitBounds: bboxPolygons });
    };

    // zoom to one or more points on map
    public static focusPoints = ({ id = MapService.defaultMapId, points }: mapFocusPointsDataT): void => {
        const bboxPoints = bbox({ type: 'FeatureCollection', features: points }) as BBox2d;
        MapService.update(id, { fitBounds: bboxPoints });
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static blurPolygons = (): void => {}; // zoom back to previous state or simply call zoomOut();

    // add the button to create a new polygon from scratch
    public static creatablePolygons = ({
        id = MapService.defaultMapId,
        createMode,
    }: mapCreatablePolygonsDataT): void => {
        MapService.update(id, { creatablePolygons: createMode });
    };

    // get newly splitted polygons
    public static getSplittedPolygons = (thisMap: MapPropsT): geoJsonFeatureT[] | null => {
        if (!thisMap.drawing && thisMap.splitPolygons?.length && thisMap.id) {
            const returnPolygons = thisMap.splitPolygons;
            MapService.update(thisMap.id, { splitPolygons: undefined });

            return returnPolygons;
        }

        return null;
    };

    // get removable polygon
    public static getPolygonsIdsToRemove = (thisMap: MapPropsT): number[] | null => {
        if (thisMap?.polygonsToRemove?.length && thisMap.id) {
            const returnPolygons = thisMap.polygonsToRemove;
            MapService.update(thisMap.id, { polygonsToRemove: undefined });

            return _.map(returnPolygons, 'id');
        }

        return null;
    };

    // make polygons on map selectable on click or not
    public static selectablePolygons = ({
        id = MapService.defaultMapId,
        selectMode,
    }: mapSelectablePolygonsDataT): void => {
        MapService.update(id, { selectablePolygons: selectMode });
    };

    // allow selecting permanent fields
    public static selectablePermanentCropPolygons = ({
        id = MapService.defaultMapId,
        canSelectPermanent,
    }: selectablePermanentCropPolygonsDataT): void => {
        MapService.update(id, { canSelectPermanent });
    };

    // define select condition
    public static defineCondition = ({
        id = MapService.defaultMapId,
        selectCondition,
        deselectCondition,
    }: mapSelectConditionDataT): void => {
        MapService.update(id, { selectCondition, deselectCondition });
    };

    // trigger map modal
    public static openModal = ({ id = MapService.defaultMapId, modal, modalData }: mapTriggerModalDataT): void => {
        MapService.update(id, { triggerModal: { modal, modalData } });
    };

    // update selected polygons
    public static updateSelectedPolygons = ({
        id = MapService.defaultMapId,
        selectedPolygons,
    }: updateSelectedPolygonsDataT): void => {
        MapService.update(id, { selectedPolygons });
    };

    // make polygons on map editable on click or not
    public static editablePolygons = ({ id = MapService.defaultMapId, editMode }: mapEditablePolygonsDataT): void => {
        MapService.update(id, { editablePolygons: editMode });
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static onAddPolygon = (): void => {}; // what happens after adding a new polygon

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static onEditPolygon = (): void => {}; // what happens after editing a polygon

    // provides information about clicked polygon so that we can manipulate it
    public static onClickPolygon = ({
        id = MapService.defaultMapId,
        clickedPolygon,
        selected,
    }: mapClickedPolygonsT): void => {
        MapService.update(id, { lastClickedPolygon: { clickedPolygon, selected } });
    };

    public static removeClickedPolygon = ({ id = MapService.defaultMapId }: MapIdOnlyT): void => {
        MapService.update(id, { lastClickedPolygon: undefined });
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static highlightPolygons = (): void => {}; // highlight the defined polygons

    // show/hide opendata layer
    public static showOpenDataLayer = ({ id = MapService.defaultMapId, openDataMode }: mapShowOpenDataT): void => {
        MapService.update(id, { openData: openDataMode });
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static hoverableLayer = (): void => {}; // add hover effect on layer polygons

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static onClickLayer = (): void => {}; // what happens after clicking on a opendata polygon

    // grey out the map or not
    public static interact = ({ id = MapService.defaultMapId, disabled }: mapInteractDataT): void => {
        MapService.update(id, { disabled });
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static addFiltering = (): void => {}; // add some filtering like crops with colors

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public static debugInfo = (): void => {};

    public static mapOpenDataParcel = async ({
        source,
        version,
        parcelId,
    }: mapOpenDataParcelDataT): Promise<AxiosResponse<geoJsonFeatureT>> =>
        request({
            url: `/map_data/parcel/${source}/${version}/${parcelId}`,
            method: 'GET',
        });

    /**
     * Store the content component to display in the map popup.
     * The component is rendered in the map and that's lead to hook issues if many contents are used.
     * Component could be rendered before been set in the store. Or another solution could be explored ?
     */
    public static setPopupContent = ({ contentComponent }: SetPopupContentPropsT): void => {
        this.update(this.defaultMapId, { popupContent: contentComponent });
    };
}

export default MapService;
