import { FarmSeasonCropT } from '@reducers/FarmSeasonCropReducer/FarmSeasonCropReducerTypes';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ResidueContextT, ResidueTaskContextPropsT } from './ResidueTaskContextTypes';

export const ResidueContext = createContext({} as ResidueContextT);

const ResidueTaskContext = ({ children }: ResidueTaskContextPropsT): JSX.Element => {
    const farmSeasonCropList = useSelector((state: HomePageStateT) => state.farmSeasonCrop.cropsList) || [];
    const farmSeasonFieldList = useSelector((state: HomePageStateT) => state.farmSeasonField.fieldsList) || [];
    const dontHaveAny = useSelector(
        (state: HomePageStateT) => state.farmSeason?.currentFarmSeason?.installation?.onboarding?.residue?.dontHaveAny,
    );
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [selectedCrop, setSelectedCrop] = useState<FarmSeasonCropT | null>(null);
    const [cropList, setCropList] = useState<FarmSeasonCropT[]>([]);
    const [fieldList, setFieldList] = useState<FarmSeasonFieldT[]>([]);
    const [noResidue, setNoResidue] = useState<boolean>(false);
    const [nothingToSave, setNothingToSave] = useState<boolean>(true);

    const { t } = useTranslation();

    // init value
    useEffect(() => {
        const newCropList = farmSeasonCropList.filter((crop) =>
            farmSeasonFieldList.some((field) =>
                field.field_crops?.some((fCrop) => fCrop.farm_season_crop.id === crop.id),
            ),
        );
        setNoResidue(!!dontHaveAny);

        setCropList(newCropList);

        setFieldList(farmSeasonFieldList);
    }, []);

    const store: ResidueContextT = {
        currentStep,
        setCurrentStep,
        t,
        cropList,
        setCropList,
        selectedCrop,
        setSelectedCrop,
        fieldList,
        setFieldList,
        noResidue,
        setNoResidue,
        nothingToSave,
        setNothingToSave,
    };

    return <ResidueContext.Provider value={store}>{children}</ResidueContext.Provider>;
};

export default ResidueTaskContext;
