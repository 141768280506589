import { Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
    GetUserCompanyDataT,
    GetUserCompanyResponseT,
    UserCompanyT,
} from '@services/userCompanyApiService/userCompanyApiServiceTypes';
import userCompanyApiService from '@services/userCompanyApiService/userCompanyApiService';
import {
    SET_BILLING_DETAILS_LOADING,
    SET_BILLING_DETAILS_SUCCESS,
    SET_BILLING_DETAILS_FAILED,
    BillingDetailsDispatchesT,
} from './BillingDetailsActions.types';

export type farmSeasonGetProductPlanStatusPropsT = {
    farmSeasonProductPlan: number;
    farmId: number;
    farmSeasonId: number;
};

export const getBillingDetails =
    ({ userId }: GetUserCompanyDataT) =>
    async (dispatch: Dispatch<BillingDetailsDispatchesT>) => {
        try {
            dispatch({
                type: SET_BILLING_DETAILS_LOADING,
            });

            const res: AxiosResponse<GetUserCompanyResponseT> = await userCompanyApiService.getFirst({
                userId,
            });

            dispatch({
                type: SET_BILLING_DETAILS_SUCCESS,
                payload: res.data,
            });

            return res.data;
        } catch (err) {
            dispatch({
                type: SET_BILLING_DETAILS_FAILED,
                error: err,
            });

            return err;
        }
    };

export const updateLocalBillingDetails =
    (userCompany: UserCompanyT) => (dispatch: Dispatch<BillingDetailsDispatchesT>) => {
        dispatch({
            type: SET_BILLING_DETAILS_SUCCESS,
            payload: userCompany,
        });

        return userCompany;
    };
