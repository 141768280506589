import { makeStyles } from 'tss-react/mui';

import { SummaryPannelStyleProps } from './SummaryPannelTypes';

const useSummaryPannelStyle = makeStyles<SummaryPannelStyleProps>()((theme, { colorTheme }) => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },

    header: {
        backgroundColor: theme.palette[colorTheme].main,
        display: 'flex',
        alignItems: 'center',
        padding: '12px 20px',
        '& *': {
            color: theme.palette[colorTheme].contrastText,
        },
        '& .title': {
            marginRight: '20px',
        },
        '& .hasProperty': {
            display: 'flex',
            alignItems: 'end',
            marginLeft: '20px',
        },
        '& .iconCheck': {
            width: '22px',
            height: '22px',
            fill: theme.palette[colorTheme].contrastText,
            marginRight: '5px',
        },
    },

    content: {
        padding: theme.spacing(4),
        backgroundColor: colorTheme === 'primary' ? '#e7ecf5' : '#f7eed9', // TODO change with color palette: theme.palette[colorTheme].light,
    },
}));

export default useSummaryPannelStyle;
