import { Components, ComponentsOverrides, ComponentsProps, SvgIcon, Theme, useTheme } from '@mui/material';
import IconDown from '@icons/icon-down.svg';
import IconUp from '@icons/icon-up.svg';
import { SCTheme } from '@soil-capital/ui-kit';

const selectCss = {
    root: {
        fontWeight: 'bold',
        '& fieldset': {
            borderWidth: 0,
        },
        '&.Mui-focused fieldset': {
            borderWidth: '0 !important',
        },
    },
    select: ({ theme, ownerState }) => ({
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        ...(ownerState?.variant === 'outlined' && {
            borderRadius: '6px',
            backgroundColor: theme.palette.linkWater.light,

            color: theme.palette.linkWater.contrastText,
        }),
    }),
} as ComponentsOverrides<Theme>['MuiSelect'];

const defaultProps: ComponentsProps['MuiSelect'] = {
    MenuProps: {
        PaperProps: {
            style: {
                borderRadius: Number(SCTheme.shape?.borderRadius) / 2,
            },
        },
    },
    IconComponent: ({ className }: { className: string }) => {
        const isOpen = className.includes('MuiSelect-iconOpen');
        const theme = useTheme();
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    right: 5,
                    width: '24px',
                    height: '100%',
                    pointerEvents: 'none',
                }}
            >
                {isOpen ? (
                    <SvgIcon
                        component={IconUp}
                        style={{ color: theme.palette.primary.light, fontSize: '0.60rem' }}
                        viewBox="0 0 9 9"
                    />
                ) : (
                    <SvgIcon
                        component={IconDown}
                        style={{ color: theme.palette.primary.light, fontSize: '0.60rem' }}
                        viewBox="0 0 9 9"
                    />
                )}
            </div>
        );
    },
};

/** @deprecated. ui-kit Select should be used instead */
const MuiSelect: Components<Theme>['MuiSelect'] = { styleOverrides: selectCss, defaultProps };

export default MuiSelect;
