import { UserInvitationI } from '@/services/partnerInvitationApiService/partnerInvitationApiServiceTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const defaultInvitationState: {
    pendingPartnerInvitation: UserInvitationI | null | undefined;
    fromInvitationEmail: boolean;
} = {
    pendingPartnerInvitation: undefined,
    fromInvitationEmail: false,
};

const slice = createSlice({
    name: 'invitation',
    initialState: defaultInvitationState,
    reducers: {
        acceptedThroughEmailInvitation: (state, action: PayloadAction<boolean>) => {
            state.fromInvitationEmail = action.payload;
        },
        setPendingInvitation: (state, action: PayloadAction<UserInvitationI | null>) => {
            state.pendingPartnerInvitation = action.payload;
        },
    },
});

export const { acceptedThroughEmailInvitation, setPendingInvitation } = slice.actions;

export const invitationReducer = slice.reducer;
