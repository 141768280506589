import { confirmNotEligibleFarmSeasonLivestocksInstallation } from '@actions/FarmSeasonLivestockActions/ConfirmNotEligibleFarmSeasonLivestocksInstallation';
import { deleteFarmSeasonLivestock } from '@actions/FarmSeasonLivestockActions/DeleteFarmSeasonLivestocks';
import getFarmSeasonLivestock from '@actions/FarmSeasonLivestockActions/GetFarmSeasonLivestocks';
import { postFarmSeasonLivestocksInstallation } from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksInstallation';
import { useModal } from '@soil-capital/ui-kit';
import MapService from '@services/mapService/mapService';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import useAppNavigate from '@hooks/useAppNavigate';
import emptyFieldValuesToNull from '@/utils/emptyFieldValuesToNull';

import { LivestockDataT, PostLivestocksDataT } from './LivestocksTaskTypes';
import LivestocksTaskView from './LivestocksTaskView';

const LivestocksTask = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const livestocksTask = useSelector(
        (state: HomePageStateT) => state.farmSeason.currentFarmSeason.installation?.onboarding?.livestock,
    );
    const livestockList = useSelector((state: HomePageStateT) => state.farmSeasonLivestock?.livestockList);
    const livestockListLoading = useSelector((state: HomePageStateT) => state.farmSeasonLivestock?.loading);
    const modalFieldsNotEligibleController = useModal();
    const modalLivestockNotEligibleController = useModal();
    const modalRemoveLivestock = useModal();

    useEffect(() => {
        if (farmSeasonId) dispatch(getFarmSeasonLivestock(farmId, farmSeasonId));
    }, [farmId, farmSeasonId]);

    useEffect(() => {
        MapService.interact({ disabled: true });

        return () => MapService.interact({ disabled: false });
    }, []);

    const validationSchema = yup.object().shape({
        livestocks: yup
            .array()
            .of(
                yup.object().shape({
                    livestock_id: yup.string().required(t('pages.installationtasks.livestock-task.type-required')),
                    quantity: yup
                        .string()
                        .test(
                            'Must have more than 0',
                            t('pages.installationtasks.livestock-task.quantity-needs-to-be-higher-than-0'),
                            (value) => parseInt(value ?? '', 10) > 0,
                        ),
                }),
            )
            .required(t('pages.installationtasks.livestock-task.must-have-livestock')),
        option: yup
            .string()
            .nullable()
            .test('option', t('validation.confirm-no-livestock-error-message'), (value, context) =>
                value === 'no' ? true : context.parent.livestocks.length > 0,
            ),
    });

    const formik = useFormik({
        initialValues: {
            livestocks: livestockList?.length > 0 ? (livestockList as LivestockDataT[]) : [],
            option: livestocksTask?.option ?? null,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: PostLivestocksDataT) => {
            if (!farmSeasonId) return;

            // No Livestock
            if (values.livestocks.length === 0) {
                await dispatch(deleteFarmSeasonLivestock(farmId, farmSeasonId));
                navigate('..');

                return;
            }

            // Has Livestock
            const res = await dispatch(
                postFarmSeasonLivestocksInstallation(farmId, farmSeasonId, {
                    livestocks: values.livestocks.map((livestock) => emptyFieldValuesToNull(livestock)),
                    option: 'yes',
                }),
            );

            if (!res) {
                return;
            }

            if (res.isEligible) {
                navigate('..');

                return;
            }

            // Has Livestock + not eligible
            // Display modal to ask user if all his fields are good.
            await modalFieldsNotEligibleController.openModal(
                async () => {
                    await dispatch(
                        confirmNotEligibleFarmSeasonLivestocksInstallation(farmId, farmSeasonId, {
                            option: 'yes',
                        }),
                    );

                    // Display modal to inform user he has too many livestock and will be contacted by sales.
                    modalLivestockNotEligibleController.openModal(async () => {
                        navigate('..');
                    });
                },
                async () => {
                    navigate('..');
                },
            );
        },
    });

    return (
        <LivestocksTaskView
            formik={formik}
            modalRemoveLivestock={modalRemoveLivestock}
            modalFieldsNotEligibleController={modalFieldsNotEligibleController}
            modalLivestockNotEligibleController={modalLivestockNotEligibleController}
            livestockListLoading={livestockListLoading}
        />
    );
};

export default LivestocksTask;
