import { Card, Typography } from '@mui/material';
import { SnackbarContent } from 'notistack';
import { forwardRef } from 'react';
import { BoxedIcon } from '@soil-capital/ui-kit';
import { CustomSnackbarPropsT } from './CustomSnackbar.types';
import TextLink from '@components/TextLink/TextLink';
import useCustomSnackbarLogic from './CustomSnackbar.logic';
import useCustomSnackbarStyle from './CustomSnackbar.style';

/**
 * The custom snackbars used in the applications based on notistack library.
 */
export const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarPropsT>((props, ref) => {
    const { message, variant, snackbarAction } = props;
    const { classes } = useCustomSnackbarStyle();
    const { snackbarIcon, iconBackgroundColor } = useCustomSnackbarLogic(variant);

    return (
        <SnackbarContent ref={ref} role="alert">
            <Card className={classes.card}>
                <BoxedIcon icon={snackbarIcon} style={{ backgroundColor: iconBackgroundColor }}></BoxedIcon>
                <Typography className={classes.typography} variant="body2">
                    {message}
                </Typography>
                {snackbarAction && (
                    <TextLink variant="body2" onClick={snackbarAction.onClick} className={classes.snackbarAction}>
                        {snackbarAction.label}
                    </TextLink>
                )}
            </Card>
        </SnackbarContent>
    );
});
