import { Typography } from '@mui/material';
import { Chip } from '@ui-kit';
import TooltipSc from '@components/TooltipSc/TooltipSc';
import LinkIcon from '@mui/icons-material/Link';
import WaterIcon from '@mui/icons-material/Water';
import NatureIcon from '@mui/icons-material/Nature';
import GrassIcon from '@mui/icons-material/Grass';
import useFieldPopupItemStyle from '@components/FieldPopupItem/FieldPopupItem.style';
import IconEco from '@icons/icon-eco.svg';
import WaterDrop from '@icons/icon-water_drop.svg';
import { FieldPopupItemPropsT } from '@components/FieldPopupItem/FieldPopupItem.types';
import useFieldPopupItemLogic from '@components/FieldPopupItem/FieldPopupItem.logic';
import { useTranslation } from 'react-i18next';

const FieldPopupItem = ({ field, farmSeason, isHighlighted }: FieldPopupItemPropsT) => {
    const {
        fieldArea,
        harvestYearTitle,
        hasAgroforestry,
        hasCoverCrop,
        isDrained,
        isIrrigated,
        isOrganic,
        crops,
        tillagePractice,
    } = useFieldPopupItemLogic({ field, farmSeason });
    const { t } = useTranslation();

    const title = harvestYearTitle;
    const areaStr = `${fieldArea} ha`;
    const subtitle = `${areaStr}${tillagePractice ? ` · ${tillagePractice}` : ''}`;

    const { classes } = useFieldPopupItemStyle();

    const classNames = [classes.fieldInfo];
    if (isHighlighted) {
        classNames.push('is-highlight');
    }

    if (field.is_permanent) {
        return (
            <div className={classes.permanentContainer}>
                <Typography variant="body2" color="primary">
                    {areaStr}
                </Typography>
                {crops?.map((crop) => (
                    <Chip
                        key={crop.key}
                        label={
                            <>
                                {crop.name}{' '}
                                {crop.isAccompanied ? (
                                    <TooltipSc translationSlug="tooltip.field-overview.companion-crop" display="flex">
                                        <LinkIcon fontSize="small" className={classes.linkIcon} />
                                    </TooltipSc>
                                ) : null}
                            </>
                        }
                    />
                ))}
                <Typography>{t('modal.field-overview.permanent-crops.explanation')}</Typography>
            </div>
        );
    }

    return (
        <div className={classNames.join(' ')}>
            <Typography variant="body2" color="secondary">
                <strong>{title}</strong>
            </Typography>
            <Typography variant="body2" color="primary">
                {subtitle}
            </Typography>
            {crops?.map((crop) => (
                <Chip
                    key={crop.key}
                    label={
                        <>
                            {crop.name}{' '}
                            {crop.isAccompanied ? (
                                <TooltipSc translationSlug="tooltip.field-overview.companion-crop" display="flex">
                                    <LinkIcon fontSize="small" className={classes.linkIcon} />
                                </TooltipSc>
                            ) : null}
                        </>
                    }
                />
            ))}

            <div className={classes.characteristicsIconsGroup}>
                {isOrganic && (
                    <TooltipSc translationSlug="tooltip.field-overview.organic">
                        <IconEco className="fill-green" />
                    </TooltipSc>
                )}
                {isDrained && (
                    <TooltipSc translationSlug="tooltip.field-overview.drained">
                        <WaterDrop className="fill-blue" />
                    </TooltipSc>
                )}
                {isIrrigated && (
                    <TooltipSc translationSlug="tooltip.field-overview.irrigated">
                        <WaterIcon className="fill-blue" />
                    </TooltipSc>
                )}
                {hasAgroforestry && (
                    <TooltipSc translationSlug="tooltip.field-overview.agroforestry">
                        <NatureIcon className="fill-green" />
                    </TooltipSc>
                )}
                {hasCoverCrop && (
                    <TooltipSc translationSlug="tooltip.field-overview.cover-crop">
                        <GrassIcon className="fill-green" />
                    </TooltipSc>
                )}
            </div>
        </div>
    );
};

export default FieldPopupItem;
