import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateT } from '@store/store';
import { useTranslation } from 'react-i18next';
import useAppDispatch from '@hooks/useAppDispatch';
import verifyEmail from '@actions/AuthActions/VerifyEmailActions';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';

import { VerifyEmailRouteParamsT } from './VerifyEmailPageTypes';
import VerifyEmailPageView from './VerifyEmailPageView';

const VerifyEmailPage = (): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { t } = useTranslation();
    const loading = useSelector((state: AppStateT) => state.auth.loading);
    const user = useSelector((state: AppStateT) => state.auth.user);
    const [msgOpen, setMsgOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [notice, setNotice] = React.useState(false);
    const { hashedId, expires, signature } = useParams<VerifyEmailRouteParamsT>();

    const sendVerification = async () => {
        if (!hashedId || !expires || !signature) {
            return;
        }

        const response = await dispatch(verifyEmail({ hashedId, expires, signature }));

        if (response.list?.length > 0) {
            setError(true);
        }

        setMsgOpen(true);
    };

    useEffect(() => {
        if (hashedId) sendVerification();
        else setNotice(true);
    }, [hashedId]);

    useEffect(() => {
        let loginRedirectTimeout: NodeJS.Timeout;

        if (msgOpen && !error && !notice) {
            loginRedirectTimeout = setTimeout(() => {
                navigate('/login');
            }, 8000);
        }

        return () => {
            clearTimeout(loginRedirectTimeout);
        };
    }, [msgOpen, error, notice]);

    if (!user) {
        return null;
    }

    return <VerifyEmailPageView error={error} loading={loading} msgOpen={msgOpen} notice={notice} t={t} user={user} />;
};

export default VerifyEmailPage;
