import FarmSeasonFieldsApiService from '@services/farmSeasonFieldsApiService/farmSeasonFieldsApiService';
import { GetFarmSeasonFieldsParamsT } from '@services/farmSeasonFieldsApiService/farmSeasonFieldsApiServiceTypes';
import { AxiosResponse } from 'axios';
import { logoutFarmerOrRedirectAgro } from '../AuthActions/LogoutActions';

import {
    FarmSeasonFieldsGetCallBackT,
    GET_FARM_SEASON_FIELDS_LOADING,
    GET_FARM_SEASON_FIELDS_SUCCESS,
    GetFarmSeasonFieldsResponseDataT,
} from './GetFarmSeasonFieldsTypes';

export const getFarmSeasonFields =
    (farmId: number, farmSeasonId: number, params?: GetFarmSeasonFieldsParamsT): FarmSeasonFieldsGetCallBackT =>
    async (dispatch) => {
        try {
            dispatch({ type: GET_FARM_SEASON_FIELDS_LOADING });
            const res: AxiosResponse<GetFarmSeasonFieldsResponseDataT> = await FarmSeasonFieldsApiService.get(
                farmId,
                farmSeasonId,
                params,
            );

            dispatch({
                type: GET_FARM_SEASON_FIELDS_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            // dispatch({
            //     type: GET_FARM_SEASON_FIELDS_FAILED,
            //     error: error as GetFarmSeasonFieldsResponseErrorT,
            // });

            // // FP-3509 - reset store to avoid corrupted data
            // logout()(dispatch);

            // FP-4233 - redirect agros instead of logout them
            logoutFarmerOrRedirectAgro()(dispatch);

            return {};
        }
    };

export const placeholder = '';
