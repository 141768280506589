import fieldEntity from '@entities/fieldEntity';
import useCurrentFarmSeasonId from '@hooks/currentFarmSeasonId';

const useFieldAmountLabelLogic = () => {
    const { farmSeasonId } = useCurrentFarmSeasonId();
    const { fieldState } = fieldEntity.useState({ farmSeasonId });

    const { fieldAreaHarvested } = fieldState.useFieldAreaHarvested();
    const { fieldAreaPermanent } = fieldState.useFieldAreaPermanent();

    return {
        fieldsCount: fieldState.list.length,
        totalCultivatedArea: fieldAreaHarvested,
        totalPermanentArea: fieldAreaPermanent,
    };
};

export default useFieldAmountLabelLogic;
