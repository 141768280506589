import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Trans } from 'react-i18next';
import ColorPatternCardView from '@components/ColorPatternCard/ColorPatternCardView';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Prompt } from '@components/Prompt/Prompt';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import IconEye from '@mui/icons-material/RemoveRedEyeOutlined';
import IconEdit from '@icons/icon-edit.svg';
import useFieldsGroupListStyle from './FieldsGroupListView.style';
import { FieldsGroupListViewPropsT } from './FieldsGroupListTypes';
import { ModalWarning } from '@soil-capital/ui-kit';
import formatNumber from '@utils/formatNumber';

const FieldsGroupListView = ({
    addCoverCrop,
    t,
    loading,
    deleteCoverCropHistoryGroups,
    modalDeleteGroupedField,
    modalDeleteAllGroupedField,
    deleteCoverCrop,
    editCoverCrop,
    nothingOnFields,
    setNothingOnFields,
    groupedFields,
    onSubmit,
    keyColor,
    nothingToSave,
    setNothingToSave,
    errorNothingOnFields,
    setErrorNothingOnFields,
    canEdit = false,
}: FieldsGroupListViewPropsT): JSX.Element => {
    const { classes } = useFieldsGroupListStyle();

    const groupCardList = () =>
        Object.keys(groupedFields).map((key) => {
            const frequencyLabel = t('contants.frequency-year-label', {
                count: groupedFields[key][0].cover_crop_history?.frequency,
            });
            const yearIntroductionLabel = groupedFields[key][0].cover_crop_history?.year_introduction;
            const label = t('pages.installationtasks.history.cover-crop.field-group-list.button-group-label', {
                frequency: frequencyLabel,
                year: yearIntroductionLabel,
            });
            const fieldLabel = groupedFields[key].length > 1 ? t('constants.fields') : t('constants.field');

            return (
                <ColorPatternCardView
                    key={key}
                    color={keyColor[key]}
                    name={label}
                    onDelete={canEdit ? () => modalDeleteGroupedField.openModal(() => deleteCoverCrop(key)) : undefined}
                    onEdit={() => editCoverCrop(key)}
                    editIcon={canEdit ? IconEdit : IconEye}
                >
                    <>
                        <Typography color="primary" variant="body1">
                            {groupedFields[key].length} {fieldLabel}
                        </Typography>
                        <Typography color="primary" variant="body1">
                            {`${formatNumber(
                                groupedFields[key].reduce((totalArea, field) => totalArea + field.area, 0) as number,
                                3,
                            )} ${t('constants.hectare-unit')}`}
                        </Typography>
                    </>
                </ColorPatternCardView>
            );
        });

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!nothingToSave}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.history.cover-crop.field-group-list.title">
                    Veuillez ajouter les différents <span className={classes.titleDark}>couverts végétaux</span>
                </Trans>
                <HelpButtonView id="pages.installationtasks.history.cover-crop.field-group-list.title" />
            </Typography>
            {Object.keys(groupedFields)?.length > 0 ? (
                <div className={classes.listContainer}>{groupCardList()}</div>
            ) : (
                <div className={classes.emptyFieldContainer}>
                    <Typography color="secondary" variant="body1">
                        {t('pages.installationtasks.history.cover-crop.field-group-list.no-cover-crop-added')}
                    </Typography>
                </div>
            )}

            {canEdit && (
                <Button className={classes.addButton} color="secondary" fullWidth onClick={addCoverCrop}>
                    <AddIcon className={classes.addIcon} />
                    {t('constants.add')}
                </Button>
            )}
            {canEdit && (
                <LabelCheckboxView
                    disabled={!canEdit}
                    border="dotted"
                    checked={nothingOnFields}
                    className={classes.checkbox}
                    error={errorNothingOnFields}
                    helperText={t('pages.installationtasks.history.cover-crop.check-to-confirm')}
                    label={t('pages.installationtasks.history.cover-crop.field-group-list.no-cover-crop')}
                    name="cover-crop-checkbox"
                    onChange={(event, checked) => {
                        if (Object.keys(groupedFields).length > 0) {
                            modalDeleteAllGroupedField.openModal(deleteCoverCropHistoryGroups);
                            setNothingOnFields(false);
                        } else {
                            setNothingOnFields(checked);
                            setNothingToSave(false);
                        }

                        if (checked) {
                            setErrorNothingOnFields(false);
                        }
                    }}
                />
            )}
            <TwoButtonFooterView
                rightDisabled={nothingToSave}
                rightLoading={loading}
                rightOnCLick={onSubmit}
                rightText={canEdit ? t('constants.save') : ''}
            />

            <ModalWarning
                controller={modalDeleteAllGroupedField}
                confirmMessage={t(
                    'pages.installationtasks.history.cover-crop.field-group-list.no-cover-crop-modal-confirm-message',
                )}
                description={t(
                    'pages.installationtasks.history.cover-crop.field-group-list.no-cover-crop-modal-description',
                )}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.save')}
                title={t('pages.installationtasks.history.cover-crop.field-group-list.no-cover-crop-modal-title')}
            />

            <ModalWarning
                controller={modalDeleteGroupedField}
                confirmMessage={t(
                    'pages.installationtasks.history.cover-crop.field-group-list.no-cover-crop-modal-confirm-message-single',
                )}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
            />
        </div>
    );
};

export default FieldsGroupListView;
