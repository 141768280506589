import { makeStyles } from 'tss-react/mui';

const useFieldsTaskStyle = makeStyles()((theme) => ({
    relative: {
        position: 'relative',
        height: '100%',
    },
    container: {
        padding: '0 30px 30px 30px',
    },
    menuBar: {
        marginTop: '15px',
        marginBottom: '40px',
    },
    listItem: {
        fontSize: '1em',
    },
    descriptionsList: {
        borderColor: theme.palette.primary.main,
        overflow: 'auto',
        maxHeight: 'calc(100vh - 400px)',
    },
    descriptionListItem: {
        padding: 'initial',
    },
    buttonImportCap: {
        display: 'flex',
        marginBottom: '20px',
    },
    buttonImportCapHelp: {
        width: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '16px',
    },
    fieldName: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    fieldNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '20px',
    },
    capFileIcon: {
        fontSize: '1rem',
        verticalAlign: 'text-bottom',
        margin: '0 2px 2px 2px',
    },
}));

export default useFieldsTaskStyle;
