import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';

const useFertiliserInputViewStyle = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        '&:hover .deleteTransition': {
            width: '20px',
            transition: 'all .5s',
        },
    },
    icons: {
        fontSize: '24px',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark,
            borderRadius: '100%',
            background: theme.palette.grey[100],
        },
    },
    fertiliserInput: {
        flex: 2,
        transition: 'width .5s',
    },
    deleteButton: {
        width: '0%',
        overflow: 'hidden',
        transition: 'width .5s',
        display: 'flex',
        alignItems: 'center',
        height: '43px',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '43px',
    },
    checkbox: {
        height: '30px',
        width: '30px',
        padding: '2px',
    },
    quantityInput: {
        '& input.MuiInputBase-input': {
            paddingRight: '0px',
            paddingLeft: '10px',
        },
        flex: 1,
    },
    highEmissionBackground: {
        '& .MuiTextField-root .MuiInputBase-root fieldset': {
            backgroundColor: alpha(theme.palette.warning.light, 0.1),
            borderColor: alpha(theme.palette.warning.main, 0.6),
        },
        '& .MuiTextField-root .MuiInputBase-root.Mui-focused fieldset': {
            backgroundColor: alpha(theme.palette.warning.light, 0.1),
            borderColor: alpha(theme.palette.warning.main, 0.6),
        },
        '&:hover .MuiInputBase-root.MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline': {
            backgroundColor: alpha(theme.palette.warning.light, 0.1),
            borderColor: theme.palette.warning.dark,
        },
        '& .MuiButtonBase-root.MuiIconButton-root': {
            color: theme.palette.warning.dark,
        },
    },
}));

export default useFertiliserInputViewStyle;
