import { useSelector } from 'react-redux';
import { useBillingDetailsPaymentFlowPropsT } from './useBillingDetailsPaymentFlow.types';
import selectLatestFarmSeason from '@store/selectors/selectLatestFarmSeason';
import { AppStateT } from '@store/store';
import PaymentApiService from '@services/paymentApiService/paymentApiService';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { BillingDetailsFormRefControllerT } from '../BillingDetailsForm.types';

/**
 * This hook is explicitly designed to be used with `PaymentForm.tsx` \
 * It uses `loadUserCompany` by default when userCompany is undefined which calls the {@link userCompanyApiService.getFirst} API
 * - {@link type} must be used to define the payment logic
 * - {@link userCompany} can be provided to avoid the initialLoading
 * - {@link paymentFormRef} is used as forwardRef such that the parent can control this component
 */
const useBillingDetailsPaymentFlow = ({ type }: useBillingDetailsPaymentFlowPropsT) => {
    const { i18n } = useTranslation();
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason?.id);
    const latestFarmSeasonId = useSelector(selectLatestFarmSeason())?.id;
    const billingDetailsFormRef = useRef<BillingDetailsFormRefControllerT>(null);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    /** This is the definition of the logic that will happen after the submit */
    const onPaymentRedirect = async () => {
        setIsSubmitting(true);
        const values = await billingDetailsFormRef.current?.getFormData();
        if (values) {
            try {
                // Todo: Avoid using type and replace it by providing the backend with proper success and fail redirect url's
                if (type == 'next-season') {
                    const nextSeasonRes = await PaymentApiService.postPaymentDetailNextSeason(
                        farmId,
                        latestFarmSeasonId,
                        { ...values },
                        i18n?.language,
                    );
                    setIsSubmitting(false);
                    return window.location.replace(nextSeasonRes.data.redirectUrl);
                }

                /** We assume the type is either `first`, `second` or `upsell` and this is the default behavior of the logic */
                const res = await PaymentApiService.postPaymentDetail(
                    farmId,
                    farmSeasonId,
                    { ...values },
                    type,
                    i18n?.language,
                );
                setIsSubmitting(false);
                return window.location.replace(res.data.redirectUrl);
            } catch (error: unknown) {
                if ((error as AxiosError<{ error: 'INVALID_TAX_ID' }>).response?.data.error === 'INVALID_TAX_ID') {
                    billingDetailsFormRef.current?.displayError('validation.tax-invalid-or-country-not-match');
                } else {
                    billingDetailsFormRef.current?.displayError('constants.server-error');
                }
                setIsSubmitting(false);
                return false;
            }
        }
        setIsSubmitting(false);
        return false;
    };

    return { billingDetailsFormRef, onPaymentRedirect, isSubmitting };
};

export default useBillingDetailsPaymentFlow;
