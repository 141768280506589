import { getUpdateQueryHelper } from '@api/api2.common';
import { FieldEndpointParamsT, FieldT } from './fieldEntity.types';
import { api2 } from '@api/api2.service';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { FarmSeasonFieldT } from '@store/reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import store from '@store/store';
import { GET_FARM_SEASON_FIELDS_SUCCESS } from '@store/actions/FarmSeasonFieldActions/GetFarmSeasonFieldsTypes';
import fieldCropEntityCache from '../fieldCropEntity/fieldCropEntity.cache';
import EntityError from '../EntityError';

export const fieldCacheAdapter = createEntityAdapter<FieldT>();
const fieldCacheQueryHelper = getUpdateQueryHelper<FieldEndpointParamsT, FieldT, 'Field'>(
    api2,
    'Field',
    fieldCacheAdapter,
);

const add: typeof fieldCacheQueryHelper.add = async (
    { farmSeasonId, body },
    apiContext,
    optimisticStrategy = 'fetch-only',
) => {
    fieldCacheQueryHelper.add({ farmSeasonId, body }, apiContext, optimisticStrategy);

    /* ------------------------ Update Legacy Redux State ----------------------- */
    if (optimisticStrategy === 'cache-then-fetch') {
        throw new EntityError('Field', 'legacy', "can't do optimistic cache-then-fetch due to legacy state");
    }

    let newField: FieldT;
    if (optimisticStrategy === 'cache-only') {
        if (!body?.id) {
            throw new EntityError('Field', 'legacy', 'try to add permanent optimistic but no id');
        }
        newField = body as FieldT;
    } else if (optimisticStrategy === 'fetch-only') {
        const { data } = await apiContext.queryFulfilled;
        newField = data;
    } else {
        throw new EntityError('Field', 'legacy', `unexpected optimistic '${optimisticStrategy}'`);
    }

    const newLegacyFields = [...store.getState().farmSeasonField.fieldsList];
    const newFieldForLegacy: FarmSeasonFieldT = { ...newField, agroforestry: [], field_crops: [] };
    newLegacyFields.push(newFieldForLegacy);

    apiContext.dispatch({
        type: GET_FARM_SEASON_FIELDS_SUCCESS,
        payload: { fields: newLegacyFields },
    });
    /* -------------------------------------------------------------------------- */
};

const update: typeof fieldCacheQueryHelper.update = async (
    { farmSeasonId, body, id },
    apiContext,
    optimisticStrategy = 'fetch-only',
) => {
    fieldCacheQueryHelper.update({ farmSeasonId, body, id }, apiContext, optimisticStrategy);

    /* ------------------------ Update Legacy Redux State ----------------------- */
    if (optimisticStrategy === 'cache-then-fetch') {
        throw new EntityError('Field', 'legacy', "can't do optimistic cache-then-fetch due to legacy state");
    }

    let updatedField: FieldT;
    if (optimisticStrategy === 'cache-only') {
        updatedField = { ...body, id } as FieldT;
    } else if (optimisticStrategy === 'fetch-only') {
        const { data } = await apiContext.queryFulfilled;
        updatedField = data;
    } else {
        throw new EntityError('Field', 'legacy', `unexpected optimistic '${optimisticStrategy}'`);
    }

    const newLegacyFields = [...store.getState().farmSeasonField.fieldsList];
    const index = newLegacyFields.findIndex((f) => f.id === updatedField.id);
    newLegacyFields[index] = { ...newLegacyFields[index], ...updatedField };

    apiContext.dispatch({
        type: GET_FARM_SEASON_FIELDS_SUCCESS,
        payload: { fields: newLegacyFields },
    });
    /* -------------------------------------------------------------------------- */
};

const remove: typeof fieldCacheQueryHelper.remove = async (
    { farmSeasonId, id },
    apiContext,
    optimisticStrategy = 'fetch-only',
) => {
    // Cascade
    fieldCropEntityCache.removeWhere('farm_season_field_id', id, { farmSeasonId }, apiContext, optimisticStrategy);

    fieldCacheQueryHelper.remove({ farmSeasonId, id }, apiContext, optimisticStrategy);

    /* ------------------------ Update Legacy Redux State ----------------------- */
    if (optimisticStrategy === 'fetch-only') {
        await apiContext.queryFulfilled;
    }

    const newLegacyFields = [...store.getState().farmSeasonField.fieldsList];
    const index = newLegacyFields.findIndex((c) => c.id === id);
    newLegacyFields.splice(index, 1);

    apiContext.dispatch({
        type: GET_FARM_SEASON_FIELDS_SUCCESS,
        payload: { fields: newLegacyFields },
    });
    /* -------------------------------------------------------------------------- */
};

export default {
    add,
    update,
    remove,
};
