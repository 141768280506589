import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalFinalizeSeasonViewPropsT } from './ModalFinalizeSeasonTypes';
import { ModalWarning } from '@soil-capital/ui-kit';

const ModalFinalizeSeasonView = ({ modalController }: ModalFinalizeSeasonViewPropsT): JSX.Element => {
    const { t } = useTranslation();

    const seasonList = useSelector((state: HomePageStateT) => state.static.seasons);
    const farmName = useSelector((state: HomePageStateT) => state.farm.currentFarm?.name);
    const farmerName = useSelector((state: HomePageStateT) => state.auth.selectedUser?.fullName);
    const seasonId = useSelector((state: HomePageStateT) => state.farmSeason?.currentFarmSeason?.season_id);
    const season = seasonList?.find((seasonItem) => seasonItem.id === seasonId);

    return (
        <ModalWarning
            controller={modalController}
            confirmMessage={t('pages.homepage.sidebar.admin-panel.finalize-season-confirm', {
                farmName,
                farmerName,
                harvestYear: season?.harvest_year,
            })}
            leftButtonText={t('constants.cancel')}
            rightButtonText={t('constants.confirm')}
        />
    );
};

export default ModalFinalizeSeasonView;
