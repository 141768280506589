import useAppDispatch from '@hooks/useAppDispatch';
import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getBillingDetails } from '@store/actions/BillingDetailsActions/BillingDetailsActions';
import { HomePageStateT } from '../types/HomePageStoreTypes';
import useHomePageLoading from '@hooks/useHomePageLoading';

const useSettingsLogic = () => {
    const dispatch = useAppDispatch();
    const { addPendingState } = useHomePageLoading();
    const userCompany = useSelector((state: AppStateT) => state.billingDetails.userCompany);
    const userId = useSelector((state: HomePageStateT) => state.auth.selectedUser?.id || state.auth.user?.id);

    useEffect(() => {
        addPendingState({ pending: dispatch(getBillingDetails({ userId })), slug: 'getPrice' });
    }, []);

    return {
        conditions: {
            canSeeBillingDetails: Boolean(userCompany),
        },
    };
};

export default useSettingsLogic;
