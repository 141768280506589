import { Badge, Button, Popover, SvgIcon } from '@mui/material';
import IconBell from '@icons/icon-bell.svg';

import useNotificationButtonStyle from './NotificationButton.style';
import { NotificationButtonProps } from './NotificationButtonTypes';

const NotificationButton = ({
    unreadCount,
    children,
    onOpenPanel,
    onClosePanel,
    popoverAnchor,
}: NotificationButtonProps): JSX.Element => {
    const { classes } = useNotificationButtonStyle();

    // TODO remove translation slug pages.homepage.notifications
    return (
        <>
            <Badge badgeContent={unreadCount} color="primary" max={9}>
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={(e) => onOpenPanel(e)}
                >
                    <SvgIcon className={classes.bellIcon} component={IconBell} viewBox="0 0 16 20" />
                </Button>
            </Badge>
            <Popover
                anchorEl={popoverAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.popover}
                open={!!popoverAnchor}
                onClose={onClosePanel}
            >
                {children}
            </Popover>
        </>
    );
};
export default NotificationButton;
