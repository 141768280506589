import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { EditStepLogicPropsT } from './EditStep.types';
import { useEffect, useState } from 'react';
import useAppDispatch from '@hooks/useAppDispatch';
import cropsGet from '@store/actions/StaticActions/CropsGetAction';
import { RestrictedPropsT } from '@components/Restricted/RestrictedTypes';
import cropEntity from '@entities/cropEntity';
import { CropBodyT } from '@entities/cropEntity/cropEntity.types';
import { useIsCFTCarbonModel } from '@hooks/useIsCFTCarbonModel';
import { useIsCropNeedingCropDestinationAssignment } from '@/hooks/useIsCropNeedingCropDestinationAssignment';
import { useGetLatestActiveSeasonId } from '@/hooks/useGetLatestActiveSeasonId';

const useEditStepLogic = ({ popStep, selectedCrop }: EditStepLogicPropsT) => {
    const { t } = useTranslation();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const dispatch = useAppDispatch();
    const staticCrops = useSelector((state: HomePageStateT) => state.static.crops);
    const currentFarm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const latestActiveSeasonId = useGetLatestActiveSeasonId();
    const isCurrentSeasonActive = latestActiveSeasonId === currentFarmSeason?.season_id;

    const isCFT = useIsCFTCarbonModel();
    const isCropNeedingCropDestinationAssignment = isCurrentSeasonActive
        ? useIsCropNeedingCropDestinationAssignment({
              crops: staticCrops,
              cropId: selectedCrop?.crop_id ?? 0,
          })
        : false;
    const [isCropNeedingDestination, setIsCropNeedingDestination] = useState(isCropNeedingCropDestinationAssignment);
    const { cropState } = cropEntity.useState({ farmSeasonId });
    const [createCrop, { isLoading: createCropLoading }] = cropEntity.useCreate();
    const [updateCrop, { isLoading: updateCropLoading }] = cropEntity.useUpdate();

    cropEntity.schema;
    const validationSchema = yup.object().shape({
        id: yup.number(),
        yield: isCFT
            ? yup
                  .number()
                  .nullable()
                  .typeError(t('validation.no-yield-provided'))
                  .min(0, t('validation.yield-minimum-0'))
                  .required(t('validation.no-yield-provided'))
            : yup.number().nullable(),
        is_accompanied: isCFT ? yup.boolean() : yup.boolean().nullable(),
        crop_id: yup.string().required(t('validation.no-crop-selected')),
        crop_destination_percentage: isCropNeedingDestination
            ? yup
                  .number()
                  .nullable()
                  .min(0, t('validation.under-0-destination-percentage'))
                  .max(100, t('validation.above-100-destination-percentage'))
                  .required(t('validation.no-destination-percentage'))
            : yup.number().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            is_accompanied: isCFT ? false : null,
            yield: isCFT ? selectedCrop?.yield : null,
            crop_id: 0,
            crop_destination_percentage: selectedCrop?.crop_destination_percentage ?? null,
            ...selectedCrop,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            if (farmId && farmSeasonId && formik.dirty) {
                if (selectedCrop?.id) {
                    await updateCrop({ farmSeasonId, id: selectedCrop.id, body: values });
                } else {
                    const { crop_id, id, ...data } = values;
                    if (crop_id && !id) {
                        await createCrop({ farmSeasonId, body: { crop_id, ...data } });
                    }
                }
            }
            setSubmitting(false);
            popStep();
        },
        validate: (values: CropBodyT & { id?: number | null }) => {
            const errors: any = {}; // eslint-disable-line

            if (
                !selectedCrop?.id &&
                cropState.list.filter(
                    (crop) => crop.crop_id === values.crop_id && values.is_accompanied === crop.is_accompanied,
                ).length
            ) {
                errors.crop = { id: t('validation.exist-crop') };
                errors.is_accompanied = t('validation.exist-crop');
            }

            return errors;
        },
    });

    useEffect(() => {
        if (!(staticCrops?.length > 0)) dispatch(cropsGet(Number(currentFarm?.country_id)));
    }, []);
    const restrictEditFarmSeasonProps: RestrictedPropsT<'EDIT_FARM-SEASON'> = {
        toPermission: 'EDIT_FARM-SEASON',
        forResource: currentFarmSeason,
        withProps: () => ({ disabled: true }),
    };

    useEffect(() => {
        if (isCurrentSeasonActive) {
            setIsCropNeedingDestination(
                useIsCropNeedingCropDestinationAssignment({
                    crops: staticCrops,
                    cropId: formik.values.crop_id,
                }),
            );
        }
    }, [formik.values.crop_id, staticCrops, currentFarmSeason]);

    return {
        formik: formik,
        hasId: !!selectedCrop?.id,
        loading: createCropLoading || updateCropLoading,
        restrictEditFarmSeasonProps,
        staticCrops,
        isCFT,
        isCropNeedingDestination,
    };
};

export default useEditStepLogic;
