import FarmSeasonFieldsTillageApiService from '@services/farmSeasonFieldsTillageApiService/farmSeasonFieldsTillageApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsTillagePostCallBackT,
    PostFarmSeasonFieldsTillageDataT,
    PostFarmSeasonFieldsTillageDispatchTypesT,
    PostFarmSeasonFieldsTillageResponseDataT,
    PostFarmSeasonFieldsTillageResponseErrorT,
    POST_FARM_SEASON_FIELDS_TILLAGE_FAILED,
    POST_FARM_SEASON_FIELDS_TILLAGE_LOADING,
    POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS,
} from './PostFarmSeasonFieldsTillageTypes';

export const postFarmSeasonFieldsTillage =
    (
        farmId: number,
        farmSeasonId: number,
        body: PostFarmSeasonFieldsTillageDataT,
    ): FarmSeasonFieldsTillagePostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldsTillageDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldsTillageResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_TILLAGE_LOADING });
            const res: AxiosResponse<PostFarmSeasonFieldsTillageResponseDataT> =
                await FarmSeasonFieldsTillageApiService.post(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_FIELDS_TILLAGE_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_TILLAGE_FAILED,
                error: error as PostFarmSeasonFieldsTillageResponseErrorT,
            });

            return {};
        }
    };

export const placeholder = '';
