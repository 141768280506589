import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Optional } from '@/utils/OptionalType';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_FIELDS_CROPS_LOADING = 'POST_FARM_SEASON_FIELDS_CROPS_LOADING';
export const POST_FARM_SEASON_FIELDS_CROPS_FAILED = 'POST_FARM_SEASON_FIELDS_CROPS_FAILED';
export const POST_FARM_SEASON_FIELDS_CROPS_SUCCESS = 'POST_FARM_SEASON_FIELDS_CROPS_SUCCESS';

export type PostFarmSeasonFieldsCropsDataT = {
    fields: Optional<FarmSeasonFieldT, 'id'>[];
};

export type PostFarmSeasonFieldsCropsResponseDataT = {
    fields: FarmSeasonFieldT[];
    installation: {
        onboarding: {
            finished: string[];
            field: {
                done: boolean;
            };
        };
    };
};

export type FarmSeasonFieldsCropsPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonFieldsCropsDispatchTypesT>,
) => Promise<boolean>;

export type PostFarmSeasonFieldsCropsResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonFieldsCropsLoadingT = {
    type: typeof POST_FARM_SEASON_FIELDS_CROPS_LOADING;
};

export type PostFarmSeasonFieldsCropsFailedT = {
    type: typeof POST_FARM_SEASON_FIELDS_CROPS_FAILED;
    error: PostFarmSeasonFieldsCropsResponseErrorT;
};

export type PostFarmSeasonFieldsCropsSuccessT = {
    type: typeof POST_FARM_SEASON_FIELDS_CROPS_SUCCESS;
    payload: PostFarmSeasonFieldsCropsResponseDataT;
};

export type PostFarmSeasonFieldsCropsDispatchTypesT =
    | PostFarmSeasonFieldsCropsLoadingT
    | PostFarmSeasonFieldsCropsFailedT
    | PostFarmSeasonFieldsCropsSuccessT;
