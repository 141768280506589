import { EntityId } from '@reduxjs/toolkit';
import fieldCropEntityApi from './fieldCropEntity.api';
import { fieldCropCacheAdapter } from './fieldCropEntity.cache';
import { FieldCropEndpointParamsT, FieldCropT } from './fieldCropEntity.types';
import { useMemo } from 'react';

export default ({ farmSeasonId }: FieldCropEndpointParamsT) => {
    const { data = fieldCropCacheAdapter.getInitialState(), isLoading } = fieldCropEntityApi.useGet({
        farmSeasonId,
    });

    /* ------------------------------ Selectors ----------------------------- */
    const { list, getById } = useMemo(() => {
        const { selectAll, selectById } = fieldCropCacheAdapter.getSelectors();
        return {
            list: selectAll(data),
            getById: (id: EntityId) => selectById(data, id),
        };
    }, [data]);

    const getByIds = (fieldIds: number[]) =>
        fieldIds.map((cropId) => getById(cropId)).filter((c) => !!c) as FieldCropT[];

    const getByCropId = ({ cropId }: { cropId: number }) => {
        return (
            (list.filter((fieldCrop) => fieldCrop && fieldCrop.farm_season_crop_id === cropId) as FieldCropT[]) ?? []
        );
    };

    const getByFieldId = ({ fieldId }: { fieldId: number }) => {
        return (
            (list.filter((fieldCrop) => fieldCrop && fieldCrop.farm_season_field_id === fieldId) as FieldCropT[]) ?? []
        );
    };

    return {
        fieldCropState: {
            list,
            isLoading,
            getByCropId,
            getByFieldId,
            getById,
            getByIds,
        },
    };
};
