import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import HomePageView from './HomePageView';
import { appInitDataForFarmSeason } from '@actions/AppActions/AppActions';
import { AppStateT } from '@store/store';

const HomePage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentFarmSeason = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason);

    useEffect(() => {
        if (currentFarmSeason?.id) {
            dispatch(appInitDataForFarmSeason());
        }
    }, [currentFarmSeason?.id]);

    // Manage Sidebar
    // ==============
    const sideBarRef = useRef<HTMLElement | null>(null);

    return <HomePageView sideBarRef={sideBarRef} />;
};

export default HomePage;
