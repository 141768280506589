import { shapeEntity } from '../shapeEntity';
import cropEntityApi, { cropApi } from './cropEntity.api';
import cropEntitySchema from './cropEntity.schema';
import cropEntityState from './cropEntity.state';

export default shapeEntity('Crop', {
    api: cropApi,
    schema: cropEntitySchema,
    ...cropEntityApi,
    useState: cropEntityState,
});
