import CardFlow from './components/CardFlow/CardFlow';
import { CardFlowNavigationContainerPropsT } from './CardFlowNavigationContainer.types';
import { useTranslation } from 'react-i18next';
import slugStateMapping from './slugStateMappings';
import useCardFlowNavigationContainerLogic from './CardFlowNavigationContainer.logic';
import { useIsReadOnlyUser } from '@/hooks/usePermissions';

/**
 * Card with navigation to the current user step depending on his flow state
 */
const CardFlowNavigationContainer = ({ cardSx }: CardFlowNavigationContainerPropsT) => {
    const { handleActionClick, currentState, nextFarmSeasonIsLoading } = useCardFlowNavigationContainerLogic();
    const { t } = useTranslation();
    const isReadOnlyUser = useIsReadOnlyUser();

    // State does not exist, we don't display the CardFlow.
    // If state is null and nextFarmSeason isn't loading, we don't display the CardFlow
    if (currentState === false || (currentState === null && !nextFarmSeasonIsLoading)) {
        return null;
    }

    return (
        <CardFlow
            description={currentState ? t(slugStateMapping[currentState].description) : ''}
            title={currentState ? t(slugStateMapping[currentState].title) : ''}
            cardSx={cardSx}
            action={
                currentState
                    ? slugStateMapping[currentState].button
                        ? {
                              text: t(slugStateMapping[currentState].button ?? ''),
                              onClick: () => handleActionClick(currentState),
                          }
                        : undefined
                    : undefined
            }
            loading={nextFarmSeasonIsLoading}
            minHeight="60px"
            disabled={
                isReadOnlyUser &&
                (currentState === 'renewal-new-season-to-start' ||
                    currentState === 'renewal-payment-required' ||
                    currentState === 'renewal-payment-in-progress')
            }
        />
    );
};

export default CardFlowNavigationContainer;
