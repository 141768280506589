import { makeStyles } from 'tss-react/mui';

import { EndWrapperStyleProps } from './EndWrapperTypes';

const useEndWrapperStyle = makeStyles<EndWrapperStyleProps>()((theme, { titleColor, justifyContent }) => ({
    root: {
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: justifyContent || 'center',
        alignItems: 'center',
    },
    title: {
        color: theme.palette[titleColor].main,
        fontSize: '26px',
        marginTop: justifyContent === 'start' ? '60px' : '0',
    },
    body: {
        margin: justifyContent === 'start' ? '50px -10px 30px -10px' : '20px auto 30px auto',
    },
    mainButton: {
        marginBottom: '20px',
    },
    secodaryButton: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

export default useEndWrapperStyle;
