import { Typography } from '@mui/material';
import useChipStyle from './Chip.style';
import { ChipPropsT } from './Chip.types';

const Chip = ({ label }: ChipPropsT) => {
    const { classes } = useChipStyle();
    return (
        <Typography variant="body2" className={classes.customChip}>
            {label}
        </Typography>
    );
};

export default Chip;
