import useCelebrationNotificationContainerLogic from './CelebrationNotificationContainer.logic';
import CertificatesGeneratedModal from './CertificatesGeneratedModal/CertificatesGeneratedModal';

const CelebrationNotificationContainer = () => {
    const { celebrationNotification, readCelebration } = useCelebrationNotificationContainerLogic();

    if (celebrationNotification?.data.celebrationType === 'CERTIFICATES_GENERATED') {
        return <CertificatesGeneratedModal onReadCelebration={readCelebration} />;
    }
    return null;
};

export default CelebrationNotificationContainer;
