import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Button, Typography, useTheme } from '@mui/material';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Prompt } from '@components/Prompt/Prompt';
import { Trans } from 'react-i18next';
import HelpButtonView from '@components/HelpButton/HelpButtonView';

import useSelectFieldStepStyle from './IrrigatedFieldsSelectView.style';
import { IrrigatedFieldsSelectViewPropsT } from './IrrigatedFieldsSelectTypes';
import SelectedFieldsContainer from '@components/SelectedFieldsContainer/SelectedFieldsContainer';
import { ModalWarning } from '@soil-capital/ui-kit';
import CallToActionSuggestion from '@pages/HomePage/Installation/components/CallToActionSuggestion/CallToActionSuggestion';
import { useSelector } from 'react-redux';
import selectIsYoySeason from '@store/selectors/selectIsYoySeason';
import useFieldSuggestion from '@pages/HomePage/Installation/hooks/useFieldSuggestion';
import { useDispatch } from 'react-redux';
import { storeFieldSuggestion } from '@reducers/Onboarding/Suggestion.slice';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import LoadingButton from '@components/LoadingButton/LoadingButton';

const IrrigatedFieldsSelectView = ({
    deleteAll,
    modalDeleteField,
    fieldLoading,
    formik,
    isSubmitted,
    modalDeleteSelection,
    onSelectedFieldDelete,
    onSelectAll,
    t,
    handleNavigation,
}: IrrigatedFieldsSelectViewPropsT): JSX.Element => {
    const { classes } = useSelectFieldStepStyle();
    const isYoYSeason = useSelector(selectIsYoySeason());
    const dispatch = useDispatch();
    const { handleBringBackSuggestion, needAttentionFields, suggestedFields, suggestionDone } = useFieldSuggestion({
        suggestionType: 'irrigation',
        onSuggestionDataFetched: (data) => dispatch(storeFieldSuggestion({ type: 'irrigation', data })),
    });
    const theme = useTheme();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <>
            <div className={classes.container}>
                <Prompt
                    messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                    messageText={t('modal.navigation-prompt-if-unsaved.text')}
                    when={!isSubmitted}
                />
                <Typography className={classes.title} color="secondary" variant="h4">
                    <Trans i18nKey="pages.installationtasks.fields-subtasks.irrigation-task.select-irrigated-fields">
                        Veuillez sélectionner <span style={{ color: theme.palette.secondary.dark }}>sur la carte</span>{' '}
                        les champs qui sont irrigués
                    </Trans>
                    <HelpButtonView id="pages.installationtasks.fields-subtasks.irrigation-task.select-irrigated-fields" />
                </Typography>

                {canEditFarmSeason.access && isYoYSeason && (
                    <CallToActionSuggestion
                        suggestion={{
                            needAttentionCount: needAttentionFields.length,
                            suggestedCount: suggestedFields.length,
                            done: suggestionDone,
                        }}
                        onClick={handleBringBackSuggestion}
                    />
                )}

                <SelectedFieldsContainer
                    disabled={!canEditFarmSeason.access}
                    suggestedFields={suggestedFields}
                    needAttentionFields={needAttentionFields}
                    handleRemoveSelectedPolygon={onSelectedFieldDelete}
                />
                <LabelCheckboxView
                    disabled={!canEditFarmSeason.access}
                    border="dotted"
                    checked={!formik.values.irrigation}
                    error={formik.touched.irrigation && Boolean(formik.errors.irrigation)}
                    helperText={formik.touched.irrigation ? formik.errors.irrigation : undefined}
                    label={t('pages.installationtasks.fields-subtasks.irrigation-task.no-irrigation')}
                    name="irrigation-checkbox"
                    onChange={(event, checked) => {
                        if (formik.values.fields.length) {
                            modalDeleteSelection.openModal(() => {
                                deleteAll?.();
                                formik.setFieldValue('irrigation', !checked);
                            });
                        } else {
                            formik.setFieldValue('irrigation', !checked);
                        }
                    }}
                />
                <TwoButtonFooterView
                    leftOnClick={onSelectAll}
                    leftText={canEditFarmSeason.access ? t('constants.select-all-fields') : ''}
                    rightButtonOverride={
                        canEditFarmSeason.access ? (
                            <LoadingButton
                                text={t('constants.save')}
                                onClick={() => formik.handleSubmit()}
                                loading={fieldLoading}
                            />
                        ) : (
                            <Button onClick={() => handleNavigation(formik.values.fields.length)}>
                                {t('constants.continue')}
                            </Button>
                        )
                    }
                />
            </div>
            <ModalWarning
                controller={modalDeleteSelection}
                confirmMessage={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.confirm-message')}
                description={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.title')}
            />
            <ModalWarning
                controller={modalDeleteField}
                confirmMessage={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.confirm-message')}
                description={t('pages.installationtasks.fields-subtasks.irrigation-task.delete-irrigated-field')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.fields-subtasks.organic-task.delete-modal.title')}
            />
        </>
    );
};

export default IrrigatedFieldsSelectView;
