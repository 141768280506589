/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import tillages from '@constants/tillages';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import fertilisersGet from '@actions/StaticActions/FertilisersGetAction';
import { setupCropPaletteFromCropLegacy } from '@utils/setupCropPalette';
import useMapFieldReset from '@hooks/useMapFieldReset';
import MapService from '@services/mapService/mapService';
import addPolygonData from '@utils/addPolygonData';
import farmSeasonOperationApiService from '@services/farmSeasonOperationApiService/farmSeasonOperationApiService';
import { postFarmSeasonOperationFertilising } from '@actions/FarmSeasonOperation/PostFarmSeasonOperationFertilising';

import { FertilisationTaskDataT, FertilisationTaskRouteParamsT, SelectedExceptionT } from './FertilisationTaskTypes';
import FertilisationTaskView from './FertilisationTaskView';
import ExceptionTask from './ExceptionTask/ExceptionTask';
import { useCanEditFarmOperations } from '@hooks/usePermissions';

// TODO: Change fields_ids management, backend should take care of baseline always and baseline should never send fields_ids
const FertilisationTask = (): JSX.Element => {
    const { cropId, tillageId } = useParams<FertilisationTaskRouteParamsT>();
    const [operationData, setOperationData] = useState<FertilisationTaskDataT | null>(null);
    const [operationDataLoading, setOperationDataLoading] = useState(true);
    const { t } = useTranslation();
    const farmSeasonId = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason?.id);
    const farmId = useSelector((store: HomePageStateT) => store.farm.currentFarm?.id);
    const countryId = useSelector((store: HomePageStateT) => store.farm.currentFarm?.country_id);
    const fertilisersLoading = useSelector((store: HomePageStateT) => store.fertiliser.fertilisersLoading);
    const crops = useSelector((store: HomePageStateT) => store.farmSeasonCrop?.cropsList);
    const fields = useSelector((store: HomePageStateT) => store.farmSeasonField?.fieldsList);
    const filteredFields = fields?.filter(
        (field) =>
            field.tillage_id === parseInt(tillageId || '', 10) &&
            field.field_crops?.filter((field_crop) => field_crop.farm_season_crop.id === parseInt(cropId || '', 10))
                .length,
    );
    const [showCollapseId, setShowColapseId] = useState('mineral-liquid');
    const fertilisers = useSelector((store: HomePageStateT) => store.fertiliser.fertilisersList);
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const [selectedException, setSelectedException] = useState<SelectedExceptionT | null>(null);
    const [submitted, setSubmitted] = useState(false);
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditOperations } = useCanEditFarmOperations(currentFarmSeason);

    const cropColors = setupCropPaletteFromCropLegacy(crops);
    const resetMap = useMapFieldReset();

    useEffect(() => {
        const getOperationData = async () => {
            setOperationDataLoading(true);
            const res = await farmSeasonOperationApiService.get(farmId, farmSeasonId, 'fertilising', tillageId, cropId);
            const operationDataRes = res.data;
            const operationFieldsIds = [...(operationDataRes.baseline?.fields_ids || [])];
            operationDataRes.exceptions?.forEach((exception) => {
                exception.fields_ids.forEach((field_id) => {
                    operationFieldsIds.push(field_id);
                });
            });
            const newFieldsIds = fields
                .filter((field) => !operationFieldsIds.includes(field.id))
                .map((field) => field.id);

            if (operationDataRes.baseline?.fields_ids) {
                operationDataRes.baseline.fields_ids = [
                    ...(operationDataRes.baseline?.fields_ids || []),
                    ...newFieldsIds,
                ];
            }
            setOperationData(operationDataRes ?? null);
            setOperationDataLoading(false);
        };

        getOperationData();
    }, []);

    useEffect(() => {
        dispatch(fertilisersGet(parseInt(countryId), farmId));
    }, [countryId]);

    useEffect(() => {
        MapService.addPolygons({
            polygons: filteredFields?.map((field) => addPolygonData({ field, colorPalette: cropColors })),
            autofocus: true,
        });

        return () => {
            resetMap(true);
        };
    }, [filteredFields, cropColors]);

    useEffect(() => {
        if (selectedException) {
            const remainingFieldsIds = [
                ...(selectedException.exception?.fields_ids || []),
                ...selectedException.remainingFields,
            ];
            const remainingFields = filteredFields.filter((filteredField) =>
                remainingFieldsIds.find((id) => id === filteredField.id),
            );

            MapService.addPolygons({
                polygons: remainingFields?.map((field) => addPolygonData({ field })),
                autofocus: true,
            });

            return () => {
                resetMap(true);
            };
        }

        return undefined;
    }, [selectedException]);

    const validationSchema = yup.object().shape({
        baseline: yup.object().shape({
            dontHaveAny: yup
                .bool()
                .test(
                    'check-dont-have-any',
                    t('validation.check-donthaveany-if-no-fertilisation-operation'),
                    (dontHaveAny, context) => {
                        if (
                            context.parent.mineral_liquid.passage === null &&
                            context.parent.mineral_solid.passage === null &&
                            context.parent.organic_liquid.passage === null &&
                            context.parent.organic_solid.passage === null &&
                            context.parent.other_liquid.passage === null &&
                            context.parent.other_solid.passage === null &&
                            dontHaveAny === false
                        ) {
                            return false;
                        }
                        return true;
                    },
                ),
            mineral_liquid: yup.object().shape(
                {
                    passage: yup.number().when('machinery_fertilisers', {
                        is: (values: any) =>
                            values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                        then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                        otherwise: yup.number().nullable(true),
                    }),
                    machinery_fertilisers: yup.array().when('passage', {
                        is: (value: any) => value > 0,
                        then: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    fertiliser: yup
                                        .object()
                                        .typeError(t('validation.no-fertiliser'))
                                        .shape({
                                            id: yup
                                                .number()
                                                .typeError(t('validation.no-fertiliser'))
                                                .min(1, t('validation.no-fertiliser')),
                                        }),
                                    quantity: yup
                                        .number()
                                        .typeError(t('validation.no-quantity'))
                                        .min(0.1, t('validation.no-quantity')),
                                }),
                            )
                            .min(1, t('validation.no-product')),
                    }),
                },
                [['passage', 'machinery_fertilisers']],
            ),
            mineral_solid: yup.object().shape(
                {
                    passage: yup.number().when('machinery_fertilisers', {
                        is: (values: any) =>
                            values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                        then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                        otherwise: yup.number().nullable(true),
                    }),
                    machinery_fertilisers: yup.array().when('passage', {
                        is: (value: any) => value > 0,
                        then: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    fertiliser: yup
                                        .object()
                                        .typeError(t('validation.no-fertiliser'))
                                        .shape({
                                            id: yup
                                                .number()
                                                .typeError(t('validation.no-fertiliser'))
                                                .min(1, t('validation.no-fertiliser')),
                                        }),
                                    quantity: yup
                                        .number()
                                        .typeError(t('validation.no-quantity'))
                                        .min(0.1, t('validation.no-quantity')),
                                }),
                            )
                            .min(1, t('validation.no-product')),
                    }),
                },
                [['passage', 'machinery_fertilisers']],
            ),
            organic_liquid: yup.object().shape(
                {
                    passage: yup.number().when('machinery_fertilisers', {
                        is: (values: any) =>
                            values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                        then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                        otherwise: yup.number().nullable(true),
                    }),
                    machinery_fertilisers: yup.array().when('passage', {
                        is: (value: any) => value > 0,
                        then: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    fertiliser: yup
                                        .object()
                                        .typeError(t('validation.no-fertiliser'))
                                        .shape({
                                            id: yup
                                                .number()
                                                .typeError(t('validation.no-fertiliser'))
                                                .min(1, t('validation.no-fertiliser')),
                                        }),
                                    quantity: yup
                                        .number()
                                        .typeError(t('validation.no-quantity'))
                                        .min(0.1, t('validation.no-quantity')),
                                }),
                            )
                            .min(1, t('validation.no-product')),
                    }),
                },
                [['passage', 'machinery_fertilisers']],
            ),
            organic_solid: yup.object().shape(
                {
                    passage: yup.number().when('machinery_fertilisers', {
                        is: (values: any) =>
                            values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                        then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                        otherwise: yup.number().nullable(true),
                    }),
                    machinery_fertilisers: yup.array().when('passage', {
                        is: (value: any) => value > 0,
                        then: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    fertiliser: yup
                                        .object()
                                        .typeError(t('validation.no-fertiliser'))
                                        .shape({
                                            id: yup
                                                .number()
                                                .typeError(t('validation.no-fertiliser'))
                                                .min(1, t('validation.no-fertiliser')),
                                        }),
                                    quantity: yup
                                        .number()
                                        .typeError(t('validation.no-quantity'))
                                        .min(0.1, t('validation.no-quantity')),
                                }),
                            )
                            .min(1, t('validation.no-product')),
                    }),
                },
                [['passage', 'machinery_fertilisers']],
            ),
            other_liquid: yup.object().shape(
                {
                    passage: yup.number().when('machinery_fertilisers', {
                        is: (values: any) =>
                            values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                        then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                        otherwise: yup.number().nullable(true),
                    }),
                    machinery_fertilisers: yup.array().when('passage', {
                        is: (value: any) => value > 0,
                        then: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    fertiliser: yup
                                        .object()
                                        .typeError(t('validation.no-fertiliser'))
                                        .shape({
                                            id: yup
                                                .number()
                                                .typeError(t('validation.no-fertiliser'))
                                                .min(1, t('validation.no-fertiliser')),
                                        }),
                                    quantity: yup
                                        .number()
                                        .typeError(t('validation.no-quantity'))
                                        .min(0.1, t('validation.no-quantity')),
                                }),
                            )
                            .min(1, t('validation.no-product')),
                    }),
                },
                [['passage', 'machinery_fertilisers']],
            ),
            other_solid: yup.object().shape(
                {
                    passage: yup.number().when('machinery_fertilisers', {
                        is: (values: any) =>
                            values?.filter((value: any) => value.fertiliser?.id || value.quantity > 0).length > 0,
                        then: yup.number().typeError(t('validation.no-passage')).min(0, t('validation.no-passage')),
                        otherwise: yup.number().nullable(true),
                    }),
                    machinery_fertilisers: yup.array().when('passage', {
                        is: (value: any) => value > 0,
                        then: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    fertiliser: yup
                                        .object()
                                        .typeError(t('validation.no-fertiliser'))
                                        .shape({
                                            id: yup
                                                .number()
                                                .typeError(t('validation.no-fertiliser'))
                                                .min(1, t('validation.no-fertiliser')),
                                        }),
                                    quantity: yup
                                        .number()
                                        .typeError(t('validation.no-quantity'))
                                        .min(0.1, t('validation.no-quantity')),
                                }),
                            )
                            .min(1, t('validation.no-product')),
                    }),
                },
                [['passage', 'machinery_fertilisers']],
            ),
        }),
    });

    const postData = async (values: FertilisationTaskDataT) => {
        await dispatch(
            postFarmSeasonOperationFertilising(farmId, farmSeasonId, {
                ...values,
                farm_season_crop_id: parseInt(cropId || '', 10),
                tillage_id: parseInt(tillageId || '', 10),
            }),
        );
    };

    const formik = useFormik({
        initialValues: operationData?.baseline?.id
            ? operationData
            : {
                  baseline: {
                      id: 0,
                      fields_ids: filteredFields?.map((field) => field.id),
                      dontHaveAny: false,
                      mineral_liquid: {
                          passage: null,
                          machinery_fertilisers: [],
                      },
                      mineral_solid: {
                          passage: null,
                          machinery_fertilisers: [],
                      },
                      organic_liquid: {
                          passage: null,
                          machinery_fertilisers: [],
                      },
                      organic_solid: {
                          passage: null,
                          machinery_fertilisers: [],
                      },
                      other_liquid: {
                          passage: null,
                          machinery_fertilisers: [],
                      },
                      other_solid: {
                          passage: null,
                          machinery_fertilisers: [],
                      },
                  },
                  exceptions: [],
              },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: FertilisationTaskDataT, { setSubmitting }) => {
            setSubmitted(true);
            await postData(values);
            setSubmitting(false);
            navigate(`/operations/${cropId}/${tillageId}`);
        },
    });

    useEffect(() => {
        if (formik.dirty) {
            window.onbeforeunload = () => t('pages.installationtasks.add-field-task.before-leave');
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [formik.dirty]);

    const menuTitle = `${t(
        crops?.find((crop) => crop.id === parseInt(cropId || '', 10))?.crop?.translation_slug || '',
    )} - ${t(tillages?.find((tillage) => tillage.id === parseInt(tillageId || '', 10))?.name || '')}`;

    const addException = (newException: any) => {
        formik.setValues((oldValues) => {
            const newExceptions = [...(oldValues?.exceptions || []), { ...newException }];

            const newBaseline = {
                ...oldValues.baseline,
                fields_ids: [
                    ...(filteredFields || [])
                        .filter(
                            (filteredField) =>
                                !newExceptions.filter((exception) =>
                                    exception.fields_ids.find((field_id: any) => field_id === filteredField.id),
                                ).length,
                        )
                        .map((filteredField) => filteredField.id),
                ],
            };

            return {
                ...oldValues,
                baseline: newBaseline,
                exceptions: newExceptions,
            };
        });
    };

    const modifyException = (oldIndex: any, newException: any) => {
        formik.setValues((oldValues) => {
            const newExceptions = [
                ...(oldValues.exceptions || []).map((exception, index) => {
                    if (oldIndex === index) {
                        return { ...newException };
                    }

                    return {
                        ...exception,
                    };
                }),
            ];

            const newBaseline = {
                ...oldValues.baseline,
                fields_ids: [
                    ...(filteredFields || [])
                        .filter(
                            (filteredField: any) =>
                                !newExceptions.filter((exception) =>
                                    exception.fields_ids.find((field_id: number) => field_id === filteredField.id),
                                ).length,
                        )
                        .map((filteredField: any) => filteredField.id),
                ],
            };

            return {
                ...oldValues,
                baseline: newBaseline,
                exceptions: newExceptions,
            };
        });
    };

    if (selectedException) {
        return (
            <ExceptionTask
                addException={addException}
                fertilisers={fertilisers}
                menuTitle={menuTitle}
                modifyException={modifyException}
                selectedException={selectedException}
                setSelectedException={setSelectedException}
                canEdit={canEditOperations.access}
            />
        );
    }

    return (
        <FertilisationTaskView
            dataLoading={operationDataLoading || fertilisersLoading}
            farmId={farmId}
            farmSeasonId={farmSeasonId}
            fertilisers={fertilisers}
            filteredFields={filteredFields}
            formik={formik}
            menuTitle={menuTitle}
            selectedFields={filteredFields?.filter(
                (filteredField) =>
                    (!operationDataLoading &&
                        formik.values.baseline?.fields_ids?.find((id) => id === filteredField.id)) ||
                    formik.values.baseline?.dontHaveAny,
            )}
            setSelectedException={setSelectedException}
            setShowColapseId={setShowColapseId}
            showCollapseId={showCollapseId}
            submitted={submitted}
            canEdit={canEditOperations.access}
        />
    );
};

export default FertilisationTask;
