import RequiredInputLabel from '@components/RequiredInputLabel/RequiredInputLabel';

import useLabelInputWrapperStyle from './LabelInputWrapperView.style';
import { LabelInputWrapperViewPropsT } from './LabelInputWrapperViewTypes';

const LabelInputWrapperView = ({
    id,
    label,
    helpName,
    required,
    withHelp,
    children,
    className,
    tooltipSlug,
}: LabelInputWrapperViewPropsT): JSX.Element => {
    const { classes } = useLabelInputWrapperStyle();

    return (
        <div className={className}>
            <RequiredInputLabel
                className={classes.label}
                helpName={helpName}
                htmlFor={id}
                id={id}
                required={required}
                tooltipSlug={tooltipSlug}
                withHelp={withHelp}
            >
                {label}
            </RequiredInputLabel>
            {children}
        </div>
    );
};

export default LabelInputWrapperView;
