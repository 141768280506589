/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';

import { DndListItemPropsT, CARD, itemType } from './DndListItemTypes';
import DndListItemView from './DndListItemView';

const DndListItem = ({
    text,
    isAssociated = false,
    canAssociat = false,
    index,
    defineMoveState,
    moveState,
    moveCrop,
    canDrag,
    onCoupleClick,
}: DndListItemPropsT): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<any, any, any>({
        accept: CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: itemType) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            if (moveState?.dragIndex === dragIndex && moveState?.hoverIndex === hoverIndex) {
                return;
            }

            defineMoveState({ dragIndex, hoverIndex });
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: CARD,
        canDrag,
        item: () => ({ index, text }),
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () =>
            moveState &&
            moveState?.hoverIndex >= 0 &&
            moveState?.dragIndex >= 0 &&
            moveCrop(index, moveState.hoverIndex),
    });

    return (
        <DndListItemView
            canAssociat={canAssociat}
            canDrag={canDrag}
            drag={drag}
            drop={drop}
            handlerId={handlerId}
            index={index}
            isAssociated={isAssociated}
            isDragging={isDragging}
            moveState={moveState}
            reference={ref}
            text={text}
            onCoupleClick={onCoupleClick}
        />
    );
};

export default DndListItem;
