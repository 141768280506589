import CheckedButtonView from '@components/CheckedButton/CheckedButtonView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import { Typography, useTheme } from '@mui/material';
import { useCanEditFarmSeason } from '@hooks/usePermissions';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SelectedFieldsListViewPropsT } from './SelectedFieldsListTypes';
import useSelectedFieldsListStyle from './SelectedFieldsListView.style';
import formatNumber from '@utils/formatNumber';

const IrrigatedFieldListView = ({
    agroforestryFields,
    getTotalTree,
    onConfirmSelectedField,
    onFieldClick,
    t,
}: SelectedFieldsListViewPropsT): JSX.Element => {
    const { classes } = useSelectedFieldsListStyle();
    const theme = useTheme();
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { canEditFarmSeason } = useCanEditFarmSeason(currentFarmSeason);

    return (
        <div className={classes.container}>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.fields-subtasks.agroforestry.add-types-of-trees">
                    <span style={{ color: theme.palette.secondary.dark }}>Pour chaque parcelle,</span> veuillez ajouter
                    les différents types d&apos;arbres
                </Trans>
            </Typography>
            <div className={classes.listContainer}>
                {agroforestryFields?.map((agroforestryField) => (
                    <CheckedButtonView
                        key={`${agroforestryField.id}-${String(agroforestryField.name)}`}
                        done={
                            (agroforestryField?.agroforestry?.length && agroforestryField.agroforestry.length > 0) ||
                            false
                        }
                        name={t(agroforestryField.name)}
                        onClick={() => onFieldClick(agroforestryField)}
                    >
                        {agroforestryField?.agroforestry?.length && agroforestryField?.agroforestry.length > 0 ? (
                            <>
                                <Typography color="primary" variant="body1">
                                    {`${formatNumber(agroforestryField.area, 3)} ${t('constants.hectare-unit')}`}
                                </Typography>
                                <Typography color="primary" variant="body1">
                                    {agroforestryField?.agroforestry.length === 1
                                        ? t('pages.installationtasks.fields-subtasks.agroforestry.type-of-tree', {
                                              x: agroforestryField.agroforestry.length,
                                          })
                                        : t('pages.installationtasks.fields-subtasks.agroforestry.type-of-trees', {
                                              x: agroforestryField.agroforestry.length,
                                          })}
                                </Typography>
                                <Typography color="primary" variant="body1">
                                    <>
                                        {getTotalTree(agroforestryField)}&nbsp;
                                        {getTotalTree(agroforestryField) === 1
                                            ? t('pages.installationtasks.fields-subtasks.agroforestry.tree')
                                            : t('pages.installationtasks.fields-subtasks.agroforestry.trees')}
                                    </>
                                </Typography>
                            </>
                        ) : (
                            <></>
                        )}
                    </CheckedButtonView>
                ))}
            </div>
            <TwoButtonFooterView
                rightOnCLick={onConfirmSelectedField}
                rightText={canEditFarmSeason.access ? t('constants.confirm') : ''}
            />
        </div>
    );
};

export default IrrigatedFieldListView;
