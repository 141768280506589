/* eslint-disable @typescript-eslint/no-explicit-any */
import { uniqueId } from 'lodash';

class ObserverService<T extends Record<string, any>> {
    /**
     * store the subscribers by subscription names and how to trigger them
     * {[subscriptionName]: {[subscriber]: dispatchToSubscriber}}
     */
    protected _subscriptions: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [SubscriptionNameT in keyof T]?: { [SubscriberT: string]: (data: T[SubscriptionNameT]) => void };
    } = {};

    /**
     * trigger all subscribers for a specific event
     * @param subscriptionName the event for which we want to trigger subscriber actions
     * @param data the data passed to the actions triggered
     */
    notify = <K extends keyof T>(subscriptionName: K, data: T[K]) => {
        // call subscribers
        Object.values(this._subscriptions[subscriptionName] || {}).forEach((dispatchToSubscriber) =>
            dispatchToSubscriber(data),
        );
    };

    /**
     * listen for an event to react on.
     * @param subscriptionName the event we want to subscribe to
     * @param callbackAction the action called when the subscription is trigerred
     * @param subscriberId usefull to debug: ideally the name of the component subscribing
     * @returns the subscriberId, auto generated or passed in param
     */
    attach = <K extends keyof T>(subscriptionName: K, callbackAction: (data: T[K]) => void, subscriberId?: string) => {
        // add subscriber
        const finalSubscriberId = subscriberId || uniqueId();

        if (!this._subscriptions[subscriptionName]) {
            this._subscriptions[subscriptionName] = {};
        }

        // we checked the subscription exist so we know it's not undefined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const subscribers = this._subscriptions[subscriptionName]! as any; // todo handle that
        subscribers[finalSubscriberId] = callbackAction;

        return { subscriberId: finalSubscriberId };
    };

    /**
     * stop listening for event.
     * @param subscriptionName the event we want to unsubscribe from.
     * @param subscriberId the owner of the subscription
     */
    detach = <K extends keyof T>(subscriptionName: K, subscriberId: string) => {
        delete this._subscriptions[subscriptionName]?.[subscriberId];
    };
}

export default ObserverService;
