/**
 * round the number to nearest, up or down to specified decimale
 */
const roundNumber = (value: number | null | undefined | '', overrideOptions?: FormatNumberOptionsT) => {
    const { decimalePlace, round }: FormatNumberOptionsT = {
        decimalePlace: 2,
        round: 'nearest',
        ...overrideOptions,
    };

    let roundFun;
    if (round === 'up') {
        roundFun = Math.ceil;
    } else if (round === 'down') {
        roundFun = Math.floor;
    } else if (round === 'nearest') {
        roundFun = Math.round;
    }
    if (roundFun) {
        return roundFun(Number(value) * 10 ** decimalePlace) / 10 ** decimalePlace;
    }

    return Number(value);
};

export type FormatNumberOptionsT = { decimalePlace?: number; round: 'up' | 'down' | 'nearest' };
export default roundNumber;
