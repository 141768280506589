export const VERIFY_EMAIL_LOADING = 'VERIFY_EMAIL_LOADING';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';

export type VerifyEmailActionDataT = {
    hashedId: string;
    expires: string;
    signature: string;
};

export type VerifyEmailResponseDataT = {
    result: boolean;
};

export type VerifyEmailResponseErrorT = {
    message?: string;
    list?: string[];
};

export type VerifyEmailLoadingT = {
    type: typeof VERIFY_EMAIL_LOADING;
};

export type VerifyEmailFailedT = {
    type: typeof VERIFY_EMAIL_FAILED;
    error: VerifyEmailResponseErrorT;
};

export type VerifyEmailSuccessT = {
    type: typeof VERIFY_EMAIL_SUCCESS;
    payload: VerifyEmailResponseDataT;
};

export type VerifyEmailDispatchTypesT = VerifyEmailLoadingT | VerifyEmailFailedT | VerifyEmailSuccessT;
