import suggestionConfidenceThreshold from '@constants/suggestionConfidenceThreshold';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import OnboardingApiService from '@services/onboardingApiService/onboardingApiService';
import {
    FieldSuggestionTypeT,
    SuggestedFieldT,
    SuggestionResponseT,
} from '@services/onboardingApiService/onboardingApiServiceTypes';
import SegmentService from '@services/segmentService/segmentService';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useModalFieldsSuggestion from '../components/ModalFieldsSuggestion/ModalFieldsSuggestion.controller';
import useModalNoFieldsSuggestion from '../components/ModalNoFieldsSuggestion/ModalNoFieldsSuggestion.controller';
import { UseFieldSuggestionPropsT } from './useFieldSuggestionTypes';

/** track field suggestion got for places 'cover_crops' | 'tillage_practices' | 'irrigation' | 'drainage' | 'residue' */
const trackFieldSuggested = <T extends FieldSuggestionTypeT>(
    suggestionType: T,
    allSuggestedFields: SuggestionResponseT<T>,
) => {
    let place: 'cover_crops' | 'tillage_practices' | 'irrigation' | 'drainage' | 'residue' | null = null;
    switch (suggestionType) {
        case 'cover-crop':
            place = 'cover_crops';
            break;
        case 'tillage/conventional':
        case 'tillage/no-till':
        case 'tillage/no-tillage':
        case 'tillage/reduced':
            place = 'tillage_practices';
            break;
        case 'irrigation':
            place = 'irrigation';
            break;
        case 'drainage':
            place = 'drainage';
            break;
        case 'residue':
            place = 'residue';
    }

    if (place !== null) {
        SegmentService.dataSuggestionRetrievedTrack({ success: !!allSuggestedFields?.length, place: place });
    }
};

const useFieldSuggestion = <T extends FieldSuggestionTypeT>({
    suggestionType,
    suggestionParam,
    onSuggestionDataFetched,
}: UseFieldSuggestionPropsT<T>) => {
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason.id);
    const { modalFieldsSuggestion } = useModalFieldsSuggestion();
    const { modalNoFieldsSuggestion } = useModalNoFieldsSuggestion();

    const [suggestedFields, setSuggestedFields] = useState<SuggestedFieldT[]>([]);
    const [needAttentionFields, setNeedAttentionFields] = useState<SuggestedFieldT[]>([]);
    const [suggestionDone, setSuggestionDone] = useState<boolean>(false);

    const handleBringBackSuggestion = async () => {
        // Fetch suggestion in background to win some time as farmers should generally accept it.
        const suggestionFetching = OnboardingApiService.getSuggestion({
            farmId,
            farmSeasonId,
            suggestionType,
            suggestionParam,
        });

        await modalFieldsSuggestion?.openModal(async () => {
            const allSuggestedFields = (await suggestionFetching).data;

            setSuggestedFields(allSuggestedFields.filter((sf) => sf.confidence >= suggestionConfidenceThreshold));
            setNeedAttentionFields(allSuggestedFields.filter((sf) => sf.confidence < suggestionConfidenceThreshold));
            setSuggestionDone(true);

            trackFieldSuggested(suggestionType, allSuggestedFields);

            if (!allSuggestedFields?.length) {
                modalNoFieldsSuggestion?.openModal();
            }
        });

        const data = (await suggestionFetching).data;
        onSuggestionDataFetched?.(data);
        return data;
    };

    return {
        /** handle modals display (suggestion info and maybe no suggestion result) + suggestion fetching */
        handleBringBackSuggestion,
        suggestedFields,
        needAttentionFields,
        suggestionDone,
    };
};

export default useFieldSuggestion;
