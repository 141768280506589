import { Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import DatePicker from '@components/DatePicker/DatePicker';
import { Prompt } from '@components/Prompt/Prompt';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';
import useHistoryMaxYear from '@hooks/useHistoryMaxYear';

import useHistoryFormStyle from './HistoryFormView.style';
import { HistoryFormViewPropsT } from './HistoryFormTypes';

const HistoryFormView = ({
    groupedFieldIndex,
    t,
    formik,
    nothingToSave,
    tillagePracticeList,
    fromLabour,
    setCurrentStep,
    canEdit = false,
}: HistoryFormViewPropsT): JSX.Element => {
    const { classes } = useHistoryFormStyle();

    const historyMaxYear = useHistoryMaxYear();

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!nothingToSave}
            />
            <Typography className={classes.groupedFieldTitle}>
                {t('pages.installationtasks.history.tillage-practice.history-form.tillage-practice-title')}{' '}
                {groupedFieldIndex}
            </Typography>
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.history.tillage-practice.history-form.title">
                    Veuillez ajouter les informations pour votre
                    <span className={classes.titleDark}>couvert végétal</span>
                </Trans>
                <HelpButtonView id="pages.installationtasks.history.tillage-practice.history-form.title" />
            </Typography>
            <Grid className={classes.groupInputContainer} container>
                <Grid className={classes.inputContainer} xs={12} item>
                    <LabelAutocomplete
                        disabled={!canEdit}
                        error={formik.touched.tillage_change_type_id && !!formik.errors.tillage_change_type_id}
                        helpName="pages.installationtasks.hsitory.tillage-practice.tillage-practice-change"
                        helperText={
                            formik.touched.tillage_change_type_id ? formik.errors.tillage_change_type_id : undefined
                        }
                        id="tillage_change_type_id"
                        label={t('constants.tillage-practice-change')}
                        options={tillagePracticeList}
                        value={formik.values.tillage_change_type_id ? Number(formik.values.tillage_change_type_id) : ''}
                        required
                        withHelp
                        onChange={(tillageId) => {
                            formik.setFieldValue('tillage_change_type_id', tillageId, false);
                            setTimeout(() => formik.setTouched({ tillage_change_type_id: true }));
                            formik.setFieldValue(
                                'key',
                                `${formik.values.year_of_change}-${tillageId}-${formik.values.year_end_labour}`,
                            );
                        }}
                    />
                </Grid>
                <Grid className={classes.inputContainer} xs={12} item>
                    <DatePicker
                        disabled={!canEdit}
                        // initialFocusedDate={historyMaxYear}
                        labelText={t('constants.year-of-change')}
                        maxDate={historyMaxYear}
                        openTo="year"
                        value={formik.values.year_of_change === '' ? '' : new Date(formik.values.year_of_change)}
                        withHelp="pages.installationtasks.hsitory.tillage-practice.year-of-change"
                        onChange={(event) => {
                            const year = new Date(event).getFullYear().toString();
                            formik.setFieldValue('year_of_change', year);
                            formik.setFieldValue(
                                'key',
                                `${year}-${formik.values.tillage_change_type_id}-${formik.values.year_end_labour}`,
                            );
                            setTimeout(() => formik.setTouched({ year_of_change: true }));
                        }}
                    />
                    {formik.touched.year_of_change && !!formik.errors.year_of_change && (
                        <div className={classes.errorCombination}>{formik.errors.year_of_change}</div>
                    )}
                </Grid>
                {formik.errors.key && (
                    <Typography className={classes.errorCombination} variant="body2">
                        {formik.errors.key}
                    </Typography>
                )}

                {!fromLabour && (
                    <Grid className={classes.inputContainer} xs={12} item>
                        <Typography className={classes.defineConventional} variant="body2">
                            {t(
                                'pages.installationtasks.history.tillage-practice.history-form.define-end-conventional-till',
                            )}
                        </Typography>
                        <DatePicker
                            disabled={!canEdit}
                            // initialFocusedDate={historyMaxYear}
                            labelText={t(
                                'pages.installationtasks.history.tillage-practice.history-form.conventional-till-end-year',
                            )}
                            maxDate={historyMaxYear}
                            openTo="year"
                            value={formik.values.year_end_labour === '' ? '' : new Date(formik.values.year_end_labour)}
                            withHelp="pages.installationtasks.history.tillage-practice.history-form.conventional-till-end-year"
                            onChange={(event) => {
                                const year = new Date(event).getFullYear().toString();
                                formik.setFieldValue('year_end_labour', year);
                                formik.setFieldValue(
                                    'key',
                                    `${formik.values.year_of_change}-${formik.values.tillage_change_type_id}-${year}`,
                                );
                                setTimeout(() => formik.setTouched({ year_end_labour: true }));
                            }}
                        />
                        {formik.touched.year_end_labour && !!formik.errors.year_end_labour && (
                            <Typography className={classes.errorYearLabour} variant="body2">
                                {formik.errors.year_end_labour}
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>
            <div className={classes.footerButton}>
                <TwoButtonFooterView
                    rightDisabled={!formik.isValid}
                    rightOnCLick={canEdit ? formik.handleSubmit : () => setCurrentStep(3)}
                    rightText={t('constants.continue')}
                />
            </div>
        </div>
    );
};

export default HistoryFormView;
