import { makeStyles } from 'tss-react/mui';
import { StickyHeaderLayoutStylePropsT } from './StickyHeaderLayoutTypes';

const useStickyHeaderLayoutStyle = makeStyles<StickyHeaderLayoutStylePropsT>()(
    (_, { bgColor, paddingTop, fillParentPadding }) => ({
        StickyHeaderLayout: {
            background: `linear-gradient(0deg, rgba(0,0,0,0) 0, ${bgColor} 10px)`,
            position: 'sticky',
            top: '0',
            padding: `${paddingTop ?? 0} 10px 21px 10px`,
            margin: '0 -10px',
            zIndex: '1',
            ...(fillParentPadding && { marginTop: `-${fillParentPadding}`, top: `-${fillParentPadding}` }),
        },
    }),
);

export default useStickyHeaderLayoutStyle;
