import App from '@/App';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '@store/store';
import { IntercomProvider } from 'react-use-intercom';
import { pdfjs } from 'react-pdf';
import './assets/css/index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@theme/theme';
import { CssBaseline, Slide, StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './constants/auth0';
import { SnackbarProvider } from 'notistack';
import { CustomSnackbar } from './ui-kit/components/CustomSnackbar/CustomSnackbar';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
    <Auth0Provider clientId={AUTH0_CLIENT_ID} domain={AUTH0_DOMAIN} redirectUri={window.location.origin}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_KEY ?? ''} autoBoot>
            <Provider store={store}>
                <BrowserRouter>
                    <StyledEngineProvider injectFirst>
                        <CssBaseline />
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider
                                maxSnack={3}
                                Components={{
                                    success: CustomSnackbar,
                                    error: CustomSnackbar,
                                    info: CustomSnackbar,
                                }}
                                TransitionComponent={(props) => <Slide {...props} direction="up" />}
                            >
                                <App />
                            </SnackbarProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </BrowserRouter>
            </Provider>
        </IntercomProvider>
    </Auth0Provider>,
);
