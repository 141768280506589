import { SeasonT } from '../reducers/StaticReducer/StaticReducerType';
import { AppStateT } from '../store';

/** return current season. "Current season" = the last season active corresponding to the farm country group id */
const selectCurrentSeason =
    () =>
    (state: AppStateT): SeasonT | null => {
        const sortedDescSeasons = state.static.seasons
            ?.filter((s) => s.active && s.country_group_id === state.farm.currentFarm?.country.country_group_id)
            ?.sort((s1, s2) => s2.id - s1.id);
        return sortedDescSeasons?.[0] ?? null;
    };

export default selectCurrentSeason;
