import InstallationTasksRouter from '@routers/InstallationTasksRouter/InstallationTasksRouter';
import { ROUTE } from '@constants/routes';
import useAppDispatch from '@hooks/useAppDispatch';
import { routingRedirect } from '@store/actions/RoutingActions/RoutingActions';
import selectIsYoySeason from '@store/selectors/selectIsYoySeason';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HomePageStateT } from '../types/HomePageStoreTypes';
import ModalFieldsSuggestion from './components/ModalFieldsSuggestion/ModalFieldsSuggestion';
import ModalNoFieldsSuggestion from './components/ModalNoFieldsSuggestion/ModalNoFieldsSuggestion';

const InstallationView = (): JSX.Element => {
    const isYoySeason = useSelector(selectIsYoySeason());
    const currentFarmSeason = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason);
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const [isFirstInitialization, setIsFirstInitialization] = useState(true);

    useEffect(() => {
        if (pathname !== ROUTE.SELF_ENCODING) {
            // don't redirect if we are coming from outside the self encoding (=first initialization) meaning we didn't switched the season
            if (!isFirstInitialization) {
                dispatch(routingRedirect({ route: ROUTE.SELF_ENCODING }));
            }
        }
        if (isFirstInitialization) {
            setIsFirstInitialization(false);
        }
    }, [currentFarmSeason.id]);

    return (
        <>
            <InstallationTasksRouter />

            {/* ---------------- Modals controllable everywhere in Onboarding (installation) --------------- */}
            {isYoySeason && <ModalFieldsSuggestion />}
            {isYoySeason && <ModalNoFieldsSuggestion />}
        </>
    );
};

export default InstallationView;
