import { Dispatch } from '@reduxjs/toolkit';
import PaymentApiService from '@services/paymentApiService/paymentApiService';
import { AxiosError } from 'axios';

import {
    HasPaidSecondDispatchTypesT,
    HAS_PAID_SECOND_LOADING,
    HAS_PAID_SECOND_FAILED,
    HAS_PAID_SECOND_SUCCESS,
    HasPaidSecondPayloadT,
    HasPaidSecondResponseErrorT,
} from './HasPaidSecondActionsTypes';

const hasPaidSecond =
    (farmId: number, farmSeasonId: number) =>
    async (dispatch: Dispatch<HasPaidSecondDispatchTypesT>): Promise<HasPaidSecondResponseErrorT> => {
        try {
            dispatch({
                type: HAS_PAID_SECOND_LOADING,
            });

            const res: HasPaidSecondPayloadT = await PaymentApiService.getHasPaidSecond(farmId, farmSeasonId);

            dispatch({
                type: HAS_PAID_SECOND_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (e) {
            const error = e as AxiosError<string>;

            let list: string[] = [];

            if (error.response?.data) {
                list = Object.keys(JSON.parse(error.response.data));
            }

            dispatch({
                type: HAS_PAID_SECOND_FAILED,
                error: {
                    message: error.response?.data || error.message,
                    list,
                },
            });

            return {
                message: error.response?.data || error.message,
                list,
            };
        }
    };

export default hasPaidSecond;
