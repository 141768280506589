import { FarmSeasonT } from '../reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { AppStateT } from '../store';

const selectPreviousFarmSeason =
    () =>
    (state: AppStateT): FarmSeasonT | undefined => {
        const orderedFarmSeasons = [...state.farmSeason.farmSeasonList].sort((s1, s2) => s1.id - s2.id);
        const currentFarmSeason = state.farmSeason.currentFarmSeason;
        const currentFarmSeasonIndex = orderedFarmSeasons.findIndex((fs) => fs.id === currentFarmSeason?.id);
        return orderedFarmSeasons[currentFarmSeasonIndex - 1];
    };

export default selectPreviousFarmSeason;
