/* eslint-disable @typescript-eslint/no-explicit-any */
import { OrganicFertilizationListGetSuccessDataT } from '@actions/StaticActions/OrganicFertilizationGetListActionTypes';
import { FertiliserT } from '@reducers/FertiliserReducer/FertiliserReducerTypes';
import request from '@services/apiService/apiService';
import { FertilisersGetSuccessDataT } from '@actions/StaticActions/FertilisersGetActionTypes';
import { AxiosResponse } from 'axios';

import { FertiliserCreateT } from './FertiliserApiServiceTypes';

class FertiliserApiService {
    public static getFertilisersList = async (
        countryId: number,
        farmId: number,
    ): Promise<AxiosResponse<FertilisersGetSuccessDataT>> =>
        request({
            url: `/farm/${farmId}/fertilisers?countryId=${countryId}`,
            method: 'GET',
        });

    public static createFertiliser = async (
        farmId: number,
        fertiliser: FertiliserCreateT,
    ): Promise<AxiosResponse<FertiliserT>> => {
        if (!farmId) {
            throw Error(`no farmId while while calling createFertiliser`);
        }

        if (!fertiliser) {
            throw Error(`no fertiliser data while while calling createFertiliser`);
        }

        const data: any = {
            ...fertiliser,
            translation_slug: fertiliser.name ?? '',
            high_emission: false,
        };

        return request({
            url: `/farm/${farmId}/fertilisers`,
            method: 'POST',
            data,
        });
    };

    public static getOrganicFertilizationList = async (
        countryId: number,
    ): Promise<AxiosResponse<OrganicFertilizationListGetSuccessDataT>> =>
        request({
            url: `/organic-fertilisers?countryId=${countryId}`,
            method: 'GET',
        });
}

export default FertiliserApiService;
