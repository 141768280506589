import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import FarmSeasonFieldsAgroforestryValuesApiService from '@services/farmSeasonFieldsAgroforestryValuesApiService/farmSeasonFieldsAgroforestryValuesApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonFieldsAgroforestryValuesPostCallBackT,
    PostFarmSeasonFieldAgroforestryValuesDataT,
    PostFarmSeasonFieldAgroforestryValuesDispatchTypesT,
    PostFarmSeasonFieldAgroforestryValuesResponseDataT,
    PostFarmSeasonFieldAgroforestryValuesResponseErrorT,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_FAILED,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_LOADING,
    POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS,
} from './PostFarmSeasonFieldAgroforestryValuesTypes';

const postFarmSeasonFieldAgroforestryValues =
    (
        farmId: number,
        farmSeasonId: number,
        farmSeasonFieldId: number,
        body: PostFarmSeasonFieldAgroforestryValuesDataT,
        fieldsList: FarmSeasonFieldT[],
    ): FarmSeasonFieldsAgroforestryValuesPostCallBackT =>
    async (
        dispatch: Dispatch<PostFarmSeasonFieldAgroforestryValuesDispatchTypesT>,
    ): Promise<PostFarmSeasonFieldAgroforestryValuesResponseErrorT> => {
        try {
            dispatch({ type: POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_LOADING });

            // TODO: Find why the data for diameter and quantity are return as string and not as number
            const res: AxiosResponse<PostFarmSeasonFieldAgroforestryValuesResponseDataT> =
                await FarmSeasonFieldsAgroforestryValuesApiService.post(farmId, farmSeasonId, farmSeasonFieldId, body);

            const newFieldList = fieldsList.map((field): FarmSeasonFieldT => {
                if (field.id === res.data.farm_season_field_id) {
                    const newField = { ...field, agroforestry: [...res.data.agroforestry] };

                    return newField;
                }

                return field;
            });

            dispatch({
                type: POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_SUCCESS,
                payload: {
                    fieldsList: newFieldList,
                    installation: res.data.installation,
                },
            });

            return {};
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_FIELDS_AGROFORESTRY_VALUES_FAILED,
                error: error as PostFarmSeasonFieldAgroforestryValuesResponseErrorT,
            });

            return {};
        }
    };

export default postFarmSeasonFieldAgroforestryValues;
export const placeholder = '';
