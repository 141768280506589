import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

export default makeStyles()((theme: Theme) => {
    return {
        container: {
            height: '100vh',
            width: '100vw',
            backgroundColor: theme.palette.spanishWhite.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
