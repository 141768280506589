import MenuBar from '@components/MenuBar/MenuBar';
import { useTranslation } from 'react-i18next';
import { ModalWarning } from '@soil-capital/ui-kit';
import ListStepView from './ListStep/ListStep';
import EditStep from './EditStep/EditStep';
import useCropsLogic from './Crops.logic';
import useCropsStyle from './Crops.style';

const Crops = (): JSX.Element => {
    const {
        currentStep,
        selectedCrop,
        addOrModifyCrop,
        popStep,
        modalDeleteCrop,
        onCropDelete,
        controllerWarningOperationDone,
        controllerWarningHasPermanentField,
    } = useCropsLogic();

    const { classes } = useCropsStyle();
    const { t } = useTranslation();

    const editTitle = selectedCrop?.id ? t('pages.crops.title-edit') : t('pages.crops.title-create');

    return (
        <div>
            <div className={classes.container}>
                <MenuBar
                    className={classes.menuBar}
                    menuTitle={currentStep === 2 ? editTitle : t('pages.crops.title')}
                    onArrowBack={currentStep === 2 ? popStep : undefined}
                    onClose="/"
                />
                {currentStep === 1 && <ListStepView addOrModifyCrop={addOrModifyCrop} onCropDelete={onCropDelete} />}
                {currentStep === 2 && <EditStep popStep={popStep} selectedCrop={selectedCrop} />}
            </div>
            <ModalWarning
                controller={modalDeleteCrop}
                confirmMessage={t('pages.crops.delete-modal.confirm-message')}
                description={t('pages.crops.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.crops.delete-modal.title')}
            />
            {/* warning when delete a crop related to operations when any operation is done */}
            <ModalWarning
                controller={controllerWarningOperationDone}
                description={t('modal.warning.crop-linked-to-operations-give-error-on-delete')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
            <ModalWarning
                controller={controllerWarningHasPermanentField}
                description={t('modal.warning.delete-crop.permanent-crop.description')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
        </div>
    );
};

export default Crops;
