import FarmSeasonCropsApiService from '@services/farmSeasonCropsApiService/farmSeasonCropsApiService';
import { AxiosResponse } from 'axios';
import { logoutFarmerOrRedirectAgro } from '../AuthActions/LogoutActions';

import {
    FarmSeasonCropsGetCallBackT,
    GetFarmSeasonCropsResponseDataT,
    GET_FARM_SEASON_CROPS_LOADING,
    GET_FARM_SEASON_CROPS_SUCCESS,
} from './GetFarmSeasonCropsTypes';

export const getFarmSeasonCrops =
    (farmId: number, farmSeasonId: number): FarmSeasonCropsGetCallBackT =>
    async (dispatch) => {
        try {
            dispatch({ type: GET_FARM_SEASON_CROPS_LOADING });
            const res: AxiosResponse<GetFarmSeasonCropsResponseDataT> = await FarmSeasonCropsApiService.get(
                farmId,
                farmSeasonId,
            );

            dispatch({
                type: GET_FARM_SEASON_CROPS_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (error) {
            // dispatch({
            //     type: GET_FARM_SEASON_CROPS_FAILED,
            //     error: error as GetFarmSeasonCropsResponseErrorT,
            // });

            // // FP-3509 - reset store to avoid corrupted data
            // logout()(dispatch);

            // FP-4233 - redirect agros instead of logout them
            logoutFarmerOrRedirectAgro()(dispatch);

            return {};
        }
    };

export const placeholder = '';
