import JSZip from 'jszip';
import ShpJs from 'shpjs';
import proj4 from 'proj4';
import parseWKT from 'wkt-parser'; // Stolen from PROJ4JS - MIT LINCENSED ANYWAYS
import all from 'epsg-index/all.json';
import i18n from 'i18next';
import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import _ from 'lodash';
import { isNullOrEmpty } from '@utils/stringHelper';
import { Optional } from '@/utils/OptionalType';
import { Feature, Polygon } from './shapeServiceTypes';
import { CapFileBuffersT } from '@components/ModalUploadCapFile/ModalUploadCapFileTypes';
import osgbGrid from '@assets/ngrid/OSTN15_NTv2_OSGBtoETRS.gsb';

// Define EPSG:31370 as seen from the https://epsg.io/31370 output
proj4.defs(
    'EPSG:31370',
    '+proj=lcc +lat_0=90 +lon_0=4.36748666666667 +lat_1=51.1666672333333 +lat_2=49.8333339 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs +type=crs',
);

// Define EPSG:27700+OSTN15
proj4.defs(
    'EPSG:27700+OSTN15',
    '+proj=lcc +lat_0=90 +lon_0=4.36748666666667 +lat_1=51.1666672333333 +lat_2=49.8333339 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs +type=crs',
);
class ShapeFileService {
    public static defaultFieldNameSlug = 'constants.field-prefix';

    static getFilesBuffers = async (files: File[]) => {
        const filesBuffers: { [fileName: string]: CapFileBuffersT & { isFromZip?: true } } = {};
        const missingOrUnknownExtensionErrors: string[] = [];

        for (const file of files) {
            // Get array of splitted string by dot
            // This array can be of any length as the filename may contain multiple dots
            const splitedName = file.name.split('.');
            // We pop the last array value which will be equal to the file extension
            splitedName.pop();
            // We join whats left in the array to create the filename
            // Example of filenames that would cause a bug if not doing this method:
            // abc.1.shp or abc.2.3.4.dbf
            const fileName = splitedName.join('.');

            filesBuffers[fileName] = filesBuffers[fileName] ?? { dbfBuffer: null, prj: null, shpBuffer: null };

            if (file.name.includes('.zip')) {
                filesBuffers[fileName] = await this.unzipShpAndDbf(file);
                filesBuffers[fileName].isFromZip = true;
            } else if (file.name.includes('.dbf')) {
                filesBuffers[fileName].dbfBuffer = await this.readFile(file, 'arraybuffer');
            } else if (file.name.includes('.shp')) {
                filesBuffers[fileName].shpBuffer = await this.readFile(file, 'arraybuffer');
            } else if (file.name.includes('.prj')) {
                filesBuffers[fileName].prj = await this.readFile(file, 'text');
            } else if (file.name.includes('.cst') || file.name.includes('.shx')) {
                // do nothing, but it's not an error
            } else {
                missingOrUnknownExtensionErrors.push(`file extension is not processable for "${file.name}"`);
                delete filesBuffers[fileName];
            }
        }

        for (const fileName in filesBuffers) {
            const buffers = filesBuffers[fileName];

            const missingExtensions = this.getMissingShpDbfExtensions(buffers);
            if (missingExtensions) {
                missingOrUnknownExtensionErrors.push(
                    buffers.isFromZip
                        ? i18n.t('validation.invalid-zip', { missingExtensions, fileName })
                        : i18n.t('validation.missing-mandatory-files', {
                              missingExtensions,
                              fileName,
                          }),
                );
            }
        }

        return { missingOrUnknownExtensionErrors, filesBuffers };
    };

    static getFilesEpsg = async (filesBuffers: { [fileName: string]: CapFileBuffersT }, countryId: number) => {
        let defaultPrjUsed = false;
        const missingPrjErrors: string[] = [];
        const filesEpsg: { [fileName: string]: number | string } = {};

        for (const fileName in filesBuffers) {
            const buffers = filesBuffers[fileName];
            // get default prj
            if (!buffers.prj) {
                const { defaultPrj, prj } = await this.getDefaultPrj(countryId);
                buffers.prj = prj;
                defaultPrjUsed = defaultPrj;
            }
            // error if no prj at all
            if (!buffers.prj) {
                missingPrjErrors.push(i18n.t('validation.missing-mandatory-prj-file', { fileName }));
                continue;
            }
            const { epsg, isDefaultPrjUsed } = await this.getEpsgFromZip(buffers.prj, countryId);
            filesEpsg[fileName] = epsg;
            defaultPrjUsed = defaultPrjUsed || isDefaultPrjUsed;
        }

        return { missingPrjErrors, defaultPrjUsed, filesEpsg };
    };

    static generateFields = (
        filesBuffers: { [fileName: string]: CapFileBuffersT },
        filesEpsg: { [fileName: string]: number | string },
        countryId: number,
    ) => {
        const uploadedFields: Optional<FarmSeasonFieldT, 'id'>[] = [];
        let fileFormatError: string | null = null;
        let currentIndex = 0; // handle index for incremental name

        for (const fileName in filesBuffers) {
            const buffers = filesBuffers[fileName];
            const epsg = filesEpsg[fileName];
            const features = this.generateGeoJson(buffers.shpBuffer, buffers.dbfBuffer);

            // TODO should those errors block the generation ?
            
            if (features.find((f) => f?.properties?.ILOT_REF)) {
                fileFormatError = i18n.t('validation.ilot-file-not-supported');
            } else if (features.find((f) => f?.properties?.clefedi)) {
                fileFormatError = i18n.t('validation.file-format-not-supported');
            }

            let fromProjection: string = (all as any)[epsg].proj4;
            if (epsg === 'EPSG:27700+OSTN15') {
                fromProjection = 'EPSG:27700+OSTN15';
            }
            if(epsg === 31370) {
                fromProjection = 'EPSG:31370';
            }
            const toProjection: string = (all as any)[4326].proj4;

            const fields = features
                .map((feature) => {
                    let coordinates = feature.geometry.coordinates[0].map((positionArray) =>
                            proj4(fromProjection, toProjection, positionArray),
                        );

                    currentIndex += 1;
                    const fieldName = this.getName(feature, currentIndex);
                    return {
                        name: fieldName,
                        area: this.getArea(feature),
                        area_source: 'pac',
                        shapefileCrop: this.getCrop(feature),
                        shapefileCountry: this.getCountryIso2(feature, countryId),
                        polygon: {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            id: `${_.uniqueId()}_` as any, // don't match the number type
                            type: 'Feature',
                            geometry: {
                                ...feature.geometry,
                                coordinates: [coordinates],
                            },
                            properties: {
                                label: fieldName,
                                area: this.getArea(feature),
                                area_source: 'pac',
                            },
                        },
                        metadata: feature,
                    };
                })
                .filter((f) => !!f) as Optional<FarmSeasonFieldT, 'id'>[];
            uploadedFields.push(...fields);
        }
        return { uploadedFields, fileFormatError };
    };

    private static getMissingShpDbfExtensions = ({ dbfBuffer, shpBuffer }: CapFileBuffersT): string | false => {
        const missings: string[] = [];
        if (!shpBuffer) missings.push('.shp');
        if (!dbfBuffer) missings.push('.dbf');

        if (shpBuffer === null || dbfBuffer === null) {
            return missings.join(', ');
        }
        return false;
    };

    private static getEpsgFromZip = async (
        prj: string,
        countryId: number,
    ): Promise<{ epsg: number | string; isDefaultPrjUsed: boolean }> => {
        let parsed = parseWKT(prj);
        let authority = parsed.AUTHORITY;
        let defaultPrjUsed = false;

        // MSC-2043 - switch back to a default PRJ
        if (!authority) {
            // MSC-2472
            if (!isNullOrEmpty(parsed.datumCode) && (parsed.datumCode as string).toLocaleLowerCase() === 'wgs84') {
                authority = {
                    epsg: '4326',
                };
            } else if (
                !isNullOrEmpty(parsed.datumCode) &&
                (parsed.datumCode as string).toLocaleLowerCase() === 'osgb36'
            ) {
                if (parsed?.name === 'British_National_Grid') {
                    await fetch(osgbGrid)
                        .then((response) => response.arrayBuffer())
                        .then((arrayBuffer) => {
                            proj4.nadgrid('OSTN15_NTv2_OSGBtoETRS', arrayBuffer);
                            proj4.defs(
                                'EPSG:27700+OSTN15',
                                `+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +nadgrids=@OSTN15_NTv2_OSGBtoETRS +units=m +no_defs`,
                            );
                        })
                        .catch((error) => {
                            console.error('Error fetching or converting the file:', error);
                        });
                    authority = {
                        epsg: 'EPSG:27700+OSTN15',
                    };
                } else {
                    authority = {
                        epsg: '27700',
                    };
                }
            } else {
                const result = await this.getDefaultPrj(countryId);
                parsed = parseWKT(result.prj);
                authority = parsed.AUTHORITY;
                defaultPrjUsed = result.defaultPrj;
            }
        }

        return {
            epsg: parsed?.name === 'British_National_Grid' ? authority.epsg : Number(authority.epsg || authority.EPSG),
            isDefaultPrjUsed: defaultPrjUsed,
        };
    };

    private static getDefaultPrj = async (countryId: number) => {
        const result: { prj: string | null; defaultPrj: boolean } = {
            prj: null,
            defaultPrj: false,
        };

        if (countryId === 1) {
            // FR
            result.prj = await this.readstaticFileAsString('/shp_country_projections/fr.prj');
            result.defaultPrj = true;
        }
        if (countryId === 4) {
            // BE
            result.prj = await this.readstaticFileAsString('/shp_country_projections/be.prj');
            result.defaultPrj = true;
        }
        if (countryId === 7) {
            // UK
            result.prj = await this.readstaticFileAsString('/shp_country_projections/uk.prj');
            result.defaultPrj = true;
        }

        return result;
    };

    /*
     * Read File Buffer as Binary string
     */
    private static readFile = <T extends 'arraybuffer' | 'text'>(file: File, mode: T) =>
        new Promise<(T extends 'arraybuffer' ? ArrayBuffer : string) | null>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as (T extends 'arraybuffer' ? ArrayBuffer : string) | null);
            };

            if (mode === 'arraybuffer') {
                reader.readAsArrayBuffer(file);
            }

            if (mode === 'text') {
                reader.readAsText(file);
            }
        });

    /*
     * Read pure-text files within static path and returns as a string
     */
    private static readstaticFileAsString = async (fileUrl: string) => {
        const fileFetch = await fetch(fileUrl);
        return fileFetch.text();
    };

    /*
     * Unzip file buffer and set shpBuffer and dbfBuffer
     */
    private static unzipShpAndDbf = async (file: File): Promise<CapFileBuffersT> => {
        let shpName = '';
        let dbfName = '';
        let prjName = '';

        const unzipResult: CapFileBuffersT = {
            shpBuffer: null,
            dbfBuffer: null,
            prj: null,
            // isDefaultPrjUsed: false,
        };

        const fileBuffer = await this.readFile(file, 'arraybuffer');

        if (fileBuffer?.byteLength) {
            (await JSZip.loadAsync(fileBuffer)).forEach((relativePath) => {
                // This condition allows us to support official RPA zip provided in UK
                // The decision was taken to allow this complexity to exist in code becauser it will cover most of the use cases in the UK
                // Today when working with RPA we only import LandParcels and we don't care about other files
                if (relativePath.includes('data/')) {
                    if (
                        relativePath.includes('LandParcels.shp') &&
                        // we exclude xml for RPA shp because there are filed named .shp.xml
                        !relativePath.includes('xml') &&
                        !relativePath.includes('MACOS')
                    )
                        shpName = relativePath;
                    if (relativePath.includes('LandParcels.dbf') && !relativePath.includes('MACOS'))
                        dbfName = relativePath;
                    if (relativePath.includes('LandParcels.prj') && !relativePath.includes('MACOS'))
                        prjName = relativePath;
                } else {
                    if (relativePath.includes('.shp') && !relativePath.includes('MACOS')) shpName = relativePath;
                    if (relativePath.includes('.dbf') && !relativePath.includes('MACOS')) dbfName = relativePath;
                    if (relativePath.includes('.prj') && !relativePath.includes('MACOS')) prjName = relativePath;
                }
            });
        }

        if (shpName && dbfName && fileBuffer?.byteLength) {
            await JSZip.loadAsync(fileBuffer).then(async (zip) => {
                unzipResult.shpBuffer = (await zip.file(shpName)?.async('arraybuffer')) ?? null;
                unzipResult.dbfBuffer = (await zip.file(dbfName)?.async('arraybuffer')) ?? null;
                unzipResult.prj = (await zip.file(prjName)?.async('string')) ?? null;
            });
        }

        return unzipResult;
    };

    private static generateGeoJson = (shpBuffer: ArrayBuffer | null, dbfBuffer: ArrayBuffer | null) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = ShpJs.combine([(ShpJs.parseShp as any)(shpBuffer), (ShpJs.parseDbf as any)(dbfBuffer)]).features;
        return result as Feature<Polygon>[];
    };

    // eslint-disable-next-line
    private static getName = (feature: any, index: number): string => {
        // BE
        if (feature.properties.nom_parc) {
            return feature.properties.nom_parc;
        }
        // UK 1
        if (feature.properties.field_name) {
            return feature.properties.field_name;
        }
        // UK 2
        if (feature.properties.Name) {
            return feature.properties.Name;
        }
        // UK 3
        if (feature.properties.f_name) {
            return feature.properties.f_name;
        }
        // FR
        if (feature.properties.NUMERO_I && feature.properties.NUMERO_P) {
            return `Parcelle ${feature.properties.NUMERO_I}-${feature.properties.NUMERO_P}`;
        }
        // RPA does not include a name
        // default - /!\ this string pattern is used outside...
        return `${i18n.t(this.defaultFieldNameSlug)} ${index}`;
    };

    // eslint-disable-next-line
    private static getCrop = (feature: any): number => {
        // FR
        if (feature.properties.TYPE) {
            return feature.properties.TYPE;
        }
        // BE
        if (feature.properties.code_cult) {
            return feature.properties.code_cult;
        }
        // UK
        if (feature.properties.crop) {
            return feature.properties.crop;
        }
        // UK no crop data // RPA we don't support crops yet
        return -1;
    };

    // eslint-disable-next-line
    private static getArea = (feature: any): number => {
        // FR
        if (feature.properties.SURF) {
            return feature.properties.SURF;
        }
        // BE
        if (feature.properties.sup_decl) {
            return feature.properties.sup_decl;
        }
        // UK 1
        if (feature.properties.calc_area) {
            return feature.properties.calc_area;
        }
        // UK 2
        if (feature.properties.Area_ha) {
            return feature.properties.Area_ha;
        }
        // UK 3
        if (feature.properties.legl_area) {
            return feature.properties.legl_area;
        }
        // RPA UK
        if (feature.properties.area_ha) {
            return feature.properties.area_ha;
        }
        return 0;
    };

    // return ISO_2 Country based on properties // TODO: FIND A BETTER WAY TO HANDLE MULTI COUNTRY
    private static getCountryIso2 = (feature: Feature, countryId: number): string | null => {
        if (feature?.properties?.TYPE || countryId === 1) return 'FR';
        if (feature?.properties?.code_cult || countryId === 4) return 'BE';
        if (feature?.properties?.calc_area || countryId === 7) return 'UK';

        return null;
    };
}

export default ShapeFileService;
