import { selectedUserKey } from '@/store/reducers/AuthReducer/AuthReducer';
import { selectedCurrentFarmKey, selectedFarmListKey } from '@/store/reducers/FarmReducer/FarmReducer';
import { selectedCurrentFarmSeasonKey } from '@/store/reducers/FarmSeasonReducer/FarmSeasonReducer';

/** clear the localstorage data related to the app behaviour (keep the static data) */
export const clearStorage = () => {
    window.sessionStorage.clear();
    localStorage.removeItem(selectedCurrentFarmSeasonKey);
    localStorage.removeItem(selectedFarmListKey);
    localStorage.removeItem(selectedCurrentFarmKey);
    localStorage.removeItem(selectedUserKey);
};
